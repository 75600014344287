import { combineReducers } from "redux";
import { Item } from "native-base";
import { helpers } from "../common";

const initialState = {
  DiscussionList: {},
  askCommunity: {
    call: false
  }
};

const discussionData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DISCUSSION_SUCCESS":
      // console.log("CLEAR_DETAILS....");
      return {
        ...state,
        DiscussionList: action.payload
      };
    case "ASK_COMMUNITY":
      return {
        ...state,
        askCommunity: action.payload
      };
    default:
      return state;
  }
};

export default combineReducers({
  discussionData
});
