export const getWidth = (item, value) => {
  let counter = 0;
  if (item == "Traffic Index") {
    counter = 250;
  } else {
    counter = 1000;
  }
  let percentage = (100 * value) / counter;
  return percentage + "%";
};

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getBackgroundColor = (item, value) => {
  if (!item.includes("Index")) {
    return (opacity = 1) => getRandomColor();
  }
  if (item.includes("Buisness")) {
    if (value <= 53) {
      return (opacity = 1) => `#2ab002`;
    } else if (value > 53 && value <= 97) {
      return (opacity = 1) => `#e3d400`;
    } else if (value > 97 && value <= 147) {
      return (opacity = 1) => `orange`;
    } else if (value > 147 && value <= 190) {
      return (opacity = 1) => `red`;
    }
  }
  if (item.includes("Clothing")) {
    if (value > 250) {
      return (opacity = 1) => `red`;
    } else if (value >= 80 && value < 130) {
      return (opacity = 1) => `orange`;
    } else if (value < 80) {
      return (opacity = 1) => `green`;
    }
  }
  if (item.includes("Health")) {
    if (Number(value) >= 67 && Number(value) < 100) {
      return (opacity = 1) => `green`;
    } else if (Number(value) >= 33 && Number(value) <= 66) {
      return (opacity = 1) => `orange`;
    } else if (Number(value) < 33) {
      return (opacity = 1) => `red`;
    }
  }
  if (item.includes("Alcohol")) {
    if (value > 200) {
      return (opacity = 1) => `red`;
    } else if (value >= 100 && value < 200) {
      return (opacity = 1) => `orange`;
    } else if (value < 100) {
      return (opacity = 1) => `green`;
    }
  }
  if (item.includes("Crime")) {
    if (value <= 40) {
      return (opacity = 1) => `#2ab002`;
    } else if (value > 40 && value <= 60) {
      return (opacity = 1) => `#e3d400`;
    } else if (value >= 60) {
      return (opacity = 1) => `red`;
    }
  }

  if (item.includes("Pollution")) {
    if (value <= 50) {
      return (opacity = 1) => `#2ab002`;
    } else if (value > 50 && value <= 100) {
      return (opacity = 1) => `#e3d400`;
    } else if (value > 100 && value <= 150) {
      return (opacity = 1) => `orange`;
    } else if (value > 150 && value <= 200) {
      return (opacity = 1) => `#F779A3`;
    } else if (value > 200 && value <= 300) {
      return (opacity = 1) => `#F85B8F`;
    } else if (value > 300) {
      return (opacity = 1) => `#F5105C`;
    }
  }

  if (value <= 100) {
    return (opacity = 1) => `#2ab002`;
  } else if (value > 100 && value <= 200) {
    return (opacity = 1) => `#e3d400`;
  } else if (value > 200) {
    return (opacity = 1) => `red`;
  } else {
    return (opacity = 1) => `#0B5345`;
  }
};

export const _setWeatherTitle = (index) => {
  let weatherTitle;
  switch (index) {
    case 0:
      weatherTitle = "Spring";
      break;
    case 1:
      weatherTitle = "Summer";
      break;
    case 2:
      weatherTitle = "Fall";
      break;
    case 3:
      weatherTitle = "Winter";
      break;
    default:
      break;
  }
  return weatherTitle;
};

export const getMetaDataDate = (name, metaData) => {
  let data = {};
  if (name == "Selected Job Salary") {
    data = metaData?.filter(
      (s) => s.value == "Average Salary for selected job in city"
    )[0];
  } else if (name.includes("Pollution Index")) {
    data = metaData?.filter((s) => s.value.includes("Pollution Index"))[0];
  } else if (name.includes("Buisness Friendliness")) {
    data = metaData?.filter((s) =>
      s.value.includes("Business Friendliness Index")
    )[0];
  } else if (name.includes("Official Language")) {
    data = metaData?.filter((s) => s.value.includes("Official Language(s)"))[0];
  } else {
    data = metaData?.filter((s) => s.value == name)[0];
  }
  return data?.metadata;
};

export const getTitle = (key) => {
  if (key == "costLiving") {
    return "Cost of Living (customized) :";
  } else if (key == "selected_job_salary") {
    return "Selected Job Salary :";
  } else if (key == "noCityApartment1Bdrm") {
    return "Apartment (1 bedroom) Outside of City Centre :";
  } else if (key == "averageCost") {
    return "Coronavirus Spread at State/Province Level (cases to-date) :";
  } else if (key == "averageMonthSalary") {
    return "Average Monthly Net Salary (After Tax) :";
  } else if (key == "population") {
    return "Population :";
  }
};

export const getWeatherTitle = (index) => {
  if (index == 0) {
    return "Spring Weather (℃) :";
  } else if (index == 1) {
    return "Summer Weather (℃) :";
  } else if (index == 2) {
    return "Fall (℃) :";
  } else if (index == 3) {
    return "Winter Weather (℃) :";
  }
};

export const getFactValue = (item, value) => {
  if (!item.includes("Index")) {
    return (opacity = 1) => `#1FDF27`;
  }
  if (item.includes("Buisness") || item.includes("Immigration")) {
    if (value <= 53) {
      return "Very Easy";
    } else if (value > 53 && value <= 97) {
      return "Easy";
    } else if (value > 97 && value <= 147) {
      return "Medium";
    } else if (value > 147 && value <= 190) {
      return "Below Average";
    }
  }

  if (item.includes("Pollution")) {
    if (value <= 50) {
      return "Good";
    } else if (value > 50 && value <= 100) {
      return "Moderate";
    } else if (value > 100 && value <= 150) {
      return "Unhealthy";
    } else if (value > 150 && value <= 200) {
      return "Unhealthy";
    } else if (value > 200 && value <= 300) {
      return "Very Unhealthy";
    } else if (value > 300) {
      return "Hazardous";
    }
  }

  if (item.includes("Traffic")) {
    if (value <= 100) {
      return "Low traffic";
    } else if (value > 100 && value <= 200) {
      return "Moderate traffic";
    } else if (value > 200) {
      return "High traffic";
    }
  }
  if (item.includes("Crime")) {
    if (value < 40) {
      return "Low crime";
    } else if (value >= 40 && value < 60) {
      return "Moderate crime";
    } else if (value >= 60) {
      return "High crime";
    }
  }
  if (item.includes("Clothing")) {
    if (value > 250) {
      return "High";
    } else if (value >= 80 && value < 130) {
      return "Moderate";
    } else if (value < 80) {
      return "Low";
    }
  }
  if (item.includes("Alcohol")) {
    if (value > 200) {
      return "High";
    } else if (value >= 100 && value < 200) {
      return "Moderate";
    } else if (value < 100) {
      return "Low";
    }
  }
  if (item.includes("Social Mobility")) {
    if (value < 30) {
      return "Very Low";
    } else if (value >= 30 && value <= 39.9) {
      return "Low";
    } else if (value >= 40 && value <= 49.9) {
      return "Average";
    } else if (value >= 50 && value <= 59.9) {
      return "Moderate";
    } else if (value >= 60 && value <= 69.9) {
      return "High";
    } else if (value >= 70 && value <= 79.9) {
      return "Very High";
    } else if (value >= 80 && value <= 89.9) {
      return "Very High";
    }
  }
  if (item.includes("Health")) {
    if (Number(value) >= 67 && Number(value) < 100) {
      return "High";
    } else if (Number(value) >= 33 && Number(value) <= 66) {
      return "Moderate";
    } else if (Number(value) < 33) {
      return "Low";
    }
  }
  if (value <= 100) {
    return (opacity = 1) => `#239B56`;
  } else if (value > 100 && value <= 200) {
    return (opacity = 1) => `yellow`;
  } else if (value > 200) {
    return (opacity = 1) => `red`;
  } else {
    return (opacity = 1) => `#0B5345`;
  }
};
