import { StyleSheet, Platform } from "react-native";
import { family } from "../../common";
import { mobilBrower } from "../../common/helpers";

export const styles = StyleSheet.create({
  mainView: {
    height: "100%",
    width: "100%",
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    height: 50,
    width: "100%",
    borderBottomWidth: 0.6,
    alignItems: "center",
    backgroundColor: "white",
  },
  innerView: {
    backgroundColor: "white",
    width: Platform.OS == "web" && !mobilBrower() ? "40%" : "90%",
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    height: "70%",
  },
  listItem: {
    borderBottomWidth: 0.5,
    padding: 15,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  proceedText: {
    color: "white",
    fontFamily: family.semibold,
  },
  itemtext: {
    fontFamily: family.semibold,
    fontSize: 14,
    marginLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
  },
  cancelButton: {
    position: "absolute",
    right: 15,
    top: 15,
  },
  proceedButton: {
    width: "90%",
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginBottom: 20,
    backgroundColor: "#030135",
    marginTop: 10,
    padding: 15,
  },
  headerTitle: {
    color: "white",
    fontFamily: family.semibold,
    padding: 20,
    fontSize: 16,
  },
  titleView: {
    width: "100%",
    backgroundColor: "#030135",
    alignItems: "center",
    flexDirection: "row",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    justifyContent: "center",
  },
});
