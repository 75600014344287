import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity, Text, ScrollView, Image } from "react-native";
import StepIndicator from "react-native-step-indicator";
import { Network } from "../../../service/api";
import { styles } from '../OpportunityReport.styles';
import Images from '../../../common/images';
import { useSelector } from 'react-redux';

const customStyles = {
  stepIndicatorSize: 200,
  stepIndicatorSize: 25,
  currentStepIndicatorSize: 25,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 3,
  stepStrokeCurrentColor: "#030135",
  stepStrokeWidth: 3,
  stepStrokeFinishedColor: "#030135",
  stepStrokeUnFinishedColor: "#030135",
  separatorFinishedColor: "#030135",
  separatorUnFinishedColor: "#030135",
  stepIndicatorFinishedColor: "#030135",
  stepIndicatorUnFinishedColor: "#ffffff",
  stepIndicatorCurrentColor: "#ffffff",
  stepIndicatorLabelFontSize: 15,
  currentStepIndicatorLabelFontSize: 15,
  stepIndicatorLabelCurrentColor: "#030135",
  stepIndicatorLabelFinishedColor: "#030135",
  stepIndicatorLabelUnFinishedColor: "#030135",
  labelColor: "#030135",
  labelSize: 12,
  width: "80%",
  currentStepLabelColor: "#030135",
};

export default function TimelineView({ timelineData, onClose }) {
  const [ComponentDetailView, setComponentDetailView] = useState(false);
  const [labels, setlabel] = useState([]);
  const [ComponentDetails, setComponentDetails] = useState(null);
  const [currentPosition, setcurrentPosition] = useState(0);
  const [Data, setData] = useState([]);
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const Token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );

  useEffect(() => {
    let temp = [];
    timelineData &&
      timelineData[0].timeline_components.forEach((item) => {
        let obj = `${item.component_name} [] ${item.component_duration_range.split("-")[0] === "Unknown"
          ? item.component_duration_range.split("-")[0]
          : item.component_duration_range.split("-")[0] ===
            item.component_duration_range.split("-")[1]
            ? "Within " + item.component_duration_range.split("-")[0] + " days"
            : item.component_duration_range + " days"
          }`;
        temp.push(obj);
      });
    setlabel(temp);
    timelineData && setData(timelineData[0].timeline_components);
  }, [timelineData]);

  const _onStepPress = (position) => {
    let timelineData = Data[position].component_id;
    const url = "/immipaths/timelines/" + timelineData + "?";
    const headers = {
      Accept: "application/json",
      Authorization: guestLogin ? "guest@immipath" : userDetails?.userid + "@" + Token,
    };
    Network(url, "GET", null, headers).then((res) => {
      if (res.status == "Success") {
        setComponentDetails(res.timeline_component_details);
        setTimeout(() => {
          setComponentDetailView(true);
        }, 500);
      }
    });
  };

  const renderLabel = ({ position, label, currentPosition }) => {
    let labelText = label.split("[]");
    return (
      <View style={{ width: 200, marginTop: 15, marginLeft: 25, }}>
        <Text
          style={
            position === currentPosition ? styles.stepLabel : styles.stepLabel
          }
        >
          {labelText[0]}
        </Text>
        <Text
          style={
            position === currentPosition
              ? styles.stepLabelSelected
              : styles.stepLabelSelected
          }
        >
          {labelText[1]}
        </Text>
      </View>
    );
  };

  const handleClose = () => {
    if (ComponentDetailView) {
      setComponentDetailView(false)
    } else {
      onClose()
    }
  }

  return (
    <>
      <TouchableOpacity onPress={handleClose} style={styles.closeButton}>
        <Image source={Images.closeBlack} style={{ height: 25, width: 25 }} />
      </TouchableOpacity>
      {
        !ComponentDetailView ?
          <View style={{ flex: 1, paddingBottom: 20 }}>
            <ScrollView
              contentContainerStyle={{
                justifyContent: "center",
                alignItems: "center",
              }}
              style={{ width: "100%" }}
            >
              <StepIndicator
                direction="vertical"
                stepCount={labels.length}
                onPress={(p) => _onStepPress(p)}
                renderLabel={renderLabel}
                customStyles={customStyles}
                currentPosition={currentPosition}
                labels={labels}
              />
              <View style={{ height: 30 }} />
            </ScrollView>
          </View> :
          <View style={{ flex: 1, paddingBottom: 20 }}>
            <ScrollView style={{ width: "100%" }}>
              <View style={styles.componentView}>
                <Text style={styles.componentTitle}>Component name</Text>
                <Text style={styles.componentdescription}>
                  {ComponentDetails.component_name}
                </Text>
              </View>
              <View style={styles.componentView}>
                <Text style={styles.componentTitle}>Component description</Text>
                <Text style={styles.componentdescription}>
                  {ComponentDetails.component_description}
                </Text>
              </View>
              <View style={styles.componentView}>
                <Text style={styles.componentTitle}>Expected Time Range</Text>
                <Text style={styles.componentdescription}>
                  {ComponentDetails.expected_time_range.split("-")[0] ===
                    "Unknown"
                    ? ComponentDetails.expected_time_range.split("-")[0]
                    : ComponentDetails.expected_time_range.split("-")[0] ===
                      ComponentDetails.expected_time_range.split("-")[1]
                      ? "Within " +
                      ComponentDetails.expected_time_range.split("-")[0] +
                      " days"
                      : ComponentDetails.expected_time_range + " days"}
                </Text>
              </View>

              {ComponentDetails.documents_required.length > 0 ? (
                <View style={[styles.componentView, { alignItems: "flex-start" }]}>
                  <Text style={[styles.componentTitle, { alignSelf: "center" }]}>Documents required</Text>
                  {ComponentDetails.documents_required.map((s) => (
                    <View
                      style={{ alignItems: "center", flexDirection: "row", }}
                    >
                      <Image
                        source={Images.bullet}
                        style={{ height: 15, width: 15, marginRight: 10, marginTop: 10 }}
                      />
                      <Text style={[styles.componentdescription, { width: "90%", textAlign: "left" }]}>
                        {s}
                      </Text>
                    </View>
                  ))}
                </View>
              ) : (
                <View />
              )}
              {ComponentDetails.last_updated ? (
                <View style={styles.metaDataView}>
                  <Text style={styles.metaDataView_date}>Last Updated:</Text>
                  <Text style={[styles.metaDataView_date, { marginLeft: 5 }]}>
                    {ComponentDetails.last_updated}
                  </Text>
                </View>
              ) : (
                <View />
              )}
            </ScrollView>
          </View>
      }
    </>
  )
}
