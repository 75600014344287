import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  TextInput,
  TouchableHighlight,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { sha256 } from "react-native-sha256";
import {
  deleteUser,
  updateUserDetails,
  getUserDetails,
  getNotificationList,
  expiryToken,
  getUserProfilePic,
} from "../../actions/auth.actions";
import { ErrorUtils } from "../../utils/auth.utils";
import { launchImageLibrary } from "react-native-image-picker";
import { Config, Images, colors } from "../../common";
import { deleteFileFromFirebase, isWeb, uploadFileToFirebase } from "../../common/helpers";
import NetInfo from "@react-native-community/netinfo";
import { Network, apiPaths } from "../../service/api";
import NavigationService from "../../navigation/NavigationService";
import DeleteAccountModal from "./components/DeleteAccountModal/DeleteAccountModal";
import SweetAlert from "../SweetAlert";
import { styles } from "./editProfile.styles";
import InterestSection from "../InterestSection/InterestSection";
import { BillingSection } from "./components/BillingSection/BillingSection";
import DateOfBirth from "./components/DateOfBirth/DateOfBirth";
import { SelectionModal } from "../SelectionModal/SelectionModal";
import { useHistory } from "react-router-dom";
import { TrailFromRight } from "./editProfile.animation";
import { isArray } from "lodash";
import CurrentStageSelectionModal from "./components/CurrentStageSelection/CurrentStageSelectionModal";
import { UpdateProfilePicModal } from "./components/UpdateProfilePicModal/UpdateProfilePicModal";
import AsyncStorage from "@react-native-community/async-storage";

const expirystr = "Auth Token not valid or expired!";

export default function EditProfile({ navigation, onClose = () => { } }) {
  const { userDetails } = useSelector((state) => state.userReducer.getUser);
  const { address, phone: prev_phone, date_of_birth, sex } = userDetails || {};
  const { address_line1: prev_address_line1 = null, address_line2: prev_address_line2, city: prev_city, state: prev_state, country: prev_country = null, pincode: prev_pincode } = address && Object.entries(address).length > 0 ? address : {};
  const [address_line1, setaddress_line1] = useState(prev_address_line1)
  const [address_line2, setaddress_line2] = useState(prev_address_line2)
  const [country, setcountry] = useState(prev_country)
  const [city, setcity] = useState(prev_city)
  const [pincode, setpincode] = useState(prev_pincode)
  const [ustate, setstate] = useState(prev_state)
  const [phone, setphone] = useState(prev_phone)
  const [isConnected, setIsConnected] = useState(true);
  const [openStageSelectionModal, setopenStageSelectionModal] = useState(false)
  const [immigrationInterest, setImmigrationInterest] = useState([]);
  const [currentStage, setcurrentStage] = useState(null)
  const [countriesInterestedIn, setCountriesInterestedIn] = useState([]);
  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [userOccupation, setuserOccupation] = useState(null)
  const [userLanguage, setuserLanguage] = useState(null)
  const [occupationList, setoccupationList] = useState([])
  const [countryResidence, setcountryResidence] = useState(null)
  const [languagesList, setlanguagesList] = useState([])
  const [changeProfilepic, setchangeProfilepic] = useState(null);
  const [showPicUpdate, setShowPicUpdate] = useState(false);
  const [selectionModal, setSelectionModal] = useState({
    active: false,
    params: null,
  });
  const [gender, setGender] = useState(sex);
  const [birthDate, setBirthDate] = useState(date_of_birth);
  const [nationality, setNationality] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const history = useHistory();

  const profilePic = useSelector(
    (state) => state.authReducer.getUserProfilePic.profilePic
  );
  const token = useSelector((state) => state.authReducer.authData.token);
  const dispatch = useDispatch();

  useEffect(() => {
    userDetails?.countries_of_interest?.length > 0 && setCountriesInterestedIn(userDetails?.countries_of_interest)
    userDetails?.immigration_interests?.length > 0 && setImmigrationInterest(userDetails.immigration_interests)
    userDetails?.homeCountries?.length > 0 && setImmigrationInterest(userDetails.homeCountries)
    userDetails?.pref_language?.length > 0 && setuserLanguage(userDetails.pref_language)
    userDetails?.country_of_residency && setcountryResidence(userDetails.country_of_residency)
    userDetails?.occupation && setuserOccupation(userDetails.occupation)
    getOccupationsList()
    userDetails?.current_stage?.length > 0 && setcurrentStage(userDetails.current_stage.join('=>'))
  }, [userDetails]);

  const handleGenderSelection = () => {
    setSelectionModal({
      active: true,
      params: {
        onSelectFn: setGender,
        options: [
          {
            name: "Male",
            id: "M",
          },
          {
            name: "Female",
            id: "F",
          },
        ],
        height: "40%",
        itemKey: "name",
        returnKey: "name",
      },
    });
  };

  const handleCountryOfBirth = () => {
    setSelectionModal({
      active: true,
      params: {
        onSelectFn: setCountryOfBirth,
        options: Config.countriesSelect,
        height: "68%",
        itemKey: "name",
        returnKey: "name",
      },
    });
  };

  const sortAplhabetically = () => {
    let newarr = Config.homeCountries.sort(function (a, b) {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    })
    return newarr
  }

  const handleHomecountries = () => {
    setSelectionModal({
      active: true,
      params: {
        onSelectFn: setcountryResidence,
        options: sortAplhabetically(),
        height: "68%",
        itemKey: "name",
        returnKey: "name",
      },
    });
  };

  const handleOccupation = () => {
    setSelectionModal({
      active: true,
      params: {
        onSelectFn: setuserOccupation,
        options: occupationList,
        height: "68%",
        itemKey: "name",
        returnKey: "name",
      },
    });
  }

  const handleLanguages = () => {
    setSelectionModal({
      active: true,
      params: {
        onSelectFn: setuserLanguage,
        options: languagesList,
        height: "68%",
        multiSelect: true,
        itemKey: "name",
        returnKey: "name",
      },
    });
  }

  const handleNationality = () => {
    setSelectionModal({
      active: true,
      params: {
        onSelectFn: setNationality,
        options: Config.countriesSelect,
        height: "68%",
        itemKey: "name",
        returnKey: "name",
      },
    });
  };

  const updatePassword = async () => {
    postdata = {};
    postdata.status = 1;
    postdata.string = "Change Password";
    Platform.OS == "web"
      ? history.push("/changePassword", { postdata })
      : navigation.navigate("ChangePassword", postdata);
  };

  const checkConnection = async () => {
    NetInfo.fetch().then((state) => {
      setIsConnected(state.isConnected);
      if (!state.isConnected) {
        SweetAlert.error("Check your connection");
      }
    });
  };

  const saveChanges = async () => {
    // this.setState({ loading: true });
    Platform.OS !== "web" && (await checkConnection());

    const postData = {
      first_name: userDetails.first_name ?? "",
      last_name: userDetails.last_name ?? "",
      country_of_birth: countryOfBirth
        ? countryOfBirth
        : userDetails.country_of_birth,
      date_of_birth: birthDate,
      country_of_citizenship1: nationality
        ? nationality
        : userDetails.country_of_citizenship1,
      email_address: userDetails.email_address,
      country_of_residence: countryOfBirth
        ? countryOfBirth
        : userDetails.countryOfBirth,
      highest_academic_achievement: userDetails.highest_academic_achievement,
      countries_of_interest: countriesInterestedIn
        ? countriesInterestedIn
        : userDetails?.countries_of_interest,
      immigration_interests: immigrationInterest
        ? immigrationInterest
        : userDetails.immigration_interests,
      sex: gender ? gender : userDetails.sex,
      occupation: userOccupation,
      pref_language: userLanguage,
      country_of_residency: countryResidence,
      current_stage: currentStage?.split('->') || [],
      phone: phone,
    };
    let address = {
      address_line1,
      address_line2,
      country,
      city,
      pincode,
      state: ustate
    }
    postData.address = address
    try {
      var string = "User Updated!";
      if (immigrationInterest.length > 0 && countriesInterestedIn.length > 0) {
        if (!isConnected && Platform.OS !== "web") {
          SweetAlert.error("Check your connection");
        } else {
          const response = await dispatch(
            updateUserDetails(postData, userDetails?.userid, token)
          );
          if (response.status == string) {
            await dispatch(
              getUserDetails(userDetails?.userid, token)
            );
            setTimeout(() => {
              dispatch(
                getNotificationList(
                  userDetails?.userid,
                  userDetails?.userid + "@" + token
                )
              );
            }, 300);
            SweetAlert.success("Successfully Updated");
            dispatch(getUserDetails(userDetails?.userid, token));
            if (Platform.OS == "web") {
              onClose();
            } else {
              navigation.pop();
            }
          }
        }
      } else {
        // if (immigrationInterest.length == 0) {
        //   this.setState({
        //     immutiabletext: "Please Select your Immigration Interest",
        //   });
        // } else {
        //   this.setState({ immutiabletext: "" });
        // }
        // if (countriesInterestedIn.length == 0) {
        //   this.setState({ countrytext: "Please Select your Countries of Interest" });
        // } else {
        //   this.setState({ countrytext: "" });
        // }
      }
      // this.setState({ loading: false });
    } catch (error) {
      // this.setState({ loading: false });
      const newError = new ErrorUtils(error, "Signup Error");
      newError.showAlert();
    }
  };

  const getOccupationsList = async () => {
    const headers = {
      Accept: "application/json",
      Authorization: token,
    };

    let response = await Network('/data/get_dropdown_options', "GET", null, headers);
    const { occupations, languages } = response
    let _occupation = []
    let _languages = []
    occupations?.length > 0 && occupations.forEach((s, i) => {
      let Obj = {
        name: s,
        id: i
      }
      _occupation.push(Obj)
    })
    languages?.length > 0 && languages.forEach((s, i) => {
      let Obj = {
        name: s,
        id: i
      }
      _languages.push(Obj)
    })
    setoccupationList(_occupation)
    setlanguagesList(_languages)
  }

  const deleteProfilePic = () => {
    if (profilePic?.path) {
      deleteFileFromFirebase(profilePic.path)
    }
    dispatch(getUserProfilePic(null))
  }

  const deleteaccount = async () => {
    await checkConnection();
    if (!isConnected && Platform.OS !== "web") {
      SweetAlert.error("Check your connection");
    } else {
      setDeleteModal(false);
      await dispatch(
        deleteUser(userDetails?.userid, token)
      );
      AsyncStorage.clear()
      Platform.OS !== "web"
        ? navigation.navigate("Login")
        : history.push("/login");
      deleteProfilePic()
      setTimeout(() => {
        dispatch(expiryToken());
      }, 1000);
    }
  };

  const confirmDelete = async () => {
    if (confirmPassword === "") {
      alert("Please enter password!");
      return;
    }

    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    const url = `${userDetails?.userid}/verifypassword`;
    let postData = {};
    postData.password_hash = await sha256(confirmPassword);
    // if (Platform.OS == "web") {
    // } else {
    //   await sha256(confirmPassword).then((hash) => {
    //     postData.password_hash = hash;
    //   });
    // }
    try {
      Network(apiPaths.verifyPassword + url, "POST", postData, headers)
        .then((res) => {
          if (res.status === "success") {
            if (res.verified) {
              deleteaccount();
            } else {
              alert("Wrong password!");
            }
          } else {
          }
        })
        .catch((s) => {
          alert("Something went wrong!");
        });
    } catch (error) {
      alert("Something went wrong!");
    }
  };

  const handleCurrentStage = () => {
    setopenStageSelectionModal(true)
  }

  const setSelectedStage = (item) => {
    setcurrentStage(item)
  }

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const getSelectedImageInfo = () => {
    return new Promise((resolve, reject) => {
      if (Platform.OS == "web") {
        let input = document.createElement("input");
        input.type = "file";
        input.onchange = async () => {
          // you can use this method to get file and perform respective operations
          let files = Array.from(input.files);
          let fileInfo = files[0];
          let fileExtension = fileInfo.type.split("/")
          const imageDataUrl = await readFile(files[0]);
          resolve({ fileType: fileExtension[1], dataUrl: imageDataUrl })
        };
        input.click();
      } else {
        const options = {
          title: 'Select Avatar',
          customButtons: [],
          allowsEditing: false,
          quality: 0.1,
          storageOptions: {
            skipBackup: true,
            path: 'images',
            privateDirectory: true,
            base64: true
          },
        };

        launchImageLibrary(options, async (response) => {
          // console.log('Response = ', response);
          if (response.didCancel || response.error || response.customButton) {
            // console.log('User cancelled image picker');
            reject(response)
          } else {
            const source = { uri: response?.assets[0]?.uri };

            // You can also display the image using data:
            // const source = { uri: 'data:image/jpeg;base64,' + response.data };
            const fileExtension = response?.assets[0]?.type;
            resolve({ fileType: fileExtension[1], dataUrl: source.uri })
          }
        });
      }
    })
  }

  const handlePickChange = (remove = false) => {
    if (remove) {
      deleteProfilePic();
      setchangeProfilepic(null);
      return;
    }
    getSelectedImageInfo().then(({ fileType, dataUrl }) => {
      var fileName = `${userDetails?.userid}.${fileType}`;
      const refPath = `/avatarImages/${userDetails?.userid}/${fileName}`
      setchangeProfilepic(dataUrl)
      uploadFileToFirebase({ refPath, file: dataUrl }).then((fileInfo) => {
        dispatch(getUserProfilePic({ ...fileInfo, path: refPath }))
      })
    }).catch(e => {
      console.log(e)
    })
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "white",
      }}
    >
      <CurrentStageSelectionModal
        setSelectedStage={setSelectedStage}
        visible={openStageSelectionModal}
        handleClose={() => setopenStageSelectionModal(false)}
        onClose={() => setopenStageSelectionModal(false)}
      />
      {Platform.OS === "ios" ? (
        <View style={[styles.statusBar]}>
          <StatusBar translucent barStyle="light-content" hidden={false} />
        </View>
      ) : (
        <StatusBar
          barStyle="light-content"
          backgroundColor={colors.LIGHT_BLUE}
        />
      )}
      <SelectionModal
        params={selectionModal.params}
        visible={selectionModal.active}
        userLanguage={userLanguage}
        setVisible={setSelectionModal}
      />
      <DeleteAccountModal
        visible={deleteModal}
        confirmDelete={confirmDelete}
        confirmPasswordChage={(e) => setConfirmPassword(e)}
        onClose={() => setDeleteModal(false)}
      />
      <View animation="fadeIn" style={styles.header}>
        <View style={styles.titleView}>
          <TouchableOpacity
            hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
            onPress={() => isWeb() ? onClose() : NavigationService.goBack()}
          >
            <Image source={Images.whiteBack} style={styles.backicon} />
          </TouchableOpacity>
          <Text style={styles.titleText}>Profile</Text>
          <View style={{ width: 17 }} />
        </View>
        <View style={styles.imagView}>
          <TouchableOpacity onPress={() => setShowPicUpdate(true)} style={styles.editButton}>
            <Image source={Images.pencil} style={styles.pencil} />
          </TouchableOpacity>
          {profilePic && profilePic.uri ? (
            <Image
              style={styles.mailImage}
              source={{
                isStatic: true,
                uri: changeProfilepic ? changeProfilepic : profilePic.uri,
              }}
            />
          ) : (
            <Image style={styles.mailImage} source={Images.userIcon} />
          )}
        </View>
        <Text
          style={styles.name}
        >
          {userDetails?.first_name}
        </Text>
      </View>
      <ScrollView>
        <TrailFromRight>
          <View style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}>
            <View style={styles.inputWrapper}>
              <Text style={styles.inputLabel}>First Name</Text>
              <TextInput
                style={[styles.textInputLarge, { color: "grey" }]}
                editable={false}
                defaultValue={userDetails?.first_name}
              />
            </View>
          </View>
          <View style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}>
            <View style={styles.inputWrapper}>
              <Text style={styles.inputLabel}>Last Name</Text>
              <TextInput
                style={[styles.textInputLarge, { color: "grey" }]}
                editable={false}
                defaultValue={userDetails?.last_name}
              />
            </View>
          </View>
          <View style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}>
            <View style={styles.inputWrapper}>
              <Text style={styles.inputLabel}>Email Address</Text>
              <TextInput
                style={[styles.textInputLarge, { color: "grey" }]}
                editable={false}
                defaultValue={userDetails?.email_address}
              />
            </View>
          </View>

          <TouchableHighlight
            underlayColor="rgb(230,230,230)"
            onPress={handleGenderSelection}
            style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}
          >
            <View style={styles.inputWrapper}>
              <Text style={styles.inputLabel}>Gender</Text>
              <Text style={styles.textInputLarge}>
                {gender ? gender : userDetails?.sex}
              </Text>
            </View>
          </TouchableHighlight>

          <TouchableHighlight
            underlayColor="rgb(230,230,230)"
            onPress={handleCountryOfBirth}
            style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}
          >
            <View style={styles.inputWrapper}>
              <Text style={styles.inputLabel}>Country of Birth</Text>
              <Text style={styles.textInputLarge}>
                {countryOfBirth
                  ? countryOfBirth
                  : userDetails?.country_of_birth}
              </Text>
            </View>
          </TouchableHighlight>

          <TouchableHighlight
            underlayColor="rgb(230,230,230)"
            onPress={handleHomecountries}
            style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}
          >
            <View style={styles.inputWrapper}>
              <Text style={styles.inputLabel}>Home Countries</Text>
              <Text style={styles.textInputLarge}>
                {countryResidence
                  ? countryResidence
                  : userDetails?.country_of_residency}
              </Text>
            </View>
          </TouchableHighlight>

          {/* <TouchableHighlight
            underlayColor="rgb(230,230,230)"
            onPress={handleOccupation}
            style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}
          >
            <View style={styles.inputWrapper}>
              <Text style={styles.inputLabel}>Occupation</Text>
              <Text style={styles.textInputLarge}>
                {userOccupation
                  ? userOccupation
                  : userDetails?.userOccupation}
              </Text>
            </View>
          </TouchableHighlight> */}

          {/* <TouchableHighlight
            underlayColor="rgb(230,230,230)"
            onPress={handleLanguages}
            style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}
          >
            <View style={styles.inputWrapper}>
              <Text style={styles.inputLabel}>Preferred Languages</Text>
              <Text style={styles.textInputLarge}>
                {!isArray(userLanguage) ? userLanguage : userLanguage?.length > 0
                  ? userLanguage.join(', ')
                  : userDetails?.userLanguage}
              </Text>
            </View>
          </TouchableHighlight> */}

          <DateOfBirth setBirthDate={setBirthDate} birthDate={birthDate} />

          <TouchableHighlight
            underlayColor="rgb(230,230,230)"
            onPress={handleNationality}
            style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}
          >
            <View style={styles.inputWrapper}>
              <Text style={styles.inputLabel}>Nationality</Text>
              <Text style={styles.textInputLarge}>
                {nationality
                  ? nationality
                  : userDetails?.country_of_citizenship1}
              </Text>
            </View>
          </TouchableHighlight>

          <TouchableHighlight
            underlayColor="rgb(230,230,230)"
            onPress={handleCurrentStage}
            style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}
          >
            <View style={styles.inputWrapper}>
              <Text style={styles.inputLabel}>Current Stage</Text>
              <Text style={styles.textInputLarge}>
                {currentStage
                  ? currentStage
                  : 'Select'}
              </Text>
            </View>
          </TouchableHighlight>

          <View
            style={{
              marginBottom: 15,
              marginTop: 15,
              width: "96.8%",
              marginLeft: "1.6%",
            }}
          >
            <InterestSection
              immigrationInterest={
                immigrationInterest.length
                  ? immigrationInterest
                  : userDetails.immigration_interests
              }
              countriesInterestedIn={
                countriesInterestedIn.length
                  ? countriesInterestedIn
                  : userDetails?.countries_of_interest
              }
              setImmigrationInterest={setImmigrationInterest}
              setCountriesInterestedIn={setCountriesInterestedIn}
            />
          </View>
          <TouchableOpacity activeOpacity={0.2}>
            <BillingSection
              setphone={setphone}
              address_line1={address_line1}
              address_line2={address_line2}
              city={city}
              ustate={ustate}
              country={country}
              pincode={pincode}
              phone={phone}
              setaddress_line1={setaddress_line1}
              setaddress_line2={setaddress_line2}
              setcountry={setcountry}
              setcity={setcity}
              _setstate={setstate}
              userDetails={userDetails}
              setpincode={setpincode}
            />
          </TouchableOpacity>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
              marginLeft: "5%",
            }}
          >
            <TouchableOpacity
              onPress={() => setDeleteModal(true)}
              style={[
                styles.koButton,
                {
                  backgroundColor: "#AF0000",
                },
              ]}
            >
              <Text style={styles.buttonText}>
                Delete Account
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={updatePassword}
              style={[styles.koButton]}
            >
              <Text style={styles.buttonText}>
                Update Password
              </Text>
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            onPress={saveChanges}
            style={[styles.saveButton, { marginTop: 10 }]}
          >
            <Text style={styles.buttonText}>
              Save
            </Text>
          </TouchableOpacity>
        </TrailFromRight>
      </ScrollView>
      {showPicUpdate ?
        <UpdateProfilePicModal
          visible={true}
          onClose={() => setShowPicUpdate(false)}
          changeProfilepic={changeProfilepic}
          profilePic={profilePic}
          handlePickChange={handlePickChange}
        /> : null}
    </View>
  );
}
