import React from "react";
import { View, Text, Platform, TouchableOpacity, TextInput } from "react-native";
import { Field } from "redux-form";
import { animated as a, useSpring } from "@react-spring/native";
import { TrailFromRight } from "../newSignupUi.animation";
import { Config } from "../../../common";
import RNPicker from "../../../components/RNModalPicker";
// import SocialLoginIcons from "../../../components/SocialLoginIcons/SocialLoginIcons";

const SignUpSection1 = ({
  styles,
  state,
  handleSubmit,
  userSocialData,
  // onGoogleButtonPress,
  handleRadioClick,
  onReferralSourceChange,
  onReferralSourceAltTextChange,
  // onFacebookButtonPress,
  renderTextInput,
}) => {
  const blendIn = useSpring({
    config: { mass: 5, tension: 2000, friction: 200 },
    to: [
      {
        marginBottom: 0,
        opacity: 1,
        scale: 1,
      },
    ],
    from: {
      opacity: 0,
      scale: 0,
      marginBottom: -450,
    },
  });

  return (
    <View style={[styles.slide1]}>
      <Text style={[styles.title]}>Personal information</Text>
      <Text style={styles.title2}>To help us identify you</Text>
      <TrailFromRight>
        <View style={[styles.commonAnimationWrap]}>
          <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
            <Field
              name={"firstName"}
              placeholder="First Name"
              component={renderTextInput}
              defaultValue={state.userSocialData?.first_name}
            />
          </View>
          {state.errmessage["firstName"] ? (
            <Text style={styles.errortext}>
              {state.errmessage["firstName"]}
            </Text>
          ) : (
            <View />
          )}
        </View>
        <View style={[styles.commonAnimationWrap]}>
          <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
            <Field
              name={"lastName"}
              placeholder="Last Name"
              defaultValue={state.userSocialData?.last_name}
              component={renderTextInput}
            />
          </View>
          {state.errmessage["lastName"] ? (
            <Text style={styles.errortext}>{state.errmessage["lastName"]}</Text>
          ) : (
            <View />
          )}
        </View>
        <View style={[styles.commonAnimationWrap]}>
          <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
            <Field
              name={"email"}
              placeholder="Email address"
              defaultValue={state.userSocialData?.email_address}
              component={renderTextInput}
              keyboardType="email-address"
            />
          </View>
          {state.errmessage["email"] ? (
            <Text style={styles.errortext}>{state.errmessage["email"]}</Text>
          ) : (
            <View />
          )}
        </View>
        <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
          <RNPicker
            data={Config.referrals}
            pickerTitle={"Select an option"}
            placeholderTextColor="#000000"
            showSearchBar={false}
            placeHolderText={"Where did you hear about us ?"}
            disablePicker={false}
            changeAnimation={"fade"}
            showPickerTitle={true}
            selectedText={state.referralSource}
            selectedTextStyle={{ ...styles.selectLabelTextStyle, color: state.referralSource ? "#000" : "grey" }}
            pickerStyle={styles.pickerStyle}
            selectedValue={onReferralSourceChange}
            listContainerStyle={styles.listContainer}
          />
        </View>
        {state.errmessage["referral"] ? (
          <Text style={styles.errortext}>
            {state.errmessage["referral"]}
          </Text>
        ) : (
          <View />
        )}
        {state.referralSource === "Others" ?
          <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
            <TextInput
              placeholder='Enter referral source'
              onChangeText={onReferralSourceAltTextChange}
              style={styles.textInput}
            />
          </View> : null}
        <View style={[styles.commonAnimationWrap]}>
          <View style={styles.ageCheckView}>
            <TouchableOpacity
              onPress={handleRadioClick}
              style={styles.chcekBox}
            >
              {state.validAge ? <View style={styles.checked} /> : <View />}
            </TouchableOpacity>
            <Text
              onPress={handleRadioClick}
              style={[styles.privacyText, { marginLeft: 5 }]}
            >
              I am at least 13 years old
            </Text>
          </View>
          {state.errmessage["age"] ? (
            <Text style={styles.errortext}>{state.errmessage["age"]}</Text>
          ) : (
            <View />
          )}
        </View>

        <View style={[styles.commonAnimationWrap]}>
          <TouchableOpacity onPress={handleSubmit} style={styles.nextButton}>
            <Text style={styles.nextText}>Next</Text>
          </TouchableOpacity>
        </View>
      </TrailFromRight>
      {/*<SocialLoginIcons
          title="or Sign up with"
          hideAppleIcon={false}
          onFacebookButtonPress={onFacebookButtonPress}
          onGoogleButtonPress={onGoogleButtonPress}
          onAppleButtonPress={onAppleButtonPress}
        />*/}
    </View>
  );
};

export default SignUpSection1;
