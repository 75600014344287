import React, { useState, useEffect, useRef } from "react";
import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    FlatList,
    TextInput,
    ScrollView,
    ActivityIndicator,
} from "react-native";
import { Images } from "../../../common";
import config from "../../../common/config";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import { isArray } from "lodash";
import { styles, multiSelectStyle } from "./../prCalculater.styles";
import { TrailAnimation } from "./../prCalculator.animation";
import moment from "moment";
import AlertModal from "../../AlertModal/AlertModal";
import { isWeb } from "../../../common/helpers";
import { useSelector } from "react-redux";

function PrQA({ creditBuilderData, selectedCountries, onScoreCalculate = () => { }, userAnswers = {} }) {
    const inputRef = useRef(null);
    const [prCommonForm, setprCommonForm] = useState([]);
    const [selectedOptions, setselectedOptions] = useState({});
    const [showErrorModal, setshowErrorModal] = useState(false);
    const [disqualifiedQtn, setDisqualifiedQtn] = useState({});
    const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
    const [selectedItemObjects, setselectedItemObjects] = useState([]);
    const [calculating, setCalculating] = useState(false);

    useEffect(() => {
        setprCommonForm(creditBuilderData.common);
    }, [creditBuilderData]);

    const _calculateAge = (birthday) => {
        // birthday is a date
        if (birthday) {
            let _getBirthMonth =
                birthday.split("/")[1] < 10
                    ? `0${birthday.split("/")[1]}`
                    : birthday.split("/")[1];
            let getDate = `${birthday.split("/")[2]}-${_getBirthMonth}-${birthday.split("/")[0]
                }`;
            return moment().diff(getDate, "years");
        }
        return 0
    };

    useEffect(() => {
        let _selectedOptions = { ...selectedOptions };
        creditBuilderData?.common?.length > 0 &&
            creditBuilderData.common.forEach((s) => {
                if (s.is_in_profile) {
                    if (s.category == "Age") {
                        _selectedOptions[s.question_id] = userAnswers[s.question_id] ? userAnswers[s.question_id] - 0 : _calculateAge(
                            userDetails?.date_of_birth
                        );
                    } else if (s.category == "Academics") {
                        let value = userAnswers[s.question_id] || (isArray(userDetails?.highest_academic_achievement)
                            ? userDetails?.highest_academic_achievement[0].name
                            : userDetails?.highest_academic_achievement);
                        _selectedOptions[s.question_id] = value;
                    } else if (s.category == "Personal") {
                        _selectedOptions[s.question_id] =
                            (userAnswers[s.question_id] == "Single" || userDetails?.marital_status == "Single") ? "No" : "Yes";
                    }
                } else {
                    _selectedOptions[s.question_id] = userAnswers[s.question_id]
                }
                setselectedOptions(_selectedOptions);
            });
    }, [creditBuilderData]);

    const customChipsRenderer = (props) => {
        return null;
    };

    const onSelectedItemObjectsChange = (selectedItemObjects, id) => {
        setselectedItemObjects(selectedItemObjects);
        let _selectedOptions = { ...selectedOptions };
        _selectedOptions[parseInt(id)] = selectedItemObjects[0].name;
        setselectedOptions(_selectedOptions);
    };

    const _iconRendere = ({ name, style }) => {
        // flatten the styles
        const flat = StyleSheet.flatten(style);
        // remove out the keys that aren't accepted on View
        const { color, fontSize, ...styles } = flat;

        let iconComponent;
        // the colour in the url on this site has to be a hex w/o hash

        const Down = (
            <Image
                source={Images.triangleDown}
                style={{
                    width: 10,
                    height: 10,
                    marginRight: 10,
                    transform: [{ rotate: "180deg" }],
                }}
            />
        );

        const Cancel = (
            <Image source={Images.cancel} style={{ width: 15, height: 15 }} />
        );

        const Up = (
            <Image
                source={Images.dropDownArrow}
                style={{
                    width: 16,
                    height: 16,
                    marginRight: 10,
                    transform: [{ rotateX: "180deg" }],
                }}
            />
        );

        switch (name) {
            case "keyboard-arrow-up":
                iconComponent = Up;
                break;
            case "keyboard-arrow-down":
                iconComponent = Down;
                break;
            case "cancel":
                iconComponent = Cancel;
                break;
            default:
                iconComponent = null;
                break;
        }
        return <View style={styles}>{iconComponent}</View>;
    };

    const _renderSelectText = (id) => {
        let name = selectedOptions[id]
            ? selectedOptions[id]
            : selectedItemObjects && selectedItemObjects.length > 0
                ? selectedItemObjects[0].name
                : "Highest Academic Achievement *";
        return name;
    };

    const getSelectComponent = (id) => {
        return (
            <TouchableOpacity
                onPress={() => inputRef.current._toggleSelector()}
                style={[styles.pickerContainerView]}
            >
                <SectionedMultiSelect
                    styles={multiSelectStyle}
                    modalWithTouchable
                    modalWithSafeAreaView
                    itemsFlatListProps={{
                        style: isWeb() ? {
                            height: "85vh"
                        } : {}
                    }}
                    items={config.education_profile}
                    uniqueKey="id"
                    hideSearch={true}
                    subKey="children"
                    ref={inputRef}
                    showCancelButton={true}
                    selectText="Select your educational crendentials"
                    showDropDowns={true}
                    single={true}
                    chipsPosition="top"
                    iconRenderer={_iconRendere}
                    showChips={true}
                    customChipsRenderer={customChipsRenderer}
                    readOnlyHeadings={true}
                    onSelectedItemObjectsChange={(item) =>
                        onSelectedItemObjectsChange(item, id)
                    }
                    onSelectedItemsChange={(_) => { }}
                    renderSelectText={() => _renderSelectText(id)}
                />
            </TouchableOpacity>
        );
    };

    const handleOptionClick = (value, id, type) => {
        let _selectedOptions = { ...selectedOptions };
        if (type == "multiselect") {
            let itemsSelected = _selectedOptions[parseInt(id)]
                ? _selectedOptions[parseInt(id)]
                : [];
            if (itemsSelected.includes(value)) {
                itemsSelected = itemsSelected.filter((q) => q !== value);
            } else {
                if (value == "None of the above") {
                    itemsSelected = [value];
                } else {
                    if (itemsSelected.includes("None of the above")) {
                        itemsSelected = itemsSelected.filter(
                            (q) => q !== "None of the above"
                        );
                    }
                    itemsSelected.push(value);
                }
            }
            if (itemsSelected.length == 0) {
                delete _selectedOptions[parseInt(id)];
            } else {
                _selectedOptions[parseInt(id)] = itemsSelected;
            }
        } else {
            _selectedOptions[parseInt(id)] = value;
        }
        setselectedOptions(_selectedOptions);
    };

    const getOptionView = (
        options,
        id,
        type,
        disqualifyOptions = [],
        country,
        qtnIndex
    ) => {
        return (
            <View style={styles.optionDropDown}>
                {options.map((option) => (
                    <TouchableOpacity
                        style={{ marginTop: 8, alignItems: "center", flexDirection: "row" }}
                        onPress={() => {
                            handleOptionClick(option, id, type);
                            if (disqualifyOptions.includes(option)) {
                                setDisqualifiedQtn({
                                    ...disqualifiedQtn,
                                    [country]: {
                                        qtnIndex: qtnIndex,
                                        country: country,
                                        message: `This answer has led to your disqualification of ${getCountryName(
                                            country
                                        )} PR.`,
                                    },
                                });
                            } else if (disqualifiedQtn[country]?.qtnIndex === qtnIndex) {
                                delete disqualifiedQtn[country];
                                setDisqualifiedQtn({
                                    ...disqualifiedQtn,
                                });
                            }
                        }}
                    >
                        <View
                            style={[
                                styles.checkBox,
                                { borderRadius: type == "multiselect" ? 0 : 5 },
                            ]}
                        >
                            {selectedOptions[id]?.includes(option) ? (
                                <View
                                    style={[
                                        styles.seelctedBox,
                                        { borderRadius: type == "multiselect" ? 0 : 5 },
                                    ]}
                                />
                            ) : (
                                <View />
                            )}
                        </View>
                        <Text style={styles.optionText}>{option}</Text>
                    </TouchableOpacity>
                ))}
            </View>
        );
    };

    const getCountryName = (id) => {
        let country = config.intrestcountry.filter((country) => country.code == id);
        return country[0].value.toUpperCase();
    };

    const handleTextInputChange = (value, id) => {
        let _selectedOptions = { ...selectedOptions };
        _selectedOptions[parseInt(id)] = parseInt(value);
        setselectedOptions(_selectedOptions);
    };

    const calculateScore = () => {
        setCalculating(true);
        let _selectedOptions = { ...selectedOptions };
        let _selectedCountries = [...selectedCountries];
        const totalQuestionsLength = Object.entries(creditBuilderData.all).reduce(
            (totalQtn, data) => {
                let countryCode = data[0];
                let questions = data[1];
                if (disqualifiedQtn[countryCode]) {
                    _selectedCountries = _selectedCountries.filter(
                        (country) => country != countryCode
                    );
                    questions.map((qtn) => {
                        delete _selectedOptions[parseInt(qtn.question_id)];
                    });
                } else {
                    totalQtn += questions.length;
                }
                return totalQtn;
            },
            creditBuilderData.common.length
        );
        setCalculating(false);
        if (Object.entries(_selectedOptions).length < totalQuestionsLength) {
            setshowErrorModal(true);
            setTimeout(() => {
                setshowErrorModal(false);
            }, 1200);
            return;
        }
        onScoreCalculate({
            responses: _selectedOptions,
            countries: _selectedCountries,
        });
    };

    const RenderCountryQuestions = ({ countryCode, questions = [] }) => (
        <View style={[styles.formItem]}>
            <Text style={[styles.countryName]}>{getCountryName(countryCode)}</Text>
            {questions.map((question, qtnIndex) => {
                let isDisqualified = false;
                if (disqualifiedQtn[countryCode]) {
                    if (qtnIndex > disqualifiedQtn[countryCode].qtnIndex) {
                        return null;
                    } else {
                        isDisqualified = true;
                    }
                }
                return (
                    <View
                        key={qtnIndex + "c"}
                        style={[
                            {
                                width: "100%",
                                marginBottom: 20,
                            },
                        ]}
                    >
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Image
                                source={Images.bullet}
                                style={{
                                    height: 12,
                                    width: 12,
                                    marginRight: 10,
                                }}
                            />
                            <Text style={styles.question}>{question.question}</Text>
                        </View>
                        {question.type == "integer" ? (
                            <View style={styles.textInputView}>
                                <TextInput
                                    style={{ height: 50, width: "100%" }}
                                    value={
                                        selectedOptions[question.question_id]
                                            ? selectedOptions[question.question_id].toString()
                                            : null
                                    }
                                    onChangeText={(value) =>
                                        handleTextInputChange(value, question.question_id)
                                    }
                                    placeholder="Enter Here"
                                    keyboardType="number-pad"
                                />
                            </View>
                        ) : question.type == "select" && question.source !== "backend" ? (
                            getSelectComponent()
                        ) : question.options.length > 0 ? (
                            getOptionView(
                                question.options,
                                question.question_id,
                                question.type,
                                question.disqualify_options,
                                countryCode,
                                qtnIndex
                            )
                        ) : (
                            <View />
                        )}
                        {isDisqualified ? (
                            <Text style={{ color: "red" }}>
                                {disqualifiedQtn[countryCode].message}
                            </Text>
                        ) : null}
                    </View>
                );
            })}
        </View>
    );

    return (
        <>
            <View style={{ flex: 1 }}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    bounces={false}
                // style={}
                >
                    <TouchableOpacity activeOpacity={1}>
                        <TrailAnimation>
                            {prCommonForm.map((item, index) => {
                                return (
                                    <View key={index + "c"} style={styles.formItem}>
                                        <View
                                            style={{ flexDirection: "row", alignItems: "center" }}
                                        >
                                            <Image
                                                source={Images.bullet}
                                                style={{ height: 12, width: 12, marginRight: 10 }}
                                            />
                                            <Text style={styles.question}>{item.question}</Text>
                                        </View>
                                        {item.type == "integer" ? (
                                            <View style={styles.textInputView}>
                                                <TextInput
                                                    style={{ height: 50, width: "100%" }}
                                                    keyboardType="number-pad"
                                                    value={
                                                        selectedOptions[item.question_id]
                                                            ? selectedOptions[item.question_id].toString()
                                                            : null
                                                    }
                                                    onChangeText={(value) =>
                                                        handleTextInputChange(value, item.question_id)
                                                    }
                                                    placeholder="Enter Here"
                                                />
                                            </View>
                                        ) : item.type == "select" && item.source !== "backend" ? (
                                            getSelectComponent(item.question_id)
                                        ) : item.options.length > 0 ? (
                                            getOptionView(item.options, item.question_id)
                                        ) : (
                                            <View />
                                        )}
                                    </View>
                                );
                            })}
                            <View style={{ flex: 1 }}>
                                <FlatList
                                    data={Object.entries(creditBuilderData.all)}
                                    style={{ marginBottom: 20 }}
                                    showsHorizontalScrollIndicator={false}
                                    contentContainerStyle={{}}
                                    extraData={[selectedItemObjects, selectedOptions]}
                                    renderItem={({ item, index }) => (
                                        <RenderCountryQuestions
                                            countryCode={item[0]}
                                            questions={item[1]}
                                            key={index + "q"}
                                        />
                                    )}
                                />
                            </View>
                        </TrailAnimation>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={calculating ? () => { } : () => calculateScore()}
                        disabled={calculating || (Object.keys(disqualifiedQtn).length == selectedCountries?.length)}
                        style={[styles.submitButton, { backgroundColor: Object.keys(disqualifiedQtn).length == selectedCountries?.length ? "grey" : "#000" }]}
                    >
                        {calculating ? (
                            <ActivityIndicator size="small" color="white" />
                        ) : (
                            <Text style={styles.submitText}>Calculate</Text>
                        )}
                    </TouchableOpacity>
                </ScrollView>
            </View>
            {showErrorModal ? (
                <AlertModal title="Oops!" description="Please answer all questions" />
            ) : (
                <View />
            )}
        </>
    );
}

export default PrQA;
