import React, { useState } from "react";
import { View, Text, Modal, Image, TouchableOpacity, Linking } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import Images from "../../../../common/images";
import { styles as s } from "./Savepath.styles";

export const SavePath = ({
  saveimmipath,
  onClose,
  saveModalOpen
}) => {
  const [title, settitle] = useState(null)

  const handleSave = () => {
    saveimmipath(title)
    onClose()
  }

  return (
    <Modal visible={saveModalOpen} animationType="fade" transparent>
      <View style={s.wrapper}>
        <View style={s.modal}>
          <View style={[s.closeWrapper]}>
            <TouchableOpacity
              onPress={() => onClose()}
              style={s.closeIconButton}
              hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
            >
              <Image source={Images.closeBlack} style={s.closeIcon} />
            </TouchableOpacity>
          </View>

          <Text style={s.title}> Save Immipath</Text>
          <View style={s.inputBox}>
            <TextInput
              placeholder="Enter title for Immipath (Optional)"
              onChangeText={(t) => settitle(t)}
            />
          </View>
          <View style={s.buttonView}>
            <TouchableOpacity
              onPress={handleSave}
              style={[
                s.applyButton,
                {
                  backgroundColor: "#F1AB32",
                },
              ]}
              activeOpacity={0.7}
            >
              <Text style={s.premiumButtonText}>Save</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => onClose()}
              style={[
                s.applyButton,
                {
                  backgroundColor: "#BABABA"
                },
              ]}
              activeOpacity={0.7}
            >
              <Text style={s.premiumButtonText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};
