import Api from '../service/apiCall'
import Toast from 'react-native-simple-toast'
import { helpers } from '../common'
import NavigationService from "../navigation/NavigationService"
import AsyncStorage from "@react-native-community/async-storage";

export const selectedFactsAndCities = (
  user_id,
  authtoken,
  body,
  immiPaths,
  cityNameArray,
  selectedImmipaths,
  callback
) => {
  var authdata = user_id + '@' + authtoken
  var string = 'Success'

  return async dispatch => {
    dispatch({
      type: 'FACT_SELECTOR_LOADING'
    })
    try {
      return Api('/immipaths/facts?', body, 'POST', authdata)
        .then(res => {
          if (res.status == string) {
            callback ? callback('Success') : null;
            dispatch({
              type: 'FACT_SELECTOR_SUCCESS',
              payload: res.facts ? res.facts : {},
              factIds: body.fact_options.fact_ids,
              countriesSelectedInFactSelector: body.city_options.country_ids,
              selectedImmipaths: selectedImmipaths ? selectedImmipaths : null
            })
          } else {
            dispatch({
              type: 'FACT_SELECTOR_FAIL',
              payload: res.status
            })
            setTimeout(() => {
              alert(res.status)
            }, 500)
          }
          return res
        })
        .catch(function (error) {
          dispatch({
            type: 'FACT_SELECTOR_FAIL',
            payload: error.responseBody
          })
          if (error.status == 401) {
            helpers.handleSessionExpiry()
          }
        })
    } catch (error) {
      // console.log('error', error)
      dispatch({
        type: 'FACT_SELECTOR_FAIL',
        payload: error.responseBody
      })
      return error
    }
  }
}

export const selectedFactsAndLocalitiesCities = (
  user_id,
  authtoken,
  body,
  immiPaths,
  cityNameArray,
  selectedImmipaths,
  callback
) => {
  var authdata = user_id + '@' + authtoken
  var string = 'Success'

  return async dispatch => {
    dispatch({
      type: 'LOCALITY_FACT_SELECTOR_LOADING'
    })
    try {
      return Api('/immipaths/facts?', body, 'POST', authdata)
        .then(res => {
          if (res.status == string) {
            callback ? callback('Success') : null;
            dispatch({
              type: 'LOCALITY_FACT_SELECTOR_SUCCESS',
              payload: res.facts ? res.facts : {},
              factIds: body.fact_options.fact_ids,
              countriesSelectedInFactSelector: body.city_options.country_ids,
              selectedImmipaths: selectedImmipaths ? selectedImmipaths : null
            })
          } else {
            dispatch({
              type: 'LOCALITY_FACT_SELECTOR_FAIL',
              payload: res.status
            })
            setTimeout(() => {
              alert(res.status)
            }, 500)
          }
          return res
        })
        .catch(function (error) {
          dispatch({
            type: 'LOCALITY_FACT_SELECTOR_FAIL',
            payload: error.responseBody
          })
          if (error.status == 401) {
            helpers.handleSessionExpiry()
          }
        })
    } catch (error) {
      // console.log('error', error)
      dispatch({
        type: 'LOCALITY_FACT_SELECTOR_FAIL',
        payload: error.responseBody
      })
      return error
    }
  }
}


export const saveComparatorFacts = dataDict => {
  return async dispatch => {
    dispatch({
      type: 'SAVE_COMPARATOR_DATA',
      payload: dataDict
    })
  }
}

export const setFilterView = value => {
  return async dispatch => {
    dispatch({
      type: 'SET_FILTER_VIEW',
      payload: value
    })
  }
}

export const proceedFromImmipaths = () => {
  return async dispatch => {
    dispatch({
      type: 'PROCEED_FROM_IMMIPATHS'
    })
  }
}

export const getCountryData = (user_id, authtoken) => {
  var authdata = user_id + '@' + authtoken
  var string = 'Success'

  return async dispatch => {
    dispatch({
      type: 'COUNTRY_DATA_LOADING'
    })
    try {
      return Api(
        '/data/fact_selector_options_data' + '?',
        null,
        'GET',
        authdata
      )
        .then(res => {
          if (res.status == string) {
            dispatch({
              type: 'COUNTRY_DATA_SUCCESS',
              payload: res
            })
          } else {
            alert('Data does not exist for selected Timeline Component')
         
            dispatch({
              type: 'COUNTRY_DATA_FAIL',
              payload: []
            })
          }
          return res
        })
        .catch(function (error) {
          dispatch({
            type: 'COUNTRY_DATA_FAIL',
            payload: error.responseBody
          })
          if (error.status == 401) {
            helpers.handleSessionExpiry()
          }
        })
    } catch (error) {
 
      dispatch({
        type: 'COUNTRY_DATA_FAIL',
        payload: error.responseBody
      })
      return error
    }
  }
}
