import { StyleSheet } from "react-native";
import fontFamily from "../../../../common/fontFamily";
import { heightPercentageToDP } from "react-native-responsive-screen";

export const styles = StyleSheet.create({
  optionTitle: {
    fontFamily: fontFamily.titilliumBold,
    fontSize: heightPercentageToDP("3.8%"),
    textAlign: "center",
    lineHeight: heightPercentageToDP("4%"),
    marginTop: 15,
  },
  optiondescription: {
    fontFamily: fontFamily.regular,
    marginTop: 5,
    textAlign: "center",
  },
  root: {flex: 1, padding: 20},
  title: {textAlign: 'center', fontSize: 20},
  codeFieldRoot: {
    marginTop: 20,
    alignSelf: 'center'
  },
  cell: {
    lineHeight: 28,
    fontSize: 14,
    borderWidth: 2,
    margin: 3,  
    paddingHorizontal: 5,
    borderColor: '#00000030',
    textAlign: 'center',
  },
  codeCell: {
    flexDirection: 'row'
  },
  separator: {
    height: 2,
    width: 6,
    margin: 3,
    backgroundColor: '#000',
    alignSelf: 'center',
  },
  focusCell: {
    borderColor: '#000',
    paddingHorizontal: 8,
  },
  logoImg: {
    resizeMode: "contain",
    padding: 10,
    marginLeft: 5,
    height: 220,
  },
  lockIcon: {
    height: 125,
    width: 125,
    transform: [{ scale: 1.2 }, { translateY: 2 }],
  },
  contentView: {
    width: "100%",
  },
  indicatorWrapper: {
    width: "100%",
    position: "absolute",
    zIndex: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  indicator: {
    height: 8,
    backgroundColor: "rgb(180,180,180)",
    marginLeft: 7,
    borderRadius: 15,
  },
  buttonWrapper: {
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F1AB32",
    width: "100%",
    height: 50,
    overflow: "hidden",
  },
  buttonText: {
    color: "white",
    fontFamily: fontFamily.titilliumBold,
  },
});
