import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Dimensions,
  FlatList,
} from "react-native";
import { Images } from "../../../../common";
import ChartDetailModal from "../ChartDetailModal/ChartDetailModal";
import { useSelector } from "react-redux";
import { TabView } from "react-native-tab-view";
import CityFactComparator from "../CityFactsComparator/CityFactsComparator";
import { isArray } from "lodash";
import config from "../../../../common/config";
import { styles } from "./localityFactsDisplay.styles";
import {
  _setWeatherTitle,
  getMetaDataDate,
} from "../../../../utils/localityFactsDisplay.utils";
import { FactTypeA } from "./Components/FactTypeA";
import { FactTypeB } from "./Components/FactTypeB";
import { FactTypeC } from "./Components/FactTypeC";
import { FactTypeD } from "./Components/FactTypeD";
import { SelectionModal } from "../../../SelectionModal/SelectionModal";
import { animated as a, useSpring } from "@react-spring/native";
import { isWeb, mobilBrower, scrollToTop } from "../../../../common/helpers";

const { width } = Dimensions.get("window");

function LocalityFactsDisplay({
  localityFacts,
  SelectedCities,
  handleBackPress,
  CityViewDataSet,
  filteredData,
  showTabs,
  selectedFactsIds,
  selectedJobname,
  showCurrencyForCity,
  scroll,
}) {
  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "first", title: "Facts view" },
    { key: "second", title: "City view" },
  ]);
  const [descriptionVisible, setdescriptionVisible] = useState(null);
  const [currencyDropdown, setcurrencyDropdown] = useState([]);
  const [selectedCurrency, setselectedCurrency] = useState("USD");
  const [detailsModalShown, setdetailsModalShown] = useState(false);
  const metaData = useSelector((state) => state.factReducer.factData.factsList);
  const [selectedCard, setselectedCard] = useState(null);
  const [selectedItemSource, setselectedItemSource] = useState(null);
  const handleDropDown = (index) => {
    if (descriptionVisible == index) {
      setdescriptionVisible(null);
      return;
    }
    setdescriptionVisible(index);
  };

  useEffect(() => {
    let _dropdown = [];
    config.currencyArray.forEach((s) => {
      s.label = s.value;
      _dropdown.push(s);
    });
    setcurrencyDropdown(_dropdown);
    scrollToTop();
  }, []);

  const handleShowRaw = (item) => {
    setselectedCard(item);
    getMetaDataDate(item.fact, metaData)?.source
      ? setselectedItemSource(getMetaDataDate(item.fact, metaData)?.source)
      : null;
    setdetailsModalShown(true);
  };

  const getContent = (item, value) => {
    if (
      !item.fact.includes("Coronavirus Vaccinations") &&
      !item.fact.includes("Weather") &&
      !item.fact.includes("Official") &&
      !item.fact.includes("Ranking")
    ) {
      return (
        <FactTypeA
          scroll={scroll}
          item={item}
          value={value}
          handleShowRaw={handleShowRaw}
          selectedCurrency={selectedCurrency}
          selectedCities={SelectedCities}
          metaData={metaData}
        />
      );
    } else if (item.fact == "Coronavirus Vaccinations") {
      return (
        <FactTypeB
          item={item}
          value={value}
          handleShowRaw={handleShowRaw}
          scroll={scroll}
          selectedCities={SelectedCities}
          metaData={metaData}
        />
      );
    } else if (item.fact == "Weather") {
      return (
        <FactTypeC
          item={item}
          handleShowRaw={handleShowRaw}
          metaData={metaData}
        />
      );
    } else {
      return <FactTypeD item={item} metaData={metaData} />;
    }
  };

  const renderFirstRoute = () => {
    let showCurrency =
      isArray(selectedFactsIds) &&
      (selectedFactsIds?.includes(0) || selectedFactsIds?.includes(2));
    return (
      <TouchableOpacity activeOpacity={1}>
        {(showTabs || showCurrencyForCity) && currencyComp(showCurrency)}
        <FlatList
          data={localityFacts}
          style={{ marginTop: 20, marginBottom: 60, zIndex: -1 }}
          contentContainerStyle={{ paddingBottom: 20 }}
          numColumns={isWeb() && !mobilBrower() ? 2 : 1}
          columnWrapperStyle={isWeb() && !mobilBrower() ? { alignItems: "flex-start" } : null}
          renderItem={({ item, index }) => {
            return (
              <TouchableOpacity
                onPress={() => handleDropDown(index)}
                activeOpacity={0.9}
                style={[styles.item]}
              >
                <View style={[styles.outerView]}>
                  <Text style={[styles.itemTitle, { width: "80%" }]}>
                    {item.fact == "Selected Job Salary"
                      ? `Average salary for ${selectedJobname} (yearly)`
                      : item.fact}
                  </Text>
                  {isWeb() && !mobilBrower() ? null :
                    <TouchableOpacity
                      style={{ marginRight: 10 }}
                      onPress={() => handleDropDown(index)}
                    >
                      <Image
                        source={Images.dropDownArrow}
                        style={{
                          height: 16,
                          width: 16,
                          transform: [
                            {
                              rotate:
                                descriptionVisible == index ? "180deg" : "0deg",
                            },
                          ],
                        }}
                      />
                    </TouchableOpacity>}
                </View>

                {(isWeb() && !mobilBrower()) || descriptionVisible == index ? (
                  <View style={{ marginBottom: 20 }}>{getContent(item)}</View>
                ) : (
                  <View />
                )}
              </TouchableOpacity>
            );
          }}
        />
      </TouchableOpacity>
    );
  };

  const renderSecondRoute = () => {
    let showCurrency =
      selectedFactsIds?.includes(0) || selectedFactsIds?.includes(2);
    return (
      <View style={{ flex: 1 }}>
        {showTabs && currencyComp(showCurrency)}
        <CityFactComparator
          CityViewDataSet={CityViewDataSet}
          filteredData={filteredData}
          selectedJobname={selectedJobname}
          selectedCurrency={selectedCurrency}
        />
      </View>
    );
  };

  const handleIndexChange = (index) => {
    setIndex(index);
  };

  const [currencyModalVisible, setCurrencyModalVisible] = useState({
    active: false,
    params: null,
  });

  const currencySelection = () => {
    setCurrencyModalVisible({
      active: true,
      params: {
        onSelectFn: setselectedCurrency,
        options: currencyDropdown,
        height: "68%",
        itemKey: "label",
        returnKey: "value",
      },
    });
  };

  const backButtonAnimation = useSpring({
    from: {
      marginLeft: 100,
    },
    to: {
      marginLeft: 0,
    },
  });

  const currencyComp = (showCurrency) => {
    return (
      <View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            zIndex: 1,
          }}
        >
          <TouchableOpacity
            onPress={() => handleBackPress()}
            style={styles.backlButton}
          >
            <a.View style={backButtonAnimation}>
              <Image
                source={Images.blackbackIcon}
                style={{ height: 20, width: 20, resizeMode: "contain" }}
              />
            </a.View>
          </TouchableOpacity>
          {showCurrency ? (
            <View
              style={{
                top: 5,
                right: -5,
              }}
            >
              <View style={styles.currencyDropDown}>
                <TouchableOpacity
                  style={{
                    width: 100,
                    marginTop: 10,
                    height: 40,
                    marginRight: 25,
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 10,
                    justifyContent: "center",
                    borderWidth: 1,
                    borderColor: "rgb(225,225,225)",
                  }}
                  onPress={currencySelection}
                >
                  <Text
                    style={{
                      color: "black",
                    }}
                  >
                    {selectedCurrency}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <View />
          )}
        </View>
      </View>
    );
  };

  const renderScene = (route) => {
    switch (route.key) {
      case "first":
        return renderFirstRoute();
      case "second":
        return renderSecondRoute();
      default: return null;
    }
  }

  const renderTabBar = (props) => {
    return (
      <View style={styles.tab}>
        <View style={styles.tabHeader}>
          {props.navigationState.routes.map((route, i) => {
            return (
              <TouchableOpacity
                key={i + "t"}
                style={styles.tabItem}
                onPress={() => props.jumpTo(route.key)}
              >
                <Text style={styles.tabLabel}>{route.title}</Text>
                {props.navigationState.index == i ? <View style={styles.tabIndicator} /> : null}
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    );
  }

  return (
    <TouchableOpacity activeOpacity={1} style={styles.mainView}>
      {showTabs ? (
        <TabView
          navigationState={{ index, routes }}
          renderScene={({ route }) => <View style={styles.tabContent}>
            {renderScene(route)}
          </View>}
          swipeEnabled={true}
          onIndexChange={handleIndexChange}
          initialLayout={{ width: width }}
          renderTabBar={renderTabBar}
        />
      ) : (
        <View>{renderFirstRoute()}</View>
      )}
      {detailsModalShown ? (
        <ChartDetailModal
          data={selectedCard}
          selectedItemSource={selectedItemSource}
          selectedCurrency={selectedCurrency}
          onClose={() => setdetailsModalShown(false)}
        />
      ) : (
        <View />
      )}
      <SelectionModal
        visible={currencyModalVisible.active}
        params={currencyModalVisible.params}
        setVisible={setCurrencyModalVisible}
      />
    </TouchableOpacity>
  );
}

export default LocalityFactsDisplay;
