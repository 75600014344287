const initialState = {
  mediaTabNext: false,
  dashboardProfileFinish: false,
  resultComparisionNext: false,
  showBottomNavTutorial: false
};

const copilotStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MEDIA_TAB_NEXT_PRESSED':
      return {
        ...state,
        mediaTabNext: true,
      };
    case 'DASHOBOARD_PROFILE_FINISH':
      return {
        ...state,
        dashboardProfileFinish: true,
      };
    case 'RESULT_COMPARISON_NEXT':
      return {
        ...state,
        resultComparisionNext: true,
      };
    case 'SHOW_BOTTOM_TAB_TUTORIAL':
      return {
        ...state,
        showBottomNavTutorial: !state.showBottomNavTutorial,
      };
    default:
      return state;
  }
};

export default copilotStateReducer;
