import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function WebCarousel({ children }) {

  const indicatorStyles = {
    display: 'inline-block',
    marginRight: '10px',
    color: '#fff',
    cursor: 'pointer',
  };
  return (
    <Carousel renderIndicator={(
      onClickHandler,
      isSelected,
      index,
      label
    ) => (
      <li
        style={{
          ...indicatorStyles, height: 8, width: 8, backgroundColor: '#0F1143',
          borderRadius: 4, opacity: isSelected ? '1' : '0.7', alignSelf: 'center'
        }}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
      />
    )}>
      {children}
    </Carousel>
  )
}
