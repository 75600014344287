import React, { useEffect, useState } from "react";
import { styles as s } from "./sectionE.styles";
import {
  View,
  TouchableOpacity,
  TextInput,
  Text,
  ScrollView,
  Image,
} from "react-native";
import { TrailFromRight } from "../../animations/immipathSearch.styles";
import Images from "../../../../common/images";
import { useSelector, useDispatch } from "react-redux";
import { groupBy, isEmpty } from "lodash";
import { setUndergraduteUserAnswer } from "../../../../actions/path.actions";

export const SectionE = ({ prevTrack, nextTrack, continuePreviousSearch }) => {
  const dispatch = useDispatch()
  const underGraduateQuestions = useSelector((state) => state.pathReducer.getImmipathDetails.underGraduateQuestions);
  const [languageTestList, setlanguageTestList] = useState([])
  const [openLanguageDropDown, setopenLanguageDropDown] = useState(false)
  const [testTaken, settestTaken] = useState({})
  const [selectedLanguageTest, setselectedLanguageTest] = useState(null)
  const [selectedEntranceTest, setselectedEntranceTest] = useState(null);
  const [languageTestNotTaken, setlanguageTestNotTaken] = useState(false)
  const [entranceTestNotTaken, setentranceTestNotTaken] = useState(false);
  const [Error, setError] = useState({})
  const [userAnswers, setuserAnswers] = useState({})
  const underGraduateUserAnswer = useSelector(state => state.pathReducer?.getImmipathDetails?.underGraduateUserAnswer)

  const handleLaguageScore = (text) => {
    let temp = { ...userAnswers };
    temp[selectedLanguageTest.question_id] = Number(text);
    setuserAnswers(temp)
    Error['languageTest'] = false
    dispatch(setUndergraduteUserAnswer(temp))
  }

  const handleEntranceTestScroe = (text) => {
    let temp = { ...userAnswers };
    temp[selectedEntranceTest.question_id] = Number(text);
    setuserAnswers(temp)
    Error['entranceTest'] = false
    dispatch(setUndergraduteUserAnswer(temp))
  }

  useEffect(() => {
    continuePreviousSearch && !isEmpty(underGraduateUserAnswer) ? setuserAnswers(underGraduateUserAnswer) : dispatch(setUndergraduteUserAnswer({}))
  }, [])

  useEffect(() => {
    let graduateQuestion = underGraduateQuestions?.form_data || [];
    let _languageTestList = graduateQuestion.length > 0 &&
      groupBy(graduateQuestion, (b) => { return b.special_field?.test_type == 'english_language_proficiency_test' })
    _languageTestList && _languageTestList[true] && setlanguageTestList(_languageTestList[true])
  }, [underGraduateQuestions])

  const handleOptionSelection = (item, index) => {
    setselectedLanguageTest(item)
    setopenLanguageDropDown(false)
  };

  const handleNext = () => {
    let err = {}
    let graduateQuestion = underGraduateQuestions?.form_data || [];
    graduateQuestion.forEach((s) => {
      if ((isEmpty(s.special_field) || s.special_field.test_type !== 'english_language_proficiency_test')
        && !userAnswers[s.question_id] && testTaken[s.question_id] !== 'Not Taken') {
        err[s.question_id] = true
      }
    })
    if (Object.entries(err).length > 0) {
      setError(err)
      return
    }

    if (languageTestList?.length > 0 && selectedLanguageTest == null && !languageTestNotTaken) {
      err['languageTest'] = true
      setError(err)
      return
    }
    nextTrack()
  }

  const handleTextChange = (text, item) => {
    let temp = { ...userAnswers };
    let _testTaken = { ...testTaken };
    if (text == null) {
      _testTaken[item.question_id] == 'Not Taken' ? delete _testTaken[item.question_id] : _testTaken[item.question_id] = 'Not Taken';
      settestTaken(_testTaken)
    } else {
      temp[item.question_id] = Number(text);
      Error[item.question_id] = false
      setuserAnswers(temp)
      dispatch(setUndergraduteUserAnswer(temp))
    }
  }

  const handleNoTestTaken = () => {
    setselectedLanguageTest(null)
    setlanguageTestNotTaken(!languageTestNotTaken)
  }

  const noEntracneTestTaken = () => {
    setselectedEntranceTest(null)
    setentranceTestNotTaken(!entranceTestNotTaken)
  }

  return (
    <View style={[s.main]}>
      <View style={[s.contentContainer]}>
        <Text style={[s.textT]}>Postgraduation Details</Text>
        <ScrollView
          style={[
            {
              width: "100%",
            },
          ]}
        >
          <TouchableOpacity activeOpacity={1}>
            {underGraduateQuestions?.form_data?.map((form, index) => {
              if (Object.entries(form.special_field).length == 0 || form.special_field?.test_type !== 'english_language_proficiency_test') {
                return (
                  <>
                    <View style={[s.qWrapper]}>
                      <Text style={[s.quesText]}>{form.question}</Text>
                      <TextInput
                        style={[s.qTextInput, Error[form.question_id] && s.errorView, testTaken[form?.question_id] == 'Not Taken' && s.disabled]}
                        onChangeText={(text) => handleTextChange(text, form)}
                        placeholder={form.placeHolderText}
                        keyboardType={form.type == 'integer' ? 'numeric' : 'default'}
                        defaultValue={userAnswers[form?.question_id]?.toString()}
                      />
                    </View>
                    {
                      form.special_field?.test_type == 'entrance_test' &&
                      <TouchableOpacity
                        onPress={() => handleTextChange(null, form)}
                        style={[s.checkWrapper, { marginTop: -20, marginBottom: 20 }]}
                      >
                        <View style={[s.radio]}>
                          {testTaken[form?.question_id] == 'Not Taken' && (
                            <View style={[s.selected]} />
                          )}
                        </View>
                        <Text style={[s.checkText]}>Not Yet Taken</Text>
                      </TouchableOpacity>
                    }
                  </>
                );
              } else {
                return <View />
              }
            })}
            {
              languageTestList?.length > 0 &&
              <>
                <View style={[s.qWrapper]}>
                  <Text style={[s.quesText]}>Language Test</Text>
                  <TouchableOpacity
                    activeOpacity={0.7}
                    onPress={() => setopenLanguageDropDown(!openLanguageDropDown)}
                    style={[s.selectedAnswerView, Error['languageTest'] && s.errorView, languageTestNotTaken && s.disabled]}
                  >
                    <Text
                      style={[
                        {
                          color: "black"
                        },
                      ]}
                    >
                      {selectedLanguageTest?.application_display_name || 'Select Test'}
                    </Text>
                    <Image source={Images.dropDownArrow} style={s.downArrow} />
                  </TouchableOpacity>
                  {
                    openLanguageDropDown ?
                      <View style={s.optionDropDown}>
                        <TrailFromRight>
                          {languageTestList.map((item1, index1) => {
                            return (
                              <TouchableOpacity
                                onPress={() =>
                                  handleOptionSelection(item1, index1)
                                }
                                style={[
                                  s.optionsView,
                                  index1 !== languageTestList?.length - 1 &&
                                  s.separator,
                                ]}
                              >
                                <Text style={s.optionsText}>{item1.application_display_name}</Text>
                              </TouchableOpacity>
                            );
                          })}
                        </TrailFromRight>
                      </View> : null
                  }
                  {
                    selectedLanguageTest ?
                      <TextInput
                        style={[s.qTextInput, { marginTop: 15 }]}
                        onChangeText={(text) => handleLaguageScore(text)}
                        placeholder={'Please enter your score'}
                      /> : null
                  }
                </View>
                <Text
                  style={{
                    fontWeight: "bold",
                    alignSelf: 'center'
                  }}
                >
                  OR{" "}
                </Text>
                <TouchableOpacity
                  onPress={handleNoTestTaken}
                  style={[s.checkWrapper]}
                >
                  <View style={[s.radio]}>
                    {languageTestNotTaken && (
                      <View style={[s.selected]} />
                    )}
                  </View>
                  <Text style={[s.checkText]}>Not Yet Taken</Text>
                </TouchableOpacity>
              </>
            }
          </TouchableOpacity>
        </ScrollView>
        <TouchableOpacity onPress={handleNext} style={[s.nextWrapper]}>
          <Text style={[s.nextText]}>NEXT </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
