import NewLoginUi from "../pages/NewLoginUi/NewLoginUi";
// import Store from "../pages/Store.web";
import Cart from "../pages/Cart.web";
import NewDashboradUi from "../pages/NewDashboardUi/NewDashboardUi";
import AppIntro from "../pages/AppIntro";
import BillingDetails from "../pages/BillingDetails.web";
import OrderConfirmation from "../pages/OrderConfirmation.web";
import AboutUs from "../components/AboutUs/AboutUs";
import NewsFilter from "../pages/NewsFilter";
import NewProfileScreen from "../components/NewProfile/Profile";
import CustomerSupport from "../pages/CustomerSupport";
import Settings from "../pages/Settings";
import PathExpNewUI from "../components/NewPathExplorer/NewPathExplorer";
import ChangePassword from "../pages/ChangePassword";
import CreatePassword from "../pages/createPassword";
import Pinvalidation from "../pages/pinvalidation";
// import CreditBuilder from "../pages/CreditBuilder/CreditBuilder";
import MainMedia from "../components/MainMedia/MainMedia";
import QuickSearch from "../pages/QuickSearch/QuickSearch";
import Policies from "../pages/Policy/Policies";
// import LanguageFeature from "../components/LanguageFeature/LanguageFeature";
import PrCalculator from "../components/PrCalculator/PrCalculater";
import CityFactPage from "../components/LocalityFactPage/LocalityFactPage";
import EligibilityChecker from "../components/EligibilityChecker/EligibilityChecker";
import ApplicableVisas from "../pages/ApplicableVisa/ApplicableVisas";
import CostOfLiving from "../components/CostOfLiving/CostOfLiving";
import AdviceChannel from "../components/AdviceChannel/AdviceChannel";
import Discussion from "../components/Discussion/Discussion";
import OpportunityReport from "../components/OpportunityReport/OpportunityReport";
import JobAnalysis from "../components/JobAnalysis/JobAnalysis";
import UniversitySearch from "../components/UniversitySearch/UniversitySearch";

export default [
  {
    label: "Cart",
    path: "/cart",
    component: Cart,
    isPrivate: true,
    showTabBar: false,
  },
  {
    label: "Media",
    path: "/media",
    component: MainMedia,
    isPrivate: true,
    showTabBar: true,
  },
  {
    label: "Media",
    path: "/changePassword",
    component: ChangePassword,
    isPrivate: true,
  },
  {
    label: "Pin Validation",
    path: "/pinvalidation",
    component: Pinvalidation,
    isPrivate: true,
  },
  {
    label: "Profile",
    path: "/profile",
    component: NewProfileScreen,
    isPrivate: true,
  },
  {
    label: "Create Password",
    path: "/createpassword",
    component: CreatePassword,
    isPrivate: true,
  },
  {
    label: "Path Explorer",
    path: "/pathExp",
    component: PathExpNewUI,
    isPrivate: true,
    showTabBar: true,
  },
  {
    label: "Settings",
    path: "/settings",
    component: Settings,
    isPrivate: true,
  },
  {
    label: "Customer Support",
    path: "/customerSupport",
    component: CustomerSupport,
    isPrivate: true,
  },
  {
    label: "NewsFilter",
    path: "/newFilter/:data",
    component: NewsFilter,
    isPrivate: true,
  },
  // {
  //   label: "Store",
  //   path: "/store",
  //   component: Store,
  //   isPrivate: true,
  //   showTabBar: true,
  // },
  {
    label: "Login",
    path: "/login",
    component: NewLoginUi,
    isPrivate: false,
  },
  {
    label: "Dashboard",
    path: "/dashboard",
    component: NewDashboradUi,
    isPrivate: false,
    showTabBar: true,
  },
  {
    label: "AppIntro",
    path: "/appIntro",
    component: AppIntro,
    isPrivate: false,
  },
  {
    label: "Billing Details",
    path: "/billing-details",
    component: BillingDetails,
    isPrivate: true,
  },
  // {
  //   label: "Credit Builder",
  //   path: "/creditBuild",
  //   component: CreditBuilder,
  //   isPrivate: true,
  // },
  {
    label: "Order Confirmation",
    path: "/order-confirmation",
    component: OrderConfirmation,
    isPrivate: true,
  },
  {
    label: "About Us",
    path: "/about",
    component: AboutUs,
    isPrivate: true,
  },
  {
    label: "Privacy Policies",
    path: "/policies",
    component: Policies,
    isPrivate: true,
  },
  {
    label: "Advice Channel",
    path: "/adviceChannel",
    component: AdviceChannel,
    isPrivate: true,
    showTabBar: true,
  },
  {
    label: "Discussion",
    path: "/discussion",
    component: Discussion,
    isPrivate: true,
  },
  {
    label: "Quick Visa Search",
    path: "/quickSearch",
    component: QuickSearch,
    isPrivate: true,
  },
  {
    label: "University Search",
    path: "/universitySearch",
    component: UniversitySearch,
    isPrivate: true,
  },
  // {
  //   label: "Language Explorer",
  //   path: "/languageFeature",
  //   component: LanguageFeature,
  //   isPrivate: true,
  // },
  {
    label: "PR Points Calculator",
    path: "/prCalculator",
    component: PrCalculator,
    isPrivate: true,
  },
  {
    label: "City Facts",
    path: "/cityFacts",
    component: CityFactPage,
    isPrivate: true,
  },
  {
    label: "Cost of Living",
    path: "/costOfLiving",
    component: CostOfLiving,
    isPrivate: true,
  },
  {
    label: "Eligibility Checker",
    path: "/eligibilityChecker",
    component: EligibilityChecker,
    ignoreCache: true
  },
  {
    label: "Eligibility Checker",
    path: "/eligibilityChecker/:code",
    component: EligibilityChecker,
  },
  {
    label: "Opportunity Report",
    path: "/opportunityReport",
    component: OpportunityReport,
    hideDownloadModal: true
  },
  {
    label: "Opportunity Report",
    path: "/opportunityReport/:code",
    component: OpportunityReport,
    hideDownloadModal: true
  },
  {
    label: "Eligibility Report",
    path: "/eligibilityReport/:code",
    component: OpportunityReport,
    hideDownloadModal: true
  },
  {
    label: "Job Analysis",
    path: "/jobanalysis",
    component: JobAnalysis,
  },
  {
    label: "Applicable Visas",
    path: "/applicableVisas",
    component: ApplicableVisas,
  }
];
