import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { styles1 } from "../cards.styles";
import { setReactionModal } from "../../../../../../../actions/mainMedia.actions";
import { helpers } from "../../../../../../../common";
import { useDispatch } from "react-redux";

export const TotalReactions = ({ item }) => {
  const dispatch = useDispatch();
  const totalReactions = Object.values(item?.reactions_count).reduce(
    (sum, num) => sum + num
  );

  const handleReactionModal = () => {
    dispatch(
      setReactionModal({
        active: true,
        params: { ...item.reactions_count },
      })
    );
  };

  return totalReactions > 0 ? (
    <TouchableOpacity
      onPress={handleReactionModal}
      style={styles1.newReactionCountView}
    >
      {helpers.emoji.map((q, index) =>
        Object.entries(item?.reactions_count).map((s) => {
          if (s[1] > 0) {
            if (s[0] == `reaction_type_${q.code}`) {
              return (
                <Text key={q.code} style={{ fontSize: 19, marginLeft: 5 }}>
                  {q.symbol}
                </Text>
              );
            }
          }
        })
      )}
      <Text style={styles1.totalReactionText}>{totalReactions}</Text>
    </TouchableOpacity>
  ) : (
    <View />
  );
};
