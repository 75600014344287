import React from "react";
import { View, Text, Image } from "react-native";
import { styles } from "../../home.styles";

export const QOccupations = ({ occupations }) => {
  const [occupation, setOccupation] = React.useState({});

  React.useEffect(() => {
    const pOccs = occupations.reduce((prev, curr, index) => {
      const prevPositions = prev[curr.company] ? prev[curr.company] : [];
      return {
        ...prev,
        [curr.company]: [...prevPositions, curr],
      };
    }, {});
    setOccupation(pOccs);
  }, [occupations]);

  return (
    <View style={{ width: "90%", marginLeft: "5%" }}>
      {Object.entries(occupation).map(([company, details], index) => {
        return (
          <View style={{ margin: 5 }}>
            <View style={{ marginBottom: 5 }}>
              <Image />
              <Text style={styles.heading}>
                {company}
              </Text>
            </View>
            <View>
              {details.map((detail) => {
                return (
                  <View style={{ width: "90%", marginLeft: "5%", marginBottom: 5 }}                  >
                    <Text style={styles.subHeading}>
                      {detail.position}
                    </Text>
                    <Text style={styles.type}>
                      Full Time
                    </Text>
                    <Text style={styles.note}>
                      {detail.duration[0]}
                      {" - "}
                      {detail.currentlyWorking ? "Present" : detail.duration[1]}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
        );
      })}
    </View>
  );
};
