import React, { createElement } from "react";
import { Text, View, Dimensions } from "react-native";
import { styles } from "../../editProfile.styles";
import { useSelector } from "react-redux";
import DatePicker from "react-native-datepicker";
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import fontFamily from "../../../../common/fontFamily";
import { heightPercentageToDP } from "react-native-responsive-screen";
import { isWeb } from "../../../../common/helpers";

const { width } = Dimensions.get("window");

export default function DateOfBirth({ setBirthDate, birthDate }) {
  const { userDetails } = useSelector((state) => state.userReducer.getUser);

  const WebDateTimePicker = ({ value, onChange = () => { }, style = {}, minDate, maxDate }) => {
    return createElement('input', {
      type: 'date',
      value: value,
      onInput: onChange,
      style: { outline: 0, borderWidth: 0, backgroundColor: "transparent", ...style },
      min: minDate,
      max: maxDate
    })
  }

  return (
    <View style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}>
      <View style={styles.inputWrapper}>
        <Text style={styles.inputLabel}>Date of Birth</Text>
        {isWeb() ?
          <WebDateTimePicker
            value={moment(birthDate || userDetails?.date_of_birth, "DD/MM/YYYY").format("YYYY-MM-DD")}
            onChange={(e) => {
              let updated = moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY")
              setBirthDate(updated)
            }}
            minDate={moment().subtract(200, "years").format("YYYY-MM-DD")}
            maxDate={moment().subtract(13, "years").format("YYYY-MM-DD")}
          /> :
          <DatePicker
            style={{ width: width / 1.22, height: heightPercentageToDP("3.2%") }}
            date={birthDate || userDetails?.date_of_birth}
            minDate={moment().subtract(200, "years")}
            maxDate={moment().subtract(13, "years")}
            mode="date"
            androidMode="spinner"
            placeholder={
              birthDate
                ? birthDate
                : userDetails?.date_of_birth
                  ? userDetails?.date_of_birth
                  : "Enter your Date of Birth"
            }
            format="DD/MM/YYYY"
            confirmBtnText="Confirm"
            cancelBtnText="Cancel"
            customStyles={{
              dateInput: {
                borderWidth: 0,
                alignItems: "flex-start",
                width: width,
                marginTop: -10,
              },
              dateText: {
                fontFamily: fontFamily.semibold,
                fontSize: heightPercentageToDP("1.95%"),
                color: "#181818",
                padding: 0,
              },
              disabled: {
                backgroundColor: "transparent",
              },
            }}
            onDateChange={(value) => setBirthDate(value)}
            iconComponent={
              <Icon size={20} color="transparent" name="caret-down" />
            }
          />}
      </View>
    </View>
  );
}
