import React, { useState, useEffect } from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Image, Modal, Dimensions, FlatList, Linking, Platform } from "react-native";
import DeviceInfo from "react-native-device-info";
import { Images, family } from "../common";
import { Card } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { apiPaths, Network } from './../service/api'
// import { normalize } from 'react-native-elements';
import { AddToDoList, GetToDoItems } from "./../actions/path.actions";
import AddToDoModal from "./../components/addToDoModal";
import { isWeb, mobilBrower } from '../common/helpers';

const height = Dimensions.get('window').height;
const width = Dimensions.get('window').width;
let hasNotch = DeviceInfo.hasNotch();

function ScoreBreakDownModal(props) {
    const toDoListItems = useSelector(state => state.pathReducer.getImmipathDetails.toDoListItems)
    const [totalPercentage, settotalPercentage] = useState(0);
    const [currentBreakDown, setcurrentBreakDown] = useState(null);
    const [averageTotal, setaverageTotal] = useState(0);
    const [totalScore, setTotalScore] = useState(0);
    const [showBulb, setshowBulb] = useState(false);
    const [selectedItem, setselectedItem] = useState(null)
    const [activeTab, setactiveTab] = useState(null);
    const [showaddModal, setshowaddModal] = useState(false)
    const [promotions, setpromotions] = useState(null)
    const [toDoTitle, settoDoTitle] = useState(null);
    const [addedtodoList, setaddedtodoList] = useState(false)
    const dispatch = useDispatch();
    const [excludedReasons, setexcludedReasons] = useState(null)
    const [availableTabs, setavailableTabs] = useState(null)
    let sum = 0;

    const getColor = (percentage) => {
        let color = ''
        if (percentage >= 40 && percentage <= 80) {
            color = '#999900'
        } else if (percentage < 40) {
            color = 'red'
        } else {
            color = 'green'
        }
        return color;
    }

    const getTodoItems = (item) => {
        let todolistArr = toDoListItems ? Object.entries(toDoListItems?.to_do) : [];
        todolistArr.length > 0 && todolistArr.forEach((s) => {
            if (s[0] == props.item.pathName) {
                item.forEach((r) => {
                    s[1].items.forEach((q) => {
                        if (r.offer_title == Object.entries(q)[0][0]) {
                            let addedToDO = { ...addedtodoList }
                            addedToDO[r.promotion_id] = true
                            setaddedtodoList(addedToDO)
                        }
                    })
                })
            }
        })
    }

    useEffect(() => {
        let averageTotal = 0;
        let totalScore = 0;
        let numberOfItem = 0;
        let counter = false;
        let str = '';
        props.breakDown && Object.entries(props.breakDown).forEach((s) => {
            if (Object.entries(s[1]).length > 0) {
                str += `${s[0]} + `
                numberOfItem += 1;
                if (!counter) {
                    setcurrentBreakDown(s[1]);
                    setactiveTab(s[0]);
                    counter = true;
                }
                Object.entries(s[1]).forEach((q) => {
                    let value = q[1].split('/')[0];
                    averageTotal += parseInt(value);
                    let value2 = q[1].split('/')[1];
                    totalScore += parseInt(value2)
                })
            }
        })
        setavailableTabs(str.slice(0, -2));
        setaverageTotal(averageTotal / numberOfItem);
        setTotalScore(totalScore);
        if (props.excludedPath) {
            let excluded_Reasons = []
            let promotions = Object.entries(props.promotions);
            Object.entries(props.excluded_reason).map((item) => {
                if (item[1].length > 0) {
                    item[1].forEach((s) => {
                        if (s == props.scoreImmipId) {
                            excluded_Reasons.push(item[0])

                        }
                    })
                }
            })
            setexcludedReasons(excluded_Reasons)
        }
    }, [])

    const CheckshowBulb = (item) => {
        let promotions = Object.entries(props.promotions);
        let promoExist = promotions.filter((s) => s[0] == item && s[1].length > 0);
        if (promoExist.length > 0) {
            return true
        } else {
            return false
        }
    }

    const getTotal = (score) => {
        let num = score.split('/')[1]
        sum = parseInt(sum) + parseInt(num);
        let totalPercentage = (100 * props.score) / sum;
        settotalPercentage(totalPercentage);
    }

    const addToDolistApi = async () => {
        let body = {
            immipath_id: props.scoreImmipId,
            to_do_item: selectedItem.offer_title,
            action: 'add',
            url: selectedItem.offer_url ? selectedItem.offer_url : null,
            is_done: false
        }
        let response = await dispatch(AddToDoList(props.user.userid, props.token, body))
        if (response.status == 'Success') {
            let addedToDO = { ...addedtodoList }
            addedToDO[selectedItem.promotion_id] = true
            setaddedtodoList(addedToDO)
            setshowaddModal(false)
            dispatch(GetToDoItems(props.user.userid, props.user.userid + '@' + props.token))
        }
    }

    const openToDoModal = async (item) => {
        setshowaddModal(true)
        settoDoTitle(item.offer_title)
        setselectedItem(item)
    }

    const handletabClick = (tab) => {
        Object.entries(props.breakDown).forEach((s) => {
            if (s[0] == tab) {
                setactiveTab(s[0]);
                setcurrentBreakDown(s[1])
            }
        })
    }

    const handleBuldClick = (reason) => {
        let promotions = props?.promotions ? Object.entries(props.promotions) : [];
        promotions.forEach((s) => {
            if (s[0] == reason) {
                setpromotions(s[1])
                getTodoItems(s[1])
            }
        })
    }

    const promotionClickedAction = (item) => {
        // alert("Promotion clicked")
        Linking.openURL(item.offer_url)
        const url = `/users/${props.user.userid}/log_actions/select_promo`
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: props.user.userid + '@' + props.token
        }
        var bodyParams = {}
        bodyParams.promotion_id = item.promotion_id
        Network(url, 'POST', bodyParams, headers).then(res => {
            if (res.status && res.status === 'Success') {
                // console.log("Event tracked")
            }
        })
    }

    return (
        <Modal transparent visible={props.visible}>
            <View style={styles.mainView}>
                <View style={styles.innerView}>
                    <TouchableOpacity onPress={() => props.handleClose()}
                        style={[styles.cancelButton,
                        {
                            position: props.eligible ? 'relative' : 'absolute',
                            right: props.eligible ? 0 : 10
                        }]}>
                        <Image source={Images.cancelGrey} style={{ height: 12, width: 12 }} />
                    </TouchableOpacity>
                    <Text style={[styles.toptext, props.excludedPath && styles.excludedReasonTitle]}>
                        {
                            props.excludedPath && !props.eligible ? 'Excluded Reasons' :
                                'Scoring is done based on your eligibility questionnaire responses, profile preferences, visa details and country-level indexes'
                        }
                    </Text>
                    <>
                        {
                            props.excludedPath && !props.eligible ?
                                <FlatList
                                    data={excludedReasons}
                                    style={{ alignSelf: 'center', marginBottom: 30, marginTop: 5 }}
                                    renderItem={({ item, index }) => (
                                        <TouchableOpacity style={{ alignItems: 'flex-start', flexDirection: 'row', width: '90%', marginTop: 8 }}>
                                            <Text style={styles.reasonBullet}>{'\u2B24'}</Text>
                                            <Text style={{ marginLeft: 10 }}>
                                                {item}
                                            </Text>
                                            {
                                                CheckshowBulb(item) ?
                                                    <TouchableOpacity onPress={() => handleBuldClick(item)}>
                                                        <Image source={Images.think} style={{ height: 18, width: 18, marginLeft: 10, marginBottom: 5 }} />
                                                    </TouchableOpacity> :
                                                    <View />
                                            }
                                        </TouchableOpacity>
                                    )}
                                />
                                :
                                currentBreakDown !== null ?
                                    <>
                                        <FlatList
                                            data={Object.entries(currentBreakDown)}
                                            style={{ marginBottom: 5 }}
                                            renderItem={({ item, index }) => {
                                                let score = item[1].split('/');
                                                let percentage = (100 * score[0]) / score[1];
                                                getTotal(item[1])
                                                return (
                                                    <View style={styles.item}>
                                                        <View style={styles.bulletView}>
                                                            <View style={styles.bullet} />
                                                            <Text style={styles.title}>
                                                                {item[0]}
                                                            </Text>
                                                        </View>
                                                        <View style={[styles.scoreView, { borderColor: getColor(percentage) }]}>
                                                            <Text style={[styles.score, { color: getColor(percentage) }]}>
                                                                {item[1]}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                )
                                            }}
                                        />
                                        <View style={styles.item}>
                                            <View style={styles.bulletView}>
                                                <Text style={styles.title}>
                                                    {`Average Total (${availableTabs})`}
                                                </Text>
                                            </View>
                                            <View style={[styles.scoreView, { borderColor: getColor(totalPercentage) }]}>
                                                <Text style={[styles.score, { color: getColor(totalPercentage) }]}>
                                                    {averageTotal + '/' + totalScore}
                                                </Text>
                                            </View>
                                        </View>
                                    </>
                                    :
                                    <View />

                        }
                    </>
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    mainView: {
        height: "100%",
        width: "100%",
        backgroundColor: '#222222c7',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerView: {
        // height: hasNotch ? height / 1.3 : height / 1.2,
        width: isWeb() && !mobilBrower() ? "50%" : '90%',
        borderRadius: 20,
        backgroundColor: 'white',
    },
    backButton: {
        height: 23,
        width: 23,
        borderRadius: 11.5,
        alignItems: 'center',
        position: 'absolute',
        zIndex: 99999,
        left: 10,
        backgroundColor: 'white',
        elevation: 12,
        marginLeft: 10,
        shadowColor: '#BDBDBD',
        shadowOffset: {
            height: 1,
            width: 1
        },
        justifyContent: 'center',

    },
    backButonView: {
        alignItems: 'flex-start',
        width: '90%',
        marginTop: 20,
        alignSelf: 'center',
        justifyContent: 'center',
    },
    bullet: {
        height: 8,
        width: 8,
        borderRadius: 4,
        backgroundColor: '#030135'
    },
    promotionstitle: {
        fontSize: 16,
        color: 'red',
        fontFamily: family.semibold
    },
    description: {
        fontSize: 13,
        marginTop: 5
    },
    bulletView: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    toptext: {
        // fontSize: 13,
        textAlign: 'center',
        fontFamily: family.roboto,
        alignSelf: 'center',
        marginTop: 5,
        marginBottom: 5,
        padding: 6,
    },
    excludedReasonTitle: {
        fontFamily: family.robotoBold,
    },
    reasonsTitle: {
        fontSize: 12,
        textAlign: 'center',
        alignSelf: 'center',
        width: '100%',
    },
    excludedTitlesView: {
        backgroundColor: '#030135'
    },
    couponStyle: {
        fontFamily: family.semibold,
        color: '#030135',
        fontSize: 14,
        alignSelf: 'center'
    },
    addtoDo: {
        fontFamily: family.semibold,
        color: '#030135',
        top: Platform.OS == 'ios' ? -3 : 0,
        // fontSize: normalize(15)
    },
    successCheck: {
        height: 18,
        width: 18,
        borderRadius: 9,
        marginBottom: 5,
        alignItems: 'center',
        alignSelf: 'flex-end',
        justifyContent: 'center',
        backgroundColor: 'green'
    },
    addToDoClick: {
        height: 18,
        width: 18,
        borderRadius: 9,
        marginBottom: 5,
        borderWidth: 0.5,
        alignItems: 'center',
        alignSelf: 'flex-end',
        justifyContent: 'center',
    },
    todobutton: {
        right: 5,
        width: '32%',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        marginBottom: 5,
        borderBottomWidth: 1,
    },
    title: {
        fontFamily: family.semibold,
        marginLeft: 10,
    },
    tab: {
        backgroundColor: '#030135',
        marginLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
    },
    reasonBullet: {
        fontSize: 10,
        color: '#030135',
        fontWeight: 'bold'
    },
    tabContanier: {
        backgroundColor: '#030135',
    },
    tabText: {
        color: 'white',
        fontFamily: family.semibold,
        padding: 5,
        fontSize: 15
    },
    cancelButton: {
        alignSelf: 'flex-end',
        marginVertical: 15,
        marginHorizontal: 15
    },
    score: {
        fontFamily: family.semibold,
        fontSize: 12,
    },
    scoreView: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 10,
        shadowColor: '#000',
        width: 50,
        height: 30,
        shadowOffset: { width: 1, height: 1 },
        shadowRadius: 1,
        elevation: 12
    },
    item: {
        flexDirection: 'row',
        width: '90%',
        alignItems: 'center',
        padding: 7,
        alignSelf: 'center',
        marginTop: 7,
        justifyContent: 'space-between'
    },
    item3: {
        width: '80%',
        alignItems: 'center',
        flexDirection: 'row',

        justifyContent: 'space-between',
        alignSelf: 'center'
    }
})
export default ScoreBreakDownModal
