import React from "react";
import { animated as a, useTrail } from "@react-spring/native";

export const TrailFromRight = ({ children }) => {
  const components = React.Children.toArray(children);
  const springs = useTrail(components.length, {
    config: {
      mass: 1,
      tension: 400,
    },
    from: {
      marginTop: 10,
      marginLeft: -10,
      opacity: 0,
    },
    to: {
      marginTop: 0,
      marginLeft: 0,
      opacity: 1,
    },
  });

  return springs.map(({ ...style }, index) => {
    return (
      <a.View key={index} style={[style]}>
        {components[index]}
      </a.View>
    );
  });
};
