import React from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
} from "react-native";
import { styles } from "./CostOfLiving.styles";
import { Config } from "../../common";
import Images from "../../common/images";
import { SelectionModal } from "../SelectionModal/SelectionModal";

export default function CurrencyDropDownComponent({
  gobackCurrentStep,
  currentStep,
  changeCurrency,
  selectedCurrency,
}) {
  const _setValueFromDropDown = (value) => {
    changeCurrency(value);
  };

  const handleBackPress = () => {
    gobackCurrentStep();
  };

  const [selectionModal, setSelectionModal] = React.useState({
    active: false,
    params: null,
  });

  const currencySelection = () => {
    setSelectionModal({
      active: true,
      params: {
        onSelectFn: _setValueFromDropDown,
        options: Config.currencyArray,
        height: "68%",
        itemKey: "label",
        returnKey: "value",
      },
    });
  };

  return (
    <View style={styles.headerView}>
      <SelectionModal
        params={selectionModal.params}
        visible={selectionModal.active}
        setVisible={setSelectionModal}
      />
      <TouchableOpacity onPress={handleBackPress}>
        <Image source={Images.blackbackIcon} style={styles.back} />
      </TouchableOpacity>
      {currentStep == 3 ? (
        <TouchableOpacity
          onPress={currencySelection}
          style={styles.currencyDropDown}
        >
          <Text style={[styles.currencyDropDownText]}>{selectedCurrency}</Text>
        </TouchableOpacity>
      ) : (
        <View />
      )}
    </View>
  );
}
