import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import colors from './colors';
import { isWeb, mobilBrower } from './helpers';

function Badge({ text, containerStyle = {}, textStyle = {} }) {
    if (text) {
        return (
            <View style={[styles.containerStyle, containerStyle]}>
                <Text style={[styles.textStyle, textStyle]}>{text}</Text>
            </View>);
    }
    return null;
}

const styles = StyleSheet.create({
    containerStyle: {
        position: "absolute",
        right:isWeb() && !mobilBrower()?-15: -10,
        top: isWeb() && !mobilBrower()?-15:-10,
        justifyContent: "center",
        alignItems: "center",
        height:  isWeb() && !mobilBrower()?30:20,
        width:isWeb() && !mobilBrower()?30: 20,
        borderRadius:isWeb() && !mobilBrower()? 15: 13,
        backgroundColor: colors.DARK_ORANGE
    },
    textStyle: {
        fontSize: isWeb() && !mobilBrower()?12:8,
        color: "#fff"
    }
})

export default Badge;