import React from "react";
import {
    Text,
    View,
    TouchableOpacity,
    Modal,
    Platform,
    Image,
} from "react-native";
import { styles } from "./AlertModal.styles";
import { useHistory, withRouter } from "react-router";
import NavigationService from "../../navigation/NavigationService";
import BlurBackground from "../BlurBackground";
import { Images } from "../../common";
import { useLocation } from "react-router-dom";

function AlertModal({
    title = "",
    titleStyle = {},
    description = "",
    descriptionStyle = {},
    showConfirm = false,
    confirmText = "Yes",
    onConfirmPress = () => { },
    confirmBtnStyle = {},
    confirmTextStyle = {},
    showCancel = false,
    routeName,
    cancelText = "No",
    onCancelPress = () => { },
    cancelBtnStyle = {},
    cancelTextStyle = {},
    loginModal = false,
    isStore = false,
    showClose = false,
    onClose = () => { }
}) {
    const location = Platform.OS == "web" ?  useLocation() : null
    const history = useHistory()

    const handleLogin = () => {
        onConfirmPress();
        setTimeout(() => {
            if (isStore) {
                Platform.OS == "web"
                    ? history.push("/login")
                    : NavigationService.navigate("Login", { fromStore: true });
            } else {
                Platform.OS == "web"
                    ? history.push("/login", { routeName: location?.pathname })
                    : NavigationService.navigate("Login", { routeName: routeName });
            }
        }, 500);
    };

    return (
        <Modal visible={true} transparent>
            <BlurBackground blurType="light" />
            <View style={styles.mainView}>
                <View style={styles.innerView}>
                    {showClose ? <TouchableOpacity style={{ position: "absolute", right: 15, top: 15 }} onPress={onClose}>
                        <Image source={Images.closeBlack} style={{ height: 23, width: 23 }} />
                    </TouchableOpacity> : null}
                    <Text style={[styles.title, titleStyle]}>
                        {title}
                    </Text>
                    <Text style={[styles.description, descriptionStyle]}>
                        {description}
                    </Text>
                    {showConfirm ?
                        <TouchableOpacity
                            onPress={() => {
                                if (loginModal) {
                                    handleLogin()
                                } else {
                                    onConfirmPress()
                                }
                            }}
                            style={[styles.btn, { backgroundColor: "#030135" }, confirmBtnStyle]}
                        >
                            <Text style={[styles.btnText, { color: "#fff" }, confirmTextStyle]}>{confirmText}</Text>
                        </TouchableOpacity> : null}
                    {showCancel ?
                        <TouchableOpacity
                            onPress={onCancelPress}
                            style={[styles.btn, cancelBtnStyle]}
                        >
                            <Text style={[styles.btnText, cancelTextStyle]}>{cancelText}</Text>
                        </TouchableOpacity> : null}
                </View>
            </View>
        </Modal>
    );
}

export default Platform.OS == "web" ? withRouter(AlertModal) : AlertModal;
