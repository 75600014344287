import React, { useState, useEffect } from "react";
import { styles } from "./newDashboardUi.styles";
import { View, Platform } from "react-native";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import WebFooter from "../../common/WebFooter";
import { useSelector, useDispatch } from "react-redux";
import { getAppTrackerList } from "../../actions/dashboard.actions";
import {
  getNotificationList,
  getUserProfilePic,
  getUserDetails,
} from "../../actions/auth.actions";
import NotificationModal from "../../components/NotificationModal/NotificationModal";
import { getCountryData } from "../../actions/fact.actions";
import NavigationService from "../../navigation/NavigationService";
import { withRouter } from "react-router";
import MiddleView from "./components/MiddleView";
import { DashboardContent } from "./components/DashboardContent";
import {
  incrementScreenViewedCount,
  setInitialAppLoadStatus,
  togglePathExpViewType,
} from "../../actions/app.actions";
import { getStoreItemsGuest } from "../../actions/referral.actions";
import { firebaseEvents, logEvent } from "../../common/firebaseEvents";
import {
  getUnlocks,
  getImmibuckBalance,
} from "../../actions/immibucks.actions";
import { fetchComponentsData } from "../../actions/mainMedia.actions";
import AlertModal from "../../components/AlertModal/AlertModal";
import helpers from "../../common/helpers";
import AsyncStorage from "@react-native-community/async-storage";
import meta from "../../common/metaData";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";


export const mobilBrower = () => {
  var isMobileBrower = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return isMobileBrower;
};

export const isWeb = (img) => {
  if (Platform.OS == "web") {
    return true;
  } else {
    return false;
  }
};

const NewDashboardUi = (props) => {
  const [Highlights, setHighlights] = useState([]);
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const Token = useSelector((state) => state.authReducer.authData.token);
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const [openNotificationModal, setopenNotificationModal] = useState(false);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [lastShortListedpath, setlastShortListedpath] = useState(null);
  //

  const getAsyncS = async () => {
    const bool = await AsyncStorage.getItem("pathExpViewType");
    return bool === "list" ? false : true;
  };

  useEffect(() => {
    const _getUserDetails = async () => {
      !guestLogin && await dispatch(getUserDetails(userDetails?.userid, Token))
    }
    _getUserDetails()
  }, [])

  useEffect(() => {
    dispatch(setInitialAppLoadStatus(false));
    dispatch(incrementScreenViewedCount());

    (async () => {
      const prevState = await getAsyncS();
      dispatch(togglePathExpViewType(prevState));
      if (!guestLogin) {
        const headers = {
          Accept: "application/json",
          Authorization: userDetails?.userid + "@" + Token,
        };

        await dispatch(
          getUnlocks(`${userDetails?.userid}/get_unlocks`, headers)
        );
        helpers.refreshCurrency()
        await dispatch(getCountryData(userDetails?.userid, Token));
        await getImgFromFirebase();
        await getUserImmiBucksBalance(userDetails?.userid, Token);
      }
      let userID = guestLogin ? 0 : userDetails?.userid;
      let token = guestLogin ? "guest@policies" : Token;
      let _token = guestLogin ? "guest@policies" : userID + "@" + token;
      await dispatch(getNotificationList(userID, _token));
      await dispatch(getStoreItemsGuest());
      if (!guestLogin) {
        let appList = await dispatch(
          getAppTrackerList(
            userDetails?.userid,
            userDetails?.userid + "@" + Token
          )
        );
        if (appList) {
          appList = Object.values(appList);
          appList?.length > 0 && setlastShortListedpath([appList[0]]);
        }
      }
      await getHighlights();
      dispatch(setInitialAppLoadStatus(true));
    })();
  }, [userDetails]);

  const getUserImmiBucksBalance = async (userId, token) => {
    const balance_url = `/users/${userId}`;
    const headers = {
      Accept: "application/json",
      Authorization: userId + "@" + token,
    };
    return await dispatch(getImmibuckBalance(balance_url, headers));
  };

  const getImgFromFirebase = async () => {
    try {
      const storage = getStorage();
      const listRef = ref(storage, `avatarImages/${userDetails?.userid}/`)
      const imgList = await listAll(listRef);
      imgList.items.forEach(async (item) => {
        if (item.fullPath.split("/")[1].split(".")[0] == userDetails?.userid) {
          getDownloadURL(item)
            .then((downloadURL) => {
              dispatch(getUserProfilePic({ uri: downloadURL, path: item.fullPath }));
            });
        }
      });
    } catch (e) {

    }
  };

  const handleNotificationClick = () => {
    logEvent(firebaseEvents.OPEN_BULLETIN, {
      message: "User pressed on notification icon, to check bulletins",
    });
    setopenNotificationModal(true);
  };

  const getHighlights = async () => {
    if (guestLogin) {
      await dispatch(
        fetchComponentsData({
          user_id: 0,
          authtoken: "guest@news",
          componentType: "news",
        })
      );
    } else {
      await dispatch(
        fetchComponentsData({
          user_id: userDetails?.userid,
          authtoken: Token,
          from: 0,
          componentType: "news",
        })
      );
    }
  };

  const handleNavigation = (path, ignoreCheck = false) => {
    if (guestLogin && !ignoreCheck) {
      setshowErrorModal(true);
    } else {
      props.history.push(path)
    }
  };

  return (
    <>
      {
        isWeb() &&
        <head>
          <meta content={meta.dashboard} />
        </head>
      }
      <HeaderComponent
        handleNotificationClick={handleNotificationClick}
        showNotificationIcon={true}
      />
      <DashboardContent
        styles={styles}
        history={props.history}
        guestLogin={guestLogin}
        lastShortListedpath={lastShortListedpath}
        handleNavigation={handleNavigation}
        highlights={Highlights}
      >
        <MiddleView
          styles={styles}
          guestLogin={guestLogin}
          handleNavigation={handleNavigation}
        />
      </DashboardContent>
      {!mobilBrower() ? <WebFooter /> : <View />}

      <NotificationModal
        openNotificationModal={openNotificationModal}
        onClose={() => setopenNotificationModal(false)}
      />

      {showErrorModal ? (
        <AlertModal
          title="Login"
          description="Login to explore more!"
          showCancel
          showConfirm
          confirmText="Login"
          cancelText="Not now"
          loginModal
          onCancelPress={() => setshowErrorModal(false)}
          onConfirmPress={() => setshowErrorModal(false)}
        />
      ) : (
        <View />
      )}
    </>
  );
};

export default withRouter(NewDashboardUi);
