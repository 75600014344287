import { StyleSheet } from "react-native";
import fontFamily from "../../common/fontFamily";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";

export const styles = StyleSheet.create({
  mainView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: !mobilBrower() ? "row" : "column",
  },
  leftView: {
    width: "100%",
    alignItems: "center",
    backgroundColor: "#0F1143",
  },
  loginTitle: {
    fontSize: 16,
    color: "rgba(15, 17, 67, 1)",
    fontWeight: "400"
  },
  bottomBar: {
    width: "55%",
    borderWidth: 0.5,
    borderColor: "#BDBDBD",
    marginTop: 15,
  },
  loginSelected: {
    width: "50%",
    borderWidth: 0.5,
    alignSelf: "flex-start",
    borderColor: "#0F1143",
  },
  loginImmiIcon: {
    resizeMode: "contain",
    height: 70,
    width: 70,
    marginBottom: 50,
  },
  loginSignupTab: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    marginTop: 20,
    width: "50%"
  },
  loginWith: {
    fontSize: 14,
    marginTop: 50,
    alignSelf: "center",
  },
  inputBoxmarginTop: {
    marginTop: 20,
  },
  inputBox: {
    width: !mobilBrower() ? "80%" : "100%",
    backgroundColor: "#F5F6F8",
    borderRadius: 5,
    flexDirection: "row",
    borderWidth: 2,
    marginTop: 15,
    borderColor: "#BDBDBD",
    alignItems: "center",
  },
  errorText: {
    color: "red",
    fontSize: 12,
    alignSelf: "flex-start",
    fontFamily: fontFamily.effra,
    marginTop: 10,
    marginLeft: !mobilBrower() ? "11%" : 0
  },
  loginButtonText: {
    color: "white",
    fontSize: 16,
  },
  loginButton: {
    alignItems: "center",
    justifyContent: "center",
    width: mobilBrower() ? "100%" : "80%",
    marginTop: 15,
    marginBottom: 15,
    paddingHorizontal: 15,
    paddingVertical: 12,
    backgroundColor: "#0F1143",
  },
  forgotPassView: {
    width: !mobilBrower() ? "80%" : "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  forgotPassText: {
    fontSize: 13,
    color: "#0F1143",
  },
  textInput: {
    paddingHorizontal: 15,
    paddingVertical: 12,
    flex: 1,
    width: "75%",
  },
  loginImg: {
    flex: 1,
    width: "100%",
    height: mobilBrower() ? "calc(100vh * 0.23 )" : "100vh",
    resizeMode: "contain",
  },
  loginView: {
    alignItems: "center",
    width: "80%",
    alignSelf: "center",
    height: "100%",
    marginTop: "5%",
  },
  rightView: {
    alignItems: "center",
    width: "100%",
    marginTop: -70,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: "white",
    justifyContent: "center",
    height: "100%",
  },
  backBtn: {
    position: "absolute",
    left: 0,
    top: 27
  },
  subview: {
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
  },
  subtext:{
    fontSize: responsiveText(16),
    color: "#2C393F",
    textAlign: "center"
  },
});
