import React, { useState, useEffect } from "react";
import {
  TouchableOpacity,
  useWindowDimensions,
  Modal,
  View,
  Dimensions,
} from "react-native";
import { URL, URLSearchParams } from "react-native-url-polyfill";
import { styles } from "./styles/youtubeModal.styles";
import { Platform } from "react-native";
import { mobilBrower } from "../../common/helpers";
import { useDispatch, useSelector } from "react-redux";
import YoutubeIframe from "react-native-youtube-iframe";
import { setYoutubeModal } from "../../actions/mainMedia.actions";
const { height, width } = Dimensions.get("window");
import ReactPlayer from 'react-player/youtube'

const YoutubeModal = () => {
  const [videoId, setvideoId] = useState(null);
  const window = Platform.OS == "web" ? useWindowDimensions() : null;
  const modalParams = useSelector(
    (state) => state.mainMediaReducer.gState.youtubeModal
  );
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setYoutubeModal());
  };

  useEffect(() => {
    if (modalParams.active) {
      if (Platform.OS !== "web") {
        const url = new URL(modalParams.item.url);
        var searchParams =
          modalParams.item &&
          modalParams.item.url &&
          new URLSearchParams(url.search);
        var c = searchParams && searchParams.get("v");
        setvideoId(c);
      }
    }
  }, [modalParams.item]);

  return (
    <Modal visible={modalParams.active} transparent onRequestClose={onClose}>
      <TouchableOpacity
        onPress={onClose}
        activeOpacity={1}
        style={styles.mainView}
      >
        <TouchableOpacity activeOpacity={1} style={styles.innerView}>
          {Platform.OS == "web" ? (
            <ReactPlayer
            url={modalParams.item?.url}
            playing={true}
            stopOnUnmount={true}
            width={mobilBrower() ? "95%" : "60%"}
            height="100%" 
            />
          ) : (
            <YoutubeIframe
              play={true}
              videoId={videoId}
              height={250}
              width={width * 0.9}
              style={{ backgroundColor: "red" }}
            />
          )}
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
};

export default YoutubeModal;
