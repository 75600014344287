import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, FlatList } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { selectedFactsAndLocalitiesCities } from "../../actions/fact.actions";
import MultipleSelectionModal from "../MultipleSelectionModal/MultipleSelectionModal";
import helpers, { isWeb, mobilBrower } from "../../common/helpers";
import LocalityFactsDisplay from "./Components/LocalityFactsDisplay/LocalityFactsDisplay";
import _, { uniq } from "lodash";
import Loader from "../Loader";
import { Network, apiPaths } from "../../service/api";
import {
  getImmibuckBalance,
  getUnlocks,
} from "../../actions/immibucks.actions";
import GetMoreCityModal from "../NewGetMoreCityModal/NewGetMoreCityModal";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { styles } from "./localityFactPage.styles";
import { SelectionModal } from "../SelectionModal/SelectionModal";
import AlertModal from "../AlertModal/AlertModal";
import { CheckBoxItem } from "./Components/CheckBoxItem/CheckBoxItem";
import meta from "../../common/metaData";
import WebFooter from "../../common/WebFooter";

function LocalityFactPage() {
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const factDetails = useSelector(
    (state) => state.factReducer.factData.localityfactDetails
  );
  const uniData = useSelector(
    (state) => state.factReducer.factData.universityData
  );
  const Token = useSelector((state) => state.authReducer.authData.token);
  const immibucks_balance = useSelector(
    (state) => state.immibucksReducer.getImmibucksDetails.immibucks_balance
  );
  const unlocks = useSelector(
    (state) => state.immibucksReducer.getImmibucksDetails.unlocks
  );
  const facts = useSelector((state) => state.factReducer.factData);
  const [loading, setloading] = useState(false);
  const [countryDropDown, setcountryDropDown] = useState([]);
  const [CityViewDataSet, setCityViewDataSet] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [selectedCurrency, setselectedCurrency] = useState(null);
  const [modalOpen, setmodalOpen] = useState(false);
  const [uniDropDown, setuniDropDown] = useState([]);
  const [showImmibuckPopUp, setshowImmibuckPopUp] = useState(false);
  const [errorMessage, seterrorMessage] = useState(null);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [selectedJobId, setselectedJobId] = useState(null);
  const [selectedJobname, setselectedJobname] = useState(null);
  const [insufficientBalance, setinsufficientBalance] = useState("");
  const [localityFacts, setlocalityFacts] = useState([]);
  const [showFactsView, setshowFactsView] = useState(false);
  const [selectedUniversity, setselectedUniversity] = useState([]);
  const [SelectedCities, setSelectedCities] = useState([]);
  const [selectedCountries, setselectedCountries] = useState([]);
  const [selectedFactsIds, setselectedFactsIds] = useState([]);
  const [selectedoption, setselectedoption] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let _countryDropDown = [];
    let _uniDropDown = [];
    facts &&
      facts.countryData.forEach((item) => {
        _countryDropDown.push({
          label: `${item.City}`,
          value: { id: item.city_id, country_id: item.country_id },
        });
      });
    facts &&
      facts.universityData.forEach((item) => {
        _uniDropDown.push({
          label: `${item["University Name"]} - ${item.City}`,
          value: {
            id: item.univ_id,
            country_id: item.country_id,
            city_id: item.city_id,
          },
        });
      });
    setcountryDropDown(_countryDropDown);
    setuniDropDown(_uniDropDown);
  }, [facts]);

  const get4facts = () => {
    const url = userDetails?.userid + "/add";
    const balance_url = `/users/${userDetails?.userid}`;
    const headers = {
      Accept: "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };
    const body = {
      amount: 100,
      type: "debit",
      statement: "Unlock 4 extra city facts",
      milestone: null,
    };
    if (immibucks_balance >= 100) {
      Network(apiPaths.checkRewardMilestone + url, "POST", body, headers).then(
        (res) => {
          if (res.status === "Success") {
            setshowImmibuckPopUp(false);
            dispatch(getUnlocks(`${userDetails?.userid}/get_unlocks`, headers));
            dispatch(getImmibuckBalance(balance_url, headers));
          }
        }
      );
    } else {
      setinsufficientBalance(
        "You do not have sufficient balance. Earn Immibucks by exploring the app more!"
      );
    }
  };

  const handleSelectedJob = (data, i) => {
    setselectedJobId(i.toString());
    setselectedJobname(data);
  };

  const handleCheckBoxClick = (item) => {
    let ids = [...selectedFactsIds];
    if (ids.includes(item.factId)) {
      ids = ids.filter((s) => s !== item.factId);
      setselectedFactsIds(ids);
    } else {
      if (!unlocks.includes("Unlock 4 extra city facts")) {
        if (selectedFactsIds.length >= 4) {
          setshowImmibuckPopUp(true);
          return;
        }
      }
      if (selectedFactsIds.length >= 8) {
        setshowErrorModal(true);
        seterrorMessage("Sorry can only select a maximum of 8 locality facts");
        setTimeout(() => {
          setshowErrorModal(false);
        }, 1500);
        return;
      }
      ids.push(item.factId);
      setselectedFactsIds(ids);
    }
  };

  const _setCity = (item) => {
    let name = countryDropDown.filter((s) => s.value.id == item);
    return name.length > 0 ? name[0].label : "";
  };

  useEffect(() => {
    let tableContentData = [];
    let CityViewData = [];
    const universityData = uniData;
    if (selectedoption == "university") {
      for (let key in factDetails) {
        if (factDetails.hasOwnProperty(key)) {
          var val = factDetails[key];
          let selectUnivArr = [];
          universityData.filter((filterData) => {
            selectedUniversity.forEach((s) => {
              if (s === filterData.univ_id) {
                if (filterData.city_id == key) {
                  const univeRanking = `${filterData["University Name"]} (Rank - ${filterData.times_univ_ranking})`;
                  selectUnivArr.push(univeRanking);
                }
              }
            });
          });
          factDetails[key][23] = selectUnivArr;
        }
      }
    }

    factDetails &&
      Object.keys(factDetails).map((item) => {
        let cityDict = factDetails[item];
        let cityDictItem = Object.keys(cityDict);
        let CityViewdict = {
          cityName: _setCity(item),
          cityId: item,
          averageMonthSalary: cityDictItem.includes("0") ? cityDict[0] : null,
          costLiving: cityDictItem.includes("1") ? cityDict[1] : null,
          notCityApartment1Bdrm: cityDictItem.includes("2")
            ? cityDict[1]
            : null,
          coronaEffect: cityDictItem.includes("3")
            ? cityDict[3]
              ? cityDict[3]
              : null
            : null,
          //averageCost: cityDictItem.includes("3") ? cityDict[3] : null,
          weather: cityDictItem.includes("7") ? cityDict[7] : null,
          ranking: cityDictItem.includes("23") ? cityDict[23] : null,
          buisnessFriendliness: cityDictItem.includes("8") ? cityDict[8] : null,
          englishLiteracy: cityDictItem.includes("9") ? cityDict[9] : null,
          officalLanguages: cityDictItem.includes("10") ? cityDict[10] : null,
          immigrationFriendliness: cityDictItem.includes("11")
            ? cityDict[11]
            : null,
          population: cityDictItem.includes("12") ? cityDict[12] : null,
          activeCases: cityDictItem.includes("14")
            ? cityDict[14]
              ? cityDict[14]
              : null
            : null,
          covidVaccination: cityDictItem.includes("13") ? cityDict[13] : null,
          gdpGrowth: cityDictItem.includes("15") ? cityDict[15] : null,
          selected_job_salary: cityDictItem.includes("selected_job_salary")
            ? cityDict["selected_job_salary"]
            : null,
          trafficIndex: cityDictItem.includes("5") ? cityDict[5] : null,
          crimeIndex: cityDictItem.includes("6") ? cityDict[6] : null,
          pollutionIndex: cityDictItem.includes("4") ? cityDict[4] : null,
          globalSocialIndex: cityDictItem.includes("17") ? cityDict[17] : null,
          healthCareIndex: cityDictItem.includes("18") ? cityDict[18] : null,
          alcoholPriceIndex: cityDictItem.includes("19") ? cityDict[19] : null,
          officalGdp: cityDictItem.includes("16") ? cityDict[16] : null,
        };
        CityViewData.push(CityViewdict);
        CityViewData = CityViewData.sort((a, b) => {
          return a.cityName > b.cityName ? 1 : -1;
        });
        let filterData = factDetails[Object.keys(factDetails)[0]];

        let data = Object.keys(filterData).filter((item) => {
          if (item === "immigration" || item === "7") return false;
          else return true;
        });
        setfilteredData(data);
        setCityViewDataSet(CityViewData);
        let dict = {
          cityName: _setCity(item),
          cityId: item,
          "Average Monthly Net Salary (After Tax)": cityDictItem.includes("0")
            ? cityDict[0]
            : null,
          "Cost of living": cityDictItem.includes("1") ? cityDict[1] : null,
          "Cost of Apartment (1BHK)": cityDictItem.includes("2")
            ? cityDict[1]
            : null,
          "Coronavirus Active cases at State/Province level":
            cityDictItem.includes("14") ? cityDict[14] : null,
          "Coronavirus Spread at State/Province level": cityDictItem.includes(
            "3"
          )
            ? cityDict[3]
            : null,
          "Pollution Index": cityDictItem.includes("4") ? cityDict[4] : null,
          "Traffic Index": cityDictItem.includes("5") ? cityDict[5] : null,
          "Crime Index": cityDictItem.includes("6") ? cityDict[6] : null,
          "University Ranking": cityDictItem.includes("23")
            ? cityDict[23]
            : null,
          "Buisness Friendliness Index": cityDictItem.includes("8")
            ? cityDict[8]
            : null,
          "English Literacy": cityDictItem.includes("9") ? cityDict[9] : null,
          "Official Language": cityDictItem.includes("10")
            ? cityDict[10]
            : null,
          "Immigration Friendliness Index": cityDictItem.includes("11")
            ? cityDict[11]
            : null,
          Population: cityDictItem.includes("12") ? cityDict[12] : null,
          "Coronavirus Active cases at State/Province level":
            cityDictItem.includes("14") ? cityDict[14] : null,
          "Coronavirus Vaccinations": cityDictItem.includes("13")
            ? cityDict[13]
            : null,
          "GDP Growth (Last quarter)": cityDictItem.includes("15")
            ? cityDict[15]
            : null,
          "Selected Job Salary": cityDictItem.includes("selected_job_salary")
            ? cityDict["selected_job_salary"]
            : null,
          "Global Social Mobility Index": cityDictItem.includes("17")
            ? cityDict[17]
            : null,
          "Health Care Quality Index": cityDictItem.includes("18")
            ? cityDict[18]
            : null,
          "Alcohol Prices Index": cityDictItem.includes("19")
            ? cityDict[19]
            : null,
          "Official GDP (Billion $)": cityDictItem.includes("16")
            ? cityDict[16]
            : null,
          Weather: cityDictItem.includes("7") ? cityDict[7] : null,
        };
        tableContentData.push(dict);
        let newData = [];
        let country = [];
        let obj = {};
        let obj2 = {};
        let obj3 = {};
        let obj4 = {};
        let obj5 = {};
        let obj6 = {};
        let obj18 = {};
        let obj7 = {};
        let obj8 = {};
        let obj9 = {};
        let obj10 = {};
        let obj11 = {};
        let obj12 = {};
        let obj13 = {};
        let obj14 = {};
        let obj15 = {};
        let obj16 = {};
        let obj17 = {};
        let obj19 = {};
        let obj20 = {};
        let obj21 = {};
        let obj22 = {};
        let obj23 = {};

        tableContentData.forEach((s) => {
          if (s["English Literacy"]) {
            obj[s["cityName"]] = s["English Literacy"];
            obj["fact"] = "English Literacy";
            country.push(obj);
          }
          if (s["Population"]) {
            obj2[s["cityName"]] = s["Population"];
            obj2["fact"] = "Population";
            country.push(obj2);
          }
          if (s["Coronavirus Spread at State/Province level"]) {
            obj19[s["cityName"]] =
              s["Coronavirus Spread at State/Province level"];
            obj19["fact"] = "Coronavirus Spread at State/Province level";
            country.push(obj19);
          }
          if (s["Immigration Friendliness Index"]) {
            obj3[s["cityName"]] = s["Immigration Friendliness Index"];
            obj3["fact"] = "Immigration Friendliness Index";
            country.push(obj3);
          }
          if (s["Buisness Friendliness Index"]) {
            obj4[s["cityName"]] = s["Buisness Friendliness Index"];
            obj4["fact"] = "Buisness Friendliness Index";
            country.push(obj4);
          }
          if (s["Coronavirus Active cases at State/Province level"]) {
            obj5[s["cityName"]] =
              s["Coronavirus Active cases at State/Province level"];
            obj5["fact"] = "Coronavirus Active cases at State/Province level";
            country.push(obj5);
          }
          if (s["Traffic Index"]) {
            obj6[s["cityName"]] = s["Traffic Index"];
            obj6["fact"] = "Traffic Index";
            country.push(obj6);
          }
          if (s["Selected Job Salary"]) {
            obj18[s["cityName"]] = s["Selected Job Salary"];
            obj18["fact"] = "Selected Job Salary";
            country.push(obj18);
          }
          if (s["Crime Index"]) {
            obj7[s["cityName"]] = s["Crime Index"];
            obj7["fact"] = "Crime Index";
            country.push(obj7);
          }
          if (s["Pollution Index"]) {
            obj8[s["cityName"]] = s["Pollution Index"];
            obj8["fact"] = "Pollution Index";
            country.push(obj8);
          }
          if (s["Weather"]) {
            obj9[s["cityName"]] = s["Weather"];
            obj9["fact"] = "Weather";
            country.push(obj9);
          }
          if (s["Average Monthly Net Salary (After Tax)"]) {
            obj10[s["cityName"]] = s["Average Monthly Net Salary (After Tax)"];
            obj10["fact"] = "Average Monthly Net Salary (After Tax)";
            country.push(obj10);
          }
          if (s["Cost of Apartment (1BHK)"]) {
            obj12[s["cityName"]] = s["Cost of Apartment (1BHK)"];
            obj12["fact"] = "Cost of Apartment (1BHK)";
            country.push(obj12);
          }
          if (s["Corona Cases"]) {
            obj13[s["cityName"]] = s["Corona Cases"];
            obj13["fact"] = "Corona Cases";
            country.push(obj13);
          }
          if (s["GDP Growth (Last quarter)"]) {
            obj14[s["cityName"]] = s["GDP Growth (Last quarter)"];
            obj14["fact"] = "GDP Growth (Last quarter)";
            country.push(obj14);
          }
          if (s["Coronavirus Vaccinations"]) {
            obj15[s["cityName"]] = s["Coronavirus Vaccinations"];
            obj15["fact"] = "Coronavirus Vaccinations";
            country.push(obj15);
          }
          if (s["Official Language"]) {
            obj16[s["cityName"]] = s["Official Language"];
            obj16["fact"] = "Official Language";
            country.push(obj16);
          }
          if (s["University Ranking"]) {
            obj17[s["cityName"]] = s["University Ranking"];
            obj17["fact"] = "University Ranking";
            country.push(obj17);
          }
          if (s["Global Social Mobility Index"]) {
            obj20[s["cityName"]] = s["Global Social Mobility Index"];
            obj20["fact"] = "Global Social Mobility Index";
            country.push(obj20);
          }
          if (s["Health Care Quality Index"]) {
            obj21[s["cityName"]] = s["Health Care Quality Index"];
            obj21["fact"] = "Health Care Quality Index";
            country.push(obj21);
          }
          if (s["Alcohol Prices Index"]) {
            obj22[s["cityName"]] = s["Alcohol Prices Index"];
            obj22["fact"] = "Alcohol Prices Index";
            country.push(obj22);
          }
          if (s["Official GDP (Billion $)"]) {
            obj23[s["cityName"]] = s["Official GDP (Billion $)"];
            obj23["fact"] = "Official GDP (Billion $)";
            country.push(obj23);
          }
        });

        setlocalityFacts(uniq(country));
      });
  }, [factDetails]);

  const _handleModalClick = () => {
    setshowImmibuckPopUp(false);
    if (insufficientBalance !== "") {
      setinsufficientBalance("");
    }
  };

  const handleProceed = async () => {
    if (selectedFactsIds.length <= 0) {
      setshowErrorModal(true);
      seterrorMessage("Select at least 1 city fact");
      setTimeout(() => {
        setshowErrorModal(false);
      }, 1200);
      return;
    }
    if (SelectedCities.length == 0 && selectedUniversity.length == 0) {
      setshowErrorModal(true);
      seterrorMessage("Select at least 1 city or university");
      setTimeout(() => {
        setshowErrorModal(false);
      }, 1200);
      return;
    }
    if (selectedFactsIds.includes(2) && !selectedJobId) {
      setshowErrorModal(true);
      seterrorMessage("Select a job for Average Yearly Salary");
      setTimeout(() => {
        setshowErrorModal(false);
      }, 1200);
      return;
    }
    setloading(true);
    let city_options = {};

    if (selectedoption == "cities") {
      city_options = {
        selection_type: 4,
        city_ids: SelectedCities,
      };
    } else {
      city_options = {
        selection_type: 3,
        city_ids: SelectedCities,
        country_ids: selectedCountries,
        univ_id: selectedUniversity,
      };
    }
    let Obj = {
      exploration_id: null,
      exploration_type: "factcomparator",
      fact_options: {
        fact_ids: selectedFactsIds.filter((s) => s !== 2),
      },
      city_options: city_options,
    };
    if (selectedFactsIds.includes(2) && selectedJobId) {
      Obj.fact_options.selected_job_id = selectedJobId;
    }
    let res = await dispatch(
      selectedFactsAndLocalitiesCities(
        userDetails?.userid,
        Token,
        Obj,
        null,
        null,
        null,
        null
      )
    );
    if (res.status == "Success") {
      setloading(false);
      setshowFactsView(true);
    }
  };

  const handleDropDownClick = (type) => {
    setselectedoption(type);
    setmodalOpen(true);
  };

  useEffect(() => {
    if (selectedCurrency) {
      let data = localityFacts.filter((s) => s.fact == "Selected Job Salary");
      if (data) {
        data = Object.entries(data[0]).map((item) => {
          return helpers.currencyConverter(selectedCurrency, item[1]);
        });
      }
    }
  }, [selectedCurrency]);

  const handleSelect = (item) => {
    if (selectedoption == "cities") {
      let _cityId = [];
      item.forEach((s) => {
        _cityId.push(s.value.id);
      });
      setSelectedCities(_cityId);
      setselectedUniversity([]);
    } else {
      let _countryId = [];
      let _cityId = [];
      let _uniId = [];
      item.forEach((s) => {
        _countryId.push(s.value.country_id);
        _cityId.push(s.value.city_id);
        _uniId.push(s.value.id);
      });
      setselectedCountries(_countryId);
      setSelectedCities(_cityId);
      setselectedUniversity(_uniId);
    }
    setmodalOpen(false);
  };

  const handleBackPress = () => {
    setshowFactsView(false);
  };

  const selectedCurrencyChange = (data, i) => {
    setselectedCurrency(data);
  };

  const [selectionModal, setSelectionModal] = useState({
    active: false,
    params: null,
  });

  const jobSelection = (dropDownValues) => {
    setSelectionModal({
      active: true,
      params: {
        onSelectFn: handleSelectedJob,
        options: dropDownValues,
        height: "68%",
        itemKey: "value",
        returnKey: "value",
      },
    });
  };

  return (
    <View style={{ flex: 1 }}>
      {
        isWeb() &&
        <head>
          <meta content={meta.localityComparator} />
        </head>
      }
      <HeaderComponent
        showNotificationIcon={false}
        showback={true}
        screenTitle="City Facts"
      />
      <SelectionModal
        params={selectionModal.params}
        visible={selectionModal.active}
        setVisible={setSelectionModal}
      />
      {loading ? <Loader /> : <View />}
      {showFactsView ? (
        <LocalityFactsDisplay
          handleBackPress={handleBackPress}
          SelectedCities={SelectedCities.length}
          filteredData={filteredData}
          showTabs={true}
          selectedJobname={selectedJobname}
          selectedFactsIds={selectedFactsIds}
          CityViewDataSet={CityViewDataSet}
          localityFacts={localityFacts}
        />
      ) : (
        <TouchableOpacity activeOpacity={1} style={styles.listView}>
          {countryDropDown && countryDropDown.length > 0 ? (
            <TouchableOpacity
              onPress={() => handleDropDownClick("cities")}
              style={styles.dropDownView}
            >
              <Text style={styles.indexName}>
                {SelectedCities.length > 0 && selectedoption == "cities"
                  ? SelectedCities.length + " cities selected"
                  : " Select cities"}
              </Text>
            </TouchableOpacity>
          ) : (
            <View />
          )}
          <Text style={styles.orText}>OR</Text>
          {uniDropDown && uniDropDown.length > 0 ? (
            <TouchableOpacity
              onPress={() => handleDropDownClick("university")}
              style={[styles.dropDownView, { marginTop: 0 }]}
            >
              <Text style={styles.indexName}>
                {selectedUniversity.length > 0
                  ? selectedUniversity.length + " university cities selected"
                  : " Select university cities"}
              </Text>
            </TouchableOpacity>
          ) : (
            <View />
          )}
          <FlatList
            data={facts.factsList}
            showsVerticalScrollIndicator={false}
            style={{ paddingHorizontal: 15, marginTop: 10 }}
            renderItem={({ item, index }) => {
              return (
                <CheckBoxItem
                  item={item}
                  handleCheckBoxClick={handleCheckBoxClick}
                  selectedFactsIds={selectedFactsIds}
                  selectedJobId={selectedJobId}
                  jobSelection={jobSelection}
                />
              );
            }}
          />
          <TouchableOpacity
            onPress={() => handleProceed()}
            style={styles.proceedButton}
          >
            <Text style={styles.proceedText}>Proceed</Text>
          </TouchableOpacity>
        </TouchableOpacity>
      )}
      {modalOpen ? (
        <MultipleSelectionModal
          data={selectedoption == "cities" ? countryDropDown : uniDropDown}
          _handleSelect={handleSelect}
          title={
            selectedoption == "cities" ? "Select city" : "Select university"
          }
          onclose={() => setmodalOpen(false)}
        />
      ) : (
        <View />
      )}
      {showErrorModal ? (
        <AlertModal title="Oops!" description={errorMessage} />
      ) : (
        <View />
      )}
      <GetMoreCityModal
        description="Get 4 more city facts to compare for"
        title="You can only select 4 facts"
        button1="Get 4 more"
        button2="I’m good with 4"
        price={100}
        insufficientBalance={
          insufficientBalance !== "" ? insufficientBalance : ""
        }
        successMessage={"Purchase Successfull!"}
        visible={showImmibuckPopUp}
        handleButton1={get4facts}
        _handleModalClick={_handleModalClick}
      />
      {isWeb() && !mobilBrower() ? <WebFooter /> : null}
    </View>
  );
}

export default LocalityFactPage;
