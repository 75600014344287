import React, { useState } from 'react';
import { Text, View } from "react-native";
import { useSelector } from 'react-redux';
import { Network } from '../../../../service/api';
import AlertModal from '../../../AlertModal/AlertModal';
import ScoreBreakDownModal from '../../../ScoreBreakDown/ScoreBreakDownModal';
import VisaDetailsModal from '../VisaDetailsModal';
import Collapsable from './Collapsable/Collapsable';
import { styles } from './EligibilityReport.styles';
import Visa from './Visa';

function EligibilityReport({ reportData = {}, onAskExpert = () => { } }) {
    const [activeReportIndex, setActiveReportIndex] = useState(null);
    const [scoreModalData, setScoreModalData] = useState(null);
    const [detailsModalData, setDetailsModalData] = useState(null);
    const [showAlert, setShowAlert] = useState(null);
    const userImmipathSearchResults = useSelector(state => state.pathReducer?.getImmipathDetails?.userImmipathSearchResults)
    let ignoreJobOffer = userImmipathSearchResults?.payload?.goal_ids[0] == 3;
    const guestLogin = useSelector(
        (state) => state.pathReducer.getImmipathDetails.guestLogin
    );
    const Token = useSelector((state) => state.authReducer.authData.token);
    const userDetails = useSelector(
        (state) => state.userReducer.getUser.userDetails
    );

    const handleInfoClick = (item, eligible) => {
        setScoreModalData({
            scoreData: item,
            eligible
        })
    }

    const handleVisaPress = async ({ immipath_id }, excluded_path = false) => {
        const headers = {
            Accept: "application/json",
            Authorization: guestLogin ? `guest@immipath` : userDetails?.userid + "@" + Token,
        };
        let response = await Network(`/immipaths/paths/${immipath_id}`, "GET", null, headers)
        const { status, path_details } = response;
        if (status == 'Success') {
            setDetailsModalData({
                data: path_details,
                excluded_path: excluded_path
            })
        }
    }

    const reports = [
        {
            title: "Eligible Visas",
            data: reportData.results.filter(item => !item.specialized_visa && (ignoreJobOffer || !item.job_offer_required || (item.job_offer_required && userImmipathSearchResults.userInfo.has_job_offer?.[item.country_id]))),
            color: "#33AC0950",
            getVisaColor: ({ score }) => {
                if (score >= 70) {
                    return "#E5FFDE"
                } else if (score >= 40) {
                    return "#FFF4BF"
                }
                return "#FFCACA"
            },
        },
        {
            title: "Non Eligible Visas",
            data: [...reportData.excluded_paths, ...(ignoreJobOffer ? [] : reportData.results.filter(item => !item.specialized_visa && (item.job_offer_required && !userImmipathSearchResults.userInfo.has_job_offer?.[item.country_id])))],
            color: "#E84A4650",
            getVisaColor: () => "#FFCACA",
            notEligible: true,
            onStarPress: (visa) => {
                let obj = {
                    title: "Requirement",
                    description: "This path is specialized and requires specific criteria to determine eligibility.",
                    confirmText: "Ask our expert",
                    cancelText: "Not at the moment",
                    onCancelPress: () => {
                        setShowAlert(null);
                    },
                    onConfirmPress: () => {
                        onAskExpert(reportData, visa);
                        setShowAlert(null);
                    },
                    showCancel: true,
                    showConfirm: true,
                }
                setShowAlert(obj)
            },
        },
        {
            title: "Uncertain Visas", data: reportData.results.filter(item => item.specialized_visa), color: "#528CC1", getVisaColor: () => "#CAEFFF", onStarPress: (visa) => {
                let obj = {
                    title: "Requirement",
                    description: "This path is specialized and requires specific criteria to determine eligibility.",
                    confirmText: "Ask our expert",
                    cancelText: "Not at the moment",
                    onCancelPress: () => {
                        setShowAlert(null);
                    },
                    onConfirmPress: () => {
                        onAskExpert(reportData, visa);
                        setShowAlert(null);
                    },
                    showCancel: true,
                    showConfirm: true,
                }
                setShowAlert(obj)
            }
        },
    ]

    return (
        <>
            <View
                style={{
                    width: "85%",
                    marginTop: 20
                }}
            >
                <View style={{ padding: 20 }}>
                    <Text style={{ fontSize: 22 }}>{"This report narrows down different paths you are likely to be eligible for."}</Text>
                    <Text style={{ fontSize: 17, marging: 5, color: "grey" }}>{"Note: Some paths require a job offer before you can apply for the visa."}</Text>
                </View>
                {reports.map(((report, index) => (
                    <Collapsable
                        title={report.title}
                        color={report.color}
                        collapsed={activeReportIndex !== index}
                        onPress={() => setActiveReportIndex(activeReportIndex !== index ? index : null)}
                        extra={
                            <View style={styles.badge}>
                                <Text style={styles.badgeText}>{report.data.length}
                                </Text>
                            </View>
                        }
                        key={index} >
                        <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                            {report.data.map((item, index) => (
                                <Visa
                                    item={item}
                                    onPress={() => { handleVisaPress(item, report.notEligible) }}
                                    key={index}
                                    showStar={item.specialized_visa}
                                    onStarPress={() => report.onStarPress(item)}
                                    onInfoClick={() => { handleInfoClick(item, !report.notEligible) }}
                                    color={report.getVisaColor(item)}
                                />
                            ))}
                        </View>
                    </Collapsable>
                )))}
            </View>
            {
                detailsModalData &&
                <VisaDetailsModal
                    visible={true}
                    criteria={false}
                    selectedPath={detailsModalData.data}
                    excludedPath={detailsModalData.excluded_path}
                    onClose={() => setDetailsModalData(null)}
                />
            }
            {showAlert ?
                <AlertModal
                    {...showAlert}
                    showClose={true}
                    onClose={() => setShowAlert(null)}
                /> : null}
            {scoreModalData && (
                <ScoreBreakDownModal
                    visible={true}
                    breakDown={scoreModalData.scoreData.score_breakdown}
                    score={scoreModalData.scoreData.score}
                    scoreImmipId={scoreModalData.scoreData.immipath_id}
                    eligible={scoreModalData.eligible}
                    item={scoreModalData.scoreData}
                    onAskExpert={async () => {
                        await onAskExpert(reportData, scoreModalData.scoreData)
                        setScoreModalData(null);
                    }}
                    excluded_reason={reportData.excluded_reason}
                    promotions={reportData.promotions}
                    excludedPath={reportData.excluded_paths}
                    handleClose={() => setScoreModalData(null)}
                />
            )}
        </>
    )
}

export default EligibilityReport;