import { StyleSheet } from "react-native";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";
import { widthPercentageToDP } from "../../../../common/responsiveScreen";

export const styles = StyleSheet.create({
    main: {
        flex: 1,
        width: mobilBrower() ? "100%" : "65%",
        alignSelf: "center",
    },
    mainWrapper: {
        backgroundColor: "#fff",
        // height: mobilBrower() ? heightPercentageToDP(100) : heightPercentageToDP(96)
    },
    mainInnerView: {
        backgroundColor: "#fff",
        overflow: "hidden",
        width: mobilBrower() ? "100%" : "116%",
        height: mobilBrower() ? "100%" : "107%",
        marginTop: mobilBrower() ? 0 : "-3%",
        marginBottom: mobilBrower() ? 0 : "-3%",
        marginLeft: mobilBrower() ? 0 : "-8%",
        borderRadius: mobilBrower() ? 0 : 50,
        // padding: mobilBrower() ? 0 : 10,
        elevation: 5,
        shadowRadius: 20,
        shadowColor: "#00000050",
        zIndex: 1
    },
    arrowWrapper: {
        height: 48,
        width: 70,
        alignItems: "center",
        justifyContent: "center"
    },
    arrow: {
        height: "40%",
        width: "40%"
    },

    header: {
        position: "absolute",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1
    },
    pointsContainer: {
        width: mobilBrower() ? "90%" : "80%",
        height: "100%",
        alignItems: "center",
        alignSelf: "center"
    },
    mainContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        flexDirection: "row"
    },

    nextWrapper: {
        backgroundColor: "rgb(15, 17, 67)",
        padding: 12,
        borderRadius: 25,
        width: 160,
        marginTop: 10,
    },
    nextText: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: responsiveText(16),
    },
    btnText: {
        fontSize: 18,
        textAlign: "center",
        flex: 1,
        fontWeight: "bold",
        paddingHorizontal: 16,
        paddingVertical: 12,
    },
    textT: {
        fontSize: mobilBrower() ? responsiveText(18) : 25,
        textAlign: "center",
        flex: 1,
        lineHeight: 55,
        marginBottom: 20,
        fontWeight: "bold"
    },
    excludedTitle: {
        fontSize: responsiveText(20),
        textAlign: "center",
        width: "80%",
        lineHeight: 55,
        alignSelf: 'center',
        marginBottom: 20,
    },
    visaContainer1: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 10,
        width: "90%",
        height: mobilBrower() ? 160 : 180,
        marginLeft: "5%",
        borderRadius: 15,
        borderColor: "rgb(230,230,230)",
        borderWidth: 1,
    },
    divider: {
        width: 2,
        marginHorizontal: 5,
        height: "100%",
        backgroundColor: "#00000020"
    },
    visaContainer: {
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 10,
        width: mobilBrower() ? widthPercentageToDP(70) : 300,
        // width: `${100 / 3.2}%`,
        marginHorizontal: 5,
        height: 150,
        borderRadius: 15,
        borderWidth: 1,
    },
    visaContent1: {
        width: "55%",
        justifyContent: "space-between",
        height: "100%",
        padding: 15,
        backgroundColor: "#F5F5F5"
    },
    visaContent: {
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        padding: 15,
    },
    list: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 10,
        width: "90%",
        marginLeft: "5%",
        padding: 15,
        borderRadius: 15,
        borderColor: "rgb(230,230,230)",
        borderWidth: 1,
    },
    noDataText: {
        textAlign: 'center',
        fontSize: responsiveText(16)
    },
    univImage: {
        height: 70,
        width: 70,
        borderRadius: 15,
    },
    infoWrapper: {
        width: "60%",
        marginLeft: 15,
    },
    exIcon: {
        height: 30,
        width: 30,
    },
    imageView: {
        backgroundColor: 'white',
        alignItems: 'center',
        height: 85,
        width: 85,
        borderWidth: 0.5,
        borderColor: '#BDBDBD',
        justifyContent: 'center'
    },
    univIcon: {
        fontSize: responsiveText(18),
        color: '#800000'
    },

    scoreView: {
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        marginLeft: -15,
        borderRadius: 20
    },
    scoreText: {
        fontSize: responsiveText(16),
    },
    visaText: {
        fontSize: responsiveText(16),
        fontWeight: "bold",
        marginBottom: 5
    },
    univNameText: {
        fontSize: responsiveText(16),
    },
    locationInfoText: {
        marginTop: 2
    },
    infoBtn: {
        backgroundColor: "#fff",
        elevation: 5,
        width: 26,
        height: 26,
        borderRadius: 13,
        borderWidth: 1,
        borderColor: "rgb(230,230,230)",
        justifyContent: "center",
        alignItems: "center"
    },
    notifItemContainer: {
        overflow: 'hidden',
        flex: 1,
    },
    visaModalContent: {
        width: !mobilBrower() ? "37vw" : "90vw",
        height: "100%"
    },
    detailsCard: {
        width: "90%",
        alignSelf: "center",
        marginTop: 35,
        flex: 1,
        borderRadius: 10,
        borderColor: "#BDBDBD",
        padding: 10,
    },
    iconV: {
        height: 70,
        width: 70,
        marginTop: -45,
        borderColor: "rgb(230,230,230)",
        borderWidth: 3,
        borderRadius: 50,
    },
    details: {
        fontSize: 16
    },
    detailsText: {
        fontWeight: "bold",
        marginVertical: 10,
        fontSize: 16
    },
    bullet: {
        height: 15,
        width: 15,
        marginTop: 3,
        marginRight: 10,
    },
    description: {
        fontSize: 16,
        width: "95%",
        textAlign: "justify",
    },
    detailWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        marginBottom: 5,
    },
    detailContentWrapper: {
        width: "90%",
    },
    detailsTitle: {
        fontSize: 16
    },
    actionButton: {
        backgroundColor: "#030135",
        alignItems: "center",
        borderRadius: 5,
        padding: 15,
        flex: 1,
        alignSelf: "center",
        margin: 10,
        justifyContent: "center",
    },
    shortText: {
        fontSize: 15,
        color: "white",
    },
    stepLabel: {
        fontSize: 15,
        textAlign: "left",
        marginTop: 5,
        color: "#030135",
    },
    stepLabelSelected: {
        fontSize: 15,
        textAlign: "left",
        marginTop: 5,
        color: "#030135",
        fontWeight: "bold",
    },
    closeButton: {
        marginRight: 15,
        alignSelf: "flex-end",
        marginTop: 10,
    },
    componentView: {
        alignItems: "center",
        marginTop: 10,
        marginBottom: 20,
        width: "80%",
        alignSelf: "center",
    },
    componentTitle: {
        fontSize: responsiveText(19),
        color: "#030135",
        fontWeight: "bold"
    },
    componentdescription: {
        marginTop: 10,
        fontSize: 15,
        // width: "90%",
        textAlign: "center"
    },
    metaDataView: {
        flexDirection: "row",
        alignSelf: "center",
        width: "85%",
        marginVertical: 20
    },
    secretModal: {
        width: mobilBrower() ? "80%" : "40%",
        height: mobilBrower() ? "50%" : "40%",
        backgroundColor: "#fff",
        borderRadius: 20,
        padding: 20
    },
    input: {
        paddingHorizontal: 15,
        paddingVertical: 12,
        flex: 1,
        marginLeft: 0,
        width: "100%",
    },
    inputBox: {
        width: "90%",
        backgroundColor: "#F5F6F8",
        borderRadius: 5,
        flexDirection: "row",
        borderWidth: 2,
        marginVertical: 15,
        borderColor: "#BDBDBD",
        alignItems: "center",
    },
    flexRow: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 10
    },
    footer: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 10,
        justifyContent: "center",
        backgroundColor: "#F5F5F5"
    },
    badge: {
        height: 36,
        width: 36,
        borderRadius: 18,
        borderWidth: 1,
        borderColor: "grey",
        alignItems: "center",
        justifyContent: "center",
    },
    badgeText: {
        fontSize: 18,
        fontWeight: "500",
        color: "grey"
    }
});
