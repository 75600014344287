import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { heightPercentageToDP } from "react-native-responsive-screen";
import fontFamily from "../../../common/fontFamily";
import Images from "../../../common/images";
import { ReactionInfo } from "../ReactionInfo/ReactionInfo";

export const Main = ({ data, discussion_id, totalComments, itemInView }) => {

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getCategoryName = (name) => {
    if(name?.split('_').length > 0) {
      return name?.split('_').map((s) => capitalizeFirstLetter(s)).join(' ')
    }
  }

  return (
    <TouchableOpacity
    activeOpacity={1}
      style={{
        padding: 25,
        borderWidth: 1,
        borderColor: "rgb(220,220,220)",
        backgroundColor: '#F6F6F6',
        borderRadius: 15,
        marginBottom: 5,
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 5,
          width: "105%",
        }}
      >
        <Text
          style={{
            color: "#33AC09",
            fontFamily: fontFamily.titilliumBold,
            fontSize: 17,
            textTransform: "capitalize",
          }}
        >
          {getCategoryName(itemInView?.category)} {"->"} {itemInView?.sub_category}
        </Text>
        <Text>{data?.date_asked?.slice(5, 16)}</Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 12,
        }}
      >
        <Text
          style={{
            color: "gray",
          }}
        >
          Posted by {data?.message_author}
        </Text>
      </View>
      <Text
        style={{
          fontFamily: fontFamily.roboto,
          fontSize: heightPercentageToDP('1.8%'),
          fontWeight: "bold",
          width: "80%",
          marginBottom: 7,
        }}
      >
        {itemInView?.topic}
      </Text>
      <Text>{data?.text?.split(']')[1]}</Text>
      <ReactionInfo
        totalComments={totalComments}
        discussion_id={discussion_id}
        displayCommentCount={true}
        item={data}
      />
    </TouchableOpacity>
  );
};
