import { StyleSheet, Platform } from "react-native";
import { normalize, family } from "../../common";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";
import { heightPercentageToDP, widthPercentageToDP } from "../../common/responsiveScreen";

export const styles1 = StyleSheet.create({
  mainView: {
    height: heightPercentageToDP(100),
    width: widthPercentageToDP(100),
    backgroundColor: "#222222c7",
    alignItems: "center",
    zIndex: 9999,
    position: "absolute",
    justifyContent: "center",
  },
  buttonView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
    justifyContent: "space-between",
    width: "60%",
  },
  availDisContainer: {
    flexDirection: "row",
    marginBottom: 15,
    alignItems: "center",
    overflow: "hidden",
    paddingTop: 10,
    width: "100%",
  },
  availDisContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 10,
  },
  availBut: {
    borderRadius: 10,
    paddingTop: 5,
    backgroundColor: "#030135",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 5,
    width: mobilBrower() ? 100 : 70,
  },
  butText: {
    color: "white",
    fontSize: heightPercentageToDP("1.7%"),
    fontFamily: family.semibold,
  },
  packageDesc: {
    fontSize: responsiveText(12),
    fontFamily: family.robotoRegular,
  },
  innerView: {
    backgroundColor: "white",
    width: !mobilBrower() ? "40%" : "85%",
    justifyContent: "center",
    marginTop: 10,
    alignSelf: !mobilBrower() ? "flex-start" : "center",
    paddingBottom: 20,
    alignItems: "center",
    borderRadius: 10,
    marginLeft: 20,
  },
  balanceView: {
    alignItems: "center",
  },
  packageTitleText: {
    fontSize: responsiveText(18),
    fontFamily: family.bebasRegular,
    fontWeight: "500",
  },
  linethrough: {
    textDecorationLine: "line-through",
  },
  packagePriceText: {
    fontSize: responsiveText(18),
    fontFamily: family.bebasRegular,
    fontWeight: "500",
  },
  balanceText: {
    color: "#030135",
    fontSize: heightPercentageToDP("1.5%"),
    fontFamily: family.semibold,
  },
  packageImg: {
    width: !mobilBrower() ? 200 : widthPercentageToDP(40),
    resizeMode: "contain",
    height: !mobilBrower() ? 200 : widthPercentageToDP(20),
  },
  packageDetails: {
    width: mobilBrower() ? "60%" : "40%",
    alignItems: "center",
    justifyContent: "space-around",
    height: !mobilBrower() ? 200 : widthPercentageToDP(40),
  },
  discount: {
    flexDirection: "row",
    marginTop: 30,
    alignItems: "center",
  },
  discount_title: {
    color: "#030135",
    fontSize: normalize(40),
    fontFamily: family.semibold,
  },
  modalInnerView: {
    width: "100%",
    borderRadius: 20,
  },
  redeemIcon: {
    height: mobilBrower() ? heightPercentageToDP("1.6%") : 18,
    marginRight: 10,
    marginLeft: 10,
    width: mobilBrower() ? heightPercentageToDP("1.6%") : 18,
  },
  checkBox: {
    height: mobilBrower() ? heightPercentageToDP("1.6%") : 18,
    marginRight: 10,
    width: mobilBrower() ? heightPercentageToDP("1.6%") : 18,
    marginLeft: 10,
    borderRadius: mobilBrower() ? heightPercentageToDP("1.6%") / 2 : 18 / 2,
    borderWidth: 2,
    borderColor: "#D8D8D8",
  },
  packageCard: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
    flex: 1,
    shadowOffset: { width: 0.2, height: 0.2 },
    shadowColor: "#000",
    shadowRadius: 2,
    marginVertical: 5,
    shadowOpacity: 0.5,
    marginHorizontal: 5,
  },
  packageImg: {
    width: "100%",
    height: !mobilBrower() ? 200 : 150,
    resizeMode: "contain",
  }
});
