import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Modal,
  FlatList,
  Linking,
} from "react-native";
import { Images } from "../../../../common";
import helpers, { isWeb } from "../../../../common/helpers";
import { styles } from "./ChartDetailModal.styles";
import BlurBackground from "../../../BlurBackground";
import { getFactValue } from "../../../../utils/localityFactsDisplay.utils";
import InAppBrowser from 'react-native-inappbrowser-reborn'

const ChartDetailModal = ({
  data,
  onClose,
  selectedCurrency,
  selectedItemSource,
}) => {

  const handleLinking = async () => {
    if (!isWeb() && await InAppBrowser.isAvailable()) {
      try {
        const result = await InAppBrowser.open(selectedItemSource, helpers.inAPPbrowserOption)
      } catch (error) {
        await InAppBrowser.close()
      }
    } else {
      Linking.openURL(selectedItemSource)
    }
  }

  return (
    <Modal transparent animationType="fade">
      <BlurBackground />
      <View style={styles.mainView}>
        <View style={styles.innerView}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <TouchableOpacity
              hitSlop={{ left: 15, right: 15, top: 15, bottom: 15 }}
              onPress={() => onClose()}
            >
              <Image
                source={Images.cancelGrey}
                style={{ height: 13, width: 13 }}
              />
            </TouchableOpacity>
          </View>
          <Text
            style={[
              styles.itemTitle,
              {
                marginBottom: 20,
                marginTop: 5,
              },
            ]}
          >
            {data ? data.fact : ""}
          </Text>

          <FlatList
            data={Object.entries(data)}
            style={{ marginBottom: 20, width: "95%" }}
            renderItem={({ item, index }) => {
              if (data.fact !== "Coronavirus Vaccinations") {
                return (
                  <View
                    style={[
                      styles.mainItem,
                      { marginTop: index == 1 ? -20 : 10 },
                    ]}
                  >
                    <Text style={styles.itemTextTitle}>
                      {item[0] !== "fact" ? `${index + 1}. ${item[0]} :` : ""}
                    </Text>
                    {data.fact == "Selected Job Salary" ||
                      data.fact == "Average Monthly Net Salary (After Tax)" ? (
                      <Text style={styles.itemText}>
                        {item[0] !== "fact"
                          ? helpers.getCurrencySymbol(selectedCurrency) +
                          " " +
                          helpers.convertFormat(
                            Math.round(
                              helpers.currencyConverter(
                                selectedCurrency,
                                item[1]
                              )
                            ),
                            selectedCurrency
                          )
                          : ""}
                      </Text>
                    ) : (
                      <Text style={styles.itemText}>
                        {item[0] !== "fact"
                          ? isNaN(item[1])
                            ? item[1]
                            : data.fact.includes("Index")
                              ? item[1]
                              : helpers.convertFormat(item[1], selectedCurrency)
                          : ""}
                      </Text>
                    )}
                    {data.fact.includes("Index") ? (
                      <Text style={styles.itemText}>
                        {item[0] !== "fact"
                          ? "(" + getFactValue(data.fact, item[1]) + ")"
                          : ""}
                      </Text>
                    ) : (
                      <View />
                    )}
                  </View>
                );
              } else {
                return (
                  <View
                    style={[
                      styles.mainItem,
                      { marginTop: index == 1 ? -30 : 10 },
                    ]}
                  >
                    <View style={{ alignItems: "center", flex: 1, marginRight: 5, }}>
                      {index == 0 ? (
                        <Text
                          style={[
                            styles.itemText,
                            { textDecorationLine: "underline" },
                          ]}
                        >
                          {"Daily Vaccinations"}
                        </Text>
                      ) : (
                        <View />
                      )}
                      <Text style={styles.itemText}>
                        {item[0] !== "fact" ? item[0] : ""}
                      </Text>
                      {
                        <Text style={styles.itemText}>
                          {helpers.convertFormat(
                            item[1].daily_vaccinations,
                            selectedCurrency
                          )}
                        </Text>
                      }
                    </View>
                    <View
                      style={{
                        alignItems: "center",
                        flex: 1,
                        marginLeft: 5,
                      }}
                    >
                      {index == 0 ? (
                        <Text
                          style={[
                            styles.itemText,
                            { textDecorationLine: "underline" },
                          ]}
                        >
                          {"Fully Vaccinated"}
                        </Text>
                      ) : (
                        <View />
                      )}
                      <Text style={styles.itemText}>
                        {item[0] !== "fact" ? item[0] : ""}
                      </Text>
                      {
                        <Text style={styles.itemText}>
                          {helpers.convertFormat(
                            item[1].people_fully_vaccinated,
                            selectedCurrency
                          )}
                        </Text>
                      }
                    </View>
                  </View>
                );
              }
            }}
          />
          {selectedItemSource ? (
            <TouchableOpacity
              style={{
                marginTop: 10,
                backgroundColor: "#030135",
                width: "100%",
                padding: 15,
                borderRadius: 8,
              }}
              onPress={() => handleLinking()}
            >
              <Text style={[styles.sourceText]}>View Source</Text>
            </TouchableOpacity>
          ) : (
            <View />
          )}
        </View>
      </View>
    </Modal>
  );
};

export default ChartDetailModal;
