import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import {
  getMetaDataDate,
  getBackgroundColor,
} from "../../../../../utils/localityFactsDisplay.utils";
import * as Animatable from "react-native-animatable";
import { colors } from "../../../../../common";
import BarChartComp from "../../BarChart/BarChart";
import Icon from "react-native-vector-icons/Entypo";
import { styles } from "../localityFactsDisplay.styles";
import { isWeb } from "../../../../../common/helpers";

export const FactTypeB = ({
  item,
  handleShowRaw,
  scroll,
  metaData,
  selectedCities,
  value,
}) => {
  let label = [];
  let datasets = [
    {
      data: [],
      colors: [],
    },
  ];
  let label2 = [];
  let datasets2 = [
    {
      data: [],
      colors: [],
    },
  ];
  Object.entries(item).forEach((s) => {
    s[0] !== "fact" && label.push(s[0]);
    s[0] !== "fact" && datasets[0].data.push(s[1].daily_vaccinations);
    s[0] !== "fact" &&
      datasets[0].colors.push(getBackgroundColor(item.fact, value));
  });
  Object.entries(item).forEach((s) => {
    s[0] !== "fact" && label2.push(s[0]);
    s[0] !== "fact" && datasets2[0].data.push(s[1].people_fully_vaccinated);
    s[0] !== "fact" &&
      datasets2[0].colors.push(getBackgroundColor(item.fact, value));
  });
  return (
    <Animatable.View animation="fadeIn" duration={500}>
      <TouchableOpacity
        activeOpacity={1}
        style={{ alignSelf: "center", marginTop: 5 }}
      >
        <BarChartComp
          scroll={scroll}
          label={label}
          title={"Daily Vaccinations"}
          datasets={datasets}
        />
      </TouchableOpacity>
      <TouchableOpacity
        activeOpacity={1}
        style={{ alignSelf: "center", marginTop: 5 }}
      >
        <BarChartComp
          scroll={scroll}
          label={label2}
          title={"Fully Vaccinated"}
          datasets={datasets2}
        />
      </TouchableOpacity>
      {selectedCities > Object.entries(item).length - 1 ? (
        <Text style={{ alignSelf: "center", color: "red", marginTop: 5 }}>
          Data not available for some cities.
        </Text>
      ) : (
        <View />
      )}

      <View style={styles.metaDataView}>
        {getMetaDataDate(item.fact, metaData)?.last_updated ? (
          <Text style={styles.metaDataView_date}>
            {"Last updated: " +
              getMetaDataDate(item.fact, metaData)?.last_updated}
          </Text>
        ) : (
          <View />
        )}
        <TouchableOpacity onPress={() => handleShowRaw(item)}>
          {isWeb() ? <i className="fa fa-info-circle" style={{ color: colors.LIGHT_BLUE, fontSize: 20 }} /> :
            <Icon name={"info-with-circle"} color={colors.LIGHT_BLUE} size={20} />
          }
        </TouchableOpacity>
      </View>
    </Animatable.View>
  );
};
