import React from "react";
import { View, Text } from "react-native";
import { styles } from "../quickSearch.styles";
import Svg, { G } from "react-native-svg";
import mapPath from "./mapData.json";
import { AnimatedPath } from "./AnimatedPath";
import { heightPercentageToDP } from "../../../common/responsiveScreen";
import { isWeb } from "../../../common/helpers";

export const mapStyles = {
  regionSelectedBackground: "#f8d7da",
  regionDefaultBackground: "rgb(240,240,240)",
  strokeSelectedColor: "red",
  strokeDefaultColor: "rgb(180,180,180)",
  strokeWidth: 30,
  strokeDasharray: 100000.0,
};

export const RegionMap = ({
  selectedRegions = [], onRegionSelect = () => { }
}) => {

  const getRegionConfig = (region) => {
    const bool = selectedRegions.includes(region)
    return {
      bool,
      params: {
        fill: bool
          ? mapStyles.regionSelectedBackground
          : mapStyles.regionDefaultBackground,
        stroke: bool
          ? mapStyles.strokeSelectedColor
          : mapStyles.strokeDefaultColor,
        strokeWidth: mapStyles.strokeWidth,
        strokeDasharray: mapStyles.strokeDasharray,
      },
    };
  };

  return (
    <View style={styles.worldContainer}>
      <Text style={styles.title}>Select Region</Text>
      <View style={{ height: heightPercentageToDP(28), width: "100%", marginTop: 15 }}>
        {!isWeb() ?
          <>
            <View
              onTouchStart={() => onRegionSelect("America")}
              style={[styles.americaOverlay]}
            />
            <View
              onTouchStart={() => onRegionSelect("Australia")}
              style={[styles.austOverlay]}
            />
            <View
              onTouchStart={() => onRegionSelect("Asia")}
              style={[styles.asiaOverlay]}
            />
          </> : null}
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1280.000000 640.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <G
            transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
            fill="rgb(220,220,220)"
            stroke="none"
          >
            {Object.keys(mapPath).map((region, index) => (
              <G onClick={() => { onRegionSelect(region) }} key={index + "r"} >
                {mapPath[region].map((path, index) => {
                  const mainConfig = getRegionConfig(region);
                  const configs = {
                    ...mainConfig.params,
                    d: path,
                  };
                  return (
                    <AnimatedPath
                      key={index}
                      configs={configs}
                    />
                  );
                })}
              </G>
            ))}
          </G>
        </Svg>
      </View>
    </View>
  );
};
