import React from "react";
import { View, ScrollView, Text } from "react-native";
import { Bullet } from "../Bullets/Bullets";
import { styles } from "./BulletsWrapper.styles";

export const BulletsWrapper = ({ arr, data, updateSettings, maxSelection }) => {

  const component = (arr) => {
    return (
      <View style={styles.bulletContainer}>
        {arr.length > 0 && arr.map((e) => {
          return (
            <Bullet
              text={e}
              data={data}
              updateSettings={updateSettings}
              maxSelection={maxSelection}
            />
          );
        })}
      </View>
    );
  };

  return (
    <ScrollView
      style={{
        margin: 5,
      }}
    >
      <View
        style={{
          width: "90%",
          marginLeft: "5%",
        }}
      >
        {arr[0] && arr[0][1] && Array.isArray(arr[0][1])
          ? arr.map((ea) => {
              return (
                <View>
                  <Text style={styles.title}>
                    {ea[0]}
                  </Text>
                  {component(ea[1])}
                </View>
              );
            })
          : component(arr)}
      </View>
    </ScrollView>
  );
};
