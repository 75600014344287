const meta = {
  localityComparator: 'Compare cities with upto 15 facts. Make the best decisions for your next travel',
  immipathSearch: 'Your personalised Path Search - Compare, analyse and shortlist your opportunities in your immigration journey.',
  creditBuilder: 'Credit scores can be tricky, especually when you are new to a country. Find out how to build your credit score.',
  costOfLiving: 'Personalized cost of living calculator with multiple metrics to calculate the most accurate cost.',
  jobsForYou: 'Explore jobs at foreign countries.',
  prcalculator: 'Calculate PR points for multiple countries in one go.',
  chooseImmipath: 'Look up visas, their basic information and timeline.',
  immistore: 'Get travel accessories and merchendise.',
  languageFeature: 'Learn important phrases to help you survive at non-english speaking countries.',
  adviceChat: 'Get help on your next immigration journey, whatever the problem you maybe facing. All queries are answered by validated expert consultants.',
  media: 'Stay up to date on the latest immigration news and stories, in the form of both articles and videos.',
  dashboard: 'Immigreat to where your compass points.',
  QuickSearch: 'Look up visas, their basic information and timeline.',
}

export default meta;