import { StyleSheet } from "react-native";
import { mobilBrower } from "../../common/helpers";
import { heightPercentageToDP } from "../../common/responsiveScreen";

export const styles = StyleSheet.create({
  main: {
    flex: 1,
    width: mobilBrower() ? "100%" : "65%",
    alignSelf: "center",
  },
  mainWrapper: {
    backgroundColor: "#fff",
    height: mobilBrower() ? heightPercentageToDP(100) : heightPercentageToDP(96)
  },
  mainInnerView: {
    backgroundColor: "#F5F5F5",
    overflow: "hidden",
    width: mobilBrower() ? "100%" : "116%",
    height: mobilBrower() ? "100%" : "107%",
    marginTop: mobilBrower() ? 0 : "-3%",
    marginLeft: mobilBrower() ? 0 : "-8%",
    borderRadius: mobilBrower() ? 0 : 45,
    // padding: mobilBrower() ? 0 : 10,
    zIndex: 1
  },
  indicaterWrapper: {
    bottom: 15,
    zIndex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "absolute",
  },
  indicator: {
    width: 25,
    height: 3,
    marginRight: 2,
  },
  currencyDropDown: {
    alignItems: 'flex-end'
  },
  arrowWrapper: {
    height: 48,
    width: 70,
    alignItems: "center",
    justifyContent: "center"
  },
  saveWrapper: {
    height: 38,
    width: 60,
    marginTop: 15,
    marginBottom: 15,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    zIndex: 10,
  },
  arrow: {
    height: "40%",
    width: "40%"
  },

  header: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%",
    margin: 10,
    zIndex: 1
  },

  filterIcon: {
    height: 30,
    width: 30,
  },
});
