import React, { useState } from "react";
import { View, Text, TouchableOpacity, TouchableHighlight } from "react-native";
import { styles1 } from "../cards.styles";
import { addUserReactions } from "../../../../../../../actions/mainMedia.actions";
import { firebaseEvents, logEvent } from "../../../../../../../common/firebaseEvents";
import { useSelector, useDispatch } from "react-redux";
import { helpers } from "../../../../../../../common";
import { TrailEase } from "../../../../../animations/TrailEase";
import { useSpring, animated as a } from "@react-spring/native";

export const MyReaction = ({ item, index }) => {
  const reactionByUser = useSelector(
    (state) => state.mainMediaReducer.gState.reactions
  )[item.story_id ?? item.news_id]?.reaction_type;

  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );

  const [picker, setPicker] = useState(false);

  const activeTab = useSelector(
    (state) => state.mainMediaReducer.gState.activeTab.title
  );
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);

  const dispatch = useDispatch();

  const handleReactions = async (reactionCode) => {
    logEvent(firebaseEvents.REACTION_NEWS, {
      message: "User reacted to the News",
      reaction_code: reactionCode,
      news_id: item.news_id,
    });
    await dispatch(
      addUserReactions(
        item,
        userDetails?.userid,
        token,
        reactionCode,
        activeTab,
        index
      )
    );
    setPicker(false);
  };

  const getEmoji = () => {
    let symbol = "";
    helpers.emoji.map((s) => {
      if (reactionByUser == s.code) {
        symbol = s.symbol;
      } else {
        return null;
      }
    });
    return symbol;
  };

  const scaleToFull = useSpring({
    width: picker ? 232 : 0,
    height: picker ? 55 : 0,
    marginLeft: picker ? 0 : 202,
  });

  return (
    <View style={[styles1.emojiView]}>
      {picker && !guestLogin ? (
        <View
          style={[
            styles1.emojiContanier,
            {
              transform: [
                {
                  translateX: reactionByUser ? -90 : -72,
                },
              ],
              bottom: reactionByUser ? 32 : 28,
            },
          ]}
        >
          <a.View style={[styles1.innerEmojiView, scaleToFull]}>
            <TrailEase offset={50}>
              {helpers.emoji.map((s, index) => (
                <TouchableHighlight
                  key={s.code}
                  onPress={() => handleReactions(s.code)}
                  underlayColor="#cee9ea"
                  style={[
                    styles1.emoji,
                    {
                      backgroundColor:
                        reactionByUser == s.code ? "#cee9ea" : "transparent",
                      // borderRightWidth: index !== 4 ? 0.5 : 0,
                      marginRight: 2,
                      marginLeft: 2,
                    },
                  ]}
                >
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text
                      style={{
                        fontSize: 20,
                        marginLeft: 5,
                      }}
                    >
                      {s.symbol}
                    </Text>
                  </View>
                </TouchableHighlight>
              ))}
            </TrailEase>
          </a.View>
        </View>
      ) : (
        <View />
      )}
      {reactionByUser ? (
        <TouchableOpacity
          style={styles1.reactedEmoji}
          onPress={() => setPicker((prev) => !prev)}
        >
          <Text
            style={{
              fontSize: 23,
            }}
          >
            {getEmoji()}
          </Text>
        </TouchableOpacity>
      ) : !guestLogin ? (
        <TouchableOpacity
          style={styles1.emojiSticker}
          onPress={() => setPicker((prev) => !prev)}
        >
          <Text
            style={{
              fontSize: 23,
            }}
          >
            {`${helpers.emoji[3].symbol}${helpers.emoji[1].symbol}`}
          </Text>
        </TouchableOpacity>
      ) : (
        <View />
      )}
    </View>
  );
};
