import { Platform, StyleSheet } from "react-native";
import fontFamily from "../../../../../../common/fontFamily";
import { colors } from "../../../../../../common";
import { responsiveText } from "../../../../../../common/normalize";

export const styles1 = StyleSheet.create({
  cardContainer:{
    position: "relative",
    marginVertical:10,
  },
  cardWrapper: {
    backgroundColor: "#EEF2F5",
    width: "90%",
    flexDirection: "row",
    marginLeft: 8,
    marginRight: 10,
    alignSelf: "center",
    shadowColor: "#000000",
    shadowOpacity: 0.3,
    shadowRadius: 1,
    shadowOffset: {
      height: 1.5,
      width: 0.3,
    },
    position: "relative"
  },
  contentView: {
    width: "55%",
    height:"100%"
  },
  tagText:{
    color: colors.LIGHT_BLUE,
    fontFamily: "SourceSansPro-Regular",
    fontSize: responsiveText(11),
  },
  emojiContanier: {
    marginRight: 0,
    flexDirection: "column",
    alignItems: "flex-start",
    borderColor: "lightgray",
    justifyContent: "space-around",
    position: "absolute",
  },
  imgView: {
    width: "45%",
    position:"relative",
  },
  imgStyle: {
    width: "100%",
    height: "100%",
  },
  newReactionCountView: {
    height: 33,
    backgroundColor: "#DFDFDF",
    position: "absolute",
    bottom: -17,
    borderWidth: Platform.OS == "android" ? 0.5 : 0,
    shadowColor: "#000",
    shadowRadius: 2,
    shadowOpacity: 0.5,
    shadowOffset: { width: 0.5, height: 0.5 },
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    left: 5,
    paddingRight: 5,
  },
  totalReactionText: {
    marginLeft: 5,
    marginRight: 5,
  },
  tagsContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
    marginTop: 10,
  },
  tagView: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
    marginTop: 10,
    width: "80%"
  },
  itemTitle:{
    color: "#000",
    fontSize: responsiveText(13),
    fontWeight: "bold",
    fontFamily: "Arial",
  },
  readMoreButton: {
    paddingVertical:8,
    paddingHorizontal:12,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0F1143",
  },
  shareView: {
    flexDirection: "row",
    alignItems: "center",
  },
  readMoreButtonText: {
    color: "white",
    fontSize:responsiveText(12),
    fontFamily: fontFamily.regular,
  },
  emojiView: {
    position: "absolute",
    bottom: -10,
    alignItems: "center",
    justifyContent: "center",
    right: 10,
  },
  faviconStyle: {
    width: 20,
    height: 20,
    resizeMode: "contain",
  },
  dateText: {
    fontFamily: fontFamily.regular,
    marginTop: 3,
    fontSize: responsiveText(10),
    color: "black",
  },
  sourceText: {
    fontFamily: fontFamily.regular,
    fontSize: responsiveText(11),
    color: "rgb(75,75,75)",
    fontWeight: "700",
  },
  innerEmojiView: {
    shadowOffset: {
      width: 0,
      height: 1,
    },
    elevation: 12,
    alignItems: "center",
    padding: 5,
    borderRadius: 5,
    flexDirection: "row",
    borderColor: "lightgray",
    borderWidth: 0.5,
    shadowColor: "lightgray",
    backgroundColor: "#FEFEFE",
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    overflow: "hidden",
  },
  emoji: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    borderRadius: 4,
    borderColor: "rgb(220,220,220)",
  },
  emojiSticker: {
    borderWidth: 0.5,
    height: 40,
    width: 75,
    backgroundColor: "#DFDFDF",
    bottom: -10,
    borderRadius: 25,
    shadowColor: "#000",
    shadowRadius: 2,
    shadowOpacity: 0.5,
    shadowOffset: { width: 0.5, height: 0.5 },
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    right: -5,
  },
  bottomTab: {
    flexDirection: "row",
    marginTop: 10,
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  reactedEmoji: {
    width: 40,
    height: 40,
    backgroundColor: "#DFDFDF",
    bottom: -6,
    borderRadius: 25,
    shadowColor: "#000",
    shadowRadius: 2,
    borderWidth: 0.5,
    shadowOpacity: 0.5,
    shadowOffset: { width: 0.5, height: 0.5 },
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },

  tagsWrapper: {
    marginRight: 5,
    padding: 5,
    borderRadius: 25,
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    borderColor: colors.LIGHT_BLUE,
  },
  textSummary: {
    fontSize: responsiveText(12),
    color: "#000",
    textAlign: "left",
    fontFamily: fontFamily.regular,
  },
});

export const styles2 = StyleSheet.create({
  videoView: {
    width: "90%",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    borderRadius: 8,
    borderWidth: 0.5,
    shadowColor: "#000000",
    shadowOpacity: 0.3,
    shadowRadius: 1,
    shadowOffset: {
      height: 1.5,
      width: 0.3,
    },
    elevation: 5,
    flexDirection: "row",
    backgroundColor: "#EEF2F5",
    borderColor: "#85929E",
    padding: 15,
    marginLeft: -5,
  },
  imgView: {
    width: "45%",
  },
  contentView: {
    width: "55%",
    height:"100%"
  },
  tagView: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
    marginTop: 10,
  },
  thumbnail: {
    alignItems: "center",
    borderRadius: 10,
    justifyContent: "center",
  },
});
