import { StyleSheet } from "react-native";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";

export const styles = StyleSheet.create({
  checkBox: {
    height: 14,
    width: 14,
    borderWidth: 1,
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  indexName: {
    marginLeft: 15,
    fontSize: responsiveText(16),
  },
  proceedButton: {
    width: "90%",
    borderRadius: 5,
    padding: 15,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginBottom: 5,
    backgroundColor: "#030135",
    marginTop: 10,
  },
  dropDownView: {
    width: "90%",
    borderRadius: 5,
    backgroundColor: "#E7EAED",
    marginTop: 20,
    marginLeft: "5%",
    padding: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
    alignItems: "center",
  },
  proceedText: {
    color: "white",
    fontSize: responsiveText(16),
  },
  orText: {
    alignSelf: "center",
    fontSize: responsiveText(16),
    marginTop: 5,
    marginBottom: 5,
  },
  listView: {
    width: mobilBrower() ? "100%" : "70%",
    alignSelf: "center",
    height: "86%",
    backgroundColor: "white",
    marginBottom: 40,
  },
  checkBoxItems: {
    marginTop: 10,
    flex: 1,
    flexDirection: "row",
    width: "90%",
    marginLeft: "5%",
  },
  checkView: {
    height: 10,
    width: 10,
    borderRadius: 2,
    backgroundColor: "green",
  },
  backlButton: {
    height: 20,
    width: 20,
    borderRadius: 10,
    alignItems: "center",
    backgroundColor: "#030135",
    marginTop: 10,
    marginLeft: 20,
    justifyContent: "center",
  },
});
