import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  FlatList,
  Platform,
} from "react-native";
import { normalize, Images, family } from "../common";
import { Network, apiPaths } from "../service/api";
import { useDispatch, useSelector } from "react-redux";
import GetMoreCityModal from "./../components/GetMoreCityModal";
import { getImmibuckBalance, getUnlocks } from "../actions/immibucks.actions";
import { TooltipComponent } from "../components/Tooltip/Tooltip";
import { StepNumberComponent } from "../common/WalkThroughable";
import { copilot } from "react-native-copilot";
import { logEvent } from "../actions/firebaselog.actions";
import * as RNIap from "react-native-iap";
import FaqAnswerModal from "../components/FaqAnswerModal";
import { mobilBrower } from "../common/helpers";
import downArrow from "../images/downArrow.png";
import { VscDebugBreakpointLog } from "react-icons/vsc";
import AlertModal from "../components/AlertModal/AlertModal";

const itemSkus = Platform.select({
  ios: ["basic_advice", "basic_advice_discount", "subscribe_monthly"],
  android: ["basic_advice_discount", "basic_advice_full"],
});

const height = Dimensions.get("window").width;

const AskQuestionComponent = (props) => {
  const token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const immiBuck_balance = useSelector(
    (state) => state.immibucksReducer.getImmibucksDetails.immibucks_balance
  );

  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );

  const [faqList, setFaqList] = useState({});

  const [Question, setQuestion] = useState("");
  const [specialitySelected, setspecialitySelected] = useState(null);
  const [categoriesList, setcategoriesList] = useState(null);
  const [selectedCategory, setselectedCategory] = useState(null);
  const [availDiscounModal, setavailDiscounModal] = useState(false);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [showpackage, setshowpackage] = useState(false);
  const [showNext, setshowNext] = useState(false);
  const [guest, setguest] = useState(false);
  const [openFaqModal, setopenFaqModal] = useState(false);
  const [selectedQuestion, setselectedQuestion] = useState(null);
  const [purchase, setpurchase] = useState(false);
  const [immibucksProduct, setimmibucksProduct] = useState(null);
  const [faqDropDown, setfaqDropDown] = useState({});
  const [inApp_Products, setinApp_Products] = useState([]);
  const [finalPackagePrice, setfinalPackagePrice] = useState(100);
  const [errorMessage, seterrorMessage] = useState(null);
  const [selectedPackage, setselectedPackage] = useState(null);
  const [showImmibuckPopUp, setshowImmibuckPopUp] = useState(false);
  const [insufficientBalance, setinsufficientBalance] = useState("");
  const adviceChannelQuideNext = useSelector(
    (state) => state.pathReducer.getImmipathDetails.adviceChannelQuideNext
  );
  const dispatch = useDispatch();

  const getFaqs = async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: guestLogin
        ? "guest@advice"
        : userDetails?.userid + "@" + token,
    };
    Network("/data/get_ac_faq", "GET", null, headers).then(async (res) => {
      if (res.status && res.status == "Success") {
        setFaqList(res.faqs);
      }
    });
  };

  useEffect(() => {
    let getImmibucksProducts = async () => {
      RNIap.initConnection()
        .catch((e) => { })
        .then(() => {
          RNIap.getProducts(itemSkus)
            .catch((qe) => { })
            .then((res) => {
              setinApp_Products(res);
              setimmibucksProduct(res);
            });
        });
    };
    getImmibucksProducts();
    return () => {
      try {
        RNIap.endConnection();
      } catch (error) { }
    };
  }, []);

  useEffect(() => {
    (async () => {
      await getFaqs();
    })();
  }, []);

  const handlePurchaseUpdate = async (purchase) => {
    if (purchase) {
      RNIap.acknowledgePurchaseAndroid(purchase.purchaseToken)
        .then(async () => {
          await RNIap.consumePurchaseAndroid(purchase.purchaseToken);
          setpurchase(true);
          RNIap.finishTransaction(purchase, true).catch((err) => { });
        })
        .catch((err) => {
          alert(err);
        });
    }
    purchaseErrorSubscription = purchaseErrorListener((error) => {
      console.log("purchaseErrorListener", error);
    });
  };

  useEffect(() => {
    if (Platform.OS !== "web" && !mobilBrower()) {
      purchaseUpdateSubscription = RNIap.purchaseUpdatedListener((purchase) =>
        handlePurchaseUpdate(purchase)
      );
      return () => {
        try {
          purchaseUpdateSubscription.remove();
        } catch (error) { }
      };
    }
  }, []);

  const clearState = () => {
    setpurchase(false);
    setQuestion("");
    setspecialitySelected(null);
    setselectedCategory(null);
    setshowNext(false);
    setavailDiscounModal(false);
    setfaqDropDown({});
    setselectedPackage(null);
    setfinalPackagePrice(100);
  };

  if (purchase) {
    const data = {
      agent_name:
        selectedPackage == "BASIC"
          ? "Immigreat Advice Support"
          : this.state.selectedAgent?.agent_name,
      avg_ratings: selectedPackage == "BASIC" ? "" : "",
      type: selectedPackage == "BASIC" ? "Consultant" : "",
      category: selectedCategory,
      parent_org: selectedPackage == "BASIC" ? "" : "",
    };

    logEvent("submit_advice_qn", {});
    const url = `/users/${userDetails?.userid}/create_discussion`;

    const headers = {
      Accept: "application/json",
      Authorization: token,
    };
    let selectedType =
      selectedPackage == "BASIC"
        ? "immigreat_consultant"
        : this.state.selectedAgent?.type;
    let typeId =
      selectedType.toLowerCase() === "lawyer"
        ? 1
        : selectedType.toLowerCase() === "immigreat_consultant"
          ? 3
          : 2;
    const payload = {
      discussion_platform_id: typeId,
      topic: Question,
      category: selectedCategory,
      text: Question,
      cc_user: "",
    };
    try {
      Network(url, "POST", payload, headers).then((res) => {
        if (res.status === "Success") {
          callAddApi(res);
          props.handleLoading(false);
          props._triggerMessageChat(res.chat_id, data, false, res);
          clearState();
        } else {
          // alert(res.status);
        }
      });
    } catch (error) {
      console.log("_____", error);
    }
  }

  const callPurchase = async (pId) => {
    try {
      pId =
        finalPackagePrice == 10
          ? "basic_advice_discount"
          : Platform.OS == "ios"
            ? "basic_advice"
            : "basic_advice_full";
      await RNIap.requestPurchase(pId, false);
    } catch (err) {
      console.warn(err.code, err.message);
    }
  };

  useEffect(() => {
    setQuestion(props.question);
  }, [props.question]);

  const openAvailDisModal = () => {
    setavailDiscounModal(true);
  };

  useEffect(() => {
    if (adviceChannelQuideNext) {
      props.start();
    }
  }, [adviceChannelQuideNext]);

  const _onAdviceSelection = (type) => {
    this.scroll.scrollToEnd();
    setspecialitySelected(type);
    let plateform_type = "";
    if (type === "Lawyer") {
      plateform_type = "lawyer";
    } else if (type === "Immigration Agent") {
      plateform_type = "immigration_agent";
    } else if (type === "Visa Specialist") {
      plateform_type = "visa_specialist";
    }

    const url = `/discussion_platforms/${plateform_type}`;
    const headers = {
      Accept: "application/json",
      Authorization: token,
    };
    Network(url, "GET", null, headers).then((res) => {
      if (res.status === "Success") {
        let categoriesArr = [];
        res.discussion_platforms[0].platform_categories.forEach((item) => {
          let categoriesObj = {
            name: item,
          };
          categoriesArr.push(categoriesObj);
        });
        setcategoriesList(categoriesArr);
      }
    });
  };

  const handleAskExpert = (question) => {
    if (guestLogin) {
      setshowErrorModal(true)
      return;
    }
    setopenFaqModal(false)
    props.setQuestion(question)
    setTimeout(() => {
      props.setInView()
    }, 1000);
  };

  const _onSubmit = () => {
    if (Question == "") {
      alert("Please enter your question! ");
      return;
    } else if (specialitySelected == "") {
      alert("Please select a category!");
      return;
    } else if (this.state.selectedCategory == "") {
      alert("Please select a topic ");
      return;
    }
    this.setState({ planView: !this.state.planView });
  };

  const selectCategory = (type) => {
    this.scroll.scrollToEnd();
    setselectedCategory(type);
  };

  const choosepackage = () => {
    setshowpackage(true);
  };

  const selectePackage = (index) => {
    if (index == 0) {
      setselectedPackage("BASIC");
    } else {
      setselectedPackage("PREMIUM");
    }
    setshowpackage(false);
  };
  const _iconRendere = ({ name, size = 12, style }) => {
    // flatten the styles
    const flat = StyleSheet.flatten(style);
    // remove out the keys that aren't accepted on View
    const { color, fontSize, ...styles } = flat;

    let iconComponent;
    // the colour in the url on this site has to be a hex w/o hash
    const iconColor =
      color && color.substr(0, 1) === "#" ? `${color.substr(1)}/` : "";

    const Down = (
      <Image
        source={Images.dropDownArrow}
        style={{ width: 16, height: 16, marginRight: 10 }}
      />
    );
    const Cancel = (
      <Image source={Images.cancel} style={{ width: 15, height: 15 }} />
    );
    const Up = (
      <Image
        source={Images.dropDownArrow}
        style={{
          width: 16,
          height: 16,
          marginRight: 10,
          transform: [{ rotateX: "180deg" }],
        }}
      />
    );
    switch (name) {
      case "keyboard-arrow-up":
        iconComponent = Up;
        break;
      case "keyboard-arrow-down":
        iconComponent = Down;
        break;
      case "cancel":
        iconComponent = Cancel;
        break;
      default:
        iconComponent = null;
        break;
    }
    return <View style={styles}>{iconComponent}</View>;
  };

  const _selectedValue4 = (item) => { };

  const _renderSelectText = (placeholder) => {
    return placeholder;
  };

  const _handleModalClick = () => {
    setshowImmibuckPopUp(false);
    setinsufficientBalance("");
  };

  const handleButtonClick = () => {
    if (selectedPackage) {
      Platform.OS == "ios"
        ? callPurchase()
        : props.askquestionmilestone
          ? callPurchase()
          : createDiscussion();
    } else {
      choosepackage();
    }
  };
  const createDiscussion = () => {
    clearState();
    const data = {
      agent_name:
        selectedPackage == "BASIC"
          ? "Immigreat Advice Support"
          : this.state.selectedAgent?.agent_name,
      avg_ratings: selectedPackage == "BASIC" ? "" : "",
      type: selectedPackage == "BASIC" ? "Consultant" : "",
      category: selectedCategory,
      parent_org: selectedPackage == "BASIC" ? "" : "",
    };

    if (Question === "") {
      alert("Please enter question");
      return;
    } else {
      logEvent("submit_advice_qn", {});
      const url = `/users/${userDetails?.userid}/create_discussion`;
      const headers = {
        Accept: "application/json",
        Authorization: token,
      };
      let selectedType =
        selectedPackage == "BASIC"
          ? "immigreat_consultant"
          : this.state.selectedAgent?.type;
      let typeId =
        selectedType.toLowerCase() === "lawyer"
          ? 1
          : selectedType.toLowerCase() === "immigreat_consultant"
            ? 3
            : 2;
      const payload = {
        discussion_platform_id: typeId,
        topic: Question,
        category: selectedCategory,
        text: Question,
        cc_user: "",
      };

      try {
        Network(url, "POST", payload, headers).then((res) => {
          if (res.status === "Success") {
            callAddApi(res);
            props.handleLoading(false);
            props._triggerMessageChat(res.chat_id, data, false, res);
          } else {
            // alert(res.status);
          }
        });
      } catch (error) {
        console.log("_____", error);
      }
    }
  };
  const makePurchase = () => {
    const url = userDetails?.userid + "/add";
    const balance_url = `/users/${userDetails?.userid}`;
    const headers = {
      Accept: "application/json",
      Authorization: token,
    };
    const body = {
      amount: 250,
      type: "debit",
      statement: `Advice Channel - ${Question}`,
      milestone: null,
    };
    if (Question == "") {
      alert("Please enter question");
      return;
    }
    props.handleLoading(true);
    if (immiBuck_balance >= 250) {
      Network(apiPaths.checkRewardMilestone + url, "POST", body, headers)
        .then((res) => {
          if (res.status === "Success") {
            createDiscussion();
            setshowImmibuckPopUp(false);
            dispatch(getImmibuckBalance(balance_url, headers));
            dispatch(getUnlocks(`${userDetails?.userid}/get_unlocks`, headers));
          } else {
            props.handleLoading(false);
          }
        })
        .catch((e) => {
          alert("Something went wrong!");
          props.handleLoading(false);
        });
    } else {
      setinsufficientBalance(
        "You do not have sufficient balance. Earn Immibucks by exploring the app more!"
      );
      props.handleLoading(false);
    }
  };

  const callAddApi = (res) => {
    const url = userDetails?.userid + "/add";
    const logPayUrl = `/users/${userDetails?.userid}/log_payment`;
    const balance_url = `/users/${userDetails?.userid}`;
    const headers = {
      Accept: "application/json",
      Authorization: token,
    };
    const paymentBody = {
      amount: 75,
      statement: `Advice Channel - ${res.ref_id}`,
    };
    const body = {
      amount: 100,
      type: "discount",
      statement: `Discount on Advice Channel - ${res.ref_id}`,
      milestone: null,
    };
    props.handleLoading(true);
    setpurchase(false);
    Network(logPayUrl, "POST", paymentBody, headers);
    Network(apiPaths.checkRewardMilestone + url, "POST", body, headers)
      .then((res) => {
        if (res.status === "Success") {
          dispatch(getImmibuckBalance(balance_url, headers));
        } else {
          props.handleLoading(false);
        }
      })
      .catch((e) => {
        alert("Something went wrong!");
        props.handleLoading(false);
      });
  };
  const handleFaqDropDown = (index) => {
    if (index == faqDropDown.index) {
      setfaqDropDown({});
      return;
    }
    setfaqDropDown({ index: index });
  };

  const handleQuestionCLick = (item) => {
    setselectedQuestion(item);
    setopenFaqModal(true);
  };

  return (
    <>
      <TouchableOpacity activeOpacity={1} style={{ height: "100%" }}>
        {
          <View
            style={[styles.askQuesView, { marginTop: 15, marginBottom: 40 }]}
          >
            <Text style={styles.title}>Frequently Asked Questions</Text>
            <FlatList
              data={
                Object.entries(faqList).length
                  ? Object.entries(faqList)[0][1]
                  : []
              }
              extraData={faqDropDown}
              style={{ marginBottom: 10 }}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  activeOpacity={0.5}
                  onPress={() => handleFaqDropDown(index)}
                >
                  <View style={styles.faqItems}>
                    <TouchableOpacity
                      style={{ alignItems: "center", flexDirection: "row" }}
                      hitSlop={{ right: 100 }}
                      onPress={() => handleFaqDropDown(index)}
                    >
                      {Platform.OS == "web" ? (
                        <VscDebugBreakpointLog
                          style={{ marginRight: 5, height: 15, width: 15 }}
                        />
                      ) : (
                        <View />
                      )}
                      <Text style={{ alignItems: "center" }}>{item.name}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => handleFaqDropDown(index)}>
                      <Image
                        source={
                          Platform.OS == "web"
                            ? downArrow
                            : Images.dropDownArrow
                        }
                        style={{
                          height: 15,
                          width: 15,
                          transform: [
                            {
                              rotate:
                                faqDropDown?.index == index
                                  ? "180deg"
                                  : "360deg",
                            },
                          ],
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                  {faqDropDown?.index == index ? (
                    <FlatList
                      data={item.children}
                      renderItem={({ item: item1 }) => (
                        <TouchableOpacity
                          onPress={() => handleQuestionCLick(item1)}
                        >
                          <View style={styles.faqItems}>
                            <Text style={{ color: "grey" }}>{item1.name}</Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    />
                  ) : (
                    <View />
                  )}
                </TouchableOpacity>
              )}
            />
          </View>
        }
      </TouchableOpacity>
      {
        <GetMoreCityModal
          description="Please confirm the below purchase:"
          button1="Confirm"
          question={Question}
          path={"advice"}
          selectedCategory={selectedCategory}
          selectedAdviceType={specialitySelected}
          button2="Cancel"
          price={250}
          insufficientBalance={
            insufficientBalance !== "" ? insufficientBalance : ""
          }
          successMessage={"Purchase Successfull"}
          handleButton1={makePurchase}
          visible={showImmibuckPopUp}
          _handleModalClick={_handleModalClick}
        />
      }
      {showErrorModal ? (
        <AlertModal
          title="Login"
          description="Login to explore more!"
          showCancel
          showConfirm
          confirmText="Login"
          cancelText="Not now"
          loginModal
          onCancelPress={() => setshowErrorModal(false)}
          onConfirmPress={() => setshowErrorModal(false)}
        />
      ) : (
        <View />
      )}
      {openFaqModal ? (
        <FaqAnswerModal
          item={selectedQuestion}
          handleAskExpert={handleAskExpert}
          handleClose={() => setopenFaqModal(false)}
        />
      ) : (
        <View />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  askQuesView: {
    backgroundColor: "white",
    borderColor: "#BDBDBD",
    shadowColor: "#BDBDBD",
    width: "100%",
    height: "100%"
  },
  check: {
    height: 12,
    width: 12,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12 / 2,
    borderWidth: 1,
  },
  checkselected: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: "green",
  },
  specialityText: {
    fontSize: 14,
    marginLeft: 7,
    fontFamily: family.semibold,
  },
  title: {
    fontSize: 18,
    fontFamily: family.titilliumBold,
    marginLeft: 10,
    color: "grey",
    padding: 10,
    textAlign: "center",
  },
  selectedPackView: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  faqItems: {
    flexDirection: "row",
    padding: 15,
    marginTop: 10,
    marginLeft: 5,
    alignItems: "center",
    justifyContent: "space-between",
    width: "90%",
    borderColor: "#bdbdbd",
    borderBottomWidth: 0.5,
    alignSelf: "center",
  },
  plusButton: {
    height: 30,
    width: 30,
    borderRadius: 15,
    marginLeft: 10,
    alignItems: "center",
    backgroundColor: "#030135",
    justifyContent: "center",
  },
  backButton: {
    height: 30,
    width: 30,
    borderRadius: 15,
    marginLeft: 10,
    alignItems: "center",
    borderWidth: 0.5,
    borderColor: "#fbfbfb",
    justifyContent: "center",
  },
  plustext: {
    color: "white",
    fontSize: normalize(55),
  },
  subButton: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#030135",
    borderRadius: 20,
    marginBottom: 30,
    alignSelf: "center",
    marginTop: 20,
    width: "80%",
  },
});

export default copilot({
  tooltipComponent: TooltipComponent,
  stepNumberComponent: StepNumberComponent,
  verticalOffset: Platform.OS == "android" ? 30 : 0,
})(AskQuestionComponent);
