import React, { useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import BullitinModal from "../BullitinModal/BullitinModal";
import { styles } from "./filterModal.styles";
import InterestSection from "../InterestSection/InterestSection";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchComponentsData,
  updateImmigrationInterests,
  setFilterModal,
  updateCountriesInterestedIn,
} from "../../actions/mainMedia.actions";
import config from "../../common/config";

export default function FilterModal() {
  // Store
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);

  const activeTab = useSelector(
    (state) => state.mainMediaReducer.gState.activeTab
  );
  const filterModalActiveState = useSelector(
    (state) => state.mainMediaReducer.gState.filterModal.active
  );

  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );

  const activeTabTitle = useSelector(
    (state) => state.mainMediaReducer.gState.activeTab.title
  );

  const searchedQuery = useSelector(
    (state) => state.mainMediaReducer[activeTab.title].config.searchedQuery
  );

  const filterConfig = useSelector(
    (state) => state.mainMediaReducer[activeTabTitle].config.filter
  );

  const activeClassification = useSelector(
    (state) => state.mainMediaReducer.news.config.activeClassification
  );

  // Components State
  const [loading, setloading] = useState(false);
  const [countriesInterestedIn, setCountriesInterestedIn] = useState(
    filterConfig.countriesInterestedIn
  );
  const [immigrationInterest, setImmigrationInterest] = useState(
    filterConfig.immigrationInterest
  );
  const [applying, setApplying] = useState(false);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setFilterModal(false));
  };

  const handleClose = (func) => {
    func();
  };

  const applyFilter = async () => {
    setApplying(true);

    const changedCountriesInterestedIn = !countriesInterestedIn.length
      ? config.intrestcountry.map((s) => s.code).join(",")
      : countriesInterestedIn.join(",");
    const changedImmigrationInterest = !immigrationInterest.length
      ? config.immigerat.map((s) => s.code).join(",")
      : immigrationInterest.join(",");

    dispatch(updateCountriesInterestedIn(changedCountriesInterestedIn));
    dispatch(updateImmigrationInterests(changedImmigrationInterest));
    await dispatch(
      fetchComponentsData({
        user_id: guestLogin ? 0 : userDetails?.userid,
        authtoken: guestLogin ? "guest@news" : token,
        from: 0,
        componentType: activeTab.title === "videos" ? "media" : activeTab.title,
        refresh: true,
        classification:
          activeClassification === "default" ? null : activeClassification,
        immiInterest: changedImmigrationInterest,
        country: changedCountriesInterestedIn,
        searchtext: searchedQuery,
      })
    );
    setTimeout(() => {
      onClose();
      setApplying(false);
    }, 50);
  };

  return (
    <BullitinModal
      logo="filter"
      heading="News"
      visible={filterModalActiveState}
      onClose={onClose}
      loading={loading}
      handleClose={handleClose}
      logoFrom="right"
      hideCloseButton={applying}
      viewableContentHeight={"68%"}
    >
      {applying ? (
        <ActivityIndicator
          size="large"
          color="gray"
          style={{
            flex: 1,
          }}
        />
      ) : (
        <>
          <Text style={styles.notificationHeaderText}>
            {activeTabTitle[0].toUpperCase() + activeTabTitle.slice(1)} Filters{" "}
          </Text>
          <ScrollView>
            <View
              style={{
                marginBottom: 15,
                marginTop: 15,
              }}
            >
              <InterestSection
                immigrationInterest={immigrationInterest}
                countriesInterestedIn={countriesInterestedIn}
                setImmigrationInterest={setImmigrationInterest}
                setCountriesInterestedIn={setCountriesInterestedIn}
              />
            </View>
            <View
              style={[
                {
                  alignItems: "center",
                  marginBottom:20
                },
              ]}
            >
              <TouchableOpacity
                onPress={applyFilter}
                style={styles.applyButton}
              >
                <Text style={styles.applyButtonText}>Apply</Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </>
      )}
    </BullitinModal>
  );
}
