import { StyleSheet } from "react-native";
import fontFamily from "../../common/fontFamily";
import { mobilBrower } from "../../common/helpers";

export const styles = StyleSheet.create({
  titleText: {
    fontSize: 24,
    fontFamily: fontFamily.semibold,
    color: "#030135",
    alignSelf: 'center'
  },
  mainView:{
    width:mobilBrower()?"100%":"65%",
    alignSelf:"center"
  },
  dataView: {
    marginTop: 10,
    flexDirection: 'row',
    width: '90%',
    alignItems: 'flex-start'
  },
  dataStyle: {
    fontFamily: fontFamily.robotoRegular,
    fontSize: 16,
    textAlign: "left",
  },
  mainItem: {
    marginTop: 15, 
    padding: 10, 
  },
  bullet: {
    height: 15,
    marginRight: 10,
    marginTop: 3,
    resizeMode: 'contain',
    width: 15
  }
})