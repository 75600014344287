import React from 'react'
import {
    View, StyleSheet, Text,
    TouchableOpacity
} from "react-native";
import LottieView from 'lottie-react-native';
import { normalize, colors, family } from "../../../common";
import { Network, apiPaths } from "../../../service/api";
import { getImmibuckBalance, immiBuckAdd } from '../../../actions/immibucks.actions'
import { useDispatch } from "react-redux";
import successTick from './../../../common/animations/successTick.json';

function VisaSaved(props) {

    const dispatch = useDispatch()
    const callAddMilestone = async () => {
        const body = { "type": "reward", "milestone": "first-path", "amount": 100, "statement": "Shortlist a path" }
        dispatch(immiBuckAdd(body, props.userid, `${props.userid}@${props.token}`))
    }

    const handleOnClose = async () => {
        const url = `/users/${props.userid}`
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${props.userid}@${props.token}`
        }
        dispatch(getImmibuckBalance(url, headers))
        props.handleModalClose()
    }

    const checkRewardMilestone = async () => {
        const url = props.userid + "/check_milestone"
        const headers = {
            Accept: "application/json",
            Authorization: `${props.userid}@${props.token}`
        }
        const body = {
            "milestone": "first-path"
        }
        Network(apiPaths.checkRewardMilestone + url, 'POST', body, headers).then(res => {
            if (res.status === 'Success' && res.is_milestone === false) {
                props.handleRewardModal()
                callAddMilestone();
            } else {
                handleOnClose()
            }
        })
    }
    return (
        <View style={styles.mainView}>
            <View style={styles.innerView}>
                <View style={styles.button}>
                    <LottieView source={successTick} autoPlay loop={false} />
                </View>
                <Text style={styles.modalInsideContentText}>
                    Your visa search has been saved!
                </Text>
                <TouchableOpacity onPress={() => checkRewardMilestone()} style={styles.navButtons}>
                    <Text style={styles.navText}>
                        View
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export const styles = StyleSheet.create({
    mainView: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerView: {
        alignItems: 'center',
        width: '85%',
        padding: 15,
        borderRadius: 15,
        justifyContent: 'center'
    },
    navText: {
        fontSize: 14,
        color: 'white',
        fontFamily: family.semibold
    },
    modalInsideContentText: {
        fontSize: 20,
        fontFamily: family.titilliumBold,
        color: colors.TABLE_ITEM_TEXT,
        textAlign: "center",
        marginTop: 20
    },
    navButtons: {
        width: '80%',
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        marginTop: 15,
        marginBottom: 10,
        backgroundColor: '#030135'
    },
    button: {
        height: 150,
        width: 150,
        borderRadius: 75,
        alignSelf: "center",
        justifyContent: "center",
        borderWidth: 6,
        borderColor: colors.ACTIVE_GREEN,
        backgroundColor: colors.WHITE,
        marginVertical: 20
    },
})
export default VisaSaved
