import { StyleSheet } from "react-native";
import { mobilBrower } from "../../../../../../common/helpers";
import { responsiveText } from "../../../../../../common/normalize";

export const styles = StyleSheet.create({
  worldContainer: {
    alignItems: "center",
    marginVertical: 15,
    width: mobilBrower() ? "100%" : "45%"
  },
  title: {
    fontSize: responsiveText(20),
    fontWeight: "bold",
    marginBottom: 20,
  },
});
