import Api from '../service/apiCall'
import Toast from 'react-native-simple-toast'
import { helpers } from '../common'
import NavigationService from "../navigation/NavigationService"
import { Network } from '../service/api'

export const getApplicationReferralData = (
  user_id,
  authtoken,
  body,
  callback
) => {
  var authdata = user_id + '@' + authtoken
  var string = 'Success'

  return async dispatch => {
    callback('Success')
    dispatch({
      type: 'APPLICATION_REFERRAL_SUCCESS',
      payload: body.timeline
    })
  }

  return async dispatch => {
    dispatch({
      type: 'APPLICATION_REFERRAL_LOADING'
    })
    try {
      return Api('/immipaths/application_referrals?', body, 'POST', authdata)
        .then(res => {
          if (res.status == string) {
            callback('Success')
            dispatch({
              type: 'APPLICATION_REFERRAL_SUCCESS',
              payload: res.application_referrals
                ? res.application_referrals
                : []
            })
          } else {
            // alert('Data does not exist for selected Immigration paths')

            // dispatch({
            //   type: 'APPLICATION_REFERRAL_FAIL',
            //   payload: []
            // })
            callback('Success')
            dispatch({
              type: 'APPLICATION_REFERRAL_SUCCESS',
              payload: body.timeline
            })
          }
          return res
        })
        .catch(function (error) {
          if (error.status == 401) {
            helpers.showSingleAlert(
              'Session expired Please login again',
              () => NavigationService.navigate("Login")
            )
          } else {
            callback('Fail')
            dispatch({
              type: 'APPLICATION_REFERRAL_FAIL',
              payload: error.responseBody
            })
          }
        })
    } catch (error) {

      // console.log('error', error)
      dispatch({
        type: 'APPLICATION_REFERRAL_FAIL',
        payload: error.responseBody
      })
      return error
    }
  }
}

export const changeTabIndex = index => {
  return async dispatch => {
    dispatch({
      type: 'CHANGE_TAB_INDEX',
      payload: index
    })
  }
}
export const clearCart = () => {
  return async dispatch => {
    dispatch({
      type: 'CLEAR_CART',
    })
  }
}

export const addToCart = item => {
  return async dispatch => {
    dispatch({
      type: 'ITEM_ADDED_TO_CART',
      payload: item
    })
  }
}

export const setStorebaseCurrency = currency => {
  return async dispatch => {
    dispatch({
      type: 'STORE_BASE_CURRENCY',
      payload: currency
    })
  }
}

export const addstoreItems = data => {
  return async dispatch => {
    dispatch({
      type: 'ADD_STORE_ITEMS',
      payload: data
    })
  }
}

export const updateCart = item => {
  return async dispatch => {
    dispatch({
      type: 'CART_UPDATED',
      payload: item
    })
  }
}

export const orderConfirmatiopnData = data => {
  return async dispatch => {
    dispatch({
      type: 'ORDER_CONFIRMATION_DATA',
      payload: data
    })
  }
}
  
export const autoApply = (user_id, authtoken, id, postData, callback) => {
  var authdata = user_id + '@' + authtoken
  var string = 'Success'

  return async dispatch => {
    try {
      return Api(
        '/immipaths/application_referrals/' + id + '/autoapply?',
        postData,
        'POST',
        authdata
      )
        .then(res => {
          if (res.status == string) {

            callback('Success')
          } else {
            callback('Fail')

          }
          return res
        })
        .catch(function (error) {
          callback('Fail')
        })
    } catch (error) {

      callback('Fail')

      return error
    }
  }
}

export const getStoreItemsGuest = () => {
  return async dispatch => {
    const headers = {
      Accept: "application/json",
      Authorization: 'guest@store'
    }
    const url = '/wallet/store_items';
    Network(url, 'GET', null, headers).then(res => {
      dispatch({
        type: 'GUEST_STORE_ITEMS',
        payload: res.store_items
      })
    }).catch((err) => {

    })
  }
}