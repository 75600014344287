import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  Linking,
} from "react-native";
import Share from "react-native-share";
import { useDispatch, useSelector } from "react-redux";
import { helpers, Images } from "../../../../../../common";
import InAppBrowser from 'react-native-inappbrowser-reborn'
import { styles1 } from "./cards.styles";
import { firebaseEvents, logEvent } from "../../../../../../common/firebaseEvents";
import { likeOrDislikeMediaContent } from "../../../../../../actions/mainMedia.actions";
import { MyReaction } from "./MyReaction/MyReaction";
import { TotalReactions } from "./TotalReactions/TotalReactions";
import moment from "moment";
import AsyncStorage from "@react-native-community/async-storage";
import { getImmibuckReward, immiBuckAdd } from "../../../../../../actions/immibucks.actions";
import { getUserDetails } from "../../../../../../actions/auth.actions";
import RewardModal from "../../../../../../pages/RewardModal";

const Cards = ({ item, index }) => {
  const rewardHistory = useSelector(
    (state) => state.immibucksReducer.getImmibucksDetails.rewardHistory
  );
  const [shareRewarddetails, setshareRewarddetails] = useState({
    rewardPrice: null,
    showRewardModal: false,
    numberOfShare: null,
  });
  const reward = useSelector(
    (state) => state.userReducer.getUser?.immibuck_history?.reward
  );
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );

  const activeTab = useSelector(
    (state) => state.mainMediaReducer.gState.activeTab.title
  );
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);

  const isItemSaved = useSelector(
    (state) => state.mainMediaReducer.gState.savedArticles
  )[item.news_id ?? item.story_id];

  const activeClassification = useSelector(
    (state) =>
      state.mainMediaReducer[state.mainMediaReducer.gState.activeTab.title]
        .config?.activeClassification
  );

  const itemClassificationState =
    item?.classification !== undefined &&
    item?.classification !== "undefined" &&
    item?.classification !== "";

  const dispatch = useDispatch();

  const tags = item?.tags
    .split(",")
    .reduce(
      (previousValue, currentValue) => [
        ...previousValue,
        currentValue[0].toUpperCase() + currentValue.slice(1),
      ],
      []
    );

  const handleFavourite = async () => {
    if (!isItemSaved) {
      logEvent(firebaseEvents.SAVE_NEWS, {
        message: "User added this news as favourite",
        news_id: item.news_id,
      });
    }
    await dispatch(
      likeOrDislikeMediaContent(
        item,
        userDetails?.userid,
        token,
        isItemSaved,
        activeTab
      )
    );
  };

  const likedOrUnlikedIcon = isItemSaved
    ? Images.redheart
    : Images.Like;

  const checkRewardMilestone = async () => {
    let history = rewardHistory ? rewardHistory : reward;
    let lastSharedTimeStamp = history.filter(
      (s) => s.statement === "Weekly article share"
    );
    var a = moment(new Date());
    var b =
      lastSharedTimeStamp.length > 0
        ? moment(lastSharedTimeStamp[lastSharedTimeStamp.length - 1].timestamp)
        : 0;

    let numberOfShare = lastSharedTimeStamp.length;
    AsyncStorage.setItem("NumberOfArticleShare", JSON.stringify(numberOfShare));

    let datediff = lastSharedTimeStamp.length > 0 ? a.diff(b, "days") : 0;
    if ((datediff > 7 && numberOfShare + 1 < 10) || lastSharedTimeStamp?.length == 0) {
      callAddMilestone();
      setshareRewarddetails({
        rewardPrice: 10,
        showRewardModal: true,
        numberOfShare: numberOfShare + 1
      })
    }
  };

  const callAddMilestone = async () => {
    const body = {
      type: "reward",
      milestone: null,
      amount: 10,
      statement: "Weekly article share",
    };
    const balance_url = `/users/${userDetails?.userid}`;
    const headers = {
      Accept: "application/json",
      Authorization: userDetails?.userid + "@" + token,
    };
    dispatch(immiBuckAdd(body, userDetails?.userid, userDetails?.userid + "@" + token));
    dispatch(getImmibuckReward(balance_url, headers));
  };

  const shareContent = () => {
    logEvent("share_news_stories", { article_link: item.link });
    const params = {
      title: `${activeTab[0].toUpperCase() + activeTab.slice(1)
        } from Immigreat!`,

      message: `Here's a ${activeTab === "news" ? "news" : "story"
        } shared from Immigreat for you: `,

      url: item.link,

      subject: `${activeTab[0].toUpperCase() + activeTab.slice(1)
        } from Immigreat!`,
    };
    Share.open(params)
      .then((res) => {
        checkRewardMilestone();
      })
      .catch((err) => { });
  };
  sendClickedLog = async (actionType, userId, articleId, authToken) => {
    var requestBody = { article_id: articleId };
    if (actionType == "select_story") {
      requestBody = { story_id: articleId };
    }

    try {
      var authData = userId + "@" + authToken;
      let response = await fetch(
        "https://immigreat-qa.appspot.com/users/" +
          userId +
          "/log_actions/" +
          actionType,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: authData,
          },
          body: JSON.stringify(requestBody),
        }
      );
      let responseJson = await response.json();
    } catch (error) {
      Toast.show("Error: " + error.toString());
    }
  };
  const openContent = async () => {
    articleId = item.news_id ? item.news_id : item.story_id
    actionType = item.news_id ? 'select_article' : 'select_story'
    if (!guestLogin){
      sendClickedLog(actionType, userDetails?.userid, articleId, token);
    }
    if (await InAppBrowser.isAvailable()) {
      try {
        const result = await InAppBrowser.open(item.link, helpers.inAPPbrowserOption)
      } catch (error) {
        await InAppBrowser.close()
      }

    } else {
      Linking.openURL(item.link);
    }
  };

  return (
    <TouchableOpacity
      style={styles1.cardContainer}
      activeOpacity={1}
    //   onPress={() => this.props.listUpdateProductClick(item)}
    >
      <View
        style={[
          {
            borderColor:  itemClassificationState
                ? item?.classification == "threat"
                  ? "red"
                  : item?.classification == "opportunity"
                    ? "green"
                    : null
              : null,
            borderWidth: itemClassificationState
                ? item?.classification == "neutral"
                  ? 0
                  : 3
              : 0,
          },
          styles1.cardWrapper,
        ]}
      >
        <View style={styles1.imgView}>
          <Image
            source={{ uri: item.image_link ?? item.source_image ?? "https://images.unsplash.com/photo-1576078361289-d7c4da40e7cd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" }}
            style={styles1.imgStyle}
            resizeMode={"cover"}
          />
          <TotalReactions item={item} />
        </View>
        <View style={styles1.contentView}>
          <View style={styles1.tagsContainer}>
            {item?.tags?.length > 0 && item?.tags[1] != "" ? (
              <FlatList
                data={tags}
                horizontal
                showsHorizontalScrollIndicator={false}
                renderItem={({ item, index }) => (
                  <View style={styles1.tagsWrapper}>
                    <Text style={styles1.tagText}>{item}</Text>
                  </View>
                )}
              />
            ) : (
              <View></View>
            )}
          </View>
          <View
            style={styles1.tagView}
          >
            <Text
              style={styles1.itemTitle}
              numberOfLines={4}
            >
              {item?.title}
            </Text>
          </View>
          <View
            style={styles1.tagView}>
            <Text numberOfLines={5} style={styles1.textSummary}>
              {item.summary}
            </Text>
          </View>
          <View
            style={[
              styles1.tagView,
              {
                justifyContent: "space-between",
              },
            ]}
          >
            <TouchableOpacity
              onPress={openContent}
              style={styles1.readMoreButton}
            >
              <Text style={styles1.readMoreButtonText}>Read More</Text>
            </TouchableOpacity>
            {!guestLogin ? (
              <View style={styles1.shareView}>
                <TouchableOpacity onPress={handleFavourite}>
                  <Image
                    source={likedOrUnlikedIcon}
                    style={[styles1.faviconStyle, { marginRight: 10 }]}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={shareContent}>
                  <Image
                    source={Images.shareNews}
                    style={[styles1.faviconStyle, { marginRight: -10 }]}
                  />
                </TouchableOpacity>
              </View>
            ) : (
              <View />
            )}
          </View>
          <View
            style={[
              styles1.tagView,
              {
                marginBottom: "2%",
              },
            ]}
          >
            <View style={{ flex: 1 }}>
              <Text style={[styles1.dateText]}>
                {item?.date_published?.slice(0, 16)}
              </Text>
              <Text style={[styles1.sourceText]}>{item?.source + " "}</Text>
            </View>
          </View>

          <MyReaction item={item} index={index} />
        </View>
      </View>
      {
        shareRewarddetails?.showRewardModal &&
        <RewardModal
          visible={shareRewarddetails?.showRewardModal}
          price={shareRewarddetails?.rewardPrice}
          text="Reward for weekly article share"
          title={"Wohoo!\nAnother reward for you!"}
          onClosed={() => setshareRewarddetails({ showRewardModal: false })}
        />
      }
    </TouchableOpacity>
  );
};

export default Cards;
