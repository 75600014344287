import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  tooltipContainer: {
    flex: 1,
    width: 400,
    alignSelf: "flex-start",
  },
  tooltipText: {
    padding: 10,
    paddingTop: 0,
    width: "60%",
    paddingBottom: 5,
  },
  bottomBar: {
    marginTop: 10,
    flexDirection: "row",
    marginBottom: 10,
    alignSelf: "flex-start",
    left: -10,
    justifyContent: "flex-start",
  },
  toolTipButton: {
    marginRight: 15,
  },
  toolTipButtonText: {
    color: "#e9a431",
    textAlign: 'center'
  },
});
