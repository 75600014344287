import React, { useEffect, useState } from "react";
import {
  View,
  Modal,
  TouchableOpacity,
  Image,
} from "react-native";
import Images from "../../common/images";
import { styles } from "./bullitinModal.styles";
import { animated as a, useSpring } from "@react-spring/native";
import Loader from "../../components/Loader";
import BlurBackground from "../BlurBackground";

export default function BullitinModal({
  children,
  logo,
  visible,
  loading,
  hidelogo,
  onClose,
  handleClose,
  height,
  logoFrom,
  closeIcon = "closeBlack",
  hideCloseButton,
  viewableContentHeight,
  innerViewStyle={}
}) {
  const [renderAnimation, setRenderAnimation] = useState(false);
  const [resetAnimation, setResetAnimation] = useState(false);
  const bullitinLogo = {
    pin: Images.pushpin,
    filter: Images.newFilterIcon,
    reaction: Images.reactionBullitinLogo,
    default: Images.defaultBullitinLogo,
  };

  useEffect(() => {
    if (visible) {
      setResetAnimation(false);
      setRenderAnimation(true);
    }
  }, [visible]);

  const closeModal = () => {
    resetAnimation && onClose();
  };

  const renderFrom = (bool) => {
    if (logoFrom === "mid") {
      return { marginTop: bool ? 0 : 700, marginLeft: 0 };
    } else {
      return {
        marginLeft: bool ? 0 : logoFrom === "right" ? 500 : -500,
        marginTop: bool ? 0 : -700,
      };
    }
  };

  const modalLogoAnimation = useSpring({
    delay: renderAnimation ? 100 : 0,
    opacity: renderAnimation ? 1 : 0,
    ...renderFrom(renderAnimation),
  });

  const openUp = useSpring({
    delay: renderAnimation ? 900 : 0,
    onRest: closeModal,
    opacity: renderAnimation ? 1 : 0,
    minHeight: renderAnimation ? viewableContentHeight ?? height ? height : "60%" : "0%",
  });

  const easeIn = useSpring({
    config: {
      friction: 80,
    },
    delay: renderAnimation ? 400 : 0,
    opacity: renderAnimation ? 1 : 0,
    marginTop: renderAnimation ? 100 : 0,
  });

  const startCloseAnimation = () =>
    handleClose(() => {
      setRenderAnimation(false);
      setResetAnimation(true);
    });

  const MainComponent = (
    <View onTouchStart={startCloseAnimation} style={styles.mainView}>
      {hidelogo ? null :
        <a.Image
          onTouchStart={(e) => e.stopPropagation()}
          source={bullitinLogo[logo ?? "default"]}
          style={[modalLogoAnimation, styles.topIcon]}
        />}
      <a.View
        onTouchStart={(e) => e.stopPropagation()}
        style={[openUp, styles.innerView,innerViewStyle]}
      >
        {hidelogo ? null : <a.Image source={Images.Vector2} style={[easeIn, styles.Vector2]} />}
        {!hideCloseButton ? (
          <TouchableOpacity
            onPress={startCloseAnimation}
            style={styles.closeIconButton}
            hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
          >
            <Image source={Images[closeIcon]} style={styles.closeIcon} />
          </TouchableOpacity>
        ) : null}
        {children}
      </a.View>
    </View>
  );

  return (
    <Modal onRequestClose={startCloseAnimation} transparent visible={visible}>
      <BlurBackground />
      {loading ? <Loader /> : MainComponent}
    </Modal>
  );
}
