import React from "react";
import {
  View,
  Text
} from "react-native";
import { colors, family } from "../../../../../common";

export const CityName = ({ value }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        paddingTop: 15,
        alignSelf: "flex-start",
        marginBottom: 10,
      }}
    >
      <View>
        <Text
          style={[
            {
              fontSize: 20,
              color: colors.DARK_BLUE,
              fontFamily: family.semibold,
            },
          ]}
        >
          {value}
        </Text>
      </View>
      <View
        style={{
          borderRightColor: "rgba(0,0,0,0.16)",
          height: 50,
          alignSelf: "center",
        }}
      />
    </View>
  );
};
