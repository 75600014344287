export default (state = {}, { type, payload }) => {
    switch (type) {
        case "DASHBOARD_LOADING":
            return { ...state, isLoading: true };
        case "GET_APPTRACKER_SUCCESS":
            return { ...state, appTrackerList: payload, isLoading: false };
        default:
            return state;
    }
};
