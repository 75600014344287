import React from "react";
import { animated as a, useSpring } from "@react-spring/native";
import { mobilBrower } from "../../common/helpers";
import { Platform } from "react-native";
import { Images } from "../../common";

export const LoginFlowAnimation = ({ children, extraStyles, dependsOn }) => {
  const animation = useSpring({
    to: [
      {
        flex:
          Platform.OS == "web" && !mobilBrower() ? 0.45 : !dependsOn ? 1 : 0.9,
      },
    ],
    from: {
      flex: Platform.OS == "web" && !mobilBrower() ? 0.1 : !dependsOn ? 0 : 0,
    },
  });
  return <a.View style={[animation, extraStyles]}>{children}</a.View>;
};

export const LoginBgImage = ({ src, imageStyle, wrapperStyle, dependsOn }) => {
  const imgAnimation = useSpring({
    to: [
      {
        opacity: !dependsOn ? 1 : Platform.OS == "web" ? 1 : 0,
      },
    ],
    from: {
      opacity: !dependsOn ? 0 : Platform.OS == "web" ? 0: 1,
    },
  });
  const imageWrapperAnimation = useSpring({
    to: [
      {
        flex: Platform.OS == "web" && !mobilBrower() ? 1 : !dependsOn ? 0.6 : 0.1,
      },
    ],
    from: {
      flex: Platform.OS == "web" && !mobilBrower() ? 1 : !dependsOn ? 0.6 : 1,
    },
    config:{
      duration:1000
    }
  });
  return (
    <a.View style={[imageWrapperAnimation, wrapperStyle]}>
      <a.Image source={Images.loginbg} style={[imgAnimation,{position:"absolute",bottom:0,left:0,width: "100%",height: "100%",}]} />
      <a.Image source={Images.loginScreenImg} style={[imgAnimation, imageStyle]} />
    </a.View>
  );
};
