import { Platform, StyleSheet, Dimensions } from "react-native";
import { heightPercentageToDP } from "react-native-responsive-screen";
import fontFamily from "../../common/fontFamily";
import { mobilBrower } from "../../common/helpers";
import { Images, colors, helpers, family } from "../../common";

const { width, height } = Dimensions.get("window");

export const styles = StyleSheet.create({
  reactionModal: {
    backgroundColor: "white",
    paddingTop: 5,
    paddingRight: 5,
    padding: 25,
    margin: 20,
    display: "flex",
    alignItems: "center",
    borderRadius: 15,
    width: "80%",
    justifyContent: "center",
  },
  closeIconButton: {
    marginRight: 15,
    alignSelf: "flex-end",
    marginTop: 10,
  },
  closeIcon: {
    height: 15,
    width: 15,
  },
  reactionModalWrapper: {
    height,
    width,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(21,21,21,0.5)",
  },
  reactionHeading: {
    fontWeight: "700",
    fontSize: heightPercentageToDP("2.7%"),
    textAlign: "center",
  },
  reactions: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 20,
  },
});
