import { StyleSheet } from "react-native";
import { family } from "../../common";
import colors from "../../common/colors";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";

export const styles = StyleSheet.create({
  mainView: {
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.7)",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
  },
  absolute: {
    left: 0,
    width: mobilBrower()? "90%":"35%",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: 10,
    justifyContent: "center",
    bottom: 0,
    right: 0,
    maxHeight: "60%",
    backgroundColor: "white",
    padding: 15,
  },
  linearCard: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "flex-start",
    borderColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: 10,
  },
  innerView: {
    width: "100%",
    borderRadius: 10,
    flex:1
  },
  title: {
    color: "black",
    marginLeft: 10,
    fontSize: responsiveText(20),
    fontFamily: family.effra,
    textTransform: "uppercase",
    textAlign: "left",
    color: colors.DARK_BLUE,
  },
  cutoffText: {
    color: "black",
    textAlign: "center",
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
  },
  pointsView: {
    alignItems: "center",
    padding: 5,
    justifyContent: "center",
    width: "100%",
    alignSelf:"center",
    backgroundColor: "rgb(244,244,244)",
    flex:1
  },
  pointsContent:{
    width: mobilBrower()?"100%":"75%",
  },
  countryName: {
    fontFamily: family.effra,
    color: "black",
    fontSize: responsiveText(20),
    marginBottom: 5,
    textAlign: "center",
    width: "100%",
  },
  titleView: {
    width: "100%",
    backgroundColor: "transparent",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.3)",
    justifyContent: "flex-end",
    padding: 5,
  },
  closebutton: {
    marginRight: 10,
  },
  pointsText: {
    fontSize: responsiveText(20),
    fontFamily: family.effra,
    marginLeft: 8,
    bottom: 15,
    color: "black",
  },
  statusIcon: {
    position: "absolute",
    height: 16,
    width: 16,
    top: 10,
    right: -20,
  },
  prStatus: {
    fontSize: responsiveText(18),
    fontFamily: family.semibold,
    alignSelf: "center",
  },
  prStatusNoteW: {
    fontSize: responsiveText(16),
    fontFamily: family.effra,
    alignSelf: "center",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: 30,
  },
  contentContainer:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "rgb(244,244,244)",
    width: "100%",
    justifyContent: "center",
    padding: 25,
    borderRadius: 15,
    marginBottom: 30,
  },
  perInfoWrapper: {
    marginTop: -65,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  perWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  completeText: {
    fontFamily: family.effra,
    alignItems: "center",
    color: "black",
    fontSize: 18,
    marginBottom: -12,
    display: "flex",
    justifyContent: "center",
    color: "gray",
  },
  completePercentageText: {
    fontFamily: family.effra,
    alignItems: "center",
    color: "black",
    fontSize: mobilBrower() ? 60 : responsiveText(60),
    marginBottom: -12,
    display: "flex",
    justifyContent: "center",
    marginLeft: 10,
  }
});
