import React, { useState } from "react";
import { View, ActivityIndicator } from "react-native";

import { useDispatch, useSelector } from "react-redux";
import { isWeb, mobilBrower } from "../../../../common/helpers";
// import Content from "./components/Content/Content";
import News from "./components/News/News";
import Stories from "./components/Stories/Stories";
import Videos from "./components/Videos/Videos";

const ActiveTab = ({ componentLoading }) => {
  const activeTab = useSelector(
    (state) => state.mainMediaReducer.gState.activeTab.key
  );

  const activeComponent = {
    first: <News />,
    second: <Stories />,
    three: <Videos />,
  };

  return (
    <View
      style={{
        width: isWeb() && !mobilBrower() ? "60%" :"100%",
        display: "flex",
        flexDirection: "row",
        height: componentLoading ? "50%" : isWeb()?"auto":"100%",
        alignSelf:"center",
        alignItems: "center",
        justifyContent: "center",
        paddingTop:isWeb()?20:0
      }}
    >
      {componentLoading ? (
        <ActivityIndicator color="rgb(220,220,220)" size="large" />
      ) : (
        activeComponent[activeTab]
      )}
      {/* <Content activeTab={activeTab} /> */}
    </View>
  );
};

export default ActiveTab;

// import React, { useState } from "react";
// import { View, FlatList, Dimensions, Animated } from "react-native";

// import { useDispatch, useSelector } from "react-redux";
// import { setActiveTab } from "../../../../actions/mainMedia.actions";
// import News from "./components/News/News";
// import Stories from "./components/Stories/Stories";
// import Videos from "./components/Videos/Videos";

// const { width, height } = Dimensions.get("window");

// const ActiveTab = () => {
//   const dispatch = useDispatch();

//   const activeComponent = {
//     first: <News />,
//     second: <Stories />,
//     three: <Videos />,
//   };

//   const components = [
//     { key: "first", title: "news" },
//     { key: "second", title: "stories" },
//     { key: "three", title: "videos" },
//   ];

//   const scrollX = React.useRef(new Animated.Value(0)).current;

//   const onMomentumScrollEnd = (e) => {
//     let pageNumber = Math.min(
//       Math.max(Math.floor(e.nativeEvent.contentOffset.x / width + 0.5) + 1, 0),
//       3
//     );
//     dispatch(setActiveTab(components[pageNumber - 1]));
//   };

//   return (
//     <View style={{ height: "100%" }}>
//       <FlatList
//         showsHorizontalScrollIndicator={true}
//         data={components}
//         showsHorizontalScrollIndicator={false}
//         keyExtractor={(item) => item.key}
//         horizontal
//         renderToHardwareTextureAndroid
//         decelerationRate={Platform.OS === "ios" ? 0 : 0.98}
//         bounces={false}
//         snapToAlignment="start"
//         scrollEventThrottle={16}
//         pagingEnabled
//         onMomentumScrollEnd={onMomentumScrollEnd}
//         contentContainerStyle={{
//           alignItems: "center",
//           // backgroundColor: "blue",
//         }}
//         renderItem={({ item, index }) => {
//           return (
//             <View
//               style={{
//                 width,
//               }}
//             >
//               {activeComponent[item.key]}
//             </View>
//           );
//         }}
//       />
//     </View>
//   );
// };

// export default ActiveTab;
