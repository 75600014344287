import { StyleSheet, Platform } from "react-native";
import fontFamily from "../../common/fontFamily";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";
import { heightPercentageToDP } from "../../common/responsiveScreen";

export const styles = StyleSheet.create({
  mainView: {
    height: "100%",
    width: "100%",
    backgroundColor: "#00000070",
    // position: "absolute",
    alignItems: "center",
    // bottom:0,
    justifyContent: "flex-end",
  },
  loaderbackground: {
    height:
      heightPercentageToDP(100) -
      heightPercentageToDP("22%"),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  discount_mainView: {
    height: "100%",
    width: "100%",
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  followUp_innerView: {
    backgroundColor: "#FFF",
    width: "90%",
    height: "80%",
    borderRadius: 15,
    paddingVertical: 15,
  },
  discount_innerView: {
    backgroundColor: "#F1F1F1",
    width: mobilBrower() ? "90%" : "50%",
    borderRadius: 15,
    alignItems: "center",
    padding: 20,
  },
  discount_Title: {
    fontSize: heightPercentageToDP("2.5%"),
    marginTop: heightPercentageToDP("1%"),
    marginBottom: heightPercentageToDP("1.7%"),
    fontFamily: fontFamily.titilliumBold,
  },
  middleView: {
    flexDirection: "row",
    alignItems: "center",
    width: "80%",
    padding: 20,
    paddingBottom: 10,
    justifyContent: "center",
  },
  closeIcon: {
    height: mobilBrower() ? heightPercentageToDP("1.8%") : 15,
    width: mobilBrower() ? heightPercentageToDP("1.8%") : 15,
  },
  checkBoxView: {
    flexDirection: "row",
    alignItems: "center",
  },
  closeIconButton: {
    marginRight: 15,
    alignSelf: "flex-end",
    marginTop: 10,
  },
  orView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  promoView: {
    flexDirection: "row",
    paddingHorizontal: 15,
    marginVertical: 10,
    alignItems: "center",
  },
  promoTextBox: {
    marginLeft: 15,
  },
  disabled: {
    backgroundColor: "#BDBDBD",
  },
  promoInputBox: {
    flex: 1,
    height: 50,
    borderWidth: 0.5,
    justifyContent: "center",
    borderColor: "#BDBDBD",
  },
  successTick: {
    height: 22,
    width: 22,
    marginLeft: 10,
    resizeMode: "contain",
  },
  orText: {
    fontSize: heightPercentageToDP("1.4%"),
  },
  okButton: {
    backgroundColor: "#000",
    padding: 12,
    borderRadius: 8,
    marginLeft: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  okText: {
    color: "white",
    fontSize: heightPercentageToDP("1.5%"),
  },
  left: {
    borderWidth: 0.5,
    margin: 10,
    flex: 1,
  },
  checked: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: "green",
  },
  checkBox: {
    height: 14,
    width: 14,
    marginRight: 10,
    alignItems: "center",
    borderRadius: 7,
    justifyContent: "center",
    borderWidth: 1,
  },
  dicPercentage: {
    fontSize: heightPercentageToDP("2.2%"),
  },
  eligibleView: {
    backgroundColor: "#33AC09",
  },
  immibuckIcon: {
    height: 20,
    marginHorizontal: 5,
    width: 20,
  },
  buttonView: {
    flexDirection: "row",
    alignItems: "center",
  },
  applyButtonText: {
    fontSize: heightPercentageToDP("1.8%"),
    color: "white",
  },
  submitButtonText: {
    fontSize: heightPercentageToDP("1.8%"),
    paddingHorizontal: heightPercentageToDP("1%"),
    paddingVertical: heightPercentageToDP("0.8%"),
    color: "white",
  },
  buttonDe: {
    width: "100%",
    alignItems: "center",
    padding: 10,
    height: 40,
    borderRadius: 5,
    justifyContent: "center",
  },
  button1: {
    backgroundColor: "#F1AB32",
    marginBottom: 10,
    marginTop: 15,
  },
  cancelButton: {
    backgroundColor: "#000",
  },
  notView: {
    alignItems: "center",
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "center",
  },
  note: {
    color: "red",
    paddingVertical: 5,
    fontSize: heightPercentageToDP("1.2%"),
  },
  notEligibleView: {
    backgroundColor: "#A0A0A0",
    padding: 5,
    borderRadius: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  notEligibleText: {
    color: "white",
  },
  innerView: {
    width: "100%",
    backgroundColor: "rgba(255,255,255,1)",
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    borderWidth: 1,
    borderColor: "gray",
    // bottom: -20,
    height: "80%",
    // borderRadius: 10,
    shadowColor: "#000",
    alignItems: "center",
    shadowRadius: 5,
    padding: 5,
    shadowOpacity: 0.5,
    shadowOffset: { height: 1, width: 1 },
  },
  contentContainer: {
    width: !mobilBrower() ? "70%" : "100%",
    height: "100%",
    padding: 15,
    marginBottom: 25,
  },
  titleView: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: 10,
    justifyContent: "space-between",
    alignItems: "center",
    // backgroundColor: "white",
  },
  backIcon: {
    height: 18,
    width: 18,
    resizeMode: "contain",
  },
  titleText: {
    alignSelf: "center",
    fontSize: heightPercentageToDP("2%"),
    fontFamily: fontFamily.effra,
  },
  backButton: {
    left: 0,
  },
  counryViewHeight: {
    // minHeight: Platform.OS == "web" ? 210 : heightPercentageToDP("15%"),
  },
  askQuesView: {
    // minHeight:
    //   Platform.OS == "web"
    //     ? heightPercentageToDP("20%")
    //     : heightPercentageToDP("15%"),
    backgroundColor: "white",
    borderColor: "#BDBDBD",
    marginTop: 10,
    shadowColor: "#BDBDBD",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 3,
    borderWidth: Platform.OS == "android" ? 1 : 0,
    elevation: 12,
    alignSelf: "center",
    borderRadius: 5,
    width: "95%",
  },
  currentStageView: {
    minHeight:
      Platform.OS == "web"
        ? heightPercentageToDP("14%")
        : heightPercentageToDP("15%"),
  },
  errView: {
    borderWidth: 1,
    borderColor: "red",
  },
  noteView: {
    width: !mobilBrower() ? "65%" : "95%",
    alignSelf: "center",
    padding: 20,
  },
  dotStyle: {
    color: "#000",
    opacity: 0.5,
    fontSize: heightPercentageToDP("3%"),
    top: -15,
    marginRight: 5,
  },
  summaryView: {
    flexDirection: "row",
    width: "100%",
    padding: 14,
    alignItems: "center",
  },
  labelView: {
    flex: 1,
    display: "flex",
    position: "relative",
  },
  label: {
    fontFamily: fontFamily.effra,
    fontSize: responsiveText(15),
    textAlignVertical: "top",
    flex: 1,
  },
  labelQ: {
    fontFamily: fontFamily.effra,
    fontSize: 15,
    textAlignVertical: "top",
    flex: 1,
  },
  contentView: {
    flex: 1,
  },
  summaryTitle: {
    alignSelf: "center",
    fontWeight: "500",
    padding: 20,
    fontSize: responsiveText(18),
    fontFamily: fontFamily.effra,
  },
  noteTitle: {
    fontFamily: fontFamily.robotoRegular,
    color: "#000",
    opacity: 0.5,
    fontSize: responsiveText(14),
  },
  nextButton: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F1AB32",
    borderRadius: 7,
    height: 44,
    alignSelf: "center",
    margin: 30,
    marginTop: 25,
    width: "95%",
  },
  nextButtonText: {
    color: "white",
    fontSize: heightPercentageToDP("2%"),
    fontWeight: "600",
    fontFamily: fontFamily.titilliumBold,
    width: "100%",
    textAlign: "center",
  },
  dropDownView: {
    // minHeight: heightPercentageToDP("15%"),
    backgroundColor: "white",
    borderColor: "#BDBDBD",
    marginTop: 10,
    padding: 5,
    paddingLeft: 15,
    shadowColor: "#BDBDBD",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 3,
    borderWidth: Platform.OS == "android" ? 1 : 0,
    elevation: 12,
    alignSelf: "center",
    borderRadius: 5,
    width: "95%",
  },
  title: {
    fontSize: responsiveText(16),
    fontFamily: fontFamily.semibold,
    width: "95%",
    textAlign: "left",
  },
  payButtonText: {
    fontFamily: fontFamily.effra,
    color: "white",
    fontSize: heightPercentageToDP("2%"),
  },
  countryIcon: {
    height: 50,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: "center",
  },
  countryName: {
    fontSize: responsiveText(8),
    fontFamily: fontFamily.semibold
  },
  listTextViewStyle: {
    color: "#000",
    marginVertical: 10,
    flex: 0.9,
    marginLeft: 10,
    //marginLeft: 20,
    //marginHorizontal: 10,
    textAlign: "left",
  },
  selectLabelTextStyle: {
    color: "#000",
    textAlign: "left",
    width: "99%",
    padding: 10,
    paddingBottom: 5,
    flexDirection: "row",
  },
  aganeName: {
    fontFamily: fontFamily.robotoRegular,
    marginTop: 5,
    fontSize: heightPercentageToDP("2%"),
  },
  placeHolderTextStyle: {
    color: "grey",
    padding: 10,
    textAlign: "left",
    width: "99%",
    flexDirection: "row",
  },
  pickerStyle: {
    //marginLeft: 10,
    paddingRight: 25,
    marginRight: 10,
    marginBottom: 0,
    paddingBottom: 0,
    backgroundColor: "#00000000",
    flexDirection: "row",
    borderWidth: 0,
    borderColor: null,
  },
  flagbuttons: {
    height: 80,
    flex: 1,
    alignItems: "center",
    marginHorizontal: 5,
    // width: 100,
  },
  payButton: {
    alignItems: "center",
    width: "95%",
    backgroundColor: "#F1AB32",
    borderRadius: 8,
    alignSelf: "center",
    marginTop: 15,
    height: 45,
    justifyContent: "center",
    marginBottom: 35,
  },
  categoryView: {
    width: "100%",
    alignSelf: "flex-start",
    marginLeft: "2.5%",
    marginTop: heightPercentageToDP("4%"),
  },
  caroselStyle: {
    marginTop: 15,
    backgroundColor: "#0F1143",
    height: Platform.OS == "web" ? 270 : 330,
    overflow: "hidden",
  },
  queryOptButtons: {
    flex: 1 / 2,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderRadius: 6,
    height: 46,
    margin: 5,
    marginTop: 5,
    marginBottom: 5,
    borderColor: "rgba(0, 0, 0, 0.14)",
  },
  availDiscountButton: {
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 0.5,
    marginRight: 20,
    borderRadius: 5,
  },
  availDiscountText: {
    opacity: 0.5,
    padding: 15,
    paddingTop: 7,
    paddingBottom: 7,
  },
  selectedCategoryBorder: {
    borderColor: "#F1AB32",
    borderWidth: 2,
  },
  subCategoryItemContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    // height: 236,
  },
  subCategoryItem: {
    width: "90%",
    height: 236,
    borderWidth: 1,
    backgroundColor: "white",
    marginTop: 25,
    borderRadius: 15,
  },
  categoryImg: {
    height: 190,
    borderRadius: 30,
    width: "100%",
    resizeMode: "contain",
  },
  selectedCategorText: {
    color: "#F1AB32",
  },
  subCategoryItemText: {
    color: "#000",
    marginBottom: Platform.OS == "web" ? 30 : 20,
    fontFamily: fontFamily.semibold,
    fontSize: heightPercentageToDP("1.8%"),
    alignSelf: "center",
  },
  queryOptButtonsText: {
    fontSize: responsiveText(13),
    textAlign: "center",
    color: "black",
  },
  check: {
    height: 12,
    width: 12,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12 / 2,
    borderWidth: 1,
  },
  customIndicator: {
    height: 20,
    width: 20,
    alignSelf: "center",
    backgroundColor: "red",
  },
  checkselected: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: "green",
  },
  specialityText: {
    fontSize: 14,
    marginLeft: 7,
    fontFamily: fontFamily.semibold,
  },
  faqItems: {
    flexDirection: "row",
    padding: 10,
    marginTop: 10,
    marginLeft: 5,
    alignItems: "center",
    justifyContent: "space-between",
    width: "90%",
    borderColor: "#bdbdbd",
    borderBottomWidth: 0.5,
    alignSelf: "center",
  },
  textInput: {
    fontSize: responsiveText(14),
    fontFamily: fontFamily.semibold,
    width: "95%",
    height: 80,
    textAlign: "left",
    marginTop: 5,
    padding: 10,
    color: "black",
  }
});
