import { combineReducers } from 'redux';

const authData = (state = {}, action) => {
    switch (action.type) {
        case "AUTH_USER_SUCCESS":
            return {
                token: action.token,
                isLoggedIn: true
            }

        case "AUTH_USER_FAIL":
            return {
                token: null,
                isLoggedIn: false
            }
        default:
            return state;
    }
}

const getUserProfilePic = (state = {}, action) => {
    switch (action.type) {
        case "GET_USER_PROFILE_PIC":
            return {
                ...state,
                profilePic: action.payload
            }
        default:
            return state;
    }
}

const createUser = (state = {}, action) => {
    switch (action.type) {

        case "CREATE_USER_LOADING":
            return {
                isLoading: true,
                isError: false,
                isSuccess: false,
                errors: null,

            }

        case "CREAT_USER_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                errors: null
            }

        case "CREAT_USER_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                errors: action.payload
            }
        case "INVALID_REF_CODE":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                errors: action.payload
            }

        default:
            return state;
    }
}

const loginUser = (state = {}, action) => {
    switch (action.type) {

        case "LOGIN_USER_LOADING":
            return {
                isLoading: action.isLoading,
                isError: false,
                isSuccess: false,
                errors: null
            }

        case "LOGIN_USER_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                errors: null,
            }

        case "LOGIN_USER_FAIL":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
                errors: action.payload
            }
        case "LOADING_STOP":
            return {
                isLoading: false,
            }
        case "LOADING_STARTED":
                return {
                    isLoading: true,
                }
        default:
            return state;
    }
}

const finishIntroScreen = (state = {}, action) => {
    switch (action.type) {

        case "FINISH_INTRO":
            return {
                finishIntro: true,
            }
        default:
            return state;
    }
}

const getNotifications = (state = {}, action) => {
    switch (action.type) {

        case "GET_NOTIFICATION_LIST":
            return {
                ...state,
                notificationList: action.payload
            }
        case "NOTIFICATION_LOADER":
            return {
                ...state,
                loader: action.notification_loader
            }
        default:
            return state;
    }
}

const finishSelectDesc = (state = {}, action) => {
    switch (action.type) {

        case "LANDING_INTRO":
            return {
                showDestination: true,
            }
        case "LANDING_FINISH":
            return {
                hideDestination: false,
            }
        case "LANDING_DESTINATION_ID":
            return {
                destinationID: action.value,
            }
        case "BOTTOM_TUTORIAL_FINISH":
            return {
                ...state,
                bottomTutorialFinish: !state.bottomTutorialFinish
            }
        case "CALL_BOTTOM_TAB_GUIDE":
            return {
                ...state,
                bottomTabGuide: !state.bottomTabGuide
            }
        default:
            return state;
    }
}
export default combineReducers({
    createUser,
    loginUser,
    authData,
    finishIntroScreen,
    finishSelectDesc,
    getUserProfilePic,
    getNotifications
});