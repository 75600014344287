import { StyleSheet } from "react-native";
import colors from "../../../../common/colors";
import fontFamily from "../../../../common/fontFamily";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";
import { heightPercentageToDP } from "../../../../common/responsiveScreen";

export const styles = StyleSheet.create({
  saveItemMainView: {
    backgroundColor: "#EEF2F5",
    borderRadius: 30,
    flexDirection: "row",
    shadowColor: "#000000",
    shadowOpacity: 0.3,
    shadowRadius: 1,
    shadowOffset: {
      height: 1.5,
      width: 0.3,
    },
    marginTop: mobilBrower() ? 20 : 10,
    marginBottom: 10,
    height: 200,
    width: "96%"
  },
  thumbail: {
    width: "45%",
    resizeMode: "contain",
  },
  imgView: {
    width: "45%",
  },
  contentView: {
    width: "55%",
    padding: 7,
  },
  tagView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop:10
  },
  tagContainer: {
    marginRight: 5,
    padding: 5,
    borderRadius: 25,
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    borderColor: colors.LIGHT_BLUE,
  },
  tagText: {
    color: colors.LIGHT_BLUE,
    fontFamily: fontFamily.effra,
    fontSize: responsiveText(12),
  },
  title: {
    color: "#2D4052",
    fontSize: responsiveText(14),
    marginTop: heightPercentageToDP("1.5%"),
    fontFamily: fontFamily.effra,
    fontWeight: "bold",
  },
  shareView: {
    flexDirection: "row",
    alignItems: "center",
  },
  dateText: {
    fontFamily: fontFamily.effra,
    fontSize: heightPercentageToDP("1.2%"),
  },
  readMoreButton: {
    width: "58%",
    height: heightPercentageToDP("3.3%"),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0F1143",
  },
  readMoreButtonText: {
    color: "white",
    fontFamily: fontFamily.effra,
    fontSize: responsiveText(11),
  },
  imgStyle: {
    width: "100%",
    height: mobilBrower() ? "100%" : "100%",
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
  },
  faviconStyle: {
    width: mobilBrower() ? heightPercentageToDP("2%") : 18,
    height: mobilBrower() ? heightPercentageToDP("2%") : 18,
    resizeMode: "contain",
  },
  spinner: {
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    position: "absolute",
    zIndex: 999,
    alignSelf: "center",
  },
});
