import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  FlatList,
  Platform,
  Modal,
} from "react-native";
import { Images, family } from "../../common";
import DeviceInfo from "react-native-device-info";
import { useSelector } from "react-redux";
import AsyncStorage from "@react-native-community/async-storage";
import _ from "lodash";
import moment from "moment";
import { copilot, walkthroughable, CopilotStep } from "react-native-copilot";
import { StepNumberComponent } from "../../common/WalkThroughable";

import { TooltipComponent } from "../../components/Tooltip/Tooltip";
import { styles } from "./TotalRewardModal.styles"
import { isWeb } from "../../common/helpers";
import { useHistory } from "react-router-dom";
import NavigationService from "../../navigation/NavigationService";

let hasNotch = DeviceInfo.hasNotch();
const WalkthroughableImage = walkthroughable(Image);
const WalkthroughableText = walkthroughable(Text);
const WalkthroughableView = walkthroughable(View);

function TotalRewardModal(props) {
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const [activeTab, setActiveTab] = useState(0);
  const [rewardArr, setrewardArr] = useState([
    {
      type: "Sign-up Reward",
      amount: 50,
    },
    {
      type: "Ask a question",
      amount: 100,
    },
    {
      type: "Shortlist a path",
      amount: 100,
    },
    {
      type: "Refer 5 friends",
      amount: 100,
    },
  ]);
  const [walltesHistory, setwalltesHistory] = useState([]);
  const history = useHistory()

  const handleStorePress=()=>{
    props._handleImmibuckModal();
        
     isWeb()? history.push("/store") : NavigationService.navigate("Store", {
            getMilestones: () => props.getMilestones(),
          });
  }

  isAccomplished = (type) => {
    let status = false;
    props.milestonesData?.map((s) => {
      if (s.statement === type) {
        status = true;
      }
    });
    return status;
  };

  useEffect(() => {
    let getTutorialStatus = async () => {
      tutorialShown = await AsyncStorage.getItem("TutorialShown");
      if (!tutorialShown && userDetails.isHelpActive) {
        tutorialShown = JSON.parse(tutorialShown);
        setTimeout(() => {
          props.start();
        }, 200);
      }
    };
    getTutorialStatus();
  }, []);

  useEffect(() => {
    let debitArr = [];
    let creditArr = [];
    let discountArr = [];
    let rewardArr = [];
    let finalArr = [];
    let numberOfShare = 0;
    props.walletHistory &&
      props.walletHistory.debit &&
      props.walletHistory.debit.forEach((s) => {
        s.type = "debit";
        debitArr.push(s);
      });
    props.walletHistory &&
      props.walletHistory.credit &&
      props.walletHistory.credit.forEach((s) => {
        s.type = "credit";
        creditArr.push(s);
      });
    props.walletHistory &&
      props.walletHistory.discount &&
      props.walletHistory.discount.forEach((s) => {
        s.type = "discount";
        discountArr.push(s);
      });
    props.walletHistory &&
      props.walletHistory.reward &&
      props.walletHistory.reward.forEach((s) => {
        s.type = "reward";
        if (s.statement === "Weekly article share") {
          s.statement = `Weekly Article Share #${numberOfShare + 1}`;
          numberOfShare += 1;
        }
        rewardArr.push(s);
      });
    finalArr = finalArr.concat(debitArr, creditArr, discountArr, rewardArr);
    finalArr = _.sortBy(finalArr, function (o) {
      return new moment(o.timestamp);
    });
    setwalltesHistory(finalArr.reverse());
  }, [props.walletHistory]);

  return (
    <Modal animationType='slide' transparent visible={true}>
    <View style={styles.mainView}>
      <View style={styles.innerView}>
        <View style={styles.totalMain}>
          <TouchableOpacity
            onPress={() => props._handleImmibuckModal()}
            style={styles.cancelButton}
          >
            <Image
              style={{ height: 10, width: 10 }}
              source={Images.cancelGrey}
            />
          </TouchableOpacity>
          <CopilotStep
            text="An internal reward system which can be used to avail discounts, unlock premium features and pay for in-app services"
            order={1}
            name="immibuck1"
          >
            <WalkthroughableView style={{ width: 200, alignSelf: "center" }}>
              <WalkthroughableView style={styles.totalRewardTitleView}>
                <WalkthroughableText style={[styles.woho, { color: "white" }]}>
                  {activeTab === 0 ? props.rewardSum : props.immiBuck_balance}
                </WalkthroughableText>
                <WalkthroughableImage
                  source={Images.immibuck}
                  style={{ height: hasNotch ? 40 : 40, width: 45 }}
                />
              </WalkthroughableView>
              <WalkthroughableText
                style={{
                  fontFamily: family.semibold,
                  color: "white",
                  fontSize: 15,
                  marginBottom: 7,
                  alignSelf: "center",
                }}
              >
                {activeTab === 0 ? "Total Rewards" : "Balance"}
              </WalkthroughableText>
            </WalkthroughableView>
          </CopilotStep>
          <View style={styles.tabView}>
            <TouchableOpacity
              onPress={() => setActiveTab(0)}
              style={{
                borderBottomWidth: activeTab === 0 ? 1 : 0,
                borderColor: "white",
              }}
            >
              <Text
                style={{
                  fontFamily: family.semibold,
                  color: "white",
                  fontSize: 15,
                  marginBottom: 7,
                }}
              >
                Total Rewards
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              hitSlop={{ left: 15, right: 15 }}
              onPress={() => setActiveTab(1)}
              style={{
                borderBottomWidth: activeTab === 1 ? 1 : 0,
                borderColor: "white",
              }}
            >
              <CopilotStep
                text="Immibucks transaction history can be viewed here"
                order={2}
                name="wallet"
              >
                <WalkthroughableText
                  style={{
                    fontFamily: family.semibold,
                    color: "white",
                    fontSize: 15,
                    marginBottom: 7,
                  }}
                >
                  Wallet
                </WalkthroughableText>
              </CopilotStep>
            </TouchableOpacity>
          </View>
        </View>
        {activeTab == 0 ? (
          <View style={styles.cardContainer}>
            <FlatList
              data={rewardArr}
              numColumns={2}
              extraData={props.milestonesData}
              showsVerticalScrollIndicator={false}
              key={activeTab === 0 ? "reward" : "wallet"}
              renderItem={({ item, index }) => (
                <View
                  style={[
                    styles.coinsStyle,
                    {
                      marginLeft: index % 2 !== 0 ? 25 : 5,
                      opacity: isAccomplished(item.type) ? 1 : 0.5,
                    },
                  ]}
                >
                  <View style={{ top: 20 }}>
                    <Text style={styles.cardtext}>{item.type}</Text>
                    <Text style={[styles.cardtext, { fontSize: 24 }]}>
                      {item.amount}
                    </Text>
                  </View>
                  <Image source={Images.immibuck} style={styles.immibuckImg} />
                </View>
              )}
            />
          </View>
        ) : (
          <View style={{ flex: 0.88 }}>
            {
              <FlatList
                data={walltesHistory}
                style={{ flex: 1 }}
                showsVerticalScrollIndicator={false}
                renderItem={({ item, index }) => {
                  let trans_date = item.timestamp.split(" ");
                  let sign =
                    item.type == "debit" || item.type == "discount" ? "-" : "+";
                  return (
                    <TouchableOpacity
                      activeOpacity={1}
                      key={item.reference_id}
                      style={styles.walletMain}
                    >
                      <View
                        style={{
                          borderBottomWidth: 1,
                          width: "100%",
                          borderColor: "#BDBDBD",
                        }}
                      >
                        <Text style={styles.walletTitleText}>
                          {trans_date[0] +
                            " " +
                            trans_date[1] +
                            " " +
                            trans_date[2] +
                            " " +
                            trans_date[3]}
                        </Text>
                      </View>
                      <View style={styles.walletItem}>
                        <Text style={styles.walletItemText}>
                          {item.statement}
                        </Text>
                        <Text
                          style={[
                            styles.walletItemText,
                            { color: sign == "+" ? "green" : "red" },
                          ]}
                        >
                          {sign + item.amount}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  );
                }}
              />
            }
          </View>
        )}
        <TouchableOpacity
          onPress={handleStorePress}
          style={styles.storeBut}
        >
          <Text
            style={{
              fontFamily: family.semibold,
              color: "white",
              padding: 7,
              paddingRight: 16,
              paddingLeft: 16,
            }}
          >
            Store
          </Text>
        </TouchableOpacity>
      </View>
    </View>
    </Modal> 
  );
}

export default copilot({
  tooltipComponent: TooltipComponent,
  stepNumberComponent: StepNumberComponent,
  verticalOffset: Platform.OS == "android" ? 30 : 0,
  animated: true,
})(TotalRewardModal);
