import React from "react";
import { View } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { likeOrDislikeMediaContent, syncSavedNews } from "../../../../actions/mainMedia.actions";
import ArticleComponent from "./ArticleComponent";

export const Articles = (props) => {
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const Token = useSelector((state) => state.authReducer.authData.token);
  const savedNews = useSelector(
    (state) => state.mainMediaReducer.gState.savedArticles
  );
  const dispatch = useDispatch();
  const removeArticleAsFavorite = async (item) => {
    await dispatch(
      likeOrDislikeMediaContent(
        item,
        userDetails?.userid,
        Token,
        true,
        item["news_id"] ? "news" : "story"
      )
    );
  };

  const _renderModalContent = (url, articleId, actionType) => {
    postData = {};
    postData.currUrl = url;
    props.navigation.navigate("ModalView", postData);
  };

  React.useEffect(() => {
    if (Token) {
      dispatch(syncSavedNews(userDetails?.userid, Token));
    }
  }, [Token]);

  return (
    <View
      style={{
        width: "100%",
        marginBottom: -40,
        marginHorizontal: "2%",
      }}
    >
      <ArticleComponent
        userDetails={userDetails}
        Token={Token}
        removeArticleAsFavorite={removeArticleAsFavorite}
        _renderModalContent={_renderModalContent}
        savedNews={Object.values(savedNews)}
      />
    </View>
  );
};
