import { combineReducers } from 'redux'
const initialState = {
  updatedAt: '',
  contentData: [],
  values: {},
  date: '',
  dropdownValues: [],
  selectedArray: []
}
const eligibilityData = (state = initialState, action) => {
  // console.log("ACTION", action);
  
  switch (action.type) {

    case 'FETCH_QUESTIONAIRE':
      return {
        contentData: action.payload,
        values: {},
        date: '',
        selectedArray: []
      }
    case 'CLEAR_QUESTIONS_DATA':
      return {
        ...state,
        values: {},
        date: '',
        selectedArray: []
      }
    case 'SET_PASSPORT_DATE':
      return {
        ...state,
        date: action.payload
      }
    case 'SET_DROPDOWN':
      // let dataSelected = [];
      // let dataTotal = [];

      // if ((action.key = "total")) {
      //   dataSelected = action.val;
      // } else {
      //   dataTotal = action.val;
      // }
      return {
        ...state,
        dropdownValues: action.payload
      }
    case 'SELECT_DROPDOWN':
      return {
        ...state,
        selectedArray: action.payload
      }

    case 'CHANGE_ELIGIBILITY':
      const id = action.id
      const isTextInput = action.isTextInput
      const title = action.title
      const val = action.val
      const base_unit= action.base_unit
      return {
        ...state,
        updatedAt: new Date(),
        values: {
          ...state.values,
          [id]: {
            value: val,
            id: id,
            isTextInput: isTextInput,
            title: title,
            base_unit: base_unit,
          }
        }
      }

      case 'CHANGE_ELIGIBILITY_CONTENT' :
        // console.log("CONTENT", state);
        const contentData = state
        contentData.contentData = action.payload
        return {
          ...state,
          updatedAt: new Date(),
          values: {
            ...state.values,
          },
          contentData: action.payload
        }


    default:
      return state
  }
}
export default combineReducers({
  eligibilityData
})
