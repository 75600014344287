import { StyleSheet } from "react-native";
import { family } from "../../common";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";

export const multiSelectStyle = {
  item: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  container: {
    width: !mobilBrower() ? "50%" : "90%",
    alignSelf:"center"
  },
  itemText: {
    color: "black",
    fontWeight: "400",
  },
  subItem: {
    color: "grey",
    paddingHorizontal: 10,
    height:"100%"
  },
  selectedItem: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  selectedSubItem: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  selectToggle: {
    // paddingVertical: !mobilBrower() ? 3 : 0,
    justifyContent: "space-between",
  },
  chipsWrapper: {
    backgroundColor: "yellow",
  },
  selectToggleText: {
    color: "black",
    fontSize: 14,
    // marginBottom: 20,
  },
  subSeparator:{
    backgroundColor:"transparent"
  },
  button: {
    backgroundColor: "#030135",
    flex:1
  },
  scrollView: { paddingHorizontal: 0 ,flex:1},
};

export const styles = StyleSheet.create({
  formView: {
    alignSelf: "center",
    width:mobilBrower()?"100%":"70%"
  },
  checkBox: {
    height: 10,
    width: 10,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 0.5,
  },
  seelctedBox: {
    height: 6,
    width: 6,
    backgroundColor: "green",
  },
  formItem: {
    alignSelf: "center",
    width: "95%",
    marginTop: 15,
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
  },
  submitButton: {
    alignItems: "center",
    justifyContent: "center",
    width: "95%",
    padding: 15,
    marginTop: 0,
    marginBottom: 30,
    backgroundColor: "black",
    borderRadius: 5,
    alignSelf: "center",
  },
  question: {
    fontSize: responsiveText(20),
    fontFamily: family.effra,
    width: "95%",
  },
  submitText: {
    fontSize: responsiveText(18),
    color: "white",
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: family.effra,
  },
  textInputView: {
    borderBottomWidth: 0.6,
  },
  optionDropDown: {
    width: "100%",

    flex: 1,
    marginBottom: 30,
  },
  pickerContainerView: {
    borderBottomWidth: 0.6,
    height: 50,
    marginTop: 20,
    width: "100%",
  },
  optionText: {
    fontSize: responsiveText(20),
    marginLeft: 10,
    width: "95%",
    textAlign: "left",
    fontFamily: family.effra,
  },
  countryName: {
    fontFamily: family.effra,
    fontSize: responsiveText(20),
    textAlign: "center",
    marginBottom: 15,
    marginTop: 25,
  },
  absolute: {
    width: 250,
    height: 300,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
  },
});
