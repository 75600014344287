import React, { useState,useEffect } from "react";
import {
  View,
  Animated,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  Text,
  Platform
} from "react-native";
import { isWeb } from "../../../../common/helpers";
import { heightPercentageToDP } from "../../../../common/responsiveScreen";
import { useSelector } from "react-redux";
const { width, height } = Dimensions.get("window");
import { styles } from "./cards.styles";
import { Home } from "../Home/Home";
import { Paths } from "../Paths/Paths";
import { Lists } from "../Lists/Lists";
import { Articles } from "../Articles/Articles";

const SPACING = 15;
const ITEM_SIZE = width * 0.85;
const BACKDROP_HEIGHT = heightPercentageToDP(53);

const TABS = ["My Paths", "My Articles", "My Orders"];

export const Cards = ({ routeParams = {}, appRef }) => {
  const scrollX = React.useRef(new Animated.Value(0)).current;
  const immipathToView = useSelector(state => state.pathReducer?.getImmipathDetails?.immipathToView)
  const flatlistRef = React.useRef();
  const [pathInfo,setPathInfo]=useState({});

  useEffect(() => {
    if(immipathToView?.app_ref && appRef) {
      setTimeout(() => {
        flatlistRef?.current && flatlistRef.current.scrollToIndex({
          animated: true,
          index: 1,
          viewOffset: SPACING * 2,
        });
      }, 2000);
    }
  }, [immipathToView])
  
  const scrollToIndex = (index) => {
    flatlistRef.current.scrollToIndex({
      animated: true,
      index: index + 1,
      viewOffset: SPACING * 2,
    });
  };

  const SLIDE_DATA = [
    {
      component: <Home />,
    },
    {
      component: <Paths pathInfo={pathInfo} />,
    },
    {
      component: <Articles />,
    },
    {
      component: <Lists />,
    },
  ];

  useEffect(() => {
    if (routeParams.app_ref &&  routeParams.app_ref!== "") {
      const timeoutref=setTimeout(()=>{
        scrollToIndex(0);
        setPathInfo(routeParams);
      },2000);
      return ()=>{
        clearTimeout(timeoutref);
      }
    }
  }, [routeParams])

  return (
    <View style={styles.container}>
      <View style={[styles.tabs]}>
        {TABS.map((tab, index) => {
          const inputRange = [
            index * ITEM_SIZE,
            (index + 1) * ITEM_SIZE,
            (index + 2) * ITEM_SIZE,
          ];

          const opacity = scrollX.interpolate({
            inputRange,
            outputRange: [0.2, 1, 0.2],
            extrapolate: "clamp",
          });

          return (
            <TouchableOpacity
              style={{
                width: "30.333%",
              }}
              onPress={() => scrollToIndex(index)}
            >
              <Animated.Text
                style={[
                  styles.tabText,
                  {
                    opacity,
                  },
                ]}
              >
                {tab}
              </Animated.Text>
            </TouchableOpacity>
          );
        })}
      </View>
      <Animated.FlatList
        showsHorizontalScrollIndicator={false}
        data={SLIDE_DATA}
        ref={flatlistRef}
        horizontal
        bounces={false}
        decelerationRate={Platform.OS === "ios" ? 0 : 0.8}
        renderToHardwareTextureAndroid
        snapToInterval={ITEM_SIZE}
        snapToAlignment="start"
        scrollEventThrottle={16}
        style={{
          marginTop: -20,
        }}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { x: scrollX } } }],
          { useNativeDriver: false }
        )}
        renderItem={({ item, index }) => {
          const inputRange = [
            (index - 1) * ITEM_SIZE,
            index * ITEM_SIZE,
            (index + 1) * ITEM_SIZE,
          ];

          const translateY = scrollX.interpolate({
            inputRange,
            outputRange: [75, 40, 75],
            extrapolate: "clamp",
          });

          const opacity = scrollX.interpolate({
            inputRange,
            outputRange: [0, 1, 0],
            extrapolate: "clamp",
          });
          return (
            <Animated.View
              style={{
                marginHorizontal: SPACING,
                alignItems: "center",
                marginLeft: index === 0 ? width * 0.08 : 0,
                marginRight: index === SLIDE_DATA.length - 1 ? width * 0.08 : 0,
                transform: [{ translateY }],
                opacity,
                borderRadius: 34,
                display: "flex",
                justifyContent: "space-between",
                width: ITEM_SIZE,
                borderWidth: 1,
                height: BACKDROP_HEIGHT - 20,
                borderColor: "rgb(200,200,200)",
                marginBottom: BACKDROP_HEIGHT * 0.1,
              }}
            >
              <ScrollView
                scrollEventThrottle={160}
                style={{
                  width: ITEM_SIZE,
                  padding: index === 0 ? 25 : 0,
                  marginBottom: 15,
                  marginTop: 15,
                }}
              >
                {item.component}
              </ScrollView>
              {index != 0 && (
                <TouchableOpacity
                  onPress={() => scrollToIndex(-1)}
                  style={styles.backToProfileBtn}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    Back to Profile Overview{" "}
                  </Text>
                </TouchableOpacity>
              )}
            </Animated.View>
          );
        }}
      />
      <View
        style={[
          {
            bottom: isWeb() ? 10 : - BACKDROP_HEIGHT * 0.05,
            width: "100%",
            position: "absolute",
            zIndex: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          },
        ]}
      >
        {[...Array(SLIDE_DATA.length)].map((e, index) => {
          const inputRange = [
            (index - 1) * ITEM_SIZE,
            index * ITEM_SIZE,
            (index + 1) * ITEM_SIZE,
          ];

          const width = scrollX.interpolate({
            inputRange,
            outputRange: [8, 18, 8],
            extrapolate: "clamp",
          });
          return (
            <Animated.View
              style={[
                {
                  width,
                  height: 8,
                  backgroundColor: "rgb(180,180,180)",
                  marginLeft: 7,
                  borderRadius: 15,
                },
              ]}
            />
          );
        })}
      </View>
    </View>
  );
};
