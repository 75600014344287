import { StyleSheet } from "react-native"
import { responsiveText } from "../../../../common/normalize"

export const styles = StyleSheet.create({
    main: {
        flex: 1,
        alignItems: "center"
    },
    headerText: {
        fontSize: responsiveText(28),
        textAlign: "center",
        marginVertical: 15,
        color: "#fff",
        fontWeight: "bold"
    }
})