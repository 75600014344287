import React, { useState } from 'react'
import { View, TouchableOpacity, Text, ScrollView, Pressable, Image } from "react-native";
import { heightPercentageToDP } from 'react-native-responsive-screen';
import Images from '../../../common/images';
import { apiPaths, Network } from '../../../service/api';
import BullitinModal from '../../../components/BullitinModal/BullitinModal';
import { styles } from './visaDetailed.styles';
import { useDispatch, useSelector } from "react-redux";
import TimelineView from './TimelineView';
import helpers, { isWeb } from '../../../common/helpers';
import { useHistory } from 'react-router-dom';
import NavigationService from '../../../navigation/NavigationService';
import VisaSaved from './VisaSaved';
import RewardModal from '../../RewardModal';
import { getAppTrackerList } from '../../../actions/dashboard.actions';
import PathDetailsComponent from '../../../components/ApplicationDetailsModal/pathDetailsComponent';
import { getSavedUserPath } from '../../../actions/path.actions';
import { get } from 'lodash';

export default function VisaDetailsModal({ visible, selectedPath, onClose, setshowErrorModal }) {
  const [loading, setloading] = useState(false)
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);
  const token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const isPathSaved = useSelector((state) => Object.values(state.pathReducer.getImmipathDetails.userSavedPaths || {}).find(path => get(path, 'immipath_data.immipath_id', 0) === selectedPath.immipath_id));
  const [showTimelineModal, setshowTimelineModal] = useState(false);
  const [timelineData, settimelineData] = useState(null);
  const [showRewardModal, setshowRewardModal] = useState(false);
  const [appRefId, setAppRefId] = useState(null);
  const [showApplicationSavedModal, setshowApplicationSavedModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleTimeline = () => {
    if (guestLogin) {
      onClose()
      setshowErrorModal(true)
      return
    }
    setloading(true);
    const url = "/immipaths/timelines?";
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let Obj = {
      exploration_id: null,
      immipath_ids: [selectedPath?.immipath_id],
      base_filters: {},
    };
    Network(url, "POST", Obj, headers)
      .then((res) => {
        if (res.status == "Success") {
          setloading(false);
          settimelineData(res.timelines_info);
          setTimeout(() => {
            setshowTimelineModal(true);
          }, 500);
        }
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const handleShortlistPress = () => {
    if (guestLogin) {
      onClose()
      setshowErrorModal(true)
      return
    }
    if (isPathSaved) {
      handleModalClose()
      return;
    }
    const postData = {
      application_fields: {},
      application_metadata: {},
      exploration_id: null,
      user_id: userDetails?.userid,
    };
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let url = `${selectedPath.immipath_id}/save`;
    Network(
      apiPaths.application_referrals + url,
      "POST",
      postData,
      headers
    ).then(async (res) => {
      await dispatch(
        getAppTrackerList(
          userDetails?.userid,
          userDetails?.userid + "@" + token
        )
      );
      dispatch(getSavedUserPath(userDetails?.userid, token))
      setAppRefId(res.application_reference);
      setshowApplicationSavedModal(true)
    });
  };

  const getImage = (id) => {
    let image = '';
    switch (id) {
      case 1:
        image = Images.usFlag
        break;
      case 2:
        image = Images.CANADAFlag
        break;
      case 3:
        image = Images.Ausflag
        break;
      case 4:
        image = Images.singaporeFlag
        break;
      case 5:
        image = Images.UKFLag
        break;
      case 6:
        image = Images.UAEFLAG
        break;
      case 7:
        image = Images.GermanyFlag
        break;
      case 8:
        image = Images.netherlanFlag
        break;
      case 9:
        image = Images.NZFlag
        break;
      case 10:
        image = Images.JapanFlag
        break;
      default:
        break;
    }
    return image
  }

  const handleModalClose = () => {
    setshowApplicationSavedModal(false);
    onClose()
    let params = {
      app_ref: isPathSaved?.application_id || appRefId,
      sortcut: true,
      selectedPath: selectedPath,
    }
    if (isWeb()) {
      history.push("profile", params)
    } else {
      NavigationService.navigate("NewProfileScreen", params);
    }
  };

  const handleRewardModal = () => {
    setshowApplicationSavedModal(false);
    setTimeout(() => {
      setshowRewardModal(true);
    }, 500);
  };

  return (
    <>
      <BullitinModal
        heading=""
        visible={visible}
        onClose={onClose}
        handleClose={onClose}
        closeIcon={!showTimelineModal && "closeBlack"}
      >
        {showRewardModal ?
          <RewardModal
            visible={true}
            price={100}
            text='congratulations on shortlisting your first path!'
            title={'Wohoo!\nAnother reward for you!'}
            onClosed={() => setshowRewardModal(false)}
          /> :
          <View />}
        <View style={styles.notifItemContainer}>
          {
            showTimelineModal ?
              <>
                <TimelineView
                  onClose={() => setshowTimelineModal(false)}
                  timelineData={timelineData} />
              </> :

              !showApplicationSavedModal ? <View style={{ flex: 1 }}>
                {selectedPath !== null ? (
                  <View style={styles.detailsCard}>
                    {/* <View style={{ alignSelf: "center", marginBottom: heightPercentageToDP('3%') }}>
                      <Image
                        source={getImage(selectedPath.country_id)}
                        style={[styles.iconV]}
                      />
                    </View> */}
                    <View
                      style={{
                        // height: heightPercentageToDP("32%"),
                        paddingTop: 0,
                        paddingBottom: 5,
                        flex: 1

                      }}
                    >
                      <ScrollView showsVerticalScrollIndicator={true} nestedScrollEnabled={true}>
                        <Pressable>
                          <PathDetailsComponent
                            pathDetails={selectedPath}
                          />
                          {/* <Text
                            style={[
                              styles.details,
                              {
                                fontWeight: "bold",
                                marginBottom: 5,
                                textAlign: "center",
                              },
                            ]}
                          >
                            Description
                          </Text>
                          <Text style={[styles.description]}>
                            {selectedPath.path_description}
                          </Text>

                          <View
                            style={{
                              padding: 5,
                            }}
                          >
                            <View style={[styles.detailWrapper, { marginTop: 15 }]}>
                              <Image source={Images.bullet} style={styles.bullet} />
                              <View style={styles.detailContentWrapper}>
                                <Text style={styles.detailsTitle}>Visa type: </Text>
                                <Text style={styles.details}>
                                  {
                                    !selectedPath.goalName && selectedPath?.goal_ids ?
                                    helpers.getGoalName(selectedPath?.goal_ids).join(', ') :
                                    selectedPath.goalName
                                  }
                                </Text>
                              </View>
                            </View>
                            <View style={styles.detailWrapper}>
                              <Image source={Images.bullet} style={styles.bullet} />
                              <View style={styles.detailContentWrapper}>
                                <Text style={styles.detailsTitle}>
                                  Approximate time for application:{" "}
                                </Text>
                                <Text style={styles.details}>
                                  {selectedPath.time_for_user_application_min ===
                                    selectedPath.time_for_user_application_max
                                    ? ""
                                    : selectedPath.time_for_user_application_max}{" "}
                                  days
                                </Text>
                              </View>
                            </View>
                            <View style={styles.detailWrapper}>
                              <Image source={Images.bullet} style={styles.bullet} />
                              <View style={styles.detailContentWrapper}>
                                <Text style={styles.detailsTitle}>Validity: </Text>
                                <Text style={styles.details}>
                                  {selectedPath.validity}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View style={{ alignItems: "flex-start" }}>
                            <View style={{ alignSelf: "center" }}>
                              <Text
                                style={[
                                  styles.details,
                                  {
                                    fontWeight: "bold",
                                    paddingTop: 10,
                                    marginBottom: 5,
                                  },
                                ]}
                              >
                                Eligibility Criteria
                              </Text>
                            </View>
                            {selectedPath.eligibility_criteria.length > 0 ? (
                              selectedPath.eligibility_criteria.map((s) => (
                                <View
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 5,
                                  }}
                                >
                                  <Image
                                    source={Images.bullet}
                                    style={[styles.bullet, { marginBottom: 10 }]}
                                  />
                                  <Text style={[styles.details, { width: "90%" }]}>
                                    {s}
                                  </Text>
                                </View>
                              ))
                            ) : (
                              <View />
                            )}
                          </View> */}
                        </Pressable>
                      </ScrollView>
                    </View>
                  </View>
                ) : (
                  <View />
                )}
                {selectedPath !== null ? (
                  <View style={styles.btnContainer}>
                    <TouchableOpacity
                      activeOpacity={0.8}
                      onPress={handleTimeline}
                      style={[
                        styles.shortButton,
                        { backgroundColor: "#030135" },
                      ]}
                    >
                      <Text style={styles.shortText}>View Timeline</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      activeOpacity={0.8}
                      onPress={handleShortlistPress}
                      style={[
                        styles.shortButton,
                        { backgroundColor: isPathSaved ? "#030135" : "green" }
                      ]}
                    >
                      <Text style={styles.shortText}>{isPathSaved ? "View" : "Save"}</Text>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View />
                )}
              </View> : <VisaSaved
                visible={true}
                token={token}
                handleRewardModal={handleRewardModal}
                handleModalClose={handleModalClose}
                userid={userDetails?.userid}
              />
          }
        </View>
      </BullitinModal>

    </>
  )
}