import React, { useState, useEffect } from 'react'
import {
    StyleSheet, Text, View, TouchableOpacity, Image, Platform, TextInput
} from "react-native";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateUserDetails } from '../actions/auth.actions';
import fontFamily from '../common/fontFamily';
import { mobilBrower } from '../common/helpers';
import HeaderComponent from '../common/NewHeader/NewHeader';
import WebFooter from '../common/WebFooter';
import config from '../common/config';
import MultiSelectList from '../pages/MultiSelectList';
import { Network } from '../service/api';
import { orderConfirmatiopnData } from '../actions/referral.actions';
import toolbarLogo from "../images/new_logo_toolbar.png";
import Loader from '../components/Loader';
import Images from '../common/images';

const _handleRazorPay = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script);
    })
}

const CustomInput = ({ label, value, error, onChangeText = () => { }, placeholder, containerStyle = {} }) => (
    <View style={[styles.inputContanier, containerStyle]}>
        <Text style={styles.title}>
            {label}
        </Text>
        <View style={styles.inputBox}>
            <TextInput
                placeholder={placeholder}
                value={value}
                onChangeText={onChangeText}
                style={styles.textInputStyle}
            />
        </View>
        {
            error ?
                <Text style={styles.errorText}>
                    {error}
                </Text> : <View />
        }
    </View>
)

export default function BillingDetails() {
    const [address1, setaddress1] = useState('');
    const [address2, setaddress2] = useState('');
    const [phone, setphone] = useState('')
    const [name, setname] = useState('')
    const [errorMessage, seterrorMessage] = useState('');
    const [city, setcity] = useState('');
    const [Userstate, setUserstate] = useState('')
    const cartItems = useSelector(state => state.referralReducer.referralData.cartItems);
    const [productIds, setproductIds] = useState([])
    const [total, settotal] = useState(0);
    const [deliveryFeeTotal, setdeliveryFeeTotal] = useState(0)
    const [loading, setloading] = useState(false)
    const [country, setcountry] = useState('');
    const [pincode, setpincode] = useState('')
    const [showErrorModal, setshowErrorModal] = useState(false)
    const [error, seterror] = useState({});
    const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
    const token = useSelector(state => state.authReducer.authData.token);
    const dispatch = useDispatch();
    const [opneCountryDropDown, setOpenCountryDropDown] = useState(false)
    const { goBack, push } = useHistory()

    const handleSelect = (item) => {
        setcountry(item)
    }

    const checkZipCodeValidation = () => {
        let regex = new RegExp("^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$");
        if (regex.test(pincode.trim())) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        setname(userDetails?.first_name + ' ' + userDetails?.last_name)
        if (userDetails?.address) {
            let { address_line1, address_line2, country, city, pincode, state } = userDetails?.address;
            setaddress1(address_line1.trim())
            setaddress2(address_line2.trim())
            setcity(city.trim())
            setUserstate(state.trim())
            setpincode(pincode.trim())
            setcountry(country.trim())
        }
        if (userDetails?.phone) {
            setphone(userDetails?.phone.trim())
        }
    }, [userDetails])

    const handleProceed = async () => {
        let error = {};
        if (address1 == '') {
            error['address1'] = 'Please enter address';
        }
        if (address2 == '') {
            error['address2'] = 'Please enter address';
        }
        if (city == '') {
            error['city'] = 'Please enter city';
        }
        if (Userstate == '') {
            error['Userstate'] = 'Please enter state'
        }
        if (pincode == '') {
            error['pincode'] = 'Please enter pincode'
        } else {
            let result = checkZipCodeValidation();
            if (!result) {
                error['pincode'] = 'Out of shipping range! We are working on shipment to all countries soon!'
            }
        }
        if (country == '') {
            error['country'] = 'Please enter country'
        }
        if (phone.length < 10) {
            error['phone'] = 'Please enter valid phone number';
        }
        if (Object.entries(error).length > 0) {
            seterror(error)
            return
        }

        let body = {
            address: {
                address_line1: address1,
                address_line2: address2,
                country: country,
                city: city,
                pincode: pincode,
                state: Userstate
            },
            name: name,
            phone: phone,
            email_address: userDetails?.email_address
        }

        handlePress(body);
        let postData = userDetails;
        postData.address = body.address;
        postData.phone = body.phone;
        let res = await dispatch(updateUserDetails(postData, userDetails?.userid, token))
        if (res.status == 'User Updated!') {
            setloading(false)
            push('/cart')
        } else {
            setloading(false)
        }
        // goBack()
    }

    useEffect(() => {
        let total = 0
        let productIds = [];
        cartItems && cartItems.forEach((s) => {
            let price = s.offer_price ? s.offer_price : s.price;
            let quantity = s.quantity ? s.quantity : 1;
            let _t = price * quantity;
            total += _t;
            productIds.push(s.product_id)
        })
        setproductIds(productIds)
        total += deliveryFeeTotal
        settotal(total)
    }, [cartItems, deliveryFeeTotal])


    const handlePress = async (addressData) => {
        const url = '/razorpay/create_order';
        let razorPayIds = {};
        let razorPayPaymentStatus = false;
        const logPayUrl = `/users/${userDetails?.userid}/log_payment`;
        const headers = {
            Accept: "application/json",
            Authorization: `${userDetails?.userid}@${token}`
        }
        let body = {
            order_amount: total * 100,
            statement: `immistore purchase for ${productIds.join(',')}`,
            order_currency: 'INR',
            order_receipt: `immi_recpt_IMST${userDetails?.userid}_${new Date().getTime()}`
        }

        const razorpay_key = global.IS_TEST_SERVER ? 'rzp_test_dIpZ7foMvdqmL7' : 'rzp_live_r5d9JWaeFWN56Z'
        Network(url, 'POST', body, headers).then(async (res) => {
            var options = {
                description: 'Immistore Purchase',
                image: toolbarLogo,
                currency: 'INR',
                key: razorpay_key,
                "handler": function (response) {
                    setloading(true)
                    razorPayPaymentStatus = true;
                    razorPayIds.razorpay_payment_id = response.razorpay_payment_id;
                    razorPayIds.razorpay_order_id = response.razorpay_order_id;
                    razorPayIds.razorpay_signature = response.razorpay_signature;
                    Network(logPayUrl, 'POST', paymentBody, headers);
                    razorPayIds.order_id = res.order_details.id;
                    razorPayIds.order_receipt = paymentBody.payment_type.additional_info.order_receipt;
                    registerPurchase(razorPayIds, addressData, true)
                },
                amount: total * 100,
                name: 'Immigreat Pvt. Ltd.',
                order_id: res.order_details.id,
                prefill: {
                    email: addressData.email_address,
                    contact: addressData.phone,
                    name: addressData.name
                },
                theme: { color: '#FFAA00' }
            }
            const paymentBody = {
                user_id: userDetails?.userid,
                amount: total,
                statement: `immistore purchase for ${productIds.join(',')}`,
                payment_type: {
                    type: 'razorpay',
                    additional_info: {
                        order_id: res.order_details.id,
                        order_receipt: body.order_receipt,
                        products: cartItems
                    }
                }
            }
            const razorypayResponse = await _handleRazorPay('https://checkout.razorpay.com/v1/checkout.js');
            if (!razorypayResponse) {
                alert('Razorpay failed to load!');
                return
            }
            try {
                const payObj = new window.Razorpay(options);
                payObj.open();
                payObj.on('payment.failed', function (response) {
                    setloading(false)
                    registerPurchase(paymentBody.payment_type.additional_info, addressData, false)
                })
            } catch (error) {

            }
        })
    }

    const registerPurchase = async (data, addressData, payStatus) => {
        const url = '/wallet/immistore_order_history';
        let purhase_url = '/wallet/immistore_purchase';
        const headers = {
            Accept: "application/json",
            Authorization: `${userDetails?.userid}@${token}`
        }
        try {
            let payload = {
                razorpay_order_details: data,
                orders: cartItems,
                address: addressData.address,
                phone: addressData.phone,
                email_address: addressData.email_address,
                name: addressData.name,
                is_payment_successful: payStatus
            }
            let res = await Network(purhase_url, 'POST', payload, headers);
            if (payStatus) {
                push('/cart?purchaseSuccess=true')
                setloading(false)
                let orderdetails = {
                    orderId: res.order_id,
                    items: cartItems
                }
                dispatch(orderConfirmatiopnData(orderdetails))
            }
        } catch (error) {
        }
    }

    return (
        <View>
            <HeaderComponent showback={true} screenTitle={mobilBrower() ? "Billing Details" : ""} />
            {
                loading ? <Loader /> : <View />
            }
            <View style={styles.mainView}>
                {!mobilBrower() ?
                    <View style={{ width: "90%", flexDirection: "row", alignItems: "center", marginVertical: 30, }}>
                        <TouchableOpacity onPress={goBack}>
                            <Image source={Images.leftArrow} style={{ width: 20, height: 20 }} />
                        </TouchableOpacity>
                        <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                            <Text style={styles.mainView_title}>Billing Details</Text>
                        </View>
                    </View> : null}
                <CustomInput
                    key="name"
                    label="Name"
                    placeholder="Name"
                    value={name}
                    onChangeText={setname}
                />
                <CustomInput
                    key="address1"
                    label="Address Line 1"
                    placeholder="Address Line 1"
                    value={address1}
                    onChangeText={(e) => setaddress1(e)}
                    error={error?.address1}
                />
                <CustomInput
                    key="address2"
                    label="Address Line 2"
                    placeholder="Address Line 2"
                    value={address2}
                    onChangeText={setaddress2}
                    error={error?.address2}
                />
                <View style={[styles.inputContanier, { flexDirection: 'row' }]}>
                    <CustomInput
                        key="city"
                        label="City"
                        placeholder="City"
                        value={city}
                        onChangeText={setcity}
                        error={error?.city}
                        containerStyle={{ marginRight: 5 }}
                    />
                    <CustomInput
                        key="state"
                        label="State"
                        placeholder="State"
                        value={Userstate}
                        onChangeText={setUserstate}
                        error={error?.Userstate}
                    />
                </View>
                <View style={[styles.inputContanier, { flexDirection: 'row' }]}>
                    <View style={[styles.inputContanier, { marginRight: 5 }]}>
                        <Text style={styles.title}>
                            Country
                        </Text>
                        <TouchableOpacity onPress={() => setOpenCountryDropDown(true)} style={styles.inputBox}>
                            <Text style={styles.textInputStyle}>
                                {country !== '' ? country : 'Country'}
                            </Text>
                        </TouchableOpacity>
                        {
                            error?.country ?
                                <Text style={styles.errorText}>
                                    {error?.country}
                                </Text> : <View />
                        }
                    </View>
                    <CustomInput
                        key="pincode"
                        label="pincode"
                        placeholder="pincode"
                        value={pincode}
                        onChangeText={setpincode}
                        error={error?.pincode}
                    />
                </View>
                <CustomInput
                    key="phone"
                    label="Phone number"
                    placeholder="Phone number"
                    value={phone}
                    onChangeText={setphone}
                    error={error?.phone}
                />
                <TouchableOpacity onPress={handleProceed} style={styles.proceedButton}>
                    <Text style={styles.proceedButtontext}>
                        Proceed to Payment
                    </Text>
                </TouchableOpacity>
            </View>
            {
                !mobilBrower() && <WebFooter />
            }
            {
                opneCountryDropDown ?
                    <MultiSelectList
                        data={config.countries_json}
                        _handleSelect={handleSelect}
                        title={'Select your country'}
                        onclose={() => setOpenCountryDropDown(false)}
                    /> : <View />
            }
        </View>
    )
}

const styles = StyleSheet.create({
    mainView: {
        width: mobilBrower() ? '100%' : '60%',
        alignSelf: 'center',
        marginBottom: '4vh',
        alignItems: 'center'
    },
    mainView_title: {
        fontWeight: '600',
        fontFamily: fontFamily.effra,
        fontSize: '3.5vh',
    },
    inputContanier: {
        width: '80%',
        marginTop: 30,
        flex: 1,
        marginBottom: '2vh',
        alignSelf: 'center',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    CityinputBox: {
        width: '100%',
        backgroundColor: 'white',
        height: Platform.OS == 'ios' ? 35 : 40,
        justifyContent: 'center',
        borderRadius: 10,
        marginTop: 8,
        borderColor: 'grey',
        borderWidth: Platform.OS == 'ios' ? 0 : 0.5,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 1,
    },
    cityBox: {
        width: '45%'
    },
    proceedButtontext: {
        color: 'white',
        fontSize: '2vh',
        fontFamily: fontFamily.effra,
        fontWeight: '700'
    },
    proceedButton: {
        marginTop: '5vh',
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        height: '5vh',
        borderRadius: 10,
        backgroundColor: 'rgba(241, 171, 50, 1)',
    },
    title: {
        fontSize: '2vh',
        fontFamily: fontFamily.effra,
        marginLeft: '1vh'
    },
    inputBox: {
        width: '100%',
        backgroundColor: 'white',
        height: Platform.OS == 'ios' ? 35 : Platform.OS == 'android' ? 40 : mobilBrower() ? 55 : 45,
        // elevation: 12,
        justifyContent: 'center',
        borderRadius: 10,
        marginTop: 8,
        padding: 20,
        shadowColor: "rgba(0, 0, 0, 0.1)",
        shadowOpacity: 0.8,
        shadowRadius: 4,
        shadowOffset: {
            height: 1,
            width: 1
        }
    },
    textInputStyle: {
        marginLeft: 10,
        color: '#707070',
        fontWeight: '600',
        fontSize: '1.5vh',
        fontFamily: fontFamily.effra,
    },
    errorText: {
        color: 'red',
        fontSize: 12,
        marginTop: 8
    }
})