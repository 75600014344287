import React from "react";
import { View, Text, Modal, Image, TouchableOpacity } from "react-native";
import Images from "../../../../../../common/images";
import { styles as s } from "./premiumModal.styles";

export const PremiumModal = ({ setPremiumModalVisibility }) => {
  return (
    <Modal visible={true} animationType="fade" transparent>
      <View style={s.wrapper}>
        <View style={s.modal}>
          <View style={[s.closeWrapper]}>
            <TouchableOpacity
              onPress={() => setPremiumModalVisibility(false)}
              style={s.closeIconButton}
              hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
            >
              <Image source={Images.closeBlack} style={s.closeIcon} />
            </TouchableOpacity>
          </View>
          <Image
            source={Images.univPremiumModalImage}
            resizeMode="contain"
            style={[s.image]}
          />
          <Text style={s.text}>
            Compare more universities with our premium version
          </Text>
          <TouchableOpacity style={[s.selectButton]} activeOpacity={0.7}>
            <Text style={s.premiumButtonText}>COMING SOON </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};
