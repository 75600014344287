import React, { useEffect, useState } from 'react';
import { styles as s } from "../section_H.styles";
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
  Pressable,
} from "react-native";
import { helpers } from '../../../../../common';
import Images from '../../../../../common/images';
import { useSelector } from 'react-redux';
import { isArray, isObject, capitalize } from "lodash";
import AvailableCoursesModal from './AvailableCoursesModal';
import { CopilotStep, walkthroughable } from "react-native-copilot";
import { copilotData } from '../../../../../common/helpers';

const CopilotTouch = walkthroughable(TouchableOpacity);

function UnicersityComparator({ sectionedLIst, getIndicator, getTitle, onPressSelect, selectedCard }) {
  const [collapsableIndex, setcollapsableIndex] = useState(0);
  const userDegreeCourseSelections = useSelector(state => state.pathReducer?.getImmipathDetails?.userDegreeCourseSelections)
  const userImmipathSearchResults = useSelector(state => state.pathReducer?.getImmipathDetails?.userImmipathSearchResults)
  const [courses, setcourses] = useState({});
  const [availableCourseModalOpen, setavailableCourseModalOpen] = useState(false);

  const handleClick = (i) => {
    collapsableIndex == i ? setcollapsableIndex(-1) : setcollapsableIndex(i)
  }

  const onAvailableCourseClick = (item) => {
    const { facts } = item;
    let courses = Object.entries(facts).filter((s) => s[1].fact_id == 16);
    if (courses.length > 0) {
      courses = courses[0][1].value;
      setcourses(courses)
      setavailableCourseModalOpen(true)
    }
  }

  const renderValue = (valueArr, value) => {
    if (value == null) {
      return "N/A"
    }
    if (isArray(value)) {
      return value.join(', ')
    }
    if (isObject(value)) {
      return Object.keys(value).reduce((str, key) => {
        str += capitalize(key) + " - " + value[key] + "\n";
        return str;
      }, "")
    }
    return getIndicator(getTitle(valueArr), value);
  }

  return (
    <>
      {sectionedLIst?.length > 0 && sectionedLIst.map((item, index) => {
        return (
          <ScrollView
            key={index}
            scrollEnabled={false}
            contentContainerStyle={{
              alignItems: "center",
              justifyContent: "center",
            }}
            style={[
              s.card,
              selectedCard?.some((s) => s.university_id == item.university_id) && s.selectedCardView,
              {
                marginLeft: index === 0 ? 15 : 7.5,
              },
            ]}
          >
            <Pressable>
              <View style={[s.cardHeader]}>
                <Text style={[s.univName, { height: 100 }]}>{item.university}</Text>
                <Text style={[s.univLocation]}>{item.city_string}</Text>
                <View style={s.imageView}>
                  {
                    item?.logo || item?.logo !== "" ?
                      <Image source={{ uri: item.logo }} style={{ height: '100%', width: '100%', resizeMode: 'contain' }} />
                      :
                      <Text style={[s.univIcon]}>
                        {helpers.getUniversityInitials(item.university)}
                      </Text>
                  }
                </View>
              </View>

              {Object.entries(item.factsSection).map((comParam, i) => {
                if (comParam[0] !== 'Application Info') {
                  return (
                    <View style={[s.params]}>
                      {
                        <TouchableOpacity style={s.typeDropDown} activeOpacity={0.5} onPress={() => handleClick(i)}>
                          <Text style={[s.univName]}>{comParam[0] == 'undefined' ? 'Basic Info' : comParam[0]}</Text>
                          <Image source={Images.dropDownArrow} style={s.dropdownArrow} />
                        </TouchableOpacity>
                      }
                      {
                        collapsableIndex == i &&
                          comParam[0] !== 'Country Facts' && comParam[0] !== 'City Facts' ? comParam[1]?.map((q) => {
                            return (
                              <>
                                {
                                  q.fact_name !== 'Available Courses' && q.fact_name !== 'GPA_graduate' && q.fact_name !== 'GPA_undergraduate' ?
                                    <>
                                      <Text style={[s.univParamLabel]}>
                                        {q.fact_name.replace('_', ' ')}
                                      </Text>
                                      {
                                        typeof q.value !== 'object' ?
                                          <Text style={[s.univParamValue]}>
                                            {q.value !== null ? q.value : 'N/A'}
                                          </Text> : <Text style={[s.univParamValue]}>
                                            {'N/A'}
                                          </Text>
                                      }
                                    </> : userDegreeCourseSelections['Degree'] == 1 && q.fact_name == 'GPA_undergraduate' ?
                                      <>
                                        <Text style={[s.univParamLabel]}>
                                          {q.fact_name.split('_')[0]}
                                        </Text>
                                        {
                                          typeof q.value !== 'object' ?
                                            <Text style={[s.univParamValue]}>
                                              {q.value !== null ? q.value : 'N/A'}
                                            </Text> : <Text style={[s.univParamValue]}>
                                              {'N/A'}
                                            </Text>
                                        }
                                      </> : userDegreeCourseSelections['Degree'] !== 1 && q.fact_name == 'GPA_graduate' ?
                                        <>
                                          <Text style={[s.univParamLabel]}>
                                            {q.fact_name.split('_')[0]}
                                          </Text>
                                          {
                                            typeof q.value !== 'object' ?
                                              <Text style={[s.univParamValue]}>
                                                {q.value !== null ? q.value : 'N/A'}
                                              </Text> : <Text style={[s.univParamValue]}>
                                                {'N/A'}
                                              </Text>
                                          }
                                        </> : <View />
                                }
                              </>
                            )
                          }) : comParam[0] == 'City Facts' ?
                          collapsableIndex == i &&
                          Object.entries(comParam[1]).map((a) => {
                            return (
                              <View style={{ marginTop: 3, alignItems: 'center' }}>
                                <Text style={[s.univParamLabel]}>
                                  {userImmipathSearchResults?.fact_names[a[0]] || 'N/A'}
                                </Text>
                                <Text style={[s.univParamValue]}>
                                  {a[1] !== null ? getIndicator(userImmipathSearchResults?.fact_names[a[0]], a[1]) : 'N/A'}
                                </Text>
                              </View>
                            )
                          }) : comParam && comParam[0] == 'Country Facts' && collapsableIndex == i &&
                          Object.entries(comParam[1])?.map((c) => {
                            return (
                              <View style={{ marginTop: 3, alignItems: 'center' }}>
                                <Text style={[s.univParamLabel]}>
                                  {getTitle(c)}
                                </Text>
                                <Text style={[s.univParamValue]}>
                                  {renderValue(c, c[1][item.country_id])}
                                  {c[1][item.country_id] !== null && c[0]?.includes('literacy') && ' %'}
                                  {c[1][item.country_id] !== null && c[0]?.includes('study') && ' years'}
                                </Text>
                              </View>
                            )
                          })
                      }
                    </View>
                  );
                } else {
                  return <View />
                }
              })}
              <TouchableOpacity
                style={[s.selectButton, { marginTop: 20, marginBottom: 20, backgroundColor: '#F1AB32', }]}
                onPress={() => onAvailableCourseClick(item)}
                activeOpacity={0.7}
              >
                <Text
                  style={{
                    color: "white",
                    padding: 15,
                  }}
                >
                  Available Courses
                </Text>
              </TouchableOpacity>
              <CopilotStep
                text={copilotData.selectedShortlistedCard}
                order={2}
                name="SelectSearchedCard"
              >
                <CopilotTouch
                  style={[s.selectButton]}
                  onPress={() => onPressSelect(item)}
                  activeOpacity={0.7}
                >
                  <Text
                    style={{
                      color: "white",
                      padding: 15,
                    }}
                  >
                    Select
                  </Text>
                </CopilotTouch>
              </CopilotStep>
            </Pressable>
          </ScrollView>
        );
      })}
      {
        availableCourseModalOpen &&
        <AvailableCoursesModal
          courses={courses}
          visible={availableCourseModalOpen}
          onClose={() => setavailableCourseModalOpen(false)}
        />
      }
    </>
  )
}

export default UnicersityComparator;
