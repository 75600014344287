import React, { useState } from 'react'
import { View, Text, TouchableOpacity, Image, } from "react-native";
import { useDispatch } from "react-redux";;
import CreatePassword from '../Password/Password';
import PinValidation from '../PinValidation/PinValidation';
import { createNewUser } from '../../actions/auth.actions';
import SweetAlert from '../SweetAlert';
import Loader from '../Loader';
import { styles } from "./CreateUser.styles"
import Login from '../../pages/NewLoginUi/components/Login';
import { Images } from '../../common';

function CreateUser({ userPostData, onPinValidated = () => { }, title, description, onCancelPress = () => { } }) {
    const [currentComponent, setCurrentComponent] = useState("signIn");
    const [validateData, setValidateData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();


    const handleCreateUser = async (values) => {
        setLoading(true);
        let postData = {
            ...userPostData,
            password_hash: values.shapassword
        }
        const response = await dispatch(
            createNewUser(postData, postData.email_address)
        );
        if (response.status.toString().includes('E-mail Sent to')) {
            let validateData = {};
            validateData.email_address = postData.email_address;
            validateData.otp_type = "new_user_verification";
            validateData.status = response.status;
            SweetAlert.success(response.status)
            setValidateData(validateData)
            setLoading(false);
            setCurrentComponent("pinValidation")
        } else {
            setLoading(false);
            setError(response.status);
            setTimeout(() => {
                setError(null);
            }, 2000)
        }
    }

    const CreatePasswordView = () => (
        <>
            <TouchableOpacity style={{ position: "absolute", top: 10, left: 20 }} onPress={() => setCurrentComponent("signIn")}>
                <Image style={{ height: 25, width: 25, resizeMode: "contain" }} source={Images.backArrow} />
            </TouchableOpacity>
            <CreatePassword onSubmit={handleCreateUser} />
            {error ? <Text style={{ color: "red", fontSize: 16, textAlign: "center", width: "80%", marginTop: 5 }}>{error}</Text> : null}
        </>
    )

    const PinValidationView = () => (
        <PinValidation postData={userPostData} valdidatedata={validateData} onPinValidated={onPinValidated} />
    )


    const SignIn = () => (
        <View style={{ alignItems: "center", width: "90%" }}>
            <Login
                showBack={false}
                onSignUpPress={() => setCurrentComponent("createPassword")}
                onLogin={onPinValidated}
            />
        </View>
    )

    const components = {
        signIn: <SignIn />,
        createPassword: <CreatePasswordView />,
        pinValidation: <PinValidationView />,
    }

    return (
        <View style={{ flex: 1, paddingHorizontal: 10, justifyContent: "center", alignItems: "center" }}>
            {loading ? <Loader /> : null}
            {components[currentComponent]}
        </View>
    )
}

export default CreateUser;
