import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Images } from "../../../../common";
import { Network } from "../../../../service/api";
import { useSelector } from "react-redux";
import NavigationService from "../../../../navigation/NavigationService";
import { isWeb, mobilBrower } from "../../../../common/helpers";
import { styles } from "./Lists.styles";
import { useHistory } from "react-router-dom";

function OrderHistory(props) {
  const [orderHistory, setorderHistory] = useState([]);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);
  const [loading, setloading] = useState(false);
  // const window = Platform.OS == "web" ? useWindowDimensions() : null;
  const history = useHistory();

  const getOrders = async () => {
    const url = "/wallet/immistore_order_history";
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let orderHistory = await Network(url, "GET", null, headers);
    if (orderHistory.status == "Success") {
      setloading(false);
      setorderHistory(orderHistory.order_history);
    } else {
      setloading(false);
    }
  };

  useEffect(() => {
    setloading(true);
    getOrders();
  }, []);

  return (
    <View style={{ flex: 1, marginBottom: 15, }}>
      {/* {loading ? <Loader /> : <View />} */}
      {
        <FlatList
          data={orderHistory}
          bounces={false}
          keyExtractor={(item, index) => index.toString()}
          ListEmptyComponent={() => {
            if (!loading) {
              return (
                <TouchableOpacity activeOpacity={1} style={styles.noDataView}>
                  <Text
                    style={styles.noteText}
                  >
                    Have you tried our store yet?
                  </Text>
                  <TouchableOpacity
                    style={styles.storeButton}
                    onPress={() => isWeb()? history.push("/store"): NavigationService.navigate("Store")}
                  >
                    <Text
                      style={styles.btnText}
                    >
                      Go to Store
                    </Text>
                  </TouchableOpacity>
                </TouchableOpacity>
              );
            } else {
              return <View />;
            }
          }}
          showsVerticalScrollIndicator={false}
          // style={{ alignSelf: "center", width: "95%" }}
          renderItem={({ item, index }) => {
            let quantity = item.quantity ? item.quantity : 1;
            return (
              <TouchableOpacity activeOpacity={1}>
                <View
                  style={[
                    styles.mainView,
                    { marginBottom: index == orderHistory.length - 1 ? 80 : 0 },
                  ]}
                >
                  <Image
                      source={Images.cartTickIcon}
                      style={styles.redeemIcon}
                    />
                  <View
                    style={[
                      styles.cardView,
                    ]}
                  >
                    <Image
                      resizeMode="cover"
                      style={styles.img}
                      source={{ uri: item.thumbnail }}
                    />
                    <View style={styles.contentView}>
                      <Text style={styles.title}>{item.title}</Text>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 7,
                          justifyContent: "flex-start",
                        }}
                      >
                        {item.color ? (
                          <View
                            style={[styles.colorView,{backgroundColor: item.color,}]}
                          />
                        ) : (
                          <View />
                        )}
                        {item.size ? (
                          <View style={styles.sizeView}>
                            <Text style={{ fontSize: 7 }}>{item.size}</Text>
                          </View>
                        ) : (
                          <View />
                        )}
                      </View>
                      <View style={styles.priceBlock}>
                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          <Text style={styles.priceText}>
                            {item.offer_price
                              ? "₹" + item.offer_price * quantity
                              : "₹" + item.price * quantity}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            );
          }}
        />
      }
    </View>
  );
}

export default OrderHistory;
