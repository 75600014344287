import { StyleSheet, Dimensions, Platform } from "react-native";
import { colors, family } from "../../common";
import { mobilBrower } from "../../common/helpers";
import { heightPercentageToDP, widthPercentageToDP } from "../../common/responsiveScreen";
const { width } = Dimensions.get("screen");

export const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.LIGHT_BLUE,
    height: 180,
    borderBottomEndRadius: 40,
    borderBottomLeftRadius: 40,
  },
  mainView2: {
    height: heightPercentageToDP(100),
    width: width,
    position: "absolute",
    zIndex: 100,
    overflow: "hidden"
  },
  mainHeaderContent: {
    display: "flex",
    flexDirection: "row",
    marginTop: Platform.OS == 'ios' ? 30 : 0,
    width: "100%",
    justifyContent: "space-between",
    padding: 25,
  },
  profileHeaderText: {
    color: "white",
    fontFamily: family.effra,
    fontSize: 16,
  },
  avatar: {
    height: mobilBrower() ? 120 : 160,
    width: mobilBrower() ? 120 : 160,
    backgroundColor: "rgb(230,230,230)",
    borderRadius: 15,
  },
  userInfoPanel: {
    display: "flex",
    flexDirection: "row",
    marginTop: -100,
    padding: 15,
    borderRadius: 15,
    backgroundColor: "white",
    borderColor: "rgb(230,230,230)",
    borderWidth: 1,
    marginBottom: 15,
  },
  usernameWrapper: {
    marginLeft: mobilBrower() ? 15 : 0,
    display: "flex",
    justifyContent: "space-between",
    width: "58%",
  },
  firstNameText: {
    fontSize: 25,
    fontFamily: family.effra,
    fontWeight: "700"
  },
  lastNameText: {
    fontSize: !mobilBrower() ? 18 : 20,
    fontFamily: family.effra,
    lineHeight: 20,
    fontWeight: "normal"
  },
  userNameLabelFooter: {
    display: "flex",
    flexDirection: mobilBrower() ? "row" : "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  editText: {
    color: "#CF3737",
    fontFamily: family.effra,
  },
  cText: {
    fontFamily: family.effra,
  },
  tabs: {
    display: "flex",
    padding: 15,
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "#F5F5F5",
    borderRadius: 10,
  },
  tabText: {
    color: "#77838F",
    fontFamily: family.effra,
  },
  subWrapper: {
    alignItems: "center",
    marginVertical: 10
  },
  bucksContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: mobilBrower() ? 0 : 10
  },
  leftContent: {
    width: "30%",
    backgroundColor: "white",
    borderRadius: 10,
    elevation: 12,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    alignItems: "center",
    padding: "2%"
  },
  rightContent: {
    width: "68%",
    minHeight: heightPercentageToDP(70),
    backgroundColor: "white",
    borderRadius: 20,
    elevation: 12,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
  },
  profileContent: {
    width: "68%",
    marginTop: -30,
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 50
  },
  editprofileModal: {
    width: widthPercentageToDP(70),
    height: heightPercentageToDP(80),
  }
});
