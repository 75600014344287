import React, { useRef, useState } from "react";
import { Bounce } from "react-native-animated-spinkit";
import { View, Dimensions, StyleSheet, ActivityIndicator } from "react-native";
// import { BlurView } from "@react-native-community/blur";
import { colors } from "../common";
import { isWeb } from "../common/helpers";
const { width, height } = Dimensions.get("screen");

export const Loader = ({bgColor = colors.LIGHT_BLUE}) => {
  const [pColor, setPColor] = useState("#e3a63a");
  const selectedColor = ["#e3a63a", "#F06A4E", "rgb(255,0,0)"];
  useState(() => {
    setInterval(() => {
      setPColor(
        selectedColor[Math.floor(Math.random() * selectedColor.length)]
      );
    }, 150);
  }, []);
  return (
    <View style={[styles.loader,{backgroundColor: 'transparent',}]}>
      <ActivityIndicator color={pColor} size="large" />
      {/* <Bounce size={120} style={{ alignSelf: "center" }} color={pColor} /> */}
    </View>
  );
};

const styles = StyleSheet.create({
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: isWeb() ?"100%" :width,
    height: isWeb() ?"100%" :height,
    backgroundColor: '#F1AB32',
    opacity: 1,
    position: "absolute",
    top: -100,
    zIndex: 99999,
  },
});

export default Loader;
