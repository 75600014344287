import { StyleSheet } from 'react-native';
import { mobilBrower } from '../../../../common/helpers';
import { responsiveText } from '../../../../common/normalize';

export const styles = StyleSheet.create({
    container: {
        width: mobilBrower() ? "90%" : "85%",
        // alignItems: "center",
        marginTop: 20
    },
    divider: {
        width: "90%",
        marginVertical: 20,
        height: 2,
        backgroundColor: "#00000020"
    },
    title: {
        fontWeight: "700",
        fontSize: responsiveText(20),
        marginVertical: 15
    },
    labelValue: {
        // flex: 1,
        flexDirection: "row",
        width: mobilBrower() ? "90%" : "48%",
        marginBottom: 10
    },
    contentContainer: {
        padding: mobilBrower() ? "10%" : "5%",
        width: "100%",
        backgroundColor: "#F1AB3224",
        borderRadius: mobilBrower() ? 10 : 50
    },
    label: {
        paddingRight: 10,
        fontWeight: "600",
        fontSize: responsiveText(11)
    },
    value: {
        fontSize: responsiveText(11)
    },
    currencyDropDown: {
        flexDirection: "row"
    }
})