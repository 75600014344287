import React, { useEffect, useState } from "react";
import { View, Platform, Switch, Image } from "react-native";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { useSelector, useDispatch } from "react-redux";
import WebFooter from "../../common/WebFooter";
import { isWeb, copilotData, checkTutorialStatus } from "../../common/helpers";
import { Network, apiPaths } from "../../service/api";
import CreditCountrySelectModal from "../CreditCountrySelectModal/CreditCountrySelectModal";
import GetMoreCityModal from "../NewGetMoreCityModal/NewGetMoreCityModal";
import {
  getImmibuckBalance,
  getUnlocks,
} from "../../actions/immibucks.actions";
import { firebaseEvents, logEvent } from "../../common/firebaseEvents";
import { incrementScreenViewedCount, togglePathExpViewType } from "../../actions/app.actions";
import DownloadModal from "../DownloadModal/DownloadModal";
import AlertModal from "../AlertModal/AlertModal";
import Cards from "./components/Cards/Cards";
import Images from "../../common/images";
import Lists from "./components/Lists/Lists";
import NavigationService from "../../navigation/NavigationService";
import AsyncStorage from "@react-native-community/async-storage";
import { copilot, CopilotStep, walkthroughable } from "react-native-copilot";
import { TooltipComponent } from "../../components/Tooltip/Tooltip";
import Loader from "../Loader";
import { useHistory, useLocation } from "react-router-dom";
import OpportunityCodeModal from "./components/OpportunityCodeModal";
import { continueFromPreviousSearch, setSelectedGoalandCountries } from "../../actions/path.actions";

const CopilotView = walkthroughable(View);

function PathExpNewUI(props) {
  const location = Platform.OS == "web" ?  useLocation() : null;
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const immiBuck_balance = useSelector(
    (state) => state.immibucksReducer.getImmibucksDetails.immibucks_balance
  );
  const unlocksItem = useSelector(
    (state) => state.immibucksReducer.getImmibucksDetails.unlocks
  );
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const pathExViewType = useSelector(
    (state) => state.appStateReducer.pathExplorerDefaultView
  );

  const Token = useSelector((state) => state.authReducer.authData.token);
  const [builderModal, setbuilderModal] = useState(false);
  const [loading, setloading] = useState(false)
  const [insufficientBalance, setinsufficientBalance] = useState("");
  const [prCalculator, setprCalculator] = useState(false);
  const [jobsUnlocked, setJobsUnlocked] = useState(false);
  const [showImmibuckPopUp, setshowImmibuckPopUp] = useState(false);
  const [prCountries, setprCountries] = useState([]);
  const [quesLoading, setquesLoading] = useState(false);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [showOppReportModal, setShowOppReportModal] = useState(false);
  const [feature, setfeatrue] = useState(null);
  const [jobsCountries, setjobsCountries] = useState([]);
  const [errorMessage, seterrorMessage] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [languageFeatureParams, setLanguageFeatureParams] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const togglePathExplorerViewType = async () => {
    await AsyncStorage.setItem(
      "pathExpViewType",
      !pathExViewType ? "stack" : "list"
    );

    dispatch(togglePathExpViewType(!pathExViewType));
  };

  const handleJobs = () => {
    if (guestLogin) {
      setshowErrorModal(true);
      seterrorMessage("Login to explore various job opportunities!");
    } else if (isWeb()) {
      // handle modal
    } else {
      if (unlocksItem && !unlocksItem.includes("Unlock jobs")) {
        setshowImmibuckPopUp(true);
        return;
      }
      setprCountries([]);
      setfeatrue("jobs");
      setquesLoading(true);
      const url = `/data/get_jobs_countries`;
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userDetails?.userid + "@" + Token,
      };
      Network(url, "GET", null, headers).then((res) => {
        setquesLoading(false);
        setbuilderModal(false);
        if (res.status && res.status === "Success") {
          setjobsCountries(res.countries);
          setbuilderModal(true);
        }
      });
    }
  };

  useEffect(() => {
    let callCoiplot = async () => {
      let status = await checkTutorialStatus('pathExpToogle')
      !guestLogin && userDetails.isHelpActive && status && props.start()
    }
    callCoiplot()
    dispatch(incrementScreenViewedCount());

  }, []);

  const _handleJobSearch = (counries) => {
    setbuilderModal(false);
    setjobsCountries([]);
    props.navigation.navigate("JobsForYou", { counries });
  };

  const _callCreditBuilder = (countries) => {
    setquesLoading(true);
    const url = `/data/credit_builder_guide?country_ids=${countries.join(",")}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };

    Network(url, "GET", null, headers).then((res) => {
      setquesLoading(false);
      setbuilderModal(false);
      if (isWeb()) {
        history.push("creditBuild", {
          creditBuilder: res.credit_builder_guide,
        });
      } else {
        props.navigation.navigate("CreditBuilder", {
          creditBuilder: res.credit_builder_guide,
        });
      }
      if (res.status && res.status === "Success") {
        console.log("Event tracked");
      }
    });
  };

  const _callPrCalculator = (countries) => {
    setbuilderModal(false);
    setquesLoading(true);
    const url = `/data/get_pr_points_form`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };
    let body = {
      countries: countries,
    };
    try {
      Network(url, "POST", body, headers)
        .then((res) => {
          setquesLoading(false);
          if (res.status && res.status === "Success") {
            props.navigation.navigate("PrCalculator", {
              PrCalculatorForm: res.form,
              countries,
            });
          }
        })
        .catch((e) => {
          setquesLoading(false);
        });
    } catch (error) {
      setquesLoading(false);
    }
  };

  const _handleModalClick = () => {
    setshowImmibuckPopUp(false);
    insufficientBalance !== "" && setinsufficientBalance("");
  };

  const callDirectSearch = async () => {
    if (guestLogin) {
      setshowErrorModal(true);
      seterrorMessage(
        "Login to look up visas and all the essential details you need to know!"
      );
    } else {
      setloading(true)
      setquesLoading(true);
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userDetails?.userid + "@" + Token,
      };
      let response = await Network("/immipaths/all", "GET", null, headers);
      if (response.status == "Success") {
        setquesLoading(false);
        isWeb() ? history.push("quickSearch", {
          immipaths: response.immipaths,
        }) :
          props.navigation.navigate("ChooseImmipath", {
            immipaths: response.immipaths,
          });
      } else {
        setquesLoading(false);
      }
      setloading(false)
    }
  };

  const unlockJobs = () => {
    const url = userDetails?.userid + "/add";
    const balance_url = `/users/${userDetails?.userid}`;
    const headers = {
      Accept: "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };
    const body = {
      amount: 50,
      type: "debit",
      statement: "Unlock jobs",
      milestone: null,
    };
    if (immiBuck_balance >= 50) {
      Network(apiPaths.checkRewardMilestone + url, "POST", body, headers)
        .then((res) => {
          if (res.status === "Success") {
            setJobsUnlocked(true);
            dispatch(getImmibuckBalance(balance_url, headers));
            dispatch(getUnlocks(`${userDetails?.userid}/get_unlocks`, headers));
            setTimeout(() => {
              handleJobs();
            }, 1000);
          }
        })
        .catch((e) => {
          alert("Something went wrong!");
        });
    } else {
      setinsufficientBalance(
        "You do not have sufficient balance. Earn Immibucks by exploring the app more!"
      );
    }
  };

  const handlePRCalClick = () => {
    if (guestLogin) {
      setshowErrorModal(true);
      seterrorMessage(
        "Login to calculate your PR score for multiple countries at once!"
      );
    } else {
      setfeatrue("pr");
      setprCalculator(true);
      setquesLoading(true);
      const url = `/data/get_pr_points_countries`;
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userDetails?.userid + "@" + Token,
      };
      Network(url, "GET", null, headers).then((res) => {
        setquesLoading(false);
        setbuilderModal(true);
        if (res.status == "Success") {
          setprCountries(res.countries);
        }
      });
    }
  };

  const handleLocality = async () => {
    if (guestLogin) {
      setshowErrorModal(true);
      seterrorMessage(
        "Login to compare different cities with up to 8 different metrics at a time!"
      );
    } else if (isWeb()) {
      history.push("cityFacts")
    } else {
      props.navigation.navigate("LocalityFactPage");
    }
  };

  const handleUniversitySearch = () => {
    if (guestLogin) {
      setshowErrorModal(true);
      seterrorMessage(
        "Login to explore various universities personalized and recommended just for you!"
      );
    } else if (isWeb()) {
      history.push("universitySearch");
    } else {
      dispatch(continueFromPreviousSearch(false))
      props.navigation.navigate("UniversitySearch");
    }
  };

  const handleCreditBuilderClick = () => {
    if (guestLogin) {
      setshowErrorModal(true);
      seterrorMessage(
        "Login to get up to date information on how to build your credit score!"
      );
    } else {
      setfeatrue("credit");
      setprCountries([]);
      setprCalculator([]);
      setprCalculator(false);
      setbuilderModal(true);
    }
  };

  const handleCostOfLiving = () => {
    if (guestLogin) {
      setshowErrorModal(true);
      seterrorMessage(
        "Login to Calculate Cost of living!"
      );
      return
    }
    isWeb() ? history.push("/costOfLiving") : NavigationService.navigate('CostOfLiving')
  }


  const handleLanguages = () => {
    if (guestLogin) {
      setshowErrorModal(true);
      seterrorMessage(
        "Login to explore various languages!"
      );
    } else {
      setloading(true)
      setfeatrue("language");
      setprCountries([]);
      setprCalculator([]);
      setprCalculator(false);

      const url = `/data/get_language_explorer_countries`;
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userDetails?.userid + "@" + Token,
      };
      try {
        Network(url, "GET", null, headers)
          .then((res) => {
            setquesLoading(false);
            setLanguageFeatureParams(res);
            setbuilderModal(true);
            setloading(false)
          })
          .catch((e) => {
            console.log(e);
            setquesLoading(false);
            setloading(false)
          });
      } catch (error) {
        setquesLoading(false);
        setloading(false)
      }
    }
  };

  const handleLanguageFeature = (countries) => {
    setbuilderModal(false);
    props.navigation.navigate("LanguageFeature", {
      languageFeatureForm: languageFeatureParams.language_mapping,
      countries,
    });
  };

  const handleuniSearch = () => {
    let obj = {
      goal: '1',
      countries: []
    }
    dispatch(setSelectedGoalandCountries(obj))
    if (guestLogin) {
      setshowErrorModal(true);
      seterrorMessage(
        "Login to try our University Search!"
      );
      return
    }
    isWeb() ? history.push("/universitySearch") : 
    NavigationService.navigate('UniversitySearch', {
      params: {
        UniversitySearch: true, obj
      },
    })
  }

  const handleReport = () => {
    if (isWeb()) {
      setShowOppReportModal(true)
    } else {
      props.navigation.navigate('EligibilityReportMobile', { showVerModal: true })
    }
  }

  const handlePathExpItemPressed = (pathKey, onlyForMob) => {
    if (isWeb() && onlyForMob) {
      setShowDownloadModal(true);
      return;
    }
    switch (pathKey) {
      case "quick_search": {
        logEvent(firebaseEvents.QUICK_SEARCH, {
          message: "User pressed on Quick Search",
        });
        callDirectSearch();
        break;
      }
      case "immipath_search": {
        logEvent(firebaseEvents.IMMIPATH_SEARCH, {
          message: "User pressed on Immipath Search",
        });
        NavigationService.navigate('ImmipathSearch')
        // handleImmipathSearch();
        break;
      }
      case "university_search": {
        logEvent(firebaseEvents.UNIVERSITY_SEARCH, {
          message: "User pressed on University Search",
        });
        handleuniSearch();
        break;
      }
      case "city_facts": {
        logEvent(firebaseEvents.CITY_FACTS_COMPARATOR, {
          message: "User pressed on City Facts Comparator",
        });
        handleLocality();
        break;
      }
      case "credit_builder": {
        handleCreditBuilderClick();
        break;
      }
      case "pr_points": {
        logEvent(firebaseEvents.PR_POINTS, {
          message: "User pressed on Pr Point calculator",
        });
        handlePRCalClick();
        break;
      }
      case "jobs": {
        handleJobs();
        break;
      }
      case "cost_of_living": {
        handleCostOfLiving()
        break;
      }
      case "language": {
        handleLanguages();
        break;
      }
      case "opportunity_report": {
        handleReport();
        break;
      }
      default:
    }
  };

  return (
    <>
      <HeaderComponent screenTitle={"Path Explorer"}
        routeName={Platform.OS == 'web' ? location?.pathname : props?.navigation?.state?.routeName || null}
      />
      {
        loading && <Loader />
      }
      {isWeb() ? null :
        <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#0F1143", flexDirection: "row" }}>
          <CopilotStep
            text={copilotData.pathexpMenuToogle}
            order={1}
            name="pathExpToogle">
            <CopilotView style={{ flexDirection: "row" }}>
              <Image style={{ width: 35, height: 35 }} source={Images.slideWhite} />
              <Switch
                trackColor={{ false: "#767577", true: "#767577" }}
                thumbColor={!pathExViewType ? "#F1AB32" : "#f4f3f4"}
                ios_backgroundColor="#030135"
                style={{ transform: [{ scale: 1 }], marginHorizontal: 10, borderWidth: 0.5, borderColor: 'white' }}
                onValueChange={togglePathExplorerViewType}
                value={!pathExViewType}
              />
              <Image style={{ width: 35, height: 35 }} source={Images.swipeWhite} />
            </CopilotView>
          </CopilotStep>
        </View>}
      {pathExViewType && !isWeb() ? (
        <Cards
          guestLogin={guestLogin}
          handlePathExpItemPressed={handlePathExpItemPressed}
        />
      ) : (
        <Lists
          guestLogin={guestLogin}
          handlePathExpItemPressed={handlePathExpItemPressed}
        />
      )}

      {Platform.OS == "web" ? (
        <WebFooter footerColor="white" textColor="black" />
      ) : (
        <View />
      )}
      {showErrorModal ? (
        <AlertModal
          title="Login"
          description={errorMessage}
          showCancel
          showConfirm
          confirmText="Login"
          cancelText="Not now"
          routeName={Platform.OS == 'web' ? location?.pathname : props?.navigation?.state?.routeName || null}
          loginModal
          onCancelPress={() => setshowErrorModal(false)}
          onConfirmPress={() => setshowErrorModal(false)}
        />
      ) : (
        <View />
      )}
      {showOppReportModal ? (
        <OpportunityCodeModal
          onClose={() => setShowOppReportModal(false)}
          onNewReport={() => {
            setShowOppReportModal(false)
            history.push("eligibilityChecker")
          }}
          onOpenReport={(code) => {
            setShowOppReportModal(false)
            history.push(`opportunityReport/${code}`)
          }}
        />
      ) : (
        <View />
      )}
      {builderModal ? (
        <CreditCountrySelectModal
          userDetails={userDetails}
          quesLoading={quesLoading}
          prCalculator={prCalculator}
          feature={feature}
          prCountries={prCountries}
          callPrCalculator={_callPrCalculator}
          jobsCountries={jobsCountries}
          _handleJobSearch={_handleJobSearch}
          onClose={() => setbuilderModal(false)}
          callCreditBuilder={_callCreditBuilder}
          languageCountires={languageFeatureParams.countries}
          handleLanguageFeature={handleLanguageFeature}
        />
      ) : (
        <View />
      )}
      {showDownloadModal ? (
        <DownloadModal closeModal={() => setShowDownloadModal(false)} />
      ) : null}
      {
        <GetMoreCityModal
          description="Explore opportunities for"
          button1="Unlock jobs"
          button2="No thanks"
          price={50}
          insufficientBalance={
            insufficientBalance !== "" ? insufficientBalance : ""
          }
          successMessage={"Jobs Unlocked"}
          factsPurchased={jobsUnlocked}
          handleButton1={unlockJobs}
          visible={showImmibuckPopUp}
          _handleModalClick={_handleModalClick}
        />
      }
    </>
  );
}

export default copilot({
  tooltipComponent: TooltipComponent
})(PathExpNewUI)