import React, { useEffect, useState } from "react";
import { View } from "react-native";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { styles } from "./CostOfLiving.styles";
import helpers, { isWeb, mobilBrower } from "./../../common/helpers";
import { useSelector } from "react-redux";
import { Network } from "../../service/api";
import CurrencyDropDownComponent from "./CurrencyDropDownComponent";
import { isArray, sumBy } from "lodash";
import CardDetailsModal from "./CardDetailsModal";
import AsyncStorage from "@react-native-community/async-storage";
import meta from "../../common/metaData";
import WebFooter from "../../common/WebFooter";
import Step3 from "./components/Step3";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";

export default function CostOfLiving() {
  const [selectedCountry, setselectedCountry] = useState(null);
  const [selectedUserAnswers, setselectedUserAnswers] = useState({});
  const [currentStep, setcurrentStep] = useState(1);
  const [selectedCurrency, setselectedCurrency] = useState("USD");
  const [costOfLivingData, setcostOfLivingData] = useState(null);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const Token = useSelector((state) => state.authReducer.authData.token);
  const [showCardDetails, setshowCardDetails] = useState(false);
  const [selectedCard, setselectedCard] = useState({});

  useEffect(() => {
    helpers.refreshCurrency();
  }, [])

  const getCostOfLivingData = async (id) => {
    const headers = {
      Accept: "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };
    let payload = {
      city_id: id.toString(),
    };
    let getCostData = await Network(
      "/data/cost_of_living",
      "POST",
      payload,
      headers
    );
    const { data, status } = getCostData;
    if (status == "Success") {
      setcostOfLivingData(data);
      setselectedUserAnswers({});
    }
  };

  const onCountrySelect = (item) => {
    setselectedCountry(item);
    getCostOfLivingData(item.value.id);
  };

  const handleNext = () => {
    setcurrentStep(currentStep + 1);
  };

  const handleCalculate = (selectedUserAnswers) => {
    setselectedUserAnswers(selectedUserAnswers);
    setcurrentStep(currentStep + 1);
    Object.entries(selectedUserAnswers).forEach((s) => {
      let extraCost =
        selectedUserAnswers[0].label == "Yes"
          ? 1.5
          : s[0] == 0 && s[1].label == "Sometimes"
            ? 1.25
            : 1;

      if (s[1].fact_id == 5) {
        Object.entries(costOfLivingData).forEach((q) => {
          if (q[0] == "facts") {
            s[1].totalCost = q[1][5].values[s[1].sub_id].value;
          }
        });
      }
      if (s[1].fact_id == 2) {
        Object.entries(costOfLivingData).forEach((q) => {
          if (q[0] == "facts") {
            if (s[1].sub_id == 3) {
              s[1].totalCost =
                q[1][2].values[s[1].sub_id].value * 6 * 25 * extraCost;
            } else if (s[1].sub_id == 1) {
              s[1].totalCost = q[1][2].values[s[1].sub_id].value * 2 * 25;
            } else if (s[1].sub_id == 5) {
              s[1].totalCost =
                q[1][2].values[s[1].sub_id].value * 60 * extraCost;
            } else if (s[1].sub_id == 0) {
              s[1].totalCost =
                q[1][2].values[s[1].sub_id].value * 2 * 25 * extraCost;
            } else {
              s[1].totalCost = q[1][2].values[s[1].sub_id].value;
            }
          }
        });
      }
      if (s[1].fact_id == 4) {
        Object.entries(costOfLivingData).forEach((q) => {
          if (q[0] == "facts") {
            s[1].totalCost = q[1][4].values[s[1].sub_id].value * extraCost;
          }
        });
      }

      if (s[1].fact_id == 1) {
        Object.entries(costOfLivingData).forEach((q) => {
          if (q[0] == "facts") {
            if (s[1].sub_id == 15 || s[1].sub_id == 16) {
              let value =
                selectedUserAnswers[0].label == "No"
                  ? q[1][1].values[s[1].sub_id].value
                  : q[1][1].values[17].value;
              s[1].totalCost = value * s[1].qty * extraCost;
            }
          }
        });
      }

      if (isArray(s[1])) {
        Object.entries(costOfLivingData).forEach((q) => {
          if (q[0] == "facts") {
            if (s[0] == 7) {
              s[1].forEach((x) => {
                if (x.fact_id == 6) {
                  x.totalCost =
                    x.sub_id == "9"
                      ? 0
                      : selectedUserAnswers[0].label == "Yes"
                        ? q[1][x.fact_id].values[1].value
                        : selectedUserAnswers[0].label == "Sometimes"
                          ? q[1][x.fact_id].values[2].value
                          : 0;
                } else {
                  x.totalCost =
                    x.sub_id == "9"
                      ? 0
                      : q[1][x.fact_id].values[x.sub_id].value;
                }
              });
              let Obj = {
                label: s[1].map((x) => x.label).join(", "),
                fact_id: 7,
                sub_id: s[1][0].sub_id == "9" ? "9" : 3,
                category: "Entertainment",
                totalCost: sumBy(s[1], "totalCost"),
              };
              selectedUserAnswers[7] = Obj;
            } else {
              s[1].forEach((x) => {
                x.totalCost =
                  x.sub_id == "9"
                    ? 0
                    : q[1][4].values[x?.sub_id].value * extraCost;
              });
              let Obj = {
                label: s[1].map((x) => x.label).join(", "),
                fact_id: s[1][0].fact_id,
                category: "Entertainment",
                sub_id: s[1][0].sub_id == "9" ? "9" : "2",
                totalCost: sumBy(s[1], "totalCost"),
              };
              selectedUserAnswers[s[0]] = Obj;
            }
          }
        });
      }

      if (s[1].fact_id == 0) {
        Object.entries(costOfLivingData).forEach((q) => {
          if (q[0] == "facts") {
            if (s[1].sub_id == 3) {
              s[1].totalCost = s[1].qty * q[1][0].values[s[1].sub_id].value;
            } else {
              let estCost =
                (q[1][0].values[0].value * 40) / 100 +
                (q[1][0].values[1].value * 10) / 100 +
                (q[1][0].values[2].value * 50) / 100;
              s[1].totalCost = s[1].days * estCost;
            }
          }
        });
      }
    });
  };

  const gobackCurrentStep = async () => {
    let tempSelection = await AsyncStorage.getItem("tempUserSelection");
    currentStep == 3 && setselectedUserAnswers(JSON.parse(tempSelection));
    setcurrentStep(currentStep - 1);
  };

  const changeCurrency = (curr) => {
    setselectedCurrency(curr);
  };

  const handleCardPress = (item) => {
    setselectedCard(item);
    setshowCardDetails(true);
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1
          handleNext={handleNext}
          selectedCountry={selectedCountry}
          onCountrySelect={onCountrySelect}
        />;
      case 2:
        return <Step2
          selectedCountry={selectedCountry}
          defaultAnswers={selectedUserAnswers}
          onCalculate={handleCalculate}
        />;
      case 3:
        return <Step3
          handleCardPress={handleCardPress}
          selectedCountry={selectedCountry}
          selectedUserAnswers={selectedUserAnswers}
          selectedCurrency={selectedCurrency}
          costOfLivingData={costOfLivingData}
        />;
      default: return null;
    }
  }

  return (
    <>
      <View style={styles.container}>
        {
          isWeb() &&
          <head>
            <meta content={meta.immipathSearch} />
          </head>
        }
        <HeaderComponent showback={true} screenTitle={"Cost of Living Calculator"} />
        <View style={styles.mainView}>
          {currentStep !== 1 ? (
            <CurrencyDropDownComponent
              gobackCurrentStep={gobackCurrentStep}
              changeCurrency={changeCurrency}
              selectedCurrency={selectedCurrency}
              currentStep={currentStep}
            />
          ) : (
            <View />
          )}
          {renderCurrentStep()}
        </View>
        <View style={styles.bottomContainer}>
          <View style={styles.indicatorWrapper}>
            <View
              style={[
                styles.indicator,
                {
                  backgroundColor: currentStep != 1 ? "gray" : "#F1AB32",
                },
              ]}
            ></View>
            <View
              style={[
                styles.indicator,
                {
                  backgroundColor: currentStep != 2 ? "gray" : "#F1AB32",
                },
              ]}
            ></View>
          </View>
        </View>
        <CardDetailsModal
          selectedCurrency={selectedCurrency}
          visible={showCardDetails}
          onClose={() => setshowCardDetails(false)}
          selectedCard={selectedCard}
        />
      </View>
      {isWeb() && !mobilBrower() ? <WebFooter /> : null}
    </>
  );
}
