import initialState from "./initialStates/mainMedia.json";

export const mainMediaTypes = {
  SET_COMPONENTS_DATA: "SET_COMPONENTS_DATA",
  CHANGE_ACTIVE_TAB: "CHANGE_ACTIVE_TAB",
  SET_FILTER_MODAL: "SET_FILTER_MODAL",
  SET_IMMIGRATION_INTEREST: "SET_IMMIGRATION_INTEREST",
  SET_COUNTRIES_INTERESTIN: "SET_COUNTRIES_INTERESTIN",
  SET_ACTIVE_CLASSIFICATION: "SET_ACTIVE_CLASSIFICATION",
  SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
  SET_REACTION_MODAL: "SET_REACTION_MODAL",
  SET_PINDEX: "SET_PINDEX",
  SET_COMPONENTS_DATA_REFRESHED: "SET_COMPONENTS_DATA_REFRESHED",
  UPDATE_REACTIONS: "UPDATE_REACTIONS",
  SYNC_FAVORITES: "SYNC_FAVORITES",
  HANDLE_FAVORITE: "HANDLE_FAVORITE",
  UPDATE_VIEW_TYPE: "UPDATE_VIEW_TYPE",
  CUSTOM_LOADING: "CUSTOM_LOADING",
  UPDATE_MAXED_FETCH_BOOL: "UPDATE_MAXED_FETCH_BOOL",
  HANDLE_LOADING: "HANDLE_LOADING",
  SET_YOUTUBE_MODAL: "SET_YOUTUBE_MODAL",
};

const mainMediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case mainMediaTypes.SET_ACTIVE_CLASSIFICATION:
      return {
        ...state,
        news: {
          ...state.news,
          config: {
            ...state.news.config,
            activeClassification: action.payload,
            searchedQuery: "",
            viewType: action.payload ?? "default",
            filter: {
              inUse: false,
              countriesInterestedIn: [],
              immigrationInterest: [],
            },
          },
        },
      };

    case mainMediaTypes.UPDATE_VIEW_TYPE:
      return {
        ...state,
        [state.gState.activeTab.title]: {
          ...state[state.gState.activeTab.title],
          config: {
            ...state[state.gState.activeTab.title].config,
            viewType: action.payload ?? "default",
          },
        },
      };

    case mainMediaTypes.HANDLE_LOADING:
      return {
        ...state,
        filterLoading: action.payload,
      };

    case mainMediaTypes.CHANGE_ACTIVE_TAB:
      return {
        ...state,
        gState: {
          ...state.gState,
          activeTab: action.payload,
        },
      };

    case mainMediaTypes.SET_YOUTUBE_MODAL:
      return {
        ...state,
        gState: {
          ...state.gState,
          youtubeModal: action.payload
            ? action.payload
            : {
                active: false,
                item: null,
              },
        },
      };

    case mainMediaTypes.SET_REACTION_MODAL:
      return {
        ...state,
        gState: {
          ...state.gState,
          reactionModal: action.payload,
        },
      };

    case mainMediaTypes.SET_FILTER_MODAL:
      return {
        ...state,
        gState: {
          ...state.gState,
          filterModal: {
            ...state.gState.filterModal,
            active: action.payload,
          },
        },
      };

    case mainMediaTypes.SET_COUNTRIES_INTERESTIN:
      return {
        ...state,
        [state.gState.activeTab.title]: {
          ...state[state.gState.activeTab.title],
          config: {
            ...state[state.gState.activeTab.title].config,
            viewType:
              state[state.gState.activeTab.title].config.searchedQuery ||
              state[state.gState.activeTab.title].config.filter
                .countriesInterestedIn.length ||
              action.payload.length
                ? "custom"
                : "default",
            filter: {
              ...state[state.gState.activeTab.title].config.filter,
              countriesInterestedIn: action.payload,
            },
          },
        },
      };

    case mainMediaTypes.SET_IMMIGRATION_INTEREST:
      return {
        ...state,
        [state.gState.activeTab.title]: {
          ...state[state.gState.activeTab.title],
          config: {
            ...state[state.gState.activeTab.title].config,
            viewType:
              state[state.gState.activeTab.title].config.searchedQuery ||
              state[state.gState.activeTab.title].config.filter
                .immigrationInterest.length ||
              action.payload.length
                ? "custom"
                : "default",
            filter: {
              ...state[state.gState.activeTab.title].config.filter,
              immigrationInterest: action.payload,
            },
          },
        },
      };

    case mainMediaTypes.SET_SEARCH_QUERY:
      return {
        ...state,
        [state.gState.activeTab.title]: {
          ...state[state.gState.activeTab.title],
          config: {
            ...state[state.gState.activeTab.title].config,
            searchedQuery: action.payload,
            viewType:
              action.payload ||
              state[state.gState.activeTab.title].config.filter
                .countriesInterestedIn.length ||
              state[state.gState.activeTab.title].config.filter
                .immigrationInterest.length
                ? "custom"
                : "default",
          },
        },
      };

    case mainMediaTypes.SET_COMPONENTS_DATA:
      return {
        ...state,
        [action.componentType]: {
          ...state[action.componentType],
          [action.catType]: [
            ...state[action.componentType]?.[action.catType],
            ...action.data,
          ],
        },
      };

    case mainMediaTypes.SET_COMPONENTS_DATA_REFRESHED:
      return {
        ...state,
        [action.componentType]: {
          ...state[action.componentType],
          [action.catType]: action.data,
          [state.news.config.viewType]: action.data,
        },
      };

    case mainMediaTypes.SET_PINDEX:
      return {
        ...state,
        [state.gState.activeTab.title]: {
          ...state[state.gState.activeTab.title],
          config: {
            ...state[state.gState.activeTab.title].config,
            pIndexes: {
              ...state[state.gState.activeTab.title].config.pIndexes,
              [state[state.gState.activeTab.title].config.viewType]:
                action.payload,
            },
          },
        },
      };

    case mainMediaTypes.UPDATE_REACTIONS:
      const { data, param } = action.payload;
      const temp = [
        ...state[param.activeTabTitle]?.[
          state[param.activeTabTitle].config.viewType
        ],
      ];
      const targetedContent = temp[param.itemIndex];
      const targetId = targetedContent.news_id ?? targetedContent.story_id;
      if (!state.gState.reactions[targetId]) {
        targetedContent.reactions_count[
          `reaction_type_${param.reaction_type}`
        ]++;
      } else {
        targetedContent.reactions_count[
          `reaction_type_${state.gState.reactions[targetId].reaction_type}`
        ]--;
        targetedContent.reactions_count[
          `reaction_type_${param.reaction_type}`
        ]++;
      }
      temp.splice(param.itemIndex, 1, targetedContent);

      return {
        ...state,
        gState: {
          ...state.gState,
          reactions: {
            ...state.gState.reactions,
            ...data,
          },
        },
        [param.activeTabTitle]: {
          ...state[param.activeTabTitle],
          [state[state.gState.activeTab.title].config.viewType]: temp,
        },
      };

    case mainMediaTypes.SYNC_FAVORITES:
      return {
        ...state,
        gState: {
          ...state.gState,
          savedArticles: {
            ...state.gState.savedArticles,
            ...action.payload,
          },
        },
      };

    case mainMediaTypes.HANDLE_FAVORITE:
      let updatedSavedArticles ={...state.gState.savedArticles};
      if(action.payload.is_deleted){
        delete updatedSavedArticles[action.payload.article_id];
      }else{
        updatedSavedArticles[action.payload.article_id]=action.payload;
      }

      return {
        ...state,
        gState: {
          ...state.gState,
          savedArticles: {
            ...updatedSavedArticles,
          },
        },
      };

    case mainMediaTypes.CUSTOM_LOADING:
      return {
        ...state,
        [state.gState.activeTab.title]: {
          ...state[state.gState.activeTab.title],
          config: {
            ...state[state.gState.activeTab.title].config,
            customLoading: action.payload,
          },
        },
      };

    case mainMediaTypes.UPDATE_MAXED_FETCH_BOOL:
      return {
        ...state,
        [state.gState.activeTab.title]: {
          ...state[state.gState.activeTab.title],
          config: {
            ...state[state.gState.activeTab.title].config,
            maxFetchedBools: {
              ...state[state.gState.activeTab.title].config.maxFetchedBools,
              [state[state.gState.activeTab.title].config.viewType]:
                action.payload,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default mainMediaReducer;
