import React, { useState } from 'react'
import { View, Text, ScrollView, Image, FlatList, Modal, TouchableOpacity } from "react-native";
import config from '../../../../common/config';
import { styles } from "./styles";
import BullitinModal from "../../../../components/BullitinModal/BullitinModal";
import Images from '../../../../common/images';

export default function CurrentStageSelectionModal({ handleClose, onClose, visible, setSelectedStage }) {
  const [selectedcategory, setselectedcategory] = useState(null)
  const [selectedChildren, setselectedChildren] = useState(null)
  const [selectedStage, setselectedStage] = useState(null)

  const handlePress = (item) => {
    if (item.name == selectedcategory) {
      setselectedcategory(null)
      return
    }
    setselectedcategory(item.name)
  }

  const handlechildrenSelection = (item) => {
    if (item.name == selectedChildren) {
      setselectedChildren(null)
      return
    }
    setselectedChildren(item.name)
  }

  const handleSubChildren = (item) => {
    if (item.name == selectedStage) {
      setselectedStage(null)
      return
    }
    if(!item.children){
      setSelectedStage(item.name)
      onClose()
      return
    }
    setSelectedStage(`${selectedcategory} => ${selectedChildren} => ${item.name}`)
    onClose()
  }

  return (
    <BullitinModal
      logo="pin"
      heading=""
      visible={visible}
      onClose={onClose}
      handleClose={handleClose}
      closeIcon={"closeBlack"}
    >
      <>
        <View style={styles.header}>
          <Text style={styles.notificationHeaderText}>Stages </Text>
        </View>
        <View style={{ padding: 20, flex: 1 }}>
          <FlatList
            data={config.CurrentStages}
            renderItem={({ item, index }) => (
              <TouchableOpacity activeOpacity={1} onPress={() => item.children ? handlePress(item) : handleSubChildren(item)} style={styles.mainItem}>
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Text style={styles.mainStageText}>
                    {item.name}
                  </Text>
                  {
                    item.children ?
                    <Image source={Images.dropDownArrow} style={[selectedcategory == item.name ? styles.upArrow : styles.down]} />
                    : null
                  }
                </View>
                {
                  selectedcategory == item.name &&
                  <FlatList
                    data={item.children}
                    style={{ marginTop: 15 }}
                    renderItem={({ item, index }) => (
                      <TouchableOpacity activeOpacity={1} onPress={() => handlechildrenSelection(item)} style={styles.mainItem}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Text style={styles.mainStageText}>
                            {item.name}
                          </Text>
                          <Image source={Images.dropDownArrow} style={[selectedChildren == item.name ? styles.upArrow : styles.down]} />
                        </View>
                        {
                          selectedChildren == item.name &&
                          <FlatList
                            data={item.subChildren}
                            style={{ marginTop: 15 }}
                            renderItem={({ item, index }) => (
                              <TouchableOpacity activeOpacity={1}
                                onPress={() => handleSubChildren(item)}
                                style={[styles.mainItem, selectedStage == item.name && styles.selectedItem]}>
                                <Text style={styles.mainStageText}>
                                  {item.name}
                                </Text>
                              </TouchableOpacity>
                            )}
                          />
                        }
                      </TouchableOpacity>
                    )}
                  />
                }
              </TouchableOpacity>
            )}
          />
        </View>
      </>
    </BullitinModal>
  )
}
