import React, { useEffect, useState } from "react";
import { StatusBar, Platform, View } from "react-native";
import { Container } from "native-base";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import MediaTabs from "./components/MediaTabs/MediaTabs";
import SearchAndFilter from "./components/SearchAndFilter/SearchAndFilter";
import FilterModal from "../FilterModal/FilterModal";
import ActiveTab from "./components/ActiveTab/ActiveTab";
import ReactionModal from "../ReactionModal/ReactionModal";
import YoutubeModal from "../YoutubeModal/YoutubeModal";
import AndroidYoutubeModal from "../YoutubeModal/YoutubeModal";
import { syncReactions, syncSavedNews } from "../../actions/mainMedia.actions";
import { useDispatch, useSelector } from "react-redux";
import { isWeb, mobilBrower, copilotData, checkTutorialStatus } from "../../common/helpers";
import WebFooter from "../../common/WebFooter";
import meta from "../../common/metaData";
import { copilot, walkthroughable, CopilotStep } from "react-native-copilot";
import { TooltipComponent } from "../Tooltip/Tooltip";
import { useLocation } from "react-router-dom";

const CopilotView = walkthroughable(View);

function MainMedia(props) {
  const location = Platform.OS == "web" ? useLocation() : null;
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const [componentLoading, setComponentLoading] = React.useState(true);
  const [showMediaTab, setshowMediaTab] = useState(false);

  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);

  useEffect(() => {
    let callCoiplot = async () => {
      let status = await checkTutorialStatus('mediaCard')
      !guestLogin && userDetails?.isHelpActive && status && props.start()
    }
    callCoiplot()
  }, [])

  useEffect(() => {
    setTimeout(() => setComponentLoading(false), 2000);
    (async () => {
      if (userDetails) {
        await dispatch(syncReactions(userDetails?.userid, token));
        await dispatch(syncSavedNews(userDetails?.userid, token));
      }
    })();
  }, []);

  return (
    <>
      {
        isWeb() &&
        <head>
          <meta content={meta.media} />
        </head>
      }
      <Container>
        <FilterModal />
        <StatusBar hidden={true} />
        <HeaderComponent
          screenTitle={"Media"}
          routeName={Platform.OS == 'web' ? location?.pathname : props?.navigation?.state?.routeName || null}
        />
        <CopilotStep
          text={copilotData?.media}
          order={1}
          name="mediaTab">
          <CopilotView>
            <MediaTabs />
          </CopilotView>
        </CopilotStep>
        <CopilotStep
          text={copilotData?.mediaSearch}
          order={2}
          name="mediaSearch">
          <CopilotView style={{ padding: 0, }}>
            <SearchAndFilter />
          </CopilotView>
        </CopilotStep>
        <CopilotStep
          text={copilotData?.mediaCard}
          order={3}
          name="mediaCard">
          <CopilotView style={{ padding: 0, }}>
            <ActiveTab componentLoading={componentLoading} />
          </CopilotView>
        </CopilotStep>
        <ReactionModal />
        {Platform.OS === "android" ? <AndroidYoutubeModal /> : <YoutubeModal />}
        {isWeb() && !mobilBrower() ? <WebFooter /> : null}
      </Container>
    </>
  );
}

export default copilot({
  tooltipComponent: TooltipComponent
})(MainMedia)
