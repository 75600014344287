import React, { useState } from "react";
import {
  View,
  Text,
  Modal,
  FlatList,
  TextInput,
  TouchableOpacity,
  Image,
} from "react-native";
import { Images } from "../../common";
import { some } from "lodash";
import { styles } from "./multiSelectModal.styles";
import AlertModal from "../AlertModal/AlertModal";

const MultipleSelectionModal = ({ data, onclose, _handleSelect, title }) => {
  const [searchedData, setsearchedData] = useState([]);
  const [searchedText, setsearchedText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  const [errorMessage, seterrorMessage] = useState(null);
  const [showErrorModal, setshowErrorModal] = useState(false);

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = data.filter(function (item) {
        const itemData =
          title == "Select your country"
            ? item.name
            : item.label
              ? item.label
              : "".toUpperCase();
        const textData = text;
        return itemData.indexOf(textData) > -1;
      });
      setsearchedData(newData);
      setsearchedText(text);
    } else {
      setsearchedData(data);
      setsearchedText(text);
    }
  };

  const handleItemPress = (item) => {
    if (title == "Select your country") {
      _handleSelect(item.name);
      onclose();
    } else {
      let _selectedItems = [...selectedItems];
      var result = some(_selectedItems, (q) => {
        return q.value.id === item.value.id;
      });
      if (result) {
        _selectedItems = _selectedItems.filter(
          (s) => s.value.id !== item.value.id
        );
      } else {
        if (_selectedItems.length >= 5) {
          setshowErrorModal(true);
          seterrorMessage("maximum 5 items allowed");
          setTimeout(() => {
            setshowErrorModal(false);
          }, 1200);
          return;
        }
        _selectedItems.push(item);
      }
      setselectedItems(_selectedItems);
    }
  };

  const handleSelect = () => {
    _handleSelect(selectedItems);
  };

  return (
    <Modal visible transparent>
      <View style={styles.mainView}>
        <View style={styles.innerView}>
          <View style={styles.titleView}>
            <Text style={styles.headerTitle}>{title}</Text>
            <TouchableOpacity
              onPress={() => onclose()}
              style={styles.cancelButton}
            >
              <Image source={Images.cancel} style={{ height: 12, width: 12 }} />
            </TouchableOpacity>
          </View>
          <View style={styles.header}>
            <TextInput
              placeholder="Search"
              onChangeText={(text) => searchFilterFunction(text)}
              defaultValue={searchedText}
              style={{ height: 45, width: "90%" }}
            />
          </View>
          <FlatList
            data={searchedData.length > 0 ? searchedData : data}
            initialNumToRender={10}
            extraData={selectedItems}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                activeOpacity={0.6}
                onPress={() => handleItemPress(item)}
                style={styles.listItem}
              >
                <Text style={styles.itemtext}>
                  {title == "Select your country" ? item.name : item.label}
                </Text>
                {some(selectedItems, (q) => {
                  return q.value.id === item.value.id;
                }) ? (
                  <Image
                    source={Images.tick}
                    style={{ height: 18, width: 18 }}
                  />
                ) : (
                  <View />
                )}
              </TouchableOpacity>
            )}
          />
          <TouchableOpacity
            onPress={() => handleSelect()}
            style={styles.proceedButton}
          >
            <Text style={styles.proceedText}>Select</Text>
          </TouchableOpacity>
        </View>
      </View>
      {showErrorModal ? (
        <AlertModal
          title="Oops!"
          description={errorMessage}
        />
      ) : (
        <View />
      )}
    </Modal>
  );
};

export default MultipleSelectionModal;
