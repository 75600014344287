import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  noDataView: {
    alignSelf: "center",
    marginTop: 80,
    alignItems: "center",
  },
  emptyListText: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#000",
  },
});
