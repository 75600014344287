import { StyleSheet } from "react-native";
import { family } from "../../../../../../common";

export const styles = StyleSheet.create({
  headerText: {
    fontFamily: family.effra,
    fontSize: 25,
    textAlign: "center",
  },
  wrapper: {
    height: "88%",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: "#F1AB32",
    width: "90%",
    marginLeft: "5%",
    borderRadius: 15,
  },
  dropDownItem: {
    padding: 5,
    margin: 5,
  },
  dropDownList: {
    position: "absolute",
    zIndex: 99999,
    top: 40,
    left: 10,
    backgroundColor: "rgb(230,230,230)",
  },
  buttonText: {
    fontFamily: family.effra,
    fontSize: 18,
    padding: 12,
    textAlign: "center",
  },
  inputs: {
    width: "100%",
    marginBottom: 15,
    borderRadius: 15,
    padding: 15,
    backgroundColor: "rgb(230,230,230)",
  },
  errorView: {
    borderWidth: 1,
    borderColor: "red",
  },
  checkIcon: {
    height: 16,
    width: 16,
    marginRight: 5,
  },
  text: {
    fontFamily: family.effra,
    fontSize: 16,
  },
  currentlyWorkingWrapper: {
    display: "flex",
    alignItems: "center",
    zIndex: -1,
    flexDirection: "row",
    // justifyContent: "center",
    marginBottom: 20,
  },
  sectionTitle: {
    fontFamily: family.effra,
    fontSize: 18,
    marginBottom: 15,
    marginLeft: 4,
    textAlign: "center",
  },
});
