/** @format */

import React, { Component } from "react";
// import { Ionicons } from "@expo/vector-icons";
import {
  View,
  Text,
  // Image,
  // I18nManager,
  Dimensions,
  ImageBackground,
  // TouchableOpacity,
  // BackHandler
} from "react-native";
// import { LinearGradient } from "expo-linear-gradient";
import AppIntroSlider from "react-native-app-intro-slider";
import styles from "./styles";
import { Config, family, normalize } from "../common";
import { setGuestlogin } from "../actions/path.actions";
import { connect } from "react-redux";
import { finishIntroScreen } from "../actions/auth.actions";
const { width, height } = Dimensions.get("window");
// import Icon from "react-native-vector-icons/FontAwesome";
class AppIntro extends Component {
  // componentDidMount() {
  //   BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
  // }

  // componentWillUnmount() {
  //   BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
  // }
  // handleBackButton = () => {
  //    BackHandler.exitApp()
  //       }
  _renderItem = props => {
    var image_height;
    if (height < width) {
      image_height = 360;
    } else {
      image_height = height / 2.5;
    }
    const { item, index, dimensions } = props;
    return (
      <View testID="app_intro_view" style={[styles.mainContent]}>
        <View>
          <ImageBackground
            source={item.images}
            defaultSource={item.images}
            style={[{ height: image_height, resizeMode: "contain", flex: 1 }]}
            blurRadius={4}
          >
            <View style={{ alignItems: "center", marginTop: 15 }}>
              {/* <TouchableOpacity ><View style = {{width:40}}><Icon name="chevron-left" size={15} color="#000" /></View></TouchableOpacity> */}
              <Text
                style={[
                  {
                    textAlign: "center",
                    width: width,
                    marginTop: 300,
                    color: "#fff",
                    fontSize: normalize(110),
                    marginBottom: 15,
                    fontWeight: 'bold',
                    fontFamily: family.semibold
                  }
                ]}
              >
                {item.title}
              </Text>
            </View>
          </ImageBackground>
          {/* <Text style={styles.title}>{item.title}</Text>
        <Image style = {{height:image_height,width:width}} source={item.images} /> */}
          <View style={{ marginTop: 50 }}>
            <Text style={[styles.text, { height: image_height / 1.1 }]}>
              {item.text}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  _renderNextButton = () => {
    return (
      <View
        style={{
          backgroundColor: "#fff",
          height: 50,
          borderRadius: 10,
          justifyContent: "center"
        }}
        testID="next_btn_in_ai"
      >
        <Text
          style={{
            color: "rgb(0, 2, 49)",
            textAlign: "center",
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: "SourceSansPro-Semibold"
          }}
        >
          Next
        </Text>
      </View>
    );
  };
  _renderPrevButton = () => {
    return (
      <View
        style={{
          backgroundColor: "#fff",
          height: 50,
          borderRadius: 10,
          justifyContent: "center"
        }}
      >
        <Text style={{ color: "#fff" }}>Pre</Text>
        {/* <Ionicons
          name={
            I18nManager.isRTL ? "md-arrow-round-forward" : "md-arrow-round-back"
          }
          color="rgba(255, 255, 255, .9)"
          size={24}
          style={{ backgroundColor: "transparent" }}
        /> */}
      </View>
    );
  };

  _renderDoneButton = () => {
    return (
      <View
        style={{
          backgroundColor: "#fff",
          height: 50,
          borderRadius: 10,
          justifyContent: "center"
        }}
        testID="get_started_btn_in_ai"
      >
        <Text
          style={{
            color: "#rgb(0, 2, 49)",
            textAlign: "center",
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: "SourceSansPro-Semibold"
          }}
        >
          Get Started
        </Text>
      </View>
    );
  };
  _introdone = async () => {
    const response = await this.props.dispatch(finishIntroScreen());
    this.props.navigation.navigate("Home", { guest: true });;
    this.props.dispatch(setGuestlogin(true))
  };
  render() {
    return (
      <AppIntroSlider
        bottomButton={true}
        slides={Config.intro}
        renderItem={this._renderItem}
        renderDoneButton={this._renderDoneButton}
        renderNextButton={this._renderNextButton}
        onDone={this._introdone}
        dotStyle={{ backgroundColor: 'rgba(255, 255, 255, .5)' }}
      />
    );
  }
}

const mapStateToProps = state => ({
  finishIntro: state.authReducer.finishIntroScreen
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

// const const mapDispatchToProps = (dispatch) => {
//   const { actions } = require("@redux/userRedux");
//   return {
//     finishIntro: () => dispatch(actions.finishIntro()),
//   };
// };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppIntro);
