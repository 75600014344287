import React, { useEffect, useState } from 'react'
import {
  View, Text, Image, TouchableOpacity,
  FlatList,
} from "react-native";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { Network } from "../../service/api";
import { styles } from "../Policy/policies.styles";
import Images from '../../common/images';
import { isWeb, mobilBrower } from '../../common/helpers';
import WebFooter from '../../common/WebFooter';

export default function Policies() {
  const [policiesData, setPoliciesData] = useState({})

  useEffect(() => {
    const getPolicyDetails = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "guest@dashboard",
      };
      let response = await Network('/data/get_privacy_refund_policy', "GET", null, headers)
      setPoliciesData(response.data);
    }
    getPolicyDetails()
  }, [])

  return (
    <View style={{ flex: 1 }}>
      <HeaderComponent
        showNotificationIcon={false}
        showback={true}
        screenTitle="Policies, terms and conditions"
      />
      <TouchableOpacity disabled={isWeb() && !mobilBrower()} style={styles.mainView} activeOpacity={1}>
        <FlatList
          data={Object.keys(policiesData)}
          contentContainerStyle={{ paddingBottom: 140 }}
          renderItem={({ item, index }) => {
            return (
              <TouchableOpacity key={index+"pd"} activeOpacity={1} style={styles.mainItem}>
                <Text style={styles.titleText}>
                  {item.replace('_', ' ').toUpperCase()}
                </Text>
                {
                  Object.values(policiesData[item]).map((q,index) => {
                    return (
                      <View style={styles.dataView} key={index+"p"}>
                        <Image source={Images.bullet} style={styles.bullet} />
                        <Text style={styles.dataStyle}>
                          {q.data}
                        </Text>
                      </View>
                    )
                  })
                }
              </TouchableOpacity>
            )
          }}
        />
      </TouchableOpacity>
      {isWeb() && !mobilBrower()? <WebFooter />:null}
    </View>
  )
}
