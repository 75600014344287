import { Dimensions, StyleSheet } from "react-native";
import DeviceInfo from "react-native-device-info";
import { family, normalize } from "../../common";
import { mobilBrower } from "../../common/helpers";
import { heightPercentageToDP } from "../../common/responsiveScreen";

const width = Dimensions.get("window").width;
let hasNotch = DeviceInfo.hasNotch();

export const styles = StyleSheet.create({
    mainView: {
      height: "100%",
      width: width,
      backgroundColor: "#222222c7",
      alignItems: "center",
      justifyContent: "center",
    },
    immibuckImg: {
      height: hasNotch ? 80 : 60,
      resizeMode: "contain",
      marginTop: 20,
    },
    walletMain: {
      backgroundColor: "white",
      marginTop: 10,
      width: "80%",
      padding: 15,
      borderRadius: 10,
      alignSelf: "center",
    },
    tabView: {
      flexDirection: "row",
      alignItems: "center",
      width: "80%",
      marginTop: 10,
      alignSelf: "center",
      justifyContent: "space-between",
    },
    walletItemText: {
      fontFamily: family.semibold,
      color: "#030135",
      fontSize: 13,
      width: "86%",
    },
    walletTitleText: {
      fontWeight: "700",
      color: "black",
      fontSize: 13,
      marginBottom: 3,
    },
    walletItem: {
      flexDirection: "row",
      width: "100%",
      marginTop: 7,
      justifyContent: "space-between",
      alignSelf: "center",
    },
    cardContainer: {
      flexDirection: "row",
      alignItems: "center",
      width: "90%",
      flex: 0.87,
      marginTop: 5,
      justifyContent: "space-between",
      alignSelf: "center",
      // top: -20
    },
    cardtext: {
      fontFamily: family.semibold,
      fontSize: 18,
      paddingHorizontal: 7,
      textAlign: "center",
      marginTop: 10,
      color: "white",
    },
    coinsStyle: {
      // width: "95%",
      flex:1,
      height: 
      heightPercentageToDP(25),
      borderRadius: 10,
      alignItems: "center",
      marginTop: 10,
      backgroundColor: "#11576C",
    },
    storeBut: {
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 10,
      position: "absolute",
      bottom: 20,
      backgroundColor: "#11576C",
    },
    totalRewardTitleView: {
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    totalMain: {
      backgroundColor: "#004c63",
      borderTopRightRadius: 20,
      borderTopLeftRadius: 20,
    },
    innerView: {
      height: heightPercentageToDP(80),
      width: mobilBrower()? width / 1.2:width/2,
      // justifyContent: 'space-between',
      borderRadius: 20,
      backgroundColor: "#01cae6",
    },
    rewardText: {
      color: "white",
      fontFamily: family.semibold,
      marginRight: 10,
      marginTop: 0,
    },
    rewardView: {
      alignItems: "center",
    },
    textContent: {
      width: "80%",
      alignSelf: "center",
      // position: 'absolute',
      // bottom: 10,
      paddingBottom: 28,
    },
    woho: {
      fontSize: 30,
      fontFamily: "SourceSansPro-SemiBold",
      fontFamily: family.semibold,
      color: "black",
      marginTop: 0,
      paddingLeft: 5,
      paddingRight: 5,
      textAlign: "center",
      marginBottom: 5,
      alignSelf: "center",
    },
    congratsText: {
      fontSize: 20,
      lineHeight: 28,
      fontFamily: family.semibold,
      color: "white",
    },
    unboxText: {
      fontSize: 17,
      color: "white",
      marginTop: 10,
    },
    cancelButton: {
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 12.5,
      height: 25,
      alignSelf: "flex-end",
      top: 10,
      right: 10,
      width: 25,
      backgroundColor: "white",
    },
    animView: {
      // height: normalize(350),
      width: normalize(350),
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
    },
    lotiStyle: {
      height: hasNotch ? width / 1.2 : width / 1.8,
      width: width / 1.7,
      alignItems: "flex-start",
      resizeMode: "contain",
    },
    claimBut: {
      backgroundColor: "white",
      borderRadius: 10,
      width: 80,
      alignItems: "center",
      marginBottom: 15,
      bottom: 0,
      justifyContent: "center",
      alignSelf: "center",
    },
    claimButText: {
      padding: 7,
      paddingLeft: 10,
      paddingRight: 10,
      fontFamily: family.semibold,
    },
  });