import { StyleSheet } from "react-native";
import { mobilBrower } from "../../common/helpers";
import { heightPercentageToDP } from "../../common/responsiveScreen";

export const styles = StyleSheet.create({
  mainView: {
    width: mobilBrower() ? "90%" : "70%",
    alignSelf: "center"
  },
  container: {
    height: mobilBrower() ? heightPercentageToDP(75) : heightPercentageToDP(71),
    padding: 15,
    backgroundColor: 'white',
  },
  replayBox: {
    width: '90%',
    backgroundColor: 'white',
    shadowColor: 'black',
    shadowOffset: { width: 0.1, height: 0.1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    borderRadius: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
    marginVertical: 10,

  },
  textBox: {
    padding: 10
    // height: 40,
  },
  sendIcon: {
    width: 15,
    height: 15,
    marginRight: 15,
  }
});
