import AsyncStorage from "@react-native-community/async-storage";
import React from "react";
import { FlatList, Image, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import Images from "../../../common/images";
import { apiPaths, Network } from "../../../service/api";
import ConversationList from "./ConversationList";
import { styles } from "./components.styles"

import _ from "lodash";
import { isWeb, mobilBrower } from "../../../common/helpers";
import MessageChat from "../../MessageChat/MessageChat";
import { heightPercentageToDP } from "../../../common/responsiveScreen";

const Conversations = ({
  setLoading,
  setQuestion,
  setHideTabs = () => { },
  chatData = {},
  setChatData = () => { },
  triggerMessageChat = () => { }
}) => {
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);
  const [askedQuestionListData, setAskedQuestionListData] = React.useState([]);
  const token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const [articleItem, setArticleItem] = React.useState([]);

  const getAskedData = async (loadingState) => {
    setLoading(loadingState);
    const url = `${userDetails?.userid}/chat_history`;
    const headers = {
      Accept: "application/json",
      Authorization: userDetails?.userid + "@" + token,
    };
    const tutorialStatus = await AsyncStorage.getItem("advicechannneltutorial");
    Network(apiPaths.chat_history + url, "GET", null, headers)
      .then((res) => {
        setLoading(false);

        if (res.status === "Success") {
          const askedQuestionListData = _.orderBy(
            res.chat_history,
            ["unseen_updates"],
            ["desc"]
          );
          setAskedQuestionListData(askedQuestionListData);

          if (articleItem) {
            const quesForExpert = `Hi, I need an expert opinion regarding this article:\n${this.state.articleItem.title}\n${this.state.articleItem.link}\nPlease advice regarding the same.`;
            setTimeout(() => {
              setQuestion(quesForExpert);
            }, 500);
          }
          if (!tutorialStatus && userDetails.isHelpActive) {
            this.props.start();
            AsyncStorage.setItem(
              "advicechannneltutorial",
              JSON.stringify(true)
            );
          }
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    (async () => {
      !guestLogin && await getAskedData(true);
    })();
  }, []);

  return (
    <View style={[styles.mainView, { height: isWeb() ? chatData?.messageId && mobilBrower() ? heightPercentageToDP(80) : heightPercentageToDP(60) : "100%" }]} >
      {chatData?.messageId && mobilBrower() ?
        null :
        <View style={{ flex: 1 }}>
          {askedQuestionListData.length ? (
            <FlatList
              data={askedQuestionListData}
              showsVerticalScrollIndicator={isWeb()}
              contentContainerStyle={{ paddingBottom: 10, marginTop: 10 }}
              bounces={false}
              renderItem={({ item, index }) => (
                <ConversationList
                  triggerChatFunc={triggerMessageChat}
                  index={index}
                  item={item}
                  active={chatData?.messageId === item.id}
                />
              )}
            />
          ) : (
            <View
              style={styles.conversationEmptyView}
            >
              <Image
                source={Images.contactUs1}
                style={styles.contactUsImg}
                resizeMode="contain"
              />
              <Text
                style={styles.emptyText}
              >
                Get expert support for your Immigration journey!
              </Text>
            </View>
          )}
        </View>}
      {chatData?.messageId ? <View style={styles.chatContainer}>
        {mobilBrower() ?
          <TouchableOpacity style={{ margin: 10 }} onPress={() => {
            setChatData(null)
            setHideTabs(false)
          }}>
            <Image style={{ height: 15, width: 15 }} source={Images.backArrow} />
          </TouchableOpacity> : null}
        <MessageChat messageId={chatData.messageId} auto_res={chatData.auto_res} getAskedData={getAskedData} chatHeaderData={chatData.chatHeaderData} refId={chatData.ref_id} expertDict={chatData.expert_dict} />
      </View>
        : null}
    </View>
  );
};

export default Conversations;