import React, { useState } from "react";
import {
    Text,
    View,
    TouchableOpacity,
    Modal,
    Image,
    TextInput,
} from "react-native";
import { styles } from "./OpportunityCodeModal.styles";
import { Images } from "../../../common";
import BlurBackground from "../../BlurBackground";

function OpportunityCodeModal({
    onNewReport = () => { },
    onOpenReport = () => { },
    onClose = () => { }
}) {
    const [code1, setCode1] = useState("")
    const [code2, setCode2] = useState("")
    const [code3, setCode3] = useState("")

    return (
        <Modal visible={true} transparent>
            <BlurBackground blurType="light" />
            <View style={styles.mainView}>
                <View style={styles.innerView}>
                    <TouchableOpacity style={{ position: "absolute", right: 15, top: 15 }} onPress={onClose}>
                        <Image source={Images.closeBlack} style={{ height: 23, width: 23 }} />
                    </TouchableOpacity>
                    <Text style={[styles.title]}>
                        {"Opportunity Report"}
                    </Text>
                    <Text style={[styles.title, { fontSize: 16, fontWeight: "normal" }]}>
                        {"Enter your report's unique code"}
                    </Text>
                    <View style={styles.inputContainer}>
                        <TextInput
                            placeholder="XXX"
                            style={styles.input}
                            value={code1}
                            onChangeText={setCode1}
                            maxLength={3} />
                        <Text>-</Text>
                        <TextInput style={styles.input} placeholder="XXX" value={code2} onChangeText={setCode2} maxLength={3} />
                        <Text>-</Text>
                        <TextInput style={styles.input} placeholder="XXX" value={code3} onChangeText={setCode3} maxLength={3} />
                    </View>
                    <TouchableOpacity
                        disabled={code1.length != 3 || code2.length != 3 || code3.length != 3}
                        onPress={() => onOpenReport(`${code1}-${code2}-${code3}`)}
                        style={[styles.btn, { backgroundColor: (code1.length != 3 || code2.length != 3 || code3.length != 3) ? "grey" : "#030135", marginTop: 20 }]}
                    >
                        <Text style={[styles.btnText, { color: "#fff" }]}>Open Report</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={onNewReport}
                        style={[styles.btn, { marginTop: 0 }]}
                    >
                        <Text style={[styles.btnText]}>Start New Report</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
}

export default OpportunityCodeModal;
