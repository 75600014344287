import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import { View, Text } from "native-base";
import { normalize, fonts, colors, family } from "../common";

const ButtonComponent = (props) => {
  return (
    <TouchableOpacity
      disabled={props.disabled ? props.disabled : false}
      activeOpacity={props.activeOpacity ? props.activeOpacity : 0.8}
      onPress={() => {
        if (props.handleSubmit) {
          props.handleSubmit();
        }
      }}
    >
      <View
        style={[
          styles.button,
          {
            backgroundColor: props.disabled
              ? "lightgray"
              : props.bgColor
              ? props.bgColor
              : colors.BLUE,
            padding: props.padding ? props.padding : normalize(10),
            width: props?.width ? props?.width : normalize(200),
            height: props?.height ? props?.height : 80,
            marginVertical: props.marginVertical ? props.marginVertical : 0,
          },
        ]}
      >
        <Text
          numberOfLines={1}
          style={[
            styles.buttonText,
            {
              fontSize: props.textFontSize ? props.textFontSize : fonts.font_15,
              fontFamily: props.family ? props.family : family.semibold,
            },
          ]}
        >
          {props.value}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default ButtonComponent;

const styles = StyleSheet.create({
  button: {
    // flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 25,
  },
  buttonText: {
    textAlign: "center",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
  },
});
