import { StyleSheet, Platform } from "react-native";
import { family } from "../../common";
import { mobilBrower } from '../../common/helpers';
import { responsiveText } from "../../common/normalize";

export const styles = StyleSheet.create({
    mainView: {
        height: "100%",
        width: "100%",
        backgroundColor: '#222222c7',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerView: {
        maxHeight: "90%",
        width: !mobilBrower() ? "50%" : '90%',
        borderRadius: 20,
        backgroundColor: 'white',
    },
    backButton: {
        height: 23,
        width: 23,
        borderRadius: 11.5,
        alignItems: 'center',
        position: 'absolute',
        zIndex: 99999,
        left: 10,
        backgroundColor: 'white',
        elevation: 12,
        marginLeft: 10,
        shadowColor: '#BDBDBD',
        shadowOffset: {
            height: 1,
            width: 1
        },
        justifyContent: 'center',

    },
    backButonView: {
        alignItems: 'flex-start',
        width: '90%',
        marginTop: 20,
        alignSelf: 'center',
        justifyContent: 'center',
    },
    bullet: {
        height: 8,
        width: 8,
        borderRadius: 4,
        backgroundColor: '#030135'
    },
    promotionstitle: {
        fontSize: 16,
        color: 'red',
        fontFamily: family.semibold
    },
    description: {
        fontSize: 13,
        marginTop: 5
    },
    bulletView: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    toptext: {
        fontSize: responsiveText(16),
        textAlign: 'center',
        alignSelf: 'center',
        marginTop: 5,
        marginBottom: 5,
        padding: 6,
    },
    excludedReasonTitle: {
        fontWeight: "800",
        fontSize: responsiveText(20)
    },
    reasonsTitle: {
        fontSize: 12,
        textAlign: 'center',
        alignSelf: 'center',
        width: '100%',
    },
    excludedTitlesView: {
        backgroundColor: '#030135'
    },
    couponStyle: {
        fontFamily: family.semibold,
        color: '#030135',
        fontSize: 14,
        alignSelf: 'center'
    },
    addtoDo: {
        fontFamily: family.semibold,
        color: '#030135',
        top: Platform.OS == 'ios' ? -3 : 0,
        // fontSize: normalize(15)
    },
    successCheck: {
        height: 18,
        width: 18,
        borderRadius: 9,
        marginBottom: 5,
        alignItems: 'center',
        alignSelf: 'flex-end',
        justifyContent: 'center',
        backgroundColor: 'green'
    },
    addToDoClick: {
        height: 18,
        width: 18,
        borderRadius: 9,
        marginBottom: 5,
        borderWidth: 0.5,
        alignItems: 'center',
        alignSelf: 'flex-end',
        justifyContent: 'center',
    },
    todobutton: {
        right: 5,
        width: '32%',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        marginBottom: 5,
        borderBottomWidth: 1,
    },
    title: {
        fontWeight: "500",
        marginLeft: 10,
        fontSize: responsiveText(18)
    },
    tab: {
        backgroundColor: '#030135',
        marginLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
    },
    reasonBullet: {
        fontSize: 10,
        color: '#030135',
        fontWeight: 'bold'
    },
    tabContanier: {
        backgroundColor: '#030135',
    },
    tabText: {
        color: 'white',
        fontFamily: family.semibold,
        padding: 5,
        fontSize: 15
    },
    cancelButton: {
        alignSelf: 'flex-end',
        marginVertical: 15,
        marginHorizontal: 15
    },
    score: {
        fontFamily: family.semibold,
        fontSize: responsiveText(15),
    },
    scoreView: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 10,
        shadowColor: '#000',
        padding: 10,
        // width: 50,
        // height: 30,
        shadowOffset: { width: 1, height: 1 },
        shadowRadius: 1,
        elevation: 12
    },
    item: {
        flexDirection: 'row',
        width: '90%',
        alignItems: 'center',
        padding: 7,
        alignSelf: 'center',
        marginTop: 7,
        justifyContent: 'space-between'
    },
    btn: {
        backgroundColor: "rgb(15, 17, 67)",
        padding: 12,
        borderRadius: 25,
        width: 160,
        marginVertical: 10,
        alignSelf: "center"
    },
    btnText: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 16,
    }
})
