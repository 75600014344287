import React, { useEffect, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Text,
  Image,
  Modal,
} from "react-native";
import * as Animatable from "react-native-animatable";
import { Images } from '../../common';
import { apiPaths, Network } from '../../service/api';
import DealsComponent from './dealsComponent';
import PathDetailsComponent from './pathDetailsComponent';
import { styles } from "./styles";

export default function ApplicationDetailsModal({ visible, token, userid, immipath_id, goBack, id }) {
  const [selectedTab, setselectedTab] = useState('Details')
  const [pathDetails, setpathDetails] = useState(null);
  const [appPromos, setAppPromos] = useState([]);

  const handleTabClick = (tab) => {
    setselectedTab(tab)
  }

  useEffect(() => {
    fetchImmipathDetails(immipath_id.id)
    fetchPromotionsInfo(id)
  }, [immipath_id])

  const fetchImmipathDetails = async (id) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${userid}@${token}`,
    };
    await Network(apiPaths.immipath_path + id, "GET", null, headers)
      .then((res) => {
        if (res.status === "Success") {
          res.path_details?.resources.length > 0 &&
            res.path_details.resources.forEach((element, index) => {
              if (element == "") {
                res.path_details.resources.splice(index, 1);
              }
            });
          setpathDetails(res.path_details);
        }
      })
      .catch((e) => {
        console.log("00000", e);
      });
  };




  const fetchPromotionsInfo = (id) => {
    const url = `/users/${userid}/app_tracker/${id}/promotions`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${userid}@${token}`,
    };
    Network(url, "GET", null, headers).then((res) => {
      if (res.status && res.status === "Success") {
        setAppPromos(res.application_promos);
      }
    });
  };



  return (
    <Modal
      transparent
      visible={visible}
    >
      <Animatable.View
        animation="fadeIn"
        delay={300}
        duration={500}
        style={styles.mainView}
      >
        <View style={styles.innerView}>
          <View style={styles.header}>
            <TouchableOpacity onPress={goBack}>
              <Image source={Images.backArrow} style={{ height: 18, width: 18, resizeMode: 'contain' }} />
            </TouchableOpacity>
            <Text style={styles.headerText}>
              Path Details
            </Text>
            <View />
            {/* <Image source={Images.blackSearchIcn} style={{ height: 18, width: 18 }} /> */}
          </View>

          <View style={styles.tabView}>
            <TouchableOpacity onPress={() => handleTabClick('Details')} style={[styles.tabViewButton, selectedTab == 'Details' && styles.selectedTab]}>
              <Text style={[styles.tabViewButtonText, selectedTab == 'Details' && styles.selectedTabText]}>
                Path Details
              </Text>
            </TouchableOpacity>
            {/* <TouchableOpacity onPress={() => handleTabClick('deals')} style={[styles.tabViewButton, selectedTab == 'deals' && styles.selectedTab]}>
              <Text style={[styles.tabViewButtonText, selectedTab == 'deals' && styles.selectedTabText]}>
                Deals for you
              </Text>
            </TouchableOpacity> */}
          </View>
          {
            selectedTab == 'Details' ?
              <PathDetailsComponent
                pathDetails={pathDetails}
              /> : <DealsComponent
                appPromos={appPromos}
              />
          }
        </View>
      </Animatable.View>
    </Modal>
  )
}
