import { StyleSheet } from "react-native";
import { family } from "../../common";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";

const styles = StyleSheet.create({
  resendOtpCont:{
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 15
  },
  resendText:{
    marginLeft: 5, 
    marginRight: 5, 
    color: '#008BC7',
    fontSize: 12,
    fontFamily: "SourceSansPro-Semibold"
  },
  roundedTextInput: {
    borderRadius: 10,
    borderWidth: 0,
    backgroundColor: "#EEF2F5",
    width: 40,
    fontSize: 15,
    marginLeft: 10,
    height: 45,
    color: "#2C393F",
    borderColor: "#EEF2F5"
  },
  subview: {
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
  },
  subtext:{
    fontSize: responsiveText(16),
    color: "#2C393F",
    textAlign: "center"
  },
  slide1: {
    width: "100%",
    alignItems: "center",
  },
  nextText: {
    fontFamily: family.semibold,
    color: "white",
    fontSize: 17,
    // letterSpacing: 1,
  },
  buttonView: {
    width: !mobilBrower() ? "80%" : "100%",
  },
  nextButton: {
    backgroundColor: "#030135",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginVertical: 30,
    paddingHorizontal: 13,
    paddingVertical: 8,
    height: "auto",
    width: "100%"
  },
});

export default styles;