import React from "react";
import { View, Text, ActivityIndicator, TouchableOpacity } from "react-native";
import { family } from "../../../../../common";
import NavigationService from "../../../../../navigation/NavigationService";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isWeb } from "../../../../../common/helpers";

const ActiveTabFooter = ({ endLoading, dataLoaded, onLoadMorePress = () => { } }) => {
  const history = useHistory()
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );

  return endLoading ? (
    <View>
      <ActivityIndicator size="large" />
    </View>
  ) : guestLogin ? (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        onPress={() =>
          isWeb() ?
            history.push("/login",  { routeName: `/Media` })
            : NavigationService.navigate("Login",  { routeName: 'Media' })
        }
        style={{
          textDecorationLine: "underline",
          color: "#030135",
          fontFamily: family.semibold,
        }}
      >
        Login
      </Text>
      <Text
        style={{
          color: "#030135",
          fontFamily: family.semibold,
          marginLeft: 3,
        }}
      >
        to read more news
      </Text>
    </View>
  ) : isWeb() && dataLoaded ?
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TouchableOpacity
        onPress={onLoadMorePress}
        style={{
          backgroundColor: "#030135",
          fontFamily: family.semibold,
          paddingHorizontal: 13,
          paddingVertical: 8
        }}
      >
        <Text style={{ color: "#fff" }}>
          Load More
        </Text>
      </TouchableOpacity>
    </View> : (
      <View />
    );
};
export default ActiveTabFooter;
