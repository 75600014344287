import React from "react";
import { Text, View, ScrollView } from "react-native";
import * as Animatable from "react-native-animatable";
import { notificationModalStyles as styles } from "../notificationModal.styles";
import { EventsFooter } from "./footers/EventsFooter";
import { PolicyFooter } from "./footers/PolicyFooter";

function NotificationContent(props) {
  const { title, description, link, Content, type, heading } = props;

  const controller = {
    policies: <PolicyFooter link={link} />,
    events: <EventsFooter {...props} />,
  };

  return (
    <Animatable.View animation="fadeIn" style={styles.application_details}>
      <View style={styles.header}>
        <Text style={styles.notificationHeaderText}>{heading} </Text>
      </View>

      <View style={styles.application_header}>
        <Text style={styles.header_text}>{title}</Text>
      </View>
      {props.date_time && (
        <Text
          style={{
            textAlign: "right",
            marginRight: "2.5%",
            fontSize: 13,
            color: "gray",
            marginBottom: 5,
          }}
        >
          Event on {new Date(props.date_time).toString().slice(0, 16)}
        </Text>
      )}
      <ScrollView
        style={{
          paddingLeft: 20,
          paddingRight: 5,
        }}
      >
        <View style={styles.box}>
          {description && (
            <Text style={styles.sub_header_text}>{description}</Text>
          )}
          <Text
            style={{
              fontSize: 15,
              marginLeft: -5,
              marginTop: 15,
            }}
            numberOfLines={20}
          >
            {Content}
          </Text>
        </View>
        {controller[type] ? controller[type] : null}
      </ScrollView>
    </Animatable.View>
  );
}

export default NotificationContent;
