import { StyleSheet } from "react-native";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  wrapper: {
    padding: 25,
    justifyContent:"space-between",
    alignItems:"center"
  },
  mailImage: {
    height: 200,
    width: 200,
    borderRadius: 100,
    alignItems: "center",
    borderWidth: 2,
    borderColor: "#fff",
  },
  btnsContainer:{
    width:"90%",
    flexDirection:"row",
    justifyContent:"space-between"
  },
  btn:{
    paddingVertical:12,
    width:"40%",
    alignItems:"center",
    borderRadius:5
  },
  btnText:{
    fontWeight:"bold",
    fontSize:responsiveText(16)
  }
});
