import React, { useState } from "react";
import {
  Text,
  View,
  FlatList,
  Linking,
  TouchableOpacity,
  Image,
} from "react-native";
import { helpers, Images } from "../../../../common";
import { useSelector } from "react-redux";
import { notificationModalStyles as styles } from "../../../NotificationModal/notificationModal.styles";
import BullitinModal from "../../../BullitinModal/BullitinModal";
import Badge from "../../../../common/Badge";
import { styles as s } from "./sectionI.styles";
import InAppBrowser from 'react-native-inappbrowser-reborn'
import { isWeb } from "../../../../common/helpers";


export default function ResourceModal({ openResourceModal, onClose, resources }) {
  const [showNotificationDetail, setshowNotificationDetail] = useState(false);
  const [notificationsDetails, setnotificationsDetails] = useState(null);
  const [loading, setloading] = useState(false);
  const [type, setType] = useState("");
  const notificationList = useSelector(
    (state) => state.authReducer?.getNotifications?.notificationList
  );
  const handleClose = (func) => {
    if (type) {
      if (showNotificationDetail) {
        setshowNotificationDetail(false);
      } else {
        setType("");
      }
    } else {
      func();
    }
  };

  const handleLinking = async (link) => {
    if (!isWeb() && await InAppBrowser.isAvailable()) {
      try {
        const result = await InAppBrowser.open(link, helpers.inAPPbrowserOption)
      } catch (error) {
        await InAppBrowser.close()
      }
    } else {
      Linking.openURL(link);
    }
  }

  return (
    <BullitinModal
      heading=""
      visible={openResourceModal}
      onClose={onClose}
      handleClose={handleClose}
      closeIcon={loading ? "" : type ? "leftArrow" : "closeBlack"}
    >
      <View style={styles.notifItemContainer}>
        <View style={styles.header}>
          <Text style={styles.notificationHeaderText}>Resources </Text>
        </View>
        <View style={{ padding: 20, flex: 1 }}>
          <FlatList
            data={resources}
            style={{ overflow: 'scroll' }}
            ListEmptyComponent={() => (
              <Text style={{ textAlign: 'center' }}>
                Oops! Couldn't find the best resources for you at this moment.
              </Text>
            )}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => handleLinking(item.link)}
                key={index + "n"}
                style={styles.notifItem}
              >
                <View>
                  <Image source={Images.resourceIcon} style={styles.leftIcon} />
                  <Badge text={notificationList?.[type]?.length} />
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={s.resourceText}>{item.title}</Text>
                  <Text style={s.resourcecategory}>{item.category}</Text>
                </View>
                <Image
                  source={Images.notificationIconModal_forward}
                  style={styles.rightIcon}
                />
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    </BullitinModal>
  );
}
