import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Dimensions,
  Image,
  ScrollView,
  KeyboardAvoidingView,
  BackHandler,
  ImageBackground,
  Platform
} from "react-native";
import Timer from "react-timer-mixin";
import { connect } from "react-redux";
import { compose } from "redux";
import { colors, Images } from "../common";
import { Field, reduxForm } from "redux-form";
import { createNewUser } from "../actions/auth.actions";
import InputText from "../components/InputText";
import Loader from "../components/Loader";
const { width, height } = Dimensions.get("window");
import Toast from "react-native-simple-toast";
import NetInfo from "@react-native-community/netinfo";
import { sha256 } from "react-native-sha256";
import Icon from "react-native-vector-icons/FontAwesome";
import resetpassword from "../images/resetpassword.png";
import { heightPercentageToDP } from "react-native-responsive-screen";
import { withRouter } from 'react-router';
import SweetAlert from "../components/SweetAlert";
import AlertModal from "../components/AlertModal/AlertModal";
import { TextInput } from "react-native-gesture-handler";

const keyboardVerticalOffset = Platform.OS === "ios" ? 0 : 0;
const styles = StyleSheet.create({
  container: {
    // flex: 1,
    alignItems: "center",
    // justifyContent: 'center',
    backgroundColor: "#fff",
    minHeight: height - 40,
    marginTop: -5
  },

  signupButton: {
    color: "#ffffff",
    fontSize: 16,
    fontWeight: "500"
  },
  button: {
    width: Platform.OS == 'web' ? '30%' : width / 1.4,
    backgroundColor: colors.LIGHT_BLUE,
    borderRadius: 25,
    marginVertical: 10,
    paddingVertical: 13,
    alignSelf: 'center',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    marginTop: 30
  },
  buttonText: {
    fontSize: 16,
    color: "#ffffff",
    textAlign: "center",
    fontFamily: "SourceSansPro-Semibold"
  },
  errorText: {
    color: "red",
    fontSize: 14
    // padding: 16,
    // paddingBottom: 8
  },
  welcomeView: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: Platform.OS == 'android' ? height / 3.7 : Platform.OS == 'web' ? heightPercentageToDP('38%') : height / 3
  },
  welcomeText: {
    fontSize: Platform.OS == 'web' ? heightPercentageToDP('2%') : 20,
    fontFamily: "SourceSansPro-Semibold",
    color: "#2C393F"
  },
  subview: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginLeft: 40,
    marginRight: 40,
    alignItems: "center"
  },
  subtext: {
    fontSize: Platform.OS == 'web' ? heightPercentageToDP('2%') : 15,
    color: "#2C393F",
    fontFamily: "SourceSansPro-Regular",
    textAlign: "center"
  },
  loginView: {
    marginTop: 10,
    backgroundColor: "#EEF2F5",
    width: Platform.OS == 'web' ? '30%' : width / 1.08,
    height: 50,
    alignSelf: 'center',
    alignItems: "center",
    borderRadius: 100,
    flexDirection: "row"
  },
  mailImage: { height: 20, width: 15, marginLeft: 15, marginRight: 15 },
  passwordView: {
    marginTop: 15,
    backgroundColor: "#EEF2F5",
    width: width / 1.1,
    height: 50,
    alignItems: "center",
    borderRadius: 100,
    flexDirection: "row"
  },
  keyImage: { height: 25, width: 25, marginLeft: 15, marginRight: 15 },
  eyeImage: { height: 25, width: 25, marginLeft: 15, marginRight: 15 },
  buttonStyle: {
    backgroundColor: "#18A4E0",
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20,
    borderRadius: 100
  },
  // buttonText: { color: "#fff" },
  signupView: {
    marginTop: 15,
    width: width / 1.1,
    height: 50,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center"
  },
  statusBar: {
    height: Platform.OS === 'ios' ? 40 : StatusBar.currentHeight,
    backgroundColor: colors.LIGHT_BLUE
  }
});

class CreatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errormessage: "",
      alertMessage: "",
      passStatus: true,
      showErrorModal: false,
      confirmpassStatus: true,
      checkconnection: false,
      shapassword: ""
    };
  }

  componentWillMount() {
    this.chechConnection();
  }
  componentDidMount() {
    BackHandler.addEventListener("hardwareBackPress", this.handleBackButton);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener("hardwareBackPress", this.handleBackButton);
  }
  handleBackButton() {
    //  Actions.pop();
    // this.props.navigation.pop();
    return true;
    // this.backpresshandle();
  }

  async chechConnection() {
    Platform.OS !== 'web' && NetInfo.fetch().then(state => {
      this.setState({ checkconnection: state.isConnected });
      if (!this.state.checkconnection) {
        Toast.show("Check your connection", Toast.LONG);
      }
    });
  }
  createNewUser = async values => {
    Platform.OS !== 'web' && await this.chechConnection();
    var str1 = "Verification E-mail Sent to ";
    var str2 = " for New User Email Verification!";

    try {
      let postData = Platform.OS == 'web' ? this.props.history?.location?.state?.postData : this.props.navigation.state.params;
      postData.password_hash = Platform.OS == 'web' ? sha256(values.newpassword) : this.state.shapassword;
      if (!postData.referral_code) {
        delete postData.referral_code
      }
      //  this.props.dispatch(createNewUser(postData))
      var status_result_str = str1 + "" + postData.email_address + "" + str2;
      if (!this.state.checkconnection && Platform.OS !== 'web') {
        Toast.show("Check your connection", Toast.LONG);
      } else {
        const response = await this.props.dispatch(
          createNewUser(postData, postData.email_address)
        );
        if (response.status.toString().includes('E-mail Sent to')) {
          valdidatedata = {};
          valdidatedata.email_address = postData.email_address;
          valdidatedata.otp_type = "new_user_verification";
          valdidatedata.status = response.status;
          SweetAlert.success(response.status)
          // Actions.pinvalidation(valdidatedata);
          Platform.OS == 'web' ? this.props.history.push(`/Pinvalidation`, { valdidatedata: valdidatedata, _postData: postData }) :
            this.props.navigation.navigate("PinValidation", { valdidatedata: valdidatedata, _postData: postData });
        } else {
          this.setState({ showErrorModal: true, errormessage: response.status })
          Timer.setTimeout(() => {
            // Actions.pop()
            this.setState({ errorModal: false }, () => {
              this.props.navigation.pop();
            })
          }, 1500);
        }
      }
    } catch (error) {
      // const newError = new ErrorUtils(error, "Signup Error");
      // newError.showAlert();
    }
  };
  backpresshandle() {
    // Actions.Signup();
    this.props.navigation.navigate("SignUp");
  }
  onSubmit = async values => {
    // Actions.destination();
    var reg = /^(?=.*\d)(?=.*[A-Z])[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{8,}$/;
    if (!values.newpassword || values.newpassword.trim() == "") {
      SweetAlert.error('Password is required.')
    } else if (!reg.test(values.newpassword)) {
      SweetAlert.error('At least 8 characters with 1 number and 1 capital letter required.')
    } else if (!values.confirmpassword || values.confirmpassword.trim() == "") {
      SweetAlert.error('Confirm password is required')
    } else if (values.confirmpassword !== values.newpassword) {
      SweetAlert.error('Passwords do not match')
    } else {
      Platform.OS == 'web' ? this.setState({ shapassword: sha256(values.newpassword) }) :
        await sha256(values.newpassword).then(hash => {
          this.setState({
            shapassword: hash
          });
        });
      this.createNewUser(values);
    }
  };
  handleChange = (text, onChange) => {
    // To restrict user from entering blank space.
    onChange(text.replace(/\s/g, ''));
  }
  renderTextInput = field => {
    const {
      meta: { touched, error },
      label,
      secureTextEntry,
      maxLength,
      keyboardType,
      placeholder,
      input: { onChange, ...restInput }
    } = field;
    return (
      <View>
        <InputText
          onChangeText={(text) => this.handleChange(text, onChange)}
          maxLength={maxLength}
          placeholder={placeholder}
          keyboardType={keyboardType}
          secureTextEntry={secureTextEntry}
          label={label}
          {...restInput}
        />
        {touched && error && <Text style={styles.errorText}>{error}</Text>}
      </View>
    );
  };

  passwordChange(status) {
    var status;
    if (status) {
      status = false;
    } else {
      status = true;
    }

    this.setState({ passStatus: status });
  }
  confirmpasswordChange(status) {
    var status;
    if (status) {
      status = false;
    } else {
      status = true;
    }

    this.setState({ confirmpassStatus: status });
  }

  _onPressButton = () => {
    // Actions.pop();
    this.props.navigation.pop();
  };

  render() {
    const { handleSubmit, loginUser } = this.props;
    // console.log(loginUser);

    var image_height;

    if (height < width) {
      image_height = 360;
    } else {
      image_height = height / 2.8;
    }

    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : null}
        keyboardVerticalOffset={keyboardVerticalOffset}
      >
        {Platform.OS === 'ios' ? <View style={[styles.statusBar]} /> :
          <StatusBar barStyle="light-content" backgroundColor={colors.LIGHT_BLUE} />
        }
        <ScrollView keyboardShouldPersistTaps={'handled'} bounces={false} showsVerticalScrollIndicator={false}>

          <View style={styles.container}>

            {loginUser && loginUser.isLoading && <Loader />}
            <ImageBackground
              style={{ height: image_height, width: width, alignItems: 'center', }}
              source={Platform.OS == 'web' ? resetpassword : Images.resetPassword}
            >

              <TouchableOpacity
                onPress={this._onPressButton}
                style={{ marginLeft: 30, marginTop: 50, width: width, }}
              >
                <View style={{ width: 30 }}>
                  {
                    Platform.OS !== 'web' ?
                      <Icon name="chevron-left" size={15} color="#fff" /> : <View />
                  }
                </View>
              </TouchableOpacity>
              {/* <View style={styles.welcomeView}>
              <Text style={styles.welcomeText}>Enter Your Password</Text>
            </View> */}
             
            </ImageBackground>
            <View style={styles.subview}>
                <Text style={styles.subtext}>
                  Enter your password below. {"\n"}Your password must contain at least 8 characters with 1 number and 1 capital letter.
                </Text>
              </View>
              <View style={[styles.loginView, { marginTop: Platform.OS == 'web' ? 20 : 70 }]}>
                <Image style={styles.mailImage} source={Images.passwordlock} />
                <Field
                  name="newpassword"
                  placeholder="New Password"
                  secureTextEntry={this.state.passStatus}
                  component={this.renderTextInput}
                />
                <TouchableOpacity
                  onPress={() => this.passwordChange(this.state.passStatus)}
                >
                  <Image
                    style={styles.eyeImage}
                    source={
                      this.state.passStatus === true
                        ? Images.unViewIcon
                        : Images.eyeIcon
                    }
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.loginView}>
                <Image style={styles.mailImage} source={Images.passwordlock} />
                <Field
                  name="confirmpassword"
                  placeholder="Confirm Password"
                  secureTextEntry={this.state.confirmpassStatus}
                  component={this.renderTextInput}
                />
                <TouchableOpacity
                  onPress={() =>
                    this.confirmpasswordChange(this.state.confirmpassStatus)
                  }
                >
                  <Image
                    style={styles.eyeImage}
                    source={
                      this.state.confirmpassStatus === true
                        ? Images.unViewIcon
                        : Images.eyeIcon
                    }
                  />
                </TouchableOpacity>
              </View>

              <TouchableOpacity
                style={styles.button}
                onPress={handleSubmit(this.onSubmit)}
              >
                <Text style={styles.buttonText}>Done</Text>
              </TouchableOpacity>
          </View>
          {
            this.state.showErrorModal ?
              <AlertModal
                title="Oops!"
                description={this.state.errormessage}
              />
              :
              <View />
          }
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

const mapStateToProps = state => ({
  createUser: state.authReducer.createUser,
  loginUser: state.authReducer.loginUser
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "createpassword"
    // validate
  })
)(Platform.OS == 'web' ? withRouter(CreatePassword) : CreatePassword);
