import { mainMediaTypes } from "../reducers/mainMedia.reducer";
import { Network, apiPaths } from "../service/api";

export const fetchComponentsData = ({
  user_id,
  authtoken,
  from,
  refresh,
  classification,
  userCountryOfNationality,
  countryOfBirth,
  searchtext,
  country,
  immiInterest,
  componentType,
}) => {
  const authHeader = `${
    authtoken == "guest@news" ? authtoken : `${user_id}@${authtoken}`
  }`;
  const localarray = [];
  const filterType =
    classification == "opportunity"
      ? "&opportunityfilter=true"
      : "&threatfilter=true";

  const endpointUrl = `/news/gettop${componentType}?search=${
    searchtext ?? ""
  }&goalfilters=${immiInterest ?? "1,2,4,3"}&countryfilters=${
    country ?? "1,2,3,4,5,6,7,8,9,10"
  }${classification ? filterType : ""}${from >= 0 ? `&from_=${from}` : ""}`;
  const customBool = searchtext || country || immiInterest ? true : false;

  return async (dispatch) => {
    const headers = {
      Authorization: authHeader,
    };
    refresh &&
      dispatch({
        type: mainMediaTypes.HANDLE_LOADING,
        payload: true,
      });
    try {
      return Network(endpointUrl, "GET", null, headers).then((response) => {
        if (response[componentType] != undefined) {
          if (!response[componentType].length) {
            dispatch({
              type: mainMediaTypes.HANDLE_LOADING,
              payload: false,
            });
            dispatch({
              type: mainMediaTypes.UPDATE_MAXED_FETCH_BOOL,
              payload: true,
            });
            return response;
          } else {
            for (let i = 0; i < response[componentType].length; i++) {
              const data = response?.[componentType][i];
              localarray.push(data);
            }
            dispatch({
              type: refresh
                ? mainMediaTypes.SET_COMPONENTS_DATA_REFRESHED
                : mainMediaTypes.SET_COMPONENTS_DATA,
              componentType:
                componentType === "media" ? "videos" : componentType,
              catType: customBool ? "custom" : classification ?? "default",
              data: localarray,
            });
            dispatch({
              type: mainMediaTypes.HANDLE_LOADING,
              payload: false,
            });
            dispatch({
              type: mainMediaTypes.UPDATE_MAXED_FETCH_BOOL,
              payload: false,
            });
          }
        } else {
          //   dispatch({
          //     type: "GET_USER_NEWS_FAIL",
          //     payload: "Call failed with error",
          //   });
          console.log("ERROR L143");
        }
        return response;
      });
    } catch (error) {
      //   dispatch({
      //     type: "GET_USER_NEWS_FAIL",
      //     payload: error.responseBody,
      //   });
      console.log("ERROR L152", error);

      return error;
    }
  };
};

export const setActiveTab = (payload) => {
  return {
    type: mainMediaTypes.CHANGE_ACTIVE_TAB,
    payload,
  };
};

export const setYoutubeModal = (payload) => {
  return {
    type: mainMediaTypes.SET_YOUTUBE_MODAL,
    payload,
  };
};

export const setActiveClassification = (payload) => {
  return {
    type: mainMediaTypes.SET_ACTIVE_CLASSIFICATION,
    payload,
  };
};

export const setFilterModal = (payload) => {
  return {
    type: mainMediaTypes.SET_FILTER_MODAL,
    payload,
  };
};

export const updateImmigrationInterests = (payload) => {
  return {
    type: mainMediaTypes.SET_IMMIGRATION_INTEREST,
    payload,
  };
};

export const updateViewType = (payload) => {
  return {
    type: mainMediaTypes.UPDATE_VIEW_TYPE,
    payload,
  };
};

export const updateCountriesInterestedIn = (payload) => {
  return {
    type: mainMediaTypes.SET_COUNTRIES_INTERESTIN,
    payload,
  };
};

export const setReactionModal = (payload) => {
  return {
    type: mainMediaTypes.SET_REACTION_MODAL,
    payload,
  };
};

export const searchMainMediaQuery = (payload) => {
  return {
    type: mainMediaTypes.SET_SEARCH_QUERY,
    payload,
  };
};

export const setPIndex = (payload) => {
  return {
    type: mainMediaTypes.SET_PINDEX,
    payload,
  };
};

export const updateMaxFetchBool = (payload) => {
  return {
    type: mainMediaTypes.UPDATE_MAXED_FETCH_BOOL,
    payload,
  };
};

export const setCustomLoading = (payload) => {
  return {
    type: mainMediaTypes.CUSTOM_LOADING,
    payload,
  };
};

export const likeOrDislikeMediaContent = (
  article,
  user_id,
  authtoken,
  isDelete,
  type
) => {
  var authdata = user_id + "@" + authtoken;
  const parsedContent = {
    ...article,
    article_id: article.news_id ?? article.article_id ?? article.story_id,
    tags: article.tags.toString(),
    is_deleted: isDelete,
  };

  let bodyObj = {};

  bodyObj.article_id = parsedContent.article_id;
  bodyObj.user_id = user_id;

  let methodType = "POST";
  let url =
    type === "news"
      ? `/users/${user_id}/log_actions/save_news`
      : `/users/${user_id}/log_actions/save_story`;

  if (isDelete) {
    methodType = "DELETE";
    url = `/news/${user_id}/saved/${parsedContent.article_id}`;
    bodyObj = null;
  }
  return async (dispatch) => {
    const headers = {
      Authorization: authdata,
    };
    try {
      return Network(url, methodType, bodyObj, headers)
        .then((response) => {
          if (response.status === "Success") {
            dispatch({
              type: mainMediaTypes.HANDLE_FAVORITE,
              payload: parsedContent,
            });
          } else {
            Alert.alert("Save failed", JSON.stringify(response), [
              { text: "OK", onPress: () => console.log("OK Pressed") },
            ]);
          }
          return response;
        })
        .catch((e) => {
          console.log(e);
          type === "news"
            ? dispatch({
                type: "FAVORITE_ACTION_STARTED",
                favorite_call_failed: true,
              })
            : dispatch({
                type: "FAVORITE_STORIES_ACTION_STARTED",
                favorite_stories_call_failed: true,
              });
        });
    } catch (error) {
      type === "news"
        ? dispatch({
            type: "FAVORITE_ACTION_STARTED",
            favorite_call_failed: true,
          })
        : dispatch({
            type: "FAVORITE_STORIES_ACTION_STARTED",
            favorite_stories_call_failed: true,
          });
      console.log(error);
      return error;
    }
  };
};

export const addUserReactions = (
  article,
  user_id,
  authtoken,
  reaction,
  type,
  index
) => {
  var authdata = user_id + "@" + authtoken;
  var bodyObj = {};
  bodyObj.article_id = type == "news" ? article.news_id : article.story_id;
  bodyObj.user_id = user_id;
  bodyObj.reaction_type = reaction;

  var methodType = "POST";
  var url =
    type == "news"
      ? `/users/${user_id}/log_actions/select_news_reaction`
      : `/users/${user_id}/log_actions/select_story_reaction`;
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_REACTIONS",
      payload: {
        param: {
          activeTabTitle: type,
          itemIndex: index,
          reaction_type: reaction,
        },
        data: {
          [article.news_id ?? article.story_id]: {
            reaction_type: reaction,
            article_id: article.news_id ?? article.story_id,
          },
        },
      },
    });
    const headers = {
      Authorization: authdata,
    };
    try {
      return Network(url, methodType, bodyObj, headers).then((response) => {
        return response.status;
      });
    } catch (error) {}
  };
};

export const syncReactions = (user_id, authtoken) => {
  try {
    let url = `/${user_id}/get_user_reactions`;
    var authdata = user_id + "@" + authtoken;
    const headers = {
      Authorization: authdata,
    };
    return async (dispatch) => {
      return Network(
        apiPaths.getUserReactions + url,
        "GET",
        null,
        headers
      ).then((response) => {
        if (response.data?.length > 0) {
          dispatch({
            type: "UPDATE_REACTIONS",
            payload: response.data.reduce((initial, current) => {
              return { ...initial, [current.article_id]: current };
            }, {}),
          });
        }
        console.log(
          response.data.reduce((initial, current) => {
            return { ...initial, [current.article_id]: current };
          }, {})
        );
      });
    };
  } catch (error) {}
};

export const syncSavedNews = (user_id, authtoken) => {
  var authdata = user_id + "@" + authtoken;
  return async (dispatch) => {
    const url = `/news/${user_id}/saved`;
    const headers = {
      Authorization: authdata,
    };
    try {
      return Network(url, "GET", null, headers).then((response) => {
        if (response.status === "Success" && response.articles.length > 0) {
          dispatch({
            type: "SYNC_FAVORITES",
            payload: response.articles.reduce((initial, current) => {
              return { ...initial, [current.article_id]: current };
            }, {}),
          });
        } else {
          // dispatch({
          //   type: "GET_SAVED_NEWS_FAIL",
          // });
        }
        return response;
      });
    } catch (error) {
      dispatch({
        type: "GET_SAVED_NEWS_FAIL",
      });
      return error;
    }
  };
};
