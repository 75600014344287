import { StyleSheet } from "react-native";
import { family } from "../../common";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";

const styles = StyleSheet.create({
  inputBox: {
    width: !mobilBrower() ? "80%" : "100%",
    backgroundColor: "#F5F6F8",
    borderRadius: 5,
    flexDirection: "row",
    borderWidth: 2,
    marginTop: 15,
    borderColor: "#BDBDBD",
    alignItems: "center",
  },
  subview: {
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
  },
  subtext:{
    fontSize: responsiveText(16),
    color: "#2C393F",
    textAlign: "center"
  },
  leftIcon:{
    height: 20, 
    width: 15, 
    marginLeft: 15, 
    marginRight: 15
  },
  rightIcon:{
    height: 25, 
    width: 25, 
    marginLeft: 15, 
    marginRight: 15
  },
  inputBoxmarginTop: {
    marginTop: 20,
  },
  slide1: {
    width: "100%",
    alignItems: "center",
  },
  nextText: {
    fontFamily: family.semibold,
    color: "white",
    fontSize: 17,
    // letterSpacing: 1,
  },
  buttonView: {
    width: !mobilBrower() ? "80%" : "100%",
  },
  nextButton: {
    backgroundColor: "#030135",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginVertical: 30,
    paddingHorizontal: 13,
    paddingVertical: 8,
    height: "auto",
    width: "100%"
  },
  commonAnimationWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textInput: {
    paddingHorizontal: 15,
    paddingVertical: 12,
    flex: 1,
    marginLeft: 0,
    width: "100%",
  },
});

export default styles