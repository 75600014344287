import { Network } from "../service/api";

export const getDiscussionAction = (authdata, dis_id) => {
  const headers = {
    Accept: "application/json",
    Authorization: authdata,
  };
  return async dispatch => {
    try {
      return Network(`/users/discussions/${dis_id}`, 'GET', null, headers).then(response => {
        if (response.status === 'Success') {
          dispatch({
            type: "GET_DISCUSSION_SUCCESS",
            payload: response.data
          });
        } else {

        }
        return response;
      })
    } catch (error) {
      dispatch({
        type: "GET_DISCUSSION_FAIL",
        payload: error.responseBody
      });
      return error;
    }
  }
}

export const callAskCommunity = (payload) => ({
  type: 'ASK_COMMUNITY',
  payload: payload,
});
