import React, { useState, useEffect } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  BackHandler,
  Text,
} from "react-native";
import Images from "../../common/images";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { SectionA } from "./components/SectionA/SectionA";
import SectionB from "./components/SectionB/SectionB";
import { SectionC } from "./components/SectionC/SectionC";
import { SectionD } from "./components/SectionD/SectionD";
import { SectionE } from "./components/SectionE/SectionE";
import SectionG from "./components/SectionG/SectionG";
import SectionH from "./components/SectionH/Section_H";
import SectionI from "./components/SectionI/SectionI";
import meta from "../../common/metaData";
import { useSelector, useDispatch } from "react-redux";
import { Network } from "../../service/api";
import {
  setSetupProfileQuestionsList, setUndergraduteQuestions, continueFromPreviousSearch,
  setUserImmipathSearchResult, setPreviousTrack, setPreviousSavePaths
} from "../../actions/path.actions";
import Loader from "../Loader";
import { SelectionModal } from '../SelectionModal/SelectionModal';
import helpers, { isWeb, mobilBrower } from "../../common/helpers";
import { styles } from "./UniversitySearch.styles";
import NavigationService from "../../navigation/NavigationService";
import WebFooter from "../../common/WebFooter";
import config from "../../common/config";
import { SavePath } from "./components/SavePath/SavePath";
import AlertModal from "../AlertModal/AlertModal";
import moment from "moment"

const UniversitySearch = () => {
  const [loading, setLoading] = React.useState(0);
  const [pageTrack, setPageTrack] = React.useState(0);
  const [callPageTrack, setcallPageTrack] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const selectedgoalandcountries = useSelector(state => state.pathReducer?.getImmipathDetails?.selectedgoalandcountries)
  const underGraduateUserAnswer = useSelector(state => state.pathReducer?.getImmipathDetails?.underGraduateUserAnswer)
  const userDegreeCourseSelections = useSelector(state => state.pathReducer?.getImmipathDetails?.userDegreeCourseSelections)
  const userSelectionWithQuestionId = useSelector(state => state.pathReducer?.getImmipathDetails?.userSelectionWithQuestionId)
  const underGraduateQuestions = useSelector((state) => state.pathReducer.getImmipathDetails.underGraduateQuestions);
  const previousUserTrack = useSelector((state) => state.pathReducer.getImmipathDetails.previousUserTrack);
  const continuePreviousSearch = useSelector((state) => state.appStateReducer.continuePreviousSearch);
  const [selectedCurrency, setselectedCurrency] = useState("USD");
  const [currencyDropdown, setcurrencyDropdown] = useState([]);
  const [currencyModalVisible, setCurrencyModalVisible] = useState({
    active: false,
    params: null,
  });
  const [saveModalOpen, setsaveMoalOpen] = useState(false)
  const [savedUnivPressed, setSavedUnivPressed] = useState(false)
  const token = useSelector((state) => state.authReducer.authData.token);
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const shortListedUniversity = useSelector(state => state.pathReducer?.getImmipathDetails?.shortListedUniversity)


  useEffect(() => {
    continuePreviousSearch && callPageTrack && setPageTrack(previousUserTrack)
  }, [continuePreviousSearch])

  const getPrevSavedPaths = async () => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let response = await Network(`/immipaths/1/${userDetails?.userid}`, "GET", null, headers)
    const { status, paths } = response;
    if (status == 'Success') {
      dispatch(setPreviousSavePaths(paths))
    }
  }

  useEffect(() => {
    let _dropdown = [];
    config.currencyArray.forEach((s) => {
      s.label = s.value;
      _dropdown.push(s);
    });
    getPrevSavedPaths();
    setcurrencyDropdown(_dropdown);
  }, []);

  const currencySelection = () => {
    setCurrencyModalVisible({
      active: true,
      params: {
        onSelectFn: setselectedCurrency,
        options: currencyDropdown,
        height: "68%",
        itemKey: "label",
        returnKey: "value",
      },
    });
  };

  const nextTrack = async (obj) => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    if (pageTrack == 1) {
      let payload = {
        goal_ids: [1],
        country_ids: obj ? obj.countries : selectedgoalandcountries.countries,
      }
      let url = "/immipaths/get_chat_bot_questions";
      setLoading(true)
      let response = await Network(url, "POST", payload, headers)
      const { form_data, status } = response
      if (status == 'Success') {
        dispatch(setSetupProfileQuestionsList(form_data))
      }
      setLoading(false)
    } else if (pageTrack == 2) {
      setLoading(true)
      let payload = {
        country_ids: selectedgoalandcountries.countries,
        degree_id: userDegreeCourseSelections['Degree'],
        course_id: userDegreeCourseSelections['Course']
      }
      setLoading(true)
      let response = await Network('/university_search/get_search_questions', "POST", payload, headers)
      const { exploration_id } = response;
      if (response.status == 'Success') {
        let payload = {
          exploration_id: exploration_id,
          form_data: response.form_data
        }
        dispatch(setUndergraduteQuestions(payload))
      }
      setLoading(false)
    } else if (pageTrack == 3) {
      let payload = {
        country_ids: selectedgoalandcountries.countries,
        goal_ids: [1],
        exploration_id: underGraduateQuestions.exploration_id,
        form_responses: {
          ...userSelectionWithQuestionId,
          ...underGraduateUserAnswer
        }
      }
      setLoading(true)
      let response = await Network('/immipaths/get_results', "POST", payload, headers)
      const { city_data, country_data, results, status, fact_names, } = response;
      if (status == 'Success') {
        let obj = {
          city_data,
          country_data,
          fact_names,
          results
        }
        dispatch(setUserImmipathSearchResult(obj))
      }
      setLoading(false)
    }
    setPageTrack(pageTrack + 1);
    setLoading(false)
    pageTrack + 1 > 2 && dispatch(setPreviousTrack(pageTrack + 1))
  };

  const prevTrack = () => {
    if (pageTrack == 0) {
      isWeb() ? history.back() : NavigationService.goBack();
      return;
    } else if (savedUnivPressed) {
      setPageTrack(0);
      setSavedUnivPressed(false);
      return true;
    }
    setPageTrack(pageTrack - 1);
    return true;
  };

  const handleContinue = (callpageTrack) => {
    setcallPageTrack(callpageTrack)
    dispatch(continueFromPreviousSearch(true))
  }
  const moveToShortListedScreen = () => {
    setSavedUnivPressed(true);
    setPageTrack(7)
  }

  const handlePreferenceFilter = async () => {
    // setPreferenceFilter({
    //   active: true,
    //   params: {},
    // });
    setsaveMoalOpen(true)

  };


  const getPresentTrack = (track) => {
    const props = { prevTrack, nextTrack, handleContinue, continuePreviousSearch, moveToShortListedScreen, selectedCurrency };
    switch (track) {
      case 0:
        return <SectionA {...props} />;
      case 1:
        return <SectionB {...props} nextTrack={nextTrack} />;
      case 2:
        return <SectionC {...props} />;
      case 3:
        return userDegreeCourseSelections['Degree'] == 1 ? <SectionD {...props} /> : <SectionE {...props} />;
      case 4:
        return <SectionG {...props} />;
      case 5:
        return <SectionH {...props} />;
      case 6:
        return <SectionI {...props} />;
      case 7:
        return <SectionI {...props} />;
      default:
        return <View />;
    }
  };

  React.useEffect(() => {
    if (pageTrack != 0) {
      const backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        prevTrack
      );
      return () => backHandler.remove();
    }
  }, [pageTrack]);

  const saveimmipath = async (title) => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let uniArr = []
    shortListedUniversity.forEach((s, i) => {
      uniArr.push(s.university_id)
    })

    let payload = {
      user_id: userDetails?.userid,
      country_ids: selectedgoalandcountries.countries,
      exploration_id: underGraduateQuestions.exploration_id,
      responses: {
        ...userSelectionWithQuestionId,
        ...underGraduateUserAnswer
      },
      saved_as: title ? title : `${helpers.getGoalName([Number(selectedgoalandcountries.goal)]).join(', ')} search dated: ${moment(new Date()).format('DD-MM-YY')}`,
      immipath_metadata: {
        universities_shortlisted: uniArr
      }
    }
    let response = await Network(`/immipaths/${[Number(selectedgoalandcountries.goal)]}/save`, "POST", payload, headers)
    if (response.status == 'Success') {
      setShowAlert(true)
    }
  }


  const bottomBarTab = [...Array(7)]
  return (
    <View>
      <View style={styles.mainWrapper}>
        {
          isWeb() &&
          <head>
            <meta content={meta.immipathSearch} />
          </head>
        }
        <HeaderComponent
          showNotificationIcon={false}
          showback={false}
          screenTitle="University Search"
        />
        <SelectionModal
          visible={currencyModalVisible.active}
          params={currencyModalVisible.params}
          setVisible={setCurrencyModalVisible}
        />
        <SavePath
          saveimmipath={saveimmipath}
          saveModalOpen={saveModalOpen}
          onClose={() => setsaveMoalOpen(false)}
        />
        <View style={styles.main}>
          <View style={styles.mainInnerView}>
            {(
              <View style={styles.header}>
                <TouchableOpacity onPress={prevTrack} style={[styles.arrowWrapper]}>
                  <Image
                    source={Images.backArrow}
                    style={[styles.arrow]}
                    resizeMethod="auto"
                    resizeMode="contain"
                  />
                </TouchableOpacity>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {
                    [7, 6].includes(pageTrack) &&
                    <View style={styles.currencyDropDown}>
                      <TouchableOpacity
                        style={{
                          width: 100,
                          marginTop: 10,
                          height: 40,
                          marginRight: 25,
                          backgroundColor: "white",
                          alignItems: "center",
                          borderRadius: 10,
                          justifyContent: "center",
                          borderWidth: 1,
                          borderColor: "rgb(225,225,225)",
                        }}
                        onPress={currencySelection}
                      >
                        <Text
                          style={{
                            color: "black",
                          }}
                        >
                          {selectedCurrency}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  }
                  {[6, 7].includes(pageTrack) && (
                    <TouchableOpacity
                      onPress={handlePreferenceFilter}
                      style={[styles.arrowWrapper]}
                    >
                      <Image
                        source={Images.diskette}
                        style={[styles.filterIcon]}
                        resizeMethod="auto"
                        resizeMode="contain"
                      />
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            )}
            <View
              style={{
                flex: 1,
                width: "100%",
              }}
            >
              {
                loading ? <Loader /> : null
              }
              {getPresentTrack(pageTrack)}
            </View>
            {pageTrack != 0 && (
              <View style={[styles.indicaterWrapper]}>
                {bottomBarTab.map((e, index) => {
                  return (
                    <View
                      style={[
                        styles.indicator,
                        {
                          backgroundColor:
                            pageTrack === index + 1 ||
                              ((pageTrack == 5 && index == 3)) ? "#0F1143" : "rgb(200,200,200)",
                        },
                      ]}
                    />
                  );
                })}
              </View>
            )}
          </View>
        </View>
      </View>
      {showAlert ? <AlertModal title="University Saved Successfully" showConfirm confirmText="Ok" onConfirmPress={() => setShowAlert(false)} onClose={() => setShowAlert(false)} /> : null}
      {isWeb() && !mobilBrower() ? <WebFooter style={{ zIndex: -1 }} /> : null}
    </View>
  );
};

export default UniversitySearch;
