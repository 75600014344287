import { StyleSheet } from "react-native";
import { family } from "../../../../common";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  checkBox: {
    height: 14,
    width: 14,
    borderWidth: 1,
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  indexName: {
    fontFamily: family.regular,
    marginLeft: 15,
    fontSize: responsiveText(18),
  },
  listView: {
    width: "100%",
    alignSelf: "center",
    height: "86%",
    backgroundColor: "white",
    marginBottom: 40,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
  },
  checkBoxItems: {
    marginTop: 10,
    flex: 1,
    flexDirection: "row",
    width: "90%",
    marginLeft: "5%",
  },
  checkView: {
    height: 10,
    width: 10,
    borderRadius: 2,
    backgroundColor: "green",
  },
});
