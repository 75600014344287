import React from "react";
import { Text, TouchableOpacity, Linking } from "react-native";
import { notificationModalStyles as styles } from "../../notificationModal.styles";
import { helpers } from "../../../../common";
import InAppBrowser from 'react-native-inappbrowser-reborn'

const handlePress = async(item) => {
  if (await InAppBrowser.isAvailable()) {
    try {
      const result = await InAppBrowser.open(item, helpers.inAPPbrowserOption)
    } catch (error) {
      await InAppBrowser.close()
    }
  } else {
    Linking.openURL(item)
  }
};

export const PolicyFooter = ({ link }) => {
  return (
    <TouchableOpacity
      onPress={() => handlePress(link)}
      style={styles.notificationDetailAction}
    >
      <Text style={styles.notificationDetailActionText}>Show More Details</Text>
    </TouchableOpacity>
  );
};
