import React, { useState, createRef, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { styles } from "../CostOfLiving.styles";
import helpers from "../../../common/helpers";
import { groupBy, sumBy } from "lodash";
import { useScreenshot, createFileName } from 'use-react-screenshot'
import AlertModal from "../../AlertModal/AlertModal";

export default function Step3({ handleCardPress = () => { }, selectedCountry, selectedUserAnswers = {}, costOfLivingData, selectedCurrency }) {
  const [showSnapshotButton, setshowSnapshotButton] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [items, setItems] = useState([]);
  const outputViewRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot({
    quality: 1.0,
    type: "image/jpeg",
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    getCombinedObj()
  }, [])

  const getTotalCost = () => {
    return `${helpers.getCurrencySymbol(selectedCurrency)} ${helpers
      .currencyConverter(selectedCurrency, totalCost)
      .toFixed(2)}/month`;
  };

  const getCombinedObj = () => {
    let reducedAtt = groupBy(selectedUserAnswers, (item) => {
      return item.fact_id;
    });
    let combinedObj = {
      "Food": {
        section: "Food",
        items: [],
        icon: "🥡",
        totalCost: 0,
      },
      "Transportation": {
        section: "Transportation",
        icon: "🚉",
        items: [],
        totalCost: 0,
      },
      "Housing": {
        section: "Housing",
        items: [],
        icon: "🏡",
        totalCost: 0,
      },
      "Leisure": {
        section: "Leisure",
        items: [],
        icon: "🎳",
        totalCost: 0,
      },
      "Others": {
        section: "Others",
        items: [],
        icon: "⛳",
        totalCost: 0,
      }
    }
    let defaultArr = [
      {
        label: "Mobile Data",
        fact_id: 101,
        totalCost: costOfLivingData?.facts[8]?.values[0]?.value,
        category: "Others",
      },
      {
        label: "Internet",
        fact_id: 101,
        totalCost: costOfLivingData?.facts[3]?.values[0]?.value,
        category: "Others",
      },
    ];

    reducedAtt[6] = defaultArr;
    let totalCost = 0;
    Object.entries(reducedAtt).forEach((s) => {
      let key = "";
      if (s[1][0].fact_id == 0) {
        key = "Food"
      } else if (
        s[1][0].fact_id == 1 ||
        s[1][0].fact_id == 4 ||
        s[1][0].fact_id == 7
      ) {
        key = "Leisure";
      } else if (s[1][0].fact_id == 2) {
        key = "Transportation";
      } else if (s[1][0].fact_id == 5) {
        key = "Housing";
      } else if (s[1][0].fact_id == 101) {
        key = "Others";
      }
      if (key) {
        combinedObj[key].items.push(...s[1]);
        let cost = sumBy(s[1], "totalCost");
        combinedObj[key].totalCost += cost;
        totalCost += cost
      }
    });
    setTotalCost(totalCost);
    setItems(Object.values(combinedObj));
  };

  const handleScreenshot = async () => {
    try {
      setshowSnapshotButton(false);
      await takeScreenshot(outputViewRef.current)
        .then(savePicture).catch(e => {
          console.log(e)
        });
    } catch (e) {

    }
    setshowSnapshotButton(true);
  };

  const savePicture = async (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName("jpg", `${selectedCountry.label}_cost_of_living`);
    a.click();
  };

  const compare = () => {
    setShowAlert(true)
  };

  return (
    <>
      <View
        ref={outputViewRef}
        style={{
          width: "100%",
          flex: 1,
          backgroundColor: "white",
          paddingTop: 25,
        }}
      >
        <Text style={[styles.TitleText]}>{getTotalCost()}</Text>
        <Text style={styles.subTitle}>{`at ${selectedCountry.label}`}</Text>

        <View style={{ flex: 1, width: "95%", marginLeft: "2.5%" }}>
          <FlatList
            data={items}
            numColumns={2}
            contentContainerStyle={{ width: "100%" }}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  onPress={() => handleCardPress(item)}
                  style={[styles.calculationSingleView]}
                >
                  <Text style={styles.costText}>
                    {helpers.getCurrencySymbol(selectedCurrency) +
                      " " +
                      helpers
                        .currencyConverter(selectedCurrency, item.totalCost)?.toFixed(2)}
                  </Text>
                  <Text style={styles.categoryText}>{item.icon}</Text>
                  <Text style={styles.categoryText}>{item.section}</Text>
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </View>
      {showSnapshotButton ? (
        <View
          style={{
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <View style={styles.bottomButtonView}>
            <TouchableOpacity
              onPress={handleScreenshot}
              style={styles.snapShotButton}
            >
              <Text style={styles.snapShotButtonText}>Save Snapshot</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={compare}
              style={[styles.snapShotButton, { backgroundColor: "#0F1143" }]}
            >
              <Text style={styles.snapShotButtonText}>Compare</Text>
            </TouchableOpacity>
          </View>
        </View>
      ) : null}
      {showAlert ?
        <AlertModal
          title="Coming Soon...!"
          description="This feature will be available soon."
          showConfirm
          confirmText="Ok"
          onConfirmPress={() => setShowAlert(false)}
        />
        : null}
    </>
  );
}
