import React from "react";
import { View, Text } from "react-native";
import { styles } from "../../home.styles";

export const QLanguages = ({ languages }) => {
  const getKnowledgeLevel = (lang) => {
    const level = [];
    if (lang.read) {
      level.push("Read");
    } else if (lang.write) {
      level.push("Write");
    } else if (lang.speak) {
      level.push("Speak");
    }
    return level;
  };

  return (
    <View
      style={{
        width: "90%",
        marginLeft: "5%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {languages.map((lang) => (
        <View
          style={{
            margin: 5,
          }}
        >
          <Text
            style={styles.heading}
          >
            {lang.language}
          </Text>
          <Text
            style={styles.subHeading}
          >
            {getKnowledgeLevel(lang).join(" | ")}
          </Text>
          <Text
            style={styles.note}
          >
            Level of Proficiency: {lang.levelOfProficiency}
          </Text>
        </View>
      ))}
    </View>
  );
};
