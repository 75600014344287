import { StyleSheet } from "react-native";
import { family } from "../../../common";
import { heightPercentageToDP } from "../../../common/responsiveScreen";

export const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "95%",
  },
  notifItemContainer: {
    overflow: 'hidden',
    flex: 1,
  },
  askExpertTextButton: {
    backgroundColor: "#0F1143",
    padding: 12,
    borderRadius: 10,
    width: "90%",
    marginTop: 10,
  },
  askExpertText: {
    color: "white",
    fontFamily: family.titilliumBold,
    textTransform: "uppercase",
    textAlign: "left",
    fontSize: 17,
  },

  nextWrapper: {
    backgroundColor: "#F1AB32",
    padding: 12,
    borderRadius: 10,
    marginTop: 10,
    width: "43.5%",
  },
  nextText: {
    color: "white",
    fontFamily: family.titilliumBold,
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 17,
  },

  textT: {
    fontFamily: family.titilliumBold,
    fontSize: 25,
    textAlign: "center",
    width: "80%",
    lineHeight: 55,
    marginTop: -25,
  },
  card: {
    width: "90%",
    marginLeft: "5%",
    backgroundColor: "#F5F6F8",
    padding: 20,
    borderWidth: 2,
    borderRadius: 15,
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 15,
    marginBottom: 15,
    borderBottomWidth: 1,
    borderColor: "rgb(220,220,220)",
  },
  imageView: {
    backgroundColor: 'white',
    alignItems: 'center',
    height: 85,
    width: 85,
    justifyContent: 'center'
  },
  univIcon: {
    fontSize: 18,
    fontFamily: family.robotoBlack
  },
  univName: {
    fontFamily: family.titilliumBold,
    fontSize: 20,
  },
  univLocation: {},
  details: {
    fontSize: 16,
    fontFamily: family.regular,
  },
  detailsCard: {
    width: "100%",
    flex:1,
    alignSelf: "center",
    borderRadius: 10,
    borderColor: "#BDBDBD",
    marginBottom: 10,
  },
  iconV: {
    height: 70,
    width: 70,
    marginTop: -35,
    borderColor: "rgb(230,230,230)",
    borderWidth: 3,
    borderRadius: 50,
  },
  detailWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 5,
  },
  detailContentWrapper: {
    width: "90%",
  },
  detailsTitle: {
    fontSize: 16,
    fontFamily: family.semibold,
    fontWeight: 'bold'
  },
  bullet: {
    height: 15,
    width: 15,
    marginTop: 3,
    marginRight: 10,
  },
  params: {
    marginBottom: 15,
    width: "50%",
    marginTop: 5,
  },
  searchIcon: {
    height: 11, 
    width: 11,
    marginLeft: 5
  },
  stepLabelSelected: {
    fontSize: 15,
    textAlign: "left",
    marginTop: 5,
    color: "#030135",
    fontWeight: "bold",
  },
  stepLabel: {
    fontSize: 15,
    textAlign: "left",
    marginTop: 5,
    color: "#030135",
  },
  univParamLabel: {
    fontFamily: family.effra,
    fontSize: 18,
    color: "#2f3280",
  },
  univParamValue: {},
  selectButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F1AB32",
    borderRadius: 10,
  },
  description: {
    fontSize: 16,
    width: "95%",
    fontFamily: family.regular,
    textAlign: "justify",
  },
  btnContainer:{ 
    //   marginBottom: 20 ,
      padding:5,
      flexDirection:"row"
    },
  shortButton: {
    flex:1,
    alignItems: "center",
    backgroundColor: "green",
    borderRadius: 5,
    paddingVertical: 10,
    // padding: 15,
    alignSelf: "center",
    marginHorizontal:5,
    // marginTop: 0,
    // marginBottom: 10,
    justifyContent: "center",
  },
  Componentdescription: {
    marginTop: 10,
    fontSize: 15,
    fontFamily: family.semibold,
  },
  metaDataView: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 15,
    marginBottom: 30
  },
  metaDataView_date: {
    fontSize: heightPercentageToDP('1.5%'),
    fontFamily: family.titilliumBold,
  },
  shortText: {
    fontSize: 15,
    color: "white",
    fontFamily: family.semibold,
  },
  chancesIcon: {
    height: 15,
    width: 15,
    marginLeft: 5,
    marginTop: 1,
  },
  chancesWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 8,
    marginBottom: -5,
  },
  chancesText: {
    textAlign: "right",
    fontFamily: family.titilliumBold,
    color: "black",
  },
  recommended: {
    backgroundColor: "#33AC09",
    paddingLeft: 15,
    paddingRight: 15,
    padding: 5,
    borderRadius: 5,
    width: 130,
    color: "white",
    marginTop: 5,
  },
  componentTitle: {
    fontSize: 17,
    fontFamily: family.semibold,
    color: "#030135",
  },
  componentView: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
    width: "90%",
    alignSelf: "center",
  },
  closeButton: {
    marginRight: 15,
    alignSelf: "flex-end",
    marginTop: 10,
  }
});
