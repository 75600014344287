import { StyleSheet } from "react-native";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "95%",
  },

  nextWrapper: {
    backgroundColor: "rgb(15, 17, 67)",
    borderRadius: 25,
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 25,
  },
  nextText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: responsiveText(16),
  },
  textT: {
    fontSize: 47,
    textAlign: "center",
    width: "80%",
    lineHeight: 55,
    marginTop: -25,
  },
  goalView: {
    backgroundColor: 'white',
    alignItems: 'center',
    height: 85,
    width: 85,
    margin: 10,
    borderWidth: 0.5,
    marginRight: 10,
    borderColor: '#BDBDBD',
    justifyContent: 'center'
  },
  goalText: {
    fontSize: 20,
    color: '#800000'
  },
  title: {
    fontSize: 15,
    flexShrink: 1,
    marginBottom: 20,
    color: '#000'
  },
  list: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
    paddingVertical: 15,
    width: '96%',
    alignSelf: 'center',
    borderRadius: 15,
    borderColor: "rgb(230,230,230)",
    borderWidth: 1,
  },
  detailsView: {
    flexDirection: "column",
    marginLeft: 10,
    flexShrink: 1,
  },
  countryText: {
    fontSize: 13,
    textAlign: 'center'
  },
  countryNameView: {
    backgroundColor: '#F1F3F4',
    flexShrink: 1,
    alignItems: 'center',
    padding: 5,
    borderRadius: 10,
    // width: 85,
    margin: 5,
    justifyContent: 'center',
    // maxWidth: 80,
    shadowColor: '#000',
    shadowRadius: 1,
    shadowOpacity: 0.3,
    shadowOffset: { height: 0.2, width: 0.2 }
  },
  countryFlag: {
    height: 15,
    width: 15
  },
  headerText: {
    fontSize: 25,
    textAlign: "center",
    marginBottom: 10,
    marginTop: 5,
  }
});
