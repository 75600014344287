import React, { useState } from 'react'
import {
  Text, View, TouchableOpacity, Image, Modal, Dimensions,
  FlatList, Platform, ScrollView, TextInput, ActivityIndicator,
} from "react-native";
import { BlurView } from "@react-native-community/blur";
import { styles } from "./newConversationModal.styles";
import Images from '../../common/images';
import { useSelector } from 'react-redux';
import { Network } from '../../service/api';
import StarRating from "react-native-star-rating";
import SweetAlert from '../SweetAlert';
const { height, width } = Dimensions.get('window');

export default function ReviewConsultant({ onClose, chat_id, expert_id, setStarCount, agentName }) {
  const [ratingStars, setratingStars] = useState(0)
  const [review, setreview] = useState('')
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);


  const onSubmit = async (star) => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    const url = `/users/${userDetails?.userid}/log_actions/new_discussion_review`;
    const body = {
      rating: ratingStars,
      review_comments: review,
      chat_id: chat_id,
      platform_id: expert_id
    };
    let res = await Network(url, "POST", body, headers);
    if (res.status == 'Success') {
      setStarCount(ratingStars)
      SweetAlert.success('Review submitted successfully!')
      setTimeout(() => {
        onClose()
      }, 3000);
    } else {
      SweetAlert.error('Review submission failed. Try again later!')
    }
  }

  return (
    <Modal transparent>
      <BlurView
        blurType="light"
        style={{ height: height, width: width, position: 'absolute' }}
        blurAmount={3}>
        <View style={styles.discount_mainView}>
          <View style={styles.discount_innerView}>
            <Text style={styles.discount_Title}>
              Rate Our Expert:
            </Text>
            <Text style={styles.aganeName}>
              {agentName}
            </Text>
            <View style={styles.middleView}>
              <StarRating
                disabled={false}
                maxStars={5}
                starSize={17}
                fullStarColor={"orange"}
                rating={ratingStars}
                selectedStar={(rating) => setratingStars(rating)}
              />
            </View>
            <View style={[styles.promoView]}>
              <View style={styles.promoInputBox}>
                <TextInput
                  style={styles.promoTextBox}
                  multiline
                  onChangeText={(text) => setreview(text)}
                  placeholder='Enter your comment (optional)'
                />
              </View>
            </View>
            <View style={styles.buttonView}>
              <TouchableOpacity onPress={onSubmit} style={styles.button1}>
                <Text style={styles.submitButtonText}>
                  Submit
                </Text>
              </TouchableOpacity>
              {/* <TouchableOpacity onPress={onClose} style={styles.cancelButton}>
                <Text style={styles.applyButtonText}>
                  Cancel
                </Text>
              </TouchableOpacity> */}
            </View>
          </View>
        </View>
      </BlurView>
    </Modal>
  )
}
