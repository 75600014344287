import React from 'react'
import {
    StyleSheet,
    Text,
    View,
    Image,
    TextInput,
    TouchableHighlight,
    Dimensions,
    TouchableOpacity,
    BackHandler,
    Alert,
    Modal,
    SafeAreaView,
    Platform,
    KeyboardAvoidingView,
    Keyboard,
    FlatList
} from "react-native";
import { family, Images } from "../common";
import { isArray } from 'lodash';

const height = Dimensions.get('window').height;
const width = Dimensions.get('window').width;

function ShareProfileModal(props) {
    return (
        <Modal transparent visible={props.visible}>
            <View style={styles.mainView}>
                <View style={styles.innerView}>
                    <TouchableOpacity hitSlop={{ left: 30, right: 30, top: 30, bottom: 30 }} onPress={() => props.handleClose()} style={styles.cancelButton}>
                        <Image source={Images.cancelGrey} style={{ height: 8, width: 8 }} />
                    </TouchableOpacity>
                    <FlatList
                        data={Object.entries(props.userObj)}
                        style={{ marginBottom: 15 }}
                        renderItem={({ item, index }) => {
                            if (item[1] !== null) {
                                return (
                                    <View style={styles.item}>
                                        <Text style={styles.info}>
                                            {item[0]}
                                        </Text>
                                        <Text style={[styles.info, { color: '#e9a431', width: '55%' }]}>
                                            {isArray(item[1]) ? item[1].map((s) => s.name).join(', ') : item[1]}
                                        </Text>
                                    </View>
                                )
                            } else {
                                return null
                            }

                        }}
                    />
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    mainView: {
        height: height,
        width: width,
        alignItems: 'center',
        backgroundColor: '#222222c7',
        justifyContent: 'center'
    },
    cancelButton: {
        marginRight: 15,
        height: 20,
        width: 20,
        alignSelf: 'flex-end',
        borderRadius: 10,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 12,
    },
    info: {
        fontFamily: family.semibold,
        width: '40%',
        color: 'white',
        fontSize: 14,
        padding: 5,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.8,
        shadowColor: '#BDBDBD',
    },
    innerView: {
        backgroundColor: '#030135',
        width: '85%',
        borderRadius: 20,
    },
    item: {
        flexDirection: 'row',
        width: '80%',
        alignSelf: 'center',
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center'
    }
})
export default ShareProfileModal
