import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
import mock from "../../data/data.json";
import { styles } from "./mediaTabs.styles";
import { setActiveTab } from "../../../../actions/mainMedia.actions";
import { useDispatch, useSelector } from "react-redux";

function MediaTabs(props) {
  const dispatch = useDispatch();
  const activeTab = useSelector(
    (state) => state.mainMediaReducer.gState.activeTab.key
  );

  return (
      <View style={styles.tabView}>
        {Object.entries(mock.routes).map((route) => {
          return (
            <TouchableOpacity
              onPress={() => dispatch(setActiveTab(route[1]))}
              key={route[0]}
              style={[
                styles.tabViewButton,
                {
                  backgroundColor:
                    activeTab == route[1].key ? "#030135" : "lightgray",
                },
              ]}
              activeOpacity={0.8}
            >
              <Text
                style={
                  (styles.tabsText,
                  {
                    color: activeTab == route[1].key ? "#fff" : "black",
                  })
                }
              >
                {route[1].title[0].toUpperCase() + route[1].title.slice(1)}
              </Text>

            </TouchableOpacity>
          );
        })}
      </View>
  );
}

export default (MediaTabs) 