import React, { useState } from 'react'
import { View, TouchableOpacity, Text, ScrollView, Modal, Pressable, Image, Platform } from "react-native";
import { heightPercentageToDP } from 'react-native-responsive-screen';
import { helpers } from '../../../../../../common';
import Images from '../../../../../../common/images';
import { apiPaths, Network } from '../../../../../../service/api';
import BullitinModal from '../../../../../BullitinModal/BullitinModal';
import { styles } from '../../sectionI.styles';
import { useSelector, useDispatch } from "react-redux";
import TimelineView from '../TimelineView';
import { useHistory } from 'react-router-dom';
import { isArray } from 'lodash';
import { getAppTrackerList } from '../../../../../../actions/dashboard.actions';
import VisaSaved from '../../../../../../pages/QuickSearch/components/VisaSaved';
import NavigationService from '../../../../../../navigation/NavigationService';
import RewardModal from '../../../../../../pages/RewardModal';
import { isWeb } from '../../../../../../common/helpers';
import { viewImmipathId } from '../../../../../../actions/path.actions';

export default function VisaDetailsModal({ visible, selectedPath, onClose, criteria }) {
  const [loading, setloading] = useState(false)
  const history = useHistory();
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin)
  const token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const [showTimelineModal, setshowTimelineModal] = useState(false);
  const [timelineData, settimelineData] = useState(null);
  const [showRewardModal, setshowRewardModal] = useState(false);
  const [appRefId, setAppRefId] = useState(null);
  const isPathSaved = useSelector((state) => Object.values(state.dashReducer.appTrackerList || {}).find(path => path.immipath_id === selectedPath.immipath_id));
  const [showApplicationSavedModal, setshowApplicationSavedModal] = useState(false);
  const dispatch = useDispatch()

  const handleTimeline = () => {
    setloading(true);
    const url = "/immipaths/timelines?";
    const headers = {
      Accept: "application/json",
      Authorization: !guestLogin ? `${userDetails?.userid}@${token}` : 'guest@immipath',
    };
    let Obj = {
      exploration_id: null,
      immipath_ids: [selectedPath?.immipath_id],
      base_filters: {},
    };
    Network(url, "POST", Obj, headers)
      .then((res) => {
        if (res.status == "Success") {
          setloading(false);
          settimelineData(res.timelines_info);
          setTimeout(() => {
            setshowTimelineModal(true);
          }, 500);
        }
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const handleModalClose = () => {
    setshowApplicationSavedModal(false);
    onClose()
    let params = {
      app_ref: isPathSaved?.application_id || appRefId,
      sortcut: true,
      selectedPath: selectedPath,
    }
    if (isWeb()) {
      history.push("profile", params)
    } else {
      dispatch(viewImmipathId(params))
      NavigationService.navigate("NewProfileScreen", params);
    }
  };

  const handleShortlistPress = () => {
    if (isPathSaved) {
      handleModalClose()
      return;
    }
    const postData = {
      application_fields: {},
      application_metadata: {},
      exploration_id: null,
      user_id: userDetails?.userid,
    };
    const headers = {
      Accept: "application/json",
      Authorization: !guestLogin ? `${userDetails?.userid}@${token}` : 'guest@immipath',
    };
    let url = `${selectedPath.immipath_id}/save`;
    Network(
      apiPaths.application_referrals + url,
      "POST",
      postData,
      headers
    ).then(async (res) => {
      await dispatch(getAppTrackerList(userDetails?.userid, userDetails?.userid + "@" + token));
      setAppRefId(res.application_reference);
      setshowApplicationSavedModal(true)
      // onClose()
    });
  };

  const handleRewardModal = () => {
    setshowApplicationSavedModal(false);
    setTimeout(() => {
      setshowRewardModal(true);
    }, 500);
  };

  return (
    <BullitinModal
      heading=""
      visible={visible}
      onClose={onClose}
      handleClose={onClose}
      closeIcon={!showTimelineModal && "closeBlack"}
    >
      <View style={styles.notifItemContainer}>
        {
          showTimelineModal ?
            <>
              <TimelineView
                onClose={() => setshowTimelineModal(false)}
                timelineData={timelineData} />
            </> :

            <View style={{ flex: 1 }}>
              {selectedPath !== null ? (
                <View style={styles.detailsCard}>
                  <View style={{ alignSelf: "center", marginBottom: heightPercentageToDP('3%') }}>
                    <Image
                      source={helpers.getCountryFlag(selectedPath?.country_id)}
                      style={[styles.iconV]}
                    />
                  </View>
                  <View
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      flex: 1
                    }}
                  >
                    <ScrollView showsVerticalScrollIndicator={true} nestedScrollEnabled={true}>
                      {
                        showApplicationSavedModal ?
                          <VisaSaved
                            visible={true}
                            token={token}
                            handleRewardModal={handleRewardModal}
                            handleModalClose={handleModalClose}
                            userid={userDetails?.userid}
                          /> :
                          <Pressable>
                            {
                              criteria ?
                                <View style={{ alignItems: "center" }}>
                                  <Text
                                    style={[
                                      styles.details,
                                      {
                                        fontWeight: "bold",
                                        paddingTop: 10,
                                        marginBottom: 5,
                                      },
                                    ]}
                                  >
                                    Eligibility Criteria
                                  </Text>
                                  {selectedPath?.eligibility_criteria?.length > 0 ? (
                                    selectedPath.eligibility_criteria.map((s) => (
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          marginTop: 5,
                                        }}
                                      >
                                        <Image
                                          source={Images.bullet}
                                          style={[styles.bullet, { marginBottom: 10 }]}
                                        />
                                        <Text style={[styles.details, { width: "90%" }]}>
                                          {s}
                                        </Text>
                                      </View>
                                    ))
                                  ) : (
                                    <View />
                                  )}
                                </View> :
                                <>
                                 <Text
                                    style={[
                                      styles.details,
                                      {
                                        fontWeight: "bold",
                                        marginBottom: 5,
                                        textAlign: "center",
                                      },
                                    ]}
                                  >
                                    {selectedPath.immipath_name}
                                  </Text>
                                  <Text
                                    style={[
                                      styles.details,
                                      {
                                        fontWeight: "600",
                                        marginBottom: 5,
                                        textAlign: "center",
                                      },
                                    ]}
                                  >
                                    Description
                                  </Text>
                                  <Text style={[styles.description]}>
                                    {selectedPath.path_description}
                                  </Text>

                                  <View
                                    style={{
                                      padding: 5,
                                    }}
                                  >
                                    <View style={[styles.detailWrapper, { marginTop: 15 }]}>
                                      <Image source={Images.bullet} style={styles.bullet} />
                                      <View style={styles.detailContentWrapper}>
                                        <Text style={styles.detailsTitle}>Visa type: </Text>
                                        <Text style={styles.details}>
                                          {isArray(selectedPath.goal_ids) ? helpers.getGoalName(selectedPath.goal_ids).join(', ') : selectedPath.goalName}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={styles.detailWrapper}>
                                      <Image source={Images.bullet} style={styles.bullet} />
                                      <View style={styles.detailContentWrapper}>
                                        <Text style={styles.detailsTitle}>
                                          Approximate time for application:{" "}
                                        </Text>
                                        <Text style={styles.details}>
                                          {selectedPath.time_for_user_application_min ===
                                            selectedPath.time_for_user_application_max
                                            ? ""
                                            : selectedPath.time_for_user_application_max}{" "}
                                          days
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={styles.detailWrapper}>
                                      <Image source={Images.bullet} style={styles.bullet} />
                                      <View style={styles.detailContentWrapper}>
                                        <Text style={styles.detailsTitle}>Validity: </Text>
                                        <Text style={styles.details}>
                                          {selectedPath.validity}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                  <View style={{ alignItems: "center" }}>
                                    <Text
                                      style={[
                                        styles.details,
                                        {
                                          fontWeight: "bold",
                                          paddingTop: 10,
                                          marginBottom: 5,
                                        },
                                      ]}
                                    >
                                      Eligibility Criteria
                                    </Text>
                                    {selectedPath.eligibility_criteria.length > 0 ? (
                                      selectedPath.eligibility_criteria.map((s) => (
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 5,
                                          }}
                                        >
                                          <Image
                                            source={Images.bullet}
                                            style={[styles.bullet, { marginBottom: 10 }]}
                                          />
                                          <Text style={[styles.details, { width: "90%" }]}>
                                            {s}
                                          </Text>
                                        </View>
                                      ))
                                    ) : (
                                      <View />
                                    )}
                                  </View>
                                </>
                            }
                          </Pressable>
                      }
                    </ScrollView>
                  </View>
                </View>
              ) : (
                <View />
              )}
              {selectedPath !== null && !showApplicationSavedModal ? (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <TouchableOpacity
                    activeOpacity={0.8}
                    onPress={handleTimeline}
                    style={[
                      styles.actionButton,
                    ]}
                  >
                    <Text style={styles.shortText}>View Timeline</Text>
                  </TouchableOpacity>
                  {
                    !guestLogin &&
                    <TouchableOpacity
                      activeOpacity={0.8}
                      onPress={handleShortlistPress}
                      style={[
                        styles.actionButton, { backgroundColor: 'green', }
                      ]}
                    >
                      <Text style={styles.shortText}>{isPathSaved ? 'View' : 'Shortlist'}</Text>
                    </TouchableOpacity>
                  }
                </View>
              ) : (
                <View />
              )}
            </View>
        }
      </View>
      {showRewardModal ?
        <RewardModal
          visible={true}
          price={100}
          text='congratulations on shortlisting your first path!'
          title={'Wohoo!\nAnother reward for you!'}
          onClosed={() => setshowRewardModal(false)}
        /> :
        <View />}
    </BullitinModal>
  )
}
