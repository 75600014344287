import React, { useState, useEffect } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  BackHandler,
  Text
} from "react-native";
import Images from "../../common/images";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { ImmigreatSearchHome } from "./components/Home/ImmigreatSearchHome";
import { SectionA } from "./components/SectionA/SectionA";
import SectionB from "./components/SectionB/SectionB";
import { SectionC } from "./components/SectionC/SectionC";
import { styles as s } from "./EligibilityChecker.styles";
import meta from "../../common/metaData";
import { useSelector, useDispatch } from "react-redux";
import { Network } from "../../service/api";
import {
  setSetupProfileQuestionsList, setUndergraduteQuestions, continueFromPreviousSearch,
  setUserImmipathSearchResult, setPreviousTrack
} from "../../actions/path.actions";
import Loader from "../Loader";
import config from "../../common/config";
import { SelectionModal } from '../SelectionModal/SelectionModal';
import helpers, { isWeb, mobilBrower } from "../../common/helpers";
import AlertModal from "../AlertModal/AlertModal";
import WebFooter from "../../common/WebFooter";
import { withRouter, useParams } from "react-router-dom";
import DownloadModal from "../../components/DownloadModal/DownloadModal";
import SweetAlert from "../SweetAlert";
import { setStorebaseCurrency } from "../../actions/referral.actions";

const EligibilityChecker = ({ history }) => {
  const [loading, setLoading] = React.useState(0);
  const [sameGoalCountriesAsLast, setsameGoalCountriesAsLast] = useState({ goal: false, countries: false })
  const [pageTrack, setPageTrack] = React.useState(0);
  const [callPageTrack, setcallPageTrack] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const { code } = useParams() || {}
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [currencyDropdown, setcurrencyDropdown] = useState([]);
  const [selectedCurrency, setselectedCurrency] = useState("USD");
  const selectedgoalandcountries = useSelector(state => state.pathReducer?.getImmipathDetails?.selectedgoalandcountries)
  const userSelections = useSelector(state => state.pathReducer?.getImmipathDetails)
  const underGraduateQuestions = useSelector((state) => state.pathReducer.getImmipathDetails.underGraduateQuestions);
  const previousUserTrack = useSelector((state) => state.pathReducer.getImmipathDetails.previousUserTrack);
  const continuePreviousSearch = useSelector((state) => state.appStateReducer.continuePreviousSearch);
  const showAllVisas = useSelector(state => state.pathReducer?.getImmipathDetails?.showAllVisas)
  const [currencyModalVisible, setCurrencyModalVisible] = useState({
    active: false,
    params: null,
  });
  const dispatch = useDispatch();
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const Token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );

  const getbaseCurrency = async () => {
    const headers = {
      Accept: "application/json",
      Authorization: 'guest@immipath',
    };
    let res = await Network("/data/get_country", "GET", null, headers);
    if (res.status == "Success") {
      let currency = config.countries_json.filter(
        (s) => s.id == res.country_code
      );
      dispatch(setStorebaseCurrency(currency[0].currency));
    }
  };

  useEffect(() => {
    getbaseCurrency()
    if (code && mobilBrower()) {
      setShowDownloadModal(true)
    }
    continuePreviousSearch && callPageTrack && setPageTrack(previousUserTrack)
  }, [continuePreviousSearch])

  const _handleSameGoalCountries = (goal, countries) => {
    setsameGoalCountriesAsLast({
      goal: goal,
      countries: countries
    })
  }

  useEffect(() => {
    let _dropdown = [];
    config.currencyArray.forEach((s) => {
      s.label = s.value;
      _dropdown.push(s);
    });
    setcurrencyDropdown(_dropdown);
    helpers.refreshCurrency();
  }, []);

  const nextTrack = async (obj) => {
    const headers = {
      Accept: "application/json",
      Authorization: guestLogin ? `guest@immipath` : userDetails?.userid + "@" + Token,
    };
    if (pageTrack == 2) {
      let payload = {
        goal_ids: obj ? [Number(obj.goal)] : [Number(selectedgoalandcountries.goal)],
        country_ids: obj ? obj.countries : selectedgoalandcountries.countries,
      }
      let url = (payload?.goal_ids[0] == '3' || payload?.goal_ids[0] == '2') ? '/immipaths/get_search_questions' : '/immipaths/get_chat_bot_questions'
      setLoading(true)
      let response = await Network(url, "POST", payload, headers)
      const { form_data, status } = response
      if (status == 'Success') {
        if (payload?.goal_ids[0] == '3' || payload?.goal_ids[0] == '2') {
          let payload = {
            exploration_id: response?.exploration_id,
            form_data: response.form_data
          }
          dispatch(setUndergraduteQuestions(payload))
        }
        dispatch(setSetupProfileQuestionsList(form_data))
      }
      setLoading(false)
    } else if (pageTrack == 3) {
      setLoading(true)
      let resultsObj;
      let payload;
      let path;
      if (selectedgoalandcountries.goal == '3' || selectedgoalandcountries.goal == '2') {
        payload = {
          country_ids: selectedgoalandcountries.countries,
          goal_ids: [Number(selectedgoalandcountries.goal)],
          show_all: showAllVisas,
          exploration_id: underGraduateQuestions.exploration_id,
          form_responses: {
            ...userSelections.userSelectionWithQuestionId,
          },
          metadata: {
            ...selectedgoalandcountries.userInfo
          }
        }
        path = '/immipaths/get_results';
        let response = await Network(path, "POST", payload, headers)
        setLoading(false)
        const { country_data, results, status, excluded_reason, promotions, excluded_paths } = response;
        if (status == 'Success') {
          resultsObj = {
            country_data,
            excluded_paths,
            excluded_reason,
            promotions,
            results
          }
        } else {
          SweetAlert.error("Something went wrong, please try after sometimes...");
          return;
        }
      } else {
        setLoading(true)
        payload = {
          exploration_id: underGraduateQuestions.exploration_id,
          show_all: showAllVisas,
          base_filters: {
            country_ids: selectedgoalandcountries.countries,
            goal_ids: [Number(selectedgoalandcountries.goal)]
          },
          elig_qns_responses: {
            ...userSelections.userSelectionWithQuestionId,
          }
        }
        path = '/immipaths/get_eligible_visas';
        let response = await Network(path, "POST", payload, headers)
        const { country_data, immipaths: results, status, excluded_reason, promotions, excluded_paths } = response;
        if (status == 'Success') {
          resultsObj = {
            country_data,
            excluded_paths,
            excluded_reason,
            promotions,
            results
          }
        }
        setLoading(false)
      }
      let obj = {
        ...resultsObj,
        payload,
        path,
        userInfo: selectedgoalandcountries.userInfo,
        userSelections: userSelections.userDegreeCourseSelections
      }
      dispatch(setUserImmipathSearchResult(obj))
      history.push("/opportunityReport");
      return;
    }
    setPageTrack(pageTrack + 1);
    setLoading(false)
    pageTrack + 1 > 2 && dispatch(setPreviousTrack(pageTrack + 1))
  };

  const prevTrack = () => {
    if (pageTrack == 4) {
      setShowAlert(true);
    } else {
      setPageTrack(pageTrack - 1);
    }
  };

  const currencySelection = () => {
    setCurrencyModalVisible({
      active: true,
      params: {
        onSelectFn: setselectedCurrency,
        options: currencyDropdown,
        height: "68%",
        itemKey: "label",
        returnKey: "value",
      },
    });
  };


  const handleContinue = (callpageTrack) => {
    setcallPageTrack(callpageTrack)
    dispatch(continueFromPreviousSearch(true))
  }

  const getPresentTrack = (track) => {
    const props = { prevTrack, nextTrack, handleContinue, continuePreviousSearch, selectedCurrency };
    switch (track) {
      case 0:
        return <ImmigreatSearchHome {...props} history={history} _handleSameGoalCountries={_handleSameGoalCountries} />;
      case 1:
        return <SectionA {...props} />;
      case 2:
        return <SectionB {...props} nextTrack={nextTrack} sameGoalCountriesAsLast={sameGoalCountriesAsLast} />;
      case 3:
        return <SectionC {...props} />;
      default:
        return <View />;
    }
  };

  React.useEffect(() => {
    if (pageTrack != 0) {
      const backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        prevTrack
      );
      return () => backHandler.remove();
    }
  }, [pageTrack]);

  const bottomBarTab = [...Array(3)]
  return (
    <View>
      <View style={s.mainWrapper}>
        {
          isWeb() &&
          <head>
            <meta content={meta.immipathSearch} />
          </head>
        }
        <HeaderComponent
          showNotificationIcon={false}
          showback={false}
          screenTitle="Eligibility Checker"
        />
        <SelectionModal
          visible={currencyModalVisible.active}
          params={currencyModalVisible.params}
          setVisible={setCurrencyModalVisible}
        />
        <View style={s.main}>
          <View style={s.mainInnerView}>
            {pageTrack != 0 && (
              <View style={s.header}>
                <TouchableOpacity onPress={prevTrack} style={[s.arrowWrapper]}>
                  <Image
                    source={Images.backArrow}
                    style={[s.arrow]}
                    resizeMethod="auto"
                    resizeMode="contain"
                  />
                </TouchableOpacity>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {
                    [3].includes(pageTrack) &&
                    <View style={s.currencyDropDown}>
                      <TouchableOpacity
                        style={{
                          width: 100,
                          height: 40,
                          marginRight: 25,
                          backgroundColor: "white",
                          alignItems: "center",
                          borderRadius: 10,
                          justifyContent: "center",
                          borderWidth: 1,
                          borderColor: "rgb(225,225,225)",
                        }}
                        onPress={currencySelection}
                      >
                        <Text
                          style={{
                            color: "black",
                          }}
                        >
                          {selectedCurrency}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  }
                </View>
              </View>
            )}
            <View
              style={{
                flex: 1,
                width: "100%",
              }}
            >
              {
                loading ? <Loader /> : null
              }
              {getPresentTrack(pageTrack)}
            </View>
            {pageTrack != 0 && (
              <View style={[s.indicaterWrapper]}>
                {bottomBarTab.map((e, index) => {
                  return (
                    <View
                      style={[
                        s.indicator,
                        {
                          backgroundColor:
                            pageTrack === index + 1 ||
                              ((selectedgoalandcountries?.goal == '2' || selectedgoalandcountries?.goal == '3') && (index == 3)) ? "#0F1143" : "rgb(200,200,200)",
                        },
                      ]}
                    />
                  );
                })}
              </View>
            )}
            {showAlert ?
              <AlertModal
                title="Hold on!"
                description="Are you sure you want to go back? All answered questions will be cleared."
                showConfirm
                confirmText="Yes"
                onConfirmPress={() => {
                  setShowAlert(false);
                  setPageTrack(pageTrack - 2)
                }}
                showCancel
                cancelText="Cancel"
                onCancelPress={() => setShowAlert(false)}
              />
              : null}
          </View>
        </View>
      </View>
      {showDownloadModal ? (
        <DownloadModal />
      ) : null}
      {isWeb() && !mobilBrower() ? <WebFooter style={{ zIndex: -1 }} /> : null}
    </View>
  );
};

export default isWeb() ? withRouter(EligibilityChecker) : EligibilityChecker;
