import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StatusBar,
  Switch,
  StyleSheet,
  Platform,
  useWindowDimensions,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { family } from "../common";
import { supportIconStatusChange } from "./../actions/factComparator.actions";
import AsyncStorage from "@react-native-community/async-storage";
import HeaderComponent from "../common/NewHeader/NewHeader";
import { togglePathExpViewType } from "../actions/app.actions";
import { heightPercentageToDP } from "../common/responsiveScreen";

function Settings(props) {
  const supportIconCurrentStatus = useSelector(
    (state) => state.factReducer.factData.supportIconStatus
  );
  const pathExViewType = useSelector(
    (state) => state.appStateReducer.pathExplorerDefaultView
  );

  const [isEnabled, setIsEnabled] = useState(null);
  const dispatch = useDispatch();
  const window = Platform.OS == "web" ? useWindowDimensions() : null;

  const toggleSwitch = (status) => {
    setIsEnabled((previousState) => !previousState);
    dispatch(supportIconStatusChange(status));
    AsyncStorage.setItem("supportIconStatus", JSON.stringify(status));
  };

  const togglePathExplorerViewType = async () => {
    await AsyncStorage.setItem(
      "pathExpViewType",
      !pathExViewType ? "stack" : "list"
    );

    dispatch(togglePathExpViewType(!pathExViewType));
  };

  useEffect(() => {
    let getIconStatus = async () => {
      let status = await AsyncStorage.getItem("supportIconStatus");
      if (status !== null) {
        setIsEnabled(JSON.parse(status) == false ? false : true);
      } else {
        setIsEnabled(true);
      }
    };
    getIconStatus();
  }, [supportIconCurrentStatus]);

  return (
    <View style={{ flex: 1 }}>
      <StatusBar hidden={true} />
      <HeaderComponent
        screenTitle={"Settings"}
        showback={true}
        showNotificationIcon={false}
      />
      <View
        style={[
          styles.setingsItem,
          {
            width: Platform.OS == "web" ? window.width / 2 : "90%",
            marginLeft: "5%",
            marginTop: 20,
          },
        ]}
      >
        <Text style={styles.settingsText}>Show support icon</Text>
        <Switch
          trackColor={{ false: "#767577", true: "#767577" }}
          thumbColor={isEnabled ? "#1c1e66" : "#f4f3f4"}
          ios_backgroundColor="#030135"
          style={styles.switch}
          onValueChange={toggleSwitch}
          value={isEnabled}
        />
      </View>
      <View
        style={[
          styles.setingsItem,
          {
            width: Platform.OS == "web" ? window.width / 2 : "90%",
            marginLeft: "5%",
          },
        ]}
      >
        <Text style={styles.settingsText}>Path Explorer List View</Text>
        <Switch
          trackColor={{ false: "#767577", true: "#767577" }}
          thumbColor={!pathExViewType ? "#1c1e66" : "#f4f3f4"}
          ios_backgroundColor="#030135"
          style={styles.switch}
          onValueChange={togglePathExplorerViewType}
          value={!pathExViewType}
        />
      </View>
      <View
        style={[
          styles.separator,
          { width: Platform.OS == "web" ? window.width / 2 : "80%" },
        ]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  setingsItem: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    justifyContent: "space-between",
    backgroundColor: "rgb(230,230,230)",
    padding: 15,
    borderRadius: 15,
  },
  switch: {
    transform: [{ scale: 1 }],
  },
  settingsText: {
    fontSize: heightPercentageToDP("2.2%"),
    fontFamily: family.regular,
  },
});
export default Settings;
