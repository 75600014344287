import React from 'react'
import {
    StyleSheet, Text, View, TouchableOpacity, FlatList
} from "react-native";
import fontFamily from '../common/fontFamily';
import { responsiveText } from '../common/normalize';
import { heightPercentageToDP } from '../common/responsiveScreen';

export default function FaqFussyDropDown({ fuzzyDropDownData, _setQuestion, onClose }) {
    return (
        <View style={styles.mainView}>
            <FlatList
                data={fuzzyDropDownData}
                showsVerticalScrollIndicator={false}
                keyExtractor={item => item}
                ItemSeparatorComponent={() => (
                    <View style={{ width: '100%', borderWidth: 0.3, borderColor: '#BDBDBD' }}/>
                )}
                renderItem={({ item, index }) => {
                    return (
                        <TouchableOpacity onPress={() =>{ _setQuestion(item.children[0].name), onClose()}} key={index} style={styles.itemView}>
                            <Text style={styles.quesText}>{item.children[0].name}</Text>
                        </TouchableOpacity>
                    )
                }}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    mainView: {
        width: '95%',
        backgroundColor: 'white',
        height: 400,
        zIndex: 9999,
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 1 },
        shadowRadius: 2,
        shadowOpacity: 0.5,
        marginLeft: heightPercentageToDP('2%'),
        borderRadius: 10
    },
    quesText: {
        fontSize: responsiveText(14),
        fontFamily: fontFamily.effra
    },
    itemView: {
        padding: 8,
    },
})