import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Image,
  Text,
  TouchableOpacity,
  Platform,
} from "react-native";
import { Images, family } from "../../../common";
// import { Card } from "native-base";
import * as Animatable from "react-native-animatable";
import moment from "moment";
import { mobilBrower } from "../../../common/helpers";

export default class ConversationList extends Component {
  loadChat(item) {
    const payload = {
      discussion_platform_id:
        item.discussion_platform.type.toLowerCase() === "lawyer" ? 1 : 2,
      topic: item.topic,
      category: item.category,
      is_resolved: item.is_resolved,
      reviewId: item.review_id,
      text: item.topic,
      specializations: item.discussion_platform.specializations,
      type: item.discussion_platform.type,
      agent_name: item.discussion_platform.alias,
      cc_user: item.checked,
      is_answered: item.is_answered,
      advice_package: item?.advice_package,
      expert_id: item?.discussion_platform?.expert_id,
      query_count: item?.query_count,
      discussion_platform: item.discussion_platform,
      follow_up_data: item?.follow_up_data,
      allow_reply: item.allow_reply,
    };
    this.props.triggerChatFunc(item.id, payload, false);
  }

  render() {
    const { index, item, triggerChatFunc, active } = this.props;
    let advice_speciality = "Immigration Agent";
    if (item.discussion_platform.type == "immigration_agent") {
      advice_speciality = "Immigration Agent";
    } else if (item.discussion_platform.type == "lawyer") {
      advice_speciality = "Lawyer";
    } else if (item.discussion_platform.type == "visa_specialist") {
      advice_speciality = "Visa Specialist";
    }
    let DateAsked = item.date_asked.split(" ");
    DateAsked = DateAsked[1] + " " + DateAsked[2] + " " + DateAsked[3];

    const getTime = () => {
      let time1 = moment(item.recent_message_timestamp).format("h:mm a");
      return time1;
    };

    let profilePic = item?.discussion_platform?.profile_image;
    return (
      <TouchableOpacity
        onPress={() => this.loadChat(item)}
        style={{
          width: "90%",
          marginLeft: "5%",
          padding: "2%",
          backgroundColor: active ? "#03013510" : "#fff",
        }}
      >
        <Animatable.View
          useNativeDriver={true}
          animation="fadeIn"
          duration={1000}
          style={styles.convoMainView}
        >
          <View style={styles.imgView}>
            <Image
              source={
                profilePic
                  ? { uri: profilePic }
                  : Images.dummyImg
              }
              style={{ height: "100%", width: "100%", borderRadius: 50 }}
            />
          </View>
          <View style={styles.quesNameView}>
            <View
              style={{
                width: "100%",
              }}
            >
              <Text style={styles.quesTitleText}>
                {item.discussion_platform.alias}
              </Text>
              {item.unseen_updates ? (
                <View style={styles.statusSymbol} />
              ) : (
                <View />
              )}
              <View style={styles.dateView}>
                <Text
                  style={{
                    fontSize: 13,
                    color: "#959B9F",
                  }}
                >
                  {`${DateAsked} ${getTime()}`}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginTop: 8,
                alignItems: "center",
                width: "95%",
              }}
            >
              <Text numberOfLines={3} style={[styles.replyText, {}]}>
                {item.topic}
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 5, width: "95%" }}>
              <Text style={[styles.categoryText]}>
                {item.category.replace("_", " ") + " "}
              </Text>
            </View>
          </View>
        </Animatable.View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  convoMainView: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingBottom: 8,
  },
  tickView: {
    height: 14,
    width: 14,
    borderRadius: 7,
    marginLeft: 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#030135",
  },
  imgView: {
    height: Platform.OS == "web" ? 43 : 35,
    width: Platform.OS == "web" ? 43 : 35,
    borderRadius: Platform.OS == "web" ? 43 / 2 : 35 / 2,
    flexDirection: "row",
    alignSelf: "center",
    marginLeft: 0,
    backgroundColor: "#768089",
  },
  quesNameView: {
    padding: 10,
    width: "95%",
    marginLeft: 5,
  },
  quesTitleText: {
    fontFamily: family.semibold,
    textTransform: "uppercase",
    fontSize: 14,
    color: "#333333",
  },
  replyText: {
    fontSize: 16,
    color: Platform.OS == "web" ? "#C4C4C4" : "#030135",
    fontFamily: family.titilliumBold,
  },
  categoryText: {
    fontSize: 12,
    color: "grey",
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: "rgba(200,200,200, 0.6)",
    paddingTop: 2,
    paddingBottom: 2,
    padding: 10,
    borderRadius: 3,
  },
  dateView: {
    marginTop: 5,
  },
  statusSymbol: {
    height: 7,
    width: 7,
    borderRadius: 3.5,
    marginLeft: 5,
    backgroundColor: "red",
  },
  msgNumbersView: {
    height: 20,
    width: 20,
    borderRadius: 10,
    marginLeft: 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#27A0D9",
  },
});
