import { StyleSheet } from "react-native";
import { family } from "../../../../common";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  headerText: {
    fontFamily: family.effra,
    fontSize: mobilBrower() ? responsiveText(18) : responsiveText(20),
    textAlign: "center",
    flex: 1
  },
  backIcon: {
    width: 22,
    height: 22,
    resizeMode: "contain"
  },
  content: {
    flexDirection: !mobilBrower() ? "row" : "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
  wrapper: {
    height: "95%",
    flex: 1,
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
    alignItems: "center"
  },
  button: {
    backgroundColor: "#F1AB32",
    paddingHorizontal: 90,
    borderRadius: 15,
  },
  buttonText: {
    color: "white",
    fontFamily: family.effra,
    fontSize: 18,
    padding: 12,
    textAlign: "center",
  },
  inputs: {
    width: "100%",
    marginBottom: 15,
    borderRadius: 15,
    padding: 15,
    backgroundColor: "rgb(230,230,230)",
  },
  checkIcon: {
    height: 16,
    width: 16,
    marginRight: 5,
  },
  text: {
    fontFamily: family.effra,
    fontSize: 16,
  },
  currentlyWorkingWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    // justifyContent: "center",
    marginBottom: 20,
  },
  sectionTitle: {
    fontFamily: family.effra,
    fontSize: 18,
    marginBottom: 15,
    marginLeft: 4,
    textAlign: "center",
  },
  errorText: {
    color: "red",
    textAlign: "center",
    fontSize: 16,
    width: "100%",
    fontFamily: family.effra,
  },
  barContainer: {
    flexDirection: "row",
    marginVertical: 5,
    position: "absolute",
    bottom: "-5%"
  },
  bar: {
    width: 35,
    height: 3,
    borderRadius: 50,
    marginHorizontal: 3
  },
  finishedText: {
    color: "black",
    textAlign: "center",
    fontSize: mobilBrower() ? 18 : responsiveText(50),
    width: "60%",
    fontFamily: family.effra,
  },
});
