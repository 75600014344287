import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  ScrollView,
} from "react-native";
import { Config, family } from "../../common";
import Loader from "../Loader";
import config from "../../common/config";
import { heightPercentageToDP } from "react-native-responsive-screen";
import { styles } from "./creditCSelect.styles";
import BullitinModal from "../BullitinModal/BullitinModal";
import AlertModal from "../AlertModal/AlertModal";

const CreditCountrySelectModal = ({
  userDetails,
  callCreditBuilder,
  quesLoading,
  prCalculator,
  prCountries,
  callPrCalculator,
  jobsCountries,
  _handleJobSearch,
  feature,
  onClose,
  handleLanguageFeature,
  languageCountires,
}) => {
  const [countryIntrest, setcountryIntrest] = useState([]);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [prCountriesArr, setprCountriesArr] = useState([]);
  const [langugaeCountriesArray, setLanguageCountriesArray] = useState([]);
  const [setCountriesForJobs, setsetCountriesForJobs] = useState([]);

  const countrySelection = (code) => {
    if (feature === "language") {
      setcountryIntrest([code]);
    } else {
      let tempCountry = [...countryIntrest];
      if (tempCountry.includes(code)) {
        tempCountry = tempCountry.filter((q) => q !== code);
      } else {
        tempCountry.push(code);
      }
      setcountryIntrest(tempCountry);
    }
  };

  useEffect(() => {
    if (feature == "pr") {
      let _prCountriesArr = [];
      if (prCountries) {
        prCountries &&
          config.creditBuilderintrestcountry.forEach((s) => {
            prCountries.forEach((q) => {
              if (s.code == q) {
                _prCountriesArr.push(s);
              }
            });
          });
        let profileCountires = [];
        _prCountriesArr.forEach((q) => {
          userDetails?.countries_of_interest.forEach((b) => {
            if (q.code == b) {
              profileCountires.push(b);
            }
          });
        });
        setcountryIntrest(profileCountires);
        setprCountriesArr(_prCountriesArr);
      }
    } else if (feature == "jobs") {
      let _jobCountries = [];
      if (jobsCountries) {
        jobsCountries &&
          config.creditBuilderintrestcountry.forEach((s) => {
            jobsCountries.forEach((q) => {
              if (s.code == q) {
                _jobCountries.push(s);
              }
            });
          });
        let profileCountires = [];
        _jobCountries.forEach((q) => {
          userDetails?.countries_of_interest.forEach((b) => {
            if (q.code == b) {
              profileCountires.push(b);
            }
          });
        });
        setcountryIntrest(profileCountires);
        setsetCountriesForJobs(_jobCountries);
      }
    } else if (feature == "language") {
      let langCountires = [];
      config.creditBuilderintrestcountry.forEach((q) => {
        languageCountires.forEach((b) => {
          if (q.code == b) {
            langCountires.push(q);
          }
        });
      });
      setLanguageCountriesArray(langCountires);
    } else {
      setcountryIntrest(userDetails?.countries_of_interest);
    }
  }, [prCountries, jobsCountries, languageCountires]);

  const handleConfirm = () => {
    if (countryIntrest.length > 0) {
      if (feature == "jobs") {
        _handleJobSearch(countryIntrest);
      } else if (feature == "pr") {
        callPrCalculator(countryIntrest);
      } else if (feature == "language") {
        handleLanguageFeature(countryIntrest);
      } else {
        callCreditBuilder(countryIntrest);
      }
    } else {
      setshowErrorModal(true);
      setTimeout(() => {
        setshowErrorModal(false);
      }, 1200);
    }
  };

  const getData = () => {
    if (feature == "jobs") {
      return setCountriesForJobs;
    } else if (feature == "pr") {
      return prCountriesArr;
    } else if (feature == "language") {
      return langugaeCountriesArray;
    } else {
      return Config.creditBuilderintrestcountry;
    }
  };

  const getTitle = () => {
    if (feature == "jobs") {
      return "Which countries are you interested to work in?";
    } else if (feature == "pr") {
      return "Which countries do you want to calculate your PR score for?";
    } else if (feature == "language") {
      return "Which country would you like to explore?";
    } else {
      return "Which countries do you want the guide for?";
    }
  };

  const getImage = (item) => {
    if (countryIntrest.includes(item.code)) {
      return item.webImg;
    } else {
      return item.webFlag;
    }
  };

  const handleClose = (func) => {
    func();
  };

  const getModalSize = () => {
    switch (feature) {
      case "language":
        return langugaeCountriesArray.length < 6 ? "33.5%" : "44%";
      case "pr":
        return "55%";
      case "jobs":
        return setCountriesForJobs.length < 6 ? "33.5%" : "44%";
      default:
        return "44%";
    }
  };

  return (
    <BullitinModal
      heading=""
      visible={true}
      onClose={onClose}
      loading={false}
      handleClose={handleClose}
      logoFrom="mid"
      hideCloseButton={false}
      viewableContentHeight={getModalSize()}
    >
      <ScrollView style={styles.innerView}>
        <Text style={styles.title}>{getTitle()}</Text>
        {quesLoading ? <Loader /> : <View />}
        <View style={styles.countiesView}>
          <FlatList
            data={getData()}
            style={{ alignSelf: "center" }}
            contentContainerStyle={{
              alignItems: "center",
              justifyContent: "center",
            }}
            numColumns={prCalculator ? 3 : 5}
            columnWrapperStyle={{width:"100%"}}
            keyExtractor={(_, index) => index + "c"}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                style={[
                  styles.flagbuttons,
                  {
                    marginHorizontal: prCalculator ? 20 : 6,
                  },
                ]}
                onPress={() => countrySelection(item.code)}
              >
                <Image
                  style={[
                    styles.countryIcon,
                    { opacity: countryIntrest.includes(item.code) ? 1 : 0.5 },
                  ]}
                  source={getImage(item)}
                />
                <Text
                  style={{
                    fontSize: heightPercentageToDP("1.2%"),
                    fontFamily: family.semibold,
                  }}
                >
                  {item.label}
                </Text>
              </TouchableOpacity>
            )}
          />
        </View>
        <TouchableOpacity
          onPress={() => handleConfirm()}
          style={styles.confirmBut}
        >
          <Text style={{ color: "white", fontFamily: family.semibold }}>
            Confirm
          </Text>
        </TouchableOpacity>
        {prCalculator ? (
          <Text style={styles.note}>
            Don’t see the country you’re looking for? We are working on
            extending support for more countries!
          </Text>
        ) : (
          <View />
        )}
      </ScrollView>
      {showErrorModal ? (
        <AlertModal
          title="Oops!"
          description="Select at least 1 country to continue"
        />
      ) : (
        <View />
      )}
    </BullitinModal>
  );
};

export default CreditCountrySelectModal;
