import Timer from "react-timer-mixin";
import AsyncStorage from "@react-native-community/async-storage";
import { Network, apiPaths } from "../service/api";
import { Alert } from "react-native";
import { setGuestlogin } from "./path.actions";

var expirystr = "Auth Token not valid or expired!";

export const createNewUser = (payload, emailID) => {
  var str1 = "Verification E-mail Sent to ";
  var str2 = " for New User Email Verification!";
  var status_result_str = str1 + "" + emailID + "" + str2;
  return async (dispatch) => {
    dispatch({
      type: "LOGIN_USER_LOADING",
      isLoading: true,
    });
    Timer.setTimeout(() => {
      dispatch({
        type: "LOGIN_USER_SUCCESS",
        isLoading: false,
      });
    }, 2000);
    const url = apiPaths.createUser;
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      return Network(url, "POST", payload, headers).then((response) => {
        if (status_result_str == response.status) {
          // console.log("inside if");
          if(payload.referral_code){
            AsyncStorage.setItem(
              "ReferralCode",
              payload.referral_code
            );
          }
          dispatch({
            type: "LOGIN_USER_SUCCESS",
            isLoading: false,
          });
          // dispatch({
          //     type: "LANDING_INTRO",
          //     showDestination: true
          // });
        } else if (response.status == "Invalid Referral Code!") {
          dispatch({
            type: "INVALID_REF_CODE",
            payload: "Invalid referral code!",
          });
          // alert('Invalid referral code!')
        } else if (
          response.status == "Referral code must be of 10 characters!"
        ) {
          dispatch({
            type: "INVALID_REF_CODE",
            payload: "Referral code must be of 10 characters!",
          });
          // alert('Invalid referral code!')
        } else {
          // console.log("inside else");
          if(payload.referral_code){
          AsyncStorage.setItem("ReferralCode", payload.referral_code);
          }
          dispatch({
            type: "LOGIN_USER_SUCCESS",
            isLoading: false,
          });
        }
        return response;
      });
      // return fetch(url, {
      //   method: "POST",
      //   body: JSON.stringify(payload),
      //   headers: {
      //     "Content-Type": "application/json"
      //   }
      // })
      //   .then(res => res.json())
      //   .then(response => {
      //     console.log("api response", response);
      //     if (status_result_str == response.status) {
      //       console.log("inside if");
      //       dispatch({
      //         type: "LOGIN_USER_SUCCESS",
      //         isLoading: false
      //       });
      //       // dispatch({
      //       //     type: "LANDING_INTRO",
      //       //     showDestination: true
      //       // });
      //     } else {
      //       console.log("inside else");
      //       dispatch({
      //         type: "LOGIN_USER_SUCCESS",
      //         isLoading: false
      //       });
      //     }

      //     return response;
      //   })
      //   .catch(function(error) {
      //     alert("Response Error!");
      //     console.log("error response", error);
      //   });
    } catch (error) {
      dispatch({
        type: "CREAT_USER_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};
export const updateUserDetails = (payload, user_id, authtoken) => {
  var authdata = user_id + "@" + authtoken;
  return async (dispatch) => {
    dispatch({
      type: "LOGIN_USER_LOADING",
      isLoading: true,
    });
    const url = "/users/" + user_id + "?";
    const headers = {
      Authorization: authdata,
    };
    try {
      return Network(url, "POST", payload, headers).then((response) => {
        dispatch({
          type: "LOGIN_USER_SUCCESS",
          isLoading: false,
        });
        return response;
      });
      // return fetch(url, {
      //   method: "POST",
      //   body: JSON.stringify(payload),
      //   headers: {
      //     Authorization: authdata,
      //     "Content-Type": "application/json"
      //   }
      // })
      //   .then(res => res.json())
      //   .then(response => {
      //     console.log("api response", response);
      //     dispatch({
      //       type: "LOGIN_USER_SUCCESS",
      //       isLoading: false
      //     });
      //     return response;
      //   })
      //   .catch(function(error) {
      //     console.log("error response", error);
      //   });
    } catch (error) {
      dispatch({
        type: "CREAT_USER_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};

const storeData = async (token, userId, firstName, lastName, email_address) => {
  try {
    await AsyncStorage.setItem("@auth_token", userId + "@" + token);
    await AsyncStorage.setItem("@user_name", firstName + " " + lastName);
    await AsyncStorage.setItem("@email_address", email_address);
  } catch (error) {
    // console.log("error is ------------", error);
    alert(
      "Error storing Login Details: " +
        str(error) +
        ". Please consult the administrator or submit feedback in the About Us section."
    );
  }
};

export const forgetpassUser = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: "LOGIN_USER_LOADING",
      isLoading: true,
    });
    Timer.setTimeout(() => {
      dispatch({
        type: "LOGIN_USER_SUCCESS",
        isLoading: false,
      });
    }, 2000);
    const url = apiPaths.forgotPassword;
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      return (
        Network(url, "POST", payload, headers)
          .then((response) => {
            dispatch({
              type: "LOGIN_USER_SUCCESS",
              isLoading: false,
            });
            return response;
          })
          // console.log("payload", payload);
          // return fetch(url, {
          //   method: "POST",
          //   body: JSON.stringify(payload),
          //   headers: {
          //     "Content-Type": "application/json"
          //   }
          // })
          //   .then(res => res.json())
          //   .then(response => {
          //     console.log("api response", response);
          //     dispatch({
          //       type: "LOGIN_USER_SUCCESS",
          //       isLoading: false
          //     });
          //     return response;
          //   })
          .catch(function (error) {
            // console.log("error response", error);
          })
      );
    } catch (error) {
      dispatch({
        type: "CREAT_USER_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};

export const ckeckSignUPOTP = (payload) => {
  var strinvalid = "Incorrect OTP!";
  var strforget = "OTP authenticated!";
  var strnewuser = "OTP Verified and New User Created!";
  return async (dispatch) => {
    const url = apiPaths.checkOtp;
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      return Network(url, "POST", payload, headers).then((response) => {
        console.log(payload,url,response)
        if (response.status === strnewuser) {
          dispatch({
            type: "CREAT_USER_SUCCESS",
          });

          dispatch({
            type: "AUTH_USER_SUCCESS",
            token: response.auth_token,
            isLoggedIn: true,
          });
          dispatch({
            type: "LOGIN_USER_SUCCESS",
            isLoading: false,
          });
          dispatch({
            type: "LANDING_INTRO",
            showDestination: true,
          });
          // getUserDetails(response.user_id,response.auth_token)
        } else if (response.status === strforget) {
          return response;
        } else {
          dispatch({
            type: "LOGIN_USER_FAIL",
            isLoading: false,
          });
        }

        return response;
      });
    } catch (error) {
      dispatch({
        type: "CREAT_USER_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};
export const getUserDetails = (user_id, authtoken) => {
  var authdata = user_id + "@" + authtoken;
  return async (dispatch) => {
    // console.log(payload);
    const url = "/users/" + user_id + "?";
    const headers = {
      Authorization: authdata,
    };
    dispatch({
      type: "LOADING_STARTED",
      isLoading: true,
    });
    try {
      return Network(url, "GET", null, headers).then(async (response) => {
        try {
          await AsyncStorage.setItem(
            "@auth_token",
            response.user_details.userid + "@" + authtoken
          );
          if (response && response.user_details) {
            await AsyncStorage.setItem(
              "@user_name",
              response.user_details.first_name +
                " " +
                response.user_details.last_name
            );
          }
          dispatch({
            type: "GET_IMMIBUCK_BALANCE",
            payload: response.immibucks.balance,
            rewardHistory: response.immibucks.history,
          });
        } catch (error) {
          // console.log("error is ------------", error);
          // alert(
          //   "Error storing Login Details! Please consult the administrator or submit feedback in the About Us section."
          // );
        }
        dispatch({
          type: "LOADING_STOP",
          isLoading: false,
        });
        dispatch({
          type: "GET_USER_SUCCESS",
          payload: response.user_details,
          immibuck_history: response.immibucks.history,
        });
        // }
        return response;
      });
    } catch (error) {
      dispatch({
        type: "LOADING_STOP",
        isLoading: false,
      });
      dispatch({
        type: "CREAT_USER_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};
export const resetpassword = (payload, user_id, auth_token) => {
  var auth = user_id + "@" + auth_token;
  // console.log(auth);
  return async (dispatch) => {
    const url = apiPaths.resetPassword;
    const headers = {
      "Content-Type": "application/json",
      Authorization: auth,
    };

    try {
      return Network(url, "POST", payload, headers).then((response) => {
        return response;
      });
    } catch (error) {
      return error;
    }
  };
};

export const loginUser = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: "LOGIN_USER_LOADING",
      isLoading: true,
    });
    Timer.setTimeout(() => {
      dispatch({
        type: "LOGIN_USER_SUCCESS",
        isLoading: false,
      });
    }, 2000);
    const url = apiPaths.userLogin;
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      return Network(url, "POST", payload, headers).then((response) => {
        if (response.auth_token != undefined) {
          // console.log("the user details are" + JSON.stringify(response));
          dispatch(setGuestlogin(false));
          storeData(
            response.auth_token,
            response.user_details.userid,
            response.user_details.first_name,
            response.user_details.last_name,
            response.user_details.email_address
          );
          dispatch({
            type: "LOGIN_USER_SUCCESS",
            isLoading: false,
          });
          dispatch({
            type: "AUTH_USER_SUCCESS",
            token: response.auth_token,
            //   isLoggedIn:true
          });
          dispatch({
            type: "GET_USER_SUCCESS",
            payload: response.user_details,
          });
        } else {
          dispatch({
            type: "LOGIN_USER_FAIL",
            isLoading: false,
          });
        }

        return response;
      });
    } catch (error) {
      dispatch({
        type: "LOGIN_USER_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};

export const logoutUser = (payload, user_id, authtoken) => {
  var authdata = user_id + "@" + authtoken;
  return async (dispatch) => {
    dispatch({
      type: "LOGIN_USER_LOADING",
      isLoading: true,
    });
    Timer.setTimeout(() => {
      dispatch({
        type: "LOGIN_USER_SUCCESS",
        isLoading: false,
      });
    }, 2000);
    const url = apiPaths.logout;
    const headers = {
      Authorization: authdata,
      "Content-Type": "application/json",
    };
    try {
      return Network(url, "POST", payload, headers).then((response) => {
        dispatch({
          type: "LOGOUT_USER_SUCCESS",
          isLoading: false,
        });
        return response;
      });

      // return fetch(url, {
      //   method: "POST",
      //   body: JSON.stringify(payload),
      //   headers: {
      //     Authorization: authdata,
      //     "Content-Type": "application/json"
      //   }
      // })
      //   .then(res => res.json())
      //   .then(response => {
      //     // if(response.status == "User Found!")
      //     // {

      //     // dispatch({
      //     //   type: "USER_LOGGED_OUT_SUCCESS"
      //     // });
      //     // dispatch({
      //     //   type: "AUTH_USER_SUCCESS",
      //     //   token: null
      //     //   //   isLoggedIn:true
      //     // });
      //     // dispatch({
      //     //   type: "GET_USER_SUCCESS",
      //     //   payload: null
      //     // });
      //     // dispatch({
      //     //   type: "FINISH_INTRO",
      //     //   finishIntro: true
      //     // });
      //     // dispatch({
      //     //   type: "LOGIN_USER_FAIL",
      //     //   payload: error.responseBody
      //     // });

      //     // }

      //     return response;
      //   })
      //   .catch(function(error) {
      //     console.log("error response", error);
      //   });
    } catch (error) {
      dispatch({
        type: "CREAT_USER_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};
export const deleteUser = (user_id, authtoken) => {
  var authdata = user_id + "@" + authtoken;
  var string = "User deleted!";
  var expirystr = "Auth Token not valid or expired!";
  return async (dispatch) => {
    dispatch({
      type: "LOGIN_USER_LOADING",
      isLoading: true,
    });
    Timer.setTimeout(() => {
      dispatch({
        type: "LOGIN_USER_SUCCESS",
        isLoading: false,
      });
    }, 2000);
    const url = "/users/" + user_id + "?";
    const headers = {
      Authorization: authdata,
    };
    try {
      return Network(url, "DELETE", null, headers).then((response) => {
        if (response.status == string) {
          dispatch({
            type: "USER_LOGGED_OUT_SUCCESS",
          });
          dispatch({
            type: "AUTH_USER_SUCCESS",
            token: null,
            //   isLoggedIn:true
          });
          dispatch({
            type: "GET_USER_SUCCESS",
            payload: null,
          });
          dispatch({
            type: "FINISH_INTRO",
            finishIntro: true,
          });
        } else if (response.status == expirystr) {
          dispatch({
            type: "USER_LOGGED_OUT_SUCCESS",
          });
          dispatch({
            type: "AUTH_USER_SUCCESS",
            token: null,
            //   isLoggedIn:true
          });
          dispatch({
            type: "GET_USER_SUCCESS",
            payload: null,
          });
          dispatch({
            type: "FINISH_INTRO",
            finishIntro: true,
          });
        } else {
          dispatch({
            type: "LOGIN_USER_LOADING",
            isLoading: false,
          });
        }

        return response;
      });
    } catch (error) {
      dispatch({
        type: "CREAT_USER_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};

export const getUserReactions = (user_id, authtoken) => {
  try {
    let url = `/${user_id}/get_user_reactions`;
    var authdata = user_id + "@" + authtoken;
    const headers = {
      Authorization: authdata,
    };
    return async (dispatch) => {
      return Network(
        apiPaths.getUserReactions + url,
        "GET",
        null,
        headers
      ).then((response) => {
        if (response.data?.length > 0) {
          dispatch({
            type: "GET_USER_ARTICLE_REACTIONS",
            payload: response.data,
          });
        }
      });
    };
  } catch (error) {}
};
export const resetLastInx = () => {
  return async (dispatch) => {
    dispatch({
      type: "RESET_LAST_INX",
      last_idx: 0,
    });
  };
};
export const getUserNews = (
  user_id,
  authtoken,
  from,
  classType,
  userCountryOfNationality,
  countryOfBirth
) => {
  var authdata =
    authtoken == "guest@news" ? "guest@news" : user_id + "@" + authtoken;
  var localarray = [];
  var obj = {};
  let filterType =
    classType == "opportunityfilter"
      ? "&opportunityfilter=true"
      : "&threatfilter=true";
  let url = "";
  return async (dispatch) => {
    if (classType) {
      url =
        authtoken == "guest@news"
          ? apiPaths.topNews +
            "search=&goalfilters=1,2,4,3&countryfilters=1,2,3,4,5,6,7,8" +
            filterType +
            "&from_=" +
            from
          : apiPaths.topNews + filterType + "&from_=" + from;
    } else {
      if (from) {
        url =
          authtoken == "guest@news"
            ? apiPaths.topNews +
              "search=&goalfilters=1,2,4,3&countryfilters=1,2,3,4,5,6,7,8" +
              "&from_=" +
              from
            : apiPaths.topNews + "&from_=" + from;
      } else {
        url =
          authtoken == "guest@news"
            ? apiPaths.topNews +
              "search=&goalfilters=1,2,4,3&countryfilters=1,2,3,4,5,6,7,8"
            : apiPaths.topNews;
      }
    }
    const headers = {
      Authorization: authdata,
    };
    dispatch({
      type: "GET_USER_NEWS_LOADING",
    });
    try {
      return Network(url, "GET", null, headers).then((response) => {
        if (response.news != undefined) {
          for (var i = 0; i < response.news.length; i++) {
            obj = {};
            obj.date_published = response.news[i].date_published;
            obj.date_val = response.news[i].date_val;
            obj.image_link = response.news[i].image_link;
            obj.link = response.news[i].link;
            obj.news_id = response.news[i].news_id;
            obj.source = response.news[i].source;
            obj.summary = response.news[i].summary;
            response.news[i].tags = response.news[i].tags.replace(
              /(^\w{1})|[,]+\w{1}/g,
              (letter) => letter.toUpperCase()
            );
            obj.tags = response.news[i].tags.split(",");
            obj.title = response.news[i].title;
            obj.classification = response.news[i].classification;
            obj.affected_countries = response.news[i].affected_countries;
            obj.reactions_count = response.news[i].reactions_count;
            if (classType) {
              if (
                obj.affected_countries &&
                (obj.affected_countries.includes(userCountryOfNationality) ||
                  obj.affected_countries.includes(countryOfBirth) ||
                  obj.affected_countries == "")
              ) {
                localarray.push(obj);
              } else if (!obj.affected_countries) {
                localarray.push(obj);
              }
            } else {
              localarray.push(obj);
            }
          }
          let mainArr = [];
          var newConcatArr = [];
          if (classType == "opportunityfilter") {
            AsyncStorage.getItem("opportunityArray").then((news) => {
              if (news?.length > 0) {
                mainArr = JSON.parse(news);
                if (mainArr.length > 0) {
                  newConcatArr = mainArr.concat(localarray);
                }
                AsyncStorage.setItem(
                  "opportunityArray",
                  JSON.stringify(newConcatArr)
                );
              } else {
                AsyncStorage.setItem(
                  "opportunityArray",
                  JSON.stringify(localarray)
                );
              }
            });
          } else if (classType == "threatfilter") {
            AsyncStorage.getItem("threatArray").then((news) => {
              if (news?.length > 0) {
                mainArr = JSON.parse(news);
                if (mainArr.length > 0) {
                  localarray.concat(mainArr);
                }
                newConcatArr = localarray.concat(mainArr);
                AsyncStorage.setItem(
                  "threatArray",
                  JSON.stringify(newConcatArr)
                );
              } else {
                AsyncStorage.setItem("threatArray", JSON.stringify(localarray));
              }
            });
          } else {
            AsyncStorage.getItem("UserNews").then((news) => {
              if (JSON.parse(news)?.length > 0) {
                mainArr = JSON.parse(news);
                if (mainArr.length > 0) {
                  newConcatArr = mainArr.concat(localarray);
                }
                AsyncStorage.setItem("UserNews", JSON.stringify(newConcatArr));
              } else {
                newConcatArr = localarray;
                AsyncStorage.setItem("UserNews", JSON.stringify(localarray));
              }
            });
          }
          setTimeout(() => {
            dispatch({
              type: "GET_USER_NEWS_SUCCESS",
              last_idx: response.last_idx,
              payload: newConcatArr,
              isLoading: false,
            });
          }, 1000);
        } else {
          dispatch({
            type: "GET_USER_NEWS_FAIL",
            payload: "Call failed with error",
          });
        }
        return response;
      });
    } catch (error) {
      dispatch({
        type: "GET_USER_NEWS_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};

export const updateDestination = () => {
  // console.log("updateDestination");
  return async (dispatch) => {
    try {
      dispatch({
        type: "LANDING_FINISH",
        hideDestination: false,
      });
      // dispatch({
      //     type: "LANDING_DESTINATION_ID",
      //     value: destion_path
      // });
    } catch (e) {
      // console.log(e);
    }
  };
};

export const getUserStories = (user_id, authtoken, from, type) => {
  var authdata =
    authtoken == "guest@news" ? "guest@news" : user_id + "@" + authtoken;
  var localarray = [];
  var obj = {};
  let url = "";
  return async (dispatch) => {
    if (from >= 0) {
      url =
        authtoken == "guest@news"
          ? apiPaths.topStories +
            "search=&goalfilters=1,2,4,3&countryfilters=1,2,3,4,5,6,7,8" +
            "&from_=" +
            from
          : apiPaths.topStories + "&from_=" + from;
    } else {
      url =
        authtoken == "guest@news"
          ? apiPaths.topStories +
            "search=&goalfilters=1,2,4,3&countryfilters=1,2,3,4,5,6,7,8"
          : apiPaths.topStories;
    }
    const headers = {
      Authorization: authdata,
    };
    dispatch({
      type: "GET_USER_STORIES_LOADING",
    });
    try {
      return Network(url, "GET", null, headers).then((response) => {
        if (response.stories != undefined) {
          for (var i = 0; i < response.stories.length; i++) {
            obj = {};
            if (response.stories[i]) {
              obj.date_published = response.stories[i].date_published;
              obj.date_val = response.stories[i].date_val;
              obj.source_image = response.stories[i].source_image;
              obj.link = response.stories[i].link;
              obj.story_id = response.stories[i].story_id;
              obj.source_image = response.stories[i].source_image;
              obj.source = response.stories[i].source;
              obj.summary = response.stories[i].summary;
              response.stories[i].tags = response.stories[i].tags.replace(
                /(^\w{1})|[,]+\w{1}/g,
                (letter) => letter.toUpperCase()
              );
              obj.tags = response.stories[i].tags.split(",");
              obj.title = response.stories[i].title;
              obj.affected_countries = response.stories[i].affected_countries;
              obj.reactions_count = response.stories[i].reactions_count;
              localarray.push(obj);
            }
          }
          let mainArr = [];
          var newConcatArr = [];
          AsyncStorage.getItem("UserStories").then((news) => {
            if (news?.length > 0 && !type) {
              mainArr = JSON.parse(news);
              if (mainArr.length > 0) {
                newConcatArr = mainArr.concat(localarray);
              }
              AsyncStorage.setItem("UserStories", JSON.stringify(newConcatArr));
            } else {
              AsyncStorage.setItem("UserStories", JSON.stringify(localarray));
            }
          });
          setTimeout(() => {
            dispatch({
              type: "GET_USER_STORIES_SUCCESS",
              last_stories_idx: response.last_idx,
              payload: localarray,
              isLoading: false,
            });
          }, 500);
        } else {
          dispatch({
            type: "GET_USER_STORIES_FAIL",
            payload: "Call failed with error",
          });
        }
        return response;
      });
    } catch (error) {
      dispatch({
        type: "GET_USER_STORIES_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};
export const getUserSearchNews = (
  user_id,
  authtoken,
  searchtext,
  country,
  immiInterest
) => {
  var authdata =
    authtoken == "guest@news" ? "guest@news" : user_id + "@" + authtoken;
  var obj = {};
  var localarray = [];
  return async (dispatch) => {
    // console.log(payload);
    const guesturl =
      `/news/gettopnews?` +
      `search=${searchtext}&goalfilters=1,2,4,3&countryfilters=1,2,3,4,5,6,7,8`;
    let url =
      "/news/gettopnews?search=" +
      searchtext +
      "&goalfilters=" +
      immiInterest +
      "&countryfilters=" +
      country;
    const headers = {
      Authorization: authdata,
    };
    url = authtoken == "guest@news" ? guesturl : url;
    dispatch({
      type: "GET_USER_NEWS_LOADING",
    });

    try {
      return Network(url, "GET", null, headers).then((response) => {
        if (response.news != undefined) {
          for (var i = 0; i < response.news.length; i++) {
            obj = {};
            obj.date_published = response.news[i].date_published;
            obj.date_val = response.news[i].date_val;
            obj.image_link = response.news[i].image_link;
            obj.link = response.news[i].link;
            obj.news_id = response.news[i].news_id;
            obj.source = response.news[i].source;
            obj.summary = response.news[i].summary;
            response.news[i].tags = response.news[i].tags.replace(
              /(^\w{1})|[,]+\w{1}/g,
              (letter) => letter.toUpperCase()
            );
            obj.tags = response.news[i].tags.split(",");
            obj.title = response.news[i].title;
            obj.classification = response.news[i].classification;
            obj.reactions_count = response.news[i].reactions_count;
            obj.affected_countries = response.news[i].affected_countries;
            localarray.push(obj);
          }
          AsyncStorage.setItem("UserNews", JSON.stringify(localarray));
          dispatch({
            type: "GET_USER_NEWS_SUCCESS",
            payload: localarray,
            isLoading: false,
          });
        } else {
          dispatch({
            type: "GET_USER_NEWS_FAIL",
            payload: "Call failed with error",
          });
        }

        return response;
      });
    } catch (error) {
      dispatch({
        type: "GET_USER_NEWS_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};
export const getUserSearchStories = (
  user_id,
  authtoken,
  searchtext,
  country,
  immigreation
) => {
  var authdata =
    authtoken == "guest@news" ? "guest@news" : user_id + "@" + authtoken;
  var localarray = [];
  var obj = {};
  return async (dispatch) => {
    // console.log(payload);
    const guesturl =
      `/news/gettopstories?` +
      `search=${searchtext}&goalfilters=1,2,4,3&countryfilters=1,2,3,4,5,6,7,8`;
    let url =
      "/news/gettopstories?search=" +
      searchtext +
      "&goalfilters=" +
      immigreation +
      "&countryfilters=" +
      country;
    const headers = {
      Authorization: authdata,
    };
    url = authtoken == "guest@news" ? guesturl : url;
    dispatch({
      type: "GET_USER_STORIES_LOADING",
    });

    try {
      return Network(url, "GET", null, headers).then((response) => {
        if (response.stories != undefined) {
          for (var i = 0; i < response.stories.length; i++) {
            obj = {};
            obj.date_published = response.stories[i].date_published;
            obj.date_val = response.stories[i].date_val;
            obj.source_image = response.stories[i].source_image;
            obj.link = response.stories[i].link;
            obj.story_id = response.stories[i].story_id;
            obj.source = response.stories[i].source;
            obj.summary = response.stories[i].summary;
            response.stories[i].tags = response.stories[i].tags.replace(
              /(^\w{1})|[,]+\w{1}/g,
              (letter) => letter.toUpperCase()
            );
            obj.tags = response.stories[i].tags.split(",");
            obj.title = response.stories[i].title;
            obj.reactions_count = response.stories[i].reactions_count;
            localarray.push(obj);
          }
          AsyncStorage.setItem("UserStories", JSON.stringify(localarray));
          dispatch({
            type: "GET_USER_STORIES_SUCCESS",
            payload: localarray,
            isLoading: false,
          });
        } else {
          dispatch({
            type: "GET_USER_STORIES_FAIL",
            payload: "Call failed with error",
          });
        }

        return response;
      });
    } catch (error) {
      dispatch({
        type: "GET_USER_STORIES_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};
export const getUserFilterNews = (user_id, authtoken, payload, classType) => {
  var authdata =
    authtoken == "guest@news" ? "guest@news" : user_id + "@" + authtoken;
  var localarray = [];
  let filterType =
    classType == "opportunityfilter"
      ? "&opportunityfilter=true"
      : "&threatfilter=true";
  var obj = {};
  let url = "";
  return async (dispatch) => {
    // console.log(payload);
    if (classType) {
      url =
        "/news/gettopnews?search=" +
        payload.search +
        "&goalfilters=" +
        payload.goal +
        "&countryfilters=" +
        payload.country +
        filterType;
    } else {
      url =
        "/news/gettopnews?search=" +
        payload.search +
        "&goalfilters=" +
        payload.goal +
        "&countryfilters=" +
        payload.country;
    }
    const headers = {
      Authorization: authdata,
    };
    dispatch({
      type: "GET_USER_NEWS_LOADING",
    });

    try {
      return Network(url, "GET", null, headers).then((response) => {
        if (response.news != undefined) {
          for (var i = 0; i < response.news.length; i++) {
            obj = {};
            obj.date_published = response.news[i].date_published;
            obj.date_val = response.news[i].date_val;
            obj.image_link = response.news[i].image_link;
            obj.link = response.news[i].link;
            obj.news_id = response.news[i].news_id;
            obj.source = response.news[i].source;
            obj.summary = response.news[i].summary;
            response.news[i].tags = response.news[i].tags.replace(
              /(^\w{1})|[,]+\w{1}/g,
              (letter) => letter.toUpperCase()
            );
            obj.tags = response.news[i].tags.split(",");
            obj.title = response.news[i].title;
            obj.affected_countries = response.news[i].affected_countries;
            obj.classification = response.news[i].classification;
            obj.reactions_count = response.news[i].reactions_count;
            localarray.push(obj);
          }
          AsyncStorage.setItem("UserNews", JSON.stringify(localarray));
          dispatch({
            type: "GET_USER_NEWS_SUCCESS",
            payload: localarray,
            isLoading: false,
          });
          dispatch({
            type: "USER_SEARCH_TAB",
            value: 1,
          });
        } else {
          dispatch({
            type: "GET_USER_STORIES_FAIL",
            payload: "Call failed with error",
          });
        }
        return response;
      });
    } catch (error) {
      dispatch({
        type: "GET_USER_STORIES_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};
export const getUserFilterStories = (user_id, authtoken, payload) => {
  var authdata =
    authtoken == "guest@news" ? "guest@news" : user_id + "@" + authtoken;
  var localarray = [];
  var obj = {};
  return async (dispatch) => {
    // console.log(payload);
    const url =
      "/news/gettopstories?search=" +
      payload.search +
      "&goalfilters=" +
      payload.goal +
      "&countryfilters=" +
      payload.country;
    const headers = {
      Authorization: authdata,
    };
    dispatch({
      type: "GET_USER_STORIES_LOADING",
    });

    try {
      return Network(url, "GET", null, headers).then((response) => {
        if (response.stories != undefined) {
          for (var i = 0; i < response.stories.length; i++) {
            obj = {};
            obj.date_published = response.stories[i].date_published;
            obj.date_val = response.stories[i].date_val;
            obj.source_image = response.stories[i].source_image;
            obj.link = response.stories[i].link;
            obj.story_id = response.stories[i].story_id;
            obj.source = response.stories[i].source;
            obj.summary = response.stories[i].summary;
            response.stories[i].tags = response.stories[i].tags.replace(
              /(^\w{1})|[,]+\w{1}/g,
              (letter) => letter.toUpperCase()
            );
            obj.tags = response.stories[i].tags.split(",");
            obj.title = response.stories[i].title;
            obj.reactions_count = response.stories[i].reactions_count;
            localarray.push(obj);
          }
          AsyncStorage.setItem("UserStories", JSON.stringify(localarray));
          dispatch({
            type: "GET_USER_STORIES_SUCCESS",
            payload: localarray,
            isLoading: false,
          });
          dispatch({
            type: "USER_SEARCH_TAB",
            value: 2,
          });
        } else {
          dispatch({
            type: "GET_USER_STORIES_FAIL",
            payload: "Call failed with error",
          });
        }
        return response;
      });
    } catch (error) {
      dispatch({
        type: "GET_USER_STORIES_FAIL",
        payload: error.responseBody,
      });
      return error;
    }
  };
};
export const finishIntroScreen = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "FINISH_INTRO",
        finishIntro: true,
      });
    } catch (e) {
      // console.log(e);
    }
  };
};
export const finishShowScreen = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "LANDING_INTRO",
        showDestination: true,
      });
    } catch (e) {
      // console.log(e);
    }
  };
};
export const hideScreen = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "LANDING_FINISH",
        hideDestination: false,
      });
    } catch (e) {
      // console.log(e);
    }
  };
};

export const getUserProfilePic = (pic) => {
  return {
    type: "GET_USER_PROFILE_PIC",
    payload: pic,
  };
};

export const expiryToken = () => {
  return async (dispatch) => {
    dispatch({
      type: "USER_LOGGED_OUT_SUCCESS",
    });
    dispatch({
      type: "GUEST_LOGIN_STATUS",
      guestLogin: true
    });
    dispatch({
      type: "FINISH_INTRO",
      finishIntro: true,
    });
  };
};

export const expipyloading = () => {
  return async (dispatch) => {
    dispatch({
      type: "LOGIN_USER_SUCCESS",
      isLoading: false,
    });
  };
};

export const markUserNewsAsFavorite = (
  article,
  user_id,
  authtoken,
  isDelete
) => {
  var authdata = user_id + "@" + authtoken;

  const parsedContent = {
    ...article,
    article_id: article.news_id ?? article.article_id,
    tags: article.tags.toString(),
  };

  let bodyObj = {};

  bodyObj.article_id = parsedContent.article_id;
  bodyObj.user_id = user_id;

  let methodType = "POST";
  let url = `/users/${user_id}/log_actions/save_news`;

  if (isDelete) {
    methodType = "DELETE";
    url = `/news/${user_id}/saved/${parsedContent.article_id}`;
    bodyObj = null;
  }
  return async (dispatch) => {
    dispatch({
      type: "FAVORITE_ACTION_STARTED",
      favorite_call_failed: false,
    });
    const headers = {
      Authorization: authdata,
    };
    try {
      return Network(url, methodType, bodyObj, headers)
        .then((response) => {
          if (response.status === "Success") {
            let dispatchActionType = isDelete
              ? "REMOVE_NEWS_AS_FAVORITE"
              : "ADD_NEWS_AS_FAVORITE";
            dispatch({
              type: dispatchActionType,
              payload: parsedContent,
            });
          } else {
            Alert.alert("Save failed", JSON.stringify(response), [
              { text: "OK", onPress: () => console.log("OK Pressed") },
            ]);
          }
          return response;
        })
        .catch((e) => {
          console.log(e);
          // dispatch({
          //   type: "FAVORITE_ACTION_STARTED",
          //   favorite_call_failed: true,
          // });
        });
    } catch (error) {
      // dispatch({
      //   type: "FAVORITE_ACTION_STARTED",
      //   favorite_call_failed: true,
      // });
      console.log(error);
      return error;
    }
  };
};

export const addUserReactions = (
  article,
  user_id,
  authtoken,
  reaction,
  type
) => {
  var authdata = user_id + "@" + authtoken;
  var bodyObj = {};
  bodyObj.article_id = type == "news" ? article.news_id : article.story_id;
  bodyObj.user_id = user_id;
  bodyObj.reaction_type = reaction;
  var methodType = "POST";
  var url =
    type == "news"
      ? `/users/${user_id}/log_actions/select_news_reaction`
      : `/users/${user_id}/log_actions/select_story_reaction`;
  return async (dispatch) => {
    dispatch({
      type: "ADD_USER_REACTIONS",
      userReactionCall: false,
    });
    const headers = {
      Authorization: authdata,
    };
    try {
      return Network(url, methodType, bodyObj, headers).then((response) => {
        return response.status;
      });
    } catch (error) {}
  };
};

export const markUserStoriesAsFavorite = (
  article,
  user_id,
  authtoken,
  isDelete,
  reaction
) => {
  var authdata = user_id + "@" + authtoken;
  var bodyObj = {};
  let articleCopy = Object.assign({}, article);
  articleCopy.article_id = article.story_id;
  articleCopy.tags = article.tags.toString();

  bodyObj.article_id = article.story_id;
  bodyObj.user_id = user_id;
  if (reaction) {
    bodyObj.reaction_type = reaction;
  }
  var methodType = "POST";

  var url = reaction
    ? `/users/${user_id}/log_actions/select_news_reaction`
    : `/users/${user_id}/log_actions/save_story`;
  if (isDelete) {
    methodType = "DELETE";
    url = `/news/${user_id}/saved/${articleCopy.article_id}`;
    bodyObj = null;
  }
  return async (dispatch) => {
    dispatch({
      type: "FAVORITE_STORIES_ACTION_STARTED",
      favorite_stories_call_failed: false,
    });
    const headers = {
      Authorization: authdata,
    };
    try {
      return Network(url, methodType, bodyObj, headers)
        .then((response) => {
          if (response.status === "Success") {
            let dispatchActionType = isDelete
              ? "REMOVE_NEWS_AS_FAVORITE"
              : "ADD_NEWS_AS_FAVORITE";
            dispatch({
              type: dispatchActionType,
              payload: articleCopy,
            });
            //removing alerts to test user experience feedback on a more modern approach
            //Alert.alert(successTitle, successMessage, [
            //  { text: "OK", onPress: () => console.log("OK Pressed") }
            //]);
          } else {
            Alert.alert("Save failed", "Story save failed", [
              { text: "OK", onPress: () => console.log("OK Pressed") },
            ]);
          }
          return response;
        })
        .catch((e) => {
          dispatch({
            type: "FAVORITE_STORIES_ACTION_STARTED",
            favorite_stories_call_failed: true,
          });
        });
    } catch (error) {
      // console.log('save failed');
      dispatch({
        type: "FAVORITE_STORIES_ACTION_STARTED",
        favorite_stories_call_failed: true,
      });
      return error;
    }
  };
};

export const getSavedNews = (user_id, authtoken) => {
  var authdata = user_id + "@" + authtoken;
  return async (dispatch) => {
    const url = `/news/${user_id}/saved`;
    const headers = {
      Authorization: authdata,
    };
    try {
      return Network(url, "GET", null, headers).then((response) => {
        if (response.status === "Success" && response.articles.length > 0) {
          dispatch({
            type: "GET_SAVED_NEWS_SUCCESS",
            payload: response,
          });
        } else {
          dispatch({
            type: "GET_SAVED_NEWS_FAIL",
          });
        }
        return response;
      });
    } catch (error) {
      dispatch({
        type: "GET_SAVED_NEWS_FAIL",
      });
      return error;
    }
  };
};

export const getNotificationList = (userId, token) => {
  return async (dispatch) => {
    try {
      const url = "/users/" + userId + "/notifications/personal";
      const headers = {
        Accept: "application/json",
        Authorization: token,
      };
      dispatch({
        type: "NOTIFICATION_LOADER",
        notification_loader: true,
      });
      Network(url, "GET", null, headers).then((res) => {
        if (res.status === "Success") {
          let notifTypes = {
            1: "updates",
            2: "updates",
            3: "policies",
            4: "events",
          };
          let segregatedNotifications = res.notifications.reduce(
            (notifications, notification) => {
              if (notifTypes[notification.notification_type]) {
                notifications[notifTypes[notification.notification_type]].push(
                  notification
                );
              }
              return notifications;
            },
            {
              updates: [],
              events: [],
              policies: [],
            }
          );
          dispatch({
            type: "GET_NOTIFICATION_LIST",
            payload: segregatedNotifications,
          });
        } else if (res.status && res.status == expirystr) {
          AsyncStorage.clear();
          dispatch({
            type: "GET_NOTIFICATION_LIST",
            payload: {},
          });
        } else {
          alert(
            "Loading latest notifications failed. HTTP status: " +
              str(res.status)
          );
        }
        dispatch({
          type: "NOTIFICATION_LOADER",
          notification_loader: false,
        });
      });
    } catch (e) {
      dispatch({
        type: "NOTIFICATION_LOADER",
        notification_loader: false,
      });
      // console.log(e);
    }
  };
};

export const updateTutorialFlow = (status) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "BOTTOM_TUTORIAL_FINISH",
        bottomTutorialFinish: status,
      });
    } catch (e) {
      // console.log(e);
    }
  };
};

export const callBottomTabGuide = (status) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "CALL_BOTTOM_TAB_GUIDE",
        bottomTabGuide: status,
      });
    } catch (e) {
      // console.log(e);
    }
  };
};
