import React, {
  useEffect,
  useState
} from "react";
import {
  View,
  // Dimensions,
  Text,
  Image,
} from "react-native";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { styles } from "./OpportunityReport.styles";
import meta from "../../common/metaData";
import { Network } from "../../service/api";
import Loader from "../Loader";
import { isWeb, mobilBrower } from "../../common/helpers";
import AlertModal from "../AlertModal/AlertModal";
import WebFooter from "../../common/WebFooter";
import { useParams, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MoreInfoModal } from "./components/MoreInfoModal/MoreInfoModal";
import SecretCodeModal from "./components/SecretCodeModal";
import SweetAlert from "../SweetAlert";
import { setUserImmipathSearchResult } from "../../actions/path.actions";
import _ from "lodash";
import PrQAModal from "./components/PrPoints/PrQAModal";
// import PrScore from "../PrScoreModal/components/PrScore";
import SignUpModal from "./components/SignUpModal";
import UserAnsweredInfo from "./components/UserAnsweredInfo/UserAnsweredInfo";
import EligibilityReport from "./components/EligibilityReport/EligibilityReport";
import Images from "../../common/images";
import { TouchableOpacity } from "react-native";
// import JobAnalysis from "../JobAnalysis/JobAnalysis";
import { qtnMappings } from "./questionIdsMapping";
import { Config } from "../../common";
import Clipboard from '@react-native-clipboard/clipboard';
import PrPointsReport from "./components/PrPoints/PrPointsReport";
import { heightPercentageToDP } from "../../common/responsiveScreen";

const OpportunityReport = ({ history, start }) => {
  const [loading, setLoading] = React.useState(0);
  const [showAlert, setShowAlert] = useState(false)
  const [showNonEligibalVisas, setShowNonEligibalVisas] = useState(false)
  const userImmipathSearchResults = useSelector(state => state.pathReducer?.getImmipathDetails?.userImmipathSearchResults)
  const params = useParams() || {}
  const [showSecretCodeModal, setShowSecretCodeModal] = useState(params?.code && !userImmipathSearchResults?.shareable_link ? { isAccess: true, verificationCode: params.code } : false)
  const [copied, setCopied] = useState(false)
  const dispatch = useDispatch()
  const [moreInfoModal, setMoreInfoModal] = React.useState({
    active: false,
    params: null,
  });
  const [showPrPointQA, setShowPrPointQA] = useState(false);
  const [prCountries, setPrCountries] = useState(null);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [userPostData, setUserPostData] = useState({});
  // const [showJobAnalysis, setShowJobAnalysis] = useState(false);
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const Token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );

  useEffect(() => {
    if (params.code && params.code !== userImmipathSearchResults?.shareable_code) {
      setShowSecretCodeModal({ isAccess: true, verificationCode: params.code });
    }
  }, [params.code])

  // const handleRegenarateReport = async () => {
  //   setLoading(true)
  //   let payload = userImmipathSearchResults.payload;
  //   let path = userImmipathSearchResults.path
  //   const headers = {
  //     Accept: "application/json",
  //     Authorization: `guest@immipath`,
  //   };
  //   let response = await Network(path, "POST", payload, headers)
  //   if (response.status !== 'Success') {
  //     SweetAlert.error("Not able to regenerate link at the moment, please try after some time.")
  //     setLoading(false)
  //     return;
  //   }
  //   let resultObj = {
  //     country_data: response.country_data,
  //     excluded_paths: response.excluded_paths,
  //     excluded_reason: response.excluded_reason,
  //     promotions: response.promotions,
  //     results: response.results || response.immipaths
  //   }
  //   let body = {
  //     exploration_id: payload.exploration_id,
  //     metadata: { ...userImmipathSearchResults, ...resultObj, secret: userImmipathSearchResults.secret }
  //   }
  //   let res = await Network('/immipaths/get_shareable_link', "POST", body, headers)
  //   setLoading(false);
  //   if (res.status == "Success") {
  //     let splitKey = "eligibilityReport";
  //     userImmipathSearchResults["shareable_link"] = window.location.href.split(splitKey)[0] + splitKey + `/${res.code}`;
  //     userImmipathSearchResults["last_updated_timestamp"] = new Date();
  //   } else {
  //     SweetAlert.error("Not able to regenerate link at the moment, please try after some time.")
  //   }
  //   dispatch(setUserImmipathSearchResult({ ...userImmipathSearchResults, ...resultObj }));
  // }

  const generateShareableLink = async (secret) => {
    setShowSecretCodeModal(false);
    setLoading(true);
    const headers = {
      Accept: "application/json",
      Authorization: guestLogin ? `guest@immipath` : userDetails?.userid + "@" + Token,
    };
    let payload = {
      exploration_id: userImmipathSearchResults.payload.exploration_id,
      metadata: { ...userImmipathSearchResults, secret: secret }
    }
    let response = await Network('/immipaths/get_shareable_link', "POST", payload, headers)
    setLoading(false);
    if (response.status == "Success") {
      userImmipathSearchResults["shareable_link"] = window.location.href + `/${response.code}`;
      userImmipathSearchResults["shareable_code"] = response.code;
      userImmipathSearchResults["last_updated_timestamp"] = new Date();
      dispatch(setUserImmipathSearchResult({ ...userImmipathSearchResults }));
      return;
    }
    SweetAlert.error("Not able to generate link at the moment, please try after some time.")
  }

  const handleOnVerified = (data) => {
    data["shareable_link"] = window.location.href;
    dispatch(setUserImmipathSearchResult(data));
    setShowSecretCodeModal(false);
  }

  const getPrCalculaterCountries = () => {
    if (userImmipathSearchResults?.payload?.goal_ids[0] != 3) {
      return;
    }
    const url = `/data/get_pr_points_countries`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: guestLogin ? `guest@immipath` : userDetails?.userid + "@" + Token,
    };
    Network(url, "GET", null, headers).then((res) => {
      if (res.status == "Success" && userImmipathSearchResults?.payload?.country_ids) {
        let countries = userImmipathSearchResults.payload.country_ids.filter(id => res.countries.includes(id));
        setPrCountries(countries.length ? countries : null);
      }
    });
  }

  const handleCalcPRPress = () => {
    if (userImmipathSearchResults.prPoints) {
      setShowPrPointQA(true);
      return;
    }
    setLoading(true);
    const url = `/data/get_pr_points_form`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: guestLogin ? `guest@immipath` : userDetails?.userid + "@" + Token,
    };
    let body = {
      countries: prCountries,
    };
    try {
      Network(url, "POST", body, headers)
        .then((res) => {
          setLoading(false);
          if (res.status && res.status === "Success") {
            setShowPrPointQA({
              creditBuilderData: res.form,
              countries: prCountries
            })
          }
        })
    } catch (error) {
      setLoading(false);
    }
  }

  const handleUserCreated = (userDetails) => {
    setShowCreateUser(false);
    history.push("/adviceChannel", userPostData.adviceChannelData);

  }

  const getCountryName = (id) => {
    let country = Config.intrestcountry.filter((s) => s.code == id);
    return country[0].value;
  };

  const getVisasFromPrPoints = () => {
    if (userImmipathSearchResults.userInfo.has_job_offer) {
      let jobOfferReason = "This path requires a job offer to be eligible.";
      [...userImmipathSearchResults.results, ...userImmipathSearchResults.excluded_paths].filter((visa) => {
        if (!userImmipathSearchResults.userInfo.has_job_offer[visa.country_id]) {
          let countries = userImmipathSearchResults.excluded_reason[jobOfferReason] || [];
          if (!countries.includes(visa.immipath_id)) {
            countries.push(visa.immipath_id);
          }
          userImmipathSearchResults.excluded_reason[jobOfferReason] = countries;
        }
      })
    }
    if (userImmipathSearchResults.prPoints) {
      const { points = {}, cutoff_data = {} } = userImmipathSearchResults.prPoints
      let cutOffs = Object.entries(cutoff_data).reduce((infos, info) => {
        infos[getCountryName(info[0]).toUpperCase()] = info[1].cut_off;
        return infos;
      })
      let nonEligibleCountries = {};
      Object.entries(points).map((pr) => {
        if (cutOffs[pr[0].toUpperCase()] <= pr[1]) {
          nonEligibleCountries[pr[0].toUpperCase()] = 1;
        }
      })
      let prPointReson = "PR points less than cut-off";
      let updatedresults = userImmipathSearchResults.results.filter((visa) => {
        if (nonEligibleCountries[getCountryName(visa.country_id).toUpperCase()]) {
          userImmipathSearchResults.excluded_paths.push(visa);
          let countries = userImmipathSearchResults.excluded_reason[prPointReson] || [];
          if (!countries.includes(visa.immipath_id)) {
            countries.push(visa.immipath_id);
          }
          userImmipathSearchResults.excluded_reason[prPointReson] = countries;
          return false;
        }
        return true;
      })
      userImmipathSearchResults.results = updatedresults;
    }
    return userImmipathSearchResults;
  }

  const handleOnScoreCalculate = (body) => {
    const url = `/data/calculate_pr_points`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: guestLogin ? "guest@immipath" : userDetails?.userid + "@" + Token,
    };
    Network(url, "POST", body, headers).then((res) => {
      if (res.status && res.status === "Success") {
        delete res.status;
        userImmipathSearchResults["prPoints"] = res;
        dispatch(setUserImmipathSearchResult({ ...userImmipathSearchResults }));

      }
    });
  }

  useEffect(() => {
    getPrCalculaterCountries()
  }, [])

  const handleOnAskExpert = (userImmipathSearchResults, scoreData) => {
    let userPostData = {
      first_name: userImmipathSearchResults.userInfo.first_name,
      last_name: userImmipathSearchResults.userInfo.last_name,
      email_address: userImmipathSearchResults.userInfo.email_address,
      country_of_birth: userImmipathSearchResults.userInfo.place_of_birth,
      highest_academic_achievement: userImmipathSearchResults.userInfo.highest_academic_achievement,
      get_notif1: true,
      get_notif2: true,
      country_of_residency: userImmipathSearchResults.userInfo.country_of_residency,
      countries_of_interest: userImmipathSearchResults.payload.country_ids,
      immigration_interests: userImmipathSearchResults.payload.goal_ids,
    }
    let adviceChannelData = {
      goal_ids: scoreData.goal_ids,
      country_id: scoreData.country_id,
      country_of_birth: userImmipathSearchResults.userInfo.place_of_birth,
      country_of_residency: userImmipathSearchResults.userInfo.country_of_residency
    }
    if (guestLogin) {
      setUserPostData({ userPostData, adviceChannelData });
      setShowCreateUser(true);
    } else {
      history.push("/adviceChannel", adviceChannelData);
    }
  }
  const closeMoreInfoModal = () => {
    setMoreInfoModal({
      active: false,
      params: null,
    });
  };

  return (
    <View>
      <View style={styles.mainWrapper}>
        {
          isWeb() &&
          <head>
            <meta content={meta.immipathSearch} />
          </head>
        }
        <HeaderComponent
          showNotificationIcon={false}
          showback={false}
          titleContainerStyle={{ paddinBottom: 80 }}
          screenTitle="OPPORTUNITY REPORT"
        />
        {moreInfoModal.active && (
          <MoreInfoModal
            closeMoreInfoModal={closeMoreInfoModal}
            params={moreInfoModal.params}
          />
        )}

        <View style={styles.main}>
          <View style={styles.mainInnerView}>
            {showSecretCodeModal ? <View style={{
              height: heightPercentageToDP(60),
              width: "100%",
              alignItems: "center"
            }} /> : <>
              <View
                style={{
                  flex: 1,
                  width: "100%",
                  alignItems: "center"
                }}
              >
                {
                  loading ? <Loader /> : null
                }
                {userImmipathSearchResults ?
                  (
                    <>
                      <UserAnsweredInfo answeredInfo={{ ...userImmipathSearchResults.userInfo, ...userImmipathSearchResults.userSelections }} />
                      <EligibilityReport
                        reportData={getVisasFromPrPoints()}
                        onAskExpert={handleOnAskExpert}
                      />
                    </>
                  )
                  : <View style={[styles.mainContent]} />}
                <View style={[styles.prPointsContainer, { alignItems: "center" }]}>
                  {prCountries ?
                    userImmipathSearchResults?.prPoints ?
                      <PrPointsReport PRPoints={userImmipathSearchResults.prPoints.points} cutOffData={userImmipathSearchResults.prPoints.cutoff_data} />
                      : <View style={styles.infoContainer}>
                        <View style={styles.infoLeft}>
                          <Image source={Images.whatWeAsked} style={styles.whatWeAskedImg} />
                        </View>
                        <View style={styles.rightInfo}>
                          <Text style={styles.infoText}>{`UNSURE ABOUT\nYOUR ELIGIBILITY?`}</Text>
                          <TouchableOpacity onPress={handleCalcPRPress} style={styles.btn}>
                            <Text style={styles.btnText}>Calculate PR Points</Text>
                          </TouchableOpacity>
                        </View>
                      </View> : null}
                </View>
                {/*<View style={[styles.jobAnalysisContainer, { alignItems: "center" }]}>
                  {showJobAnalysis ?
                    <JobAnalysis onBackPress={() => setShowJobAnalysis(false)} /> :
                    <View style={[styles.infoContainer, { marginTop: 0, flexDirection: mobilBrower() ? "column-reverse" : "row" }]}>
                      <View style={[styles.rightInfo, { marginLeft: mobilBrower() ? 0 : "10%" }]}>
                        <Text style={styles.infoText}>{`FINDING JOBS?\nNO PROBLEM...`}</Text>
                        <TouchableOpacity onPress={() => { setShowJobAnalysis(true) }} style={[styles.btn, { width: "80%" }]}>
                          <Text style={styles.btnText}>View Job Analysis</Text>
                        </TouchableOpacity>
                      </View>
                      <View style={[styles.infoLeft, { width: mobilBrower() ? "90%" : "50%" }]}>
                        <Image source={Images.jobImg} style={styles.jobImg} />
                      </View>
                    </View>}
                  </View>*/}
                {false && <View style={{ width: "100%" }}>
                  {isWeb() && !mobilBrower() ? null :
                    <View style={{ width: "100%", flexDirection: "row" }}>
                      <Text onPress={() => setShowNonEligibalVisas(false)} style={[styles.textT, { opacity: showNonEligibalVisas ? 0.5 : 1 }]}>{'Eligible Visas'}</Text>
                      {userImmipathSearchResults?.excluded_paths?.length > 0 ?
                        <Text onPress={() => setShowNonEligibalVisas(true)} style={[styles.textT, { opacity: showNonEligibalVisas ? 1 : 0.5 }]}>Non Eligible Visas</Text> : null}
                    </View>
                  }
                </View>}
              </View>
              <View style={styles.footer}>
                {userImmipathSearchResults?.shareable_link ?
                  <>
                    <Text style={[{ fontSize: 14, marginLeft: "5%" }]}>{"Last updated on " + new Date(userImmipathSearchResults.last_updated_timestamp).toDateString()}</Text>
                    <View style={styles.flexRow}>
                      {/* <TouchableOpacity onPress={handleRegenarateReport} style={[styles.nextWrapper, { alignSelf: "center", width: "auto", marginLeft: 10, marginTop: 0 }]}>
                  <Text style={styles.nextText}>Regenerate report</Text>
                </TouchableOpacity> */}
                      <Text style={[{ fontSize: 16, fontWeight: "bold" }]}>{userImmipathSearchResults.shareable_link}</Text>
                      <TouchableOpacity onPress={() => {
                        Clipboard.setString(userImmipathSearchResults.shareable_link);
                        setCopied(true);
                        setTimeout(() => {
                          setCopied(false)
                        }, 3000)
                      }} style={[{ alignSelf: "center", width: "auto", marginLeft: 10 }]}>
                        {copied ? <Text style={{ color: "green" }}>Copied!</Text> :
                          <Image source={Images.copy} style={{ width: 25, height: 25 }} />
                        }
                      </TouchableOpacity>
                    </View>
                  </> :
                  <>
                    <TouchableOpacity disabled={loading} onPress={() => setShowSecretCodeModal({ isAccess: false })} style={[styles.nextWrapper, { alignSelf: "center", width: "auto", margin: 10 }]}>
                      <Text style={styles.nextText}>Generate Shareable Link</Text>
                    </TouchableOpacity>
                    {/* {prCountries ?
                    <TouchableOpacity onPress={handleCalcPRPress} style={[styles.nextWrapper, { alignSelf: "center", width: "auto", margin: 10 }]}>
                      <Text style={styles.nextText}>Calculate PR points</Text>
                    </TouchableOpacity>
                    : null} */}
                  </>
                }
              </View>
            </>}
            {showSecretCodeModal ?
              <SecretCodeModal
                onClose={() => setShowSecretCodeModal(false)}
                isAccess={showSecretCodeModal.isAccess}
                verificationCode={showSecretCodeModal.verificationCode}
                onGenerate={generateShareableLink}
                onVerified={handleOnVerified}
              /> : null}
            {showAlert ?
              <AlertModal
                title="Hold on!"
                description="Are you sure you want to go back? All your data will be cleared."
                showConfirm
                confirmText="Yes"
                onConfirmPress={() => {
                  setShowAlert(false);
                  history.goBack();
                }}
                showCancel
                cancelText="Cancel"
                onCancelPress={() => setShowAlert(false)}
              />
              : null}
            {showPrPointQA ?
              <PrQAModal
                creditBuilderData={showPrPointQA.creditBuilderData}
                selectedCountries={prCountries}
                visible={true}
                userResults={userImmipathSearchResults}
                userAnswers={Object.keys(qtnMappings).reduce((obj, id) => {
                  let answers = { ...userImmipathSearchResults.payload?.form_responses, ...userImmipathSearchResults.userInfo }
                  obj[qtnMappings[id]] = answers[id];
                  return obj;
                }, {})}
                onClose={() => {
                  setShowPrPointQA(false)
                }}
                onScoreCalculate={handleOnScoreCalculate}
              />
              : null}
            {showCreateUser ?
              <SignUpModal
                visible={true}
                title={"Sign up"}
                description={"You have to sign up to Ask our Experts."}
                onCancelPress={() => setShowCreateUser(false)}
                userPostData={userPostData.userPostData}
                onPinValidated={handleUserCreated}
              />
              : null}
          </View>
        </View>
      </View>
      {isWeb() && !mobilBrower() ? <WebFooter style={{ zIndex: -1, paddingTop: 30 }} /> : null}
    </View>
  );
};

export default isWeb() ? withRouter(OpportunityReport) : OpportunityReport;
