import { combineReducers } from "redux";

const getUser = (state = {}, action) => {
  switch (action.type) {
    case "GET_USER_LOADING":
      return {
        isLoading: true,
        isError: false,
        isSuccess: false,
        userDetails: null,
        errors: null,
      };

    case "GET_USER_SUCCESS":
      return {
        isLoading: false,
        isError: false,
        isSuccess: true,
        userDetails: action.payload,
        immibuck_history: action.immibuck_history,
        errors: null,
      };
    case "LOGOUT_USER_SUCCESS":
      return {
        isLoading: false,
        isError: false,
        isSuccess: true,
        userDetails: null,
        immibuck_history: null,
        errors: null,
      };
    case "GET_USER_FAIL":
      return {
        isLoading: false,
        isError: true,
        isSuccess: false,
        userDetails: null,
        errors: action.payload,
      };

    default:
      return state;
  }
};

const getUserReactions = (state = {}, action) => {
  switch (action.type) {
    case "GET_USER_ARTICLE_REACTIONS":
      return {
        isLoading: false,
        isError: false,
        isSuccess: true,
        userReactions: action.payload,
        errors: null,
      };
    default:
      return state;
  }
};

const getUserNews = (state = {}, action) => {
  switch (action.type) {
    case "GET_USER_NEWS_LOADING":
      return {
        isLoading: true,
        isError: false,
        isSuccess: false,
        errors: null,
      };

    case "GET_USER_NEWS_SUCCESS":
      return {
        isLoading: false,
        isError: false,
        isSuccess: true,
        last_idx: action.last_idx,
        userNews: action.payload,
        errors: null,
      };

    case "RESET_LAST_INX":
      return {
        last_idx: action.last_idx,
      };

    case "GET_USER_NEWS_FAIL":
      return {
        isLoading: false,
        isError: true,
        isSuccess: false,
        userNews: null,
        errors: action.payload,
      };

    default:
      return state;
  }
};

const getUserStories = (state = {}, action) => {
  switch (action.type) {
    case "GET_USER_STORIES_LOADING":
      return {
        isLoading: true,
        isError: false,
        isSuccess: false,
        errors: null,
      };

    case "GET_USER_STORIES_SUCCESS":
      return {
        isLoading: false,
        isError: false,
        last_stories_idx: action.last_stories_idx,
        isSuccess: true,
        userStories: action.payload,
        errors: null,
      };

    case "GET_USER_STORIES_FAIL":
      return {
        isLoading: false,
        isError: true,
        isSuccess: false,
        userNews: null,
        errors: action.payload,
      };

    default:
      return state;
  }
};

const getSavedNews = (state = {}, action) => {
  switch (action.type) {
    case "GET_SAVED_NEWS_SUCCESS":
      let articleIds = action.payload.articles.map(
        (article) => article.article_id
      );
      return {
        isError: false,
        isSuccess: true,
        savedNews: action.payload.articles,
        articleIds: articleIds,
      };

    case "ADD_NEWS_AS_FAVORITE":
      return {
        ...state,
        savedNews: state.savedNews
          ? [...state.savedNews, action.payload]
          : [action.payload],
        articleIds: state.articleIds
          ? [...state.articleIds, action.payload.article_id]
          : [action.payload.article_id],
        saveArticle_isLoading: false,
      };

    case "REMOVE_NEWS_AS_FAVORITE":
      return {
        ...state,
        savedNews: state.savedNews.filter(
          (article) => article.article_id !== action.payload.article_id
        ),
        articleIds: state.articleIds.filter(
          (articleID) => articleID !== action.payload.article_id
        ),
        saveArticle_isLoading: false,
      };
    case "FAVORITE_ACTION_STARTED":
      return {
        ...state,
        favorite_call_failed: action.favorite_call_failed,
      };
    case "FAVORITE_STORIES_ACTION_STARTED":
      return {
        ...state,
        favorite_stories_call_failed: action.favorite_stories_call_failed,
      };

    case "GET_SAVED_NEWS_FAIL":
      return {
        isError: true,
        isSuccess: false,
        savedNews: [],
        articleIds: [],
      };

    default:
      return state;
  }
};

export default combineReducers({
  getUser,
  getUserNews,
  getUserStories,
  getSavedNews,
  getUserReactions,
});
