import React, { useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from "react-native";
import { Images } from "../../common";
import { useSelector } from "react-redux";
import { Network } from "../../service/api";
import { notificationModalStyles as styles } from "./notificationModal.styles";
import BullitinModal from "../BullitinModal/BullitinModal";
import Notifications from "./components/Notifications";
import NotificationContent from "./components/NotificationContent";
import Badge from "../../common/Badge";

const POLICY_NOTIFS = "policies";
const EVENT_NOTIFS = "events";
const APP_UPDATE_NOTIFS = "updates";

export default function NotificationModal({ openNotificationModal, onClose }) {
  const [showNotificationDetail, setshowNotificationDetail] = useState(false);
  const [notificationsDetails, setnotificationsDetails] = useState(null);
  const [loading, setloading] = useState(false);
  const [type, setType] = useState("");
  const notificationList = useSelector(
    (state) => state.authReducer?.getNotifications?.notificationList
  );
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const Token = useSelector((state) => state.authReducer.authData.token);

  const NotifTypes = {
    policies: {
      leftIcon: Images.privacy,
      title: "Policy Updates",
      type: POLICY_NOTIFS,
    },
    events: {
      leftIcon: Images.events,
      title: "Events",
      type: EVENT_NOTIFS,
    },
    updates: {
      leftIcon: Images.update,
      title: "App Updates",
      type: APP_UPDATE_NOTIFS,
    },
  };

  const getNotificationsDetail = async (notification_id) => {
    setloading(true);
    const url = `/notifications/${notification_id}/details`;
    const headers = {
      Accept: "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };
    Network(url, "GET", null, headers)
      .then((res) => {
        setloading(false);
        if (res.status === "Success") {
          setnotificationsDetails(res.notifications);
          setshowNotificationDetail(true);
        } else if (res.status && res.status == expirystr) {
          AsyncStorage.clear();
          props.handleNotificationModalToggle();
          helpers.showSingleAlert(
            "Session expired Please login again",
            NavigationService.navigate("Login")
          );
        } else {
          alert("Unable to fetch notification detail");
        }
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const handleClose = (func) => {
    if (type) {
      if (showNotificationDetail) {
        setshowNotificationDetail(false);
      } else {
        setType("");
      }
    } else {
      func();
    }
  };
  return (
    <BullitinModal
      logo="pin"
      heading=""
      visible={openNotificationModal}
      onClose={onClose}
      handleClose={handleClose}
      closeIcon={loading ? "" : type ? "leftArrow" : "closeBlack"}
    >
      <View style={styles.notifItemContainer}>
        {type ? (
          loading ? (
            <View style={styles.loader}>
              <ActivityIndicator color="#000" size="large" />
            </View>
          ) : (
            <View style={styles.contentContainer}>
              {showNotificationDetail ? (
                <NotificationContent
                  heading={NotifTypes[type].title}
                  type={type}
                  {...notificationsDetails}
                />
              ) : (
                <Notifications
                  notifications={notificationList[type]}
                  onNotificationPress={getNotificationsDetail}
                  title={NotifTypes[type].title}
                />
              )}
            </View>
          )
        ) : (
          <>
            <View style={styles.header}>
              <Text style={styles.notificationHeaderText}>Bulletin </Text>
            </View>
            <View style={{ padding: 20 }}>
              {Object.values(NotifTypes).map(
                ({ title, leftIcon, type }, index) => (
                  <TouchableOpacity
                    onPress={() => {
                      setType(type);
                    }}
                    key={index + "n"}
                    style={styles.notifItem}
                  >
                    <View>
                      <Image source={leftIcon} style={styles.leftIcon} />
                      <Badge text={notificationList?.[type]?.length} />
                    </View>
                    <Text style={styles.notifItemText}>{title}</Text>
                    <Image
                      source={Images.notificationIconModal_forward}
                      style={styles.rightIcon}
                    />
                  </TouchableOpacity>
                )
              )}
            </View>
          </>
        )}
      </View>
    </BullitinModal>
  );
}
