import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, BackHandler } from "react-native";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import CountrySelect from "./components/CountrySelect";
import config from "../../common/config";
import VisasList from "./components/VisasList";
import SweetAlert from "../../components/SweetAlert";
import { isWeb, mobilBrower } from "../../common/helpers";
import { styles } from "./quickSearch.styles"
import WebFooter from "../../common/WebFooter";
import { withRouter } from "react-router-dom";
import { RegionMap } from "./components/RegionMap";
import meta from "../../common/metaData";

const QuickSearch = (props) => {
  const [selectedRegions, setSelectedRegions] = useState({});
  const [selectedCountries, setSelectedCountries] = useState({});
  const [visas, setVisas] = useState([]);
  const [showVisasList, setShowVisasList] = useState(false);
  const immipaths = isWeb() ? props.history?.location?.state?.immipaths : props.navigation.state.params.immipaths;


  const handleRegionSelect = (region) => {
    let updatedRegions = { ...selectedRegions }
    let updatedCountries = { ...selectedCountries }
    if (updatedRegions[region]) {
      Object.keys(updatedRegions[region]).map(country => {
        delete updatedCountries[country]
      })
      delete updatedRegions[region]
    } else {
      let countries = config.intrestcountry.reduce((countries, country) => {
        if (country.region === region) {
          countries[country.code] = 1
          updatedCountries[country.code] = country
        }
        return countries;
      }, {})
      if (!Object.keys(countries).length) {
        return;
      }
      updatedRegions[region] = countries
    }
    setSelectedRegions(updatedRegions)
    setSelectedCountries(updatedCountries)
  }

  const handleBackPress = () => {
    if (showVisasList) {
      return true;
    }
    return false
  }

  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", handleBackPress);
    return () => {
      BackHandler.removeEventListener("hardwareBackPress", handleBackPress);
    }
  })

  const handleOnNextPress = () => {
    if (Object.keys(selectedCountries).length) {
      let visas = immipaths.filter(path => selectedCountries[path.country_id]);
      setVisas(visas);
      setShowVisasList(true)
    } else {
      SweetAlert.error("Select atleast one country.")
    }
  }

  const handleCountrySelect = (country) => {
    let updatedRegions = { ...selectedRegions }
    let updatedCountries = { ...selectedCountries }
    if (updatedCountries[country.code]) {
      delete updatedCountries[country.code]
      delete updatedRegions[country.region][country.code]
      if (!Object.keys(updatedRegions[country.region]).length) {
        delete updatedRegions[country.region]
      }
    } else {
      updatedCountries[country.code] = country;
      if (!updatedRegions[country.region]) {
        updatedRegions[country.region] = {}
      }
      updatedRegions[country.region][country.code] = 1
    }
    setSelectedRegions(updatedRegions)
    setSelectedCountries(updatedCountries)
  }

  return (
    <View style={{ backgroundColor: "white", flex: 1 }}>
      {
        isWeb() &&
        <head>
          <meta content={meta.QuickSearch} />
        </head>
      }
      <HeaderComponent
        showNotificationIcon={false}
        showback={true}
        screenTitle="Quick Visa Search"
      />
      <View style={styles.mainView}>
        {!showVisasList ?
          <>
            <View style={styles.innerView}>
              <RegionMap selectedRegions={Object.keys(selectedRegions)} onRegionSelect={handleRegionSelect} />
              <View style={styles.orBorderContainer}>
                <View style={styles.orBorder} />
                <Text style={styles.orText}>OR</Text>
                <View style={styles.orBorder} />
              </View>
              <CountrySelect selectedCountries={selectedCountries} onCountrySelect={handleCountrySelect} />
            </View>
            <TouchableOpacity onPress={handleOnNextPress} style={styles.nextBtn}>
              <Text style={styles.nextText}>Next</Text>
            </TouchableOpacity>
          </>
          : <VisasList onBackPress={() => setShowVisasList(false)} visas={visas} />}
      </View>
      {isWeb() && !mobilBrower() ? <WebFooter /> : null}
    </View>
  );
};
export default isWeb() ? withRouter(QuickSearch) : QuickSearch;
