import { family } from "../../../../common";
import { heightPercentageToDP } from "../../../../common/responsiveScreen";
import { hasNotch } from "react-native-device-info";
import { Dimensions,StyleSheet } from "react-native";
import { responsiveText } from "../../../../common/normalize";
import { mobilBrower } from "../../../../common/helpers";
const { width } = Dimensions.get("window");

export const styles = StyleSheet.create({
    innerView: {
      shadowColor: "#000",
      elevation: 5,
      width: "90%",
      alignSelf: "center",
      marginTop: 100,
      alignItems: "center",
      borderRadius: 5,
      backgroundColor: "#EFEFEF",
    },
    contentView: {
      flexDirection: "column",
      marginLeft: mobilBrower()? 10:15,
      flex:1,
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    redeemIcon: {
      height: 18,
      marginRight: 10,
      width: 18,
    },
    storeButton: {
      alignSelf: "center",
      width: heightPercentageToDP("12%"),
      height: heightPercentageToDP("4%"),
      backgroundColor: "#F1AB32",
      marginTop: heightPercentageToDP("3%"),
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      aspectRatio: 1,
      width: mobilBrower()?110 :140,
      height: mobilBrower()?110 :140,
      borderRadius: 10,
    },
    priceText: {
      color: "#1E2022",
      fontFamily: family.effra,
      textAlign: "center",
      fontSize: mobilBrower()?responsiveText(11):responsiveText(14),
    },
    priceBlock: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "60%",
      marginTop: 20,
      alignItems: "center",
    },
    colorView: {
      height: 14,
      width: 14,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 7,
    },
    description: {
      color: "#77838F",
      fontSize: responsiveText(16),
      width: hasNotch() ? width / 2.1 : width / 2.5,
      marginTop: 5,
    },
    noDataView: {
      alignSelf: "center",
      paddingTop: 80,
      alignItems: "center",
      height: heightPercentageToDP(38),
    },
    checkOutButton: {
      width: "90%",
      height: 45,
      alignSelf: "center",
      borderRadius: 15,
      marginTop: 10,
      marginBottom: 40,
      backgroundColor: "#F1AB32",
      alignItems: "center",
      justifyContent: "center",
    },
    whiteArrow: {
      width: 20,
      resizeMode: "contain",
      position: "absolute",
      right: 15,
    },
    proceedText: {
      fontSize: 14,
      color: "white",
      fontFamily: family.effra,
    },
    colorOuter: {
      height: 20,
      borderRadius: 13,
      alignItems: "center",
      justifyContent: "center",
      width: 20,
      marginLeft: 5,
    },
    title: {
      color: "#1E2022",
      fontSize:mobilBrower()?responsiveText(11) : responsiveText(17),
      marginTop: 10,
      fontFamily: family.effra,
    },
    colorsView:{
      height: mobilBrower()?16:24,
      width: mobilBrower()?16:24,
      borderRadius: mobilBrower()?8:12,
      marginLeft: 5,
    },
    mainView: {
      width: "97%",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      padding: 5,
      marginTop: 30,
      alignSelf: "center",
      // marginLeft: "-5%",
    },
    cardView: {
      shadowColor: "#000",
      padding: 4,
      flexDirection: "row",
      alignItems: "center",
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.5,
      shadowRadius: 5,
      elevation: 12,
      flex:1,
      backgroundColor: "#FFF",
      borderRadius: 10,
    },
    successAnim: {
      height: "100%",
      width: "100%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    noItems: {
      fontSize: 15,
      alignSelf: "center",
      fontFamily: family.effra,
    },
    animationSize: {
      height: "30%",
      width: "80%",
    },
    proImg: {
      height: 80,
      width: 80,
    },
    proceed: {
      height: 30,
      alignSelf: "center",
      borderWidth: 1,
      width: 80,
      marginTop: 15,
      borderRadius: 10,
      marginBottom: 10,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#030135",
    },
    quantityView: {
      alignItems: "center",
      flexDirection: "row",
      marginTop: 5,
    },
    subtractItem: {
      height: 20,
      width: 20,
      borderRadius: 5,
      backgroundColor: "#F1AB32",
      alignItems: "center",
      justifyContent: "center",
    },
    totalView: {
      flexDirection: "row",
      alignItems: "center",
      width: "90%",
      alignSelf: "center",
      justifyContent: "space-between",
    },
    bottomView: {
      width: "100%",
      height: heightPercentageToDP(20),
      borderTopRightRadius: 20,
      borderTopLeftRadius: 20,
      backgroundColor: "#0F1143",
    },
    totalText: {
      color: "white",
      fontFamily: family.effra,
      fontSize: 17,
    },
    itemsSelectedCount: {
      color: "white",
      fontSize: 14,
    },
    item: {
      flexDirection: "row",
      padding: 3,
      alignItems: "center",
      borderBottomWidth: 0.5,
      borderColor: "#BEBEBE",
      justifyContent: "space-between",
      width: "95%",
      alignSelf: "center",
    },
    sizeView: {
      height: 16,
      width: 16,
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      borderWidth: 1,
      marginLeft: 10,
    },
    noteText:{
        fontSize: heightPercentageToDP("2%"),
        fontFamily: family.effra,
        color: "#000",
      },
      btnText:{
        color: "white",
        fontSize: heightPercentageToDP("1.6%"),
        fontFamily: family.effra,
      }
  });
