import { Platform } from 'react-native'

const fontFamily = {
  bold: Platform.OS === 'ios' ? 'SourceSansPro-Bold' : 'SourceSansPro-Bold',
  roboto: 'Roboto-Regular',
  robotoBold: 'Roboto-Bold',
  robotoBlack: 'Roboto-Black',
  bebas: 'BebasNeue-Regular',
  regular:
    Platform.OS === 'ios' ? 'SourceSansPro-Regular' : 'SourceSansPro-Regular',
  semibold:
    Platform.OS === 'ios' ? 'SourceSansPro-SemiBold' : 'SourceSansPro-SemiBold',
  titilliumBold: Platform.OS === 'ios' ? 'TitilliumWeb-Bold' : 'TitilliumWeb-Bold',
  bebasRegular: Platform.OS === 'web' ? 'Bebas+Neue' :'BebasNeue_Regular',
  robotoRegular: 'Roboto-Regular',
  robotoBold: 'Roboto-Bold',
  effra: Platform.OS === 'ios' ? 'SourceSansPro-SemiBold' : Platform.OS === 'web' ? 'Bebas+Neue' : 'SourceSansPro-SemiBold'
}

export default fontFamily
