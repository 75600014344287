import { StyleSheet } from "react-native";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";
import { heightPercentageToDP } from "../../common/responsiveScreen";

export const styles = StyleSheet.create({
  container: mobilBrower() ? {
    height: heightPercentageToDP(100),
    width: "100%"
  } : {
    minHeight: heightPercentageToDP(95),
    width: "100%"
  },
  dropDownStyle: {
    bottom: 0
  },
  bottomContainer: {
    width: mobilBrower() ? "100%" : "70%",
    alignSelf: "center"
  },
  mainView: {
    alignItems: "center",
    backgroundColor: "white",
    flex: 1,
    width: mobilBrower() ? "100%" : "70%",
    alignSelf: "center"
  },
  TitleText: {
    fontSize: heightPercentageToDP("5%"),
    alignSelf: "center",
    zIndex: -9999,
  },
  subTitle: {
    marginTop: heightPercentageToDP("1%"),
    fontSize: heightPercentageToDP("2.2%"),
    alignSelf: "center",
    marginBottom: heightPercentageToDP("2%"),
  },
  nextButton: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F1AB32",
    padding: heightPercentageToDP("2%"),
    bottom: heightPercentageToDP("3%"),
    alignSelf: "center",
    position: "absolute",
    borderRadius: 10,
    width: "90%",
  },
  calculateButton: {
    backgroundColor: "#F1AB32",
    width: "90%",
    marginLeft: "5%",
    padding: heightPercentageToDP("2%"),
    bottom: heightPercentageToDP("3%"),
    marginTop: heightPercentageToDP("5%"),
    borderRadius: 10,
  },
  nextButtonText: {
    color: "white",
    fontSize: 16,
    width: "100%",
    textAlign: "center",
  },
  disabled: {
    backgroundColor: "#A0A0A0",
  },
  questionView: {
    width: "95%",
    marginBottom: 25,
    alignSelf: "center",
  },
  questionText: {
    paddingLeft: 15,
    paddingRight: 5,
    fontSize: responsiveText(18),
    zIndex: -1999999,
  },
  separator: {
    borderColor: "rgba(1,1,1, 0.2)",
    borderBottomWidth: 0.25,
  },
  optionsView: {
    padding: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bottomButtonView: {
    // height: 125,
    marginBottom: 25,
    width: "95%",
    flexDirection: mobilBrower() ? "column" : "row",
    marginLeft: "2.5%",
  },
  snapShotButtonText: {
    color: "white",
    fontSize: heightPercentageToDP("1.7%"),
  },
  snapShotButton: {
    flex: 1,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    padding: 15,
    backgroundColor: "#F1AB32",
    marginHorizontal: 10,
    marginTop: 10,
  },
  errorView: {
    borderWidth: 0.3,
    borderColor: "red",
  },
  downArrow: {
    height: 18,
    width: 18,
    marginRight: 10,
    resizeMode: "contain",
  },
  selectedAnswerView: {
    width: "95%",
    marginLeft: "2.5%",
    // height: 45,
    marginTop: heightPercentageToDP("2%"),
    paddingLeft: 15,
    flexDirection: "row",
    zIndex: -1999999,
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 8,
    backgroundColor: "#F5F6F8",
    borderRadius: 10,
  },

  optionDropDown: {
    width: "95%",
    marginTop: 5,
    borderRadius: 10,
    padding: 8,
    backgroundColor: "#E7E7E7",
    marginLeft: "2.5%",
    overflow: "hidden",
  },
  currencyDropDown: {
    width: "25%",
    marginTop: 0,
    height: 35,
    marginRight: 18,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99999,
    backgroundColor: "#DEDEDE",
    elevation: 5,
  },
  calculationSingleView: {
    backgroundColor: "rgb(250,250,250)",
    borderColor: "grey",
    borderWidth: 1,
    height: 150,
    margin: 8,
    borderRadius: 25,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  costText: {
    fontSize: heightPercentageToDP("3.8%"),
    letterSpacing: -1,
    marginBottom: 10,
  },
  modalTitle: {
    marginBottom: 30,
    fontSize: heightPercentageToDP("2.6%"),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  categoryText: {
    paddingHorizontal: heightPercentageToDP("1.5%"),
    textAlign: "center",
    fontSize: responsiveText(20),
  },
  back: {
    height: 25,
    width: 25,
    resizeMode: "contain",
  },
  headerView: {
    flexDirection: "row",
    width: "100%",
    padding: heightPercentageToDP("2%"),
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalmainView: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  modalinnerView: {
    backgroundColor: "#F1F1F1",
    borderRadius: 15,
    width: mobilBrower() ? "90%" : "50%",
    alignItems: "center",
    padding: 20,
    shadowColor: "white",
    shadowOffset: { width: 0, height: 0.5 },
    shadowOpacity: 0.4,
    shadowRadius: 5,
    elevation: 12,
    justifyContent: "center",
    // height: 500,
  },
  detailsView: {
    flexDirection: "row",
    width: "100%",
    padding: 5,
    paddingLeft: 0,
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailsLabel: {
    flex: 0.6,
    fontSize: responsiveText(18)
  },
  detailsText: {
    flex: 0.3,
    fontSize: responsiveText(18),
    textAlign: "right"
  },
  closeIcon: {
    height: mobilBrower() ? heightPercentageToDP("1.8%") : 15,
    width: mobilBrower() ? heightPercentageToDP("1.8%") : 15,
  },
  closeIconButton: {
    alignSelf: "flex-end",
    marginTop: 5,
  },

  indicator: {
    width: 50,
    height: 3.5,
    margin: 3,
    borderRadius: 15,
  },
  indicatorWrapper: {
    position: "absolute",
    bottom: 5,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});
