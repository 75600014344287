import { StyleSheet, Dimensions } from "react-native";
import colors from "../../../../common/colors";
import fontFamily from "../../../../common/fontFamily";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";
const { width } = Dimensions.get("window");

export const newsItemStyles = StyleSheet.create({
  newItem: {
    flexDirection: !mobilBrower() ? "row" : "column",
    alignItems: "center",
    justifyContent: "flex-start",
    alignSelf: "center",
    marginTop: 30,
    backgroundColor: "#F6F6F6",
    width: !mobilBrower() ? "60%" : width / 1.1,
  },
  newImage: {
    height: 310,
    marginTop: 1,
  },
  tagView: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  newImgStyle: {
    height: "100%",
    width: mobilBrower() ? width / 1.1 : 350,
  },
  tagText: {
    color: "rgba(0, 0, 0, 0.61)",
    padding: 5,
    fontSize:responsiveText(12),
  },
  newContent: {
    marginTop: 5,
    paddingBottom: 5,
    marginBottom: 0,
    paddingRight: 15,
    height: "90%",
    marginTop: 0,
    justifyContent: "space-between",
    flex: 1,
    padding: 20,
  },
  bottomView: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: !mobilBrower() ? 25 : 12,
  },
  readMoreButton: {
    backgroundColor: "#0F1143",
    alignItems: "center",
    justifyContent: "center",
  },
  readMoreText: {
    padding: 9,
    paddingHorizontal: 16,
    fontWeight:"bold",
    fontSize:responsiveText(16),
    color: "white",
    fontFamily: fontFamily.effra
  },
  sourceText: {
    paddingTop: 7,
    paddingBottom: 7,
    color: colors.SUBHEADER_GREY,
    fontSize:responsiveText(14),
    marginTop: mobilBrower()? 0:10,
    fontWeight: "700",
  },
  publishedDate: {
    color: colors.SUBHEADER_GREY,
    fontSize:responsiveText(14),
    marginTop: mobilBrower()? 0:10,
    fontWeight: "400",
  },
  title: {
    color: "black",
    fontFamily: fontFamily.effra,
    fontWeight: "700",
    textAlign: mobilBrower()?"center": "left",
    fontSize:responsiveText(18),
  },
  details: {
    color: "black",
    fontSize: responsiveText(16),
    marginTop: 15,
    textAlign: "center",
    width: "90%",
    marginLeft: "5%",
    marginBottom: 15,
  },
  newsHighTitle: {
    color: "black",
    alignSelf: "center",
    marginTop: 35,
    fontSize: 20,
    fontWeight: "700",
    fontFamily: "Effra",
  },
});
