import { StyleSheet } from "react-native";
import { family } from "../../common";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";
import { heightPercentageToDP } from "../../common/responsiveScreen";


export const styles = StyleSheet.create({
    mainView: {
        alignSelf: "center",
        flex: 1,
        width: mobilBrower() ? "90%" : "70%",
        paddingBottom: mobilBrower() ? 0 : 100
    },
    innerView: {
        flexDirection: mobilBrower() ? "column" : "row",
        paddingVertical: mobilBrower() ? 0 : 20,
        justifyContent: "space-between"
    },
    listContainer:{
        flexDirection:mobilBrower() ?"column":"row",
        flexWrap:mobilBrower() ?"nowrap":"wrap"
    },
    worldContainer: {
        alignItems: "center",
        marginVertical: 15,
        width: mobilBrower() ? "100%" : "45%"
    },
    title: {
        fontSize: responsiveText(20),
        fontWeight: "bold",
        marginBottom: 20,
    },
    container: {
        alignItems: "center",
        width: "100%",
        flex: 1
    },
    searchContainer: {
        width: mobilBrower()? "100%":"60%",
        flexDirection: "row",
        borderWidth: 1,
        alignItems: "center",
        borderRadius: 10,
        elevation: 5,
        marginVertical: 15
    },
    searchInput: {
        flex: 1,
        paddingHorizontal: 10,
        paddingVertical: 15
    },
    tagView: {
        borderRadius: 10,
        borderWidth: 1,
        marginLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: '#000',
        paddingHorizontal: 10
    },
    tagText: {
        paddingVertical: 5,
        color: 'rgba(0, 0, 0, 0.61)',
        fontSize: responsiveText(11),
    },
    visaItem: {
        flexDirection: "row",
        height: mobilBrower() ?160:180,
        marginVertical: mobilBrower() ? 10 : 20,
        backgroundColor: '#F6F6F6',
        borderRadius:10,
        overflow:"hidden",
        width:mobilBrower() ?"100%":"40%",
        marginHorizontal: mobilBrower() ? 0 : 20,
    },
    visaTitle: {
        fontSize: responsiveText(16),
        fontWeight: "bold",
        marginVertical: mobilBrower() ? 0 : 10,
    },
    takeViewButton: {
        alignItems: 'center',
        backgroundColor: '#F1AB32',
        justifyContent: 'center',
        padding: 5,
        alignSelf: "flex-end",
        paddingHorizontal: 20
    },
    takeViewText: {
        fontSize: responsiveText(12),
        color: 'white',
        fontWeight: "700",
    },
    detailsCotnainer: {
        paddingVertical: 10
    },
    description: {
        fontSize: responsiveText(16),
        fontFamily: family.regular,
    },
    details: {
        fontSize: responsiveText(14),
        marginTop: 10,
        fontFamily: family.regular,
    },
    btnContainer:{
        width: mobilBrower()? "100%":"60%",
        flexDirection:"row",
        paddingVertical:15,
    },
    imgContainer:{ 
        alignSelf: "center", 
        marginBottom: 10,
        height:100,
        width:200 
    },
    shortButton: {
        flex:1,
        alignItems: "center",
        backgroundColor: "green",
        borderRadius: 15,
        padding: 10,
        alignSelf: "center",
        marginHorizontal:10,
        justifyContent: "center",
    },
    shortText: {
        fontSize: responsiveText(14),
        color: "white",
        fontFamily: family.semibold,
        textAlign: "center"
    },
    orBorderContainer: {
        flexDirection: mobilBrower() ? "row" : "column",
        marginHorizontal: mobilBrower() ? 0 : 10,
        alignItems: "center"
    },
    orBorder: {
        borderWidth: 1,
        flex: 1,
        borderColor: "#BDBDBD"
    },
    orText: {
        marginHorizontal: mobilBrower() ? 5 : 0,
        marginVertical: mobilBrower() ? 0 : 5,
        fontWeight: "bold"
    },
    countryView: {
        width: mobilBrower() ? "100%" : "45%",
        alignItems: "center",
        justifyContent: "center",
        marginVertical: 15
    },
    countryItem: {
        height: 80,
        flex: 1,
        alignItems: "center",
    },
    countryIcon: {
        height: 50,
        aspectRatio: 1,
        resizeMode: "contain",
        alignSelf: "center",
    },
    countryLabel: {
        fontSize: responsiveText(8),
        textAlign: "center",
        fontWeight: "700",
    },
    countryRow: {
        width: "100%",
        flexDirection: "row",
        marginTop: 10
    },
    countriesView:{ 
        flex: 1, 
        alignItems: "center",
        width:"100%",
        justifyContent:"center" 
    },
    nextBtn: {
        backgroundColor: "#F1AB32",
        alignSelf: "center",
        height: 40,
        width: 160,
        marginTop: 10,
        borderRadius: 20,
        alignItems: "center",
        justifyContent: "center"
    },
    nextText: {
        color: "#fff",
        fontSize: responsiveText(16),
        fontWeight: "bold"
    }
})