import React, { useState } from "react";
import {
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
} from "react-native";
import {
  isWeb,
} from "../../common/helpers";
import { useDispatch } from "react-redux";
import NewSignupUi from "../NewSignupUi/newSignupUi";
import NavigationService from "../../navigation/NavigationService";
import { setGuestlogin } from "../../actions/path.actions";
import { withRouter } from "react-router";
import { styles } from "./newLoginUi.styles";
import { LoginFlowAnimation, LoginBgImage } from "./newLoginUi.animation";
import Login from "./components/Login";

function NewLoginUi({ navigation, route, history }) {
  const params = isWeb() ? history?.location?.state : route?.params;
  const [signUpview, setsignUpview] = useState(params?.signUp);
  const [socialUserInfo, setsocialUserInfo] = useState(params?.userInfo)
  const dispatch = useDispatch();

  const handleSignUpPress = () => {
    setsignUpview(!signUpview);
  };

  const onGuestLogin = () => {
    dispatch(setGuestlogin(true));
    Platform.OS == 'web' ? history.push('/dashboard') :
      NavigationService.navigate('HomeStack')
  };

  const onLogin = () => {
    let routeName = Platform.OS == 'web' ? history?.location?.state?.routeName : navigation.state.params.routeName || null
    Platform.OS == "web" ? routeName ? history.goBack() :
       history?.location?.state?.from == 'adviceForm' ? history.push("/adviceChannel", { autofillFromGuest: true }) : history.push("/dashboard")
      : routeName ? navigation.navigate(routeName) : navigation.navigate("HomeStack");
  }

  return (
    <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : null} style={styles.mainView}>
      <LoginBgImage
        dependsOn={signUpview}
        wrapperStyle={styles.leftView}
        imageStyle={styles.loginImg}
      />
      <LoginFlowAnimation dependsOn={signUpview} extraStyles={styles.rightView}>
        {!signUpview ? (
          <ScrollView
            style={{ width: "100%" }}
            contentContainerStyle={{ justifyContent: "center" }}
          >
            <TouchableOpacity activeOpacity={1} disabled={Platform.OS == "web" ? true : false} style={styles.loginView}>
              <Login
                onSignUpPress={handleSignUpPress}
                showBack={Platform.OS == "web" && !history?.location?.state?.hideBack}
                onBackPress={() => { history.goBack() }}
                onGuestLogin={onGuestLogin}
                onNewUser={() => {
                  Platform.OS == "web"
                    ? ""
                    : setsocialUserInfo(Obj); setsignUpview(true);
                }}
                onLogin={onLogin}
              />
            </TouchableOpacity>
          </ScrollView>
        ) : (
          <NewSignupUi countryIntrest={params?.countryIntrest} goalsIntrest={params?.goalsIntrest} socialUserInfo={socialUserInfo} handleSignUpPress={handleSignUpPress} onLoginPress={() => { setsignUpview(false) }} />
        )}
      </LoginFlowAnimation>
    </KeyboardAvoidingView>
  );
}

export default Platform.OS == "web" ? withRouter(NewLoginUi) : NewLoginUi;
