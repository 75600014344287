import Swal from 'sweetalert2';
import Toast from "react-native-simple-toast";
import { Platform } from 'react-native';

export default class SweetAlert {
  static success(message) {
    const webToast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
    });
    Platform.OS == 'web' ?
      webToast.fire({
        icon: 'success',
        title: message,
      }) :
      Toast.show(message, Toast.LONG);
  }

  static error(message) {
    const webToast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
    });
    Platform.OS == 'web' ?
      webToast.fire({
        icon: 'error',
        title: message,
      }) : Toast.show(message, Toast.LONG);;
  }
}
