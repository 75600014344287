import React, { useEffect, useState } from "react";
import {
    View,
    Text,
    TouchableOpacity,
    Dimensions,
    ScrollView,
    Image,
    Platform
} from "react-native";
import { Config, family, Images } from "../../../common";
import { LineChart } from "react-native-chart-kit";
import { hasNotch } from "react-native-device-info";
import { styles } from "./../prScoreModal.styles";
import CircleProgressBar from "../../CirlcleProgressBar/CircleProgressBar";
import { isWeb, mobilBrower } from "../../../common/helpers";

const { width: SCREEN_WIDTH } = Dimensions.get("window");

function PrScore({ PRPoints, 
    cutOffData, progressWidth = "100%", goBack,
    height = hasNotch() ? 220 : 180, 
    width = isWeb() && !mobilBrower() ? SCREEN_WIDTH * 0.2 : SCREEN_WIDTH / 1.2 }) {
    const [dynamicChartObj, setdynamicChartObj] = useState({
        labels: [],
        datasets: [],
        legend: [],
    });
    const [cutOffNote, setcutOffNote] = useState("");

    const getLegendTitle = (title) => {
        let _title = title.split(" ");
        let str = "";
        _title.forEach((s) => {
            str += s.charAt(0);
        });
        return str;
    };

    const sortByMonth = (arr) => {
        var months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        arr.sort(function (a, b) {
            let _a = Object.entries(a);
            let _b = Object.entries(b);
            return (
                months.indexOf(_a[0][1].split(" ")[0]) -
                months.indexOf(_b[0][1].split(" ")[0])
            );
        });
        return arr;
    };

    const getSecondHighest = (arr) => {
        const secondHighest = arr?.sort((a, b) => b - a)[arr.length - 2];
        return secondHighest;
    };

    const canadaPotential = () => {
        const { datasets } = dynamicChartObj;
        const refCEC = getSecondHighest(datasets[0]?.data);
        const refPNP = getSecondHighest(datasets[1]?.data);
        const refScore = getSecondHighest(datasets[2]?.data);

        if (refScore >= Math.max(refCEC, refPNP)) {
            return "Potentially Eligible";
        }

        if (refScore >= refCEC) {
            return "Potentially Eligible for CEC";
        }

        return "Potentially Not Eligible";
    };

    useEffect(() => {
        let label = [];
        let legend = [];
        let datasets = [];
        let points = Object.entries(PRPoints);
        if (points.length > 0) {
            let cuttOff = Object.entries(cutOffData);
            cuttOff.length > 0 &&
                cuttOff.forEach((s) => {
                    if (s[1].cut_off_type == "dynamic") {
                        setcutOffNote(s[1].cut_off.note);
                        let programs = s[1].cut_off.programs;
                        programs = Object.entries(programs);
                        let history = Object.entries(programs[0][1].history);
                        history = sortByMonth(history);
                        history.forEach((r) => {
                            label.push(r[0]);
                        });
                        programs.forEach((q, index) => {
                            let history = Object.entries(q[1].history);
                            history = sortByMonth(history);
                            legend.push(getLegendTitle(q[0]));
                            let t1 = [];
                            let obj = {};
                            history.forEach((r) => {
                                t1.push(r[1]);
                                obj = {
                                    data: t1,
                                    color:
                                        index == 0
                                            ? (opacity = 1) => `#F6E80C`
                                            : (opacity = 1) => `#0B6ACF`,
                                };
                            });
                            datasets.push(obj);
                        });
                    }
                });

            if (PRPoints["Canada"]) {
                datasets.push({
                    data: [
                        PRPoints["Canada"],
                        PRPoints["Canada"],
                        PRPoints["Canada"],
                        PRPoints["Canada"],
                    ],
                    color: (opacity = 1) => `red`,
                });
                legend.push("Your score");
            }
            setdynamicChartObj({
                labels: label,
                datasets,
                legend,
            });
        }
    }, [cutOffData, PRPoints]);

    const getCountryName = (id) => {
        let country = Config.intrestcountry.filter((s) => s.code == id);
        return country[0].value;
    };
    //
    const getEligibleStatus = (score, country) => {
        let color = "";
        let cutoffDif = 0;
        let maxCutOff = 10000;
        let cutOff = Object.entries(cutOffData);
        cutOff.forEach((s) => {
            if (getCountryName(s[0]).toUpperCase() == country.toUpperCase()) {
                if (s[1].cut_off <= score) {
                    color = "#00FF00";
                } else {
                    cutoffDif = s[1].cut_off - score;
                    color = "red";
                }
                maxCutOff = s[1].max_val;
            }
        });
        return { color: color, diff: cutoffDif, maxCutOff };
    };

    return (
        <>
        {   Platform.OS !== 'web' ?
            <TouchableOpacity 
                onPress={goBack}
                style={{ position: 'absolute', padding: 10, left: 10, top: 5 }}>
                <Image source={Images.backArrow} style={{ height: 16, width: 16, resizeMode: 'contain' }}/>
            </TouchableOpacity> : null
        }
            <Text style={styles.title}>Your PR score</Text>
            <View style={styles.pointsView}>
                <ScrollView
                    vertical={true}
                    showsVerticalScrollIndicator={false}
                    style={styles.pointsContent}
                >
                    {Object.entries(PRPoints).map((s, prPointsIndex) => {
                        const itemProgressPercentage =
                            s[0] == "Canada"
                                ? 70
                                : (s[1] / getEligibleStatus(s[1], s[0]).maxCutOff) *
                                100;
                        return (
                            <TouchableOpacity
                                key={s[0]}
                                activeOpacity={1}
                                style={[styles.contentContainer, { marginBottom: prPointsIndex == Object.entries(PRPoints).length - 1 ? 220 : 20 }]}
                            >
                                <Text style={styles.countryName}>{s[0]}</Text>
                                <View style={styles.wrapper}>
                                    <View
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: progressWidth,
                                        }}
                                    >
                                        <CircleProgressBar color={getEligibleStatus(s[1], s[0]).color} progressPercentage={itemProgressPercentage}>
                                            {(fill) => (
                                                <View style={[styles.perInfoWrapper]}>
                                                    <View style={[styles.perWrapper]}>
                                                        <Text style={[styles.completePercentageText]}>
                                                            {Math.floor(
                                                                (s[1] * fill) / itemProgressPercentage
                                                            )}
                                                        </Text>
                                                    </View>
                                                    <Text style={[styles.completeText]}>Points</Text>
                                                </View>
                                            )}
                                        </CircleProgressBar>
                                        <View
                                            style={{
                                                marginTop: -50,
                                            }}
                                        >
                                            {s[0] !== "Canada" ? (
                                                getEligibleStatus(s[1], s[0]).color !==
                                                    "red" ? (
                                                    <Text
                                                        style={[
                                                            styles.prStatus,
                                                            { color: "#008c00" },
                                                        ]}
                                                    >
                                                        Eligible
                                                    </Text>
                                                ) : (
                                                    <View>
                                                        <Text
                                                            style={[
                                                                styles.prStatus,
                                                                { color: "red" },
                                                            ]}
                                                        >
                                                            Not eligible
                                                        </Text>
                                                        <Text
                                                            style={[
                                                                styles.prStatus,
                                                                { color: "red" },
                                                            ]}
                                                        >
                                                            {getEligibleStatus(s[1], s[0]).diff +
                                                                " points below cut off"}
                                                        </Text>
                                                    </View>
                                                )
                                            ) : (
                                                <View>
                                                    <Text
                                                        style={[
                                                            styles.prStatus,
                                                            { color: "orange" },
                                                        ]}
                                                    >
                                                        {canadaPotential()}
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            styles.prStatusNoteW,
                                                            { color: "gray" },
                                                        ]}
                                                    >
                                                        *Based on average cut off from last 4 months
                                                    </Text>
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        backgroundColor: "rgb(244,244,244)",
                                        width: "100%"
                                    }}
                                >
                                    {s[0] == "Canada" ? (
                                        dynamicChartObj.datasets.length > 0 &&
                                            dynamicChartObj.labels.length > 0 ? (
                                            <View style={{ width: "100%", justifyContent: "center" }}>
                                                <Text
                                                    style={[
                                                        styles.cutoffText,
                                                        {
                                                            fontFamily: family.effra,
                                                            fontWeight: "bold",
                                                            marginBottom: 15,
                                                            fontSize: 16,
                                                        },
                                                    ]}
                                                >
                                                    More Info
                                                </Text>
                                                <LineChart
                                                    data={dynamicChartObj}
                                                    width={width} // from react-native
                                                    height={height}
                                                    fromZero
                                                    yAxisInterval={1} // optional, defaults to 1
                                                    getDotProps={(value, index) => {
                                                        let propsForDots = {};
                                                        if (
                                                            value == Object.entries(PRPoints)[0][1]
                                                        ) {
                                                            return (propsForDots = {
                                                                r: "3",
                                                                strokeWidth: "1",
                                                                stroke: "#ffa726",
                                                            });
                                                        } else {
                                                            return (propsForDots = {
                                                                r: "6",
                                                                strokeWidth: "2",
                                                                stroke: "#ffa726",
                                                            });
                                                        }
                                                    }}
                                                    chartConfig={{
                                                        backgroundGradientFromOpacity: 0,
                                                        backgroundColor: "transparent",
                                                        backgroundGradientToOpacity: 0,
                                                        backgroundGradientFrom: "transparent",
                                                        backgroundGradientTo: "transparent",
                                                        decimalPlaces: 2, // optional, defaults to 2dp
                                                        color: (opacity = 1) =>
                                                            `rgba(5, 5, 5, ${opacity})`,
                                                        labelColor: (opacity = 1) =>
                                                            `rgba(5, 5, 5, ${opacity})`,
                                                        style: {
                                                            borderRadius: 16,
                                                        },
                                                        propsForDots: {
                                                            r: "6",
                                                            strokeWidth: "2",
                                                            stroke: "#ffa726",
                                                        },
                                                        propsForLabels: {
                                                            fontSize: 8,
                                                            padding: 7,
                                                            paddingLeft: 12,
                                                            fontWeight: "bold",
                                                        },
                                                    }}
                                                    bezier
                                                    style={{
                                                        marginVertical: 8,
                                                        borderRadius: 16,
                                                    }}
                                                />
                                                <Text style={styles.cutoffText}>
                                                    {cutOffNote}
                                                </Text>
                                            </View>
                                        ) : (
                                            <View />
                                        )
                                    ) : (
                                        <View />
                                    )}
                                </View>
                            </TouchableOpacity>
                        );
                    })}
                </ScrollView>
            </View>
        </>
    );
}

export default PrScore;
