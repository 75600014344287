import { StyleSheet } from "react-native";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  main: {
    height: "100%",
    width: "100%",
  },
  text: {
    textAlign: "center",
    fontSize: 20,
    marginTop: "5%"
  },
  textT: {
    fontSize: 28,
    textAlign: "center",
    width: "100%",
    padding: 20,
  },
  note: {
    color: "grey"
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    flex: 1
  },
  jobCardContainer: {
    // height: 200,
    width: mobilBrower() ? "95%" : "45%",
    marginBottom: 10
  },
  first: {
    display: "flex",
    // flexDirection: "row",
    width: "45.5%",
    height: "100%",
    marginLeft: "3%",
    marginRight: "3%",
  },
  second: {
    display: "flex",
    width: "45.5%",
    height: "100%"
  },
  flexRow: {
    flexDirection: "row",
    width: "100%",
    paddingHorizontal: 10,
    marginVertical: 5
  },
  labelValue: {
    flex: 1
  },
  label: {
  },
  img: {
    height: 80,
    width: 80,
    backgroundColor: "grey",
    marginRight: 10
  },
  value: {
    fontWeight: "600",
    fontSize: 16,
    marginTop: 5
  },
  title: {
    fontWeight: "600",
    fontSize: 20,
    marginVertical: 5
  },
  btn: {
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  btnText: {
    color: "#fff",
    fontSize: 18
  },
  jobCard: {
    width: "100%",
    height: "100%",
    position: "relative",
    backgroundColor: "white",
    padding: 15,
    paddingTop: 5,
    borderRadius: 25,
    display: "flex",
    borderColor: "rgb(236,236,236)",
    borderWidth: 1,
  },
  card: {
    width: "100%",
    position: "relative",
    backgroundColor: "white",
    padding: 15,
    marginBottom: "2.5%",
    paddingTop: 5,
    borderRadius: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "rgb(236,236,236)",
    borderWidth: 1,
  },
  image: {
    width: "100%",
    height: "80%"
    // height: 180,
  },
  backWrapper: {
    backgroundColor: "rgb(15, 17, 67)",
    // padding: 25,
    width: "100%",
    height: "10%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 25,
  },
  backText: {
    color: "white",
    textTransform: "uppercase",
    textAlign: "center",
  },
  headerText: {
    fontSize: responsiveText(28),
    textAlign: "center",
    marginVertical: 15,
    color: "#fff",
    fontWeight: "bold"
  }
});
