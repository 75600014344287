import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
  Image,
} from "react-native";
import { styles as s } from "./JobsList.styles";
import { Tooltip } from "@material-ui/core";
import { Images } from "../../../../common";

const JobsList = ({ onViewMore = () => { }, jobs = [] }) => {

  return (
    <View style={[s.main]}>
      <Text style={s.headerText}>
        Jobs List
      </Text>
      <ScrollView style={{ marginVertical: 20, }} contentContainerStyle={{ flex: 1, flexDirection: "row", justifyContent: "space-evenly", flexWrap: "wrap" }}>
        {jobs.map((job, index) => (
          <View style={[s.jobCardContainer]}>
            <TouchableOpacity
              style={[s.jobCard]}
              disabled
              activeOpacity={0.6}
            >
              <View style={[s.flexRow, { marginVertical: 10, alignItems: "center" }]}>
                <View style={s.img} />
                <View style={{ flex: 1 }}>
                  <Text style={[s.note]}>{job.company}</Text>
                  <Tooltip arrow placement="top-start" title={<Text style={[{ color: "#fff" }]}>{job.job_title}</Text>}>
                    <Text style={[s.title]} numberOfLines={2}>{job.job_title}</Text>
                  </Tooltip>
                  <Text style={[s.note]}>{job.city}</Text>
                </View>
              </View>
              <View style={[s.flexRow, { flex: 1 }]}>
                <View style={s.labelValue}>
                  <Text style={[s.label]}>Experience Required</Text>
                  <Text style={[s.value]}>{job.experience_required || "0"} years</Text>
                </View>
                <View style={s.labelValue}>
                  <Text style={[s.label]}>Salary</Text>

                  <View style={{ flexDirection: "row" }}>
                    <Text style={[s.value]}>{job.salary || "Not Available"}</Text>
                    {job.salary_info ?
                      <Tooltip arrow placement="bottom-end" title={<Text style={[{ color: "#fff" }]}>{job.salary_info}</Text>}>
                        <Image source={Images.info1} style={{ height: 20, width: 20, marginLeft: 5, borderWidth: 1, borderColor: "#00000050", borderRadius: 20, }} />
                      </Tooltip>
                      : null}
                  </View>
                </View>
              </View>
              <View style={s.flexRow}>
                <View style={s.labelValue}>
                  <Text style={[s.label]}>Tax Rate</Text>
                  <Text style={[s.value]}>{job.tax_rate}</Text>
                </View>
                <View style={s.labelValue}>
                  <Text style={[s.label]}>Cost of Living</Text>
                  <Text style={[s.value]}>{job.cost_of_living}</Text>
                </View>
              </View>
              <View style={[s.flexRow, { marginTop: 10, marginBottom: 0, justifyContent: "space-between" }]}>
                <TouchableOpacity onPress={onViewMore} style={[s.btn, { paddingHorizontal: 0 }]}>
                  <Text style={[s.value]}>View More</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Linking.openURL(job.apply)} style={[s.btn, { backgroundColor: "rgb(15, 17, 67)" }]}>
                  <Text style={[s.btnText]}>Apply Now</Text>
                </TouchableOpacity>
              </View>
            </TouchableOpacity>
          </View>
        ))}
      </ScrollView>
    </View>
  );
};


export default JobsList;