// import _Config from "./config";
// import _Image from "./images";

// export const Config = _Config;
// export const Images = _Image;

import _Config from './config'
import _Image from './images'
import _Font from './font'
import _Normalize from './normalize'
import _Colors from './colors'
import _Helpers from './helpers'
import _Fontfamily from './fontFamily'

export const Config = _Config
export const Images = _Image
export const fonts = _Font
export const normalize = _Normalize
export const colors = _Colors
export const family = _Fontfamily
export const helpers = _Helpers
