import React, { useState } from "react";
import { View, Text, TextInput } from "react-native";
import { styles } from "../../editProfile.styles";

export const BillingSection = ({ setphone,address_line1,
  address_line2,city,ustate,country,pincode,phone,
  setaddress_line1, setaddress_line2, setcountry,userDetails,
  setcity, _setstate, setpincode }) => {

  return (
    <View
      style={{
        marginBottom: 25,
      }}
    >
      <Text style={[styles.title]}>Billing </Text>
      <View style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}>
        <View style={styles.inputWrapper}>
          <Text style={styles.inputLabel}>Address line 1</Text>
          <TextInput style={styles.textInputLarge}
            onChangeText={(t) => setaddress_line1(t)}
            value={address_line1}
            defaultValue="" />
        </View>
      </View>
      <View style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}>
        <View style={styles.inputWrapper}>
          <Text style={styles.inputLabel}>Address line 2</Text>
          <TextInput
            onChangeText={(t) => setaddress_line2(t)}
            value={address_line2}
            style={styles.textInputLarge} defaultValue="" />
        </View>
      </View>
      <View style={styles.smallInputsWrapper}>
        <View style={[styles.inputBoxSmall, styles.inputBoxmarginTop,{marginRight:10}]}>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabel}>City</Text>
            <TextInput
              onChangeText={(t) => setcity(t)}
              value={city}
              style={styles.textInputSmall} defaultValue="" />
          </View>
        </View>
        <View style={[styles.inputBoxSmall, styles.inputBoxmarginTop]}>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabel}>State</Text>
            <TextInput
              onChangeText={(t) => _setstate(t)}
              value={ustate}
              style={styles.textInputSmall} defaultValue="" />
          </View>
        </View>
      </View>
      <View style={styles.smallInputsWrapper}>
        <View style={[styles.inputBoxSmall, styles.inputBoxmarginTop,{marginRight:10}]}>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabel}>Country</Text>
            <TextInput
              onChangeText={(t) => setcountry(t)}
              value={country}
              style={styles.textInputSmall} defaultValue="" />
          </View>
        </View>
        <View style={[styles.inputBoxSmall, styles.inputBoxmarginTop]}>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabel}>Pincode</Text>
            <TextInput
              onChangeText={(t) => setpincode(t)}
              value={pincode}
              style={styles.textInputSmall} defaultValue="" />
          </View>
        </View>
      </View>
      <View style={[styles.inputBoxLarge, styles.inputBoxmarginTop]}>
        <View style={styles.inputWrapper}>
          <Text style={styles.inputLabel}>Phone Number</Text>
          <TextInput
            onChangeText={(t) => setphone(t)}
            keyboardType="number-pad"
            value={phone}
            maxLength={10}
            style={styles.textInputLarge} defaultValue="" />
        </View>
      </View>
    </View>
  );
};
