import { StyleSheet } from "react-native";
import { family, helpers } from "../../../../../../common";
import { responsiveText } from "../../../../../../common/normalize"
const { mobilBrower } = helpers

export const styles = StyleSheet.create({
  completePercentageText: {
    fontFamily: family.effra,
    alignItems: "center",
    color: "black",
    fontSize: mobilBrower() ? 60 : responsiveText(70),
    marginBottom: -12,
    display: "flex",
    justifyContent: "center",
    marginLeft: 10,
  },
  percentageInfoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: -80,
    marginTop: 10,
  },
  perInfoWrapper: {
    marginTop: -65,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  perWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  completeText: {
    fontFamily: family.effra,
    alignItems: "center",
    color: "black",
    fontSize: 15,
    marginBottom: -12,
    display: "flex",
    justifyContent: "center",
  },
});
