import React, { useState } from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import { Images } from "../../common";
import { useSelector, useDispatch } from "react-redux";
import Icon from "react-native-vector-icons/dist/FontAwesome";
import AsyncStorage from "@react-native-community/async-storage";
import NetInfo from "@react-native-community/netinfo";
import Toast from "react-native-simple-toast";
import { useSpring, animated as a } from "@react-spring/native";
import { logoutUser, expiryToken } from "../../actions/auth.actions";
import { TrailFromRight } from "./editProfileSidebar.animation";
import { styles } from "./editProfileSidebar.styles";
import AlertModal from "../AlertModal/AlertModal";
import { isWeb } from "../../common/helpers";
import { useHistory } from "react-router-dom";
import { heightPercentageToDP } from "../../common/responsiveScreen";
import SweetAlert from "../SweetAlert";

const EditProfileSideBar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);

  const [showAlert, setShowAlert] = useState(false);

  const SIDEBAR_OPTIONS = [
    {
      iconSize: 23,
      iconName: "id-badge",
      title: "Edit Profile",
      onPress: () => isWeb() ? props.handleEditClick() : navigateTo("EditProfile")
    },
    {
      iconSize: 16,
      iconName: "user-plus",
      title: "Refer a Friend",
      onPress: () => props.handleReferClick()
    },
    {
      imgSource: Images.toolbarLogo,
      title: "About Us",
      onPress: () => isWeb() ? navigateToWeb("about") : navigateTo("AboutUs")
    },
    {
      iconSize: 20,
      iconName: "comments-o",
      title: "Support",
      onPress: () => navigateTo("CustomerSupport"),
      onlyMob: true
    },
    {
      iconSize: 22,
      iconName: "gear",
      title: "Settings",
      onPress: () => isWeb() ? navigateToWeb("settings") : navigateTo("Settings")
    },
    {
      iconSize: 22,
      iconName: "gear",
      title: "Policies, terms and conditions",
      onPress: () => isWeb()? navigateToWeb("Policies"):navigateTo("Policies"),
    },
  ]

  const logout = () => {
    NetInfo.fetch().then((state) => {
      if (!state.isConnected) {
        Toast.show("Check your connection", Toast.LONG);
      } else {
        setShowAlert(true);
      }
    });
  };

  const handleLogoutConfirmed = async () => {
    let postdata = {};
    postdata.email_address = userDetails.email_address;
    var user_id = userDetails?.userid;
    const responsedata = await dispatch(logoutUser(postdata, user_id, token));
    AsyncStorage.clear();
    const expirystr = "Auth Token not valid or expired!";

    if (responsedata.status == expirystr) {
      SweetAlert.error(expirystr);
      await dispatch(expiryToken());
      isWeb() ? history.push("/login", { hideBack: true }) :
        props.navigation.navigate("Login");
    } else {
      isWeb() ? history.push("/login", { hideBack: true }) :
        props.navigation.navigate("Login");
      setTimeout(() => {
        dispatch(expiryToken());
      }, 1000);
    }
  };

  const slideIn = useSpring({
    marginRight: props.visible ? "0%" : "-100%",
  });

  const arrowSlideIn = useSpring({
    left: props.visible ? -10 : 500,
  });

  const buttonSlideIn = useSpring({
    marginLeft: props.visible ? 0 : 20,
    width: props.visible ? "80%" : "0%",
  });

  const navigateTo = (to, params = {}) => {
    props.navigation.navigate(to, params);
    props.closeSideBar();
  };

  const navigateToWeb = (to) => {
    history.push(`/${to}`);
    props.closeSideBar();
  }

  const Icons = ({ name, size }) => (
    isWeb() ? <i className={`fa fa-${name}`} style={{ color: "#D4A651", fontSize: size }} /> : <Icon name={name} size={size} color="#D4A651" />
  )

  const renderOptions = () => {
    return SIDEBAR_OPTIONS.map(({ onPress, iconName, imgSource, iconSize, title, onlyMob }, index) => {
      if (isWeb() && onlyMob) {
        return null;
      }
      return (
        <TouchableOpacity
          key={index + "o"}
          onPress={onPress}
          style={styles.optionInnerView}
        >
          {imgSource ?
            <Image
              style={{ height: 20, width: 20 }}
              source={imgSource}
            /> :
            <Icons name={iconName} size={iconSize} />}
          <Text style={[styles.optionsText, { left: 2 }]}>{title}</Text>
        </TouchableOpacity>
      )
    })
  }

  return (
    <a.View style={[slideIn, styles.mainView]}>
      <View style={styles.imageView}>
        <a.View style={[styles.leftArrow, arrowSlideIn]}>
          <TouchableOpacity onPress={() => props.closeSideBar()}>
            <Image
              source={Images.whiteBack}
              style={{
                height: heightPercentageToDP("2.8%"),
                width: heightPercentageToDP("2.8%"),
                resizeMode: "contain",
              }}
            />
          </TouchableOpacity>
        </a.View>
      </View>
      <View style={styles.optionsView}>
        <TrailFromRight renderAnimation={props.visible}>
          {renderOptions()}
        </TrailFromRight>
      </View>
      <a.View style={[buttonSlideIn, styles.logoutButton]}>
        <TouchableOpacity onPress={() => logout()}>
          <Text style={[styles.logoutButtonText]}>Logout</Text>
        </TouchableOpacity>
      </a.View>
      {showAlert ? (
        <AlertModal
          confirmText="No"
          showConfirm
          showCancel
          title="Logout"
          description="Are you sure you want to logout?"
          cancelText="Yes"
          onCancelPress={handleLogoutConfirmed}
          onConfirmPress={() => {
            setShowAlert(false);
          }}
        />
      ) : null}
    </a.View>
  );
};

export default EditProfileSideBar;
