import { combineReducers } from 'redux'
import { helpers } from '../common'
import { sortBy } from "lodash";
import AsyncStorage from '@react-native-community/async-storage';

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errors: null,
  userSavedPaths: [],
  immipathToView: null,
  showAllVisas: true,
  appTrackerList: [],
  pathDetails: {
    countryName: '',
    pathName: '',
    minCost: '',
    maxCost: '',
    minTime: '',
    maxTime: '',
    eligibilityCriteria: [],
    goalIds: [],
    immipathId: '',
    pathDescription: '',
    resources: []
  },
  proceedClicked: false,
  immiPaths: [],
  selectedImmiPaths: [],
  countryArray: [],
  countryIdArray: [],
  filterButtonGuide: false,
  unStringifiedCountryArray: [],
  explorationId: '',
  eligibilityQue: [],
  userPressedGo: false,
  pathTutorialProceed: '',
  countryOfInterest: [],
  immigationInterest: [],
  selectedgoalandcountries: {},
  quesarray: [],
  applicationDict: {},
  select_all: false,
  isKeepHistory: false,
  isFromPrevious: 0,
  guestLogin: true
}

const getImmipathDetails = (state = initialState, action) => {
  switch (action.type) {
    case 'KEEP_HISTORY':
      return {
        ...state,
        isKeepHistory: action.payload
      }
    case 'CONTINUE_FROM_PREVIOUS':
      return {
        ...state,
        isFromPrevious: action.payload
      }
    case 'CLEAR_QUESTIONS_DATA':
      return {
        ...state,
        explorationId: ""
      }
    case 'SEARCH_IMMI_LOADING':
      return {
        ...state,
        isSearchLoading: true,
        isError: false,
        isSuccess: false,
        errors: null
      }

    case 'SEARCH_IMMI_SUCCESS':

      return {
        ...state,
        isSearchLoading: false,
        isError: false,
        isSuccess: true,
        eligibilityQue: action.payload.res.eligibility_qns,
        explorationId: action.payload.res.exploration_id,
        errors: null,
        countryOfInterest: action.payload.countryOfInterest,
        immigationInterest: action.payload.immigationInterest
      }

    case 'SEARCH_IMMI_FAIL':
      return {
        ...state,
        isSearchLoading: false,
        isError: true,
        isSuccess: false,
        eligibilityQue: null,
        explorationId: null,
        errors: action.payload,
        updatedAt: new Date()
      }

    case 'CLEAR_DETAILS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errors: null,
        pathDetails: {
          countryName: '',
          pathName: '',
          minCost: '',
          maxCost: '',
          minTime: '',
          maxTime: '',
          eligibilityCriteria: [],
          goalIds: [],
          immipathId: '',
          pathDescription: '',
          resources: []
        },
        proceedClicked: false,
        immiPaths: [],
        selectedImmiPaths: [],
        countryArray: [],
        countryIdArray: [],
        unStringifiedCountryArray: [],
        updatedAt: ''
      }

    case 'GET_IMMIPATH_LOADING':
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        errors: null
      }

    case 'GET_IMMIPATH_DETAILS_SUCCESS':
      let pathDetails = state.pathDetails
      const details = action.pathDetails
      // console.log("path_details", action.countryName);

      const goalNames = action.goalArray
      pathDetails['countryName'] = action.countryName || ''
      pathDetails['countryName'] = action.countryName || ''
      pathDetails['pathName'] = details.immipath_name || ''
      pathDetails['minCost'] = details.cost_for_application_min || ''
      pathDetails['maxCost'] = details.cost_for_application_max || ''
      pathDetails['minTime'] = details.time_for_user_application_min || ''
      pathDetails['maxTime'] = details.time_for_user_application_max || ''
      pathDetails['eligibilityCriteria'] = details.eligibility_criteria || []
      pathDetails['criteriaStatus'] = details.criteria_status || []
      pathDetails['goalNames'] = goalNames || []
      pathDetails['immipathId'] = details.immipath_id || ''
      pathDetails['pathDescription'] = details.path_description || ''
      pathDetails['resources'] = details.resources || []
      pathDetails['validity'] = details.validity || ''

      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        pathDetails: pathDetails,
        errors: null
      }

    case 'GET_IMMIPATH_DETAILS_FAIL':
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        pathDetails: null,
        errors: action.payload,
        updatedAt: new Date()
      }
    case 'RESOLVE_QUESTIONNAIRE_LOADING':
      return {
        ...state,
        isSearchLoading: true,
        isError: false,
        isSuccess: false,
        errors: null
      }

    case 'RESOLVE_QUESTIONNAIRE_SUCCESS':
      let immiPaths = action.payload
      let excludedPaths = action.excluded_path.map((item, index) => {
        const { country_id } = item
        const goalNameArray = item.goals_ids || item.goal_ids
        const goalNames = helpers.getGoalName(goalNameArray)
        const countryName = helpers.getCountryName(country_id)
        const data = {
          cost: item.application_cost_range || '',
          time: item.application_processing_time_range || '',
          pathName: item.immipath_name || '',
          checked: false,
          countryName: countryName || '',
          goalNames: goalNames || '',
          countryId: country_id,
          immipathId: item.immipath_id || '',
          score: item.score,
          isexcluded: true,
          score_breakdown: item.score_breakdown
        }

        return data
      })
      let dataArray = immiPaths.map((item, index) => {
        const { country_id } = item
        const goalNameArray = item.goals_ids || item.goal_ids
        const goalNames = helpers.getGoalName(goalNameArray)
        const countryName = helpers.getCountryName(country_id)
        const data = {
          cost: item.application_cost_range || '',
          time: item.application_processing_time_range || '',
          pathName: item.immipath_name || '',
          checked: false,
          countryName: countryName || '',
          goalNames: goalNames || '',
          countryId: country_id,
          immipathId: item.immipath_id || '',
          score: item.score,
          isexcluded: false,
          score_breakdown: item.score_breakdown
        }

        return data
      })
      dataArray = sortBy(dataArray, o => o.score).reverse()
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        immiPaths: dataArray.concat(excludedPaths),
        questionnaire: action.questionnaire,
        updatedAt: new Date(),
        excluded_reason: action.excluded_reason,
        promotions: action.promotions,
        errors: null,
        isSearchLoading: false
      }

    case 'RESOLVE_QUESTIONNAIRE_FAIL':
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        immiPaths: null,
        errors: action.payload,
        isSearchLoading: false
      }

    case 'SAVE_QUESTIONS':
      return {
        ...state,
        quesarray: action.payload
      }

    case 'SETUP_PROFILE_QUESTION_LIST':
      return {
        ...state,
        setupProfileQuestions: action.payload
      }

    case 'SET_USER_DEGREE_COURSE_SELECTION':
      return {
        ...state,
        userDegreeCourseSelections: action.userselection,
        userSelectionWithQuestionId: action.userSelectionWithQuestionId
      }

    case 'SET_UNDERGRADUATE_QUESTIONS':
      return {
        ...state,
        underGraduateQuestions: action.payload
      }

    case 'PEVIOUS_SAVED_PATHS':
      return {
        ...state,
        previousSavePaths: action.payload
      }

    case 'PEVIOUS_USER_TRACK':
      return {
        ...state,
        previousUserTrack: action.payload
      }
    case 'VIEW_IMMIPATH_DATA':
      return {
        ...state,
        immipathToView: action.payload
      }
    case 'SET_USER_IMMMIPATH_SEARCH_RESULT':
      return {
        ...state,
        userImmipathSearchResults: action.payload
      }

    case 'SET_SELECTED_UNIVERSITY':
      return {
        ...state,
        selectedUniversity: action.payload
      }

    case 'SET_SHORTLISTED_UNIVERSITY':
      return {
        ...state,
        shortListedUniversity: action.payload
      }

    case 'SET_UNDERGRADUATE_USER_ANSWERS':
      return {
        ...state,
        underGraduateUserAnswer: action.payload
      }
    case 'FETCH_DYNAMIC_FACTS':
      return {
        ...state,
        dynamicFacts: action.payload
      }

    case '':
      return {
        ...state,
        appTrackerList: action.payload
      }
    case 'TUTORIAL_GO_PRESSED':
      return {
        ...state,
        userPressedGo: !state.userPressedGo
      }
    case 'SET_SELECTED_GOAL':
      return {
        ...state,
        selectedgoalandcountries: action.payload
      }
    case 'PATH_TUTORIAL_NEXT_CLICK':
      return {
        ...state,
        pathTutorialNextClick: !state.pathTutorialNextClick
      }
    case 'PATH_TUTORIAL_PROCEED':
      return {
        ...state,
        pathTutorialProceed: action.pathTutorialProceed
      }
    case 'SIDEBAR_TUTORIAL_FINISH_CLICKED':
      return {
        ...state,
        sideBarTutorialFinshClicked: action.sideBarTutorialFinshClicked
      }
    case 'COUNTRIES_NEXT_CLICKED':
      return {
        ...state,
        countriesNextClicked: action.countriesNextClicked
      }
    case 'QUESTIONNAIRE_TUTORIAL_NEXT_CLICKED':
      return {
        ...state,
        quesTutorialNextClicked: action.quesTutorialNextClicked
      }
    case 'CALL_ELIGIBLE_PATH_NEXT':
      return {
        ...state,
        eligiblePathNext: action.eligiblePathNext
      }
    case 'CALL_PATH_EXP_GUIDE':
      return {
        ...state,
        callPathExpGuide: action.callPathExpGuide
      }
    case 'FETCH_SAVED_PATHS':
      return {
        ...state,
        userSavedPaths: action.payload
      }
    case 'RESTART_QUES_GUIDE':
      return {
        ...state,
        restartQuesGuide: action.restartQuesGuide
      }
    case 'RESTART_ELIG_PATHEADER_GUIDE':
      return {
        ...state,
        restartEligiPathHeaderGuide: action.restartEligiPathHeaderGuide
      }
    case 'ELIG_PATHEADER_GUIDE':
      return {
        ...state,
        EligiPathHeaderGuide: action.EligiPathHeaderGuide
      }
    case 'RESTART_APP_TIMELINE_GUIDE':
      return {
        ...state,
        restartAppTimelineGuide: action.restartAppTimelineGuide
      }
    case 'RESTART_NEWS_GUIDE':
      return {
        ...state,
        restartNewsGuideStatus: action.restartNewsGuideStatus
      }
    case 'RESTART_SAVE_IMMIPATH_GUIDE':
      return {
        ...state,
        restartsaveimmipath: action.restartsaveimmipath
      }
    case 'CALL_COMPARATOR_GUIDE':
      return {
        ...state,
        comparatorGuide: action.comparatorGuide
      }
    case 'RESTART_COMPARATOR_GUIDE':
      return {
        ...state,
        restartComparator: action.restartComparator
      }
    case 'ADVICE_CHANNEL_QUIDE_NEXT':
      return {
        ...state,
        adviceChannelQuideNext: action.adviceChannelQuideNext
      }
    case 'RESTART_ADVICE_CHANNEL_GUIDE':
      return {
        ...state,
        restartAdviceChannelguideStatus: action.restartAdviceChannelguideStatus
      }
    case 'FILTER_BUTTON_GUIDE':
      return {
        ...state,
        filterButtonGuide: action.filterButtonGuide
      }
    case 'SHOW_ALL_VISAS':
      return {
        ...state,
        showAllVisas: action.showAllVisas
      }
    case 'ADD_TO_DO_LIST':
      return {
        ...state,
        todoList: action.todoList
      }
    case 'GUEST_LOGIN_STATUS':
      return {
        ...state,
        guestLogin: action.guestLogin
      }
    case 'COMPARE_GUIDE_NEXT_CLICK':
      return {
        ...state,
        compareGuideNext: action.compareGuideNext
      }
    case 'CALL_SHORTLIST_BUTTON_GUIDE':
      return {
        ...state,
        shortListButtonGuide: action.shortListButtonGuide
      }
    case 'APPLICATION_TIMELINE_GUIDE':
      return {
        ...state,
        applicationTimelineGuide: action.applicationTimelineGuide
      }
    case 'GET_TO_DO_ITEM_SUCCESS':
      return {
        ...state,
        toDoListItems: action.payload,
        isLoading: false
      }
    case 'GET_TO_DO_LIST_LOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'TOGGLE_TICK':
      let immiArray = state.immiPaths
      let dataDict = immiArray[action.payload]
      dataDict.checked = !dataDict.checked
      immiArray[action.payload] = dataDict

      return {
        ...state,
        immiPaths: immiArray,
        updatedAt: new Date()
      }

    case "SELECT_ALL":
      let immiPathsArray = state.immiPaths.map(function (singleTimeline) {
        singleTimeline.checked = action.payload
        return singleTimeline
      });
      AsyncStorage.setItem('selectAll', JSON.stringify(action.payload))
      return {
        ...state,
        timelineData: immiPathsArray,
        select_all: action.payload,
        updatedAt: new Date()
      };

    case 'PROCEED_FROM_IMMIPATHS':
      const immiPathArray = state.immiPaths
      let array = []
      let idArray = []
      let selectedCountries = []
      let selectedCountryId = []
      let selectedCountryIdUnstringified = []
      immiPathArray.find(val => {
        if (val.checked == true) {
          array.push(val)
          idArray.push(val.immipathId)
          selectedCountries.push(val.countryName)
          selectedCountryId.push(JSON.stringify(val.countryId))
          selectedCountryIdUnstringified.push(val.countryId)
        }
      })
      const uniqueCountryArray = [...new Set(selectedCountries)]
      const uniqueCountryIdArray = [...new Set(selectedCountryId)]
      const unqueSelectedCountryIdUnstringified = [
        ...new Set(selectedCountryIdUnstringified)
      ]
      return {
        ...state,
        selectedImmiPaths: array,
        immipathIdArray: idArray,
        proceedClicked: true,
        updatedAt: new Date(),
        countryArray: uniqueCountryArray,
        countryIdArray: uniqueCountryIdArray,
        unStringifiedCountryArray: unqueSelectedCountryIdUnstringified
      }

    default:
      return state
  }
}

export default combineReducers({
  getImmipathDetails
})
