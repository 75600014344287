import React, { useState } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { getDiscussionAction } from "../../../actions/discussion.action";
import Images from "../../../common/images";
import { Network } from "../../../service/api";
import AlertModal from "../../AlertModal/AlertModal";

export const ReactionInfo = ({ item, children, discussion_id, totalComments, displayCommentCount }) => {
  
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const token = useSelector((state) => state.authReducer.authData.token);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const dispatch = useDispatch()
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);


  const addReaction = async (reactionType) => {
    if (guestLogin) {
      setShowLoginAlert(true);
      return;
    }
    let payload = {
      discussion_id: discussion_id,
      response_id: item.response_id,
      action: reactionType ? 'thumbs_up' : 'thumbs_down'
    }
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    await Network(`/users/${userDetails?.userid}/log_actions/select_forum_reaction`, "POST", payload, headers);
    dispatch(getDiscussionAction(`${userDetails?.userid}@${token}`, discussion_id))
  }

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderColor: "rgb(220,220,220)",
        // borderTopWidth: 1,
        paddingTop: 5,
        marginTop: 15,
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <TouchableOpacity
          onPress={() => addReaction(true)}
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: 15,
            alignItems: "center",
          }}
        >
          <Image
            style={{
              width: 17,
              height: 17,
              marginRight: 5
            }}
            source={item?.thumbs_up_users?.includes(userDetails?.userid) ? Images.likeComment : Images.likeIcon}
          />
          <Text>{item?.thumbs_up_users.length}</Text>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => addReaction(false)}
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: 15,
            alignItems: "center",
          }}
        >
          <Image
            style={{
              width: 17,
              height: 17,
              marginTop: 3,
              marginRight: 5
            }}
            source={item?.thumbs_down_users?.includes(userDetails?.userid) ? Images.dislikeComent : Images.dislikeIcon}
          />
          <Text>{item?.thumbs_down_users.length}</Text>
        </TouchableOpacity>
        {
          displayCommentCount &&
          <View
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Image
              style={{
                width: 17,
                height: 17,
                marginTop: 3,
                marginRight: 5
              }}
              source={Images.comments}
            />
            <Text>{totalComments}</Text>
          </View>
        }
      </View>
      {children}
      {
        showLoginAlert &&
        <AlertModal
          title="Login"
          description="Login to do this action!"
          showCancel
          showConfirm
          confirmText="Login"
          cancelText="Not now"
          loginModal
          onCancelPress={() => setShowLoginAlert(false)}
          onConfirmPress={() => setShowLoginAlert(false)}
        />
      }
    </View>
  );
};
