import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import helpers, { isWeb } from "../../../../../common/helpers";
import {
  getMetaDataDate,
  getBackgroundColor,
} from "../../../../../utils/localityFactsDisplay.utils";
import * as Animatable from "react-native-animatable";
import { colors } from "../../../../../common";
import { styles } from "../localityFactsDisplay.styles";
import BarChartComp from "../../BarChart/BarChart";
import Icon from "react-native-vector-icons/Entypo";

export const FactTypeA = ({
  item,
  handleShowRaw,
  selectedCurrency,
  scroll,
  selectedCities,
  metaData,
}) => {
  let label = [];
  let datasets = [
    {
      data: [],
      colors: [],
    },
  ];
  Object.entries(item).forEach((s) => {
    let value =
      item.fact == "Selected Job Salary" ||
        item.fact == "Average Monthly Net Salary (After Tax)"
        ? Math.round(helpers.currencyConverter(selectedCurrency, s[1]))
        : item.fact == "Population"
          ? parseInt(s[1].replace(/\,/g, ""))
          : s[1];
    if (value) {
      s[0] !== "fact" && label.push(s[0]);
      s[0] !== "fact" && datasets[0].data.push(value);
      s[0] !== "fact" &&
        datasets[0].colors.push(getBackgroundColor(item.fact, value));
    }
  });
  return (
    <Animatable.View animation="fadeIn" duration={500}>
      <TouchableOpacity
        activeOpacity={1}
        style={{ alignSelf: "center", marginTop: 5 }}
      >
        <BarChartComp label={label} scroll={scroll} datasets={datasets} />
      </TouchableOpacity>
      {selectedCities > Object.entries(item).length - 1 ? (
        <Text style={{ alignSelf: "center", color: "red", marginTop: 5 }}>
          Data not available for some cities.
        </Text>
      ) : (
        <View />
      )}

      <View style={styles.metaDataView}>
        {getMetaDataDate(item.fact, metaData)?.last_updated ? (
          <Text style={styles.metaDataView_date}>
            {"Last updated: " +
              getMetaDataDate(item.fact, metaData)?.last_updated}
          </Text>
        ) : (
          <View />
        )}
        <TouchableOpacity onPress={() => handleShowRaw(item)}>
          {isWeb() ? <i className="fa fa-info-circle" style={{ color: colors.LIGHT_BLUE, fontSize: 20 }} /> :
            <Icon name={"info-with-circle"} color={colors.LIGHT_BLUE} size={20} />
          }
        </TouchableOpacity>
      </View>
    </Animatable.View>
  );
};
