import React from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import { walkthroughable } from "react-native-copilot";

export const StepNumberComponent = ({
  isFirstStep,
  isLastStep,
  currentStep,
  currentStepNumber,
}) => (
  <View />
  // <View style={styles.stepNumber}>
  //     <Text style={[styles.stepNumberText]}>{currentStepNumber}</Text>
  // </View>
);

export const WalkthroughableImage = walkthroughable(Image);
export const WalkthroughableText = walkthroughable(Text);
export const WalkthroughableView = walkthroughable(View);
export const WalkthroughableTouch = walkthroughable(TouchableOpacity);
