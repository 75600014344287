import React from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Image,
  StyleSheet,
} from "react-native";
import { Images, family } from "../common";
import * as Animatable from "react-native-animatable";
import BlurBackground from "./BlurBackground";
import { Network, apiPaths } from "../service/api";
import { useSelector } from "react-redux";
import { heightPercentageToDP, widthPercentageToDP } from "../common/responsiveScreen";
import { RWebShare } from "react-web-share";

const ReferralCodeModal = (props) => {
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const Token = useSelector((state) => state.authReducer.authData.token);

  const [userReferralCode, setuserReferralCode] = React.useState(null);
  const [friendsReferred, setFriendReferred] = React.useState(0);

  const getUserReferralCode = async () => {
    const url = `referrals/${userDetails?.userid}`;
    const headers = {
      Accept: "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };
    await Network(apiPaths.ref_code + url, "GET", null, headers).then((res) => {
      if (res.status === "Success") {
        setuserReferralCode(res.referral_code);
      }
    });
  };

  const getUserRedeemStatus = async () => {
    const url = `referrals/redeem/${userDetails?.userid}`;
    const headers = {
      Accept: "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };
    await Network(apiPaths.ref_code + url, "GET", null, headers).then((res) => {
      if (res.status === "Success") {
        setFriendReferred(res.redeem_status.referred);
      }
    });
  };

  React.useEffect(() => {
    if (Token) {
      (async () => {
        await getUserReferralCode();
        await getUserRedeemStatus();
      })();
    }
  }, [Token]);

  const iOS = "https://apps.apple.com/in/app/immigreat/id1577394991";

  const android =
    "https://play.google.com/store/apps/details?id=global.immigreat.app&hl=en_CA&gl=US";

  const msg = `Hey! I have gifted you 100 Immibucks on Immigreat App. How to claim?\n1. Download the Immigreat App from here:\niOS: ${iOS}\nandroid: ${android}\n2. Use my invite code ${userReferralCode}\n3. Get 100 Immibucks!\nImmigreat to where your compass points!`;

  // const progress = friendsReferred >= 1 ? friendsReferred / 5 : 0;

  const friendText = friendsReferred == 1 ? "friend" : "friends";

  return (
    <>
      {props.visible ?
        <View style={{ position: "absolute", zIndex: 999, height: heightPercentageToDP(100), width: widthPercentageToDP(100) }}>
          <BlurBackground />
          <View style={styles.mainView}>
            <Animatable.View
              duration={500}
              animation="slideInUp"
              style={styles.innerView}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <Text style={[styles.reftext, { fontSize: 16 }]}>
                  Refer 5 friends to earn 100
                </Text>
                <Image source={Images.immibuck} style={styles.immibuckImg} />
              </View>
              <Text style={styles.reftext}>Your referral code</Text>
              <Text style={styles.referralCode}>{userReferralCode}</Text>
              <Text style={[styles.reftext, { fontSize: 11, marginTop: 5 }]}>
                {`You have referred ${friendsReferred} ${friendText}`}
              </Text>
              <Text
                style={[
                  styles.reftext,
                  {
                    marginTop: 10,
                    textAlign: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  },
                ]}
              >
                Referral is successful only when your friend signs up using your
                referral code
              </Text>
              <View style={styles.buttonView}>
                <TouchableOpacity
                  style={styles.buttonStyle}
                >
                  <RWebShare
                    data={{
                      text: msg,
                      title: "",
                    }}
                    onClick={() => props.close()}
                  >
                    <Text style={[styles.reftext, { color: "white" }]}>Share</Text>
                  </RWebShare>


                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => props.close()}
                  style={[
                    styles.buttonStyle,
                    { marginTop: 5, backgroundColor: "tarnsparent" },
                  ]}
                >
                  <Text style={[styles.reftext, { color: "black" }]}>Not now</Text>
                </TouchableOpacity>
              </View>
            </Animatable.View>
          </View>
        </View> : null}
    </>
  );
};

const styles = StyleSheet.create({
  mainView: {
    backgroundColor: "#222222c7",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  immibuckImg: {
    height: 30,
    width: 30,
    position: "absolute",
    right: -30,
    resizeMode: "contain",
    marginTop: 20,
  },
  buttonStyle: {
    backgroundColor: "#030135",
    borderRadius: 5,
    width: "100%",
    height: heightPercentageToDP("5%"),
    alignItems: "center",
    justifyContent: "center",
  },
  innerView: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F1F1F1",
    borderRadius: 15,
    paddingTop: 15,
    width: "90%",
  },
  reftext: {
    fontFamily: family.semibold,
    fontSize: 15,
    color: "#030135",
  },
  referralCode: {
    fontFamily: family.semibold,
    fontSize: 19,
    marginTop: 5,
    marginBottom: 15,
    color: "#030135",
  },
  absolute: {
    left: 0,
    width: "100%",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",
    borderRadius: 10,
    backgroundColor: "transparent",
    justifyContent: "center",
    bottom: 0,
    right: 0,
  },
  buttonView: {
    alignItems: "center",
    marginBottom: 15,
    width: "55%",
    marginTop: 15,
    alignSelf: "center",
    justifyContent: "space-between",
  },
});

export default ReferralCodeModal;
