import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  TextInput,
  Modal,
} from "react-native";
import { family } from "../../../../common";
import { isWeb, mobilBrower } from "../../../../common/helpers";
import BlurBackground from "../../../BlurBackground";
const { width } = Dimensions.get("window");

export default function DeleteAccountModal({
  visible,
  onClose,
  confirmDelete,
  confirmPasswordChage,
}) {
  const content = () => {
    return (
      <View style={styles.mainView}>
        <View style={styles.innerView}>
          <View
            style={{
              borderWidth: 1,
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderColor: "#BDBDBD",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.deleteTitle}>Delete Account</Text>
          </View>
          <View style={{ paddingTop: 15, paddingLeft: 8 }}>
            <Text
              style={{
                textAlign: "center",
              }}
            >
              Are you sure you want to delete your account?
            </Text>
            <Text style={{ marginTop: 0, textAlign: "center" }}>
              If yes, enter your password to confirm.
            </Text>
          </View>
          <View
            style={{
              backgroundColor: "#BDBDBD",
              width: "100%",
              marginTop: 15,
              borderRadius: 5,
              alignSelf: "center",
            }}
          >
            <TextInput
              placeholder="Enter your password"
              secureTextEntry={true}
              onChangeText={(text) => confirmPasswordChage(text)}
              style={{
                height: 40,
                width: "100%",
                textAlign: "center",
              }}
            />
          </View>
          <Text
            style={[
              styles.warningText,
              {
                marginTop: 20,
                width: "80%",
                textAlign: "center",
              },
            ]}
          >
            Once deleted all your data and settings will be lost.
          </Text>

          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => confirmDelete()}
            style={{
              width: "100%",
              marginTop: 10,
              marginBottom: 5,
            }}
          >
            <Text
              style={{
                padding: 10,
                fontWeight: "bold",
                backgroundColor: "red",
                color: "white",
                width: "100%",
                borderRadius: 5,
                textAlign: "center",
              }}
            >
              Confirm{" "}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => onClose()}
            style={{
              width: "100%",
              marginTop: 2,
              marginBottom: 5,
            }}
          >
            <Text
              style={{
                padding: 10,
                fontWeight: "bold",
                backgroundColor: "#030135",
                textAlign: "center",
                width: "100%",
                color: "white",
                borderRadius: 5,
                marginBottom: 5,
              }}
            >
              Close{" "}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={() => {}}
    >
      <BlurBackground />
      {content()}
    </Modal>
  );
}

const styles = StyleSheet.create({
  deleteTitle: {
    fontSize: 18,
    padding: 17,
    paddingLeft: 8,
  },
  warningText: {
    fontFamily: family.semibold,
    fontSize: 15,
    marginBottom: 5,
  },
  mainView: {
    height: "100%",
    width: width,
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  innerView: {
    backgroundColor: "#F1F1F1",
    borderRadius: 10,
    width: isWeb() && !mobilBrower() ? "30%" : "90%",
    alignItems: "center",
    padding: 15,
    shadowColor: "white",
    shadowOffset: { width: 0, height: 0.5 },
    shadowOpacity: 0.4,
    shadowRadius: 5,
    elevation: 12,
    justifyContent: "center",
  },
});
