import React, { useState } from "react";
import {
  ScrollView,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  FlatList,
  Image,
} from "react-native";
import { styles } from "./languages.styles";
import Images from "../../../../../../common/images";
import { useSelector } from "react-redux";

const languageTemplate = {
  language: "",
  levelOfProficiency: "",
  read: false,
  write: false,
  speak: false,
};

export const Languages = ({ availableLangs = [], updateSettings, error }) => {
  const [levelDropdown, setLevelDropdown] = React.useState(false);
  const languages = useSelector(
    (state) => state.appStateReducer.occupationAndLanguageList?.languages
  );
  const [filteredLanguageList, setfilteredLanguageList] = useState([]);

  const onChange = (langIndex, paramKey, value) => {
    const filteredList = languages?.filter((item) => {
      const topicData = item.name;
      return topicData.indexOf(value) > -1;
    });
    setfilteredLanguageList(filteredList);

    let newArray = [...availableLangs];
    newArray[langIndex][paramKey] = value;
    updateSettings(newArray);
    value?.length == 0 && setfilteredLanguageList([]);
  };

  const handlePress = (langIndex, paramKey, item) => {
    let newArray = [...availableLangs];
    newArray[langIndex][paramKey] = item.name;
    updateSettings(newArray);
    setfilteredLanguageList([]);
  };

  const handleDelete = (index) => {
    let newArray = [...availableLangs];
    newArray.splice(index, 1);
    updateSettings(newArray);
  };

  React.useEffect(() => {
    if (!availableLangs.length) {
      updateSettings([...availableLangs, languageTemplate]);
    }
  }, []);

  return (
    <View
      style={{
        width: "90%",
        marginLeft: "5%",
        height:"100%"
      }}
    >
      <ScrollView
        style={{
          marginTop: "5%",
          height: "65%",
        }}
      >
        {availableLangs.map((prevSavedLang, index) => {
          return (
            <View
              style={{
                borderColor: "rgb(225,225,225)",
                borderRadius: 15,
                borderWidth: 1,
                padding: 15,
                marginBottom: 15,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <TouchableOpacity onPress={() => handleDelete(index)}>
                  <Text>✖</Text>
                </TouchableOpacity>
              </View>
              <Text style={[styles.sectionTitle]}>Language {index + 1}</Text>
              <View>
                <TextInput
                  onChangeText={(val) => onChange(index, "language", val)}
                  placeholder="Language Name"
                  textContentType="name"
                  value={prevSavedLang?.language}
                  style={[
                    styles.inputs,
                    error["language"]?.includes(index) && styles.errorView,
                  ]}
                />
                {filteredLanguageList?.length > 0 && (
                  <FlatList
                    data={filteredLanguageList}
                    style={styles.dropDownList}
                    renderItem={({ item }) => (
                      <TouchableOpacity
                        onPress={() => handlePress(index, "language", item)}
                        style={styles.dropDownItem}
                      >
                        <Text>{item.name}</Text>
                      </TouchableOpacity>
                    )}
                  />
                )}
              </View>
              <TouchableOpacity
                style={{ zIndex: -1 }}
                onPress={() => setLevelDropdown(levelDropdown ? false : index)}
              >
                <Text
                  style={[
                    styles.inputs,
                    error["levelOfProficiency"]?.includes(index) &&
                      styles.errorView,
                  ]}
                >
                  {prevSavedLang.levelOfProficiency
                    ? prevSavedLang.levelOfProficiency
                    : "Level of Proficiency"}
                </Text>
              </TouchableOpacity>
              {levelDropdown === index && (
                <View
                  style={{
                    marginBottom: 20,
                    zIndex: -1,
                    marginTop: -12,
                  }}
                >
                  {["Basic", "Intermediate", "Advanced"].map((e, pI) => {
                    return (
                      <TouchableOpacity
                        style={{
                          backgroundColor: "rgb(238,238,238)",
                          borderWidth: 1,
                          borderColor: "rgb(220,220,220)",
                          borderTopLeftRadius: pI === 0 ? 15 : 0,
                          borderTopRightRadius: pI === 0 ? 15 : 0,
                          borderBottomLeftRadius: pI === 2 ? 15 : 0,
                          borderBottomRightRadius: pI === 2 ? 15 : 0,
                        }}
                        onPress={() => {
                          onChange(index, "levelOfProficiency", e);
                          setLevelDropdown(false);
                        }}
                      >
                        <Text
                          style={{
                            padding: 15,
                            textAlign: "center",
                          }}
                        >
                          {e}
                        </Text>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              )}
              {["read", "write", "speak"].map((type) => {
                return (
                  <TouchableOpacity
                    onPress={() => onChange(index, type, !prevSavedLang[type])}
                    style={[styles.currentlyWorkingWrapper]}
                  >
                    <Image
                      style={styles.checkIcon}
                      source={
                        prevSavedLang[type]
                          ? Images.immipathSearchChecked
                          : Images.immipathSearchUnchecked
                      }
                    />
                    <Text style={[styles.text]}>{type}</Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          );
        })}
      </ScrollView>
      <TouchableOpacity
        onPress={() => updateSettings([...availableLangs, languageTemplate])}
        style={[
          {
            marginBottom: 10,
            borderRadius: 15,
          },
        ]}
      >
        <Text style={[styles.buttonText]}>+ Add Another Language</Text>
      </TouchableOpacity>
    </View>
  );
};
