import _ from 'lodash';
import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { styles } from "./CountrySelect.styles"

const CountrySelect = ({ countries = [], selectedCountries = [], containerStyle = {}, onCountrySelect = () => { } }) => {

    const getImage = (item) => {
        if (selectedCountries[item.code]) {
            return item.webImg;
        } else {
            return item.webFlag;
        }
    }

    const renderCountry = ({ key, item }) => (
        <TouchableOpacity
            key={key}
            style={styles.countryItem}
            onPress={() => onCountrySelect(item)}
        >
            <Image
                style={[styles.countryIcon, { opacity: selectedCountries[item.code] ? 1 : 0.6 }]}
                source={getImage(item)}
            />
            <Text
                style={styles.countryLabel}
            >
                {item.label}
            </Text>
        </TouchableOpacity>
    )

    return (
        <View style={[styles.countryView, containerStyle]}>
            <Text style={[styles.title, { marginBottom: 10 }]}>Select Country</Text>
            <View style={styles.countriesView}>
                {_.chunk(countries, 5).map((countriesChunk, index) => (
                    <View key={index + "c"} style={styles.countryRow}>
                        {countriesChunk.map((item, index) => (
                            renderCountry({ key: index + "s", item: item })
                        ))}
                    </View>
                ))}
            </View>
        </View>
    )
}

export default CountrySelect;