import React from "react";
import { View, Text, Modal, TouchableOpacity, Image } from "react-native";
import { Images, colors, helpers } from "../../common";
import { styles1 } from "../MainMedia/components/ActiveTab/components/Cards/cards.styles";
import { heightPercentageToDP } from "react-native-responsive-screen";
import { mobilBrower } from "../../common/helpers";
import { styles } from "./reactionModal.styles";
import { useSelector, useDispatch } from "react-redux";
import { setReactionModal } from "../../actions/mainMedia.actions";

export default function ReactionModal({ reaction_by_user }) {
  const rM = useSelector(
    (state) => state.mainMediaReducer.gState.reactionModal
  );
  const dispatch = useDispatch();

  const closeReactionModal = () =>
    dispatch(
      setReactionModal({
        active: false,
        params: {},
      })
    );

  return (
    <Modal
      animationType="fade"
      transparent
      visible={rM.active}
      onRequestClose={closeReactionModal}
    >
      <View
        onTouchStart={closeReactionModal}
        style={styles.reactionModalWrapper}
      >
        <View
          onTouchStart={(e) => e.stopPropagation()}
          style={styles.reactionModal}
        >
          <TouchableOpacity
            onPress={closeReactionModal}
            style={styles.closeIconButton}
            hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
          >
            <Image source={Images["closeBlack"]} style={styles.closeIcon} />
          </TouchableOpacity>

          <Text style={styles.reactionHeading}>Reactions </Text>
          <View style={styles.reactions}>
            {Object.entries(rM.params).length > 0 &&
              helpers?.emoji.map(
                (s, index) =>
                  Object.entries(rM.params)[index][1] != 0 && (
                    <TouchableOpacity
                      key={s.code}
                      //   onPress={() => this.handleReactionClick(item, s.code)}
                      style={[styles1.emoji]}
                    >
                      <Text
                        style={{
                          fontSize: mobilBrower()
                            ? heightPercentageToDP("1.6%")
                            : 18,
                          marginLeft: 5,
                        }}
                      >
                        {s.symbol}
                      </Text>
                      {rM.params && Object.entries(rM.params).length > 0 ? (
                        <Text
                          style={{
                            fontSize: mobilBrower()
                              ? heightPercentageToDP("1.6%")
                              : 17,
                          }}
                        >
                          {Object.entries(rM.params)[index][1]}
                        </Text>
                      ) : (
                        <View />
                      )}
                    </TouchableOpacity>
                  )
              )}
          </View>
        </View>
      </View>
    </Modal>
  );
}
