import React from "react";
import { styles as s } from "./sectionA.styles";
import { View, TouchableOpacity, Text, } from "react-native";

export const SectionA = ({ prevTrack, nextTrack, handleContinue, moveToShortListedScreen }) => {

  return (
    <View style={{ height: "100%" }}>
      <View style={[s.main]}>
        <Text style={[s.textT]}>LET'S GET TO KNOW YOU</Text>
        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
          <TouchableOpacity onPress={nextTrack} style={[s.nextWrapper]}>
            <Text style={[s.nextText]}>{previousSavePaths?.length > 0 ? 'New Search' : 'Next'}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
