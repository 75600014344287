import React from 'react'
import { Text, TouchableOpacity, Image } from 'react-native'
import { styles } from "./FactsTable.styles";
import Images from '../../common/images';

//Not used now 
// const renderPollutionImageComponent = (index, description) => {
//   console.log("ImageComo", index, description);

//   return(
//     <TouchableOpacity onPress={() => showPollutionAlert(value, description)}>
//       <Image source={require('../../images/pollution/index1.png')} style={styles.renderIndex} />
//       {/* {index === 1 ?
//           return <Image source={require('../../images/pollution/index1.png')} style={styles.renderIndex} /> :
//         index === 2 ? 
//          return <Image source={require('../../images/pollution/index2.png')} style={styles.renderIndex} /> :
//         index === 3 ? 
//          return <Image source={require('../../images/pollution/index3.png')} style={styles.renderIndex} /> :
//         index === 4 ? 
//          return <Image source={require('../../images/pollution/index1.png')} style={styles.renderIndex} />:
//         index === 5 ?
//          return <Image source={require('../../images/pollution/index1.png')} style={styles.renderIndex} />:
//         null
//       } */}
//     </TouchableOpacity>
//   )
// }

//Not used now
//  const renderTrafficImageComponent = (index, description) => {
//   return(
//     <TouchableOpacity onPress={() => showTrafficAlert(value, description)}>
//       <Image source={index === 1 ?
//         require('../../images/traffic/index3.png') :
//         index === 2 ? 
//         require('../../images/traffic/index3.png') :
//         index === 3 ? 
//         require('../../images/traffic/index5.png') :
//         index === 4 ? 
//         require('../../images/traffic/index3.png') :
//         index === 5 ?
//         require('../../images/traffic/index5.png') :
//         index === 6 ?
//         require('../../images/traffic/index5.png') :
//         require('../../images/traffic/index5.png')
//       } style={styles.renderIndex} />
//     </TouchableOpacity>
//   )
// }


export const renderPollutionIndex = (value, onPress = () => { }) => {
  //somehow creating particular component is not working so i am doing like this
  if (value <= 50) {
    return (
      <TouchableOpacity style={styles.renderIndex} onPress={() => onPress('Good. Air quality is considered satisfactory, and air pollution poses little or no risk None')}>
        <Image source={Images.pi1} style={styles.renderPollutionImg} />
      </TouchableOpacity>
    )
  } else if (value > 50 && value <= 100) {
    return (
      <TouchableOpacity style={styles.renderIndex} onPress={() => onPress('Moderate. Air quality is acceptable; however, for some pollutants there may be a moderate health concern for a very small number of people who are unusually sensitive to air pollution. Active children and adults, and people with respiratory disease, such as asthma, should limit prolonged outdoor exertion.')}>
        <Image source={Images.pi2} style={styles.renderPollutionImg} />
      </TouchableOpacity>
    )
  } else if (value > 100 && value <= 150) {
    return (
      <TouchableOpacity style={styles.renderIndex} onPress={() => onPress(' Unhealthy for Sensitive Groups. Members of sensitive groups may experience health effects. The general public is not likely to be affected. Active children and adults, and people with respiratory disease, such as asthma, should limit prolonged outdoor exertion.')}>
        <Image source={Images.pi3} style={styles.renderPollutionImg} />
      </TouchableOpacity>
    )
  } else if (value > 150 && value <= 200) {
    return (
      <TouchableOpacity style={styles.renderIndex} onPress={() => onPress(' Unhealthy. Everyone may begin to experience health effects; members of sensitive groups may experience more serious health effects Active children and adults, and people with respiratory disease, such as asthma, should avoid prolonged outdoor exertion; everyone else, especially children, should limit prolonged outdoor exertion')}>
        <Image source={Images.pi4} style={styles.renderPollutionImg} />
      </TouchableOpacity>
    )
  } else if (value > 200 && value <= 300) {
    return (
      <TouchableOpacity style={styles.renderIndex} onPress={() => onPress('Very Unhealthy. Health warnings of emergency conditions. The entire population is more likely to be affected. Active children and adults, and people with respiratory disease, such as asthma, should avoid all outdoor exertion; everyone else, especially children, should limit outdoor exertion.')}>
        <Image source={Images.pi5} style={styles.renderPollutionImg} />
      </TouchableOpacity>
    )
  } else if (value > 300) {
    return (
      <TouchableOpacity style={styles.renderIndex} onPress={() => onPress('Hazardous Health alert: everyone may experience more serious health effects Everyone should avoid all outdor.')}>
        <Image source={Images.pi5} style={styles.renderPollutionImg} />
      </TouchableOpacity>
    )
  }
}

export const renderTrafficIndex = (value, onPress = () => { }) => {
  if (value <= 100) {
    return (
      <TouchableOpacity style={[styles.renderIndex,]} onPress={() => onPress('Low traffic')}>
        <Image source={Images.indexLow} style={styles.renderIndex} />
      </TouchableOpacity>
    )
  } else if (value > 100 && value <= 200) {
    return (
      <TouchableOpacity style={[styles.renderIndex,]} onPress={() => onPress('Moderate traffic')}>
        <Image source={Images.indexMed} style={styles.renderIndex} />
      </TouchableOpacity>
    )
  } else if (value > 200) {
    return (
      <TouchableOpacity style={[styles.renderIndex,]} onPress={() => onPress('High traffic')}>
        <Image source={Images.indexHeigh} style={styles.renderIndex} />
      </TouchableOpacity>
    )
  }
}

export const renderHealthCareIndex = (value, onPress = () => { }) => {
  if (value <= 32) {
    return (
      <TouchableOpacity style={[styles.renderIndex,]} onPress={() => onPress('Low health care')}>
        <Image source={Images.indexLow} style={styles.renderIndex} />
      </TouchableOpacity>
    )
  } else if (value > 33 && value <= 66) {
    return (
      <TouchableOpacity style={[styles.renderIndex,]} onPress={() => onPress('Moderate health care')}>
        <Image source={Images.indexMed} style={styles.renderIndex} />
      </TouchableOpacity>
    )
  } else if (value > 66) {
    return (
      <TouchableOpacity style={[styles.renderIndex,]} onPress={() => onPress('High health care')}>
        <Image source={Images.indexHeigh} style={styles.renderIndex} />
      </TouchableOpacity>
    )
  }
}

export const buisnessFriendlinessIndex = (value) => {
  if (value <= 53) {
    return (
      <Text style={styles.tableContentText}>
        Very Easy
      </Text>
    )
  } else if (value > 53 && value <= 97) {
    return (
      <Text style={styles.tableContentText}>
        Easy
      </Text>
    )
  } else if (value > 97 && value <= 147) {
    return (
      <Text style={styles.tableContentText}>
        Medium
      </Text>
    )
  } else if (value > 147 && value <= 190) {
    return (
      <Text style={styles.tableContentText}>
        Below Average
      </Text>
    )
  }
}

export const renderCrimeIndex = (value, onPress = () => { }) => {
  if (value < 40) {
    return (
      <TouchableOpacity style={[styles.renderIndex]} onPress={() => onPress('Low crime')}>
        <Image source={Images.indexLow} style={styles.renderIndex} />
      </TouchableOpacity>
    )
  } else if (value >= 40 && value < 60) {
    return (
      <TouchableOpacity style={[styles.renderIndex]} onPress={() => onPress('Moderate crime')}>
        <Image source={Images.indexMed} style={styles.renderIndex} />
      </TouchableOpacity>
    )
  } else if (value >= 60) {
    return (
      <TouchableOpacity style={[styles.renderIndex]} onPress={() => onPress('High crime')}>
        <Image source={Images.indexHeigh} style={styles.renderIndex} />
      </TouchableOpacity>
    )
  }
}
