import React from "react";
import {
  View,
  TouchableOpacity,
  Text,
  FlatList,
  Image,
  Linking,
  Alert,
  ActivityIndicator,
} from "react-native";
import { Images } from "../../../../common";
import { useSelector } from "react-redux";
import Share from "react-native-share";
import * as Animatable from "react-native-animatable";
import { heightPercentageToDP } from "../../../../common/responsiveScreen";
import { styles } from "./Articles.styles"

const ArticleComponent = (props) => {
  const saveArticle_isLoading = useSelector(
    (state) => state.userReducer.getSavedNews.saveArticle_isLoading
  );
  const showAlert1 = (item) => {
    Alert.alert("All Tags", item.tags.toString(), [
      { text: "OK", onPress: () => console.log("OK Pressed") },
    ]);
  };

  const _rendershareContentNews = (item) => {
    shareOptions = {
      title: "News from Immigreat!",
      message: "Here's a news shared from Immigreat for you: ",
      url: item.link,
      subject: "News from Immigreat!", //  for email
    };
    Share.open(shareOptions);
  };

  const renderSavedItem = (item, index) => {
    var tagsArray = item.tags.split(",");
    return (
      <Animatable.View
        useNativeDriver={true}
        animation="fadeIn"
        duration={1200}
        style={[
          styles.saveItemMainView,
          {
            marginBottom: index === props.savedNews.length - 1 ? 55 : 0,
          },
        ]}
      >
        <View style={styles.imgView}>
          <Image
            source={{
              uri: item.image_link
                ? item.image_link
                : item.source_image
                  ? item.source_image
                  : "https://images.unsplash.com/photo-1576078361289-d7c4da40e7cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
            }}
            style={styles.imgStyle}
          />
        </View>
        <View style={styles.contentView}>
          <View style={styles.tagView}>
            {tagsArray?.length > 0 ? (
              <FlatList
                data={tagsArray}
                horizontal
                showsHorizontalScrollIndicator={false}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    //onPress={() => this.props.showAlert1(item)}
                    style={styles.tagContainer}
                  >
                    <Text
                      style={styles.tagText}
                    >
                      {item}
                    </Text>
                  </TouchableOpacity>
                )}
              />
            ) : (
              <View></View>
            )}
          </View>
          <View>
          <Text
            style={styles.title}
            numberOfLines={3}
          >
            {item.title}
          </Text>
          </View>
          <View
            style={[
              styles.tagView,
              {
                marginTop: heightPercentageToDP("1.2%"),
                width: "80%",
                justifyContent: "space-between",
              },
            ]}
          >
            <TouchableOpacity
              onPress={() => Linking.openURL(item.link)}
              style={styles.readMoreButton}
            >
              <Text style={styles.readMoreButtonText}>Read More</Text>
            </TouchableOpacity>
            {
              <View style={styles.shareView}>
                <TouchableOpacity
                  onPress={() => props.removeArticleAsFavorite(item)}
                >
                  <Image
                    source={Images.redheart}
                    style={styles.faviconStyle}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => _rendershareContentNews(item)}>
                  <Image
                    source={Images.shareNews}
                    style={[
                      styles.faviconStyle,
                      { marginLeft: 10 },
                    ]}
                  />
                </TouchableOpacity>
              </View>
            }
          </View>
          <View
            style={[
              styles.tagView,
              {
                marginTop: heightPercentageToDP("1%"),
                width: "80%",
                marginBottom: heightPercentageToDP("2%"),
              },
            ]}
          >
            <View>
              <Text style={[styles.dateText, { color: "black" }]}>
                {item.date_published?.slice(0, 16)}
              </Text>
              <Text style={[styles.dateText, { color: "black", marginTop: 2 }]}>
                {item.source}
              </Text>
            </View>
          </View>
        </View>
      </Animatable.View>
    );
  };
  return (
    <View style={{ flex: 1 }}>
      <FlatList
        style={{ marginBottom: 4, marginTop: 10 }}
        data={props.savedNews}
        ListEmptyComponent={() => {
          return (
            <TouchableOpacity
              activeOpacity={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: heightPercentageToDP(38),
                width: "90%",
              }}
            >
              <Text
                style={{
                  color: "black",
                }}
              >
                No Data Available
              </Text>
            </TouchableOpacity>
          );
        }}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => {
          return (
            <TouchableOpacity activeOpacity={1}>
              {renderSavedItem(item, index)}
            </TouchableOpacity>
          );
        }}
      />
      {saveArticle_isLoading ? (
        <View style={styles.spinner}>
          <ActivityIndicator size="large" color="#030135" />
        </View>
      ) : (
        <View />
      )}
    </View>
  );
};

export default ArticleComponent;
