import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  Image,
  ActivityIndicator,
  Modal,
} from "react-native";
import { Images } from "../../../../common";
import { styles } from "./home.styles";
import { SetupProfileModal } from "../SetupProfileModal/SetupProfileModal";
import { BulletsWrapper } from "./Components/BulletsWrapper/BulletsWrapper";
import { QLanguages } from "./Components/Languages/QLanguages";
import { QOccupations } from "./Components/Occupations/QOccupations";
import { useSelector } from "react-redux";
import { CircleProgress } from "./Components/AnimatedCircle/CircleProgress";
import { isWeb, mobilBrower } from "../../../../common/helpers";
import BlurBackground from "../../../BlurBackground";
import { isEmpty, isArray } from "lodash";

export const Home = () => {
  const [profileProgress, setProfileProgress] = React.useState();
  const [progressPercentage, setProgressPercentage] = React.useState(0);
  const [showHelpModal, setShowHelpModal] = React.useState(false);

  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let obj = {
      interests: {
        title: "Interests",
        icons: Images.interestIcon,
        response: [],
      },
      occupations: {
        title: "Occupations",
        icons: Images.occupationIcon,
        response: [],
      },
      languages: {
        title: "Languages",
        icons: Images.languagesIcon,
        response: [],
      },
      lifestyles: {
        title: "Lifestyles",
        icons: Images.lifestylesIcon,
        response: [],
      },
    };

    if (userDetails?.interests)  obj.interests.response = isArray(userDetails?.interests) ? userDetails?.interests : Object.values(userDetails?.interests);


    if (userDetails?.lifestyles)
      obj.lifestyles.response = isArray(userDetails?.lifestyles) ? userDetails?.lifestyles : Object.values(userDetails?.lifestyles);

    if (userDetails?.languages)
      obj.languages.response = Object.values(userDetails.languages);

    if (userDetails?.occupations)
      obj.occupations.response = Object.values(userDetails.occupations);

    setLoading(false);
    setProfileProgress(obj);
  }, []);

  const [expandedMenu, setExpandedMenu] = React.useState(
    "InterestsOccupationsLanguagesLifestyles"
  );

  const [visible, setVisible] = React.useState({
    active: false,
    track: null,
  });

  const expandOptions = (option) => {
    if (expandedMenu.includes(option)) {
      setExpandedMenu(expandedMenu.replace(option, ""));
    } else {
      setExpandedMenu(expandedMenu + option);
    }
  };

  const getProgressPercentage = () => {
    let percentage = 0;

    const editProfileMap = {
      first_name: userDetails?.first_name,
      last_name: userDetails?.last_name,
      country_of_birth: userDetails?.country_of_birth,
      date_of_birth: userDetails?.date_of_birth,
      email_address: userDetails?.email_address,
      highest_academic_achievement: userDetails?.highest_academic_achievement,
      countries_of_interest: userDetails?.countries_of_interest,
      immigration_interests: userDetails?.immigration_interests,
      sex: userDetails?.sex,
      country_of_residency: userDetails?.country_of_residency,
      interests: profileProgress?.interests?.response?.length,
      occupations: profileProgress?.occupations?.response?.length,
      languages: profileProgress?.languages?.response?.length,
      lifestyles: profileProgress?.lifestyles?.response?.length,
      marital_status: userDetails?.marital_status,
      phone: userDetails?.phone,
      lifestyles: !isEmpty(profileProgress?.lifestyles?.response) ? Object.entries(profileProgress?.lifestyles?.response).length : null,
      current_stage: userDetails?.current_stage
    };

    const objArr = Object.entries(editProfileMap);

    objArr.map((e) => {
      if (e[1]) {
        if (Array.isArray(e[1])) {
          if (e[1]?.length) percentage = percentage + 1 / objArr.length;
        } else {
          percentage = percentage + 1 / objArr.length;
        }
      }
    });

    setProgressPercentage(percentage * 100);
  };

  React.useEffect(() => {
    getProgressPercentage();
  }, [userDetails, profileProgress]);

  return loading ? (
    <ActivityIndicator />
  ) : (
    <TouchableOpacity
      activeOpacity={1}
      disabled={isWeb() && !mobilBrower()}
      style={{
        marginBottom: 15,
        flex: 1
      }}
    >
      {visible.active && (
        <SetupProfileModal
          visible={visible}
          setVisible={setVisible}
          profileProgress={profileProgress}
          setProfileProgress={setProfileProgress}
        />
      )}

      <View style={{ flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center" }}>
        <Text style={[styles.mainHeadText]}>Profile Overview</Text>
        <TouchableOpacity onPress={() => setShowHelpModal(true)} style={{ marginLeft: 10 }}>
          <Image source={Images.help} style={{ width: 22, height: 22, resizeMode: "contain" }} />
        </TouchableOpacity>
      </View>
      <CircleProgress progressPercentage={progressPercentage} />
      <TouchableOpacity
        onPress={() =>
          setVisible({
            active: true,
            track: null,
          })
        }
        style={[styles.setupButton]}
      >
        <Text style={[styles.setupButtonText]}>
          {progressPercentage === 100 ? "Update" : "Setup"} your profile
        </Text>
      </TouchableOpacity>
      <FlatList
        data={Object.values(profileProgress)}
        renderItem={({ item, index }) => (
          <View>
            <View style={[styles.listItem]}>
              <View style={[styles.infoWrapper]}>
                <Image
                  style={{
                    height: 45,
                    width: 45,
                  }}
                  resizeMode="contain"
                  source={item.icons}
                />
                <Text style={[styles.itemTitle]}>{item.title}</Text>
              </View>
              <View style={[styles.expansionWrapper]}>
                {item.response.length != 0 && (
                  <Text style={[styles.countText]}>{item.response.length}</Text>
                )}
                <TouchableOpacity
                  onPress={() =>
                    item.response.length === 0
                      ? setVisible({
                        active: true,
                        track: index,
                      })
                      : expandOptions(item.title)
                  }
                  style={[styles.expandButton]}
                >
                  <Image
                    style={{
                      height: 35,
                      width: 35,
                      transform: [
                        {
                          rotate:
                            !expandedMenu.includes(item.title) &&
                              item.response.length != 0
                              ? "180deg"
                              : "0deg",
                        },
                      ],
                    }}
                    resizeMode="contain"
                    source={
                      item.response.length === 0
                        ? Images.expandIcon
                        : Images.collapseIcon
                    }
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                marginBottom: 25,
              }}
            >
              {index === 0 && expandedMenu.includes(item.title) && (
                <BulletsWrapper arr={item.response} />
              )}
              {index === 1 && expandedMenu.includes(item.title) && (
                <QOccupations occupations={item.response} />
              )}
              {index === 2 && expandedMenu.includes(item.title) && (
                <QLanguages languages={item.response} />
              )}
              {index === 3 && expandedMenu.includes(item.title) && (
                <BulletsWrapper arr={item.response} />
              )}
            </View>
            {item.response.length > 0 && expandedMenu.includes(item.title) && (
              <TouchableOpacity
                onPress={() =>
                  setVisible({
                    active: true,
                    track: index,
                  })
                }
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: -35,
                  marginBottom: 15,
                }}
              >
                <Image
                  source={Images.editIcon}
                  style={{
                    height: 35,
                    width: 35,
                    borderRadius: 35,
                  }}
                />
              </TouchableOpacity>
            )}
          </View>
        )}
      />
      {showHelpModal ? (
        <Modal visible={true} transparent>
          <BlurBackground />
          <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <View style={styles.helpModal}>
              <View style={{ flexDirection: "row", marginBottom: 10 }}>
                <Text style={styles.helpTitle}>What's this for?</Text>
                <TouchableOpacity onPress={() => setShowHelpModal(false)}>
                  <Image source={Images.closeBlack} style={{ height: 22, width: 22 }} />
                </TouchableOpacity>
              </View>
              <Text style={styles.note}>{'\n'}The information on your profile will be used to personalize your experience on the app.{'\n'}</Text>
              <Text style={styles.note}>This public profile will be visible to other users in near future. This is our first step in making Immigreat a community-based/social platform!{'\n'}</Text>
              <Text style={styles.note}>We will also use this information to recommend opportunities and solutions.{'\n'}</Text>
            </View>
          </View>
        </Modal>
      ) : null}
    </TouchableOpacity>
  );
};
