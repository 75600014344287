import React from 'react'
import {
    Text, View, TouchableOpacity, Dimensions, Image, Platform, useWindowDimensions
} from "react-native";
import { isWeb, mobilBrower } from '../../common/helpers';
import { Images, Config } from "../../common";
import { useSelector } from "react-redux";
import { take } from 'lodash';
import NavigationService from '../../navigation/NavigationService';
import { styles } from './ShortListItem.style';

const { width } = Dimensions.get('window')

export default function ShortListItem({ item, navigation ,history}) {
    const window = Platform.OS == 'web' ? useWindowDimensions() : null;
    const guestLogin = useSelector(state => state.pathReducer.getImmipathDetails.guestLogin);
    const getCountryName = (id) => {
        let country = Config.intrestcountry.filter((s) => s.code == id);
        return country[0]?.value;
    }
    const getGoalNames = (id) => {
        if (id === 1) {
            return 'study'
        } else if (id === 2) {
            return 'work'
        } else if (id === 3) {
            return 'residency'
        } else if (id === 4) {
            return 'tourism'
        }
    }

    const handleLogin = () => {
        isWeb()?history.push('/login'):
        NavigationService.navigate('Login')
    }

    const getImage = (id) => {
        let image = '';
        switch (id) {
            case 1:
                image = Images.usFlag
                break;
            case 2:
                image = Images.CANADAFlag
                break;
            case 3:
                image = Images.Ausflag
                break;
            case 4:
                image = Images.singaporeFlag
                break;
            case 5:
                image = Images.UKFLag
                break;
            case 6:
                image = Images.UAEFLAG
                break;
            case 7:
                image = Images.GermanyFlag
                break;
            case 8:
                image = Images.netherlanFlag
                break;
            case 9:
                image = Images.NZFlag
                break;
            case 9:
                image = Images.JapanFlag
                break;
            default:
                break;
        }
        return image
    }

    const handleButtonPress = () => {
        isWeb()?history.push('profile',{ app_ref: item?.application_id, selectedPath: item?.immipath_data?.immipath_id }):
        NavigationService.navigate('NewProfileScreen', { app_ref: item?.application_id, selectedPath: item?.immipath_data?.immipath_id })
    }

    return (
        <View style={[styles.newItem, { width: Platform.OS == 'web' && !mobilBrower() ? window.width / 1.7 : width / 1.1 }]}>
            <View style={{ width: '50%',height:"100%" }}>
                <Image
                    source={guestLogin ? Images.immilock : getImage(item?.immipath_data?.country_id)}
                    style={styles.newImgStyle} />
            </View>
            {
                guestLogin ?
                    <View style={[{ justifyContent:"space-between",height:"100%",padding:"4%",width:"50%" }]}>
                        <Text style={styles.guestText}>
                            You must be an Immigreat Web user to access this mode.
                        </Text>
                        <Text style={[styles.guestText]}>
                            Login/Sign Up for free!
                        </Text>
                        <TouchableOpacity onPress={handleLogin} style={[{
                            width: '100%',
                            alignItems: 'center',
                            backgroundColor: '#F1AB32',
                            justifyContent: 'center',
                            padding:8,
                            }]}>
                            <Text style={styles.takeViewText}>
                                Login/Sign Up
                            </Text>
                        </TouchableOpacity>
                    </View>
                    :
                    <View style={styles.newContent}>
                        <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                            {
                                take(item?.goal_ids, 2)?.map((s, i) => (
                                    <View key={"t"+i} style={[styles.tagView, { marginLeft: i == 0 ? 0 : 10 }]}>
                                        <Text style={styles.tagText}>
                                            {getGoalNames(s)}
                                        </Text>
                                    </View>
                                ))
                            }
                        </View>
                        <Text style={styles.title}>
                            {item?.path_descriptor}
                        </Text>
                        <Text style={styles.details}>
                            {getCountryName(item?.immipath_data?.country_id)}
                        </Text>
                        <TouchableOpacity onPress={() => handleButtonPress()} style={styles.takeViewButton}>
                            <Text style={styles.takeViewText}>
                                View
                            </Text>
                        </TouchableOpacity>
                    </View>
            }
        </View>
    )
}
