import { StyleSheet } from "react-native";
import { colors, family } from "../../../../common";
import { mobilBrower } from "../../../../common/helpers";

export const multiSelectStyle = {
  item: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  container: {
    width: !mobilBrower() ? "50%" : "90%",
    alignSelf: "center",
  },
  itemText: {
    color: "black",
    fontWeight: "400",
  },
  subItem: {
    color: "grey",
    paddingHorizontal: 10,
    height: "100%"
  },
  selectedItem: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  selectedSubItem: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  selectToggle: {
    justifyContent: "space-between",
  },
  chipsWrapper: {
    backgroundColor: "yellow",
  },
  selectToggleText: {
    color: "black",
    fontSize: 14,
  },
  subSeparator: {
    backgroundColor: "transparent"
  },
  button: {
    backgroundColor: "#030135",
    flex: 1
  },
  scrollView: { paddingHorizontal: 0, flex: 1 },
};

export const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexDirection: "row"
  },
  contentContainer: {
    flex: 1,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 20,
    paddingHorizontal: 15
  },
  nextWrapper: {
    backgroundColor: "rgb(15, 17, 67)",
    padding: 12,
    borderRadius: 25,
    width: 160,
    marginTop: 10,
  },
  nextText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 17,
  },
  textT: {
    // marginTop: -15,
    fontSize: 25,
    fontWeight: "bold"
  },
  messageWrapper: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
  },
  message: {
    color: "white",
    flex: 1
  },
  messageTime: {
    color: "rgb(180,180,180)",
    marginTop: 15,
    fontSize: 12,
  },
  options: {
    color: "black",
  },
  optionTime: {
    color: "rgb(140,140,140)",
    marginTop: 15,
    fontSize: 12,
  },
  messageVisibleView: {
    width: "80%",
    borderRadius: 10,
    padding: 15,
    marginLeft: -10,
  },
  checkIcon: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
  nextBtnContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: mobilBrower() ? "100%" : "90%"
  },
  qtnContainer: {
    width: "100%",
    height: "100%",
  },
  listTextViewStyle: {
    color: "#000",
    marginVertical: 10,
    flex: 0.9,
    marginLeft: 20,
    marginHorizontal: 10,
    textAlign: "left",
  },
  selectLabelTextStyle: {
    color: "#000",
    textAlign: "left",
    width: "99%",
    padding: 0,
    flexDirection: "row",
  },
  pickerStyle: {
    elevation: 3,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 13,
    height: "auto",
  },
  pickerListContainer: {
    width: mobilBrower() ? "90%" : "50%"
  },
  itemListStyle: {
    flex: 1,
    overflowY: "auto"
  },
  mainView: {
    marginTop: 5,
  },
  summaryView: {
    borderWidth: 0.5,
    alignItems: 'center',
    borderRadius: 10,
    borderColor: '#E0DEEA',
    backgroundColor: 'white',
    justifyContent: 'center',
    width: '95%',
    alignSelf: 'center',
    padding: 15
  },
  separator: {
    borderTopWidth: 0.1,
    borderColor: '#E0DEEA',
    width: '95%',
  },
  summaryText: {
    alignSelf: 'flex-start',
    marginLeft: 10,
    padding: 10,
    fontSize: 20,
    fontFamily: family.robotoBold
  },
  innerRow: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    width: '95%',
    alignSelf: 'center',
    justifyContent: 'space-between'
  },
  label: {
    color: '#41435E',
    fontSize: 15
  },
  price: {
    color: '#41435E',
    fontSize: 18
  },
  coupounView: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '95%',
    marginTop: 10,
    alignSelf: 'center',
    padding: 10
  },
  havecodeText: {
    fontSize: 20,
    fontFamily: family.robotoBlack
  },
  couponTextboxWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 15
  },
  applyButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.IMMIGREAT_YELLOW,
    borderRadius: 5
  },
  applyText: {
    padding: 5,
    paddingHorizontal: 15,
    color: 'white',
    fontFamily: family.robotoBold,
  },
  continueButton: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: 20,
    backgroundColor: colors.IMMIGREAT_YELLOW,
    borderRadius: 5,
    width: '50%'
  },
  continueText: {
    padding: 10,
    fontFamily: family.robotoBold,
    paddingHorizontal: 15, 
    fontSize: 18,
    color: 'white'
  }
});
