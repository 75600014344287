import { StyleSheet } from "react-native";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";

export const styles = StyleSheet.create({
    countryView: {
        width: mobilBrower() ? "100%" : "45%",
        alignItems: "center",
        marginVertical: 15
    },
    title: {
        fontSize: responsiveText(20),
        fontWeight: "bold",
        marginBottom: 20,
    },
    countriesView: {
        flex: 1,
        alignItems: "center",
        width: "100%",
        justifyContent: "center"
    },
    countryRow: {
        width: "100%",
        flexDirection: "row",
        marginTop: 10
    },
    countryItem: {
        height: 80,
        flex: 1,
        alignItems: "center",
    },
    countryIcon: {
        height: 50,
        aspectRatio: 1,
        resizeMode: "contain",
        alignSelf: "center",
    },
    countryLabel: {
        fontSize: responsiveText(8),
        textAlign: "center",
        fontWeight: "700",
    }
})