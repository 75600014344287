import { StyleSheet, Dimensions, StatusBar, Platform } from "react-native";
import { colors } from "../../common";
import { mobilBrower } from "../../common/helpers";
import fontFamily from "../../common/fontFamily";
import { heightPercentageToDP } from "../../common/responsiveScreen";

const { width } = Dimensions.get("window");

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    marginBottom: 10,
  },
  backButton: {
    top: "14%",
    left: 25,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: 9999,
  },
  welcomeView: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
  },
  welcomeText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  subview: {
    justifyContent: "center",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
  },
  subtext1: {
    fontSize: 15,
    marginBottom: 5,
    lineHeight: 15 * 1.2,
  },
  subtext2: {
    fontSize: 15,
  },
  disableLoginView: {
    marginTop: 20,
    backgroundColor: "#fff",
    width: width / 1.1,
    height: 100,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    marginLeft: 16,
  },
  enableLoginView: {
    marginTop: 20,
    backgroundColor: "lightgrey",
    width: width / 1.1,
    height: 100,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    marginLeft: 16,
  },
  textInputStyle: {
    marginLeft: 10,
    width: "95%",
    marginTop: 5,
    fontSize: heightPercentageToDP("1.5%")
  },
  contentView: {
    width: !mobilBrower() ? "50%" : "90%",
    flexDirection: !mobilBrower() ? "row" : "column",
    backgroundColor: "white",
    marginTop: 65,
    alignSelf: "center",
    alignItems: "center",
  },
  rightView: {
    flex: 1,
  },
  socialLinkText: {
    alignSelf: "center",
    color: "white",
    marginTop: heightPercentageToDP("4%"),
    marginBottom: !mobilBrower() ? 0 : heightPercentageToDP("2%"),
    fontFamily: fontFamily.semibold,
    fontSize: heightPercentageToDP("3%"),
  },
  socialLinkView: {
    flexDirection: "row",
    alignItems: "center",
    width: "60%",
    justifyContent: "center",
    alignSelf: "center",
  },
  socailIconStyles: {
    height: !mobilBrower() ? heightPercentageToDP("5%") : 40,
    width: !mobilBrower() ? heightPercentageToDP("5%") : 40,
    resizeMode: "contain",
  },
  socailbuttons: {
    margin: !mobilBrower() ? heightPercentageToDP("2%") : 10,
  },
  inputBox: {
    width: !mobilBrower() ? "20%" : "70%",
    borderColor: "white",
    alignItems: "center",
    flexDirection: "row",
    paddingBottom: 0,
    marginRight: 10,
    borderBottomWidth: 1,
    alignSelf: "center",
   
  },
  textArea: {
    width: !mobilBrower() ? "35%" : "90%",
    height:!mobilBrower()
        ? heightPercentageToDP("12%")
        : heightPercentageToDP("15%"),
    borderRadius: 10,
    backgroundColor: "white",
    alignSelf: "center",
    marginTop: 20,
  },
  submitButton: {
    height: 40,
    width: !mobilBrower() ? "15%" : "35%",
    marginTop: 20,
    marginBottom: heightPercentageToDP("8%"),
    alignSelf: "center",
    backgroundColor: "#F1AB32",
    alignItems: "center",
    justifyContent: "center",
  },
  submitText: {
    color: "white",
    fontFamily: fontFamily.semibold,
    fontSize: heightPercentageToDP("1.8%"),
  },
  inputIcon: {
    width: 20,
    height: 20,
  },
  aboutImg1: {
    height: !mobilBrower()
        ? heightPercentageToDP("33%")
        : heightPercentageToDP("25%"),
    aspectRatio: 1,
    marginTop: -40,
    resizeMode: "contain",
  },
  description: {
    textAlign: !mobilBrower() ? "left" : "center",
    marginBottom: 30,
    width: "85%",
    fontSize: heightPercentageToDP("2%"),
    marginTop: heightPercentageToDP("2%"),
  },
  title: {
    fontFamily: fontFamily.effra,
    fontSize: heightPercentageToDP("3.5%"),
  },
  button: {
    backgroundColor: colors.LIGHT_BLUE,
    borderRadius: 25,
    justifyContent: "center",
    alignSelf: "center",
    width: width * 0.6,
    height: 44,
    alignItems: "center",
    marginTop: 18,
    marginBottom: 80,
    padding: 5,
  },
  buttonText: {
    fontSize: 18,
    color: "#ffffff",
    textAlign: "center",
    justifyContent: "center",
  },
  enableCategoryStyle: {
    marginTop: 15,
    width: "100%",
    height: 60,
    backgroundColor: "transparent",
    paddingRight: 10,
    paddingLeft: 15,
  },
  pickerStyle: {
    borderRadius: 30,
    width: "90%",
    paddingLeft: 15,
    marginLeft: 10,
  },
  statusBar: {
    height: Platform.OS === "ios" ? 40 : StatusBar.currentHeight,
    backgroundColor: colors.LIGHT_BLUE,
  },
  placeholder:{
    color: "black",
    fontSize: heightPercentageToDP("1.5%"),
  },
  dropDownContainer:{
    width: "100%",
    marginTop: !mobilBrower() ? 10 : 30,
    alignSelf: "center",
  }
});
