import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { Images } from "../../../../common";
import fontFamily from "../../../../common/fontFamily";

export const Categories = ({ handleCategory }) => {
  const categories = [
    {
      label: "study",
      wPercentage: "80%",
      pPercentage: 0.6,
      color: "#FF3434",
    },
    {
      label: "work",
      wPercentage: "85%",
      pPercentage: 0.75,
      color: "#33AC09",
    },
    {
      label: "permanent residency",
      wPercentage: "90%",
      pPercentage: 1,
      color: "#0F1143",
    },
    {
      label: "tourism",
      wPercentage: "85%",
      pPercentage: 0.75,
      color: "#F1AB32",
    },
    {
      label: "legal",
      wPercentage: "80%",
      pPercentage: 0.6,
      color: "#5A9BFE",
    },
  ];

  return (
    <View
      style={{
        width: "90%",
        marginLeft: "5%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          fontFamily: fontFamily.titilliumBold,
          fontSize: 18,
          color: "gray",
        }}
      >
        Browse Categories{" "}
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 20,
          width: "100%"
        }}
      >
        {categories.map(({ wPercentage, label, color, pPercentage }) => {
          return (
            <TouchableOpacity
              onPress={() => handleCategory(label)}
              style={{
                width: wPercentage,
                backgroundColor: color,
                padding: 25 * pPercentage,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontFamily: fontFamily.bold,
                  fontSize: 16,
                  textTransform: "capitalize",
                }}
              >
                {label}
              </Text>
              <Image
                source={Images.moreDiscussionIcon}
                style={{
                  height: 40,
                  width: 40,
                }}
              />
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};
