import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  Platform,
} from "react-native";
import { Images } from "../../../common";
import { TrailFromRight } from "../newSignupUi.animation";
import { mobilBrower } from "../../../common/helpers";


const SignUpSection3 = ({ styles, avatarPicker, state, handleSubmit }) => {
  return (
    <View style={[styles.slide1 ]}>
      <TrailFromRight>
        <View style={{ alignItems: "center" ,width:"80%"}}>
          <Text style={[styles.title, { marginTop: 20 }]}>One last step!</Text>
          <Text
            style={[
              styles.titlchoosePictitlee,
              {
                marginTop: 5,
                marginVertical:Platform.OS == "web" && !mobilBrower() ? "15%" :0,
                width: "50%",
                textAlign: "center",
              },
            ]}
          >
            Choose a profile picture if you’d like to!
          </Text>
          <View style={styles.imageview}>
            <View style={[styles.commonAnimationWrap]}>
              <TouchableOpacity testID="userImg" onPress={avatarPicker}>
                <ImageBackground
                  borderRadius={90}
                  style={styles.mailImage}
                  source={
                    state.avatarSource
                      ? { uri: `${state.avatarSource?.dataUrl}` }
                      : Images.uploadPicImg
                  }
                />
              </TouchableOpacity>
            </View>
          </View>
          <View style={[styles.buttonView, {}]}>
            <TouchableOpacity
              onPress={handleSubmit}
              style={[styles.nextButton]}
            >
              <Text style={styles.nextText}>Finish</Text>
            </TouchableOpacity>
          </View>
        </View>
      </TrailFromRight>
    </View>
  );
};

export default SignUpSection3;
