import React, { useEffect, useState } from "react";
import {
    Text,
    View,
    TouchableOpacity,
    Image,
    TextInput,
    Platform,
} from "react-native";
import { heightPercentageToDP } from "react-native-responsive-screen";
import { sha256 } from "react-native-sha256";
import {
    onFacebookButtonPressHelper,
    onGoogleButtonPressHelper,
    onAppleButtonPressHealper,
} from "../../../common/helpers";
import { Images } from "../../../common";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../actions/auth.actions";
import { GoogleSignin } from "@react-native-community/google-signin";
import Loader from "../../../components/Loader";
import { styles } from "./../newLoginUi.styles";
import { logEvent, firebaseEvents } from "../../../common/firebaseEvents";
import ForgotPassword from "./../components/ForgotPassword";
import SocialLoginIcons from "../../../components/SocialLoginIcons/SocialLoginIcons";
import SweetAlert from "../../../components/SweetAlert";

function Login({ onSignUpPress = () => { }, onNewUser = () => { }, onGuestLogin, onBackPress = () => { }, showBack, onLogin = () => { } }) {

    const [emailAddress, setemailAddress] = useState("");
    const [password, setpassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, seterrorMessage] = useState(null);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();

    const onFacebookButtonPress = async () => {
        // Attempt login with permissions
        onFacebookButtonPressHelper(onSocialSubmit).catch(e => {
            console.log(e)
        });
        return true;
    };

    useEffect(() => {
        if (Platform.OS !== "web") {
            GoogleSignin.configure({
                webClientId:
                    "145651957140-4tnnajcqe2lemj9tkjp4kb88hor32qtd.apps.googleusercontent.com",
                forceCodeForRefreshToken: true,
            });
        }
    }, []);

    const onSocialSubmit = async (login_type, data) => {
        setloading(true);
        const values = {
            email_address: data.email_address,
        };
        if (login_type === "facebook") {
            var data_key = "fb_data";
        } else if (login_type === "apple") {
            var data_key = "apple_data";
        } else if (login_type === "google") {
            var data_key = "google_data";
        } else {
            alert("Invalid Social Login Type: " + string(login_type) + " selected!");
        }
        values[data_key] = data.metadata;
        await handleloginUser(values, "social");
        setloading(false);
    };

    const onGoogleButtonPress = async () => {
        // Get the users ID token
        await onGoogleButtonPressHelper(onSocialSubmit);
    };



    const onAppleButtonPress = async () => {
        await onAppleButtonPressHealper(onSocialSubmit)
    }

    const handleloginUser = async (values, type) => {
        setloading(true);
        // await chechConnection();
        let errMsg = {};
        let postData = {};
        let response = {}
        try {
            if (type == "social") {
                postData = values;
            } else {
                postData.email_address = values.email;
                postData.password_hash = values.password;
            }
            if (!postData.email_address && !postData["apple_data"]) {
                response["status"] = "User does not exist!"
            } else {
                response = await dispatch(loginUser(postData));
            }
            setloading(false);
            if (response.status == "User does not exist!" && type == "social") {
                let userObj = values.fb_data || values.google_data || values.apple_data;
                let Obj = postData["apple_data"] ? response.user_details : {
                    email_address: values.email_address,
                    first_name: userObj.first_name,
                    last_name: userObj.last_name,
                };

                errMsg["loginError"] = "";
                onNewUser(Obj);
                SweetAlert.error(response.status)
            } else if (response.status == "error") {
                // Platform.OS == "web" ? "" : navigation.navigate("Login");
            } else if (response.auth_token == undefined) {
                errMsg["loginError"] = response.status;
                setpassword("");
            } else if (response.auth_token && response.auth_token.length > 0) {
                onLogin(response);
            } else if (
                response.status == "User Credentials Invalid." &&
                type !== "social"
            ) {
                errMsg["loginError"] = response.status;
            }
            if (Object.entries(errMsg).length > 0) {
                seterrorMessage(errMsg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleLogin = async () => {
        setloading(true);
        const values = {
            email: emailAddress,
            password: password,
        };
        let errorMsg = {};
        var emailvalidate =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (values.email == undefined || values.email.trim() == "") {
            errorMsg["email"] = "Email is required";
        } else if (!emailvalidate.test(values.email)) {
            errorMsg["email"] = "Enter valid email";
        } else if (values.password == undefined || values.password.trim() == "") {
            errorMsg["password"] = "Password is required";
        } else {
            if (Platform.OS == "web") {
                values.password = sha256(values.password);
            } else {
                let hashPass = await sha256(values.password);
                values.password = hashPass;
            }
            await handleloginUser(values);
        }

        if (Object.entries(errorMsg).length > 0) {
            seterrorMessage(errorMsg);
        }
        setloading(false);
    };

    const handlePasswordChange = (e) => {
        setpassword(e);
        errorMessage ? (errorMessage["password"] = "") : "";
    };

    return (
        <>
            {loading ? <Loader /> : <View />}
            {showForgotPassword ?
                <ForgotPassword onClose={() => { setShowForgotPassword(false) }} /> :
                <>
                    {showBack ?
                        <TouchableOpacity onPress={onBackPress} style={styles.backBtn}>
                            <Image
                                source={Images.blackbackIcon}
                                style={{ width: 30, height: 20, }}
                            />
                        </TouchableOpacity>
                        : null}
                    <Image
                        source={Images.loginImmiIcon}
                        style={styles.loginImmiIcon}
                    />
                    <View style={[styles.loginSignupTab]}>
                        <TouchableOpacity >
                            <Text style={styles.loginTitle}>Login</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            logEvent(firebaseEvents.SIGN_UP, {
                                message: "User pressed on Sign Up button from Login screen"
                            })
                            onSignUpPress()
                        }}>
                            <Text style={styles.loginTitle}>Sign up</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.bottomBar}>
                        <View style={styles.loginSelected} />
                    </View>
                    {/* {Platform.OS == "web" ? (
              <>
                <Text style={styles.loginLine1}>Get more things done</Text>
                <Text style={styles.loginLine2}>
                  Search Sourcing the world's brightest professionals for your
                  business.
                </Text>
              </>
            ) : (
              <View />
            )} */}
                    <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
                        <TextInput
                            placeholder="Email Address"
                            onChangeText={(e) => {
                                setemailAddress(e),
                                    errorMessage ? (errorMessage["email"] = "") : "";
                            }}
                            placeholderTextColor="rgba(39, 39, 39, 0.4)"
                            style={styles.textInput}
                        />
                    </View>
                    {errorMessage && errorMessage["email"] ? (
                        <Text style={styles.errorText}>{errorMessage["email"]}</Text>
                    ) : (
                        <View />
                    )}
                    <View style={styles.inputBox}>
                        <TextInput
                            placeholder="Password"
                            selectionColor="red"
                            secureTextEntry={!showPassword}
                            onChangeText={(e) => handlePasswordChange(e)}
                            placeholderTextColor="rgba(39, 39, 39, 0.4)"
                            style={styles.textInput}
                        />
                        <TouchableOpacity
                            onPress={() => setShowPassword(!showPassword)}
                            style={{ marginRight: 10 }}
                        >
                            <Text
                                style={{
                                    color: "#0F1143",
                                    fontSize: Platform.OS == "web" ? 13 : heightPercentageToDP("1.8%"),
                                }}
                            >
                                {showPassword ? "Hide" : "Show"}
                            </Text>
                        </TouchableOpacity>
                    </View>
                    {errorMessage && errorMessage["password"] ? (
                        <Text style={styles.errorText}>{errorMessage["password"]}</Text>
                    ) : (
                        <View />
                    )}
                    {errorMessage && errorMessage["loginError"] ? (
                        <Text style={styles.errorText}>
                            {errorMessage["loginError"]}
                        </Text>
                    ) : (
                        <View />
                    )}
                    <TouchableOpacity
                        onPress={handleLogin}
                        style={styles.loginButton}
                    >
                        <Text style={styles.loginButtonText}>Login</Text>
                    </TouchableOpacity>
                    <View style={styles.forgotPassView}>
                        <TouchableOpacity onPress={() => { setShowForgotPassword(true) }}>
                            <Text style={styles.forgotPassText}>Forgot Password</Text>
                        </TouchableOpacity>
                        {onGuestLogin ?
                            <TouchableOpacity onPress={onGuestLogin}>
                                <Text style={styles.forgotPassText}>Guest Login</Text>
                            </TouchableOpacity> : null}
                    </View>
                    <SocialLoginIcons
                        onAppleButtonPress={onAppleButtonPress}
                        onFacebookButtonPress={onFacebookButtonPress}
                        onGoogleButtonPress={onGoogleButtonPress}
                    />
                </>}
        </>
    )
}

export default Login;
