import React from "react";
import { Categories } from "./Categories/Categories";
import { View, Image, TextInput } from "react-native";
import { MyPublicDiscussion } from "./MyPublicDiscussion/MyPublicDiscussion";
import { styles as s } from "./discussion.styles";
import { Images } from "../../../common";
import { useSelector } from "react-redux";
import { Network } from "../../../service/api";

export const Discussions = (props) => {
  const [discussions, setDiscussions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [textSearched, settextSearched] = React.useState(null)
  const [showCategories, setShowCategories] = React.useState(false);
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [searchedList, setsearchedList] = React.useState([])
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const token = useSelector((state) => state.authReducer.authData.token);

  const handleCategory = (cat) => {
    setSelectedCategory(cat);
    setShowCategories(false);
  };

  React.useEffect(() => {
    setLoading(true);
    var authdata = guestLogin ? '' : userDetails?.userid + "@" + token;
    const cat = selectedCategory ? selectedCategory.split(' ')?.length > 0 ? selectedCategory.split(' ').join('_') : selectedCategory : '';
    const headers = {
      Accept: "application/json",
      Authorization: guestLogin ? 'guest@advice' : authdata,
    };
    (async () => {
      const data = guestLogin ?
        await Network(
          `/users/discussions/recent`,
          "GET",
          null,
          headers
        )
        : await Network(
          `/users/${selectedCategory ? cat.toLowerCase() : userDetails?.userid
          }/discussions`,
          "GET",
          null,
          headers
        );
      if (data) {
        setDiscussions(guestLogin ? data?.data : data?.discussions);
        setLoading(false);
      }
    })();
  }, [selectedCategory]);

  const handleChange = (text) => {
    let _discusisons = discussions;
    const searchByTopic = _discusisons?.filter((item) => {
      const topic = item.topic;
      const textData = text;
      return topic.indexOf(textData) > -1 || item?.sub_category?.indexOf(textData) > -1;
    });
    settextSearched(text.length)
    setsearchedList(searchByTopic)
  }

  return showCategories ? (
    <>
      <Categories handleCategory={handleCategory} />
    </>
  ) : (
    <>
      {
        selectedCategory &&
        <View style={s.inputBox}>
          <TextInput onChangeText={(e) => handleChange(e)} placeholder="search categories or questions" style={s.textInputStyle} />
          <Image source={Images.blackSearchIcn} style={{ height: 16, width: 16, marginRight: 20 }} />
        </View>
      }
      <MyPublicDiscussion
        handleCategory={handleCategory}
        discussions={textSearched > 0 ? searchedList : discussions}
        selectedCategory={selectedCategory}
        loading={loading}
        setShowCategories={setShowCategories}
        navigation={props.navigation}
      />
    </>
  );
};
