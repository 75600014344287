import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity, Image, Text, ScrollView, FlatList, Pressable } from "react-native";

import { family, helpers } from '../../common';
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { apiPaths, Network } from '../../service/api';
import { styles } from "./ApplicableVisa.styles";
import { useSelector, useDispatch } from "react-redux";
import VisaDetailsModal from '../../components/ImmipathSearch/components/SectionI/components/ApplicationChecklistModal/VisaDetailsModal';
import config from '../../common/config';
import { SelectionModal } from '../../components/SelectionModal/SelectionModal';
import { isWeb, mobilBrower } from '../../common/helpers';
import { withRouter } from 'react-router-dom';
import WebFooter from '../../common/WebFooter';

function ApplicableVisas(props) {
  const applicableVisa = isWeb() ? props.history.location.state.applicableVisa : props.navigation.state.params.applicableVisa;
  const token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const [showDetailsModal, setshowDetailsModal] = useState({ show: false })
  const [currencyDropdown, setcurrencyDropdown] = useState([]);
  const [selectedCurrency, setselectedCurrency] = useState("USD");
  const [currencyModalVisible, setCurrencyModalVisible] = useState({
    active: false,
    params: null,
  });

  useEffect(() => {
    let _dropdown = [];
    config.currencyArray.forEach((s) => {
      s.label = s.value;
      _dropdown.push(s);
    });
    setcurrencyDropdown(_dropdown);
  }, []);

  const currencySelection = () => {
    setCurrencyModalVisible({
      active: true,
      params: {
        onSelectFn: setselectedCurrency,
        options: currencyDropdown,
        height: "68%",
        itemKey: "label",
        returnKey: "value",
      },
    });
  };

  const handleOnPress = async (item) => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let response = await Network(apiPaths.immipath_path + `${item.immipath_id}`, "GET", null, headers)
    const { status, path_details } = response;
    if (status == 'Success') {
      setshowDetailsModal({
        show: true,
        data: path_details
      })
    }
  }

  const getCost = (cost) => {
    let _cost = cost.split('-')
    if (_cost.length > 1) {
      return (_cost[0] == "0" ? 0 : helpers.convertFormat(Math.round(helpers.currencyConverter(selectedCurrency, _cost[0])), selectedCurrency)) + ' - ' +
        helpers.convertFormat(Math.round(helpers.currencyConverter(selectedCurrency, _cost[1])), selectedCurrency)
    }
    return helpers.convertFormat(Math.round(helpers.currencyConverter(selectedCurrency, _cost[0])), selectedCurrency)
  }

  return (
    <View style={styles.mainView}>
      <HeaderComponent
        showNotificationIcon={false}
        showback={true}
        screenTitle="Applicable Visas"
      />
      {
        showDetailsModal.show &&
        <VisaDetailsModal
          visible={showDetailsModal.show}
          selectedPath={showDetailsModal.data}
          onClose={() => setshowDetailsModal({ show: false })}
        />
      }
      <SelectionModal
        visible={currencyModalVisible.active}
        params={currencyModalVisible.params}
        setVisible={setCurrencyModalVisible}
      />
      <View style={styles.innerView}>
        <View style={styles.currencyDropDown}>
          <TouchableOpacity
            style={{
              width: 100,
              marginTop: 10,
              height: 40,
              marginRight: 25,
              backgroundColor: "white",
              alignItems: "center",
              borderRadius: 10,
              justifyContent: "center",
              borderWidth: 1,
              borderColor: "rgb(225,225,225)",
            }}
            onPress={currencySelection}
          >
            <Text
              style={{
                color: "black",
              }}
            >
              {selectedCurrency}
            </Text>
          </TouchableOpacity>
        </View>
        <FlatList
          data={applicableVisa}
          bounces={false}
          ListEmptyComponent={() => (
            <Text style={{ alignSelf: 'center', marginTop: 40, fontFamily: family.robotoBlack }}>
              No data available for now
            </Text>
          )}
          style={{ marginTop: 15, }}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              onPress={() => handleOnPress(item)}
              activeOpacity={1}
              style={[styles.list, { marginBottom: index == applicableVisa.length - 1 ? 350 : 10 }]}>
              <View style={styles.imgView}>
                <Image source={helpers.getCountryFlag(item.country_id)} style={styles.countryFlag} />
              </View>
              <View style={styles.contentView}>
                <Text style={styles.visaName}>
                  {item.immipath_name}
                </Text>
                <Text style={styles.countryName}>
                  {helpers.getCountryName(item.country_id)}
                </Text>
                <View style={{ flexDirection: 'row', marginTop: 5 }}>
                  {
                    helpers.getGoalName(item.goal_ids).map((s) => (
                      <View style={styles.tagView}>
                        <Text style={styles.tagText}>
                          {s}
                        </Text>
                      </View>
                    ))
                  }
                </View>
                <Text style={styles.goals}>
                  {'Cost: ' + helpers.getCurrencySymbol(selectedCurrency) + ' ' + getCost(item.application_cost_range)}
                </Text>
                <Text style={styles.goals}>
                  {'Time: ' + item.application_processing_time_range.split('-')[0] + ' days' + ' - ' +
                    item.application_processing_time_range.split('-')[1] + ' days'
                  }
                </Text>
              </View>
            </TouchableOpacity>
          )}
        />
      </View>
      {isWeb() && !mobilBrower() ? <WebFooter /> : null}
    </View>
  )
}


export default isWeb()
  ? withRouter(ApplicableVisas) : ApplicableVisas;