import React, { useState, useEffect } from "react";
import { StatusBar, Text, View } from "react-native";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import * as Animatable from "react-native-animatable";
import Loader from "../Loader";
import { Tabs } from "./Tabs/Tabs";
import { FAQs } from "./FAQs/FAQs";
import Conversations from "./Conversation/Conversations";
import { Discussions } from "./Discussions/Discussions";
import NewConversationModal from "../NewConversationModal/NewConversationModal";
import { useSelector } from "react-redux";
import { Network } from "../../service/api";
import AlertModal from "../AlertModal/AlertModal";
import { styles } from "./adviceChannel.styles"
import { isWeb, mobilBrower } from "../../common/helpers";
import WebFooter from "../../common/WebFooter";
import { useLocation, withRouter } from "react-router-dom";

const AdviceChannel = (props) => {
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [inView, setInView] = React.useState("conversations");
  const [askQuestionMilestone, setAskQuestionMilestone] = React.useState(false);
  const [question, setQuestion] = React.useState("");
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [faqList, setfaqList] = useState({})
  let userSelections = props.history?.location?.state;
  const [hideTabs, setHideTabs] = useState(false)
  const [showNewConversationModal, setShowNewConversationModal] = useState(userSelections ? { userSelections } : null)
  const token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);
  const askCommunity = useSelector((state) => state.discussion.discussionData?.askCommunity);
  const [chatData, setChatData] = useState({});



  useEffect(() => {
    getFaqs()
  }, [])

  const getFaqs = async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: guestLogin
        ? "guest@advice"
        : userDetails?.userid + "@" + token,
    };
    Network("/data/get_ac_faq", "GET", null, headers).then(async (res) => {
      if (res.status && res.status == "Success") {
        setfaqList(res.faqs);
      }
    });
  };

  const triggerMessageChat = (
    chat_id,
    data,
    floatingViewState,
    res,
    fileDownloadUrl,
    callchatHistoryApi
  ) => {
    let auto_res = null;
    if (res) {
      auto_res = {
        chat_identifier: res.chat_identifier,
        response: res.response,
        fileDownloadUrl,
        callchatHistoryApi,
      };
    }
    if (isWeb()) {
      setChatData({
        messageId: chat_id,
        auto_res: auto_res,
        chatHeaderData: data,
        ref_id: res?.ref_id,
        expert_dict: res?.expert_dict,
      })
      mobilBrower() && setHideTabs(true);
      return
    }
    const params = {
      messageId: chat_id,
      auto_res: auto_res,
      ref_id: res?.ref_id,
      expert_dict: res?.expert_dict,
      chatHeaderData: data,
      // getAskedData: getAskedData,
    }
    props.navigation.navigate("MessageChat", params)
  };
  const handleTabPress = (e) => {
    if ((e == 'ask community' || e == 'ask our expert')) {
      if (guestLogin) {
        setshowErrorModal(true);
      } else {
        setShowNewConversationModal({ askFriend: e == 'ask community' })
      }
    } else {
      setInView(e)
    }
  }
  const getComponentInView = () => {
    switch (inView) {
      case "conversations":
        return (
          <Conversations
            triggerMessageChat={triggerMessageChat}
            setQuestion={setQuestion}
            setLoading={setLoading}
            chatData={chatData}
            setHideTabs={setHideTabs}
            setChatData={setChatData}
          />
        );
      case "faqs":
        return (
          <FAQs
            triggerMessageChat={triggerMessageChat}
            question={question}
            handleAskExpert={(question) => {
              if (guestLogin) {
                setshowErrorModal(true);
              } else {
                setQuestion(question);
                setShowNewConversationModal({ question });
              }
            }}
            askQuestionMilestone={askQuestionMilestone}
            setQuestion={setQuestion}
            setLoading={setLoading}
            setAskQuestionMilestone={setAskQuestionMilestone}
          />
        );
      case "discussions":
        return <Discussions {...props} />;
      default:
        return <Text>NVM</Text>;
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <StatusBar hidden={true} />
      <HeaderComponent
        screenTitle={"Advice Channel"}
        routeName={location?.pathname || null}
        showNotificationIcon={false}
      />
      {
        showErrorModal &&
        <AlertModal
          title="Login"
          description="Login to explore more!"
          showCancel
          showConfirm
          routeName={ location?.pathname || null}
          confirmText="Login"
          cancelText="Not now"
          loginModal
          onCancelPress={() => setshowErrorModal(false)}
          onConfirmPress={() => setshowErrorModal(false)}
        />
      }
      <Animatable.View
        useNativeDriver={true}
        animation="fadeIn"
        duration={1200}
        style={styles.contentContainer}
      >
        {loading ? <Loader /> : <View />}
        {hideTabs ? null :
          <Tabs inView={inView}
            setQuestion={setQuestion}
            setInView={(e) => handleTabPress(e)} />
        }
        <View style={styles.contentView}>
          {getComponentInView()}
        </View>
      </Animatable.View>
      {showNewConversationModal ?
        <NewConversationModal
          autofillFromGuest={
            props.history?.location?.state?.autofillFromGuest
          }
          userSelections={showNewConversationModal.userSelections}
          askFriend={askCommunity?.call || showNewConversationModal.askFriend ? true : false}
          question={question}
          askCommunity={askCommunity}
          handleLoading={() => { }}
          faqList={
            Object.entries(faqList).length > 0
              ? faqList
              : null
          }
          onCreateDiscussion={() => {
            setInView("discussions")
            setShowNewConversationModal(false)
          }}
          onClose={() => {
            props.history.replace(props.history.location.pathname, null)
            setShowNewConversationModal(false)
          }}
          _triggerMessageChat={triggerMessageChat}
        /> : null}
      {isWeb() && !mobilBrower() ? <WebFooter /> : null}
    </View>
  );
};

export default withRouter(AdviceChannel)