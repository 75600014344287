import React from "react";
import {
  View,
  Text,
  TextInput,
  TouchableHighlight,
  ScrollView,
  TouchableOpacity
} from "react-native";
import BullitinModal from "../BullitinModal/BullitinModal";
import { styles } from "./selectionModal.styles";

export const SelectionModal = ({ visible, setVisible, params, userLanguage }) => {
  const [searched, setSearched] = React.useState("");
  const [userSelection, setUserSelections] = React.useState([]);

  const handleClose = (func) => {
    func();
  };

  const onClose = () => {
    setVisible({
      active: false,
      params: null,
    });
    setSearched("");
  };

  const handleMultiSelect = (item) => {
    let temp = [...userSelection];
    let exists = temp.indexOf(item);
    if (exists !== -1) {
      temp.splice(exists, 1)
    } else {
      temp.push(item)
    }
    setUserSelections(temp)
  }

  const handleSubmit = () => {
    params.onSelectFn(userSelection)
    onClose()
  }

  const search = (e) => {
    setSearched(e);
  };

  return (
    <BullitinModal
      heading=""
      visible={visible}
      onClose={onClose}
      loading={false}
      handleClose={handleClose}
      logoFrom="mid"
      hideCloseButton={false}
      viewableContentHeight={params?.height}
    >
      <View style={[styles.wrapper, { height: "90%" }]}>
        <TouchableHighlight style={styles.searchWrapper}>
          <TextInput
            placeholder="Search here..."
            style={styles.searchInput}
            onChangeText={search}
          />
        </TouchableHighlight>
        <ScrollView>
          {params &&
            params.options
              .filter((e) =>
                searched
                  ? e[params.itemKey]
                    .toLowerCase()
                    .includes(searched.toLowerCase())
                  : true
              )
              .map((e, i) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      !params?.multiSelect ? params.onSelectFn(
                        params.returnKey ? e[params.returnKey] : e, i
                      ) : handleMultiSelect(e[params.returnKey]);
                      !params?.multiSelect && onClose();
                    }}
                    key={`${i}_${params.itemKey}`}
                    activeOpacity={0.6}
                    underlayColor="rgb(230,230,230)"
                    style={[styles.optionsWrapper, params?.multiSelect
                      && userSelection?.includes(e[params.itemKey]) && styles.selectedOption]}
                  >
                    <Text style={styles.options}>{e[params.itemKey]}</Text>
                  </TouchableOpacity>
                );
              })}

        </ScrollView>
        {
          params?.multiSelect ?
            <TouchableOpacity onPress={handleSubmit} style={styles.submitButton}>
              <Text style={styles.submitButtonText}>
                Submit
              </Text>
            </TouchableOpacity> : null
        }
      </View>
    </BullitinModal>
  );
};
