import React, { useState } from "react";
import { styles } from "./sectionB.styles";
import {
  View,
  TouchableOpacity,
  Text,
} from "react-native";
import config from "../../../../common/config";
import { RegionMap } from "./components/RegionMap/RegionMap";
import { continueFromPreviousSearch, setSelectedGoalandCountries, setUserDegreeAndCourseSelection, showAllVisasStatus } from "../../../../actions/path.actions";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";
import { copilot, CopilotStep, walkthroughable } from "react-native-copilot";
import { copilotData } from "../../../../common/helpers";
import { TooltipComponent } from "../../../Tooltip/Tooltip";
import SweetAlert from "../../../SweetAlert";
import CountrySelect from "./components/CountrySelect";

const CopilotView = walkthroughable(View);
const CopilotTouch = walkthroughable(TouchableOpacity);

const SectionB = ({ nextTrack, handleContinue }) => {
  const [continueVisible, setcontinueVisible] = useState(false)
  const selectedgoalandcountries = useSelector(state => state.pathReducer?.getImmipathDetails?.selectedgoalandcountries)
  const showAllVisas = useSelector(state => state.pathReducer?.getImmipathDetails?.showAllVisas)
  const [selectedRegions, setSelectedRegions] = useState({});
  const [selectedCountries, setSelectedCountries] = useState([]);
  const dispatch = useDispatch();


  const handleNext = () => {
    let obj = { ...selectedgoalandcountries, goal: 1 }
    obj.countries = selectedCountries
    obj.userInfo = {}
    if (obj.countries.length < 1) {
      SweetAlert.error("Select at least one country!")
      return;
    }
    dispatch(continueFromPreviousSearch(false))
    dispatch(setUserDegreeAndCourseSelection({}, {}))
    dispatch(setSelectedGoalandCountries(obj))
    nextTrack(obj)
  }

  const _handleContinue = () => {
    handleContinue(true)
  }

  const handleShowall = () => {
    dispatch(showAllVisasStatus(!showAllVisas))
  }

  const handleRegionSelect = (region) => {
    let updatedRegions = { ...selectedRegions }
    let updatedCountries = [...selectedCountries]
    if (updatedRegions[region]) {
      updatedCountries = updatedCountries.filter(item => !updatedRegions[region][item])
      delete updatedRegions[region]
    } else {
      let countries = config.intrestcountry.reduce((countries, country) => {
        if (country.region === region) {
          countries[country.code] = 1
          updatedCountries.push(country.code)
        }
        return countries;
      }, {})
      if (!Object.keys(countries).length) {
        return;
      }
      updatedRegions[region] = countries
    }
    setSelectedRegions(updatedRegions)
    if (isEqual(updatedCountries, selectedgoalandcountries?.countries)) {
      setcontinueVisible(true)
    } else {
      setcontinueVisible(false)
    }
    setSelectedCountries(updatedCountries)
  }

  const handleCountrySelect = (country) => {
    let updatedRegions = { ...selectedRegions }
    let updatedCountries = [...selectedCountries]
    if (updatedCountries.includes(country.code)) {
      updatedCountries = updatedCountries.filter(code => code !== country.code);
      delete updatedRegions[country.region][country.code]
      if (!Object.keys(updatedRegions[country.region]).length) {
        delete updatedRegions[country.region]
      }
    } else {
      updatedCountries.push(country.code);
      if (!updatedRegions[country.region]) {
        updatedRegions[country.region] = {}
      }
      updatedRegions[country.region][country.code] = 1
    }
    if (isEqual(updatedCountries, selectedgoalandcountries?.countries)) {
      setcontinueVisible(true)
    } else {
      setcontinueVisible(false)
    }
    setSelectedRegions(updatedRegions)
    setSelectedCountries(updatedCountries)
  }

  return (
    <View style={{ height: "100%" }}>
      <View style={[styles.main]}>
        <View style={styles.innerView}>
          <RegionMap selectedRegions={Object.keys(selectedRegions)} onRegionSelect={handleRegionSelect} />
          <View style={styles.orBorderContainer}>
            <View style={styles.orBorder} />
            <Text style={styles.orText}>OR</Text>
            <View style={styles.orBorder} />
          </View>
          <CountrySelect selectedCountries={selectedCountries} onCountrySelect={handleCountrySelect} />
        </View>
        <CopilotStep
          text={copilotData.immipathNext}
          order={3}
          name="immipathCountryNext"
        >
          <CopilotView style={styles.buttonRow}>
            <CopilotTouch onPress={handleNext} style={[styles.nextWrapper]}>
              <Text style={[styles.nextText]}>Next</Text>
            </CopilotTouch>
          </CopilotView>
        </CopilotStep>
      </View>
    </View>
  );
};

export default copilot({
  tooltipComponent: TooltipComponent
})(SectionB)