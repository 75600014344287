import { StyleSheet } from "react-native";
import { mobilBrower } from "../../common/helpers";
import { heightPercentageToDP, widthPercentageToDP } from "../../common/responsiveScreen";

export const styles = StyleSheet.create({
  mainView: {
    height: heightPercentageToDP(100),
    width: widthPercentageToDP(100)
  },
  innerView: {
    height: mobilBrower() ? "100%" : "75%",
    width: mobilBrower() ? "100%" : "70%",
    alignSelf: "center"
  },
  list: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    width: "90%",
    marginLeft: "5%",
    padding: 5,
    borderRadius: 15,
    borderColor: "rgb(230,230,230)",
    borderWidth: 1,
  },
  tagView: {
    borderRadius: 10,
    borderWidth: 1,
    marginBottom: heightPercentageToDP('1%'),
    marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#000'
  },
  tagText: {
    paddingVertical: 5,
    color: 'rgba(0, 0, 0, 0.61)',
    paddingHorizontal: heightPercentageToDP('1.3%'),
    fontSize: heightPercentageToDP('1.5%'),
  },
  visaName: {
    margin: 5,
  },
  countryName: {
    margin: 5,
  },
  goals: {
    margin: 5,
  },
  detailRow: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  imgView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentView: {
    flex: 1,
    padding: 5,
    flexGrow: 1,
  },
  countryFlag: {
    width: 150,
    height: 150,
    aspectRatio: 1.1,
    resizeMode: 'contain'
  },
  currencyDropDown: {
    width: '100%',
    alignItems: 'flex-end'
  },
})