import React, { useState, useEffect } from 'react'
import { View, Text, TouchableOpacity, Image, Modal, FlatList } from "react-native";
import { Images } from "../../common";
import { useSelector } from "react-redux";
import { styles } from "./ScoreBreakDownModal.styles"

function ScoreBreakDownModal(props) {
    const toDoListItems = useSelector(state => state.pathReducer.getImmipathDetails.toDoListItems)
    const [totalPercentage, settotalPercentage] = useState(0);
    const [currentBreakDown, setcurrentBreakDown] = useState(null);
    const [averageTotal, setaverageTotal] = useState(0);
    const [totalScore, setTotalScore] = useState(0);
    const [activeTab, setactiveTab] = useState(null);
    const [promotions, setpromotions] = useState(null)
    const [addedtodoList, setaddedtodoList] = useState(false)
    const [excludedReasons, setexcludedReasons] = useState(null)
    const [availableTabs, setavailableTabs] = useState(null)
    let sum = 0;

    const DESCRIPTIONS = {
        "Cost of living": "Lesser the cost of living, higher the score",
        "Business friendliness": "Greater the business friendliness index value, higher the score",
        "Preferred language": "Score given if the preferred language benificial in the country of relevance",
        "Cost budget": "Determined by calculating the percentage of budget required to cover the application cost, lesser the percentage, greater the score",
        "Immigration friendliness": "Greater the immigration friendliness index value, higher the score",
        "Unemployment rate": "Lesser the unemployment rate, higher the score",
        "Part time work": "Score given for possible part time opportunities",
        "Application time": "Lesser the minimum application time, higher the score",
        "Ethnicity": "Score given if your ethinicty is popular in the given country",
        "Speciality Occupation": "Score given if your occupation is among the top ten in-demand jobs in the given country",
        "Average income": "Greater the average income in the given country, greater the score"
    }

    const getColor = (percentage) => {
        let color = ''
        if (percentage >= 40 && percentage <= 80) {
            color = '#999900'
        } else if (percentage < 40) {
            color = 'red'
        } else {
            color = 'green'
        }
        return color;
    }

    const getTodoItems = (item) => {
        let todolistArr = toDoListItems ? Object.entries(toDoListItems?.to_do) : [];
        todolistArr.length > 0 && todolistArr.forEach((s) => {
            if (s[0] == props.item.pathName) {
                item.forEach((r) => {
                    s[1].items.forEach((q) => {
                        if (r.offer_title == Object.entries(q)[0][0]) {
                            let addedToDO = { ...addedtodoList }
                            addedToDO[r.promotion_id] = true
                            setaddedtodoList(addedToDO)
                        }
                    })
                })
            }
        })
    }

    useEffect(() => {
        let averageTotal = 0;
        let totalScore = 0;
        let numberOfItem = 0;
        let counter = false;
        let str = '';
        props.breakDown && Object.entries(props.breakDown).forEach((s) => {
            if (Object.entries(s[1]).length > 0) {
                str += `${s[0]} + `
                numberOfItem += 1;
                if (!counter) {
                    setcurrentBreakDown(s[1]);
                    setactiveTab(s[0]);
                    counter = true;
                }
                Object.entries(s[1]).forEach((q) => {
                    let value = q[1].split('/')[0];
                    averageTotal += parseInt(value);
                    let value2 = q[1].split('/')[1];
                    totalScore += parseInt(value2)
                })
            }
        })
        setavailableTabs(str.slice(0, -2));
        setaverageTotal(averageTotal / numberOfItem);
        setTotalScore(totalScore);
        if (props.excludedPath) {
            let excluded_Reasons = []
            let promotions = Object.entries(props.promotions);
            Object.entries(props.excluded_reason).map((item) => {
                if (item[1].length > 0) {
                    item[1].forEach((s) => {
                        if (s == props.scoreImmipId) {
                            excluded_Reasons.push(item[0])

                        }
                    })
                }
            })
            setexcludedReasons(excluded_Reasons)
        }
    }, [])

    const CheckshowBulb = (item) => {
        let promotions = Object.entries(props.promotions);
        let promoExist = promotions.filter((s) => s[0] == item && s[1].length > 0);
        if (promoExist.length > 0) {
            return true
        } else {
            return false
        }
    }

    const getTotal = (score) => {
        let num = score.split('/')[1]
        sum = parseInt(sum) + parseInt(num);
        let totalPercentage = (100 * props.score) / sum;
        settotalPercentage(totalPercentage);
    }

    const handleBuldClick = (reason) => {
        let promotions = props?.promotions ? Object.entries(props.promotions) : [];
        promotions.forEach((s) => {
            if (s[0] == reason) {
                setpromotions(s[1])
                getTodoItems(s[1])
            }
        })
    }

    return (
        <Modal transparent visible={props.visible}>
            <View style={styles.mainView}>
                <View style={styles.innerView}>
                    <TouchableOpacity onPress={() => props.handleClose()} style={styles.cancelButton}>
                        <Image source={Images.cancelGrey} style={{ height: 18, width: 18 }} />
                    </TouchableOpacity>
                    <Text style={[styles.toptext, props.excludedPath && !props.eligible && styles.excludedReasonTitle]}>
                        {
                            props.excludedPath && !props.eligible ? 'Excluded Reasons' :
                                'Scoring is done based on your eligibility questionnaire responses, profile preferences, visa details and country-level indexes'
                        }
                    </Text>
                    <>
                        {
                            props.excludedPath && !props.eligible ?
                                <>
                                    <View style={{ flex: 1 }}>
                                        <FlatList
                                            data={excludedReasons}
                                            style={{ marginBottom: 30, marginTop: 15 }}
                                            contentContainerStyle={{ width: "100%" }}
                                            renderItem={({ item, index }) => (
                                                <TouchableOpacity style={{ alignSelf: "center", flexDirection: 'row', width: "90%", marginVertical: 10 }}>
                                                    <Text style={styles.reasonBullet}>{'\u2B24'}</Text>
                                                    <Text style={styles.title}>
                                                        {item}
                                                    </Text>
                                                    {
                                                        CheckshowBulb(item) ?
                                                            <TouchableOpacity onPress={() => handleBuldClick(item)}>
                                                                <Image source={Images.think} style={{ height: 18, width: 18, marginLeft: 10, marginBottom: 5 }} />
                                                            </TouchableOpacity> :
                                                            <View />
                                                    }
                                                </TouchableOpacity>
                                            )}
                                        />
                                    </View>
                                    <TouchableOpacity onPress={props.onAskExpert ? props.onAskExpert : () => { }} style={styles.btn}>
                                        <Text style={styles.btnText}>Ask our Expert</Text>
                                    </TouchableOpacity>
                                </>
                                :
                                currentBreakDown !== null ?
                                    <>
                                        <FlatList
                                            data={Object.entries(currentBreakDown)}
                                            style={{ marginBottom: 5 }}
                                            renderItem={({ item, index }) => {
                                                let score = item[1].split('/');
                                                let percentage = (100 * score[0]) / score[1];
                                                getTotal(item[1])
                                                return (
                                                    <View style={styles.item}>
                                                        <View style={{ flex: 1, paddingRight: 10 }}>
                                                            <View style={styles.bulletView}>
                                                                <View style={styles.bullet} />
                                                                <Text style={styles.title}>
                                                                    {item[0]}
                                                                </Text>
                                                            </View>
                                                            {DESCRIPTIONS[item[0].split("(")[0].trim()] ?
                                                                <Text style={{ marginTop: 5 }}>{DESCRIPTIONS[item[0].split("(")[0].trim()]}</Text> : null}
                                                        </View>
                                                        <View style={[styles.scoreView, { borderColor: getColor(percentage) }]}>
                                                            <Text style={[styles.score, { color: getColor(percentage) }]}>
                                                                {item[1]}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                )
                                            }}
                                        />
                                        <View style={[styles.item, { marginTop: 0, marginBottom: 10 }]}>
                                            <View style={[styles.bulletView, { marginLeft: 5 }]}>
                                                <Text style={[styles.title, { flex: 1 }]}>
                                                    {`Total Points (${availableTabs?.trim()})`}
                                                </Text>
                                            </View>
                                            <View style={[styles.scoreView, { borderColor: getColor(totalPercentage) }]}>
                                                <Text style={[styles.score, { color: getColor(totalPercentage) }]}>
                                                    {averageTotal + '/' + totalScore}
                                                </Text>
                                            </View>
                                        </View>
                                    </>
                                    :
                                    <View />

                        }
                    </>
                </View>
            </View>
        </Modal>
    )
}

export default ScoreBreakDownModal
