import React, { useState, useRef, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  Modal,
  Dimensions,
  FlatList,
  Platform,
  ScrollView,
  TextInput,
  ActivityIndicator,
} from "react-native";
import { Images } from "../../common";
import { apiPaths, Network } from "../../service/api";
import { useSelector, useDispatch } from "react-redux";
import AdviceChannelPackage from "../AdviceChannelPackage/AdviceChannelPackage";
import * as RNIap from "react-native-iap";
import ErrorModal from "../errorModal";
import blackbackIcon from "../../images/blackbackIcon.png";
import { Tooltip, } from 'react-native-elements';
import helpers, { isWeb, mobilBrower } from "../../common/helpers";
import FaqFussyDropDown from "../FaqFussyDropDown";
import { styles } from "./newConversationModal.styles";
import { TrailFromRight } from "./newConversationModal.animation";
import storage from "@react-native-firebase/storage";
import config from "../../common/config";
import fontFamily from "../../common/fontFamily";
import Carousel, { Pagination } from "react-native-snap-carousel";
import DocumentPicker from "react-native-document-picker";
import { isArray, isEmpty } from "lodash";
import RazorpayCheckout from "react-native-razorpay";
import { setStorebaseCurrency } from "../../actions/referral.actions";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "../SweetAlert";
import WebCarousel from "../WebCarousel";
import uploadIcon from "../../images/uploadIcon.png";
import { GoPrimitiveDot } from "react-icons/go";
import Loader from "../Loader";
import AsyncStorage from "@react-native-community/async-storage";
import DiscountModal from "./DiscountModal";
import {
  setAdviceChannelSelctions,
  setAdviceChannelSubCategories,
} from "../../actions/adviceChannel.action";
import { Switch } from "react-native-paper";
import BlurBackground from "../BlurBackground";
import { heightPercentageToDP } from "../../common/responsiveScreen";
import RNPicker from "../RNModalPicker";
import { getImmibuckBalance } from "../../actions/immibucks.actions";

const itemSkus = Platform.select({
  ios: ["basic_advice", "basic_advice_discount", "subscribe_monthly"],
  android: ["basic_advice_discount", "basic_advice_full"],
});

const _handleRazorPay = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export default function NewConversationModal(props) {
  const [Question, setQuestion] = useState(props?.question || "");
  const [discussiontitle, setDiscussiontitle] = useState(null)
  const inpuRef = useRef(null);
  const scrollViewRef = useRef(null);
  const dispatch = useDispatch();
  const [discountDetails, setdiscountDetails] = useState(null);
  const [promoCodeApplied, setpromoCodeApplied] = useState(false);
  const [discountApplied, setdiscountApplied] = useState(false);
  const token = useSelector((state) => state.authReducer.authData.token);
  const [fileDownloadUrl, setfileDownloadUrl] = useState(null);
  const [fileUri, setFileUri] = useState(null);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const [Err, setErr] = useState({});
  const [topics, settopics] = useState([]);
  const [loading, setloading] = useState(false);
  const [inApp_Products, setinApp_Products] = useState([]);
  const [currentStep, setcurrentStep] = useState(1);
  const [userSelectionsObj, setuserSelectionsObj] = useState({});
  const [fuzzyDropDownData, setfuzzyDropDownData] = useState([]);
  const [baseCurrency, setbaseCurrency] = useState("INR");
  const [privateQuestion, setPrivateQuestion] = React.useState(false);
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const [countryIntrest, setcountryIntrest] = useState(props.userSelections?.country_id || null);
  const [selectedPackagePrice, setselectedPackagePrice] = useState(null);
  const [selectedPackage, setselectedPackage] = useState(null);
  const [faqDropDown, setfaqDropDown] = useState({});
  const [queryCount, setqueryCount] = useState(0);
  const [finalPackagePrice, setfinalPackagePrice] = useState(100);
  const [showFussyDropDown, setshowFussyDropDown] = useState(false);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [razorApiKey, setrazorApiKey] = useState(null);
  const [errorMessage, seterrorMessage] = useState(null);
  const [subCategoryList, setsubCategoryList] = useState({});
  const [showpackage, setshowpackage] = useState(false);
  const [selectedsubcategory, setselectedsubcategory] = useState(null);
  const [selectedSubCategoryTier, setselectedSubCategoryTier] = useState(null);
  const [purchase, setpurchase] = useState(false);
  const [residenceDialogOpen, setresidenceDialogOpen] = useState(false);
  const [currentCaroselItem, setcurrentCaroselItem] = useState(0);
  const [selctedCategory, setselctedCategory] = useState(null);
  const [countryOfResidence, setcountryOfResidence] = useState(null);
  const [countryOfCitizenship, setcountryOfCitizenship] = useState(null);
  const [citizenshipDialog, setcitizenshipDialog] = useState(false);
  const [selectedCurrentStage, setselectedCurrentStage] = useState(null);
  const [uploadedDocName, setuploadedDocName] = useState(null);
  const [uploadedFile, setuploadedFile] = useState(null);
  const [advicePackages, setadvicePackages] = useState({});
  const [showDisModal, setshowDisModal] = useState(false);

  useEffect(() => {
    const getRazorPayKey = async () => {
      const headers = {
        Accept: "application/json",
        Authorization: `${userDetails?.userid}@${token}`,
      };
      let res = await Network("/data/get_razorpay_key", "GET", null, headers);
      if (res.status == "Success") {
        setrazorApiKey(res.key);
      }
    };
    !guestLogin && getRazorPayKey();
  }, [userDetails]);

  useEffect(() => {
    getTopics();
  }, []);

  useEffect(() => {
    const getSubCategory = async () => {
      const headers = {
        Accept: "application/json",
        Authorization: guestLogin
          ? "guest@advice"
          : `${userDetails?.userid}@${token}`,
      };
      let res = await Network("/data/get_sub_categories", "GET", null, headers);
      setsubCategoryList(res.sub_categories);
      dispatch(setAdviceChannelSubCategories(res.sub_categories));
    };
    getSubCategory();
  }, []);

  useEffect(() => {
    setcountryOfResidence(userDetails?.country_of_birth || props.userSelections?.country_of_residency);
    setcountryOfCitizenship(userDetails?.country_of_citizenship1 || props.userSelections?.country_of_birth);
  }, [userDetails]);

  const getGoal = (goal) => {
    console.log(goal)
    switch (goal) {
      case 1:
        handlecategorySelection(['study', subCategoryList['study']])
        break;
      case 2:
        handlecategorySelection(['work', subCategoryList['work']])
        break;
      case 4:
        handlecategorySelection(['tourism', subCategoryList['tourism']])
        break;
      case 3:
        handlecategorySelection(['permanent_residency', subCategoryList['permanent_residency']])
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (props.userSelections) {
      Object.keys(subCategoryList).length && getGoal(props.userSelections?.goal || props.userSelections?.goal_ids?.[0])
    }
  }, [props.userSelections, subCategoryList])

  useEffect(() => {
    if (props.askCommunity?.category) {
      Object.keys(subCategoryList).length && getGoal(helpers.getGoalId(props.askCommunity?.category))
    }
  }, [props.askCommunity, subCategoryList])

  useEffect(() => {
    const getGuestFromInfo = async () => {
      let info = await AsyncStorage.getItem("guestAdviceFormInfo");
      info = JSON.parse(info);
      Object.entries(info).forEach((s) => {
        if (s[0].includes("relevance")) {
          Object.entries(subCategoryList).forEach((q) => {
            if (s[1].split("->")[0].trim() == q[0]) {
              setselctedCategory(q);
              Object.entries(q[1]).forEach((f) => {
                if (f[0] == s[1].split("->")[1].trim()) {
                  setselectedCurrentStage(f);
                }
              });
            }
          });
        }
        if (s[0].includes("Citizenship")) {
          setcountryOfCitizenship(s[1]);
        }
        if (s[0].includes("Residence")) {
          setcountryOfResidence(s[1]);
        }
      });
      console.log("autofillFromGuest", info);
    };
    props.autofillFromGuest && getGuestFromInfo();
    props.expertQuestion && setQuestion(props.expertQuestion);
  }, [props.expertQuestion, subCategoryList]);

  useEffect(() => {
    if (Platform.OS !== "web") {
      purchaseUpdateSubscription = RNIap.purchaseUpdatedListener((purchase) =>
        handlePurchaseUpdate(purchase)
      );
      return () => {
        try {
          purchaseUpdateSubscription.remove();
        } catch (error) { }
      };
    }
  }, []);

  if (purchase) {
    setloading(true);
    const data = {
      agent_name:
        selectedPackage == "BASIC"
          ? "Immigreat Advice Support"
          : this.state.selectedAgent?.agent_name,
      avg_ratings: selectedPackage == "BASIC" ? "" : "",
      type: selectedPackage == "BASIC" ? "Consultant" : "",
      category: selctedCategory,
      parent_org: selectedPackage == "BASIC" ? "" : "",
    };

    const url = `/users/${userDetails?.userid}/create_discussion`;

    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let selectedType =
      selectedPackage == "BASIC"
        ? "immigreat_consultant"
        : this.state.selectedAgent?.type;
    let typeId =
      selectedType.toLowerCase() === "lawyer"
        ? 1
        : selectedType.toLowerCase() === "immigreat_consultant"
          ? 3
          : 2;
    const payload = {
      discussion_platform_id: typeId,
      topic: Question,
      category: selctedCategory,
      text: Question,
      cc_user: "",
    };
    try {
      Network(url, "POST", payload, headers).then((res) => {
        if (res.status === "Success") {
          callAddApi(res);
          props.handleLoading(false);
          props._triggerMessageChat(res.chat_id, data, false, res);
        } else {
        }
      });
    } catch (error) { }
  }

  const handleDiscount = (item, discountApplied) => {
    !discountApplied ? setpromoCodeApplied(true) : setdiscountApplied(true);
    setdiscountDetails(item);
  };

  const afterSuccessPay = () => {
    setloading(true);
    const data = {
      country_of_residence: countryOfResidence,
      country_of_citizenship: countryOfCitizenship,
      category: selctedCategory[0],
      sub_category: selectedsubcategory,
      advice_package: selectedPackage,
      text: Question,
      query_count: queryCount,
      country_id: countryIntrest,
      payment_made: selectedPackagePrice == 0 ? false : true,
    };
    const url = `/users/${userDetails?.userid}/create_discussion`;
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    try {
      Network(url, "POST", data, headers).then(async (res) => {
        if (res.status === "Success") {
          dispatch(setAdviceChannelSelctions({}));
          uploadedDocName
            ? uploadFileToFirebase(res, data)
            : props._triggerMessageChat(
              res.chat_id,
              data,
              false,
              res,
              null,
              true
            );
          // callAddApi(res);
          // props.handleLoading(false);
          props.onClose();
        } else {
          setloading(false);
          SweetAlert.error(res.status);
        }
      });
    } catch (error) {
      setloading(false);
      props.onClose();
    }
  };

  const uploadFileToFirebase = async (res, data) => {
    if (Platform.OS == "web") {
      const storage = getStorage();
      try {
        const storageRef = ref(
          storage,
          `adviceSupportingDoc/${userDetails?.userid}/${res.ref_id}/${uploadedDocName}`
        );
        uploadBytes(storageRef, uploadedFile).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            setfileDownloadUrl(`${downloadURL}/${uploadedDocName}`);
            props._triggerMessageChat(
              res.chat_id,
              data,
              false,
              res,
              `${downloadURL}>>${uploadedDocName}`,
              true
            );
            setloading(false);
            props.onClose();
          });
        });
      } catch (error) {
        console.log("errr", error);
      }
    } else {
      var storageRef = storage().ref(
        `/adviceSupportingDoc/${userDetails?.userid}/${res.ref_id}/${uploadedDocName}`
      );
      storageRef
        .putFile(fileUri.uri)
        .on(storage.TaskEvent.STATE_CHANGED, (snapshot) => {
          if (snapshot.state === storage.TaskState.SUCCESS) {
            storage()
              .ref(`${storageRef.path}`)
              .getDownloadURL()
              .then((url) => {
                props._triggerMessageChat(
                  res.chat_id,
                  data,
                  false,
                  res,
                  `${url}>>${uploadedDocName}`,
                  true
                );
                setloading(false);
                props.onClose();
              });
          }
        });
    }
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const handleCreateDiscussion = async () => {
    let err = {};
    if (!discussiontitle) {
      err["discussiontitle"] = true;
    }
    if (!Question) {
      err["Question"] = true;
    }
    if (!selctedCategory) {
      err["category"] = true;
    }
    if (!selectedCurrentStage) {
      err["currentStage"] = true;
    }
    if (!selectedsubcategory) {
      err["subCategory"] = true;
    }
    if (!countryIntrest) {
      err["countryIntrest"] = true;
    }
    if (!countryOfResidence) {
      err["countryOfResidence"] = true;
    }

    if (Object.entries(err).length > 0) {
      setErr(err);
      return;
    }
    let paylaod = {
      title: discussiontitle,
      sub_category: selectedsubcategory,
      category: selctedCategory[0],
      text: Question,
      is_private: privateQuestion,
      country_id: countryIntrest
    }
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    await Network(`/users/${userDetails?.userid}/create_discussion_forum`, "POST", paylaod, headers);
    props.onCreateDiscussion()
  }

  const removeUploadedDocument = () => {
    setuploadedFile(null);
    setuploadedDocName(null)
  }

  const uploadDocument = async () => {
    try {
      if (Platform.OS == "web") {
        let input = document.createElement("input");
        input.type = "file";
        input.accept = ".xls,.xlsx, .pdf, .txt";
        input.onchange = async () => {
          // you can use this method to get file and perform respective operations
          let files = Array.from(input.files);
          const imageDataUrl = await readFile(files[0]);
          setuploadedFile(files[0]);
          setuploadedDocName(files[0]?.name);
        };
        Err["uploadedDocName"] = false;
        input.click();
      } else {
        const response = await DocumentPicker.pickSingle({
          type: [
            DocumentPicker.types.pdf,
            DocumentPicker.types.csv,
            DocumentPicker.types.plainText,
            DocumentPicker.types.xls,
            DocumentPicker.types.xlsx,
            DocumentPicker.types.doc,
            DocumentPicker.types.docx,
          ],
        });
        const source = { uri: response.uri };
        const fileExtension = response.type.split("/");
        var fileName = `${userDetails?.userid}.${fileExtension[1]}`;
        setFileUri(source);
        setuploadedDocName(fileName);
      }
    } catch (err) {
      if (DocumentPicker.isCancel(err)) {
        // User cancelled the picker, exit any dialogs or menus and move on
      } else {
        throw err;
      }
    }
  };

  const callAddApi = (res) => {
    const url = userDetails?.userid + "/add";
    const logPayUrl = `/users/${userDetails?.userid}/log_payment`;
    const balance_url = `/users/${userDetails?.userid}`;
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    const paymentBody = {
      amount: 75,
      statement: `Advice Channel - ${res.ref_id}`,
    };
    const body = {
      amount: 100,
      type: "discount",
      statement: `Discount on Advice Channel - ${res.ref_id}`,
      milestone: null,
    };
    props.handleLoading(true);
    setpurchase(false);
    Network(logPayUrl, "POST", paymentBody, headers);
    Network(apiPaths.checkRewardMilestone + url, "POST", body, headers)
      .then((res) => {
        if (res.status === "Success") {
          dispatch(getImmibuckBalance(balance_url, headers));
        } else {
          props.handleLoading(false);
        }
      })
      .catch((e) => {
        alert("Something went wrong!");
        props.handleLoading(false);
      });
  };

  const handlePurchaseUpdate = async (purchase) => {
    // console.log('purchaseUpdatedListener', purchase);
    if (purchase) {
      RNIap.acknowledgePurchaseAndroid(purchase.purchaseToken)
        .then(async () => {
          await RNIap.consumePurchaseAndroid(purchase.purchaseToken);
          setpurchase(true);
          RNIap.finishTransaction(purchase, true).catch((err) => { });
        })
        .catch((err) => {
          alert(err);
        });
    }
    purchaseErrorSubscription = purchaseErrorListener((error) => { });
  };

  const handleSubCategorySelection = (item) => {
    Err["subCategory"] = false;
    if (item.category == selectedsubcategory) {
      setselectedsubcategory(null);
      setselectedSubCategoryTier(null);
    } else {
      setselectedsubcategory(item.category);
      setselectedSubCategoryTier(item.tier);
    }
  };

  const _questionBox = (text) => {
    if (props.guestLogin) {
      inpuRef.current.blur();
      setshowErrorModal(true);
      setguest(true);
      seterrorMessage(
        "Login to ask queries regarding your immigration problems, answered by experts for the lowest cost!"
      );
      return;
    }
    if (text.length > 800) {
      alert("Only 800 characters allowed!");
      return;
    } else if (text.length > 250 && text.length <= 800) {
      setQuestion(text);
    } else if (text.length == 0) {
      setshowFussyDropDown(false);
      setQuestion(text);
    } else {
      Err["Question"] = false;
      const searchByName = props.faqList?.faqs?.filter((item) => {
        const ques = item.children[0]?.name;
        const textData = text;
        return ques.indexOf(textData) > -1;
      });
      setfuzzyDropDownData(searchByName);
      searchByName?.length > 0
        ? setshowFussyDropDown(true)
        : setshowFussyDropDown(false);
      setQuestion(text);
    }
  };

  const handleFaqDropDown = (index) => {
    if (index == faqDropDown.index) {
      setfaqDropDown({});
      return;
    }
    setfaqDropDown({ index: index });
  };

  const choosepackage = () => {
    setshowpackage(true);
  };

  useEffect(() => {
    getbaseCurrency = async () => {
      const headers = {
        Accept: "application/json",
        Authorization: userDetails?.userid + "@" + token,
      };
      let res = await Network("/data/get_country", "GET", null, headers);
      if (res.status == "Success") {
        let currency = config.countries_json.filter(
          (s) => s.id == res.country_code
        );
        dispatch(setStorebaseCurrency(currency[0].currency));
        setbaseCurrency(currency[0].currency);
      }
    };
    getbaseCurrency();
    getUserImmiBucksBalance();
  }, []);

  const callPurchase = async (pId) => {
    if (selectedPackagePrice == 0) {
      afterSuccessPay();
      return;
    }
    const url = "/razorpay/create_order";
    const logPayUrl = `/users/${userDetails?.userid}/log_payment`;
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let body = {
      order_amount: Number(selectedPackagePrice) * 100,
      statement: `immistore purchase for ${selectedPackage}`,
      order_currency: baseCurrency,
      order_receipt: `immi_recpt_IMAP${userDetails?.userid
        }_${new Date().getTime()}`,
    };
    // setloading(true);
    Network(url, "POST", body, headers).then(async (res) => {
      let razorPayIds = {};
      var options =
        Platform.OS == "web"
          ? {
            description: "Immistore Purchase",
            image: Images.toolbarLogo,
            currency: "INR",
            key: razorApiKey,
            handler: function (response) {
              // setloading(true);
              razorPayPaymentStatus = true;
              razorPayIds.razorpay_payment_id = response.razorpay_payment_id;
              razorPayIds.razorpay_order_id = response.razorpay_order_id;
              razorPayIds.razorpay_signature = response.razorpay_signature;
              Network(logPayUrl, "POST", paymentBody, headers);
              razorPayIds.order_id = res.order_details.id;
              razorPayIds.order_receipt =
                paymentBody.payment_type.additional_info.order_receipt;
              afterSuccessPay();
            },
            amount: selectedPackagePrice * 100,
            name: "Immigreat Pvt. Ltd.",
            order_id: res.order_details.id,
            prefill: {
              email: userDetails?.email_address,
            },
            theme: { color: "#FFAA00" },
          }
          : {
            description: "Immistore Purchase",
            image: Images.toolbarLogo,
            currency: baseCurrency,
            key: razorApiKey,
            amount: Number(selectedPackagePrice) * 100,
            name: "Immigreat Pvt. Ltd.",
            order_id: res.order_details.id,
            prefill: {
              email: userDetails?.email_address,
              contact: userDetails?.phone,
              name: userDetails?.first_name,
            },
            theme: { color: "#FFAA00" },
          };
      const paymentBody = {
        user_id: userDetails?.userid,
        amount: selectedPackagePrice,
        statement: `immistore purchase for ${selectedPackage}`,
        payment_type: {
          type: "razorpay",
          additional_info: {
            order_id: res.order_details.id,
            order_receipt: body.order_receipt,
            Advice_package: selectedPackage,
          },
        },
      };

      if (Platform.OS == "web") {
        const razorypayResponse = await _handleRazorPay(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!razorypayResponse) {
          alert("Razorpay failed to load!");
          return;
        }
        try {
          const payObj = new window.Razorpay(options);
          payObj.open();
        } catch (error) { }
      } else {
        RazorpayCheckout.open(options)
          .then((data) => {
            setloading(false);
            afterSuccessPay(res, data);
            props.handleLoading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      }
    });
  };
  const callPurchaseWeb = () => {
    const url = "/razorpay/create_order";
    let razorPayIds = {};
    let razorPayPaymentStatus = false;
    const logPayUrl = `/users/${userDetails?.userid}/log_payment`;
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };

    let body = {
      order_amount: selectedPackagePrice.toFixed(2) * 100,
      statement: `immistore purchase for ${"Basic"}`,
      order_currency: "INR",
      order_receipt: `immi_recpt_IMST${userDetails?.userid
        }_${new Date().getTime()}`,
    };
    Network(url, "POST", body, headers).then(async (res) => {
      const paymentBody = {
        user_id: userDetails?.userid,
        amount: finalPackagePrice.toFixed(2),
        statement: `immistore purchase for ${"Basic"}`,
        payment_type: {
          type: "razorpay",
          additional_info: {
            order_id: res.order_details.id,
            order_receipt: body.order_receipt,
            // products: cartItems
          },
        },
      };
      const razorypayResponse = await _handleRazorPay(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!razorypayResponse) {
        alert("Razorpay failed to load!");
        return;
      }
      try {
        const payObj = new window.Razorpay(options);
        payObj.open();
      } catch (error) { }
    });
  };

  const getUserImmiBucksBalance = () => {
    const userId = userDetails?.userid;
    if (!userId || !token) {
      return;
    }
    const balance_url = `/users/${userId}`;
    const headers = {
      Accept: "application/json",
      Authorization: userId + "@" + token,
    };
    dispatch(getImmibuckBalance(balance_url, headers));
  };

  const handleButtonClick = () => {
    if (selectedPackage) {
      Platform.OS == "web" ? callPurchaseWeb() : callPurchase();
    } else {
      choosepackage();
    }
  };

  const getImage = (item) => {
    if (countryIntrest && countryIntrest == item.code) {
      return Platform.OS == "web" ? item.webImg : item.webImg;
    } else {
      return Platform.OS == "web" ? item.webFlag : item.webFlag;
    }
  };

  const countrySelection = (code) => {
    Err["countryIntrest"] = false;
    let tempCountry = countryIntrest;
    setcountryIntrest(code);
  };

  const _setFinalPackagePrice = (amt, item) => {
    if (item) {
      amt = amt - 0;
      setfinalPackagePrice(amt.toFixed(2));
      setqueryCount(item[1].query_count);
      setselectedPackagePrice(
        helpers.storeCurrencyConverer(props.baseCurrency, amt.toFixed(2))
      );
      setselectedPackage(
        `${item[1].name}@${helpers.storeCurrencyConverer(
          props.baseCurrency,
          amt.toFixed(2)
        )}`
      );
      setshowpackage(false);
    } else {
      setselectedPackage(null);
      setfinalPackagePrice(0);
      setselectedPackagePrice(null);
      setqueryCount(0);
    }
  };

  const checkUserIsGuest = () => {
    if (guestLogin) {
      setshowErrorModal(true);
      seterrorMessage(
        "Login to ask queries regarding your immigration problems, answered by experts for the lowest cost!"
      );
      setTimeout(() => {
        setshowErrorModal(false);
      }, 1500);
    } else {
      if (Question == "") {
        setshowErrorModal(true);
        seterrorMessage("Please enter your query!");
        setTimeout(() => {
          setshowErrorModal(false);
        }, 1500);
      } else {
        handleButtonClick();
      }
    }
  };

  const getTopics = (type) => {
    let plateform_type = "immigreat_consultant";
    const url = `/discussion_platforms/${plateform_type}`;
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    Network(url, "GET", null, headers).then((res) => {
      if (res.status === "Success") {
        let categoriesArr = [];
        categoriesArr.push(res.discussion_platforms[0].platform_categories);
        settopics(categoriesArr[0]);
      }
    });
  };

  const fetchAdvicePackages = async (category) => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let payload = {
      category: category[0],
    };
    let response = await Network(
      "/data/get_advice_packages",
      "POST",
      payload,
      headers
    );
    const { status, advice_packages } = response;
    if (status == "Success") {
      setadvicePackages(advice_packages);
    }
  };

  const handlecategorySelection = (item) => {
    Err["category"] = false;
    setselctedCategory(item);
    fetchAdvicePackages(item);
  };

  const handleCurrentStageClick = (item) => {
    Err["currentStage"] = false;
    setselectedCurrentStage(item);
  };

  const _selectedValue1 = (index, item) => {
    setcountryOfResidence(item.name);
  };

  const handleNextClick = () => {
    let err = {};
    if (currentStep == 1) {
      if (!selctedCategory) {
        err["category"] = true;
      }
      if (!selectedCurrentStage) {
        err["currentStage"] = true;
      }
      if (!selectedsubcategory) {
        err["subCategory"] = true;
      }
      if (!countryIntrest) {
        err["countryIntrest"] = true;
      }
      if (!countryOfResidence) {
        err["countryOfResidence"] = true;
      } if (!countryOfResidence) {
        err["countryOfResidence"] = true;
      } if (!countryOfCitizenship) {
        err["countryOfCitizenship"] = true;
      }
      if (Object.entries(err).length > 0) {
        setErr(err);
        return;
      }
    } else if (currentStep == 2) {
      if (!selectedPackage) {
        err["selectedPackage"] = true;
        setErr(err);
        return;
      }
    } else if (currentStep == 3) {
      if (!Question) {
        err["Question"] = true;
      }
      if (Object.entries(err).length > 0) {
        setErr(err);
        return;
      }
    }
    let Obj = {
      Question: Question,
      "Uploaded\nDocument": uploadedDocName,
      "Category of\nRelevance": `${capitalizeFirstLetter(
        selctedCategory[0]
      )} -> ${capitalizeFirstLetter(selectedsubcategory)}`,
      "Your Country\nOf Residence": countryOfResidence,
      "Your Country\nOf Citizenship": countryOfCitizenship,
      "Selected\nPackage": selectedPackage,
    };
    if (guestLogin) {
      AsyncStorage.setItem("guestAdviceFormInfo", JSON.stringify(Obj));
      setshowErrorModal(true);
      seterrorMessage("Login to explore all the features of the app!");
      return;
    }
    setuserSelectionsObj(Obj);
    setcurrentStep(currentStep + 1);
    scrollViewRef.current.scrollTo({
      y: 0,
      animated: true,
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const _setcountryOfCitizenship = (index, item) => {
    setcountryOfCitizenship(item.name);
  };

  const [modalLoad, setModalLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setModalLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (selectedCurrentStage) {
      handleSubCategorySelection(selectedCurrentStage[1][0]);
    }
  }, [selectedCurrentStage]);

  const pagination = () => {
    return (
      <Pagination
        dotsLength={selectedCurrentStage[1].length}
        activeDotIndex={currentCaroselItem}
        containerStyle={{ backgroundColor: "transparent" }}
        dotStyle={{
          width: 10,
          height: 10,
          borderRadius: 5,
          color: "red",
          marginHorizontal: 8,
          backgroundColor: "white",
        }}
        inactiveDotStyle={
          {
            // Define styles for inactive dots here
          }
        }
        inactiveDotOpacity={0.4}
        inactiveDotScale={0.6}
      />
    );
  };

  const handleBackButton = () => {
    if (currentStep > 1) {
      setcurrentStep(currentStep - 1);
      if (currentStep === 3) {
        setQuestion("");
      }
    } else {
      props.onClose();
    }
  };

  const onToggleSwitch = () => setPrivateQuestion(!privateQuestion);

  const sortAplhabetically = () => {
    let newarr = config.homeCountries.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return newarr;
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1: {
        return <>
          {
            props.askFriend &&
            <>
              <View style={styles.categoryView}>
                <Text style={styles.title}>Enter Title </Text>
              </View>
              <View
                style={[
                  styles.askQuesView,
                  Err["discussiontitle"] && styles.errView, { minHeight: 50 }
                ]}
              >
                <TextInput
                  style={[
                    styles.title,
                    {
                      marginTop: 5,
                      padding: 10,
                      fontSize: mobilBrower()
                        ? heightPercentageToDP("1.5%")
                        : 14,
                      color: "black",
                      marginLeft: 10,
                      width: "99%",
                    },
                  ]}
                  placeholder="Enter Title "
                  value={discussiontitle}
                  ref={inpuRef}
                  onChangeText={setDiscussiontitle}
                  placeholderTextColor="grey"
                />
              </View>
              <View style={styles.categoryView}>
                <Text style={styles.title}>Enter your question</Text>
              </View>
              <View
                style={[
                  styles.askQuesView,
                  Err["Question"] && styles.errView,
                ]}
              >
                <TextInput
                  style={[
                    styles.title,
                    {
                      marginTop: 5,
                      padding: 10,
                      fontSize: mobilBrower()
                        ? heightPercentageToDP("1.5%")
                        : 14,
                      color: "black",
                      marginLeft: 10,
                      width: "99%",
                    },
                  ]}
                  placeholder="Enter your question"
                  multiline={true}
                  numberOfLines={5}
                  value={Question}
                  ref={inpuRef}
                  onChangeText={_questionBox}
                  placeholderTextColor="grey"
                />
                {showFussyDropDown ? (
                  <FaqFussyDropDown
                    _setQuestion={(ques) => setQuestion(ques)}
                    onClose={() => setshowFussyDropDown(false)}
                    fuzzyDropDownData={fuzzyDropDownData}
                  />
                ) : (
                  <View />
                )}
              </View>
            </>
          }
          <View style={styles.categoryView}>
            <Text style={styles.title}>
              Which category does your query fall under?{" "}
            </Text>
          </View>
          <View
            style={[
              styles.askQuesView,
              Err["category"] && styles.errView,
              {
                marginTop: 15,
                borderColor: Err["category"] ? "red" : "#BDBDBD",
              },
            ]}
          >
            <FlatList
              data={Object.entries(subCategoryList)}
              numColumns={2}
              style={{ padding: 5 }}
              columnWrapperStyle={{ justifyContent: "center" }}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  onPress={() => handlecategorySelection(item)}
                  style={[
                    styles.queryOptButtons,
                    selctedCategory &&
                    selctedCategory[0] == item[0] &&
                    styles.selectedCategoryBorder,
                    { alignSelf: "center" },
                  ]}
                >
                  <Text
                    style={[
                      styles.queryOptButtonsText,
                      selctedCategory &&
                      selctedCategory[0] == item[0] &&
                      styles.selectedCategorText,
                    ]}
                  >
                    {item[0] == "tourism"
                      ? "TRAVEL"
                      : item[0].replace("_", " ").toUpperCase()}
                  </Text>
                </TouchableOpacity>
              )}
            />
          </View>
          {selctedCategory && selctedCategory[0] != "legal" ? (
            <>
              <View style={styles.categoryView}>
                <Text style={styles.title}>
                  What is your current stage?
                </Text>
              </View>
              <View
                style={[
                  styles.askQuesView,
                  Object.entries(selctedCategory[1]).length <=
                  3 && styles.currentStageView,
                  Err["currentStage"] && styles.errView,
                  { marginTop: 15 },
                ]}
              >
                <FlatList
                  data={Object.entries(selctedCategory[1])}
                  numColumns={2}
                  style={{ padding: 5 }}
                  columnWrapperStyle={{
                    justifyContent: "center",
                  }}
                  renderItem={({ item, index }) => (
                    <TouchableOpacity
                      onPress={() =>
                        handleCurrentStageClick(item)
                      }
                      style={[
                        styles.queryOptButtons,
                        selectedCurrentStage &&
                        selectedCurrentStage[0] == item[0] &&
                        styles.selectedCategoryBorder,
                        { alignSelf: "center" },
                      ]}
                    >
                      <Text
                        style={[
                          styles.queryOptButtonsText,
                          selectedCurrentStage &&
                          selectedCurrentStage[0] == item[0] &&
                          styles.selectedCategorText,
                        ]}
                      >
                        {item[0].replace("_", " ").toUpperCase()}
                      </Text>
                    </TouchableOpacity>
                  )}
                />
                <View style={{ height: 20 }} />
              </View>
            </>
          ) : (
            <View />
          )}
          {selectedCurrentStage ? (
            <>
              <View style={styles.categoryView}>
                <Text style={styles.title}>
                  Which sub-category does your query fall under?
                </Text>
              </View>
              <View
                style={[
                  styles.askQuesView,
                  Err["subCategory"] && styles.errView,
                  styles.caroselStyle,
                ]}
              >
                {Platform.OS == "web" ? (
                  <>
                    <WebCarousel>
                      {selectedCurrentStage[1].map(
                        (item, index) => (
                          <View style={styles.subCategoryItemContainer} key={index + "sb"}>
                            <TouchableOpacity
                              onPress={() =>
                                handleSubCategorySelection(item)
                              }
                              style={[
                                styles.subCategoryItem,
                                selectedsubcategory &&
                                selectedsubcategory ==
                                item.category &&
                                styles.selectedCategoryBorder,
                              ]}
                            >
                              <Image
                                source={{ uri: item.thumbnail }}
                                style={styles.categoryImg}
                              />
                              <Text
                                style={styles.subCategoryItemText}
                              >
                                {item.category}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )
                      )}
                    </WebCarousel>
                  </>
                ) : (
                  <>
                    <Carousel
                      ref={(c) => {
                        this._carousel = c;
                      }}
                      data={selectedCurrentStage[1]}
                      indicatorStyle="black"
                      pagingEnabled
                      onSnapToItem={(index) =>
                        setcurrentCaroselItem(index)
                      }
                      renderItem={({ item, index }) => (
                        <View
                          key={index + "c"}
                          style={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TouchableOpacity
                            onPress={() =>
                              handleSubCategorySelection(item)
                            }
                            style={[
                              styles.subCategoryItem,
                              selectedsubcategory &&
                              selectedsubcategory ==
                              item.category &&
                              styles.selectedCategoryBorder,
                            ]}
                          >
                            <Image
                              source={{ uri: item.thumbnail }}
                              style={styles.categoryImg}
                            />
                            <Text
                              style={styles.subCategoryItemText}
                            >
                              {item.category}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )}
                      sliderWidth={
                        Dimensions.get("window").width * 0.87
                      }
                      itemWidth={
                        Dimensions.get("window").width * 0.87
                      }
                    />
                    {pagination()}
                  </>
                )}
              </View>
            </>
          ) : (
            <View />
          )}
          <View style={styles.categoryView}>
            <Text style={styles.title}>
              Which country is your query about?
            </Text>
          </View>
          <View
            style={[
              styles.askQuesView,
              styles.counryViewHeight,
              Err["countryIntrest"] && styles.errView,
              { marginTop: 15 },
            ]}
          >
            <FlatList
              data={config.intrestcountry}
              numColumns={5}
              showsVerticalScrollIndicator={false}
              style={{ padding: 10 }}
              columnWrapperStyle={{ justifyContent: "center" }}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  style={styles.flagbuttons}
                  onPress={() => countrySelection(item.code)}
                >
                  <Image
                    style={styles.countryIcon}
                    source={getImage(item)}
                  />
                  <Text
                    style={{
                      fontSize: heightPercentageToDP("1.2%"),
                      fontFamily: fontFamily.semibold,
                      textAlign: "center"
                    }}
                  >
                    {item.label}
                  </Text>
                </TouchableOpacity>
              )}
            />
          </View>
          <View style={styles.categoryView}>
            <Text style={styles.title}>
              What is your Country of Residence?
            </Text>
          </View>
          <View
            style={[
              styles.dropDownView,
              Err["countryOfResidence"] && styles.errView,
              { marginTop: 15, },
            ]}
          >
            <RNPicker
              data={sortAplhabetically()}
              pickerTitle={"Country of Residence"}
              placeholderTextColor="#000000"
              showSearchBar={true}
              disablePicker={false}
              changeAnimation={"fade"}
              searchBarPlaceHolder={"Search....."}
              showPickerTitle={true}
              selectedText={countryOfResidence}
              placeHolderText={"Select Country of residence"}
              selectedTextStyle={styles.selectLabelTextStyle}
              pickerStyle={styles.pickerStyle}
              selectedValue={(index, item) =>
                _selectedValue1(index, item)
              }
            />
          </View>
          {
            props.askFriend ?
              <View style={{ flexDirection: 'row', alignItems: 'center', width: '83%' }}>
                <View style={styles.categoryView}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={[styles.title, { width: '40%' }]}>
                      Private Discussion
                    </Text>
                    {!isWeb() ?
                      <Tooltip
                        containerStyle={{
                          width: '40%', alignItems: 'flex-start',
                          bottom: 10,
                          height: 70, padding: 0, paddingLeft: 5
                        }}
                        popover={
                          <Text style={{ width: '80%' }}>Private discussions will be visible to all users but comments and replies can only be seen by you.</Text>
                        }
                        withPointer={false}
                      >
                        <View style={styles.questionView}>
                          <Text style={{ fontSize: 12 }}>
                            ?
                          </Text>
                        </View>
                      </Tooltip> : null}
                  </View>
                </View>
                <Switch
                  value={privateQuestion}
                  onValueChange={onToggleSwitch}
                  style={{ marginTop: heightPercentageToDP("4%") }} />
              </View>
              :
              <>
                <View style={styles.categoryView}>
                  <Text style={styles.title}>
                    What is your Country of Citizenship?
                  </Text>
                </View>
                <View
                  style={[
                    styles.dropDownView,
                    Err["countryOfCitizenship"] && styles.errView,
                    { marginTop: 15 },
                  ]}
                >
                  <RNPicker
                    data={config.countriesSelect}
                    defaultValue={true}
                    pickerTitle={"Country of citizenship"}
                    placeholderTextColor="#000000"
                    showSearchBar={true}
                    disablePicker={false}
                    changeAnimation={"fade"}
                    searchBarPlaceHolder={"Search....."}
                    showPickerTitle={true}
                    selectedText={countryOfCitizenship}
                    placeHolderText={"Select country of citizenship"}
                    selectedTextStyle={styles.selectLabelTextStyle}
                    pickerStyle={styles.pickerStyle}
                    selectedValue={(index, item) =>
                      _setcountryOfCitizenship(index, item)
                    }
                  />
                </View>
              </>
          }
        </>
      }
      case 2: {
        return <>
          <View
            style={[
              styles.categoryView,
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              },
            ]}
          >
            <Text
              style={[
                styles.title,
                {
                  width: 200,
                  fontSize: 16,
                  fontFamily: fontFamily.effra,
                },
              ]}
            >
              Choose Your Package
            </Text>
            <TouchableOpacity
              onPress={() =>
                discountApplied
                  ? setdiscountApplied(false)
                  : setshowDisModal(true)
              }
              style={[
                styles.availDiscountButton,
                {
                  borderColor: discountApplied ? "red" : "green",
                },
              ]}
            >
              <Text
                style={[
                  styles.availDiscountText,
                  {
                    color: discountApplied ? "red" : "green",
                  },
                ]}
              >
                {discountApplied
                  ? "Remove Discount"
                  : "Avail Discount"}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={[
              styles.askQuesView,
              Err["selectedPackage"] && styles.errView,
              { marginTop: 15 },
            ]}
          >
            <AdviceChannelPackage
              inApp_Products={inApp_Products}
              checkUserIsGuest={checkUserIsGuest}
              baseCurrency={baseCurrency}
              discountApplied={discountApplied}
              promoCodeApplied={promoCodeApplied}
              discountDetails={discountDetails}
              advicePackages={advicePackages}
              selectedSubCategoryTier={selectedSubCategoryTier}
              setFinalPackagePrice={_setFinalPackagePrice}
              onClose={() => setshowpackage(false)}
            />
          </View>
        </>
      }
      case 3: {
        return <>
          <View style={styles.categoryView}>
            <Text style={styles.title}>Enter your question</Text>
          </View>
          <View
            style={[
              styles.askQuesView,
              Err["Question"] && styles.errView,
            ]}
          >
            <TextInput
              style={[
                styles.title,
                {
                  marginTop: 5,
                  padding: 10,
                  fontSize: mobilBrower()
                    ? heightPercentageToDP("1.5%")
                    : 14,
                  color: "black",
                  marginLeft: 10,
                  width: "99%",
                },
              ]}
              placeholder="Enter your question"
              multiline={true}
              numberOfLines={5}
              value={Question}
              ref={inpuRef}
              onChangeText={_questionBox}
              placeholderTextColor="grey"
            />
            {showFussyDropDown ? (
              <FaqFussyDropDown
                _setQuestion={(ques) => setQuestion(ques)}
                onClose={() => setshowFussyDropDown(false)}
                fuzzyDropDownData={fuzzyDropDownData}
              />
            ) : (
              <View />
            )}
          </View>
          <View style={styles.categoryView}>
            <Text style={styles.title}>
              Upload document (if any)
            </Text>
          </View>
          <View
            style={[
              styles.askQuesView,
              Err["uploadedDocName"] && styles.errView,
              {
                marginTop: 15,
                paddingVertical: 15,
                alignItems: "center",
                justifyContent: "center",
              },
            ]}
          >
            <TouchableOpacity
              onPress={uploadDocument}
              style={{ alignItems: "center" }}
            >
              <Image
                source={
                  Platform.OS == "web"
                    ? uploadIcon
                    : Images.uploadIcon
                }
                style={{
                  height: 18,
                  width: 18,
                  resizeMode: "contain",
                }}
              />
              <Text
                style={{
                  fontFamily: fontFamily.robotoRegular,
                  fontSize: heightPercentageToDP("1.5%"),
                  marginTop: 5,
                }}
              >
                {uploadedDocName ? uploadedDocName : "Upload"}
              </Text>
            </TouchableOpacity>
            {uploadedDocName ?
              <TouchableOpacity
                onPress={removeUploadedDocument}
                style={{ alignItems: "center", position: "absolute", right: 10, top: 10 }}
              >
                <Image
                  source={Images.closeBlack}
                  style={{
                    height: 18,
                    width: 18,
                    resizeMode: "contain",
                  }}
                />
              </TouchableOpacity> : null}
          </View>
          <View style={styles.noteView}>
            <Text
              style={[
                styles.noteTitle,
                { fontSize: heightPercentageToDP("1.8%") },
              ]}
            >
              Note:
            </Text>
            <FlatList
              data={helpers.epxertNotes}
              renderItem={({ item, index }) => (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems:
                      Platform.OS == "web"
                        ? "center"
                        : "flex-start",
                    marginTop: 5,
                  }}
                >
                  {Platform.OS == "web" ? (
                    <GoPrimitiveDot
                      style={{ opacity: 0.5, marginRight: 5 }}
                    />
                  ) : (
                    <Text style={styles.dotStyle}>.</Text>
                  )}
                  <Text style={styles.noteTitle}>{item}</Text>
                </View>
              )}
            />
          </View>
        </>
      }
      default: return <>
        <View
          style={[
            styles.askQuesView,
            {
              marginTop: 15,
              backgroundColor: "#F8F8F8",
              marginBottom: 14,
              padding: 15,
            },
          ]}
        >
          <Text style={styles.summaryTitle}>SUMMARY </Text>
          <FlatList
            data={Object.entries(userSelectionsObj)}
            renderItem={({ item, index }) =>
              item[1] ? (
                <View style={styles.summaryView}>
                  <View style={styles.labelView}>
                    <Text style={styles.labelQ}>{item[0]}</Text>
                  </View>
                  <View style={styles.contentView}>
                    <Text style={styles.label}>
                      {isArray(item[1])
                        ? item[1].join(", ")
                        : item[1]}
                    </Text>
                  </View>
                </View>
              ) : null
            }
          />
        </View>
      </>;
    }
  }

  useEffect(() => {
    if (selctedCategory && selctedCategory[0] === "legal") {
      setselectedCurrentStage(Object.entries(selctedCategory[1])[0]);
    }
  }, [selctedCategory]);

  const getContent = () => {
    return (
      <View style={styles.mainView}>
        <View style={styles.innerView}>
          <View style={[
            styles.contentContainer,
          ]}>
            {modalLoad ? (
              <View style={styles.loaderbackground}>
                <ActivityIndicator
                  color="gray"
                  size="large"
                  style={styles.loaderE}
                />
              </View>
            ) : (
              <>
                <View style={styles.titleView}>
                  <TouchableOpacity
                    onPress={() => handleBackButton()}
                    style={styles.backButton}
                  >
                    <Image
                      source={Images.blackbackIcon}
                      style={styles.backIcon}
                    />
                  </TouchableOpacity>
                  <Text
                    style={[
                      styles.titleText,
                      {
                        fontSize: heightPercentageToDP("2.4%"),
                        fontFamily: fontFamily.titilliumBold,
                      },
                    ]}
                  >
                    {!props.askFriend ? 'Ask Our Expert' : 'Ask Community'}
                  </Text>
                  <Text style={styles.titleText}>{!props.askFriend && `${currentStep}/4`}</Text>
                  <TouchableOpacity
                    onPress={() => props.onClose()}
                    style={styles.backButton}
                  >
                    <Image
                      source={Images.closeBlack}
                      style={styles.backIcon}
                    />
                  </TouchableOpacity>
                </View>
                <ScrollView
                  nestedScrollEnabled={true}
                  ref={scrollViewRef}

                >
                  <TouchableOpacity activeOpacity={1}>
                    <TrailFromRight>
                      {renderCurrentStep()}
                      <TouchableOpacity
                        disabled={loading}
                        onPress={currentStep > 3 ? callPurchase : props.askFriend ? handleCreateDiscussion : handleNextClick}
                        style={[
                          styles.nextButton,
                          {
                            paddingLeft: currentStep > 3 ? 10 : 0,
                            alignItems: currentStep > 3 ? "flex-start" : "center",
                          },
                        ]}
                      >
                        <Text style={styles.nextButtonText}>
                          {currentStep > 3
                            ? `PAY ${helpers.getCurrencySymbol(
                              baseCurrency
                            )} ${selectedPackagePrice} `
                            : "Next"}
                        </Text>
                      </TouchableOpacity>
                    </TrailFromRight>
                  </TouchableOpacity>
                </ScrollView>
              </>
            )}
          </View>
          {showErrorModal ? (
            <ErrorModal
              errorMessage={errorMessage}
              guest={guestLogin}
              from="adviceForm"
              closeModal={() => setshowErrorModal(false)}
            />
          ) : (
            <View />
          )}
          {showDisModal && (
            <DiscountModal
              handleDiscount={handleDiscount}
              onClose={() => setshowDisModal(false)}
            />
          )}
        </View>
      </View>
    );
  };

  return (
    <Modal animationType="slide" transparent>
      <BlurBackground />
      {loading ? <Loader /> : <View />}
      {getContent()}
    </Modal>
  );
}
