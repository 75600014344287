import { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { scrollToTop } from './helpers';

function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        scrollToTop();
    }, [location]);

    return (null);
}

export default withRouter(ScrollToTop);
