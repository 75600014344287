import { StyleSheet } from "react-native";
import { responsiveText } from "../../common/normalize";

export const styles = StyleSheet.create({
    title: {
        fontSize: responsiveText(20),
        textAlign: "center",
        color: "#030135",
    },
    description: {
        fontSize: responsiveText(16),
        textAlign: "center",
        width: "80%",
        marginVertical: "7%",
        color: "#030135",
    },
    btn: {
        paddingVertical: 8,
        marginTop: 5,
        width: "80%"
    },
    btnText: {
        padding: 5,
        paddingLeft: 7,
        textAlign: "center",
        color: "#030135",
        paddingRight: 7,
    },
});
