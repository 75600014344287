import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, TouchableOpacity, Image, FlatList } from "react-native";
import fontFamily from "../common/fontFamily";
import { mobilBrower } from "../common/helpers";
import { Images, helpers } from "../common";
import HeaderComponent from "../common/NewHeader/NewHeader";
import redeemStoreIcon from "../images/redeemStoreIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { Network } from "../service/api";
import { clearCart, updateCart } from "../actions/referral.actions";
import RazorpayCheckout from "react-native-razorpay";
import { useHistory, useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import * as animationData from "./../common/animations/successTick.json";
import { responsiveText } from "../common/normalize";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const _handleRazorPay = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export default function Cart(props) {
  const cartItems = useSelector(
    (state) => state.referralReducer.referralData.cartItems
  );
  const [productIds, setproductIds] = useState([]);
  const [total, settotal] = useState(0);
  const [deliveryFeeTotal, setdeliveryFeeTotal] = useState(0);
  const storeItems = useSelector(
    (state) => state.referralReducer.referralData.storeItems
  );
  const token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const baseCurrency = useSelector(
    (state) => state.referralReducer.referralData.baseCurrency
  );
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const [animationFinished, setanimationFinished] = useState(false);
  const { push, goBack } = useHistory();
  const [showAnimation, setshowAnimation] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    let total = 0;
    let productIds = [];
    cartItems &&
      cartItems.forEach((s) => {
        let price = s.offer_price ? s.offer_price : s.price;
        let quantity = s.quantity ? s.quantity : 1;
        let _t = price * quantity;
        total += _t;
        productIds.push(s.product_id);
      });
    setproductIds(productIds);
    total = Number(total);
    total += Number(deliveryFeeTotal);
    settotal(total);
  }, [cartItems, deliveryFeeTotal]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.has("purchaseSuccess")) {
      let purchaseSuccess = queryParams.get("purchaseSuccess");
      setshowAnimation(purchaseSuccess);
    }
  }, []);

  const getShippingCost = async (country) => {
    setloading(true);
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let quantity = 0;
    let base_weight = 0;
    cartItems &&
      cartItems.forEach((s) => {
        quantity += s.quantity;
        base_weight += s.base_weight;
      });
    let body = {
      product_details: {
        quantity,
        base_weight,
      },
      country: country,
    };
    let res = await Network("/data/get_shipping_cost", "POST", body, headers).catch(e => {
      return {}
    });
    if (res.status == "Success") {
      setloading(false);
      let shippingCost = helpers.storeCurrencyConverer(
        baseCurrency,
        res.shipping
      );
      setdeliveryFeeTotal(shippingCost);
      return res;
    } else {
      setloading(false);
      return res;
    }
  };

  useEffect(() => {
    if (userDetails?.address?.country) {
      getShippingCost(userDetails?.address?.country);
    }
  }, [userDetails, storeItems]);

  const registerPurchase = async (data, addressData, payStatus) => {
    const url = "/wallet/immistore_order_history";
    let purhase_url = "/wallet/immistore_purchase";
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    try {
      let payload = {
        razorpay_order_details: data,
        orders: cartItems,
        address: addressData.address,
        phone: addressData.phone,
        email_address: addressData.email_address,
        name: addressData.name,
        is_payment_successful: payStatus,
        total_pay: total,
        delivery_fee: deliveryFeeTotal,
      };
      if (payStatus) {
        let res = await Network(purhase_url, "POST", payload, headers);
        if (res.status == "Success") {
          // props.navigation.navigate("OrderConfirmation", { userCart: true, orderDetails: payload, orderId: res.order_id });
          dispatch(clearCart());
        }
      }
    } catch (error) { }
  };

  const handlePress = (addressData) => {
    const url = "/razorpay/create_order";
    let razorPayIds = {};
    const logPayUrl = `/users/${userDetails?.userid}/log_payment`;
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let body = {
      order_amount: total * 100,
      statement: `immistore purchase for ${productIds.join(",")}`,
      order_currency: "INR",
      order_receipt: `immi_recpt_IMST${userDetails?.userid
        }_${new Date().getTime()}`,
    };

    setloading(true);
    const razorpay_key = global.IS_TEST_SERVER
      ? "rzp_test_dIpZ7foMvdqmL7"
      : "rzp_live_r5d9JWaeFWN56Z";
    Network(url, "POST", body, headers).then(async(res) => {
      var options = {
        description: 'Immistore Purchase',
        image: Images.toolbarLogo,
        currency: 'INR',
        key: razorpay_key,
        "handler": function (response) {
            setloading(true)
            razorPayPaymentStatus = true;
            razorPayIds.razorpay_payment_id = response.razorpay_payment_id;
            razorPayIds.razorpay_order_id = response.razorpay_order_id;
            razorPayIds.razorpay_signature = response.razorpay_signature;
            Network(logPayUrl, 'POST', paymentBody, headers);
            razorPayIds.order_id = res.order_details.id;
            razorPayIds.order_receipt = paymentBody.payment_type.additional_info.order_receipt;
            registerPurchase(razorPayIds, addressData, true)
        },
        amount: total * 100,
        name: 'Immigreat Pvt. Ltd.',
        order_id: res.order_details.id,
        prefill: {
            email: addressData.email_address,
            contact: addressData.phone,
            name: addressData.name
        },
        theme: { color: '#FFAA00' }
    };
      const paymentBody = {
        user_id: userDetails?.userid,
        amount: total,
        statement: `immistore purchase for ${productIds.join(",")}`,
        payment_type: {
          type: "razorpay",
          additional_info: {
            order_id: res.order_details.id,
            order_receipt: body.order_receipt,
            products: cartItems,
          },
        },
      };
      const razorypayResponse = await _handleRazorPay('https://checkout.razorpay.com/v1/checkout.js');
      if (!razorypayResponse) {
        alert('Razorpay failed to load!');
        return
    }
    try {
        const payObj = new window.Razorpay(options);
        payObj.open();
        payObj.on('payment.failed', function (response) {
            setloading(false)
            registerPurchase(paymentBody.payment_type.additional_info, addressData, false)
        })
    } catch (error) {

    }
      // RazorpayCheckout.open(options)
      //   .then((data) => {
      //     setloading(false);
      //     setpaySuccess(true);
      //     Network(logPayUrl, "POST", paymentBody, headers);
      //     data.order_id = res.order_details.id;
      //     data.order_receipt =
      //       paymentBody.payment_type.additional_info.order_receipt;
      //     registerPurchase(data, addressData, true);
      //     // alert(`Success: ${data.razorpay_payment_id}`);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     setloading(false);
      //     registerPurchase(
      //       paymentBody.payment_type.additional_info,
      //       addressData,
      //       false
      //     );
      //     // // seterrorMsg("Payment Failed");
      //     // setshowErrorModal(true);
      //     // setTimeout(() => {
      //     //   setshowErrorModal(false);
      //     // }, 3200);
      //   });
    });
  };

  const handleIncrement = (item) => {
    let _cartItems = cartItems;
    _cartItems.forEach((s) => {
      if (s.uniqId == item.uniqId) {
        s.quantity = s.quantity ? s.quantity + 1 : 2;
        if (s.quantity >= 10) {
          s.quantity = 10;
          return;
        }
      }
    });
    dispatch(updateCart(_cartItems));
  };

  const removeItemFromCart=(item)=>{
    let _cartItems = cartItems;
    let newCart = _cartItems.filter((s) => s.uniqId !== item.uniqId);
      dispatch(updateCart(newCart));
  }

  const handledDecrement = (item) => {
    let _cartItems = cartItems;
    let _item = _cartItems.filter((s) => s.uniqId == item.uniqId);
    if (item && (item.quantity == 1 || !item.quantity)) {
      let newCart = _cartItems.filter((s) => s.uniqId !== item.uniqId);
      dispatch(updateCart(newCart));
    } else {
      _cartItems.forEach((s) => {
        if (s.uniqId == item.uniqId) {
          s.quantity = s.quantity - 1;
        }
      });
      dispatch(updateCart(_cartItems));
    }
  };

  const handleContinue = () => {
    push("/store");
  };

  handleChangeAddress = () => {
    push("/billing-details");
  };

  const handleProceed = () => {
    try {
      if (userDetails?.address) {
        let body = {
          address: {
            address_line1: userDetails?.address?.address_line1,
            address_line2: userDetails?.address?.address_line2,
            country: userDetails?.address?.country,
            city: userDetails?.address?.city,
            pincode: userDetails?.address?.pincode,
            state: userDetails?.address?.state,
          },
          name: userDetails?.first_name,
          phone: userDetails?.phone,
          email_address: userDetails?.email_address,
        };
        cartItems.length > 0 && handlePress(body);
      } else {
        push("/billing-details");
      }
    } catch (error) {
      console.log(error);
     }
  };

  const handleCallback = () => {
    setanimationFinished(true);
    push("/order-confirmation");
    dispatch(clearCart());
  };
  return (
    <>
      <HeaderComponent screenTitle={mobilBrower() ? "Cart" : ""} showback={true} />
      <View style={styles.mainView}>
        {!mobilBrower() ?
          <View style={{ width: "100%", flexDirection: "row", alignItems: "center", marginVertical: 30, }} >
            <TouchableOpacity onPress={goBack}>
              <Image source={Images.leftArrow} style={{ width: 20, height: 20 }} />
            </TouchableOpacity>
            <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
              <Text style={styles.mainView_title}>Cart</Text>
            </View>
          </View> : null}
        <FlatList
          style={{ width: "90%" }}
          data={cartItems}
          ListEmptyComponent={() => (
            <View style={styles.emptyView}>
              <Text style={styles.emptyViewText}>Your Cart is empty</Text>
            </View>
          )}
          renderItem={({ item, index }) => {
            return (
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                  height: "20vh",
                  marginVertical: 20
                }}
                disabled
              >
                <Image
                  source={redeemStoreIcon}
                  style={styles.redeemedIconStyle}
                />
                <View style={styles.mobile_redeemItems}>
                  <View style={styles.ImageView}>
                    <Image
                      source={{ uri: item.thumbnail }}
                      style={styles.thumbnail}
                    />
                  </View>
                  <View style={styles.contentView}>
                    <View style={{ flexDirection: "row",width:"100%", justifyContent: "space-between" }}>
                      <Text style={[styles.contentView_title,{flex:1}]}>{item?.title}</Text>
                      <TouchableOpacity onPress={()=>{removeItemFromCart(item)}}>
                        <Image
                          source={{ uri: Images.closeBlack }}
                          style={{height:20,width:20,marginHorizontal:15}}
                        />
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 7,
                        justifyContent: "flex-start",
                        marginLeft: -7,
                      }}
                    >
                      {item.color ? (
                        <View
                          style={{
                            height: 16,
                            width: 16,
                            borderRadius: 8,
                            backgroundColor: item.color,
                            marginLeft: 5,
                          }}
                        />
                      ) : (
                        <View />
                      )}
                      {item.size ? (
                        <View style={styles.sizeView}>
                          <Text style={{ fontSize: 7 }}>{item.size}</Text>
                        </View>
                      ) : (
                        <View />
                      )}
                    </View>
                    <View style={styles.priveView}>
                      <View style={{ flexDirection: "row" }}>
                        {item.original_price ?
                          <Text style={[styles.contentView_title, { textDecorationLine: "line-through", marginRight: 10 }]}>
                            {`${baseCurrency && helpers.getCurrencySymbol(baseCurrency)}  ${item.original_price}`}
                          </Text> : null}
                        <Text style={styles.contentView_title}>
                          {`${baseCurrency && helpers.getCurrencySymbol(baseCurrency)}  ${item.price || item.offer_price}`}
                        </Text>
                      </View>
                      <View style={{ alignItems: "flex-end" }}>
                        <View style={styles.incrementView}>
                          <TouchableOpacity
                            onPress={() => handledDecrement(item)}
                            style={styles.incrementButton}
                          >
                            <Text style={{ color: "white" }}>-</Text>
                          </TouchableOpacity>
                          <Text style={styles.quantityText}>
                            {item?.quantity}
                          </Text>
                          <TouchableOpacity
                            onPress={() => handleIncrement(item)}
                            style={styles.incrementButton}
                          >
                            <Text style={{ color: "white" }}>+</Text>
                          </TouchableOpacity>
                        </View>
                        {item?.quantity >= 10 ?
                          <Text style={[styles.quantityText, { marginRight: 20 }]}>{"*max 10 items"}</Text> : null}
                      </View>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            );
          }}
        />
        <TouchableOpacity
          onPress={handleContinue}
          style={styles.addToCartButton}
        >
          <Text style={styles.addToCartButton_text}>Continue Shopping</Text>
        </TouchableOpacity>
        {cartItems.length > 0 && (
          <View style={{ width: "90%" }}>
            <View style={styles.totalView}>
              <Text onPress={handleChangeAddress} style={styles.itemsSelected}>
                Change address
              </Text>
              <View style={styles.total_bottomView}>
                <Text style={styles.totalText}>Shipping</Text>
                <Text style={styles.totalText}>
                  {`${baseCurrency && helpers.getCurrencySymbol(baseCurrency)
                    } ${deliveryFeeTotal}`}
                </Text>
              </View>
              <View style={styles.total_bottomView}>
                <Text style={styles.totalText}>Total</Text>
                <Text style={styles.totalText_amt}>
                  {`${baseCurrency && helpers.getCurrencySymbol(baseCurrency)
                    } ${total}`}
                </Text>
              </View>
              <TouchableOpacity
                onPress={handleProceed}
                style={styles.checkoutButton}
              >
                <Text style={styles.checkoutButton_text}>
                  Proceed to Checkout
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
      {showAnimation && !animationFinished ? (
        <View style={styles.animationStyle}>
          <Lottie
            options={defaultOptions}
            height={400}
            width={400}
            eventListeners={[
              {
                eventName: "complete",
                callback: () => handleCallback(),
              },
            ]}
          />
        </View>
      ) : (
        <View />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  mainView: {
    width: "100%",
    alignSelf: "center",
    alignItems: "center",
  },
  checkoutButton: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 25,
    borderRadius: 10,
    backgroundColor: "#F1AB32",
    marginBottom: "6vh",
    height: "5vh",
  },
  mainView_title: {
    fontWeight: "800",
    fontSize: responsiveText(22),
  },
  redeemedIconStyle: {
    width: "3vh",
    height: "3vh",
  },
  totalText: {
    fontSize: responsiveText(14),
    fontWeight: "600",
  },
  checkoutButton_text: {
    fontSize: responsiveText(14),
    color: "white",
    fontWeight: "600",
  },
  totalText_amt: {
    fontSize: responsiveText(14),
  },
  emptyView: {
    marginTop: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  emptyViewText: {
    fontSize: responsiveText(16),
    fontFamily: fontFamily.effra,
  },
  sizeView: {
    height: 16,
    width: 16,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    marginLeft: 10,
  },
  animationStyle: {
    position: "absolute",
    alignItems: "center",
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
  itemsSelected: {
    fontSize: responsiveText(14),
    textDecorationLine: "underline",
    color: "blue",
  },
  total_bottomView: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 30,
  },
  totalView: {
    alignItems: "center",
    width: "100%",
    marginBottom: 30,
  },
  addToCartButton: {
    width: "60%",
    borderRadius: 10,
    alignItems: "center",
    marginTop: "5vh",
    justifyContent: "center",
    backgroundColor: "#0F1143",
    marginBottom: "3vh",
    height: "5vh",
  },
  mainView: {
    width: mobilBrower() ? "100%" : "50%",
    alignSelf: "center",
    alignItems: "center",
  },
  addToCartButton_text: {
    color: "white",
    fontFamily: fontFamily.effra,
    fontWeight: "600",
    fontSize: responsiveText(12),
  },
  mobile_redeemItems: {
    marginHorizontal: 15,
    height: "100%",
    flex: 1,
    alignItems: "center",
    borderRadius: 10,
    shadowColor: "#000000",
    overflow: "hidden",
    shadowRadius: 4,
    shadowOpacity: 0.5,
    flexDirection: "row",
  },
  redeemItems: {
    width: "25%",
    // height: '140px',
    margin: 15,
    alignItems: "center",
    borderRadius: 10,
    alignSelf: "center",
    shadowColor: "#000000",
    overflow: "hidden",
    shadowRadius: 4,
    shadowOpacity: 0.5,
    flexDirection: "row",
  },
  redeemableView: {
    width: "100%",
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
  },
  redeemableView_title: {
    fontSize: 25,
    marginTop: 30,
    marginBottom: 20,
    fontFamily: fontFamily.effra,
  },
  ImageView: {
    flex: 0.6,
    height: "100%",
    padding: 5,
    borderRadius: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "#D8D8D8",
  },
  thumbnail: {
    flex: 1,
    aspectRatio: 1,
    borderRadius: 10,
  },
  contentView: {
    flex: 1,
    marginLeft: 15,
    marginTop: 5,
    alignItems: "flex-start",
    height: "90%",
  },
  priveView: {
    flex: 1,
    flexDirection: "column",
    width: "100%",
    marginTop: 15,
  },
  incrementView: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-end",
    marginRight: 20,
    marginVertical: 10,
  },
  incrementButton: {
    width: "2.5vh",
    height: "2.5vh",
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F1AB32",
  },
  quantityText: {
    marginLeft: 10,
    fontSize: responsiveText(12),
    marginRight: 10,
  },
  contentView_title: {
    fontFamily: fontFamily.effra,
    paddingRight: 5,
    fontSize: responsiveText(13),
  },
  contentView_description: {
    fontSize: responsiveText(12),
    marginTop: 10,
    paddingRight: 5,
    color: "#77838F",
  },
  redeemButton: {
    backgroundColor: "#F1AB32",
    borderRadius: 10,
    alignItems: "center",
    marginRight: 20,
    justifyContent: "center",
  },
  redeemButton_text: {
    color: "white",
    paddingLeft: 15,
    fontSize: mobilBrower() ? 25 : 13,
    paddingRight: 15,
    padding: 5,
  },
});
