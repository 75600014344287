import { StyleSheet } from "react-native";
import { family } from "../../common";
import { responsiveText } from "../../common/normalize";

export const styles = StyleSheet.create({
  countryMainView: {
    width: "100%",
    borderRadius: 10,
    alignItems: "center",
    shadowColor: "grey",
    borderColor: "#030135",
  },
  title: {
    alignSelf: "center",
    width: "80%",
    fontFamily: family.regular,
    marginBottom: 8,
    marginLeft: 5,
    marginTop: 10,
    fontSize: 19,
    color: "#0F1143",
    alignSelf: "center",
    textAlign: "center",
    letterSpacing: 1,
  },
  countiesView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 0,
    width:"100%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  flagbuttons: {
    height: 80,
    alignItems: "center",
    width:"20%",
    // margin:10
  },
  countryIcon: {
    height: 50,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: "center",
  },
  countryLabel:{
    fontSize: responsiveText(9),
    fontFamily: family.semibold,
    textAlign:"center"
  },
  immigrationInterestText: {
    color: "white",
    fontSize: responsiveText(12),
    fontFamily: family.semibold,
  },
  immigrationInterestContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  immigrationInterestButtons: {
    width: "45%",
    padding:5,
    paddingVertical:10,
    alignItems: "center",
    borderWidth: 1,
    justifyContent: "center",
    borderRadius: 5,
    marginBottom: 10,
  },
});
