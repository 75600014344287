// import reactotron from "reactotron-react-native";

const initialState = {
  subCategories: {},
  userselections: []
};

const adviceChannelReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADVICE_SUB_CATEGORIES':
      return {
        ...state,
        subCategories: action.payload,
      };
    case 'STORE_USER_SELECTIONS':
      // reactotron.log('1XXXXXXXXXXX23', action)
      return {
        ...state,
        userselections: action.payload,
      };
    default:
      return state;
  }
};

export default adviceChannelReducer;
