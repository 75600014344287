import React, { useEffect, useState } from "react";
import {
    Text,
    View,
    TouchableOpacity
} from "react-native";
import { styles } from "./../CostOfLiving.styles";
import DropDownPicker from "react-native-dropdown-picker";
import { useSelector } from "react-redux";

export default function Step1({ handleNext = () => { }, selectedCountry, onCountrySelect = () => { } }) {
    const [cityDropDown, setcityyDropDown] = useState(null);
    const userDetails = useSelector(
        (state) => state.userReducer.getUser.userDetails
    );
    const facts = useSelector((state) => state.factReducer.factData);

    useEffect(() => {
        let _countryDropDown = [];
        facts.countryData.forEach((item) => {
            _countryDropDown.push({
                label: `${item.City}`,
                value: { id: item.city_id, country_id: item.country_id },
            });
        });
        setcityyDropDown(_countryDropDown);
    }, [userDetails]);



    return (
        <>
            <View style={{ flex: 1, width: "100%" }}>
                <Text
                    style={[
                        styles.TitleText,
                        { marginTop: 10 },
                    ]}
                >
                    SELECT A CITY
                </Text>
                {cityDropDown && cityDropDown.length > 0 ? (
                    <DropDownPicker
                        items={cityDropDown}
                        containerStyle={{
                            height: 50,
                            width: "90%",
                            marginTop: 30,
                            alignSelf: "center",
                        }}
                        style={{
                            backgroundColor: "#F5F6F8",
                            alignSelf: "center",
                            width: "100%"
                        }}
                        itemStyle={{
                            justifyContent: "flex-start",
                        }}
                        placeholder={selectedCountry?.label || "Select City"}
                        searchablePlaceholder="Search"
                        searchable={true}
                        zIndex={5000}
                        dropDownMaxHeight={180}
                        dropDownStyle={{ backgroundColor: "#fafafa" }}
                        onChangeItem={onCountrySelect}
                    />
                ) : (
                    <View />
                )}
            </View>
            <TouchableOpacity
                onPress={handleNext}
                disabled={!selectedCountry}
                style={[styles.nextButton, !selectedCountry && styles.disabled]}
            >
                <Text style={styles.nextButtonText}>NEXT</Text>
            </TouchableOpacity>
        </>

    );
}
