import React, { useState } from "react";
import {
  StyleSheet,
  // Text,
  // View,
  StatusBar,
  TouchableOpacity,
  Alert,
  Dimensions,
  Image,
} from "react-native";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  View,
  Text
} from "native-base";
const { width } = Dimensions.get("window");
import { Images } from "../common";
import { callToPathExpGuide, restartEligibilitQuesGuide, 
  restartEligibilitPathHeaderGuide, restartTimelineGuide, 
  restartsaveImmiPathGuide, restartComparatorGuide, 
  restartAdvicechannelGuide, callAdviceChannelNextQuide, restartNewsQuide } from "../actions/path.actions";
import StarRating from "react-native-star-rating";
import Iconn from "react-native-vector-icons/AntDesign";
import Feather from "react-native-vector-icons/Feather";
import { normalize, fonts, colors, family } from "../common";
import AsyncStorage from '@react-native-community/async-storage';
import { ifIphoneX } from 'react-native-iphone-x-helper'
import * as Animatable from 'react-native-animatable';
import StepIndicatorView from "./StepIndicator";
import ErrorModal from "./../components/errorModal";

const labels = [
  "Cart",
  "Delivery Address",
  "Order Summary",
  "Payment Method",
  "Track"
];
const customStyles = {
  stepIndicatorSize: 20,
  currentStepIndicatorSize: 20,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 3,
  stepStrokeCurrentColor: colors.WHITE,
  stepStrokeWidth: 3,
  stepStrokeFinishedColor: colors.ACTIVE_GREEN,
  stepStrokeUnFinishedColor: colors.LIGHT_TEXT_GREY,
  separatorFinishedColor: colors.ACTIVE_GREEN,
  separatorUnFinishedColor: colors.LIGHT_TEXT_GREY,
  stepIndicatorFinishedColor: colors.ACTIVE_GREEN,
  stepIndicatorUnFinishedColor: colors.LIGHT_TEXT_GREY,
  stepIndicatorCurrentColor: colors.WHITE,
  stepIndicatorLabelFontSize: 9,
  currentStepIndicatorLabelFontSize: 9,
  stepIndicatorLabelCurrentColor: "#ffffff",
  stepIndicatorLabelFinishedColor: "#ffffff",
  stepIndicatorLabelUnFinishedColor: "#ffffff",
  labelColor: "#999999",
  currentStepLabelColor: colors.INDICATOR_BLUE
};
const styles = StyleSheet.create({
  container: {
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    // ,
    width: "100%",
    backgroundColor: colors.LIGHT_BLUE,
    elevation: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    justifyContent: "center"
  },
  containerSep: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: "100%",
    backgroundColor: colors.LIGHT_BLUE,
    justifyContent: "center"
  },
  snackBar: {
    marginTop: normalize(105),
    marginBottom: normalize(145),
    width: width + 28,
    marginLeft: -14
  },
  snackBarSep: {
    marginTop: normalize(105),
    width: width + 28,
    marginLeft: -14
  },
  wrap: {
    alignItems: "center",
    flexDirection: "row",
    marginHorizontal: normalize(50),
    height: normalize(130),
    marginTop: normalize(96),
    justifyContent: "center"
  },
  wrap2: {
    alignItems: "center",
    flexDirection: "row",
    marginHorizontal: normalize(50),
    height: normalize(110),
    marginTop: normalize(116),
    justifyContent: "center",
    marginBottom: 29
  },
  viewIcon: {
    justifyContent: "center",
    height: normalize(50, true),
    width: normalize(25, true)
  },
  viewTitle: {
    flex: 1,
    alignItems: "center",
    marginLeft: normalize(50)
  },
  text1: {
    fontSize: fonts.font_16,
    textAlign: "center",
    color: colors.WHITE,
    fontFamily: family.bold
  },
  imageWrapp: {
    width: 30,
    height: 30
  },
  imageWrap: {
    paddingHorizontal: normalize(5)
  },
  image: {
    width: normalize(105, true),
    height: normalize(105, true),
    borderRadius: normalize(105, true) /2,
    marginLeft: 5
  },
  savedNews: {
    width: 30,
    height: 30,
    marginLeft: 5,
    marginRight: 10
  },
  logoImage: {
    width: normalize(90, true),
    height: normalize(90, true),
  },
  image2: {
    width: normalize(120, true),
    height: normalize(120, true),
    resizeMode: "contain",
    marginLeft: -10,
    marginBottom: 3
  },
  qimage: {
    width: normalize(71, true),
    height: normalize(71, true)
  }
});
const MainView = React.memo(props => {
  const authReducerPic = useSelector((state) => state.authReducer.getUserProfilePic.profilePic ? state.authReducer.getUserProfilePic.profilePic : '');
  const callPathExpGuide = useSelector(state => state.pathReducer.getImmipathDetails.callPathExpGuide);
  const restartQuesGuide = useSelector(state => state.pathReducer.getImmipathDetails.restartQuesGuide);
  const restartAppTimelineGuide = useSelector(state => state.pathReducer.getImmipathDetails.restartAppTimelineGuide);
  const restartsaveimmipath = useSelector(state => state.pathReducer.getImmipathDetails.restartsaveimmipath);
  const guestLogin = useSelector(state => state.pathReducer.getImmipathDetails.guestLogin)
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [errorMsg, seterrorMsg] = useState(null)
  const restartComparator = useSelector(state => state.pathReducer.getImmipathDetails.restartComparator);
  const restartAdviceChannelguideStatus = useSelector(state => state.pathReducer.getImmipathDetails.restartAdviceChannelguideStatus);
  const restartNewsGuideStatus = useSelector(state => state.pathReducer.getImmipathDetails.restartNewsGuideStatus);

  const dispatch = useDispatch();

  const handleQiconClick = () => {
      if(props.title == 'Eligibility Questionnaire'){
        dispatch(restartEligibilitQuesGuide(!restartQuesGuide))
      }else if(props.title == 'Immipath Search'){
        dispatch(callToPathExpGuide(!callPathExpGuide))
      }else if(props.title == 'Eligible Paths Found'){
        dispatch(restartEligibilitPathHeaderGuide(true))
      }else if(props.title == 'Application Timeline'){
        dispatch(restartTimelineGuide(!restartAppTimelineGuide))
      }else if(props.title == 'Save Immipath Search'){
        dispatch(restartsaveImmiPathGuide(true))
      }else if(props.title == 'Locality Facts Selection'){
        dispatch(restartComparatorGuide(!restartComparator))
      }else if(props.title == 'Advice Channel'){
        dispatch(restartAdvicechannelGuide(!restartAdviceChannelguideStatus))
        dispatch(callAdviceChannelNextQuide(false))
      }else if(props.title == 'News'){
        dispatch(restartNewsQuide(!restartNewsGuideStatus))
      }
  }

  const handleProfileClick = () =>{
    if(guestLogin){
      setshowErrorModal(true)
      seterrorMsg('Login to explore all the features of the app!')
    }else {
      props.profileView()
    }
  }

  return (
    <View>
    <View style={[props.simpleCase ? styles.wrap2 : styles.wrap, { }]}>
      {(props.isBackIcon ) ? (
        <TouchableOpacity
          onPress={() => props.didTapOnBack()}
          style={{
            width: normalize(80),
            height: normalize(80),
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 10
          }}
        >
          <Iconn name="left" style={{ fontFamily: family.semibold }} size={16} color={colors.WHITE} />
        </TouchableOpacity>
      ): <View/>}
      {!props.isBackIcon  ? (
        <TouchableOpacity activeOpacity={props.isDashboard ? 0.7 : 1} onPress={() => props.isDashboard ? props.handleNotificationModalToggle() : props.navigate ? props.navigate.navigate('Home') : console.log("Empty navigation")} >
          <Animatable.Image animation="fadeIn" duration={1500} source={ props.isDashboard ? Images.no_notify : Images.toolbarLogo} style={[styles.image2, props.isTabTag == true ? {marginTop: 5, marginBottom: 2} : {}]} />
        </TouchableOpacity>
      ) : <View/>}
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          marginRight: 25,
          justifyContent:
            !props.isBackIcon && !props.isImage ? "flex-start" : "center"
        }}
      >
        <View
          style={{
            alignItems: 'center',
            marginTop: props.chatScreen && ifIphoneX ? 18 : props.isDashboard ? 10 : 0,
            marginLeft: !props.isBackIcon && !props.isImage ? normalize(10) : 25,
          }}
        >
          { props.isDashboard ? <Animatable.Image animation="fadeIn" duration={1500} source={Images.toolbarLogo} style={[styles.logoImage]} /> : <View/>}
          <Text style={styles.text1}>{props.title}</Text>
          { props.chatScreen ? <Text style={[styles.text1, { fontSize: 10, marginTop: 5 }]}>{`advising on ${props.category}`}</Text> : <View/>}
        </View>
        {props.isQIcon && !props.isDashboard && props.title !== 'Locality Facts View' &&(
          <TouchableOpacity
            onPress={() => handleQiconClick()}
            style={[
              {
                marginLeft: normalize(40),
                marginTop:  props.isDashboard ? 30 : normalize(5),
                width: normalize(55),
                height: normalize(55),
                borderRadius: normalize(40),
                borderWidth: normalize(3),
                borderColor: colors.WHITE,
                alignItems: "center",
                justifyContent: "center"
              }
            ]}
          >
            <Iconn name="question" size={15} color={colors.WHITE} />
          </TouchableOpacity>
        )}
      </View>
      {/* {props.isAccessoryPresent && (
        <TouchableOpacity
          onPress={() => props.accessoryViewTapped()}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Image source={Images.newsMain} style={[styles.image2,{marginTop: 15, marginBottom: 2}]} />
        </TouchableOpacity>
      )} */}
      {props.isImage && (
        <TouchableOpacity
          onPress={() => handleProfileClick()}
          style={{ justifyContent: "center", alignItems: "center"}}
        >
          { authReducerPic ? 
              <Image  animation="fadeIn" duration={1500} style={styles.image} source={{ isStatic: true, uri: authReducerPic.uri  ? authReducerPic.uri  : '' }} /> :
              <Image  animation="fadeIn" duration={1500} source={Images.profilePic} style={styles.image} />
          }
        </TouchableOpacity>
      )}
    </View>
     { props.isBackIcon && props.title != "Customer Support" 
      && props.title != "Credit Builder" && props.title != 'Choose your path'
      && props.title != "Path Explorer" && props.title != "Locality Facts View" 
      && props.title != "Locality Facts Selection" && props.title != "Eligibility Questionnaire" 
      && props.title !== 'Immigration timeline Details' 
      && props.title != "Eligible Paths Found" && props.title != "Application Timeline" && props.title !== 'Orders' && props.title !== 'Order Confirmation'
      && props.title != "Save Immipath Search" && props.title != "Store" && props.title !== 'Locality Facts'
      && props.title != "Immipath Search" && props.title != "PR Points Calculator" && props.title != "Jobs For You" && props.title != "Cart" ? 
      <TouchableOpacity disabled={props.resolveValue || props.is_resolved ? true : false } onPress={()=>props.resolveChat(true)} style={{position: 'absolute', bottom: -10, left: 25, zIndex: 9999,  height: 20, width: 40, borderRadius: 25, backgroundColor: props.resolveValue || props.is_resolved ? 'grey' : 'white', justifyContent: 'center' }}>
        <TouchableOpacity disabled={props.resolveValue || props.is_resolved ? true : false } onPress={()=>props.resolveChat(true)} style={{ height: 17, width: 17, borderRadius: 8.5, backgroundColor: 'green', alignSelf: props.resolveValue || props.is_resolved ? 'flex-end' : 'flex-start', justifyContent: 'center', marginLeft: props.resolveValue ? 0 : 2, marginRight: props.resolveValue || props.is_resolved ? 2 : 0 }}>
          <Image source={Images.donetick} style={{ height: 17, width: 17, }}/>
        </TouchableOpacity>
      </TouchableOpacity>
      : <View/>
      }
       { 
          showErrorModal ?
          <ErrorModal
            visible={showErrorModal}
            errorMessage={errorMsg}
            guest={true}
            navigation={props.navigate}
            closeModal={() => setshowErrorModal(false)}
          /> : <View/>
        }
    </View>
  );
});
// const StepIndicatorView = React.memo(props => {
//   return (
   
//   );
// });
class Toolbar extends React.Component {
  constructor(props) {
    super(props);
    this._popUpClose = this._popUpClose.bind(this);
    this.alertActive = false;
    this.state = {
      currentPosition: this.props.index,
      helpPopup: false,
      starCount: '  ',
      reviewModal: false
    };
  }

  _navigateToPathExplorer() {
    this.alertActive = false;
    this.props.navigation.navigate('PathExplorer');
  };

  _cancelTrigger() {
    this.alertActive = false;
  };

  _onStepPress = async position => {
    if(this.props.explorationId) {
      const isSaved = await AsyncStorage.getItem(`${this.props.explorationId}_saved`)
      if(isSaved == 'true') {
        if(!this.alertActive){
          this.alertActive = true;
          Alert.alert('Info',  "This search have been saved to profile. Please proceed with a new search from path explorer screen!", 
          [
            {
              text: "Path explorer",
              onPress: () => this.props.navigation.navigate('PathExplorer'),
              style: "cancel"
            },
            { text: "Cancel", onPress: () => this._cancelTrigger() }
          ],
          )
        }
      } else {
        this.setState({ currentPosition: position });
        this.props.navigateToPage(position);
      }
    } else {
      this.setState({ currentPosition: position });
      this.props.navigateToPage(position);
    }
  };
  _viewPopUp = () => {
    setTimeout(() => {
      this.setState({
        helpPopup: true 
      });
    }, 1500)
  };

  _popUpClose = () => {
    this.setState({
      helpPopup: false
    })
  };

  renderStepIndicator = params => {
    return (
      <Feather
        name={
          params.stepStatus === "current" || params.stepStatus === "finished"
            ? "check"
            : ""
        }
        color={"white"}
      />
    );
  };

   componentDidMount() {
    this.helperModelInitalization(this.props.title)
   }
 
  helperModelInitalization = async (title) => {  
    let pages = {
      "MainDashboard": false,
      "PathExplorer": false,
      "News": false,
      "ImmigrationAdviceChannel": false,
      "LocalityFactsView": false,
      "LocalityFactsSelection": false,
      "EligibilityQuestionnaire" : false,
      "EligiblePathsFound": false,
      "ApplicationTimelines": false,
      "SaveImmipathSearch": false
    }
    const explorer = await AsyncStorage.getItem('explorer')
    return;
    // if(explorer == null) {
    //   this._viewPopUp()
    //   pages.MainDashboard = true
    //   await AsyncStorage.setItem("explorer", JSON.stringify(pages))
    // } else {  
    //   let exp = JSON.parse(explorer)
    //   if(title === "Main Dashboard" && !exp.MainDashboard) {
    //     this._viewPopUp()
    //     exp.MainDashboard = true
    //     await AsyncStorage.setItem("explorer", JSON.stringify(exp))
    //   } else if(title === "Path Explorer" && !exp.PathExplorer) {
    //     this._viewPopUp()
    //     exp.PathExplorer = true
    //     await AsyncStorage.setItem("explorer", JSON.stringify(exp))
    //   } else if(title === "News" && !exp.News) {
    //     this._viewPopUp()
    //     exp.News = true
    //     await AsyncStorage.setItem("explorer", JSON.stringify(exp))
    //   } else if(title === "Immigration Advice Channel" && !exp.ImmigrationAdviceChannel) {
    //     this._viewPopUp()
    //     exp.ImmigrationAdviceChannel = true
    //     await AsyncStorage.setItem("explorer", JSON.stringify(exp))
    //   } else if(title === "Locality Facts View" && !exp.LocalityFactsView) {
    //     this._viewPopUp()
    //     exp.LocalityFactsView = true
    //     await AsyncStorage.setItem("explorer", JSON.stringify(exp))
    //   } else if(title === "Locality Facts Selection" && !exp.LocalityFactsSelection) {
    //     this._viewPopUp()
    //     exp.LocalityFactsSelection = true
    //     await AsyncStorage.setItem("explorer", JSON.stringify(exp))
    //   } else if(title === "Eligibility Questionnaire" && !exp.EligibilityQuestionnaire) {
    //     this._viewPopUp()
    //     exp.EligibilityQuestionnaire = true
    //     await AsyncStorage.setItem("explorer", JSON.stringify(exp))
    //   } else if(title === "Eligible Paths Found" && !exp.EligiblePathsFound) {
    //     this._viewPopUp()
    //     exp.EligiblePathsFound = true
    //     await AsyncStorage.setItem("explorer", JSON.stringify(exp))
    //   } else if(title === "Application Timeline" && !exp.ApplicationTimelines) {
    //     this._viewPopUp()
    //     exp.ApplicationTimelines = true
    //     await AsyncStorage.setItem("explorer", JSON.stringify(exp))
    //   } else if(title === "Save Immipath Search" && !exp.SaveImmipathSearch) {
    //     this._viewPopUp()
    //     exp.SaveImmipathSearch = true
    //     await AsyncStorage.setItem("explorer", JSON.stringify(exp))
    //   }
    // }
  }
  openReviewModal= () =>{
    this.setState({ reviewModal: !this.state.reviewModal })
  }
  render() {
    const {title} = this.props
    if(title === 'Eligible Paths Found' || title === "Locality Facts Selection" || 
      title === "Locality Facts View" || title === "Application Timeline") {
        this.helperModelInitalization(title)
    }
    return (
      <>
       <StatusBar hidden={false} barStyle={'light-content'}  />
      <View
        onLayout={({ nativeEvent }) => {
          let toolbarHeight = nativeEvent.layout.height;
          if (this.props.getHeightCallback)
            this.props.getHeightCallback(toolbarHeight);
        }}
        style={
          this.props.isTabTag
            ? [styles.containerSep, { borderBottomLeftRadius: this.props.adviceChannel ? 20 : 0, 
                borderBottomRightRadius: this.props.adviceChannel ? 20 : 0 }]
            : [
                styles.container,
                {
                  height: this.props.noHeight ? normalize(459) : this.props.simpleCase ? normalize(300) : null,top:0,
                  position: "absolute", zIndex: 9999,
                  borderBottomLeftRadius: this.props.title == 'Locality Facts View' || this.props.showFactsView  ? 0 : 20,
                  borderBottomRightRadius: this.props.title == 'Locality Facts View' ||this.props.showFactsView ? 0 : 20,
                }
              ]
        }
      >
        <MainView
          title={this.props.title}
          isBackIcon={this.props.isBackIcon}
          isImage={this.props.isImage}
          profileView={this.props.profileView}
          viewPopUp={this._viewPopUp}
          isQIcon={this.props.isQIcon}
          resolveValue={this.props.resolveValue}
          category={this.props.category}
          didTapOnBack={this.props.didTapOnBack}
          simpleCase={this.props.simpleCase}
          resolveChat={this.props.resolveChat}
          is_resolved={this.props.is_resolved}
          chatScreen={this.props.chatScreen}
          isDashboard={this.props.isDashboard}
          handleNotificationModalToggle={this.props.handleNotificationModalToggle}
          isTabTag = {this.props.isTabTag}
          navigate= {this.props.navigation}
          accessoryViewTapped={this.props.accessoryViewTapped}
          isAccessoryPresent = {this.props.isAccessoryPresent}
        />
        {this.props.isIndicator && (
          <StepIndicatorView
            renderStepIndicator={this.renderStepIndicator}
            isTabTag={this.props.isTabTag}
            customStyles={customStyles}
            currentPosition={this.props.index}
            onStepPress={this._onStepPress}
          />
        )}
       {  this.state.reviewModal ? 
       <View style={{ height: 0, width: '100%', backgroundColor: 'red', top: -10, zIndex: -1 }}>
          <View style={{width: '100%',  height: 120, backgroundColor: '#0f1143', borderBottomLeftRadius: 45, borderBottomRightRadius: 45, alignItems: 'center'}}>
            <View style={{ marginLeft: 0, padding: 20, paddingTop: 0,paddingLeft: 0, alignItems: 'center' }}> 
              <View style={{ width: "33%", marginTop: 10, alignItems: 'center' }}>
                <StarRating
                    disabled={false}
                    maxStars={5}
                    starSize={17}
                    fullStarColor={"orange"}
                    rating={this.props.starCount}
                    selectedStar={rating => this.props.onStarRatingPress(rating)}
                  />
                <Image style={{ width: 45, height: 45, marginTop: 10 }} source={this.props.adviceType === 'lawyer' ? require("../images/chat_icon.jpg") : require("../images/immi_agent.png")} />
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10, alignSelf: 'center'}}> 
                <View>
                  <Text style={{ color: 'white', fontSize: 12, fontFamily: family.semibold }}>
                    Advice Speciality : 
                  </Text>
                </View>
                <View style={{ marginLeft: 15 }}>
                  <Text style={{ color: 'white', fontSize: 12, fontFamily: family.semibold }}>
                    {this.props.adviceType=="immigration_agent"?"Immigration Agent":this.props.adviceType=="lawyer"?"Lawyer":this.props.adviceType=="visa_specialist"?"Visa Specialist":"Immigration Support"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          { 
            this.state.reviewModal ? 
            <TouchableOpacity onPress={()=> this.openReviewModal()}>
              <Image source={Images.top} style={{ height: 20, width: 20, alignSelf: 'center'}}/>
            </TouchableOpacity> 
              : 
            <View/>
          }
        </View> : <View/>}
       { this.props.chatScreen && !this.state.reviewModal ? 
        <TouchableOpacity style={{ height: 20, width: 20, alignSelf: 'center', }} onPress={()=> this.openReviewModal()}>
          <Image source={Images.top} style={{ height: 20, width: 20, alignSelf: 'center', top: 20, transform: [{ rotate: '180deg' }] }}/>
        </TouchableOpacity> 
         : 
        <View/>
        }
      </View>
      </>
    );
  }
}
const mapStateToProps = state => ({
  profilePic: state.authReducer.profilePic,
});
const mapDispatchToProps = dispatch => ({
  dispatch
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toolbar);
