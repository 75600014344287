import React, { useEffect, useState } from "react";
import { styles as s } from "./sectionG.styles";
import {
  View,
  TouchableOpacity,
  FlatList,
  Text,
  ScrollView,
  Image,
} from "react-native";
import Images from "../../../../common/images";
import { MoreInfoModal } from "./components/MoreInfoModal/MoreInfoModal";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedUniversity } from "../../../../actions/path.actions";
import { helpers, } from "../../../../common";
import { copilot, CopilotStep, walkthroughable } from "react-native-copilot";
import { checkTutorialStatus, copilotData } from "../../../../common/helpers";
import { TooltipComponent } from "../../../Tooltip/Tooltip";
import { Network } from "../../../../service/api";
import VisaDetailsModal from "../SectionI/components/ApplicationChecklistModal/VisaDetailsModal";
import ScoreBreakDownModal from "../../../ScoreBreakDownModal";
import AlertModal from "../../../AlertModal/AlertModal";

const CopilotTouch = walkthroughable(TouchableOpacity);

const SectionG = ({ prevTrack, nextTrack, start }) => {
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const [scoreData, setScoreData] = useState(null);
  const [eligible, seteligible] = useState(false)
  const [alertMessage, setAlertMessage] = useState(false)
  const [scoreModalVisible, setscoreModalVisible] = useState(false);
  const userImmipathSearchResults = useSelector(state => state.pathReducer?.getImmipathDetails?.userImmipathSearchResults)
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const dispatch = useDispatch()
  const [showDetailsModal, setshowDetailsModal] = useState({ show: false })
  const token = useSelector((state) => state.authReducer.authData.token);

  const [comparisionList, setComparisionList] = React.useState([]);
  const [moreInfoModal, setMoreInfoModal] = React.useState({
    active: false,
    params: null,
  });

  const handleScoreClick = (item, eligible) => {
    seteligible(eligible)
    setScoreData(item)
    setscoreModalVisible(true)
  }

  const onEligibilityPress = async ({ immipath_id }) => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let response = await Network(`/immipaths/paths/${immipath_id}`, "GET", null, headers)
    const { status, path_details } = response;
    if (status == 'Success') {
      setshowDetailsModal({
        show: true,
        data: path_details
      })
    }
  }

  const handleCompareListUpdation = (item, index) => {
    let newList = [...comparisionList];
    let _temp = newList.filter((s) => s.university_id == item.university_id);
    if (_temp.length > 0) {
      newList = newList.filter((s) => s.university_id !== item.university_id);
    } else {
      if (newList?.length == 3) {
        setAlertMessage('You can select only 3 universities!')
        return
      }
      newList.push(item)
    }
    setComparisionList(newList);
    dispatch(setSelectedUniversity(newList))
  };

  useEffect(() => {
    let callCoiplot = async () => {
      let status = await checkTutorialStatus('Compare results')
      !guestLogin && userDetails.isHelpActive && status && start()
    }
    callCoiplot()
  }, [])

  const handleMoreInfoModal = (params) => {
    setMoreInfoModal({
      active: true,
      params,
    });
  };

  const closeMoreInfoModal = () => {
    setMoreInfoModal({
      active: false,
      params: null,
    });
  };

  const handleCompare = () => {
    if (comparisionList?.length == 0) {
      setAlertMessage('Plesae select at least one university!')
      return
    }
    nextTrack()
  }

  return (
    <View style={[s.main]}>
      {moreInfoModal.active && (
        <MoreInfoModal
          closeMoreInfoModal={closeMoreInfoModal}
          params={moreInfoModal.params}
        />
      )}
      {
        showDetailsModal.show &&
        <VisaDetailsModal
          visible={showDetailsModal.show}
          criteria={false}
          selectedPath={showDetailsModal.data}
          onClose={() => setshowDetailsModal({ show: false })}
        />
      }
      {scoreModalVisible && (
        <ScoreBreakDownModal
          visible={scoreModalVisible}
          breakDown={scoreData.score_breakdown}
          user={userDetails}
          score={scoreData.score}
          scoreImmipId={scoreData.immipath_id}
          token={token}
          eligible={eligible}
          item={scoreData}
          excluded_reason={userImmipathSearchResults?.excluded_reason}
          promotions={userImmipathSearchResults?.promotions}
          excludedPath={userImmipathSearchResults?.excluded_paths}
          handleClose={() => setscoreModalVisible(false)}
        />
      )}
      <View style={[s.contentContainer]}>
        <Text style={[s.textT]}>{'Eligible Universities'}</Text>
        <ScrollView
          style={[
            {
              width: "100%",
            },
          ]}
        >
          <CopilotStep
            text={copilotData.eligibleResultCard}
            order={1}
            name="selected university card"
          >
            <CopilotTouch activeOpacity={1}>
              <FlatList
                data={userImmipathSearchResults?.results[1]}
                ListEmptyComponent={() => (
                  <Text style={s.noDataText}>
                    No universities available currently!
                  </Text>
                )}
                renderItem={({ item, index }) => {
                  return (
                    <TouchableOpacity
                      onPress={() => handleCompareListUpdation(item, index)}
                      key={item.id}
                      style={[
                        s.list,
                        {
                          backgroundColor: comparisionList.some((s) => s.university_id == item.university_id)
                            ? "#ffe6ba"
                            : "white",
                        },
                      ]}
                    >
                      <View style={s.imageView}>
                        {
                          item?.logo || item?.logo !== "" ?
                            <Image source={{ uri: item.logo }} style={{ height: '100%', width: '100%', resizeMode: 'contain' }} />
                            :
                            <Text style={[s.univIcon]}>
                              {helpers.getUniversityInitials(item.university)}
                            </Text>
                        }
                      </View>
                      <View style={[s.infoWrapper]}>
                        <Text style={[s.univNameText]}>{item.university}</Text>
                        <Text style={[s.locationInfoText]}>{item.city_string}</Text>
                        <Text style={[s.locationInfoText]}>{item.location}</Text>
                      </View>
                      {
                        index == 0 ?
                          <CopilotStep
                            text={copilotData.eligibleDetailModal}
                            order={2}
                            name="More details"
                          >
                            <CopilotTouch
                              onPress={() => handleMoreInfoModal({ ...item, index })}
                            >
                              <Image
                                style={[s.exIcon]}
                                source={Images.univExpandIcon}
                              />
                            </CopilotTouch>
                          </CopilotStep> :
                          <CopilotTouch
                            onPress={() => handleMoreInfoModal({ ...item, index })}
                          >
                            <Image
                              style={[s.exIcon]}
                              source={Images.univExpandIcon}
                            />
                          </CopilotTouch>
                      }
                    </TouchableOpacity>
                  );
                }}
                keyExtractor={(item) => item.id}
              />
            </CopilotTouch>
          </CopilotStep>
        </ScrollView>
        <CopilotStep
          text={copilotData.eligibleCompareButton}
          order={3}
          name="Compare results"
        >
          <CopilotTouch onPress={handleCompare} style={[s.nextWrapper]}>
            <Text style={[s.nextText]}>COMPARE </Text>
          </CopilotTouch>
        </CopilotStep>
        {alertMessage ?
          <AlertModal
            title={alertMessage}
            confirmText={"Ok"}
            showConfirm
            onConfirmPress={() => setAlertMessage(false)}
          />
          : null}
      </View>
    </View>
  );
};

export default copilot({
  tooltipComponent: TooltipComponent
})(SectionG)