import React from 'react'
import {
  View, TouchableOpacity, TouchableHighlight, Text, FlatList, Image
} from "react-native";
import BullitinModal from "../../../components/BullitinModal/BullitinModal";
import { styles } from "../../../components/NewProfile/Components/Home/home.styles";
import { BulletsWrapper } from "../../../components/NewProfile/Components/Home/Components/BulletsWrapper/BulletsWrapper";
import { QLanguages } from "../../../components/NewProfile/Components/Home/Components/Languages/QLanguages";
import { QOccupations } from "../../../components/NewProfile/Components/Home/Components/Occupations/QOccupations";
import { Images } from "../../../common";
import { isEmpty } from 'lodash';
import { SetupProfileModal } from "../../../components/NewProfile/Components/SetupProfileModal/SetupProfileModal";

function CompleteProfileModalTwo({ tvisible, onClose, profileProgress, setProfileProgress }) {
  const [visible, setVisible] = React.useState({
    active: false,
    track: null,
  });
  const [expandedMenu, setExpandedMenu] = React.useState(
    "InterestsOccupationsLanguagesLifestyles"
  );

  const expandOptions = (option) => {
    if (expandedMenu.includes(option)) {
      setExpandedMenu(expandedMenu.replace(option, ""));
    } else {
      setExpandedMenu(expandedMenu + option);
    }
  };

  return (
    <BullitinModal
      logo="pin"
      heading=""
      visible={tvisible}
      onClose={onClose}
      handleClose={onClose}
      closeIcon={"closeBlack"}
    >
      {visible.active && (
        <SetupProfileModal
          visible={visible}
          setVisible={setVisible}
          profileProgress={profileProgress}
          onCloseDashboardModal={onClose}
          setProfileProgress={setProfileProgress}
        />
      )}
      <View style={{ padding: 25, }}>
        {
          !isEmpty(profileProgress) &&
          <FlatList
            data={Object.values(profileProgress)}
            renderItem={({ item, index }) => {
              if (item.response?.length == 0) {
                return (
                  <>
                    <View style={[styles.listItem]}>
                      <View style={[styles.infoWrapper]}>
                        <Image
                          style={{
                            height: 45,
                            width: 45,
                          }}
                          resizeMode="contain"
                          source={item.icons}
                        />
                        <Text style={[styles.itemTitle]}>{item.title}</Text>
                      </View>
                      <View style={[styles.expansionWrapper]}>
                        {item.response.length != 0 && (
                          <Text style={[styles.countText]}>{item.response.length}</Text>
                        )}
                        <TouchableOpacity
                          onPress={() =>
                            item.response.length === 0
                              ? setVisible({
                                active: true,
                                track: index,
                              })
                              : expandOptions(item.title)
                          }
                          style={[styles.expandButton]}
                        >
                          <Image
                            style={{
                              height: 35,
                              width: 35,
                              transform: [
                                {
                                  rotate:
                                    !expandedMenu.includes(item.title) &&
                                      item.response.length != 0
                                      ? "180deg"
                                      : "0deg",
                                },
                              ],
                            }}
                            resizeMode="contain"
                            source={
                              item.response.length === 0
                                ? Images.expandIcon
                                : Images.collapseIcon
                            }
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  </>
                )
              } else {
                return null
              }
            }}
          />
        }
      </View>
    </BullitinModal>
  )
}

export default CompleteProfileModalTwo