import normalize from './normalize'

const fonts = {
  // font_7:normalize(23,true),
  font_8: normalize(24,true),
  font_9:normalize(25,true),
  font_10: normalize(30,true),
  font_11: normalize(33,true),
  font_12: normalize(36,true),
  font_13: normalize(39,true),
  font_37:normalize(37,true),
  font_40:normalize(40,true),
  font_15: normalize(45,true),
  font_16: normalize(48,true),
  font_50:normalize(50,true),
  font_17: normalize(51,true),
  font_18: normalize(54,true),
  font_19: normalize(57,true),
  font_20: normalize(60,true),
  font_22: normalize(62,true),
  font_25: normalize(75,true),
  font_29: normalize(87,true),
  font_30: normalize(90,true),
  font_32: normalize(96,true),
  font_34: normalize(102,true),
  font_36: normalize(108,true)
}

export default fonts
