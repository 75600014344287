import { StyleSheet } from "react-native";
import { family } from "../../../common";
import { mobilBrower } from "../../../common/helpers";

export const styles = StyleSheet.create({
    mainView: {
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    },
    innerView: {
        backgroundColor: "#F1F1F1",
        borderRadius: 30,
        width: !mobilBrower() ? "40%" : "90%",
        alignItems: "center",
        padding: "5%",
        shadowColor: "white",
        shadowOffset: { width: 0, height: 0.5 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
        elevation: 12,
        justifyContent: "space-between",
    },
    title: {
        fontSize: 22,
        marginVertical: 10,
        textAlign: "center",
        fontWeight: "bold",
        color: "#030135",
    },
    description: {
        fontSize: 14,
        textAlign: "center",
        fontFamily: family.effra,
        width: "80%",
        marginVertical: "7%",
        color: "#030135",
    },
    btn: {
        paddingVertical: 8,
        marginVertical: 10,
        width: "80%"
    },
    btnText: {
        padding: 5,
        paddingLeft: 7,
        textAlign: "center",
        color: "#030135",
        paddingRight: 7,
    },
    input: {
        width: 60,
        padding: 10
    },
    inputContainer: {
        flexDirection: "row",
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "space-around",
        borderWidth: 1,
        borderColor: "#00000030"
    }
});
