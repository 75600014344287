import React from "react";
import { animated as a, useTrail } from "@react-spring/native";

export const TrailEase = ({ children, offset }) => {
  const components = React.Children.toArray(children);
  const springs = useTrail(components.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    to: [
      {
        marginTop: 0,
        opacity: 1,
      },
    ],
    from: {
      opacity: 0,
      marginTop: offset,
    },
  });

  return springs.map(({ ...style }, index) => {
    return (
      <a.View key={index} style={[style]}>
        {components[index]}
      </a.View>
    );
  });
};
