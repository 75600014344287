import React from "react";
import {
  Image,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Images from "../../../../common/images";
import BullitinModal from "../../../BullitinModal/BullitinModal";
import { styles } from "./UpdateProfilePicModal.styles";

export const UpdateProfilePicModal = ({ visible, onClose, handlePickChange, changeProfilepic, profilePic }) => {
  const handleClose = (func) => {
    func();
  };

  return (
    <BullitinModal
      heading=""
      visible={visible}
      onClose={onClose}
      loading={false}
      handleClose={handleClose}
      logoFrom="mid"
      hideCloseButton={false}
    >
      <View style={[styles.wrapper, { height: "90%" }]}>
        {profilePic && profilePic.uri ? (
          <Image
            style={styles.mailImage}
            source={{
              isStatic: true,
              uri: changeProfilepic ? changeProfilepic : profilePic.uri,
            }}
          />
        ) : (
          <Image style={styles.mailImage} source={Images.userIcon} />
        )}
        <View style={styles.btnsContainer}>
          <TouchableOpacity style={styles.btn} onPress={() => handlePickChange(true)}>
            <Text style={styles.btnText}>Remove</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.btn, { backgroundColor: "#0F1143" }]} onPress={() => handlePickChange()}>
            <Text style={[styles.btnText, { color: "#fff" }]}>Change</Text>
          </TouchableOpacity>
        </View>
      </View>
    </BullitinModal>
  );
};
