import { Dimensions, StyleSheet } from "react-native";
import fontFamily from "../../common/fontFamily";
const { width, height } = Dimensions.get("window");
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("screen");
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";
import { heightPercentageToDP } from "react-native-responsive-screen";

export const styles = StyleSheet.create({
  topView: {
    maxWidth: SCREEN_WIDTH,
    height: mobilBrower() ? SCREEN_HEIGHT - 10 : SCREEN_HEIGHT - 280,
    backgroundColor: "#0F1143",
    alignItems: "center",
    marginTop: -5,
    position: "relative",
    borderColor: "#0F1143",
  },
  eligibilityButton: {
    backgroundColor: "#F1AB32",
    paddingHorizontal: 15,
    paddingVertical: 12,
    borderRadius: 10,
    marginVertical: 10
  },
  nameView: {
    flexDirection: !mobilBrower() ? "row" : "column",
    width: !mobilBrower() ? "70%" : "100%",
    height: "100%",
    marginTop: !mobilBrower() ? 0 : 20,
    padding: 0,
    justifyContent: mobilBrower() ? "flex-start" : "space-between",
    alignSelf: "center",
    alignItems: "center",
  },
  nameView_left: {
    width: mobilBrower() ? "100%" : "45%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  nameView_right: {
    width: mobilBrower() ? "100%" : "50%",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  dashboadImg: {
    width: "100%",
    height: mobilBrower() ? "70%" : "100%",
    justifyContent: "center"
  },
  dashboadImgMobile: {
    alignSelf: "center",
    marginTop: heightPercentageToDP("2%"),
    height: height / 1.9,
    width: width / 1.1,
    resizeMode: "contain",
  },
  discoverButton: {
    height: mobilBrower() ? "6vh" : "8vh",
    width: "55%",
    marginLeft: "4vh",
    marginTop: "9vh",
  },
  opportunitiesButton: {
    height: mobilBrower() ? "6vh" : "8vh",
    width: "55%",
    alignSelf: "flex-end",
    marginTop: "8vh",
  },
  solutionsButton: {
    height: mobilBrower() ? "6vh" : "8vh",
    width: "55%",
    marginTop: "7vh",
  },
  newItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: !mobilBrower() ? "45%" : "90%",
    alignSelf: "center",
    marginTop: 30,
    backgroundColor: "#F6F6F6",
    height: !mobilBrower() ? 200 : 300,
  },
  showMoreButtom: {
    height: 40,
    alignSelf: "center",
    borderRadius: 20,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  showMoreText: {
    color: "#000000",
    fontSize: 16,
    paddingHorizontal: 15,
    fontFamily: fontFamily.effra,
  },
  userNameText: {
    color: "#F1AB32",
    fontFamily: fontFamily.effra,
    fontWeight: "700",
    marginTop: 0,
    fontSize: SCREEN_HEIGHT * 0.03,
  },
  userNameTextwelcome: {
    color: "white",
    fontSize: SCREEN_HEIGHT * 0.02,
    fontFamily: fontFamily.effra,
    marginRight: 10,
  },
  newImage: {
    flex: 0.7,
    padding: 10,
  },
  newContent: {
    flex: 1,
    paddingRight: 15,
  },
  newImgStyle: {
    aspectRatio: 1,
    height: !mobilBrower() ? 180 : 280,
    width: !mobilBrower() ? 200 : 280,
  },
  newsHighTitle: {
    color: "black",
    alignSelf: "center",
    marginTop: 35,
    fontSize: responsiveText(25),
    fontWeight: "700",
  },
  section2: {
    height: !mobilBrower() ? 450 : 250,
    width: "100%",
    backgroundColor: "#030135",
    justifyContent: "center",
    alignItems: "center",
  },
  dashboadImg2: {
    height: 400,
    width: "50%",
    aspectRatio: 1,
  },
  dashboadImgMobile2: {
    height: 220,
    width: "90%",
  },
  welcomeTextBg: {
    backgroundColor: "#F1AB32",
    height: "50%",
    position: "absolute",
    width: "95%",
    left: 4,
    bottom: 0,
  },
  welcomeText: {
    color: "white",
    zIndex: 1,
    position: "relative",
    fontFamily: fontFamily.effra,
    fontWeight: "bold",
    marginTop: 0,
    fontSize: responsiveText(50),
    letterSpacing: "0.04em",
  },
  nameContanier: {
    position: "relative",
  },
  immiTitle: {
    color: "white",
    fontFamily: fontFamily.effra,
    fontWeight: "bold",
    fontSize: responsiveText(16.5),
    marginTop: !mobilBrower() ? "5%" : "1%",
  },
  title: {
    color: "black",
    fontSize: !mobilBrower() ? 17 : 35,
    fontWeight: "700",
    fontFamily: "Effra",
  },
  details: {
    color: "black",
    fontSize: !mobilBrower() ? 15 : 30,
    marginTop: 20,
    fontWeight: "700",
    fontFamily: "Effra"
  },
  introTextWrapper: {
    position: "relative",
    width: "100%",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start"
  },
});
