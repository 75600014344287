import React, { useState, useEffect } from "react";
import { View, Image, Text } from "react-native";
import fontFamily from "../../../../common/fontFamily";
import Images from "../../../../common/images";
import { ReactionInfo } from "../../ReactionInfo/ReactionInfo";
import moment from "moment";
import storage from "@react-native-firebase/storage";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { isWeb } from "../../../../common/helpers";

export const Comment = ({ item, discussion_id, totalComments, displayCommentCount }) => {
  const [profilePic, setprofilePic] = useState(null)

  const calculateDateDiff = () => {
    let a = moment(item?.timestamp)
    let b = moment(new Date())
    let dateDiff = moment().diff(a, "days")
    return dateDiff > 0 ? dateDiff + ' days ago' : 'today'
  }

  useEffect(() => {
    getImgFromFirebase()
  }, [item])

  const getImgFromFirebase = async () => {
    if (isWeb()) {
      const storage = getStorage();
      const listRef = ref(storage, `avatarImages/${item?.message_author_id}/`)
      const imgList = await listAll(listRef);
      imgList.items.forEach(async (img) => {
        if (img.fullPath.split("/")[1].split(".")[0] == item?.message_author_id) {
          getDownloadURL(img)
            .then((downloadURL) => {
              setprofilePic(downloadURL);
            });
        }
      });
    } else {
      const imgList = await storage()
        .ref(`avatarImages/${item?.message_author_id}/`)
        .list();
      imgList._items.forEach(async (s) => {
        if (s.path.split("/")[1].split(".")[0] == item?.message_author_id) {
          storage()
            .ref(`${s.path}`)
            .getDownloadURL()
            .then((downloadURL) => {
              setprofilePic(downloadURL)
            });
        }
      });
    }
  };

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "flex-start",
        padding: 5,
      }}
    >
      <Image
        style={{
          width: 45,
          height: 45,
          borderRadius: 45 / 2,
          margin: 10,
        }}
        defaultSource={Images.userIcon}
        source={{ uri: profilePic }}
      />
      <View
        style={{
          width: "80%",
          borderBottomWidth: displayCommentCount ? 0.4 : 0,
          paddingBottom: 20
        }}
      >
        <Text
          style={{
            fontFamily: fontFamily.effra,
            fontSize: 17,
            marginTop: 15,
            marginBottom: 3
          }}
        >
          {item?.message_author}
        </Text>
        <Text style={{ marginTop: 15 }}>
          {item?.text.split(']')[1]}
        </Text>
        <ReactionInfo
          totalComments={totalComments}
          displayCommentCount={displayCommentCount}
          discussion_id={discussion_id}
          item={item}>
          <Text>{calculateDateDiff()}</Text>
        </ReactionInfo>
      </View>
    </View>
  );
};
