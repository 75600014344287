import React, { useEffect, useState } from 'react'
import { View, TouchableOpacity, Text, ScrollView, Pressable, Image } from "react-native";
import { heightPercentageToDP } from 'react-native-responsive-screen';
import { helpers } from '../../../common';
import Images from '../../../common/images';
import { apiPaths, Network } from '../../../service/api';
import BullitinModal from '../../BullitinModal/BullitinModal';
import { styles } from '../OpportunityReport.styles';
import { useSelector, useDispatch } from "react-redux";
import TimelineView from './TimelineView';
import { useHistory } from 'react-router-dom';
import { isArray } from 'lodash';
import VisaSaved from '../../../pages/QuickSearch/components/VisaSaved';
import NavigationService from '../../../navigation/NavigationService';
import RewardModal from '../../../pages/RewardModal';
import { isWeb, mobilBrower } from '../../../common/helpers';
import { viewImmipathId } from '../../../actions/path.actions';
import { getAppTrackerList } from '../../../actions/dashboard.actions';
import CreateUser from '../../CreateUser/CreateUser';

export default function VisaDetailsModal({ visible, selectedPath, onClose, criteria }) {
  const history = useHistory();
  const [currentComponent, setCurrentComponent] = useState("detailedView");
  const [timelineData, settimelineData] = useState(null);
  const [showRewardModal, setshowRewardModal] = useState(false);
  const [appRefId, setAppRefId] = useState(null);
  const [loading, setLoading] = useState(false);
  const selectedgoalandcountries = useSelector(state => state.pathReducer?.getImmipathDetails?.selectedgoalandcountries)
  const dispatch = useDispatch();
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const Token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );

  let userPostData = {
    country_of_birth: "",
    highest_academic_achievement: "",
    ...selectedgoalandcountries?.userInfo,
    get_notif1: true,
    get_notif2: true,
    countries_of_interest: selectedgoalandcountries?.countries,
    immigration_interests: [selectedgoalandcountries?.goal],
  }

  const handleTimeline = () => {
    const url = "/immipaths/timelines?";
    const headers = {
      Accept: "application/json",
      Authorization: guestLogin ? `guest@immipath` : userDetails?.userid + "@" + Token,
    };
    let Obj = {
      exploration_id: null,
      immipath_ids: [selectedPath?.immipath_id],
      base_filters: {},
    };
    Network(url, "POST", Obj, headers)
      .then((res) => {
        if (res.status == "Success") {
          settimelineData(res.timelines_info);
        }
      })
      .catch((e) => {
      });
  };

  useEffect(() => {
    handleTimeline()
  }, [])

  const handleShortlist = async (userDetails) => {
    setLoading(true);
    setUserDetails(userDetails);
    const postData = {
      application_fields: {},
      application_metadata: {},
      exploration_id: null,
      user_id: userDetails?.user_id,
    };
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails.user_id}@${userDetails.auth_token}`,
    };
    let url = `${selectedPath.immipath_id}/save`;
    let res = await Network(
      apiPaths.application_referrals + url,
      "POST",
      postData,
      headers
    );
    if (res.application_reference) {
      await dispatch(getAppTrackerList(userDetails.user_id, userDetails.user_id + "@" + userDetails.auth_token));
      setAppRefId(res.application_reference);
      setLoading(false);
      setCurrentComponent("savedView")
    } else {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    let params = {
      app_ref: appRefId,
      sortcut: true,
      selectedPath: selectedPath,
    }
    onClose()
    if (isWeb()) {
      history.push("profile", params)
    } else {
      dispatch(viewImmipathId(params))
      NavigationService.navigate("NewProfileScreen", params);
    }
  };

  const handleRewardModal = () => {
    setTimeout(() => {
      setshowRewardModal(true);
    }, 500);
  };

  const DetailedView = () => (
    <View style={{ flex: 1 }}>
      <View style={styles.detailsCard}>
        <View style={{ alignSelf: "center", marginBottom: heightPercentageToDP('3%') }}>
          <Image
            source={helpers.getCountryFlag(selectedPath?.country_id)}
            style={[styles.iconV]}
          />
        </View>
        <View
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            flex: 1
          }}
        >
          <ScrollView showsVerticalScrollIndicator={true} nestedScrollEnabled={true}>
            <Pressable>
              {
                criteria ?
                  <View style={{ alignItems: "center" }}>
                    <Text
                      style={[
                        styles.details,
                        {
                          fontWeight: "bold",
                          paddingTop: 10,
                          marginBottom: 5,
                        },
                      ]}
                    >
                      Eligibility Criteria
                    </Text>
                    {selectedPath.eligibility_criteria.length > 0 ? (
                      selectedPath.eligibility_criteria.map((s) => (
                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: 5,
                          }}
                        >
                          <Image
                            source={Images.bullet}
                            style={[styles.bullet, { marginBottom: 10 }]}
                          />
                          <Text style={[styles.details, { width: "90%" }]}>
                            {s}
                          </Text>
                        </View>
                      ))
                    ) : (
                      <View />
                    )}
                  </View> :
                  <>
                    <Text
                      style={[
                        styles.details,
                        {
                          fontWeight: "bold",
                          marginBottom: 5,
                          textAlign: "center",
                        },
                      ]}
                    >
                      Description
                    </Text>
                    <Text style={[styles.description]}>
                      {selectedPath.path_description}
                    </Text>

                    <View
                      style={{
                        padding: 5,
                      }}
                    >
                      <View style={[styles.detailWrapper, { marginTop: 15 }]}>
                        <Image source={Images.bullet} style={styles.bullet} />
                        <View style={styles.detailContentWrapper}>
                          <Text style={styles.detailsTitle}>Visa type: </Text>
                          <Text style={styles.detailsText}>
                            {isArray(selectedPath.goal_ids) ? helpers.getGoalName(selectedPath.goal_ids).join(', ') : selectedPath.goalName}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.detailWrapper}>
                        <Image source={Images.bullet} style={styles.bullet} />
                        <View style={styles.detailContentWrapper}>
                          <Text style={styles.detailsTitle}>
                            Approximate time for application:{" "}
                          </Text>
                          <Text style={styles.detailsText}>
                            {selectedPath.time_for_user_application_min + " - " + selectedPath.time_for_user_application_max}{" "}
                            days
                          </Text>
                        </View>
                      </View>
                      <View style={styles.detailWrapper}>
                        <Image source={Images.bullet} style={styles.bullet} />
                        <View style={styles.detailContentWrapper}>
                          <Text style={styles.detailsTitle}>Validity: </Text>
                          <Text style={styles.detailsText}>
                            {selectedPath.validity}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={{}}>
                      <Text
                        style={[
                          styles.details,
                          {
                            fontWeight: "bold",
                            paddingTop: 10,
                            marginBottom: 5,
                            alignSelf: "center"
                          },
                        ]}
                      >
                        Eligibility Criteria
                      </Text>
                      {selectedPath.eligibility_criteria.length > 0 ? (
                        selectedPath.eligibility_criteria.map((s) => (
                          <View
                            style={{
                              flexDirection: "row",
                              marginTop: 5,
                            }}
                          >
                            <Image
                              source={Images.bullet}
                              style={[styles.bullet, { marginBottom: 10 }]}
                            />
                            <Text style={[styles.details, { width: "90%" }]}>
                              {s}
                            </Text>
                          </View>
                        ))
                      ) : (
                        <View />
                      )}
                    </View>
                  </>
              }
            </Pressable>
          </ScrollView>
        </View>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TouchableOpacity
          activeOpacity={0.8}
          onPress={() => setCurrentComponent("timelineView")}
          style={[
            styles.actionButton,
          ]}
        >
          <Text style={styles.shortText}>View Timeline</Text>
        </TouchableOpacity>
        {/* <TouchableOpacity
          activeOpacity={0.8}
          disabled={excludedPath}
          onPress={() => setCurrentComponent("createUser")}
          style={[
            styles.actionButton, { backgroundColor: excludedPath ? 'grey' : 'green', }
          ]}
        >
          <Text style={styles.shortText}>Shortlist</Text>
        </TouchableOpacity> */}
      </View>
    </View>
  )

  const SavedView = () => (
    <View style={{ flex: 1 }}>
      <View style={styles.detailsCard}>
        <View style={{ alignSelf: "center", marginBottom: heightPercentageToDP('3%') }}>
          <Image
            source={helpers.getCountryFlag(selectedPath?.country_id)}
            style={[styles.iconV]}
          />
        </View>
        <View
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            flex: 1
          }}
        >
          <VisaSaved
            visible={true}
            token={userDetails.auth_token}
            handleRewardModal={handleRewardModal}
            handleModalClose={handleModalClose}
            userid={userDetails.user_id}
          />
        </View>
      </View>
    </View>
  )

  const handleOnRewardModalClose = () => {
    setshowRewardModal(false);
    handleModalClose();
  }

  const components = {
    timelineView: <TimelineView
      onClose={() => setCurrentComponent("detailedView")}
      timelineData={timelineData} />,
    detailedView: <DetailedView />,
    createUser: <CreateUser userPostData={userPostData} onPinValidated={handleShortlist} />,
    savedView: <SavedView />,
  }

  return (
    <BullitinModal
      heading=""
      visible={visible}
      onClose={onClose}
      loading={loading}
      handleClose={onClose}
      closeIcon={!["savedView", "timelineView"].includes(currentComponent) && "closeBlack"}
      hideCloseButton={["savedView", "timelineView"].includes(currentComponent)}
      innerViewStyle={{ width: mobilBrower() ? "90%" : "40%" }}
    >
      <View style={styles.notifItemContainer}>
        {components[currentComponent]}
      </View>
      {showRewardModal ?
        <RewardModal
          visible={true}
          price={100}
          text='congratulations on shortlisting your first path!'
          title={'Wohoo!\nAnother reward for you!'}
          onClosed={handleOnRewardModalClose}
        /> :
        <View />}
    </BullitinModal>
  )
}
