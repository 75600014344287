import React, { useRef, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  TextInput,
  Text,
  ActivityIndicator,
} from "react-native";
import { WalkthroughableTouch } from "../../../../common/WalkThroughable";
import Images from "../../../../common/images";
import { styles } from "./searchAndFilter.styles";

import mock from "../../data/data.json";
import {
  setFilterModal,
  setActiveClassification,
  fetchComponentsData,
  setCustomLoading,
} from "../../../../actions/mainMedia.actions";
import { useDispatch, useSelector } from "react-redux";
import { searchMainMediaQuery } from "../../../../actions/mainMedia.actions";
import config from "../../../../common/config";
import { isWeb } from "../../../../common/helpers";

const SearchAndFilter = () => {
  const dispatch = useDispatch();
  const debounceTime = useRef(0);

  const activeClassification = useSelector(
    (state) => state.mainMediaReducer.news.config.activeClassification
  );

  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );

  const activeTab = useSelector(
    (state) => state.mainMediaReducer.gState.activeTab
  );
  const searchedQuery = useSelector(
    (state) => state.mainMediaReducer[activeTab.title].config.searchedQuery
  );
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);

  const filterParams = useSelector(
    (state) => state.mainMediaReducer[activeTab.title].config.filter
  );

  const opportunityNews = useSelector(
    (state) => state.mainMediaReducer.news["opportunity"]
  );
  const threatNews = useSelector(
    (state) => state.mainMediaReducer.news["threat"]
  );

  const customLoading = useSelector(
    (state) =>
      state.mainMediaReducer[state.mainMediaReducer.gState.activeTab.title]
        .config.customLoading
  );

  const searchQuery = (e, delay = 800) => {
    const searchedQuery = e;
    dispatch(searchMainMediaQuery(searchedQuery));
    dispatch(setCustomLoading(true));

    // debounce to reduce api calls
    if (debounceTime.current) {
      clearTimeout(debounceTime.current);
    }
    if (searchedQuery) {
      debounceTime.current = setTimeout(async () => {
        // fns
        await getCustomNews(searchedQuery);
        dispatch(setCustomLoading(false));
      }, delay);
    } else {
      dispatch(setActiveClassification(null));
      dispatch(setCustomLoading(false));
    }
  };

  const handleClassificationClick = async (key) => {
    if (activeClassification === key) {
      dispatch(setActiveClassification(null));
    } else {
      dispatch(setActiveClassification(key));
      if (key === "opportunity" && !opportunityNews.length)
        await getData(searchedQuery, key);
      if (key === "threat" && !threatNews.length)
        await getData(searchedQuery, key);
    }
  };

  const getCustomNews = async (searchedQuery) => {
    if (
      searchedQuery ||
      filterParams.immigrationInterest.length ||
      filterParams.countriesInterestedIn.length
    ) {
      await getData(searchedQuery);
    } else {
    }
  };

  const getData = async (searchedQuery, classification) => {
    await dispatch(
      fetchComponentsData({
        user_id: guestLogin ? 0 : userDetails?.userid,
        authtoken: guestLogin ? "guest@news" : token,
        from: 0,
        componentType: activeTab.title === "videos" ? "media" : activeTab.title,
        refresh: true,
        classification: classification
          ? classification
          : activeClassification === "default"
          ? null
          : activeClassification,
        immiInterest: guestLogin ? config.immigerat.map((s) => s.code).join(",") : filterParams.immigrationInterest.length == 0
          ? config.immigerat.map((s) => s.code).join(",")
          : filterParams.immigrationInterest.join(","),
        country: guestLogin ? config.intrestcountry.map((s) => s.code).join(",") : filterParams.countriesInterestedIn.length == 0
          ? config.intrestcountry.map((s) => s.code).join(",")
          : filterParams.countriesInterestedIn.join(","),
        searchtext: searchedQuery,
      })
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.firstContainer}>
        <View style={[styles.searchemailtextInput]}>
          <TextInput
            value={searchedQuery}
            style={styles.textInput}
            placeholder=" Search"
            placeholderTextColor="#77838F"
            onChangeText={searchQuery}
          />
          <View style={styles.searchIcon}>
            {customLoading ? (
              <ActivityIndicator
                style={{
                  height: 15,
                  width: 15,
                }}
                size="small"
                color="#0f1143"
              />
            ) : (
              <Image
                source={Images.blackSearchIcn}
                style={{ height: 15, width: 15, resizeMode: "contain" }}
              />
            )}
          </View>
        </View>
        <View style={styles.filterBtnContainer}>
          {isWeb() ? (
            <TouchableOpacity
                onPress={() => dispatch(setFilterModal(true))}
              style={styles.filterBtn}
            >
              <Image
                source={Images.newFilterIcon}
                style={styles.filterIcon}
              />
            </TouchableOpacity>
          ) : (
              <WalkthroughableTouch
                onPress={() => dispatch(setFilterModal(true))}
              >
                <Image
                  source={Images.newFilterIcon}
                  style={styles.filterIcon}
                />
              </WalkthroughableTouch>
          )}
        </View>
      </View>
      {activeTab.key === "first" && (
        <View style={styles.classificationContainer}>
          {mock.mainTabs.map((classification) => {
            return (
              <TouchableOpacity
                activeOpacity={0.8}
                key={classification.key}
                onPress={() => handleClassificationClick(classification.key)}
                style={[
                  styles.classificationTags,
                  {
                    backgroundColor:
                      activeClassification == classification.key
                        ? classification.colors.dark
                        : classification.colors.light,
                    marginLeft: classification.key === "threat" ? 5 : -2.5,
                  },
                ]}
              >
                <Text style={styles.classificationText}>
                  {classification.title}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
      )}
    </View>
  );
};

export default (SearchAndFilter);
