import React, { useState, useEffect, useRef } from "react";
import { styles } from "./sectionC.styles";
import { View, TouchableOpacity, Image, Text, FlatList, TextInput, StyleSheet } from "react-native";
import Images from "../../../../common/images";
import { useSelector, useDispatch } from "react-redux";
import { setUserDegreeAndCourseSelection, setSelectedGoalandCountries } from "../../../../actions/path.actions";
import Slider from "@react-native-community/slider";
import helpers, { isWeb, mobilBrower } from "../../../../common/helpers";
import { TrailFromBottom } from "./components/Animations";
import Tooltip from "@material-ui/core/Tooltip";
import { Network } from "../../../../service/api";
import * as Animatable from 'react-native-animatable';
import _, { get } from "lodash";
import { Config } from "../../../../common";
import RNModalPicker from "../../../RNModalPicker";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import { multiSelectStyle } from "./sectionC.styles.web";
import Loader from "../../../Loader";
import { getOccupationAndLanguageList } from "../../../../actions/app.actions";
import moment from "moment";
import CountrySelect from "../../../CountrySelect/CountrySelect";
import config from "../../../../common/config";
import PaymentModal from "./PaymentModal";

let preCurrency = ["USD"];
const _handleRazorPay = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const SectionC = ({ nextTrack, selectedCurrency }) => {
  const [userSelections, setuserSelections] = useState({})
  const userSelectionWtihQuestionId = useSelector(state => state.pathReducer?.getImmipathDetails?.userSelectionWithQuestionId)
  const baseCurrency = useSelector((state) => state.referralReducer.referralData.baseCurrency);
  const dispatch = useDispatch();
  const [currentQtn, setCurrentQtn] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const setupProfileQuestions = useSelector((state) => state.pathReducer.getImmipathDetails.setupProfileQuestions);
  const [Error, setError] = useState({})
  const [errorText, setErrorText] = useState({})
  const userDegreeCourseSelections = useSelector(state => state.pathReducer?.getImmipathDetails?.userDegreeCourseSelections)
  const selectedgoalandcountries = useSelector(state => state.pathReducer?.getImmipathDetails?.selectedgoalandcountries)
  const occupationList = useSelector(
    (state) => state.appStateReducer.occupationAndLanguageList?.occupations
  );
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const Token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const [userData, setUserData] = useState({});

  const userInfoQuetions = [
    {
      application_display_name: "Email address",
      fact_id: null,
      is_chat: true,
      is_drop_down: false,
      is_mandatory: true,
      is_text_input: true,
      type: "text",
      question: "What is your email address?",
      user_info: true,
      key: "email_address",
      select_options: [],
      special_field: {}
    },
    {
      application_display_name: "First name",
      fact_id: null,
      is_chat: true,
      is_drop_down: false,
      is_mandatory: true,
      is_text_input: true,
      type: "text",
      question: "What is your first name?",
      user_info: true,
      key: "first_name",
      select_options: [],
      special_field: {}
    },
    {
      application_display_name: "Last name",
      fact_id: null,
      is_chat: true,
      is_drop_down: false,
      is_mandatory: true,
      is_text_input: true,
      type: "text",
      question: "What is your last name?",
      user_info: true,
      key: "last_name",
      select_options: [],
      special_field: {}
    },
    {
      application_display_name: "Age",
      fact_id: null,
      is_chat: true,
      is_drop_down: false,
      is_mandatory: true,
      is_text_input: true,
      type: "integer",
      question: "What is your age? (in years)",
      user_info: true,
      key: "age",
      select_options: [],
      special_field: {}
    },
    {
      application_display_name: "Country of Birth",
      fact_id: null,
      is_chat: true,
      is_drop_down: false,
      is_mandatory: true,
      is_text_input: false,
      type: "picker",
      question: "What is your Country of Birth?",
      user_info: true,
      key: "place_of_birth",
      select_options: Config.countriesSelect,
      special_field: {}
    },
    {
      application_display_name: "Home Country",
      fact_id: null,
      is_chat: true,
      is_drop_down: false,
      is_mandatory: true,
      is_text_input: false,
      type: "picker",
      question: "What is your current home country?",
      user_info: true,
      key: "country_of_residency",
      select_options: Config.homeCountries.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      }),
      special_field: {}
    },
    {
      application_display_name: "Status in Country of residency",
      fact_id: null,
      is_chat: true,
      is_drop_down: false,
      is_mandatory: false,
      is_text_input: false,
      type: "select",
      question: "What is your status in country of residency?",
      user_info: true,
      key: "country_of_residency_status",
      select_options: Config.country_redidency_status,
      special_field: {}
    },
    {
      application_display_name: "Highest Academic Achievment",
      fact_id: null,
      is_chat: true,
      is_drop_down: false,
      is_mandatory: true,
      is_text_input: false,
      type: "picker",
      is_single: true,
      question: "What is your qualification?",
      user_info: true,
      key: "highest_academic_achievement",
      select_options: Config.education_profile.reduce((finalList, item) => {
        finalList.push(...item.children);
        return finalList
      }, []),
      special_field: {}
    },
    {
      application_display_name: "Occupation",
      fact_id: null,
      is_chat: true,
      is_drop_down: false,
      is_mandatory: true,
      is_text_input: false,
      type: "picker",
      question: "What is your Occupation?",
      user_info: true,
      key: "occupation",
      select_options: occupationList,
      special_field: {}
    },
  ]

  if (selectedgoalandcountries.goal == 2) {
    userInfoQuetions.push({
      application_display_name: "Job Offer",
      fact_id: null,
      is_chat: true,
      is_drop_down: false,
      is_mandatory: true,
      is_text_input: false,
      type: "countries",
      question: "Which of these countries do you have a job offer in?",
      user_info: true,
      key: "has_job_offer",
      select_options: config.intrestcountry.filter(country => selectedgoalandcountries?.countries?.includes(country.code)),
      special_field: {}
    })
  }

  let [answeredQtns, setAnsweredQtns] = useState([userInfoQuetions[0]])
  let [loading, setLoading] = useState(false)
  const scrollRef = useRef(null);
  const [showPaymentSummaryModal, setshowPaymentSummaryModal] = useState(false)
  const [razorApiKey, setrazorApiKey] = useState(null);
  const scroll2Ref = useRef(null);
  const scrollHeightRef = useRef({ heights: {} });
  let allQuetions = userInfoQuetions.concat(Object.entries(setupProfileQuestions)[0][1]);
  const isPrev = useRef();

  const allQuestionsAnswered = userDegreeCourseSelections[allQuetions[allQuetions.length - 1]?.application_display_name];

  useEffect(() => {
    if (isPrev.current) {
      isPrev.current = false;
      return;
    }
    let item = allQuetions[currentQtn];
    let userDetailsObj = (userDetails?.userid ? userDetails : userData) || {};
    const userDataMapping = {
      email_address: {
        key: "email_address"
      },
      first_name: {
        key: "first_name"
      },
      last_name: {
        key: "last_name"
      },
      age: {
        key: "date_of_birth",
        formate: (value) => {
          return moment(value, "DD/MM/YYYY").month(0).from(moment().month(0))?.split(" ")[0]
        }
      },
      highest_academic_achievement: {
        key: "highest_academic_achievement"
      },
      place_of_birth: {
        key: "country_of_birth",
      },
      country_of_residency: {
        key: "country_of_residency"
      },
      marital_status: {
        key: "marital_status"
      },
      occupation: {
        key: "occupation"
      }
    }
    let value;
    if (userDataMapping[item.key]) {
      value = userDetailsObj[userDataMapping[item.key]?.key];
      if (value && userDataMapping[item.key]?.formate) {
        value = userDataMapping[item.key]?.formate(value)
      }
    }

    if (value) {
      switch (item.type) {
        case "slider": {
          handleTextInput(value, item)
          return;
        }
        case "multiselect": {
          handleDropDownSelect(value, item)
          return;
        }
        case "integer": {
          handleTextInput(value.replace(/[^0-9]/g, ''), item)
          handleNext()
          return;
        }
        case "text": {
          handleTextInput(value.trim(), item)
          handleNext()
          return;
        }
        case "picker": {
          handleDropDownSelect(value, item)
          return;
        }
        default: {
          let index = item.select_options.findIndex(op => op == value);
          handleChecklistedItems(item, index, value)
        }
      }
    }

  }, [currentQtn, userData, userDetails])

  useEffect(() => {
    const headers = {
      Accept: "application/json",
      Authorization: `guest@immipath`,
    };
    const getRazorPayKey = async () => {
      let res = await Network("/data/get_razorpay_key", "GET", null, headers);
      if (res.status == "Success") {
        setrazorApiKey(res.key);
      }
    };
    getRazorPayKey();
  }, [userDetails]);

  const getOccupationsList = async () => {
    const headers = {
      Accept: "application/json",
      Authorization: guestLogin ? "guest@immipath" : userDetails?.userid + "@" + Token,
    };

    let response = await Network(
      "/data/get_dropdown_options",
      "GET",
      null,
      headers
    );
    const { occupations, languages } = response;
    let _occupation = [];
    let _languages = [];
    occupations?.length > 0 &&
      occupations.forEach((s, i) => {
        let Obj = {
          name: s,
          id: i,
        };
        _occupation.push(Obj);
      });
    languages?.length > 0 &&
      languages.forEach((s, i) => {
        let Obj = {
          name: s,
          id: i,
        };
        _languages.push(Obj);
      });
    let payload = {
      occupations: _occupation,
      languages: _languages,
    };
    dispatch(getOccupationAndLanguageList(payload));
  };

  useEffect(() => {
    if (Object.keys(selectedgoalandcountries?.userInfo || {}).length == userInfoQuetions.length && allQuestionsAnswered) {
      setUserInfo(selectedgoalandcountries.userInfo);
      setuserSelections(userDegreeCourseSelections);
      let questions = allQuetions.filter(qt => selectedgoalandcountries.userInfo[qt.key] || userDegreeCourseSelections[qt.application_display_name])
      setAnsweredQtns(questions);
      setCurrentQtn(allQuetions.length - 1)
      setTimeout(() => {
        scrollRef.current?.scrollToEnd({ animated: true });
        scroll2Ref.current.scrollToEnd({ animated: true });
      }, 1000)
    } else {
      setTimeout(() => {
        scrollRef.current?.scrollToOffset({ offset: 0, animated: true });
      }, 1000)
    }
    getOccupationsList();
  }, [])

  useEffect(() => {
    preCurrency.push(selectedCurrency);
  }, [selectedCurrency])

  const handleChecklistedItems = (item, index, option) => {
    let currentQt = currentQtn;
    let updatedQtns = answeredQtns;
    if (item.user_info) {
      userInfo[item.key] = option;
      setUserInfo({ ...userInfo })
      selectedgoalandcountries["userInfo"] = userInfo
      dispatch(setSelectedGoalandCountries(selectedgoalandcountries))
    } else {
      let temp = { ...userSelections }
      let temp2 = { ...userSelectionWtihQuestionId }
      temp[item.application_display_name] = index + 1;
      temp2[item.question_id] = option;
      let ind = allQuetions.findIndex(qt => qt.question_id == "7")
      if (item.question_id == "7") {
        updatedQtns = updatedQtns.slice(0, ind + 1);
        currentQt = ind;
        if (option == "Yes" && userSelections[allQuetions[currentQt + 1].application_display_name]) {
          currentQt = ind + 1;
          updatedQtns.push(allQuetions[currentQt])
        } else if (option == "No") {
          delete temp[allQuetions[currentQt + 1].application_display_name]
          delete temp2["8"]
        }
      }
      setuserSelections(temp)
      dispatch(setUserDegreeAndCourseSelection(temp, temp2));
    }
    Error[item.application_display_name] = false
    if (currentQt < allQuetions.length - 1) {
      let currentQtnIndex = currentQt + (item.question_id == "7" ? (index + 1) : 1)
      scrollQtnView(currentQtnIndex)
      updatedQtns.push(allQuetions[currentQtnIndex])
      setTimeout(() => {
        scroll2Ref.current.scrollToEnd();
      }, 500)
      setCurrentQtn(currentQtnIndex);
    }
    setAnsweredQtns(updatedQtns)
  };

  const handlePayment = (total) => {
    setshowPaymentSummaryModal(false)
    let razorPayIds = {};
    const url = "/razorpay/create_order";
    // const logPayUrl = `/users/${get(userInfo, 'email_address', '')}/log_payment`;
    const headers = {
      Accept: "application/json",
      Authorization: `guest@immipath`,
    };
    let body = {
      order_amount: helpers.storeCurrencyConverer(baseCurrency, total) * 100,
      statement: `Eligibility checker purchase`,
      order_currency: baseCurrency,
      order_receipt: `immi_recpt_IMEC_${new Date().getTime()}`,
    };

    Network(url, "POST", body, headers).then(async (res) => {
      const paymentBody = {
        user_id: get(userInfo, 'email_address', ''),
        amount: helpers.storeCurrencyConverer(baseCurrency, total),
        statement: `Eligibility checker purchase`,
        payment_type: {
          type: "razorpay",
          additional_info: {
            order_id: res?.order_details?.id,
            order_receipt: body.order_receipt,
          },
        },
      };
      var options = {
        description: 'Immistore Purchase',
        image: Images.toolbarLogo,
        currency: baseCurrency,
        key: razorApiKey,
        "handler": function (response) {
          // setLoading(true)
          handleFinish()
          razorPayPaymentStatus = true;
          razorPayIds.razorpay_payment_id = response.razorpay_payment_id;
          razorPayIds.razorpay_order_id = response.razorpay_order_id;
          razorPayIds.razorpay_signature = response.razorpay_signature;
          // Network(logPayUrl, 'POST', paymentBody, headers);
          razorPayIds.order_id = res.order_details.id;
          razorPayIds.order_receipt = paymentBody.payment_type.additional_info.order_receipt;

        },
        amount: helpers.storeCurrencyConverer(baseCurrency, total) * 100,
        name: 'Immigreat Pvt. Ltd.',
        order_id: res.order_details.id,
        prefill: {
          email: get(userInfo, 'email_address', ''),
        },
        theme: { color: '#FFAA00' }
      };
      const razorypayResponse = await _handleRazorPay('https://checkout.razorpay.com/v1/checkout.js');
      if (!razorypayResponse) {
        alert('Razorpay failed to load!');
        return
      }
      try {
        const payObj = new window.Razorpay(options);
        payObj.open();
        payObj.on('payment.failed', function (response) {
          setLoading(false)
          // registerPurchase(paymentBody.payment_type.additional_info, addressData, false)
        })
      } catch (error) {

      }
    })
  }

  const handleFinish = () => {
    let errorIndex;
    let error = answeredQtns.reduce((errors, qtn, index) => {
      if (qtn.is_mandatory && !userSelections[qtn.application_display_name] && !userInfo[qtn.key]) {
        errors[qtn.application_display_name] = 1;
        errorIndex = index
      }
      return errors
    }, {})
    if (Object.values(error).length) {
      setError({ ...error });
      setCurrentQtn(errorIndex);
      scroll2Ref.current?.scrollToIndex({ index: errorIndex });
      return;
    }
    nextTrack()
  }

  const scrollQtnView = (index) => {
    try {
      scrollRef.current?.scrollToIndex({ index: parseInt(index / 5) * 5 + (parseInt(index / 5) ? 1 : 0), animated: true })
    } catch (e) { }
  }

  const openPaySummaryModal = () => {
    setshowPaymentSummaryModal(true)
  }

  const handleNext = async () => {
    if (currentQtn >= allQuetions.length - 1) {
      return;
    }
    setLoading(true);
    let currentQtnIndex = currentQtn;
    let item = allQuetions[currentQtnIndex];
    let currentQtnAns = userInfo[item.key] || userSelections[item.application_display_name]

    if (currentQtnIndex < answeredQtns.length - 1) {
      currentQtnIndex = answeredQtns.length - 1
    }
    scrollQtnView(currentQtnIndex)
    if (item.key == "age" && currentQtnAns < 16) {
      errorText[item.application_display_name] = "Age must be greater than 16";
      setErrorText({ ...errorText });
      setLoading(false);
      return;
    } else if (item.key == "email_address" && currentQtnAns) {
      let res = {};
      let validateEmail = /\S+@\S+\.\S+/;
      if (!validateEmail.test(currentQtnAns)) {
        res.status = "Please enter a valid email id";
        // } else {
        //   res = await checkEmailExist(currentQtnAns);
        // };
        // if (res.status != "Success") {
        errorText[item.application_display_name] = res.status;
        setErrorText({ ...errorText });
        setLoading(false);
        return;
      }
      if (!userDetails?.userid) {
        checkAndGetUserByEmail(currentQtnAns)
      }
    }
    setLoading(false);
    if (!item.is_mandatory || currentQtnAns !== undefined) {
      currentQtnIndex = currentQtnIndex + 1;
      answeredQtns.push(allQuetions[currentQtnIndex])
      setAnsweredQtns(answeredQtns)
      setTimeout(() => {
        scroll2Ref.current?.scrollToEnd();
      }, 500)
      setCurrentQtn(currentQtnIndex);
      return;
    }

    scroll2Ref.current?.scrollToEnd();
    setCurrentQtn(currentQtnIndex);
    setError({ [item.application_display_name]: 1 })
  }

  const checkAndGetUserByEmail = async (email) => {
    const url = `/users/${email}`;
    const headers = {
      Accept: "application/json",
      Authorization: guestLogin ? "guest@immipath" : userDetails?.userid + "@" + Token
    };
    return Network(url, "GET", null, headers).then((res) => {
      if (res.user_data) {
        setUserData(res.user_data);
      }
    });
  };

  const handleTextInput = (text, item) => {
    Error[item.application_display_name] = false
    errorText[item.application_display_name] = false
    setError({ ...Error })
    setErrorText({ ...errorText })
    if (item.user_info) {
      userInfo[item.key] = text;
      setUserInfo(userInfo)
      selectedgoalandcountries["userInfo"] = userInfo
      dispatch(setSelectedGoalandCountries(selectedgoalandcountries))
      return;
    }
    let temp = { ...userSelections }
    let temp2 = { ...userSelectionWtihQuestionId }
    temp[item.application_display_name] = text
    temp2[item.question_id] = Number(text);
    setuserSelections(temp)
    // setuserSelectionWtihQuestionId(temp2)
    dispatch(setUserDegreeAndCourseSelection(temp, temp2))
  }

  const handleDropDownSelect = (value, item) => {
    handleTextInput(value, item);
    handleNext();
  }

  const colorOptions = ["#5338FC", "#F1AB32", "#E84A46", "#33AC09", "#5A9BFE"];
  let colors = allQuetions.reduce((colors, qt, index) => {
    colors[qt.application_display_name] = colorOptions[index % 5];
    return colors;
  }, {});

  const renderQuetionsContainer = () => {

    const scaleUp = { from: { scale: 1 }, to: { scale: 1.2 } };
    const scaleDown = { from: { scale: 1.2 }, to: { scale: 1 } };


    return (
      <View style={{ height: 360, width: "100%", alignItems: "center" }}>
        <FlatList
          data={allQuetions}
          showsVerticalScrollIndicator={false}
          style={{ width: "80%", }}
          initialScrollIndex={0}
          ref={ref => scrollRef.current = ref}
          keyExtractor={(_, index) => index + "styles"}
          renderItem={({ item, index }) => {
            const active = currentQtn == index;
            return (
              <Animatable.View animation={active ? scaleUp : scaleDown} style={{ flexDirection: "row", alignSelf: "center", alignItems: "center", width: "80%", marginBottom: active ? 15 : 10 }}>
                <View style={{ marginHorizontal: 20 }}>
                  {userSelections[item.application_display_name] || userInfo[item.key] ?
                    <Image style={{ width: 14, height: 14 }} source={Images.redeemIconWhite} />
                    : <View style={{ width: 12, height: 12, borderRadius: 6, borderWidth: 2, borderColor: "#fff" }} />}
                </View>
                <TouchableOpacity onPress={() => {
                  if (answeredQtns.length > index || allQuestionsAnswered) {
                    setCurrentQtn(index)
                    scroll2Ref.current?.scrollToIndex({ index });
                  }
                }} style={{ flex: 1, paddingHorizontal: 10, paddingVertical: 13, backgroundColor: "#fff", height: 50, justifyContent: "center", alignItems: "center" }}>
                  <Text style={{ fontWeight: "400", textAlign: "center", color: colors[item.application_display_name] }}>{item.application_display_name}</Text>
                </TouchableOpacity>
              </Animatable.View>
            )
          }}
        />
      </View>)
  }

  const renderQtn = ({ item, bgColor }) => {
    var minVal = item.min_val;
    var maxVal = item.max_val;
    let displayMaxVal = item.max_val;
    let displayValue = userInfo[item.key] || userSelections[item.application_display_name];
    let base_unit = item.base_unit == 'USD' ? helpers.getCurrencySymbol(selectedCurrency) : item.base_unit;
    if (item.base_unit === "USD") {
      minVal = Math.round(
        helpers.currencyConverter(selectedCurrency, item.min_val)
      );
      maxVal = Math.round(
        helpers.currencyConverter(selectedCurrency, item.max_val)
      );
      displayMaxVal = helpers.convertFormat(
        Math.round(
          helpers.currencyConverter(selectedCurrency, item.max_val)
        ),
        selectedCurrency
      );
      if (preCurrency[preCurrency.length - 1] == selectedCurrency) {
        displayValue = helpers.convertFormat(
          Math.round(
            userSelections[item.application_display_name] === undefined
              ? maxVal
              : userSelections[item.application_display_name]
          ),
          selectedCurrency
        );
      } else if (preCurrency[preCurrency.length - 1] !== "USD") {
        displayValue = helpers.convertFormat(
          Math.round(
            helpers.currencyDevider(
              preCurrency[preCurrency.length - 1],
              userSelections[item.application_display_name] === undefined
                ? maxVal
                : userSelections[item.application_display_name]
            )
          ),
          selectedCurrency
        );
      } else {
        displayValue = helpers.convertFormat(
          Math.round(
            helpers.currencyConverter(
              selectedCurrency,
              userSelections[item.application_display_name] === undefined
                ? maxVal
                : userSelections[item.application_display_name]
            )
          ),
          selectedCurrency
        );
      }
    }

    const renderItem = (item) => {
      switch (item.type) {
        case "countries": {
          return (
            <>
              <CountrySelect
                countries={item.select_options}
                selectedCountries={userInfo[item.key]}
                containerStyle={{ width: "100%" }}
                onCountrySelect={(country) => {
                  userInfo[item.key] = userInfo[item.key] || {};
                  if (userInfo[item.key][country.code]) {
                    delete userInfo[item.key][country.code];
                  } else {
                    userInfo[item.key][country.code] = 1;
                  }
                  setUserInfo(userInfo)
                  selectedgoalandcountries["userInfo"] = userInfo
                  dispatch(setSelectedGoalandCountries(selectedgoalandcountries))
                }} />
              <TouchableOpacity
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 8,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: userInfo[item.key] ? Object.values(userInfo[item.key]).includes(1) ? "transparent" : "#ff000050" : "transparent"
                }}
                onPress={() => {
                  userInfo[item.key] = item.select_options.reduce((countries, country) => {
                    countries[country.code] = 0
                    return countries;
                  }, {})
                  setUserInfo(userInfo)
                  selectedgoalandcountries["userInfo"] = userInfo
                  dispatch(setSelectedGoalandCountries(selectedgoalandcountries))
                }}>
                <Text>{"None"}</Text>
              </TouchableOpacity>
            </>
          )
        }
        case "slider": {
          return (
            <View style={styles.sliderView}>
              <Slider
                onValueChange={(value) => handleTextInput(value, item)}
                maximumValue={maxVal}
                step={item.slider_step}
                value={userSelections[item.application_display_name]}
                thumbTintColor="maroon"
              />
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Text>
                  {base_unit + ' ' + minVal}
                </Text>
                <Text>
                  {displayValue}
                </Text>
                <Text>
                  {base_unit + ' ' + maxVal}
                </Text>
              </View>
            </View>
          )
        }
        case "multiselect": {
          const _iconRendere = ({ name, size = 12, style }) => {
            // flatten the styles
            const flat = StyleSheet.flatten(style);
            // remove out the keys that aren't accepted on View
            const { color, fontSize, ...styles } = flat;

            let iconComponent;
            // the colour in the url on this site has to be a hex w/o hash
            const iconColor =
              color && color.substr(0, 1) === "#" ? `${color.substr(1)}/` : "";

            const Down = (
              <Image
                source={Images.dropDownArrow}
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 5,
                }}
              />
            );
            const Cancel = (
              <Image source={Images.cancel} style={{ width: 15, height: 15 }} />
            );
            const Up = (
              <Image
                source={Images.dropDownArrow}
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 10,
                  transform: [{ rotateX: "180deg" }],
                }}
              />
            );
            switch (name) {
              case "keyboard-arrow-up":
                iconComponent = Up;
                break;
              case "keyboard-arrow-down":
                iconComponent = Down;
                break;
              case "cancel":
                iconComponent = Cancel;
                break;
              default:
                iconComponent = null;
                break;
            }
            return iconComponent;
          };
          return <SectionedMultiSelect
            styles={multiSelectStyle}
            modalWithTouchable
            modalWithSafeAreaView
            itemsFlatListProps={{
              style: isWeb() ? {
                height: "83vh",
                overflowY: "auto"
              } : {}
            }}
            items={item.select_options}
            uniqueKey="id"
            // hideSearch={true}
            subKey="children"
            showCancelButton={true}
            // selectText={`select ${item.application_display_name}`}
            showDropDowns={true}
            single={true}
            chipsPosition="top"
            iconRenderer={_iconRendere}
            // showChips={true}
            // customChipsRenderer={() => null}
            readOnlyHeadings={true}

            onSelectedItemObjectsChange={(objs) => {
              handleDropDownSelect(objs[0].name, item)
            }}
            onSelectedItemsChange={(value) => {
              console.log("ee", value)
            }}
            renderSelectText={() => (
              <Text style={{ color: displayValue ? "black" : "grey", flex: 1, marginLeft: 0 }}>{displayValue || "Highest Academic Achievement *"}</Text>
            )}
          />
        }
        case "integer": {
          return (
            <TextInput
              key={item.application_display_name}
              keyboardType="numeric"
              placeholder="Enter here"
              value={userInfo[item.key] || userSelections[item.application_display_name]}
              autoFocus={true}
              onSubmitEditing={() => handleNext()}
              onChangeText={(t) => {
                if (isNaN(Number(t))) {
                  t = t.length > 1 ? t.slice(0, t.length - 1)
                    : "0"
                }
                handleTextInput(t, item)
              }}
            />
          )
        }
        case "text": {
          return (
            <TextInput
              key={item.application_display_name}
              placeholder="Enter here"
              value={userInfo[item.key] || userSelections[item.application_display_name]}
              autoFocus={true}
              onSubmitEditing={() => handleNext()}
              onChangeText={(t) => {
                handleTextInput(t.trim(), item)
              }}
            />
          )
        }
        case "picker": {
          return <RNModalPicker
            data={item.select_options}
            pickerTitle={item.application_display_name}
            showSearchBar={true}
            disablePicker={false}
            changeAnimation={"fade"}
            searchBarPlaceHolder={"Search....."}
            showPickerTitle={true}
            listTextStyle={styles.listTextViewStyle}
            itemListStyle={styles.itemListStyle}
            selectedText={displayValue || `Select ${item.application_display_name}`}
            selectedTextStyle={{ ...styles.selectLabelTextStyle, color: displayValue ? "#000" : "grey" }}
            pickerStyle={styles.pickerStyle}
            selectedValue={(index, value) => handleDropDownSelect(value.name, item)}
            listContainerStyle={styles.pickerListContainer}
          />
        }
        default: {
          return item.select_options.map((option, index2) => {
            return (
              <View
                style={{
                  marginBottom: 10,
                }}
              >
                <TouchableOpacity
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onPress={() => handleChecklistedItems(item, index2, option)}
                >
                  <Image
                    style={[styles.checkIcon]}
                    source={
                      (userSelections[item.application_display_name] - 1 === index2 || userInfo[item.key] == option)
                        ? Images.immipathSearchChecked
                        : Images.immipathSearchUnchecked
                    }
                  />
                  <Text style={[styles.options]}>{option}</Text>
                </TouchableOpacity>
              </View>
            );
          })
        }
      }
    }

    return (
      <View
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          scrollHeightRef.current.heights[item.application_display_name] = height;
          scrollHeightRef.current.offset = _.sum(Object.values(scrollHeightRef.current.heights));
        }}
        style={{
          width: "90%",
          marginLeft: "5%",
        }}
      >
        <TrailFromBottom>
          <TouchableOpacity activeOpacity={1} style={[styles.messageWrapper]}>
            <ChatTriangle color={bgColor} />
            <View
              style={[
                styles.messageVisibleView,
                {
                  backgroundColor: bgColor,
                },
              ]}
            >
              <Text style={[styles.message]}>{item.is_mandatory ? item.question + '*' : item.question}</Text>
              {item.info ? <Tooltip arrow placement="top-end" style={{ fontSize: 13 }} title={<Text style={{ color: "#fff" }}>{item.info}</Text>}>
                <Image source={Images.info1} style={[{ height: 20, width: 20, alignSelf: "flex-end" }]} />
              </Tooltip> : null
              }
            </View>
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={1} style={[styles.messageWrapper]}>
            <View
              style={[
                styles.messageVisibleView,
                {
                  backgroundColor: "#fff",
                  marginLeft: "18%",
                  borderWidth: 0.5,
                  borderColor: Error[item.application_display_name] ? 'red' : 'white'
                },
              ]}
            >
              {renderItem(item)}
            </View>
            <ChatTriangle color={"#fff"} style={{ marginRight: 0, marginLeft: -14 }} />
          </TouchableOpacity>
          {errorText[item.application_display_name] ? <Text style={{ color: "red", fontSize: 12, textAlign: "center", marginVertical: 5 }}>{errorText[item.application_display_name]}</Text> : null}
        </TrailFromBottom>
      </View>
    )
  }


  const ChatTriangle = ({ color, style = {} }) => (
    <View style={[{
      width: 0,
      height: 0,
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderBottomWidth: 0,
      marginRight: -7,
      borderRightWidth: 15,
      borderTopWidth: 15,
      borderLeftWidth: 15,
      borderTopColor: color,
      borderRightColor: 'transparent',
      borderBottomColor: "transparent",
      borderLeftColor: 'transparent',
      zIndex: -1
    }, style]} />
  )

  const emailQtn = {
    application_display_name: "Email address",
    fact_id: null,
    is_chat: true,
    is_drop_down: false,
    is_mandatory: true,
    is_text_input: true,
    type: "text",
    question: "What is your email address?",
    user_info: true,
    key: "email_address",
    select_options: [],
    special_field: {}
  }

  return (
    <View style={[styles.main]}>
      {loading ? <Loader /> : null}
      {isWeb() && !mobilBrower() ?
        <View style={[styles.contentContainer, { backgroundColor: colors[allQuetions[currentQtn].application_display_name] }]}>
          {renderQuetionsContainer()}
        </View> : null}
      <View style={styles.contentContainer}>
        <Text style={[styles.textT]}>Setup Your Profile</Text>
        <View style={{ flex: 1, width: "100%" }}>

          {/* {renderQtn({ item: emailQtn, bgColor: colors[emailQtn.application_display_name], index: 101 })} */}
          <FlatList
            keyExtractor={(_, index) => index + "d"}
            data={answeredQtns}
            renderItem={({ item, index }) => (
              renderQtn({ item, bgColor: colors[item.application_display_name], index })
            )}
            showsVerticalScrollIndicator={false}
            style={styles.qtnContainer}
            onLayout={(event) => {
              const { height } = event.nativeEvent.layout;
              scrollHeightRef.current.viewHeight = height;
            }}
            ref={ref => scroll2Ref.current = ref}
            contentContainerStyle={{ height: "100%" }}
          />
          <View style={styles.nextBtnContainer}>
            {currentQtn ?
              <TouchableOpacity onPress={() => {
                scrollHeightRef.current.offset = scrollHeightRef.current.offset - scrollHeightRef.current.heights[allQuetions[currentQtn].application_display_name]
                let currentQt = currentQtn - 1
                if (allQuetions[currentQt - 1]?.question_id == "7" && userSelectionWtihQuestionId["7"] == "No") {
                  currentQt -= 1;
                }
                isPrev.current = true;
                setCurrentQtn(currentQt)
                scroll2Ref.current?.scrollToOffset({ offset: scrollHeightRef.current.offset - scrollHeightRef.current.viewHeight, animated: true });
                scrollRef.current?.scrollToIndex({ index: parseInt(currentQt / 5) * 5 - (parseInt(currentQt / 5) ? 1 : 0), animated: true })
              }} style={[styles.nextWrapper]}>
                <Text style={[styles.nextText]}>Prev</Text>
              </TouchableOpacity> : <View />}
            <TouchableOpacity disabled={loading} onPress={() => allQuestionsAnswered ? openPaySummaryModal() : handleNext()} style={[styles.nextWrapper, { width: allQuestionsAnswered ? 180 : 160 }]}>
              <Text style={[styles.nextText]}>{allQuestionsAnswered ? "Check Eligibility" : (answeredQtns[answeredQtns.length - 1].is_mandatory || (userSelections[answeredQtns[answeredQtns.length - 1].application_display_name] || userInfo[answeredQtns[answeredQtns.length - 1].key]) ? "Next" : "Skip")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {
        showPaymentSummaryModal &&
        <PaymentModal
          visible={showPaymentSummaryModal}
          handlePayment={handlePayment}
          handleFinish={handleFinish}
          onClose={() => setshowPaymentSummaryModal(false)} />
      }
    </View>
  );
};
