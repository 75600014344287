import React, { useState } from 'react'
import {
  View, TouchableOpacity, TouchableHighlight, Text, TextInput
} from "react-native";
import BullitinModal from "../../../components/BullitinModal/BullitinModal";
import { styles as s } from "../newDashboardUi.styles";
import { SelectionModal } from "../../../components/SelectionModal/SelectionModal";
import { Config, Images, colors } from "../../../common";
import CurrentStageSelectionModal from "../../../components/EditProfile/components/CurrentStageSelection/CurrentStageSelectionModal";
import DateOfBirth from "../../../components/EditProfile/components/DateOfBirth/DateOfBirth"
import { Network } from "../../../service/api";
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from "../../../actions/auth.actions";
import { get } from 'lodash';

function CompleteProfileModal({ visible, onClose, missinFields }) {
  const dispatch = useDispatch()
  const [gender, setGender] = useState(null);
  const [maritalStatus, setMartitalStatus] = useState(null);
  const [phoneNumber, setphoneNumber] = useState(null)
  const [selectionModal, setSelectionModal] = useState({
    active: false,
    params: null,
  });
  const [countryResidence, setcountryResidence] = useState(null)
  const [openStageSelectionModal, setopenStageSelectionModal] = useState(false)
  const [currentStage, setcurrentStage] = useState(null)
  const [birthDate, setBirthDate] = useState(null);
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const token = useSelector((state) => state.authReducer.authData.token);

  const sortAplhabetically = () => {
    let newarr = Config.homeCountries.sort(function (a, b) {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    })
    return newarr
  }
  const handleGenderSelection = () => {
    setSelectionModal({
      active: true,
      params: {
        onSelectFn: setGender,
        options: [
          {
            name: "Male",
            id: "M",
          },
          {
            name: "Female",
            id: "F",
          },
        ],
        height: "40%",
        itemKey: "name",
        returnKey: "name",
      },
    });
  };

  const handleMaritalStatusSelection = () => {
    setSelectionModal({
      active: true,
      params: {
        onSelectFn: setMartitalStatus,
        options: [
          {
            name: "Married",
            id: "married",
          },
          {
            name: "Single",
            id: "single",
          },
          {
            name: "Divorced",
            id: "divorced",
          },
        ],
        height: "40%",
        itemKey: "name",
        returnKey: "name",
      },
    });
  };

  const handleHomecountries = () => {
    setSelectionModal({
      active: true,
      params: {
        onSelectFn: setcountryResidence,
        options: sortAplhabetically(),
        height: "68%",
        itemKey: "name",
        returnKey: "name",
      },
    });
  };

  const handleSubmit = async () => {
    try {
      const headers = {
        Accept: "application/json",
        Authorization: userDetails?.userid + "@" + token,
      };
      const url = "/users/" + userDetails?.userid;
      let obj = {
        current_stage: currentStage?.split('->') || [],
        country_of_residency: countryResidence,
        date_of_birth: birthDate,
        sex: gender,
        phone: phoneNumber,
        marital_status: maritalStatus
      }
      let newObj = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
      const e = await Network(url, "PATCH", newObj, headers);
      if (e) {
        onClose()
        await dispatch(getUserDetails(userDetails?.userid, token));
        setTimeout(() => {
        }, 1000);
      }
    } catch (error) {
      onClose()
    }
  }

  const handleCurrentStage = () => {
    setopenStageSelectionModal(true)
  }
  const setSelectedStage = (item) => {
    setcurrentStage(item)
  }
  return (
    <BullitinModal
      logo="pin"
      heading=""
      visible={visible}
      onClose={onClose}
      handleClose={onClose}
      closeIcon={"closeBlack"}
    >
      <SelectionModal
        params={selectionModal.params}
        visible={selectionModal.active}
        setVisible={setSelectionModal}
      />
      <CurrentStageSelectionModal
        setSelectedStage={setSelectedStage}
        visible={openStageSelectionModal}
        handleClose={() => setopenStageSelectionModal(false)}
        onClose={() => setopenStageSelectionModal(false)}
      />
      {
        missinFields?.filter((s) => s.alias == 'date_of_birth')?.length > 0 &&
        <DateOfBirth setBirthDate={setBirthDate} birthDate={birthDate} />
      }
      {
        missinFields?.filter((s) => s.alias == 'sex')?.length > 0 &&
        <TouchableHighlight
          underlayColor="rgb(230,230,230)"
          onPress={handleGenderSelection}
          style={[s.inputBoxLarge, s.inputBoxmarginTop]}
        >
          <View style={s.inputWrapper}>
            <Text style={s.inputLabel}>Gender</Text>
            <Text style={s.textInputLarge}>
              {gender ? gender : 'Select'}
            </Text>
          </View>
        </TouchableHighlight>
      }

      {
        missinFields?.filter((s) => s.alias == 'marital_status')?.length > 0 &&
        <TouchableHighlight
          underlayColor="rgb(230,230,230)"
          onPress={handleMaritalStatusSelection}
          style={[s.inputBoxLarge, s.inputBoxmarginTop]}
        >
          <View style={s.inputWrapper}>
            <Text style={s.inputLabel}>Marital Status</Text>
            <Text style={s.textInputLarge}>
              {maritalStatus ? maritalStatus : 'Select'}
            </Text>
          </View>
        </TouchableHighlight>
      }
      {
        missinFields?.filter((s) => s.alias == 'phone')?.length > 0 &&
        <View style={[s.inputBoxLarge, s.inputBoxmarginTop]}>
          <View style={s.inputWrapper}>
            <Text style={s.inputLabel}>Phone Number</Text>
            <TextInput
              style={[s.textInputLarge, { color: "grey" }]}
              maxLength={10}
              onChangeText={setphoneNumber}
            />
          </View>
        </View>
      }
      {
        missinFields?.filter((s) => s.alias == 'country_of_residency')?.length > 0 &&
        <TouchableHighlight
          underlayColor="rgb(230,230,230)"
          onPress={handleHomecountries}
          style={[s.inputBoxLarge, s.inputBoxmarginTop]}
        >
          <View style={s.inputWrapper}>
            <Text style={s.inputLabel}>Home Countries</Text>
            <Text style={s.textInputLarge}>
              {countryResidence
                ? countryResidence
                : 'Select'}
            </Text>
          </View>
        </TouchableHighlight>
      }
      {
        missinFields?.filter((s) => s.alias == 'current_stage')?.length > 0 &&
        <TouchableHighlight
          underlayColor="rgb(230,230,230)"
          onPress={handleCurrentStage}
          style={[s.inputBoxLarge, s.inputBoxmarginTop]}
        >
          <View style={s.inputWrapper}>
            <Text style={s.inputLabel}>Current Stage</Text>
            <Text style={s.textInputLarge}>
              {currentStage
                ? currentStage : get(userDetails, 'current_stage', []) ? get(userDetails, 'current_stage', [])
                  : 'Select'}
            </Text>
          </View>
        </TouchableHighlight>
      }
      <TouchableOpacity onPress={handleSubmit} style={s.submitButton}>
        <Text style={s.ViewButtonText}>
          Submit
        </Text>
      </TouchableOpacity>
    </BullitinModal>
  )
}

export default CompleteProfileModal