import React, { useEffect, useState, useRef } from 'react'
import { View, Platform, ScrollView, Text, Image, Dimensions, TouchableOpacity, FlatList } from "react-native";
import { styles as s } from "./styles";
import { family, Images, helpers } from '../../../common';
import { useDispatch, useSelector } from 'react-redux';
import { Network } from '../../../service/api';
import VisaDetailsModal from "../../../pages/QuickSearch/components/VisaDetailed"
import Carousel from 'react-native-snap-carousel';
import AlertModal from '../../../components/AlertModal/AlertModal';
import { get } from 'lodash';
import { getSavedUserPath } from '../../../actions/path.actions';
import AsyncStorage from '@react-native-community/async-storage';

const { width } = Dimensions.get('window')

export default function YourOppCard({ lastShortListedpath }) {
  const sliderRef = useRef()
  const dispatch = useDispatch()
  const [showDetailsModal, setshowDetailsModal] = useState(false)
  const [showErrorModal, setshowErrorModal] = useState(false)
  const [selectedVisa, setselectedVisa] = useState(null)
  const [guestPaths, setguestPaths] = useState([])
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);
  const savedPaths = useSelector((state) => state.pathReducer.getImmipathDetails.userSavedPaths);
  let path = lastShortListedpath;
  const [previousPaths, setpreviousPaths] = useState(path)
  // const [savedpaths, setsavedpaths] = useState([])
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const token = useSelector((state) => state.authReducer.authData.token);
  const [slideIndex, setslideIndex] = useState(0)

  useEffect(() => {
    if (!guestLogin) {
      dispatch(getSavedUserPath(userDetails?.userid, token))
    }
  }, [userDetails])

  useEffect(() => {
    const getlastStage = async () => {
      const lastVisitedVisa = await AsyncStorage.getItem('lastVisitedVisa');
      if (lastVisitedVisa && !guestLogin) {
        setselectedVisa(JSON.parse(lastVisitedVisa))
        setTimeout(() => {
          setshowDetailsModal(true)
        }, 500);
        AsyncStorage.removeItem('lastVisitedVisa')
      }
    }
    getlastStage()
  }, [])

  useEffect(() => {
    const getOppourtunities = async () => {
      const headers = {
        Accept: "application/json",
        Authorization: 'guest@dashboard',
      };
      let temp = []
      let guestPaths = await Network('/immipaths/randomize', "GET", null, headers)
      guestPaths?.immipaths.length > 0 && guestPaths?.immipaths?.forEach((s) => s.type = 'Recommended')
      if (!path || path?.length < 3) {
        temp = path ? [...path] : [];
        let remainPath = 3 - temp.length;
        for (let index = 0; index < remainPath; index++) {
          let obj = {
            immipath_data: guestPaths?.immipaths[index + 1]
          }
          temp.push(obj)
        }
        setpreviousPaths(temp)
      }
      setguestPaths(guestPaths)
    }
    getOppourtunities()
  }, [path])

  const isSavedPath = (id) => {
    let str = ''
    if (id) {
      let isSaved = savedPaths?.find((i) => get(i, 'immipath_data.immipath_id', 0) == id)
      if (isSaved) {
        str = 'Saved Path'
      } else {
        str = 'Recommended'
      }
    }
    return str
  }

  const _renderItem = ({ item }) => {
    let data = guestLogin ? item : item.immipath_data
    return (
      <TouchableOpacity onPress={() => handlePress(data)} activeOpacity={1} style={{ flexDirection: 'row', alignItems: 'flex-end', height: 100, }}>
        <Image
          style={s.flag}
          source={helpers.getCountryFlag(data?.country_id)} />
        <View style={{ flex: 1, marginLeft: 10, overflow: 'visible', top: 10 }}>
          <View style={s.recommendedView}>
            <Text style={s.recommendedText}>
              {guestLogin ? 'Recommended' : isSavedPath(get(item, 'immipath_data.immipath_id', 0))}
            </Text>
          </View>
          <Text numberOfLines={3} style={s.visaName}>
            {data?.immipath_name}
          </Text>
          <Text>
            {helpers.getCountryName(data?.country_id)}
          </Text>
          <TouchableOpacity style={s.ViewButton} onPress={() => handlePress(data)}>
            <Text style={s.ViewButtonText}>
              View
            </Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    );
  }

  const handlePress = async ({ immipath_id }) => {
    const headers = {
      Accept: "application/json",
      Authorization: guestLogin ? 'guest@dashboard' : userDetails?.userid + "@" + token,
    };
    let id = immipath_id ? immipath_id : path[0].immipath_id;
    let details = await Network(`/immipaths/paths/${id}`, "GET", null, headers)
    if (details?.status == 'Success') {
      setselectedVisa(details?.path_details)
      setshowDetailsModal(true)
      if (guestLogin) {
        AsyncStorage.setItem('lastVisitedVisa', JSON.stringify(details?.path_details))
      }
    }

  }

  return (
    <View style={s.discovercrd}>
      <Text style={{ fontSize: 25, fontFamily: family.bebas }}>
        YOUR
      </Text>
      <Text style={{ fontSize: 32, fontFamily: family.bebas }}>
        OPPORTUNITIES
      </Text>
      <View style={s.bottomRow}>
        {
          <>
            <TouchableOpacity onPress={() => sliderRef.current.snapToPrev(animated = true)} style={{ arginRight: 5, position: 'absolute', zIndex: 111111 }}>
              <Image source={Images.sliderLeft} style={{ height: 28, width: 28, opacity: 0.5, }} />
            </TouchableOpacity>
            <Carousel
              ref={sliderRef}
              data={guestLogin ? guestPaths.immipaths : previousPaths}
              renderItem={_renderItem}
              sliderWidth={width}
              loop
              itemWidth={width}
            />
            <TouchableOpacity style={{ zIndex: 1111111, }} onPress={() => sliderRef.current.snapToNext(animated = true)}>
              <Image source={Images.sliderRight} style={{ height: 28, width: 28, opacity: 0.5 }} />
            </TouchableOpacity>
          </>
        }
      </View>
      {showDetailsModal && (
        <VisaDetailsModal
          visible={true}
          selectedPath={selectedVisa}
          setshowErrorModal={setshowErrorModal}
          onClose={() => { setshowDetailsModal(false) }} />
      )}
      {showErrorModal ? (
        <AlertModal
          title="Login"
          description="Login to explore more!"
          showCancel
          showConfirm
          confirmText="Login"
          cancelText="Not now"
          loginModal
          onCancelPress={() => setshowErrorModal(false)}
          onConfirmPress={() => setshowErrorModal(false)}
        />
      ) : (
        <View />
      )}
    </View>
  )
}
