import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Modal,
  Platform,
} from "react-native";
import { Images, family } from "../common";
import { mobilBrower } from "../common/helpers";
import { heightPercentageToDP } from "../common/responsiveScreen";
import BlurBackground from "./BlurBackground";

export default function FaqAnswerModal({ item, handleClose, handleAskExpert }) {
  const content = () => {
    return (
      <View style={styles.mainView}>
        <View style={styles.innerView}>
          <TouchableOpacity
            onPress={handleClose}
            style={styles.closeIconButton}
            hitSlop={{ left: 20, right: 20, top: 20, bottom: 20 }}
          >
            <Image
              source={Images.closeBlack}
              style={styles.closeIcon}
            />
          </TouchableOpacity>
          <View style={{ padding: heightPercentageToDP("1%") }}>
            <Text style={styles.questionText}>{item.name}</Text>
            <Text style={styles.answer}>{item.answer}</Text>
          </View>
          <TouchableOpacity
            onPress={() => {
              handleAskExpert(item.name), handleClose();
            }}
            style={styles.askExpertbutton}
          >
            <Text style={styles.askExpertText}>Ask an expert</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  return (
    <Modal transparent>
      <BlurBackground />
      {content()}
    </Modal>
  );
}

const styles = StyleSheet.create({
  mainView: {
    height: "100%",
    width: "100%",
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  innerView: {
    backgroundColor: "#F1F1F1",
    borderRadius: 15,
    width: Platform.OS == "web" && !mobilBrower() ? "35%" : "90%",
    alignItems: "center",
    padding: 25,
    shadowColor: "white",
    shadowOffset: { width: 0, height: 0.5 },
    shadowOpacity: 0.4,
    shadowRadius: 5,
    elevation: 12,
    justifyContent: "center",
  },
  closeIconButton: {
    position: "absolute",
    top: 15,
    right: 15,
    zIndex: 99999,
    height: 20,
    alignItems: "center",
    justifyContent: "center",
    width: 20,
  },
  closeIcon: {
    height: 15,
    width: 15,
  },
  questionText: {
    fontSize: 17,
    marginTop: 15,
    fontFamily: family.effra,
    textAlign: "left",
    alignSelf: "center",
  },
  answer: {
    fontSize: 15,
    fontFamily: family.regular,
    textAlign: "left",
    marginTop: heightPercentageToDP("1%"),
    alignSelf: "center",
  },
  askExpertbutton: {
    height: 45,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    alignSelf: "center",
    marginTop: heightPercentageToDP("1.2%"),
    backgroundColor: "#F1AB32",
    width: "100%",
  },
  askExpertText: {
    color: "white",
    fontSize: heightPercentageToDP("1.6%"),
  },
});
