import React, { Component } from "react";
import {
  TextInput,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  SafeAreaView,
  Image,
  ScrollView,
  Platform,
  KeyboardAvoidingView,
  BackHandler,
} from "react-native";
import { Images, colors } from "../../common";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, change } from "redux-form";
import { launchImageLibrary } from "react-native-image-picker";
import AsyncStorage from "@react-native-community/async-storage";
import { Network } from "../../service/api";
import NetInfo from "@react-native-community/netinfo";
import EnterReferralCodeModal from "../../components/EnterReferralCodeModal";
import { newSignUpUiStyles as styles } from "./newSignUpUi.styles";
import {
  onFacebookButtonPressHelper,
  onGoogleButtonPressHelper,
} from "../../common/helpers";
let currentInput = null;
import SignUpSection1 from "./components/SignUpSection1";
import SignUpSection2 from "./components/SignUpSection2";
import SignUpSection3 from "./components/SignUpSection3";
import { firebaseEvents, logEvent } from "../../common/firebaseEvents";
import AlertModal from "../../components/AlertModal/AlertModal";
import SweetAlert from "../../components/SweetAlert";
import { createNewUser } from "../../actions/auth.actions";
import PinValidation from "../../components/PinValidation/PinValidation";
import CreatePassword from "../../components/Password/Password";

class NewSignupUi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryOfBirth: "",
      referralSource: "",
      referralSourceAlt: "",
      referralModalVisible: false,
      countryIntrest: this.props?.countryIntrest || [],
      immigrationInterst: this.props?.goalsIntrest || [],
      errmessage: {},
      userSocialData: this.props?.socialUserInfo ? this.props.socialUserInfo : {},
      validAge: false,
      checkconnection: false,
      policyCheck: true,
      errModal: false,
      referralCode: "",
      showBottomErro: {},
      errorMessage: "",
      eventCheck: true,
      currentSlide: 1,
      userPostData: {},
      valdidatedata: {}
    };
  }

  _selectedValue4(index, item) {
    this.setState({ highestAcademicAchievement: item.name });
    this.props.highestAcademicAchievement(item.name);
  }

  _selectedValue4(item) { }

  customChipsRenderer = (props) => {
    // console.log("props", props);
    return null;
  };

  goalSelected = (code) => {
    let tempGoal = [...this.state.immigrationInterst];
    if (tempGoal.includes(code)) {
      tempGoal = tempGoal.filter((q) => q !== code);
    } else {
      tempGoal.push(code);
    }
    this.setState({ immigrationInterst: tempGoal });
  };

  countrySelection = (code) => {
    let tempCountry = [...this.state.countryIntrest];
    if (tempCountry.includes(code)) {
      tempCountry = tempCountry.filter((q) => q !== code);
    } else {
      tempCountry.push(code);
    }
    this.setState({ countryIntrest: tempCountry });
  };

  _selectedValue1(index, item) {
    this.setState({ countryOfBirth: item.name });
    this.props.countrySelect(item.name);
  }

  _selectedValue2(index, item) {
    this.setState({ homeCountries: item.name });
  }

  handleBackButton = () => {
    if (this.state.currentSlide > 1) {
      this.onBackPress()
    }
    return true;
  }

  componentDidMount = async () => {
    BackHandler.addEventListener("hardwareBackPress", this.handleBackButton);
  };

  componentWillUnmount() {
    BackHandler.removeEventListener("hardwareBackPress", this.handleBackButton);
  }

  checkEmailExist = async (email) => {
    const url = `/users/check_if_new_user`;
    const headers = {
      Accept: "application/json",
    };
    let body = {
      email_address: email,
    };
    return Network(url, "POST", body, headers).then((res) => {
      return res;
    });
  };

  checkReferralCode = async () => {
    const url = `/users/check_referral_code`;
    const headers = {
      Accept: "application/json",
    };
    let body = {
      referral_code: this.state.referralCode,
    };
    return Network(url, "POST", body, headers).then((res) => {
      return res;
    });
  };

  handleOnChange = (e, onChange, name) => {
    if (currentInput && currentInput !== name) {
      let temp = this.state.errmessage;
      temp[currentInput] = null;
      this.setState({ errmessage: temp });
    }
    if (name !== "email") {
      if (/^[a-zA-Z\s]*$/.test(e)) {
        currentInput = name;
        onChange(e);
        let temp = this.state.errmessage;
        temp[name] = null;
        this.setState({ errmessage: temp });
      } else {
        let temp = { ...this.state.errmessage };
        temp[name] = "only letters allowed";
        this.setState({ errmessage: temp });
      }
    } else {
      let temp = this.state.errmessage;
      temp[name] = null;
      this.setState({ errmessage: temp });
      onChange(e);
    }
  };

  _renderSelectText = () => {
    return this.state.selectedItemObjects ? (
      <Text style={{ color: "black", flex: 1, marginLeft: 0 }}>{this.state.selectedItemObjects[0].name}</Text>
    ) : (
      <Text style={{ color: "grey", flex: 1, marginLeft: 0 }}>Highest Academic Achievement *</Text>
    );
  };

  onBackPress = () => {
    let { currentSlide } = this.state;
    this.setState({ currentSlide: currentSlide - 1 });
  };

  handleReferralInput = (code) => {
    this.setState({ referralCode: code.trim() });
  };

  _handleReferralModalClose = async (type) => {
    if (type == "checkCode") {
      let validCode = await this.checkReferralCode();
      if (validCode.status == "Success") {
        this.setState({ referralModalVisible: false, currentSlide: 3 });
      } else {
        setTimeout(() => {
          this.setState({
            showErrorModal: true,
            errorMessage: validCode.status,
          });
        }, 500);
        this.setState({ referralModalVisible: false });
        setTimeout(() => {
          this.setState({ showErrorModal: false, errorMessage: "" });
        }, 2500);
      }
    } else {
      this.setState({
        referralModalVisible: false,
        currentSlide: 3,
        referralCode: "",
      });
    }
  };

  onvalidate_1 = async (values) => {
    let err = {};
    var emailvalidate =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let socialValues = this.state.userSocialData;
    if (!values.firstName && socialValues?.first_name) {
      values.firstName = socialValues.first_name;
    }
    if (!values.lastName && socialValues?.last_name) {
      values.lastName = socialValues.last_name;
    }
    if (!values.email && socialValues?.email_address) {
      values.email = socialValues.email_address;
    }
    if (values.firstName == undefined || values.firstName.trim() == "") {
      err["firstName"] = "First Name is required";
    }
    if (values.lastName == undefined || values.lastName.trim() == "") {
      err["lastName"] = "Last Name is required";
    }
    if (values.email == undefined || values.email.trim() == "") {
      err["email"] = "Email address is required";
    } else if (!emailvalidate.test(values.email)) {
      err["email"] = "Enter valid email";
    }
    if (!this.state.referralSource) {
      err["referral"] = "This is a mandatory field";
    }
    if (!this.state.validAge) {
      err["age"] = "You have to be at least 13 years old to sign up";
    }

    if (Object.entries(err).length > 0) {
      this.setState({ errmessage: err });
      return;
    } else {
      this.setState({ errmessage: {} });
    }

    let checkEmailExist = await this.checkEmailExist(values.email);
    if (checkEmailExist.status == "Success") {
      this.setState({ currentSlide: 2 });
    } else {
      err["email"] = checkEmailExist.status;
      this.setState({ errmessage: err });
    }
    logEvent(firebaseEvents.SIGN_UP, {
      message: "User completed sigup step1 form",
    });
  };

  onvalidate_2 = async (values) => {
    let err = {};
    if (!this.state.countryOfBirth) {
      err["countryOfBirth"] = "Country of Birth is Required";
    }
    if (!this.state.homeCountries) {
      err["homeCountries"] = "Home country is Required";
    }
    if (!this.state.highestAcademicAchievement) {
      err["highestAcademicAchievement"] = "Highest academic achievement is Required";
    }
    if (this.state.immigrationInterst.length == 0) {
      err["immigrationInterst"] = "Select at least one Immigration Interest";
    }
    if (this.state.countryIntrest.length == 0) {
      err["countryIntrest"] = "Select at least one Country of Interest";
    }
    if (Object.entries(err).length > 0) {
      this.setState({ errmessage: err });
      return;
    }
    values["placeOfBorth"] = this.state.countryOfBirth;
    values["highestAcademicAchievement"] =
      this.state.highestAcademicAchievement;
    this.setState({ referralModalVisible: true });
    logEvent(firebaseEvents.SIGN_UP, {
      message: "User completed sigup step2 form",
    });
  };

  readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  getSelectedImageInfo = () => {
    return new Promise((resolve, reject) => {
      if (Platform.OS == "web") {
        let input = document.createElement("input");
        input.type = "file";
        input.onchange = async () => {
          // you can use this method to get file and perform respective operations
          let files = Array.from(input.files);
          let fileInfo = files[0];
          let fileExtension = fileInfo.type.split("/")
          const imageDataUrl = await this.readFile(files[0]);
          resolve({ fileType: fileExtension[1], dataUrl: imageDataUrl })
        };
        input.click();
      } else {
        const options = {
          title: 'Select Avatar',
          customButtons: [],
          allowsEditing: false,
          quality: 0.1,
          storageOptions: {
            skipBackup: true,
            path: 'images',
            privateDirectory: true,
            base64: true
          },
        };

        launchImageLibrary(options, async (response) => {
          // console.log('Response = ', response);
          if (response.didCancel || response.error || response.customButton) {
            // console.log('User cancelled image picker');
            reject(response)
          } else {
            const source = { uri: response?.assets[0]?.uri };

            // You can also display the image using data:
            // const source = { uri: 'data:image/jpeg;base64,' + response.data };
            const fileExtension = response?.assets[0]?.type;
            resolve({ fileType: fileExtension[1], dataUrl: source.uri })
          }
        });
      }
    })
  }

  avatarPicker = async () => {
    const imageInfo = await this.getSelectedImageInfo();
    this.setState({
      avatarSource: imageInfo,
    });
    await AsyncStorage.setItem(`@newuser_avatar_uri`, imageInfo.dataUrl);
    await AsyncStorage.setItem(`@newuser_avatar_fileExt`, imageInfo.fileType);
    // if (Platform.OS == "web") {
    //   let input = document.createElement("input");
    //   input.type = "file";
    //   input.onchange = async () => {
    //     // you can use this method to get file and perform respective operations
    //     let files = Array.from(input.files);
    //     const imageDataUrl = await this.readFile(files[0]);
    //     // console.log(imageDataUrl);
    //     this.setState({
    //       avatarSource: imageDataUrl,
    //     });
    //     // console.log(imageDataUrl);
    //   };
    //   input.click();
    // } else {
    //   const options = {
    //     title: "Select Avatar",
    //     customButtons: [],
    //     allowsEditing: false,
    //     quality: 0.1,
    //     storageOptions: {
    //       skipBackup: true,
    //       path: "images",
    //       privateDirectory: true,
    //       base64: true,
    //     },
    //   };

    //   launchImageLibrary(options, async (response) => {
    //     // console.log("Response = ", response);
    //     if (response.didCancel) {
    //       console.log("User cancelled image picker");
    //     } else if (response.error) {
    //       console.log("ImagePicker Error: ", response.error);
    //     } else if (response.customButton) {
    //       console.log("User tapped custom button: ", response.customButton);
    //     } else {
    //       const source = { uri: response.assets[0].uri };
    //       // You can also display the image using data:
    //       // const source = { uri: 'data:image/jpeg;base64,' + response.data };
    //       const fileExtension = response.assets[0].type.split("/");
    //       var fileExt = `${fileExtension[1]}`;
    //       this.setState({
    //         avatarSource: source,
    //       });
    //       await AsyncStorage.setItem(`@newuser_avatar_uri`, response.uri);
    //       await AsyncStorage.setItem(`@newuser_avatar_fileExt`, fileExt);
    //     }
    //   });
    // }
  };

  onGoogleButtonPress = async () => {
    let googleCredentials = await onGoogleButtonPressHelper();
    const { email_address, metadata } = googleCredentials;
    let Obj = {
      first_name: metadata.first_name,
      last_name: metadata.last_name,
      email_address: email_address,
    };
    this.setState({ userSocialData: Obj });
  };

  onFacebookButtonPress = async () => {
    try {
      let data = await onFacebookButtonPressHelper();
      const { email_address, metadata } = data;
      let Obj = {
        first_name: metadata.first_name,
        last_name: metadata.last_name,
        email_address: email_address,
      };
      this.setState({ userSocialData: Obj });
      return true;
    } catch (e) {
      console.log(e)
    }
  };

  async chechConnection() {
    return NetInfo.fetch().then((state) => {
      if (!state.isConnected) {
        SweetAlert.error("Check your connection");
        return;
      } else {
        return state.isConnected;
      }
    });
  }

  onSubmit = async (values) => {
    let connectionStatus = await this.chechConnection();
    if (connectionStatus) {
      postData = {};
      if (Object.values(this.state.userSocialData)?.length > 0) {
        let socialValues = this.state.userSocialData;
        postData.first_name = socialValues.first_name;
        postData.last_name = socialValues.last_name;
        postData.email_address = socialValues.email_address;
      } else {
        postData.first_name =
          values.firstName == undefined ? "" : values.firstName;
        postData.last_name =
          values.lastName == undefined ? "" : values.lastName;
        postData.email_address = values.email;
      }
      postData.country_of_birth =
        values.placeOfBorth == undefined ? "" : values.placeOfBorth;
      postData.highest_academic_achievement =
        values.highestAcademicAchievement == undefined
          ? ""
          : values.highestAcademicAchievement;
      postData.immigration_interests = this.state.immigrationInterst;
      postData.countries_of_interest = this.state.countryIntrest;
      postData.get_notif1 = this.state.policyCheck;
      postData.get_notif2 = this.state.eventCheck;
      postData.referral_source = this.state.referralSource;
      postData.country_of_residency = this.state.homeCountries;
      if (this.state.referralSourceAlt) {
        postData.referral_source_alt = this.state.referralSourceAlt;
      }
      if (this.state.referralCode !== "") {
        postData.referral_code = this.state.referralCode;
      }
      logEvent(firebaseEvents.SIGN_UP, {
        message: "User completed sigup form and pressed on submit.",
      });
      this.setState({ userPostData: postData, currentSlide: 4 })
    }
  };

  onSelectedItemObjectsChange = (selectedItemObjects) => {
    this.setState({ selectedItemObjects });
    this.setState({ highestAcademicAchievement: selectedItemObjects[0].name });
    this.props.highestAcademicAchievement(selectedItemObjects[0].name);
  };

  handleFirstName = (e) => {
    let userSocialData = Object.keys(this.state.userSocialData).length === 0;
    if (userSocialData) {
      this.setState({ first_name: e });
    } else {
      this.setState({ firstName: e });
    }
  };

  handleLastName = (e) => {
    let userSocialData = Object.keys(this.state.userSocialData).length === 0;
    if (userSocialData) {
      this.setState({ last_name: e });
    } else {
      this.setState({ lastName: e });
    }
  };

  handleEmailChange = (e) => {
    let userSocialData = Object.keys(this.state.userSocialData).length === 0;
    if (userSocialData) {
      this.setState({ email_address: e });
    } else {
      this.setState({ email: e });
    }
  };

  handleLogin = () => {
    this.props.onLoginPress();
  };

  getImage = (item) => {
    if (this.state.countryIntrest.includes(item.code)) {
      return Platform.OS == "web" ? item.webImg : item.webImg;
    } else {
      return Platform.OS == "web" ? item.webFlag : item.webFlag;
    }
  };

  renderTextInput = (field) => {
    const {
      meta: { touched, error },
      label,
      val,
      secureTextEntry,
      maxLength,
      keyboardType,
      placeholder,
      ignoreCheck,
      placeholderTextColor,
      defaultValue,
      input: { onChange, name, value, ...restInput },
    } = field;

    return (
      <>
        <TextInput
          onChangeText={(e) => {
            if (ignoreCheck) {
              onChange(e.replace(/\s/g, ''))
            } else {
              this.handleOnChange(e, onChange, name)
            }
          }}
          maxLength={maxLength}
          placeholder={placeholder}
          style={styles.textInput}
          placeholderTextColor="gray"
          keyboardType={keyboardType}
          defaultValue={defaultValue}
          secureTextEntry={secureTextEntry}
          label={label}
          name
        />
        {/* {touched && error && <Text style={styles.errorText}>{error}</Text>} */}
      </>
    );
  };

  createNewUser = async values => {
    Platform.OS !== 'web' && await this.chechConnection();
    try {
      let postData = this.state.userPostData;
      postData.password_hash = values.shapassword;
      if (!postData.referral_code) {
        delete postData.referral_code
      }
      const response = await this.props.dispatch(
        createNewUser(postData, postData.email_address)
      );
      if (response.status.toString().includes('E-mail Sent to')) {
        valdidatedata = {};
        valdidatedata.email_address = postData.email_address;
        valdidatedata.otp_type = "new_user_verification";
        valdidatedata.status = response.status;
        SweetAlert.success(response.status)
        this.setState({ currentSlide: 5, valdidatedata });
      } else {
        this.setState({ showErrorModal: true, errormessage: response.status })
        Timer.setTimeout(() => {
          this.setState({ errorModal: false })
        }, 1500);
      }

    } catch (error) {
      // const newError = new ErrorUtils(error, "Signup Error");
      // newError.showAlert();
    }
  };

  renderCurrentSlide() {
    let userSocialData = Object.keys(this.state.userSocialData).length === 0;
    const { handleSubmit } = this.props;
    switch (this.state.currentSlide) {
      case 1: {
        return (
          <SignUpSection1
            styles={styles}
            state={this.state}
            handleSubmit={handleSubmit(this.onvalidate_1)}
            userSocialData={userSocialData}
            onReferralSourceChange={(_, item) => {
              this.state.errmessage["referral"] = null;
              this.setState({ referralSource: item.name });
            }}
            onReferralSourceAltTextChange={(text) => {
              this.setState({ referralSourceAlt: text });
            }}
            handleRadioClick={() => {
              this.state.errmessage["age"] = null;
              this.setState({
                validAge: !this.state.validAge,
              });
            }}
            renderTextInput={this.renderTextInput}
            onGoogleButtonPress={this.onGoogleButtonPress}
            onFacebookButtonPress={this.onFacebookButtonPress}
          />
        )
      }
      case 2: {
        return (
          <SignUpSection2
            styles={styles}
            state={this.state}
            handleSubmit={handleSubmit(this.onvalidate_2)}
            handleRNSelection={(index, item) =>
              this._selectedValue1(index, item)
            }
            handleHomeCountrySelection={(index, item) =>
              this._selectedValue2(index, item)
            }
            customChipsRenderer={this.customChipsRenderer}
            onSelectedItemObjectsChange={this.onSelectedItemObjectsChange}
            _selectedValue4={this._selectedValue4}
            _renderSelectText={this._renderSelectText}
            countrySelection={this.countrySelection}
            getImage={this.getImage}
            goalSelected={this.goalSelected}
          />
        )
      }
      case 3: {
        return (
          <SignUpSection3
            styles={styles}
            state={this.state}
            handleSubmit={handleSubmit(this.onSubmit)}
            avatarPicker={this.avatarPicker}
          />
        )
      }
      case 4: {
        return (
          <CreatePassword
            onSubmit={this.createNewUser}
          />
        )
      }
      case 5: {
        return (
          <PinValidation
            postData={this.state.userPostData}
            valdidatedata={this.state.valdidatedata}
          />
        )
      }
      default: return null;
    }
  }

  render() {
    return (
      <KeyboardAvoidingView
        style={styles.keyboardAvoidingStyle}
        keyboardVerticalOffset={Platform.OS === "ios" ? -500 : 0}
        behavior={Platform.OS === "ios" ? "padding" : null}
      >
        <ScrollView
          bounces={false}
          ref={(scroll) => {
            this.scroll = scroll;
          }}
          showsVerticalScrollIndicator={Platform.OS === "web" ? true : false}
          style={styles.mainView}
        >
          <TouchableOpacity
            activeOpacity={1}
            disabled={Platform.OS === "web" ? true : false}
            style={styles.mainContainer}
          >
            <SafeAreaView />
            {Platform.OS === "ios" ? (
              <View style={[styles.statusBar]}>
                <StatusBar
                  translucent
                  barStyle="light-content"
                  hidden={false}
                />
              </View>
            ) : (
              <StatusBar
                barStyle="light-content"
                backgroundColor={colors.LIGHT_BLUE}
              />
            )}
            <View animation="slideInUp" style={styles.signUpFiledMain}>
              {this.state.currentSlide !== 1 ? (
                <TouchableOpacity
                  onPress={this.onBackPress}
                  style={styles.closeIconButton}
                  hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
                >
                  <Image
                    source={Images.blackbackIcon}
                    style={styles.closeIcon}
                  />
                </TouchableOpacity>
              ) : (
                <View />
              )}
              <Image
                source={Images.loginImmiIcon}
                style={styles.loginImmiIcon}
              />
              <View style={styles.loginSignupTab}>
                <TouchableOpacity onPress={this.handleLogin} disabled={this.state.currentSlide == 1 ? false : true}>
                  <Text style={styles.loginTitle}>Login</Text>
                </TouchableOpacity>
                <TouchableOpacity>
                  <Text style={styles.loginTitle}>Sign up</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.bottomBar}>
                <View style={styles.loginSelected} />
              </View>
              {this.renderCurrentSlide()}
            </View>
            <EnterReferralCodeModal
              visible={this.state.referralModalVisible}
              _handleReferralModalClose={this._handleReferralModalClose}
              handleReferralInput={this.handleReferralInput.bind(this)}
            />
          </TouchableOpacity>
          {this.state.showErrorModal ? (
            <AlertModal title="Oops!" description={this.state.errorMessage} />
          ) : (
            <View />
          )}
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  countrySelect: (val) => change("register", "country", val),
  countryOfNationality: (val) =>
    change("register", "country_of_nationality", val),
  countryOfNationality2: (val) => change("register", "nationalitytwo", val),
  changeButtonFields: (val, key) => change("register", key, val),
  highestAcademicAchievement: (val) =>
    change("register", "highest_academic_achievement", val),
  gender: (val) => change("register", "gender", val),
  marital_status: (val) => change("register", "marital_status", val),
  occupation: (val) => change("register", "occupation", val),
  preferredLanguage: (val) => change("register", "pref_language", val),
  dispatch
});

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: "register",
    destroyOnUnmount: false,
  })
)(NewSignupUi);
