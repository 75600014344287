import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  ImageBackground,
  Dimensions,
  Platform,
  ScrollView, KeyboardAvoidingView, BackHandler
} from 'react-native';
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router';

import InputText from "../components/InputText";
import { ckeckSignUPOTP, getUserDetails, /*forgetpassUser,*/ getUserProfilePic } from "../actions/auth.actions";
// import Form from '../components/Form';
import { logEvent } from "../actions/firebaselog.actions";
import { setGuestlogin } from "./../actions/path.actions";
import storage from "@react-native-firebase/storage";
import { createNewUser } from "../actions/auth.actions";
import Icon from 'react-native-vector-icons/FontAwesome';
const { width, height } = Dimensions.get("window");
import { Images, colors } from "../common";
import OTPTextView from '../components/OTPTextInput';
import Toast from 'react-native-simple-toast';
import NetInfo from "@react-native-community/netinfo";
import { ErrorUtils } from "../utils/auth.utils";
import Spinner from 'react-native-loading-spinner-overlay';
import AsyncStorage from '@react-native-community/async-storage';
import pinvalidate from "../images/pinvalidate.png";
import SweetAlert from '../components/SweetAlert';
import AlertModal from '../components/AlertModal/AlertModal';
const keyboardVerticalOffset = Platform.OS === 'ios' ? 0 : 0

const styles = StyleSheet.create({
  signupTextCont: {
    // flexGrow: 1,
    // alignItems: 'flex-end',
    justifyContent: 'center',
    paddingVertical: 16,
    flexDirection: 'row',
    marginTop: 15
  },
  button: {
    width: Platform.OS == 'web' ? '30%' : width / 1.1,
    backgroundColor: colors.LIGHT_BLUE,
    borderRadius: 10,
    marginVertical: 10,
    paddingVertical: 13,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15
  },
  buttonText: {
    fontSize: 16,
    fontFamily: "SourceSansPro-Semibold",
    color: '#ffffff',
    textAlign: 'center'
  },
  textInputContainer: {
    marginBottom: 20,
    borderColor: "#000",
  },

  container: { alignItems: 'center', backgroundColor: "#fff", minHeight: height - 40, marginTop: -5 },
  welcomeView: { justifyContent: 'center', alignItems: 'center', marginTop: 50 },
  welcomeText: { fontSize: 20, fontFamily: "SourceSansPro-Semibold", color: "#2C393F" },
  subview: { justifyContent: 'center', alignItems: 'center', marginTop: 5, marginLeft: 40, marginRight: 40, alignItems: "center" },
  subtext: { fontSize: 15, color: "#2C393F", fontFamily: "SourceSansPro-Regular", textAlign: "center" },
  emailtextInput: { width: width / 1.2 },
  loginView: { width: width / 1.1, height: 50, alignItems: "center", flexDirection: "row", marginTop: 50 },
  mailImage: { height: 20, width: 30, marginLeft: 15, marginRight: 15 },
  signUpText: {
    marginLeft: 5, marginRight: 5, color: '#008BC7',
    fontSize: 12,
    fontFamily: "SourceSansPro-Semibold"
  },
  textInputContainer: {
    marginBottom: 20,
    marginLeft: 0
  },
  roundedTextInput: {
    borderRadius: 10,
    borderWidth: 0,
    backgroundColor: "#EEF2F5",
    width: 40,
    fontSize: 15,
    marginLeft: 10,
    height: 45,
    color: "#2C393F",
    borderColor: "#EEF2F5"
  },
  statusBar: {
    height: Platform.OS === 'ios' ? 40 : StatusBar.currentHeight,
    backgroundColor: colors.LIGHT_BLUE
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
});


class Pinvalidation extends Component {


  constructor(props) {
    super(props)
    this.state = {
      alertMessage: "",
      text2: "",
      referral_code: '',
      showErrorModal: false,
      checkconnection: false,
      isLoading: false
    };
    this.otpInput = null;
  }
  componentWillMount() {
    let props = Platform.OS == 'web' ? this.props.history?.location?.state?.valdidatedata : this.props.navigation.state.params
    this.setState({ alertMessage: props.status });
    this.chechConnection();

  }
  async componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
    let rederralCode = await AsyncStorage.getItem('ReferralCode');
    this.setState({ referral_code: rederralCode })
    logEvent('otp_entry', {});
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
  }
  handleBackButton() {
    //  Actions.pop();
    // this.props.navigation.pop();
    return true;
    // this.backpresshandle();
  }
  async chechConnection() {
    NetInfo.fetch().then(state => {
      this.setState({ checkconnection: state.isConnected });
      if (!this.state.checkconnection) {
        Toast.show('Check your connection', Toast.LONG)

      }
    });
  }
  resendotp = async () => {
    await this.chechConnection();
    try {
      postData = {};
      let { valdidatedata, _postData } = Platform.OS == 'web' ? this.props.history?.location?.state : this.props.navigation.state.params
      postData.email_address = valdidatedata.email_address;
      //  this.props.dispatch(createNewUser(postData))
      if (!this.state.checkconnection) {
        SweetAlert.error('Check your connection')
      } else {
        this.props.dispatch(createNewUser(_postData))
        // const response = await this.props.dispatch(forgetpassUser(postData));
      }

    } catch (error) {
      const newError = new ErrorUtils(error, "Signup Error");
      newError.showAlert();
    }
  }

  _renderLoading = (visible) => {
    return (
      <View style={styles.loader}>
        <Spinner
          visible={visible}
        />
      </View>
    );
  }

  clearOTPText = () => {
    this.otpInput.inputs.forEach(input => input.clear());
    this.otpInput.inputs[0].focus()
  }

  otpvalidation = async (values) => {
    await this.chechConnection();
    var strinvalid = "Incorrect OTP!";
    var strforget = "OTP authenticated!";
    var strnewuser = "OTP Verified and New User Created!";
    if (this.state.text2 == "") {
      Toast.show('please Enter OTP', Toast.LONG);
      // this.setState({ alertMessage: "please Enter OTP" })
    } else {
      try {
        let { valdidatedata } = Platform.OS == 'web' ? this.props.history?.location?.state : this.props.navigation.state.params
        postData = {};
        postData.email_address = valdidatedata.email_address;
        postData.otp = parseInt(this.state.text2);
        postData.otp_type = valdidatedata.otp_type;
        if (this.state.referral_code !== null && this.state.referral_code !== 'undefined' && this.state.referral_code !== undefined) {
          postData.referral_code = this.state.referral_code;
        }
        //  this.props.dispatch(createNewUser(postData))
        if (!this.state.checkconnection && !Platform.OS !== 'web') {
          Toast.show('Check your connection', Toast.LONG)
        } else {
          this.setState({ isLoading: true })
          const response = await this.props.dispatch(ckeckSignUPOTP(postData));
          if (response.status === strnewuser) {
            const responsedata = await this.props.dispatch(getUserDetails(response.user_id, response.auth_token));
            // Actions.destination();
            logEvent('otp_success', {});
            const sourceUri = await AsyncStorage.getItem(`@newuser_avatar_uri`)
            const fileExt = await AsyncStorage.getItem(`@newuser_avatar_fileExt`)
            if (sourceUri && fileExt) {
              var storageRef = storage().ref(`/avatarImages/${response.user_id}/${response.user_id}.${fileExt}`);
              storageRef.putFile(sourceUri).on(storage.TaskEvent.STATE_CHANGED, snapshot => {
                if (snapshot.state === storage.TaskState.SUCCESS) {
                  // console.log("Success");
                  storage()
                    .ref(`${storageRef.path}`).getDownloadURL().then((downloadURL) =>
                      this.props.dispatch(getUserProfilePic({ uri: downloadURL, path:storageRef.path }))
                    )
                }
              })
              await AsyncStorage.removeItem("@newuser_avatar_fileExt")
              await AsyncStorage.removeItem("@newuser_avatar_uri")
            }
            this.setState({ isLoading: false })
            this.props.dispatch(setGuestlogin(false))
            Platform.OS == 'web' ? this.props.history.push('/dashboard') : this.props.navigation.navigate("HomeStack")
          } else if (response.status === strforget) {
            resetdata = {};
            resetdata.email_address = valdidatedata.email_address;

            resetdata.auth_token = response.auth_token;

            resetdata.user_id = response.user_id;

            resetdata.status = 2;
            resetdata.string = "Reset Password";
            // Actions.resetPassword(resetdata);
            this.setState({ isLoading: false })
            this.props.navigation.navigate("ResetPassword", resetdata)
          } else {
            this.setState({ isLoading: false })
            setTimeout(() => {
              this.setState({ showErrorModal: true, errorMessage: response.status })
            }, 300);
            setTimeout(() => {
              this.setState({ showErrorModal: false, errorMessage: '' })
            }, 2000);
            this.clearOTPText()
            Toast.show(response.status, Toast.LONG);
          }
        }
      } catch (error) {
        this.setState({ isLoading: false })
        const newError = new ErrorUtils(error, "Signup Error");
        newError.showAlert();
      }
    }
  }
  onSubmit = (values) => {
    // Actions.resetPassword();
    this.otpvalidation(values);
  }

  renderTextInput = (field) => {
    const { meta: { touched, error }, label, secureTextEntry, maxLength, keyboardType, placeholder, input: { onChange, ...restInput } } = field;
    return (
      <View style={{ width: 40, backgroundColor: "#EEF2F5", borderRadius: 10, marginLeft: 15, textAlign: "center" }}>
        <InputText
          style={{ textAlign: "center" }}
          onChangeText={onChange}
          maxLength={maxLength}
          //   placeholder={placeholder}
          keyboardType={keyboardType}
          secureTextEntry={secureTextEntry}
          label={label}
          {...restInput} />
        {(touched && error) && <Text style={styles.errorText}>{error}</Text>}
      </View>
    );
  }
  _onPressButton = () => {
    // Actions.pop();
    this.props.navigation.pop();
  }

  render() {

    const { handleSubmit, loginUser } = this.props;
    var image_height;

    if (height < width) {
      image_height = 360;
    } else {
      image_height = height / 2.5;
    }

    return (
      <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : null} keyboardVerticalOffset={keyboardVerticalOffset}>
        {Platform.OS === 'ios' ? <View style={[styles.statusBar]} /> :
          <StatusBar barStyle="light-content" backgroundColor={colors.LIGHT_BLUE} />
        }
        <ScrollView keyboardShouldPersistTaps={'handled'} bounces={false} showsVerticalScrollIndicator={false}>
          <View style={styles.container}>
            {this.state.isLoading ? this._renderLoading(this.state.isLoading) : null}
            <ImageBackground style={{ height: image_height, width: width }} source={Platform.OS == 'web' ? pinvalidate : Images.pinValidate}>
              <TouchableOpacity
                onPress={this._onPressButton}
                style={{ marginLeft: 40, marginTop: 30, width: 40, height: 40 }}
              >
                <View style={{ width: 40 }}>
                  {
                    Platform.OS !== 'web' ?
                      <Icon name="chevron-left" size={13} color="#fff" /> : <View />
                  }
                </View>
              </TouchableOpacity>
            </ImageBackground>
            <View style={styles.welcomeView}><Text style={styles.welcomeText}>Verify Your Email</Text></View>
            <View style={styles.subview}><Text style={styles.subtext}>Please Enter the verification code send to your given E-mail address</Text></View>
            {/* <View style={[styles.subview, { marginTop: 15 }]}><Text style={[styles.subtext, { color: "green" }]}>{this.state.alertMessage}</Text>
          </View> */}
            <View style={styles.loginView}>
              <View style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <OTPTextView
                  containerStyle={styles.textInputContainer}
                  handleTextChange={text => this.setState({ text2: text })}
                  textInputStyle={styles.roundedTextInput}
                  inputCount={6}
                  keyboardType="numeric"
                  tintColor="#fff"
                  offTintColor="#fff"
                  ref={e => (this.otpInput = e)}
                /></View>
            </View>
            <TouchableOpacity style={styles.button} onPress={handleSubmit(this.onSubmit)}>
              <Text style={styles.buttonText}>Continue</Text>
            </TouchableOpacity>
            <View style={styles.signupTextCont}>
              <Text style={{ color: "#B7BBBD", fontSize: 13, fontFamily: "SourceSansPro-Regular" }}>Didn't Receive the OTP?</Text>
              <TouchableOpacity onPress={this.resendotp}><Text style={styles.signUpText}> Resend OTP</Text></TouchableOpacity>

            </View>
          </View>
          {
            this.state.showErrorModal ?
              <AlertModal
                title="Oops!"
                description={this.state.errorMessage}
              />
              :
              <View />
          }
        </ScrollView></KeyboardAvoidingView>
    )
  }
}

// const validate = (values) => {
//     const errors = {};
//     if(!values.email) {
//         errors.email = "Email is required"
//     }
//     return errors;
// };

const mapStateToProps = (state) => ({
  createUser: state.authReducer.createUser,
  loginUser: state.authReducer.loginUser
})

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "pinvalidation",
    // validate
  })
)(Platform.OS == 'web' ? withRouter(Pinvalidation) : Pinvalidation);