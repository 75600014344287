import React, { useState } from "react";
import { View, Text, Modal, Image, TouchableOpacity, Linking } from "react-native";
import Images from "../../../../../../common/images";
import { styles as s } from "./applicationCheckModal.styles";
import checkList from "./data.json";
import InAppBrowser from 'react-native-inappbrowser-reborn'
import { helpers } from "../../../../../../common";
import { isWeb } from "../../../../../../common/helpers";

export const ApplicationChecklistModal = ({
  setApplicationChecklistModal,
  checklists,
  applyLink,
  setCheckLists,
}) => {
  const [selectedCheckList, setselectedCheckList] = useState([])
  const handleChecklistedItems = (val) => {
    const newList = [...selectedCheckList];
    if (newList.includes(val)) {
      newList.splice(
        newList.findIndex((e) => e === val),
        1
      );
    } else {
      newList.push(val);
    }
    setselectedCheckList(newList);
  };

  const handleLinking = async (link) => {
    if (!isWeb() && await InAppBrowser.isAvailable()) {
      try {
        const result = await InAppBrowser.open(link, helpers.inAPPbrowserOption)
      } catch (error) {
        await InAppBrowser.close()
      }
    } else {
      Linking.openURL(link);
    }
  }

  return (
    <Modal visible={true} animationType="fade" transparent>
      <View style={s.wrapper}>
        <View style={s.modal}>
          <View style={[s.closeWrapper]}>
            <TouchableOpacity
              onPress={() => setApplicationChecklistModal(false)}
              style={s.closeIconButton}
              hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
            >
              <Image source={Images.closeBlack} style={s.closeIcon} />
            </TouchableOpacity>
          </View>

          <Text style={s.title}>Application Checklist</Text>
          {checkList.length > 0 ? checkList.map((list, index) => {
            return (
              <View style={[s.listWrapper]}>
                <TouchableOpacity
                  hitSlop={{
                    bottom: 15,
                    left: 15,
                    right: 15,
                    top: 15,
                  }}
                  onPress={() => handleChecklistedItems(list.value)}
                  key={list.value}
                >
                  <Image
                    style={{
                      height: 18,
                      width: 18,
                    }}
                    resizeMode="contain"
                    source={
                      selectedCheckList.includes(list.value)
                        ? Images.immipathSearchChecked
                        : Images.immipathSearchUnchecked
                    }
                  />
                </TouchableOpacity>
                <Text style={[s.listText]}>{list.label}</Text>
              </View>
            );
          }) :
            <Text style={{ textAlign: 'center', padding: 10, }}>
              Sorry, we are unsure of the checklist items at this moment! You may try to visit the application portal anyways.
            </Text>
          }
          <TouchableOpacity
            disabled={checkList.length > 0 && selectedCheckList.length !== checkList.length}
            onPress={() => handleLinking(applyLink)}
            style={[
              s.applyButton,
              {
                backgroundColor:
                  selectedCheckList.length == checkList.length
                    ? "#2B8A0A"
                    : "#BABABA",
              },
            ]}
            activeOpacity={0.7}
          >
            <Text style={s.premiumButtonText}>APPLY NOW </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};
