import React, { useState } from "react";
import { View } from "react-native";
import { Network } from "../../service/api";
import { useSelector } from "react-redux";
import PrScoreModal from "../PrScoreModal/PrScoreModal";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { isWeb, mobilBrower } from "../../common/helpers";
import meta from "../../common/metaData";
import { withRouter } from "react-router-dom";
import WebFooter from "../../common/WebFooter";
import PrQA from "./components/PrQA";
import { styles } from "./prCalculater.styles";

function PrCalculator(props) {
  const [cutOffData, setcutOffData] = useState({});
  const params = isWeb() ? props.history.location.state : props?.navigation?.state?.params;
  const creditBuilderData = params.PrCalculatorForm;
  const selectedCountries = params.countries;
  const [PRPoints, setPRPoints] = useState({});
  const [showPrScoreModal, setshowPrScoreModal] = useState(false);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const Token = useSelector((state) => state.authReducer.authData.token);

  const onScoreCalculate = (body) => {
    const url = `/data/calculate_pr_points`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };
    Network(url, "POST", body, headers).then((res) => {
      if (res.status && res.status === "Success") {
        setshowPrScoreModal(true);
        setPRPoints(res.points);
        setcutOffData(res.cutoff_data);
      }
    });
  };

  return (
    <>
      {
        isWeb() &&
        <head>
          <meta content={meta.prcalculator} />
        </head>
      }
      <View style={{ flex: 1 }}>
        <HeaderComponent
          showNotificationIcon={false}
          showback={true}
          screenTitle="PR Points Calculator"
        />
        <View style={[styles.formView, { flex: 1 }]}>
          <PrQA selectedCountries={selectedCountries} userDetails={userDetails} creditBuilderData={creditBuilderData} onScoreCalculate={onScoreCalculate} />
        </View>
        {isWeb() && !mobilBrower() ? <WebFooter /> : null}
      </View>
      {showPrScoreModal ? (
        <PrScoreModal
          PRPoints={PRPoints}
          onclose={() => setshowPrScoreModal(false)}
          cutOffData={cutOffData}
          visible={showPrScoreModal}
        />
      ) : (
        <View />
      )}
    </>
  );
}

export default isWeb() ? withRouter(PrCalculator) : PrCalculator;
