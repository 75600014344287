import React, { useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  Dimensions,
  Linking,
} from "react-native";
import { heightPercentageToDP } from "react-native-responsive-screen";
import { isWeb, mobilBrower } from "../../../../common/helpers";
import { Images, helpers } from "../../../../common";
import { newsItemStyles as styles } from "./newsHighlightCard.styles";
import InAppBrowser from 'react-native-inappbrowser-reborn'

const { width } = Dimensions.get("window");

export default function NewsHighlightCard({ item }) {
  const [showDefaultImage, setshowDefaultImage] = useState({})

  const handleError = () => {
    let temp = {...showDefaultImage}
    temp[item.news_id] = true
    setshowDefaultImage(temp)
  }

  const handleLinking = async() => {
    if (await InAppBrowser.isAvailable()) {
      try {
        const result = await InAppBrowser.open(item.link, helpers.inAPPbrowserOption)
      } catch (error) {
        await InAppBrowser.close()
      }
    } else {
      Linking.openURL(item.link);
    }
  }

  return (
    <TouchableOpacity
      activeOpacity={1}
      style={[
        styles.newItem,
      ]}
    >
      <View style={styles.newImage}>
        <Image
          source={ showDefaultImage[item.news_id] ? Images.defaultThum : { uri: item.image_link }}
          resizeMode="cover"
          onError={handleError}
          defaultSource={Images.defaultThum}
          style={styles.newImgStyle}
        />
      </View>
      <View style={styles.newContent}>
        <Text numberOfLines={3} style={styles.title}>
          {item.title}
        </Text>
        <Text numberOfLines={5} style={styles.details}>
          {item.summary}
        </Text>
        <View
          style={[
            styles.bottomView,
            { marginVeticle: 5, justifyContent: "center", marginBottom: 15 },
          ]}
        >
          <TouchableOpacity
            onPress={() => item.link && handleLinking()}
            style={styles.readMoreButton}
          >
            <Text style={styles.readMoreText}>Read More</Text>
          </TouchableOpacity>
        </View>
        <View
          style={[
            styles.bottomView,
            { justifyContent: "space-between", marginTop: isWeb() ? 10 : 0 },
          ]}
        >
          <Text style={styles.sourceText}>{item.source}</Text>
          <Text style={styles.publishedDate}>
            {item.date_published.slice(5, 16)}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}
