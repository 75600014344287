import moment from 'moment';
import React, { useState, useRef, useEffect } from 'react'
import { View, Platform, ScrollView, Text, Image, Dimensions, TouchableOpacity } from "react-native";
import { FlatList } from 'react-navigation';
import { family, Images } from '../../../common';
import { styles as s } from "./styles";
import { capitalize } from "lodash";
import Carousel from 'react-native-snap-carousel';
import NavigationService from '../../../navigation/NavigationService';
import ProductInfoModal from '../../../components/ProductInfoModal/ProductInfoModal';
import { setStorebaseCurrency } from '../../../actions/referral.actions';
import { useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal/AlertModal';
import helpers from '../../../common/helpers';
import AsyncStorage from '@react-native-community/async-storage';
const { width, height } = Dimensions.get("window");

export default function YourSolutionCard({ discusisons, products }) {
  const [slideIndex, setslideIndex] = useState(0)
  const sliderRef = useRef()
  const [baseCurrency, setbaseCurrency] = useState("INR");
  const [selectedItem, setselectedItem] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [showErrorModal, setshowErrorModal] = useState(false)
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const token = useSelector((state) => state.authReducer.authData.token);
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);

  const calculateDateDiff = (item) => {
    let a = moment(item?.date_asked)
    let b = moment(new Date())
    let dateDiff = moment().diff(a, "days")
    return dateDiff > 0 ? dateDiff + ' days ago' : 'today'
  }

  useEffect(() => {
    getbaseCurrency = async () => {
      const headers = {
        Accept: "application/json",
        Authorization: guestLogin
          ? "guest@store"
          : userDetails?.userid + "@" + token,
      };
      let res = await Network("/data/get_country", "GET", null, headers);
      if (res.status == "Success") {
        let currency = config.countries_json.filter(
          (s) => s.id == res.country_code
        );
        dispatch(setStorebaseCurrency(currency[0].currency));
        setbaseCurrency(currency[0].currency);
      }
    };
    getbaseCurrency();
  }, []);

  useEffect(() => {
    const getlastStage = async () => {
      const lastStage = await AsyncStorage.getItem('lastStage');
      if (lastStage && !guestLogin) {
        NavigationService.navigate("Discussion", {
          itemInView: JSON.parse(lastStage),
        })
        AsyncStorage.removeItem('lastStage')
      }
    }
    getlastStage()
  }, [])

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getCategoryName = (name) => {
    if (name?.split('_').length > 0) {
      return name?.split('_').map((s) => capitalizeFirstLetter(s)).join(' ')
    }
  }

  const handlePress = (item) => {
    if (guestLogin) {
      AsyncStorage.setItem('lastStage', JSON.stringify(item))
    }
    NavigationService.navigate("Discussion", {
      itemInView: item,
    })
  }

  const _renderItem = (item, index) => {
    return (
      <>
        <TouchableOpacity
          onPress={() => handlePress(item)}
          activeOpacity={1}
          style={s.solutionItem}>
          <View style={s.sliderItem}>
            <Text style={{ fontSize: 16, color: '#AC0909', fontFamily: family.robotoBold, padding: 5 }}>
              {getCategoryName(item.category)}
            </Text>
            <Text style={{ fontSize: 13, fontFamily: family.robotoRegular, marginRight: 35 }}>
              {calculateDateDiff(item)}
            </Text>
          </View>
          <Text style={{ fontSize: 18, fontFamily: family.robotoBold, width: '50%', marginTop: 5 }}>
            {item.topic}
          </Text>
        </TouchableOpacity>
        <View style={{ width: width, borderTopWidth: 1, borderColor: '#C4C4C4', }} />
        <TouchableOpacity activeOpacity={1} style={s.postedByView}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontFamily: family.roboto, }}>
              Posted by
            </Text>
            <Text style={{ color: '#AC0909', fontFamily: family.robotoBold, marginLeft: 5 }}>
              {item.discussion_author}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image source={Images.commenticon} style={{ height: 20, width: 20 }} />
            <Text style={{ fontFamily: family.roboto, marginLeft: 5 }}>
              {item.replies_count}
            </Text>
          </View>
        </TouchableOpacity>
      </>
    );
  }

  const handleProceed = (item) => {
    if (guestLogin) {
      setshowErrorModal(true)
      return
    }
    let temp = {}
    temp[1] = item
    setselectedItem(temp)
    setopenModal(true)
  }

  const _renderItem2 = (item, index) => {
    return (
      <>
        <TouchableOpacity onPress={() => handleProceed(item)} activeOpacity={1}
          style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
          <Image
            style={s.flag}
            source={{ uri: item.thumbnail }} />
          <View style={{ flex: 0.6, marginLeft: 15, overflow: 'visible', }}>
            <Text style={s.visaName}>
              {item?.title}
            </Text>
            <Text>
              {helpers.getCurrencySymbol(baseCurrency) +
                helpers.storeCurrencyConverer(baseCurrency, item.price)}
            </Text>
            <TouchableOpacity onPress={() => handleProceed(item)} style={s.AddtocartButton}>
              <Text style={s.AddtocartButtonText}>
                Add to Cart
              </Text>
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </>
    );
  }

  const handlearrowClick = (type) => {
    if (type == 'pre') {
      sliderRef.current.snapToPrev(animated = true)
    } else {
      sliderRef.current.snapToNext(animated = true)
    }

  }

  return (
    <View style={[s.discovercrd, { marginBottom: 170 }]}>
      <Text style={{ fontSize: 25, fontFamily: family.bebas }}>
        YOUR
      </Text>
      <Text style={{ fontSize: 32, fontFamily: family.bebas }}>
        SOLUTIONS
      </Text>
      <View style={[s.bottomRow, {
        borderTopWidth: 1,
        paddingVertical: 5,
        borderColor: '#C4C4C4',
        // borderBottomWidth: 1,
      }]}>
        <TouchableOpacity onPress={() => handlearrowClick('pre')} style={s.arrowStyle}>
          <Image source={Images.sliderLeft} style={{ height: 28, width: 28 }} />
        </TouchableOpacity>
        <Carousel
          ref={sliderRef}
          data={discusisons}
          renderItem={({ item, index }) => {
            if (item.type == 'discussions') {
              return _renderItem(item, index)
            } else {
              return _renderItem2(item, index)
            }
          }}
          sliderWidth={width}
          loop
          itemWidth={width}
        />
        <TouchableOpacity style={[s.arrowStyle, { position: 'relative', top: -15 }]} onPress={() => handlearrowClick('forword')}>
          <Image source={Images.sliderRight} style={{ height: 28, width: 28 }} />
        </TouchableOpacity>
      </View>
      {openModal ? (
        <ProductInfoModal
          selectedItem={selectedItem}
          openModal={openModal}
          baseCurrency={baseCurrency}
          onclose={() => setopenModal(false)}
        />
      ) : (
        <View />
      )}
      {showErrorModal ? (
        <AlertModal
          title="Login"
          description="Login to explore more!"
          showCancel
          showConfirm
          confirmText="Login"
          cancelText="Not now"
          loginModal
          onCancelPress={() => setshowErrorModal(false)}
          onConfirmPress={() => setshowErrorModal(false)}
        />
      ) : (
        <View />
      )}
    </View>
  )
}
