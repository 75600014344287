import { StyleSheet } from "react-native";
import { family } from "../../../../common";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  searchemailtextInput: {
    elevation: 12,
    height: 45,
    padding: 15,
    backgroundColor: "#fff",
    marginBottom: 15,
    borderRadius: 5,
    shadowOpacity: 0.5,
    shadowRadius: 3,
    flex:mobilBrower()? 1:0.6,
    marginRight:mobilBrower()? 5:"5%",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    flexDirection: "row",
    shadowOffset: { width: 1, height: 1 },
  },
  textInput: {
    height: 45,
    flex:1,
    color: "#000",
    fontSize: responsiveText(14),
  },
  searchIcon: {
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 10,
  },
  filterIcon: {
    width: 50,
    height: 50,
  },
  container: {
    alignSelf:"center",
    width: mobilBrower()?"100%":"60%",
    display: "flex",
    alignItems:"center",
    flexDirection: "column",
  },
  filterBtnContainer:{
    alignItems:"center",
    justifyContent:"center",
  },
  firstContainer: {
    display: "flex",
    width:"95%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  classificationContainer: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    width: "90%",
    position: "relative",
    zIndex: 10,
  },
  classificationTags: {
    borderRadius: 8,
    flex: 1,
    paddingHorizontal: 15,
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 0,
  },
  classificationText: {
    color: "white",
    fontFamily: family.semibold,
  },
});
