import { StyleSheet } from "react-native";
import { family } from "../../common";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";
import { heightPercentageToDP, widthPercentageToDP } from "../../common/responsiveScreen";

export const styles = StyleSheet.create({
    mainView: {
        height: "100%",
        width: widthPercentageToDP(100),
        backgroundColor: "#222222c7",
        alignItems: "center",
        justifyContent: "center",
    },
    innerView: {
        backgroundColor: "#F1F1F1",
        borderRadius: 15,
        width: mobilBrower() ? "90%" : '25%',
        height: "40%",
        padding: 15,
        shadowColor: "white",
        shadowOffset: { width: 0, height: 0.5 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
        elevation: 12,
    },
    bgImg: {
        width: "55%",
        height: "100%",
        right: "5%",
        top: "-5%",
        resizeMode: "contain",
        position: "absolute"
    },
    buttonContanier: {
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
    },
    playStoreImg: {
        width: mobilBrower() ? 60 : 100,
        height: mobilBrower() ? 30 : 50,
        resizeMode: "contain",
    },
    playStoreImg2: {
        width: mobilBrower() ? 100 : 100,
        height: mobilBrower() ? 70 : 50,
        resizeMode: "contain",
    },
    text: {
        fontSize: mobilBrower() ? responsiveText(12) : responsiveText(16),
        fontFamily: "Sans-Serif",
        color: "#030135",
        fontWeight: "700"
    },
    infoContainer: {
        flexDirection: "row",
        padding: 10,
        height: heightPercentageToDP(30)
    },
    closeIconButton: {
        marginLeft: 5,
        alignSelf: "flex-start",
        marginBottom: 15,
    },
    closeIcon: {
        height: 20,
        width: 20,
    },
    storeIconView: {
        marginTop: heightPercentageToDP("1.5%"),
        flexDirection: "row",
        alignItems: "center",
    },
    pathsContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-evenly"
    },
    pathItem: {
        height: 60,
        alignItems: "center",
        width: mobilBrower() ? "45%" : "40%",
        alignSelf: "center",
        borderRadius: 10,
        padding: mobilBrower() ? 5 : 10,
        flexDirection: "row",
        margin: mobilBrower() ? 5 : 10,
        backgroundColor: "white",
    },
    logoImg: {
        width: "40%",
        resizeMode: "contain",
        padding: 10,
        marginLeft: 5,
        height: "100%",
    },
    pathItemContent: {
        flexDirection: "column",
        paddingLeft: 10,
        width: "60%",
        alignItems: "flex-start",
    },
    pathItemTitle: {
        fontFamily: family.effra,
        fontSize: responsiveText(8),
        fontWeight: "700",
    },
    pathItemDesc: {
        fontFamily: family.effra,
        marginTop: 5,
        fontSize: responsiveText(5),
        fontWeight: "400",
    }
});