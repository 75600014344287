import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
import { useDispatch } from "react-redux";
import { callAskCommunity } from "../../../actions/discussion.action";
import { styles } from "./tabs.styles";

export const Tabs = ({ inView, setInView, setQuestion }) => {
  const dispatch = useDispatch();

  const tabs = [
    {
      tab: "Conversations",
    },
    {
      tab: "Discussions",
    },
    {
      tab: "FAQs",
    },
    {
      tab: "Ask Our Expert",
    },
    {
      tab: "Ask Community",
    },
  ];

  const handleOnPress = (e) => {
    setQuestion("")
    setInView(e.tab.toLowerCase())
    dispatch(callAskCommunity({}))
  }
  return (
    <View style={styles.tabView}>
      <View style={[styles.tabs]}>
        {tabs.slice(0, 3).map((e) => {
          const isTabActive = inView === e.tab.toLowerCase();
          return (
            <TouchableOpacity
              onPress={() => setInView(e.tab.toLowerCase())}
              style={[styles.forumTab]}
            >
              <Text
                style={[
                  styles.tabsText,
                  {
                    color: isTabActive ? "black" : "#77838F",
                    backgroundColor: isTabActive ? "white" : "transparent",
                  },
                ]}
              >
                {e.tab}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>

      {!["Converstions"].includes(inView) ? (
        <View style={styles.oTab}>
          {tabs.slice(3).map((e) => (
            <View style={styles.oButton}>
              <TouchableOpacity
                onPress={() => handleOnPress(e)}
                style={styles.oButtonA}
              >
                <Text style={styles.oTitle}>{e.tab}</Text>
                <View style={styles.plusView}>
                  <Text style={styles.plusText}>+</Text>
                </View>
              </TouchableOpacity>
            </View>
          ))}
        </View>
      ) : (
        <View />
      )}
    </View>
  );
};
