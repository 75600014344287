import { combineReducers } from "redux";
import { Item } from "native-base";
import { helpers } from "../common";
import AsyncStorage from '@react-native-community/async-storage';

const initialState = {
  timelineData: [],
  timelineDetails: {}
};

const timelineData = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_DETAILS":
      // console.log("CLEAR_DETAILS....");
      return {
        ...state,
        timelineData: [],
        timelineDetails: {},
        updatedAt: new Date()
      };
    case "CLEAR_TIMELINE_DETAILS":
      return {
        ...state,
        timelineData: [],
        timelineDetails: {},
        updatedAt: new Date()
      };
    case "CLEAR_QUESTIONS_DATA":
      return {
        ...state,
        timelineData: [],
        timelineDetails: {},
        updatedAt: new Date()
      };
    case "TOGGLES_TICK":
      // console.log("actio payload", action.payload, state.timelineData);
      let timelineArray = state.timelineData;
      let dataDict = timelineArray[action.payload];
      dataDict.checked = !dataDict.checked;
      timelineArray[action.payload] = dataDict;
      // console.log("actio after", timelineArray);

      return {
        ...state,
        timelineData: timelineArray,
        updatedAt: new Date()
      };

      case "SELECT_ALL_TIMELINE":
      timelineArray = state.timelineData.map(function (singleTimeline) {
        singleTimeline.checked = action.payload
        return singleTimeline
      });
      AsyncStorage.setItem('selectAllTimelines', JSON.stringify(action.payload))
      return {
        ...state,
        timelineData: timelineArray,
        select_all: action.payload,
        updatedAt: new Date()
      };

    // case "REMOVE_TIMELINE":
    //   console.log("actio payload", action.payload);
    //   // let timelinesArray = state.timelineData;

    //   // // timelinesArray.splice(index, 1);
    //   // console.log("actio after", timelinesArray);

    //   return {
    //     ...state,
    //     // timelineData: timelinesArray,
    //     // updatedAt: new Date()
    //   };

    case "HIGHLIGHT_TOOLTIP":
      // console.log("actio payload", action);
      let timelineArrayy = state.timelineData;
      timelineArrayy.map(item => {
        item.typeArray.map(i => {
          i.toolTipVisible = false;
        });
      });
      const dataDicts = timelineArrayy[action.arrayIndex];
      // console.log("data dict..", dataDicts);
      dataDicts.typeArray.map((item, index) => {
        // console.log("items..", item, index);
        item.toolTipVisible = false;
        if (index == action.tooltipIndex) {
          item.toolTipVisible = action.val;
        }
      });
      // console.log("data dict..", dataDicts);

      timelineArrayy[action.arrayIndex] = dataDicts;
      // console.log("actio after", timelineArrayy);

      return {
        ...state,
        timelineData: timelineArrayy,
        updatedAt: new Date()
      };

      // case "HIGHLIGHT_TOOLTIP_ALL":
      //   console.log("actio payload", action);
      //   let timelineArrayy = state.timelineData;
      //   timelineArrayy.map(item => {
      //     item.typeArray.map(i => {
      //       i.toolTipVisible = false;
      //     });
      //   });

      return {
        ...state,
        timelineData: timelineArrayy,
        updatedAt: new Date()
      };

    case "DELETE_TIMELINE":
      // console.log("actio payload", action);
      let timelineArrays = state.timelineData;

      timelineArrays.splice(action.payload, 1);

      return {
        ...state,
        timelineData: timelineArrays,
        updatedAt: new Date()
      };

    case "TIMELINE_SELECTOR_LOADING":
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        errors: null
      };

    case "TIMELINE_SELECTOR_SUCCESS":
      // console.log("TIMELINE res..", action, state);
      let timlineData = action.payload;
      const immiPathsDetails = action.immiPaths;

      let dataArray = [];
      timlineData.map((item, index) => {
        let timelinesArray = [];

        const component = item.timeline_components.map(item => {
          const obj = {};

          const data = item.component_duration_range.split("-");
          let ends = data[1] ? Number(data[1]) : null;
          let starts = data[0] ? Number(data[0]) : null; //Number(data[0]);
          obj.start = starts || null;
          obj.end = ends || starts || null;
          // console.log(ends, starts, obj.start, obj.end);
          obj.color = helpers.findColor(item.component_type_id + "");
          obj.typeName = item.component_name || "";
          obj.componentId = item.component_id || null;
          obj.componentTypeId = item.component_type_id || "";
          obj.toolTipVisible = false;
          timelinesArray.push(obj);
        });
        let pathDeatils = immiPathsDetails.filter(i => {
          return i.immipathId === item.immipath_id;
        });

        if (pathDeatils.length > 0) {
          let timeLineObj = {
            country: pathDeatils[0].countryName || "",
            immegrationPath: pathDeatils[0].pathName || "",
            immipathId: pathDeatils[0].immipathId,
            checked: false,
            typeArray: timelinesArray || []
            //   ite
            // })
            // item.timeline_components || []
          };
          dataArray.push(timeLineObj);
        }
      });

      // console.log("TIMELINE pathDeatils", dataArray);

      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        timelineData: dataArray,
        errors: null
      };

    case "TIMELINE_SELECTOR_FAIL":
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        timelineData: [],
        errors: action.payload
      };

    case "TIMELINE_DETAILS_LOADING":
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        errors: null
      };

    case "TIMELINE_DETAILS_SUCCESS":
      // console.log("TIMELINE res..", action, state);

      const details = action.payload;

      const data = {};
      data.pathName = action.pathName || "";
      data.countryName = action.country || "";
      data.cost = details.component_cost_range || "";
      data.componentName = details.component_name || "";
      data.description = details.component_description || "";
      data.time = details.expected_time_range || "";
      data.documents = details.documents_required || [];
      data.immipathId = details.immipath_id;
      data.countryId = details.country_id;

      // console.log("timelineDetails data", data);
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        timelineDetails: data,
        errors: null
      };

    case "TIMELINE_DETAILS_FAIL":
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        details: [],
        errors: action.payload
      };

    default:
      return state;
  }
};

export default combineReducers({
  timelineData
});
