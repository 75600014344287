import React, { useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  SafeAreaView,
} from "react-native";
import Images from "../images";
import NavigationService from "../../navigation/NavigationService";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "./newHeader.styles";
import { animated as a, useSpring } from "@react-spring/native";
import NotificationModal from "../../components/NotificationModal/NotificationModal";
import AlertModal from "../../components/AlertModal/AlertModal";
import { isWeb } from "../helpers";
import DownloadModal from "../../components/DownloadModal/DownloadModal";
import { walkthroughable } from "react-native-copilot";
import { expiryToken, logoutUser } from "../../actions/auth.actions";
import AsyncStorage from "@react-native-community/async-storage";

const CopilotView = walkthroughable(View);
const CopilotTouch = walkthroughable(TouchableOpacity);

function HeaderComponent({
  handleNotificationClick,
  history,
  screenTitle,
  start,
  routeName,
  titleContainerStyle = {},
  showNotificationIcon,
  showback,
}) {

  const cartItems = useSelector(
    (state) => state.referralReducer.referralData.cartItems
  );
  const [openNotificationModal, setopenNotificationModal] = useState(false);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDropView, setShowDropView] = useState(false);
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const userImg = useSelector(
    (state) => state.authReducer.getUserProfilePic.profilePic
  );
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const initialLoadStatus = useSelector(
    (state) => state.appStateReducer.initialLoadState
  );
  const token = useSelector((state) => state.authReducer.authData.token);

  const dispatch = useDispatch();
  const mobilBrower = () => {
    var isMobileBrower = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobileBrower;
  };

  const handleProfileClick = () => {
    if (guestLogin) {
      setshowErrorModal(true);
    } else {
      isWeb()
        ? history.push("/profile")
        : NavigationService.navigate("NewProfileScreen");
    }
  };

  const handlePress = (path, mobileOnly = false) => {
    isWeb()
      ? mobileOnly ? setShowDownloadModal(true) : history.push(`/${path}`)
      : NavigationService.navigate(path);
  };

  const handleCartPress = () => {
    history.push("/cart");
  };

  const handleLogoutConfirmed = async () => {
    let postdata = {};
    postdata.email_address = userDetails.email_address;
    var user_id = userDetails?.userid;
    await dispatch(logoutUser(postdata, user_id, token));
    setShowAlert(false);
    AsyncStorage.clear();
    history.push("/login", { hideBack: true });
    setTimeout(() => {
      dispatch(expiryToken());
    }, 1000);
  };

  const getView = () => {
    if (isWeb() && !mobilBrower()) {
      const WEB_HEADER_ITEMS = [
        { displayName: "Dashboard", path: "dashboard" },
        { displayName: "Path Explorer", path: "pathExp" },
        { displayName: "Media", path: "Media" },
        { displayName: "Advice Channel", path: "adviceChannel" },
        { displayName: "About Us", path: "about" },
      ]
      const SIDE_BAR_OPTIONS = [
        {
          iconName: "user-circle",
          label: "Profile",
          onPress: () => handleProfileClick()
        },
        {
          iconName: "sign-out",
          label: "Logout",
          onPress: () => setShowAlert(true)
        }
      ]
      return (
        <View style={styles.innerView}>
          <View style={styles.leftView}>
            <Image
              source={Images.toolbarLogo}
              style={styles.logo}
            />
            <View style={styles.tabView}>
              {WEB_HEADER_ITEMS.map(({ displayName, path, mobileOnly }, index) => {
                let isActive = history.location.pathname == `/${path}`;
                return (
                  <TouchableOpacity
                    key={index + "h"}
                    onPress={() => handlePress(path, mobileOnly)}
                    style={styles.headerItems}
                  >
                    <Text style={[styles.dashboardItemText, { fontWeight: isActive ? "700" : "400" }]}>{displayName}</Text>
                    {isActive ?
                      <View style={styles.activeDot} />
                      : null}
                  </TouchableOpacity>
                )
              })}
            </View>
          </View>
          <View style={styles.rightView}>
            {/* {cartItems?.length > 0 ? (
              <TouchableOpacity style={{marginHorizontal:10}} onPress={handleCartPress}>
                {cartItems?.length > 0 ? (
                  <View style={styles.cartlength}>
                    <Text style={styles.cartlengthtext}>
                      {cartItems.length}
                    </Text>
                  </View>
                ) : (
                  <View />
                )}
                <Image source={Images.userCartIcon} style={styles.userCart} />
              </TouchableOpacity>
            ) : (
              <View />
            )} */}
            {
              guestLogin ?
                <>
                  <TouchableOpacity
                    style={styles.loginButton}
                    onPress={() => history.push('/login')}>
                    <Text style={{ fontWeight: "700", color: 'white', fontSize: 16, }}>
                      Login
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ margin: 10 }}
                    hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
                    onPress={() => history.push('/login', { signUp: true })}>
                    <Text style={{ fontWeight: "400", color: 'white', fontSize: 16 }}>
                      Register
                    </Text>
                  </TouchableOpacity>
                </> :
                <>
                  <TouchableOpacity onPress={() => setopenNotificationModal(true)}>
                    <Image source={Images.headerNotify} style={styles.notificationBell} />
                  </TouchableOpacity>
                  <View>
                    <TouchableOpacity hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }} onPress={() => setShowDropView(!showDropView)}>
                      <Image source={userImg?.uri ? { uri: userImg.uri } : Images.profilePic} style={styles.userIcon} />
                    </TouchableOpacity>
                    {showDropView ?
                      <View style={styles.dropDownContainer}>
                        <Image source={Images.Vector2} style={styles.vector2} />
                        {SIDE_BAR_OPTIONS.map(({ onPress, iconName, label }, index) => (
                          <TouchableOpacity key={index + "o"} onPress={onPress} style={styles.dropViewBtn}>
                            <i className={`fa fa-${iconName}`} style={{ color: "#000", fontSize: 20 }} />
                            <Text style={{ fontSize: 18, fontWeight: "400", color: "#000", marginLeft: 10 }}>{label}</Text>
                          </TouchableOpacity>
                        ))}
                      </View>
                      : null}
                  </View>
                </>
            }
          </View>
          {showAlert ? (
            <AlertModal
              confirmText="No"
              showConfirm
              showCancel
              routeName={routeName}
              title="Logout"
              description="Are you sure you want to logout?"
              cancelText="Yes"
              onCancelPress={handleLogoutConfirmed}
              onConfirmPress={() => {
                setShowAlert(false);
              }}
            />
          ) : null}
        </View>
      );
    } else {
      const opacityWiseEaseIn = mobilBrower() ? {} : useSpring({
        config: {},
        pause: !initialLoadStatus,
        delay: 200,
        from: {
          opacity: 0,
        },
        to: {
          opacity: 1,
        },
      });
      const easeIn = mobilBrower() ? {} : useSpring({
        config: {},
        pause: !initialLoadStatus,
        from: {
          marginTop: -20,
          opacity: 0,
        },
        to: [
          {
            opacity: 1,
            marginTop: 0,
          },
        ],
      });
      return (
        <View style={styles.innerViewMobile}>
          {showback ? (
            <TouchableOpacity onPress={() => {
              isWeb() ? history.goBack() : NavigationService.goBack();
            }}>
              <a.Image
                source={Images.whiteBack}
                style={[opacityWiseEaseIn, styles.backIcon]}
              />
            </TouchableOpacity>
          ) : showNotificationIcon ? (

            <TouchableOpacity onPress={() => handleNotificationClick()}>
              <a.Image
                source={Images.headerNotify}
                style={[opacityWiseEaseIn, styles.notificationBell]}
              />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              onPress={() => {
                isWeb() ? history.push("/dashboard") :
                  NavigationService.navigate("Home")
              }}
            >
              <a.Image
                source={Images.toolbarLogo}
                style={[opacityWiseEaseIn, styles.logo]}
              />
            </TouchableOpacity>
          )}
          {screenTitle ? (
            <a.Text style={[styles.screenTitle, easeIn]}>{screenTitle} </a.Text>
          ) : (
            <a.Image
              source={Images.toolbarLogo}
              style={[styles.logo, easeIn]}
            />
          )}
          <TouchableOpacity
            hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
            onPress={() => handleProfileClick()}
          >
            <a.Image
              source={userImg?.uri ? { uri: userImg.uri } : Images.profilePic}
              style={[styles.userIcon, { marginLeft: 0 }, opacityWiseEaseIn]}
            />
          </TouchableOpacity>
        </View>
      );
    }
  };

  return (
    <>
      <View style={styles.mainView}>
        <SafeAreaView />
        {getView()}
        {showDownloadModal ? (
          <DownloadModal closeModal={() => setShowDownloadModal(false)} />
        ) : null}
        {showErrorModal ? (
          <AlertModal
            title="Login"
            description="Login to explore more!"
            showCancel
            showConfirm
            confirmText="Login"
            cancelText="Not now"
            routeName={routeName}
            loginModal
            onCancelPress={() => setshowErrorModal(false)}
            onConfirmPress={() => setshowErrorModal(false)}
          />
        ) : (
          <View />
        )}
        {
          <NotificationModal
            openNotificationModal={openNotificationModal}
            onClose={() => setopenNotificationModal(false)}
          />
        }
      </View>
      {isWeb() && !mobilBrower() && screenTitle ?
        <View style={[styles.titleContainer, titleContainerStyle]}>
          <Text style={styles.title}>{screenTitle}</Text>
        </View> : null}
    </>
  );
}

export default isWeb()
  ? withRouter(HeaderComponent)
  : (HeaderComponent);
