import React, { useState } from "react";
import { TouchableOpacity, Text, FlatList, View } from "react-native";
import { useSelector } from "react-redux";
import ShortListItem from "../../../components/ShortListItem/ShortListItem";
import { isWeb } from "../../../common/helpers";
import NewsHighlightCard from "./NewsHighlightCard/NewsHighlightCard";

export const DashboardContent = ({
  children,
  guestLogin,
  lastShortListedpath,
  highlights,
  history,
  headerCoppilotShown,
  styles,
}) => {
  const [showMoreHighlights, setshowMoreHighlights] = useState(false);
  const handleShowMore = () => {
    setshowMoreHighlights(!showMoreHighlights);
  };
  const defaultNews = useSelector(
    (state) => state.mainMediaReducer.news["default"]
  );

  return (
    <TouchableOpacity
      activeOpacity={1}
      disabled={isWeb() ? true : false}
      style={{ backgroundColor: isWeb() ? "#0F1143" : "#FFF", marginTop: !headerCoppilotShown ? 0 : 110 }}
    >
      {children}
      <View style={{ backgroundColor: "#FFF", width: "100%" }}>
        <Text style={styles.newsHighTitle}>News Highlights</Text>
        <FlatList
          data={
            !showMoreHighlights
              ? defaultNews.slice(0, 2)
              : defaultNews.slice(0, 5)
          }
          style={{ alignSelf: "center", marginBottom: 150 }}
          ListFooterComponent={() => (
            <TouchableOpacity
              onPress={() => handleShowMore()}
              style={[styles.showMoreButtom, { marginVertical: 20 }]}
            >
              <Text style={styles.showMoreText}>
                {showMoreHighlights ? "Show Less" : "Show More"}
              </Text>
            </TouchableOpacity>
          )}
          renderItem={({ item, name }) => <NewsHighlightCard item={item} />}
        />
        {isWeb() ?
          <>
            <Text style={styles.newsHighTitle}>Latest Shortlisted Path</Text>
            <FlatList
              data={guestLogin ? [1] : lastShortListedpath}
              ListFooterComponent={() => {
                return <View />;
              }}
              style={{ alignSelf: "center" }}
              contentContainerStyle={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 60,
              }}
              renderItem={({ item, name }) => (
                <ShortListItem item={item} history={history} />
              )}
            />
          </>
          : null}
      </View>
    </TouchableOpacity>
  );
};
