import React, { useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Image,
} from "react-native";
import Images from "../../../../common/images";
import { Articles } from "../Articles/Articles";
import { Home } from "../Home/Home";
import { Lists } from "../Lists/Lists";
import { Paths } from "../Paths/Paths";
import { styles } from "./cards.styles";


const TABS = ["My Paths", "My Articles", "My Orders"];


export const CardsWeb = ({ routeParams = {} }) => {
  const [currentCard, setCurrentCard] = useState(null)

  const renderCurrentCard = () => {
    switch (currentCard) {
      case 0:
        return <Paths pathInfo={routeParams} />;
      case 1:
        return <Articles />;
      case 2:
        return <Lists />;
      default:
        return <Home />
    }
  }

  useEffect(() => {
    if (routeParams?.app_ref && routeParams?.app_ref !== "") {
      setCurrentCard(0);
    }
  }, [routeParams])

  return (
    <View style={{ flex: 1, alignItems: "center", paddingBottom: 10 }}>
      <View style={{ width: "100%", justifyContent: "center" }}>
        {currentCard != null ? (
          <TouchableOpacity
            onPress={() => setCurrentCard(null)}
            style={{ padding: 5, marginLeft: 10, position: "absolute", left: 10 }}
          >
            <Image source={Images.blackbackIcon} style={{ width: 24, height: 24, resizeMode: "contain" }} />
          </TouchableOpacity>
        ) : null}
        <View style={[styles.tabs, { alignSelf: "center" }]}>
          {TABS.map((tab, index) => {
            return (
              <TouchableOpacity
                style={styles.tab}
                onPress={() => setCurrentCard(index)}
              >
                <Text
                  style={[
                    styles.tabText,
                    {
                      opacity: currentCard == index ? 1 : 0.2,
                    },
                  ]}
                >
                  {tab}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
      <View style={{ width: "60%", flex: 1 }}>
        {renderCurrentCard()}
      </View>
      {currentCard != null ? (
        <TouchableOpacity
          onPress={() => setCurrentCard(null)}
          style={styles.backToProfileBtn}
        >
          <Text
            style={{
              textAlign: "center",
              color: "white",
            }}
          >
            Back to Profile Overview{" "}
          </Text>
        </TouchableOpacity>
      ) : null}
      <View style={{ flexDirection: "row" }}>
        {[0, 1, 2].map((tab => (
          <View key={tab + "t"} style={[styles.bar, { backgroundColor: currentCard == tab ? "#0F1143" : "rgb(180,180,180)" }]} />
        )))}
      </View>
    </View>
  );
};
