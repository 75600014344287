import { StyleSheet } from "react-native";
import { family } from "../../common";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";
import { heightPercentageToDP } from "../../common/responsiveScreen";

export const multiSelectStyle = {
  item: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  container: {
    width: !mobilBrower() ? "50%" : "90%",
    alignSelf: "center",
  },
  itemText: {
    color: "black",
    fontWeight: "400",
  },
  subItem: {
    color: "grey",
    paddingHorizontal: 10,
    height:"100%"
  },
  selectedItem: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  selectedSubItem: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  selectToggle: {
    justifyContent: "space-between",
  },
  chipsWrapper: {
    backgroundColor: "yellow",
  },
  selectToggleText: {
    color: "black",
    fontSize: 14,
  },
  subSeparator:{
    backgroundColor:"transparent"
  },
  button: {
    backgroundColor: "#030135",
    flex:1
  },
  scrollView: { paddingHorizontal: 0 ,flex:1},
};

export const newSignUpUiStyles = StyleSheet.create({
  mainView: {
    // flex: 1,
    width: "100%",
    backgroundColor: !mobilBrower() ? "#fff" : "#0F1143",
  },
  mainContainer:{
    justifyContent: "flex-end",
    alignSelf: "center",
    height: "100%",
    width: !mobilBrower() ? "80%" : "100%",
  },
  keyboardAvoidingStyle: {
    width: "100%",
    maxHeight: "98vh",
  },
  countiesView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 0,
    width: "100%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  textInput: {
    paddingHorizontal: 15,
    paddingVertical: 12,
    flex: 1,
    marginLeft: 0,
    width: "100%",
  },
  inputBox: {
    width: "90%",
    backgroundColor: "#F5F6F8",
    borderRadius: 5,
    flexDirection: "row",
    borderWidth: 2,
    marginTop: 15,
    borderColor: "#BDBDBD",
    alignItems: "center",
  },
  inputBoxmarginTop: {
    marginTop: 20,
  },
  pickerContainerView: {
    paddingHorizontal: 13,
    paddingVertical: 8,
    paddingRight: 6,
    height: "auto",
    width: "100%",
  },
  backBtn: {
    marginTop: 30,
    left: "5%",
  },
  goalsButton: {
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#F1AB32",
    marginVertical: 10,
    justifyContent: "center",
    height: mobilBrower() ? 45 : 50,
    borderRadius: 5,
    width: "48%",
    paddingLeft: 8,
    paddingRight: 8,
  },
  unimmigrationInterst: {
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#BDBDBD",
    marginVertical: 10,
    justifyContent: "center",
    borderRadius: 5,
    height: mobilBrower() ? 45 : 50,
    width: "48%",
    paddingLeft: 8,
    paddingRight: 8,
  },
  goalText: {
    color: "white",
    fontSize: responsiveText(12),
    fontFamily: family.semibold,
  },
  loginSignupTab: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 10,
    justifyContent: "space-between",
    width: "45%",
  },
  loginTitle: {
    fontSize: 16,
    color: "rgba(15, 17, 67, 1)",
  },
  bottomBar: {
    marginTop: 10,
    borderWidth: 0.5,
    width: "55%",
    borderColor: "#BDBDBD",
    marginVertical: 15
  },
  loginSelected: {
    width: "50%",
    borderWidth: 0.5,
    alignSelf: "flex-end",
    borderColor: "#0F1143",
  },
  privacyView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    width: "90%",
    alignSelf: "center",
  },
  ageCheckView: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
    marginLeft: 20,
    marginTop: 15,
  },
  chcekBox: {
    height: 16,
    width: 16,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
  },
  checked: {
    height: 16,
    width: 16,
    borderRadius:8,
    backgroundColor: "green",
  },
  privacyText: {
    textAlign: "justify",
    marginTop: 4,
  },
  privacyLink: {
    color: "blue",
  },
  countryIcon: {
    height: 50,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: "center",
  },
  flagbuttons: {
    height: 80,
    alignItems: "center",
    margin: 10,
  },
  closeIconButton: {
    alignSelf: "flex-start",
    marginTop: 10,
    marginLeft: 20,
    position: "absolute",
    left: 0,
    top: 20,
  },
  closeIcon: {
    height: 20,
    width: 30,
    resizeMode: "contain",
  },
  mailImage: {
    height: 180,
    width: 180,
    marginLeft: 10,
    marginRight: 10,
    resizeMode: "contain",
    borderRadius: 50,
    alignItems: "center",
    borderWidth: 2,
    borderColor: "#fff",
  },
  imageview: {
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    justifyContent: "center",
  },
  firstName: {
    width: "95%",
    borderRadius: 10,
    marginTop: 30,
    height: 60,
    shadowColor: "grey",
    borderBottomWidth: 1,
    borderColor: "#030135",
  },
  slide2Fields: {
    width: "95%",
    borderRadius: 10,
    marginTop: 30,
    shadowColor: "grey",
    borderBottomWidth: 1,
    borderColor: "#030135",
  },

  countryMainView: {
    width: "100%",
    borderRadius: 10,
    marginTop: 15,
    alignItems: "center",
    shadowColor: "grey",
    borderColor: "#030135",
  },
  loginImmiIcon: {
    resizeMode: "contain",
    height: 70,
    marginBottom: 50,
    width: 70,
  },
  editIon: {
    position: "absolute",
    top: 0,
    padding: 6,
    right: 30,
    backgroundColor: "#fff",
    zIndex: 999999,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: "gray",
    alignItems: "center",
  },
  slide1: {
    width: "100%",
    alignItems: "center",
  },
  label: {
    fontFamily: family.semibold,
    color: "#bdbdbd",
    marginLeft: 5,
    fontSize: 16,
    alignSelf: "flex-start",
    textAlign: "center",
    letterSpacing: 1,
  },
  title: {
    alignSelf: "center",
    width: "80%",
    fontFamily: family.regular,
    marginBottom: 8,
    marginLeft: 5,
    marginTop: 10,
    fontSize: 19,
    color: "#0F1143",
    alignSelf: "center",
    textAlign: "center",
    letterSpacing: 1,
  },
  title2: {
    alignSelf: "center",
    width: "80%",
    fontFamily: family.regular,
    marginBottom: 8,
    marginLeft: 5,
    marginTop: 0,
    fontSize: 14,
    color: "#0F1143",
    alignSelf: "center",
    textAlign: "center",
    letterSpacing: 1,
  },
  innerView: {
    backgroundColor: "#222222c7",
    borderRadius: 10,
    bottom: -30,
    padding: 10,
  },
  errortext: {
    marginTop: 5,
    color: "red",
    alignSelf: "flex-start",
    marginLeft: 25,
  },
  choosePictitle: {
    alignSelf: "center",
    width: "20%",
    fontFamily: family.semibold,
    marginBottom: 8,
    marginLeft: 5,
    marginTop: 10,
    fontSize: 17,
    alignSelf: "center",
    textAlign: "center",
    letterSpacing: 1,
  },
  nextText: {
    fontFamily: family.semibold,
    color: "white",
    fontSize: 17,
    letterSpacing: 1,
  },
  buttonView: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  pickerListContainer:{
    width:mobilBrower()?"90%":"50%"
  },
  nextButton: {
    backgroundColor: "#030135",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginVertical: 30,
    paddingHorizontal: 13,
    paddingVertical: 8,
    height: "auto",
    width: "100%"
  },
  loginWith: {
    fontSize: 14,
    marginTop: 40,
    alignSelf: "center",
  },
  uploadButton: {
    backgroundColor: "#030135",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    paddingLeft: 8,
    paddingRight: 8,
    width: "40%",
    alignSelf: "center",
    marginTop: 65,
  },
  signUpFiledMain: {
    backgroundColor: "white",
    width: "100%",
    minHeight: heightPercentageToDP(100),
    borderTopLeftRadius: !mobilBrower() ? 0 : 20,
    borderTopRightRadius: !mobilBrower() ? 0 : 20,
    alignItems: "center",
    padding: !mobilBrower() ? 0 : 10,
    marginTop: "12%",
    alignSelf: "center",
  },
  selectLabelTextStyle: {
    color: "#000",
    textAlign: "left",
    width: "99%",
    padding: 0,
    flexDirection: "row",
  },
  placeHolderTextStyle: {
    color: "grey",
    padding: 10,
    textAlign: "left",
    width: "99%",
    flexDirection: "row",
  },
  listTextViewStyle: {
    color: "#000",
    marginVertical: 10,
    flex: 0.9,
    marginLeft: 20,
    marginHorizontal: 10,
    textAlign: "left",
  },
  pickerStyle: {
    elevation: 3,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 13,
    height: "auto",
  },
  listContainer:{
    width: "50%"
  },
  commonAnimationWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
