import { StyleSheet, Platform } from "react-native";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  missingInfoView: {
    alignItems: 'center'
  },
  missingtitle: {
    fontSize: 16,
    marginBottom: 30
  },
  submit: {
    backgroundColor: "#F1AB32",
    padding: 8,
    borderRadius: 5,
    paddingLeft: 16,
    marginTop: 15,
    paddingRight: 16
  },
  submitText: {
    color: 'white'
  },
  sectionBreaker: {
    width: "100%",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  sectionLine: {
    height: 2,
    flex: 1,
    backgroundColor: "rgb(230,230,230)",
  },
  showAllBox: {
    flexDirection: 'row',
    marginTop: 15,
    alignSelf: 'center',
    alignItems: 'center'
  },
  showALlText: {
    fontSize: 14,
  },
  checkBox: {
    height: 15,
    width: 15,
    marginRight: 10,
    borderRadius: 7.5,
    borderWidth: 0.5
  },
  sectionBreakText: {
    marginHorizontal: 20,
    textAlign: "center",
  },
  buttonRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center'
    // width: '60%'
  },
  nextWrapper: {
    backgroundColor: "rgb(15, 17, 67)",
    alignSelf: 'center',
    padding: 10,
    marginHorizontal: 8,
    marginBottom: 40,
    borderRadius: 25,
    width: 160,
    marginTop: 20,
  },
  nextText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 17,
  },
  textT: {
    fontSize: 25,
    textAlign: "center",
    width: "80%",
    lineHeight: 55,
  },
  countryMainView: {
    width: "100%",
    borderRadius: 10,
    alignItems: "center",
    shadowColor: "grey",
    borderColor: "#030135",
  },
  countiesView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 0,
    alignSelf: "center",
    justifyContent: "space-between",
  },
  flagbuttons: {
    height: 80,
    alignItems: "center",
    ...Platform.select({
      web: {
        margin: 10,
      },
      android: {
        width: "20%",
      },
      ios: {
        width: "20%",
      },
    }),
  },
  countryIcon: {
    height: 50,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: "center",
  },
  countryView: {
    width: mobilBrower() ? "100%" : "45%",
    alignItems: "center",
    marginVertical: 15
  },
  title: {
    fontSize: responsiveText(20),
    fontWeight: "bold",
    marginBottom: 20,
  },
  countriesView: {
    flex: 1,
    alignItems: "center",
    width: "100%",
    justifyContent: "center"
  },
  countryRow: {
    width: "100%",
    flexDirection: "row",
    marginTop: 10
  },
  countryLabel: {
    fontSize: responsiveText(8),
    textAlign: "center",
    fontWeight: "700",
  },
  countryItem: {
    height: 80,
    flex: 1,
    alignItems: "center",
  },
  innerView: {
    flexDirection: mobilBrower() ? "column" : "row",
    paddingVertical: mobilBrower() ? 0 : 20,
    justifyContent: "space-between",
    width: "90%"
  },
  orBorderContainer: {
    flexDirection: mobilBrower() ? "row" : "column",
    marginHorizontal: mobilBrower() ? 0 : 10,
    alignItems: "center"
  },
  orBorder: {
    borderWidth: 1,
    flex: 1,
    borderColor: "#BDBDBD"
  },
  orText: {
    marginHorizontal: mobilBrower() ? 5 : 0,
    marginVertical: mobilBrower() ? 0 : 5,
    fontWeight: "bold"
  },
});
