import React from "react";
import { Text } from "native-base";
import { TouchableHighlight, TouchableOpacity, View } from "react-native";
import { styles } from "./checkBoxItem.styles";

export const CheckBoxItem = ({
  item,
  handleCheckBoxClick,
  selectedFactsIds,
  selectedJobId,
  jobSelection
}) => {
  if (item.value !== "Cost of Living (customized)") {
    return (
      <TouchableOpacity
        onPress={() => handleCheckBoxClick(item)}
        activeOpacity={1}
        style={styles.checkBoxItems}
      >
        <View style={styles.checkBox}>
          {selectedFactsIds.includes(item.factId) ? (
            <View style={styles.checkView} />
          ) : (
            <View />
          )}
        </View>
        <View style={{ width: "100%" }}>
          <Text style={[styles.indexName]}>{item.value}</Text>

          {item.dropDownValues && item.dropDownValues.length > 0 ? (
            <TouchableHighlight
              onPress={() => jobSelection(item.dropDownValues)}
              underlayColor="rgb(220,220,220)"
              activeOpacity={0.8}
              style={{
                backgroundColor: "rgb(240, 240, 240)",
                width: "90%",
                marginLeft: "5%",
                borderRadius: 5,
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  padding: 10,
                }}
              >
                {selectedJobId
                  ? item.dropDownValues[selectedJobId].value
                  : "Select"}
              </Text>
            </TouchableHighlight>
          ) : (
            <View />
          )}
        </View>
      </TouchableOpacity>
    );
  } else {
    return <View />;
  }
};
