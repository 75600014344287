import React, { useState, useRef, useEffect } from "react";
import {
    Text,
    View,
    TouchableOpacity,
    Image,
    FlatList,
} from "react-native";
import { styles } from "./../CostOfLiving.styles";
import { COST_OF_LIVING } from "./../../../common/constofLivingQuestions";
import Images from "../../../common/images";
import { isArray, isEmpty } from "lodash";
import { TrailFromRight } from "./../Animations/costOfLiving.animation";
import AsyncStorage from "@react-native-community/async-storage";
import { isWeb } from "../../../common/helpers";

export default function Step2({ selectedCountry, defaultAnswers = {}, onCalculate = () => { } }) {
    const [selectedUserAnswers, setselectedUserAnswers] = useState(defaultAnswers);
    const [selectedQuestionIndex, setselectedQuestionIndex] = useState(-1);
    const [err, seterr] = useState({});
    const flatListRef = useRef(null);

    useEffect(() => {
        if (isWeb()) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [])

    const handleOptionTabPress = (index) => {
        if (index == selectedQuestionIndex) {
            setselectedQuestionIndex(-1);
            return;
        }
        setselectedQuestionIndex(index);
    };

    const handleOptionSelection = (type, item, index) => {
        let Obj = { ...selectedUserAnswers };
        if (isEmpty(Obj)) {
            Obj[index] = type == "multiselect" ? [item] : item;
        } else {
            if (type && type == "multiselect") {
                if (item.sub_id == "9") {
                    Obj[index] = [item];
                    setselectedUserAnswers(Obj);
                    return;
                }
                if (Obj[index]) {
                    let temp = isArray(Obj[index]) ? Obj[index] : [Obj[index]];
                    temp = isArray(temp) && temp.filter((s) => s.sub_id !== "9");
                    if (temp.find((s) => s.label == item.label)) {
                        temp.splice(
                            temp.findIndex((s) => s.label == item.label),
                            1
                        );
                    } else {
                        temp.push(item);
                    }
                    Obj[index] = temp;
                } else {
                    Obj[index] = [item];
                }
            } else {
                Obj[index] = item;
                setselectedQuestionIndex(-1);
            }
        }
        setselectedUserAnswers(Obj);
        AsyncStorage.setItem("tempUserSelection", JSON.stringify(Obj));
        seterr({});
    };

    const handleCalculate = () => {
        let err = {};
        for (let index = 0; index < 6; index++) {
            if (
                selectedUserAnswers[index] == undefined &&
                (index == 0 || index == 1 || index == 4 || index == 5)
            ) {
                err[index] = true;
            }
        }
        if (Object.entries(err).length > 0) {
            flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
            seterr(err);
            return;
        }
        onCalculate(selectedUserAnswers)
    }


    return (<View
        style={{
            flex: 1,
            alignSelf: "center",
            alignItems: "center",
            // padding: 5,
            zIndex: -1,
        }}
    >
        <Text style={styles.TitleText}>PREFERENCES</Text>
        <FlatList
            data={COST_OF_LIVING}
            ref={flatListRef}
            ListFooterComponent={() => (
                <TouchableOpacity
                    onPress={handleCalculate}
                    disabled={!selectedCountry}
                    style={[
                        styles.calculateButton,
                        !selectedCountry && styles.disabled,
                    ]}
                >
                    <Text style={styles.nextButtonText}>Calculate</Text>
                </TouchableOpacity>
            )}
            style={{ marginTop: 20 }}
            renderItem={({ item, index }) => {
                return (
                    <TouchableOpacity
                        activeOpacity={1}
                        style={[styles.questionView, { zIndex: -100 }]}
                    >
                        <Text style={styles.questionText}>{item.question}</Text>
                        <TouchableOpacity
                            activeOpacity={0.7}
                            onPress={() => handleOptionTabPress(index)}
                            style={[
                                styles.selectedAnswerView,
                                err[index] && styles.errorView,
                            ]}
                        >
                            <Text style={{ flex: 1 }}>
                                {selectedUserAnswers[index]
                                    ? isArray(selectedUserAnswers[index])
                                        ? selectedUserAnswers[index]
                                            .map((s) => s?.label)
                                            .join(", ")
                                        : selectedUserAnswers[index]?.label
                                    : "Select your answer"}
                            </Text>
                            <Image
                                source={Images.dropDownArrow}
                                style={styles.downArrow}
                            />
                        </TouchableOpacity>
                        {index == selectedQuestionIndex ? (
                            <View style={styles.optionDropDown}>
                                <TrailFromRight>
                                    {item.options.map((item1, index1) => {
                                        return (
                                            <TouchableOpacity
                                                onPress={() =>
                                                    handleOptionSelection(item.type, item1, index)
                                                }
                                                style={[
                                                    styles.optionsView,
                                                    index1 !== item?.options?.length - 1 &&
                                                    styles.separator,
                                                ]}
                                            >
                                                <Text style={styles.optionsText}>
                                                    {item1.label}
                                                </Text>
                                                {index == selectedQuestionIndex &&
                                                    isArray(selectedUserAnswers[index]) &&
                                                    selectedUserAnswers[index]
                                                        .map((s) => s?.label)
                                                        .join(", ")
                                                        .includes(item1.label) ? (
                                                    <Text
                                                        style={[
                                                            styles.optionsText,
                                                            { color: "green" },
                                                        ]}
                                                    >
                                                        ✔
                                                    </Text>
                                                ) : null}
                                            </TouchableOpacity>
                                        );
                                    })}
                                </TrailFromRight>
                            </View>
                        ) : (
                            <View />
                        )}
                    </TouchableOpacity>
                );
            }}
        />
    </View>

    );
}
