import { NavigationActions } from "react-navigation";

let _navigator;

function setTopLevelNavigator(navigatorRef) {
  _navigator = navigatorRef;
}

function getCurrentRoute() {
  const route = NavigationActions.getCurrentRoute();
  return route.name;
}

function navigate(routeName, params) {
  if(routeName=="advice"){
    alert("OMG")
  }
  _navigator.dispatch(
    NavigationActions.navigate({
      routeName,
      params,
    })
  );
}

function goBack(key) {
  _navigator.dispatch(
    NavigationActions.back({
      key: key,
    })
  );
}

function pop() {
  _navigator.dispatch(NavigationActions.back());
}

export default {
  navigate,
  goBack,
  getCurrentRoute,
  setTopLevelNavigator,
  pop,
};
