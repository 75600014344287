import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TextInput,
  TouchableHighlight,
  Dimensions,
  TouchableOpacity,
  BackHandler,
  Alert,
  SafeAreaView,
  Platform,
  KeyboardAvoidingView,
  Keyboard,
  Linking,
  ActivityIndicator,
} from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { connect } from "react-redux";
import { helpers, family } from "../../common";
import ReversedFlatList from "react-native-reversed-flat-list";
import * as moment from "moment";
import StarRating from "react-star-ratings";
import { TypingAnimation } from "react-native-typing-animation";
import { logEvent } from "../../actions/firebaselog.actions";
import { Network, apiPaths } from "../../service/api";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { Images } from "../../common";
import {
  immiBuckAdd,
  getImmibuckBalance,
} from "../../actions/immibucks.actions";
import Modal from "react-native-modal";
import RewardModal from "../../pages/RewardModal";
import ShareProfileModal from "../../pages/ShareProfileModal";
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from "../../common/responsiveScreen";
import { mobilBrower } from "../../common/helpers";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import storage from "@react-native-firebase/storage";

var width = Dimensions.get("window").width;

import { styles } from "./messageChat.styles";
import ReviewConsultant from "../NewConversationModal/ReviewConsultantModal";
import FollowUpQuestionModal from "../NewConversationModal/FollowUpQuestionModal";
import QuestionItem from "./QuestionItem";
import SweetAlert from "../SweetAlert";
import AlertModal from "../AlertModal/AlertModal";

const botsInfo = {
  permanent_residency: {
    name: "Swallow",
    for: "PR",
    icon: Images.swallow,
  },
  study: {
    name: "Kea",
    for: "study",
    icon: Images.kea,
  },
  work: {
    name: "Tern",
    for: "work",
    icon: Images.tern,
  },
  tourism: {
    name: "Macaw",
    for: "tourism",
    icon: Images.macaw,
  },
  legal: {
    name: "Macaw",
    for: "legal",
    icon: Images.macaw,
  },
};
class MessageChat extends Component {
  constructor(props) {
    super(props);
    let chatHeaderData = this.props.chatHeaderData;
    let messageId = this.props.messageId;
    this.state = {
      starCount: chatHeaderData.avg_ratings,
      messages: [],
      dialogclose: false,
      msgArr: false,
      paymentEnabled: false,
      reviewModal: false,
      messageList: "",
      Message: "",
      Token: "",
      reviewConsultantModal: false,
      followUpModal: false,
      showRewardModal: false,
      category: chatHeaderData?.category,
      UserId: "",
      auto_res: this.props.auto_res || null,
      getAskedData: this.props.getAskedData,
      resolveValue: false,
      is_resolved: chatHeaderData.is_resolved,
      specializations: chatHeaderData.specializations,
      messageId: messageId,
      showActionView: false,
      chatName: chatHeaderData.agent_name,
      expert_id: chatHeaderData?.expert_id,
      query_count: chatHeaderData?.query_count,
      sub_category: this.props.chatHeaderData?.sub_category,
      discussion_platform: chatHeaderData?.discussion_platform
        ? chatHeaderData?.discussion_platform
        : null,
      follow_up_data: chatHeaderData?.follow_up_data,
      occupations: [],
      advice_package: chatHeaderData?.advice_package,
      ref_id: this.props.refId || "",
      adviceType: chatHeaderData.type,
      allow_reply: chatHeaderData?.allow_reply
        ? chatHeaderData?.allow_reply
        : false,
      questionIndex: -1,
      reviewId: chatHeaderData.reviewId,
      expert_dict: this.props.expertDict || "",
      is_answered: chatHeaderData?.is_answered,
      shareObj: {},
      showTyping: false,
      fileUri: null,
      uploadedDocName: null,
      lawFirms: chatHeaderData.parent_org,
      userName: "",
    };
  }

  onStarRatingPress = (rating) => {
    if (!this.state.is_resolved && !this.state.resolveValue) {
      alert("Cannot give a review untill your question is resolved!");
      return;
    }
    this.setState({
      starCount: rating,
    });
    if (rating === 0) {
      SweetAlert.success("Rate between 1 and 5 stars")
    } else {
      const url = `/users/${this.state.UserId}/log_actions/new_discussion_review`;
      const headers = {
        Accept: "application/json",
        Authorization: this.state.Token,
      };
      const body = {
        rating: rating,
        review_comments: "",
        chat_id: this.state.messageId,
        platform_id: this.state.adviceType.toLowerCase() === "lawyer" ? 1 : 2,
      };
      Network(url, "POST", body, headers);
    }
  };

  dialogopen() {
    this.setState({ dialogclose: true });
  }

  componentWillMount() {
    this.getData();
    setTimeout(() => {
      this.MessageList();
      this.setState({
        resolveValue: this.state.is_resolved,
        reviewConsultantModal:
          this.state.reviewId == null && this.state.is_resolved,
      });
      this.getUserReview();
      this.checkMilestone();
      this.getOccupationsList();
      // this.triggerSaleBot()
    }, 1500);

    if (this.state.auto_res) {
      this.setState({ showTyping: true });
      setTimeout(() => {
        this.postChatHistory(null, null, null, false);
      }, 3500);
    }
  }

  getUserReview = async (id) => {
    if (this.state.reviewId || id) {
      const url = `/users/${this.state.UserId}/reviews/chat/${id ? id : this.state.reviewId
        }`;
      const headers = {
        Accept: "application/json",
        Authorization: this.state.Token,
      };
      Network(url, "GET", null, headers).then((res) => {
        if (res.status === "Success") {
          this.setState({
            starCount: res.review.rating,
            comment: res.review.review_comments,
            postReview: false,
            updateReview: true,
          });
        }
      });
    }
  };

  async componentDidMount() {
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleBackPress
    );
  }

  componentWillUnmount() {
    this.backHandler.remove();
  }

  handleBackPress = () => {
    this.goBack(); // works best when the goBack is async
    return true;
  };

  goBack = () => {
    // Actions.ChannelMainScreen({ tabId: "1" });
    this.props.navigation.pop();
  };

  getData = async () => {
    try {
      const value = await AsyncStorage.getItem("@auth_token");
      const splitToken = value.split("@");
      const userId = splitToken[0];
      const userName = await AsyncStorage.getItem("@user_name");
      this.setState({
        UserId: userId,
      });
      if (value !== null) {
        this.setState(
          {
            Token: value,
            UserId: userId,
            userName: userName,
          },
          () => { }
        );
      }
    } catch (error) {
      // Error retrieving data
    }
  };
  callAddMilestone = async () => {
    const body = {
      type: "reward",
      milestone: "ask-question",
      amount: 100,
      statement: "Ask a question",
    };
    this.props.dispatch(immiBuckAdd(body, this.state.UserId, this.state.Token));
  };
  checkMilestone = async () => {
    const url = this.state.UserId + "/check_milestone";
    const headers = {
      Accept: "application/json",
      Authorization: this.state.Token,
    };
    const body = {
      milestone: "ask-question",
    };
    Network(apiPaths.checkRewardMilestone + url, "POST", body, headers).then(
      (res) => {
        if (res.status === "Success" && res.is_milestone === false) {
          this.setState({ showRewardModal: true });
          this.callAddMilestone();
        }
      }
    );
  };

  triggerSaleBot = async () => {
    const headers = {
      Accept: "application/json",
      Authorization: this.state.Token,
    };
    // Accommodation, Travel Preparation, Admissibility, Application Process, :Eligibility, Adaptation, Others
    // const url = `/data/get_sales_bot/study/work_opportunities`;
    let subCategory = this.props.chatHeaderData?.sub_category?.split(" ")
      .map((s) => s.toLowerCase())
      .join("_");
    const url = `/data/get_sales_bot/${this.state.category}/${subCategory}`;
    let response = await Network(url, "GET", null, headers);
    const { data, status } = response;
    data.forEach((s, i) => {
      (s.type = "question"), (s.questionIndex = i);
    });
    if (data?.length > 0 && data?.length == this.state.questionIndex) {
      let obj = {
        type: "question",
        question:
          "Thank you for the responses. An expert will get in touch shortly.",
      };
      this.setState({
        messageList: this.state.messageList.concat([obj]),
        showTyping: false,
      });
      this.postChatHistory(
        `${obj.question}`,
        "Immigreat Support",
        null,
        null,
        true
      );
    }
    if (status == "Success") {
      if (
        data[this.state.questionIndex]?.response_type == "integer" ||
        data[this.state.questionIndex]?.response_type == "string"
      ) {
        this.setState({
          allow_reply_to_bot: true,
          replyToBot: true,
          botquestion: data[this.state.questionIndex].question,
        });
      }
      setTimeout(() => {
        this.setState({ showTyping: false, })
        this.setState({
          messageList: this.state.messageList.concat(
            data[this.state.questionIndex]
          ),
        });
      }, 1500);
    }
  };

  getOccupationsList = async () => {
    const headers = {
      Accept: "application/json",
      Authorization: this.state.Token,
    };
    let response = await Network(
      "/data/get_dropdown_options",
      "GET",
      null,
      headers
    );
    const { occupations } = response;
    let temp = [];
    occupations?.length > 0 &&
      occupations.forEach((s, i) => {
        let Obj = {
          name: s,
          id: i,
        };
        temp.push(Obj);
      });
    this.setState({ occupations: temp });
  };

  postChatHistory = async (chatRes, chat_id, fileDownloadUrl, useranswer, callMessage) => {
    // this.setState({ showTyping: true });
    const url = `/users/${this.state.UserId}/chat_history/${this.state.messageId}`;
    const headers = {
      Accept: "application/json",
      Authorization: this.state.Token,
    };
    let chat_identifier = chat_id
      ? chat_id
      : this.state.auto_res.chat_identifier;
    let chatResponse = chatRes ? chatRes : this.state.auto_res?.response;
    const body = {
      chat_identifier: `Advice Support - ${chat_identifier}`,
      chat_text: chatResponse,
      firebase_ref: this.state.auto_res?.fileDownloadUrl
        ? this.state.auto_res.fileDownloadUrl
        : null,
      chat_initiator: "support",
    };
    try {
      return Network(url, "POST", body, headers).then((res) => {
        if (res.status === "Success") {
          if (this.state.auto_res && chatRes == null) {
            localArr = [];
            localArr.push({
              text: `[Advice Support - ${this.state.auto_res.chat_identifier}] ${chatResponse}`,
              chat_type: 2,
              firebase_ref: this.state.auto_res?.fileDownloadUrl
                ? this.state.auto_res.fileDownloadUrl
                : null,
              auto_res: true,
              seen_status: false,
            });
            this.setState({
              messageList: this.state.messageList.concat(localArr),
              showTyping: false,
            });
          }
          this.state.questionIndex == -1 &&
            this.setState(
              {
                questionIndex: this.state.questionIndex + 1,
                showTyping: false,
              },
              () => {
                this.triggerSaleBot();
              }
            );
          return Promise.resolve(res.status)
        } else {
          this.setState({ showTyping: false, isLoading: false });
        }
      });
    } catch (error) {
      this.setState({ showTyping: false, isLoading: false });
    }
  };

  MessageList = (id) => {
    this.setState({ isLoading: true });
    const url = `/users/${this.state.UserId}/chat_history/${id ? id : this.state.messageId
      }`;
    const headers = {
      Accept: "application/json",
      Authorization: this.state.Token,
    };

    Network(url, "GET", null, headers).then((res) => {
      if (res.status === "Success") {
        this.setState(
          {
            messageList: res.data.chat_log,
          },
          () => { }
        );
        this.state.getAskedData(false);
      }
      this.setState({ isLoading: false });
    });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.messageId !== prevProps.messageId) {
      this.setState({ messageId: this.props.messageId });
      this.MessageList(this.props.messageId);
      this.getUserReview(this.props.chatHeaderData?.reviewId);
      this.props?.auto_res?.callchatHistoryApi &&
        this.postChatHistory(
          this.props?.auto_res?.response,
          this.props.messageId,
          this.props?.auto_res?.fileDownloadUrl,
          false
        );
    }
    if (this.props.chatHeaderData !== prevProps.chatHeaderData) {
      this.getUserReview(this.props.chatHeaderData?.reviewId);
      this.setState({
        resolveValue: this.props.chatHeaderData?.chatHeaderData?.is_resolved,
        questionIndex: -1
      });
    }
  };

  resolveChat = () => {
    this.setState({ resolveValue: true, showTyping: true, showAlertModal: false });
    const url = `${this.state.UserId}/chat_history/${this.state.messageId}/close`;
    const headers = {
      Accept: "application/json",
      Authorization: this.state.Token,
    };
    Network(apiPaths.resolveChat + url, "GET", null, headers).then(
      (res) => {
        if (res.status == "Success") {
          var today = new Date();
          var str = today.toGMTString();
          this.postChatHistory(
            res.response,
            res.chat_identifier,
            null,
            false
          );
          localArr = [];
          localArr.push({
            text: `[Advice Support - ${res.chat_identifier}] ${res.response}`,
            chat_type: 2,
            auto_res: true,
            timestamp: str,
            seen_status: false,
          });
          setTimeout(() => {
            this.setState({
              messageList: this.state.messageList.concat(localArr),
              showTyping: false,
            });
          }, 2000);
          this.setState({ reviewConsultantModal: true });
          this.state.getAskedData(false);
        }
      }
    );
  };

  handleFollowUpQuestion = (response, ques) => {
    let localArr = [];
    var today = new Date();
    var str = today.toGMTString();
    localArr.push({
      chat_type: 1,
      content: "profile",
      text: `[${this.state.userName}]` + " " + ques,
      timestamp: str,
      seen_status: true,
    });
    this.setState(
      {
        messageList: this.state.messageList.concat(localArr),
        Message: "",
      },
      () => {
        this.downButtonHandler();
      }
    );

    this.setState({ showTyping: true });

    localArr.push({
      text: `[Advice Support - ${response.chat_identifier}] ${response.response}`,
      chat_type: 2,
      firebase_ref: response?.fileDownloadUrl ? response.fileDownloadUrl : null,
      auto_res: true,
      seen_status: false,
    });

    setTimeout(() => {
      this.setState({
        messageList: this.state.messageList.concat(localArr),
        showTyping: false,
      });
    }, 1000);
  };

  Message_Send = async (docUrl, message, call) => {
    logEvent("chat_message_sent", {});
    let _message = message ? message : this.state.Message.trim();
    if (this.state.replyToBot) {
      let res = await this.postChatHistory(
        `${this.state.botquestion}`,
        "Immigreat Support",
        null,
        _message,
        true
      );
      if (res == 'Success') {
        this.setState({ replyToBot: false, allow_reply_to_bot: false });
        this.Message_Send(null, message, call);
      }
      return;
    }
    if (_message === "" && !_message) {
      //Alert.alert("", "Please enter message", [{ text: "OK" }], {
      //  cancelable: false
      //});
    } else {
      Keyboard.dismiss();
      this.setState({ isLoading: true, showTyping: true });
      const url = `/users/${this.state.UserId}/chat_history/${this.state.messageId}`;
      const headers = {
        Accept: "application/json",
        Authorization: this.state.Token,
      };
      const body = {
        chat_identifier: this.state.userName,
        chat_initiator: "mobile",
        firebase_ref: docUrl ? docUrl : null,
        chat_text: _message,
      };
      Network(url, "POST", body, headers)
        .then((res) => {
          if (res.status === "Success" || res.status === "Success") {
            let localArr = [];
            var today = new Date();
            var str = today.toGMTString();
            localArr.push({
              chat_type: 1,
              content: "profile",
              text: `[${this.state.userName}]` + " " + _message,
              timestamp: str,
              firebase_ref: docUrl ? docUrl : null,
              seen_status: true,
            });
            this.setState(
              {
                messageList: this.state.messageList.concat(localArr),
                Message: "",
                isLoading: false,
              },
              () => {
                this.downButtonHandler();
              }
            );
            this.setState(
              {
                questionIndex: this.state.questionIndex + 1,

              },
              () => {
                setTimeout(() => {
                  this.triggerSaleBot();
                  this.setState({ showTyping: true })
                }, 1000);
              }
            );
          } else {
            this.setState({ isLoading: false, showTyping: false });
            alert("Message sending Failed!");
          }
        })
        .catch((e) => {
          this.setState({ isLoading: false, showTyping: false });
        });
    }
  };

  downButtonHandler = () => {
    //OnCLick of down button we scrolled the list to bottom
    // this.ListView_Ref.scrollToBottom({ animated: true });
  };

  dateSplit = (date) => {
    const daySpilt = date;
    const timeReamin = daySpilt.split(", ");
    if (timeReamin && timeReamin[1]) {
      const dateTime = timeReamin[1]?.split("GMT");
      const finalDate = dateTime[0]?.split(" ");
      return finalDate[0] + " " + finalDate[1] + " " + finalDate[2];
    }
  };

  timeSplit = (time) => {
    let utcTime = time;
    let now = moment.utc(utcTime).local().format("h:mm a");
    return now;
  };

  getFIleIcon = (file) => {
    let type = file?.split(".")[1];
    let image = "";
    switch (type) {
      case "pdf":
        image = Images.pdf;
        break;
      case "txt":
        image = Images.txt;
        break;
      case "xls":
        image = Images.xls;
        break;
      default:
        image = Images.defaultfileicon;
        break;
    }
    return image;
  };

  handleClose = () => {
    this.setState({ showRewardModal: false });
    this.getMilestones();
  };

  getImmigrationInterestType = (code) => {
    if (code == 1) {
      return "Study";
    } else if (code == 2) {
      return "Work";
    } else if (code == 3) {
      return "Permanent Residency";
    } else {
      return "Tourism";
    }
  };

  uploadFileToFirebase = async () => {
    if (Platform.OS == "web") {
      const storage = getStorage();
      try {
        const storageRef = ref(
          storage,
          `adviceSupportingDoc/${userDetails?.userid}/${this.state.ref_id}/${uploadedDocName}`
        );
        uploadBytes(storageRef, uploadedFile).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((downloadURL) => { });
        });
      } catch (error) {
        console.log("errr", error);
      }
    } else {
      var storageRef = storage().ref(
        `/adviceSupportingDoc/${this.state.UserId}/${this.state.ref_id}/${this.state.uploadedDocName}`
      );
      storageRef
        .putFile(this.state.fileUri.uri)
        .on(storage.TaskEvent.STATE_CHANGED, (snapshot) => {
          if (snapshot.state === storage.TaskState.SUCCESS) {
            storage()
              .ref(`${storageRef.path}`)
              .getDownloadURL()
              .then((url) => {
                this.Message_Send(url, null, true);
              });
          }
        });
    }
  };

  uploadDocument = async () => {
    try {
      let input = document.createElement("input");
      input.type = "file";
      input.accept = ".xls,.xlsx, .pdf, .txt";
      input.onchange = async () => {
        // you can use this method to get file and perform respective operations
        let files = Array.from(input.files);
        const imageDataUrl = await readFile(files[0]);
        setuploadedFile(files[0]);
        setuploadedDocName(files[0]?.name);
      };
      Err["uploadedDocName"] = false;
      input.click();

    } catch (err) {
      throw err;
    }
  };

  handleProfileModalOpen = () => {
    let userDetails = this.props.getUser.userDetails;
    let countryInterest = "";
    let ImmiInterest = "";
    if (userDetails?.countries_of_interest) {
      let countryName = [];
      userDetails?.countries_of_interest.forEach((s) => {
        countryName.push(helpers.getCountryName(s));
      });
      countryInterest = countryName.join(", ");
    }
    if (userDetails.immigration_interests) {
      let countryName = [];
      userDetails.immigration_interests.forEach((s) => {
        countryName.push(this.getImmigrationInterestType(s));
      });
      ImmiInterest = countryName.join(", ");
    }
    let Obj = {
      Name: userDetails.first_name + " " + userDetails.last_name,
      Email: userDetails.email_address,
      Gender: userDetails.sex ? userDetails.sex : null,
      "Date Of Birth": userDetails.date_of_birth
        ? userDetails.date_of_birth
        : null,
      "Country of birth": userDetails.country_of_birth
        ? userDetails.country_of_birth
        : null,
      "Country of Interest": userDetails?.countries_of_interest
        ? countryInterest
        : null,
      "Immigration Interest": userDetails.immigration_interests
        ? ImmiInterest
        : null,
      Nationality: userDetails.country_of_citizenship1
        ? userDetails.country_of_citizenship1
        : null,
      "2nd Nationality": userDetails.country_of_citizenship2
        ? userDetails.country_of_citizenship2
        : null,
      "Highest academic achievement": userDetails.highest_academic_achievement
        ? userDetails.highest_academic_achievement
        : null,
      Occupation: userDetails.occupation ? userDetails.occupation : null,
      "Preferred Language": userDetails.pref_language
        ? userDetails.pref_language
        : null,
      "Marital Status": userDetails.marital_status
        ? userDetails.marital_status
        : null,
    };
    this.setState({ shareObj: Obj });
    setTimeout(() => {
      this.setState({ profileModalVisible: true });
    }, 200);
  };

  shareProfile = () => {
    // for (var key of Object.keys(Obj)) {
    //   if(Obj[key] !== null){
    //     if(key == '2nd Nationality'){
    //       str = key +': '+ helpers.getCountryName(Obj[key])
    //     }else if(key == 'Country of Interest' || key == 'Immigration Interest'){
    //       let countryName = [];
    //       Obj[key].forEach((s) => countryName.push(helpers.getCountryName(s)))
    //         str = key +': '+ countryName.join(',')
    //     }else{
    //       str = key +': '+ Obj[key]
    //     }
    //     strArr.push(str)
    //   }
    // }
    this.setState({ Message: `[user_profile]`, showActionView: false }, () => {
      this.Message_Send(null, null, null);
    });
  };

  renderChat = ({ item, _category, agentImage, agentName }) => {

    return (
      <>
        {item?.type == "question" && (
          <QuestionItem
            item={item}
            occupations={this.state.occupations}
            ref_id={this.state.ref_id}
            botCategory={botsInfo[_category]?.for}
            botName={botsInfo[_category]?.name}
            Message_Send={this.Message_Send}
            botIcon={botsInfo[_category]?.icon}
            enableTextBox={this.enableTextBox}
            postChatHistory={this.postChatHistory}
          />
        )}
        {item?.seen_status === true && (
          <View
            style={{ marginBottom: 3, marginTop: 10, padding: 0 }}
          >
            <TouchableHighlight underlayColor={"#EAF5FF"}>
              <View style={styles.flatview}>
                <View
                  style={{
                    flexDirection: "row",
                    alignSelf:
                      item?.chat_type != 1
                        ? "flex-start"
                        : "flex-end",
                  }}
                >
                  <View>
                    {item &&
                      item?.text &&
                      item?.text?.split("] ")[1] ==
                      "[user_profile]" ? (
                      <View style={{ flexDirection: "row" }}>
                        <View style={styles.shareProfileMsgView}>
                          <TouchableOpacity
                            onPress={() =>
                              this.handleProfileModalOpen()
                            }
                            style={styles.shareProfileView}
                          >
                            <Image
                              source={Images.chatProfile}
                              style={{ height: 40, width: 40 }}
                            />
                          </TouchableOpacity>
                          <Text
                            style={{
                              alignSelf: "center",
                              fontSize: 13,
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          >
                            Profile
                          </Text>
                        </View>
                      </View>
                    ) : (
                      <>
                        <View
                          style={{
                            width:
                              Platform.OS == "web" &&
                                !mobilBrower()
                                ? widthPercentageToDP("20%")
                                : width / 1.3,
                            borderRadius: 10,
                            borderTopRightRadius:
                              item.chat_type != 1 ? 10 : 0,
                            borderTopLeftRadius:
                              item.chat_type != 1 ? 0 : 10,
                            elevation: 12,
                            borderWidth:
                              Platform.OS == "android" ? 1 : 0,
                            shadowOffset: { width: 1, height: 1 },
                            shadowRadius: 2,
                            shadowOpacity: 0.5,
                            shadowColor: "black",
                            backgroundColor:
                              item.chat_type == 1
                                ? "#0F1143"
                                : "#FFFFFF",
                            marginLeft: 10,
                          }}
                        >
                          <View style={{ padding: 15 }}>
                            {item.chat_type != 1 &&
                              botsInfo[_category] &&
                              item.text.includes(
                                "Advice Support -"
                              ) ? (
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "flex-end",
                                  marginBottom: 5,
                                }}
                              >
                                {
                                  item.text.split('-')[1]?.includes('Immigreat Support') &&
                                  <Image
                                    style={{
                                      height: 30,
                                      width: 30,
                                    }}
                                    resizeMode="contain"
                                    source={
                                      !item.text.split('-')[1]?.includes('Immigreat Support') ?
                                        { uri: agentImage } : botsInfo[_category]?.icon
                                    }
                                  />
                                }
                                <Text
                                  style={{
                                    fontSize:
                                      heightPercentageToDP(
                                        "1.7%"
                                      ),
                                    fontFamily:
                                      family.titilliumBold,
                                    color: "black",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {
                                    !item.text.split('-')[1]?.includes('Support') ? agentName : botsInfo[_category]
                                      ?.name
                                  }
                                </Text>
                                {
                                  item.text.split('-')[1]?.includes('Support') &&
                                  <Text
                                    style={{
                                      fontSize:
                                        heightPercentageToDP(
                                          "1.6%"
                                        ),
                                      marginTop:
                                        heightPercentageToDP(
                                          "0.4%"
                                        ),
                                      fontFamily: family.regular,
                                      color: "gray",
                                    }}
                                  >
                                    {/* // 123 */}
                                    {", the " +
                                      botsInfo[_category]
                                        ?.for +
                                      " bot "}
                                  </Text>
                                }
                              </View>
                            ) : null}
                            <Text
                              style={{
                                color:
                                  item.chat_type == 1
                                    ? "#FFFFFF"
                                    : "black",
                                fontSize:
                                  heightPercentageToDP("1.8%"),
                              }}
                            >
                              {item &&
                                item.text &&
                                item.text?.split("] ")[1]}
                            </Text>
                            {item.firebase_ref && (
                              <TouchableOpacity
                                onPress={() =>
                                  Linking.openURL(
                                    item.firebase_ref?.split(
                                      ">>"
                                    )[0]
                                  )
                                }
                                style={{
                                  marginTop: 20,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginLeft: 10,
                                }}
                              >
                                {
                                  <>
                                    <Image
                                      source={this.getFIleIcon(
                                        item.firebase_ref?.split(
                                          ">>"
                                        )[1]
                                      )}
                                      style={{
                                        height: 40,
                                        width: 40,
                                        resizeMode: "contain",
                                      }}
                                    />
                                    <Text
                                      style={{ marginTop: 5 }}
                                    >
                                      {
                                        item.firebase_ref?.split(
                                          ">>"
                                        )[1]
                                      }
                                    </Text>
                                  </>
                                }
                              </TouchableOpacity>
                            )}
                          </View>
                          <Text
                            style={[
                              styles.time,
                              {
                                alignSelf:
                                  item.chat_type == 1
                                    ? "flex-end"
                                    : "flex-start",
                                marginLeft:
                                  item.chat_type == 1 ? 0 : 15,
                                marginRight:
                                  item.chat_type == 1 ? 15 : 0,
                              },
                            ]}
                          >
                            {`${this.dateSplit(
                              item.timestamp
                            )}, ${this.timeSplit(
                              item.timestamp
                            )}`}
                          </Text>
                        </View>
                      </>
                    )}
                  </View>
                  {item &&
                    item.chat_type == 1 &&
                    item.text &&
                    item.text?.split("] ")[1] !==
                    "[user_profile]" ? (
                    <View
                      style={{
                        alignSelf: "center",
                        marginLeft: 10,
                        justifyContent: "center",
                      }}
                    ></View>
                  ) : (
                    <View
                      style={{
                        alignSelf: "center",
                        marginLeft: 10,
                        justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          alignSelf: "center",
                          width: 45,
                          height: 45,
                          borderRadius: 22.5,
                        }}
                      />
                    </View>
                  )}
                </View>
              </View>
            </TouchableHighlight>
          </View>
        )}
        {item?.seen_status === false && (
          <View
            style={{
              marginBottom: mobilBrower() ? 10 : 10,
              marginTop: mobilBrower() ? 10 : 10,
              padding: 0,
            }}
          >
            <TouchableHighlight underlayColor={"#EAF5FF"}>
              <View style={styles.flatview}>
                <View
                  style={{
                    flexDirection: "row",
                    alignSelf:
                      item.chat_type == 1
                        ? "flex-end"
                        : "flex-start",
                  }}
                >
                  <View>
                    <View
                      style={{
                        width:
                          Platform.OS == "web"
                            ? "40%"
                            : width / 1.3,
                        borderRadius: 5,
                        borderTopRightRadius:
                          item?.chat_type != 1 ? 20 : 0,
                        borderTopLeftRadius:
                          item?.chat_type != 1 ? 0 : 20,
                        elevation: 12,
                        borderWidth:
                          Platform.OS == "android" ? 1 : 0,
                        shadowOffset: { width: 1, height: 1 },
                        shadowRadius: 1,
                        shadowOpacity: 0.5,
                        shadowColor: "black",
                        backgroundColor:
                          item?.chat_type == 1
                            ? "#0F1143"
                            : "#FFFFFF",
                        marginLeft: 10,
                      }}
                    >
                      <View style={{ padding: 15 }}>
                        {item.chat_type != 1 &&
                          item?.text?.includes(
                            "Advice Support -"
                          ) ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-end",
                              marginBottom: 5,
                            }}
                          >
                            <Image
                              style={{
                                height: 30,
                                width: 30,
                              }}
                              resizeMode="contain"
                              source={
                                botsInfo[_category]?.icon
                              }
                            />
                            <Text
                              style={{
                                fontSize:
                                  heightPercentageToDP("1.7%"),
                                fontFamily: family.titilliumBold,
                                color: "black",
                                textTransform: "uppercase",
                                marginBottom: 5,
                              }}
                            >
                              {botsInfo[_category]?.name}
                            </Text>
                            <Text
                              style={{
                                fontSize:
                                  heightPercentageToDP("1.6%"),
                                marginTop:
                                  heightPercentageToDP("0.4%"),
                                fontFamily: family.regular,
                                color: "gray",
                              }}
                            >
                              {", the " +
                                botsInfo[this.state.category == 'legal' ? this.props.chatHeaderData?.sub_category?.split(' ')[0]?.toLowerCase() : this.state.category]
                                  ?.for +
                                " bot "}
                            </Text>
                          </View>
                        ) : null}
                        <Text
                          style={{
                            color:
                              item.chat_type == 1
                                ? "#FFFFFF"
                                : "black",
                            fontSize: 13,
                          }}
                        >
                          {item &&
                            item?.text &&
                            item?.text?.split("] ")[1]}
                        </Text>
                      </View>
                      {item.timestamp ? (
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            alignSelf: "flex-start",
                          }}
                        >
                          <Text style={styles.time}>
                            {this.dateSplit(item.timestamp)}
                          </Text>
                          <Text style={styles.time}>
                            {this.timeSplit(item.timestamp)}
                          </Text>
                        </View>
                      ) : (
                        <View />
                      )}
                    </View>
                    {item.firebase_ref && (
                      <TouchableOpacity
                        onPress={() =>
                          Linking.openURL(
                            item.firebase_ref?.split(">>")[0]
                          )
                        }
                        style={{
                          marginTop: 20,
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: 10,
                        }}
                      >
                        {
                          <>
                            <Image
                              source={this.getFIleIcon(
                                item.firebase_ref?.split(">>")[1]
                              )}
                              style={{
                                height: 40,
                                width: 40,
                                resizeMode: "contain",
                              }}
                            />
                            <Text style={{ marginTop: 5 }}>
                              {item.firebase_ref?.split(">>")[1]}
                            </Text>
                          </>
                        }
                      </TouchableOpacity>
                    )}
                  </View>
                  {item.chat_type == 1 ? (
                    <View
                      style={{
                        alignSelf: "center",
                        marginLeft: 10,
                        justifyContent: "center",
                      }}
                    ></View>
                  ) : (
                    <View />
                  )}
                </View>
              </View>
            </TouchableHighlight>
          </View>
        )}
      </>
    )
  }

  renderAgentInfo = ({ agentName, agentImage, rating, changeRating }) => (
    <View style={styles.topView}>
      <View style={styles.topView_title}>
        <Image source={agentImage ? { uri: agentImage } : Images.dummyImg} style={styles.imgView} />
        <View style={{ alignItems: 'flex-start', marginBottom: 10 }}>
          <Text style={styles.topView_name}>{agentName}</Text>
          <View style={styles.ratingView}>
            <Text style={styles.ratingText}>Ratings :</Text>
            <StarRating
              disabled={false}
              numberOfStars={5}
              starDimension="17px"
              starRatedColor={"orange"}
              changeRating={changeRating}
              rating={rating}
            />
          </View>
        </View>
      </View>
    </View>)

  getMilestones = () => {
    const url = `/users/${this.state.UserId}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: this.state.Token,
    };
    this.props.dispatch(getImmibuckBalance(url, headers));
  };

  enableTextBox = (question, enable) => {
    this.setState({ botquestion: question });
  };

  setStarCount = (star) => {
    this.setState({ starCount: star });
  };

  onSubmit = () => {
    // Actions.ChannelMainScreen({ tabId: "1" });
    this.props.navigation.pop();
  };
  _profile = () => {
    // Actions.profile()
    this.props.navigation.navigate("NewProfileScreen");
  };
  render() {
    const agentName = this.state.expert_dict
      ? this.state.expert_dict?.alias
      : this.state.chatName;
    const avgRating =
      this.state.expert_dict && this.state.expert_dict?.avg_ratings;
    const agentImage =
      this.state.expert_dict?.profile_image ||
      this.state.discussion_platform?.profile_image;
    const type =
      this.state.adviceType === "lawyer" ? "Lawyer" : "Immigration Agent";
    let _category = this.state.category == 'legal' ? this.props.chatHeaderData?.sub_category?.split(' ')[0]?.toLowerCase() : this.state.category;
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS == "ios" ? "padding" : null}
        enabled
        style={{ flex: 1 }}
      >
        <View style={styles.container}>
          <SafeAreaView />
          {this.renderAgentInfo({
            agentImage,
            agentName,
            rating: this.state.messageList?.length ? (avgRating ? avgRating : this.state.discussion_platform?.avg_ratings) : this.state.starCount,
            changeRating: this.state.messageList?.length === 0 ? this.onStarRatingPress : null
          })}
          <View style={styles.chatView}>
            {!this.state.messageList ? (
              <Text style={styles.loadingText}>Loading Messages ...</Text>
            ) : (!this.state.messageList.length ? (
              <Text>No messages found</Text>
            ) : (
              <>
                <ReversedFlatList
                  style={{ marginTop: 15, width: "100%" }}
                  data={this.state.messageList}
                  extraData={[
                    this.state,
                    this.state.messageId,
                    this.state.auto_res,
                  ]}
                  keyExtractor={(item, index) => index.toString()}
                  ref={(ref) => {
                    this.ListView_Ref = ref;
                  }}
                  renderItem={({ item, index }) => {
                    return (
                      <View key={index + "c"}>
                        {this.renderChat({ item, _category, agentImage, agentName })}
                        {
                          index == 0 &&
                          <View style={styles.typingView}>
                            {this.state.showTyping && !this.state.allow_reply ? (
                              <View style={{ height: 30, left: 30 }}>
                                <TypingAnimation />
                              </View>
                            ) : (
                              <View />
                            )}
                          </View>
                        }
                      </View>
                    );
                  }}
                />
                {this.state.is_answered && (
                  <View style={styles.bottomButtonView}>
                    <TouchableOpacity
                      disabled={this.state.resolveValue}
                      onPress={() => this.setState({ followUpModal: true })}
                      style={[
                        styles.resolveButton,
                        {
                          marginLeft: 10,
                          backgroundColor: this.state.resolveValue
                            ? "grey"
                            : "#0F1143",
                        },
                      ]}
                    >
                      <Text style={styles.resolveButtonText}>
                        Follow up question{" "}
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      disabled={this.state.resolveValue}
                      onPress={() => this.setState({ showAlertModal: true })}
                      style={styles.resolveButton}
                    >
                      <Text style={styles.resolveButtonText}>
                        {this.state.resolveValue ? "Resolved " : "Resolve "}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
              </>))}
          </View>
          <View
            style={{
              marginBottom: 30,
              marginTop: 5,
              width: "90%",
              flexDirection: "row",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderRadius: Platform.OS === "web" ? 5 : 10,
                borderColor: "black",
                width: Platform.OS === "web" ? "95%" : "90%",
                justifyContent:
                  Platform.OS === "ios" ? "space-between" : "space-between",
                backgroundColor:
                  !this.state.allow_reply && !this.state.allow_reply_to_bot
                    ? "rgb(220,220,220)"
                    : "rgb(240,240,240)",
                alignSelf: "center",
                paddingTop: 5,
                paddingBottom: 5,
                alignItems: "center",
                flexDirection: "row",
                marginRight: "5%",
                paddingLeft: 10,
              }}
            >
              <TextInput
                editable={
                  this.state.resolveValue ||
                    (!this.state.allow_reply &&
                      !this.state.allow_reply_to_bot)
                    ? false
                    : true
                }
                style={[styles.messageInput]}
                placeholder={"Message"}
                placeholderTextColor="#5D5E5D"
                multiline={true}
                returnKeyType={"default"}
                autoCapitalize={"none"}
                underlineColorAndroid={"transparent"}
                onChangeText={(text) => this.setState({ Message: text })}
                value={this.state.Message}
              />
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View
                  style={{
                    marginLeft: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    height: mobilBrower() ? 35 : 25,
                    flexDirection: "row",
                    backgroundColor: "#030135",
                    width: mobilBrower() ? 35 : 25,
                    marginRight: 10,
                    borderRadius: mobilBrower() ? 35 / 2 : 12.5,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => this.Message_Send(null, null, true)}
                    style={{}}
                    disabled={
                      this.state.resolveValue ||
                      (!this.state.allow_reply &&
                        !this.state.allow_reply_to_bot)
                    }
                  >
                    <Image
                      style={styles.sendButton}
                      source={Images.send}
                    />
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    marginLeft: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    height: mobilBrower() ? 35 : 25,
                    flexDirection: "row",
                    backgroundColor: "#030135",
                    width: mobilBrower() ? 35 : 25,
                    marginRight: 10,
                    borderRadius: mobilBrower() ? 35 / 2 : 12.5,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => this.uploadDocument()}
                    style={{}}
                    disabled={
                      this.state.resolveValue || !this.state.allow_reply
                    }
                  >
                    <Image
                      style={styles.sendButton}
                      source={Images.attachment}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              <TouchableOpacity
                disabled={this.state.resolveValue ? true : false}
              >
                <Image source={Images.more} style={{ height: 20, width: 20 }} />
              </TouchableOpacity>
            </View>
            {this.state.showActionView ? (
              <View style={styles.actionView}>
                <TouchableOpacity
                  onPress={() => this.shareProfile()}
                  disabled={
                    this.state.resolveValue || !this.state.allow_reply
                  }
                  style={styles.sendProfileButton}
                >
                  <Text style={styles.sendProfileText}>
                    Share Your Profile
                  </Text>
                </TouchableOpacity>
              </View>) : null}
          </View>
        </View>
        <RewardModal
          visible={this.state.showRewardModal}
          title="Congrats on your first question, we hope you find your answer soon!"
          text={`Here’s a small reward for you`}
          price={100}
          onClosed={() => this.handleClose()}
        />
        {this.state.profileModalVisible ? (
          <ShareProfileModal
            visible={this.state.profileModalVisible}
            userObj={this.state.shareObj}
            handleClose={() => this.setState({ profileModalVisible: false })}
          />
        ) : (
          <View />
        )}
        {this.state.reviewConsultantModal && (
          <ReviewConsultant
            chat_id={this.state.messageId}
            agentName={agentName}
            setStarCount={this.setStarCount}
            expert_id={this.state.expert_id}
            onClose={() => this.setState({ reviewConsultantModal: false })}
          />
        )}
        {this.state.followUpModal && (
          <FollowUpQuestionModal
            category={this.state.category}
            follow_up_data={this.state.follow_up_data}
            chat_id={this.state.messageId}
            query_count={this.state.query_count}
            handleFollowUpQuestion={this.handleFollowUpQuestion}
            advice_package={this.state.advice_package}
            onClose={() => this.setState({ followUpModal: false })}
          />
        )}
        {this.state.showAlertModal ?
          <AlertModal
            title="Are you sure ?"
            description="you want to mark the conversation as complete, You will not be able to continue the conversation after that."
            cancelText="Cancel"
            onCancelPress={() => this.setState({ showAlertModal: false })}
            onConfirmPress={this.resolveChat}
            confirmText="Yes"
          /> : null}
      </KeyboardAvoidingView>
    )
  }
}

const mapStateToProps = (state) => ({
  profilePic: state.authReducer.getUserProfilePic.profilePic,
  getUser: state.userReducer.getUser,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageChat);
