import AsyncStorage from '@react-native-community/async-storage';
import analytics from '@react-native-firebase/analytics';
import { Platform } from 'react-native';

Platform.OS == 'web' ? "" : analytics().setAnalyticsCollectionEnabled(true);

export const firebaseEvents = {
    SAVE_NEWS: "save_news",
    SAVE_STORY: "save_story",
    REACTION_NEWS: "reaction_news",
    REACTION_STORY: "reaction_story",
    PLAY_VIDEO: "play_video",
    CLICK_NEWS: "click_news",
    CLICK_STORY: "click_story",
    SIGN_UP: "sign_up",
    OPEN_BULLETIN: "open_bulletin",
    QUICK_SEARCH: "quick_search",
    CITY_FACTS_COMPARATOR: "city_facts_comparator",
    PR_POINTS: "pr_points",
    IMMIPATH_SEARCH: "immipath_search",
    UNIVERSITY_SEARCH: "university_search",
    LOGOUT: "logout",
    VIEW_ABOUTUS: "view_aboutus"
}

export const logEvent = async (eventType, data) => {
    try {
        if (!global.IS_TEST_SERVER) {
            return;
        }
        const value = await AsyncStorage.getItem("@auth_token");
        if (value) {
            const splitToken = value.split("@");
            data["user_id"] = splitToken[0];
            data["token"] = splitToken[1];
        }
        const username = await AsyncStorage.getItem("@user_name");
        if (username) {
            data["user_name"] = username;
        }

        Platform.OS == 'web' ? "" : await analytics().logEvent(eventType, data);
    } catch (error) {
        console.log("Error trying to log for event: " + eventType, error);
    }
};
