import { StyleSheet } from "react-native";
import { family } from "../../../../common";
import fontFamily from "../../../../common/fontFamily";

export const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "90%",
  },
  nextWrapper: {
    backgroundColor: "#F1AB32",
    padding: 12,
    borderRadius: 25,
    width: 160,
    marginTop: 10,
  },
  nextText: {
    color: "white",
    fontFamily: family.titilliumBold,
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 17,
  },
  textT: {
    fontFamily: family.titilliumBold,
    fontSize: 25,
    textAlign: "center",
    width: "80%",
    lineHeight: 55,
    marginBottom: 20,
    marginTop: -25,
  },
  qWrapper: {
    width: "88%",
    marginLeft: "6%",
    marginBottom: 15,
  },
  errorView: {
    borderWidth: 0.5,
    borderColor: 'red'
  },
  disabled: {
    backgroundColor: '#BDBDBD',
  },
  quesText: {
    marginBottom: 10,
    fontWeight: "bold",
    marginLeft: 2,
  },
  qTextInput: {
    width: "100%",
    backgroundColor: "#F5F6F8",
    padding: 10,
    borderRadius: 10,
    marginBottom: 15,
  },
  questionView: {
    width: "95%",
    marginBottom: 25,
    alignSelf: "center",
  },
  questionText: {
    fontFamily: fontFamily.robotoBlack,
    zIndex: -1999999,
  },
  selectedAnswerView: {
    width: "100%",
    padding: 15,
    flexDirection: "row",
    zIndex: -1999999,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F5F6F8",
    borderRadius: 10,
  },
  downArrow: {
    height: 18,
    width: 18,
    marginRight: 10,
    resizeMode: "contain",
  },
  optionsText: {
    fontFamily: fontFamily.robotoBold,
    padding: 10,
  },

  optionDropDown: {
    width: "100%",
    marginTop: 5,
    borderRadius: 10,
    padding: 8,
    backgroundColor: "#E7E7E7",
    overflow: "hidden",
  },
  separator: {
    borderColor: "rgba(1,1,1, 0.2)",
    borderBottomWidth: 0.25,
  },
  checkWrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  radio: {
    width: 15,
    height: 15,
    borderWidth: 2,
    borderColor: "gray",
    borderRadius: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
  },
  selected: {
    width: 8,
    height: 8,
    backgroundColor: "green",
    borderRadius: 5,
  },

  sep: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  sLine: {
    width: 20,
    backgroundColor: "gray",
    height: 1.6,
    marginLeft: 5,
    marginRight: 5,
  },
});
