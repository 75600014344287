import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./auth.reducer";
import userReducer from "./user.reducer";
import pathReducer from "./path.reducer";
import factReducer from "./fact.reducer";
import timelineReducer from "./timeline.reducer";
import referralReducer from "./referral.reducer";
import eligibilityReducer from "./eligibility.reducer";
import immibucksReducer from "./immibucks.reducers";
import appStateReducer from "./app.reducer";
import mainMediaReducer from "./mainMedia.reducer";
import adviceChannelReducer from "./adviceChannel.reducer";
import dashReducer from "./dashboard.reducer";
import copilotStateReducer from "./CopilotStates.reducer";
import discussion from "./discussion.reducer";

const reducers = {
  authReducer,
  userReducer,
  pathReducer,
  form: formReducer,
  factReducer,
  copilotStateReducer,
  timelineReducer,
  referralReducer,
  eligibilityReducer,
  immibucksReducer,
  appStateReducer,
  discussion,
  mainMediaReducer,
  adviceChannelReducer,
  dashReducer
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  //console.log("state", state);
  if (action.type === "USER_LOGGED_OUT_SUCCESS") {
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
