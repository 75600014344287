import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Animated,
  Vibration,
  Platform
} from "react-native";
import LottieView from "lottie-react-native";
import { styles } from "./cards.styles";
import { EXPLORER_ITEMS } from "./config/explorerItems";
const { width, height } = Dimensions.get("window");

const AnimatedTouchableOpacity =
  Animated.createAnimatedComponent(TouchableOpacity);

const SPACING = 10;
const ITEM_SIZE = width * 0.84;
const BACKDROP_HEIGHT = height * 0.82;

export default function Cards({ guestLogin, handlePathExpItemPressed }) {
  const scrollX = React.useRef(new Animated.Value(0)).current;

  return (
    <View
      style={{
        height: BACKDROP_HEIGHT,
        backgroundColor: "#0F1143",
      }}
    >
      <Animated.FlatList
        showsHorizontalScrollIndicator={false}
        data={EXPLORER_ITEMS}
        keyExtractor={(item) => item.key}
        horizontal
        bounces={false}
        decelerationRate={Platform.OS === "ios" ? 0 : 0.8}
        renderToHardwareTextureAndroid
        snapToInterval={ITEM_SIZE}
        snapToAlignment="start"
        // onMomentumScrollEnd={() => Vibration.vibrate([50, 200])}
        scrollEventThrottle={16}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { x: scrollX } } }],
          { useNativeDriver: false }
        )}
        renderItem={({ item, index }) => {
          const inputRange = [
            (index - 1) * ITEM_SIZE,
            index * ITEM_SIZE,
            (index + 1) * ITEM_SIZE,
          ];

          const translateY = scrollX.interpolate({
            inputRange,
            outputRange: [75, 40, 75],
            extrapolate: "clamp",
          });

          const opacity = scrollX.interpolate({
            inputRange,
            outputRange: [0, 1, 0],
            extrapolate: "clamp",
          });

          const scale = scrollX.interpolate({
            inputRange,
            outputRange: [0.4, 1, 0.4],
            extrapolate: "clamp",
          });

          return (
            <TouchableOpacity
              activeOpacity={1}
              style={{
                width: ITEM_SIZE,
                marginLeft: index === 0 ? width * 0.08 : 0,
                marginRight:
                  index === EXPLORER_ITEMS.length - 1 ? width * 0.08 : 0,
                position: "relative",
                backgroundColor: "#0F1143",
              }}
            >
              <Animated.View
                style={{
                  marginHorizontal: SPACING,
                  padding: SPACING * 2,
                  alignItems: "center",
                  transform: [{ translateY }],
                  backgroundColor: "white",
                  borderRadius: 34,
                  height: BACKDROP_HEIGHT * 0.8,
                  display: "flex",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
              >
                <View />
                <View
                  style={{
                    width: "90%",
                  }}
                >
                  <Animated.Image
                    source={item.img}
                    style={{
                      width: "100%",
                      height: BACKDROP_HEIGHT * 0.35,
                      resizeMode: "contain",
                      borderRadius: 24,
                      margin: 0,
                      marginBottom: 10,
                      transform: [{ scale }],
                    }}
                  />
                  <Animated.View
                    style={[
                      styles.contentView,
                      {
                        opacity,
                      },
                    ]}
                  >
                    <Text style={styles.optionTitle}>{item.title}</Text>
                    <Text style={styles.optiondescription}>
                      { item.key !== 'eligibility_report' ?
                      item.description : 'View your Oppourtunity Report'}
                    </Text>
                  </Animated.View>
                </View>
                <AnimatedTouchableOpacity
                  activeOpacity={0.8}
                  style={[
                    styles.buttonWrapper,
                    {
                      opacity,
                    },
                  ]}
                  onPress={() =>
                    item.key !== 'eligibility_report' ? handlePathExpItemPressed(item.key, item.onlyForMob)
                      : handleExligibilityReport(verificationCode, isDeepLinkAvailable ? secCode : verificationCode, false)
                  }
                >
                  {guestLogin && item.key !== 'eligibility_report' ? (
                    <LottieView
                      duration={3000}
                      style={styles.lockIcon}
                      source={require("./config/12195-locked.json")}
                      autoPlay
                      loop={true}
                    />
                  ) : (
                    <Text style={styles.buttonText}>Explore</Text>
                  )}
                </AnimatedTouchableOpacity>
              </Animated.View>
            </TouchableOpacity>
          );
        }}
      />
      <View
        style={[
          styles.indicatorWrapper,
          {
            bottom: BACKDROP_HEIGHT * 0.09,
          },
        ]}
      >
        {[...Array(EXPLORER_ITEMS.length)].map((e, index) => {
          const inputRange = [
            (index - 1) * ITEM_SIZE,
            index * ITEM_SIZE,
            (index + 1) * ITEM_SIZE,
          ];

          const width = scrollX.interpolate({
            inputRange,
            outputRange: [8, 18, 8],
            extrapolate: "clamp",
          });
          return (
            <Animated.View
              style={[
                {
                  width,
                },
                styles.indicator,
              ]}
            />
          );
        })}
      </View>
    </View>
  );
}
