import { Alert, Platform, Dimensions } from "react-native";
import Moment from "moment";
import AsyncStorage from '@react-native-community/async-storage';
import { Network, apiPaths } from '../service/api'
import NavigationService from "../navigation/NavigationService"
import { GoogleSignin } from '@react-native-community/google-signin';
import { AccessToken, LoginManager } from 'react-native-fbsdk';
import cityfactsLogo from "../images/cityfactsLogo.png";
import { GoogleAuthProvider, signInWithPopup, getAuth, FacebookAuthProvider } from "firebase/auth";
import { appleAuth, appleAuthAndroid } from '@invertase/react-native-apple-authentication';
import { history } from "./browserHistory";
import SweetAlert from "../components/SweetAlert";
export const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("screen");
import Images from "./images";
import storage from "@react-native-firebase/storage";
import { getStorage, ref, uploadString, getDownloadURL, deleteObject } from "firebase/storage";

let monthArray = [
  { month: "JAN", year: "" },
  { month: "FEB", year: "" },
  { month: "MAR", year: "" },
  { month: "APR", year: "" },
  { month: "MAY", year: "" },
  { month: "JUN", year: "" },
  { month: "JUL", year: "" },
  { month: "AUG", year: "" },
  { month: "SEP", year: "" },
  { month: "OCT", year: "" },
  { month: "NOV", year: "" },
  { month: "DEC", year: "" }
];

export const copilotData = {
  dashboard: '',
  media: `Access our exclusive aggregation of media from various sources, personalized just for you!`,
  mediaSearch: 'Looking for a particular news, story or video? Search here!',
  mediaCard: 'Scroll to view more, react, save and share!',
  dashboardNotification: 'Bulletin: Stay up to date on latest policy updates and events!',
  dashboardProfileIcon: 'View your public profile, edit your private information and access your personal saved items!',
  askExpert: 'Got a question? Get it answered now!',
  adviceConversationButton: 'View your conversations and ask our experts for more!',
  adviceChatHistory: 'Your conversations will appear here!',
  adviceFaq: 'Check out our list of FAQs, read expert answers and ask our experts for more info!',
  immipathSelectRegion: 'Select a region of your choice',
  immipathSelectCountry: 'Select a country of your choice',
  immipathNext: 'Press Next to proceed',
  eligibleResultCard: 'List of eligible items based on your previous responses',
  eligibleDetailModal: 'Click here to view more details',
  eligibleCompareButton: 'Select items and click here to compare',
  shorlistedResult: 'Compare with various metrics and facts',
  selectedShortlistedCard: 'Click here to select an item to shortlist',
  shortListedButton: 'Shortlist to proceed to the next step',
  visaApplyButton: 'View application checklist, ensure you have all the requirements and apply!',
  askExpert: 'Got questions regarding university selection/application? Ask our expert!',
  applicableVisa: 'View applicable visas',
  immipathResource: 'Find resources to help achieve your dream',
  pathexpMenuToogle: 'You can switch between horizontal and vertical scroll views as per your convenience',
  languagedropDown: 'Switch between languages spoken in the selected country',
  languageSelectPhrase: 'Select your social setting to look at important phrases to learn',
  languageAudioIcon: 'Click here to hear the pronounciations',
  adjustSpeech: 'Adjust speech rate to understand better and practice!',
  dashboardProfileButton: 'View your public profile, edit your private information and access your personal saved items!',
  dashboardPathExpButton: 'Explore your opportunities and solutions with your wide range of tools',
  dashboardAdviceButton: 'Got a question? Get it answered now!'

}

export const adviceChannelqueryOptions = [
  'Study',
  'Work',
  'Tourism',
  'Permanent Residency',
  'Legal'
]

export const currentStageOptions = [
  'Pre Application',
  'Post Arrival',
  'General/Non-Specific',
]

export const epxertNotes = [
  'Our Expert will respond to your request within 2-3 work days',
  'Questions/ Chat discussion to be relevant to the category and country selected',
  'The information provided in “Chat with our Expert” does not and is not intended to, constitute legal advice'
]

export const adviceChannelPackages = [
  {
    title: 'ASK OUR EXPERT',
    description: 'Our Expert will respond to your\nquestion in 2-3 work days',
    price: 225
  },
  {
    title: 'TALK TO OUR EXPERT',
    description: '15 mins Call with our Expert. We will send\nan invite for an appointment with in 24 hrs',
    price: 325
  }
]

let formQuestIds = {
  0: 'Maximum Visa Application Budget',
  1: 'Maximum Duration for Visa Application',
  2: 'Marital Status',
  3: 'IELTS Score',
  4: 'TOEFL Score',
  5: 'PTE Score',
  6: 'ELICOS on Arrival',
  7: 'Work Experience in domain of study',
  8: 'Duration of Work Experience',
  9: 'Criminal Offense',
}
let dataArray = [
  { id: 1, name: "USA" },
  { id: 2, name: "Canada" },
  { id: 3, name: "Australia" },
  { id: 4, name: "Singapore" },
  { id: 5, name: "UK" },
  { id: 6, name: "UAE" },
  { id: 7, name: "Germany" },
  { id: 8, name: "Netherlands" },
  { id: 10, name: "Japan" },
  { id: 9, name: "New Zealand" }
];

let goalArray = [
  { id: 1, name: "Study" },
  { id: 2, name: "Work" },
  { id: 4, name: "Tourism" },
  { id: 3, name: "Permanent Residency" },
];

const getGoalId = (goal) => {
  let _arr = goalArray.filter((s) => s.name.toLowerCase() == goal);
  return _arr[0]?.id;
}
// let currencyArray = [
//   { id: "GBP", val: 0.81 },
//   { id: "EUR", val: 0.91 },
//   { id: "AUD", val: 1.47 },
//   { id: "CAD", val: 1.33 },
//   { id: "INR", val: 70.9 },
//   { id: "NZD", val: 1.58 },
//   { id: "SGD", val: 1.38 },
//   { id: "MYR", val: 4.19 },
//   { id: "JPY", val: 106.94 },
//   { id: "CNY", val: 7.15 },
//   { id: "SAR", val: 3.75 },
//   { id: "MXN", val: 19.54 },
//   { id: "AED", val: 3.67 },
//   { id: "HKD", val: 7.84 },
//   { id: "ILS", val: 3.48 },
//   { id: "IRR", val: 41956.83 },
//   { id: "BRL", val: 4.06 },
//   { id: "USD", val: 1 }
// ];

let currencyArrayVar = []

const refreshCurrency = async () => {
  const CACHED_HOURS = 2
  const lastCached = await AsyncStorage.getItem('lastcachedCurrencyArray')
  if (lastCached == null) {
    fetchCurrency()
    await AsyncStorage.setItem('lastcachedCurrencyArray', JSON.stringify(new Date()))
  } else if (lastCached != null) {
    const lastcachedTime = new Date(JSON.parse(lastCached))
    const cachedDuration = lastcachedTime.setHours(lastcachedTime.getHours() + CACHED_HOURS)
    const currentTime = new Date()
    if (currentTime > cachedDuration) {
      fetchCurrency()
      await AsyncStorage.setItem('lastcachedCurrencyArray', JSON.stringify(new Date()))
    } else {
      const currencyData = JSON.parse(await AsyncStorage.getItem('currencyArray'))
      currencyData.map(item => {
        currencyArrayVar.push(item)
      })
    }
  }
}

export const checkTutorialStatus = async (name) => {
  let shwonTutorialItems = await AsyncStorage.getItem('TutorialItemsShown');
  let DontShowTutorial = await AsyncStorage.getItem('DontShowTutorial');
  shwonTutorialItems = JSON.parse(shwonTutorialItems);
  if ((shwonTutorialItems?.length > 0 && shwonTutorialItems.includes(name)) || DontShowTutorial == 'true') {
    return false
  }
  return true
}

export const fetchCurrency = async () => {
  const url = apiPaths.currencyRefresh
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
  await Network(url, 'GET', null, headers).then(async res => {
    if (res.status && res.status == 'Success') {
      await AsyncStorage.setItem('currencyArray', JSON.stringify(res.convert_currency))
      res.convert_currency.map(item => {
        currencyArrayVar.push(item)
      })
    }
  })
}

const storeCurrencyConverer = (currencyCode, number) => {
  let usdValue = currencyArrayVar.filter((s) => s.id == 'INR');
  let conversion = number / usdValue[0]?.val;
  let obj = currencyArrayVar.find(item => {
    if (item.id === currencyCode) {
      return item.val;
    }
  });
  return obj ? (obj.val * conversion).toFixed(2) : number
}

const handleSessionExpiry = () => {
  AsyncStorage.clear();
  if (Platform.OS == 'web') {
    history.push('/login')
  } else {
    NavigationService.navigate("Login")
  }
  SweetAlert.error("Your Login Session Expired");
}

const currencyConverter = (currencyCode, number) => {
  obj = currencyArrayVar.find(item => {
    if (item.id === currencyCode) {
      return item.val;
    }
  });
  return obj ? obj.val * number : number
};

const currencyDevider = (currencyCode, number) => {
  const obj = currencyArrayVar.find(item => {
    if (item.id == currencyCode) {
      return item.val;
    }
  });

  return obj ? number / obj.val : number
};

const getCountryName = id => {
  const obj = dataArray.find(item => {
    if (item.id == id) {
      return item;
    }
  });

  return obj ? obj.name : null;
};

const getGoalName = goalDataArray => {
  let goalMap = [];
  return goalDataArray.map(item => {
    goalMap = goalArray.filter(i => {
      return i.id === item;
    });
    return goalMap[0].name;
  });
};

const getNumberOfDays = () => {
  var a = new Date();
  var days = a.getDate();
  return days;
};

const setMonthArray = (month, year) => {
  var indexx = monthArray.findIndex((item, index) => {
    return item.month === month;
  });

  let currentYear = [];

  let dataDict = monthArray[indexx];
  dataDict.year = year;
  monthArray[indexx] = dataDict;
  currentYear.push(dataDict);

  let remainingMonth = Moment().format("M");

  for (let i = remainingMonth; i < 12; i++) {
    let item = monthArray[i];
    item.year = year;
    monthArray[i] = item;
    currentYear.push(item);
  }

  year = year + 1;

  for (let i = 0; i < remainingMonth - 1; i++) {
    let item = monthArray[i];
    item.year = year;
    monthArray[i] = item;
    currentYear.push(item);
  }

  const value = [
    ...monthArray.slice(indexx, monthArray.length),
    ...monthArray.slice(0, indexx)
  ];

  return value;
};

const findColor = id => {
  let color;
  switch (id) {
    case "1":
      color = "#F06A4E";
      break;
    case "2":
      color = "#25A2E5";
      break;
      break;
    case "3":
      color = "#7A7A7A";
      break;
    case "4":
      color = "#159013";
      break;
    case "5":
      color = "#890606";
      break;

    default:
      break;
  }
  return color;
};

const showSingleAlert = (alertMessage, callbackFunction) => {
  // setTimeout(function() {
  return Alert.alert("Immigreat", alertMessage, [
    {
      text: "Ok",
      onPress: () => {
        callbackFunction ? callbackFunction() : null;
      }
    }
  ]);
  // }, 500);
};

// const handleSessionExpiry = () => {
//   AsyncStorage.clear();
//   showSingleAlert(
//     "Session expired Please login again",
//     NavigationService.navigate("Login")
//   );
// }
const inAPPbrowserOption = {
  dismissButtonStyle: 'cancel',
  preferredBarTintColor: '#0F1143',
  preferredControlTintColor: 'white',
  readerMode: false,
  animated: true,
  modalPresentationStyle: 'overFullScreen',
  // modalTransitionStyle: 'partialCurl',
  modalEnabled: true,
  enableBarCollapsing: false,
  // Android Properties
  showTitle: true,
  toolbarColor: '#0F1143',
  secondaryToolbarColor: '#0F1143',
  enableUrlBarHiding: true,
  enableDefaultShare: true,
  forceCloseOnRedirection: false,
  // Specify full animation resource identifier(package:anim/name)
  // or only resource name(in case of animation bundled with app).
  animations: {
    startEnter: 'slide_in_right',
    startExit: 'slide_out_left',
    endEnter: 'slide_in_left',
    endExit: 'slide_out_right'
  },
  headers: {
    'my-custom-header': 'my custom header value'
  }
}
const addThousandSeparator = (value) => {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return '-'
  }
}

const getUniversityInitials = (name) => {
  let tt = name.split(' ');
  let dd = '';
  tt.forEach((s) => {
    let c = s.charAt(0);
    dd = s == 'of' ? dd : dd + c
  })
  return dd
}

const convertFormat = (value, multiplier) => {
  if (value) {
    if (value.toString().length >= 4 && value.toString().length < 6) {
      return addThousandSeparator(value);
    } else if (value.toString().length >= 6 && value.toString().length < 8) {
      if (multiplier === 'INR') {
        value = value / 100000;
        return value % 1 === 0 ? value + ' Lakh' : value.toFixed(1) + ' Lakh'
      } else {
        if (value.toString().length === 7) {
          value = value / 1000000;
          return value % 1 === 0 ? value + ' million' : value.toFixed(1) + ' million'
        }
        value = value / 1000;
        return value % 1 === 0 ? value + 'k' : value.toFixed(1) + 'k'
      }
    } else if (value.toString().length >= 8 && value.toString().length < 10) {
      if (multiplier === 'INR') {
        value = value / 10000000;
        return value % 1 === 0 ? value + ' crore' : value.toFixed(1) + ' crore'
      } else {
        value = value / 1000000;
        return value % 1 === 0 ? value + ' million' : value.toFixed(1) + ' million'
      }
    } else {
      return value
    }
  }
  else {
    return null
  }
}

const getCurrencySymbol = (multiplier) => {
  if (multiplier === 'INR') {
    return '₹';
  } else if (multiplier === 'USD') {
    return '$';
  } else if (multiplier === 'EUR') {
    return '€'
  } else if (multiplier === 'SGD') {
    return 'S$'
  } else if (multiplier === 'AUD') {
    return 'A$'
  } else if (multiplier === 'NZD') {
    return 'NZ$'
  } else if (multiplier === 'MYR') {
    return 'RM'
  } else if (multiplier === 'JPY') {
    return '¥'
  } else if (multiplier === 'CNY') {
    return '¥'
  } else if (multiplier === 'SAR') {
    return '﷼'
  } else if (multiplier === 'MXN') {
    return 'mex$'
  } else if (multiplier === 'AED') {
    return 'د.إ'
  } else if (multiplier === 'HKD') {
    return 'hk$'
  } else if (multiplier === 'ILS') {
    return '₪'
  } else if (multiplier === 'IRR') {
    return '﷼'
  } else if (multiplier === 'BRL') {
    return 'R$'
  } else if (multiplier === 'CAD') {
    return 'C$'
  } else if (multiplier === 'GBP') {
    return '£'
  } else if (multiplier === 'OMR') {
    return 'ر.ع.'
  } else if (multiplier === 'QAR') {
    return 'ر.ق'
  } else if (multiplier === 'KWD') {
    return 'د.ك'
  } else if (multiplier === 'KWD') {
    return 'د.ك'
  } else if (multiplier === 'LKR') {
    return 'ரூ, රු'
  } else if (multiplier === 'CNH') {
    return 'HK$'
  }
}

export const scrollToTop = () => {
  window && window.scrollTo && window?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}

export const onAppleButtonPressHealper = async (callback = () => { }) => {
  try {
    let appleResponse = {}
    if (Platform.OS === "ios") {
      appleResponse = await appleAuth.performRequest({
        requestedOperation: appleAuth.Operation.LOGIN,
        requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
      });
      if (appleResponse.fullName && appleResponse?.fullName?.givenName != null && appleResponse?.fullName?.familyName != null) {
        appleResponse.user = {
          email: appleResponse.email,
          firstName: appleResponse?.fullName?.givenName,
          lastName: appleResponse?.fullName?.familyName
        }
      }
    } else {
      await appleAuthAndroid.configure({
        clientId: "com.immigreatglobal.immigreat",
        redirectUri: "https://web.immigreat.global/dashboard",
        responseType: appleAuthAndroid.ResponseType.ALL,
        scope: appleAuthAndroid.Scope.ALL,
      });
      appleResponse = await appleAuthAndroid.signIn();
    }

    let token = Platform.OS == 'ios' ? appleResponse.identityToken : appleResponse.id_token

    if (!token) {
      throw 'Apple Sign-In failed - no identify token returned';
    }
    let appleData = {
      "email_address": null,
      "metadata": {
        "token": token,
        'platform': Platform.OS
      }
    }
    if (appleResponse.user) {
      appleData.email_address = appleResponse.user.email;
      appleData.metadata["first_name"] = appleResponse.user.firstName;
      appleData.metadata["last_name"] = appleResponse.user.lastName;
    }
    callback("apple", appleData)
    return appleData;
  } catch (e) {
    console.log(e, "ee")
  }
}

export const onGoogleButtonPressHelper = async (callback) => {
  let token = ''
  // console.log("test")
  // Get the users ID token
  try {
    let googleData = {}
    if (Platform.OS == 'web') {
      const auth = getAuth();
      const googleProvider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, googleProvider);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        token = credential.idToken;
        let user = result._tokenResponse;
        googleData = {
          email_address: user.email,
          metadata: {
            first_name: user.firstName,
            last_name: user.lastName,
            token: token,
          },
        };
        const res = await getGoogleUserDetails(token, "login", callback, googleData);
        if (!callback) {
          return res;
        }
      } catch (error) {
      }
    } else {
      await GoogleSignin.hasPlayServices();
      token = await GoogleSignin.signIn();
      googleData = await getGoogleUserDetails(token?.idToken, 'login', callback)
    }
    if (!callback) {
      return googleData
    }
  } catch (error) {
    if (error.code === statusCodes.SIGN_IN_CANCELLED) {
      // user cancelled the login flow
    } else if (error.code === statusCodes.IN_PROGRESS) {
      // operation (e.g. sign in) is in progress already
    } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
    } else {
      // some other error happened
    }
  }
  const googleCredential = Platform.OS !== 'web' && auth.GoogleAuthProvider.credential(token);
  //console.log('-user-', user)
  // Sign-in the user with the credential
  // return {
  //   token,
  //   signInresponse: auth().signInWithCredential(googleCredential)
  // }
}

const getGoogleUserDetails = async (token, action, callback, googleData) => {
  const res = await fetch('https://oauth2.googleapis.com/tokeninfo?id_token=' + token);
  if (res) {
    if (action === "login") {
      let json = await res.json();
      const google_data = {
        "email_address": json.email,
        "metadata": {
          "first_name": json.given_name,
          "last_name": json.family_name,
          "token": token
        }
      }
      // console.log(google_data);
      //process login with token
      if (callback) {
        callback("google", Platform.OS == 'web' ? googleData : google_data);
      } else {
        return Platform.OS == 'web' ? googleData : google_data
      }
    }
  }
}

export const mobilBrower = () => {
  var isMobileBrower = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return isMobileBrower
}

export const pathMenu = [
  {
    name: 'Quick Search',
    img: cityfactsLogo,
    description: 'Look up visas and their basic information'
  },
  {
    name: 'Quick Search',
    img: cityfactsLogo,
    description: 'Look up visas and their basic information'
  },
  {
    name: 'Quick Search',
    img: cityfactsLogo,
    description: 'Look up visas and their basic information'
  },
  {
    name: 'Quick Search',
    img: cityfactsLogo,
    description: 'Look up visas and their basic information'
  },
  {
    name: 'Quick Search',
    img: cityfactsLogo,
    description: 'Look up visas and their basic information'
  },
  {
    name: 'Quick Search',
    img: cityfactsLogo,
    description: 'Look up visas and their basic information'
  }
]


export const isWeb = () => {
  if (Platform.OS == 'web') {
    return true
  } else {
    return false
  }
}

const getFBUserDetails = async (token, action, callback) => {
  let res = await fetch('https://graph.facebook.com/v2.5/me?fields=email,first_name,last_name,friends&access_token=' + token);
  if (res) {
    let json = await res.json();
    if (action === "login") {
      const fb_data = {
        "email_address": json.email,
        "metadata": {
          "first_name": json.first_name,
          "last_name": json.last_name,
          "token": token
        }
      }
      // console.log(fb_data);
      //process login with token
      if (callback) {
        callback("facebook", fb_data);
      } else {
        return fb_data
      }
    }
  }
}

export const onFacebookButtonPressHelper = async (callback) => {
  if (Platform.OS == 'web') {
    const auth = getAuth();
    const fbProvider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, fbProvider);
      const credential = FacebookAuthProvider.credentialFromResult(result);
      token = credential.accessToken;
      let fbData = await getFBUserDetails(token, "login", callback);
      if (!callback) {
        return fbData;
      }
    } catch (error) {
      console.log('111', error);
    }
  } else {
    const result = await LoginManager.logInWithPermissions(['public_profile', 'email']);
    if (result.isCancelled) {
      throw 'User cancelled the login process';
    }
    // Once signed in, get the users AccessToken
    const data = await AccessToken.getCurrentAccessToken();
    if (!data) {
      throw 'Something went wrong obtaining access token';
    }
    let fbData = await getFBUserDetails(data.accessToken, "login", callback);
    if (!callback) {
      return fbData
    }
    // const facebookCredential = auth.FacebookAuthProvider.credential(data.accessToken);
    return true;
  }
}

const getCountryFlag = (id) => {
  let image = "";
  switch (id) {
    case 1:
      image = Images.usFlag2;
      break;
    case 2:
      image = Images.CANADAFlag;
      break;
    case 3:
      image = Images.Ausflag;
      break;
    case 4:
      image = Images.singaporeFlag;
      break;
    case 5:
      image = Images.UKFLag;
      break;
    case 6:
      image = Images.UAEFLAG;
      break;
    case 7:
      image = Images.GermanyFlag;
      break;
    case 8:
      image = Images.netherlanFlag;
      break;
    case 9:
      image = Images.NZFlag3;
      break;
    case 10:
      image = Images.JapanFlag;
      break;
    default:
      break;
  }
  return image;
};

const emoji = [
  {
    code: 1,
    symbol: '💡'
  },
  {
    code: 2,
    symbol: '😄'
  },
  {
    code: 3,
    symbol: '😔'
  },
  {
    code: 4,
    symbol: '👍'
  },
  {
    code: 5,
    symbol: '👎'
  },
]

export const selectWebStyle = (style = {}) => {
  return Platform.select({
    web: {
      ...style
    }
  })
}

export const uploadFileToFirebase = ({ refPath, file }) => {
  if (isWeb()) {
    const storage = getStorage();
    storageRef = ref(storage, refPath);
    return uploadString(storageRef, file, "data_url")
      .then(s => getDownloadURL(storageRef).then(url => ({ uri: url, path: storageRef.path })))
  } else {
    let storageRef = storage().ref(refPath);
    return new Promise((resolve, reject) => {
      storageRef.putFile(file).on(storage.TaskEvent.STATE_CHANGED, snapshot => {
        if (snapshot.state === storage.TaskState.SUCCESS) {
          storageRef.getDownloadURL().then((downloadURL) => {
            resolve({ uri: downloadURL, path: storageRef.path })
          })
        } else {
          reject(snapshot)
        }
      })
    })
  }
}

export const deleteFileFromFirebase = (refPath) => {
  if (isWeb()) {
    const storage = getStorage();
    const desertRef = ref(storage, refPath);
    deleteObject(desertRef).catch((error) => { console.log(error) });
  } else {
    let storageRef = storage().ref(refPath);
    storageRef.delete().catch(e => { });
  }
}

export default {
  getCountryName,
  formQuestIds,
  getGoalName,
  findColor,
  getNumberOfDays,
  storeCurrencyConverer,
  dataArray,
  setMonthArray,
  currencyConverter,
  showSingleAlert,
  getUniversityInitials,
  getCurrencySymbol,
  convertFormat,
  currencyDevider,
  getGoalId,
  inAPPbrowserOption,
  mobilBrower,
  adviceChannelqueryOptions,
  adviceChannelPackages,
  isWeb,
  emoji,
  epxertNotes,
  getCountryFlag,
  refreshCurrency,
  handleSessionExpiry,
  onGoogleButtonPressHelper,
  onFacebookButtonPressHelper,
};
