import React from "react";
import { BlurView } from "@react-native-community/blur";
import { isWeb } from "../common/helpers";
import { View } from "react-native";

export default function BlurBackground({ blurType = "dark" }) {
    return (
        <>
            {isWeb() ? <View style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                backgroundColor: "rgba(2, 2, 2, 0.3)",
                backdropFilter: "blur(10px)"
            }} /> :
                <BlurView
                    blurType={blurType}
                    style={{ height: "100%", width: "100%", position: "absolute" }}
                    blurAmount={3}
                />
            }
        </>
    );
}
