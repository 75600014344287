const colors = {
  BLUE: '#00bfff',
  BACKGROUND_WHITE: 'rgb(249,249,249)',
  WHITE: 'rgb(255,255,255)',
  ACTIVE_GREEN: 'rgb(84,194,106)',
  INDICATOR_BLUE: 'rgb(0,139,199)',
  RED: 'rgb(255,0,0)',
  DARK_BLUE: 'rgb(26, 110, 219)',
  LIGHT_GREY: 'rgb(238,242,245)',
  EYE_COLOR: 'rgb(27,27,27)',
  TABLE_ITEM_TEXT: 'rgb(37,48,54)',
  SHADOW_COLOR: 'rgba( 0,0, 0 ,0.16)',
  HEADER_BLACK: 'rgb(44,57,63)',
  PARA_VIEW_COLOR: 'rgb( 238,242,245)',
  STAR_YELLOW: '#e9a431',
  LIGHT_BLUE: '#0f1143',
  LIGHT_TEXT_GREY: 'rgb(174,174,174)',
  UNKNOWN_GREY: 'rgb(227,227,227)',
  DROPDOWN_HEADER: 'rgb(44,57,63)',
  ICON_GREY: 'rgb(232,232,232)',
  EYE:'rgb(49,58,62)',
  HEADER_COLOR: 'rgb(160,163,162)',
  TERMS_AND_SERVICES_BLUE: 'rgb(2,153,240)',
  TEXT_BLACK: 'rgb(57,61,59)',
  TEXT_AQUA_BLUE: 'rgb(0,202,229)',
  BACKGROUND_WHITE: 'rgb(255,255,255)',
  BUTTON_TEXT_WHITE: 'rgb(251,252,252)',
  SUBHEADER_GREY: 'rgb(130,130,130)',
  TEXT_GREY: 'rgb(150,150,151)',
  TAB_SUBHEADER_TEXT_COLOR: 'rgba(0,0,0,0.46)',
  NOTIFICATIONS_TEXT: 'rgb(78,75,75)',
  APPOINTMENT_DATE_HEADER_TEXT: 'rgb(178,178,178)',
  APPOINTMENT_BACKGROUND_DIV: 'rgb(234,231,231)',
  BILL_RECIEPT_TEXT_COLOR: 'rgb(120,121,120)',
  WELCOME_BUTTON_TEXT_COLOR: 'rgb(248,248,248)',
  CALENDAR_UNSELECTED_GREY: 'rgb(234,231,231)',
  CALENDAR_EDIT_BUTTON_COLOR: 'rgb(202,204,203)',
  TODAY_DATE_COLOR: 'rgb(203,202,202)',
  DR_PROFILE_NAV_BACKGROUND: 'rgb(246,244,244)',
  REG_BLUE_BORDER: 'rgb(206,216,220)',
  REG_BLUE_TEXT: 'rgb(56,71,79)',
  REG_BLUE_LABEL: 'rgb(55,71,78)',
  REG_BLUE_PLACEHOLDER: 'rgb(143,164,173)',
  REG_RADIO_COLOR: '(rgb,202,202,202)',
  FOOTER_ICON: 'rgb(117,126,153)',
  NUMBER_TEXT: 'rgb(34,34,34)',
  ITEM_SEPERATOR: 'rgb(212,212,212)',
  DARK_ORANGE: '#F06A4E',
  DARK_GREEN: '#008000d9',
  DARK_SKY: '#25A2E5',
  DARK_GRAY: '#7A7A7A',
  MARON: 'maroon',
  IMMIGREAT_YELLOW: '#e3a63a'
}

export default colors
