import { StyleSheet } from "react-native";
import { family } from "../../../../common";
import fontFamily from "../../../../common/fontFamily";
import { mobilBrower } from "../../../../common/helpers";
import { heightPercentageToDP, widthPercentageToDP } from "../../../../common/responsiveScreen";

export const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexDirection: "row"
  },
  contentContainer: {
    flex: 1,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 20,
    paddingHorizontal: 15
  },
  wrapper: {
    padding: 10,
  },
  switch: {
    transform: [{ scale: 0.75 }],
    marginHorizontal: 5
  },
  title: {
    fontSize: 25,
    fontFamily: family.effra,
    width: "90%",
    marginLeft: 25,
  },
  stageInfoList: {
    marginTop: 20,
    width: '100%',
    height: 100,
    paddingVertical: 5
  },
  monthSeparator: {
    height: 100,
    width: 1,
    borderWidth: 0.5,
    borderColor: '#BDBDBD',
    alignSelf: 'center'
  },
  stagename: {
    flexWrap: 'wrap',
    flexShrink: 2,
    fontSize: 12
  },
  switchView: {
    flexDirection: 'row',
    alignItems: 'center',
    right: 45,
  },
  topView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    width: '80%',
    alignSelf: 'center'
  },
  headerText: {
    textAlign: 'center',
    fontSize: heightPercentageToDP('2%'),
    paddingBottom: 5,
    fontFamily: fontFamily.semibold
  },
  pathname: {
    fontFamily: fontFamily.semibold,
    fontSize: 12,
    height: 65,
    width: 80
  },
  timelineView: {
    marginHorizontal: 15,
  },
  countryName: {
    fontSize: 12,
    marginTop: 5,
    marginBottom: 5,
    fontFamily: fontFamily.regular,
  },
  textinputBox: {
    borderWidth: 0.5,
    borderRadius: 10,
    borderColor: '#BDBDBD',
    marginTop: 5,
    width: '90%',
    alignSelf: 'center',
    padding: 10
  },
  itemText: {
    fontFamily: fontFamily.roboto
  },
  listItem: {
    borderBottomWidth: 0.4,
    padding: 10,
    marginVertical: 5,
    borderColor: '#BDBDBD',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nextWrapper: {
    backgroundColor: "#F1AB32",
    padding: 12,
    borderRadius: 25,
    width: 160,
    marginTop: 10,
  },
  infoBlock: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5,
    flexShrink: 1,
    marginBottom: 5,
    width: 110
  },
  componentBlocks: {
    borderWidth: 1,
    borderColor: '#BDBDBD',
    width: 50
  },
  colorBlocks: {
    height: 10,
    width: 10,
    marginRight: 10
  },
  nextText: {
    color: "white",
    fontFamily: family.titilliumBold,
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 17,
  },
  visaItems: {
    alignItems: 'center',
    marginTop: 10
  },
  textT: {
    fontFamily: family.titilliumBold,
    fontSize: 25,
    textAlign: "center",
    width: "80%",
    lineHeight: 55,
    marginTop: -25,
  },
  card: {
    width: mobilBrower() ? widthPercentageToDP(90) : 400,
    backgroundColor: "#F5F6F8",
    padding: 20,
    height: '100%',
    borderRadius: 15,
    display: "flex",
    alignSelf: 'flex-start',
  },
  selectedCardView: {
    borderColor: '#F1AB32',
    borderWidth: 1
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginBottom: 15,
    height: 200,
    borderBottomWidth: 1,
    borderColor: "rgb(220,220,220)",
  },
  imageView: {
    backgroundColor: 'white',
    alignItems: 'center',
    height: 85,
    width: 85,
    marginTop: 10,
    borderWidth: 0.5,
    borderColor: '#BDBDBD',
    justifyContent: 'center'
  },
  univIcon: {
    fontSize: 18,
    fontFamily: family.robotoBlack
  },
  univName: {
    fontFamily: family.titilliumBold,
    fontSize: 20,
    textTransform: 'capitalize',
    textAlign: 'center'
  },
  univLocation: {
    marginTop: -10
  },
  typeDropDown: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  dropdownArrow: {
    height: 15,
    width: 15,
    marginLeft: 7,
    marginTop: 3
  },
  params: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  },
  univParamLabel: {
    fontFamily: family.effra,
    textAlign: 'center',
    fontSize: 18,
    color: "#2f3280",
    marginTop: 10,
  },
  univParamValue: {
    marginTop: 5,
    textAlign: "center"
  },
  scoreView: {
    height: 50,
    alignSelf: 'center',
    width: 50,
    marginTop: 10,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center'
  },
  pathName: {

  },
  monthView: {
    flexDirection: 'row',
    flex: 8,
    borderBottomWidth: 0.5,
    borderTopWidth: 0.5,
    paddingVertical: 15
  },
  datePickrcView: {
    width: '85%',
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: { height: 0.5, width: 0.2 },
    shadowOpacity: 0.5,
    shadowRadius: 2,
    alignSelf: 'center',
    margin: 15,
    padding: 5,
    paddingLeft: 10,
    borderRadius: 10
  },
  selectButton: {
    // width: "100%",
    display: "flex",
    flex: 1,
    marginBottom: 50,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0F1143",
    borderRadius: 10,
  },
  Componenttitle: {
    alignSelf: 'center',
    fontFamily: fontFamily?.robotoBold,
    fontSize: 17,
    marginTop: 20,
  },
  componentdescription: {
    alignSelf: 'center',
    marginTop: 10,
    fontFamily: fontFamily?.roboto,
    fontSize: 16
  }
});
