import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  mainView: {
    height: "100%",
    width: "100%",
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  tagView: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginTop: 10,
  },
  tags: {
    color: "white",
    marginLeft: 8,
  },
  innerView: {
    backgroundColor: "transparent",
    // padding: 10,
    height: "50%",
    alignItems: "center",
    justifyContent: "center",
    width: "90%",
  },
});
