import React, { useState, useEffect, useRef } from "react";
import { styles as s } from "./section_H.styles";
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
} from "react-native";
import Images from "../../../../common/images";
import { PremiumModal } from "./components/PremiumModal/PremiumModal";
import { useSelector, useDispatch } from "react-redux";
import { setShortlistedUnivrsity } from "../../../../actions/path.actions";
import { groupBy } from "lodash";
import UnicersityComparator from "./components/UnicersityComparator";
import { copilot, CopilotStep, walkthroughable } from "react-native-copilot";
import { checkTutorialStatus, copilotData } from "../../../../common/helpers";
import { TooltipComponent } from "../../../Tooltip/Tooltip";
import AlertModal from "../../../AlertModal/AlertModal";

const CopilotTouch = walkthroughable(TouchableOpacity);

const SectionH = ({ nextTrack, selectedCurrency, start }) => {
  const dispatch = useDispatch()
  const [premiumModalVisible, setPremiumModalVisibility] = useState(false);
  const [selectedCard, setselectedCard] = useState([])
  const [sectionedLIst, setsectionedLIst] = useState([])
  const [alertMessage, setAlertMessage] = useState(false);
  const resultComparisionNext = useSelector(state => state.copilotStateReducer?.resultComparisionNext)

  const selectedUniversity = useSelector(state => state.pathReducer?.getImmipathDetails?.selectedUniversity)
  const userImmipathSearchResults = useSelector(state => state.pathReducer?.getImmipathDetails?.userImmipathSearchResults)
  const underGraduateQuestions = useSelector((state) => state.pathReducer.getImmipathDetails.underGraduateQuestions);

  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const scrollRef = useRef()

  useEffect(() => {
    let callCoiplot = async () => {
      let status = await checkTutorialStatus('ShortlistButton')
      !guestLogin && userDetails.isHelpActive && status && start()
    }
    callCoiplot()
    resultComparisionNext && scrollRef.current.scrollToEnd({ animated: true })
  }, [resultComparisionNext]);

  useEffect(() => {
    let cityData = userImmipathSearchResults?.city_data[1];
    let factnames = userImmipathSearchResults?.fact_names;
    let countryData = userImmipathSearchResults?.country_data;

    selectedUniversity?.forEach((s) => {
      s.factsSection = groupBy(s.facts, 'fact_type')
      for (var k in cityData) {
        s.factsSection['City Facts'] = cityData[s.city_id]
      }
      s.factsSection['Country Facts'] = countryData
    })
    if (selectedUniversity?.length > 0) {
      selectedUniversity?.forEach((x, v) => {
        let result = x.factsSection.Requirements?.filter(o1 => underGraduateQuestions.form_data.
          some(o2 => Number(o1.fact_id) === Number(o2.fact_id)));
        selectedUniversity[v].factsSection.Requirements = result
      })
    }
    setsectionedLIst(selectedUniversity)
  }, [selectedUniversity, userImmipathSearchResults])



  const handleShortlist = () => {
    if (selectedCard?.length == 0) {
      setAlertMessage('Please select at least one university!')
      return
    }
    nextTrack()
  }

  const onPressSelect = (item) => {
    let _selectedCard = [...selectedCard];
    let _temp = _selectedCard.filter((s) => {
      return s.university_id == item.university_id
    });

    if (_temp.length > 0) {
      _selectedCard = _selectedCard.filter((s) => {
        return s.university_id !== item.university_id
      });
    } else {
      if (_selectedCard?.length == 3) {
        setAlertMessage(`Maximum 3 Universities allowed`)
        return
      }
      _selectedCard.push(item)
    }
    setselectedCard(_selectedCard)
    dispatch(setShortlistedUnivrsity(_selectedCard))
  }

  const getTitle = (c) => {
    if (c[0] == 'business') {
      return 'Business Friendliness Index'
    } else if (c[0] == 'preferred_language') {
      return 'Official Language(s)'
    } else if (c[0].includes('unemployment') || c[0].includes('cost')) {
      return c[0].charAt(0).toUpperCase() + c[0].replace('_', ' ').slice(1).replace(/_/g, ' ') + ' Index'
    }
    return c[0].replace('_', ' ').charAt(0).toUpperCase() + c[0].replace('_', ' ').slice(1)
  }

  const getIndicator = (title, value) => {
    let indicator = ''
    if (title.includes('Pollution')) {
      if (value < 50) {
        indicator = 'Good'
      } else if (value > 50 && value <= 100) {
        indicator = 'Moderate'
      } else if (value > 100 && value <= 150) {
        indicator = 'Unhealthy'
      } else if (value > 150 && value <= 200) {
        indicator = 'Unhealthy'
      } else if (value > 200 && value <= 300) {
        indicator = 'Very Unhealthy'
      } else if (value > 300) {
        indicator = 'Hazardous'
      }
    } else if (title.includes('Traffic')) {
      if (value <= 100) {
        indicator = 'Low traffic'
      } else if (value > 100 && value <= 200) {
        indicator = 'Moderate traffic'
      } else if (value > 200) {
        indicator = 'High traffic'
      }
    } else if (title.includes('business')) {
      if (value < 53) {
        indicator = 'Very Easy'
      } else if (value > 53 && value <= 97) {
        indicator = 'Easy'
      } else if (value > 97 && value <= 147) {
        indicator = 'Medium'
      } else if (value > 147 && value <= 190) {
        indicator = 'Below Average'
      }
    } else if (title.includes('Crime')) {
      if (value <= 40) {
        indicator = 'Low crime'
      } else if (value > 40 && value <= 60) {
        indicator = 'Moderate crime'
      } else if (value > 60) {
        indicator = 'High crime'
      }
    } else if (title.includes('Part')) {
      switch (value) {
        case value:
          indicator = 'Yes'
          break;
        case !value:
          indicator = 'No'
          break;
        default:
          break;
      }
    }
    else if (title.includes('Business') || title.includes('income')) {
      indicator = value[0].toUpperCase() + value.slice(1)
    }
    else {
      indicator = value
    }
    return indicator
  }

  return (
    <View style={[s.main]}>
      {premiumModalVisible && (
        <PremiumModal
          visible={premiumModalVisible}
          setPremiumModalVisibility={setPremiumModalVisibility}
        />
      )}
      <View style={[s.contentContainer]}>
        <Text style={[s.textT]}>Comparisions</Text>
        <View style={{ flex: 1, width: "100%" }}>
          <ScrollView ref={scrollRef} contentContainerStyle={{ width: "100%" }}>
            <CopilotStep
              text={copilotData.shorlistedResult}
              order={1}
              name="Shortlist Results">
              <CopilotTouch activeOpacity={1}>
                <ScrollView nestedScrollEnabled={true} horizontal={true}>
                  <CopilotTouch
                    activeOpacity={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <UnicersityComparator
                      getIndicator={getIndicator}
                      getTitle={getTitle}
                      onPressSelect={onPressSelect}
                      selectedCard={selectedCard}
                      sectionedLIst={sectionedLIst}
                    />
                    <TouchableOpacity
                      style={[
                        {
                          height: 55,
                          width: 55,
                        },
                      ]}
                      onPress={() => setPremiumModalVisibility(true)}
                      activeOpacity={0.7}
                    >
                      <Image
                        style={{
                          height: 45,
                          width: 45,
                        }}
                        source={Images.univAddMoreIcon}
                      />
                    </TouchableOpacity>
                  </CopilotTouch>
                </ScrollView>
              </CopilotTouch>
            </CopilotStep>
          </ScrollView>
        </View>
        {
          resultComparisionNext ?
            <CopilotStep
              text={copilotData.shortListedButton}
              order={3}
              name="ShortlistButton"
            >
              <CopilotTouch onPress={handleShortlist} style={[s.nextWrapper]}>
                <Text style={[s.nextText]}>SHORTLIST </Text>
              </CopilotTouch>
            </CopilotStep> :
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', width: '90%' }}>
              {/* {
                goal == '2' || goal == '3' &&
                <TouchableOpacity onPress={handleTimeline} style={[s.nextWrapper]}>
                  <Text style={[s.nextText]}>Timeline </Text>
                </TouchableOpacity>
              } */}
              <CopilotTouch onPress={handleShortlist} style={[s.nextWrapper]}>
                <Text style={[s.nextText]}>SHORTLIST </Text>
              </CopilotTouch>
            </View>
        }
        {alertMessage ?
          <AlertModal
            title={alertMessage}
            confirmText={"Ok"}
            showConfirm
            onConfirmPress={() => setAlertMessage(false)}
          />
          : null}
      </View>
    </View>
  );
};

export default copilot({
  tooltipComponent: TooltipComponent
})(SectionH)