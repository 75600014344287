import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  Platform,
} from "react-native";
import { Images, helpers } from "../../common";
import { useSelector } from "react-redux";
import { styles1 } from "./adviceChannelPackage.styles";
import { Network } from "../../service/api";
import { ActivityIndicator } from "react-native-paper";

function AdviceChannelPackage(props) {
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);
  const [dropDownIndex, setdropDownIndex] = useState(-1);
  const [cardSelected, setcardSelected] = useState(-1);
  const [advicePackages, setadvicePackages] = useState({});
  const [planSelected, setplanSelected] = useState(false);
  const baseCurrency = useSelector(
    (state) => state.referralReducer.referralData.baseCurrency
  );
  const [availDiscounModal, setavailDiscounModal] = useState(false);
  const immiBuck_balance = useSelector(
    (state) => state.immibucksReducer.getImmibucksDetails.immibucks_balance
  );
  const [discountAvailed, setdiscountAvailed] = useState(false);
  const [showPackage, setshowPackage] = useState(true);
  const preDiscount_prodcut = props?.inApp_Products?.filter((s) =>
    Platform.OS == "ios"
      ? s.productId == "basic_advice"
      : s.productId == "basic_advice_full"
  );
  const discounted_prodcut = props?.inApp_Products?.filter(
    (s) => s.productId == "basic_advice_discount"
  );
  openCard = (index) => {
    if (dropDownIndex === index) {
      setdropDownIndex(-1);
    } else {
      setdropDownIndex(index);
    }
  };

  useEffect(() => {
    props.visible &&
      setTimeout(() => {
        setshowPackage(true);
      }, 300);
  }, [props.visible]);

  const handleCardClick = (item, index) => {
    if (item[1].name == cardSelected) {
      setcardSelected(-1);
      setplanSelected(false);
      props.setFinalPackagePrice(null, null);
      return;
    }
    setcardSelected(item[1].name);
    setplanSelected(index == 0 ? true : false);
    props.setFinalPackagePrice(getPackagePrice(item[1].price, item[0]), item);
  };

  const onClose = () => {
    setavailDiscounModal(false);
  };

  const handleAvailClick = () => {
    setdiscountAvailed(true);
    let price =
      Platform.OS == "web" ? 179 : props.inApp_Products[1].localizedPrice;
    props.setFinalPackagePrice(price);
    setavailDiscounModal(false);
  };

  const getPreDiscountedPrice = (discount) => {
    if (discount) {
      return Platform.OS == "web"
        ? `${helpers.getCurrencySymbol(baseCurrency)} 179`
        : discounted_prodcut?.length > 0
          ? discounted_prodcut[0].localizedPrice
          : 0;
    } else {
      return Platform.OS == "web"
        ? `${helpers.getCurrencySymbol(baseCurrency)} 269`
        : preDiscount_prodcut?.length > 0
          ? preDiscount_prodcut[0].localizedPrice
          : 0;
    }
  };

  useEffect(() => {
    const getPackagePrice = async () => {
      const headers = {
        Accept: "application/json",
        Authorization: `${userDetails?.userid}@${token}`,
      };
      let updatedPackages = props.advicePackages;
      let promises = Object.entries(updatedPackages).map(async (s) => {
        let payload = {
          package: s[0],
        };
        let response = await Network(
          "/data/get_advice_pricing",
          "POST",
          payload,
          headers
        );
        const { pricing, status } = response;
        if (status == "Success") {
          if (props.baseCurrency == "INR") {
            s[1].price = pricing.INR[props.selectedSubCategoryTier];
          } else {
            s[1].price = helpers.currencyConverter(
              props.baseCurrency,
              pricing.USD[props.selectedSubCategoryTier]
            );
          }
        }
        updatedPackages[s[0]] = s[1]
        return;
      });
      await Promise.all(promises);
      setadvicePackages({ ...updatedPackages });
    };
    setadvicePackages(props.advicePackages);
    getPackagePrice();
  }, [props.advicePackages]);

  const getPackagePrice = (price, name) => {
    if (props.promoCodeApplied && props?.discountDetails?.package == name) {
      const { discount_perc } = props.discountDetails;
      let disAmount = price * (discount_perc / 100);
      return discount_perc == 100 ? 0 : (price - disAmount).toFixed(2);
    } else if (props.discountApplied) {
      let disAmount = price * (25 / 100);
      return (price - disAmount).toFixed(2);
    }
    return price % 1 == 0 ? price : price.toFixed(2);
  };

  const getPrice = (price) => {
    if (price % 1 == 0) {
      return price
    } else {
      return price.toFixed(2)
    }
  }
  return (
    <>
      {showPackage ? (
        <View style={styles1.modalInnerView}>
          <FlatList
            data={Object.entries(advicePackages).sort(
              (a, b) => a[1].package_id - b[1].package_id
            )}
            style={{ width: "100%" }}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ width: "100%" }}
            bounces={false}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                activeOpacity={1}
                onPress={() => handleCardClick(item, index)}
              >
                {!availDiscounModal ? (
                  <View
                    style={styles1.availDisContainer}
                  >
                    {cardSelected == item[1].name ? (
                      <Image
                        source={Images.redeemIcon}
                        style={styles1.redeemIcon}
                      />
                    ) : (
                      <View style={styles1.checkBox} />
                    )}

                    <View style={styles1.packageCard}>
                      <Image
                        source={Images.advicePackage1}
                        style={styles1.packageImg}
                      />
                      <View
                        style={styles1.availDisContent}
                      >
                        <View style={{ flex: 2 }}>
                          <Text style={styles1.packageTitleText}>
                            {item[1].name}
                          </Text>
                          <Text
                            style={styles1.packageDesc}
                          >
                            {item[1].description}
                          </Text>
                        </View>
                        <View style={{ flex: 1, alignItems: "flex-end" }}>
                          {item[1].price ? (
                            <>
                              {getPackagePrice(item[1].price, item[0]) !==
                                item[1].price ? (
                                <>
                                  <Text
                                    style={[
                                      styles1.packagePriceText,
                                      styles1.linethrough,
                                    ]}
                                  >
                                    {helpers.getCurrencySymbol(baseCurrency) +
                                      getPrice(item[1].price)}
                                  </Text>
                                  <Text style={styles1.packagePriceText}>
                                    {helpers.getCurrencySymbol(baseCurrency) +
                                      getPackagePrice(item[1].price, item[0])}
                                  </Text>
                                </>
                              ) : (
                                <Text style={[styles1.packagePriceText]}>
                                  {helpers.getCurrencySymbol(baseCurrency) +
                                    getPrice(item[1].price)}
                                </Text>
                              )}
                            </>
                          ) : (
                            <ActivityIndicator color="black" size="large" />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View style={styles1.innerView}>
                    <View style={styles1.balanceView}>
                      <Text style={styles1.balanceText}>Balance</Text>
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Text style={[styles1.balanceText, {}]}>
                          {immiBuck_balance}
                        </Text>
                        <Image
                          style={{ height: 25, width: 25, marginLeft: 5 }}
                          source={Images.immibuck}
                        />
                      </View>
                    </View>
                    <View style={styles1.discount}>
                      <Text style={styles1.discount_title}>33% Discount</Text>
                      <Text style={{ marginLeft: 15, marginRight: 15 }}>@</Text>
                      <Text style={[styles1.balanceText]}>100</Text>
                      <Image
                        style={{ height: 25, width: 25, marginLeft: 5 }}
                        source={Images.immibuck}
                      />
                    </View>
                    <View style={styles1.buttonView}>
                      <TouchableOpacity
                        onPress={() => handleAvailClick()}
                        style={styles1.availBut}
                      >
                        <Text style={styles1.butText}>Avail</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => onClose()}
                        style={styles1.availBut}
                      >
                        <Text style={styles1.butText}>Cancel</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
              </TouchableOpacity>
            )}
          />
        </View>
      ) : (
        <View />
      )}
    </>
  );
}

export default AdviceChannelPackage;
