import { StyleSheet } from "react-native";
import { family } from "../../../../common";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  tabView: {
    width: mobilBrower()?"95%":"60%",
    alignItems: "center",
    flexDirection: "row",
    marginVertical: "3%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  tabViewButton: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    margin: 8,
    borderRadius: 10,
    padding:15,
    shadowColor: "#000",
    shadowOffset: { width: 0.5, height: 0.5 },
    shadowRadius: 2,
    shadowOpacity: 0.5,
    backgroundColor: "#0F1143",
  },
  tabsText: {
    color: "white",
    fontSize: responsiveText(14),
    fontFamily: family.semibold,
  },
});
