import { StyleSheet } from "react-native";
import fontFamily from "../../../../common/fontFamily";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  pathCard: {
    borderWidth: 0,
    borderColor: "lightgray",
    borderRadius: 5,
    shadowColor: "gray",
    shadowOpacity: 0.3,
    shadowRadius: 1,
    shadowOffset: {
      height: 1,
      width: 0.3,
    },
    marginVertical: 10,
  },
  cardView: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#EEF2F5",
    borderRadius: 10,
    borderWidth: 0,
    height: 160,
    shadowColor: "#000000",
    shadowOpacity: 0.2,
    shadowRadius: 1,
    shadowOffset: {
      height: 2,
      width: 0.5,
    },
    borderRadius: 15,
  },
  countryImg: {
    width: "100%",
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    height: "100%",
  },
  contentView: {
    padding: 2,
    flex: 1,
    marginTop: 10,
    height: "100%",
    marginLeft: 15,
    marginBottom: 10,
  },
  tagsView: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 3,
    marginBottom: 5,
  },
  tagText: {
    textAlign: "center",
    color: "white",
    fontSize: 10,
    padding: 5,
    marginLeft: 5,
    marginRight: 5,
  },
  title: {
    fontSize: mobilBrower() ? responsiveText(14) : responsiveText(16),
    color: "#000",
    fontWeight: "bold",
    alignSelf: "stretch",
    fontFamily: fontFamily.effra,
  },
  desc: {
    color: "gray",
    fontSize: mobilBrower() ? responsiveText(12) : responsiveText(14),

    fontWeight: "bold",
    alignSelf: "stretch",
    fontFamily: fontFamily.effra,
  },
  clearBtn: {
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    alignSelf: "flex-end",
    marginRight: 10,
    position: "absolute",
    bottom: 5,
    right: 5,
    height: 30,
    bottom: 20,
    borderRadius: 5,
    backgroundColor: "#c90f02",
  },
  clearText: {
    color: "white",
    fontSize: mobilBrower() ? responsiveText(12) : responsiveText(14),
  }
})
