import React from "react";
import { animated as a, useTrail } from "@react-spring/native";

export const TrailFromBottom = ({ children }) => {
  const components = React.Children.toArray(children);
  const springs = useTrail(components.length, {
    config: { mass: 5, tension: 2000, friction: 200, duration: 1500 },
    to: [
      {
        opacity: 1,
      },
    ],
    from: {
      opacity: 0,
    },
  });

  return springs.map(({ ...style }, index) => {
    return (
      <a.View key={index} style={[style, {
        width: "100%"
      }]}>
        {components[index]}
      </a.View>
    );
  });
};
