import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Dimensions,
  Image,
  ScrollView,
  Platform,
  FlatList,
  BackHandler,
  // TextInput
} from "react-native";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import AsyncStorage from "@react-native-community/async-storage";
const { width } = Dimensions.get("window");
import { getUserFilterNews, expiryToken } from "../actions/auth.actions";
import Loader from "../components/Loader";
import Icon from "react-native-vector-icons/FontAwesome";
import config from "../common/config";
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';
import { Config, Images, colors } from "../common";
import Toast from "react-native-simple-toast";
import NetInfo from "@react-native-community/netinfo";
import InputTextTemplate from "../components/InputTextTemplate";
import fontFamily from '../common/fontFamily';
var immigrationInterst = [];
var countryIntrest = [];
var expirystr = "Auth Token not valid or expired!";
const styles = StyleSheet.create({
  buttonstyle: {
    width: "85%",
    backgroundColor: "#EEF2F5",
    borderRadius: 100,
    height: 40,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginRight: 5,
    marginLeft: 5
  },
  countryIcon: {
    height: 50,
    aspectRatio: 1,
    resizeMode: 'contain',
    alignSelf: 'center',
  },
  flagbuttons: {
    height: 80,
    alignItems: 'center',
    ...Platform.select({
      web: {
        margin: 10
      },
      android: {
        width: '20%',
      },
      ios: {
        width: '20%',
      }
    }),

  },
  selectedbutton: {
    width: "85%",
    backgroundColor: colors.LIGHT_BLUE,
    borderRadius: 100,
    height: 40,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginRight: 15
  },
  selectedbuttontext: { textAlign: "center", color: colors.IMMIGREAT_YELLOW, fontWeight: "bold" },
  buttonstyletext: { textAlign: "center", color: "#fff" },
  custombuttonView: {
    flexDirection: "row",
    marginTop: 15,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginLeft: 15
  },
  buttonstyleview: {
    width: "85%",
    backgroundColor: "rgba(0, 2, 49, 0.5)",
    borderRadius: 100,
    height: 40,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginRight: 15
  },
  emailtextInput: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 2,
    width: width / 1.1,
    height: 50,
    padding: 15,
    backgroundColor: "#fff",
    marginBottom: 15,
    borderRadius: 30
  },
  loginView: {
    marginTop: 25,
    width: width / 1.1,
    height: 50,
    alignItems: "center",
    borderRadius: 100,
    flexDirection: "row",
    marginLeft: 15,
    marginRight: 15
  },
  countiesView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: heightPercentageToDP('2%'),
    alignSelf: 'center',
    justifyContent: 'space-between'
  },
  searchIcon: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center'
  },
  statusBar: {
    height: Platform.OS === 'ios' ? 40 : StatusBar.currentHeight,
    backgroundColor: colors.LIGHT_BLUE
  },
});

class NewsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      immibut1: false,
      immibut2: false,
      immibut3: false,
      immibut4: false,
      countrybut1: false,
      countrybut2: false,
      countrybut3: false,
      countrybut4: false,
      countryIntrest: [],
      countrybut5: false,
      countrybut6: false,
      countrybut7: false,
      countrybut8: false,
      countrybut9: false,
      countrybut10: false,
      checkconnection: false,
      isloading: false,
      search: ""
    };
  }
  componentWillMount() {
    immigrationInterst = [];
    countryIntrest = [];

    let filterData = this.props.navigation.state.params.postdata;
    // this.setState({ search: this.props.newsfilter });
    this.setState({ search: filterData.newsfilter });

    if (filterData.immigreatintrest != undefined) {
      var immigreatData = filterData.immigreatintrest;
      for (var i = 0; i < immigreatData.length; i++) {
        var code = parseInt(immigreatData[i]);
        var butstr = "immibut" + code;
        immigrationInterst.push(code);
        this.setState({ [butstr]: true });
      }
    }

    if (filterData.countryintrest != undefined) {
      var temparray = filterData.countryintrest;
      for (var i = 0; i < temparray.length; i++) {
        var code = parseInt(temparray[i]);
        var butstr = "countrybut" + code;
        countryIntrest.push(code);
        this.setState({ [butstr]: true });
      }
    }
    this.chechConnection();
  }

  countrySelection = (code) => {
    let tempCountry = [...this.state.countryIntrest];
    if (tempCountry.includes(code)) {
      tempCountry = tempCountry.filter((q) => q !== code)
    } else {
      tempCountry.push(code)
    }
    this.setState({ countryIntrest: tempCountry })
  }

  getImage = (item) => {
    if (this.state.countryIntrest.includes(item.code)) {
      return Platform.OS == 'web' ? item.webImg : item.webImg
    } else {
      return Platform.OS == 'web' ? item.webFlag : item.webFlag
    }
  }

  componentDidMount() {
    BackHandler.addEventListener("hardwareBackPress", this.handleBackButton);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener("hardwareBackPress", this.handleBackButton);
  }
  handleBackButton() {
    // Actions.pop();
    this.props.navigation.pop();
    return true;
    // this.backpresshandle();
  }
  async chechConnection() {
    NetInfo.fetch().then(state => {
      this.setState({ checkconnection: state.isConnected });
      if (!this.state.checkconnection) {
        Toast.show("Check your connection", Toast.LONG);
      }
    });
  }
  newsfilter = async values => {
    await this.chechConnection();
    const {
      getUser: { userDetails },
      authData: { token }
    } = this.props;
    if (!this.state.checkconnection) {
      Toast.show("Check your connection", Toast.LONG);
    } else {
      this.setState({ isloading: true });
      postdata = {};
      postdata.goal = immigrationInterst.toString();
      postdata.country = this.state.countryIntrest.toString();
      postdata.search = this.state.search ? this.state.search : "";
      // console.log("SEARCH data....", postdata);
      let filterQuery = postdata;
      let userID = this.props.guestLogin ? 0 : userDetails?.userid;
      let ptoken = this.props.guestLogin ? 'guest@news' : token
      const responce = await this.props.dispatch(
        getUserFilterNews(userID, ptoken, postdata)
      );
      this.setState({ isloading: false });
      if (responce.status == expirystr) {
        Toast.show(expirystr, Toast.LONG);
        const expityupdate = this.props.dispatch(expiryToken());
        AsyncStorage.clear();
        this.props.navigation.navigate("Login");
      } else {
        // console.log("SEARCH data....", postdata);

        postdata = {};
        postdata.newstoriesStatus = 0;
        postdata.search = true;
        postdata.searchdata = this.state.search;
        postdata.immigreatintrest = immigrationInterst;
        postdata.countryintrest = countryIntrest;
        // Actions.pop(postdata);
        // Actions.NewsStories(postdata);
        if (this.props.navigation.state.params.newsFilterCallback) {
          this.props.navigation.state.params.newsFilterCallback(postdata, filterQuery);
        }
        this.props.navigation.pop();
      }
    }
  };
  renderTextInput = field => {
    const {
      meta: { touched, error },
      label,
      secureTextEntry,
      maxLength,
      keyboardType,
      placeholder,
      input: { onChange, ...restInput }
    } = field;
    return (
      <View>
        <InputTextTemplate
          onChangeText={onChange}
          maxLength={maxLength}
          placeholder={placeholder}
          keyboardType={keyboardType}
          secureTextEntry={secureTextEntry}
          label={label}
          {...restInput}
        />
        {touched && error && <Text style={styles.errorText}>{error}</Text>}
      </View>
    );
  };
  _countryIntrest(code) {
    var index = countryIntrest.indexOf(code);
    var butstr = "countrybut" + code;
    if (index == -1) {
      countryIntrest.push(code);
      this.setState({ [butstr]: true });
    } else {
      countryIntrest.splice(index, 1);
      this.setState({ [butstr]: false });
    }
  }
  _immigreateIntrest(code) {
    var index = immigrationInterst.indexOf(code);
    var butstr = "immibut" + code;
    if (index == -1) {
      immigrationInterst.push(code);
      this.setState({ [butstr]: true });
    } else {
      immigrationInterst.splice(index, 1);
      this.setState({ [butstr]: false });
    }
  }
  _onPressButton = () => {
    // Actions.pop();
    this.props.navigation.pop();
  };
  render() {
    const {
      handleSubmit,
      createUser,
      getUser: { userDetails },
      loginUser
    } = this.props;
    return (
      <View style={{ backgroundColor: '#FFF', flex: 1 }}>
        {Platform.OS === 'ios' ? <View style={[styles.statusBar]} /> :
          <StatusBar barStyle="light-content" backgroundColor={colors.LIGHT_BLUE} />
        }
        <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
          {this.state.isloading ? <Loader /> : <View></View>}
          <TouchableOpacity activeOpacity={1} style={{ backgroundColor: "#fff" }}>
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                marginTop: 20,
                marginLeft: 10
              }}
            >
              <TouchableOpacity
                onPress={this._onPressButton}
                style={{ marginLeft: 10, marginTop: 30, width: 40, height: 40 }}
              >
                <View style={{ width: 40 }}>
                  <Icon name="chevron-left" size={20} color="#000" />
                </View>
              </TouchableOpacity>
              <Text
                style={[
                  styles.title,
                  {
                    width: width / 1.5,
                    textAlign: "center",
                    fontSize: 15,
                    fontWeight: "bold"
                  }
                ]}
              >
                News Filter
              </Text>
            </View>
            {/* <View style={[styles.emailtextInput, styles.loginView]}>
            <TextInput
              style={{ height: 50, width: width / 1.4, color: '#000' }}
              placeholder="Search"
              placeholderTextColor="#2C393F"
              onChangeText={search => this.setState({ search })}
              value={this.state.search}
            />
            <TouchableOpacity style={styles.searchIcon}>
              <Icon name="search" size={20} color="#DBDBDB" />
            </TouchableOpacity>
          </View> */}
            <View style={{ marginTop: 30 }}>
              <Text style={{ fontSize: 15, fontWeight: "bold", marginLeft: 15 }}>
                Select Immigration
              </Text>
              <View style={styles.custombuttonView}>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    onPress={() =>
                      this._immigreateIntrest(Config.immigerat[0].code)
                    }
                    style={
                      this.state.immibut1
                        ? styles.selectedbutton
                        : styles.buttonstyleview
                    }
                  >
                    <Text
                      style={
                        this.state.immibut1
                          ? styles.selectedbuttontext
                          : styles.buttonstyletext
                      }
                    >
                      {Config.immigerat[0].value}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    onPress={() =>
                      this._immigreateIntrest(Config.immigerat[1].code)
                    }
                    style={
                      this.state.immibut2
                        ? styles.selectedbutton
                        : styles.buttonstyleview
                    }
                  >
                    <Text
                      style={
                        this.state.immibut2
                          ? styles.selectedbuttontext
                          : styles.buttonstyletext
                      }
                    >
                      {Config.immigerat[1].value}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    onPress={() =>
                      this._immigreateIntrest(Config.immigerat[2].code)
                    }
                    style={
                      this.state.immibut4
                        ? styles.selectedbutton
                        : styles.buttonstyleview
                    }
                  >
                    <Text
                      style={
                        this.state.immibut4
                          ? styles.selectedbuttontext
                          : styles.buttonstyletext
                      }
                    >
                      {Config.immigerat[2].value}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.custombuttonView}>
                <View style={{ width: "55%" }}>
                  <TouchableOpacity
                    onPress={() =>
                      this._immigreateIntrest(Config.immigerat[3].code)
                    }
                    style={
                      this.state.immibut3
                        ? styles.selectedbutton
                        : styles.buttonstyleview
                    }
                  >
                    <Text
                      style={
                        this.state.immibut3
                          ? styles.selectedbuttontext
                          : styles.buttonstyletext
                      }
                    >
                      {Config.immigerat[3].value}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <View style={{ marginTop: heightPercentageToDP('6%') }}>
              <Text style={{ fontSize: 15, fontWeight: "bold", marginLeft: 15 }}>
                Select Countries
              </Text>
              <View style={styles.countiesView}>
                <FlatList
                  data={config.intrestcountry}
                  style={{ alignSelf: 'center' }}
                  contentContainerStyle={{ alignItems: 'center', justifyContent: 'center' }}
                  numColumns={5}
                  renderItem={({ item, index }) => (
                    <TouchableOpacity style={styles.flagbuttons} onPress={() => this.countrySelection(item.code)}>
                      <Image
                        style={styles.countryIcon}
                        source={this.getImage(item)} />
                      <Text style={{ fontSize: heightPercentageToDP('1.2%'), fontFamily: fontFamily.semibold }}>
                        {item.label}
                      </Text>
                    </TouchableOpacity>
                  )}
                />
              </View>
              {/* <View style={[styles.custombuttonView, { marginLeft: -8 }]}>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    testID='countryInterest0'
                    onPress={() =>
                      this._countryIntrest(Config.intrestcountry[0].code)
                    }
                  >
                    <Image
                      style={styles.countryIcon}
                      source={this.state.countrybut1
                        ? Images.usa_selected
                        : Images.usa_unavailable}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    testID='countryInterest1'
                    onPress={() =>
                      this._countryIntrest(Config.intrestcountry[1].code)
                    }
                  >
                    <Image
                      style={styles.countryIcon}
                      source={this.state.countrybut2
                        ? Images.canada_selected
                        : Images.canada_unavailable}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    testID='countryInterest0'
                    onPress={() =>
                      this._countryIntrest(Config.intrestcountry[2].code)
                    }
                  >
                    <Image
                      style={styles.countryIcon}
                      source={this.state.countrybut3
                        ? Images.australia_selected
                        : Images.australia_unavailable}
                    />
                  </TouchableOpacity>
                </View>
              </View> */}
              {/* <View style={[styles.custombuttonView, { marginLeft: -8 }]}>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    testID='countryInterest3'
                    onPress={() =>
                      this._countryIntrest(Config.intrestcountry[3].code)
                    }
                  >
                    <Image
                      style={styles.countryIcon}
                      source={this.state.countrybut4
                        ? Images.singapore_selected
                        : Images.singapore_unavailable}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    testID='countryInterest4'
                    onPress={() =>
                      this._countryIntrest(Config.intrestcountry[4].code)
                    }
                  >
                    <Image
                      style={styles.countryIcon}
                      source={this.state.countrybut5
                        ? Images.uk_selected
                        : Images.uk_unavailable}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    testID='countryInterest5'
                    onPress={() =>
                      this._countryIntrest(Config.intrestcountry[5].code)
                    }
                  >
                    <Image
                      style={styles.countryIcon}
                      source={this.state.countrybut6
                        ? Images.uae_selected
                        : Images.uae_unavailable}
                    />
                  </TouchableOpacity>
                </View>
              </View> */}
              {/* <View style={[styles.custombuttonView, { marginLeft: -8 }]}>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    testID='countryInterest0'
                    onPress={() =>
                      this._countryIntrest(Config.intrestcountry[6].code)
                    }
                  >
                    <Image
                      style={styles.countryIcon}
                      source={this.state.countrybut7
                        ? Images.germanyFlag
                        : Images.germanyUnavailableFlag}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    testID='countryInterest1'
                    onPress={() =>
                      this._countryIntrest(Config.intrestcountry[7].code)
                    }
                  >
                    <Image
                      style={styles.countryIcon}
                      source={this.state.countrybut8
                        ? Images.netherlandsFlag
                        : Images.netherlandsUnavilableFlag}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    testID='countryInterest0'
                    onPress={() =>
                      this._countryIntrest(Config.intrestcountry[8].code)
                    }
                  >
                    <Image
                      style={styles.countryIcon}
                      source={this.state.countrybut9
                        ? Images.new_zealand_selected
                        : Images.new_zealand_unavailable}
                    />
                  </TouchableOpacity>
                </View>
              </View> */}
              {/* <View style={[styles.custombuttonView, { marginLeft: -8 }]}>
                <View style={{ width: "33%" }}>
                  <TouchableOpacity
                    testID='countryInterest1'
                    onPress={() =>
                      this._countryIntrest(Config.intrestcountry[9].code)
                    }
                  >
                    <Image
                      style={styles.countryIcon}
                      source={this.state.countrybut10
                        ? Images.japan_selected
                        : Images.japan_unavailable}
                    />
                  </TouchableOpacity>
                </View>
              </View> */}
            </View>

            <View>
              <TouchableOpacity
                style={{
                  justifyContent: "center",
                  backgroundColor: colors.LIGHT_BLUE,
                  borderRadius: 10,
                  padding: 15,
                  alignSelf: "center",
                  marginTop: 20
                }}
                onPress={handleSubmit(this.newsfilter)}
              >
                <Text style={{ textAlign: "center", color: "#fff" }}>
                  Apply Filter
                </Text>
              </TouchableOpacity>
            </View>
          </TouchableOpacity>
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  getUser: state.userReducer.getUser,
  guestLogin: state.pathReducer.getImmipathDetails.guestLogin,
  authData: state.authReducer.authData
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "search"
    // validate
  })
)(NewsFilter);
