import { StyleSheet, Dimensions, Platform } from "react-native";
import { family } from "../../../common";
import { heightPercentageToDP } from "../../../common/responsiveScreen";
const { height, width } = Dimensions.get("window");

export const styles = StyleSheet.create({
  tabs: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
    marginTop: height / 2.8,
    backgroundColor: "#F5F5F5",
    alignItems: "flex-start",
    borderRadius: 15,
    alignSelf: "center",
    position: "relative",
    justifyContent: "space-around",
    marginTop: Platform.OS == "android" ? 22 : 20,
  },
  tabView: {
    backgroundColor: "white",
    alignItems: "flex-start",
  },
  forumTab: {
    alignItems: "center",
    width: "32%",
    borderRadius: 8,
    justifyContent: "center",
  },
  tabsText: {
    fontSize: Platform.OS == "web" ? heightPercentageToDP("1.8%") : heightPercentageToDP("1.3%"),
    fontFamily: family.effra,
    padding: 13,
    textTransform: "uppercase",
    color: "#77838F",
    backgroundColor: "white",
    borderRadius: 13,
    margin: 5,
    width: "100%",
    textAlign: "center",
  },
  oTab: {
    width: "92%",
    marginLeft: "5%",
    display: "flex",
    flexDirection: "row",
  },
  oButton: {
    flexDirection: "row",
    alignItems: "center",
    width: "50%",
  },
  oButtonA: {
    width: "95%",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    justifyContent: "center",
    marginTop: 10,
    alignSelf: "center",
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "rgb(230,230,230)",
    borderRadius: 15,
  },
  oTitle: {
    fontSize: heightPercentageToDP('1.5%'),
    fontFamily: family.effra,
    color: "black",
    marginLeft: -10,
    padding: 13,
  },
  plusText: {
    fontFamily: family.effra,
    color: "black",
    fontSize: heightPercentageToDP("3%"),
    textAlign: "center",
    bottom: 2,
  },
  plusView: {
    height: 30,
    width: 30,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "#F5F5F5",
  },
});
