import React, { useRef } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  StyleSheet,
} from "react-native";
import RNPicker from "../../../components/RNModalPicker";
import { Config } from "../../../common";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import { heightPercentageToDP } from "react-native-responsive-screen";
import fontFamily from "../../../common/fontFamily";
import config from "../../../common/config";
import { multiSelectStyle } from "../newSignUpUi.styles";
import { TrailFromRight } from "../newSignupUi.animation";
import Images from "../../../common/images";
import { isWeb, mobilBrower } from "../../../common/helpers";
import { responsiveText } from "../../../common/normalize";

const SignUpSection2 = ({
  styles,
  state,
  handleSubmit,
  handleRNSelection,
  handleHomeCountrySelection,
  customChipsRenderer,
  onSelectedItemObjectsChange,
  _selectedValue4,
  _renderSelectText,
  countrySelection,
  goalSelected,
  getImage,
}) => {
  const sectionedMultiSelectRef = useRef(null);

  const _iconRendere = ({ name, size = 12, style }) => {
    // flatten the styles
    const flat = StyleSheet.flatten(style);
    // remove out the keys that aren't accepted on View
    const { color, fontSize, ...styles } = flat;

    let iconComponent;
    // the colour in the url on this site has to be a hex w/o hash
    const iconColor =
      color && color.substr(0, 1) === "#" ? `${color.substr(1)}/` : "";

    const Down = (
      <Image
        source={Images.dropDownArrow}
        style={{
          width: 15,
          height: 15,
          marginRight: 5,
        }}
      />
    );
    const Cancel = (
      <Image source={Images.cancel} style={{ width: 15, height: 15 }} />
    );
    const Up = (
      <Image
        source={Images.dropDownArrow}
        style={{
          width: 15,
          height: 15,
          marginRight: 10,
          transform: [{ rotateX: "180deg" }],
        }}
      />
    );
    switch (name) {
      case "keyboard-arrow-up":
        iconComponent = Up;
        break;
      case "keyboard-arrow-down":
        iconComponent = Down;
        break;
      case "cancel":
        iconComponent = Cancel;
        break;
      default:
        iconComponent = null;
        break;
    }
    return iconComponent;
  };

  const ErrorView = ({ errorKey }) => state.errmessage[errorKey] ? (
    <Text style={styles.errortext}>
      {state.errmessage[errorKey]}
    </Text>
  ) : (
    null
  )

  const sortAplhabetically = () => {
    let newarr = Config.homeCountries.sort(function (a, b) {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    })
    return newarr
  }
  return (
    <View style={styles.slide1}>
      <Text style={[styles.title]}>Professional information</Text>
      <Text style={styles.title2}>To help us identify you</Text>
      <TrailFromRight>
        <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
          <RNPicker
            data={Config.countriesSelect}
            pickerTitle={"Country of Birth"}
            showSearchBar={true}
            disablePicker={false}
            changeAnimation={"fade"}
            searchBarPlaceHolder={"Search....."}
            showPickerTitle={true}
            listTextStyle={styles.listTextViewStyle}
            selectedText={state.countryOfBirth || "Where were you born?"}
            selectedTextStyle={{ ...styles.selectLabelTextStyle, color: state.countryOfBirth ? "#000" : "grey" }}
            pickerStyle={styles.pickerStyle}
            selectedValue={handleRNSelection}
            listContainerStyle={styles.pickerListContainer}
          />
        </View>
        <ErrorView errorKey={"countryOfBirth"} />
        <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
          <RNPicker
            data={sortAplhabetically()}
            pickerTitle={"Select Home Country"}
            showSearchBar={true}
            disablePicker={false}
            changeAnimation={"fade"}
            searchBarPlaceHolder={"Search....."}
            showPickerTitle={true}
            listTextStyle={styles.listTextViewStyle}
            selectedText={state.homeCountries || "What is your current home country?"}
            selectedTextStyle={{ ...styles.selectLabelTextStyle, color: state.homeCountries ? "#000" : "grey" }}
            pickerStyle={styles.pickerStyle}
            listContainerStyle={styles.pickerListContainer}
            selectedValue={handleHomeCountrySelection}
          />
        </View>
        <ErrorView errorKey={"homeCountries"} />
        <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
          <TouchableOpacity
            onPress={() => sectionedMultiSelectRef.current?._toggleSelector()}
            style={[
              styles.pickerContainerView,
            ]}
          >
            <SectionedMultiSelect
              styles={multiSelectStyle}
              modalWithTouchable
              modalWithSafeAreaView
              itemsFlatListProps={{
                style: isWeb() ? {
                  height: "85vh"
                } : {}
              }}
              items={config.education_profile}
              uniqueKey="id"
              hideSearch={true}
              subKey="children"
              ref={sectionedMultiSelectRef}
              showCancelButton={true}
              selectText="What is your qualification?"
              showDropDowns={true}
              single={true}
              chipsPosition="top"
              iconRenderer={_iconRendere}
              showChips={true}
              customChipsRenderer={customChipsRenderer}
              readOnlyHeadings={true}
              onSelectedItemObjectsChange={onSelectedItemObjectsChange}
              onSelectedItemsChange={(item) => _selectedValue4(item)}
              renderSelectText={_renderSelectText}
            />
          </TouchableOpacity>
        </View>
        <ErrorView errorKey={"highestAcademicAchievement"} />
        <View style={[styles.countryMainView, {}]}>
          <Text style={[styles.title]}>Countries of Interest</Text>
          <View style={styles.countiesView}>
            <FlatList
              data={config.intrestcountry}
              style={{ alignSelf: "center" }}
              contentContainerStyle={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
              columnWrapperStyle={{ justifyContent: "space-between" }}
              numColumns={4}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  style={styles.flagbuttons}
                  onPress={() => countrySelection(item.code)}
                >
                  <Image
                    style={[styles.countryIcon, { opacity: state.countryIntrest.includes(item.code) ? 1 : 0.6 }]}
                    source={getImage(item)}
                  />
                  <Text
                    style={{
                      fontSize: isWeb() ? responsiveText(11) : heightPercentageToDP("1.2%"),
                      fontFamily: fontFamily.semibold,
                      textAlign: "center",
                      fontWeight: isWeb() && !mobilBrower() ? "700" : "normal"
                    }}
                  >
                    {item.label}
                  </Text>
                </TouchableOpacity>
              )}
            />
          </View>
        </View>
        <ErrorView errorKey={"countryIntrest"} />
        <View style={styles.countryMainView}>
          <Text style={[styles.title, {}]}>Immigration Interest</Text>
          <View style={[styles.countiesView, {}]}>
            <FlatList
              data={config.immigerat}
              style={{ alignSelf: "center", marginBottom: 20, }}
              contentContainerStyle={{ width: "100%" }}
              numColumns={2}
              columnWrapperStyle={{ justifyContent: "space-between" }}
              ItemSeparatorComponent={() => <View style={{ marginTop: 10 }} />}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  onPress={() => goalSelected(item.code)}
                  style={[
                    state.immigrationInterst.includes(item.code)
                      ? styles.goalsButton
                      : styles.unimmigrationInterst,
                    {},
                  ]}
                >
                  <Text
                    style={[
                      styles.goalText,
                      {
                        color: state.immigrationInterst.includes(item.code)
                          ? "#F1AB32"
                          : "#BDBDBD",
                      },
                    ]}
                  >
                    {item.value}
                  </Text>
                </TouchableOpacity>
              )}
            />
          </View>
          <ErrorView errorKey={"immigrationInterst"} />
          <TouchableOpacity
            onPress={handleSubmit}
            style={[
              styles.nextButton,
            ]}
          >
            <Text style={styles.nextText}>Next</Text>
          </TouchableOpacity>
        </View>
      </TrailFromRight>
    </View>
  );
};

export default SignUpSection2;
