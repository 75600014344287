import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
} from "react-native";
import Images from "../../../../common/images";
import { styles as s } from "./immigreatSearch.styles";
import NavigationService from "../../../../navigation/NavigationService";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedGoalandCountries } from "../../../../actions/path.actions";
import AlertModal from "../../../AlertModal/AlertModal";
import { isWeb } from "../../../../common/helpers";

export const ImmigreatSearchHome = ({ nextTrack, _handleSameGoalCountries, history }) => {
  const [showErrorModal, setshowErrorModal] = useState(false);
  const selectedgoalandcountries = useSelector(state => state.pathReducer?.getImmipathDetails?.selectedgoalandcountries)

  const dispatch = useDispatch()
  const handleCardPress = (item) => {
    if ([4, 1].includes(item)) {
      setshowErrorModal(true);
      return
    }
    let obj = {
      goal: item,
      countries: selectedgoalandcountries?.countries || [],
      userInfo: selectedgoalandcountries?.userInfo || {}
    }
    if (selectedgoalandcountries?.goal == item) {
      _handleSameGoalCountries(true, false)
    } else {
      _handleSameGoalCountries(false, false)
    }
    dispatch(setSelectedGoalandCountries(obj))
    nextTrack(obj);
  };


  return (
    <View style={[s.main]}>
      <Text style={[s.textT]}>Choose your goal</Text>
      {showErrorModal ? (
        <AlertModal
          title="COMING SOON"
          description="We are working on this feature!"
          showConfirm
          confirmText="YAY"
          onConfirmPress={() => setshowErrorModal(false)}
        />
      ) : (
        <View />
      )}
      <TouchableOpacity style={{ flex: 1 }} activeOpacity={1}>
        <View style={[s.wrapper]}>
          <View style={[s.first]}>
            {/* <TouchableOpacity
              onPress={() => handleCardPress(1)}
              style={[s.card, { opacity: 0.5, height: "50%" }]}
              activeOpacity={0.6}
            >
              <Image
                resizeMode="contain"
                style={[
                  s.image,
                ]}
                source={Images.studyingWithBook}
              />
              <Text style={[s.text]}>STUDY</Text>
            </TouchableOpacity> */}
            <TouchableOpacity
              onPress={() => handleCardPress(2)}
              style={[s.card, { height: "45%" }]}
              activeOpacity={0.6}
            >
              <Image
                resizeMode="contain"
                style={[
                  s.image,
                ]}
                source={Images.workAtHome}
              />
              <Text style={[s.text]}>WORK</Text>
            </TouchableOpacity>
          </View>
          <View style={[s.second]}>
            <TouchableOpacity
              onPress={() => handleCardPress(3)}
              style={[s.card, { height: "45%" }]}
              activeOpacity={0.6}
            >
              <Image
                resizeMode="contain"
                style={[
                  s.image,
                ]}
                source={Images.houseImmigreatSearch}
              />
              <Text style={[s.text]}>PR</Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
              onPress={() => handleCardPress(4)}
              style={[s.card, { opacity: 0.5, height: "40%" }]}
              activeOpacity={0.6}
            >
              <Image
                resizeMode="contain"
                style={[
                  s.image,
                ]}
                source={Images.travellingAroundTheWorld}
              />
              <Text style={[s.text]}>TRAVEL</Text>
            </TouchableOpacity> */}

          </View>
        </View>
        <TouchableOpacity activeOpacity={0.6} onPress={() => isWeb() ? history.goBack() : NavigationService.goBack()} style={[s.backWrapper]}>
          <Text style={[s.backText]}>Back To Main Menu</Text>
        </TouchableOpacity>

      </TouchableOpacity>
    </View>
  );
};
