import { combineReducers } from 'redux'
import { Item } from 'native-base'
import { helpers } from '../common'

const initialState = {
  applicationReferralData: [],
  routes: [],
  index: 0,
  cartItems: []
}

const referralData = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_TAB_INDEX':
      return {
        ...state,
        index: action.payload,
        updatedAt: new Date()
      }

    case 'APPLICATION_REFERRAL_LOADING':
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        errors: null
      }

    case 'APPLICATION_REFERRAL_SUCCESS':
      const dataArray = action.payload
      const routes = []

      let dataArrays = []
      let resultArray = Object.keys(dataArray).map(function (key) {
        dataArrays.push({ header: key, content: dataArray[key] })
      })
      dataArrays.map((i, index) => {
        routes.push({ key: `${index}`, title: i.header, data: i.content })
      })

      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        applicationReferralData: dataArrays,
        routes: routes,
        index: 0,
        errors: null
      }

    case 'APPLICATION_REFERRAL_FAIL':
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        applicationReferralData: [],
        errors: action.payload
      }
    case 'ITEM_ADDED_TO_CART':
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload]
      }
    case 'ADD_STORE_ITEMS':
      return {
        ...state,
        storeItems: action.payload
      }
    case 'GUEST_STORE_ITEMS':
      return {
        ...state,
        guestStoreItems: action.payload
      }
    case 'CART_UPDATED':
      return {
        ...state,
        cartItems: [...action.payload]
      }
    case 'STORE_BASE_CURRENCY':
      return {
        ...state,
        baseCurrency: action.payload
      }
    case 'ORDER_CONFIRMATION_DATA':
      return {
        ...state,
        orderData: action.payload
      }
    case 'CLEAR_CART':
      return {
        ...state,
        cartItems: []
      }
    default:
      return state
  }
}

export default combineReducers({
  referralData
})
