import React from "react";
import {
  View,
  Modal,
  TouchableOpacity,
  Image,
} from "react-native";
import { styles } from "./prScoreModal.styles";
import PrScore from "./components/PrScore";
import Images from "../../common/images";

function PrScoreModal({ visible, PRPoints, onclose, cutOffData }) {

  return (
    <Modal visible={visible} transparent animationType="fade">
      <View style={styles.mainView}>
        <View style={[styles.absolute]}>
          <View style={styles.linearCard}>
            <View style={styles.innerView}>
              <View style={styles.titleView}>
                <TouchableOpacity
                  hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                  onPress={() => onclose()}
                  style={styles.closebutton}
                >
                  <Image
                    source={Images.cancelGrey}
                    style={{ height: 10, width: 10 }}
                  />
                </TouchableOpacity>
              </View>
              <PrScore PRPoints={PRPoints} cutOffData={cutOffData} onclose={onclose} />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
}

export default PrScoreModal;
