const BASE_URL = 'https://develop-dot-immigreat-qa.el.r.appspot.com'
const PRO_BASE_URL = "https://immigreat-qa.appspot.com";
export const getBaseURLFrom = (url) => {
  const baseUrl = global.IS_TEST_SERVER ? DEV_BASE_URL : PRO_BASE_URL
  return baseUrl.concat(url)
}
export default function api (path, params, method, token) {
  let errorDict = {
    isError: true,
    errorData: {}
  }

  let options
  options = {
    headers: {
      // Accept: "application/json",
      'Content-Type': 'application/json',
      ...(token && { Authorization: token })
    },
    method: method,
    ...(params && { body: JSON.stringify(params) })
  }
  // console.log('::::', BASE_URL + path, options, path)
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject({ responseBody: 'timeout' })
    }, 30000)
    fetch(BASE_URL + path, options)
      .then(response => {
        if (response.status == 401) {
          return response
        } else {
          return response.json()
        }
      })
      .then(jsonResponse => {
        jsonResponse.status == 401
          ? reject(jsonResponse)
          : resolve(jsonResponse)
      })
      .catch(error => {
        // console.log('error', error)
        reject(error)
      })
  })
}

function timeout (ms, promise) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(new Error('timeout'))
    }, ms)
    promise.then(resolve, reject)
  })
}
