import React from "react";
import { Card } from "../Card/Card";
import { FlatList, TouchableOpacity } from "react-native";
import { useHistory } from "react-router-dom";
import NavigationService from "../../../../navigation/NavigationService";
import { isWeb } from "../../../../common/helpers";

export const MainDiscussions = ({ discussions }) => {
  const history = useHistory();
  return (
    <FlatList
      data={discussions}
      renderItem={({ item }) => (
        <TouchableOpacity
          onPress={() => {
            isWeb() ? history.push("/discussion", {
              itemInView: item,
            }) :
              NavigationService.navigate("Discussion", {
                itemInView: item,
              })
          }
          }
        >
          <Card data={item} />
        </TouchableOpacity>
      )}
    />
  );
};
