import React, { useEffect, useRef, useState } from 'react';
import { FlatList, Image, Text, TouchableOpacity, View } from 'react-native';
import _ from "lodash";
import { styles } from './UserAnsweredInfo.styles';
import { mobilBrower } from '../../../../common/helpers';
import Collapsable from '../EligibilityReport/Collapsable/Collapsable';
import { helpers, Images } from '../../../../common';
import config from '../../../../common/config';
import { SelectionModal } from '../../../SelectionModal/SelectionModal';

function UserAnsweredInfo({ answeredInfo = {} }) {
    const [infos, setInfos] = useState([]);
    const [showInfo, setShowInfo] = useState(false);
    const [selectedCurrency, setselectedCurrency] = useState("USD");
    const [currencyDropdown, setcurrencyDropdown] = useState([]);
    const [currencyModalVisible, setCurrencyModalVisible] = useState({
        active: false,
        params: null,
    });
    const selectedCurrencyRef = useRef();
    selectedCurrencyRef.current = selectedCurrency

    const currencySelection = () => {
        setCurrencyModalVisible({
            active: true,
            params: {
                onSelectFn: setselectedCurrency,
                options: currencyDropdown,
                height: "68%",
                itemKey: "label",
                returnKey: "value",
            },
        });
    };

    useEffect(() => {
        helpers.refreshCurrency()
    }, [])

    useEffect(() => {
        const ValueMap = {
            "Maximum Duration For Visa Application": { formate: (value) => value + ` month${value > 1 ? "s" : ""}` },
            "Work Experience In Domain Of Study": { formate: (value) => value == 1 ? "Yes" : "No" },
            "Criminal Offense": { formate: (value) => (value === 1 ? "Yes" : "No") },
            "Marital Status": {
                formate: (value) => {
                    switch (value) {
                        case 1: return "Single";
                        case 2: return "Married";
                        default: return "Common-law partner"
                    }
                }
            },
            "Duration Of Work Experience": { formate: (value) => (value + ` year${value > 1 ? "s" : ""}`) },
            "Has Job Offer": {
                render: (countries) => Object.values(countries).includes(1) ? config.intrestcountry.map((country, indx) => {
                    if (countries[country.code]) {
                        return <Image key={indx} source={country.webFlag} style={{ width: 15, height: 15, margin: 5 }} />
                    }
                    return null;
                }) : "None"
            },
            "Maximum Visa Application Budget": {
                render: (value) => (<View style={styles.currencyDropDown}>
                    <Text>{helpers.getCurrencySymbol(selectedCurrencyRef.current) + " " + helpers.currencyConverter(selectedCurrencyRef.current, value)}</Text>
                    <TouchableOpacity
                        style={{
                            // width: 100,
                            marginLeft: 10,
                            // height: 40,
                            // marginRight: 25,
                            backgroundColor: "white",
                            alignItems: "center",
                            borderRadius: 10,
                            justifyContent: "center",
                            borderWidth: 1,
                            borderColor: "rgb(225,225,225)",
                        }}
                        onPress={currencySelection}
                    >
                        <Text
                            style={{
                                color: "black",
                            }}
                        >
                            {selectedCurrencyRef.current}
                        </Text>
                    </TouchableOpacity>
                </View>)
            }
        }
        const ignoreValue = ["First Name", "Last Name"]
        let infos = Object.keys(answeredInfo).reduce((infos, key) => {
            let label = _.startCase(key);
            if (!ignoreValue.includes(label)) {
                let info = {
                    label: label,
                    value: ValueMap[label]?.formate ? ValueMap[label]?.formate(answeredInfo[key]) : answeredInfo[key],
                    render: ValueMap[label]?.render
                }
                infos.push(info);
            }
            return infos;
        }, [])
        setInfos(infos);
    }, [answeredInfo])

    useEffect(() => {
        let _dropdown = [];
        config.currencyArray.forEach((s) => {
            s.label = s.value;
            _dropdown.push(s);
        });
        setcurrencyDropdown(_dropdown);
    }, []);

    return (
        <>
            <SelectionModal
                visible={currencyModalVisible.active}
                params={currencyModalVisible.params}
                setVisible={setCurrencyModalVisible}
            />
            <View style={styles.container}>
                {/* <Text style={styles.title}>{answeredInfo.first_name + " " + answeredInfo.last_name}</Text> */}
                <Collapsable
                    collapsed={showInfo}
                    extra={
                        <Image
                            source={Images.dropDownArrow}
                            style={{ height: 20, width: 20, transform: [{ rotate: showInfo ? "0deg" : "180deg" }] }}
                        />
                    }
                    onPress={() => { setShowInfo(!showInfo) }}
                    color={"#F1AB3250"}
                    title={answeredInfo.first_name + " " + answeredInfo.last_name}
                >
                    <View style={styles.contentContainer}>
                        <FlatList
                            data={infos}
                            numColumns={mobilBrower() ? 1 : 2}
                            columnWrapperStyle={mobilBrower() ? null : { width: "100%" }}
                            renderItem={({ item, index }) => (
                                <View style={[styles.labelValue, mobilBrower() ? {} : (index % 2 == 0 ? { marginRight: 10 } : { marginLeft: 10 })]} key={index + "i"}>
                                    <Text style={[styles.label, mobilBrower() ? { width: "50%" } : { flex: 0.6 }]}>{item.label}</Text>
                                    <Text style={[styles.value, mobilBrower() ? { width: "50%" } : { flex: 0.4 }]}>{item.render ? item.render(item.value) : item.value}</Text>
                                </View>
                            )}
                        />
                    </View>
                </Collapsable>
            </View>

            <View style={styles.divider} />
        </>)
}

export default UserAnsweredInfo