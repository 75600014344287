import React from "react";
import { View, Text, TouchableOpacity, FlatList, Linking } from "react-native";
import {
  getMetaDataDate,
  _setWeatherTitle,
} from "../../../../../utils/localityFactsDisplay.utils";
import { family, helpers } from "../../../../../common";
import { styles } from "../localityFactsDisplay.styles";
import colors from "../../../../../common/colors";
import InAppBrowser from 'react-native-inappbrowser-reborn'
import { responsiveText } from "../../../../../common/normalize";
import { isWeb } from "../../../../../common/helpers";

export const FactTypeC = ({ item, metaData }) => {

  const handleLinking = async () => {
    let cityname = Object.keys(item)[0] !== "fact" ? Object.keys(item)[0] : "";
    if (!isWeb() && await InAppBrowser.isAvailable()) {
      try {
        const result = await InAppBrowser.open(getMetaDataDate(
          "Weather (Spring, Summer, Fall, Winter)",
          metaData
        )?.source + cityname, helpers.inAPPbrowserOption)
      } catch (error) {
        await InAppBrowser.close()
      }
    } else {
      Linking.openURL(
        getMetaDataDate(
          "Weather (Spring, Summer, Fall, Winter)",
          metaData
        )?.source + cityname
      )
    }
  }

  return (
    <View style={{ alignItems: "center", flexDirection: "row" }}>
      {
        <FlatList
          data={Object.entries(item)}
          style={{ width: "100%" }}
          ListFooterComponent={() => {
            return (
              <>
                {
                  <View style={[styles.metaDataView, { marginTop: 25 }]}>
                    {getMetaDataDate(
                      "Weather (Spring, Summer, Fall, Winter)",
                      metaData
                    )?.last_updated ? (
                      <Text style={styles.metaDataView_date}>
                        {"Last updated: " +
                          getMetaDataDate(
                            "Weather (Spring, Summer, Fall, Winter)",
                            metaData
                          )?.last_updated}
                      </Text>
                    ) : (
                      <View />
                    )}
                    {getMetaDataDate(
                      "Weather (Spring, Summer, Fall, Winter)",
                      metaData
                    )?.source ? (
                      <TouchableOpacity
                        onPress={() => handleLinking()}
                      >
                        <Text
                          style={[
                            styles.metaDataView_date,
                            { textDecorationLine: "underline" },
                          ]}
                        >
                          View Source
                        </Text>
                      </TouchableOpacity>
                    ) : (
                      <View />
                    )}
                  </View>
                }
              </>
            );
          }}
          renderItem={({ item, index }) => {
            return (
              <View
                style={{
                  alignItems: "center",
                  marginTop: index === 0 ? 0 : index === 1 ? -40 : 10,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: family.semibold,
                    fontSize: responsiveText(18),
                    marginBottom: 5,
                    textAlign: "left",
                    color: colors.DARK_BLUE,
                    width: "85%",
                    marginTop: 10,
                  }}
                >
                  {item[0] !== "fact" ? item[0] + " (°C)" : ""}
                </Text>
                {
                  <View style={styles.weatherRow}>
                    {item[0] !== "fact" &&
                      item[1].map((q, index1) => (
                        <View style={{ justifyContent: "center" }}>
                          <Text
                            style={{
                              fontFamily: family.semibold,
                              fontSize: responsiveText(16),
                            }}
                          >
                            {_setWeatherTitle(index1)}
                          </Text>
                          <Text style={{ fontFamily: family.regular }}>
                            {q}
                          </Text>
                        </View>
                      ))}
                  </View>
                }
              </View>
            );
          }}
        />
      }
    </View>
  );
};
