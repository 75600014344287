import { StyleSheet } from "react-native";
import { responsiveText } from "../../common/normalize";

export const styles = StyleSheet.create({
  applyButton: {
    width: "93%",
    height: 50,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F1AB32",
  },
  applyButtonText: {
    color: "white",
    fontSize: responsiveText(14),
  },
  notificationHeaderText: {
    fontSize: responsiveText(22),
    fontWeight: "bold",
    marginLeft: 15,
    marginBottom: 10,
    alignSelf: "flex-start",
  },
});
