import { StyleSheet } from "react-native";
import colors from "../../../../common/colors";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  mainView: {
    width: "100%",
    alignSelf: "center"
  },
  item: {
    width: mobilBrower() ? "90%" : "48%",
    marginHorizontal: mobilBrower() ? 0 : "1%",
    borderRadius: 10,
    backgroundColor: "white",
    alignSelf: mobilBrower() ? "center" : "flex-start",
    shadowColor: "#000",
    marginBottom: 15,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
  },
  itemTitle: {
    marginLeft: 10,
    padding: 15,
    paddingLeft: 10,
    alignSelf: "flex-start",
    color: "#030135",
    width: "30%",
    fontSize: responsiveText(18),
  },
  indexBar: {
    width: "60%",
    backgroundColor: "white",
    borderRadius: 10,
    height: 18,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 15,
  },
  weatherRow: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "86%",
    marginTop: 5,
  },
  indexbarText: {
    fontSize: 10,
    zIndex: 9999,
  },
  currencyDropDown: {
    width: "50%",
  },
  backlButton: {
    height: 50,
    width: 50,
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    marginLeft: 15,
    justifyContent: "center",
  },
  metaDataView: {
    width: "93%",
    alignSelf: "center",
    flexDirection: "row",
    marginTop: 15,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgb(230,230,230)",
    padding: 15,
    borderRadius: 10,
  },
  metaDataView_date: {
    fontSize: responsiveText(16)
  },
  indexInnerBar: {
    borderRadius: 10,
    position: "absolute",
    left: 0,
    height: 16,
  },
  outerView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 6,
  },
  innerView: {
    marginLeft: 10,
    marginTop: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  tabIndicator: {
    backgroundColor: "#F1AB32",
    width: "90%",
    height: 8,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    marginBottom: 5,
  },
  tabLabel: {
    color: "white",
    marginBottom: 5,
    fontSize: responsiveText(18),
  },
  tab: {
    backgroundColor: colors.LIGHT_BLUE,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  tabContent: {
    width: mobilBrower() ? "100%" : "70%",
    alignSelf: "center"
  },
  tabHeader: {
    width: mobilBrower() ? "100%" : "70%",
    alignSelf: "center",
    flexDirection: "row"
  },
  tabItem: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});
