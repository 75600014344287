import React from 'react'
import {
    StyleSheet, Text, View, Image,
    Linking
} from "react-native";
import toolbarLogo from "../images/new_logo_toolbar.png";
import footerIcon from "../images/footerIcon.png";

import fontFamily from './fontFamily';
import { FaInstagram, FaFacebookF, FaAppStore, FaGooglePlay } from "react-icons/fa";
import { mobilBrower } from './helpers';
import { useHistory } from "react-router";

export default function WebFooter({ footerColor, textColor = "white", style = {} }) {
    const { push } = useHistory();

    const handlePress = (type) => {
        if (type == 'android') {
            Linking.openURL('https://play.google.com/store/apps/details?id=global.immigreat.app')
        } else if (type == 'ios') {
            Linking.openURL('https://apps.apple.com/in/app/immigreat/id1577394991')
        } else if (type == 'fb') {
            Linking.openURL('https://www.facebook.com/immigreatplatform/')
        } else {
            Linking.openURL('https://www.instagram.com/immigreat_official/')
        }
    }

    const handlequichAccess = (path) => {
        push(path)
    }

    return (
        <View style={[styles.mainView, { backgroundColor: footerColor ? footerColor : '#0F1143', }, style]}>
            <View style={styles.innerView}>
                <View style={styles.row1}>
                    <Image source={textColor ? footerIcon : toolbarLogo} style={styles.logo} />
                    <Text style={[styles.socialText, { color: textColor }]}>
                        Social Links
                    </Text>
                    <View style={styles.storeIconView}>
                        <View style={[styles.storeIconContainer, { backgroundColor: footerColor ? '#0F1143' : "#fff" }]}>
                            <FaAppStore color={footerColor ? "#fff" : "#000"} onClick={() => handlePress('ios')} size={18} />
                        </View>
                        <View style={[styles.storeIconContainer, { backgroundColor: footerColor ? '#0F1143' : "#fff" }]}>
                            <FaGooglePlay color={footerColor ? "#fff" : "#000"} onClick={() => handlePress('android')} size={18} />
                        </View>
                        <View style={[styles.storeIconContainer, { backgroundColor: footerColor ? '#0F1143' : "#fff" }]}>
                            <FaFacebookF color={footerColor ? "#fff" : "#000"} size={18} onClick={() => handlePress('fb')} />
                        </View>
                        <View style={[styles.storeIconContainer, { backgroundColor: footerColor ? '#0F1143' : "#fff" }]}>
                            <FaInstagram color={footerColor ? "#fff" : "#000"} size={18} onClick={() => handlePress('insta')} />
                        </View>
                    </View>
                    {/* <View style={styles.storeIconView}>
                        <TouchableOpacity onPress={() => handlePress('android')}>
                            <Image source={{ uri: 'https://immigreat.global/static/media/playstore.adfa6a6d.png' }} style={styles.playStoreImg} />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => handlePress('ios')}>
                            <Image source={{ uri: 'https://immigreat.global/static/media/appstore.4712184f.png' }} style={[styles.playStoreImg, { marginLeft: 15 }]} />
                        </TouchableOpacity>
                    </View> */}
                </View>
                <View style={styles.row2}>
                    <Text style={[styles.title, { color: textColor }]}>
                        Quick Access
                    </Text>
                    <Text onPress={() => handlequichAccess('/dashboard')} style={[styles.rowItems, { color: textColor }]}>
                        Dashboard
                    </Text>
                    <Text onPress={() => handlequichAccess('/pathExp')} style={[styles.rowItems, { color: textColor }]}>
                        Path Explorer
                    </Text>
                    <Text onPress={() => handlequichAccess('/Media')} style={[styles.rowItems, { color: textColor }]}>
                        Media
                    </Text>
                    <Text onPress={() => handlequichAccess('/adviceChannel')} style={[styles.rowItems, { color: textColor }]}>
                        Advice Channel
                    </Text>
                    <Text onPress={() => handlequichAccess('/about')} style={[styles.rowItems, { color: textColor }]}>
                        About Us
                    </Text>
                </View>
                <View style={styles.row2}>
                    <Text onPress={() => handlequichAccess('/dashboard')} style={[styles.title, { color: textColor }]}>
                        Support
                    </Text>
                    {/* <Text style={[styles.rowItems, { color: textColor }]}>
                        FAQ
                    </Text> */}
                    <Text style={[styles.rowItems, { color: textColor }]} onPress={() => handlequichAccess('/about')}>
                        Contact Us
                    </Text>
                    <Text style={[styles.rowItems, { color: textColor }]} onPress={() => handlequichAccess('/policies')}>
                        Privacy Policy
                    </Text>
                </View>
                {/* <View style={styles.row3}>
                    <Text style={[styles.title, { color: textColor }]}>
                        Stay up-to date by Immigreat
                    </Text>
                    <View style={[styles.inputBoxView, { borderColor: textColor }]}>
                        <TextInput
                            placeholder='Enter your e-mail address'
                            placeholderTextColor={textColor ? textColor : '#FFF'}
                            style={{ color: textColor }}
                        />
                    </View>
                    <TouchableOpacity style={styles.submitButton}>
                        <Text style={styles.submnitText}>
                            Submit
                        </Text>
                    </TouchableOpacity>
                </View> */}
            </View>
            <View style={styles.copyrightView}>
                <Text style={[styles.copyrightImmi, { color: textColor }]}>
                    © Immigreat Pvt. Ltd.
                </Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    mainView: {
        width: "100%",
        bottom: 0
    },
    innerView: {
        width: mobilBrower() ? '95%' : '70%',
        flexDirection: 'row',
        marginTop: mobilBrower() ? 10 : 40,
        alignSelf: 'center',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    logo: {
        height: 80,
        width: 80
    },
    row1: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    row2: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginLeft: 30
    },
    row3: {
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: mobilBrower() ? 15 : 30
    },
    inputBoxView: {
        borderBottomWidth: 1,
        borderColor: '#FFF',
        width: 180,
        marginTop: 25,
        paddingBottom: 5,
    },
    socialText: {
        color: 'white',
        marginTop: 25,
        fontFamily: fontFamily.effra,
        fontSize: 14,
        fontWeight: "bold",
        marginBottom: 5
    },
    title: {
        fontFamily: fontFamily.effra,
        fontWeight: '700',
        fontSize: 24,
        marginBottom: 10,
        color: 'white'
    },
    rowItems: {
        color: 'white',
        fontFamily: fontFamily.effra,
        fontSize: 16,
        marginVertical: 8,
        fontWeight: '400',
    },
    scoialLinkImg: {
        height: 20,
        width: 100
    },
    playStoreImg: {
        width: 100,
        height: 100,
        resizeMode: 'contain'
    },
    storeIconView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    storeIconContainer: {
        height: 30,
        width: 30,
        margin: 5,
        borderRadius: 15,
        alignItems: "center",
        justifyContent: "center"
    },
    submitButton: {
        width: '50%',
        height: 35,
        alignItems: 'center',
        backgroundColor: '#F1AB32',
        marginTop: 15,
        justifyContent: 'center'
    },
    copyrightView: {
        flexDirection: 'row',
        marginVertical: 20,
        alignSelf: 'center'
    },
    copyright: {
        color: 'white',
        fontSize: 15,
        fontFamily: fontFamily.effra,
    },
    copyrightImmi: {
        color: 'white',
        fontSize: 15,
        marginLeft: 8,
        fontFamily: fontFamily.effra,
        fontWeight: 'bold'
    },
    submnitText: {
        color: 'white',
        fontSize: 15,
        fontFamily: fontFamily.effra,
        fontWeight: 'bold'
    }
})