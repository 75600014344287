/** @format */
import React, {
  Platform,
  StyleSheet,
  Dimensions,
  PixelRatio,
} from 'react-native'

const { width, height } = Dimensions.get('window'),
  vw = width / 100,
  vh = height / 100,
  vmin = Math.min(vw, vh),
  vmax = Math.max(vw, vh)

export default {
  containerStyle: {
    shadowColor: '#000',
    backgroundColor: 'transparent',
    shadowOpacity: 0.4,
    shadowRadius: 8,
    shadowOffset: { width: 0, height: 12 },
    elevation: 10,
  },
  dim_layout: {
    width: width - 30,
    height: height / 5 - 10,
    backgroundColor: 'rgba(0,0,0,0.4)',
    padding: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  fab: {
    position: 'absolute',
    overflow: 'hidden',
    bottom: 15,
    right: 12,
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    backgroundColor: 'rgba(0, 0, 0, .85)',

    elevation: 5,
    shadowColor: '#000',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
  },
  horizontalLoading: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
  body: {
    flex: 1,
    backgroundColor: '#fff',
    paddingBottom: 40,
    marginBottom: 30,
  },
  mainCategoryText: {
    color: 'white',
    fontSize: 25,
  },
  count: {
    fontSize: 15,
    color: '#fff',
    marginTop: 4,
    backgroundColor: 'transparent',
  },
  image: {
    flex: 1,
    width: width - 30,
    height: height / 5 - 10,
    borderRadius: 5,
    resizeMode: 'cover',
  },
  borderView: {
    flex: 1,
    width: width - 30,
    height: height / 5 - 10,
    borderRadius: 6,
    overflow: 'hidden',
    justifyContent: 'center',
  },
  mainContent: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor:"rgb(0, 2, 49)",
  },
  image: {
    width: 320,
    height: 320,
  },
  text: {
    color: "rgba(255, 255, 255,1)",
    backgroundColor: "transparent",
    textAlign: "center",
    paddingHorizontal: 16,
    paddingVertical : 30,
    fontSize:18,
    fontWeight: 'bold',
    fontFamily: 'SourceSansPro-Regular'
  },
  title: {
    fontSize: 22,
    color: "white",
    backgroundColor: "transparent",
    textAlign: "center",
    marginBottom: 16,
  },
  buttonCircle: {
    width: "100%",
    height: 40,
    backgroundColor: "#fff",
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    marginBottom:10,
  },
};
