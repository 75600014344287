import { StyleSheet } from "react-native";
import { family } from "../../../../common";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  mainHeadText: {
    textAlign: "center",
    fontSize: mobilBrower()?responsiveText(24): responsiveText(32),
    marginTop: 15,
    marginBottom: 15,
    fontFamily: family.effra,
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  setupButton: {
    padding: 15,
    borderRadius: 15,
    backgroundColor: "#0F1143",
    marginBottom: 20,
  },
  setupButtonText: {
    color: "white",
    textAlign: "center",
    fontSize: 16,
    fontFamily: family.effra,

    textTransform: "uppercase",
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  expansionWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  itemTitle: {
    fontFamily: family.effra,
    fontSize: 18,
    marginLeft: 10,
  },
  countText: {
    fontSize: 15,
    marginRight: 15,
  },

  completePercentageText: {
    fontFamily: family.effra,
    alignItems: "center",
    color: "black",
    fontSize: 60,
    marginBottom: -12,
    display: "flex",
    justifyContent: "center",
    marginLeft: 10,
  },
  percentageInfoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: -80,
    marginTop: 10,
  },
  perInfoWrapper: {
    marginTop: -65,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  perWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  completeText: {
    fontFamily: family.effra,
    alignItems: "center",
    color: "black",
    fontSize: 15,
    marginBottom: -12,
    display: "flex",
    justifyContent: "center",
  },
  heading: {
    fontFamily: family.effra,
    fontSize: 17,
  },
  subHeading: {
    fontFamily: family.effra,
    fontSize: 16,
  },
  type: {
    fontFamily: family.effra,
    fontSize: 16,
    color: "black",
  },
  note: {
    fontFamily: family.effra,
    fontSize: 15,
    color: "gray",
  },
  helpModal: {
    backgroundColor: "#F1F1F1",
    borderRadius: 30,
    width: !mobilBrower() ? "40%" : "90%",
    height: "40%",
    paddingHorizontal: 15,
    paddingVertical: 10,
    shadowColor: "white",
    shadowOffset: { width: 0, height: 0.5 },
    shadowOpacity: 0.4,
    shadowRadius: 5,
    elevation: 12,
  },
  helpTitle: {
    fontSize: responsiveText(20),
    fontFamily: family.effra,
    flex: 1,
    textAlign: "center",
  }
});
