import React from "react";
import {
  View,
  Text
} from "react-native";
import { styles } from "../cityFacts.styles";

export const Ranking = ({ university }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        marginBottom: 10,
        alignSelf: "flex-start",
      }}
    >
      <View style={{ paddingHorizontal: 20 }}
      >
        <Text style={styles.tableContentText}>{university}</Text>
      </View>
    </View>
  );
};
