import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from "react-native";
import { BarChart as Chart } from "react-native-chart-kit";
import { family } from "../../../../common";
import { isWeb, mobilBrower } from "../../../../common/helpers";
const { width } = Dimensions.get("window");

const chartConfig = {
  backgroundColor: "#fff",
  backgroundGradientFrom: "#fff",
  backgroundGradientTo: "#fff",
  barPercentage: 0.7,
  barSpace: 0,
  propsForBackgroundLines: {
    strokeWidth: 1,
    strokeDasharray: "0",
  },
  propsForLabels: {
    fontSize: 9,
    padding: 7,
    paddingLeft: 12,
    // fontWeight: "bold",
  },
  color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  style: {
    borderRadius: 16,
  },
};

function BarChart({ label, datasets, title, scroll }) {
  const [data, setdata] = useState({
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        data: [20, 45, 28, 80, 99, 43],
      },
    ],
  });
  useEffect(() => {
    let temp = datasets;
    // temp[0].colors = [
    //     (opacity = 1) => `red`,
    //     (opacity = 1) => `#ff00ff`,
    //     (opacity = 1) => `teal`,
    //     (opacity = 1) => `yellow`,
    //     (opacity = 1) => `purple`,
    // ]
    setdata({
      labels: label,
      datasets: temp,
    });
    chartConfig.data = data.datasets;
  }, [datasets]);

  const getWidth = () => {
    if (data.datasets[0].data.length > 4 && data.datasets[0].data.length < 8) {
      return 700;
    } else if (
      data.datasets[0].data.length > 8 &&
      data.datasets[0].data.length < 15
    ) {
      return 1200;
    } else if (data.datasets[0].data.length > 15) {
      return 1500;
    } else if (data.datasets[0].data.length < 4) {
      return width;
    }
  };

  const getChartView = () => {
    if (scroll) {
      return (
        <ScrollView
          horizontal={true}
          style={{ maxHeight: 250 }}
          contentOffset={{ x: 0, y: 0 }}
          showsHorizontalScrollIndicator={false}
        >
          <TouchableOpacity activeOpacity={1}>
            <Chart
              data={data}
              height={250}
              // width={getWidth()}
              withCustomBarColorFromData
              style={{ alignSelf: "center" }}
              chartConfig={chartConfig}
              fromZero
              verticalLabelRotation={0}
              withInnerLines
              flatColor
              showBarTops={false}
            />
          </TouchableOpacity>
        </ScrollView>
      );
    } else {
      return (
        <Chart
          data={data}
          height={250}
          width={isWeb() && !mobilBrower() ? width * 0.6 * 0.45 : width / 1.2}
          withCustomBarColorFromData
          style={{ alignSelf: "center" }}
          chartConfig={chartConfig}
          fromZero
          verticalLabelRotation={0}
          withInnerLines
          flatColor
          showBarTops={false}
        />
      );
    }
  };

  return (
    <View>
      {Object.entries(data).length > 0 ? getChartView() : <View />}
      {title ? (
        <Text
          style={{
            fontFamily: family.semibold,
            marginTop: 10,
            alignSelf: "center",
            marginBottom: 10,
          }}
        >
          {title}
        </Text>
      ) : (
        <View />
      )}
    </View>
  );
}

export default BarChart;
