import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Modal,
} from "react-native";
import { Images, family } from "../common";
import LottieView from "lottie-react-native";
import * as Animatable from "react-native-animatable";
import { mobilBrower } from "../common/helpers";
import { responsiveText } from "../common/normalize";
import BlurBackground from "../components/BlurBackground";

function RewardModal(props) {
  const [animationState, setAnimationState] = useState(false);
  const handleClaim = () => {
    props.onClosed();
  };
  return (
    <Modal animationType="slide" transparent visible={props.visible}>
      <BlurBackground />
      <View style={styles.mainView}>
        <View style={styles.innerView}>
          {animationState ? (
            <Text style={styles.woho}>{props.title}</Text>
          ) : (
            <View />
          )}
          <View style={styles.animView}>
            {!animationState ? (
              <LottieView
                duration={3000}
                onAnimationFinish={() => setAnimationState(true)}
                style={styles.lotiStyle}
                source={require("../common/animations/unbox.json")}
                autoPlay
                loop={false}
              />
            ) : (
              <Animatable.View
                animation="fadeIn"
                duration={500}
                style={styles.rewardView}
              >
                <Text
                  style={[
                    styles.rewardText
                  ]}
                >
                  {props.price}
                </Text>
                <Image
                  source={Images.immibuck}
                  style={{
                    height: 120,
                    width: 120,
                    resizeMode: "contain",
                    marginTop: -10,
                    marginRight: 10,
                  }}
                />
              </Animatable.View>
            )}
          </View>

          <View style={styles.textContent}>
            {animationState ? (
              <Animatable.View animation="fadeIn" duration={500}>
                <TouchableOpacity
                  onPress={() => handleClaim()}
                  style={styles.claimBut}
                >
                  <Text style={styles.claimButText}>Claim your reward</Text>
                </TouchableOpacity>
              </Animatable.View>
            ) : (
              <View />
            )}
            {animationState ? (
              <Text style={styles.congratsText}>{props.text}</Text>
            ) : (
              <View />
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  mainView: {
    height: "100%",
    width: "100%",
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  innerView: {
    height: !mobilBrower() ? "60%" : "80%",
    width: !mobilBrower() ? "50%" : "90%",
    justifyContent: "space-between",
    borderRadius: 20,
    backgroundColor: "#303044",
  },
  rewardText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 50,
    marginRight: 10,
    marginTop: 0,
  },
  rewardView: {
    alignItems: "center",
  },
  textContent: {
    width: "80%",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    // position: 'absolute',
    // bottom: 10,
    paddingBottom: 28,
  },
  woho: {
    fontSize: 30,
    fontFamily: family.semibold,
    color: "white",
    marginTop: 25,
    paddingLeft: 5,
    paddingRight: 5,
    textAlign: "center",
    marginBottom: 30,
    alignSelf: "center",
  },
  congratsText: {
    fontSize: responsiveText(18),
    lineHeight: 28,
    width: "80%",
    textAlign: "center",
    fontFamily: family.semibold,
    color: "white",
  },
  unboxText: {
    fontSize: 17,
    color: "white",
    marginTop: 10,
  },
  cancelButton: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12.5,
    height: 25,
    alignSelf: "flex-end",
    top: 20,
    right: 20,
    width: 25,
    backgroundColor: "white",
  },
  animView: {
    // height: normalize(350),
    width: "90%",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  lotiStyle: {
    height: mobilBrower() ? "50vw" : "40vw",
    width: mobilBrower() ? "50vw" : "40vw",
    bottom: 20,
    marginLeft: 5,
    // alignItems: 'flex-start',
    resizeMode: "contain",
  },
  claimBut: {
    backgroundColor: "white",
    borderRadius: 10,
    paddingHorizontal: 20,
    alignItems: "center",
    marginBottom: 15,
    bottom: 0,
    justifyContent: "center",
    alignSelf: "center",
  },
  claimButText: {
    padding: 10,
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: family.semibold,
  },
});

export default RewardModal;
