import { StyleSheet } from "react-native";
import { family } from "../../../../common";
import { mobilBrower } from "../../../../common/helpers";

export const styles = StyleSheet.create({
  tabs: {
    display: "flex",
    padding: 7,
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "#F5F5F5",
    borderRadius: 10,
    width: mobilBrower() ? "90%" : "60%",
    marginLeft: mobilBrower() ? "5%" : 0,
    marginVertical: mobilBrower() ? 0 : "3%",
    elevation: 12,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },
  tab: {
    flex: 1
  },
  tabText: {
    fontFamily: family.effra,
    color: "black",
    backgroundColor: "white",
    textAlign: "center",
    padding: 10,
    borderRadius: 10,
  },
  list: {
    marginTop: -20,
    width: mobilBrower() ? "90%" : "72%"
  },
  backToProfileBtn: {
    height: 50,
    width: mobilBrower() ? "90%" : "60%",
    borderRadius: 15,
    marginBottom: mobilBrower() ? 50 : 15,
    backgroundColor: "#0F1143",
    display: "flex",
    justifyContent: "center",
  },
  bar: {
    width: 50,
    height: 5,
    borderRadius: 25,
    marginHorizontal: 5
  },
  container: {
    flex: 1
  }
});
