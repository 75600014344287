import { StyleSheet, Dimensions } from "react-native";
import { family, colors } from "../../common";
import fontFamily from "../../common/fontFamily";
import { mobilBrower} from "../../common/helpers";
const { height, width } = Dimensions.get("window");

export const notificationModalStyles = StyleSheet.create({
  mainView: {
    height: height,
    width: width,
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  innerView: {
    width: "90%",
    backgroundColor: "#F1F1F1",
    borderRadius: 15,
    height: "auto",
  },
  notifItem_type: {
    height: 40,
    width: 40,
    resizeMode: "contain",
  },
  notificationDetailActionText: {
    color: colors.LIGHT_BLUE,
    fontSize: 14,
    fontFamily: family.semibold,
    textAlign: "center",
    marginTop: 30,
    marginLeft: -20,
  },
  application_header: {
    paddingHorizontal: 25,
    marginVertical: 5,
    borderRadius: 5,
  },
  sub_header_text: {
    fontSize: 16,
    textAlign: "left",
    fontWeight: "500",
    color: "#000",
    marginBottom: 5,
    marginTop: 5,
  },
  box: {
    borderColor: "gray",
    padding: 10,
  },
  header_text: {
    fontSize: 18,
    textAlign: "left",
    fontWeight: "500",
    color: "black",
    marginTop: 15,
    fontWeight: "bold",
  },
  forwardIcon: {
    height: 20,
    width: 20,
    position: "absolute",
    right: 5,
    resizeMode: "contain",
  },
  closeIcon: {
    height: 15,
    width: 15,
  },
  application_details: {
    flex: 1,
    paddingLeft: 5,
    paddingRight: 5,
    marginHorizontal: !mobilBrower() ? 30 : 0,
  },
  description: {
    marginLeft: 5,
    fontFamily: fontFamily.regular,
    width: "75%",
    padding: 5,
  },
  notifItemDescription: {
    paddingHorizontal: 10,
    fontFamily: fontFamily.regular,
  },
  separator: {
    height: 1,
    width: "75%",
    marginTop: 10,
    alignSelf: "center",
    backgroundColor: "#CECECE",
  },
  absolute: {
    left: 0,
    width: "100%",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",
    borderRadius: 10,
    backgroundColor: "transparent",
    justifyContent: "center",
    bottom: 0,
    right: 0,
  },
  topIcon: {
    height: 55,
    width: 55,
    alignSelf: "center",
    marginBottom: 15,
    resizeMode: "contain",
  },
  Vector2: {
    height: 30,
    width: 30,
    resizeMode: "contain",
    position: "absolute",
    marginTop: -20,
    alignSelf: "center",
  },
  notificationHeaderText: {
    fontWeight: "bold",
    alignSelf: "flex-start",
    fontSize: 26,
  },
  notifInnerView: {
    width: "80%",
    height: "auto",
    backgroundColor: "#F1F1F1",
    borderRadius: 15,
    padding: 15,
    paddingTop: 0,
  },
  notifItemContainer: {
    backgroundColor: "#F1F1F1",
    borderRadius: 15,
    height: !mobilBrower() ? "100%" : "90%",
  },
  notifItem: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    margin: 5,
    marginHorizontal: !mobilBrower() ? 30 : 0,
    borderBottomWidth: 0.2,
    borderBottomColor: "grey",
  },
  leftIcon: {
    height: !mobilBrower() ? 50 : 30,
    width: !mobilBrower() ? 50 : 30,
    alignSelf: "center",
    resizeMode: "contain",
  },
  rightIcon: {
    height: !mobilBrower() ? 15 : 10,
    width: !mobilBrower() ? 15 : 10,
    alignSelf: "center",
    resizeMode: "contain",
  },
  notifItemText: {
    fontSize: 18,
    paddingLeft: 30,
    flex: 1,
    fontFamily: fontFamily.regular,
  },
  header: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 25,
  },
  contentContainer: {
    height: "97%",
  },
  loader: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },

  eventControlWrapper: {
    paddingRight: 15,
    marginTop: 15,
  },
  eventControlHeaderWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    zIndex: 1,
  },

  eventControllHeaderButtons: {
    backgroundColor: "#0F1143",
    width: "48%",
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },

  eventControlRegister: {
    backgroundColor: "#33AC09",
    borderRadius: 5,
    marginTop: 10,
  },

  eventControllText: {
    fontSize: 12,
    color: "white",
    padding: 12,
    textAlign: "center",
  },
  remindMedDropDown: {
    backgroundColor: "white",
    width: "100%",
    borderRadius: 5,
  },

  remindMedDropDownText: {
    padding: 10,
    borderTopColor: "rgb(225,225,225)",
    borderTopWidth: 1,
  },

  notifItemDescriptionCountryText: {
    paddingHorizontal: 10,
    fontFamily: fontFamily.regular,
    fontWeight: "bold",
    color: "gray",
    fontSize: 12,
  },

  notifItemDescriptionClassificationText: {
    paddingHorizontal: 10,
    fontFamily: fontFamily.regular,
    fontWeight: "bold",
    fontSize: 12,
    textTransform: "capitalize",
  },
});
