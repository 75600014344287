import React, { useEffect, useState } from 'react'
import { View, Platform, ScrollView, TouchableOpacity } from "react-native";
import { useSelector } from 'react-redux';
import { Network } from '../../../service/api';
import DiscoverYourselfCard from './DiscoverYourselfCard';
import RecommendedProducts from './RecommendedProducts';
import { styles as s } from "./styles";
import YourOppCard from './YourOppCard';
import { isEmpty } from "lodash";
import YourSolutionCard from './YourSolutionCard';

export default function DashboardCards({lastShortListedpath, isProfileComlpete}) {
  const [dashboardSolutions, setdashboardSolutions] = useState(null)
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const token = useSelector((state) => state.authReducer.authData.token);

  useEffect(() => {
    const getDashboardSolutions = async () => {
      const headers = {
        Accept: "application/json",
        Authorization: guestLogin ? 'guest@dashboard' : userDetails?.userid + "@" + token,
      };
      let solutions = await Network('/users/dashboard_solutions', "GET", null, headers)
      let _solutions = []
      !isEmpty(solutions?.dashboard_solutions) && Object.entries(solutions?.dashboard_solutions).forEach((item) => {
          if(item[1].length > 0) {
            item[1].forEach((q) => {
              q.type = item[0]
            })
          }
          _solutions = _solutions.concat(item[1])
      })
      setdashboardSolutions(_solutions)
    }
    getDashboardSolutions()
  }, [])

  return (
    <ScrollView showsVerticalScrollIndicator={false} scrollEnabled={false}>
      <TouchableOpacity activeOpacity={1} style={s.cardView}>
        <DiscoverYourselfCard  isProfileComlpete={isProfileComlpete}/>
        {
          <YourOppCard lastShortListedpath={lastShortListedpath} />
        }
        {
          dashboardSolutions?.length > 0 &&
          <YourSolutionCard discusisons={dashboardSolutions} />
        }
      </TouchableOpacity>
    </ScrollView>
  )
}
