import moment from 'moment';
import React, { useState, useRef, useEffect } from 'react'
import { View, Platform, ScrollView, Text, Image, Dimensions, TouchableOpacity } from "react-native";
import { FlatList } from 'react-navigation';
import { family, Images } from '../../../common';
import { styles as s } from "./styles";
import Carousel from 'react-native-snap-carousel';
import AlertModal from '../../../components/AlertModal/AlertModal';
import { useDispatch, useSelector } from 'react-redux';
import ProductInfoModal from '../../../components/ProductInfoModal/ProductInfoModal';
import config from '../../../common/config';
import { setStorebaseCurrency } from '../../../actions/referral.actions';
const { width, height } = Dimensions.get("window");

export default function RecommendedProducts({ products }) {
  const [slideIndex, setslideIndex] = useState(0)
  const dispatch = useDispatch()
  const sliderRef = useRef()
  const [showErrorModal, setshowErrorModal] = useState(false)
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);
  const [openModal, setopenModal] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [baseCurrency, setbaseCurrency] = useState("INR");

  const calculateDateDiff = (item) => {
    let a = moment(item?.date_asked)
    let b = moment(new Date())
    let dateDiff = moment().diff(a, "days")
    return dateDiff > 0 ? dateDiff + ' days ago' : 'today'
  }

  const handleAddtocart = (item) => {
    if (guestLogin) {
      setshowErrorModal(true)
      return
    }
  }

  useEffect(() => {
    getbaseCurrency = async () => {
      const headers = {
        Accept: "application/json",
        Authorization: guestLogin
          ? "guest@store"
          : userDetails?.userid + "@" + token,
      };
      let res = await Network("/data/get_country", "GET", null, headers);
      if (res.status == "Success") {
        let currency = config.countries_json.filter(
          (s) => s.id == res.country_code
        );
        dispatch(setStorebaseCurrency(currency[0].currency));
        setbaseCurrency(currency[0].currency);
      }
    };
    getbaseCurrency();
  }, []);

  const handleProceed = (item) => {
    let tt = {}
    tt[1] = item
    setselectedItem(tt)
    setopenModal(true)
  }
  const _renderItem = ({ item, index }) => {
    return (
      <>
        <TouchableOpacity onPress={() => alert(item?.immipath_name)} activeOpacity={1}
          style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
          <Image
            style={s.flag}
            source={{ uri: item.thumbnail }} />
          <View style={{ flex: 0.6, marginLeft: 15, overflow: 'visible', }}>
            <Text style={s.visaName}>
              {item?.title}
            </Text>
            <Text>
              {item?.price}
            </Text>
            <TouchableOpacity onPress={() => handleProceed(item)} style={s.AddtocartButton}>
              <Text style={s.AddtocartButtonText}>
                Add to Cart
              </Text>
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </>
    );
  }

  const handlearrowClick = (type) => {
    if (type == 'pre') {
      sliderRef.current.snapToPrev(animated = true)
    } else {
      sliderRef.current.snapToNext(animated = true)
    }

  }

  return (
    <View style={[s.discovercrd, { marginBottom: 120 }]}>
      <Text style={{ fontSize: 25, fontFamily: family.bebas }}>
        RECOMMENDED
      </Text>
      <Text style={{ fontSize: 32, fontFamily: family.bebas }}>
        PRODUCTS
      </Text>
      <View style={[s.bottomRow, {
        borderTopWidth: 1,
        paddingVertical: 5,
        borderColor: '#C4C4C4',
      }]}>
        <TouchableOpacity onPress={() => handlearrowClick('pre')} style={s.arrowStyle}>
          <Image source={Images.sliderLeft} style={{ height: 28, width: 28 }} />
        </TouchableOpacity>
        <Carousel
          ref={sliderRef}
          data={products}
          renderItem={_renderItem}
          sliderWidth={width}
          loop
          itemWidth={width}
        />
        <TouchableOpacity style={[s.arrowStyle, { position: 'relative', top: -15 }]} onPress={() => handlearrowClick('forword')}>
          <Image source={Images.sliderRight} style={{ height: 28, width: 28 }} />
        </TouchableOpacity>
      </View>
      {showErrorModal ? (
        <AlertModal
          title="Login"
          description="Login to explore more!"
          showCancel
          showConfirm
          confirmText="Login"
          cancelText="Not now"
          loginModal
          onCancelPress={() => setshowErrorModal(false)}
          onConfirmPress={() => setshowErrorModal(false)}
        />
      ) : (
        <View />
      )}
       {openModal ? (
        <ProductInfoModal
          selectedItem={selectedItem}
          openModal={openModal}
          baseCurrency={baseCurrency}
          onclose={() => setopenModal(false)}
        />
      ) : (
        <View />
      )}
    </View>
  )
}
