import React, { useEffect, useState } from "react";
import {
    View,
    Text,
    Dimensions,
    ScrollView
} from "react-native";
import { Config, family } from "../../../../common";
import { LineChart } from "react-native-chart-kit";
import { hasNotch } from "react-native-device-info";
import { styles } from "./PrPointsReport.styles";
import CircleProgressBar from "../../../CirlcleProgressBar/CircleProgressBar";
import { isWeb, mobilBrower } from "../../../../common/helpers";

const { width: SCREEN_WIDTH } = Dimensions.get("window");

function PrPointsReport({ PRPoints, cutOffData = {}, progressWidth = "100%", height = hasNotch() ? 220 : 180, width = isWeb() && !mobilBrower() ? SCREEN_WIDTH * 0.2 : SCREEN_WIDTH / 1.2 }) {
    const [dynamicChartObj, setdynamicChartObj] = useState({
        labels: [],
        datasets: [],
        legend: [],
    });
    const [cutOffNote, setcutOffNote] = useState("");

    const getLegendTitle = (title) => {
        let _title = title.split(" ");
        let str = "";
        _title.forEach((s) => {
            str += s.charAt(0);
        });
        return str;
    };

    const sortByMonth = (arr) => {
        var months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        arr.sort(function (a, b) {
            let _a = Object.entries(a);
            let _b = Object.entries(b);
            return (
                months.indexOf(_a[0][1].split(" ")[0]) -
                months.indexOf(_b[0][1].split(" ")[0])
            );
        });
        return arr;
    };

    const getSecondHighest = (arr) => {
        const secondHighest = arr?.sort((a, b) => b - a)[arr.length - 2];
        return secondHighest;
    };

    const canadaPotential = () => {
        const { datasets } = dynamicChartObj;
        const refCEC = getSecondHighest(datasets[0]?.data);
        const refPNP = getSecondHighest(datasets[1]?.data);
        const refScore = getSecondHighest(datasets[2]?.data);

        if (refScore >= Math.max(refCEC, refPNP)) {
            return "Potentially Eligible";
        }

        if (refScore >= refCEC) {
            return "Potentially Eligible for CEC";
        }

        return "Potentially Not Eligible";
    };

    useEffect(() => {
        let label = [];
        let legend = [];
        let datasets = [];
        let points = Object.entries(PRPoints);
        if (points.length > 0) {
            let cuttOff = Object.entries(cutOffData);
            cuttOff.length > 0 &&
                cuttOff.forEach((s) => {
                    if (s[1].cut_off_type == "dynamic") {
                        setcutOffNote(s[1].cut_off.note);
                        let programs = s[1].cut_off.programs;
                        programs = Object.entries(programs);
                        let history = Object.entries(programs[0][1].history);
                        history = sortByMonth(history);
                        history.forEach((r) => {
                            label.push(r[0]);
                        });
                        programs.forEach((q, index) => {
                            let history = Object.entries(q[1].history);
                            history = sortByMonth(history);
                            legend.push(getLegendTitle(q[0]));
                            let t1 = [];
                            let obj = {};
                            history.forEach((r) => {
                                t1.push(r[1]);
                                obj = {
                                    data: t1,
                                    color:
                                        index == 0
                                            ? (opacity = 1) => `#F6E80C`
                                            : (opacity = 1) => `#0B6ACF`,
                                };
                            });
                            datasets.push(obj);
                        });
                    }
                });

            if (PRPoints["Canada"]) {
                datasets.push({
                    data: [
                        PRPoints["Canada"],
                        PRPoints["Canada"],
                        PRPoints["Canada"],
                        PRPoints["Canada"],
                    ],
                    color: (opacity = 1) => `red`,
                });
                legend.push("Your score");
            }
            setdynamicChartObj({
                labels: label,
                datasets,
                legend,
            });
        }
    }, [cutOffData, PRPoints]);

    const getCountryName = (id) => {
        let country = Config.intrestcountry.filter((s) => s.code == id);
        return country[0].value;
    };
    //
    const getEligibleStatus = (score, country) => {
        let color = "";
        let cutoffDif = 0;
        let maxCutOff = 10000;
        let cutOff = Object.entries(cutOffData);
        cutOff.forEach((s) => {
            if (getCountryName(s[0]).toUpperCase() == country.toUpperCase()) {
                if (s[1].cut_off <= score) {
                    color = "#00FF00";
                } else {
                    cutoffDif = s[1].cut_off - score;
                    color = "red";
                }
                maxCutOff = s[1].max_val;
            }
        });
        return { color: color, diff: cutoffDif, maxCutOff };
    };

    return (
        <>
            <Text style={styles.title}>PR POINTS REPORT</Text>
            <View style={styles.pointsView}>
                <ScrollView
                    vertical={true}
                    showsVerticalScrollIndicator={false}
                    style={styles.pointsContent}
                >
                    {Object.entries(PRPoints).map((s, prPointsIndex) => {
                        const itemProgressPercentage =
                            s[0] == "Canada"
                                ? 70 * 100
                                : (s[1] / getEligibleStatus(s[1], s[0]).maxCutOff) *
                                100;
                        return (
                            <View
                                key={s[0]}
                                style={styles.contentContainer}
                            >
                                <View style={styles.wrapper}>
                                    <View
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                        }}
                                    >
                                        <View style={{ width: "35%" }}>
                                            <CircleProgressBar pathStyles={{ strokeWidth: 5, }} trailStyles={{ strokeWidth: 5, stroke: "#fff" }} tintColorSecondary={"#fff"} color={getEligibleStatus(s[1], s[0]).color} progressPercentage={itemProgressPercentage}>
                                                {(fill) => (
                                                    <View style={[styles.perInfoWrapper]}>
                                                        <View style={[styles.perWrapper]}>
                                                            <Text style={[styles.completePercentageText]}>
                                                                {Math.floor(
                                                                    (s[1] * fill) / itemProgressPercentage
                                                                )}
                                                            </Text>
                                                        </View>
                                                        <Text style={[styles.completeText]}>Points</Text>
                                                    </View>
                                                )}
                                            </CircleProgressBar>
                                        </View>
                                        <View
                                            style={{
                                                flex: 1,
                                                padding: "5%",
                                                paddingLeft: "7%",
                                            }}
                                        >
                                            <View style={{ flexDirection: "row" }}>

                                                <Text style={styles.countryName}>{s[0].toUpperCase()}</Text>
                                                {s[0] !== "Canada" ? (
                                                    getEligibleStatus(s[1], s[0]).color !==
                                                        "red" ? (
                                                        <Text
                                                            style={[
                                                                styles.prStatus,
                                                                { backgroundColor: "#008c00" },
                                                            ]}
                                                        >
                                                            Eligible
                                                        </Text>
                                                    ) : (
                                                        <View>
                                                            <Text
                                                                style={[
                                                                    styles.prStatus,
                                                                    { backgroundColor: "red" },
                                                                ]}
                                                            >
                                                                Not eligible
                                                            </Text>
                                                            {/* <Text
                                                                style={[
                                                                    styles.prStatus,
                                                                    { color: "red" },
                                                                ]}
                                                            >
                                                                {getEligibleStatus(s[1], s[0]).diff +
                                                                    " points below cut off"}
                                                            </Text> */}
                                                        </View>
                                                    )
                                                ) : (
                                                    <View>
                                                        <Text
                                                            style={[
                                                                styles.prStatus,
                                                                { backgroundColor: "orange" },
                                                            ]}
                                                        >
                                                            {canadaPotential()}
                                                        </Text>
                                                        {/* <Text
                                                            style={[
                                                                styles.prStatusNoteW,
                                                                { color: "gray" },
                                                            ]}
                                                        >
                                                            *Based on average cut off from last 4 months
                                                        </Text> */}
                                                    </View>
                                                )}
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        );
                    })}
                </ScrollView>
            </View>
        </>
    );
}

export default PrPointsReport;
