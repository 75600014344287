import { StyleSheet } from "react-native";
import { family } from "../../../../../../common";
import { mobilBrower } from "../../../../../../common/helpers";

export const styles = StyleSheet.create({
  applyButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(20,20,20,0.8)",
  },

  modal: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    width: mobilBrower() ? "90%" : "60%",
  },

  closeIconButton: {
    width: 20,
    height: 20,
  },

  closeIcon: {
    width: "100%",
    height: "100%",
  },

  closeWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  image: {
    width: "100%",
    height: 200,
    marginTop: 25,
    marginBottom: 25,
  },

  title: {
    marginBottom: 20,
    textAlign: "center",
    width: "80%",
    fontSize: 25,
    marginLeft: "10%",
    fontFamily: family.titilliumBold,
    textTransform: "capitalize",
  },

  text: {
    marginBottom: 15,
    marginLeft: "10%",
    textTransform: "capitalize",
  },

  premiumButtonText: {
    color: "white",
    padding: 15,
    fontFamily: family.effra,
  },

  listWrapper: {
    width: "90%",
    marginLeft: "5%",
    display: "flex",
    flexDirection: "row",
    marginBottom: 15,
    alignItems: "center",
  },
  listText: {
    marginLeft: 15,
    fontSize: 17,
    fontFamily: family.regular,
  },
});
