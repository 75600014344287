import React, { useEffect, useState, useRef } from "react";
import {
  View,
  StyleSheet,
  TextInput,
  Dimensions,
  TouchableOpacity,
  Image,
  SafeAreaView,
  ScrollView,
  KeyboardAvoidingView,
  Text,
  FlatList,
  Platform,
  useWindowDimensions,
} from "react-native";
import { useSelector } from "react-redux";
import Toolbar from "./../components/Toolbar";
import { Images, family } from "../common";
import { Network, apiPaths } from "../service/api";
import CustomerSupportQuesModal from "./CustomerSupportQuesModal";
import AsyncStorage from "@react-native-community/async-storage";
import * as Animatable from "react-native-animatable";
import DeviceInfo from "react-native-device-info";
import { TypingAnimation } from "react-native-typing-animation";
import HeaderComponent from "../common/NewHeader/NewHeader";
import send from "../images/send.png";
import new_logo_toolbar from "../images/new_logo_toolbar.png";
import { heightPercentageToDP } from "react-native-responsive-screen";
import fontFamily from "../common/fontFamily";
import { mobilBrower } from "../common/helpers";

const height = Dimensions.get("window").height;
const width = Dimensions.get("window").width;
let initialApicall = false;

function CustomerSupport(props) {
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const userImg = useSelector(
    (state) => state.authReducer.getUserProfilePic.profilePic
  );
  const [chatBotData, setchatBotData] = useState([]);
  const [Token, setToken] = useState();
  const [textInputResponse, settextInputResponse] = useState(false);
  const [chatEnded, setchatEnded] = useState(false);
  const [SelectedOption, setSelectedOption] = useState({});
  const [feedbackSent, setfeedbackSent] = useState(false);
  const [userInputState, setuserInputState] = useState(false);
  const [userInput, setuserInput] = useState("");
  // const [restartedChatOptionSelected, setrestartedChatOptionSelected] = useState(null)
  const [faqModalVisible, setfaqModalVisible] = useState(false);
  const [modalData, setmodalData] = useState(null);
  const window = Platform.OS == "web" ? useWindowDimensions() : null;
  const [loading, setloading] = useState(false);
  const inputRef = useRef(null);
  // const markerRef = useRef(null);
  const scrollViewref = useRef(null);
  const flatlistRef = useRef(null);
  const [newChatStart, setnewChatStart] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [leftChat, setleftChat] = useState(false);
  const [startAgainClicked, setstartAgainClicked] = useState(false);
  const [chatDataHistory, setchatDataHistory] = useState([]);
  const [userSelectionHistory, setuserSelectionHistory] = useState([]);
  // const [scrollToHeight, setscrollToHeight] = useState(null);

  const _profile = () => {
    // Actions.profile()
    initialApicall = false;
    props.navigation.navigate("NewProfileScreen");
  };

  useEffect(() => {
    const getBotQues = async () => {
      setloading(true);
      const value = await AsyncStorage.getItem("@auth_token");
      setToken(value);
      const url = `/data/get_chat_bot_response`;
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: value,
      };
      const payLoad = {
        start: true,
        end: false,
        query: "",
      };
      Network(url, "POST", payLoad, headers).then((res) => {
        if (res.status === "Success") {
          setTimeout(() => {
            setloading(false);
            setchatBotData([res.chatbot_response]);
          }, 300);
        }
      });
    };
    getBotQues();
  }, [startAgainClicked]);

  const endChat = () => {
    // setrestartedChatOptionSelected('No');
    let tempSelectedOption = SelectedOption;
    tempSelectedOption["leftChat"] = "No";
    setSelectedOption({ ...tempSelectedOption });
    setloading(true);
    setleftChat(false);
    const url = `/data/get_chat_bot_response`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: Token,
    };
    const payLoad = {
      start: false,
      end: true,
      query: "",
    };
    Network(url, "POST", payLoad, headers).then((res) => {
      setTimeout(() => {
        setloading(false);
        setShowResponse(true);
        if (
          SelectedOption[0] !== "FAQs" &&
          SelectedOption[0] !== "How to use this feature?" &&
          SelectedOption[0] !== "Work with us"
        ) {
          onMessageSend(false);
          setchatEnded(true);
          flatlistRef.current.scrollToEnd({ animated: false });
          setchatBotData([...chatBotData, res.chatbot_response]);
        } else {
          setchatBotData([...chatBotData]);
        }
        chatDataHistory.push([...chatBotData, res.chatbot_response]);
        userSelectionHistory.push(SelectedOption);
        setchatBotData([]);
        setuserInputState(false);
        setSelectedOption({});
      }, 4000);
    });
  };

  const sendUserResponse = async (option, item, index) => {
    setnewChatStart(false);
    setfeedbackSent(false);
    setfaqModalVisible(false);
    setchatBotData([...chatBotData]);
    let tempSelectedOption = SelectedOption;
    tempSelectedOption[index] = option;
    setSelectedOption({ ...tempSelectedOption });
    const url = `/data/get_chat_bot_response`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: Token,
    };
    if (!initialApicall) {
      setloading(true);
      const payLoad = {
        start: false,
        end: false,
        query: option,
      };
      Network(url, "POST", payLoad, headers).then((res) => {
        setTimeout(() => {
          setloading(false);
          setchatBotData([...chatBotData, res.chatbot_response]);
          if (option === "FAQs") {
            setmodalData(res.chatbot_response);
            setfaqModalVisible(true);
          }
          if (res.chatbot_response.response_type === "textinput") {
            setuserInputState(true);
            setTimeout(() => {
              inputRef.current.focus();
            }, 600);
          }
        }, 300);

        initialApicall = true;
      });
    }
    if (item.responses && !item.responses[option].is_response) {
      setloading(true);
      settextInputResponse(false);
      const payLoad = {
        start: false,
        end: true,
        query: "",
      };
      let leftChatMsg = "Is there anything else we can help you with?";
      let res = {
        leftChatMsg,
      };
      setTimeout(() => {
        setloading(false);
        setleftChat(true);
        setShowResponse(true);
        setchatBotData([...chatBotData, res]);
      }, 4000);
    }
    if (
      item.responses &&
      item.responses[option].is_response &&
      item.responses[option].response_type == "textinput"
    ) {
      setuserInputState(true);
      settextInputResponse(true);
      setchatBotData([
        ...chatBotData,
        { requestInput: item.responses[option].response },
      ]);
      setTimeout(() => {
        inputRef.current.focus();
      }, 1000);
    }
  };

  const getFeedbackType = () => {
    if (SelectedOption[0] === "Technical Problem") {
      return 2;
    } else if (SelectedOption[0] === "Feedback/Complaint") {
      return 1;
    } else if (SelectedOption[0] === "How to use this feature?") {
      return 3;
    } else {
      return 1;
    }
  };

  const getFeedbackBody = () => {
    const deviceType = DeviceInfo.getBrand();
    const deviceModel = DeviceInfo.getModel();
    const totalMemory = DeviceInfo.getTotalMemory();
    const usedMemory = DeviceInfo.getUsedMemory();
    if (userInput !== "") {
      SelectedOption.userInput = userInput;
    }
    let joinedOptions = Object.values(SelectedOption).join("-");

    if (joinedOptions.split("-")[joinedOptions.split("-").length - 1] == "No") {
      let temp = joinedOptions.split("-");
      temp.splice(temp.length - 1, 1);
      joinedOptions = temp.join("-");
    }
    const body = {
      user_feedback: joinedOptions,
      user_id: userDetails?.userid,
      feedback_type: getFeedbackType(),
      email_id: userDetails.email_address,
      device_type: deviceType + " " + deviceModel,
      free_memory_data: totalMemory - usedMemory,
    };
    return body;
  };

  const onMessageSend = (endChat) => {
    const url = apiPaths.feedBack;
    const endChatUrl = `/data/get_chat_bot_response`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: Token,
    };
    const payLoad = {
      start: false,
      end: true,
      query: "",
    };
    inputRef.current.blur();
    !feedbackSent &&
      Network(url, "POST", getFeedbackBody(), headers).then((res) => {
        if (textInputResponse) {
          userInput !== "" && setchatBotData([...chatBotData, { userInput }]);
          let leftChatMsg = "Is there anything else we can help you with?";
          let res2 = {
            leftChatMsg,
          };
          setloading(true);
          setfeedbackSent(true);
          setTimeout(() => {
            setloading(false);
            setleftChat(true);
            userInput !== ""
              ? setchatBotData([...chatBotData, { userInput }, res2])
              : setchatBotData([...chatBotData, res2]);
          }, 4000);
        }
        endChat &&
          Network(endChatUrl, "POST", payLoad, headers).then((res) => {
            setloading(true);
            setTimeout(() => {
              setloading(false);
              setchatBotData([
                ...chatBotData,
                { userInput },
                res.chatbot_response,
                { leftChatMsg: "if there anything else we can help you with?" },
              ]);
            }, 900);
            setTimeout(() => {
              setleftChat(true);
              flatlistRef.current.scrollToEnd({ animated: false });
            }, 2200);
          });
        setuserInput("");
      });
  };

  const LoadingComp = () => {
    return (
      <View
        style={{ marginLeft: 10, alignItems: "center", flexDirection: "row" }}
      >
        <View style={styles.imgComtanier}>
          <Image source={Images.toolbarLogo} style={styles.useImgStyle} />
        </View>
        <TypingAnimation
          dotColor="black"
          dotMargin={3}
          dotAmplitude={3}
          dotSpeed={0.15}
          dotRadius={2.5}
          dotX={12}
          dotY={6}
        />
      </View>
    );
  };
  const _handleStartAgain = () => {
    const url = apiPaths.feedBack;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: Token,
    };
    if (
      SelectedOption[0] !== "FAQs" &&
      SelectedOption[0] !== "How to use this feature?" &&
      SelectedOption[0] !== "Work with us" &&
      !feedbackSent
    ) {
      Network(url, "POST", getFeedbackBody(), headers);
    }
    let tempSelectedOption = SelectedOption;
    tempSelectedOption["leftChat"] = "Yes";
    setSelectedOption({ ...tempSelectedOption });
    chatDataHistory.push(chatBotData);
    userSelectionHistory.push(SelectedOption);
    // setrestartedChatOptionSelected('yes');
    setchatBotData([]);
    setSelectedOption({});
    setleftChat(false);
    setnewChatStart(true);
    setShowResponse(false);
    setuserInput("");
    setuserInputState(false);
    initialApicall = false;
    setTimeout(() => {
      setstartAgainClicked(!startAgainClicked);
    }, 500);
  };
  const _handleGoBack = () => {
    initialApicall = false;
    props.navigation.pop();
  };
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS == "ios" ? "padding" : ""}
      enabled
      style={{ flex: 1 }}
    >
      <View style={styles.container}>
        {/* <Toolbar
                    isBackIcon={true}
                    isImage
                    isPath
                    title={'Customer Support'}
                    didTapOnBack={() => {
                        initialApicall = false
                        props.navigation.pop();
                    }}
                    profileView={() => _profile()}
                    noHeight={false}
                    simpleCase={true}
                    unlockedState={[]}
                    navigation={props.navigation}
                /> */}
        <HeaderComponent screenTitle="Customer Support" showback />
        {Platform.OS == "web" ? (
          <Text
            style={{
              fontSize: heightPercentageToDP("3.5%"),
              fontFamily: fontFamily.semibold,
              alignSelf: "center",
              marginTop: heightPercentageToDP("2%"),
            }}
          >
            Customer Support
          </Text>
        ) : null}
        <SafeAreaView />
        <ScrollView
          ref={scrollViewref}
          onContentSizeChange={(width, height) =>
            scrollViewref.current.scrollTo({ y: height })
          }
          style={{ padding: 8 }}
        >
          <TouchableOpacity
          activeOpacity={1}
          disabled={ Platform.OS == "web" && !mobilBrower()}
            style={[
              styles.chatView,
              {
                width:
                  Platform.OS == "web" && !mobilBrower()
                    ? window.width / 2
                    : mobilBrower()
                    ? "90%"
                    : "100%",
                alignSelf: "center",
              },
            ]}
          >
            {chatDataHistory.length > 0 ? (
              chatDataHistory.map((item, outerindex) =>
                item.map((history, index) => (
                  <View
                    key={index}
                    style={{
                      marginTop: 25,
                      marginBottom: chatBotData.length - 1 == index ? 45 : 0,
                    }}
                  >
                    {history?.question || history?.requestInput ? (
                      <View
                        style={{ alignItems: "center", flexDirection: "row" }}
                      >
                        <View style={styles.imgComtanier}>
                          <Image
                            source={
                              Platform.OS == "web"
                                ? new_logo_toolbar
                                : Images.toolbarLogo
                            }
                            style={styles.useImgStyle}
                          />
                        </View>
                        <Animatable.View
                          animation="fadeIn"
                          duration={200}
                          style={[styles.msgBubble, styles.msgBubbleLeft]}
                        >
                          <Text style={[styles.msg]}>
                            {history?.question
                              ? history?.question
                              : history?.requestInput}
                          </Text>
                        </Animatable.View>
                      </View>
                    ) : (
                      <View />
                    )}
                    {history?.leftChatMsg ? (
                      <View
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          marginTop: 10,
                        }}
                      >
                        <View style={styles.imgComtanier}>
                          <Image
                            source={
                              Platform.OS == "web"
                                ? new_logo_toolbar
                                : Images.toolbarLogo
                            }
                            style={styles.useImgStyle}
                          />
                        </View>
                        <Animatable.View
                          animation="fadeIn"
                          duration={200}
                          style={[styles.msgBubble, styles.msgBubbleLeft]}
                        >
                          <Text style={[styles.msg]}>
                            {history?.leftChatMsg}
                          </Text>
                        </Animatable.View>
                      </View>
                    ) : (
                      <View />
                    )}
                    <Animatable.View
                      animation="fadeIn"
                      duration={2800}
                      style={styles.optionsView}
                    >
                      {userSelectionHistory[outerindex][index] === undefined &&
                      !loading &&
                      history.userInput === undefined &&
                      history.leftChatMsg == undefined ? (
                        item?.options?.map((optionsItem, optindex) => (
                          <TouchableOpacity
                            key={optindex}
                            onPress={() =>
                              sendUserResponse(optionsItem, history, index)
                            }
                            style={styles.optionContanier}
                          >
                            <View style={styles.label}>
                              <Text style={styles.labeltext}>
                                {optindex + 1}
                              </Text>
                            </View>
                            <Text numberOfLines={2} style={styles.optionsText}>
                              {optionsItem}
                            </Text>
                          </TouchableOpacity>
                        ))
                      ) : (
                        <View style={{ width: width }}>
                          <View
                            style={{
                              alignItems: "center",
                              flexDirection: "row",
                              alignSelf: "flex-end",
                            }}
                          >
                            <View
                              style={[
                                styles.msgBubble,
                                styles.msgBubbleRight,
                                { marginTop: history.userInput ? 0 : 15 },
                              ]}
                            >
                              <Text
                                numberOfLines={2}
                                style={[styles.msg, { color: "black" }]}
                              >
                                {history.leftChatMsg
                                  ? userSelectionHistory[outerindex]["leftChat"]
                                  : history.userInput
                                  ? history.userInput
                                  : userSelectionHistory[outerindex][index]}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.imgComtanier,
                                { alignSelf: "flex-end", marginLeft: 0 },
                              ]}
                            >
                              <Image
                                source={userImg}
                                style={styles.useImgStyle}
                              />
                            </View>
                          </View>
                        </View>
                      )}
                    </Animatable.View>
                  </View>
                ))
              )
            ) : (
              <View />
            )}
            <FlatList
              data={chatBotData}
              ref={flatlistRef}
              showsVerticalScrollIndicator={false}
              extraData={SelectedOption}
              ListFooterComponent={() => {
                if (loading) {
                  return LoadingComp();
                } else {
                  return (
                    <View>
                      {leftChat ? (
                        <View
                          style={[
                            styles.optionsView,
                            { marginRight: 40, marginTop: -20 },
                          ]}
                        >
                          <TouchableOpacity
                            onPress={() => _handleStartAgain()}
                            style={styles.optionContanier}
                          >
                            <View style={styles.label}>
                              <Text style={styles.labeltext}>{1}</Text>
                            </View>
                            <Text style={styles.optionsText}>Yes</Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => endChat()}
                            style={styles.optionContanier}
                          >
                            <View style={styles.label}>
                              <Text style={styles.labeltext}>{2}</Text>
                            </View>
                            <Text style={styles.optionsText}>No</Text>
                          </TouchableOpacity>
                        </View>
                      ) : (
                        <View />
                      )}
                    </View>
                  );
                }
              }}
              renderItem={({ item, index }) => {
                return (
                  <View
                    key={index}
                    style={{
                      marginTop: 25,
                      marginBottom: chatBotData.length - 1 == index ? 45 : 0,
                    }}
                  >
                    {item?.question || item?.requestInput ? (
                      <View
                        style={{ alignItems: "center", flexDirection: "row" }}
                      >
                        <View style={styles.imgComtanier}>
                          <Image
                            source={
                              Platform.OS == "web"
                                ? new_logo_toolbar
                                : Images.toolbarLogo
                            }
                            style={styles.useImgStyle}
                          />
                        </View>
                        <Animatable.View
                          animation="fadeIn"
                          duration={200}
                          style={[styles.msgBubble, styles.msgBubbleLeft]}
                        >
                          <Text style={[styles.msg]}>
                            {newChatStart
                              ? "How may I help you?"
                              : item?.question
                              ? item?.question
                              : item?.requestInput}
                          </Text>
                        </Animatable.View>
                      </View>
                    ) : (
                      <View />
                    )}
                    {item?.leftChatMsg ? (
                      <View
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          marginTop: 10,
                        }}
                      >
                        <View style={styles.imgComtanier}>
                          <Image
                            source={
                              Platform.OS == "web"
                                ? new_logo_toolbar
                                : Images.toolbarLogo
                            }
                            style={styles.useImgStyle}
                          />
                        </View>
                        <Animatable.View
                          animation="fadeIn"
                          duration={200}
                          style={[styles.msgBubble, styles.msgBubbleLeft]}
                        >
                          <Text style={[styles.msg]}>{item?.leftChatMsg}</Text>
                        </Animatable.View>
                      </View>
                    ) : (
                      <View />
                    )}
                    <Animatable.View
                      animation="fadeIn"
                      duration={2800}
                      style={styles.optionsView}
                    >
                      {SelectedOption[index] === undefined &&
                      !loading &&
                      item.userInput === undefined &&
                      SelectedOption["leftChat"] == undefined ? (
                        item?.options?.map((optionsItem, optindex) => (
                          <TouchableOpacity
                            key={optindex}
                            onPress={() =>
                              sendUserResponse(optionsItem, item, index)
                            }
                            style={styles.optionContanier}
                          >
                            <View style={styles.label}>
                              <Text style={styles.labeltext}>
                                {optindex + 1}
                              </Text>
                            </View>
                            <Text numberOfLines={2} style={styles.optionsText}>
                              {optionsItem}
                            </Text>
                          </TouchableOpacity>
                        ))
                      ) : (
                        <View style={{ width: width }}>
                          {!chatEnded &&
                          item.requestInput &&
                          item.userInput !== undefined ? (
                            <View
                              style={{
                                alignItems: "center",
                                flexDirection: "row",
                                alignSelf: "flex-end",
                              }}
                            >
                              <View
                                style={[
                                  styles.msgBubble,
                                  styles.msgBubbleRight,
                                  { marginTop: item.userInput ? 0 : 15 },
                                ]}
                              >
                                <Text
                                  numberOfLines={2}
                                  style={[styles.msg, { color: "black" }]}
                                >
                                  {item.leftChatMsg
                                    ? SelectedOption["leftChat"]
                                    : item.userInput
                                    ? item.userInput
                                    : SelectedOption[index]}
                                </Text>
                              </View>
                              <View
                                style={[
                                  styles.imgComtanier,
                                  { alignSelf: "flex-end", marginLeft: 0 },
                                ]}
                              >
                                <Image
                                  source={userImg}
                                  style={styles.useImgStyle}
                                />
                              </View>
                            </View>
                          ) : !chatEnded && item.requestInput == undefined ? (
                            <View
                              style={{
                                alignItems: "center",
                                flexDirection: "row",
                                alignSelf: "flex-end",
                              }}
                            >
                              <View
                                style={[
                                  styles.msgBubble,
                                  styles.msgBubbleRight,
                                  { marginTop: item.userInput ? 0 : 15 },
                                ]}
                              >
                                <Text
                                  numberOfLines={3}
                                  style={[styles.msg, { color: "black" }]}
                                >
                                  {item.leftChatMsg
                                    ? SelectedOption["leftChat"]
                                    : item.userInput
                                    ? item.userInput
                                    : SelectedOption[index]}
                                </Text>
                              </View>
                              <View
                                style={[
                                  styles.imgComtanier,
                                  { alignSelf: "flex-end", marginLeft: 0 },
                                ]}
                              >
                                <Image
                                  source={userImg}
                                  style={styles.useImgStyle}
                                />
                              </View>
                            </View>
                          ) : (
                            <View />
                          )}

                          {item.responses && showResponse && (
                            <View
                              style={{
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <View style={[styles.imgComtanier]}>
                                <Image
                                  source={
                                    Platform.OS == "web"
                                      ? new_logo_toolbar
                                      : Images.toolbarLogo
                                  }
                                  style={styles.useImgStyle}
                                />
                              </View>
                              <View
                                style={[
                                  styles.msgBubble,
                                  styles.msgBubbleLeft,
                                  { alignSelf: "flex-start", marginTop: 25 },
                                ]}
                              >
                                <Text style={styles.msg}>
                                  {
                                    item?.responses[SelectedOption[index]]
                                      ?.response
                                  }
                                </Text>
                              </View>
                            </View>
                          )}
                        </View>
                      )}
                    </Animatable.View>
                  </View>
                );
              }}
            />
          </TouchableOpacity>
        </ScrollView>
        <View
          style={[
            styles.inputBoxContainer,
            {
              width:
                Platform.OS == "web" && !mobilBrower()
                  ? window.width / 3
                  : mobilBrower()
                  ? "90%"
                  : undefined,
              alignSelf: "center",
            },
          ]}
        >
          <View
            style={[
              styles.inputBoxView,
              { backgroundColor: userInputState ? "#dedede" : "#a1a1a1" },
            ]}
          >
            <TextInput
              placeholder="Enter Message"
              ref={inputRef}
              value={userInput}
              editable={userInputState}
              onChangeText={(text) => setuserInput(text)}
              style={styles.inputBox}
            />
          </View>
          <TouchableOpacity
            disabled={!userInputState}
            onPress={() => onMessageSend(true)}
            style={styles.sendContainer}
          >
            <Image
              style={styles.sendButton}
              source={Platform.OS == "web" ? send : Images.send}
            />
          </TouchableOpacity>
        </View>
      </View>
      <CustomerSupportQuesModal
        visible={faqModalVisible}
        sendUserResponse={sendUserResponse}
        questions={modalData}
      />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  chatView: {
    marginTop: height / 9,
    ...Platform.select({
      web: {
        shadowColor: "#000",
        shadowRadius: 4,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.5,
        padding: 5,
      },
    }),
  },
  msgBubble: {
    backgroundColor: "#757F88",
    marginRight: 70,
    maxWidth: "70%",
  },
  leftChat: {
    backgroundColor: "#BDBDBD",
    maxWidth: "70%",
    alignSelf: "center",
    borderRadius: 15,
    marginTop: 10,
  },
  msgBubbleLeft: {
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    borderBottomRightRadius: 25,
  },
  msgBubbleRight: {
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    alignSelf: "flex-end",
    marginLeft: 0,
    marginRight: 10,
    marginTop: 15,
    color: "black",
    backgroundColor: "#D9D9D9",
  },
  label: {
    height: 25,
    width: 25,
    borderRadius: 25 / 2,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#030135",
  },
  labeltext: {
    fontWeight: "800",
    color: "white",
  },
  optionsText: {
    fontWeight: "800",
    padding: 10,
    fontSize: heightPercentageToDP("1.5%"),
    color: "#030135",
  },
  optionsView: {
    alignSelf: "flex-end",
    marginTop: 10,
  },
  startAgainButton: {
    backgroundColor: "#030135",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  optionContanier: {
    flexDirection: "row",
    alignItems: "center",
    ...Platform.select({
      android: {
        width: "60%",
      },
      ios: {
        width: "60%",
      },
    }),
  },
  msg: {
    fontWeight: "800",
    padding: 10,
    flexWrap: "wrap",
    color: "white",
    fontSize: heightPercentageToDP("1.5%"),
  },
  useImgStyle: {
    height: 45,
    width: 45,
    borderRadius: 45 / 2,
  },
  yesNoButtonView: {
    flexDirection: "row",
  },
  yesText: {
    fontFamily: family.semibold,
    color: "white",
    padding: 10,
  },
  yesBut: {
    backgroundColor: "#030135",
    marginLeft: 10,
  },
  imgComtanier: {
    height: 45,
    width: 45,
    borderRadius: 45 / 2,
    backgroundColor: "#030135",
    marginRight: 10,
    marginLeft: 10,
  },
  buttonContanier: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "35%",
    alignSelf: "center",
  },
  inputBoxContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    marginBottom: 20,
    marginTop: Platform.OS == "web" ? heightPercentageToDP("3%") : 10,
    paddingRight: 10,
  },
  sendContainer: {
    marginLeft: 10,
    justifyContent: "center",
    alignItems: "center",
    height: mobilBrower() ? 45 : 35,
    flexDirection: "row",
    backgroundColor: "#030135",
    width: mobilBrower() ? 45 : 35,
    borderRadius: mobilBrower() ? 45 / 2 : 35 / 2,
  },
  inputBoxView: {
    flex: 1,
    alignSelf: "center",
    borderRadius: 20,
  },
  inputBox: {
    padding: 10,
    height: 45,
    fontSize: heightPercentageToDP("1.5%"),
  },
  sendButton: {
    height: mobilBrower() ? 25 : 12,
    width: mobilBrower() ? 25 : 12,
  },
});
export default CustomerSupport;
