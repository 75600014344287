import { StyleSheet } from "react-native";
import fontFamily from "../../../common/fontFamily";
import { mobilBrower } from "../../../common/helpers";
import { responsiveText } from "../../../common/normalize";
import { heightPercentageToDP } from "../../../common/responsiveScreen";

export const styles = StyleSheet.create({
  contactUsImg: {
    height: heightPercentageToDP(40),
    width: "100%",
    opacity: 0.5
  },
  emptyText: {
    fontFamily: fontFamily.bebas,
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: responsiveText(25),
    marginTop: -heightPercentageToDP(6),
    opacity: 0.5
  },
  mainView: {
    flexDirection: "row",
    height: heightPercentageToDP(60)
  },
  chatContainer: {
    flex: 1,
  }
})