import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  ScrollView,
  Image,
} from "react-native";
import { Config } from "../../../../common";
import helpers, { isWeb } from "../../../../common/helpers";
import * as Animatable from "react-native-animatable";
import { useDispatch, useSelector } from "react-redux";
import { Network } from "../../../../service/api";
import Images from "../../../../common/images";
import colors from "../../../../common/colors";
// import ApplicationDetailsModal from "../../../../pages/ApplicationDetailsModal/ApplicationDetailsModal";
import ApplicationDetailsModal from "../../../../components/ApplicationDetailsModal";
import { styles } from "./Path.styles";
import AlertModal from "../../../AlertModal/AlertModal";
import { viewImmipathId } from "../../../../actions/path.actions";
import { getAppTrackerList } from "../../../../actions/dashboard.actions";
import { useHistory, useLocation } from "react-router-dom";

export const Paths = ({ pathInfo }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = isWeb() ? useLocation() : '';
  const immipathToView = useSelector(state => state.pathReducer?.getImmipathDetails?.immipathToView)
  const [appTrackerList, setApptracekerList] = React.useState([]);
  const [loadingState, setloadingState] = React.useState(false);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const [applicationID, setapplicationID] = React.useState("");
  const [immipath_id, setimmipath_id] = React.useState(null);
  const Token = useSelector((state) => state.authReducer.authData.token);
  const [isDetailsView, setisDetailsView] = React.useState(false);
  const contentData = useSelector(
    (state) => state.eligibilityReducer.eligibilityData.contentData
  );
  const [confirmDelApp, setConfirmDelApp] = useState(null);

  const deleteApplication = async (application_id) => {
    const url = `/users/${userDetails?.userid}/app_tracker/${application_id}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };
    Network(url, "DELETE", null, headers).then((res) => {
      // console.log('Delete ka response: ', res)
      if (res.status && res.status == "Success") {
        let updatedappTrackerList = appTrackerList.filter(
          (app) => app.application_id !== application_id
        );
        dispatch(getAppTrackerList(userDetails?.userid, userDetails?.userid + "@" + Token))
        setApptracekerList(updatedappTrackerList);
      } else if (res.status && res.status == expirystr) {
        this.setState({ modalApplicationsVisible: false }, () => {
          helpers.handleSessionExpiry();
        });
      }
    });
  };

  useEffect(() => {
    if (immipathToView?.app_ref) {
      const { immipath_id, immipath_name } = immipathToView?.selectedPath;
      goToDetails(immipathToView?.app_ref, immipath_id, immipath_name)
    }
  }, [immipathToView])

  const goToDetails = (id, immipath_id, immipath_name) => {
    setapplicationID(id);
    setimmipath_id({ id: immipath_id, name: immipath_name });
    setisDetailsView(true);
  };

  const getImage = (id) => {
    let image = "";
    switch (id) {
      case 1:
        image = Images.usFlag;
        break;
      case 2:
        image = Images.CANADAFlag;
        break;
      case 3:
        image = Images.Ausflag;
        break;
      case 4:
        image = Images.singaporeFlag;
        break;
      case 5:
        image = Images.UKFLag;
        break;
      case 6:
        image = Images.UAEFLAG;
        break;
      case 7:
        image = Images.GermanyFlag;
        break;
      case 8:
        image = Images.netherlanFlag;
        break;
      case 9:
        image = Images.NZFlag3;
        break;
      case 10:
        image = Images.JapanFlag;
        break;
      default:
        break;
    }
    return image;
  };

  const _getAppTrackerList = async () => {
    setloadingState(true);
    const url = `/users/${userDetails?.userid}/app_tracker`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };
    Network(url, "GET", null, headers)
      .then((res) => {
        if (res.status && res.status == "Success") {
          setApptracekerList(res.applications);
          if (pathInfo?.app_ref) {
            goToDetails(pathInfo.app_ref, pathInfo.selectedPath.immipath_id, pathInfo.selectedPath.immipath_name)
          }
        }
        setloadingState(false);
      })
      .catch((err) => {
        console.log(err);
        setloadingState(false);
      });
  };

  React.useEffect(() => {
    (async () => {
      await _getAppTrackerList();
    })();
  }, [pathInfo]);

  const _setisDetailsView = () => {
    if (isWeb()) {
      history.replace(location.pathname, null)
    }
    setisDetailsView(false);
    dispatch(viewImmipathId(null))
  };

  const _renderItem = (item, index) => {
    let { application_id, application_made_timestamp, immipath_id, goal_ids } =
      item;
    const paths = item.immipath_data;
    application_made_timestamp = moment().diff(
      application_made_timestamp,
      "days"
    );
    return (
      <>
        <Animatable.View
          duration={1000}
          animation="fadeIn"
          style={styles.pathCard}
        >
          <TouchableOpacity
            onPress={() =>
              goToDetails(application_id, item.immipath_id, paths.immipath_name)
            }
          >
            <View
              style={styles.cardView}
            >
              <View
                style={{
                  width: "40%",
                  height: "100%"
                }}
              >
                {paths.country_id && (
                  <Image
                    source={getImage(paths.country_id)}
                    style={styles.countryImg}
                  />
                )}
              </View>
              <View
                style={styles.contentView}
              >
                <View
                  style={styles.tagsView}
                >
                  <ScrollView horizontal>
                    {goal_ids.map((goal, i) => {
                      return (
                        <View
                          style={[
                            {
                              marginLeft: i == 0 ? 0 : 5,
                              borderRadius: 5,
                              backgroundColor: colors.LIGHT_BLUE,
                            },
                          ]}
                          key={i + "g"}
                        >
                          <Text
                            style={[
                              styles.tagText,
                            ]}
                          >
                            {
                              Config.immigerat.find((immi) => immi.code == goal)
                                .value
                            }
                          </Text>
                        </View>
                      );
                    })}
                  </ScrollView>
                </View>
                {/* <Text style={styles.appText}>Search ID: {application_id}</Text> */}
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 5,
                    justifyContent: "flex-start",
                  }}
                >
                  <Text
                    numberOfLines={2}
                    style={styles.title}
                  >
                    {paths.immipath_name}
                  </Text>
                </View>
                <Text
                  style={[
                    styles.desc,
                  ]}
                >
                  {application_made_timestamp == 0
                    ? "Today"
                    : `${application_made_timestamp} days ago`}
                </Text>
              </View>
              <TouchableOpacity
                style={styles.clearBtn}
                onPress={() => {
                  setConfirmDelApp({ applicatonId: application_id, applicationName: paths.immipath_name })
                }}
              >
                <Text
                  style={styles.clearText}
                >
                  Clear
                </Text>
              </TouchableOpacity>
            </View>
          </TouchableOpacity>
        </Animatable.View>
      </>
    );
  };

  return (
    <View
      style={{
        // width:
        //   Platform.OS == "web" && !mobilBrower() ? window.width / 2.2 : "100%",
        flex: 1,
        marginBottom: 15,
      }}
    >
      <FlatList
        contentContainerStyle={{ marginHorizontal: 20, marginTop: 10 }}
        data={appTrackerList}
        keyExtractor={(item, index) => index.toString()}
        ListFooterComponent={<View style={{ marginBottom: 10 }} />}
        ListEmptyComponent={() => {
          return (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <Text style={[{ textAlign: "center", fontSize: 16 }]}>
                No paths shortlisted. You can shortlist paths in path explorer
                or quick search.
              </Text>
            </View>
          );
        }}
        renderItem={({ item, index }) => {
          return (
            <TouchableOpacity activeOpacity={1}>
              {_renderItem(item, index)}
            </TouchableOpacity>
          );
        }}
        showsVerticalScrollIndicator={true}
      />
      {isDetailsView && applicationID !== "" && (
        <ApplicationDetailsModal
          eligibilityQues={contentData}
          token={Token}
          immipath_id={immipath_id}
          goBack={_setisDetailsView}
          userid={userDetails?.userid}
          id={applicationID}
          detailsModalVisible={applicationID !== ""}
        />
      )}
      {confirmDelApp ?
        <AlertModal
          title={confirmDelApp.applicationName}
          description="Are you sure you want to delete this application?"
          showConfirm
          showCancel
          onCancelPress={() => { setConfirmDelApp(null) }}
          onConfirmPress={() => {
            deleteApplication(confirmDelApp.applicatonId);
            setConfirmDelApp(null)
          }}
        /> : null}
    </View>
  );
};
