import { StyleSheet, Dimensions } from "react-native";
import { heightPercentageToDP } from "react-native-responsive-screen";
import { family } from "../../../../common";
import fontFamily from "../../../../common/fontFamily";
const { width, height } = Dimensions.get("window");

export const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "90%",
  },
  nextWrapper: {
    backgroundColor: "#F1AB32",
    padding: 12,
    borderRadius: 25,
    width: 160,
    marginTop: 10,
  },
  nextText: {
    color: "white",
    fontFamily: family.titilliumBold,
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 17,
  },
  textT: {
    fontFamily: family.titilliumBold,
    fontSize: 25,
    textAlign: "center",
    width: "80%",
    lineHeight: 55,
    marginBottom: 20,
    marginTop: -25,
  },
  excludedTitle: {
    fontFamily: family.titilliumBold,
    fontSize: 25,
    textAlign: "center",
    width: "80%",
    lineHeight: 55,
    alignSelf: 'center',
    marginBottom: 20,
  },
  list: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    width: "90%",
    marginLeft: "5%",
    padding: 15,
    borderRadius: 15,
    borderColor: "rgb(230,230,230)",
    borderWidth: 1,
  },
  noDataText: {
    textAlign: 'center',
    fontFamily: family.roboto,
    fontSize: 17
  },
  univImage: {
    height: 70,
    width: 70,
    borderRadius: 15,
  },
  infoWrapper: {
    width: "60%",
    marginLeft: 15,
  },
  exIcon: {
    height: 30,
    width: 30,
  },
  imageView: {
    backgroundColor: 'white',
    alignItems: 'center',
    height: 85,
    width: 85,
    borderWidth: 0.5,
    borderColor: '#BDBDBD',
    justifyContent: 'center'
  },
  univIcon: {
    fontSize: 20,
    fontFamily: family.robotoBlack,
    color: '#800000'
  },

  scoreView: {
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    marginLeft: -15,
    borderRadius: 20
  },
  scoreText: {
    fontSize: 16,
    fontFamily: family.robotoBlack,
  },
  univNameText: {
    fontFamily: family.effra,
    fontSize: 17,
  },
  locationInfoText: {
    marginTop: 2
  }
});
