import { StyleSheet, Platform } from "react-native";
import fontFamily from "../../../../common/fontFamily";
import { isWeb, mobilBrower } from "../../../../common/helpers";
import { heightPercentageToDP } from "react-native-responsive-screen";

export const styles = StyleSheet.create({
  mainView: {
    width: "100%",
    backgroundColor: "#0F1143",
  },
  innerView: {
    width: isWeb() && !mobilBrower() ? "60%" : "100%",
    alignSelf: "center",
    justifyContent: "center",
    paddingBottom: 100,
    alignItems: "center",
    ...Platform.select({
      web: {
        width: isWeb() && !mobilBrower() ? "80%" : "90%",
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 100,
      },
    }),
  },
  row1: {
    flexDirection: isWeb() && !mobilBrower() ? "row" : "column",
    marginTop: isWeb() && !mobilBrower() ? 20 : 0,
    alignItems: "center",
    justifyContent: "space-between",
    ...Platform.select({
      ios: {
        width: isWeb() && !mobilBrower() ? "75%" : "100%",
      },
      android: {
        width: isWeb() && !mobilBrower() ? "75%" : "100%",
      },
    }),
  },
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    alignItems: "center",
    backgroundColor: "red",
    marginBottom: 40,
  },
  optionTitle: {
    fontFamily: fontFamily.titilliumBold,
    fontSize: isWeb() ? 22 : 20,
    width: isWeb() && !mobilBrower() ? "100%" : "70%",
    lineHeight: 24,
    ...Platform.select({
      web: {
        fontWeight: "700",
        fontSize: 23,
      },
    }),
  },
  optiondescription: {
    fontFamily: fontFamily.effra,
    marginTop: 5,
    marginRight: 10,
    width: isWeb() && !mobilBrower() ? "100%" : "80%",
    fontSize: isWeb() && !mobilBrower() ? 14 : heightPercentageToDP("1.4%"),
    fontWeight: "400",
  },
  optionView: {
    height: isWeb() && !mobilBrower() ? 170 : 150,
    alignItems: "center",
    width: isWeb() && !mobilBrower() ? "40%" : "90%",
    alignSelf: "center",
    borderRadius: 20,
    marginTop: isWeb() && !mobilBrower() ? 10 : 30,
    flexDirection: "row",
    ...Platform.select({
      web: {
        margin: 40,
      },
    }),
    backgroundColor: "white",
  },
  logoImg: {
    width: "40%",
    resizeMode: "contain",
    padding: 10,
    marginLeft: 5,
    height: 120,
  },
  lockIcon: {
    position: "absolute",
    bottom: 5,
    right: 10,
    height: 22,
    width: 22,
    ...Platform.select({
      web: {
        right: 23,
        height: 40,
        width: 23,
      },
    }),
  },
  contentView: {
    flexDirection: "column",
    marginLeft: 10,
    width: "67%",
    ...Platform.select({
      web: {
        paddingRight: 120,
      },
    }),
    alignItems: "flex-start",
  },
  title: {
    fontSize: isWeb() && !mobilBrower() ? 36 : 30,
    color: "white",
    alignSelf: "center",
    fontFamily: fontFamily.effra,
    ...Platform.select({
      web: {
        fontWeight: "700",
      },
    }),
  },
});
