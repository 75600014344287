import { StyleSheet } from "react-native";
import { colors } from "../../../../common";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
  mainView: {
    height: "100%",
    width: "100%",
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  innerView: {
    backgroundColor: "#F1F1F1",
    borderRadius: 10,
    width: mobilBrower() ? "90%" : "40%",
    minHeight: "30%",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  mainItem: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  absolute: {
    left: 0,
    width: "100%",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",
    borderRadius: 10,
    backgroundColor: "transparent",
    justifyContent: "center",
    bottom: 0,
    right: 0,
  },

  itemTitle: {
    marginLeft: 0,
    color: "#030135",
    fontSize: responsiveText(20),
    textAlign: "center",
    width: "100%",
    marginBottom: 20,
    color: colors.DARK_BLUE,
  },

  itemTextTitle: {
    fontSize: responsiveText(16),
    color: "#030135",
    textAlign: "left",
    flex: 1
  },

  itemText: {
    marginLeft: 10,
    flex: 1,
    fontSize: responsiveText(16),
    color: "#030135",
    textAlign: "center",
  },

  sourceText: {
    fontSize: responsiveText(18),
    color: "white",
    textAlign: "center",
  },
});
