import React from "react";
import { View, Text, Image, Dimensions, TouchableOpacity } from "react-native";
import { Images } from "../../../../common";
import fontFamily from "../../../../common/fontFamily";
import moment from "moment";

const { width, height } = Dimensions.get("screen");

export const Card = ({ data }) => {

  const calculateDateDiff = () => {
    let a = moment(data.date_asked)
    let b = moment(new Date())
    let dateDiff = moment().diff(a, "days")
    return dateDiff > 0 ? dateDiff + ' days ago' : 'today'
  }

  return (
    <View
      style={{
        padding: 25,
        borderWidth: 1,
        borderColor: "rgb(220,220,220)",
        backgroundColor: '#F6F6F6',
        borderRadius: 15,
        marginBottom: 20,
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        <Text
          style={{
            color: "#33AC09",
            fontFamily: fontFamily.titilliumBold,
            textTransform: "capitalize",
          }}
        >
        {data?.category?.replace(/\_/g," ")} {"->"} {data?.sub_category}
        </Text>
        <Text>{calculateDateDiff()}</Text>
      </View>
      <Text
        style={{
          fontFamily: fontFamily.roboto,
          fontSize: 16,
          width: "80%",
        }}
      >
        {data.topic}
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderColor: "rgb(220,220,220)",
          borderTopWidth: 1,
          paddingTop: 5,
          marginTop: 15,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text>Posted by </Text>
          <Text
            style={{
              color: "#33AC09",
            }}
          >
            {data.discussion_author}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Image
            style={{
              width: 17,
              height: 17,
              marginTop: 3,
              marginRight: 5
            }}
            source={Images.comments}
          />
          <Text>{data.replies_count}</Text>
        </View>
      </View>
    </View>
  );
};
