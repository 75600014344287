import React from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Image,
} from "react-native";
import { styles } from "./interestSection.styles";
import config from "../../common/config";

const InterestSection = ({
  immigrationInterest,
  countriesInterestedIn,
  setImmigrationInterest,
  setCountriesInterestedIn,
}) => {
  const getImage = (item) => {
    if (countriesInterestedIn.includes(item.code)) {
      return item.webImg;
    } else {
      return item.webFlag;
    }
  };

  const updateImmigrationInterest = (code) => {
    const prevInterests = [...immigrationInterest];
    if (prevInterests.includes(code)) {
      prevInterests.splice(
        prevInterests.findIndex((el) => el === code),
        1
      );
    } else {
      prevInterests.push(code);
    }
    setImmigrationInterest(prevInterests);
  };

  const updateCountriesInterestIn = (code) => {
    const countriesInterested = [...countriesInterestedIn];
    if (countriesInterested.includes(code)) {
      countriesInterested.splice(
        countriesInterested.findIndex((el) => el === code),
        1
      );
    } else {
      countriesInterested.push(code);
    }
    setCountriesInterestedIn(countriesInterested);
  };

  return (
    <View>
      <View style={[styles.countryMainView, {}]}>
        <Text style={[styles.title]}>Countries of Interest</Text>
        <View style={styles.countiesView}>
          <FlatList
            data={config.intrestcountry}
            style={{ alignSelf: "center" ,width:"100%"}}
            extraData={countriesInterestedIn}
            contentContainerStyle={{
              width:"100%"
            }}
            numColumns={5}
            keyExtractor={(item) => item.code}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                style={[styles.flagbuttons]}
                onPress={() => updateCountriesInterestIn(item.code)}
              >
                <Image 
                style={[styles.countryIcon,{opacity:countriesInterestedIn.includes(item.code)?1:0.6}]} 
                source={getImage(item)} 
                />
                <Text style={styles.countryLabel}>
                  {item.label}
                </Text>
              </TouchableOpacity>
            )}
          />
        </View>
      </View>

      <View style={[styles.countryMainView, {}]}>
        <Text style={[styles.title, {}]}>Immigration Interest</Text>
        <View style={[styles.immigrationInterestContainer]}>
          {config.immigerat.map((item, index) => (
            <TouchableOpacity
              key={item.code}
              onPress={() => updateImmigrationInterest(item.code)}
              style={[
                styles.immigrationInterestButtons,
                {
                  marginLeft: index % 2 === 0 ? 0 : "2.5%",
                  borderColor: immigrationInterest.includes(item.code)
                    ? "#F1AB32"
                    : "#BDBDBD",
                },
              ]}
            >
              <Text
                style={[
                  styles.immigrationInterestText,
                  {
                    color: immigrationInterest.includes(item.code)
                      ? "#F1AB32"
                      : "#BDBDBD",
                  },
                ]}
              >
                {item.value}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    </View>
  );
};

export default InterestSection;
