import {AppRegistry} from 'react-native';

import {name as appName} from './app.json';
import App from './App';
import './src/utils/global'
import './web.css';

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('app-root'),
});