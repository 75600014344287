import React, { useEffect, useState } from "react";
import {
    Text,
    View,
    TouchableOpacity,
    Image,
    TextInput,
    BackHandler,
} from "react-native";
import { Images } from "../../../common";
import { useDispatch } from "react-redux";
import { forgetpassUser, resetpassword } from "../../../actions/auth.actions";
import { styles } from "../newLoginUi.styles";
import PinValidation from "../../../components/PinValidation/PinValidation";
import NetInfo from "@react-native-community/netinfo";
import CreatePassword from "../../../components/Password/Password";
import SweetAlert from "../../../components/SweetAlert";

function ForgotPassword({ onClose = () => { } }) {
    const [forgotemail, setForgotemail] = useState("");
    const [loading, setloading] = useState(false)
    const [showPinValidation, setShowPinValidation] = useState(false);
    const [valdidatedata, setValdidatedata] = useState({});
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [userResetData, setUserResetData] = useState({});
    const dispatch = useDispatch();

    const checkConnection = () => {
        return NetInfo.fetch().then((state) => {
            if (!state.isConnected) {
                SweetAlert.error("Check your connection");
            }
            return state.isConnected
        });
    };

    const handleBackButton = () => {
        if (showPinValidation) {
            if (showResetPassword) {
                setShowResetPassword(false)
            } else {
                setShowPinValidation(false);
            }
        } else {
            onClose()
        }
        return true
    }

    useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', handleBackButton);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', handleBackButton);
        }
    }, [])

    const forgetpass = async email => {
        const isConnected = await checkConnection();
        if (!isConnected) {
            return;
        }
        try {
            setloading(true)
            let postData = {};
            postData.email_address = email;
            const response = await dispatch(forgetpassUser(postData));
            var strnum = response.status.indexOf("!");
            var responceStr = response.status.substring(0, strnum);
            if (response.status.toString().includes('E-mail Sent')) {
                let passwordval = {};
                passwordval.email_address = email;
                passwordval.status = responceStr;
                passwordval.otp_type = "forgot_password_verification";
                setValdidatedata(passwordval)
                SweetAlert.success(response.status);
                setShowPinValidation(true);
            } else {
                SweetAlert.error(response.status);
            }
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error)
            // const newError = new ErrorUtils(error, "Signup Error");
            // newError.showAlert();
        }
    };

    const handleForgotPassword = () => {
        var emailvalidate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!forgotemail) {
            SweetAlert.error("Email is required");
        } else if (!emailvalidate.test(forgotemail)) {
            SweetAlert.error("Enter valid email");
        } else {
            forgetpass(forgotemail);
        }
    }

    const handleNewPasswordset = async (passwordData) => {
        const isConnected = await checkConnection()
        if (!isConnected) {
            return;
        }
        let postData = {};
        let resetstr = "Password reset successful!";
        postData.email_address = userResetData.email_address;
        postData.new_password_hash = passwordData.shapassword;
        const response = await dispatch(resetpassword(postData, userResetData.user_id, userResetData.auth_token));
        if (response.status === resetstr) {
            SweetAlert.success(response.status);
            onClose()
        } else {
            SweetAlert.error(response.status + " Try Again");
        }
    }

    return (
        <>
            <TouchableOpacity onPress={handleBackButton} style={styles.backBtn}>
                <Image
                    source={Images.blackbackIcon}
                    style={{ width: 30, height: 20, }}
                />
            </TouchableOpacity>
            <Image
                source={Images.loginImmiIcon}
                style={styles.loginImmiIcon}
            />
            {showPinValidation ?
                !showResetPassword ?
                    <PinValidation
                        styles={styles}
                        valdidatedata={valdidatedata}
                        onResetPasswordData={(resetData) => {
                            setUserResetData(resetData);
                            setShowResetPassword(true);
                        }}
                    />
                    : <CreatePassword
                        reset={true}
                        onSubmit={handleNewPasswordset}
                    />
                : <>
                    <View style={{ alignItems: "center", width: "100%", justifyContent: "center" }}>
                        <View style={styles.subview}>
                            <Text style={[styles.subtext, { fontSize: 20, marginBottom: 5 }]}>Forgot Password</Text>
                            <Text style={styles.subtext}>
                                Enter your email and we will send you a link to reset your
                                password
                            </Text>
                        </View>
                        <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
                            <TextInput
                                placeholder="Email Address"
                                onChangeText={(e) => {
                                    setForgotemail(e)
                                }}
                                value={forgotemail}
                                placeholderTextColor="rgba(39, 39, 39, 0.4)"
                                style={styles.textInput}
                            />
                        </View>
                        <TouchableOpacity
                            onPress={handleForgotPassword}
                            style={styles.loginButton}
                            disabled={loading}
                        >
                            <Text style={styles.loginButtonText}>Continue</Text>
                        </TouchableOpacity>
                    </View>
                </>}
        </>
    );
}

export default ForgotPassword;
