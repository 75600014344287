import React from "react";
import { View, Text } from "react-native";
import { getMetaDataDate } from "../../../../../utils/localityFactsDisplay.utils";
import { styles } from "../localityFactsDisplay.styles";

export const FactTypeD = ({ item, metaData }) => {
  return (
    <View>
      {Object.entries(item).map((s, index) => (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: index == 1 ? -15 : 5,
          }}
        >
          <Text style={styles.itemTitle}>{s[0] !== "fact" ? s[0] : ""}</Text>
          <Text style={[styles.itemTitle, { width: "40%" }]}>
            {s[0] !== "fact" ? s[1].join(",  ") : ""}
          </Text>
        </View>
      ))}
      {
        <View style={[styles.metaDataView, { marginTop: 10 }]}>
          {getMetaDataDate(item.fact, metaData)?.last_updated ? (
            <Text style={styles.metaDataView_date}>
              {"Last updated: " +
                getMetaDataDate(item.fact, metaData)?.last_updated}
            </Text>
          ) : (
            <View />
          )}
        </View>
      }
    </View>
  );
};
