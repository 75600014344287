import React from "react";
import { View, Text, TouchableOpacity, FlatList, Image } from "react-native";
import { colors, family } from "../../../../../../common";
import { Thumbnail } from "react-native-thumbnail-video";
import { styles2 as styles } from "./cards.styles";
import { useDispatch } from "react-redux";
import { setYoutubeModal } from "../../../../../../actions/mainMedia.actions";
import { isWeb } from "../../../../../../common/helpers";

const VideoCards = ({ item }) => {
  const dispatch = useDispatch();
  
  const openVideo = () => {
    dispatch(
      setYoutubeModal({
        active: true,
        item,
      })
    );
  };

  return (
    <TouchableOpacity
        onPress={openVideo}
        activeOpacity={1}
        style={[styles.videoView]}
      >
        <View style={styles.imgView}>
          {isWeb() ?
          <Image
              source={item.thumbnail_url}
              style={[styles.thumbnail, { width: "100%", height: "20vh" }]} />
            : <Thumbnail
              //   onPress={() => handleOnPress(item)}
              url={item.url}
              style={styles.thumbnail}
            />}
        </View>
        <View style={styles.contentView}>
          <View style={styles.tagView}>
            {item.tags.split(",").length > 0 ? (
              <FlatList
                data={item?.tags.split(",")}
                horizontal
                showsHorizontalScrollIndicator={false}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    //onPress={() => this.props.showAlert1(item)}
                    style={{
                      marginRight: 5,
                      padding: 5,
                      borderRadius: 25,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderWidth: 1,
                      borderColor: colors.LIGHT_BLUE,
                    }}
                  >
                    <Text style={styles.tagText}>
                      {item}
                    </Text>
                  </TouchableOpacity>
                )}
              />
            ) : (
              <View></View>
            )}
          </View>
          <View style={styles.tagView}>
            <Text
              style={{
                color: "#000",
                fontSize: 14,
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              {item.title}
            </Text>
          </View>
          <View style={styles.tagView}>
            <Text
              style={{
                color: "#000",
                fontSize: 14,
                fontFamily: family.regular,
              }}
            >
              {item.channel_name}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
  );
};

export default VideoCards;
