import React from "react";
import { TouchableOpacity, View } from "react-native";
import AddComment from "../AddComment";
import { Comment } from "./Comment/Comment";
import { groupBy } from "lodash";

export const Comments = ({ data, discussion_id }) => {
  return (
    <TouchableOpacity activeOpacity={1}>
      {
        data?.length > 1 && groupBy(data?.filter((q, i) => i > 0), 'reply_to')[null]?.reverse().map((q) => (
          <>
            <Comment
              discussion_id={discussion_id}
              totalComments={data.length}
              displayCommentCount={false}
              item={q} />
            {
              <View style={{ width: '88%', alignSelf: 'flex-end' }}>
                <AddComment
                  is_reply={true}
                  discussion_id={discussion_id}
                  data={q}
                />
              </View>
            }
            {
             data.length > 0 && Object.entries(groupBy(data, 'reply_to')).map((s) => {
                if (s[0] == null) return <View />
                return (
                  <View  style={{
                    borderColor: "rgb(220,220,220)",
                    width: "90%",
                    marginLeft: "10%",
                  }}>
                    {
                      q.response_id == s[0] &&
                      <>
                        {
                          s[1].map((d) => (
                            <Comment
                              discussion_id={discussion_id}
                              totalComments={data.length}
                              displayCommentCount={false}
                              item={d} />
                          ))
                        }
                      </>
                    }
                  </View>
                )
              })
            }
          </>
        ))
      }
    </TouchableOpacity>
  );
};
