import React from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Modal,
} from "react-native";
import EditProfile from "../../../EditProfile/EditProfile";
import BlurBackground from "../../../BlurBackground";
import { mobilBrower } from "../../../../common/helpers"

export default function EditProfileModal({ onClose }) {
  return (
    <Modal visible={true} transparent>
      <BlurBackground />
      <View style={styles.mainView}>
        <View style={styles.innerView}>
          <ScrollView>
            <EditProfile onClose={onClose} />
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  mainView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  innerView: {
    width: mobilBrower() ? "95%" : "50%",
    height: "90%",
    borderRadius: 20,
    overflow: "hidden"
  },
});
