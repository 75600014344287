import { StyleSheet, Platform } from "react-native";
import fontFamily from "../../common/fontFamily";
import { mobilBrower } from "../../common/helpers";
import { heightPercentageToDP, widthPercentageToDP } from "../../common/responsiveScreen";

//Use stylesheet for designing
export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  h2text: {
    marginTop: 10,
    fontFamily: "Helvetica",
    fontSize: 36,
    fontWeight: "bold",
  },
  actionView: {
    position: "absolute",
    backgroundColor: "white",
    borderRadius: 10,
    right: -5,
    elevation: 12,
    bottom: 50,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
    shadowColor: "#BDBDBD",
    shadowRadius: 1,
  },
  sendProfileButton: {
    padding: 10,
  },
  sendProfileText: {
    fontWeight: "bold",
  },
  messageInput: {
    fontSize: heightPercentageToDP("1.5%"),
    color: "black",
    paddingTop: 10,
    marginLeft: 4.5,
    padding: 7,
  },
  flatview: {
    borderRadius: 2,
    // borderBottomWidth: 1,
    borderBottomColor: "black",
    width: "100%",
    alignSelf: "center",
    paddingBottom: 8,
  },
  seeMsgView: {
    borderRadius: 2,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    // width: width - 20,
    alignSelf: "center",
    paddingBottom: 7,
    // paddingTop: 7,
    paddingLeft: 2,
  },
  name: {
    fontFamily: "Cochin",
    fontSize: 15,
    color: "#2699FB",
  },
  shareProfileMsgView: {
    width: widthPercentageToDP(55),
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 20,
    backgroundColor: "#D9D9D9",
    marginLeft: 10,
  },
  shareProfileView: {
    height: 60,
    width: 60,
    marginTop: 15,
    borderRadius: 30,
    backgroundColor: "#FFF",
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    elevation: 12,
    shadowColor: "#000000",
    shadowOpacity: 0.3,
    shadowRadius: 1,
    shadowOffset: {
      height: 1.5,
      width: 0.3,
    },
  },
  data: {
    color: "black",
    fontSize: 13,
    textAlign: "justify",
  },
  time: {
    fontSize: 11,
    color: "#89888C",
    marginBottom: 5,
    marginLeft: 15,
  },
  chatView: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  loadingView: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  loadingText: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    color: "rgba(202,202,202,1)",
  },
  noDataView: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  chatBoxStyle: {
    backgroundColor: "#FFF",
    flex: 6,
    marginTop: 5,
  },
  chatBoxStylemobileBrower: {
    backgroundColor: "#FFF",
    // height: heightPercentageToDP(55),
    flex: 1,
    marginTop: 5,
  },
  sendButton: {
    height: 14,
    width: 14,
  },
  reviewBut: {
    height: 16,
    width: 16,
  },
  button: {
    backgroundColor: "red",
    borderRadius: 25,
    marginVertical: 7,
    paddingVertical: 7,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontSize: 16,
    fontWeight: "500",
    color: "#ffffff",
    textAlign: "center",
  },
  backButtonStyle: {
    width: 17,
    marginTop: 12,
    marginRight: 10,
    height: 15,
    tintColor: "#000",
  },
  closeIcon: {
    position: "absolute",
    left: 15,
  },
  headingText: {
    fontSize: 17,
    fontWeight: "bold",
  },
  slideMainView: {
    flexDirection: "row",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    backgroundColor: "white",
    justifyContent: "center",
    paddingTop: 8,
    width: "100%",
    alignItems: "center",
    paddingLeft: 15,
    borderRadius: 30,
  },
  agentName: {
    fontSize: 17,
    alignSelf: "center",
    alignItems: "center",
    color: "#4971cd",
    fontWeight: "bold",
    marginTop: 10,
  },
  adviceTypeReviews: {
    fontSize: 15,
    alignSelf: "center",
    alignItems: "center",
    color: "gray",
    marginTop: 10,
    fontWeight: "bold",
  },
  enableSubmitBtn: {
    marginTop: 10,
    width: "90%",
    backgroundColor: "#18A4E0",
    borderRadius: 25,
    marginVertical: 10,
    paddingVertical: 13,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
  },
  textInputStyle: {
    width: "90%",
    paddingLeft: 20,
  },
  loginView: {
    marginTop: 15,
    width: "90%",
    height: 100,
    borderWidth: 1,
    paddingBottom: 5,
    marginBottom: 15,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  topView_title: {
    flexDirection: "row",
    width: "90%",
    marginTop: heightPercentageToDP("2.5%"),
    alignSelf: "center",
  },
  topView: {
    width: "100%",
    backgroundColor: "white",
  },
  ratingView: {
    flexDirection: "row",
    marginLeft: heightPercentageToDP("1%"),
  },
  ratingText: {
    marginRight: 5,
    fontSize: heightPercentageToDP("1.6%"),
    fontFamily: fontFamily.effra,
    color: "gray",
  },
  imgView: {
    height: heightPercentageToDP("5%"),
    width: heightPercentageToDP("5%"),
    borderRadius: heightPercentageToDP("4.4%") / 2,
    backgroundColor: "grey",
    marginTop: 3,
  },
  resolveButton: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
    paddingHorizontal: 10,
    paddingVertical: 10,
    alignSelf: "flex-end",
    marginRight: heightPercentageToDP("2%"),
    marginBottom: heightPercentageToDP("1%"),
    backgroundColor: "#33AC09",
    width: "45%",
    height: 40,
  },
  resolveButtonText: {
    color: "white",
    fontSize: heightPercentageToDP("1.6%"),
    fontWeight: "600",
    textTransform: "capitalize",
    fontFamily: fontFamily.titilliumBold,
  },
  topView_name: {
    fontSize: heightPercentageToDP("2.3%"),
    fontFamily: fontFamily.titilliumBold,
    marginLeft: heightPercentageToDP("1%"),
  },
  questionItem: {
    width:
      !mobilBrower()
        ? widthPercentageToDP("20%")
        : widthPercentageToDP(80),
    borderRadius: 10,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 0,
    padding: 15,
    elevation: 12,
    shadowOffset: { width: 1, height: 1 },
    shadowRadius: 2,
    shadowOpacity: 0.5,
    shadowColor: "black",
    backgroundColor: "#FFFFFF",
    marginLeft: 25,
    margin: 15,
    marginTop: 10,
    marginBottom: 0,
  },
  selectionView: {
    padding: 10,
    width: !mobilBrower()
      ? widthPercentageToDP("20%")
      : widthPercentageToDP(80),
    shadowOffset: { width: 1, height: 1 },
    shadowRadius: 2,
    alignSelf: "flex-end",
    borderTopLeftRadius: 10,
    shadowOpacity: 0.5,
    marginRight: 25,
    shadowColor: "black",
    marginTop: 30,
    backgroundColor: "#0F1143",
  },
  dropDownView: {
    padding: 10,
    width: !mobilBrower()
      ? widthPercentageToDP("20%")
      : widthPercentageToDP(80),
    shadowOffset: { width: 1, height: 1 },
    shadowRadius: 2,
    alignSelf: "flex-end",
    borderTopLeftRadius: 10,
    shadowOpacity: 0.5,
    marginRight: 25,
    shadowColor: "black",
    backgroundColor: "#FFFFFF",
  },
  optionView: {
    flexDirection: "row",
    margin: 5,
    alignItems: "center",
  },
  checked: {
    height: 8,
    width: 8,
    backgroundColor: "green",
    borderRadius: 4,
  },
  checkBox: {
    height: 12,
    width: 12,
    alignItems: "center",
    borderColor: "white",
    justifyContent: "center",
    marginRight: 10,
    borderRadius: 12 / 2,
    borderWidth: 0.5,
  },
  questionText: {},
  questionWrapper: {
    marginBottom: 20,
  },
  listTextViewStyle: {
    color: "#000",
    marginVertical: 10,
    flex: 0.9,
    marginLeft: 10,
    //marginLeft: 20,
    //marginHorizontal: 10,
    textAlign: "left",
  },
  pickerStyle: {
    //marginLeft: 10,
    paddingRight: 25,
    marginRight: 10,
    marginBottom: 0,
    paddingBottom: 0,
    backgroundColor: "#00000000",
    flexDirection: "row",
    borderWidth: 0,
    borderColor: null,
  },
  uploadButtonsText: {
    color: "white",
  },
  uploadButtons: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F1AB32",
    padding: 5,
    margin: 15,
    justifyContent: "center",
  },
  uploadOptionsView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    alignSelf: "center",
    width: "80%",
    fontFamily: fontFamily.regular,
    marginBottom: 8,
    marginLeft: 5,
    marginTop: 10,
    fontSize: 19,
    color: "#0F1143",
    alignSelf: "center",
    textAlign: "center",
    letterSpacing: 1,
  },
  selectLabelTextStyle: {
    color: "#000",
    textAlign: "left",
    width: "99%",
    padding: 10,
    paddingBottom: 5,
    flexDirection: "row",
  },
  typingView: {
    height: 25,
    width: '100%',
    backgroundColor: 'transparent',
    justifyContent: 'center'
  },
  placeHolderTextStyle: {
    color: "grey",
    padding: 10,
    textAlign: "left",
    width: "99%",
    flexDirection: "row",
  },
  flagbuttons: {
    height: 80,
    alignItems: "center",
    ...Platform.select({
      web: {
        margin: 10,
      },
      android: {
        width: "20%",
      },
      ios: {
        width: "20%",
      },
    }),
  },
  pickerContainerView: {
    borderColor: "#dcdad9",
    width: "95%",
    borderRadius: 5,
    backgroundColor: "transparent",
    alignItems: "flex-start",
    paddingBottom: 0,
  },
  bottomButtonView: {
    flexDirection: "row",
    marginTop: 25,
    borderTopWidth: 1,
    paddingTop: 15,
    width: "90%",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  botIcon: {
    height: 30,
    width: 30,
  },
  botName: {
    fontSize: heightPercentageToDP("1.7%"),
    color: "black",
    textTransform: "uppercase",
    marginBottom: 5,
  },
  botCategory: {
    fontSize: heightPercentageToDP("1.6%"),
    marginTop: heightPercentageToDP("0.4%"),
    color: "gray",
  }
});
