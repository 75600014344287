import { StyleSheet } from "react-native";
import { hasNotch } from "react-native-device-info";
import { heightPercentageToDP } from "react-native-responsive-screen";
import { family } from "../../common";

export const styles = StyleSheet.create({
  mainView: {
    backgroundColor: "#222222c7",
    height: "100%",
    alignItems: "center",
  },
  innerView: {
    backgroundColor: "white",
    width: "95%",
    borderRadius: 25,
    marginTop: hasNotch() ? 40 : 20,
    height: hasNotch ? "90%" : "87%",
  },
  countryName: {
    marginVertical: 2
  },
  bullet: {
    height: 12,
    width: 12,
    marginRight: 10,
  },
  header: {
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    width: '85%', 
    marginVertical: 30, 
    alignSelf: 'center'
  },
  coupon: {
    textAlign: 'center',
    padding: 10,
    color: 'red',
    fontSize: heightPercentageToDP('1.2%')

  },
  couponImg: {
    width: 100,
    alignItems: 'center', 
    alignSelf: 'flex-start',
    justifyContent: 'center',
  },
  headerText: {
    fontFamily: family.robotoBold,
    fontSize: heightPercentageToDP('2.5%')
  },
  tabViewButtonText: {
    fontFamily: family.effra
  },
  tabViewButton: {
    flex: 1,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  selectedTab: {
    backgroundColor: 'white',
    borderRadius: 10,
    margin: 3
  },
  selectedTabText: {
    color: '#5338FC'
  },
  tabView: {
    width: '85%',
    backgroundColor: '#F5F5F5',
    borderRadius: 10,
    alignSelf: 'center',
    flexDirection: 'row'
  },
  VisaTitleView: {
    width: '85%',
    alignSelf: 'center',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 10
  },
  flagStyle: {
    width: '40%',
    height: 90,
    resizeMode: 'contain',
    borderRadius: 10
  },
  dealImg: {
    width: '40%',
    // height: 100,
    resizeMode: 'stretch',
  },
  visaName: {
    fontSize: heightPercentageToDP('1.8%'),
    fontFamily: family.robotoBold,
    flexShrink: 1,
  },
  offerInfo: {
    fontSize: heightPercentageToDP('1.5%'),
    marginTop: 15,
    fontFamily: family.robotoBold,
    flexShrink: 1,
  },
  visaNameView: {
    padding: 10,
    flex: 1
  },
  tags: {
    color: 'black',
    fontFamily: family.roboto,
    borderWidth: 0.2,
    padding: 10,
    opacity: 0.7,
    borderRadius: 10,
    paddingVertical: 1,
    margin: 5,
    fontSize: heightPercentageToDP('1.3%'),
    marginTop: 10
  },
  descriptionView: {
    width: '85%',
    alignSelf: 'center'
  },
  descriptionTitle: {
    fontFamily: family.robotoBold,
    marginTop: 5,
  },
  desText: {
    marginVertical: 8
  }
})