import React, { useState } from 'react'
import { View, Image, TouchableOpacity, TextInput } from "react-native";
import { Images } from '../../common';
import { styles as s } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { Network } from '../../service/api';
import { getDiscussionAction } from '../../actions/discussion.action';

function AddComment({ data, is_reply, discussion_id, getDiscussions }) {
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const token = useSelector((state) => state.authReducer.authData.token);
  const [reply, setreply] = useState('')
  const dispatch = useDispatch()

  const sendReply = async () => {
    if (!reply) {
      return;
    }
    var authdata = userDetails?.userid + "@" + token;
    let payload = {
      message_author: `${userDetails?.first_name} ${userDetails?.last_name}`,
      text: reply,
      is_reply: is_reply,
      reply_to: is_reply ? data.response_id : null
    }
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };

    await Network(`/users/${userDetails?.userid}/discussions/${discussion_id}`, "POST", payload, headers);
    setreply('')
    dispatch(getDiscussionAction(authdata, discussion_id))
  }

  return (
    <View style={s.replayBox}>
      <TextInput
        style={s.textBox}
        value={reply}
        onChangeText={(e) => setreply(e)}
        placeholder='Add a reply' />
      <TouchableOpacity style={{ zIndex: 999999 }} onPress={() => sendReply()}>
        <Image source={Images.replaySend} style={s.sendIcon} />
      </TouchableOpacity>
    </View>
  )
}

export default AddComment