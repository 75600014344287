import React, { useEffect } from "react";
import {
  View,
  Text,
  Platform,
  StatusBar,
  TouchableOpacity,
  Image,
  Dimensions,
} from "react-native";
import { styles } from "./profile.styles";
import { Images, colors } from "../../common";
import { Cards } from "./Components/Cards/Cards";
import { useDispatch, useSelector } from "react-redux";
import { Network } from "../../service/api";
import { getOccupationAndLanguageList } from "../../actions/app.actions";

const { height } = Dimensions.get("screen");

import NavigationService from "../../navigation/NavigationService";
import TotalRewardModal from "../../pages/TotalRewardModal/TotalRewardModal";
import EditProfileSideBar from "../EditProfileSidebar/EditProfileSidebar";
import ReferralCodeModal from "../ReferralCodeModal";
import { getImmibuckBalance } from "../../actions/immibucks.actions";
import { isWeb, mobilBrower } from "../../common/helpers";
import { withRouter } from "react-router-dom";
import HeaderComponent from "./../../common/NewHeader/NewHeader"
import WebFooter from "../../common/WebFooter";
import { CardsWeb } from "./Components/Cards/CardsWeb";
import EditProfileModal from "./Components/EditProfileModal/EditProfileModal";

const Profile = (props) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );

  const getUser = useSelector((state) => state.userReducer.getUser);

  const userImg = useSelector(
    (state) => state.authReducer.getUserProfilePic.profilePic
  );

  const immiBuck_balance = useSelector(
    (state) => state.immibucksReducer.getImmibucksDetails.immibucks_balance
  );

  const immiBucks = useSelector(
    (state) => state.immibucksReducer.getImmibucksDetails.immibucks
  );
  const routeParams = isWeb() ? props.history.location.state : props.navigation.state.params;

  const [sidebarvisible, setsidebarvisible] = React.useState(false);
  const [rewardModal, setRewardModal] = React.useState(false);
  const [rewardSum, setRewardSum] = React.useState(null);
  const [milestonesData, setMilestoneData] = React.useState(null);
  const [walletHistory, setwalletHistory] = React.useState([]);
  const [referModalOpen, setReferModalOpen] = React.useState(false);
  const [openEditprofile, setOpenEditprofile] = React.useState(false);

  const token = useSelector((state) => state.authReducer.authData.token);

  const handlebackPress = () => {
    isWeb() ? props.history.goBack() : NavigationService.goBack();
  };

  const goToEditProfile = () => {
    isWeb() ? setOpenEditprofile(true) :
      NavigationService.navigate("EditProfile");
  };

  const getUserImmiBucksBalance = () => {
    const userId = userDetails?.userid;
    if (!userId || !token) {
      return;
    }
    const balance_url = `/users/${userId}`;
    const headers = {
      Accept: "application/json",
      Authorization: userId + "@" + token,
    };
    dispatch(getImmibuckBalance(balance_url, headers));
  };

  useEffect(() => {
    if (userDetails) {
      getMilestones();
      getOccupationsList();
      getUserImmiBucksBalance()
    }
  }, [userDetails]);

  const getOccupationsList = async () => {
    try {
      const headers = {
        Accept: "application/json",
        Authorization: token,
      };

      let response = await Network(
        "/data/get_dropdown_options",
        "GET",
        null,
        headers
      );
      const { occupations, languages } = response;
      let _occupation = [];
      let _languages = [];
      occupations?.length > 0 &&
        occupations.forEach((s, i) => {
          let Obj = {
            name: s,
            id: i,
          };
          _occupation.push(Obj);
        });
      languages?.length > 0 &&
        languages.forEach((s, i) => {
          let Obj = {
            name: s,
            id: i,
          };
          _languages.push(Obj);
        });
      let payload = {
        occupations: _occupation,
        languages: _languages,
      };
      dispatch(getOccupationAndLanguageList(payload));
    } catch (error) {

    }
  };

  const getMilestones = () => {
    const url = `/users/${userDetails?.userid}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: userDetails?.userid + "@" + token,
    };
    Network(url, "GET", null, headers).then((res) => {
      if (res.status && res.status == "User Found!") {
        let rewardSum = 0;
        res.immibucks.history.reward &&
          res.immibucks.history.reward.forEach((s) => {
            rewardSum += s.amount;
          });
        setRewardSum(rewardSum);
        setMilestoneData(res.immibucks.history.reward);
        setwalletHistory(res.immibucks.history);
      }
    });
  };

  const handleReferClick = () => {
    setsidebarvisible(false);
    setReferModalOpen(true);
  };

  const renderWebContent = () => {
    return <>
      <HeaderComponent screenTitle={"Profile"} />
      <View
        style={styles.profileContent}
      >
        {renderProfileCard()}
        <View style={styles.rightContent}>
          <CardsWeb routeParams={routeParams} />
        </View>
      </View>
      <WebFooter />
    </>
  }

  const renderProfileCard = () => (
    <View style={isWeb() && !mobilBrower() ? styles.leftContent : styles.userInfoPanel}>
      {userImg && userImg.uri ? (
        <Image
          style={[styles.avatar]}
          source={{ isStatic: true, uri: userImg.uri }}
        />
      ) : (
        <Image
          style={[styles.avatar]}
          source={Images.userIcon}
        />
      )}
      <View style={[styles.usernameWrapper]}>
        <Text></Text>
        <View style={styles.subWrapper} >
          <Text style={[styles.firstNameText]}>
            {userDetails?.first_name}
          </Text>
          <Text style={[styles.lastNameText]}>
            {userDetails?.last_name}
          </Text>
        </View>

        <View style={[styles.userNameLabelFooter]}>
          <TouchableOpacity
            style={styles.bucksContainer}
            onPress={() => setRewardModal(true)}
          >
            <Image
              style={[
                {
                  marginRight: 2,
                  height: 23,
                  width: 23,
                },
              ]}
              source={Images.immibuck}
            />
            <Text style={[styles.cText]}> {immiBuck_balance}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={goToEditProfile}>
            <Text style={[styles.editText]}>{isWeb() && !mobilBrower() ? "Edit profile" : "EDIT"}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )

  return (
    <View
      style={{
        backgroundColor: "white",
        height,
      }}
    >
      {isWeb() && !mobilBrower() ? renderWebContent() :
        <>
          <ReferralCodeModal
            visible={referModalOpen}
            close={() => setReferModalOpen(false)}
          />
          {sidebarvisible ?
            <EditProfileSideBar
              visible={true}
              handleEditClick={() => setOpenEditprofile(true)}
              navigation={props?.navigation}
              handleReferClick={() => handleReferClick()}
              closeSideBar={() => setsidebarvisible(false)}
            /> : null}

          {Platform.OS === "ios" ? (
            <View style={[styles.statusBar]}>
              <StatusBar translucent barStyle="light-content" hidden={false} />
            </View>
          ) : (
            <StatusBar
              barStyle="light-content"
              backgroundColor={colors.LIGHT_BLUE}
            />
          )}
          <View style={[styles.header]}>
            <View style={[styles.mainHeaderContent]}>
              <TouchableOpacity
                hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
                onPress={() => handlebackPress()}
              >
                <Image source={Images.whiteBack} style={{ height: 23, width: 23, marginTop: 5 }} />
              </TouchableOpacity>
              <Text style={[styles.profileHeaderText]}>Profile</Text>
              <TouchableOpacity
                disabled
                hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
              // onPress={() => this.handlebackPress()}
              >
                <TouchableOpacity
                  hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
                  onPress={() => setsidebarvisible(true)}
                >
                  <Image
                    source={Images.burgerMenu}
                    style={{ height: 23, width: 23, marginTop: 5 }}
                  />
                </TouchableOpacity>
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              width: "90%",
              marginTop: Platform.OS == "ios" ? 30 : 0,
              marginLeft: "5%",
            }}
          >
            {renderProfileCard()}
          </View>
          <Cards
            routeParams={routeParams}
            appRef={routeParams?.app_ref}
          />
        </>}
      {/* required for web */}
      {openEditprofile ? (
        <EditProfileModal onClose={() => setOpenEditprofile(false)} />
      ) : null}
      {rewardModal ? (
        // <View style={styles.mainView2}>
        <TotalRewardModal
          visible={rewardModal}
          userDetails={getUser}
          profilePic={userImg}
          navigation={props.navigation}
          rewardSum={rewardSum}
          immiBuck_balance={immiBuck_balance}
          start={props}
          milestonesData={milestonesData}
          walletHistory={walletHistory}
          getMilestones={() => getMilestones()}
          immiBucks={immiBucks}
          _handleImmibuckModal={() => setRewardModal(false)}
        />
        // </View>
      ) : null}
    </View>
  );
};

export default isWeb()
  ? withRouter(Profile) : Profile;
