import helpers from "../common/helpers";
import { Network } from "../service/api";

var expirystr = "Auth Token not valid or expired!";

export const getAppTrackerList = (UserId, Token) => {
    return async dispatch => {
        const url = `/users/${UserId}/app_tracker`
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: Token
        }
        dispatch({
            type: 'DASHBOARD_LOADING',
            payload: true
        })
        try {
            return Network(url, 'GET', null, headers).then(res => {
                try {
                    if (res.status && res.status == 'Success') {
                        dispatch({
                            type: 'GET_APPTRACKER_SUCCESS',
                            payload: res.applications
                        })
                        return Promise.resolve({ ...res.applications });
                    } else if (res.status && res.status == expirystr) {
                        dispatch({
                            type: 'DASHBOARD_LOADING',
                            payload: false
                        })
                        helpers.handleSessionExpiry()
                    }
                } catch (error) {
                }
            })
        } catch (error) {

        }
    };
};