import { Dimensions, StyleSheet } from "react-native";
const { height, width } = Dimensions.get("window");
import { family } from "../../common";

export const styles = StyleSheet.create({
  mainView: {
    height: height,
    width: width,
    backgroundColor: "#D8D8D8",
  },
  imgView: {
    width: "100%",
    backgroundColor: "#D8D8D8",
  },
  contentView: {
    height: "55%",
    top: -30,
    backgroundColor: "white",
    borderTopRightRadius: 40,
    justifyContent: "space-between",
    borderTopLeftRadius: 40,
    width: "100%",
  },
  colorView: {
    height: 20,
    width: 20,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  titleRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 25,
    width: "85%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  colorOuter: {
    height: 26,
    borderRadius: 13,
    alignItems: "center",
    justifyContent: "center",
    width: 26,
    shadowColor: "#BDBDBD",
    shadowRadius: 1,
    shadowOpacity: 0.3,
    margin: 5,
  },
  title: {},
  dailyOfferimg: {
    width: "100%",
    height: "100%",
    zIndex: -9999,
  },
  cartBack: {
    height: 20,
    resizeMode: "contain",
  },
  backButton: {
    position: "absolute",
    top: 50,
    zIndex: 9999,
    left: 10,
  },
  addButton: {
    backgroundColor: "#0F1143",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    height: 50,
    borderRadius: 10,
  },
  addtext: {
    color: "white",
    fontFamily: family.semibold,
  },
  bottomView: {
    alignSelf: "center",
    flexDirection: "row",
    marginBottom: 60,
    alignItems: "center",
    justifyContent: "space-between",
  },
  description: {
    color: "#77838F",
    fontSize: 13,
    width: "80%",
    marginTop: 10,
  },
  colorMainView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  favButton: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    width: 50,
    marginLeft: 15,
    backgroundColor: "#F1AB32",
    height: 50,
  },
  heart: {
    height: 22,
    width: 22,
  },
});
