import { StyleSheet, Dimensions } from "react-native";
import { family, normalize } from "../../../../common";
import colors from "../../../../common/colors";
import { responsiveText } from "../../../../common/normalize";
const { height, width } = Dimensions.get("window");

export const styles = StyleSheet.create({
  flatlist: {
    borderBottomColor: "rgb(238,242,245)",
    borderBottomWidth: 1,
    borderLeftColor: "rgb(238,242,245)",
    borderLeftWidth: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  coronaVirusColumnStyle: {
    width: normalize(465),
    paddingHorizontal: normalize(25),
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tickImage: {
    tintColor: "white",
    resizeMode: "contain",
    height: normalize(20, true),
    width: normalize(28, true),
  },
  tickView: {
    marginRight: normalize(62),
    alignItems: "center",
    justifyContent: "center",
  },

  bottomWrap: {
    flex: 1,
    marginBottom: normalize(313),
  },
  itemHeaderContainer: {
    minHeight: normalize(250),
    backgroundColor: colors.ACTIVE_GREEN,
    flexDirection: "row",
    alignItems: "center",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    elevation: 0.01,
  },
  headerContentView: {
    alignItems: "center",
    justifyContent: "center",
    width: normalize(380),
  },
  headerContentView1: {
    width: normalize(380),
  },
  text: {
    color: "black",
    fontSize: responsiveText(14),
  },
  tableContentText: {
    fontSize: responsiveText(14),
    color: colors.DARK_BLUE,
  },
  deleteButton: {
    width: normalize(35.4),
    height: normalize(43.8),
  },
  filterContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterText: {
    fontSize: normalize(55, true),
    fontFamily: family.semibold,
    lineHeight: normalize(52),
    color: colors.LIGHT_BLUE,
    marginLeft: normalize(50),
  },
  filterButton: {
    height: normalize(90),
    width: normalize(75),
    borderRadius: normalize(22),
    justifyContent: "flex-end",
    alignItems: "center",
    marginLeft: normalize(65),
    marginTop: normalize(15),
  },
  filterImage: {
    height: normalize(12.5),
    width: normalize(22.3),
  },
  underLineView: {
    marginTop: normalize(33),
    borderBottomWidth: normalize(2),
    borderBottomColor: colors.LIGHT_GREY,
  },
  filterTypeText: {
    fontSize: normalize(35, true),
    fontFamily: family.semibold,
    lineHeight: normalize(54),
    color: colors.EYE_COLOR,
    paddingTop: normalize(25),
  },
  modalInsideView: {
    backgroundColor: colors.WHITE,
    width: normalize(1050),
    marginHorizontal: normalize(50),
    borderRadius: normalize(10),
    borderWidth: 1,
    borderColor: "#fff",
  },
  redCloseImage: {
    alignSelf: "flex-end",
    marginTop: normalize(23),
    marginRight: normalize(23),
  },
  removeItem: {
    width: normalize(200),
    marginLeft: 8,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(240,240,240)",
    borderTopRightRadius: 5,
    flex: 1,
  },
  deleteItem: {
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    height: 50,
    right: 0,
    top: 0,
    bottom: 0,
    marginLeft: 8,
  },
  cardTitleView: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  weatherText: {
    color: "white",
    fontSize: normalize(40, true),
    fontFamily: family.semibold,
    textAlign: "center",
    marginBottom: normalize(25),
    marginHorizontal: normalize(10),
  },
  currencyDropDown: {
    width: "100%",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    backgroundColor: "white",
    elevation: 5,
  },
  seperationLine: {
    borderRightWidth: 1,
    borderRightColor: "white",
    height: normalize(50),
    alignSelf: "center",
  },
  tableContentView: {
    alignItems: "center",
    flexDirection: "row",
    flex: 1
  },
  dropdown: {
    backgroundColor: " rgb(238,242,245)",
    width: normalize(350),
    height: normalize(80),
    borderRadius: 5,
    marginRight: normalize(20),
    marginLeft: normalize(90),
    marginTop: normalize(20),
  },
  universityTitle: {
    width: normalize(495),
    paddingHorizontal: normalize(15),
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tableValueView: {
    borderRightWidth: 1,
    borderRightColor: "rgba(0,0,0,0.16)",
    height: normalize(50),
    alignSelf: "center",
  },
  delete: {
    width: normalize(200),
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRightColor: "rgba(0,0,0,0.16)",
  },
  titleContainer: {
    width: normalize(465),
    paddingHorizontal: normalize(10),
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  mainView: {
    marginTop: normalize(40),
  },
  innerView: {
    flex: 1,
    marginHorizontal: normalize(20),
  },
  emptyText: {
    flex: 1,
    textAlign: "center",
    color: colors.DARK_BLUE,
    fontFamily: family.semibold,
    fontSize: normalize(60, true),
    marginTop: height / 8,
    marginBottom: normalize(103),
  },
  modalContentView: {
    marginHorizontal: normalize(50),
    marginVertical: normalize(75),
  },
  btnContainer: {
    marginBottom: normalize(10),
    marginTop: normalize(50),
    marginHorizontal: normalize(200),
  }
});
