import React from "react";
import {
    Text,
    View,
    TouchableOpacity,
    Image,
    Modal,
    Linking,
    ScrollView,
} from "react-native";
import { family, Images } from "../../common";
import { mobilBrower } from "../../common/helpers";
import { heightPercentageToDP } from "../../common/responsiveScreen";
import { styles } from "./downloadModal.styles";

function DownloadModal({
    closeModal,
    modalContent,
    fromDeepLink,
    showBack = true,
    deepLink,
    showMobileAppInfo
}) {

    const handlePress = (type) => {
        if (type == "android") {
            Linking.openURL(
                "https://play.google.com/store/apps/details?id=global.immigreat.app"
            );
        } else {
            Linking.openURL("https://apps.apple.com/in/app/immigreat/id1577394991");
        }
    };

    const handleOpenApp = () => {
        Linking.openURL(deepLink)
    }

    const renderAppDownloadButtons = () => (
        <View style={[styles.buttonContanier, { justifyContent: showMobileAppInfo ? "flex-start" : "center" }]}>
            <View style={styles.storeIconView}>
                <TouchableOpacity onPress={() => handlePress("android")}>
                    <Image
                        source={{
                            uri: "https://immigreat.global/static/media/playstore.adfa6a6d.png",
                        }}
                        style={[styles.playStoreImg, fromDeepLink && styles.playStoreImg2]}
                    />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handlePress("ios")}>
                    <Image
                        source={{
                            uri: "https://immigreat.global/static/media/appstore.4712184f.png",
                        }}
                        style={[styles.playStoreImg, fromDeepLink && styles.playStoreImg2, { marginLeft: 15 }]}
                    />
                </TouchableOpacity>
            </View>
        </View>
    )

    const EXPLORER_ITEMS = [
        {
            title: "Immipath\nSearch",
            description: "Your personalised\nVisa Search",
            img: Images.immipatSearch,
            key: "immipath_search",
        },
        {
            title: "PR Points\nCalculator",
            description: "Calculate PR points for\nmultiple countries in one go",
            img: Images.prcalImg,
            key: "pr_points",
        },
        {
            title: "Jobs for you",
            description: "Search and find jobs",
            img: Images.jobforyouIcon,
            key: "jobs",
        },
        {
            title: "Language Explorer",
            description: "Learn important phrases...!",
            img: Images.languageFeatureIcon,
            key: "language",
        }
    ];

    const ExplorerItem = ({ img, title, description }) => (
        <View style={[styles.pathItem]}>
            <Image source={img} style={styles.logoImg} />
            <View style={styles.pathItemContent}>
                <Text style={styles.pathItemTitle}>{title}</Text>
                <Text style={styles.pathItemDesc} numberOfLines={3}>{description}</Text>
            </View>
        </View>
    );

    const renderPathExpCards = () => {
        return !fromDeepLink ? <View style={styles.pathsContainer}>
            {EXPLORER_ITEMS.map((item, index) => (
                <ExplorerItem
                    key={index + "e"}
                    {...item}
                />
            ))}
        </View> : null
    }

    return (
        <Modal transparent>
            <View style={styles.mainView}>
                <View style={[styles.innerView, { width: mobilBrower() ? "90%" : "40%", height: "auto", }]}>
                    <ScrollView>
                        {
                            closeModal && showBack ?
                                <TouchableOpacity
                                    onPress={closeModal}
                                    style={styles.closeIconButton}
                                    hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
                                >
                                    <Image source={Images.leftArrow} style={styles.closeIcon} />
                                </TouchableOpacity> : null
                        }
                        <View style={[styles.infoContainer, { height: heightPercentageToDP(30) }]}>
                            <Image source={Images.phone} style={styles.bgImg} />
                            <View style={{ width: "50%", height: "80%", justifyContent: "space-between" }}>
                                <Text
                                    style={styles.text}
                                >
                                    {modalContent ? modalContent : showMobileAppInfo ? "The Web App is still under development. Download the mobile app for a complete experience with exclusive features!" : "Download the\nImmigreat App\nto try this\nfeature..."}
                                </Text>
                                {showMobileAppInfo ? null : <Text
                                    style={styles.text}
                                >
                                    And more!
                                </Text>}
                                {showMobileAppInfo ? renderAppDownloadButtons() : null}
                            </View>
                        </View>
                        {showMobileAppInfo ? renderPathExpCards() : renderAppDownloadButtons()}
                        {
                            fromDeepLink ?
                                <TouchableOpacity onPress={handleOpenApp} style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Text style={{ fontSize: heightPercentageToDP('1.4%') }}>
                                        Already have the mobile app?
                                        <Text style={{
                                            marginLeft: 5,
                                            fontFamily: family.robotoBold, textDecorationLine: 'underline',
                                            textDecorationColor: 'blue'
                                        }}>
                                            Click here to open your report
                                        </Text>
                                    </Text>
                                </TouchableOpacity> : null
                        }
                    </ScrollView>
                </View>
            </View>
        </Modal>
    );
}

export default DownloadModal;
