import AsyncStorage from "@react-native-community/async-storage";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
// import { logger } from "redux-logger";

import thunk from "redux-thunk";

import reducers from "../reducers";

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  timeout: null,
  whitelist: [
    "formReducer",
    "authReducer",
    "userReducer",
    "pathReducer",
    "factReducer",
    "timelineReducer",
    "referralReducer",
    "eligibilityReducer",
    'adviceChannelReducer'
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const mainStore = () => {
  let store = createStore(persistedReducer, {}, applyMiddleware(thunk));
  let persistor = persistStore(store);
  return { store, persistor };
};

export const mainReduxStore = mainStore().store;

export default mainStore;
