import PropTypes from "prop-types";
import React, {Component} from "react";
import {TextInput, View, StyleSheet} from "react-native";
import {Dimensions} from "react-native";
const { width } = Dimensions.get("window");

const propTypes = {
    mapElement: PropTypes.func,
    onSubmitEditing: PropTypes.func,
    onChangeText: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    keyboardType: PropTypes.string,
    secureTextEntry: PropTypes.bool,
    label: PropTypes.string
};

const defaultProps = {
    mapElement: (n) => {},
    onSubmitEditing: () => {},
    onChangeText: () => {},
    value: "",
    placeholder: "",
    maxLength: 200,
    keyboardType: "default",
    secureTextEntry: false,
    label: ""
};

const styles = StyleSheet.create({
  inputBox: {
    width:300,
    backgroundColor:'rgba(255, 255,255,0.2)',
    borderRadius: 25,
    fontSize:16,
    fontFamily:"SourceSansPro-Regular"
  },
  emailtextInput:{width:width/1.6,marginLeft:15,height:50, color: '#000'},
  textboxfieldd:{fontFamily:"SourceSansPro-Regular"}

});

class InputText extends Component {

    state = {
        value: ""
    }

    componentDidMount() {
        
        this.setState({
            value: this.props.value
        });
    }

    onChangeText = (value) => {
        this.setState({
            value
        }, () => {
            this.props.onChangeText(value);
        })
    }

    render() {
        const {placeholder, secureTextEntry, keyboardType, maxLength, value, onChangeText, onSubmitEditing, socialValue, name} = this.props;
        return (
            <View>
                <TextInput
                    style={styles.emailtextInput}
                    placeholder={placeholder}
                    secureTextEntry={secureTextEntry}
                    keyboardType={keyboardType}
                    maxLength={maxLength}
                    returnKeyType="next"
                    value={socialValue ? name == 'email_address' ? socialValue.email_address : socialValue[name] : value}
                    onSubmitEditing={onSubmitEditing}
                    placeholderStyle={styles.textboxfieldd}
                    contextMenuHidden={true}
                    placeholderTextColor="#2C393F"
                    onChangeText={this.onChangeText} />
           </View>
        );
    }
}

InputText.defaultProps = defaultProps;

InputText.propTypes = propTypes;

export default InputText;