export const COST_OF_LIVING = [
  {
    question: "Are you a lavish spendor?",
    fact_id: 8,
    options: [
      {
        label: "Yes",
        sub_id: 8,
      },
      {
        label: "Sometimes",
        sub_id: 8,
      },
      {
        label: "No",
        sub_id: 8,
      },
    ],
  },
  {
    question:
      "Which type of accommodation would you prefer? (If you do not have the information about the location of your rented place, please choose city centre)",
    fact_id: 5,
    options: [
      {
        label: "1 BHK flat/apartment (city centre)",
        fact_id: 5,
        category: "Housing",
        sub_id: "0",
      },
      {
        label: "1 BHK flat/apartment (outside of city centre)",
        fact_id: 5,
        sub_id: "1",
        category: "Housing",
      },
      {
        label: "3 BHK flat/apartment (city centre)",
        fact_id: 5,
        sub_id: "2",
        category: "Housing",
      },
      {
        label: "3 BHK flat/apartment (outside of city centre)",
        fact_id: 5,
        category: "Housing",
        sub_id: "3",
      },
    ],
  },
  {
    question: "What mode of transportation would you prefer?",
    fact_id: 2,
    options: [
      {
        label: "Taxi",
        sub_id: "3",
        category: "Transportation",
        fact_id: 2,
      },
      {
        label: "Local bus or Metro",
        fact_id: 2,
        category: "Transportation",
        sub_id: "0",
      },
      {
        label: "Car Rentals",
        category: "Transportation",
        fact_id: 2,
        sub_id: "2",
      },
      {
        label: "Owning a car",
        category: "Transportation",
        fact_id: 2,
        sub_id: "5",
      },
    ],
  },
  {
    question:
      "For which of these Leisure & Entertainment sources would you spend?",
    fact_id: 4,
    type: "multiselect",
    options: [
      {
        label: "Movie Tickets",
        fact_id: 4,
        sub_id: "2",
        category: "Entertainment",
      },
      {
        label: "Weekend outing/ Short trips /cost of domestic travel",
        sub_id: "2",
        category: "Entertainment",
        fact_id: 4,
      },
      {
        label: "None",
        sub_id: "9",
      },
    ],
  },
  {
    question: "Do you consume alcohol? If yes, how often?",
    fact_id: 1,
    options: [
      {
        label: "Yes, beer everyday",
        qty: 30,
        category: "Alcohol",
        sub_id: "16",
        fact_id: 1,
      },
      {
        label: "Yes, non-beer (wine) drink everyday",
        sub_id: "15",
        qty: 6,
        fact_id: 1,
        category: "Alcohol",
      },
      {
        label: " Yes, beer weekly thrice",
        sub_id: "16",
        category: "Alcohol",
        qty: 12,
        fact_id: 1,
      },
      {
        label: "Yes, non-beer drink weekly thrice",
        sub_id: "15",
        category: "Alcohol",
        fact_id: 1,
        qty: 2.4,
      },
      {
        label: "Yes, beer weekly once",
        sub_id: "16",
        fact_id: 1,
        category: "Alcohol",
        qty: 4,
      },
      {
        label: "Yes, non-beer drink beer weekly once",
        sub_id: "15",
        fact_id: 1,
        category: "Alcohol",
        qty: 0.8,
      },
      {
        label: " Yes, I’m a social drinker",
        sub_id: "16",
        fact_id: 1,
        category: "Alcohol",
        qty: 2,
      },
      {
        label: "No, I do not drink",
        sub_id: "16",
        fact_id: 1,
        category: "Alcohol",
        qty: 0,
      },
      {
        label: "Skip",
        sub_id: "9",
      },
    ],
  },
  {
    question: "How many times in a week would you eat out?",
    fact_id: 0,
    options: [
      {
        label: "Almost all the time (15-20 times)",
        sub_id: "2",
        days: 20,
        category: "Restaurants",
        fact_id: 0,
      },
      {
        label: "Very often (12-15 times)",
        sub_id: "1",
        fact_id: 0,
        category: "Restaurants",
        days: 15,
      },
      {
        label: "Often (8-12 times)",
        sub_id: "1",
        category: "Restaurants",
        days: 12,
        fact_id: 0,
      },
      {
        label: "Just sometimes (4-8 times)",
        sub_id: "1",
        category: "Restaurants",
        days: 8,
        fact_id: 0,
      },
      {
        label: "Rarely (1-4 times)",
        sub_id: "1",
        category: "Restaurants",
        days: 4,
        fact_id: 0,
      },
      {
        label: "I always cook my own food",
        sub_id: "1",
        category: "Restaurants",
        days: 0,
        fact_id: 0,
      },
    ],
  },
  {
    question: "Do you regularly drink Coffee/Tea?",
    fact_id: 0,
    options: [
      {
        label: "Yes, 3 times a day or more duh!",
        sub_id: "3",
        qty: 90,
        category: "Coffee / Tea",
        fact_id: 0,
      },
      {
        label: "Yes, just once a day",
        sub_id: "3",
        category: "Coffee / Tea",
        qty: 30,
        fact_id: 0,
      },
      {
        label: "Nope I don’t",
        sub_id: "3",
        category: "Coffee / Tea",
        qty: 0,
        fact_id: 0,
      },
    ],
  },
  {
    question: `Which of these OTT platforms do you wish to subscribe to? (You can select multiple options)`,
    fact_id: 7,
    type: "multiselect",
    options: [
      {
        label: "Netflix",
        sub_id: "3",
        qty: 0,
        category: "Entertainment",
        fact_id: 6,
      },
      {
        label: "Amazon Prime",
        sub_id: "0",
        category: "Entertainment",
        qty: 0,
        fact_id: 7,
      },
      {
        label: "None",
        category: "Entertainment",
        qty: 0,
        sub_id: "9",
        fact_id: 7,
      },
    ],
  },
  {
    question: "Which of these Lifestyle and Fitness options would you spend for? (You can select multiple options)",
    type: "multiselect",
    fact_id: 4,
    options: [
      {
        label: "Gym Membership",
        sub_id: "0",
        category: "Lifestyle and Fitness",
        fact_id: 4,
      },
      {
        label: "Weekend sports",
        sub_id: "1",
        category: "Lifestyle and\nFitness",
        fact_id: 4,
      },
      {
        label: "None",
        sub_id: "9",
      },
    ],
  },
];

export const DUMMY_RESULTS = [
  {
    cost: "$800",
    label: "Food",
  },
  {
    cost: "$800",
    label: "Transport",
  },
  {
    cost: "$800",
    label: "Leisure",
  },
  {
    cost: "$800",
    label: "Hosuing",
  },
];
