import { combineReducers } from "redux";

const initialState = {
  changeHeader: false,
  factDetails: [],
  countriesSelectedInFactSelector: [],
  countryData: [],
  universityData: [],
  toolbarHeight: "",
  citySelectionType: 4,
  savedapplicationName: [],
  saveApplicationBaseUnit: [],
  supportIconStatus: null,
  filterView: false,
  comparatorData: { cityComparatorArray: [], citySelectionArray: [] }
};

const factData = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_COMPARATOR_DATA":
      return {
        ...state,
        comparatorData: action.payload
      };
    case "SET_FILTER_VIEW":
      return {
        ...state,
        filterView: action.payload
      };
    case "CHANGE_HEADER_TEXT":
      return {
        ...state,
        changeHeader: action.payload
      };
    case "GET_TOOLBAR_HEIGHT":
      return {
        ...state,
        toolbarHeight: action.payload
      };
    case "FACT_SELECTOR_LOADING":
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        errors: null
      };
    case "FACT_SELECTOR_SUCCESS":
      const dataArray = action.payload;
      let array = [];
      if (action.countriesSelectedInFactSelector) {
        const countriesSelectedInFactSelector = action.countriesSelectedInFactSelector;
        countriesSelectedInFactSelector.map(i => {
          array.push(Number(i));
        });
      }
      const selectedImmipaths = selectedImmipaths ? action.selectedImmipaths : [];
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        factDetails: dataArray,
        errors: null,
        countriesSelectedInFactSelector: array
      };
    case "LOCALITY_FACT_SELECTOR_SUCCESS":
      const dataArray1 = action.payload;
      let array1 = [];
      if (action.countriesSelectedInFactSelector) {
        const countriesSelectedInFactSelector = action.countriesSelectedInFactSelector;
        countriesSelectedInFactSelector.map(i => {
          array1.push(Number(i));
        });
      }
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        localityfactDetails: dataArray1,
        errors: null,
        countriesSelectedInFactSelector: array1
      };
    case "LOCALITY_FACT_SELECTOR_FAIL":
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errors: action.payload
      };
    case "FACT_SELECTOR_FAIL":
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errors: action.payload
      };
    case "COUNTRY_DATA_LOADING":
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        errors: null
      };
    case "COUNTRY_DATA_SUCCESS":
      const countryDetails = action.payload;

      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        errors: null,
        factsList: countryDetails.facts_metadata,
        countryData: countryDetails.city_data,
        universityData: countryDetails.university_data
      };
    case "COUNTRY_DATA_FAIL":
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errors: action.payload
      };
    case "SAVE_APP_DISPLAY_NAME":
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        savedapplicationName: action.payload.value,
        saveApplicationBaseUnit: action.payload.baseUnit
      };
    case "SUPPORT_ICON_STATUS_CHANGED":
      return {
        ...state,
        supportIconStatus: action.payload
      };
    default:
      return state;
  }
};
export default combineReducers({
  factData
});
