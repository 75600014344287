import React, { useState } from "react";
import { styles as s } from "./sectionA.styles";
import { View, TouchableOpacity, Image, Text, FlatList } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import helpers from "../../../../common/helpers";
import { Network } from "../../../../service/api";
import {
  setShortlistedUnivrsity, setUserDegreeAndCourseSelection, setSelectedGoalandCountries,
  setUserImmipathSearchResult, setUndergraduteUserAnswer, setUndergraduteQuestions
} from "../../../../actions/path.actions";
import Loader from "../../../Loader";

export const SectionA = ({ prevTrack, nextTrack, handleContinue, moveToShortListedScreen }) => {
  const dispatch = useDispatch()
  const [loading, setloading] = useState(false)
  const token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const previousSavePaths = useSelector(state => state.pathReducer?.getImmipathDetails?.previousSavePaths)

  const handlePress = async (item) => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let payload = {
      country_ids: item.country_ids,
      goal_ids: [Number(item.goal_id)],
      exploration_id: item.exploration_id,
      form_responses: item.immipath_responses
    }
    let botQuestionspayload = {
      goal_ids: [Number(item.goal_id)],
    }
    setloading(true)
    let response = await Network('/immipaths/get_results', "POST", payload, headers);
    let botQuestions = await Network('/immipaths/get_chat_bot_questions', "POST", botQuestionspayload, headers)
    const { form_data, status: botQuestionsStatus } = botQuestions
    const { city_data, country_data, results, status, fact_names } = response;
    if (status == 'Success' && botQuestionsStatus == 'Success') {
      let obj = {
        city_data,
        country_data,
        fact_names,
        results
      }
      let temp = {}
      let temp2 = {}
      form_data[1].forEach((s) => {
        Object.entries(item?.immipath_responses).forEach((q) => {
          let _index = s.select_options.indexOf(q[1])
          if (_index !== -1) {
            temp[s.application_display_name] = _index + 1;
            temp2[s.question_id] = q[1];
          }
        })
      })
      let questionspayload = {
        country_ids: item.country_ids,
        goal_ids: [Number(item.goal_id)],
        degree_id: temp['Degree'],
        course_id: temp['Course']
      }
      let questions = await Network('/immipaths/get_search_questions', "POST", questionspayload, headers)
      const { exploration_id } = questions;
      if (questions.status == 'Success') {
        let payload = {
          exploration_id: exploration_id,
          form_data: questions?.form_data
        }
        dispatch(setUndergraduteQuestions(payload))
      }
      let arr = []
      results[1]?.forEach((s) => {
        item.metadata?.universities_shortlisted.forEach((q) => {
          if (q == s.university_id) {
            arr.push(s)
          }
        })
      })
      let goalsCountries = {
        countries: item.country_ids,
        goal: [Number(item.goal_id)],
      }
      dispatch(setSelectedGoalandCountries(goalsCountries))
      dispatch(setUserDegreeAndCourseSelection(temp, temp2))
      dispatch(setUndergraduteUserAnswer(item?.immipath_responses))
      dispatch(setShortlistedUnivrsity(arr))
      dispatch(setUserImmipathSearchResult(obj))
      moveToShortListedScreen()
      handleContinue(false)
      setloading(false)
    }
  }

  const sortArray = () => {
    return previousSavePaths.sort(function (a, b) {
      return new Date(b.immipath_saved_timestamp) - new Date(a.immipath_saved_timestamp);
    });
  }

  return (
    <View style={{ height: "100%" }}>
      {
        loading && <Loader />
      }
      <View style={[s.main]}>
        {
          previousSavePaths?.length > 0 ?
            <>
              <Text style={s.headerText}>
                Search History
              </Text>
              <FlatList
                data={sortArray()}
                bounces={false}
                showsHorizontalScrollIndicator={false}
                style={{ width: '98%' }}
                renderItem={({ item, index }) => (
                  <TouchableOpacity activeOpacity={1} onPress={() => handlePress(item)} style={s.list}>
                    <View style={s.goalView}>
                      <Text style={s.goalText}>
                        {helpers.getGoalName([Number(item.goal_id)]).join(',')}
                      </Text>
                    </View>
                    <View style={s.detailsView}>
                      <Text style={s.title}>
                        {item.saved_as}
                      </Text>
                      <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                        {
                          item.country_ids?.map((q) => (
                            <View style={s.countryNameView}>
                              <Text style={s.countryText}>
                                {helpers.getCountryName(q)}
                              </Text>
                            </View>
                          ))
                        }
                      </View>
                    </View>
                  </TouchableOpacity>
                )}
              />
            </>
            :
            <>
              <Text style={[s.textT]}>LET'S GET TO KNOW YOU</Text>
            </>
        }
        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
          <TouchableOpacity onPress={nextTrack} style={[s.nextWrapper]}>
            <Text style={[s.nextText]}>{previousSavePaths?.length > 0 ? 'New Search' : "Next"}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

