import React from "react";
import { } from "react-native";
import AskQuestionComponent from "../../../pages/AskQuestionComponent";
import * as Animatable from "react-native-animatable";

export const FAQs = ({
  askQuestionMilestone,
  setAskQuestionMilestone,
  handleAskExpert,
  setLoading,
  setInView,
  setQuestion,
  question,
  triggerMessageChat,
}) => {
  return (
    <Animatable.View
      useNativeDriver={true}
      transition="opacity"
      easing="ease-in"
      style={[
        {
          width: "100%",
          flex: 1,
          marginBottom: 10,
          alignSelf: "center",
          opacity: 1,
        },
      ]}
    >
      <AskQuestionComponent
        askquestionmilestone={askQuestionMilestone}
        handleLoading={setLoading}
        question={question}
        setInView={setInView}
        setQuestion={setQuestion}
        handleAskExpert={handleAskExpert}
        _triggerMessageChat={triggerMessageChat}
      />
    </Animatable.View>
  );
};
