import React, { useState, useRef } from "react";
import {
  ScrollView,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Image,
  FlatList,
  Platform
} from "react-native";
import Images from "../../../../../../common/images";
import { styles } from "./occupation.styles";
import DatePicker from "react-native-datepicker";
import { useSelector } from "react-redux";
import { isWeb } from "../../../../../../common/helpers";

export const Occupations = ({ occupations = [], updateSettings, error }) => {
  const occupationList = useSelector(
    (state) => state.appStateReducer.occupationAndLanguageList?.occupations
  );
  const [filteredOccupationList, setfilteredOccupationList] = useState({});
  const scrollRef = useRef(null);

  const onChange = (occupationIndex, paramKey, value) => {
    if (paramKey == "position") {
      const filteredList = occupationList?.filter((item) => {
        const topicData = item.name;
        return topicData.indexOf(value) > -1;
      });
      setfilteredOccupationList({
        filteredList: filteredList,
        index: occupationIndex,
      });
    }
    let newArray = [...occupations];
    newArray[occupationIndex][paramKey] = value;
    updateSettings(newArray);
    delete error[occupationIndex];
    value?.length == 0 && setfilteredOccupationList({});
  };

  const handlePress = (occupationIndex, paramKey, item) => {
    let newArray = [...occupations];
    newArray[occupationIndex][paramKey] = item.name;
    updateSettings(newArray);
    setfilteredOccupationList([]);
  };

  const WebDatePicker = ({ date, onChange = () => { }, disabled, placeholder, style = {}, minDate, maxDate }) => {
    const [type, setType] = useState('text');
    if (Platform.OS == 'web') {
      return <input type={type} placeholder={placeholder} disabled={disabled} onInput={(e) => onChange(e.target.value)} onFocus={() => setType("date")} onBlur={() => setType("text")} min={minDate} max={maxDate} value={date} style={{ outline: 0, borderWidth: 0, backgroundColor: "transparent", ...style }} />
    } else {
      return null
    }
  }

  const updateDurationOfJob = (occupationIndex, durationIndex, value) => {
    let newArray = [...occupations];
    newArray[occupationIndex].duration[durationIndex] = value;
    updateSettings(newArray);
  };

  const handleDelete = (index) => {
    let newArray = [...occupations];
    newArray.splice(index, 1);
    updateSettings(newArray);
  };

  React.useEffect(() => {
    if (!occupations.length) {
      updateSettings([
        ...occupations,
        {
          company: "",
          position: "",
          duration: ["", ""],
          currentlyWorking: false,
        },
      ]);
    }
  }, []);

  return (
    <View
      style={{
        width: "90%",
        marginLeft: "5%",
        height: "100%"
      }}
    >
      <ScrollView
        style={{
          marginTop: "5%",
          height: "65%",
        }}
        ref={(ref) => (scrollRef.current = ref)}
      >
        {[...occupations].map((prevSavedJob, index) => {
          return (
            <View
              style={{
                borderColor: "rgb(225,225,225)",
                borderRadius: 15,
                borderWidth: 1,
                padding: 15,
                marginBottom: 15,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <TouchableOpacity onPress={() => handleDelete(index)}>
                  <Text>✖</Text>
                </TouchableOpacity>
              </View>
              <Text style={[styles.sectionTitle]}>Occupation {index + 1}</Text>
              <TextInput
                onChangeText={(val) => onChange(index, "company", val)}
                placeholder="Company Name"
                textContentType="organizationName"
                value={prevSavedJob.company}
                style={[
                  styles.inputs,
                  error["company"]?.includes(index) && styles.errorView,
                ]}
              />
              <View>
                <TextInput
                  onChangeText={(val) => onChange(index, "position", val)}
                  placeholder="Position"
                  textContentType="jobTitle"
                  value={prevSavedJob.position}
                  style={[
                    styles.inputs,
                    error["position"]?.includes(index) && styles.errorView,
                  ]}
                />
                {filteredOccupationList?.filteredList?.length > 0 &&
                  filteredOccupationList?.index == index && (
                    <FlatList
                      data={filteredOccupationList.filteredList}
                      style={styles.dropDownList}
                      renderItem={({ item, index: key }) => (
                        <TouchableOpacity
                          key={key + "f"}
                          onPress={() => handlePress(index, "position", item)}
                          style={styles.dropDownItem}
                        >
                          <Text>{item.name}</Text>
                        </TouchableOpacity>
                      )}
                    />
                  )}
              </View>
              <View
                style={{
                  display: "flex",
                  zIndex: -1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flex: 1,
                }}
              >
                {isWeb() ?
                  <WebDatePicker
                    key={"1"}
                    style={{
                      flex: 1,
                      padding: "10px 15px",
                      height: 50,
                      margin: "10px 0px",
                      backgroundColor: "rgb(230, 230, 230)",
                      borderRadius: 15,
                      borderWidth: error["startdate"]?.includes(index)
                        ? 1
                        : 0,
                      borderColor: "red",
                      width: "45%",
                      marginRight: "5%"
                    }}
                    placeholder={"Start Date"}
                    maxDate={new Date()}
                    date={prevSavedJob.duration[0]}
                    onChange={(date) => {
                      updateDurationOfJob(index, 0, date)
                    }}
                  />
                  : <DatePicker
                    style={[{ flex: 1 }]}
                    date={prevSavedJob.duration[0]}
                    mode="date"
                    placeholder="Start Date"
                    maxDate={new Date()}
                    format="DD-MM-YYYY"
                    confirmBtnText="Confirm"
                    cancelBtnText="Close"
                    customStyles={{
                      placeholderText: {
                        color: "gray",
                        height: 20,
                      },
                      dateTouchBody: [
                        {
                          width: "80%",
                          height: 70,
                        },
                      ],
                      dateInput: [
                        styles.inputs,
                        error["startdate"]?.includes(index) && styles.errorView,
                        {
                          borderRadius: 15,
                          borderWidth: error["startdate"]?.includes(index)
                            ? 1
                            : 0,
                          height: 55,
                        },
                      ],
                    }}
                    showIcon={false}
                    onDateChange={(date) => updateDurationOfJob(index, 0, date)}
                  />}
                {isWeb() ?
                  <WebDatePicker
                    key={"2"}
                    style={{
                      flex: 1,
                      padding: "10px 15px",
                      height: 50,
                      margin: "10px 0px",
                      backgroundColor: "rgb(230, 230, 230)",
                      borderRadius: 15,
                      borderWidth: error["startdate"]?.includes(index)
                        ? 1
                        : 0,
                      width: "45%",
                      marginLeft: "5%",
                      borderColor: "red",
                    }}
                    placeholder={"End Date"}
                    maxDate={new Date()}
                    minDate={prevSavedJob.duration[0]}
                    date={prevSavedJob.duration[1]}
                    onChange={(date) => {
                      updateDurationOfJob(index, 1, date)
                    }}
                    disabled={prevSavedJob.currentlyWorking}
                  />
                  :
                  <DatePicker
                    style={[{ flex: 1 }]}
                    date={prevSavedJob.duration[1]}
                    maxDate={new Date()}
                    mode="date"
                    minDate={prevSavedJob.duration[0]}
                    placeholder="End Date"
                    format="DD-MM-YYYY"
                    confirmBtnText="Confirm"
                    cancelBtnText="Close"
                    showIcon={false}
                    disabled={prevSavedJob.currentlyWorking}
                    hideText={prevSavedJob.currentlyWorking}
                    customStyles={{
                      placeholderText: {
                        color: "gray",
                        height: 20,
                      },
                      dateTouchBody: [
                        {
                          width: "80%",
                          marginLeft: "-15%",
                          height: 70,
                        },
                      ],
                      dateInput: [
                        styles.inputs,
                        error["enddate"]?.includes(index) && styles.errorView,
                        {
                          borderRadius: 15,
                          borderWidth: error["enddate"]?.includes(index) ? 1 : 0,
                          height: 55,
                        },
                      ],
                    }}
                    onDateChange={(date) => updateDurationOfJob(index, 1, date)}
                  />}
              </View>
              <TouchableOpacity
                onPress={() =>
                  onChange(
                    index,
                    "currentlyWorking",
                    !prevSavedJob.currentlyWorking
                  )
                }
                style={[styles.currentlyWorkingWrapper]}
              >
                <Image
                  style={styles.checkIcon}
                  source={
                    prevSavedJob.currentlyWorking
                      ? Images.immipathSearchChecked
                      : Images.immipathSearchUnchecked
                  }
                />
                <Text style={[styles.text]}>Currently Working</Text>
              </TouchableOpacity>
            </View>
          );
        })}
      </ScrollView>
      <TouchableOpacity
        onPress={() => {
          updateSettings([
            ...occupations,
            {
              company: "",
              position: "",
              duration: ["", ""],
              currentlyWorking: false,
            },
          ]);
          setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
          }, 300);
        }}
        style={[
          {
            marginBottom: 10,
            // backgroundColor: "#0F1143",
            borderRadius: 15,
          },
        ]}
      >
        <Text style={[styles.buttonText]}>+ Add New Occupation</Text>
      </TouchableOpacity>
    </View>
  );
};
