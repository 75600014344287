import { Tooltip } from '@material-ui/core';
import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import helpers from '../../../../common/helpers';
import Images from '../../../../common/images';
import { styles } from "./EligibilityReport.styles"

function Visa({ item, color, onInfoClick = () => { }, onPress = () => { }, onStarPress = () => { }, showStar = false }) {
    let bgcolor = color;

    return (
        <TouchableOpacity
            onPress={onPress}
            key={item.id}
            style={[
                styles.visaContainer,
                {
                    backgroundColor: bgcolor,
                    borderColor: bgcolor,
                },
            ]}
        >
            <View style={styles.visaContent}>
                <View style={{ height: "70%", flexDirection: "row", width: "100%" }}>
                    <View style={{ height: "100%", width: "40%" }}>
                        <Image source={helpers.getCountryFlag(item.country_id)} style={{ height: "100%", width: "100%", resizeMode: "contain" }} />
                    </View>
                    <View style={{ flex: 1, marginLeft: 10 }}>
                        <View style={{ flexDirection: "row" }}>
                            <Tooltip arrow placement="top-end" title={<Text style={{ color: "#fff" }}>{item.immipath_name}</Text>}>
                                <Text style={[styles.visaText, { flex: 1 }]} numberOfLines={2}>{item.immipath_name}</Text>
                            </Tooltip>
                            {showStar ?
                                <TouchableOpacity onPress={onStarPress}>
                                    <Image source={Images.starIcon} style={{ height: 30, width: 30 }} />
                                </TouchableOpacity>
                                : null}
                        </View>
                        <Text >{helpers.getCountryName(item.country_id)} </Text>
                    </View>
                </View>
                <View style={{ justifyContent: "flex-end", flexDirection: "row", width: "100%", alignItems: "center" }}>
                    {item.score ?
                        <View style={{ flex: 1, paddingHorizontal: 15, flexDirection: "row", paddingVertical: 8, borderRadius: 10, marginRight: 10, backgroundColor: "#fff" }}>
                            <Text style={{ fontWeight: "bold" }}>{`Match Score: `}</Text>
                            <Text style={{ fontWeight: "bold" }}>{Math.round(item.score) + '%'}</Text>
                        </View> : null}
                    <TouchableOpacity style={styles.infoBtn} onPress={() => onInfoClick()}>
                        <Image source={Images.info1} style={{ height: 27, width: 27 }} />
                    </TouchableOpacity>
                </View>
            </View>
        </TouchableOpacity>
    )
}

export default Visa;