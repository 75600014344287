import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import config from '../../../../../common/config';
import { styles } from "../sectionB.styles"

const CountrySelect = ({ selectedCountries = {}, onCountrySelect = () => { } }) => {

    const getImage = (item) => {
        if (selectedCountries.includes(item.code)) {
            return item.webImg;
        } else {
            return item.webFlag;
        }
    }

    const renderCountry = ({ key, item }) => (
        <TouchableOpacity
            key={key}
            style={styles.countryItem}
            onPress={() => onCountrySelect(item)}
        >
            <Image
                style={[styles.countryIcon, { opacity: selectedCountries.includes(item.code) ? 1 : 0.6 }]}
                source={getImage(item)}
            />
            <Text
                style={styles.countryLabel}
            >
                {item.label}
            </Text>
        </TouchableOpacity>
    )

    return (
        <View style={styles.countryView}>
            <Text style={[styles.title, { marginBottom: 10 }]}>Select Country</Text>
            <View style={styles.countriesView}>
                <View style={styles.countryRow}>
                    {config.intrestcountry.slice(0, 5).map((item, index) => (
                        renderCountry({ key: index + "s", item: item })
                    ))}
                </View>
                <View style={styles.countryRow}>
                    {config.intrestcountry.slice(5).map((item, index) => (
                        renderCountry({ key: index + "s", item: item })

                    ))}
                </View>
            </View>
        </View>
    )
}

export default CountrySelect;