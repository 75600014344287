import React from "react";
import {
  StatusBar,
  ScrollView,
  View,
  Image,
  Text,
  ActivityIndicator,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import fontFamily from "../../common/fontFamily";
import Images from "../../common/images";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { Comments } from "./Comments/Comments";
import { Main } from "./Main/Main";
import { useSelector, useDispatch } from "react-redux";
import AddComment from "./AddComment";
import { getDiscussionAction } from "../../actions/discussion.action";
import { isWeb, mobilBrower } from "../../common/helpers";
import { useLocation, withRouter } from "react-router-dom";
import { heightPercentageToDP } from "../../common/responsiveScreen";
import { styles } from "./styles";
import WebFooter from "../../common/WebFooter";

const Discussion = ({ navigation, history }) => {
 
  const { itemInView  } = isWeb() ? history?.location?.state ?? {} : navigation.state.params;
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);
  const [loading, setLoading] = React.useState(true);
  // const [discussion, setDiscussion] = React.useState(null);
  const dispatch = useDispatch()
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const discussion = useSelector(
    (state) => state.discussion.discussionData?.DiscussionList?.discussion_log
  );
  const discussion_user_id = useSelector(
    (state) => state.discussion.discussionData?.DiscussionList?.discussion_user_id
  );
  const token = useSelector((state) => state.authReducer.authData.token);

  const getDiscussions = async () => {
    var authdata = guestLogin ? 'guest@advice' : userDetails?.userid + "@" + token;
    await dispatch(getDiscussionAction(authdata, itemInView?.id))
    setLoading(false)
  }

  React.useEffect(() => {
    getDiscussions()
  }, []);

  return (
    <View style={{ flex: 1, backgroundColor: 'white', }}>
      <StatusBar hidden={true} />
      <HeaderComponent
        screenTitle={"Advice Channel"}
        showback={true}
        showNotificationIcon={false}
      />
      <View style={styles.mainView}>
        <View
          style={{
            padding: 25,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {isWeb() && !mobilBrower() ?
            <TouchableOpacity onPress={() => {
              history.goBack()
            }}>
              <Image
                style={{
                  width: 20,
                  height: 20,
                }}
                resizeMode="contain"
                source={Images.backArrow}
              />
            </TouchableOpacity> : null}
          <Text
            style={{
              fontFamily: fontFamily.robotoBold,
              fontSize: 17,
              marginLeft: -15,
            }}
          >
            {guestLogin ? 'Recent Discussion' : 'Your Public Discussion'}
          </Text>
          <View />
        </View>
        <ScrollView
          style={styles.container}
        >
          {loading ? (
            <View
              style={{
                height: heightPercentageToDP(71),
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ActivityIndicator
                size="large"
                color={"gray"}
                style={{ alignSelf: "center" }}
              />
            </View>
          ) : (
            <View
              style={{
                paddingBottom: 50,
              }}
            >
              {discussion && discussion?.length > 0 &&
                <Main
                  totalComments={discussion.length}
                  discussion_id={itemInView?.id}
                  itemInView={itemInView}
                  data={discussion[0]} />}
              {discussion && discussion?.length > 0 && !guestLogin &&
                <AddComment
                  is_reply={null}
                  discussion_id={itemInView?.id}
                  getDiscussions={getDiscussions}
                  data={discussion[0]} />
              }
              {
                !itemInView?.is_private || (itemInView?.is_private && userDetails?.userid == discussion_user_id) ?
                  <Comments
                    is_private={itemInView?.is_private}
                    discussion_id={itemInView?.id}
                    data={discussion} /> :
                  <Text style={{ alignSelf: 'center', marginTop: 15 }}>
                    This is a private discussion. Only the discussion owner can see replies.
                  </Text>
              }
            </View>
          )}
        </ScrollView>
      </View>
      {isWeb() && !mobilBrower() ? <WebFooter /> : null}
    </View>
  );
};

export default isWeb() ? withRouter(Discussion) : Discussion;