import { Icon } from 'native-base';
import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native';
import { isWeb } from '../../common/helpers';
import Images from '../../common/images';

function SocialLoginIcons({ title = "Or login with", onGoogleButtonPress = () => { }, onFacebookButtonPress = () => { }, onAppleButtonPress = () => { },hideAppleIcon=false }) {
    return (
        <View style={styles.container}>
            <Text style={styles.loginWith}>{title}</Text>
            <View style={styles.iconsContainer}>
                <TouchableOpacity style={styles.iconContainer} onPress={onGoogleButtonPress}>
                   {isWeb()?<Image source={Images.googleIcon} style={{ width: 25, height: 25 }} />
                    :<Icon type="AntDesign" name="google" style={{fontSize:23,color:"#fff"}} />}
                </TouchableOpacity>
                <TouchableOpacity style={styles.iconContainer} onPress={onFacebookButtonPress}>
                {isWeb()?<Image source={Images.facebookIcon} style={{ width: 28, height: 28 }} />
                    :<Icon type="FontAwesome" name="facebook" style={{fontSize:23,color:"#fff"}} />}
                </TouchableOpacity>
                {hideAppleIcon || isWeb()?null:
                <TouchableOpacity style={[styles.iconContainer]} onPress={onAppleButtonPress}>
                    {isWeb()?<Image source={Images.appleIcon} style={{ width: 30, height: 30 }} />
                    :<Icon type="AntDesign" name="apple1" style={{fontSize:25,color:"#fff"}} />}
                </TouchableOpacity>}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container:{ 
        flex:1,
        justifyContent:"center",
        alignItems:"center",
        marginTop:20 
    },
    iconsContainer: {
        marginBottom: 20,
        justifyContent: "center",
        flexDirection: "row",
    },
    iconContainer: {
        margin: 10,
        alignItems: "center",
        justifyContent: "center",
        height: 40,
        width: 40,
        backgroundColor: isWeb()?"#fff":"#0F1143",
        borderRadius:5
    }
})

export default SocialLoginIcons;