import React, { useEffect, useState } from "react";
import { styles as s } from "./sectionI.styles";
import { View, TouchableOpacity, Text, ScrollView, Image, Linking } from "react-native";
import { ApplicationChecklistModal } from "./components/ApplicationChecklistModal/ApplicationChecklistModal";
import dummyData from "./data.json";
import { useSelector } from "react-redux";
import NavigationService from "../../../../navigation/NavigationService";
import { Network } from "../../../../service/api";
import ResourceModal from './ResourceModal'
import Images from "../../../../common/images";
import InAppBrowser from 'react-native-inappbrowser-reborn'
import helpers, { isWeb } from "../../../../common/helpers";
import { checkTutorialStatus, copilotData } from "../../../../common/helpers";
import { copilot, CopilotStep, walkthroughable } from "react-native-copilot";
import { TooltipComponent } from "../../../../components/Tooltip/Tooltip";
import { isArray } from "lodash";
import { useHistory } from "react-router-dom";

const CopilotTouch = walkthroughable(TouchableOpacity);

const SectionI = ({ prevTrack, nextTrack, selectedCurrency, start }) => {
  const [resources, setresources] = useState([])
  const [applyLink, setapplyLink] = useState(null)
  const [openResourceModal, setopenResourceModal] = useState(false)
  const [applicationChecklist, setapplicationChecklist] = useState([])
  const token = useSelector((state) => state.authReducer.authData.token);
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const shortListedUniversity = useSelector(state => state.pathReducer?.getImmipathDetails?.shortListedUniversity)
  const userDegreeCourseSelections = useSelector(state => state.pathReducer?.getImmipathDetails?.userDegreeCourseSelections)
  const selectedgoalandcountries = useSelector(state => state.pathReducer?.getImmipathDetails?.selectedgoalandcountries)
  const [applicationChecklistModal, setApplicationChecklistModal] = React.useState(false);
  const [updatedUniversityList, setupdatedUniversityList] = useState([])
  const [checklists, setCheckLists] = React.useState([]);
  const underGraduateUserAnswer = useSelector(state => state.pathReducer?.getImmipathDetails?.underGraduateUserAnswer)
  const userSelectionWithQuestionId = useSelector(state => state.pathReducer?.getImmipathDetails?.userSelectionWithQuestionId)
  const underGraduateQuestions = useSelector((state) => state.pathReducer.getImmipathDetails.underGraduateQuestions);
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const history = useHistory();

  const getName = (name) => {
    let _name = null
    if (name == 'IELTS') {
      _name = 'IELTS'
    } else if (name == 'GRE_quant') {
      _name = 'GRE Quantitative'
    } else if (name == 'Tuition Fee') {
      _name = 'Tuition Fees'
    } else if (name == 'GRE_verbal') {
      _name = 'GRE Verbal'
    } else {
      _name = name
    }
    return _name
  }

  useEffect(() => {
    let callCoiplot = async () => {
      let status = await checkTutorialStatus('resouceButton')
      !guestLogin && userDetails.isHelpActive && status && start()
    }
    callCoiplot()
  }, [])

  const handleEligibleVisa = async () => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let paylaod = {
      base_filters: {
        country_ids: selectedgoalandcountries.countries,
        goal_ids: [1],
      },
      elig_qns_responses: {
        ...userSelectionWithQuestionId,
        ...underGraduateUserAnswer
      }
    }
    let response = await Network('/immipaths/get_eligible_visas', "POST", paylaod, headers)
    const { status, immipaths } = response;
    if (status == 'Success') {
      isWeb() ? history.push("applicableVisas", { applicableVisa: immipaths }) :
        NavigationService.navigate('ApplicableVisas', { applicableVisa: immipaths })
    }
  }

  const handleLinking = async (item, param) => {
    let link = 'https://www.google.com/search?q=' + item.university.split(' ').join('+') + '+' + 'tuition+fee';
    if (!isWeb() && await InAppBrowser.isAvailable()) {
      try {
        const result = await InAppBrowser.open(link, helpers.inAPPbrowserOption)
      } catch (error) {
        await InAppBrowser.close()
      }
    } else {
      Linking.openURL(link);
    }
  }

  const handleResource = async () => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let paylaod = {
      country_ids: selectedgoalandcountries.countries,
      goal_ids: [1],
      degree_id: userDegreeCourseSelections['Degree'],
      course_id: userDegreeCourseSelections['Course']
    }
    let response = await Network('/immipaths/get_immipath_resources', "POST", paylaod, headers);
    const { status, resources } = response;
    if (status == 'Success') {
      setresources(resources)
      setopenResourceModal(true)
    }
  }
  let data = underGraduateQuestions ? isArray(underGraduateQuestions.form_data) ?
    underGraduateQuestions.form_data : underGraduateQuestions.form_data[1] : []

  useEffect(() => {
    let counter = {}
    shortListedUniversity?.length > 0 && shortListedUniversity.forEach((s) => {
      let temp = [];
      s.facts && Object.entries(s.facts).forEach((q) => {
        data.forEach((d) => {
          if (d.fact_id == q[1].fact_id && underGraduateUserAnswer[d.question_id]) {
            q[1].displayname = q[1]?.fact_name.includes('GPA') ? q[1]?.fact_name?.split('_')[0] : q[1]?.fact_name.replace('_', ' ')
            q[1].value = q[1]?.value == null ? 'N/A' : q[1]?.value;
            q[1].userValue = underGraduateUserAnswer[d.question_id]
            temp.push(q)
          }
        })
        if (q[1]?.fact_name == 'Tuition Fee') {
          q[1].displayname = getName(q[1]?.fact_name)
          q[1].value = q[1]?.value == null ? 'N/A' : q[1]?.value;
          temp.push(q)
        }
        if (q[1]?.fact_name == 'Acceptance Rate') {
          q[1].displayname = getName(q[1]?.fact_name)
          q[1].value = q[1]?.value == null ? 'N/A' : q[1]?.value;
          temp.push(q)
        }
        s.displayfacts = temp
      })
      setupdatedUniversityList(shortListedUniversity)
    })
  }, [shortListedUniversity])

  const handleAskExpert = () => {
    isWeb() ? history.push("adviceChannel", { ...selectedgoalandcountries, goal_ids: [1] }) :
      NavigationService.navigate('Advice', { from: 'advice', userSelections: { ...selectedgoalandcountries, goal_ids: [1] } })
  }

  const handleApplyNow = (item) => {
    let degree = userDegreeCourseSelections['Degree'] == 1;
    Object.entries(item.facts).map((s) => {
      let applicationChecklist =
        userDegreeCourseSelections['Degree'] == 1 ? s[1].fact_name == 'application_link UG' && s[1].value
          : s[1].fact_name == 'application_link PG' && s[1].value;
      setapplicationChecklist(applicationChecklist)
    })
    setApplicationChecklistModal(true)

    let link = Object.entries(item?.facts).filter((s) => degree ? s[1].fact_name == 'application_link UG' : s[1].fact_name == 'application_link PG')[0];
    link?.length > 0 ? setapplyLink(link[1].value) : null
    // let link = item?.factsSection['Application Info']?.filter((s) => 
    // s.fact_name ==  degree ? 'application_link UG' : 'application_link PG')[0].value || item.link;

  }

  const getColor = (item) => {
    if (item.userValue && item.userValue >= item.value) {
      return 'green'
    } else if (item.userValue && item.userValue < item.value) {
      return 'red'
    } else {
      return 'black'
    }
  }

  return (
    <View style={[s.main]}>
      {
        openResourceModal &&
        <ResourceModal
          resources={resources}
          openResourceModal={openResourceModal}
          onClose={() => setopenResourceModal(false)}
        />
      }
      {applicationChecklistModal && (
        <ApplicationChecklistModal
          checklists={applicationChecklist}
          setCheckLists={setCheckLists}
          applyLink={applyLink}
          setApplicationChecklistModal={setApplicationChecklistModal}
        />
      )}

      <View style={[s.contentContainer]}>
        <Text style={[s.textT]}>Shortlisted Universities</Text>
        <ScrollView
          style={[
            {
              width: "100%",
            },
          ]}
        >
          <TouchableOpacity activeOpacity={1}>
            {updatedUniversityList.map((item, index) => {
              return (
                <View
                  style={[
                    s.card,
                    index != dummyData.length - 1 && {
                      marginBottom: 25,
                    },
                    {
                      borderColor: item?.otherParams?.recommended
                        ? "#33AC09"
                        : "#F5F6F8",
                    },
                  ]}
                >
                  <View style={[s.cardHeader]}>
                    <View style={s.imageView}>

                      {
                        item?.logo || item?.logo !== "" ?
                          <Image source={{ uri: item.logo }} style={{ height: '100%', width: '100%', resizeMode: 'contain' }} />
                          :
                          <Text style={[s.univIcon]}>
                            {helpers.getUniversityInitials(item.university)}
                          </Text>
                      }
                    </View>
                    <View
                      style={[
                        {
                          marginLeft: 15,
                          flexDirection: 'column',
                          flexShrink: 1
                        },
                      ]}
                    >
                      <Text style={[s.univName]}>{item.university}</Text>
                      <Text style={[s.univLocation]}>{item.city_string}</Text>
                      {/* {item.otherParams.recommended && (
                        <Text style={[s.recommended]}>Recommended</Text>
                      )} */}
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      marginBottom: 15,
                    }}
                  >
                    {item.displayfacts && item.displayfacts.map((param) => {
                      return (
                        <View style={[s.params]}>
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={[s.univParamLabel]}>{param[1].displayname}</Text>
                            {
                              param[1].displayname == 'Tuition Fees' &&
                              <TouchableOpacity onPress={() => handleLinking(item, param)}>
                                <Image source={Images.blackSearchIcn} style={s.searchIcon} />
                              </TouchableOpacity>
                            }
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 5
                            }}
                          >
                            <Text
                              style={[
                                s.univParamValue,
                                {
                                  color: getColor(param[1]),
                                },
                              ]}
                            >
                              {
                                param[1]?.displayname == 'Tuition Fees' && param[1].value !== 'N/A' && helpers.getCurrencySymbol(selectedCurrency) + ' '
                              }
                              {param[1].hasOwnProperty('userValue') ? param[1].userValue || 'Test yet to be taken' :
                                param[1].displayname == 'Tuition Fees' ?
                                  param[1].value == 'N/A' ? param[1]?.value :
                                    helpers.convertFormat(Math.round(helpers.currencyConverter(selectedCurrency, Number(param[1].value))), selectedCurrency) :
                                  param[1].value}
                            </Text>
                            {
                              param[1].userValue &&
                              <Text style={[s.univParamValue]}>
                                {` (Min: ${param[1].value})`}
                              </Text>
                            }
                          </View>
                        </View>
                      );
                    })}
                  </View>
                  <CopilotStep
                    text={copilotData?.visaApplyButton}
                    order={1}
                    name="ApplyButton">
                    <CopilotTouch
                      style={[s.selectButton]}
                      activeOpacity={0.7}
                      onPress={() => handleApplyNow(item)}
                    >
                      <Text
                        style={{
                          color: "white",
                          padding: 15,
                        }}
                      >
                        APPLY
                      </Text>
                    </CopilotTouch>
                  </CopilotStep>
                  {/* <View style={[s.chancesWrapper]}>
                    <Text
                      style={[s.chancesText]}
                    >{`Your Chance: ${item?.otherParams?.chances}`}</Text>
                    <Image
                      source={Images.chancesInfoIcon}
                      style={s.chancesIcon}
                    />
                  </View> */}
                </View>
              );
            })}
          </TouchableOpacity>
        </ScrollView>
        <CopilotStep
          text={copilotData?.askExpert}
          order={2}
          name="AskExpert">
          <CopilotTouch onPress={handleAskExpert} style={[s.askExpertTextButton]}>
            <Text style={[s.askExpertText]}>Ask Our Expert </Text>
          </CopilotTouch>
        </CopilotStep>
        <View
          style={[s.btnContainer]}
        >
          <CopilotStep
            text={copilotData?.applicableVisa}
            order={3}
            name="applicableVisa">
            <CopilotTouch
              onPress={handleEligibleVisa}
              style={[
                s.nextWrapper,
                {
                  marginRight: 10,
                },
              ]}
            >
              <Text style={[s.nextText]}>Applicable Visas </Text>
            </CopilotTouch>
          </CopilotStep>
          <CopilotStep
            text={copilotData?.immipathResource}
            order={4}
            name="resouceButton">
            <CopilotTouch onPress={handleResource} style={[s.nextWrapper]}>
              <Text style={[s.nextText]}>Resources </Text>
            </CopilotTouch>
          </CopilotStep>
        </View>
      </View>

    </View>
  );
};

export default copilot({
  tooltipComponent: TooltipComponent
})(SectionI)