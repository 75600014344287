import React from "react";
import { View, Text, ScrollView, TouchableOpacity, Image } from "react-native";
import { isWeb } from "../../../../common/helpers";
import Images from "../../../../common/images";
import { EXPLORER_ITEMS } from "../Cards/config/explorerItems";
import { styles } from "./list.styles";

export default function Lists({ guestLogin, handlePathExpItemPressed }) {
  return (
    <ScrollView bounces={false}>
      <TouchableOpacity
        activeOpacity={1}
        disabled={isWeb() ? true : false}
        style={styles.mainView}
      >
        <View style={styles.innerView}>
          {EXPLORER_ITEMS.map((item, index) => isWeb() && item.hideForWeb ? null : (
            <TouchableOpacity
              key={index + "e"}
              onPress={() => {
                handlePathExpItemPressed(item.key, item.onlyForMob);
              }}
              style={[styles.optionView]}
            >
              <Image source={item.img} style={styles.logoImg} />
              <View style={styles.contentView}>
                <Text style={styles.optionTitle}>{item.title}</Text>
                <Text style={styles.optiondescription}>{item.description}</Text>
              </View>
              {!item.allowGuest && guestLogin ? (
                <Image source={Images.menuLockIcon} style={styles.lockIcon} />
              ) : (
                <View />
              )}
            </TouchableOpacity>
          ))}
        </View>
      </TouchableOpacity>
    </ScrollView>
  );
}
