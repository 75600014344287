import { StyleSheet } from "react-native";
import { heightPercentageToDP } from "react-native-responsive-screen";

export const styles = StyleSheet.create({
  wrapper: {
    padding: 25,
  },
  searchWrapper: {
    width: "100%",
    backgroundColor: "rgb(230,230,230)",
    borderRadius: 10,
    marginBottom: 15,
  },
  searchInput: {
    padding: 15,
  },
  optionsWrapper: {
    width: "100%",
    borderColor: "rgb(230,230,230)",
    borderRadius: 10,
    borderWidth: 1,
    padding: 15,
    marginBottom: 5,
  },
  selectedOption: {
    borderColor: '#F1AB32'
  },
  options: {
    textAlign: "center",
  },
  submitButton: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: 25,
    borderRadius: 10,
    backgroundColor: '#F1AB32',
  },
  submitButtonText: {
    color: 'white',
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
});
