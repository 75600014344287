import React, { useState, useEffect } from "react";
import { styles } from "./sectionC.styles";
import { View, TouchableOpacity, Image, Text, FlatList } from "react-native";
import Images from "../../../../common/images";
import { useSelector, useDispatch } from "react-redux";
import { setUserDegreeAndCourseSelection } from "../../../../actions/path.actions";
import { isEmpty } from "lodash";
import Slider from "@react-native-community/slider";
import { TextInput } from "react-native-gesture-handler";
import helpers, { isWeb, mobilBrower } from "../../../../common/helpers";
let preCurrency = ["USD"];

export const SectionC = ({ prevTrack, nextTrack, continuePreviousSearch, selectedCurrency }) => {
  const [userSelections, setuserSelections] = useState({})
  const [userSelectionWtihQuestionId, setuserSelectionWtihQuestionId] = useState({})
  const dispatch = useDispatch();
  const [sliderValue, setsliderValue] = useState(100);
  const setupProfileQuestions = useSelector((state) => state.pathReducer.getImmipathDetails.setupProfileQuestions);
  const [Error, setError] = useState({})
  const userDegreeCourseSelections = useSelector(state => state.pathReducer?.getImmipathDetails?.userDegreeCourseSelections)
  const selectedgoalandcountries = useSelector(state => state.pathReducer?.getImmipathDetails?.selectedgoalandcountries)

  useEffect(() => {
    continuePreviousSearch && !isEmpty(userDegreeCourseSelections) ? setuserSelections(userDegreeCourseSelections) : dispatch(setUserDegreeAndCourseSelection({}, {}))
  }, [])

  useEffect(() => {
    preCurrency.push(selectedCurrency);
  }, [selectedCurrency])

  const handleChecklistedItems = (item, index, option) => {
    let temp = { ...userSelections }
    let temp2 = { ...userSelectionWtihQuestionId }
    temp[item.application_display_name] = index + 1;
    temp2[item.question_id] = option;
    setuserSelections(temp)
    setuserSelectionWtihQuestionId(temp2)
    Error[item.application_display_name] = false
    dispatch(setUserDegreeAndCourseSelection(temp, temp2))
  };

  const handleNext = () => {
    let err = {}
    let graduateQuestion = setupProfileQuestions ? setupProfileQuestions[1] : [];
    graduateQuestion.forEach((s) => {
      if (!userSelections[s.application_display_name] && s.is_mandatory) {
        err[s.application_display_name] = true
      }
    })
    if (Object.entries(err).length > 0) {
      setError(err)
      return
    }
    nextTrack()
  }

  const handleTextInput = (text, item) => {
    let temp = { ...userSelections }
    let temp2 = { ...userSelectionWtihQuestionId }
    temp[item.application_display_name] = text
    temp2[item.question_id] = Number(text);
    setuserSelections(temp)
    setuserSelectionWtihQuestionId(temp2)
    Error[item.application_display_name] = false
    dispatch(setUserDegreeAndCourseSelection(temp, temp2))
  }

  const ChatTriangle = ({ color, style = {} }) => (
    <View style={[{
      width: 0,
      height: 0,
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderBottomWidth: 0,
      marginRight: -7,
      borderRightWidth: 15,
      borderTopWidth: 15,
      borderLeftWidth: 15,
      borderTopColor: color,
      borderRightColor: 'transparent',
      borderBottomColor: "transparent",
      borderLeftColor: 'transparent',
      zIndex: -1
    }, style]} />
  )


  const renderQtn = ({ item, index }) => {
    var minVal = item.min_val;
    var maxVal = item.max_val;
    let displayMaxVal = item.max_val;
    let displayValue = userSelections[item.application_display_name];
    let base_unit = item.base_unit == 'USD' ? helpers.getCurrencySymbol(selectedCurrency) : item.base_unit;
    if (item.base_unit === "USD") {
      minVal = Math.round(
        helpers.currencyConverter(selectedCurrency, item.min_val)
      );
      maxVal = Math.round(
        helpers.currencyConverter(selectedCurrency, item.max_val)
      );
      displayMaxVal = helpers.convertFormat(
        Math.round(
          helpers.currencyConverter(selectedCurrency, item.max_val)
        ),
        selectedCurrency
      );
      if (preCurrency[preCurrency.length - 1] == selectedCurrency) {
        displayValue = helpers.convertFormat(
          Math.round(
            userSelections[item.application_display_name] === undefined
              ? maxVal
              : userSelections[item.application_display_name]
          ),
          selectedCurrency
        );
      } else if (preCurrency[preCurrency.length - 1] !== "USD") {
        displayValue = helpers.convertFormat(
          Math.round(
            helpers.currencyDevider(
              preCurrency[preCurrency.length - 1],
              userSelections[item.application_display_name] === undefined
                ? maxVal
                : userSelections[item.application_display_name]
            )
          ),
          selectedCurrency
        );
      } else {
        displayValue = helpers.convertFormat(
          Math.round(
            helpers.currencyConverter(
              selectedCurrency,
              userSelections[item.application_display_name] === undefined
                ? maxVal
                : userSelections[item.application_display_name]
            )
          ),
          selectedCurrency
        );
      }
    }

    const renderItem = (item) => {
      switch (item.type) {
        case "slider": {
          return (
            <View style={styles.sliderView}>
              <Slider
                onValueChange={(value) => handleTextInput(value, item)}
                maximumValue={maxVal}
                step={item.slider_step}
                value={userSelections[item.application_display_name]}
                thumbTintColor="maroon"
              />
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Text>
                  {base_unit + ' ' + minVal}
                </Text>
                <Text>
                  {displayValue}
                </Text>
                <Text>
                  {base_unit + ' ' + maxVal}
                </Text>
              </View>
            </View>
          )
        }
        case "multiselect": {
          const _iconRendere = ({ name, size = 12, style }) => {
            // flatten the styles
            const flat = StyleSheet.flatten(style);
            // remove out the keys that aren't accepted on View
            const { color, fontSize, ...styles } = flat;

            let iconComponent;
            // the colour in the url on this site has to be a hex w/o hash
            const iconColor =
              color && color.substr(0, 1) === "#" ? `${color.substr(1)}/` : "";

            const Down = (
              <Image
                source={Images.dropDownArrow}
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 5,
                }}
              />
            );
            const Cancel = (
              <Image source={Images.cancel} style={{ width: 15, height: 15 }} />
            );
            const Up = (
              <Image
                source={Images.dropDownArrow}
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 10,
                  transform: [{ rotateX: "180deg" }],
                }}
              />
            );
            switch (name) {
              case "keyboard-arrow-up":
                iconComponent = Up;
                break;
              case "keyboard-arrow-down":
                iconComponent = Down;
                break;
              case "cancel":
                iconComponent = Cancel;
                break;
              default:
                iconComponent = null;
                break;
            }
            return iconComponent;
          };
          return <SectionedMultiSelect
            styles={multiSelectStyle}
            modalWithTouchable
            modalWithSafeAreaView
            itemsFlatListProps={{
              style: isWeb() ? {
                height: "83vh",
                overflowY: "auto"
              } : {}
            }}
            items={item.select_options}
            uniqueKey="id"
            // hideSearch={true}
            subKey="children"
            showCancelButton={true}
            // selectText={`select ${item.application_display_name}`}
            showDropDowns={true}
            single={true}
            chipsPosition="top"
            iconRenderer={_iconRendere}
            // showChips={true}
            // customChipsRenderer={() => null}
            readOnlyHeadings={true}

            onSelectedItemObjectsChange={(objs) => {
              console.log(objs[0], objs, item);
              handleDropDownSelect(objs[0].name, item)
            }}
            onSelectedItemsChange={(value) => {
              console.log("ee", value)
            }}
            renderSelectText={() => (
              <Text style={{ color: displayValue ? "black" : "grey", flex: 1, marginLeft: 0 }}>{displayValue || "Highest Academic Achievement *"}</Text>
            )}
          />
        }
        case "integer": {
          return (
            <TextInput
              key={item.application_display_name}
              keyboardType="number"
              placeholder="Enter here"
              value={userSelections[item.application_display_name]}
              autoFocus={true}
              onSubmitEditing={() => handleNext()}
              onChangeText={(t) => {
                handleTextInput(t.replace(/[^0-9]/g, ''), item)
              }}
            />
          )
        }
        case "text": {
          return (
            <TextInput
              key={item.application_display_name}
              placeholder="Enter here"
              value={userSelections[item.application_display_name]}
              autoFocus={true}
              onSubmitEditing={() => handleNext()}
              onChangeText={(t) => {
                handleTextInput(t.trim(), item)
              }}
            />
          )
        }
        case "picker": {
          return <RNModalPicker
            data={item.select_options}
            pickerTitle={item.application_display_name}
            showSearchBar={true}
            disablePicker={false}
            changeAnimation={"fade"}
            searchBarPlaceHolder={"Search....."}
            showPickerTitle={true}
            listTextStyle={styles.listTextViewStyle}
            itemListStyle={styles.itemListStyle}
            selectedText={displayValue || `Select ${item.application_display_name}`}
            selectedTextStyle={{ ...styles.selectLabelTextStyle, color: displayValue ? "#000" : "grey" }}
            pickerStyle={styles.pickerStyle}
            selectedValue={(index, value) => handleDropDownSelect(value.name, item)}
            listContainerStyle={styles.pickerListContainer}
          />
        }
        default: {
          return item.select_options.map((option, index2) => {
            return (
              <View
                style={{
                  marginBottom: 10,
                }}
              >
                <TouchableOpacity
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onPress={() => handleChecklistedItems(item, index2, option)}
                >
                  <Image
                    style={[styles.checkIcon]}
                    source={
                      (userSelections[item.application_display_name] - 1 === index2)
                        ? Images.immipathSearchChecked
                        : Images.immipathSearchUnchecked
                    }
                  />
                  <Text style={[styles.options]}>{option}</Text>
                </TouchableOpacity>
              </View>
            );
          })
        }
      }
    }

    return (
      <View
        style={{
          width: "90%",
          marginLeft: "5%",
        }}
      >
        <TouchableOpacity activeOpacity={1} style={[styles.messageWrapper]}>
          <ChatTriangle color={"#0F1143"} />
          <View
            style={[
              styles.messageVisibleView,
              {
                backgroundColor: "#0F1143",
              },
            ]}
          >
            <Text style={[styles.message]}>{item.is_mandatory ? item.question + '*' : item.question}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity activeOpacity={1} style={[styles.messageWrapper]}>
          <View
            style={[
              styles.messageVisibleView,
              {
                backgroundColor: "#F8F8F8",
                marginLeft: "18%",
                borderWidth: 0.5,
                borderColor: Error[item.application_display_name] ? 'red' : 'white'
              },
            ]}
          >
            {renderItem(item)}
          </View>
          <ChatTriangle color={"#fff"} style={{ marginRight: 0, marginLeft: -14 }} />
        </TouchableOpacity>
      </View>)
  }

  return (
    <View style={[styles.main]}>
      <View style={styles.contentContainer}>
        <Text style={[styles.textT]}>Setup Your Profile</Text>
        <FlatList
          data={Object.entries(setupProfileQuestions)[0][1]}
          style={{ width: "100%" }}
          renderItem={renderQtn}
        />
        <TouchableOpacity onPress={handleNext} style={[styles.nextWrapper]}>
          <Text style={[styles.nextText]}>NEXT </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
