import React, { useEffect } from "react";
import { StyleSheet, Dimensions } from "react-native";
import StepIndicator from "react-native-step-indicator";
import { normalize } from "../common";
import { useSelector } from "react-redux";
import { copilot, walkthroughable, CopilotStep } from "react-native-copilot";
import {
  StepNumberComponent,
  WalkthroughableImage,
  WalkthroughableText,
  WalkthroughableTouch,
  WalkthroughableView,
} from "../common/WalkThroughable";
import { TooltipComponent as ToolTipComponent } from "../components/Tooltip/Tooltip";

const { width } = Dimensions.get("window");
export const WalkthroughableStep = walkthroughable(StepIndicator);

let counter = true;
function StepIndicatorView(props) {
  const sideBarTutorialFinshClicked = useSelector(
    (state) => state.pathReducer.getImmipathDetails.sideBarTutorialFinshClicked
  );

  useEffect(() => {
    if (sideBarTutorialFinshClicked) {
      // if(counter){
      props.start();
      counter = false;
      // }
    }
  }, [sideBarTutorialFinshClicked]);

  return (
    <CopilotStep
      text="You can navigate between path explorer steps from here"
      order={1}
      name="stepper"
    >
      <WalkthroughableView
        style={props.isTabTag ? styles.snackBarSep : styles.snackBar}
      >
        <WalkthroughableStep
          renderStepIndicator={(params) => props.renderStepIndicator(params)}
          onPress={props.onStepPress}
          customStyles={props.customStyles}
          currentPosition={props.currentPosition}
        />
      </WalkthroughableView>
    </CopilotStep>
  );
}

const styles = StyleSheet.create({
  snackBarSep: {
    marginTop: normalize(105),
    width: width + 28,
    marginLeft: -14,
  },
  snackBar: {
    marginTop: normalize(65),
    marginBottom: normalize(65),
    width: width + 28,
    marginLeft: -14,
  },
});

export default copilot({
  stepNumberComponent: StepNumberComponent,
  tooltipComponent: ToolTipComponent,
})(StepIndicatorView);
