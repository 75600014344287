import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  Modal,
  Dimensions,
  FlatList,
  Platform,
  ScrollView,
  TextInput,
  ActivityIndicator,
  KeyboardAvoidingView,
} from "react-native";
import { BlurView } from "@react-native-community/blur";
import { styles } from "./newConversationModal.styles";
import { useSelector } from "react-redux";
import { Network } from "../../service/api";
import Carousel, { Pagination } from "react-native-snap-carousel";
import WebCarousel from "../WebCarousel";
import { heightPercentageToDP } from "react-native-responsive-screen";
import helpers, {
  adviceChannelqueryOptions,
  currentStageOptions,
  mobilBrower,
} from "../../common/helpers";
import Images from "../../common/images";
import RazorpayCheckout from "react-native-razorpay";
import BullitinModal from "../BullitinModal/BullitinModal";
import fontFamily from "../../common/fontFamily";

const { height, width } = Dimensions.get("window");

export default function FollowUpQuestionModal({
  category,
  onClose,
  follow_up_data,
  query_count,
  advice_package,
  chat_id,
  handleFollowUpQuestion,
}) {
  const [selectedCurrentStage, setselectedCurrentStage] = useState(null);
  const baseCurrency = useSelector(
    (state) => state.referralReducer.referralData.baseCurrency
  );
  const [subCategoryList, setsubCategoryList] = useState([]);
  const [Question, setQuestion] = useState("");
  const [amountToPayFollowUp, setamountToPayFollowUp] = useState(0);
  const [packagePrice, setpackagePrice] = useState({});
  const [razorApiKey, setrazorApiKey] = useState(null);
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const [currentCaroselItem, setcurrentCaroselItem] = useState(0);
  const [loading, setloading] = useState(false);
  const [selectedsubcategory, setselectedsubcategory] = useState(null);
  const [selectedSubCategoryTier, setselectedSubCategoryTier] = useState(null);
  const [Err, setErr] = useState({});
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);
  const handleCurrentStageClick = (item) => {
    Err["currentStage"] = false;
    setselectedCurrentStage(item);
    item[1] && item[1].length > 0 && handleSubCategorySelection(item[1][0]);
  };

  const getPackageKey = (name) => {
    let key = "";
    if (name.includes("Basic")) {
      key = "ask_our_expert_basic";
    } else if (name.includes("Gaurdian")) {
      key = "ask_our_expert_gaurdian";
    } else if (name.includes("Standard")) {
      key = "ask_our_expert_standard";
    }
    return key;
  };
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    const getSubCategory = async () => {
      let res = await Network("/data/get_sub_categories", "GET", null, headers);
      setsubCategoryList(res.sub_categories);
    };
    const getPackagePrice = async () => {
      let payload = {
        package: getPackageKey(advice_package),
      };
      let response = await Network(
        "/data/get_advice_pricing",
        "POST",
        payload,
        headers
      );
      const { status, pricing } = response;
      if (status == "Success") {
        setpackagePrice(pricing);
      }
    };
    getSubCategory();
    getPackagePrice();
  }, []);

  const handleSubCategorySelection = (item) => {
    Err["subCategory"] = false;
    setselectedsubcategory(item.category);
    setselectedSubCategoryTier(item.tier);
    if (baseCurrency == "INR") {
      setamountToPayFollowUp(packagePrice.INR.Followup[item.tier]);
    } else {
      setamountToPayFollowUp(
        helpers.currencyConverter(packagePrice.USD.Followup[item.tier])
      );
    }
  };

  useEffect(() => {
    const getRazorPayKey = async () => {
      const headers = {
        Accept: "application/json",
        Authorization: `${userDetails?.userid}@${token}`,
      };
      let res = await Network("/data/get_razorpay_key", "GET", null, headers);
      if (res.status == "Success") {
        setrazorApiKey(res.key);
      }
    };
    !guestLogin && getRazorPayKey();
  }, [userDetails]);

  const pagination = () => {
    return (
      <Pagination
        dotsLength={selectedCurrentStage[1].length}
        activeDotIndex={currentCaroselItem}
        containerStyle={{ backgroundColor: "transparent" }}
        dotStyle={{
          width: 10,
          height: 10,
          borderRadius: 5,
          color: "red",
          marginHorizontal: 8,
          backgroundColor: "white",
        }}
        inactiveDotStyle={
          {
            // Define styles for inactive dots here
          }
        }
        inactiveDotOpacity={0.4}
        inactiveDotScale={0.6}
      />
    );
  };

  const _questionBox = (text) => {
    if (text.length > 800) {
      alert("Only 800 characters allowed!");
      return;
    } else if (text.length > 250 && text.length <= 800) {
      setQuestion(text);
    } else if (text.length == 0) {
      setshowFussyDropDown(false);
      setQuestion(text);
    } else {
      Err["Question"] = false;
      setQuestion(text);
    }
  };

  const callPurchase = async (pId) => {
    const url = "/razorpay/create_order";
    const logPayUrl = `/users/${userDetails?.userid}/log_payment`;
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let body = {
      order_amount: Number(amountToPayFollowUp) * 100,
      statement: `immistore purchase for ${advice_package}`,
      order_currency: baseCurrency,
      order_receipt: `immi_recpt_IMAP${userDetails?.userid
        }_${new Date().getTime()}`,
    };
    setloading(true);
    Network(url, "POST", body, headers).then(async (res) => {
      let razorPayIds = {};
      var options =
        Platform.OS == "web"
          ? {
            description: "Immistore Purchase",
            image: Images.toolbarLogo,
            currency: "INR",
            key: razorApiKey,
            handler: function (response) {
              setloading(true);
              razorPayPaymentStatus = true;
              razorPayIds.razorpay_payment_id = response.razorpay_payment_id;
              razorPayIds.razorpay_order_id = response.razorpay_order_id;
              razorPayIds.razorpay_signature = response.razorpay_signature;
              Network(logPayUrl, "POST", paymentBody, headers);
              razorPayIds.order_id = res.order_details.id;
              razorPayIds.order_receipt =
                paymentBody.payment_type.additional_info.order_receipt;
              afterSuccessPay();
            },
            amount: amountToPayFollowUp * 100,
            name: "Immigreat Pvt. Ltd.",
            order_id: res.order_details.id,
            prefill: {
              email: userDetails?.email_address,
            },
            theme: { color: "#FFAA00" },
          }
          : {
            description: "Immistore Purchase",
            image: Images.toolbarLogo,
            currency: baseCurrency,
            key: razorApiKey,
            amount: Number(amountToPayFollowUp) * 100,
            name: "Immigreat Pvt. Ltd.",
            order_id: res.order_details.id,
            prefill: {
              email: userDetails?.email_address,
              contact: userDetails?.phone,
              name: userDetails?.first_name,
            },
            theme: { color: "#FFAA00" },
          };
      const paymentBody = {
        user_id: userDetails?.userid,
        amount: amountToPayFollowUp,
        statement: `immistore purchase for ${advice_package}`,
        payment_type: {
          type: "razorpay",
          additional_info: {
            order_id: res.order_details.id,
            order_receipt: body.order_receipt,
            Advice_package: advice_package,
          },
        },
      };

      if (Platform.OS == "web") {
        const razorypayResponse = await _handleRazorPay(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!razorypayResponse) {
          alert("Razorpay failed to load!");
          return;
        }
        try {
          const payObj = new window.Razorpay(options);
          payObj.open();
        } catch (error) { }
      } else {
        RazorpayCheckout.open(options)
          .then((data) => {
            setloading(false);
            afterSuccessPay(res, data);
            props.handleLoading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      }
    });
  };

  const afterSuccessPay = async () => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    let body = {
      sub_category: category,
      text: Question,
      payment_made: follow_up_data?.length > query_count - 1 ? false : true,
      chat_id: chat_id,
    };
    let response = await Network(
      `/users/${userDetails?.userid}/update_follow_up`,
      "POST",
      body,
      headers
    );
    const { status } = response;
    if (status == "Success") {
      handleFollowUpQuestion(response, Question);
      onClose();
    }
  };

  const handleClose = (func) => {
    func();
  };

  return (
    <BullitinModal
      logo="filter"
      heading="News"
      visible={true}
      onClose={onClose}
      loading={false}
      handleClose={handleClose}
      logoFrom="right"
      hideCloseButton={false}
      viewableContentHeight={"56%"}
    >
      <ScrollView
        style={{
          padding: 15,
        }}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : null}
        >
          <View style={styles.categoryView}>
            <Text style={styles.title}>What is your current stage?</Text>
          </View>
          {subCategoryList[category] && (
            <View>
              <FlatList
                data={Object.entries(subCategoryList[category])}
                numColumns={2}
                style={{ padding: 5 }}
                columnWrapperStyle={{ justifyContent: "center" }}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    onPress={() => handleCurrentStageClick(item)}
                    style={[
                      styles.queryOptButtons,
                      selectedCurrentStage &&
                      selectedCurrentStage[0] == item[0] &&
                      styles.selectedCategoryBorder,
                      { alignSelf: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.queryOptButtonsText,
                        selectedCurrentStage &&
                        selectedCurrentStage[0] == item[0] &&
                        styles.selectedCategorText,
                      ]}
                    >
                      {item[0].replace("_", " ").toUpperCase()}
                    </Text>
                  </TouchableOpacity>
                )}
              />
              <View style={{ height: 20 }} />
            </View>
          )}
          {selectedCurrentStage ? (
            <>
              <View style={[styles.categoryView, { marginTop: 5 }]}>
                <Text style={styles.title}>
                  Which sub-category does your query fall under?
                </Text>
              </View>
              <View
                style={[
                  styles.askQuesView,
                  { width: "100%" },
                  Err["subCategory"] && styles.errView,
                  styles.caroselStyle,
                ]}
              >
                {Platform.OS == "web" ? (
                  <>
                    <WebCarousel>
                      {selectedCurrentStage[1].map((item, index) => (
                        <TouchableOpacity
                          onPress={() => handleSubCategorySelection(item)}
                          style={[
                            styles.subCategoryItem,
                            selectedsubcategory &&
                            selectedsubcategory == item.category &&
                            styles.selectedCategoryBorder,
                          ]}
                        >
                          <Image
                            source={{ uri: item.thumbnail }}
                            style={styles.categoryImg}
                          />
                          <Text style={styles.subCategoryItemText}>
                            {item.category}
                          </Text>
                        </TouchableOpacity>
                      ))}
                    </WebCarousel>
                  </>
                ) : (
                  <>
                    <Carousel
                      ref={(c) => {
                        this._carousel = c;
                      }}
                      data={selectedCurrentStage[1]}
                      indicatorStyle="black"
                      pagingEnabled
                      onSnapToItem={(index) => {
                        setcurrentCaroselItem(index),
                          handleSubCategorySelection(
                            selectedCurrentStage[1][index]
                          );
                      }}
                      renderItem={({ item, index }) => (
                        <TouchableOpacity
                          onPress={() => handleSubCategorySelection(item)}
                          style={[
                            styles.subCategoryItem,
                            selectedsubcategory &&
                            selectedsubcategory == item.category &&
                            styles.selectedCategoryBorder, { width: "100%" }
                          ]}
                        >
                          <Image
                            source={{ uri: item.thumbnail }}
                            style={styles.categoryImg}
                          />
                          <Text style={styles.subCategoryItemText}>
                            {item.category}
                          </Text>
                        </TouchableOpacity>
                      )}
                      sliderWidth={Dimensions.get("window").width * 0.83}
                      itemWidth={Dimensions.get("window").width * 0.75}
                    />
                    {pagination()}
                  </>
                )}
              </View>
            </>
          ) : (
            <View />
          )}
          <View style={[styles.categoryView, {}]}>
            <Text style={styles.title}>Enter your question</Text>
          </View>
          <View
            style={[
              styles.askQuesView,
              Err["Question"] && styles.errView,
              {
                width: Platform.OS == "web" ? "65%" : "95%",
                position: "relative",
              },
            ]}
          >
            <TextInput
              style={[
                styles.title,
                {
                  marginTop: 5,
                  padding: 10,
                  fontSize: mobilBrower() ? heightPercentageToDP("1.5%") : 14,
                  color: "black",
                  marginLeft: 10,
                  width: "99%",
                },
              ]}
              placeholder="Enter your question"
              multiline={true}
              value={Question}
              onChangeText={_questionBox}
              placeholderTextColor="grey"
            />
          </View>
          {advice_package && advice_package?.split("@")[1] != "0" && (
            <Text
              style={{
                textAlign: "right",
                marginBottom: -10,
                marginTop: 15,
                color: "green",
                marginRight: "2.5%",
                fontFamily: fontFamily.titilliumBold,
              }}
            >
              {amountToPayFollowUp / parseInt(advice_package.split("@")[1])}%
              Discount
            </Text>
          )}
          <TouchableOpacity
            onPress={
              follow_up_data?.length >= query_count - 1
                ? callPurchase
                : afterSuccessPay
            }
            style={styles.payButton}
          >
            <Text style={styles.payButtonText}>
              {follow_up_data?.length >= query_count - 1
                ? `Pay ${helpers.getCurrencySymbol(
                  baseCurrency
                )} ${amountToPayFollowUp}`
                : `Free`}
            </Text>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      </ScrollView>
    </BullitinModal>
  );
}
