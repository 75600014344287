import { StyleSheet, Dimensions } from "react-native";

import { scale, verticalScale, moderateScale } from "react-native-size-matters";
import { RFValue } from "react-native-responsive-fontsize";
import { heightPercentageToDP } from "../../common/responsiveScreen";
import { mobilBrower } from "../../common/helpers";

var width = Dimensions.get("window").width;
var height = Dimensions.get("window").width;

//Use stylesheet for designing
export const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    width: mobilBrower() ? "100%" : "70%",
    backgroundColor: "white",
    alignSelf: "center",
    marginBottom: 20
  },
  container: {
    flex: 1,
    backgroundColor: "#EEF2F5",
    // marginBottom: 40
  },
  contentView: {
    height: mobilBrower() ? heightPercentageToDP(57) : heightPercentageToDP(60),
    width: mobilBrower() ? "100%" : "90%",
    alignSelf: "center"
  },
  loginView: {
    marginTop: 20,
    backgroundColor: "#fff",
    width: width / 1.1,
    height: 100,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
  },
  questionTitleView: {
    marginTop: 20,
    backgroundColor: "#fff",
    width: width / 1.1,
    height: 50,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    justifyContent: "center",
  },
  containerStyle: {
    marginTop: 15,
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 30,
    width: width / 1.1,
    backgroundColor: "#fff",
    paddingRight: 10,
    paddingLeft: 15,
    borderBottomColor: "transparent",
  },
  disableCategoryStyle: {
    marginTop: 15,
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 30,
    width: width / 1.1,
    backgroundColor: "lightgrey",
    paddingRight: 10,
    paddingLeft: 15,
    borderBottomColor: "transparent",
  },
  enableCategoryStyle: {
    marginTop: 15,
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 30,
    width: width / 1.1,
    backgroundColor: "#fff",
    paddingRight: 10,
    paddingLeft: 15,
    borderBottomColor: "transparent",
  },
  textInputStyle: {
    width: "95%",
    paddingLeft: 20,
  },
  pickerStyle: {
    borderRadius: 30,
    width: width / 1.1,
    paddingLeft: 15,
    marginLeft: 10,
  },
  dropDownView: {},
  enableWhomStyle: {
    marginTop: 15,
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 30,
    width: width / 1.1,
    backgroundColor: "#fff",
    paddingRight: 10,
    paddingLeft: 15,
    flexDirection: "row",
    height: 50,
    justifyContent: "center",
    paddingRight: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  disableWhomStyle: {
    marginTop: 15,
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 30,
    width: width / 1.1,
    backgroundColor: "lightgrey",
    paddingRight: 10,
    paddingLeft: 15,
    flexDirection: "row",
    height: 50,
    justifyContent: "center",
    paddingRight: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  whomStyle: {
    color: "gray",
  },
  lookup: {
    color: "#fff",
  },
  enableSubmitBtn: {
    marginTop: 25,
    width: width / 1.1,
    backgroundColor: "#18A4E0",
    borderRadius: 25,
    marginVertical: 10,
    paddingVertical: 13,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  disableSubmitBtn: {
    marginTop: 25,
    width: width / 1.1,
    backgroundColor: "#2b80a4b5",
    borderRadius: 25,
    marginVertical: 10,
    paddingVertical: 13,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  buttonText: {
    fontSize: 18,
    color: "#ffffff",
    textAlign: "center",
  },
  slideContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.58,
    shadowRadius: 16.0,
    elevation: 24,
    flex: 1,
    backgroundColor: "white",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: moderateScale(15),
  },
  slideMainView: {
    flexDirection: "row",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    backgroundColor: "white",
    justifyContent: "space-between",
    marginTop: 5,
    paddingTop: 15,
    paddingLeft: 15,
    borderRadius: 30,
  },
  backButtonStyle: {
    width: 20,
    marginTop: 14,
    height: 20,
    tintColor: "#000",
  },
  closeSearch: {
    width: 15,
    height: 15,
    tintColor: "#000",
  },
  closeSearchView: {
    position: "absolute",
    top: 15,
    right: 25,
  },
  headingText: {
    fontSize: 18,
    marginTop: 9,
    alignItems: "center",
    alignSelf: "center",
    marginLeft: 15,
  },
  sideImg: {
    width: 20,
    height: 20,
    marginTop: 14,
  },
  ratingHeaderView: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5,
    paddingTop: 15,
    paddingLeft: 15,
  },
  SelectButton: {
    marginTop: 15,
    width: "20%",
    backgroundColor: "#19a4df",
    borderRadius: 25,
    marginVertical: 10,
    paddingVertical: 13,
  },
  SelectButtonText: {
    fontSize: height > 760 ? RFValue(10) : RFValue(12),
    color: "#ffffff",
    textAlign: "center",
  },
  messageView: {
    padding: 10,
  },
  DataMessageView: {
    padding: 10,
    borderBottomWidth: 1,
  },
  titleStyle: {
    color: "#19a4df",
    fontSize: RFValue(16),
    marginBottom: 4,
    fontWeight: "bold",
  },
  ratingDateView: {
    flexDirection: "row",
  },
  editIconTouch: {
    backgroundColor: "#19a4df",
    alignItems: "center",
    borderRadius: 15,
    justifyContent: "center",
    height: verticalScale(30),
    width: scale(54),
  },
  commentStyle: {
    marginTop: 3,
    fontSize: RFValue(14),
  },
  mainView: {
    marginTop: 5,
    paddingTop: 10,
    paddingLeft: 15,
  },
  searchBoxView: {
    backgroundColor: "#fff",
    width: "95%",
    height: 40,
    marginTop: 3,
  },
  searchInputStyle: {
    backgroundColor: "#fff",
    paddingLeft: 10,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderWidth: 1,
    borderColor: "lightgray",
  },
});
