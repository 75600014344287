import React, { useState } from 'react';
import { Image, Modal, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import Images from '../../../common/images';
import { Network } from '../../../service/api';
import BlurBackground from '../../BlurBackground';
import { styles } from '../OpportunityReport.styles';

function SecretCodeModal({ isAccess = true, onGenerate = () => { }, onVerified = () => { }, verificationCode, onClose = () => { } }) {
    const [secret, setSecret] = useState("");
    const [error, setError] = useState("");
    const guestLogin = useSelector(
        (state) => state.pathReducer.getImmipathDetails.guestLogin
    );
    const Token = useSelector((state) => state.authReducer.authData.token);
    const userDetails = useSelector(
        (state) => state.userReducer.getUser.userDetails
    );

    const getDataFromShareableLink = async () => {
        const headers = {
            Accept: "application/json",
            Authorization: guestLogin ? `guest@immipath` : userDetails?.userid + "@" + Token,
        };
        let response = await Network(`/immipaths/fetch_data_from_code/${verificationCode}`, "POST", { secret: secret }, headers)
        if (response.status === "Success") {
            onVerified(response.data);
        } else {
            setError("Please enter a valid secret code");
        }
    }

    const handlePress = () => {
        if (!secret) {
            setError("secret code is required");
            return;
        }
        if (isAccess) {
            getDataFromShareableLink();
        } else {
            onGenerate(secret);
        }
    }

    return <Modal transparent>
        <BlurBackground />
        <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <View style={styles.secretModal}>
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                    {!isAccess ?
                        <TouchableOpacity onPress={onClose}>
                            <Image source={Images.closeBlack} style={{ width: 25, height: 25 }} />
                        </TouchableOpacity> : null}
                </View>
                <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                    <View>
                        <Text style={styles.textT}>Enter Secret Code</Text>
                    </View>
                    <Text>This Secret code is used to access your Shareable link</Text>
                    <View style={styles.inputBox}>
                        <TextInput style={styles.input} value={secret} onChangeText={(text) => {
                            setError(null);
                            setSecret(text)
                        }} placeholder="secret code ..." />
                    </View>
                    {error ? <Text style={{ color: "red" }}>{error}</Text> : null}
                    <TouchableOpacity onPress={handlePress} style={styles.nextWrapper}>
                        <Text style={styles.nextText}>{isAccess ? "Access Link" : "Generate Link"}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    </Modal>
}

export default SecretCodeModal