import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  Platform,
} from "react-native";
import { styles } from "./messageChat.styles";
import { setAdviceChannelSelctions } from "../../actions/adviceChannel.action";
import { useSelector, useDispatch } from "react-redux";
import DocumentPicker from "react-native-document-picker";
import Images from "../../common/images";
import fontFamily from "../../common/fontFamily";
import RNPicker from "./../RNModalPicker";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import storage from "@react-native-firebase/storage";
import { heightPercentageToDP } from "../../common/responsiveScreen";

export default function QuestionItem({
  item,
  postChatHistory,
  enableTextBox,
  occupations,
  botName,
  botIcon,
  botCategory,
  ref_id,
  Message_Send,
}) {
  const [selectedOptions, setselectedOptions] = useState([]);
  const [answeredQuestionIndex, setansweredQuestionIndex] = useState([0]);
  const [fileUri, setFileUri] = useState(null);
  const [showUploadBox, setshowUploadBox] = useState(false);
  const [showDropdown, setShowdropdown] = useState(false);
  const [uploadedDocName, setuploadedDocName] = useState(null);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const userselections = useSelector(
    (state) => state.adviceChannelReducer?.userselections
  );
  const dispatch = useDispatch();

  const hanldleSelection = async (option) => {
    let temp = { ...userselections };
    if (item.response_type == "select") {
      temp[item.questionIndex] = option;
      setselectedOptions(temp);
      dispatch(setAdviceChannelSelctions(temp));
    } else {
      temp[item.questionIndex] = option;
      dispatch(setAdviceChannelSelctions(temp));
      setselectedOptions(temp);
    }
    let res = await postChatHistory(`${item.question}`, 'Immigreat Support', null, option, false)
    Message_Send(null, option, false);
  }

  const uploadFileToFirebase = async () => {
    if (Platform.OS == "web") {
      const storage = getStorage();
      try {
        const storageRef = ref(
          storage,
          `adviceSupportingDoc/${userDetails?.userid}/${ref_id}/${uploadedDocName}`
        );
        uploadBytes(storageRef, uploadedFile).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((downloadURL) => { });
        });
      } catch (error) {
        console.log("errr", error);
      }
    } else {
      var storageRef = storage().ref(
        `/adviceSupportingDoc/${userDetails?.userid}/${ref_id}/${uploadedDocName}`
      );
      storageRef
        .putFile(fileUri.uri)
        .on(storage.TaskEvent.STATE_CHANGED, (snapshot) => {
          if (snapshot.state === storage.TaskState.SUCCESS) {
            storage()
              .ref(`${storageRef.path}`)
              .getDownloadURL()
              .then((url) => { });
          }
        });
    }
  };

  const uploadDocument = async () => {
    try {
      if (Platform.OS == "web") {
        let input = document.createElement("input");
        input.type = "file";
        input.accept = ".xls,.xlsx, .pdf, .txt";
        input.onchange = async () => {
          let files = Array.from(input.files);
          const imageDataUrl = await readFile(files[0]);
          setuploadedFile(files[0]);
          setuploadedDocName(files[0]?.name);
        };
        Err["uploadedDocName"] = false;
        input.click();
      } else {
        const response = await DocumentPicker.pickSingle({
          type: [DocumentPicker.types.allFiles],
        });
        const source = { uri: response.uri };
        const fileExtension = response.type.split("/");
        var fileName = `${userDetails?.userid}.${fileExtension[1]}`;
        setFileUri(source);
        setuploadedDocName(fileName);
        uploadFileToFirebase();
        // Message_Send(null, fileName)
        postChatHistory(
          `${item.question}`,
          "Immigreat Support",
          null,
          fileName
        );
      }
    } catch (err) {
      if (DocumentPicker.isCancel(err)) {
      } else {
        throw err;
      }
    }
  };

  const handleNo = () => {
    let temp = { ...userselections };
    temp[item.questionIndex] = "No";
    dispatch(setAdviceChannelSelctions(temp));
    postChatHistory(`${item.question}`, "Immigreat Support", null, "No");
  };

  const renderDocumentUploader = () => {
    return (
      <View style={styles.selectionView}>
        {userselections[item.questionIndex] ? (
          <Text>{userselections[item.questionIndex]}</Text>
        ) : (
          <TouchableOpacity style={{ alignItems: "center" }}>
            <Image
              source={Images.uploadIcon}
              style={{ height: 18, width: 18, resizeMode: "contain" }}
            />
            <Text
              style={{
                fontFamily: fontFamily.robotoRegular,
                fontSize: heightPercentageToDP("1.5%"),
                marginTop: 5,
              }}
            >
              {uploadedDocName ? uploadedDocName : "Upload"}
            </Text>
            <View style={styles.uploadOptionsView}>
              <TouchableOpacity
                onPress={uploadDocument}
                style={styles.uploadButtons}
              >
                <Text style={styles.uploadButtonsText}>Yes</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={handleNo} style={styles.uploadButtons}>
                <Text style={styles.uploadButtonsText}>No</Text>
              </TouchableOpacity>
            </View>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const renderSelectComponent = () => {
    return (
      <>
        {
          !userselections[item?.questionIndex] ?
            <View style={styles.selectionView}>
              {

                item.options.map((s) => (
                  <TouchableOpacity disabled={userselections[item?.questionIndex]}
                    onPress={() => !userselections[item?.questionIndex] && hanldleSelection(s)} style={styles.optionView}>
                    <View style={styles.checkBox}>
                      {
                        userselections[item?.questionIndex] == s && <View style={styles.checked} />
                      }
                    </View>
                    <Text style={{ color: 'white' }}>
                      {s}
                    </Text>
                  </TouchableOpacity>
                ))
              }
            </View> : null
        }
      </>

    )
  }

  useEffect(() => {
    if (item.response_type == "integer" || item.response_type == "string") {
      // enableTextBox(item.question)
    } else if (item.response_type == "file") {
      setshowUploadBox(true);
      // enableTextBox(null, null)
    }
    //  else {
    //   setshowUploadBox(false)
    //   enableTextBox(null, false)
    // }

    if (item.is_data_available_in_profile) {
      if (userDetails?.occupation == "" || userDetails?.occupation == null) {
        setShowdropdown(true);
      }
    } else {
      setShowdropdown(false);
    }
  }, [item]);

  renderSearchableDropdown = () => {
    return (
      <View style={[styles.dropDownView, { marginTop: 25 }]}>
        <RNPicker
          data={occupations}
          defaultValue={true}
          pickerTitle={"Choose"}
          showSearchBar={true}
          disablePicker={false}
          changeAnimation={"fade"}
          searchBarPlaceHolder={"Search....."}
          showPickerTitle={true}
          selectedText={userselections[item?.questionIndex] || "Choose"}
          placeHolderText={"Choose"}
          selectedTextStyle={styles.selectLabelTextStyle}
          placeHolderTextColor={styles.placeHolderTextStyle}
          pickerStyle={styles.pickerStyle}
          selectedValue={(index, item1) => {
            let temp = { ...userselections };
            temp[item.questionIndex] = item1.name;
            dispatch(setAdviceChannelSelctions(temp));
            postChatHistory(
              `${item.question}`,
              "Immigreat Support",
              null,
              item1.name
            );
          }}
        />
      </View>
    );
  };

  return (
    <>
      {
        <TouchableOpacity activeOpacity={1} style={styles.questionWrapper}>
          <View style={styles.questionItem}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                marginBottom: 5,
              }}
            >
              <Image style={styles.botIcon} resizeMode="contain" source={botIcon} />
              <Text style={styles.botName}>
                {botName}
              </Text>
              <Text style={styles.botCategory}>
                {", the " + botCategory + " bot "}
              </Text>
            </View>
            <Text style={styles.questionText}>{item.question}</Text>
          </View>
          {(item.response_type == "select" ||
            item.response_type == "multiselect") &&
            !item.is_data_available_in_profile ? (
            renderSelectComponent()
          ) : (
            <View />
          )}
          {showDropdown ? renderSearchableDropdown() : <View />}
          {showUploadBox ? renderDocumentUploader() : <View />}
        </TouchableOpacity>
      }
    </>
  );
}
