import { StyleSheet } from "react-native";
import fontFamily from "../fontFamily";
import { mobilBrower } from "../helpers";
import { widthPercentageToDP } from "react-native-responsive-screen";
import { responsiveText } from "../normalize";

export const styles = StyleSheet.create({
  mainView: {
    height: mobilBrower() ? 100 : 120,
    width: "100%",
    justifyContent: "center",
    display: "flex",
    borderBottomWidth: 0,
    borderColor: "red",
    backgroundColor: "#0F1143",
    zIndex: 1
  },
  activeDot: {
    height: 8,
    width: 8,
    borderRadius: 4,
    position: 'absolute',
    bottom: -10,
    backgroundColor: '#F1AB32',
  },
  innerView: {
    maxWidth: "100%",
    width: mobilBrower() ? "100%" : "70%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    alignSelf: "center",
  },
  titleContainer: {
    width: "100%",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    zIndex: -1,
    backgroundColor: "#0F1143",
    paddingBottom: 35
  },
  title: {
    color: "white",
    fontSize: 45,
    fontWeight: "700",
    fontFamily: fontFamily.effra,
  },
  screenTitle: {
    color: "white",
    fontSize: responsiveText(16),
    fontFamily: fontFamily.effra,
  },
  cartlength: {
    height: 20,
    width: 20,
    position: "absolute",
    top: -12,
    right: 4,
    backgroundColor: "red",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  cartlengthtext: {
    color: "white",
    fontSize: responsiveText(14),
  },
  innerViewMobile: {
    width: "90%",
    alignItems: "center",
    marginTop: 5,
    paddingBottom: 15,
    justifyContent: "space-between",
    flexDirection: "row",
    alignSelf: "center",
  },
  headerItems: {
    marginLeft: 30,
    alignItems: 'center',
    justifyContent: 'center'
  },
  tabView: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    flex: 1,
    marginHorizontal: 40,
  },
  dashboardItemText: {
    color: "white",
    fontWeight: "400",
    fontSize: 16,
  },
  loginButton: {
    borderWidth: 2,
    borderColor: 'white',
    marginHorizontal: 10,
    borderRadius: 36,
    paddingHorizontal: 31,
    paddingVertical: 10
  },
  logo: {
    height: mobilBrower() ? widthPercentageToDP("9%") : widthPercentageToDP("3%"),
    width: mobilBrower() ? widthPercentageToDP("9%") : widthPercentageToDP("2.9%"),
  },
  backIcon: {
    height: widthPercentageToDP("5.5%"),
    width: widthPercentageToDP("5.5%"),
  },
  notificationBell: {
    height: mobilBrower() ? widthPercentageToDP("9.8%") : 37,
    width: mobilBrower() ? widthPercentageToDP("9.8%") : 37,
  },
  userCart: {
    height: 30,
    width: 30,
    marginRight: 20,
  },
  userIcon: {
    height: mobilBrower() ? widthPercentageToDP("9%") : 50,
    width: mobilBrower() ? widthPercentageToDP("9%") : 50,
    marginLeft: 25,
    borderRadius: mobilBrower() ? widthPercentageToDP("9%") / 2 : 50 / 2,
  },
  leftView: {
    flexDirection: "row",
    alignItems: "center",
  },
  rightView: {
    flexDirection: "row",
    alignItems: "center",
  },
  dropDownContainer: {
    position: "absolute",
    top: "120%",
    left: "-50%",
    zIndex: 9999,
    paddingHorizontal: 15,
    paddingVertical: 10,
    width: 180,
    shadowColor: "black",
    shadowOffset: {
      width: 5,
      height: 5
    },
    shadowRadius: 5,
    shadowOpacity: 0.5,
    borderRadius: 10,
    backgroundColor: "#F1F1F1"
  },
  dropViewBtn: {
    paddingHorizontal: 12,
    paddingVertical: 10,
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center"
  },
  vector2: {
    height: 30,
    width: 30,
    resizeMode: "contain",
    marginTop: -20,
    alignSelf: "center",
    top: 0,
    zIndex: -1,
    position: "absolute",
    alignSelf: "center",
  },
});
