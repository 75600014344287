import React from "react";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AnimatedProgressProvider from "./AnimatedProgressProvider";

const CircleProgressBar = ({ progressPercentage, children, color, pathStyles = {}, trailStyles = {} }) => {
  return (
    <AnimatedProgressProvider
      valueStart={0}
      valueEnd={progressPercentage}
    >
      {value => (
        <CircularProgressbarWithChildren
          value={value}
          size={250}
          circleRatio={0.60}
          styles={{
            path: {
              stroke: color || (progressPercentage > 25 ? "#00ff00" : "#F1AB32"),
              strokeLinecap: "butt",
              transform: `rotate(${1 / 2 + 1 / 5}turn)`,
              transformOrigin: 'center center',
              strokeWidth: 7,
              transition: "stroke-dashoffset 0.5s ease 0s",
              ...pathStyles
            },
            trail: {
              stroke: color || (progressPercentage > 25 ? "green" : "orange"),
              strokeLinecap: "butt",
              transform: `rotate(${1 / 2 + 1 / 5}turn)`,
              transformOrigin: 'center center',
              strokeWidth: 3,
              ...trailStyles
            }
          }}
        >
          {children(value)}
        </CircularProgressbarWithChildren>
      )}
    </AnimatedProgressProvider>
  );
};

export default CircleProgressBar;
