import React, { useState, useEffect } from 'react'
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    TextInput,
    Platform,
    Modal,
} from "react-native";
import { family } from "../common";
import * as Animatable from 'react-native-animatable';

function EnterReferralCodeModal(props) {
    const [inputBox, setInputBox] = useState(false)
    useEffect(() => {
        setInputBox(false)
    }, [props.visible])
    return (
        <Modal transparent visible={props.visible}>
            <View style={styles.mainView}>
                <Animatable.View animation={'slideInUp'} duration={500} style={styles.innerView}>
                    {!inputBox ?
                        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={styles.text}>
                                Do you have a referral code?
                            </Text>
                            <View style={styles.buttonView}>
                                <TouchableOpacity onPress={() => setInputBox(true)} style={[styles.buttons, { marginRight: 10 }]}>
                                    <Text style={styles.buttonsText}>
                                        Yes
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={()=>props._handleReferralModalClose()} style={[styles.buttons, { marginLeft: 10 }]}>
                                    <Text style={styles.buttonsText}>
                                        No
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View> :
                        <View style={styles.textBoxContanier}>
                            <TextInput
                                placeholder='Enter referral code'
                                placeholderTextColor='#BDBDBD'
                                onChangeText={(t)=>props.handleReferralInput(t)}
                                style={{ height: 45, marginTop: 10, fontSize: 15, fontFamily: family.semibold, width: '90%', textAlign: 'center' }}
                            />
                            <TouchableOpacity onPress={()=>{ props._handleReferralModalClose('checkCode')}} style={[styles.buttons, { marginBottom: 15 }]}>
                                <Text style={styles.buttonsText}>
                                    Submit
                                </Text>
                            </TouchableOpacity>
                        </View>
                    }
                </Animatable.View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    mainView: {
        height: '100%',
        width: '100%',
        backgroundColor: '#222222c7',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textBoxContanier: {
        alignItems: 'center',
        width: '100%'
    },
    innerView: {
        alignItems: 'center',
        justifyContent: 'center',
        width: Platform.OS == 'web' ? '40%' : '85%',
        backgroundColor: 'white',
        borderRadius: 10,
    },
    text: {
        marginRight: 5,
        fontWeight: '700',
        fontSize: 17,
        marginTop: 15,
        textAlign: 'center'
    },
    buttonView: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
        width: '40%',
        marginTop: 10,
        justifyContent: 'space-between',
        marginBottom: 15,
    },
    buttons: {
        backgroundColor: '#030135',
        borderRadius: 10,
    },
    buttonsText: {
        padding: 10,
        paddingLeft: 12,
        paddingRight: 12,
        color: 'white',
        fontWeight: '800',
        fontSize: 13
    },
})
export default EnterReferralCodeModal
