import Timer from "react-timer-mixin";
import AsyncStorage from "@react-native-community/async-storage";
import { Network, apiPaths } from "../service/api";
import { Alert } from 'react-native';

export const getUserImmibucks = (userId, token) => {
  return async dispatch => {
    try {
      return Network(apiPaths.immibucks, 'GET', null, headers).then(response => {
        if (response.status === 'success') {
          dispatch({
            type: "GET_USERS_IMMIBUCKS",
            payload: res.status
          });
        } else {

        }
        return response;
      })
    } catch (error) {
      dispatch({
        type: "GET_USERS_IMMIBUCKS_FAIL",
        payload: error.responseBody
      });
      return error;
    }
  }

}

export const immiBuckAdd = (Obj, userID, token) => {
  return async dispatch => {
    try {
      const url = `${userID}/add`;
      const balance_url = `/users/${userID}`
      const headers = {
        Accept: "application/json",
        Authorization: token
      }

      return Network(apiPaths.checkRewardMilestone + url, 'POST', Obj, headers).then((res)=>{
        if (res.status === 'Success') {
            dispatch(getImmibuckBalance(balance_url, headers));
        }
      });
    } catch (error) {

    }
  }
}

export const getImmibuckBalance = (url, headers) => {
  return async dispatch => {
    Network(url, 'GET', null, headers).then(res => {
      if (res.status && res.status == 'User Found!') {
        dispatch({
          type: "GET_IMMIBUCK_BALANCE",
          payload: res.immibucks.balance
        });
      }
    })

  }
}

export const getImmibuckReward = (url, headers) => {
  return async dispatch => {
    Network(url, 'GET', null, headers).then(res => {
      if (res.status && res.status == 'User Found!') {
        dispatch({
          type: "GET_IMMIBUCK_REWARD",
          payload: res.immibucks.history?.reward
        });
      }
    })

  }
}

export const getUnlocks = (url, headers) => {
  return async dispatch => {
    Network(apiPaths.checkRewardMilestone + url, 'GET', null, headers).then(res => {
      if (res.status === 'Success') {
        dispatch({
          type: "GET_UNLOCKS",
          payload: res.unlocks
        });
        // AsyncStorage.setItem('UnlockItems', JSON.stringify(res.unlocks))
      }
    })
  }
}