import { StyleSheet } from "react-native";
import colors from "../../../../common/colors";
import { mobilBrower } from "../../../../common/helpers";
import { responsiveText } from "../../../../common/normalize";

export const styles = StyleSheet.create({
    title: {
        color: "#fff",
        fontSize: responsiveText(42),
        textTransform: "uppercase",
        textAlign: "center",
        fontWeight: "bold",
        marginTop: "3%"
    },
    cutoffText: {
        color: "black",
        textAlign: "center",
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20,
    },
    pointsView: {
        alignItems: "center",
        padding: 5,
        justifyContent: "center",
        width: "80%",
        alignSelf: "center",
        flex: 1
    },
    pointsContent: {
        width: "100%",
    },
    countryName: {
        color: "#fff",
        fontWeight: "bold",
        fontSize: responsiveText(25),
        marginBottom: 5,
        marginRight: 10,
        textAlign: "center",
    },
    titleView: {
        width: "100%",
        backgroundColor: "transparent",
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        flexDirection: "row",
        alignItems: "center",
        borderBottomWidth: 1,
        borderColor: "rgba(255, 255, 255, 0.3)",
        justifyContent: "flex-end",
        padding: 5,
    },
    pointsText: {
        fontSize: responsiveText(20),
        marginLeft: 8,
        bottom: 15,
        color: "black",
    },
    statusIcon: {
        position: "absolute",
        height: 16,
        width: 16,
        top: 10,
        right: -20,
    },
    prStatus: {
        fontSize: responsiveText(16),
        paddingHorizontal: 12,
        paddingVertical: 8,
        color: "#fff",
        alignSelf: "center",
    },
    prStatusNoteW: {
        fontSize: responsiveText(16),
        alignSelf: "center",
    },
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    },
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
        // padding: 25,
        borderRadius: 15,
        marginTop: 20,
    },
    perInfoWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    perWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
    },
    completeText: {
        alignItems: "center",
        color: "#fff",
        fontSize: 18,
        marginBottom: -12,
        display: "flex",
        justifyContent: "center",
    },
    completePercentageText: {
        alignItems: "center",
        color: "#fff",
        fontSize: mobilBrower() ? 60 : responsiveText(60),
        marginBottom: -12,
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        marginLeft: 10,
    }
});
