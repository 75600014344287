import { StyleSheet } from "react-native";
import { mobilBrower } from "../../common/helpers";

export const styles = StyleSheet.create({
  mainView: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  innerView: {
    width: !mobilBrower() ? "37%" : "90%",
    backgroundColor: "#F1F1F1",
    borderRadius: 15,
    maxHeight:"60%"
  },
  closeIcon: {
    height: !mobilBrower() ? 25 : 15,
    width: !mobilBrower() ? 25 : 15,
  },
  closeIconButton: {
    marginRight: 15,
    alignSelf: "flex-end",
    marginTop: 10,
  },
  topIcon: {
    height: 55,
    width: 55,
    alignSelf: "center",
    marginBottom: 15,
    resizeMode: "contain",
  },
  Vector2: {
    height: 30,
    width: 30,
    resizeMode: "contain",
    marginTop: -20,
    alignSelf: "center",
  },
});
