import { StyleSheet } from "react-native";
import colors from "../../common/colors";
import { mobilBrower } from "../../common/helpers";
import { responsiveText } from "../../common/normalize";

export const styles = StyleSheet.create({
    renderIndex: {
        height: 40,
        width: "100%",
        marginLeft: 5,
        alignItems: "flex-end",
        resizeMode: "stretch"
    },
    tableContentText: {
        textAlign: "center",
        fontSize: responsiveText(16),
        color: colors.DARK_BLUE,
        marginLeft: 10,
    },
    renderPollutionImg: {
        width: mobilBrower() ? "100%" : "70%",
        height: '100%',
        resizeMode: "contain",
    }
})