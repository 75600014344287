import React, { useState, useEffect } from "react";
import { Text, View, TouchableOpacity, Image, Modal } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Images, helpers } from "../../common";
import { addToCart, updateCart } from "../../actions/referral.actions";
import { styles } from "./productInfoModal.styles";
import { animated as a, useSpring, useTrail } from "@react-spring/native";
import { isEmpty } from 'lodash';

const ProductInfoModal = ({
  selectedItem,
  openModal,
  onclose,
  baseCurrency,
}) => {
  let defaultColor = selectedItem[1].customisations.color?.length ?selectedItem[1].customisations.color[0]:null;
  let defaultSize = selectedItem[1].customisations.sizes?.length ?selectedItem[1].customisations.sizes[0]:null
  const [selectedColor, setselectedColor] = useState(defaultColor);
  const [selectedSize, setselectedSize] = useState(defaultSize);
  const [dropDownItem, setdropDownItem] = useState(null);
  const [selectedPrint, setselectedPrint] = useState(null);
  const cartItems = useSelector(
    (state) => state.referralReducer.referralData.cartItems
  );
  const dispatch = useDispatch();

  const handleColorClick = (color) => {
    setselectedColor(color);
  };

  const handleSizeClick = (size) => {
    setselectedSize(size);
  };

  const checkPrintSelected = () => {
    if (dropDownItem.length > 0) {
      if (selectedPrint !== null) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const onPrintSelect = (item) => {
    setselectedPrint(item.value);
  };

  const checkAndIncreamentIfItemAlreadyInCart=(hascustomisations)=>{
    let _cartItems = cartItems;
    let isInCart = false;
    _cartItems = _cartItems.map((item) => {
      const { product_id, size, color, quantity } = item;
      if(hascustomisations && selectedItem[1].product_id == product_id && size == selectedSize && color == selectedColor) {
          item.quantity = quantity + 1;
          isInCart = true;
      }else if(!hascustomisations && selectedItem[1].product_id == product_id){
        item.quantity = quantity + 1;
        isInCart = true;
      }
      return item
    });
    if (isInCart) {
      dispatch(updateCart(_cartItems));
      return  true;
    }
    return false;
  }

  const handleProceed = () => {
    let _selectedItem = { ...selectedItem[1] };
    if (!isEmpty(selectedItem[1].customisations)) {
      if (selectedColor !== null && checkPrintSelected()) {
        let isItemInCart = checkAndIncreamentIfItemAlreadyInCart(true);
        if(isItemInCart){
          onclose();
          return ;
        }
        var val = Math.floor(1000 + Math.random() * 9000);
        if (selectedItem[1].customisations.sizes?.length > 0) {
          _selectedItem.size = selectedSize;
        }
        _selectedItem.color = selectedColor;
        _selectedItem.printing = selectedPrint;
        _selectedItem.quantity = 1;
        _selectedItem.uniqId = val;
        dispatch(addToCart(_selectedItem));
        onclose();
        // NavigationService.navigate('UserCart')
      } else {
        alert("Select color and size");
      }
    } else {
      let isItemInCart = checkAndIncreamentIfItemAlreadyInCart(false);
        if(isItemInCart){
          onclose();
          return ;
        }
      var val = Math.floor(1000 + Math.random() * 9000);
      _selectedItem.quantity = 1;
      _selectedItem.uniqId = val;
      dispatch(addToCart(_selectedItem))
      onclose()
    }
  };

  useEffect(() => {
    let dropDown = [];
    selectedItem[1] &&
      selectedItem[1].customisations &&
      selectedItem[1].customisations.printing &&
      selectedItem[1].customisations.printing.forEach((s) => {
        dropDown.push({
          label: `${s}`,
          value: s,
        });
      });
    setdropDownItem(dropDown);
    selectedItem[1] && !isEmpty(selectedItem[1].customisations) &&
      Object.entries(selectedItem[1].customisations).forEach((s) => {
        if (s[1].length == 1) {
          s[0] == "color" ? setselectedColor(s[1[0]]) : s[0] == "sizes" ? setselectedSize(s[1][0]) : null
        }
      })
  }, [selectedItem]);

  const price = selectedItem[1]?.price
    ? selectedItem[1].price
    : selectedItem[1].offer_price
      ? selectedItem[1].offer_price
      : 0;

  const getColoredThumbnail = () => {
    if (selectedColor && selectedItem[1].color_thumbnail) {
      return selectedItem[1].color_thumbnail[selectedColor];
    } else {
      return selectedItem[1].thumbnail;
    }
  };

  const squeezeIn = useSpring({
    from: {
      height: "90%",
    },
    to: {
      height: "50%",
    },
  });

  const squeezeContentIn = useSpring({
    from: {
      marginTop: 80,
    },
    to: {
      marginTop: 0,
    },
  });

  const colors = useTrail(selectedItem[1].customisations.color?.length, {
    from: { opacity: 0, marginRight: -5 },
    to: { opacity: 1, marginRight: 0 },
  });

  const sizes = useTrail(selectedItem[1].customisations.sizes.length, {
    from: { opacity: 0, marginRight: -5 },
    to: { opacity: 1, marginRight: 0 },
  });

  const easeFromBottom = useSpring({
    delay: 100,
    from: {
      width: "0%",
    },
    to: {
      width: "85%",
    },
  });

  return (
    <Modal transparent visible={openModal}>
      <View style={styles.mainView}>
        <a.View style={[styles.imgView, squeezeIn]}>
          <TouchableOpacity onPress={() => onclose()} style={styles.backButton}>
            <Image style={styles.cartBack} source={Images.cartBack} />
          </TouchableOpacity>
          <a.Image
            style={[styles.dailyOfferimg]}
            source={{ uri: getColoredThumbnail() }}
          />
        </a.View>
        <a.View style={[styles.contentView, squeezeContentIn]}>
          <View>
            <View style={styles.titleRow}>
              <Text style={styles.title}>{selectedItem[1].title}</Text>
              <Text style={styles.price}>
                {helpers.getCurrencySymbol(baseCurrency) +
                  helpers.storeCurrencyConverer(baseCurrency, price)}
              </Text>
            </View>
            <View style={styles.titleRow}>
              <Text style={styles.description}>
                {selectedItem[1].description}
              </Text>
            </View>

            {selectedItem[1].customisations.color?.length > 0 ? (
              <View style={styles.titleRow}>
                <Text style={styles.title}>Choose color</Text>
                <View style={styles.colorMainView}>
                  {colors.map(({ ...otherProps }, i) => {
                    const s = selectedItem[1].customisations.color[i];
                    return (
                      <a.View
                        key={i}
                        onTouchStart={() => handleColorClick(s)}
                        style={[
                          styles.colorOuter,
                          otherProps,
                          {
                            borderWidth: selectedColor == s ? 2 : 1,
                            borderColor: selectedColor == s ? "green" : "black",
                          },
                        ]}
                      >
                        <View
                          style={[styles.colorView, { backgroundColor: s }]}
                        />
                      </a.View>
                    );
                  })}
                </View>
              </View>
            ) : (
              <View />
            )}
            {selectedItem[1].customisations.sizes?.length > 0 ? (
              <View style={styles.titleRow}>
                <Text style={styles.title}>Choose Size</Text>
                <View style={styles.colorMainView}>
                  {sizes.map(({ ...otherProps }, i) => {
                    const s = selectedItem[1].customisations.sizes[i];
                    return (
                      <a.View
                        key={i}
                        onTouchStart={() => handleSizeClick(s)}
                        style={[
                          styles.colorOuter,
                          {
                            ...otherProps,
                            borderWidth: selectedSize == s ? 1 : 1,
                            borderColor: selectedSize == s ? "green" : "black",
                          },
                        ]}
                      >
                        <View
                          style={[
                            styles.colorView,
                            { backgroundColor: "white" },
                          ]}
                        >
                          <Text style={{ fontSize: 10 }}>{s}</Text>
                        </View>
                      </a.View>
                    );
                  })}
                </View>
              </View>
            ) : (
              <View />
            )}
          </View>
          <a.View style={[easeFromBottom, styles.bottomView]}>
            <TouchableOpacity
              onPress={() => handleProceed()}
              style={styles.addButton}
            >
              <Text style={styles.addtext}>Add to Cart</Text>
            </TouchableOpacity>
          </a.View>
        </a.View>
      </View>
    </Modal>
  );
};

export default ProductInfoModal;
