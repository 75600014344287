import React from "react";
import { View, Text, Image, Dimensions, TouchableOpacity } from "react-native";
import { useDispatch } from "react-redux";
import { callAskCommunity } from "../../../../actions/discussion.action";
import { Images } from "../../../../common";
import fontFamily from "../../../../common/fontFamily";
const { width, height } = Dimensions.get("screen");

export const EmptyDiscussion = ({ setShowCategories, selectedCategory }) => {
  const dispatch = useDispatch();

  const handleAskCommunity = () => {
    let payload = {
      call: true,
      category: selectedCategory
    }
    dispatch(callAskCommunity(payload))
  }

  return (
    <View
      style={{
        flexDirection: "column",
        alignItems: "center",
        alignSelf: 'center',
        width: "90%",
      }}
    >
      <Image
        resizeMode="contain"
        source={Images.emptyDiscussion}
        style={{
          width,
          height: 280,
        }}
      />
      <View>
        <Text>You don't have any discussions yet</Text>
        <TouchableOpacity
          onPress={handleAskCommunity}
          style={{
            marginTop: 5,
            backgroundColor: "#0F1143",
            borderRadius: 9,
            padding: 15,
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: fontFamily.effra,
              fontSize: 15,
            }}
          >
            Ask Community
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
