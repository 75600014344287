import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  inputBox: {
    width: '90%',
    borderRadius: 20,
    height: 45,
    borderWidth: 0.5,
    alignSelf: 'center',
    marginTop: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textInputStyle: {
    paddingLeft: 10
  }
});
