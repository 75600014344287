import likeIconImg from "../images/discussions/like.png";
import dislikeIconImg from "../images/discussions/dislike.png";
import emptyDiscussionImg from "../images/discussions/emptyDiscussion.png";
import commentsImg from "../images/discussions/comment.png";
import moreDiscussionIconImg from "../images/discussions/moreDiscussionCategoryIcon.png";
import swipeWhiteImg from "../images/swipe_white.png";
import slideWhiteImg from "../images/slide_white.png";
import pi1Img from "../images/pollution/pi1.png";
import pi2Img from "../images/pollution/pi2.png";
import pi3Img from "../images/pollution/pi3.png";
import pi4Img from "../images/pollution/pi4.png";
import pi5Img from "../images/pollution/pi5.png";
import indexLowImg from "../images/traffic/index_low.png";
import indexMedImg from "../images/traffic/index_med.png";
import indexHeighImg from "../images/traffic/index_high.png";
import swallowImg from "../images/bots/swallow.png";
import keaImg from "../images/bots/kea.png";
import ternImg from "../images/bots/tern.png";
import macawImg from "../images/bots/macaw.png";
import interestIconImg from "../images/newprofile/R_(1).png";
import collapseIconImg from "../images/newprofile/expandIcon.png";
import editIconImg from "../images/newprofile/edit.png";
import expandIconImg from "../images/newprofile/plus.png";
import occupationIconImg from "../images/newprofile/R_(2).png";
import languagesIconImg from "../images/newprofile/R_(3).png";
import lifestylesIconImg from "../images/newprofile/R_(4).png";
import bellRingImg from "../images/bell-ring.png";
import loginbgImg from "../images/loginbg.png";
import nounAddCalendarImg from "../images/noun_add_calendar.png";
import nextArrowGrayImg from "../images/stepImages/next-arrow.png";
import loudSpeakerImg from "../images/volume-up-interface-symbol.png";
import languageFeatureIconImg from "../images/20944354-ai.png";
import studyingWithBookImg from "../images/immipathSearch/Boy-studying-with-a-book-at-the-table-with-lamp.png";
import houseImmigreatSearchImg from "../images/immipathSearch/house1.png";
import travellingAroundTheWorldImg from "../images/immipathSearch/Traveling-Around-The-World.png";
import workAtHomeImg from "../images/immipathSearch/Working-at-Home-Vector-Isometric-Collection.png";
import univExpandIconImg from "../images/immipathSearch/expand-5.png";
import univAddMoreIconImg from "../images/immipathSearch/plus.png";
import immipathSearchCheckedImg from "../images/immipathSearch/icon_s.png";
import immipathSearchUncheckedImg from "../images/immipathSearch/oval.png";
import univPremiumModalImageImg from "../images/immipathSearch/Group.png";
import chancesInfoIconImg from "../images/immipathSearch/information-button1.png";
import bookmarkIconImg from "../images/immipathSearch/bookmark1.png";
import polyChatAImg from "../images/immipathSearch/PolygonA.png";
import polyChatBImg from "../images/immipathSearch/PolygonB.png";
import languageBannerImg from "../images/20944354-ai.png";
import profileInterestImg from "../images/profileInterest.png";
import profileInterest2Img from "../images/profileInterest2.png";
import profileLangImg from "../images/profileLang.png";
import profileOccupationImg from "../images/profileOccupation.jpg";
import loginImageImg from "../images/Skeleton-01.png";
import loginImage2Img from "../images/Skeleton-01.png";
import infoIconImg from "../images/Login/notify.png";
import homeIconImg from "../images/homeIcon.png";
import rocketIconImg from "../images/rocket.png";
import mailIconImg from "../images/Login/mail.png";
import infoIcon2 from "../images/infoIcon2.png";
import keyIconImg from "../images/Login/password.png";
import facebookIconImg from "../images/facebook.png";
import googleIconImg from "../images/google.png";
import appleIconImg from "../images/apple.png";
import eyeIconImg from "../images/Login/to_view_pwd.png";
import unViewIconImg from "../images/Login/unview_pwd.png";
import splashScreenImg from "../images/splashscreen_new.png";
import forgetPasswordImg from "../images/ForgetPassword.png";
import pinValidateImg from "../images/pinvalidate.png";
import newIconFactsImg from "../images/new-icon.png";
import defaultimageImg from "../images/default-video-image.png";
import dashboardImgImg from "../images/dashboardImg.png";
import newFilterIconImg from "../images/NewFilterIcon.png";
import passwordlockImg from "../images/lock.png";
import resetPasswordImg from "../images/resetpassword.png";
import creatPasswordImg from "../images/resetpassword.png";
import DestinationImg from "../images/Destination.png";
import aboutUsImg from "../images/immigreat_aboutus.png";
import japan_unavailableImg from "../images/japan_unavailable.png";
import japan_unselectedImg from "../images/japan_unselected.png";
import japan_selectedImg from "../images/japan_selected.png";
import new_zealand_unavailableImg from "../images/new_zealand_unavailable.png";
import new_zealand_selectedImg from "../images/new_zealand_selected.png";
import new_zealand_unselectedImg from "../images/new_zealand_unselected.png";
import launch_screenImg from "../images/launch_screen.png";
import test1Img from "../images/test1.jpeg";
import test2Img from "../images/test2.jpeg";
import userIconImg from "../images/icon_new_cool.png";
import dashboardBgImg from "../images/Giant-wheel.gif";
import sunsetMainImg from "../images/bg_image.png";
import dashboardExplorerImg from "../images/dashboard_explorer_link.png";
import dashboardBottomImageImg from "../images/dashboard_bottom_img.png";
import newsMainImg from "../images/newsicon.png";
import newsMainNotifyImg from "../images/newsicon2.png";
import newsStories1Img from "../images/news_help1.png";
import newsStories2Img from "../images/news_help2.png";
import newsStories3Img from "../images/news_help3.png";
import newsStories4Img from "../images/news_help4.png";
import newsStories5Img from "../images/news_help5.png";
import newsStories6Img from "../images/news_help6.png";
import updateMainImg from "../images/updateicon.png";
import updateMainNotifyImg from "../images/updateicon2.png";
import applicationsMainImg from "../images/appicon.png";
import applicationsMainNotifyImg from "../images/appicon2.png";
import workImageImg from "../images/work_vec.png";
import dashboardHelp1Img from "../images/dashboard_help_explore.png";
import dashboardHelp2Img from "../images/dashboard_help_notif_button.png";
import dashboardHelp3Img from "../images/dashboard_help_events.png";
import dashboardHelp4Img from "../images/dashboard_help_policy.png";
import dashboardHelp5Img from "../images/dashboard_help_news_button.png";
import dashboardHelp6Img from "../images/dashboard_help_appl_button.png";
import dashboardHelp7Img from "../images/dashboard_immipath_saved.png";
import dashboardHelp8Img from "../images/dashboard_help_appl_promos.png";
import dashboardHelp9Img from "../images/dashboard_help_updates.png";
import pathExplorerEligb1Img from "../images/path_explorer_eligb1.png";
import pathExplorerStart1Img from "../images/path_explorer_start1.png";
import pathExplorerStart2Img from "../images/path_explorer_start2.png";
import pathExplorerBasic1Img from "../images/path_explorer_basic1.png";
import pathExplorerBasic2Img from "../images/path_explorer_basic_eligb.png";
import pathExplorerBasic3Img from "../images/path_explorer_basic_proceed_skip.png";
import factSelection1Img from "../images/fact_selection1.png";
import factSelection2Img from "../images/fact_selection2.png";
import factSelection3Img from "../images/fact_selection3.png";
import adviceChannel1Img from "../images/advice_channel1.png";
import adviceChannel2Img from "../images/advice_channel2.png";
import adviceChannel3Img from "../images/advice_channel3.png";
import cityDataView1Img from "../images/city_fact_explorer_toggle_filters.png";
import cityDataView2Img from "../images/city_fact_explorer_filter.png";
import cityDataView3Img from "../images/city_fact_explorer_crime.png";
import cityDataView4Img from "../images/city_fact_explorer_skip_proceed.png";
import askedQuestions1Img from "../images/asked_questions1.png";
import askedQuestions2Img from "../images/asked_questions2.png";
import askedQuestions3Img from "../images/asked_questions3.png";
import askedQuestions4Img from "../images/asked_questions4.png";
import askedQuestions5Img from "../images/asked_questions5.png";
import askedQuestions6Img from "../images/asked_questions6.png";
import timelineView1Img from "../images/timeline_view1.png";
import timelineView2Img from "../images/timeline_view2.png";
import studyImageImg from "../images/study_vec.png";
import policyIconImg from "../images/policy_icon.png";
import eventIconImg from "../images/event_icon.png";
import comingSoonImg from "../images/coming_soon.png";
import residencyImageImg from "../images/PR_vec.png";
import costoflivingiconImg from "../images/costoflivingicon.png";
import tourismImageImg from "../images/tourism_vec.png";
import userdeleteImg from "../images/rubbish-bin.png";
import attachmentImg from "../images/attachment.png";
import tododeleteImg from "../images/tododelete.png";
import changepasswordImg from "../images/graphical-password-phone-tool.png";
import logoutImg from "../images/logout.png";
import empty_imageImg from "../images/user_icon_new.png";
import placeHolderImageImg from "../images/empty-image.png";
import supportImg from "../images/support.png";
import contactUs1Img from "../images/contact_us1.png";
import notificationsMainImg from "../images/noticon2.png";
import notificationsMainNewImg from "../images/noticon.png";
import informationIconImg from "../images/informationIcon.png";
import tickImg from "../images/stepImages/tick.png";
import whitetickImg from "../images/whiteTick.png";
import sideBarOpenImg from "../images/sideBarOpen.png";
import nextImg from "../images/next.png";
import whatWeAsked from "../images/whatWeAsked.png";
import pathDetailImg1 from "../images/pathDetailImg1.png";
import pathDetailImg2 from "../images/pathDetailImg2.png";
import pathDetailImg3 from "../images/pathDetailImg3.png";

import bulletImg from "../images/stepImages/1.png";
import leftArrowImg from "../images/left-arrow2.png";
import backArrowImg from "../images/stepImages/cartBack.png";
import phoneImg from "../images/phoneImg.png";
import eyeImg from "../images/stepImages/Group.png";
import qMarkImg from "../images/stepImages/QMark.png";
import redCloseImg from "../images/stepImages/redClose.png";
import greenTickCircleImg from "../images/stepImages/greenTickCircle.png";
import pathExplorerImg from "../images/stepImages/pathExplorer.png";
import rightArrowImg from "../images/stepImages/right.png";
import downArrowImg from "../images/stepImages/download-arrow.png";
import caretDownImg from "../images/stepImages/caret-down.png";
import deleteButtonImg from "../images/stepImages/delete-button.png";
import helpLogoImg from "../images/stepImages/helpLogo.png";
import InfoSymImg from "../images/stepImages/InfoIcon.png";
import currencyConvertImg from "../images/stepImages/currencyConvert.png";
import dummyImgImg from "../images/p3.png";
import dottedrect from "../images/dottedrect.png";
import checkImg from "../images/check.png";
import immibuckImg from "../images/immibuck2.png";
import ImmibuckssmallImg from "../images/Immibuckssmall.png";
import immiBackgroundImg from "../images/immiBackground.png";
import ImibucksScreenImg from "../images/ImibucksScreen.jpg";
import Imibucks_5000Img from "../images/Imibucks_5000.png";
import Imibucks_1kImg from "../images/Imibucks_1k.png";
import Imibucks_20kImg from "../images/Imibucks_20k.png";
import Imibucks_25Img from "../images/Imibucks_25.png";
import Imibucks1Img from "../images/Imibucks1.png";
import chatProfileImg from "../images/p1.jpg";
import settingsiconImg from "../images/settingsicon.png";
import warningImgImg from "../images/warningImg.png";
import thinkImg from "../images/think.png";
import emojipickerImg from "../images/emojipicker.png";
import burgerMenuImg from "../images/burgerMenu.png";
import aboutimg3Img from "../images/aboutimg3.png";
import aboutImg2Img from "../images/aboutImg2.png";
import aboutImg1Img from "../images/aboutImg1.png";
import aboutusInputiconImg from "../images/aboutusInputicon.png";
import fbImg from "../images/fb.png";
import instaImg from "../images/insta.png";
import PlaystoreImg from "../images/Playstore.png";
import appstoreImg from "../images/appstore.png";
import googlePlayStoreImg from "../images/Google Play Store.png";
import appleAppStoreImg from "../images/Apple App Store.png";
import creditBuilderIconImg from "../images/creditBuilderIcon.png";
import loginScreenImg from "../images/loginScreenImg.png";
import loginImg3Img from "../images/loginImg3.png";
import dashboardimg3Img from "../images/dashboardimg3.png";
import defaultThumImg from "../images/defaultThum.jpeg";
import japanHighlightedImg from "../images/japanHighlighted.png";
import germanyHighlightedImg from "../images/germanyHighlighted.png";
import uaeHighlightedImg from "../images/uaeHighlighted.png";
import ukHighlightedImg from "../images/ukHighlighted.png";
import singaporeHighlightedImg from "../images/singaporeHighlighted.png";
import netherlandHighlightedImg from "../images/netherlandHighlighted.png";
import ausHighlightedImg from "../images/ausHighlighted.png";
import canadaHighlightedImg from "../images/canadaHighlighted.png";
import usaHighlightedImg from "../images/usaHighlighted.png";
import nzHighlightedImg from "../images/nzHighlighted.png";
import nz2Img from "../images/nz2.png";
import netherlandsFlagImg from "../images/netherlands_selected.png";
import germanyFlagImg from "../images/germany_selected.png";
import netherlandsUnavilableFlagImg from "../images/netherlands_unavailable.png";
import germanyUnavailableFlagImg from "../images/germany_unavailable.png";
import germany_unselectedImg from "../images/germany_unselected.png";
import netherlands_unselectedImg from "../images/netherlands_unselected.png";
import dashboardImg2Img from "../images/dashboardImg2.png";
import usFlagImg from "../images/usFlag.png";
import backIconImg from "../images/stepImages/back.png";
import usFlag2Img from "../images/usFlag2.png";
import pencilImg from "../images/pencil.png";
import usFlagreducedImg from "../images/usFlagreduced.png";
import visaIconPlaceholderImg from "../images/stepImages/VisaIconPlaceholder.png";
import saveImmipathSearch1Img from "../images/save_immipath_search1.png";
import saveImmipathSearch2Img from "../images/save_immipath_search2.png";
import saveImmipathSearch3Img from "../images/save_immipath_search3.png";
import dropDownArrowImg from "../images/downArrow.png";
import cancelImg from "../images/close.png";
import promoSuccessTickImg from "../images/promoSuccessTick.png";
import mapsmarkerImg from "../images/mapsmarker.png";
import helpImg from "../images/help.png";
import redheartImg from "../images/redheart.png";
import sendImg from "../images/send.png";
import topImg from "../images/top.png";
import mobilephoneImg from "../images/mobilephone.png";
import eventImg from "../images/event.png";
import updatedImg from "../images/updated.png";
import infoImg from "../images/info.png";
import updatenotifyImg from "../images/updatenotify.png";
import eventnotifyImg from "../images/eventnotify.png";
import policynotifyImg from "../images/policynotify.png";
import basicImg from "../images/basic.png";
import drop_down_blueImg from "../images/drop_down_blue.png";
import contact_usImg from "../images/contact_us.png";
import donetickImg from "../images/tick.png";
import appUpdateImg from "../images/appUpdate.png";
import profileImgEditImg from "../images/profileImgEdit.png";
import profileEditImg from "../images/hamburgermenu.png";
import profileEdit2Img from "../images/profileEdit.png";
import notifyImg from "../images/notify.png";
import closeBlackImg from "../images/cross.png";
import notifyBellImg from "../images/notifyBell.png";
import notificationBellImg from "../images/notificationBell.png";
import immilockImg from "../images/immilock.png";
import cityfactsLogoImg from "../images/cityfactsLogo.png";
import jobforyouIconImg from "../images/jobforyouIcon.png";
import immipatSearchImg from "../images/immipatSearch.png";
import quickSearchIconImg from "../images/quickSearchIcon1.png";
import builderImgImg from "../images/builderImg.png";
import blackSocialLinkIconImg from "../images/blackSocialLinkIcon.png";
import footerIconImg from "../images/footerIcon.png";
import loginImg2Img from "../images/loginImg2.png";
import no_notifyImg from "../images/no_notify.png";
import logout1Img from "../images/logout1.png";
import cancelGreyImg from "../images/cancelgrey.png";
import moreImg from "../images/more.png";
import leftarrow_blueImg from "../images/left-arrow_blue.png";
import storeImg from "../images/store.png";
import triangleDownImg from "../images/triangleDown.png";
import whiteCloseImg from "../images/whiteClose.png";
import lokiImg from "../images/loki.jpeg";
import redcancelImg from "../images/redcancel.png";
import dummyproImg from "../images/dummyPro.jpg";
import cartBackImg from "../images/cartBack.png";
import cartWhiteArrowImg from "../images/cartWhiteArrow.png";
import shopping_cartImg from "../images/shopping-cart.png";
import greencheckedImg from "../images/greenchecked.png";
import jobIconImg from "../images/jobIcon.png";
import calculatorImg from "../images/calculator.png";
import padlockImg from "../images/padlock.png";
import menuLockIconImg from "../images/menuLockIcon.png";
import loginImgImg from "../images/loginImg.png";
import replaySendImg from "../images/replySend.png";
import loginImmiIconImg from "../images/loginImmiIcon.png";
import userCartIconImg from "../images/userCartIcon.png";
import notificationIconModalImg from "../images/notificationIconModal.png";
import notificationIconModal_forwardImg from "../images/notificationIconModal_forward.png";
import headerNotifyImg from "../images/headerNotify.png";
import whiteBackImg from "../images/whiteBack.png";
import toolbarLogoImg from "../images/new_logo_toolbar.png";
import profilePicImg from "../images/icon_new_cool.png";
import bottomHomeIconImg from "../images/bottomHomeIcon.png";
import bottomMediaIconImg from "../images/bottomMediaIcon.png";
import bottomPathExpIconImg from "../images/bottomPathExpIcon.png";
import bottomAdviceIconImg from "../images/bottomAdviceIcon.png";
import bottomStoreIconImg from "../images/bottomStoreIcon.png";
import tabHomeIconImg from "../images/tabicons/home.png";
import tabMediaIconImg from "../images/tabicons/media.png";
import tabPathExpIconImg from "../images/tabicons/pathExp.png";
import tabAdviceIconImg from "../images/tabicons/advice.png";
import tabStoreIconImg from "../images/tabicons/store.png";
import highlightedNZImg from "../images/highlightedNZ.png";
import uploadPicImgImg from "../images/uploadPicImg.png";
import blackbackIconImg from "../images/blackbackIcon.png";
import pushpinImg from "../images/push-pin.png";
import Vector2Img from "../images/Vector2.png";
import AusflagImg from "../images/Ausflag.png";
import netherlanFlagImg from "../images/netherlanFlag.png";
import singaporeFlagImg from "../images/singaporeFlag.png";
import NZFlagImg from "../images/NZFlag.png";
import JapanFlagImg from "../images/JapanFlag.png";
import prcalImg from "../images/prcalImg.png";
import redeemIconImg from "../images/redeemIcon.png";
import redeemIconWhiteImg from "../images/redeem_white.png";
import whiteHeartImg from "../images/whiteHeart.png";
import NZFlag3Img from "../images/NZflag3.png";
import USAFlagImg from "../images/USAFlag.png";
import CANADAFlagImg from "../images/CANADAFlag.png";
import resourceIconImg from "../images/resourceIcon.png";
import info1Img from "../images/info1.png";
import copyImg from "../images/copy.png";
import uploadIconImg from "../images/uploadIcon.png";
import advicePackage1Img from "../images/advicePackage1.png";
import disketteImg from "../images/diskette.png";
import xlsImg from "../images/xls.png";
import pdfImg from "../images/download-pdf.png";
import txtImg from "../images/txt.png";
import defaultfileiconImg from "../images/defaultfileicon.png";
import UKFLagImg from "../images/UKFLag.png";
import UAEFLAGImg from "../images/UAEFLAG.png";
import GermanyFlagImg from "../images/GermanyFlag.png";
import playButtonImg from "../images/play-button.png";
import updateImg from "../images/update.png";
import eventsImg from "../images/eventicon.png";
import privacyImg from "../images/privacy_policy.png";
import USAImg from "../images/united-states.png";
import UKImg from "../images/united-kingdom.png";
import UAEImg from "../images/uae-flag.png";
import AUImg from "../images/australia.png";
import CANADAImg from "../images/canada.png";
import SINGAPOREImg from "../images/singapore.png";
import NZImg from "../images//NZcircle.png";
import cartTickIconImg from "../images/cartTickIcon.png";
import JAPANImg from "../images/japanCircle.png";
import NETHERLANDImg from "../images/netherlandCircle.png";
import GERMANYImg from "../images/germanyCircle.png";
import dislikeComentImg from "../images/dislikeComent.png";
import likeCommentImg from "../images/likeComment.png";
import grayQuestionMarkImg from "../images/question_mark.png";
import greenTickMarkImg from "../images/green_tick.png";
import darkBlueTickImg from "../images/darkBlueTick.png";
import redRejectedMarkImg from "../images/red_rejected.jpg";
import whiteSearchIconImg from "../images/whiteSearchIcon.png";
import creditImg from "../images/credit.png";
import comparechartImg from "../images/comparechart.png";
import rocketsearchImg from "../images/rocketsearch.png";
import blackSearchIcnImg from "../images/blackSearchIcn.png";
import shareNewsImg from "../images/shareNews.png";
import LikeImg from "../images/Like.png";
import banner1Img from "../images/promotionImg/4.jpg";
import banner2Img from "../images/promotionImg/3.jpg";
import banner3Img from "../images/promotionImg/1.jpg";
import usa_unavailableImg from "../images/countries/usa_unavailable.png";
import usa_unselectedImg from "../images/countries/usa_unselected.png";
import usa_selectedImg from "../images/countries/usa_selected.png";
import uk_selectedImg from "../images/countries/uk_selected.png";
import uk_unavailableImg from "../images/countries/uk_unavailable.png";
import uk_unselectedImg from "../images/countries/uk_unselected.png";
import uae_selectedImg from "../images/countries/uae_selected.png";
import uae_unavailableImg from "../images/countries/uae_unavailable.png";
import uae_unselectedImg from "../images/countries/uae_unselected.png";
import australia_selectedImg from "../images/countries/australia_selected.png";
import australia_unavailableImg from "../images/countries/australia_unavailable.png";
import australia_unselectedImg from "../images/countries/australia_unselected.png";
import canada_selectedImg from "../images/countries/canada_selected.png";
import canada_unavailableImg from "../images/countries/canada_unavailable.png";
import canada_unselectedImg from "../images/countries/canada_unselected.png";
import singapore_selectedImg from "../images/countries/singapore_selected.png";
import singapore_unavailableImg from "../images/countries/singapore_unavailable.png";
import singapore_unselectedImg from "../images/countries/singapore_unselected.png";
import icon_newZealandImg from "../images/interestedCountries/9-2.png";
import icon_ukImg from "../images/interestedCountries/5.png";
import icon_singaporeImg from "../images/interestedCountries/4.png";
import icon_uaeImg from "../images/interestedCountries/6.png";
import icon_germanyImg from "../images/interestedCountries/7.png";
import icon_usaImg from "../images/interestedCountries/1.png";
import icon_canadaImg from "../images/interestedCountries/2.png";
import icon_netherlandsImg from "../images/interestedCountries/8.png";
import icon_australiaImg from "../images/interestedCountries/3.png";
import icon_japanImg from "../images/interestedCountries/10.png";
import defaultBullitinLogoImg from "../images/filterModal/defaultLogo.png";
import reactionBullitinLogoImg from "../images/filterModal/reactionLogo.png";
import WelcomeImg from "../images/Intro/welcome_image.png";
import MapImg from "../images/Intro/map_image.png";
import JourneyImg from "../images/Intro/journey.png";
import profileCompleteBarImg from "../images/profileCompleteBar.png";
import sliderRightImg from "../images/sliderRight.png";
import sliderLeftImg from "../images/sliderLeft.png";
import commenticonImg from "../images/commenticon.png";
import profileInfoCompletecheckImg from "../images/profileInfoCompletecheck.png";
import emptyCircleImg from "../images/emptyCircle.png";
import tickShapeImg from "../images/tickShape.png";
import redEditIconImg from "../images/redEditIcon.png";
import threatIconImg from "../images/threatIcon.png";
import oppiconImg from "../images/oppicon.png";
import starImg from "../images/star.png";
import whatWeAskedImg from "../images/whatWeAsked.png";
import jobImg from "../images/job.png";

const Images = {
  starIcon: starImg,
  jobImg: jobImg,
  whatWeAsked: whatWeAskedImg,
  likeIcon: likeIconImg,
  dislikeIcon: dislikeIconImg,
  emptyDiscussion: emptyDiscussionImg,
  comments: commentsImg,
  moreDiscussionIcon: moreDiscussionIconImg,
  swipeWhite: swipeWhiteImg,
  slideWhite: slideWhiteImg,
  copy: copyImg,
  pi1: pi1Img,
  pi2: pi2Img,
  pi3: pi3Img,
  pi4: pi4Img,
  pi5: pi5Img,
  indexLow: indexLowImg,
  indexMed: indexMedImg,
  indexHeigh: indexHeighImg,
  swipeWhite: swipeWhiteImg,
  slideWhite: slideWhiteImg,
  swallow: swallowImg,
  kea: keaImg,
  tern: ternImg,
  macaw: macawImg,
  interestIcon: interestIconImg,
  collapseIcon: collapseIconImg,
  editIcon: editIconImg,
  expandIcon: expandIconImg,
  occupationIcon: occupationIconImg,
  languagesIcon: languagesIconImg,
  lifestylesIcon: lifestylesIconImg,
  bellRing: bellRingImg,
  loginbg: loginbgImg,
  nounAddCalendar: nounAddCalendarImg,
  nextArrowGray: nextArrowGrayImg,
  loudSpeaker: loudSpeakerImg,
  languageFeatureIcon: languageFeatureIconImg,
  studyingWithBook: studyingWithBookImg,
  houseImmigreatSearch: houseImmigreatSearchImg,
  travellingAroundTheWorld: travellingAroundTheWorldImg,
  workAtHome: workAtHomeImg,
  univExpandIcon: univExpandIconImg,
  univAddMoreIcon: univAddMoreIconImg,
  immipathSearchChecked: immipathSearchCheckedImg,
  immipathSearchUnchecked: immipathSearchUncheckedImg,
  univPremiumModalImage: univPremiumModalImageImg,
  chancesInfoIcon: chancesInfoIconImg,
  infoIcon2: infoIcon2,
  bookmarkIcon: bookmarkIconImg,
  polyChatA: polyChatAImg,
  polyChatB: polyChatBImg,
  languageBanner: languageBannerImg,
  Intro: {
    Welcome: WelcomeImg,
    Map: MapImg,
    Journey: JourneyImg,
  },
  profileInterest: profileInterestImg,
  profileInterest2: profileInterest2Img,
  profileLang: profileLangImg,
  profileOccupation: profileOccupationImg,
  loginImage: loginImageImg,
  loginImage2: loginImage2Img,
  infoIcon: infoIconImg,
  homeIcon: homeIconImg,
  rocketIcon: rocketIconImg,
  mailIcon: mailIconImg,
  keyIcon: keyIconImg,
  facebookIcon: facebookIconImg,
  googleIcon: googleIconImg,
  appleIcon: appleIconImg,
  eyeIcon: eyeIconImg,
  unViewIcon: unViewIconImg,
  splashScreen: splashScreenImg,
  forgetPassword: forgetPasswordImg,
  pinValidate: pinValidateImg,
  newIconFacts: newIconFactsImg,
  defaultimage: defaultimageImg,
  dashboardImg: dashboardImgImg,
  newFilterIcon: newFilterIconImg,
  passwordlock: passwordlockImg,
  resetPassword: resetPasswordImg,
  creatPassword: creatPasswordImg,
  Destination: DestinationImg,
  aboutUs: aboutUsImg,
  japan_unavailable: japan_unavailableImg,
  japan_unselected: japan_unselectedImg,
  japan_selected: japan_selectedImg,
  new_zealand_unavailable: new_zealand_unavailableImg,
  new_zealand_selected: new_zealand_selectedImg,
  new_zealand_unselected: new_zealand_unselectedImg,
  launch_screen: launch_screenImg,
  test1: test1Img,
  test2: test2Img,
  userIcon: userIconImg,
  dashboardBg: dashboardBgImg,
  sunsetMain: sunsetMainImg,
  dashboardExplorer: dashboardExplorerImg,
  dashboardBottomImage: dashboardBottomImageImg,
  newsMain: newsMainImg,
  newsMainNotify: newsMainNotifyImg,
  newsStories1: newsStories1Img,
  newsStories2: newsStories2Img,
  newsStories3: newsStories3Img,
  newsStories4: newsStories4Img,
  newsStories5: newsStories5Img,
  newsStories6: newsStories6Img,
  updateMain: updateMainImg,
  updateMainNotify: updateMainNotifyImg,
  applicationsMain: applicationsMainImg,
  applicationsMainNotify: applicationsMainNotifyImg,
  workImage: workImageImg,
  dashboardHelp1: dashboardHelp1Img,
  dashboardHelp2: dashboardHelp2Img,
  dashboardHelp3: dashboardHelp3Img,
  dashboardHelp4: dashboardHelp4Img,
  dashboardHelp5: dashboardHelp5Img,
  dashboardHelp6: dashboardHelp6Img,
  dashboardHelp7: dashboardHelp7Img,
  dashboardHelp8: dashboardHelp8Img,
  dashboardHelp9: dashboardHelp9Img,
  pathExplorerEligb1: pathExplorerEligb1Img,
  pathExplorerStart1: pathExplorerStart1Img,
  pathExplorerStart2: pathExplorerStart2Img,
  pathExplorerBasic1: pathExplorerBasic1Img,
  pathExplorerBasic2: pathExplorerBasic2Img,
  pathExplorerBasic3: pathExplorerBasic3Img,
  factSelection1: factSelection1Img,
  factSelection2: factSelection2Img,
  factSelection3: factSelection3Img,
  adviceChannel1: adviceChannel1Img,
  adviceChannel2: adviceChannel2Img,
  adviceChannel3: adviceChannel3Img,
  cityDataView1: cityDataView1Img,
  cityDataView2: cityDataView2Img,
  cityDataView3: cityDataView3Img,
  cityDataView4: cityDataView4Img,
  askedQuestions1: askedQuestions1Img,
  askedQuestions2: askedQuestions2Img,
  askedQuestions3: askedQuestions3Img,
  askedQuestions4: askedQuestions4Img,
  askedQuestions5: askedQuestions5Img,
  askedQuestions6: askedQuestions6Img,
  timelineView1: timelineView1Img,
  timelineView2: timelineView2Img,
  studyImage: studyImageImg,
  policyIcon: policyIconImg,
  eventIcon: eventIconImg,
  comingSoon: comingSoonImg,
  residencyImage: residencyImageImg,
  costoflivingicon: costoflivingiconImg,
  tourismImage: tourismImageImg,
  userdelete: userdeleteImg,
  attachment: attachmentImg,
  tododelete: tododeleteImg,
  changepassword: changepasswordImg,
  logout: logoutImg,
  empty_image: empty_imageImg,
  placeHolderImage: placeHolderImageImg,
  toolbarLogo: toolbarLogoImg,
  support: supportImg,
  contactUs1: contactUs1Img,
  notificationsMain: notificationsMainImg,
  notificationsMainNew: notificationsMainNewImg,
  informationIcon: informationIconImg,
  tick: tickImg,
  whitetick: whitetickImg,
  sideBarOpen: sideBarOpenImg,
  next: nextImg,
  bullet: bulletImg,
  pathDetailImg1: pathDetailImg1,
  pathDetailImg2: pathDetailImg2,
  pathDetailImg3: pathDetailImg3,
  leftArrow: leftArrowImg,
  backArrow: backArrowImg,
  phone: phoneImg,
  eye: eyeImg,
  qMark: qMarkImg,
  redClose: redCloseImg,
  greenTickCircle: greenTickCircleImg,
  pathExplorer: pathExplorerImg,
  rightArrow: rightArrowImg,
  downArrow: downArrowImg,
  caretDown: caretDownImg,
  deleteButton: deleteButtonImg,
  helpLogo: helpLogoImg,
  InfoSym: InfoSymImg,
  currencyConvert: currencyConvertImg,
  dummyImg: dummyImgImg,
  whatWeAsked: whatWeAsked,
  check: checkImg,
  immibuck: immibuckImg,
  Immibuckssmall: ImmibuckssmallImg,
  immiBackground: immiBackgroundImg,
  ImibucksScreen: ImibucksScreenImg,
  Imibucks_5000: Imibucks_5000Img,
  Imibucks_1k: Imibucks_1kImg,
  Imibucks_20k: Imibucks_20kImg,
  Imibucks_25: Imibucks_25Img,
  Imibucks1: Imibucks1Img,
  chatProfile: chatProfileImg,
  settingsicon: settingsiconImg,
  warningImg: warningImgImg,
  think: thinkImg,
  emojipicker: emojipickerImg,
  whiteBack: whiteBackImg,
  burgerMenu: burgerMenuImg,
  aboutimg3: aboutimg3Img,
  aboutImg2: aboutImg2Img,
  aboutImg1: aboutImg1Img,
  aboutusInputicon: aboutusInputiconImg,
  fb: fbImg,
  insta: instaImg,
  Playstore: PlaystoreImg,
  appstore: appstoreImg,
  googlePlayStore: googlePlayStoreImg,
  appleAppStore: appleAppStoreImg,
  creditBuilderIcon: creditBuilderIconImg,
  loginScreenImg: loginScreenImg,
  loginImg3: loginImg3Img,
  dashboardimg3: dashboardimg3Img,
  defaultThum: defaultThumImg,
  japanHighlighted: japanHighlightedImg,
  germanyHighlighted: germanyHighlightedImg,
  uaeHighlighted: uaeHighlightedImg,
  ukHighlighted: ukHighlightedImg,
  singaporeHighlighted: singaporeHighlightedImg,
  netherlandHighlighted: netherlandHighlightedImg,
  ausHighlighted: ausHighlightedImg,
  canadaHighlighted: canadaHighlightedImg,
  usaHighlighted: usaHighlightedImg,
  nzHighlighted: nzHighlightedImg,
  nz2: nz2Img,
  netherlandsFlag: netherlandsFlagImg,
  germanyFlag: germanyFlagImg,
  netherlandsUnavilableFlag: netherlandsUnavilableFlagImg,
  germanyUnavailableFlag: germanyUnavailableFlagImg,
  germany_unselected: germany_unselectedImg,
  netherlands_unselected: netherlands_unselectedImg,
  dashboardImg2: dashboardImg2Img,
  usFlag: usFlagImg,
  backIcon: backIconImg,
  usFlag2: usFlag2Img,
  pencil: pencilImg,
  usFlagreduced: usFlagreducedImg,
  profilePic: profilePicImg,
  visaIconPlaceholder: visaIconPlaceholderImg,
  saveImmipathSearch1: saveImmipathSearch1Img,
  saveImmipathSearch2: saveImmipathSearch2Img,
  saveImmipathSearch3: saveImmipathSearch3Img,
  dropDownArrow: dropDownArrowImg,
  cancel: cancelImg,
  promoSuccessTick: promoSuccessTickImg,
  mapsmarker: mapsmarkerImg,
  help: helpImg,
  redheart: redheartImg,
  send: sendImg,
  info1: info1Img,
  top: topImg,
  mobilephone: mobilephoneImg,
  event: eventImg,
  updated: updatedImg,
  info: infoImg,
  updatenotify: updatenotifyImg,
  eventnotify: eventnotifyImg,
  policynotify: policynotifyImg,
  basic: basicImg,
  drop_down_blue: drop_down_blueImg,
  contact_us: contact_usImg,
  dottedrect: dottedrect,
  donetick: donetickImg,
  appUpdate: appUpdateImg,
  profileImgEdit: profileImgEditImg,
  profileEdit: profileEditImg,
  profileEdit2: profileEdit2Img,
  notify: notifyImg,
  closeBlack: closeBlackImg,
  notifyBell: notifyBellImg,
  notificationBell: notificationBellImg,
  immilock: immilockImg,
  cityfactsLogo: cityfactsLogoImg,
  jobforyouIcon: jobforyouIconImg,
  immipatSearch: immipatSearchImg,
  quickSearchIcon: quickSearchIconImg,
  prcalImg: prcalImg,
  builderImg: builderImgImg,
  blackSocialLinkIcon: blackSocialLinkIconImg,
  footerIcon: footerIconImg,
  loginImg2: loginImg2Img,
  no_notify: no_notifyImg,
  logout1: logout1Img,
  cancelGrey: cancelGreyImg,
  more: moreImg,
  leftarrow_blue: leftarrow_blueImg,
  store: storeImg,
  triangleDown: triangleDownImg,
  whiteClose: whiteCloseImg,
  loki: lokiImg,
  redcancel: redcancelImg,
  dummypro: dummyproImg,
  cartBack: cartBackImg,
  cartWhiteArrow: cartWhiteArrowImg,
  shopping_cart: shopping_cartImg,
  greenchecked: greencheckedImg,
  jobIcon: jobIconImg,
  calculator: calculatorImg,
  padlock: padlockImg,
  menuLockIcon: menuLockIconImg,
  loginImg: loginImgImg,
  replaySend: replaySendImg,
  loginImmiIcon: loginImmiIconImg,
  userCartIcon: userCartIconImg,
  notificationIconModal: notificationIconModalImg,
  notificationIconModal_forward: notificationIconModal_forwardImg,
  bottomHomeIcon: bottomHomeIconImg,
  bottomMediaIcon: bottomMediaIconImg,
  bottomPathExpIcon: bottomPathExpIconImg,
  bottomAdviceIcon: bottomAdviceIconImg,
  bottomStoreIcon: bottomStoreIconImg,
  tabHomeIcon: tabHomeIconImg,
  tabMediaIcon: tabMediaIconImg,
  tabPathExpIcon: tabPathExpIconImg,
  tabAdviceIcon: tabAdviceIconImg,
  tabStoreIcon: tabStoreIconImg,
  highlightedNZ: highlightedNZImg,
  uploadPicImg: uploadPicImgImg,
  blackbackIcon: blackbackIconImg,
  pushpin: pushpinImg,
  Vector2: Vector2Img,
  headerNotify: headerNotifyImg,
  advicePackage1: advicePackage1Img,
  uploadIcon: uploadIconImg,
  Ausflag: AusflagImg,
  netherlanFlag: netherlanFlagImg,
  singaporeFlag: singaporeFlagImg,
  NZFlag: NZFlagImg,
  NZFlag3: NZFlag3Img,
  USAFlag: USAFlagImg,
  CANADAFlag: CANADAFlagImg,
  resourceIcon: resourceIconImg,
  diskette: disketteImg,
  xls: xlsImg,
  pdf: pdfImg,
  txt: txtImg,
  defaultfileicon: defaultfileiconImg,
  UKFLag: UKFLagImg,
  UAEFLAG: UAEFLAGImg,
  GermanyFlag: GermanyFlagImg,
  JapanFlag: JapanFlagImg,
  playButton: playButtonImg,
  update: updateImg,
  events: eventsImg,
  privacy: privacyImg,

  //Country flags
  USA: USAImg,
  UK: UKImg,
  UAE: UAEImg,
  AU: AUImg,
  profileCompleteBar: profileCompleteBarImg,
  sliderRight: sliderRightImg,
  sliderLeft: sliderLeftImg,
  commenticon: commenticonImg,
  profileInfoCompletecheck: profileInfoCompletecheckImg,
  emptyCircle: emptyCircleImg,
  tickShape: tickShapeImg,
  CANADA: CANADAImg,
  redEditIcon: redEditIconImg,
  SINGAPORE: SINGAPOREImg,
  NZ: NZImg,
  threatIcon: threatIconImg,
  oppicon: oppiconImg,
  USA: USAImg,
  UK: UKImg,
  UAE: UAEImg,
  AU: AUImg,
  CANADA: CANADAImg,
  SINGAPORE: SINGAPOREImg,
  NZ: NZImg,
  redeemIcon: redeemIconImg,
  redeemIconWhite: redeemIconWhiteImg,
  whiteHeart: whiteHeartImg,
  cartTickIcon: cartTickIconImg,
  JAPAN: JAPANImg,
  NETHERLAND: NETHERLANDImg,
  GERMANY: GERMANYImg,
  dislikeComent: dislikeComentImg,
  likeComment: likeCommentImg,
  grayQuestionMark: grayQuestionMarkImg,
  greenTickMark: greenTickMarkImg,
  darkBlueTick: darkBlueTickImg,
  redRejectedMark: redRejectedMarkImg,
  whiteSearchIcon: whiteSearchIconImg,
  credit: creditImg,
  comparechart: comparechartImg,
  rocketsearch: rocketsearchImg,
  blackSearchIcn: blackSearchIcnImg,
  shareNews: shareNewsImg,
  Like: LikeImg,
  banner1: banner1Img,
  banner2: banner2Img,
  banner3: banner3Img,
  usa_unavailable: usa_unavailableImg,
  usa_unselected: usa_unselectedImg,
  usa_selected: usa_selectedImg,
  uk_selected: uk_selectedImg,
  uk_unavailable: uk_unavailableImg,
  uk_unselected: uk_unselectedImg,
  uae_selected: uae_selectedImg,
  uae_unavailable: uae_unavailableImg,
  uae_unselected: uae_unselectedImg,
  australia_selected: australia_selectedImg,
  australia_unavailable: australia_unavailableImg,
  australia_unselected: australia_unselectedImg,
  canada_selected: canada_selectedImg,
  canada_unavailable: canada_unavailableImg,
  canada_unselected: canada_unselectedImg,
  singapore_selected: singapore_selectedImg,
  singapore_unavailable: singapore_unavailableImg,
  singapore_unselected: singapore_unselectedImg,
  icon_newZealand: icon_newZealandImg,
  icon_uk: icon_ukImg,
  icon_singapore: icon_singaporeImg,
  icon_uae: icon_uaeImg,
  icon_germany: icon_germanyImg,
  icon_usa: icon_usaImg,
  icon_canada: icon_canadaImg,
  icon_netherlands: icon_netherlandsImg,
  icon_australia: icon_australiaImg,
  icon_japan: icon_japanImg,
  defaultBullitinLogo: defaultBullitinLogoImg,
  reactionBullitinLogo: reactionBullitinLogoImg
}

export default Images;
