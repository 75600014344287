import React, { useState } from "react";
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    TextInput,
} from "react-native";
import { Images } from "../../common";
import { TrailFromRight } from "./Password.animation";
import styles from "./Password.styles"
import { sha256 } from 'react-native-sha256';

const CreatePassword = ({ onSubmit = () => { }, reset = false }) => {
    const [securePass, setSecurePass] = useState(true);
    const [newpassword, setNewpassword] = useState("");
    const [confirmpassword, setConfirmpassword] = useState("");
    const [secureConfirmPass, setSecureConfirmPass] = useState(true);
    const [error, setError] = useState(null);

    const handleSubmit = async () => {
        var reg = /^(?=.*\d)(?=.*[A-Z])[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{8,}$/;
        if (!newpassword || newpassword.trim() == "") {
            setError('Password is required');
        } else if (!reg.test(newpassword)) {
            setError('Atleast 8 characters, with at least 1 number and 1 capital letter');
        } else if (!confirmpassword || confirmpassword.trim() == "") {
            setError('Confirm password is required');
        } else if (confirmpassword !== newpassword) {
            setError('Password mismatch');
        } else {
            let shapassword = await sha256(newpassword)
            onSubmit({
                newpassword,
                confirmpassword,
                shapassword
            })
        }
    }

    return (
        <View style={[styles.slide1]}>
            <TrailFromRight>
                <View style={{ alignItems: "center", width: "100%" }}>
                    <View style={styles.subview}>
                        <Text style={styles.subtext}>
                            {!reset ?
                                `Enter your password below. \nYour password must contain at least 8 characters with 1 number and 1 capital letter.`
                                : `Enter your new password below, We're just being extra safe`}
                        </Text>
                    </View>
                    <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
                        <Image style={styles.leftIcon} source={Images.passwordlock} />
                        <TextInput
                            onChangeText={(text) => {
                                setNewpassword(text.replace(/\s/g, ''))
                                setError(null)
                            }}
                            placeholder="New Password"
                            style={styles.textInput}
                            placeholderTextColor="gray"
                            value={newpassword}
                            secureTextEntry={securePass}
                        />
                        <TouchableOpacity
                            onPress={() => setSecurePass(!securePass)}
                        >
                            <Image
                                style={styles.rightIcon}
                                source={
                                    securePass
                                        ? Images.unViewIcon
                                        : Images.eyeIcon
                                }
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={[styles.inputBox, styles.inputBoxmarginTop]}>
                        <Image style={styles.leftIcon} source={Images.passwordlock} />
                        <TextInput
                            onChangeText={(text) => {
                                setConfirmpassword(text.replace(/\s/g, ''))
                                setError(null)
                            }}
                            placeholder="Confirm Password"
                            style={styles.textInput}
                            placeholderTextColor="gray"
                            value={confirmpassword}
                            secureTextEntry={secureConfirmPass}
                        />
                        <TouchableOpacity
                            onPress={() => setSecureConfirmPass(!secureConfirmPass)}
                        >
                            <Image
                                style={styles.rightIcon}
                                source={
                                    secureConfirmPass
                                        ? Images.unViewIcon
                                        : Images.eyeIcon
                                }
                            />
                        </TouchableOpacity>
                    </View>
                    {error ? <Text style={{ color: "red", fontSize: 16, textAlign: "center", width: "80%", marginTop: 5 }}>{error}</Text> : null}
                    <View style={[styles.buttonView, {}]}>
                        <TouchableOpacity
                            onPress={handleSubmit}
                            style={[styles.nextButton]}
                        >
                            <Text style={styles.nextText}>Done</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </TrailFromRight>
        </View>
    );
};

export default CreatePassword;
