const initialState = {
  initialLoadState: false,
  screenViewedCount: 0,
  pathExplorerDefaultView: true,
  continuePreviousSearch: false,
  occupationAndLanguageList: []
};

export const setInitialLoadTypes = {
  SET_INITIAL_LOAD: "SET_INITIAL_LOAD",
  SET_SCREEN_VIEWED_COUNT: "SET_SCREEN_VIEWED_COUNT",
  INCREMENT_SCREEN_VIEWED_COUNT: "INCREMENT_SCREEN_VIEWED_COUNT",
  CHANGE_PATHEXPLORER_VIEW: "CHANGE_PATHEXPLORER_VIEW",
  GET_OCCUPATION_LIST: 'GET_OCCUPATION_LIST'
};

const appStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case setInitialLoadTypes.SET_INITIAL_LOAD:
      return {
        ...state,
        initialLoadState: action.payload,
      };
    case setInitialLoadTypes.SET_SCREEN_VIEWED_COUNT:
      return {
        ...state,
        screenViewedCount: action.count,
      };
    case setInitialLoadTypes.INCREMENT_SCREEN_VIEWED_COUNT:
      return {
        ...state,
        screenViewedCount: state.screenViewedCount + 1,
      };
    case setInitialLoadTypes.CHANGE_PATHEXPLORER_VIEW:
      return {
        ...state,
        pathExplorerDefaultView: action.payload,
      };
    case 'CONTINUE_PREVIOUS_SEARCH':
      return {
        ...state,
        continuePreviousSearch: action.payload
      }
    case setInitialLoadTypes.GET_OCCUPATION_LIST:
      return {
        ...state,
        occupationAndLanguageList: action.payload
      }
    default:
      return state;
  }
};

export default appStateReducer;
