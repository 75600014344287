import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Modal,
  Platform,
  Linking,
} from "react-native";
import NavigationService from "../navigation/NavigationService";

import { family } from "../common";
import { withRouter } from "react-router";
import BlurBackground from "./BlurBackground";
import { mobilBrower } from "../common/helpers";

function ErrorModal({
  errorMessage,
  guest,
  closeModal,
  navigation,
  todo,
  item,
  store,
  history,
}) {
  const handleLogin = () => {
    closeModal();
    setTimeout(() => {
      if (store) {
        Platform.OS == "web"
          ? history.push("/login")
          : NavigationService.navigate("Login", { fromStore: true });
      } else {
        Platform.OS == "web"
          ? history.push("/login")
          : NavigationService.navigate("Login");
      }
    }, 500);
  };
  const openLink = () => {
    Linking.openURL(item.url);
    closeModal();
  };
  return (
    <Modal transparent>
      <BlurBackground />
      <View style={styles.mainView}>
        <View style={styles.innerView}>
          <Text
            style={[
              styles.errortext,
              { color: guest || store ? "#030135" : "red" },
            ]}
          >
            {errorMessage}
          </Text>
          {todo ? (
            <View style={styles.buttonContanier}>
              <TouchableOpacity
                onPress={() => openLink()}
                style={styles.signUp}
              >
                <Text style={styles.signUpText}>Open</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => closeModal()}
                style={[styles.signUp, { marginLeft: 10 }]}
              >
                <Text style={styles.signUpText}>Not now</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View />
          )}
          {guest ? (
            <View style={styles.buttonContanier}>
              <TouchableOpacity
                testID="login_btn_in_em"
                onPress={() => handleLogin()}
                style={styles.signUp}
              >
                <Text style={styles.signUpText}>Login</Text>
              </TouchableOpacity>
              <TouchableOpacity
                testID="not_now_btn_in_em"
                onPress={() => closeModal()}
                style={[styles.signUp, { marginLeft: 10 }]}
              >
                <Text style={styles.signUpText}>Not now</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View />
          )}
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  mainView: {
    height: "100%",
    width: "100%",
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  innerView: {
    backgroundColor: "#F1F1F1",
    borderRadius: 30,
    width: mobilBrower() ? "90%" : "40%",
    alignItems: "center",
    padding: 15,
    shadowColor: "white",
    shadowOffset: { width: 0, height: 0.5 },
    shadowOpacity: 0.4,
    shadowRadius: 5,
    elevation: 12,
    justifyContent: "center",
  },
  buttonContanier: {
    flexDirection: "row",
    alignItems: "center",
  },
  signUpText: {
    padding: 5,
    paddingLeft: 7,
    fontFamily: family.semibold,
    textAlign: "center",
    color: "#030135",
    paddingRight: 7,
  },
  signUp: {
    borderRadius: 10,
    borderColor: "#030135",
    borderWidth: 1,
    marginTop: 10,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.4,
    shadowRadius: 5,
  },

  errortext: {
    fontSize: 18,
    textAlign: "center",
    fontFamily: family.semibold,
    color: "red",
  },
  signUp: {
    borderRadius: 10,
    borderColor: "#030135",
    borderWidth: 1,
    marginTop: 10,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.4,
    shadowRadius: 5,
  },
  buttonContanier: {
    flexDirection: "row",
    alignItems: "center",
  },
  signUpText: {
    padding: 5,
    paddingLeft: 7,
    fontFamily: family.semibold,
    textAlign: "center",
    color: "#030135",
    paddingRight: 7,
  },
  cancelButton: {
    height: 10,
    width: 10,
  },
  cancelButtonStyle: {
    position: "absolute",
    right: 10,
    top: 5,
  },
});
export default Platform.OS == "web" ? withRouter(ErrorModal) : ErrorModal;
