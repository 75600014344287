import { StyleSheet } from "react-native";
import { mobilBrower } from '../../common/helpers';
import fontFamily from '../../common/fontFamily';
import { responsiveText } from '../../common/normalize';

export const styles = StyleSheet.create({
    newItem: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'center',
        marginTop: 30,
        marginBottom: 100,
        backgroundColor: '#F6F6F6',
        shadowColor: '#000',
        shadowOffset: { width: 0.5, height: 0.5 },
        shadowRadius: 1.5,
        shadowOpacity: 0.5,
        height: !mobilBrower() ? 298: 200,
    },
    newContent: {
        marginTop: 20,
        marginLeft: 20,
        paddingRight: 15,
        width: '50%',
    },
    newImgStyle: {
        height: '100%',
        width: '100%',
        resizeMode:!mobilBrower() ?"stretch":"cover"
    },
    title: {
        color: 'black',
        fontWeight: '700',
        fontSize: responsiveText(16),
        marginTop: mobilBrower()?5: 20,
        fontFamily: fontFamily.effra,
    },
    tagView: {
        borderRadius: 10,
        borderWidth: 1,
        marginBottom: 5,
        marginLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: '#000'
    },
    tagText: {
        paddingVertical: 5,
        color: 'rgba(0, 0, 0, 0.61)',
        paddingHorizontal: 8,
        fontFamily: fontFamily.effra,
        fontSize: responsiveText(12),
    },
    takeViewButton: {
        width: '70%',
        marginTop: 20,
        alignItems: 'center',
        backgroundColor: '#F1AB32',
        justifyContent: 'center',
        padding:8,
        marginBottom: 20,
    },
    takeViewText: {
        fontSize: responsiveText(13),
        fontFamily: fontFamily.effra,
        color: 'white',
        fontWeight:"700",
    },
    details: {
        color: 'black',
        fontSize: responsiveText(13),
        marginTop: 5,
        fontFamily: fontFamily.effra,
    },
    guestText: {
        color: 'black',
        fontSize: responsiveText(14),
        fontFamily: fontFamily.effra,
        fontWeight: "400",
        textAlign: 'center'
    },
    newsHighTitle: {
        color: 'black',
        alignSelf: 'center',
        marginTop: 35,
        fontSize: 35,
        fontWeight: '700',
        fontFamily: 'Effra',
    },
})