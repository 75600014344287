import React, { useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  Modal,
  TextInput,
} from "react-native";
import { styles } from "./newConversationModal.styles";
import Images from "../../common/images";
import { useSelector } from "react-redux";
import { Network } from "../../service/api";
import BlurBackground from "../BlurBackground";

export default function DiscountModal({
  onClose,
  getPromoCodeStatus,
  handleDiscount,
}) {
  const [promoCode, setpromoCode] = useState(null);
  const [discountChecked, setdiscountChecked] = useState(false);
  const [wrongPromoCode, setwrongPromoCode] = useState(false);
  const [promoApplied, setpromoApplied] = useState(null);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);
  const immiBuck_balance = useSelector(
    (state) => state.immibucksReducer.getImmibucksDetails.immibucks_balance
  );
  const isEligible = immiBuck_balance < 100 ? false : true;

  const applyPromoCode = async () => {
    const headers = {
      Accept: "application/json",
      Authorization: `${userDetails?.userid}@${token}`,
    };
    const payload = {
      promo_code: promoCode,
      platform: "advice_channel",
      user_id: userDetails?.userid,
    };
    let res = await Network(
      "/data/verify_promo_code",
      "POST",
      payload,
      headers
    );
    const { status, promo_dict } = res;
    if (status == "Success") {
      setpromoApplied(promo_dict);
      setdiscountChecked(false);
      setwrongPromoCode(false);
    } else {
      setpromoApplied(null);
      setwrongPromoCode(true);
    }
  };

  const handleApply = () => {
    handleDiscount(promoApplied, discountChecked);
    onClose();
  };

  return (
    <Modal transparent>
      <BlurBackground
        blurType="light"
      />
      <View style={styles.discount_mainView}>
        <View style={styles.discount_innerView}>
          <Text style={styles.discount_Title}>DISCOUNT</Text>
          <View
            style={[styles.middleView, { justifyContent: "space-between" }]}
          >
            <View style={styles.checkBoxView}>
              <TouchableOpacity
                disabled={!isEligible}
                onPress={() => setdiscountChecked(!discountChecked)}
                style={styles.checkBox}
              >
                {discountChecked && <View style={styles.checked} />}
              </TouchableOpacity>
              <Text style={styles.dicPercentage}>25%</Text>
            </View>
            <View
              style={[
                styles.notEligibleView,
                isEligible && styles.eligibleView,
              ]}
            >
              <Text style={styles.notEligibleText}>
                {!isEligible ? 'Not Applicable' : 'Applicable'}
              </Text>
            </View>
          </View>
          {!isEligible && (
            <View style={[styles.notView]}>
              <Text style={styles.note}>
                Earn more {`${100 - immiBuck_balance}`}
              </Text>
              <Image source={Images.immibuck} style={styles.immibuckIcon} />
              <Text style={styles.note}>to avail this discount</Text>
            </View>
          )}
          <View style={styles.orView}>
            <View style={styles.left} />
            <Text style={styles.orText}>OR</Text>
            <View style={styles.left} />
          </View>
          <View style={[styles.promoView]}>
            <View
              style={[
                styles.promoInputBox,
                discountChecked && styles.disabled,
              ]}
            >
              <TextInput
                style={styles.promoTextBox}
                editable={!discountChecked}
                onChangeText={(text) => setpromoCode(text)}
                placeholder="Enter promo code"
              />
            </View>
            {!promoApplied ? (
              <TouchableOpacity
                disabled={discountChecked || !promoCode}
                onPress={applyPromoCode}
                style={styles.okButton}
              >
                <Text style={styles.okText}>OK</Text>
              </TouchableOpacity>
            ) : (
              <Image
                source={Images.promoSuccessTick}
                style={styles.successTick}
              />
            )}
          </View>
          {wrongPromoCode && (
            <Text style={styles.note}>Promo code not valid</Text>
          )}
          <View
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <TouchableOpacity
              onPress={handleApply}
              style={[styles.button1, styles.buttonDe]}
            >
              <Text style={styles.applyButtonText}>Apply</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={onClose}
              style={[styles.cancelButton, styles.buttonDe]}
            >
              <Text style={styles.applyButtonText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {/* </BlurBackground> */}
    </Modal>
  );
}
