import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  FlatList,
  ScrollView,
  Alert,
  Dimensions,
} from "react-native";
import { normalize, colors, family } from "../../../../common";
import { Card } from "native-base";
import ButtonComponent from "../../../ButtonComponent";
import { styles } from "./cityFacts.styles";
import { Ranking } from "./Components/Ranking";
import { CityName } from "./Components/CityName";
import { CommonSection } from "./Components/CommonSection";
import { InfoSection } from "./Components/InfoSection";
import helpers from "../../../../common/helpers";
import { getWeatherTitle } from "../../../../utils/localityFactsDisplay.utils";
import { buisnessFriendlinessIndex, renderCrimeIndex, renderHealthCareIndex, renderPollutionIndex, renderTrafficIndex } from "../../../FactsTable";
import AlertModal from "../../../AlertModal/AlertModal";

const { height, width } = Dimensions.get("window");

const CityFactComparator = (props) => {
  const [showFilterModal, setFilterModal] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [alertText, setAlertText] = React.useState(null);

  const didTapOnDelete = (item, index) => {
    Alert.alert(
      "Immigreat",
      "Do you want to remove?",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
        {
          text: "OK",
          onPress: () => {
            const deletedData = props.CityViewDataSet;
            deletedData.splice(index - 1, 1);
            setDataList(deletedData);
          },
        },
      ],
      { cancelable: false }
    );
  };

  const INFOCOMP = {
    "activeCases": ({ value, selectedCurrency }) => (
      value && <InfoSection info={`Coronavirus Active cases at State/Province level:`} value={helpers.convertFormat(value, selectedCurrency)} />
    ),
    "covidVaccination": ({ value, selectedCurrency }) => (value &&
      <>
        <InfoSection info={`Coronavirus Daily Vaccinations (${value.date}):`} value={helpers.convertFormat(value.daily_vaccinations, selectedCurrency)} />
        <InfoSection info={`Coronavirus Fully Vaccinated (${value.date}):`} value={helpers.convertFormat(value.total_vaccinations, selectedCurrency)} />
      </>
    ),
    "averageMonthSalary": ({ filteredData, value, selectedCurrency }) => filteredData.map((row) => {
      if (row == 0) {
        return (<InfoSection info={`Average Monthly Net Salary (After Tax) :`} value={helpers.getCurrencySymbol(selectedCurrency) + " " +
          helpers.convertFormat(
            Math.round(helpers.currencyConverter(selectedCurrency, value)),
            selectedCurrency
          )} />);
      }
      return null;
    }),
    "englishLiteracy": ({ filteredData, value }) => filteredData.map((row) => {
      if (row == "9") {
        return (<InfoSection info={`English Literacy :`} value={`${value}%`} />);
      }
    }),
    "officalLanguages": ({ filteredData, value }) => filteredData.map((row) => {
      if (row == "10") {
        return (<InfoSection info={`Official Language(s) :`} value={value.join()} />);
      }
    }),
    "buisnessFriendliness": ({ filteredData, value }) => filteredData.map((row) => {
      if (row == "8") {
        return (<InfoSection info={`Business Friendliness Index :`} value={buisnessFriendlinessIndex(value)} />);
      }
    }),
    "pollutionIndex": ({ filteredData, value }) => filteredData.map((row) => {
      if (row == "4") {
        return (<InfoSection info={`Pollution Index :`} item={renderPollutionIndex(value, (text) => setAlertText({ title: `Pollution index ${value}`, desc: text }))} />);
      }
    }),
    "trafficIndex": ({ filteredData, value }) => filteredData.map((row) => {
      if (row == "5") {
        return (<InfoSection info={`Traffic Index :`} item={renderTrafficIndex(value, (text) => setAlertText({ title: `Traffic index ${value}`, desc: text }))} />);
      }
    }),
    "crimeIndex": ({ filteredData, value }) => filteredData.map((row) => {
      if (row == "6") {
        return (<InfoSection info={`Crime Index :`} item={renderCrimeIndex(value, (text) => setAlertText({ title: `Crime index ${value}`, desc: text }))} />);
      }
    }),
    "healthCareIndex": ({ filteredData, value }) => filteredData.map((row) => {
      if (row == "18") {
        return (<InfoSection info={`Health Care Index :`} item={renderHealthCareIndex(value, (text) => setAlertText({ title: `Health Care index ${value}`, desc: text }))} />);
      }
    }),
    "weather": ({ value }) => value && Array.isArray(value) && value.map((value, index) => <InfoSection info={getWeatherTitle(index)} value={value} />),
    "cityName": ({ value }) => (
      value && <CityName value={value} />
    ),
    "ranking": ({ value }) => (value?.map((university) => <Ranking university={university} />))
  }

  const renderItem = ({ item, index }) => {
    let contentArray = [];
    Object.keys(item).map((key, index) => {
      const value = item[key];
      const extraParam = {
        itemKey: key,
        filteredData: props.filteredData,
        selectedCurrency: props.selectedCurrency,
        selectedJobname: props.selectedJobname,
        value: item[key],
      };
      if (INFOCOMP[key]) {
        contentArray.push(INFOCOMP[key](extraParam))
      } else if (key !== "cityId" && value && !Array.isArray(value)) {
        contentArray.push(<CommonSection itemKey={key} {...extraParam} />);
      }
    });
    return (
      <Card
        style={{
          padding: 20,
          marginBottom: 5,
          borderRadius: 10,
          width: "95%",
          marginLeft: "2%",
        }}
      >
        <View style={styles.tableContentView}>
          <View
            style={{
              alignItems: "center",
              flexDirection: "column",
              flex: 1
            }}
          >
            {contentArray.map((item) => item)}
          </View>
        </View>
      </Card>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        style={styles.mainView}
      >
        <TouchableOpacity
          activeOpacity={1}
          style={styles.innerView}
        >
          <FlatList
            alwaysBounceVertical={false}
            style={[styles.flatlist]}
            data={dataList.length > 0 ? dataList : props.CityViewDataSet}
            contentContainerStyle={{
              marginBottom: 20,
            }}
            ListEmptyComponent={() => {
              if (false) {
                return <View />;
              } else {
                return (
                  <View style={{ flex: 1, backgroundColor: "white" }}>
                    <Text
                      style={styles.emptyText}
                    >
                      No Data Available
                    </Text>
                  </View>
                );
              }
            }}
            renderItem={renderItem}
            keyExtractor={(item, index) => index}
          />
        </TouchableOpacity>
      </ScrollView>

      {showFilterModal && (
        <Modal
          transparent={true}
          // animationType={"slide"}
          visible={showFilterModal}
          onRequestClose={() => {
            setFilterModal(false);
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#696969B3",
            }}
          >
            <View style={styles.modalInsideView}>
              <TouchableOpacity
                activeOpacity={0.7}
                onPress={() => {
                  setFilterModal(false);
                }}
                hitSlop={{ top: 20, bottom: 20, left: 50, right: 50 }}
              >
                <AntDesign
                  name="closecircle"
                  size={18}
                  color="rgb(174 ,174 ,174)"
                  style={styles.redCloseImage}
                />
              </TouchableOpacity>
              <View
                style={styles.modalContentView}
              >
                <ScrollView
                  style={{
                    height:
                      130 * props.filteredData.length < height - 250
                        ? 130 * props.filteredData.length
                        : "75%",
                  }}
                >
                  <View style={{ height: 160 * props.filteredData.length }}>
                    {/* <FlatList
                      style={{ flex: 1 }}
                      data={props.filteredData}
                      alwaysBounceVertical={false}
                      extraData={props.filteredData}
                      renderItem={this._renderItem}
                      keyExtractor={(item, index) => index}
                    /> */}
                  </View>
                </ScrollView>
                <View
                  style={styles.btnContainer}
                >
                  <ButtonComponent
                    handleSubmit={didTapOnDelete}
                    width={normalize(550)}
                    height={normalize(120)}
                    value={"Apply"}
                    bgColor={colors.LIGHT_BLUE}
                    textFontSize={normalize(42)}
                    family={family.regular}
                  />
                </View>
              </View>
            </View>
          </View>
        </Modal>
      )}
      {alertText ?
        <AlertModal
          title={alertText.title}
          description={alertText.desc}
          showConfirm
          confirmText="Ok"
          onConfirmPress={() => setAlertText(null)}
        />
        : null}
    </View>
  );
};

export default CityFactComparator;
