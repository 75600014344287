import React from "react";
import { ScrollView } from "react-native";
import { mobilBrower } from "../../../common/helpers";
import BullitinModal from "../../BullitinModal/BullitinModal";
import CreateUser from "../../CreateUser/CreateUser";

function SignUpModal({ visible, title, description, userPostData, onPinValidated, onCancelPress }) {

    return (
        <BullitinModal
            heading=""
            visible={visible}
            onClose={onCancelPress}
            // loading={loading}
            handleClose={onCancelPress}
            closeIcon={"closeBlack"}
            innerViewStyle={{ width: mobilBrower() ? "90%" : "40%", }}
        >
            <ScrollView style={{ paddingBottom: 20 }}>
                <CreateUser description={description} title={title} userPostData={userPostData} onCancelPress={onCancelPress} onPinValidated={onPinValidated} />
            </ScrollView>
        </BullitinModal>
    );
}

export default SignUpModal;
