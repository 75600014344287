import { StyleSheet } from "react-native";
import { family } from "../../../../../../common";

export const styles = StyleSheet.create({
    container:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: 5,
        borderRadius: 25,
        borderWidth: 2,
        padding: 5,
      },
    text:{
        fontSize: 16,
        marginLeft: 6,
        marginRight: 6,
        fontFamily: family.effra,
      }
})