import React, { useState, useEffect } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  Text
} from "react-native";
import Images from "../../common/images";
import { styles as s } from "./JobAnalysis.styles";
import Loader from "../Loader";
import config from "../../common/config";
import { SelectionModal } from '../SelectionModal/SelectionModal';
import helpers from "../../common/helpers";
import JobsList from "./components/JobsList/JobsList";
import JobChart from "./components/JobChart/JobChart";

const JobAnalysis = ({ onBackPress }) => {
  const [loading, setLoading] = React.useState(0);
  const [pageTrack, setPageTrack] = React.useState(0);
  const [currencyDropdown, setcurrencyDropdown] = useState([]);
  const [selectedCurrency, setselectedCurrency] = useState("USD");
  const [currencyModalVisible, setCurrencyModalVisible] = useState({
    active: false,
    params: null,
  });

  const JOBS = [
    {
      "job_title": "Developer Java",
      "company": "Hagido GmbH",
      "city": "Dortmund ,Germany",
      "experience_required": null,
      "salary": "50000-75000 EUR",
      "salary_info": "The salary data here, is the average salary for this role since the salary data is not given by the employer.",
      "cost_of_living": "17148 EUR",
      "tax_rate": "14% to 42%",
      "apply": "https:\/\/germantechjobs.de\/en\/jobs\/agido-GmbH-Developer-Java--Kotlin-wmd"
    },
    {
      "job_title": ".NET Software Engineer – \nMarketing Software Platform",
      "company": "Noir  ",
      "city": "Hamburg, Germany",
      "experience_required": null,
      "salary": "€75.000 – €95.000",
      "cost_of_living": "30696 EUR",
      "tax_rate": "42%",
      "apply": "https:\/\/www.linkedin.com\/jobs\/view\/3088095045"
    },
    {
      "job_title": "Back End Developer",
      "company": "Trust In SODA ",
      "city": "Berlin, Germany(Hybrid)",
      "experience_required": null,
      "salary": "€70,000 - €85,000",
      "salary_info": "The salary data here, is the average salary for this role since the salary data is not given by the employer.",
      "cost_of_living": "20971 EUR",
      "tax_rate": "42%",
      "apply": "https:\/\/www.linkedin.com\/jobs\/view\/3083657841"
    },
    {
      "job_title": "Software Engineer - Cloud Computing",
      "company": "Apple",
      "city": "Berlin,Germany",
      "experience_required": 3,
      "salary": null,
      "cost_of_living": "20971 EUR",
      "tax_rate": "14% to 42%",
      "apply": "https:\/\/www.linkedin.com\/jobs\/view\/3075067827"
    },
    {
      "job_title": "Software Engineer ",
      "company": "BMW Group",
      "city": "Munich,Bavaria",
      "experience_required": null,
      "salary": null,
      "cost_of_living": "32040 EUR ",
      "tax_rate": "14% to 42%",
      "apply": "https:\/\/www.linkedin.com\/jobs\/view\/3047370499"
    },
    {
      "job_title": "Senior Software Engineer-Backend",
      "company": "Telus International",
      "city": "European union",
      "experience_required": 5,
      "salary": null,
      "cost_of_living": "20971 EUR",
      "tax_rate": "14% to 42%",
      "apply": "https:\/\/www.linkedin.com\/jobs\/view\/3075084047"
    }
  ]
  const ChartData = [
    {
      country: 'Germany',
      tax: 10000,
      expenses: 12000,
      income: 28000,
      total: 50000
    },
    {
      country: 'India',
      tax: 6000,
      expenses: 10000,
      income: 34000,
      total: 40000,
    },
    {
      country: 'Canada',
      tax: 5000,
      expenses: 12000,
      income: 26000,
      total: 43000,
    },
    {
      country: 'Australia',
      tax: 10000,
      expenses: 41000,
      income: 46000,
      total: 97000,
    },
    {
      country: 'Japan',
      tax: 15000,
      expenses: 42000,
      income: 83000,
      total: 140000,
    },
  ];


  useEffect(() => {
    let _dropdown = [];
    config.currencyArray.forEach((s) => {
      s.label = s.value;
      _dropdown.push(s);
    });
    setcurrencyDropdown(_dropdown);
    helpers.refreshCurrency();
  }, []);

  const currencySelection = () => {
    setCurrencyModalVisible({
      active: true,
      params: {
        onSelectFn: setselectedCurrency,
        options: currencyDropdown,
        height: "68%",
        itemKey: "label",
        returnKey: "value",
      },
    });
  };

  const getPresentTrack = (track) => {
    switch (track) {
      case 0:
        return <JobsList onViewMore={() => setPageTrack(1)} jobs={JOBS} />;
      case 1:
        return <JobChart selectedCurrency={selectedCurrency} data={ChartData} />
      case 2:
      default:
        return <View />;
    }
  };

  return (
    <>
      <View style={s.main}>
        {pageTrack != 0 && (
          <View style={s.header}>
            <View />
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {
                [1].includes(pageTrack) &&
                <View style={s.currencyDropDown}>
                  <TouchableOpacity
                    style={{
                      width: 100,
                      height: 40,
                      marginRight: 25,
                      backgroundColor: "#F1AB32",
                      alignItems: "center",
                      borderRadius: 10,
                      justifyContent: "center",
                      borderWidth: 1,
                      borderColor: "#F1AB32",
                    }}
                    onPress={currencySelection}
                  >
                    <Text
                      style={{
                        color: "#fff",
                      }}
                    >
                      {selectedCurrency}
                    </Text>
                  </TouchableOpacity>
                </View>
              }
            </View>
          </View>
        )}
        <View
          style={{
            flex: 1,
            width: "100%",
          }}
        >
          {
            loading ? <Loader /> : null
          }
          {getPresentTrack(pageTrack)}
        </View>
        <View style={s.footer}>
          <TouchableOpacity onPress={() => { pageTrack == 1 ? setPageTrack(0) : onBackPress() }} style={s.btn}>
            <Text style={s.btnText}>{pageTrack == 1 ? "< Job Analysis" : "< Opportunities"} </Text>
          </TouchableOpacity>
          <View style={{ flex: 1 }} />
          {pageTrack == 1 ?
            <View style={{ flex: 1 }} /> :
            <TouchableOpacity onPress={() => { setPageTrack(1) }} style={s.btn}>
              <Text style={s.btnText}>{"Salary Insights >"}</Text>
            </TouchableOpacity>}
        </View>
      </View>
      <SelectionModal
        visible={currencyModalVisible.active}
        params={currencyModalVisible.params}
        setVisible={setCurrencyModalVisible}
      />
    </>
  );
};

export default JobAnalysis;
