import React, { useRef, useState } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import OTPTextView from "../OTPTextInput";
import NetInfo from "@react-native-community/netinfo";
import AsyncStorage from '@react-native-community/async-storage';
import { ckeckSignUPOTP, createNewUser, forgetpassUser, getUserDetails, getUserProfilePic } from "../../actions/auth.actions";
import { logEvent } from "../../actions/firebaselog.actions";
import { setGuestlogin } from "../../actions/path.actions";
import { useHistory } from "react-router-dom";
import NavigationService from "../../navigation/NavigationService";
import AlertModal from "..//AlertModal/AlertModal";
import { connect } from "react-redux";
import { Spinner } from "native-base";
import SweetAlert from "../SweetAlert";
import styles from "./PinValidation.styles"
import { uploadFileToFirebase } from "../../common/helpers";

const PinValidation = ({ valdidatedata, postData, dispatch, onResetPasswordData = () => { }, onPinValidated }) => {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const otpInputRef = useRef(null);
  const history = useHistory()

  const clearOTPText = () => {
    otpInputRef.current.inputs.forEach(input => input.clear());
    otpInputRef.current.inputs[0].focus()
  }

  const checkConnection = async () => {
    return NetInfo.fetch().then(state => {
      if (!state.isConnected) {
        SweetAlert.error('Check your connection')
      }
      return state.isConnected;
    });
  }

  const getReferalCode = async () => {
    try {
      return await AsyncStorage.getItem('ReferralCode')
    } catch (e) {
      return false;
    }
  }

  const otpValidation = async () => {
    if (!otp) {
      SweetAlert.error('please Enter OTP');
      return;
    }
    const isConnected = await checkConnection();
    if (!isConnected) {
      return;
    }
    var strinvalid = "Incorrect OTP!";
    var strforget = "OTP authenticated!";
    var strnewuser = "OTP Verified and New User Created!";
    try {
      let postData = {};
      postData.email_address = valdidatedata.email_address;
      postData.otp = parseInt(otp);
      postData.otp_type = valdidatedata.otp_type;
      let referralCode = await getReferalCode();
      if (referralCode) {
        postData.referral_code = referralCode;
      }
      setIsLoading(true);
      const response = await dispatch(ckeckSignUPOTP(postData));
      if (response.status === strnewuser) {
        const responsedata = await dispatch(getUserDetails(response.user_id, response.auth_token));
        logEvent('otp_success', {});
        const sourceUri = await AsyncStorage.getItem(`@newuser_avatar_uri`)
        const fileExt = await AsyncStorage.getItem(`@newuser_avatar_fileExt`)
        if (sourceUri && fileExt) {
          await uploadFileToFirebase({ refPath: `/avatarImages/${response.user_id}/${response.user_id}.${fileExt}`, file: sourceUri }).then((fileInfo) => {
            dispatch(getUserProfilePic(fileInfo))
          })
          await AsyncStorage.removeItem("@newuser_avatar_fileExt")
          await AsyncStorage.removeItem("@newuser_avatar_uri")
        }
        dispatch(setGuestlogin(false))
        setIsLoading(false)
        onPinValidated ? onPinValidated(response) :
          Platform.OS == 'web' ? history.push('/dashboard') : NavigationService.navigate("HomeStack")
      } else if (response.status === strforget) {
        resetdata = {};
        resetdata.email_address = valdidatedata.email_address;

        resetdata.auth_token = response.auth_token;

        resetdata.user_id = response.user_id;

        resetdata.status = 2;
        resetdata.string = "Reset Password";
        setIsLoading(false)
        onResetPasswordData(resetdata)
      } else {
        setIsLoading(false)
        setTimeout(() => {
          setShowErrorModal(true);
          setErrorMessage(response.status)
        }, 300);
        setTimeout(() => {
          setShowErrorModal(false);
          setErrorMessage("")
        }, 2000);
        clearOTPText()
        SweetAlert.error(response.status);
      }

    } catch (error) {
      setIsLoading(false)
    }

  }

  resendotp = async () => {
    let isConnected = await checkConnection();
    if (!isConnected) {
      return;
    }
    try {
      let response;
      let _postData = postData;
      if (valdidatedata.otp_type === "forgot_password_verification") {
        _postData = { email_address: valdidatedata.email_address };
        response = await dispatch(forgetpassUser(_postData))
      } else {
        response = await dispatch(createNewUser(_postData))
      }
      if (response.status.toString().includes('E-mail Sent to')) {
        SweetAlert.success(response.status)
      } else {
        setTimeout(() => {
          setShowErrorModal(true);
          setErrorMessage(response.status)
        }, 300);
        setTimeout(() => {
          setShowErrorModal(false);
          setErrorMessage("")
        }, 2000);
      }
    } catch (error) {

    }
  }


  return (
    <View style={[styles.slide1]}>
      {isLoading ?
        <View style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Spinner
            visible={isLoading}
          />
        </View> : null}
      <>
        <View style={{ alignItems: "center", width: "100%" }}>
          <View style={styles.subview}>
            <Text style={[styles.subtext, { fontSize: 20, marginBottom: 5 }]}>Verify Your Email</Text>
            <Text style={styles.subtext}>{"Please Enter the verification code \nsend to your given E-mail address"}</Text>
          </View>
          <View style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <OTPTextView
              containerStyle={{
                marginBottom: 20,
                marginLeft: 0
              }}
              handleTextChange={text => setOtp(text)}
              textInputStyle={styles.roundedTextInput}
              inputCount={6}
              keyboardType="numeric"
              tintColor="#fff"
              offTintColor="#fff"
              ref={e => (otpInputRef.current = e)}
            /></View>
          <View style={[styles.buttonView]}>
            <TouchableOpacity
              onPress={otpValidation}
              style={[styles.nextButton]}
            >
              <Text style={styles.nextText}>Continue</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.resendOtpCont}>
            <Text style={{ color: "#B7BBBD", fontSize: 13, fontFamily: "SourceSansPro-Regular" }}>Didn't Receive the OTP?</Text>
            <TouchableOpacity onPress={resendotp}><Text style={styles.resendText}> Resend OTP</Text></TouchableOpacity>
          </View>
        </View>
        {
          showErrorModal ?
            <AlertModal
              title="Oops!"
              description={errorMessage}
            />
            :
            <View />
        }
      </>
    </View>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(null, mapDispatchToProps)(PinValidation);
