import { get } from 'lodash';
import React from 'react'
import {
  View,
  Text,
  Image,
  ScrollView,
  Linking,
} from "react-native";
import { Images } from '../../common';
import helpers from '../../common/helpers';
import { styles } from "./styles";
import InAppBrowser from 'react-native-inappbrowser-reborn'

export default function PathDetailsComponent({ pathDetails }) {
  const getlable = (label) => {
    return (
      <Text style={styles.descriptionTitle}>
        {label}
      </Text>
    )
  }

  const handleLink = async (link) => {
    if (await InAppBrowser.isAvailable()) {
      try {
        const result = await InAppBrowser.open(link, helpers.inAPPbrowserOption)
      } catch (error) {
        await InAppBrowser.close()
        Linking.openURL(link)
      }
    } else {
      Linking.openURL(link)
    }
  };

  const getContent = (field, value, value2) => {
    if (field.includes('Time')) {
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Image source={Images.pathDetailImg3} style={{ height: 16, width: 16, marginRight: 5 }} />
          <Text style={styles.desText}>
            {`${value} - ${value2} days`}
          </Text>
        </View>
      )
    } else if (field == 'Source') {
      return (
        <>
          {
            value?.map((s) => (
              <Text onPress={() => handleLink(s)} style={[styles.desText, { color: 'blue' }]}>
                {s}
              </Text>
            ))
          }
        </>
      )
    } else if (field == 'Criteria') {
      return (
        <>
          {
            value?.map((s) => (
              <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 5 }}>
                <Image
                  source={Images.bullet}
                  style={[styles.bullet, { marginVertical: 12 }]}
                />
                <Text onPress={() => handleLink(s)} style={[styles.desText]}>
                  {s}
                </Text>
              </View>
            ))
          }
        </>
      )
    } else {
      return (
        <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
          {
            field == 'validity' ?
              <Image source={Images.pathDetailImg1} style={{ height: 18, width: 18, marginRight: 5, marginTop: 8 }} />
              : null
          }
          <Text style={styles.desText}>
            {value}
          </Text>
        </View>
      )
    }
  }

  return (
    <ScrollView bounces={false}>
      <View style={styles.VisaTitleView}>
        <Image source={helpers.getCountryFlag(get(pathDetails, 'country_id', 0))} style={styles.flagStyle} />
        <View style={styles.visaNameView}>
          <Text numberOfLines={2} style={styles.visaName}>
            {get(pathDetails, 'immipath_name', 0)}
          </Text>
          <Text style={styles.countryName}>
            {helpers.getCountryName(get(pathDetails, 'country_id'))}
          </Text>
          <View style={{ flexDirection: 'row' }}>
            {
              helpers.getGoalName([1, 2]).map((s) => (
                <Text numberOfLines={1} style={[styles.tags]}>
                  {s}
                </Text>
              ))
            }
          </View>
        </View>
      </View>

      <View style={styles.descriptionView}>
        {getlable('Description')}
        {getContent('Description', get(pathDetails, 'path_description', ''))}

        {getlable('Application Time')}
        {getContent('Application Time', get(pathDetails, 'time_for_user_application_max', ''), get(pathDetails, 'time_for_user_application_min', ''))}

        {/* {getlable('Application Cost')}
            {getContent(get(pathDetails, 'path_description', ''))} */}

        {getlable('Validity')}
        {getContent('validity', get(pathDetails, 'validity', ''))}

        {getlable('Source')}
        {getContent('Source', get(pathDetails, 'resources', []))}

        {getlable('Eligibility Criteria')}
        {getContent('Criteria', get(pathDetails, 'eligibility_criteria', []))}
      </View>
    </ScrollView>
  )
}
