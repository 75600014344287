import React from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { useSelector } from "react-redux";
import fontFamily from "../../../../common/fontFamily";
import { heightPercentageToDP } from "../../../../common/responsiveScreen";
import { EmptyDiscussion } from "../EmptyDiscussion/EmptyDiscussion";
import { MainDiscussions } from "../MainDiscussions/MainDiscussions";

export const MyPublicDiscussion = ({
  selectedCategory,
  setShowCategories,
  discussions,
  handleCategory,
  loading,
}) => {
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);

  return (
    <View
      style={{
        marginTop: 20,
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Text
        style={{
          fontFamily: fontFamily.titilliumBold,
          fontSize: 18,
          color: "gray",
          marginBottom: 10,
          textTransform: "capitalize",
        }}
      >
        {selectedCategory ? guestLogin ? 'Recent Discussions' : "Your Public Discussions " : ''}
      </Text>
      <ScrollView
        style={{
          // marginBottom: 240,
          paddingLeft: "5%",
          width: '100%',
          flex: 1,
          // flexGrow: 5,
          paddingRight: "5%",
          // height: height * 0.46,
        }}
      >
        {loading ? (
          <View
            style={{
              height: heightPercentageToDP(46),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ActivityIndicator
              size="large"
              color={"green"}
              style={{ alignSelf: "center" }}
            />
          </View>
        ) : discussions?.length ? (
          <MainDiscussions
            selectedCategory={selectedCategory}
            discussions={discussions}
          />
        ) : (
          <EmptyDiscussion setShowCategories={setShowCategories} selectedCategory={selectedCategory} />
        )}
      </ScrollView>
      <View style={{ width: "90%", alignSelf: 'center' }}>
        <TouchableOpacity
          onPress={() =>
            selectedCategory ? handleCategory(null) : setShowCategories(true)
          }
          style={{
            marginTop: 15,
            backgroundColor: selectedCategory ? "gray" : "#F1AB32",
            borderRadius: 9,
            // marginBottom: 50,
            alignSelf: 'center',
            padding: 15,
            width: "90%",
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: 15,
              textAlign: "center",
              fontFamily: fontFamily.effra,
            }}
          >
            {selectedCategory ? "Clear" : "Browse"}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
