import React from "react";
import { mobilBrower } from "../../../../common/helpers";
import BullitinModal from "../../../BullitinModal/BullitinModal";
import PrQA from "../../../PrCalculator/components/PrQA";
import { qtnMappings } from "../../questionIdsMapping";

function PrQAModal({ visible, userResults = {}, creditBuilderData, selectedCountries, onClose = () => { }, onScoreCalculate = () => { } }) {

    const userAnswers = Object.keys(qtnMappings).reduce((obj, id) => {
        let answers = { ...userResults.payload.form_responses, ...userResults.userInfo }
        obj[qtnMappings[id]] = answers[id];
        return obj;
    }, {})

    return (
        <BullitinModal
            heading=""
            visible={visible}
            onClose={onClose}
            // loading={loading}
            handleClose={onClose}
            closeIcon={"closeBlack"}
            innerViewStyle={{ width: mobilBrower() ? "90%" : "70%", maxHeight: "80%" }}
        >
            <PrQA
                creditBuilderData={creditBuilderData}
                selectedCountries={selectedCountries}
                onScoreCalculate={onScoreCalculate}
                userAnswers={userAnswers}
            />
        </BullitinModal>
    );
}

export default PrQAModal;
