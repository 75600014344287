import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from "react-native";
import BullitinModal from "../../../BullitinModal/BullitinModal";
import { styles } from "./setupProfileModal.styles";
import profileProgressData from "./config.json";
import LottieView from "lottie-react-native";
import { BulletsWrapper } from "../Home/Components/BulletsWrapper/BulletsWrapper";
import { Languages } from "../Home/Components/Languages/Languages";
import { Occupations } from "../Home/Components/Occupations/Occupations";
import { useSelector, useDispatch } from "react-redux";
import { Network } from "../../../../service/api";
import { getUserDetails } from "../../../../actions/auth.actions";
import { isArray } from "lodash";
import { isWeb, mobilBrower } from "../../../../common/helpers";
import Images from "../../../../common/images";

export const SetupProfileModal = ({
  visible,
  setVisible,
  profileProgress,
  onCloseDashboardModal,
  setProfileProgress,
}) => {
  const [loading, setloading] = React.useState(false);
  const [error, seterror] = useState({
    interests: false,
    occupations: {},
    languages: {},
    lifestyles: false,
  });
  const [saving, setSaving] = React.useState(false);
  const [track, setTrack] = React.useState(visible.track ? visible.track : 0);
  const dispatch = useDispatch();
  const [tempProgressData, setTempProgressData] =
    React.useState(profileProgress);
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const Token = useSelector((state) => state.authReducer.authData.token);

  const onClose = () => {
    setVisible({
      active: false,
      track: null,
    });
  };

  const handleClose = (func) => {
    func();
  };

  const updateSettings = (key, value) => {
    setTempProgressData({
      ...tempProgressData,
      [key]: {
        ...tempProgressData[key],
        response: value,
      },
    });
  };
  const validate = (callback) => {
    try {
      let _error = {
        interests: false,
        occupations: {},
        languages: {},
        lifestyles: false,
      };

      if (track === 0 && !tempProgressData.interests.response.length) {
        _error.interests = true;
      }

      tempProgressData.occupations.response.length &&
        tempProgressData.occupations.response.forEach((s, i) => {
          for (var key in s) {
            if (isArray(s[key])) {
              s[key].forEach((x, j) => {
                if (x == "") {
                  if (j == 0) {
                    _error.occupations["startdate"] = _error.occupations[
                      "startdate"
                    ]
                      ? _error.occupations["startdate"].concat(i)
                      : [i];
                  } else if (!s["currentlyWorking"]) {
                    _error.occupations["enddate"] = _error.occupations[
                      "enddate"
                    ]
                      ? _error.occupations["enddate"].concat(i)
                      : [i];
                  }
                }
              });
            } else if (
              s[key] == "" &&
              key !== "currentlyWorking"
            ) {
              if (_error.occupations[key]) {
                _error.occupations[key] = _error.occupations[key].concat([i]);
              } else {
                _error.occupations[key] = [i];
              }
            }
          }
        });

      tempProgressData?.languages?.response?.length &&
        tempProgressData?.languages?.response?.forEach((s, i) => {
          Object.entries(s).map((e) => {
            if (!["read", "write", "speak"].includes(e[0])) {
              if (!e[1])
                _error.languages[e[0]] = _error.languages[e[0]]
                  ? _error[e[0]].concat(i)
                  : [i];
            }
          });
        });

      if (track === 3 && !tempProgressData.lifestyles.response.length) {
        _error.lifestyles = true;
      }

      if (visible.track === 0 || visible.track || track === 4) {
        return handleFinalValidation(_error);
      } else {
        return handleFinalValidation(_error, callback);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleFinalValidation = (err, callback) => {
    const refErrorObj = Object.values(err)[track];
    const isError =
      typeof refErrorObj === "object"
        ? Object.values(refErrorObj).length
        : refErrorObj;

    if (isError) {
      seterror(err);
      return;
    } else {
      if (callback) {
        callback();
      } else {
        saveChanges();
        console.log("Save Call");
      }
    }
  };

  const saveChanges = async () => {
    setSaving(true);
    let newObjArr = Object.entries({ ...tempProgressData }).reduce(
      (prev, curr, index) => {
        let newArr;
        if ([1, 2].includes(index)) {
          let val = curr[1]?.response.length > 0 && curr[1]?.response?.reduce((pr, cu, index) => {
            return { ...pr, [index]: cu };
          }, {});
          newArr = { ...prev, [curr[0]]: val };
        } else {
          newArr = { ...prev, [curr[0]]: curr[1].response };
        }
        return newArr;
      },
      {}
    );
    const url = "/users/" + userDetails?.userid;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: userDetails?.userid + "@" + Token,
    };

    const e = await Network(url, "PATCH", newObjArr, headers);
    if (e) {
      await dispatch(getUserDetails(userDetails?.userid, Token));
      setProfileProgress(tempProgressData);
      onCloseDashboardModal && onCloseDashboardModal()
      setVisible({
        active: false,
        track: null,
      });
    }
  };

  const renderCurrentTrack = () => {
    switch (track) {
      case 0:
        return (
          <>
            <BulletsWrapper
              data={tempProgressData.interests.response}
              updateSettings={(value) => updateSettings("interests", value)}
              arr={profileProgressData[0].availableOptions}
              maxSelection={10}
            />
            {error.interests ? (
              <Text style={styles.errorText}>
                {`Select atleast one interest`}
              </Text>
            ) : null}
          </>
        );
      case 1:
        return (
          <Occupations
            occupations={tempProgressData.occupations.response}
            error={error.occupations}
            updateSettings={(value) => updateSettings("occupations", value)}
          />
        );
      case 2:
        return (
          <Languages
            updateSettings={(value) => updateSettings("languages", value)}
            availableLangs={tempProgressData.languages.response}
            error={error.languages}
          />
        );
      case 3:
        return (
          <>
            <BulletsWrapper
              data={tempProgressData.lifestyles.response}
              updateSettings={(value) => updateSettings("lifestyles", value)}
              arr={profileProgressData[3].availableOptions}
              maxSelection={5}
            />
            {error.lifestyles ? (
              <Text style={styles.errorText}>
                {`Select atleast one lifestyle`}
              </Text>
            ) : null}
          </>
        )
      case 4:
        return (
          <View
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LottieView
              duration={3000}
              style={[
                {
                  height: 250,
                },
              ]}
              source={require("./CheckLottie/33886-check-okey-done.json")}
              autoPlay
              loop={false}
            />
            <Text style={[styles.finishedText]}>
              Finished Setting Up Your Profile
            </Text>
          </View>
        );
      default:
        return null;
    }
  }

  const renderTrackImage = () => {
    const trackImages = {
      0: Images.profileInterest,
      1: Images.profileOccupation,
      2: Images.profileLang,
      3: Images.profileInterest2,
    }
    return <>
      {trackImages[track] ? <View style={{ height: "100%", width: "50%" }}>
        <Image source={trackImages[track]} style={{ height: "100%", width: "100%" }} />
      </View> : null}
    </>
  }

  return (
    <BullitinModal
      heading="News"
      hidelogo={isWeb() && !mobilBrower()}
      visible={visible.active}
      onClose={onClose}
      loading={loading}
      handleClose={handleClose}
      logoFrom="right"
      hideCloseButton={(isWeb() && !mobilBrower()) || saving ? true : false}
      viewableContentHeight={isWeb() && !mobilBrower() ? "85%" : "77%"}
      innerViewStyle={isWeb() && !mobilBrower() ? { width: "90%", borderRadius: 0, height: "100%" } : {}}
    >
      {saving ? (
        <ActivityIndicator
          size="large"
          color="gray"
          style={{
            flex: 1,
          }}
        />
      ) : (
        <View style={styles.content}>
          {isWeb() && !mobilBrower() ? renderTrackImage() : null}
          <View style={[styles.wrapper, isWeb() && !mobilBrower() ? {} : { width: "100%" }]}>
            <View style={{ flexDirection: "row", width: "100%", alignItems: "center" }}>
              {track != 0 ?
                <TouchableOpacity onPress={() => setTrack((prev) => prev - 1)}>
                  <Image source={Images.blackbackIcon} style={styles.backIcon} />
                </TouchableOpacity> : null}
              {track < profileProgressData.length ? (
                <>
                  <Text style={[styles.headerText]}>
                    Add your {profileProgressData[track].title}
                  </Text>
                  {isWeb() && !mobilBrower() ?
                    <TouchableOpacity onPress={onClose}>
                      <Image source={Images.closeBlack} style={{ width: 25, height: 25, resizeMode: "contain" }} />
                    </TouchableOpacity> : null}
                </>
              ) : (
                <View />
              )}
            </View>
            <View style={{ flex: 1, width: "100%" }}>
              {renderCurrentTrack()}
            </View>
            <TouchableOpacity
              onPress={() => validate(() => setTrack((prev) => prev + 1))}
              style={[styles.button]}
            >
              <Text style={[styles.buttonText]}>
                {track === profileProgressData.length || visible.track?.toString()
                  ? "Finish"
                  : "Next"}
              </Text>
            </TouchableOpacity>
            {isWeb() && !mobilBrower() ?
              <View style={styles.barContainer}>
                {["0", "1", "2", "3"].map((_, index) => (
                  <View key={index + "t"} style={[styles.bar, { backgroundColor: track == index ? "#0F1143" : "rgb(180,180,180)" }]} />
                ))}
              </View> : null}
          </View>
        </View>
      )}
    </BullitinModal>
  );
};
