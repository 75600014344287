import { get } from 'lodash';
import React, { useState } from 'react'
import { Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import helpers from '../../../../common/helpers';
import { Network } from '../../../../service/api';
import BullitinModal from '../../../BullitinModal/BullitinModal'
import { styles as s } from "./sectionC.styles.web";

export default function PaymentModal({ onClose, handlePayment, visible, handleFinish }) {
    const [promoCode, setPromoCode] = useState(null)
    const baseCurrency = useSelector((state) => state.referralReducer.referralData.baseCurrency);
    const [basePrice, setbasePrice] = useState(299)
    const [total, settotal] = useState(352.82)
    const [discountAmt, setdiscountAmt] = useState(null)
    const [errorMsg, seterrorMsg] = useState(null)
    
    const headers = {
        Accept: "application/json",
        Authorization: `guest@immipath`,
    }

    const handleChange = (value) => {
        setPromoCode(value)
    }

    const handleContinue = () => {
        if (total == 0) {
            handleFinish()
            return
        }
        handlePayment(total)
    }

    const handleApply = async () => {
        if (!promoCode) {
            seterrorMsg('Please enter promo code')
            return
        }
        seterrorMsg(null)
        const payload = {
            promo_code: promoCode.trim(),
            platform: "opportunity_report",
            user_id: 'guest@s@g.com'
        };
        let res = await Network("/data/verify_promo_code", "POST", payload, headers);
        if (res.status == 'Success') {
            if (get(res, 'promo_dict.is_active', false)) {
                let discountamt = basePrice * (get(res, 'promo_dict.discount_perc', 0) / 100)
                let priceAfterDiscount = basePrice - discountamt
                setdiscountAmt(discountamt)
                settotal(priceAfterDiscount)
            }
        } else {
            seterrorMsg(res.status)
        }
    }

    return (
        <BullitinModal
            heading=""
            visible={visible}
            onClose={onClose}
            handleClose={onClose}
            closeIcon={"closeBlack"}
        >
            <View style={s.mainView}>
                <View style={s.summaryView}>
                    <Text style={s.summaryText}>
                        Summary
                    </Text>
                    <View style={s.separator} />
                    <View style={s.innerRow}>
                        <Text style={s.label}>
                            Base Price
                        </Text>
                        <Text style={s.price}>
                            {helpers.getCurrencySymbol(baseCurrency)+helpers.storeCurrencyConverer(baseCurrency, basePrice)}
                        </Text>
                    </View>
                    <View style={s.innerRow}>
                        <Text style={s.label}>
                            GST(18%)
                        </Text>
                        <Text style={s.price}>
                            {helpers.getCurrencySymbol(baseCurrency)+helpers.storeCurrencyConverer(baseCurrency, 53.82)}
                        </Text>
                    </View>
                    {
                        discountAmt ?
                            <View style={s.innerRow}>
                                <Text style={s.label}>
                                    Discount
                                </Text>
                                <Text style={s.price}>
                                    {`-${helpers.storeCurrencyConverer(baseCurrency, discountAmt)}`}
                                </Text>
                            </View> : null
                    }

                    <View style={s.innerRow}>
                        <Text style={s.label}>
                            Total
                        </Text>
                        <Text style={s.price}>
                            {helpers.getCurrencySymbol(baseCurrency)+helpers.storeCurrencyConverer(baseCurrency, total)}
                        </Text>
                    </View>
                </View>
                <View style={s.coupounView}>
                    <Text style={s.havecodeText}>
                        Have a coupon code ?
                    </Text>
                    <View style={s.couponTextboxWrapper}>
                        <TextInput
                            placeholder='Enter Promo code'
                            style={{ marginTop: 10, padding: 5 }}
                            onChangeText={handleChange}
                        />
                        <TouchableOpacity onPress={handleApply} style={s.applyButton}>
                            <Text style={s.applyText}>
                                Apply
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <View style={s.separator} />
                    {
                        errorMsg ?
                            <Text style={{ color: 'red', marginTop: 10 }}>
                                {errorMsg}
                            </Text> : null
                    }
                </View>
                <TouchableOpacity onPress={handleContinue} style={s.continueButton}>
                    <Text style={s.continueText}>
                        Continue To Pay
                    </Text>
                </TouchableOpacity>
            </View>
        </BullitinModal>
    )
}
