import { StyleSheet } from "react-native";
import { family } from "../../../../common";
import { mobilBrower } from "../../../../common/helpers";

export const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(20,20,20,0.8)",
  },
  closeWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  modal: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    width: mobilBrower() ? "90%" : "60%",
  },
  inputBox: {
    borderWidth: 0.5,
    borderRadius: 5,
    padding: 10,
    borderColor: 'grey',
    marginBottom: 10
  },
  closeIconButton: {
    width: 20,
    height: 20,
  },

  closeIcon: {
    width: "100%",
    height: "100%",
  },
  title: {
    marginBottom: 20,
    textAlign: "center",
    width: "80%",
    fontSize: 25,
    marginLeft: "10%",
    fontFamily: family.titilliumBold,
    textTransform: "capitalize",
  },
  buttonView: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  applyButton: {
    flex: 1,
    alignItems: "center",
    margin: 10,
    justifyContent: "center",
    borderRadius: 10,
  },
  premiumButtonText: {
    color: "white",
    padding: 10,
    fontFamily: family.effra,
  },
})