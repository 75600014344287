import React from "react";
import {
  Text,
  TouchableOpacity,
  Image,
  View,
  Linking,
  TouchableHighlight,
} from "react-native";
import { notificationModalStyles as styles } from "../../notificationModal.styles";
import Images from "../../../../common/images";
import * as AddCalendarEvent from "react-native-add-calendar-event";

export const EventsFooter = ({ link, date_time, title, description }) => {
  const [dropDown, setDropDown] = React.useState(false);
  const [reminderAdded, setReminderAdded] = React.useState(false);
  const [addedToCalender, setAddedToCalender] = React.useState(false);
  const typeReminders = ["week", "day", "hour"];

  const addToCalender = async (remindOn) => {
    const eventConfig = {
      title,
      startDate: remindOn,
      location: "",
      allDay: true,
      url: link,
      notes: description,
      endDate: new Date(
        new Date(remindOn).getTime() + 2 * 60 * 60 * 1000
      ).toISOString(),
    };

    const event = await AddCalendarEvent.presentEventCreatingDialog(
      eventConfig
    );

    if (event.action === "CANCELED") {
    } else {
      remindOn ? setDropDown(false) : null;
      remindOn ? setReminderAdded(true) : setAddedToCalender(true);
    }
  };

  const getParsedDateInfo = (type) => {
    const eventDate = new Date(date_time);
    const startDate = {
      week: new Date(
        eventDate.getTime() - 7 * 24 * 60 * 60 * 1000
      ).toISOString(),
      day: new Date(
        eventDate.getTime() - 1 * 24 * 60 * 60 * 1000
      ).toISOString(),
      hour: new Date(eventDate.getTime() - 1 * 60 * 60 * 1000).toISOString(),
    };
    return type ? startDate[type] : startDate;
  };

  const handleCustomReminder = (type) => {
    const remindOn = getParsedDateInfo(type);
    addToCalender(remindOn);
  };

  return (
    <View style={styles.eventControlWrapper}>
      {/* <View style={[styles.remindMedDropDown]}>
        {dropDown &&
          typeReminders
            .filter((type) => {
              const remindOn = getParsedDateInfo(type);
              return new Date().getTime() - new Date(remindOn).getTime() < 0;
            })
            .map((type) => {
              return (
                <TouchableHighlight
                  activeOpacity={0.8}
                  underlayColor={"rgb(247,247,247)"}
                  onPress={() => handleCustomReminder(type)}
                  style={[styles.remindMedDropDownText]}
                >
                  <Text
                    style={[
                      {
                        borderTopWidth: 0,
                        textAlign: "center",
                      },
                    ]}
                  >
                    Before 1 {type}
                  </Text>
                </TouchableHighlight>
              );
            })}
      </View> */}
      <TouchableOpacity
        style={styles.eventControlRegister}
        onPress={() => Linking.openURL(link)}
      >
        <Text style={styles.eventControllText}>Register</Text>
      </TouchableOpacity>
      <View style={styles.eventControlHeaderWrapper}>
        {/* <TouchableOpacity
          onPress={(prev) => setDropDown(!dropDown)}
          disabled={reminderAdded}
          style={[
            styles.eventControllHeaderButtons,
            {
              backgroundColor: reminderAdded ? "rgb(210,210,210)" : "#0F1143",
            },
          ]}
        >
          {reminderAdded ? (
            <Text
              style={[
                styles.eventControllText,
                { color: "green", fontSize: 12 },
              ]}
            >
              ✔
            </Text>
          ) : (
            <>
              <Image
                source={Images.bellRing}
                style={{
                  height: 21,
                  width: 23,
                }}
                resizeMode="contain"
              />
              <Text style={styles.eventControllText}>Remind Me</Text>
            </>
          )}
        </TouchableOpacity> */}
        <TouchableOpacity
          onPress={() => addToCalender(new Date(date_time).toISOString())}
          disabled={addedToCalender}
          style={[
            styles.eventControllHeaderButtons,
            {
              backgroundColor: addedToCalender ? "rgb(210,210,210)" : "#0F1143",
            },
          ]}
        >
          {addedToCalender ? (
            <Text
              style={[
                styles.eventControllText,
                { color: "green", fontSize: 12 },
              ]}
            >
              ✔
            </Text>
          ) : (
            <>
              <Image
                style={{
                  height: 23,
                  width: 23,
                }}
                resizeMode="contain"
                source={Images.nounAddCalendar}
              />
              <Text style={styles.eventControllText}>Add to calendar</Text>
            </>
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};
