import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  Animated,
  TouchableOpacity,
  Pressable,
  Platform
} from "react-native";
import { heightPercentageToDP } from "react-native-responsive-screen";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchComponentsData,
  setPIndex,
} from "../../../../../../actions/mainMedia.actions";
import config from "../../../../../../common/config";
import { isWeb } from "../../../../../../common/helpers";
import { styles } from "../../activeTab.styles";
import ActiveTabFooter from "../ActiveTabFooter";
import DefaultCards from "../Cards/DefaultCards";

var counter = false;
const News = () => {
  //States
  const [loading, setLoading] = useState(true);
  const [endLoading, setEndLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const flatlistRef = useRef(null);
  const scrollPositionRef = useRef();

  // If limit is needed
  // const maxNews = 200;

  // Selectors
  const presentlyViewingNews = useSelector(
    (state) =>
      state.mainMediaReducer.news[state.mainMediaReducer.news.config.viewType]
  );

  const filterLoading = useSelector(
    (state) => state.mainMediaReducer.filterLoading
  );
  const pIndex = useSelector(
    (state) =>
      state.mainMediaReducer.news.config.pIndexes[
      state.mainMediaReducer.news.config.viewType
      ]
  );

  const maxFetchedBool = useSelector(
    (state) =>
      state.mainMediaReducer.news.config.maxFetchedBools[
      state.mainMediaReducer.news.config.viewType
      ]
  );

  const defaultNews = useSelector(
    (state) => state.mainMediaReducer.news["default"]
  );
  const guestLogin = useSelector(
    (state) => state.pathReducer.getImmipathDetails.guestLogin
  );
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const token = useSelector((state) => state.authReducer.authData.token);

  const activeClassification = useSelector(
    (state) =>
      state.mainMediaReducer[state.mainMediaReducer.gState.activeTab.title]
        .config.viewType
  );

  const searchedQuery = useSelector(
    (state) =>
      state.mainMediaReducer[state.mainMediaReducer.gState.activeTab.title]
        .config.searchedQuery
  );

  const filterParams = useSelector(
    (state) =>
      state.mainMediaReducer[state.mainMediaReducer.gState.activeTab.title]
        .config.filter
  );

  const customLoading = useSelector(
    (state) =>
      state.mainMediaReducer[state.mainMediaReducer.gState.activeTab.title]
        .config.customLoading
  );

  const dispatch = useDispatch();

  // Fns
  const fetchInitialNews = async () => {
    if (guestLogin) {
      await dispatch(
        fetchComponentsData({
          user_id: 0,
          authtoken: "guest@news",
          componentType: "news",
        })
      );
    } else {
      await dispatch(
        fetchComponentsData({
          user_id: userDetails?.userid,
          authtoken: token,
          from: 0,
          componentType: "news",
        })
      );
    }
    setLoading(false);
  };

  const refreshData = async () => {
    setRefreshing(true);
    await dispatch(
      fetchComponentsData({
        user_id: guestLogin ? 0 : userDetails?.userid,
        authtoken: guestLogin ? "guest@news" : token,
        from: 0,
        componentType: "news",
        refresh: true,
        classification:
          activeClassification === "default" ? null : activeClassification,
        immiInterest: guestLogin
          ? config.immigerat.map((s) => s.code).join(",")
          : filterParams.immigrationInterest.join(","),
        country: guestLogin
          ? config.intrestcountry.map((s) => s.code).join(",")
          : filterParams.countriesInterestedIn.join(","),
        searchtext: searchedQuery,
      })
    );
    dispatch(setPIndex(0));
    setRefreshing(false);
  };

  const fetchMoreNews = async () => {
    if (!endLoading && !maxFetchedBool && !guestLogin) {
      setEndLoading(true);
      counter = true;
      await dispatch(
        fetchComponentsData({
          user_id: guestLogin ? 0 : userDetails?.userid,
          authtoken: guestLogin ? "guest@news" : token,
          from: pIndex + 25,
          componentType: "news",
          refresh: false,
          classification:
            activeClassification === "default" ? null : activeClassification,
          immiInterest: filterParams.immigrationInterest.join(","),
          country: filterParams.countriesInterestedIn.join(","),
          searchtext: searchedQuery,
        })
      );
      dispatch(setPIndex(pIndex + 25));
      setEndLoading(false);
    }
  };

  // UseEffects
  useEffect(() => {
    (async () => {
      if (defaultNews.length === 0) {
        fetchInitialNews();
      } else {
        setLoading(false);
      }
    })();
  }, []);

  // Animation Refs
  const scrollY = React.useRef(new Animated.Value(0)).current;
  const ITEM_SIZE = 330;

  const handleAnimationEvents = (index) => {
    const inputRange = [
      -1,
      0,
      ITEM_SIZE * index + 1.2,
      ITEM_SIZE * (index + 2),
    ];
    const opacityRange = [-1, 0, ITEM_SIZE * index, ITEM_SIZE * (index + 1)];

    const scale = scrollY.interpolate({
      inputRange,
      outputRange: [1, 1, 1, 0],
    });

    const opacity = scrollY.interpolate({
      inputRange: opacityRange,
      outputRange: [1, 1, 1, 0],
    });

    return { scale, opacity };
  };

  const classificationLoading =
    ["opportunity", "threat"].includes(activeClassification) &&
    presentlyViewingNews.length === 0;

  const data = guestLogin
    ? presentlyViewingNews.slice(0, 3)
    : presentlyViewingNews;

  return customLoading || filterLoading ? (
    <View style={styles.noDataView}>
      <Text>Please wait,</Text>
      <Text>Loading results...</Text>
      <ActivityIndicator
        size="large"
        color="rgb(201,201,201)"
        style={{
          margin: 40,
        }}
      />
    </View>
  ) : (
    <TouchableOpacity activeOpacity={1} style={{ flex: 1 }}>
      <Animated.FlatList
        style={{ marginBottom: isWeb() ? 25 : heightPercentageToDP("45%") }}
        onEndReachedThreshold={100}
        contentContainerStyle={{ paddingBottom: 40 }}
        scrollEventThrottle={400}
        ref={flatlistRef}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: true }
        )}
        ListEmptyComponent={() =>
          loading ? (
            <View style={styles.noDataView}>
              <ActivityIndicator size="large" color="rgb(201,201,201)" />
            </View>
          ) : (
            <View style={styles.noDataView}>
              <Text style={styles.emptyListText}>Oops! No results found</Text>
            </View>
          )
        }
        removeClippedSubviews={true}
        ListFooterComponent={() => (
          <ActiveTabFooter dataLoaded={data.length} endLoading={endLoading} onLoadMorePress={fetchMoreNews} />
        )}
        initialNumToRender={2}
        maxToRenderPerBatch={100}
        keyExtractor={(e) => e.news_id}
        data={data}
        windowSize={30}
        extraData={data}
        onRefresh={refreshData}
        refreshing={refreshing}
        onEndReached={isWeb() ? () => { } : fetchMoreNews}
        renderItem={({ item, index }) => {
          const { scale, opacity } = handleAnimationEvents(index);
          return (
            <Animated.View
              style={{
                transform: Platform.OS == 'android' ? [{ scale }] : [],
                opacity: Platform.OS == 'android' ? opacity : 1,
                paddingBottom: 20,
                marginTop: index === 0 ? 0 : 10,
              }}
            >
              <DefaultCards item={item} key={index} index={index} />
            </Animated.View>
          );
        }}
      />
    </TouchableOpacity>
  );
};

export default News;
