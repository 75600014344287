import React from "react";
import {
    View,
    Text
} from "react-native";
import colors from "../../../../../common/colors";
import { styles } from "../cityFacts.styles";

export const InfoSection = ({ info, value, item }) => {
    return (
        <View style={{
            flexDirection: "row",
            marginTop: 0,
            alignSelf: "flex-start",
            marginBottom: 10,
            height: 40,
            width: "100%",
            alignItems: "center"
        }}>
            <View style={{ flex: 0.7 }}>
                <Text
                    multiLine={true}
                    style={[styles.text, { textAlign: "left" }]}
                >
                    {info}
                </Text>
            </View>
            <View style={{ flex: 0.3, marginLeft: 15, alignItems: "flex-end", }}>
                {value ?
                    <Text style={[styles.tableContentText]}>
                        {value}
                    </Text> : item ? item :
                        <Text style={{ textAlign: "center", color: colors.DARK_BLUE }}>
                            N/A
                        </Text>}
            </View>
        </View>
    );
};
