import AsyncStorage from "@react-native-community/async-storage";
import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  Image,
  ScrollView,
  TextInput,
  Linking,
  Platform,
} from "react-native";
import { connect } from "react-redux";
import { helpers, Images } from "../../common";
import DropDownPicker from "react-native-dropdown-picker";
import { logEvent } from "../../actions/firebaselog.actions";
import DeviceInfo from "react-native-device-info";
import { Network, apiPaths } from "../../service/api";
import HeaderComponent from "../../common/NewHeader/NewHeader";
import { styles } from "./aboutUs.styles";
import aboutImg1 from "../../images/aboutImg1.png";
import aboutImg2 from "../../images/aboutImg2.png";
import aboutImg3 from "../../images/aboutimg3.png";
import WebFooter from "../../common/WebFooter";
import appstore from "../../images/appstore.png";
import Playstore from "../../images/Playstore.png";
import insta from "../../images/insta.png";
import fb from "../../images/fb.png";
import InAppBrowser from 'react-native-inappbrowser-reborn'

import { mobilBrower } from "../../common/helpers";

const { width, height } = Dimensions.get("window");

let categoryData = [
  {
    value: "Application Help",
    label: "Application Help",
  },
  {
    value: "Application Error",
    label: "Application Error",
  },
  {
    value: "Feature Request",
    label: "Feature Request",
  },
];

class AboutUs extends Component {
  constructor() {
    super();
    this.state = {
      text: "",
      disableText: false,
      selectCategoryData: "Hello",
      UserId: "",
      emailId: "",
    };
  }

  componentWillMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const value = await AsyncStorage.getItem("@auth_token");
      const emailAddress = await AsyncStorage.getItem("@email_address");
      const totalMemory = DeviceInfo.getTotalMemory();
      const usedMemory = DeviceInfo.getUsedMemory();
      // console.log(totalMemory);
      // console.log(usedMemory);
      const splitToken = value.split("@");
      const userId = splitToken[0];
      if (value !== null) {
        this.setState(
          {
            Token: value,
            UserId: userId,
            emailId: emailAddress,
          },
          () => { }
        );
        logEvent("about_us_entry", {});
      }
    } catch (error) {
      // Error retrieving data
    }
  };

  handlePress = async(item) => {
    logEvent("home_page_click", {});
    if (await InAppBrowser.isAvailable()) {
      try {
        const result = await InAppBrowser.open(item, helpers.inAPPbrowserOption)
      } catch (error) {
        await InAppBrowser.close()
      }
    } else {
      Linking.openURL(item)
    }
  };

  onChangeCategoryText = (e) => {
    this.setState(
      {
        selectCategoryData: e,
        disableText: true,
      },
      () => {
        // console.log(this.state.selectCategoryData);
      }
    );
  };

  submitFeedback = () => {
    if (this.state.text == "") {
      alert("Please enter your feedback!");
      return;
    }
    let feedbackType = 0;
    const deviceType = DeviceInfo.getBrand();
    const deviceModel = DeviceInfo.getModel();
    const totalMemory = DeviceInfo.getTotalMemory();
    const usedMemory = DeviceInfo.getUsedMemory();
    // console.log(totalMemory - usedMemory);

    logEvent("submit_user_feedback", {
      feedback_type: this.state.selectCategoryData,
    });
    if (this.state.selectCategoryData.value === "Application Help") {
      feedbackType = 1;
    } else if (this.state.selectCategoryData.value === "Application Error") {
      feedbackType = 2;
    } else if (this.state.selectCategoryData.value === "Feature Request") {
      feedbackType = 3;
    }
    if (this.state.selectCategoryData === "") {
      alert("Select feedback type");
    } else if (this.state.text === "") {
      alert("Enter feedback to continue");
    } else {
      const url = apiPaths.feedBack;
      const headers = {
        Accept: "application/json",
        Authorization: this.state.Token,
      };
      const body = {
        user_feedback: this.state.text,
        user_id: this.state.UserId,
        feedback_type: feedbackType,
        email_id: this.props.userData.email_address,
        device_type: deviceType + " " + deviceModel,
        free_memory_data: totalMemory - usedMemory,
      };

      Network(url, "POST", body, headers).then((res) => {
        this.setState({
          text: "",
          disableText: false,
        });
        alert(res.status);
      });
    }
  };

  getContent = (content) => {
    if (content == 1) {
      return (
        <>
          <Text style={styles.title}>Mission</Text>
          <Text style={styles.description}>
            To become Immigration's most wholesome solution by empowering
            individuals to make informed choices. Anyone who aspires to
            immigrate, deserves to Immigreat. We want to be the bridge between
            the aspirants and their dreams
          </Text>
        </>
      );
    } else if (content == 2) {
      return (
        <>
          <Text style={styles.title}>Our Values</Text>
          <Text style={styles.description}>
            At Immigreat, we believe that every stakeholder - both internal and
            external - deserves to have a genuine and seemless experience
            resulting in the addition of meaningful value.
          </Text>
        </>
      );
    } else {
      return (
        <>
          <Text style={styles.title}>Vision</Text>
          <Text style={styles.description}>
            Our vision is to be the world's most integrated, advanced and
            reliable immigration platform. We aim to be the most secure and
            trustworthy source of information for everyone, by leveraging smart
            technology to propel the world closer toward actualizing their
            goals. We want to serve our customers to our best and to be prompt
            in our services
          </Text>
        </>
      );
    }
  };

  render() {
    var image_height;
    if (height < width) {
      image_height = 300;
    } else {
      image_height = height / 2.6;
    }
    const disableEnableTextBox = this.state.disableText
      ? styles.disableLoginView
      : styles.enableLoginView;

    return (
      <View style={{ flex: 1, backgroundColor: "#0F1143" }}>
        <HeaderComponent
          showNotificationIcon={false}
          showback={true}
          screenTitle="About Us"
        />
        <ScrollView>
          <TouchableOpacity activeOpacity={1}>
            <View style={styles.contentView}>
              <Image
                source={Platform.OS == "web" ? aboutImg1 : Images.aboutImg1}
                style={styles.aboutImg1}
              />
              {Platform.OS == "web" && !mobilBrower() ? (
                <View style={styles.rightView}>{this.getContent(1)}</View>
              ) : (
                this.getContent(1)
              )}
            </View>
            <View style={styles.contentView}>
              {mobilBrower() || Platform.OS !== "web" ? (
                <Image
                  source={Platform.OS == "web" ? aboutImg2 : Images.aboutImg2}
                  style={styles.aboutImg1}
                />
              ) : (
                <View />
              )}
              {Platform.OS == "web" && !mobilBrower() ? (
                <View style={[styles.rightView, { marginLeft: 15 }]}>
                  {this.getContent(2)}
                </View>
              ) : (
                this.getContent(2)
              )}
              {Platform.OS == "web" && !mobilBrower() ? (
                <Image
                  source={Platform.OS == "web" ? aboutImg2 : Images.aboutImg2}
                  style={styles.aboutImg1}
                />
              ) : (
                <View />
              )}
            </View>
            <View style={styles.contentView}>
              <Image
                source={Platform.OS == "web" ? aboutImg3 : Images.aboutimg3}
                style={styles.aboutImg1}
              />
              {Platform.OS == "web" && !mobilBrower() ? (
                <View style={styles.rightView}>{this.getContent(3)}</View>
              ) : (
                this.getContent(3)
              )}
            </View>
          </TouchableOpacity>
          <Text style={styles.socialLinkText}>Social Links</Text>
          <View style={styles.socialLinkView}>
            <TouchableOpacity
              onPress={() =>
                this.handlePress(
                  "https://apps.apple.com/in/app/immigreat/id1577394991"
                )
              }
              style={styles.socailbuttons}
            >
              <Image
                source={Platform.OS == "web" ? appstore : Images.appstore}
                style={styles.socailIconStyles}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                this.handlePress(
                  "https://play.google.com/store/apps/details?id=global.immigreat.app&hl=en_CA&gl=US"
                )
              }
              style={styles.socailbuttons}
            >
              <Image
                source={Platform.OS == "web" ? Playstore : Images.Playstore}
                style={styles.socailIconStyles}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                this.handlePress(
                  "https://www.instagram.com/immigreat_official/"
                )
              }
              style={styles.socailbuttons}
            >
              <Image
                source={Platform.OS == "web" ? insta : Images.insta}
                style={styles.socailIconStyles}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                this.handlePress("https://www.facebook.com/immigreatplatform/")
              }
              style={styles.socailbuttons}
            >
              <Image
                source={Platform.OS == "web" ? fb : Images.fb}
                style={styles.socailIconStyles}
              />
            </TouchableOpacity>
          </View>
          <Text style={styles.socialLinkText}>Give us a Feedback</Text>
          <View style={styles.inputBox}>
            <DropDownPicker
              items={categoryData}
              placeholderStyle={styles.placeholder}
              containerStyle={styles.dropDownContainer}
              style={{
                backgroundColor: "white",
                // alignSelf: "center",
                borderWidth: 0,
                borderColor: "transparent",
              }}
              itemStyle={{
                justifyContent: "flex-start",
              }}
              selectedLabelStyle={{ color: "black" }}
              placeholder="Select Feedback type"
              zIndex={5000}
              dropDownMaxHeight={300}
              dropDownStyle={{ backgroundColor: "#fafafa" }}
              onChangeItem={this.onChangeCategoryText}
            />
          </View>
          <View style={styles.textArea}>
            <TextInput
              placeholder="Enter your Feedback"
              multiline
              numberOfLines={6}
              value={this.state.text}
              onChangeText={(e) => this.setState({ text: e })}
              style={styles.textInputStyle}
              placeholderTextColor="#000"
            />
          </View>
          <TouchableOpacity
            onPress={() => this.submitFeedback()}
            style={styles.submitButton}
          >
            <Text style={styles.submitText}>Submit</Text>
          </TouchableOpacity>
        </ScrollView>
        {Platform.OS == "web" && !mobilBrower() ? (
          <WebFooter footerColor="white" textColor="black" />
        ) : (
          <View />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.authReducer.loginUser,
  userData: state.userReducer.getUser.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
