import Images from "../../../../../common/images";

export const EXPLORER_ITEMS = [
  {
    title: "Quick Visa\nSearch",
    description: "Look up visas and their basic information",
    img: Images.quickSearchIcon,
    key: "quick_search",
  },
  /*{
    title: "Immipath Search",
    description: "Your personalised Path Search - Compare, analyse and shortlist your opportunities",
    img: Images.immipatSearch,
    key: "university_search",
    onlyForMob: true,
    hideForWeb: true
  },*/
  {
    title: "University Search",
    description: "Your personalised University Search - Compare, analyse and shortlist your opportunities",
    img: Images.immipatSearch,
    key: "university_search",
    //onlyForMob: true,
    allowGuest: false,
    //hideForWeb: true
  },
  {
    title: "Opportunity Report",
    description: "View your personalized opportunity report!",
    img: Images.jobforyouIcon,
    key: "opportunity_report",
    allowGuest: true,
    onlyForMob: true,
    hideForWeb: true
  },
  {
    title: "City Facts Comparator",
    description: "Compare cities with various metrics to choose from",
    img: Images.cityfactsLogo,
    key: "city_facts",
  },
  /*{
    title: "Credit Builder Guide",
    description: "Find out how to build your credit score",
    img: Images.creditBuilderIcon,
    key: "credit_builder",
    onlyForMob: false,
    hideForWeb: true
  },*/
  {
    title: "PR Points Calculator",
    description: "Calculate PR points for multiple countries in one go",
    img: Images.prcalImg,
    key: "pr_points",
  },
  /*{
    title: "Jobs for you",
    description: "Search and find jobs",
    img: Images.jobforyouIcon,
    key: "jobs",
    onlyForMob: true,
    hideForWeb: true
  },
  {
    title: "Language Explorer",
    description: "Learn important phrases",
    img: Images.languageBanner,
    key: "language",
    onlyForMob: true,
    hideForWeb: true
  },*/
  {
    title: "Cost of Living\nCalculator",
    description: "Personalised to a new level",
    img: Images.costoflivingicon,
    key: "cost_of_living"
  },
];
