import React, { useState } from 'react'
import {
    StyleSheet, Text, View, TouchableOpacity, Image
} from "react-native";
import { useHistory } from 'react-router-dom';
import Images from '../common/images';
import DownloadModal from "./DownloadModal/DownloadModal";

export default function MobileBottomBar() {
    const { push, location } = useHistory();
    const [showDownloadModal, setShowDownloadModal] = useState(false);

    const handleIconPress = (path) => {
        // if(path == "adviceChannel"){
        //     setShowDownloadModal(true);
        //     return false;
        // }
        push(`/${path}`)
    }

    const TABS = [
        { path: "dashboard", icon: Images.tabHomeIcon, displayName: "Home" },
        { path: "pathExp", icon: Images.tabPathExpIcon, displayName: "Path Explorer" },
        { path: "media", icon: Images.tabMediaIcon, displayName: "Media" },
        { path: "adviceChannel", icon: Images.tabAdviceIcon, displayName: "Advice" },
        //{ path: "store", icon: Images.tabStoreIcon, displayName: "Store" },
    ]

    return (
        <View style={styles.bottombar}>
            {TABS.map(({ path, icon, style = {}, displayName },index) => (
                <TouchableOpacity key={index+"t"} style={styles.tabContainer} onPress={() => handleIconPress(path)}>
                    {location.pathname === `/${path}` ? <View style={styles.higlight} />:null}
                    <Image source={icon} style={[styles.bottomIcon]} />
                    <Text style={styles.tabname}>{displayName}</Text>
                </TouchableOpacity>
            ))}
            {showDownloadModal ? (
            <DownloadModal closeModal={() => setShowDownloadModal(false)} />
        ) : null}
        </View>
    )
}

const styles = StyleSheet.create({
    bottombar: {
        position: 'fixed',
        width: '100%',
        height: "8%",
        bottom: 0,
        zIndex:100,
        flexDirection: 'row',
        padding: 5,
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#0F1143',
    },
    higlight:{
        position: 'absolute',
        top:-8,
        width:"100%",
        height:5,
        backgroundColor:"#F1AB32"

    },
    tabContainer: {
        position: "relative",
        flex: 1,
        height:"100%",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    tabname:{
        fontSize: 11,
        marginTop:5,
        color:"#fff"
    },
    bottomIcon: {
        height: 20,
        width: 20
    },
})