import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Platform,
  Modal,
} from "react-native";
import { Images } from "../common";
import * as Animatable from 'react-native-animatable';
import { mobilBrower } from '../common/helpers';
import { heightPercentageToDP, widthPercentageToDP } from "../common/responsiveScreen";

function GetMoreCityModal(props) {
  return (
    <Modal transparent visible={props.visible}>
      <View style={styles.mainView}>
        <Animatable.View animation={"slideInUp"} style={styles.innerView}>
          {!props.factsPurchased && props.insufficientBalance == "" ? (
            <View>
              <View style={styles.infoView}>
                {props.title ? (
                  <Text style={styles.textTitle}>{props.title}</Text>
                ) : (
                  <View />
                )}
                <View
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Text
                    style={[
                      styles.text,
                      {
                        fontSize:
                          props.type === "share"
                            ? 20
                            : heightPercentageToDP("1.8%"),
                      },
                    ]}
                  >
                    {props.description}
                  </Text>
                  {props.path == "advice" ? (
                    <View>
                      <Text style={[styles.text, { fontWeight: "600" }]}>
                        {"\n" + props.question}
                      </Text>
                      <Text
                        style={[
                          styles.text,
                          { fontWeight: "600", marginTop: 5 },
                        ]}
                      >
                        Speciality: {props.selectedAdviceType}
                      </Text>
                      <Text
                        style={[
                          styles.text,
                          { fontWeight: "600", marginTop: 5 },
                        ]}
                      >
                        Categorty: {props.selectedCategory}
                      </Text>
                    </View>
                  ) : (
                    <View />
                  )}
                </View>
                <View
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Text
                    style={[
                      styles.text,
                      { fontSize: heightPercentageToDP("2%") },
                    ]}
                  >
                    {props.price}
                  </Text>
                  <Image
                    source={Images.immibuck}
                    style={{
                      height: 38,
                      width: 38,
                      marginLeft: 7,
                      top: 12,
                      alignItems: "center",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  />
                </View>
              </View>
              <View style={styles.buttonView}>
                {props.type !== "share" ? (
                  <TouchableOpacity
                    onPress={() => props.handleButton1()}
                    style={[styles.buttons, { marginRight: 10 }]}
                  >
                    <Text style={styles.buttonsText}>{props.button1}</Text>
                  </TouchableOpacity>
                ) : (
                  <View />
                )}
                <TouchableOpacity
                  onPress={() => props._handleModalClick()}
                  style={[styles.buttons, { marginLeft: 10 }]}
                >
                  <Text style={styles.buttonsText}>{props.button2}</Text>
                </TouchableOpacity>
              </View>
            </View>
          ) : props.insufficientBalance !== "" ? (
            <View style={{ alignItems: "center" }}>
              <Text style={[styles.text, { padding: 15 }]}>
                {props.insufficientBalance}
              </Text>
              <TouchableOpacity
                onPress={() => props._handleModalClick()}
                style={[
                  styles.buttons,
                  { marginBottom: 10, paddingLeft: 10, paddingRight: 10 },
                ]}
              >
                <Text style={styles.buttonsText}>Close</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={{ alignItems: "center" }}>
              <Text style={[styles.text, { padding: 15 }]}>
                {props.successMessage}
              </Text>
              <TouchableOpacity
                onPress={() => props._handleModalClick()}
                style={[
                  styles.buttons,
                  { marginBottom: 10, paddingLeft: 10, paddingRight: 10 },
                ]}
              >
                <Text style={styles.buttonsText}>Close</Text>
              </TouchableOpacity>
            </View>
          )}
        </Animatable.View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  mainView: {
    height: heightPercentageToDP(100),
    width: widthPercentageToDP(100),
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonsText: {
    padding: 10,
    paddingLeft: 12,
    paddingRight: 12,
    color: "white",
    fontWeight: "800",
    fontSize: heightPercentageToDP("1.7%"),
  },
  buttons: {
    backgroundColor: "#030135",
    borderRadius: 10,
  },
  infoView: {
    // flexDirection: 'row',
    alignItems: "center",
    padding: 15,
  },
  buttonView: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    justifyContent: "center",
    marginBottom: 15,
  },
  innerView: {
    backgroundColor: "white",
    borderWidth: 1,
    width: Platform.OS == "web" && !mobilBrower() ? "40%" : "80%",
    borderRadius: 10,
    borderColor: "#BDBDBD",
  },
  text: {
    marginRight: 5,
    fontWeight: "700",
    fontSize: 15,
    textAlign: "center",
  },
  textTitle: {
    padding: heightPercentageToDP("2%"),
    fontWeight: "500",
    fontSize: heightPercentageToDP("2%"),
    textAlign: "center",
  },
});
export default GetMoreCityModal;
