import React from "react";
import { StyleSheet, Alert } from "react-native";

import { Provider } from "react-redux";
import AsyncStorage from "@react-native-community/async-storage";
import moment from "moment";
import NavigationService from "./src/navigation/NavigationService";
import { Router, Route } from "react-router-dom";
import routes from "./src/navigation/webPaths";
import PreLoader from "./src/pages/Preloader";
import persist from "./src/config/store";
import { PersistGate } from "redux-persist/integration/react";
import MobileBottomBar from "./src/components/MobileBottomBar.web";
import { mobilBrower } from "./src/common/helpers";
import { initializeApp } from "firebase/app";
import DownloadModal from "./src/components/DownloadModal/DownloadModal";
import { history } from "./src/common/browserHistory";
import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
import ScrollToTop from "./src/common/ScrollToTop";

const persistStore = persist();
class App extends React.Component {
  constructor(props) {
    super(props);

    //  this.notif = new NotifService(
    //    this.onRegister.bind(this),
    //    this.onNotif.bind(this),
    //  );
    this.state = {
      showDownloadModal: false,
      modalContent: '',
      showBack: true,
      fromDeepLink: false,
      deepLink: null
    }
  }

  async componentDidMount() {
    //  SplashScreen.hide();
    if (mobilBrower()) {
      let url = window.location.href;
      if (url.split('/')[4]) {
        this.setState({ modalContent: 'Download the mobile app to view this report', showBack: false, fromDeepLink: true, deepLink: url })
      }
    }
    let firebaseConfig = {
      apiKey: "AIzaSyB85KfCJJf56ssxk8nRVaY8hyMZNjJIf0Q",
      authDomain: "immigreat-qa.firebaseapp.com",
      databaseURL: "https://immigreat-qa.firebaseio.com",
      projectId: "immigreat-qa",
      storageBucket: "immigreat-qa.appspot.com",
      messagingSenderId: "145651957140",
      appId: "1:145651957140:web:81ec25d2ae758c40a42381",
      measurementId: "G-4KQ51PS396",
    };
    initializeApp(firebaseConfig);
    await AsyncStorage.setItem("lastOpenedAppTime", JSON.stringify(new Date()));
    let lastOpenedTime = await AsyncStorage.getItem("lastOpenedAppTime");
    let numberOfArticleShare = await AsyncStorage.getItem(
      "NumberOfArticleShare"
    );
    if (numberOfArticleShare) {
      numberOfArticleShare = JSON.parse(numberOfArticleShare);
    }
    numberOfArticleShare = numberOfArticleShare
      ? parseInt(numberOfArticleShare)
      : 0;
    lastOpenedTime = moment(JSON.parse(lastOpenedTime));
    let currentDate = moment(new Date());
    let futureDate = lastOpenedTime.add(7, "d");
    let dayDiff = currentDate.diff(lastOpenedTime, "days");
    if (dayDiff > 7 && numberOfArticleShare < 10) {
      //  PushNotification.localNotificationSchedule({
      //    channelId:'default_notification_channel_id',
      //    message: "Immibucks are waiting for you! Share an article now to get the weekly reward!", // (required)
      //    date: new Date(futureDate),
      //    allowWhileIdle: false, // (optional) set notification to work while on doze, default: false
      //  });
    }
    this.setState({ showDownloadModal: true })
  }

  onRemoteNotification = (notification) => {
    const isClicked = notification?.getData().userInteraction === 1;

    if (isClicked) {
      // Navigate user to another screen
    } else {
      // Do something else with push notification
    }
  };

  renderCurrentPath({ component: Page, exact, label, path, hideDownloadModal, isPrivate, showTabBar }) {
    return (
      <>
        <Page />
        {mobilBrower() && showTabBar && <MobileBottomBar />}
        {this.state.showDownloadModal && !hideDownloadModal && mobilBrower() ? (
          <DownloadModal
            showMobileAppInfo={true}
            deepLink={this.state.deepLink}
            modalContent={this.state.modalContent}
            fromDeepLink={this.state.fromDeepLink}
            showBack={this.state.showBack}
            closeModal={() => this.setState({ showDownloadModal: false })} />
        ) : null}
      </>
    )
  }

  render() {
    console.log('====>', this.state.showDownloadModal)
    return (
      <Provider store={persistStore.store}>
        <PersistGate loading={null} persistor={persistStore.persistor}>
          <Router forceRefresh={false} history={history}>
            <ScrollToTop />
            <CacheSwitch>
              {routes.map(
                (item) =>
                  item.ignoreCache ?
                    <Route
                      exact={true}
                      key={item.label}
                      path={item.path}
                    >
                      {this.renderCurrentPath(item)}
                    </Route> : (
                      <CacheRoute
                        exact={true}
                        key={item.label}
                        path={item.path}
                      >
                        {this.renderCurrentPath(item)}
                      </CacheRoute>
                    )
              )}
              <Route exact path="/" component={PreLoader} />
            </CacheSwitch>
          </Router>
        </PersistGate>
      </Provider>
    );
  }

  onRegister(token) {
    this.setState({ registerToken: token.token, fcmRegistered: true });
  }

  onNotif(notif) {
    setTimeout(() => {
      NavigationService.navigate("NewProfileScreen", {
        notificationClicked: true,
      });
    }, 500);
    //
  }

  handlePerm(perms) {
    Alert.alert("Permissions", JSON.stringify(perms));
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#455a64",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default App;
