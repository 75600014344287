export const handleMediatabNext = () => ({
  type: 'MEDIA_TAB_NEXT_PRESSED',
});

export const handleDashoardProfileFinish = () => ({
  type: 'DASHOBOARD_PROFILE_FINISH',
});

export const handleResultComparisonNext = () => ({
  type: 'RESULT_COMPARISON_NEXT',
});

export const showBottomNavTutorial = () => ({
  type: 'SHOW_BOTTOM_TAB_TUTORIAL',
});