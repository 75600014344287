import { StyleSheet, StatusBar, Dimensions, Platform } from "react-native";
import { heightPercentageToDP } from "react-native-responsive-screen";
import { isWeb, mobilBrower } from "../../../../common/helpers";
const { height, width } = Dimensions.get('window');

export const styles = StyleSheet.create({
    mainView: {
      height: height,
      width: width,
      backgroundColor: "#222222c7",
      alignItems: "center",
      justifyContent: "center",
    },
    innerView: {
      width: "90%",
      backgroundColor: "#FFF",
      padding: 15,
      paddingHorizontal: 8,
      borderRadius: 15,
      height: "auto",
    },
    mainItem: {
      borderColor: "rgb(230,230,230)",
      borderRadius: 10,
      borderWidth: 1.5,
      padding: 10,
      margin: 5,
    },
    selectedItem: {
      borderWidth: 1,
      borderColor: '#F1AB32'
    },
    mainStageText: {
      color: 'black'
    },
    notificationHeaderText: {
      fontSize: isWeb() && !mobilBrower() ? 26 : heightPercentageToDP("3%"),
      fontWeight: "bold",
      alignSelf: "flex-start",
    },
    header: {
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 25,
    },
    down: {
      height: 15,
      width: 15,
      resizeMode: 'contain',
    },
    upArrow: {
      height: 15,
      width: 15,
      resizeMode: 'contain',
      transform: [{ rotate: '180deg' }] 
    }
})