import { StyleSheet } from "react-native";
import { family } from "../../common";
import { responsiveText } from "../../common/normalize";

export const styles = StyleSheet.create({
  confirmBut: {
    alignItems: "center",
    justifyContent: "center",
    padding: 15,
    width: "80%",
    backgroundColor: "#030135",
    borderRadius: 10,
    marginTop: 15,
    alignSelf: "center",
    marginBottom: 15,
  },
  title: {
    fontSize: responsiveText(20),
    color: "#030135",
    marginTop: 15,
    alignSelf: "center",
    marginBottom: 15,
    fontFamily: family.semibold,
  },
  countiesView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 0,
    marginBottom: 10,
    alignSelf: "center",
    justifyContent: "space-between",
  },
  innerView: {
    width: "90%",
    alignSelf: "center",
    padding: 5,
    borderRadius: 10,
  },
  note: {
    alignSelf: "center",
    textAlign: "center",
    width: "90%",
    marginBottom: 5,
    fontFamily: family.semibold,
  },
  countryIcon: {
    height: 50,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: "center",
  },
  flagbuttons: {
    height: 80,
    alignItems: "center",
  },
});
