import React from 'react';
import { Text, View } from 'react-native';
import { BarChart, LabelList, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { helpers } from '../../../../common';
import { mobilBrower } from '../../../../common/helpers';
import { styles } from './JobChart.styles';

function JobChart({ selectedCurrency, data = [] }) {

    return (
        <View style={styles.main}>
            <Text style={styles.headerText}>
                Salary Insights
            </Text>
            <ResponsiveContainer width={mobilBrower() ? "100%" : "80%"} height="100%">
                <BarChart
                    // width={500}
                    // height={300}
                    data={data}
                    margin={mobilBrower() ? {} : {
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis axisLine={false} tickLine={false} fontSize={mobilBrower() ? 10 : 16} stroke="#F1AB32" dataKey="country" />
                    <YAxis tickCount={4} axisLine={false} fontSize={mobilBrower() ? 10 : 16} stroke="#F1AB32" dataKey="total" tickFormatter={(value) => helpers.getCurrencySymbol(selectedCurrency) + (value ? helpers.convertFormat(
                        Math.round(
                            helpers.currencyConverter(selectedCurrency, value)
                        ),
                        selectedCurrency
                    ) : 0)} tickLine={false} />
                    <Tooltip formatter={(value) => helpers.getCurrencySymbol(selectedCurrency) + helpers.convertFormat(
                        Math.round(
                            helpers.currencyConverter(selectedCurrency, value)
                        ),
                        selectedCurrency
                    )} />
                    <Legend iconSize={19} fontSize={mobilBrower() ? 10 : 16} stroke="#F1AB32" formatter={(value) => value.toUpperCase()} verticalAlign="top" wrapperStyle={{ lineHeight: '40px', fontSize: mobilBrower() ? 10 : 16 }} />
                    <Bar dataKey="tax" stackId="a" fill="red" />
                    <Bar dataKey="expenses" stackId="a" fill="green" />
                    <Bar dataKey="income" stackId="a" fill="blue"  >
                        <LabelList stroke="#F1AB32" fontSize={mobilBrower() ? 10 : 16} fill="#F1AB32" dataKey="total" formatter={(value) => helpers.getCurrencySymbol(selectedCurrency) + helpers.convertFormat(
                            Math.round(
                                helpers.currencyConverter(selectedCurrency, value)
                            ),
                            selectedCurrency
                        )} position="top" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </View>
    )
}

export default JobChart;