import React from 'react';
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native';

function Collapsable({ title, extra = null, onPress = () => { }, color, collapsed = true, children = null }) {
    return (
        <View style={[styles.container, {
            borderColor: color,
            shadowColor: color
        }]}>
            <TouchableOpacity onPress={onPress}>
                <View style={styles.titleContainer}>
                    <Text style={styles.title}>{title}</Text>
                    <View>
                        {extra}
                    </View>
                </View>
            </TouchableOpacity>
            {collapsed ? null :
                <View>
                    {children}
                </View>
            }
        </View>
    )
}

const styles = StyleSheet.create({
    title: {
        fontSize: 20,
        fontWeight: "bold"
    },
    titleContainer: {
        flexDirection: "row",
        paddingHorizontal: "5%",
        paddingVertical: "2.5%",
        alignItems: "center",
        justifyContent: "space-between"
    },
    container: {
        flex: 1,
        paddingHorizontal: "5%",
        paddingVertical: "1.5%",
        borderWidth: 0.2,
        margin: 5,
        marginVertical: 10,
        borderRadius: 25,
        zIndex: 4,
        elevation: 4,
        shadowRadius: 10,
        shadowOffset: {
            width: 0,
            height: 4
        }
    }
})

export default Collapsable;