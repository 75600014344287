import helpers from "../common/helpers";
const DEV_BASE_URL = "https://develop-dot-immigreat-qa.el.r.appspot.com";
const PRO_BASE_URL = "https://immigreat-qa.appspot.com";

export const getBaseURLFrom = (url) => {
  const baseUrl = global.IS_TEST_SERVER ? DEV_BASE_URL : PRO_BASE_URL;
  return baseUrl.concat(url);
};

export const Network = (url, method, body = null, headers = {}) => {
  try {
    if (headers["Authorization"] && headers["Authorization"].includes("undefined")) {
      throw new Error("token is undefined");
    }
    const endPoint = getBaseURLFrom(url);
    const reqBody = body ? JSON.stringify(body) : null;

    const fetchParams = { method, headers };

    if ((method === "POST" || method === "PUT") && !reqBody) {
      throw new Error("Request body required");
    }

    if (reqBody) {
      fetchParams.headers["Content-type"] = "application/json";
      fetchParams.body = reqBody;
    }
    return fetch(endPoint, fetchParams).then(res => {
      if (res.status == 401 && headers["Authorization"]) {
        helpers.handleSessionExpiry();
        return Promise.reject({ status: "Not authorised"})
      } else if (res.status >= 500) {
        return { status: "failed", e: "Server Error" }
      }
      return res.json();
    })
  } catch (e) {
    return Promise.resolve({ status: "failed", e: e })
  }
}

export const api = async (url, method, body = null, headers = {}) => {
  try {
    const endPoint = getBaseURLFrom(url);
    const reqBody = body ? JSON.stringify(body) : null;

    const fetchParams = { method, headers };

    if ((method === "POST" || method === "PUT") && !reqBody) {
      throw new Error("Request body required");
    }

    if (reqBody) {
      fetchParams.headers["Content-type"] = "application/json";
      fetchParams.body = reqBody;
    }

    const fetchPromise = fetch(endPoint, fetchParams);
    const timeOutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject("Request Timeout");
      }, 3000);
    });

    const response = await Promise.race([fetchPromise, timeOutPromise]);

    return response;
  } catch (e) {
    return e;
  }
}

export const fetchApi = async (
  url,
  method,
  body,
  statusCode,
  token = null,
  loader = false
) => {
  try {
    const headers = {};
    const result = {
      token: null,
      success: false,
      responseBody: null,
    };
    if (token) {
      headers["x-auth"] = token;
    }

    const response = await api(url, method, body, headers);

    if (response.status === statusCode) {
      result.success = true;

      if (response.headers.get("x-auth")) {
        result.token = response.headers.get("x-auth");
      }

      if (response.headers.get("x-auth")) {
        result.token = response.headers.get("x-auth");
      }

      let responseBody;
      const responseText = await response.text();

      try {
        responseBody = JSON.parse(responseText);
      } catch (e) {
        responseBody = responseText;
      }

      result.responseBody = responseBody;
      return result;
    }

    let errorBody;
    const errorText = await response.text();

    try {
      errorBody = JSON.parse(errorText);
    } catch (e) {
      errorBody = errorText;
    }

    result.responseBody = errorBody;

    // console.log(result);

    throw result;
  } catch (error) {
    return error;
  }
};

export const apiPaths = {
  createUser: "/users/newuser?",
  forgotPassword: "/users/forgotpassword?",
  checkOtp: "/users/checkotp?",
  resetPassword: "/users/resetpassword?",
  userLogin: "/users/userlogin?",
  logout: "/users/userlogout?",
  topNews: "/news/gettopnews?",
  resolveChat: "/users/",
  chat_history: "/users/",
  occupation_languages: "/data/get_dropdown_options",
  topStories: "/news/gettopstories?",
  immipath_search: "/immipaths/search?",
  eligibilityQuestions: "/data/eligib_qns",
  currencyRefresh: "/data/currencyrefresh",
  feedBack: "/help/feedback",
  dynamicFacts: "/data/fact_selector_options_data",
  verifyPassword: "/users/",
  getHighlights: "/users/",
  checkRewardMilestone: "/wallet/",
  immibucks: "/users/immibucks",
  getUserReactions: "/users",
  log_news_reactions: "/log_actions/select_news_reaction",
  immipath_path: "/immipaths/paths/",
  test: "/test",
  application_referrals: "/immipaths/application_referrals/",
  jobs: "/users/",
  app_status: "/app_status",
  ref_code: "/",
  resolvequestionnaire: "/immipaths/resolvequestionnaire",
  checkIsAdminUser: "/admin/verify_admin",
  getAdminToken: "/get_admin_token/"
};
