import { combineReducers } from 'redux';

const initialState = {
    immibucks: [],
    immibucks_balance: 0,
    unlocks: [],
    rewardHistory: []
}

const getImmibucksDetails = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_USERS_IMMIBUCKS':
            return {
                ...state,
                immibucks: action.payload
            }
        case 'GET_USERS_IMMIBUCKS_FAIL':
            return {
                ...state,
                immibucks: action.payload
            }
        case 'GET_IMMIBUCK_BALANCE':
            return {
                ...state,
                immibucks_balance: action.payload,
            }
        case 'GET_IMMIBUCK_REWARD':
            return {
                ...state,
                rewardHistory: action.payload
            }
        case 'GET_UNLOCKS':
            return {
                ...state,
                unlocks: action.payload
            }
        default:
            return state
    }
}

export default combineReducers({
    getImmibucksDetails
})
