import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  FlatList
} from "react-native";
import { useSelector } from 'react-redux';
import BullitinModal from "../../../../BullitinModal/BullitinModal";
import { styles as s } from "../section_H.styles";

export default function AvailableCoursesModal({ courses, onClose, visible }) {
  const userDegreeCourseSelections = useSelector(state => state.pathReducer?.getImmipathDetails?.userDegreeCourseSelections)
  const [filteredList, setfilteredList] = useState(userDegreeCourseSelections['Degree'] == 1 ? courses['UndergraduateUG'] : courses['MastersPG']);

  const handleSearch = (text) => {
    let _courses = userDegreeCourseSelections['Degree'] == 1 ? courses['UndergraduateUG'] : courses['MastersPG'];
    const searchByName = _courses.filter((item) => {
      return item.toLowerCase().includes(text.toLowerCase());
    });
    setfilteredList(searchByName)
  }

  return (
    <BullitinModal
      heading=""
      visible={visible}
      onClose={onClose}
      loading={false}
      handleClose={onClose}
      logoFrom="mid"
      hideCloseButton={false}
    >
      <View style={[s.wrapper, { height: "90%" }]}>
        <Text style={s.headerText}>
          {userDegreeCourseSelections['Degree'] == 1 ? 'Bachelors Course' : 'Masters Courses'}
        </Text>
        <View style={s.textinputBox}>
          <TextInput
            placeholder='Search your course'
            onChangeText={handleSearch}
          />
        </View>
        <FlatList
          data={filteredList}
          renderItem={({ item, index }) => {
            return (
              <View style={s.listItem} key={index}>
                <Text style={s.itemText}>
                  {item}
                </Text>
              </View>
            )
          }}
        />
      </View>
    </BullitinModal>
  )
}
