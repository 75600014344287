import React, { useRef, useState } from 'react';
import { ActivityIndicator, FlatList, Image, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Config } from '../../../common';
import Images from '../../../common/images';
import { styles } from "./../quickSearch.styles";
import { useSelector } from 'react-redux';
import { apiPaths, Network } from '../../../service/api';
import helpers, { isWeb, mobilBrower } from '../../../common/helpers';
import VisaDetailsModal from './VisaDetailed';

const VisasList = ({ visas = [], onBackPress = () => { } }) => {
    const [loading, setLoading] = useState(false);
    const [showDetailed, setShowDetailed] = useState(false);
    const [selectedVisa, setSelectedVisa] = useState({});
    const [filteredList, setFilteredList] = useState(visas);
    const [filterText, setFilterText] = useState("");
    const userDetails = useSelector(
        (state) => state.userReducer.getUser.userDetails
    );
    const authData = useSelector((state) => state.authReducer.authData);
    const timeout = useRef(null)

    const getCountryName = (id) => {
        let country = Config.intrestcountry.filter((s) => s.code == id);
        return country[0]?.value;
    }

    const handleBackPress = () => {
        if (showDetailed) {
            setShowDetailed(false);
        } else {
            onBackPress()
        }
    }

    const getGoalNames = (id) => {
        if (id === 1) {
            return 'Study'
        } else if (id === 2) {
            return 'Work'
        } else if (id === 3) {
            return 'Residency'
        } else if (id === 4) {
            return 'Tourism'
        }
    }

    const getImage = (id) => {
        let image = '';
        switch (id) {
            case 1:
                image = Images.usFlag
                break;
            case 2:
                image = Images.CANADAFlag
                break;
            case 3:
                image = Images.Ausflag
                break;
            case 4:
                image = Images.singaporeFlag
                break;
            case 5:
                image = Images.UKFLag
                break;
            case 6:
                image = Images.UAEFLAG
                break;
            case 7:
                image = Images.GermanyFlag
                break;
            case 8:
                image = Images.netherlanFlag
                break;
            case 9:
                image = Images.NZFlag
                break;
            case 10:
                image = Images.JapanFlag
                break;
            default:
                break;
        }
        return image
    }

    const handleOnTextInput = (value) => {
        setFilterText(value);
        setLoading(true)
        if (value.length) {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                let filteredVisas = visas.filter(visa => visa.immipath_name?.includes(value))
                setFilteredList(filteredVisas)
                setLoading(false)
            }, 2000)
        } else {
            clearTimeout(timeout.current);
            setFilteredList(visas)
            setLoading(false)
        }

    }

    const getVisaDetails = async (item) => {
        const headers = {
            Accept: "application/json",
            Authorization: `${userDetails?.userid}@${authData.token}`,
        };
        Network(
            apiPaths.immipath_path + `${item.immipath_id}`,
            "GET",
            null,
            headers
        ).then((res) => {
            if (res.status === "Success") {
                res.path_details.goalName = helpers
                    .getGoalName(res.path_details.goal_ids)
                    .join(", ");
                setSelectedVisa(res.path_details);
                setShowDetailed(true)
            }
        });
    };

    const Visa = ({ item }) => (
        <TouchableOpacity style={styles.visaItem} disabled={isWeb() && !mobilBrower()} activeOpacity={1}>
            <Image source={getImage(item.country_id)} style={{ height: "100%", width: "45%", resizeMode: "stretch" }} />
            <View style={{ flex: 1, paddingHorizontal: 15, paddingVertical: 10 }}>
                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                    {item.goal_ids.map((goal, index) => (
                        <View key={"t" + index} style={[styles.tagView, { marginLeft: index == 0 ? 0 : 10 }]}>
                            <Text style={styles.tagText}>
                                {getGoalNames(goal)}
                            </Text>
                        </View>
                    ))}
                </View>
                <View style={{ flex: 1, justifyContent: "space-between" }}>
                    <View>
                        <Text style={styles.visaTitle} numberOfLines={2}>{item.immipath_name}</Text>
                        <Text >{getCountryName(item.country_id)}</Text>
                    </View>
                    <TouchableOpacity style={styles.takeViewButton} onPress={() => getVisaDetails(item)}>
                        <Text style={styles.takeViewText}>View</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </TouchableOpacity>
    )

    const renderListView = () => (
        <>
            <Text style={styles.title}>{`${filteredList.length} Visas Found`}</Text>
            <View style={styles.searchContainer}>
                <TextInput
                    key="ss"
                    style={styles.searchInput}
                    value={filterText}
                    onChangeText={handleOnTextInput}
                />
                <View style={{ paddingHorizontal: 15 }}>
                    {loading ? (
                        <ActivityIndicator
                            style={{
                                height: 15,
                                width: 15,
                            }}
                            size="small"
                            color="#0f1143"
                        />
                    ) : (
                        <Image
                            source={Images.blackSearchIcn}
                            style={{ height: 20, width: 20, resizeMode: "contain" }}
                        />
                    )}
                </View>
            </View>
            <View style={{ width: "100%", flex: 1 }}>
                <FlatList
                    data={filteredList}
                    numColumns={mobilBrower() || !isWeb() ? 1 : 2}
                    columnWrapperStyle={mobilBrower() || !isWeb() ? null : { flex: 1, justifyContent: "space-between" }}
                    keyExtractor={(_, index) => index + "v"}
                    showsVerticalScrollIndicator={false}
                    renderItem={({ item, index }) => (
                        <Visa item={item} key={index + "v"} />
                    )}
                />
            </View>
        </>
    )

    return (
        <View style={styles.container}>
            <TouchableOpacity style={{ alignSelf: "flex-start", marginTop: 20 }} onPress={handleBackPress}>
                <Image source={Images.backArrow} style={{ width: 20, height: 20 }} />
            </TouchableOpacity>
            {!showDetailed ?
                renderListView() :
                <VisaDetailsModal visible={true} selectedPath={selectedVisa} onClose={() => { setShowDetailed(false) }} />}
        </View>
    )
}

export default VisasList;