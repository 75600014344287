import React from 'react'
import { Modal, View, TouchableOpacity, Text, StyleSheet, Dimensions, FlatList } from "react-native";
import { heightPercentageToDP } from 'react-native-responsive-screen';
import { family } from "../common";

function CustomerSupportQuesModal({ questions, visible, sendUserResponse }) {
    return (
        <Modal transparent visible={visible} >
            <View style={styles.mainView}>
                <View style={styles.innerView}>
                    <FlatList
                        data={questions?.options}
                        renderItem={({ item, index }) => (
                            <TouchableOpacity onPress={() => sendUserResponse(item, questions, 1)} style={[styles.questions, { marginTop: index == 0 ? 0 : 15 }]}>
                                <Text style={styles.quesText}>
                                    {item}
                                </Text>
                            </TouchableOpacity>
                        )}
                    />
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    mainView: {
        backgroundColor: '#222222c7',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerView: {
        backgroundColor: 'white',
        width: '90%',
        borderRadius: 20,
        padding: 20,
    },
    questions: {
        marginTop: 15,
        borderBottomWidth: 1,
        borderColor :'#BDBDBD'
    },
    quesText:{
        fontFamily: family.semibold,
        color: '#030135',
        paddingBottom: 5,
        fontSize: heightPercentageToDP('1.5%')
    },
})
export default CustomerSupportQuesModal
