import React, { useEffect, useState } from 'react'
import { View, Platform, ScrollView, Text, Image, ImageBackground } from "react-native";
import { TouchableOpacity } from 'react-native-gesture-handler';
import { FlatList } from 'react-navigation';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CompleteProfileModal from "../components/CompleteProfileModal";
import CompleteProfileModalTwo from "../components/CompleteProfileModalTwo";
import { family, Images } from '../../../common';
import NavigationService from '../../../navigation/NavigationService';
import { styles as s } from "./styles";
import { isArray, isEmpty, take } from 'lodash';

let profileInfo = [
  {
    title: "Add Interests",
    alias: 'interests'
  },
  {
    title: "Add Occupations",
    alias: 'occupations'
  },
  {
    title: "Add Languages",
    alias: 'languages'
  },
  {
    title: "Add Lifestyles",
    alias: 'lifestyles'
  },
]

let secondaryFields = [
  {
    title: "Home Country",
    alias: 'country_of_residency'
  },
  {
    title: "Date of Birth",
    alias: 'date_of_birth'
  },
  {
    title: "Gender",
    alias: 'sex'
  },
  {
    title: "Current Stage",
    alias: 'current_stage'
  },
  {
    title: "Marital Status",
    alias: 'marital_status'
  },
  {
    title: "Phone Number",
    alias: 'phone'
  },
];

export default function DiscoverYourselfCard({ isProfileComlpete }) {
  const guestLogin = useSelector((state) => state.pathReducer.getImmipathDetails.guestLogin);
  const userDetails = useSelector((state) => state.userReducer.getUser.userDetails);
  const [ProgressPercentage, setProgressPercentage] = useState(0)
  const [profileProgress, setProfileProgress] = React.useState();
  const [userInfoPopUpVisible, setuserInfoPopUpVisible] = useState(false)
  const [totalMissingInfoLength, settotalMissingInfoLength] = useState(0)
  const [completeProfileModalTwoVisible, setcompleteProfileModalTwoVisible] = useState(false)
  const [missinFields, setmissinFields] = useState([])
  const history = useHistory()

  useEffect(() => {
    getProgressPercentage()
  }, [userDetails, profileProgress])

  React.useEffect(() => {
    if (!guestLogin) {
      let missingFields = [];
      let obj = {
        interests: {
          title: "Interests",
          icons: Images.interestIcon,
          response: [],
        },
        occupations: {
          title: "Occupations",
          icons: Images.occupationIcon,
          response: [],
        },
        languages: {
          title: "Languages",
          icons: Images.languagesIcon,
          response: [],
        },
        lifestyles: {
          title: "Lifestyles",
          icons: Images.lifestylesIcon,
          response: [],
        },
      };

      userDetails && secondaryFields.forEach((q) => {
        if (userDetails[q.alias] == "" || !userDetails[q.alias]) {
          missingFields.push(q);
        }
      });
      setmissinFields(missingFields)
      if (userDetails?.interests) obj.interests.response = userDetails?.interests;

      if (userDetails?.lifestyles)
        obj.lifestyles.response = isArray(userDetails?.lifestyles) ? userDetails?.lifestyles : Object.values(userDetails?.lifestyles);
      if (userDetails?.languages)
        obj.languages.response = Object.values(userDetails?.languages);

      if (userDetails?.occupations)
        obj.occupations.response = Object.values(userDetails?.occupations);

      setProfileProgress(obj);
      let totalMissingInfoLength = 0
      profileInfo.forEach((i) => {
        if (obj[i.alias]?.response?.length == 0) {
          totalMissingInfoLength += 1
        }
      })
      settotalMissingInfoLength(totalMissingInfoLength + missingFields.length)
    }
  }, [userDetails]);

  const handleLogin = () => {
    Platform.OS == "web"
      ? history.push("/login")
      : NavigationService.navigate("Login");
  };

  const getProgressPercentage = () => {
    let percentage = 0;
    const editProfileMap = {
      first_name: userDetails?.first_name,
      last_name: userDetails?.last_name,
      country_of_birth: userDetails?.country_of_birth,
      date_of_birth: userDetails?.date_of_birth,
      email_address: userDetails?.email_address,
      highest_academic_achievement: userDetails?.highest_academic_achievement,
      countries_of_interest: userDetails?.countries_of_interest,
      immigration_interests: userDetails?.immigration_interests,
      sex: userDetails?.sex,
      country_of_residency: userDetails?.country_of_residency,
      interests: profileProgress?.interests?.response?.length,
      occupations: profileProgress?.occupations?.response?.length,
      languages: profileProgress?.languages?.response?.length,
      lifestyles: !isEmpty(profileProgress?.lifestyles?.response) ? Object.entries(profileProgress?.lifestyles?.response).length : null,
      marital_status: userDetails?.marital_status,
      phone: userDetails?.phone,
      current_stage: userDetails?.current_stage
    };

    const objArr = Object.entries(editProfileMap);
    objArr.map((e) => {
      if (e[1]) {
        if (Array.isArray(e[1])) {
          if (e[1]?.length) percentage = percentage + 1 / objArr.length;
        } else {
          percentage = percentage + 1 / objArr.length;
        }
      }
    });

    percentage < 1 ? isProfileComlpete(false) : isProfileComlpete(true)
    setProgressPercentage(percentage * 100);
  };

  const handleLetsDoIt = () => {
    // setuserInfoPopUpVisible(true)
    if (missinFields.length > 0) {
      setuserInfoPopUpVisible(true)
    } else {
      setcompleteProfileModalTwoVisible(true)
    }
  }

  const getstageText = (stage, second) => {
    const temp = stage?.length && stage[0].split('=>')
    return second ? temp ? temp[2] : '' : temp ? temp.length > 1 ? `${temp[0]} => ${temp[1]}` : `${temp[0]}` : ''
  }

  const handleStageEdit = () => {
    setmissinFields([secondaryFields[3]])
    setuserInfoPopUpVisible(true)
  }

  return (
    <View style={s.discovercrd}>
      <Text style={{ fontSize: 25, fontFamily: family.bebas }}>
        DISCOVER
      </Text>
      <Text style={{ fontSize: 32, fontFamily: family.bebas }}>
        YOURSELF
      </Text>
      {
        !guestLogin && ProgressPercentage !== 100 &&
        <Text style={{ fontSize: 16, fontFamily: family.robotoRegular, marginTop: 5 }}>
          {' Fill following information to complete\nYour profile'}
        </Text>
      }
      <View style={s.bottomRow}>
        {
          guestLogin ?
            <>
              <View>
                <Text style={{ fontSize: 16, fontFamily: family.effra }}>
                  Login to access all personalized features
                </Text>
              </View>
            </>
            :
            <>
              <ImageBackground source={Images.profileCompleteBar}
                style={s.profileBar}>
                <Text style={{ fontSize: 20, fontFamily: family.robotoBold }}>
                  {Math.round(ProgressPercentage) + '%'}
                </Text>
                <Text style={{ fontSize: 10, fontFamily: family.robotoBold }}>
                  Profile Completed
                </Text>
              </ImageBackground>
              <View style={{ alignItems: 'flex-start', marginRight: 20, bottom: 15 }}>
                {
                  ProgressPercentage !== 100 ?
                    <>
                      <FlatList
                        data={totalMissingInfoLength > 5 ? take(profileInfo, 3) : profileInfo}
                        style={{ top: 15 }}
                        renderItem={({ item, index }) => {
                          if (profileProgress && profileProgress[item.alias]?.response?.length == 0) {
                            return (
                              <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                                <View style={s.emptyCicle} />
                                <Text style={{ fontSize: 16 }}>
                                  {item.title}
                                </Text>
                              </View>
                            )
                          } else {
                            return null
                          }
                        }}
                      />
                      <FlatList
                        data={totalMissingInfoLength > 5 ? take(missinFields, 2) : missinFields}
                        style={{ top: 15 }}
                        ListFooterComponent={() => (
                          <TouchableOpacity onPress={handleLetsDoIt} style={s.doItbutton}>
                            <Text style={s.ViewButtonText}>
                              Let's do it
                            </Text>
                          </TouchableOpacity>
                        )}
                        renderItem={({ item, index }) => {
                          return (
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                              <View style={s.emptyCicle} />
                              <Text style={{ fontSize: 16 }}>
                                {item.title}
                              </Text>
                            </View>
                          )
                        }}
                      />
                    </>
                    :
                    <View style={{ alignItems: 'flex-end' }}>
                      <Text style={{ fontSize: 10, fontFamily: family.robotoBold, marginBottom: 2, alignSelf: 'flex-start' }}>
                        CURRENT STAGE
                      </Text>
                      <View style={s.currentStageBox}>
                        <Text style={{ fontSize: 14, fontFamily: family.regular, padding: 5, paddingHorizontal: 23, color: 'white' }}>
                          {
                            getstageText(userDetails?.current_stage, false) || null
                          }
                        </Text>
                        <Text style={{ fontSize: 16, fontFamily: family.robotoBold, padding: 5, paddingHorizontal: 23, color: 'white', paddingVertical: 0, paddingBottom: 5 }}>
                          {
                            getstageText(userDetails?.current_stage, true) || null
                          }
                        </Text>
                      </View>
                      <TouchableOpacity onPress={handleStageEdit} style={{ alignSelf: 'flex-start', bottom: 10, left: 5 }}>
                        <Image source={Images.redEditIcon} style={{ height: 25, width: 25 }} />
                      </TouchableOpacity>
                    </View>
                }
              </View>
            </>
        }
        {
          guestLogin &&
          <TouchableOpacity onPress={handleLogin} style={s.loginButton}>
            <Text style={s.logintext}>
              Login
            </Text>
          </TouchableOpacity>
        }
        <CompleteProfileModal
          visible={userInfoPopUpVisible}
          missinFields={missinFields}
          onClose={() => setuserInfoPopUpVisible(false)}
        />
        <CompleteProfileModalTwo
          tvisible={completeProfileModalTwoVisible}
          profileProgress={profileProgress}
          setProfileProgress={setProfileProgress}
          onClose={() => setcompleteProfileModalTwoVisible(false)}
        />
      </View>
    </View>
  )
}
