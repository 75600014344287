/**
 * Created by Jebin for iLeaf Solutions Pvt.Ltd
 * on January 09, 2020
 * Preloader - Everthing starts from the preloader.
 */

import { connect } from "react-redux";
import React, { Component } from "react";
import { Platform, SafeAreaView, View } from "react-native";
import { setGuestlogin } from "./../actions/path.actions";
import { withRouter } from "react-router-dom";

class PreLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { authData, finishIntroScreen, finishSelectDesc, history } = this.props;

    // console.log("finishIntroScreen", finishIntroScreen);
    // console.log("authData", authData);
    // console.log("finishSelectDesc", finishSelectDesc);
    if (finishIntroScreen && !finishIntroScreen.finishIntro) {
      Platform.OS == 'web' ? history.push('/dashboard') : this.props.navigation.navigate("AppIntro");
      this.props.setGuestlogin(true)
    } else if (authData && authData.token) {
      Platform.OS == 'web' ? history.push('/dashboard') : this.props.navigation.navigate("HomeStack");
      this.props.setGuestlogin(false)
    } else {
      Platform.OS == 'web' ? history.push('/dashboard') : this.props.navigation.navigate("HomeStack");
      this.props.setGuestlogin(true)
    }

    console.disableYellowBox = true;
  }

  componentWillUnmount() { }

  render() {
    const {
      authData: { isLoggedIn },
      finishIntroScreen: { finishIntro },
      authData: { token },
      finishSelectDesc: { showDestination, hideDestination }
    } = this.props;

    var loginstatus = isLoggedIn == undefined ? false : undefined;
    var introstate = finishIntro == undefined ? false : finishIntro;
    var tokenstatus =
      token == undefined ? false : token.length > 5 ? true : false;

    var showsatus = showDestination == undefined ? false : showDestination;
    var hisdestatus = hideDestination == undefined ? true : hideDestination;
    var showDestinationcheck = false;
    if (hisdestatus && showsatus) {
      showDestinationcheck = true;
    }

    return (
      <SafeAreaView
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <View style={{}}></View>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = state => ({
  authData: state.authReducer.authData,
  finishIntroScreen: state.authReducer.finishIntroScreen,
  finishSelectDesc: state.authReducer.finishSelectDesc
  // getUser: state.userReducer.getUser
});

export default connect(
  mapStateToProps,
  { setGuestlogin }
)(Platform.OS == 'web' ? withRouter(PreLoader) : PreLoader);
