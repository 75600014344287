import { StyleSheet } from "react-native";
import { family } from "../../../../../../common";

export const styles = StyleSheet.create({
    bulletContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingTop: 15,
        paddingBottom: 15,
    },
    title: {
        fontFamily: family.effra,
        fontSize: 18,
        marginTop: 15,
    }
})