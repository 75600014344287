import { StyleSheet, Platform } from "react-native";
import { mobilBrower } from "../../common/helpers";
import { heightPercentageToDP } from "../../common/responsiveScreen";

export const styles = StyleSheet.create({
  mainView: {
    height: "100%",
    width: "100%",
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonsText: {
    padding: 10,
    paddingLeft: 12,
    paddingRight: 12,
    color: "white",
    fontWeight: "800",
    fontSize: heightPercentageToDP("1.7%"),
  },
  buttons: {
    backgroundColor: "#030135",
    borderRadius: 10,
  },
  infoView: {
    // flexDirection: 'row',
    alignItems: "center",
    padding: 15,
  },
  buttonView: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    justifyContent: "center",
    marginBottom: 15,
  },
  innerView: {
    backgroundColor: "white",
    borderWidth: 1,
    width: Platform.OS == "web" && !mobilBrower() ? "40%" : "80%",
    borderRadius: 15,
    borderColor: "#BDBDBD",
  },
  text: {
    marginRight: 5,
    fontWeight: "700",
    fontSize: 15,
    textAlign: "center",
  },
  textTitle: {
    padding: heightPercentageToDP("2%"),
    fontWeight: "500",
    fontSize: heightPercentageToDP("2%"),
    textAlign: "center",
  },
});
