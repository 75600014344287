import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  Modal,
  FlatList,
  TextInput,
  Dimensions,
  TouchableOpacity,
  Image,
  Platform,
} from "react-native";
import { Images, family } from "../common";
import { some } from "lodash";
import ErrorModal from "./../components/errorModal";
import { mobilBrower } from "../common/helpers";
import { heightPercentageToDP } from "../common/responsiveScreen";

const { width } = Dimensions.get("window");

function MultiSelectList({ data, onclose, _handleSelect, title }) {
  const [searchedData, setsearchedData] = useState([]);
  const [searchedText, setsearchedText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  const [errorMessage, seterrorMessage] = useState(null);
  const [showErrorModal, setshowErrorModal] = useState(false);

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = data.filter(function (item) {
        const itemData =
          (title == "Select your country"
            ? item.name
            : item.label
            ? item.label
            : "").toLowerCase();
        const textData = text.toLowerCase();
        return itemData.indexOf(textData) > -1;
      });
      setsearchedData(newData);
      setsearchedText(text);
    } else {
      setsearchedData(data);
      setsearchedText(text);
    }
  };

  const handleItemPress = (item) => {
    if (title == "Select your country") {
      _handleSelect(item.name);
      onclose();
    } else {
      let _selectedItems = [...selectedItems];
      var result = some(_selectedItems, (q) => {
        return q.value.id === item.value.id;
      });
      if (result) {
        _selectedItems = _selectedItems.filter(
          (s) => s.value.id !== item.value.id
        );
      } else {
        if (_selectedItems.length >= 5) {
          setshowErrorModal(true);
          seterrorMessage("maximum 5 items allowed");
          setTimeout(() => {
            setshowErrorModal(false);
          }, 1200);
          return;
        }
        _selectedItems.push(item);
      }
      setselectedItems(_selectedItems);
    }
  };

  // const HeaderCom = () => {
  //     return (
  //         <View style={styles.header}>
  //             <TextInput
  //                 placeholder='Search'
  //                 onChangeText={(text) => searchFilterFunction(text)}
  //                 defaultValue={searchedText}
  //                 style={{ height: 45, width: '90%' }}
  //             />
  //         </View>
  //     )
  // }

  const handleSelect = () => {
    _handleSelect(selectedItems);
  };

  return (
    <Modal visible transparent>
      <View style={styles.mainView}>
        <View style={styles.innerView}>
          <View style={styles.titleView}>
            <Text style={styles.headerTitle}>{title}</Text>
            <TouchableOpacity
              onPress={() => onclose()}
              style={styles.cancelButton}
            >
              <Image source={Images.cancel} style={{ height: 14, width: 14 }} />
            </TouchableOpacity>
          </View>
          <View style={styles.header}>
            <TextInput
              placeholder="Search"
              onChangeText={(text) => searchFilterFunction(text)}
              defaultValue={searchedText}
              style={{ height: 45, width: "90%" }}
            />
          </View>
          <FlatList
            data={searchedData.length > 0 ? searchedData : data}
            initialNumToRender={10}
            extraData={selectedItems}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                onPress={() => handleItemPress(item)}
                style={styles.listItem}
              >
                <Text style={styles.itemtext}>
                  {title == "Select your country" ? item.name : item.label}
                </Text>
                {some(selectedItems, (q) => {
                  return q.value.id === item.value.id;
                }) ? (
                  <Image
                    source={Images.tick}
                    style={{ height: 18, width: 18 }}
                  />
                ) : (
                  <View />
                )}
              </TouchableOpacity>
            )}
          />
          <TouchableOpacity
            onPress={() => handleSelect()}
            style={styles.proceedButton}
          >
            <Text style={styles.proceedText}>Select</Text>
          </TouchableOpacity>
        </View>
      </View>
      {showErrorModal ? (
        <ErrorModal
          errorMessage={errorMessage}
          guest={false}
          closeModal={() => setshowErrorModal(false)}
        />
      ) : (
        <View />
      )}
    </Modal>
  );
}

const styles = StyleSheet.create({
  mainView: {
    height: heightPercentageToDP(100),
    width: width,
    backgroundColor: "#222222c7",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    height: 50,
    width: "100%",
    borderWidth: 0.6,
    alignItems: "center",
    backgroundColor: "white",
  },
  innerView: {
    backgroundColor: "white",
    width: Platform.OS == "web" && !mobilBrower() ? "40%" : "90%",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    height: "70%",
  },
  listItem: {
    borderBottomWidth: 0.5,
    padding: 6,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  proceedText: {
    color: "white",
    fontFamily: family.semibold,
  },
  itemtext: {
    fontFamily: family.semibold,
    fontSize: 14,
    marginLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
  },
  cancelButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  proceedButton: {
    width: "30%",
    height: 30,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginBottom: 20,
    backgroundColor: "#030135",
    marginTop: 10,
  },
  headerTitle: {
    color: "white",
    fontFamily: family.semibold,
    padding: 10,
  },
  titleView: {
    width: "100%",
    backgroundColor: "#030135",
    alignItems: "center",
    flexDirection: "row",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    justifyContent: "center",
  },
});

export default MultiSelectList;
