import React from "react";
import {
  View
} from "react-native";
import helpers from "../../../../../common/helpers";
import { InfoSection } from "./InfoSection";
import _ from "lodash"

export const CommonSection = ({
  value,
  selectedCurrency,
  selectedJobname,
  itemKey,
}) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignSelf: "flex-start",
        marginTop: 0,
        flex: 1,
        width: "100%"
      }}
    >
      {itemKey === "costLiving" ||
        itemKey === "selected_job_salary" ||
        itemKey === "noCityApartment1Bdrm" ||
        itemKey === "averageCost" ? (
        <InfoSection info={itemKey == "selected_job_salary"
          ? `Average salary for ${selectedJobname}`
          : this._getTitle(itemKey)} value={helpers.getCurrencySymbol(selectedCurrency) + " " + helpers.convertFormat(
            Math.round(helpers.currencyConverter(selectedCurrency, value)),
            selectedCurrency
          )} />
      ) : itemKey === "cityName" ? (
        this._splitCity(value)
      ) : itemKey === "coronaEffect" ? (
        <InfoSection info={"Coronavirus Spread at State/Province Level (cases to-date):"} value={helpers.convertFormat(value, selectedCurrency)} />
      ) : itemKey === "population" ? (
        <InfoSection info={"Population :"} value={helpers.convertFormat(value.replace(/\,/g, ""), selectedCurrency)} />
      ) : itemKey === "immigrationFriendliness" ? (
        <InfoSection info={"Immigration Friendliness Index :"} value={value} />
      ) : itemKey === "gdpGrowth" ? (
        <InfoSection info={"GDP Growth :"} value={value} />
      ) : (
        <InfoSection info={_.startCase(itemKey) + " :"} value={value} />
      )}
    </View>
  );
};
