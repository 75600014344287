import React from "react";
import { animated as a, useTrail } from "@react-spring/native";

export const TrailFromRight = ({ children, renderAnimation }) => {
  const components = React.Children.toArray(children);
  const springs = useTrail(components.length, {
    delay: 100,
    marginLeft: renderAnimation ? 0 : 20,
    opacity: renderAnimation ? 1 : 0,
  });

  return springs.map(({ ...style }, index) => {
    return (
      <a.View
        key={index}
        style={[
          style,
          {
            width: "100%",
          },
        ]}
      >
        {components[index]}
      </a.View>
    );
  });
};
