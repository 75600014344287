import { setInitialLoadTypes } from "../reducers/app.reducer";

export const setInitialAppLoadStatus = (payload) => ({
  type: setInitialLoadTypes.SET_INITIAL_LOAD,
  payload,
});

export const setScreenViewedCount = (count) => ({
  type: setInitialLoadTypes.SET_SCREEN_VIEWED_COUNT,
  count,
});

export const incrementScreenViewedCount = () => ({
  type: setInitialLoadTypes.INCREMENT_SCREEN_VIEWED_COUNT,
});

export const togglePathExpViewType = (payload) => ({
  type: setInitialLoadTypes.CHANGE_PATHEXPLORER_VIEW,
  payload,
});

export const getOccupationAndLanguageList = (payload) => ({
  type: setInitialLoadTypes.GET_OCCUPATION_LIST,
  payload,
});
