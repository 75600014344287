import React, { useEffect, useState } from "react";
import { View, Text, Modal, Image, TouchableOpacity, Linking, Platform } from "react-native";
import { helpers } from "../../../../../../common";
import Images from "../../../../../../common/images";
import { styles as s } from "./moreInfoModal.styles";

export const MoreInfoModal = ({ closeMoreInfoModal, params }) => {
  const [dataExist, setdataExist] = useState(true)

  useEffect(() => {
    let factId = [11, 10, 0, 12, 1]
    let temp = 0;
    factId.forEach((s) => {
      if (!params.facts[s].value) {
        temp += 1
      }
    })
    temp == 5 ? setdataExist(false) : setdataExist(true)
  }, [params])
  const dialCall = (p) => {
  
      let phoneNumber = '';
      if (Platform.OS === 'android') {
        phoneNumber = 'tel:${'+p+'}';
      }
      else {
        phoneNumber = 'telprompt:${'+p+'}';
      }
      Linking.openURL(phoneNumber);
    };

  return (
    <Modal visible={true} animationType="fade" transparent>
      <View style={s.wrapper}>
        <View style={s.modal}>
          <View style={[s.closeWrapper]}>
            <TouchableOpacity
              onPress={closeMoreInfoModal}
              style={s.closeIconButton}
              hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
            >
              <Image source={Images.closeBlack} style={s.closeIcon} />
            </TouchableOpacity>
          </View>
          <View style={[s.list]}>
            <View style={s.imageView}>
              <Text style={[s.univIcon]}>
                {helpers.getUniversityInitials(params.university)}
              </Text>
            </View>
            {/* <Text style={[s.univNameText2]}>
                {params.university}
              </Text> */}
            <View style={[s.infoWrapper]}>
              <View style={[]}>
                <Text style={[s.univNameText]}>{params.university}</Text>
                <Text style={[s.locationInfoText]}>{params.city_string}</Text>
                <Text style={[s.locationInfoText]}>{params.location}</Text>
              </View>
              {/* <Image
                resizeMode="contain"
                style={[s.bookmarkIcon]}
                source={Images.bookmarkIcon}
              /> */}
            </View>
          </View>
          {
            dataExist ?
              <>
                <Text style={s.textTitle}>About</Text>
                {
                  params.facts[15]?.value &&
                  <Text style={s.text}>
                    {params.facts[15]?.value}
                  </Text>
                }
                {
                  params.facts[11]?.value &&
                  <View style={s.detailRow}>
                    <Text style={s.label}>
                      {'Phone:   '}
                    </Text>
                    <TouchableOpacity onPress={() => dialCall(params.facts[11]?.value)}>
                      <Text style={s.text}>
                        {params.facts[11]?.value}
                      </Text>
                    </TouchableOpacity>
                  </View>
                }
                {
                  params.facts[10]?.value &&
                  <View style={s.detailRow}>
                    <Text style={s.label}>
                      {'Address:   '}
                    </Text>
                    <Text style={s.text}>
                      {params.facts[10]?.value}
                    </Text>
                  </View>
                }
                {
                  params.facts[0]?.value &&
                  <View style={s.detailRow}>
                    <Text style={s.label}>
                      {'Status:   '}
                    </Text>
                    <Text style={s.text}>
                      {params.facts[0]?.value}
                    </Text>
                  </View>
                }
                {
                  params.facts[1]?.value &&
                  <View style={s.detailRow}>
                    <Text style={s.label}>
                      {'Research Output: '}
                    </Text>
                    <Text style={s.text}>
                      {params.facts[1]?.value}
                    </Text>
                  </View>
                }
                {
                  params.facts[12]?.value &&
                  <View style={s.detailRow}>
                    <Text style={s.label}>
                      {'Acceptance Rate: '}
                    </Text>
                    <Text style={s.text}>
                      {params.facts[12]?.value}
                    </Text>
                  </View>
                }
              </> :
              <>
                <Text style={[s.text, { alignSelf: 'center' }]}>
                  {'No Data Available'}
                </Text>
              </>
          }

          {/* <TouchableOpacity style={[s.addToMyListButton]} activeOpacity={0.7}>
            <Text style={s.premiumButtonText}>Add To My List </Text>
          </TouchableOpacity>
          <TouchableOpacity style={[s.saveCityButton]} activeOpacity={0.7}>
            <Text style={s.premiumButtonText}>Save City </Text>
          </TouchableOpacity> */}
        </View>
      </View>
    </Modal>
  );
};
