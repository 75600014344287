import { Dimensions, Platform, StyleSheet } from "react-native";
const { width, height } = Dimensions.get("window");
import { heightPercentageToDP, widthPercentageToDP } from "react-native-responsive-screen";
import { family } from "../../../common";

export const styles = StyleSheet.create({
  cardView: {
    width: width - 30,
  },
  discovercrd: {
    width: '100%',
    marginTop: 10,
    padding: 10,
    zIndex: -10000,
    backgroundColor: 'white',
    marginBottom: 5
  },
  currentStageBox: {
    alignSelf: 'center', 
    position: 'relative',
    alignItems: 'center', 
    minWidth: widthPercentageToDP('40%'),
    backgroundColor: '#528CC1', 
    borderRadius: 5
  },
  bottomRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  loginButton: {
    backgroundColor: '#F1AB32',
    padding: 5,
    paddingHorizontal: 7,
    borderRadius: 6
  },
  doItbutton: {
    backgroundColor: '#F1AB32',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    marginTop: 15
  },
  ViewButton: {
    backgroundColor: '#F1AB32',
    padding: 5,
    width: '30%',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: 10,
    right: -20,
    bottom: 20,
    paddingHorizontal: 7,
    borderRadius: 6
  },
  ViewButtonText: {
    fontFamily: family.robotoBold,
    color: 'white'
  },
  AddtocartButton: {
    backgroundColor: '#F1AB32',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    borderRadius: 10,
    padding: 5,
    marginTop: 15
  },
  AddtocartButtonText: {
    color: 'white',
    fontFamily: family.robotoBold
  },
  logintext: {
    color: 'white',
    fontFamily: family.robotoBold
  },
  recommendedText: {
    color: 'white',
    fontFamily: family.robotoBold,
  },
  solutionItem: {
    width: '100%',
    marginLeft: 30,
    height: 100,
    padding: 10,
  },
  sliderItem: {
    flexDirection: 'row', 
    alignItems: 'flex-end', 
    justifyContent: 'space-between', 
    width: '70%',
  },
  postedByView: {
    width: width - 100, 
    flexDirection: 'row', 
    alignItems: 'center', 
    marginTop: 10, 
    justifyContent: 'space-between', 
  },
  recommendedView: {
    backgroundColor: '#33AC09',
    width: '40%',
    alignItems: 'center',
    marginBottom: 5,
    justifyContent: 'center',
    padding: 4
  },
  visaName: {
    fontFamily: family.robotoBold,
    marginBottom: 3,
    width: '65%',
    flexShrink: 1,
  },
  profileBar: {
    resizeMode: 'contain', 
    width: 120, 
    alignItems: 'center',
    marginTop: 10,
    justifyContent: 'flex-end',
    height: 60 
  },
  flag: {
   height: '100%',
   width: 100,
   flex: 1/2.5,
   resizeMode: 'contain'
  },
  emptyCicle: {
    height: 12, 
    width: 12, 
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    borderWidth: 1,
    marginRight: 5
  },
  checkCircle: {
    height: 12, 
    resizeMode: 'contain',
    width: 12, 
    marginLeft: 10,
    bottom: 2,
    marginRight: 5
  },
  arrowStyle: {
    position: 'absolute', 
    zIndex: 99999,
    bottom: 75,
  }
});
