import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Platform,
  ImageBackground,
} from "react-native";
import { heightPercentageToDP } from "react-native-responsive-screen";
import * as Animatable from "react-native-animatable";
import { checkTutorialStatus, copilotData, isWeb, mobilBrower } from "../../../common/helpers";
import { animated as a, useSpring } from "@react-spring/native";
import Images from "../../../common/images";
import dashboardimg3 from "../../../images/dashboardimg3.png";
import { useSelector } from "react-redux";
import { copilot, CopilotStep, walkthroughable } from "react-native-copilot";
import { TooltipComponent } from "../../../components/Tooltip/Tooltip";
import DashboardCards from "../DashboardCards";

const MiddleView = ({ handleNavigation, styles, guestLogin, start, history, lastShortListedpath }) => {
  const CopilotTouch = walkthroughable(TouchableOpacity);
  const CopilotView = walkthroughable(View);
  const [profileComplete, setprofileComplete] = useState(false)
  const dashboardProfileFinish = useSelector(
    (state) => state.copilotStateReducer.dashboardProfileFinish
  );
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const initialLoadStatus = useSelector(
    (state) => state.appStateReducer.initialLoadState
  );

  const headerAnimation = useSpring({
    delay: 100,
    opacity: initialLoadStatus ? 1 : 0,
    marginBottom: initialLoadStatus ? 0 : -50,
  });

  const headerBgAnimation = useSpring({
    delay: 200,
    width: initialLoadStatus ? "100%" : "0%",
    opacity: initialLoadStatus ? 1 : 0,
    bottom: initialLoadStatus ? 0 : -50,
    backgroundColor: initialLoadStatus ? "#F1AB32" : "#0F1143",
  });

  const immiTitleAnimation = useSpring({
    delay: 400,
    opacity: initialLoadStatus ? 1 : 0,
    marginBottom: initialLoadStatus ? 0 : -50,
  });

  const mapAnimation = useSpring({
    delay: 800,
    opacity: initialLoadStatus ? 1 : 0,
    marginTop: initialLoadStatus ? 0 : 100,
  });

  const easeIn = useSpring({
    delay: 600,
    opacity: initialLoadStatus ? 1 : 0,
    marginTop: initialLoadStatus ? 0 : 100,
  });

  useEffect(() => {
    let callCoiplot = async () => {
      let status = await checkTutorialStatus('adviceClick')
      dashboardProfileFinish && status && start()
    }
    !isWeb() && callCoiplot()
  }, [dashboardProfileFinish])

  const isProfileComlpete = (status) => {
    setprofileComplete(status)
  }

  return Platform.OS == "web" ? (
    <Animatable.View animation="fadeIn" style={[styles.topView,]}>
      <View style={styles.nameView}>
        <View style={styles.nameView_left}>
          <View style={styles.nameContanier}>
            <Text style={styles.welcomeText}>IMMIGREAT</Text>
            <View style={styles.welcomeTextBg} />
          </View>
          <Text style={styles.immiTitle}>TO WHERE YOUR COMPASS POINTS</Text>
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              marginTop: mobilBrower() ? 25 : 80,
            }}
          >
            <Text style={styles.userNameTextwelcome}>{`Welcome`}</Text>
            {
              <Text style={styles.userNameText}>
                {!guestLogin ? `${userDetails?.first_name}` : "Guest"}
              </Text>}
          </View>
          {guestLogin ? <TouchableOpacity
            onPress={() => handleNavigation("/eligibilityChecker", true)}
            style={styles.eligibilityButton}
          >
            <Text style={[styles.userNameTextwelcome, { fontWeight: "bold" }]}>Check your eligibility</Text>
          </TouchableOpacity> : null}
        </View>
        <View style={styles.nameView_right}>
          <ImageBackground
            source={dashboardimg3}
            resizeMode="contain"
            style={styles.dashboadImg}
          >
            <TouchableOpacity
              onPress={() => handleNavigation("/profile")}
              hitSlop={{ top: 40, right: 40, left: 40 }}
              style={styles.discoverButton}
            />
            <TouchableOpacity
              onPress={() => handleNavigation("/pathExp")}
              hitSlop={{ top: 40, right: 40, left: 40 }}
              style={styles.opportunitiesButton}
            />
            <TouchableOpacity
              onPress={() => handleNavigation("/adviceChannel")}
              hitSlop={{ top: 40, right: 40, left: 40 }}
              style={styles.solutionsButton}
            />
          </ImageBackground>
        </View>
      </View>
    </Animatable.View>
  ) : (
    <View style={[styles.topView, { height: guestLogin || profileComplete ? heightPercentageToDP('90%') : heightPercentageToDP('100%') }]}>
      <View style={styles.nameView}>
        <View style={styles.introTextWrapper}>
          <a.Text style={[headerAnimation, styles.welcomeText]}>
            IMMIGREAT
          </a.Text>
          <a.View style={[headerBgAnimation, styles.nameContanier]} />
        </View>
        <a.Text style={[immiTitleAnimation, styles.immiTitle]}>
          TO WHERE YOUR COMPASS POINTS
        </a.Text>
        {!guestLogin ? (
          <a.View
            style={[
              easeIn,
              {
                alignItems: "center",
                flexDirection: "row",
                marginTop: mobilBrower() ? "1%" : heightPercentageToDP("2%"),
              },
            ]}
          >
            <Text style={styles.userNameTextwelcome}>{`Welcome`}</Text>
            <Text style={styles.userNameText}>
              {`${userDetails?.first_name}`}
            </Text>
          </a.View>
        ) : (
          <View />
        )}
        <a.View style={[mapAnimation]}>
          {/* <ImageBackground
            source={Images.dashboardimg3}
            style={[
              styles.dashboadImgMobile,
            ]}
          >
            <CopilotStep
              text={copilotData?.dashboardProfileButton}
              order={1}
              name="profileTab">
              <CopilotView style={{ height: 120, top: 40 }}>
                <CopilotTouch
                  onPress={() => handleNavigation("NewProfileScreen")}
                  hitSlop={{ top: 40, right: 40, left: 40, bottom: 20 }}
                  style={styles.discoverButton}
                />
              </CopilotView>
            </CopilotStep>
            <CopilotStep
              text={copilotData?.dashboardPathExpButton}
              order={2}
              name="pathexpClick">
              <CopilotView style={{ height: 120, top: 40 }}>
                <TouchableOpacity
                  onPress={() => handleNavigation("Path Explorer")}
                  hitSlop={{ top: 20, right: 40, left: 40, bottom: 20 }}
                  style={styles.opportunitiesButton}
                />
              </CopilotView>
            </CopilotStep>
            <CopilotStep
              text={copilotData?.dashboardAdviceButton}
              order={3}
              name="adviceClick">
              <CopilotView style={{ height: 100, top: 80 }}>
                <TouchableOpacity
                  onPress={() => handleNavigation("Advice")}
                  hitSlop={{ top: 20, right: 40, left: 40, bottom: 40 }}
                  style={styles.solutionsButton}
                />
              </CopilotView>
            </CopilotStep>
          </ImageBackground> */}
          {
            <DashboardCards
              guestLogin={guestLogin}
              isProfileComlpete={isProfileComlpete}
              lastShortListedpath={lastShortListedpath} />
          }
        </a.View>
      </View>
    </View>
  );
};

export default copilot({
  tooltipComponent: TooltipComponent
})(MiddleView)