import { StyleSheet, StatusBar, Dimensions } from "react-native";
const { width } = Dimensions.get("window");
import { colors } from "../../common";
import { heightPercentageToDP } from "../../common/responsiveScreen";
import { responsiveText } from "../../common/normalize";

export const multiSelectStyle = {
  item: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  itemText: {
    color: "black",
    fontWeight: "400",
  },
  subItem: {
    color: "grey",
  },
  subItem: {
    paddingHorizontal: 10,
  },
  selectedItem: {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  selectedSubItem: {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  selectToggle: {
    width: width / 1.2,
  },
  chipsWrapper: {
    backgroundColor: "yellow",
  },
  selectToggleText: {
    color: "black",
    fontSize: 14,
    marginLeft: 30,
    marginBottom: 15,
    marginTop: 10,
  },
  button: {
    backgroundColor: "#030135",
  },
  scrollView: { paddingHorizontal: 0 },
};

export const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.LIGHT_BLUE,
    flex: 1,
    marginTop: -15,
  },
  imageview: {
    alignItems: "center",
    justifyContent: "center",
  },
  deleteTitle: {
    fontSize: 18,
    padding: 17,
    paddingLeft: 8,
  },
  warningText: {
    fontSize: 15,
    marginBottom: 5,
  },
  signupTextCont: {
    flexGrow: 1,
    alignItems: "flex-end",
    justifyContent: "center",
    paddingVertical: 16,
    flexDirection: "row",
  },
  button: {
    width: 300,
    backgroundColor: colors.LIGHT_BLUE,
    borderRadius: 25,
    marginVertical: 10,
    paddingVertical: 13,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 35,
    marginBottom: 10,
  },
  buttonText: {
    fontSize: responsiveText(16),
    color: "#FFF",
    textAlign: "center"
  },
  errorText: {
    color: "red",
    fontSize: 14,
  },

  loginView: {
    width: "100%",
    alignItems: "center",
    marginVertical: 10,
  },
  buttonStyle: {
    backgroundColor: "#18A4E0",
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20,
    borderRadius: 100,
  },
  signUpText: { marginLeft: 5, marginRight: 5, color: colors.LIGHT_BLUE },
  buttonstyleview: {
    width: "85%",
    backgroundColor: "rgba(0, 2, 49, 0.5)",
    borderRadius: 100,
    height: 40,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginRight: 15,
  },
  selectedbutton: {
    width: "85%",
    backgroundColor: colors.LIGHT_BLUE,
    borderRadius: 100,
    height: 40,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginRight: 15,
  },
  mainView2: {
    height: heightPercentageToDP(100),
    width: width,
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
  },
  selectedbuttontext: {
    alignSelf: "stretch",
    textAlign: "center",
    color: colors.IMMIGREAT_YELLOW,
    fontWeight: "bold",
    fontSize: responsiveText(12),
  },
  buttonstyletext: {
    alignSelf: "stretch",
    textAlign: "center",
    color: "#fff",
    fontSize: responsiveText(12),
  },
  custombuttonView: {
    flexDirection: "row",
    marginTop: 15,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginLeft: 10,
  },
  checkBoxRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginVertical: 10,
    marginLeft: -width * 0.061,
  },
  checkbox: {
    marginHorizontal: 20,
    backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
  },
  imagView: {
    marginLeft: 10,
    marginTop: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  backicon: {
    height: 23,
    width: 23,
  },
  customCheckbox: {
    backgroundColor: "#29A6EC",
    height: 20,
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 20,
    width: 20,
    borderRadius: 10,
  },
  checkboxContainer: {
    marginTop: heightPercentageToDP(3),
    marginHorizontal: heightPercentageToDP(3),
    alignItems: "flex-start",
  },
  header: {
    width: "100%",
    alignItems: "center",
    backgroundColor: "#0F1143",
    marginBottom: 15,
    paddingBottom: 15,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
  },
  titleView: {
    flexDirection: "row",
    width: "90%",
    alignSelf: "center",
    justifyContent: "space-between",
    marginTop: 30,
    alignItems: "center",
  },
  titleText: {
    fontSize: responsiveText(18),
    color: "white",
  },
  inputBox: {
    width: "90%",
    backgroundColor: "#F5F6F8",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: heightPercentageToDP("0.1%"),
    borderColor: "#BDBDBD",
    alignItems: "flex-start",
  },
  goalsButton: {
    alignItems: "center",
    paddingLeft: 12,
    paddingRight: 12,
    borderWidth: 1,
    borderColor: "#F1AB32",
    margin: 10,
    height: heightPercentageToDP("5%"),
    width: heightPercentageToDP("20%"),
    justifyContent: "center",
    borderRadius: 5,
  },
  unimmigrationInterst: {
    alignItems: "center",
    paddingLeft: 12,
    paddingRight: 12,
    borderWidth: 2,
    borderColor: "#BDBDBD",
    margin: 10,
    height: heightPercentageToDP("5%"),
    width: heightPercentageToDP("20%"),
    justifyContent: "center",
    borderRadius: 5,
  },
  goalText: {
    color: "white",
    fontSize: responsiveText(15)
  },
  inputBoxmarginTop: {
    marginTop: heightPercentageToDP("5%")
  },
  nextButtonInnerStyle: {
    width: "100%",
    height: heightPercentageToDP("5%"),
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  nextButton: {
    width: "90%",
    height: heightPercentageToDP("5%"),
    backgroundColor: "#0F1143",
    alignItems: "center",
    position: "absolute",
    bottom: heightPercentageToDP("10%"),
    alignSelf: "center",
    justifyContent: "center",
  },
  saveButton: {
    width: "90%",
    height: heightPercentageToDP("5.5%"),
    backgroundColor: "#0F1143",
    alignItems: "center",
    marginTop: heightPercentageToDP("3%"),
    marginBottom: heightPercentageToDP("5%"),
    alignSelf: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  koButton: {
    width: "48%",
    height: heightPercentageToDP("5.5%"),
    backgroundColor: "#0F1143",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  mailImage: {
    height: heightPercentageToDP(12),
    width: heightPercentageToDP(12),
    borderRadius: 60,
    alignItems: "center",
    marginTop: 5,
    borderWidth: 2,
    borderColor: "#fff",
  },
  editIon: {
    position: "absolute",
    top: 78,
    left: "56%",
    padding: 6,
    backgroundColor: "#fff",
    zIndex: 999999,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: "gray",
    alignItems: "center",
  },
  disableText: {
    marginLeft: 15,
    color: "gray",
  },
  disabledView: {
    backgroundColor: "#fff",
    marginBottom: -10,
  },
  statusBar: {
    height: StatusBar.currentHeight,
    backgroundColor: colors.LIGHT_BLUE,
  },
  selectBox: {
    width: "95%",
    padding: 7,
    borderWidth: 1,
    borderColor: "#adadad85",
    backgroundColor: "#e4d4d43b",
    height: 55,
    borderRadius: 5,
  },
  labelStyle: {
    color: "gray",
  },
  disableText: {
    color: "gray",
  },
  listTextViewStyle: {
    color: "#000",
    marginVertical: 10,
    flex: 0.9,
    marginLeft: 10,
    textAlign: "left",
  },
  pickerStyle: {
    paddingRight: 25,
    marginRight: 10,
    marginBottom: 0,
    paddingBottom: 0,
    backgroundColor: "#00000000",
    flexDirection: "row",
    borderWidth: 0,
    borderColor: null,
  },
  title: {
    alignSelf: "center",
    width: "80%",
    marginBottom: 8,
    marginLeft: 5,
    marginTop: 10,
    fontSize: 19,
    color: "#0F1143",
    alignSelf: "center",
    textAlign: "center",
    letterSpacing: 1,
  },
  RNPickerIcon: {
    height: 0,
    width: 0,
  },
  selectLabelTextStyle: {
    textAlign: "left",
    width: "95%",
    marginLeft: 5,
    fontSize: responsiveText(17),
    color: "#181818",
    marginTop: 4,
  },
  placeHolderTextStyle: {
    color: "grey",
    padding: 10,
    textAlign: "left",
    width: "99%",
    flexDirection: "row",
  },
  flagbuttons: {
    height: 80,
    alignItems: "center",
    margin: 10,
  },
  pickerContainerView: {
    borderColor: "#dcdad9",
    width: "95%",
    borderRadius: 5,
    backgroundColor: "transparent",
    alignItems: "flex-start",
    paddingBottom: 0,
  },
  countryMainView: {
    width: "90%",
    borderRadius: 10,
    marginTop: 15,
    alignItems: "center",
    shadowColor: "grey",
    borderColor: "#030135",
    marginLeft: "5%",
  },
  countiesView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 0,
    alignSelf: "center",
    justifyContent: "space-between",
  },
  countryIcon: {
    height: 50,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: "center",
  },
  loader: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  inputBoxLarge: {
    width: "90%",
    backgroundColor: "#F5F6F8",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: heightPercentageToDP("0.1%"),
    borderColor: "#BDBDBD",
    alignItems: "flex-start",
    padding: 5,
    position: "relative",
  },
  inputBoxSmall: {
    // width: widthPercentageToDP("44%"),
    flex: 1,
    backgroundColor: "#F5F6F8",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: heightPercentageToDP("0.1%"),
    borderColor: "#BDBDBD",
    position: "relative",
    alignItems: "flex-start",
  },
  smallInputsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    marginLeft: "5%",
  },

  inputWrapper: { marginLeft: 15, marginTop: 5, marginBottom: 5 },
  inputLabel: {
    fontSize: responsiveText(15),
    color: "#272727",
  },
  textInputLarge: {
    fontSize: responsiveText(16),
    color: "#181818",
    padding: 0,
    width: width * 0.8,
  },
  textInputSmall: {
    fontSize: responsiveText(16),
    color: "#181818",
    padding: 0,
    width: width * 0.36,
  },
  pencil: {
    height: 20,
    width: 20,
  },
  editButton: {
    position: 'absolute',
    top: 10,
    right: 0,
    padding: 5,
    backgroundColor: 'rgba(255,255,255, 0.5)',
    borderRadius: 10,
    zIndex: 9999,
  },
  name: {
    color: "#fff",
    fontSize: responsiveText(18),
    marginTop: 20,
  }
});
