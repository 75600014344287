import React from 'react'
import {
    StyleSheet, Text, View, TouchableOpacity, Dimensions, Modal, Platform
} from "react-native";
import { family } from "../common";
const height = Dimensions.get('window').height;
const width = Dimensions.get('window').width;

function AddToDoListModal({showAddModal, toDoTitle, closeModal, addToDolistApi }) {
    return (
        <Modal visible={showAddModal} transparent>
            <View style={styles.mainView}>
                <View style={styles.innerView}>
                    <Text style={styles.errortext}>
                        {`Add ${toDoTitle} to your list ?`}
                    </Text>
                    <View style={styles.buttonContanier}>
                        <TouchableOpacity onPress={() => addToDolistApi()} style={styles.signUp}>
                            <Text style={styles.signUpText}>
                                Add
                                </Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => closeModal()} style={[styles.signUp, { marginLeft: 10 }]}>
                            <Text style={styles.signUpText}>
                                Cancel
                                </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    mainView: {
        height: height,
        width: width,
        backgroundColor: '#222222c7',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerView: {
        backgroundColor: 'white',
        borderRadius: 30,
        width: Platform.OS == 'web' ? '35%' : '90%',
        alignItems: 'center',
        padding: 15,
        shadowColor: 'white',
        shadowOffset: { width: 0, height: 0.5 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
        elevation: 12,
        justifyContent: 'center',
    },
    errortext: {
        fontSize: 18,
        textAlign: 'center',
        fontFamily: family.semibold,
        color: '#030135'
    },
    signUp: {
        borderRadius: 10,
        borderColor: '#030135',
        borderWidth: 1,
        marginTop: 10,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
    },
    buttonContanier: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    signUpText: {
        padding: 5,
        paddingLeft: 7,
        fontFamily: family.semibold,
        paddingRight: 7
    },
    cancelButton: {
        height: 10,
        width: 10
    },
    cancelButtonStyle: {
        position: 'absolute',
        right: 10,
        top: 5,
    }
})
export default AddToDoListModal
