
export const changeHeaderText = value => {
  return async dispatch => {
    dispatch({
      type: 'CHANGE_HEADER_TEXT',
      payload: value
    })
  }
}
export const getToolbarHeight = value => {
  return async dispatch => {
    dispatch({
      type: 'GET_TOOLBAR_HEIGHT',
      payload: value
    })
  }
}
export const saveApplicationDisplayName = (value, baseUnit) => {
  return async dispatch => {
    dispatch({
      type: 'SAVE_APP_DISPLAY_NAME',
      payload: {value, baseUnit}
    })
  }
}

export const supportIconStatusChange = (value) => {
  return async dispatch => {
    dispatch({
      type: 'SUPPORT_ICON_STATUS_CHANGED',
      payload: value
    })
  }
}
