import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  Image,
  Button,
  Modal,
  StatusBar,
  FlatList,
  Platform,
  ImageBackground,
  ActivityIndicator,
  SafeAreaView,
  Alert,
  ScrollView,
  Linking,
} from "react-native";
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from "react-native-responsive-screen";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import fontFamily from "../common/fontFamily";
import { mobilBrower } from "../common/helpers";
import HeaderComponent from "../common/NewHeader/NewHeader";
import WebFooter from "../common/WebFooter";
import redeemStoreIcon from "../images/redeemStoreIcon.png";

export default function OrderConfirmation() {
  const { push } = useHistory();
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const cartItems = useSelector(
    (state) => state.referralReducer.referralData.cartItems
  );
  const orderData = useSelector(
    (state) => state.referralReducer.referralData.orderData
  );
  const address =
    userDetails?.address?.address_line1 +
    " " +
    userDetails?.address?.address_line2 +
    " " +
    userDetails?.address?.city +
    " " +
    userDetails?.address?.country +
    ", " +
    userDetails?.address?.pincode;

  const handlePress = () => {
    push("/store");
  };

  return (
    <View>
      <HeaderComponent />
      <View style={styles.mainView}>
        <Text style={styles.mainView_title}>Order</Text>
        <View style={styles.orderDetailsTitleView}>
          <Text style={styles.orderDetailsTitleView_text}>Order Details</Text>
        </View>
        <View style={styles.orderdetailsRow}>
          <Text style={styles.orderIdTitle}>Order Id :</Text>
          <Text style={styles.orderDetailsText}>{orderData?.orderId}</Text>
        </View>
        <View style={styles.orderdetailsRow}>
          <Text style={styles.orderIdTitle}>Address :</Text>
          <Text style={styles.orderDetailsText}>{address}</Text>
        </View>
        <View style={styles.orderDetailsTitleView}>
          <Text style={styles.orderDetailsTitleView_text}>Products</Text>
        </View>
        <FlatList
          style={{ width: "100%", marginTop: heightPercentageToDP("3%") }}
          data={orderData?.items}
          ListFooterComponent={() => (
            <TouchableOpacity
              onPress={handlePress}
              style={styles.continueButton}
            >
              <Text style={styles.continueButtonText}>Continue Shopping</Text>
            </TouchableOpacity>
          )}
          renderItem={({ item, index }) => {
            return (
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  alignSelf: "center",
                }}
              >
                <Image
                  source={redeemStoreIcon}
                  style={styles.redeemedIconStyle}
                />
                <View
                  style={[
                    mobilBrower()
                      ? styles.mobile_redeemItems
                      : styles.redeemItems,
                  ]}
                >
                  <View style={styles.ImageView}>
                    <Image
                      source={{ uri: item.thumbnail }}
                      style={styles.thumbnail}
                    />
                  </View>
                  <View style={styles.contentView}>
                    <Text style={styles.contentView_title}>{item?.title}</Text>
                    <Text
                      numberOfLines={2}
                      style={styles.contentView_description}
                    >
                      {item?.description}
                    </Text>
                    <View style={styles.priveView}>
                      <Text style={styles.contentView_title}>
                        {"₹" + item?.price}
                      </Text>
                      <View style={styles.incrementView}>
                        <Text style={styles.quantityText}>Quantity</Text>
                        <Text style={styles.quantityText}>
                          {item?.quantity}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            );
          }}
        />
      </View>
      <WebFooter />
    </View>
  );
}

const styles = StyleSheet.create({
  mainView: {
    width: mobilBrower() ? "100%" : "50%",
    alignSelf: "center",
    alignItems: "center",
    marginBottom: heightPercentageToDP("5%"),
  },
  orderIdTitle: {
    fontSize: heightPercentageToDP("2%"),
    fontFamily: fontFamily.effra,
  },

  orderDetailsText: {
    fontSize: heightPercentageToDP("2%"),
    fontFamily: fontFamily.effra,
    marginLeft: heightPercentageToDP("2%"),
  },
  contentView_description: {
    fontSize: heightPercentageToDP("1.5%"),
    marginTop: 10,
    paddingRight: 5,
    color: "#77838F",
  },
  incrementView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: heightPercentageToDP("2%"),
    marginRight: heightPercentageToDP("2%"),
  },
  redeemedIconStyle: {
    width: heightPercentageToDP("3%"),
    height: heightPercentageToDP("3%"),
  },
  quantityText: {
    marginLeft: heightPercentageToDP("1%"),
    fontSize: heightPercentageToDP("1.6%"),
    marginRight: heightPercentageToDP("1%"),
  },
  contentView: {
    flex: 1,
    marginLeft: 15,
    marginTop: 5,
    alignItems: "flex-start",
    height: "70%",
  },
  ImageView: {
    width: heightPercentageToDP("20%"),
    height: "100%",
    padding: 5,
    borderRadius: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "#D8D8D8",
  },
  thumbnail: {
    flex: 1,
    aspectRatio: 1,
    borderRadius: 10,
  },
  continueButton: {
    width: mobilBrower() ? "70%" : "40%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    alignSelf: "center",
    marginTop: heightPercentageToDP("3%"),
    backgroundColor: "rgba(241, 171, 50, 1)",
    height: heightPercentageToDP("4.4%"),
  },
  continueButtonText: {
    color: "white",
    fontFamily: fontFamily.effra,
    fontSize: heightPercentageToDP("2%"),
  },
  mobile_redeemItems: {
    height: mobilBrower() ? 250 : "160px",
    margin: 15,
    width: heightPercentageToDP("50%"),
    alignItems: "center",
    borderRadius: 10,
    shadowColor: "#000000",
    overflow: "hidden",
    shadowRadius: 4,
    shadowOpacity: 0.5,
    flexDirection: "row",
  },
  contentView_title: {
    fontFamily: fontFamily.effra,
    paddingRight: 5,
    fontSize: heightPercentageToDP("1.7%"),
  },
  redeemItems: {
    width: widthPercentageToDP("25%"),
    height: "140px",
    margin: 15,
    alignItems: "center",
    borderRadius: 10,
    alignSelf: "center",
    shadowColor: "#000000",
    overflow: "hidden",
    shadowRadius: 4,
    shadowOpacity: 0.5,
    flexDirection: "row",
  },
  orderdetailsRow: {
    flexDirection: "row",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    marginLeft: heightPercentageToDP("2%"),
    marginTop: heightPercentageToDP("2%"),
  },
  mainView_title: {
    fontWeight: "800",
    fontSize: heightPercentageToDP("3%"),
    fontFamily: fontFamily.effra,
    marginTop: heightPercentageToDP("3%"),
  },
  orderDetailsTitleView: {
    height: heightPercentageToDP("3%"),
    width: "100%",
    marginTop: heightPercentageToDP("3%"),
    backgroundColor: "rgba(241, 171, 50, 1)",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  orderDetailsTitleView_text: {
    color: "white",
    marginLeft: heightPercentageToDP("2%"),
    fontSize: heightPercentageToDP("2%"),
    fontFamily: fontFamily.effra,
  },
});
