import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Dimensions,
  Image,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  BackHandler,
  ImageBackground,
} from "react-native";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { colors } from "../common";
import InputText from "../components/InputText";
import {
  resetpassword,
  updateUserDetails,
  expiryToken,
} from "../actions/auth.actions";
import Loader from "../components/Loader";
const { width, height } = Dimensions.get("window");
import Toast from "react-native-simple-toast";
import HeaderComponent from "../common/NewHeader/NewHeader";
import NetInfo from "@react-native-community/netinfo";
import { Images, family } from "../common";
import { sha1, sha256 } from "react-native-sha256";
import resetpasswordImg from "../images/resetpassword.png";
import Icon from "react-native-vector-icons/FontAwesome";
import { Network, apiPaths } from "../service/api";
import AsyncStorage from "@react-native-community/async-storage";
import { mobilBrower } from "../common/helpers";
import { heightPercentageToDP } from "react-native-responsive-screen";

const keyboardVerticalOffset = Platform.OS === "ios" ? 0 : -110;
const styles = StyleSheet.create({
  container: {
    // flex: 1,
    alignItems: "center",
    // justifyContent: 'center',
    backgroundColor: "#fff",
    minHeight: height,
    marginTop: -5,
  },
  signupButton: {
    color: "#ffffff",
    fontSize: 16,
    fontWeight: "500",
  },
  button: {
    width: Platform.OS == "web" && !mobilBrower() ? "30%" : width / 1.1,
    backgroundColor: colors.LIGHT_BLUE,
    borderRadius: 25,
    marginVertical: 10,
    paddingVertical: 13,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
  },
  buttonText: {
    fontSize: 16,
    fontFamily: "SourceSansPro-Semibold",
    color: "#ffffff",
    textAlign: "center",
  },
  errorText: {
    color: "red",
    fontSize: 14,
    // padding: 16,
    // paddingBottom: 8
  },
  welcomeView: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: Platform.OS == "android" ? height / 3.7 : height / 3,
  },
  welcomeText: {
    fontSize: 25,
    fontFamily: "SourceSansPro-Semibold",
    color: "#2C393F",
    marginTop: 55,
    marginBottom: 30,
  },
  subview: {
    justifyContent: "center",
    alignItems: "center",
    marginTop:
      Platform.OS == "web" && !mobilBrower() ? heightPercentageToDP("1.5%") : 5,
    marginLeft: 40,
    marginRight: 40,
    alignItems: "center",
  },
  subtext: {
    fontSize: Platform.OS == "web" ? heightPercentageToDP("1.8%") : 15,
    color: "#2C393F",
    // fontFamily: fon.effra,
    marginBottom: Platform.OS == "web" ? 10 : 0,
    textAlign: "center",
  },

  loginView: {
    marginTop: 10,
    backgroundColor: "#EEF2F5",
    width: Platform.OS == "web" && !mobilBrower() ? "30%" : width / 1.1,
    height: 50,
    alignItems: "center",
    borderRadius: 100,
    flexDirection: "row",
  },
  mailImage: { height: 20, width: 15, marginLeft: 15, marginRight: 15 },
  passwordView: {
    marginTop: 15,
    backgroundColor: "#EEF2F5",
    width: width / 1.1,
    height: 50,
    alignItems: "center",
    borderRadius: 100,
    flexDirection: "row",
  },
  keyImage: { height: 25, width: 25, marginLeft: 15, marginRight: 15 },
  eyeImage: { height: 25, width: 25, marginLeft: 15, marginRight: 15 },
  forgotpassview: { marginTop: 15, width: width / 1.1 },
  passwordText: { textAlign: "right", color: "blue", fontSize: 12 },
  buttonStyle: {
    backgroundColor: "#18A4E0",
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20,
    borderRadius: 100,
  },
  buttonText: { color: "#fff" },
  signupView: {
    marginTop: 15,
    width: width / 1.1,
    height: 50,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  statusBar: {
    height: Platform.OS === "ios" ? 40 : StatusBar.currentHeight,
    backgroundColor: colors.LIGHT_BLUE,
  },
});

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textString: "",
      currPassStatus: true,
      passStatus: true,
      confirmpassStatus: true,
      checkconnection: false,
      shapassword: "",
      alertMessage: "",
    };
  }

  componentWillMount() {
    this.setState({
      textString:
        Platform.OS == "web"
          ? this.props.history?.location?.state?.postdata?.string
          : this.props?.navigation?.state?.params?.string,
    });
    this.checkConnection();
  }
  componentDidMount() {
    BackHandler.addEventListener("hardwareBackPress", this.handleBackButton);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener("hardwareBackPress", this.handleBackButton);
  }
  handleBackButton = () => {
    //  Actions.pop();
    this.props.navigation.pop();
    return true;
    // this.backpresshandle();
  };

  async checkConnection() {
    NetInfo.fetch().then((state) => {
      this.setState({ checkconnection: state.isConnected });
      if (!this.state.checkconnection) {
        Toast.show("Check your connection", Toast.LONG);
      }
    });
  }
  resetpassword = async (values) => {
    await this.checkConnection();
    const {
      getUser: { userDetails },
      authData: { token },
    } = this.props;
    var resetstr = "Password reset successful!";
    var expirystr = "Auth Token not valid or expired!";
    try {
      var user_id;
      var auth_token;
      var email_add;

      if (this.props.navigation.state.params.status == 1) {
        var string = "User Updated!";
        // user_id = userDetails?.userid;
        // auth_token = token;
        // email_add = userDetails.email_address;
        postData = {};
        postData.first_name =
          userDetails && userDetails.first_name ? userDetails.first_name : "";
        postData.last_name = userDetails.last_name;
        postData.country_of_birth = userDetails.country_of_birth;
        postData.date_of_birth = userDetails.date_of_birth;
        postData.country_of_citizenship1 = userDetails.country_of_citizenship1;
        postData.country_of_citizenship2 = userDetails.country_of_citizenship2;
        postData.occupation = userDetails.occupation;
        postData.sex = userDetails.sex;
        postData.pref_language = userDetails.pref_language;
        postData.email_address = userDetails.email_address;
        postData.country_of_residence = userDetails.country_of_birth;
        postData.highest_academic_achievement =
          userDetails.highest_academic_achievement;
        postData.immigration_interests = userDetails.immigration_interests;
        postData.countries_of_interest = userDetails?.countries_of_interest;
        postData.password_hash = this.state.shapassword;
        // console.log(postData);
        if (!this.state.checkconnection) {
          Toast.show("Check your connection", Toast.LONG);
        } else {
          const response = await this.props.dispatch(
            updateUserDetails(postData, userDetails?.userid, token)
          );
          if (response.status == expirystr) {
            Toast.show(expirystr, Toast.LONG);
            const expityupdate = await this.props.dispatch(expiryToken());
          } else {
            // Actions.pop();
            Toast.show("Password Updated Successfully", Toast.LONG);
            this.props.navigation.pop();
          }
        }
        // if(response.status == string)
        // {
        //   const responseupdate = await this.props.dispatch(getUserDetails(userDetails?.userid,token));
        //   this.setState({reloaddata:true});
        //   Actions.pop();
        // }
      } else {
        user_id = this.props.user_id;
        auth_token = this.props.auth_token;
        email_add = this.props.email_address;
        postData = {};
        postData.email_address = email_add;
        postData.new_password_hash = this.state.shapassword;
        if (!this.state.checkconnection) {
          Toast.show("Check your connection", Toast.LONG);
        } else {
          const response = await this.props.dispatch(
            resetpassword(postData, user_id, auth_token)
          );
          if (response.status == resetstr) {
            // Actions.login();
            this.props.navigation.navigate("Login");
          } else {
            Toast.show(response.status + " Try Again", Toast.LONG);
            // const expityupdate = await this.props.dispatch(expiryToken());
          }
        }
      }
    } catch (error) {
      const newError = new ErrorUtils(error, "Signup Error");
      newError.showAlert();
    }
  };

  onSubmit = async (values) => {
    await this.checkConnection();
    var reg = /^(?=.*\d)(?=.*[A-Z])[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{8,}$/;
    if (!values.currentpassword || values.currentpassword.trim() == "") {
      Toast.show("Current Password is required", Toast.LONG);
      return;
    } else if (!values.newpassword || values.newpassword.trim() == "") {
      Toast.show("Password is required", Toast.LONG);
    } else if (!reg.test(values.newpassword)) {
      Toast.show(
        "At least 8 characters with 1 number and 1 capital letter required.",
        Toast.LONG
      );
    } else if (!values.confirmpassword || values.confirmpassword.trim() == "") {
      Toast.show("Confirm password is required", Toast.LONG);
    } else if (values.confirmpassword !== values.newpassword) {
      Toast.show("Password mismatch", Toast.LONG);
    } else {
      const {
        getUser: { userDetails },
        authData: { token },
      } = this.props;
      const value = await AsyncStorage.getItem("@auth_token");

      const headers = {
        Accept: "application/json",
        Authorization: value,
      };
      var user_id = userDetails?.userid;
      const url = `${user_id}/verifypassword`;
      let postData = {};
      await sha256(values.currentpassword).then((hash) => {
        postData.password_hash = hash;
      });

      try {
        Network(apiPaths.verifyPassword + url, "POST", postData, headers)
          .then(async (res) => {
            if (res.status === "success") {
              if (res.verified) {
                await sha256(values.newpassword).then((hash) => {
                  this.setState({
                    shapassword: hash,
                  });
                });
                this.resetpassword(values);
              } else {
                Toast.show("Incorrect current password", Toast.LONG);
              }
            } else {
              //alert(res.status);
            }
          })
          .catch((s) => {
            alert("Something went wrong!");
          });
      } catch (error) {
        alert("Something went wrong!");
      }
    }

    // Actions.login();
  };
  handleChange = (text, onChange) => {
    // To restrict user from entering blank space.
    onChange(text.replace(/\s/g, ""));
  };
  renderTextInput = (field) => {
    const {
      meta: { touched, error },
      label,
      secureTextEntry,
      maxLength,
      keyboardType,
      placeholder,
      input: { onChange, ...restInput },
    } = field;
    return (
      <View>
        <InputText
          onChangeText={(text) => this.handleChange(text, onChange)}
          maxLength={maxLength}
          placeholder={placeholder}
          keyboardType={keyboardType}
          secureTextEntry={secureTextEntry}
          label={label}
          {...restInput}
        />
        {touched && error && <Text style={styles.errorText}>{error}</Text>}
      </View>
    );
  };
  currPasswordChange(status) {
    this.setState({ currPassStatus: !status });
  }
  passwordChange(status) {
    this.setState({ passStatus: !status });
  }

  confirmpasswordChange(status) {
    this.setState({ confirmpassStatus: !status });
  }

  _onPressButton = () => {
    // Actions.pop();
    this.props.navigation.pop();
  };

  render() {
    const { handleSubmit, loginUser } = this.props;
    var image_height;

    if (height < width) {
      image_height = 360;
    } else {
      image_height = height / 2.8;
    }

    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : null}
        keyboardVerticalOffset={keyboardVerticalOffset}
      >
        {Platform.OS === "ios" ? (
          <View style={[styles.statusBar]} />
        ) : (
          <StatusBar
            barStyle="light-content"
            backgroundColor={colors.LIGHT_BLUE}
          />
        )}
        {Platform.OS == "web" ? <HeaderComponent /> : null}
        <ScrollView
          keyboardShouldPersistTaps={"handled"}
          bounces={false}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.container}>
            {loginUser && loginUser.isLoading && <Loader />}
            <ImageBackground
              style={{
                height: image_height,
                width: width,
                alignItems: "center",
              }}
              source={
                Platform.OS == "web" ? resetpasswordImg : Images.creatPassword
              }
            >
              <TouchableOpacity
                onPress={this._onPressButton}
                style={{ marginLeft: 30, marginTop: 50, width: width }}
              >
                <View style={{ width: 30 }}>
                  {/* <Icon name="chevron-left" size={15} color="#fff" /> */}
                </View>
              </TouchableOpacity>
              <View style={styles.welcomeView}>
                <Text style={styles.welcomeText}>{this.state.textString}</Text>
              </View>
              {/* <View style={styles.welcomeView}><Text style={styles.welcomeText}>Reset Password</Text></View> */}
              <View style={styles.subview}>
                <Text style={styles.subtext}>
                  Enter your new password below. {"\n"}Your password must
                  contain at least 8 characters with 1 number and 1 capital
                  letter.
                </Text>
              </View>
              <View style={[styles.loginView, { marginTop: 10 }]}>
                <Image style={styles.mailImage} source={Images.passwordlock} />
                <Field
                  name="currentpassword"
                  placeholder="Current Password"
                  secureTextEntry={this.state.currPassStatus}
                  component={this.renderTextInput}
                />
                <TouchableOpacity
                  onPress={() =>
                    this.currPasswordChange(this.state.currPassStatus)
                  }
                >
                  <Image
                    style={styles.eyeImage}
                    source={
                      this.state.currPassStatus === true
                        ? Images.unViewIcon
                        : Images.eyeIcon
                    }
                  />
                </TouchableOpacity>
              </View>
              <View style={[styles.loginView]}>
                <Image style={styles.mailImage} source={Images.passwordlock} />
                <Field
                  name="newpassword"
                  placeholder="New Password"
                  secureTextEntry={this.state.passStatus}
                  component={this.renderTextInput}
                />
                <TouchableOpacity
                  onPress={() => this.passwordChange(this.state.passStatus)}
                >
                  <Image
                    style={styles.eyeImage}
                    source={
                      this.state.passStatus === true
                        ? Images.unViewIcon
                        : Images.eyeIcon
                    }
                  />
                </TouchableOpacity>
              </View>

              <View style={[styles.loginView, { marginBottom: 40 }]}>
                <Image style={styles.mailImage} source={Images.passwordlock} />
                <Field
                  name="confirmpassword"
                  placeholder="Confirm Password"
                  secureTextEntry={this.state.confirmpassStatus}
                  component={this.renderTextInput}
                />
                <TouchableOpacity
                  onPress={() =>
                    this.confirmpasswordChange(this.state.confirmpassStatus)
                  }
                >
                  <Image
                    style={styles.eyeImage}
                    source={
                      this.state.confirmpassStatus === true
                        ? Images.unViewIcon
                        : Images.eyeIcon
                    }
                  />
                </TouchableOpacity>
              </View>
              <TouchableOpacity
                style={styles.button}
                onPress={handleSubmit(this.onSubmit)}
              >
                <Text style={styles.buttonText}>Done</Text>
              </TouchableOpacity>
            </ImageBackground>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// const validate = values => {
//   var reg = /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
//   const errors = {};
//   if (!values.currentpassword) {
//     errors.currentpassword = "Required";
//   }
//   if (!values.newpassword) {
//     errors.newpassword = "Required";
//   } else if (!reg.test(values.newpassword)) {
//     errors.newpassword =
//       "At least 8 characters with 1 number and 1 capital letter required.";
//   }
//   if (!values.confirmpassword) {
//     errors.confirmpassword = "Required";
//   } else if (values.confirmpassword !== values.newpassword) {
//     errors.confirmpassword = "Password mismatched";
//   }
//   return errors;
// };

const mapStateToProps = (state) => ({
  loginUser: state.authReducer.loginUser,
  getUser: state.userReducer.getUser,
  authData: state.authReducer.authData,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "resetpassword",
    // validate
  })
)(ChangePassword);
