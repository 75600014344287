import { get } from 'lodash';
import React from 'react'
import {
  View,
  TouchableOpacity,
  Text,
  Image,
  FlatList,
  Platform,
  Modal,
  Dimensions,
  Alert,
  useWindowDimensions,
  ScrollView,
  Linking,
  ImageBackground,
} from "react-native";
import { heightPercentageToDP } from 'react-native-responsive-screen';
import { family, Images } from '../../common';
import helpers from '../../common/helpers';
import { styles } from "./styles";

export default function DealsComponent({ appPromos }) {
  return (
    <>
      <FlatList
        data={appPromos}
        ListEmptyComponent={() => (
          <Text style={{ alignSelf: 'center', marginTop: 50, fontFamily: family.roboto, fontSize: heightPercentageToDP('1.8%') }}>
            No Deals available for now
          </Text>
        )}
        renderItem={({ item, index }) => (
          <TouchableOpacity onPress={() => Linking.openURL(get(item, 'offer_url', ''))} activeOpacity={0.5}>
            <View style={styles.VisaTitleView}>
              <Image source={{ uri: get(item, 'logo_url', '') }} style={styles.dealImg} />
              <View style={styles.visaNameView}>
                <Text numberOfLines={1} style={styles.visaName}>
                  {get(item, 'offer_title', '')}
                </Text>
                <Text numberOfLines={2} style={[styles.desText, { fontSize: heightPercentageToDP('1.2%') }]}>
                  {get(item, 'offer_description', '')}
                </Text>
                {
                  item.coupon_code ?
                    <ImageBackground
                      resizeMode='contain'
                      source={Images.dottedrect} style={styles.couponImg}>
                      <Text numberOfLines={2} style={styles.coupon}>
                        {get(item, 'coupon_code', 'VISA50')}
                      </Text>
                    </ImageBackground> : null
                }
              </View>
            </View>
          </TouchableOpacity>
        )}
      />
    </>
  )
}
