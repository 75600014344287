import AsyncStorage from '@react-native-community/async-storage';
// import analytics from '@react-native-firebase/analytics';
import { Config } from "../common";


export const logEvent = (eventType, data) => {
	if(Config.app_mode != "debug"){
		return;
	}

	const eventsToExpound = ["about_us_entry", "attempt_login", "reset_password_entry", "terms_disclaimer_accept", "otp_entry", "otp_success", "terms_of_use_view", "disclaimer_view", "asked_qns_entry", "discussion_options_lookup", "submit_advice_qn", "search_discussion_options", "home_page_click", "main_dashboard_entry", "path_country_selection", "path_goal_selection", "profile_page_entry", "chat_message_sent", "submit_user_feedback", "open_chat", "advice_channel_entry", "select_chat_review", "submit_chat_review", "update_chat_review", "about_us_entry", "path_exploration_entry", "news_stories_entry", "select_story", "select_news", "filter_news", "search_news", "search_stories", "filter_stories", "select_news_tab", "select_stories_tab", "share_news_stories"];

	return async (dispatch) => {

		if(eventsToExpound.includes(eventType)){
			const value = await AsyncStorage.getItem("@auth_token");
			const username = await AsyncStorage.getItem("@user_name");
			const splitToken = value.split("@");
			data["user_id"] = splitToken[0];
			data["token"] = splitToken[1];
			data["user_name"] = username;
		}


	    try {
	        // await analytics().logEvent(event_type, data);
	    } catch (error) {
	        // console.log("Error trying to log for event: "+event_type, error);
	    }
	}
 };
