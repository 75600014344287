import React, { useEffect, useState } from "react";
import { View, TouchableOpacity, Text, Alert } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails, getUserDetails } from "../../actions/auth.actions";
import { countriesNextClickedStatus } from "../../actions/path.actions";
import { styles } from "./tooltip.styles";
import { handleDashoardProfileFinish, showBottomNavTutorial,
   handleMediatabNext, handleResultComparisonNext } from "../../actions/CopilotStates.actions";
import { Network } from "../../service/api";

export const TooltipComponent = ({
  isFirstStep,
  isLastStep,
  handleNext,
  handlePrev,
  handleStop,
  currentStep,
}) => {
  const stpesArr = ['mediaTab', 'profile']
  const dispatch = useDispatch();
  const [shownItems, setshownItems] = useState([])
  const userDetails = useSelector(
    (state) => state.userReducer.getUser.userDetails
  );
  const Token = useSelector((state) => state.authReducer.authData.token);
  const handleYes = () => {
    let postData = userDetails;
    AsyncStorage.setItem("DontShowTutorial", JSON.stringify(true)),
      (postData.is_help_active = false);
    handleStop();
    dispatch(updateUserDetails(postData, userDetails?.userid, Token));
  };

  useEffect(() => {
    const getShownItem = async () => {
      let _itemShown = await AsyncStorage.getItem('TutorialItemsShown');
      if(_itemShown && JSON.parse(_itemShown).length > 0) {
        setshownItems(JSON.parse(_itemShown))
      }
    }
    getShownItem()
  }, [])
  
  const handleDiscardTutorial = () => {
    Alert.alert(
      "",
      "Are you sure you don’t want a tutorial on how to use the app?",
      [
        {
          text: "Yes",
          onPress: () => handleYes(),
        },
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
      ],
      { cancelable: false }
    );
  };

  const handleNextClick = async() => {
    if(currentStep.name == 'notification') {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: userDetails?.userid + "@" + Token,
      };
      let paylaod = {
        is_help_active: false
      }
      const url = "/users/" + userDetails?.userid;
      const e = await Network(url, "PATCH", paylaod, headers);
      // dispatch(getUserDetails(userDetails?.userid, Token))
      handleNext();
    } else if (currentStep.name == 'mediaTab') {
      handleNext();
    } else if(currentStep.name == 'Shortlist Results') {
      dispatch(handleResultComparisonNext())
     setTimeout(() => {
      handleNext();
     }, 500);
    } else if(currentStep.name == 'profile') {
      handleFinish();
     setTimeout(() => {
      dispatch(showBottomNavTutorial())
      dispatch(handleDashoardProfileFinish())
     }, 500);
    } else {
      handleNext();
    }
  }; 

  const handleFinish = async() => {
    let items = [...shownItems];
    items.push(currentStep.name)
    AsyncStorage.setItem('TutorialItemsShown', JSON.stringify(items))
    handleStop()
  }

  return (
    <View style={styles.tooltipContainer}>
      <Text testID="stepDescription" style={styles.tooltipText}>
        {currentStep.text}
      </Text>
      <View style={[styles.bottomBar]}>
        {!isFirstStep ? (
          <TouchableOpacity style={styles.toolTipButton} onPress={handlePrev}>
            <Text style={styles.toolTipButtonText}>{"Previous"}</Text>
          </TouchableOpacity>
        ) : null}
        {!isLastStep || stpesArr.includes(currentStep.name) ? (
          <TouchableOpacity
            style={styles.toolTipButton}
            onPress={handleNextClick}
          >
            <Text style={styles.toolTipButtonText}>{"Next"}</Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={styles.toolTipButton} onPress={handleFinish}>
            <Text style={styles.toolTipButtonText}>{"Finish"}</Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity onPress={handleDiscardTutorial}>
          {
            currentStep.order == 3 ?
            <Text style={styles.toolTipButtonText}>{`Do not\nshow tutorial`}</Text> :
            <Text style={styles.toolTipButtonText}>{`Do not show tutorial`}</Text>
          }
        </TouchableOpacity>
      </View>
    </View>
  );
};
