import React from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  Modal,
} from "react-native";
import { Images, helpers } from "./../../common";
import { styles } from "./CostOfLiving.styles";

export default function CardDetailsModal({
  selectedCard,
  visible,
  selectedCurrency,
  onClose,
}) {
  return (
    <Modal transparent animationType="fade" visible={visible}>
      <View style={styles.modalmainView}>
        <View style={styles.modalinnerView}>
          <TouchableOpacity
            onPress={onClose}
            style={styles.closeIconButton}
            hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
          >
            <Image source={Images.closeBlack} style={styles.closeIcon} />
          </TouchableOpacity>
          <Text style={styles.modalTitle}>{selectedCard.section}</Text>
          {selectedCard?.items?.map((s) => (
            <View style={styles.detailsView}>
              <Text style={styles.detailsLabel}>
                {`${s.category}:  ${s.label}`}
              </Text>
              <Text style={styles.detailsText}>
                {helpers.getCurrencySymbol(selectedCurrency) +
                  " " +
                  helpers
                    .currencyConverter(selectedCurrency, s.totalCost)
                    .toFixed(2)}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </Modal>
  );
}
