import { StyleSheet } from "react-native";
import { family } from "../../../../../../common";

export const styles = StyleSheet.create({
  addToMyListButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F1AB32",
    borderRadius: 10,
    marginBottom: 10,
  },
  saveCityButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0F1143",
    borderRadius: 10,
  },
  bookmarkIcon: {
    height: 20,
    width: 20,
  },
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(20,20,20,0.8)",
  },
  list: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  univNameText: {
    fontFamily: family.effra,
    fontSize: 17,
  },
  imageView: {
    backgroundColor: 'white',
    alignItems: 'center',
    height: 85,
    width: 85,
    borderWidth: 0.5,
    borderColor: '#BDBDBD',
    justifyContent: 'center'
  },
  univNameText2: {
    flexGrow: 2,
    fontFamily: family.effra,
    fontSize: 17,
    marginLeft: 10
  },
  univIcon: {
    fontSize: 20,
    fontFamily: family.robotoBlack,
    color: '#800000'
  },
  infoWrapper: {
    width: "70%",
    marginLeft: 15,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  univImage: {
    height: 70,
    width: 70,
    borderRadius: 15,
  },
  modal: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    width: "90%",
  },

  closeIconButton: {
    width: 20,
    height: 20,
  },

  closeIcon: {
    width: "100%",
    height: "100%",
  },

  closeWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  image: {
    width: "100%",
    height: 200,
    marginTop: 25,
    marginBottom: 25,
  },

  textTitle: {
    marginBottom: 15,
    textAlign: "center",
    width: "80%",
    fontSize: 18,
    marginLeft: "10%",
    fontFamily: family.titilliumBold,
    textTransform: "uppercase",
  },
  text: {
    marginBottom: 15,
    textAlign: "left",
    fontFamily: family.roboto,
  },
  detailRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  label: {
    fontWeight: '700',
    marginBottom: 15,
  },
  premiumButtonText: {
    color: "white",
    padding: 15,
    fontFamily: family.effra,
  },
});
