import React from "react";
import {
    View,
    Text,
} from "react-native";
import { styles } from "./CircleProgress.styles";
import CircleProgressBar from "../../../../../CirlcleProgressBar/CircleProgressBar";

export const CircleProgress = ({ progressPercentage }) => {
    return (
        <View style={[styles.percentageInfoWrapper]}>
            <CircleProgressBar progressPercentage={progressPercentage}>
                {(fill)=>(
                <View style={[styles.perInfoWrapper]}>
                    <View style={[styles.perWrapper]}>
                        <Text style={[styles.completePercentageText]}>
                            {Math.floor(fill)}
                        </Text>
                        <Text>%</Text>
                    </View>
                    <Text style={[styles.completeText]}>Complete</Text>
                </View>
                )}
            </CircleProgressBar>
        </View>
    );
};
