import Image from "./images";
import usaHighlighted from "../images/usa1.png";
import ukHighlighted from "../images/uk1.png";
import singaporeHighlighted from "../images/singapore1.png";
import ausHighlighted from "../images/aus1.png";

import japanHighlighted from "../images/japan1.png";
import nzHighlighted from "../images/nzHighlighted.png";
import NZflag3 from "../images/NZflag3.png";
import germanyHighlighted from "../images/germany1.png";
import netherlandHighlighted from "../images/netherland1.png";
import uaeHighlighted from "../images/uae1.png";

import USAFlag from "../images/usa2.png";
import Ausflag from "../images/aus2.png";
import singaporeFlag from "../images/singapore2.png";
import UKFLag from "../images/uk2.png";
import canadaT2 from "../images/canadaT2.png";
import canadaT1 from "../images/canadaT1.png";

import UAEFLAG from "../images/uae2.png";
import GermanyFlag from "../images/germany2.png";
import netherlanFlag from "../images/netherland2.png";
import NZFlag from "../images/nz2.png";
import JapanFlag from "../images/japan2.png";

export default {
  app_mode: "debug",
  timelineStages: [
    {
      name: 'Pre Application',
      color: 'green'
    },
    {
      name: 'Post Application',
      color: 'blue'
    },
    {
      name: 'Waiting stage',
      color: 'grey'
    },
    {
      name: 'Pre Approval',
      color: 'red'
    },
    {
      name: 'Post Approval',
      color: 'orange'
    },
  ],
  country_redidency_status: [
    "Visitor",
    "Student",
    "Worker",
    "PR",
    "Citizen"],
  countries_json: [
    {
      name: "India",
      id: "IN",
      currency: "INR",
    },
    {
      name: "Australia",
      id: "AU",
      currency: "AUD",
    },
    {
      name: "China",
      id: "CN",
      currency: "CNY",
    },
    {
      name: "France",
      id: "FR",
      currency: "EUR",
    },
    {
      name: "Germany",
      id: "DE",
      currency: "EUR",
    },
    {
      name: "Hong Kong",
      id: "HK",
      currency: "CNH",
    },
    {
      name: "Japan",
      id: "JP",
      currency: "JPY",
    },
    {
      name: "Malaysia",
      id: "MY",
      currency: "MYR",
    },
    {
      name: "Singapore",
      id: "SG",
      currency: "SGD",
    },
    {
      name: "Sri Lanka",
      id: "LK",
      currency: "LKR",
    },
    {
      name: "United Kingdom",
      id: "GB",
      currency: "GBP",
    },
    {
      name: "United States",
      id: "US",
      currency: "USD",
    },
    {
      name: "United Arab Emirates",
      id: "AE",
      currency: "AED",
    },
    {
      name: "Saudi Arabia",
      id: "SA",
      currency: "SAR",
    },
    {
      name: "New Zealand",
      id: "NZ",
      currency: "NZD",
    },
    {
      name: "Canada",
      id: "CA",
      currency: "CAD",
    },
    {
      name: "Kuwait",
      id: "KW",
      currency: "KWD",
    },
    {
      name: "Oman",
      id: "OM",
      currency: "OMR",
    },
    {
      name: "Qatar",
      id: "QA",
      currency: "QAR",
    },
    {
      name: "Netherlands",
      id: "NL",
      currency: "EUR",
    },
  ],
  intro: [
    {
      key: "page1",
      title: "Welcome!",
      text: "Get the latest and most relevant immigration news and stories.",
      images: Image.Intro.Welcome,
      // icon: "ios-basket-outline",
      // colors: "#0FF0B3",
    },
    {
      key: "page2",
      title: "To Immigreat",
      text: "See all available immigration options and compare, understand and choose the paths that makes most sense for you.",
      images: Image.Intro.Map,
      // icon: "ios-card-outline",
      // colors: ["#13f1fc", "#0470dc"],
    },
    {
      key: "page3",
      title: "Start Your Journey",
      text: "Finally, get connected with the immigration solutions that can help you achieve your dreams with the click of a button!",
      images: Image.Intro.Journey,
      // icon: "ios-finger-print-outline",
      // colors: ["#b1ea4d", "#459522"],
    },
  ],
  adviceData: [
    {
      name: "Lawyer",
      description: "Legal expert in immigration",
    },
    {
      name: "Immigration Agent",
      description: "Ask general questions about immigration",
    },
    {
      name: "Visa Specialist",
      description: "Ask any questions about visas or your chance of getting it",
    },
  ],
  basic_plan: [
    {
      desciption:
        "Get super reliable pro advice in less that a week at the lowest price.",
    },
    {
      desciption:
        "Our intelligent problem resolution system will determine the advisor who is best suited to answer your doubts, thus choosing the cost effective option for you.",
    },
    {
      desciption: "Don't forget to avail your discount while checking out.",
    },
    {
      desciption:
        "You can earn immibucks by simply using different features of the app. Go to profile to find out different rewards available.",
    },
  ],
  premium_plan: [
    {
      desciption: "Get expert advice with 2 follow up questions",
    },
    {
      desciption: "Get your questions answered within 24hrs.",
    },
    {
      desciption:
        "If the expert is unable to answer your question in one business day then your full payment will be refunded automatically.",
    },
  ],
  frequentlyAsked: [
    // this is the parent or 'item'
    {
      name: "Canada Immigration",
      id: 0,
      // these are the children or 'sub items'
      children: [
        {
          name: "Can I legally immigrate to Canada if I don’t have a status in my country of residence?",
          id: 1,
        },
      ],
    },
    {
      name: "Canada-Residency Immigration",
      id: 1,
      // these are the children or 'sub items'
      children: [
        {
          name: "How can I become a permanent resident of Canada?",
          id: 2,
        },
        {
          name: "Once I am a permanent resident of Canada, do I have to stay in Canada?",
          id: 3,
        },
        {
          name: "How can I determine if I am eligible to become a permanent resident of Canada/to sponsor a family member to become a permanent resident of Canada?",
          id: 4,
        },
        {
          name: "What are the general tax implications of acquiring Canadian permanent residence?",
          id: 5,
        },
      ],
    },
    {
      name: "Canada-Study Immigration",
      id: 2,
      children: [
        {
          name: "What are my options/restrictions on working in Canada after studying on a study permit?",
          id: 6,
        },
      ],
    },
    {
      name: "Australia-Tourism",
      id: 3,
      children: [
        {
          name: "I am planning a holiday for more than three months in Australia. What should I do?",
          id: 7,
        },
      ],
    },
    {
      name: "Australia-study",
      id: 4,
      children: [
        {
          name: "What visa do I have to apply for to study as an exchange student in Australia? What are the requirements and procedure??",
          id: 8,
        },
      ],
    },
    {
      name: "USA immigration",
      id: 5,
      // these are the children or 'sub items'
      children: [
        {
          name: "My passport has expired but my visa to travel to the US is still valid. Do I need to apply for the visa again with a new password?",
          id: 9,
        },
        {
          name: "What do I do if I’ve received a deportation order?",
          id: 10,
        },
        {
          name: "What are the options for bringing my family members to the U.S?",
          id: 11,
        },
      ],
    },
    {
      name: "USA-Residency Immigration",
      id: 6,
      // these are the children or 'sub items'
      children: [
        {
          name: "How long after my marriage can I apply for a green card?",
          id: 12,
        },
        {
          name: "What are the income requirements for a marriage visa?",
          id: 13,
        },
        {
          name: "How do I become a U.S. citizen?",
          id: 14,
        },
        {
          name: "Can I get a green card by investing in a U.S. company?",
          id: 15,
        },
      ],
    },
    {
      name: "USA-Work Immigration",
      id: 7,
      // these are the children or 'sub items'
      children: [
        {
          name: "Can my employer sponsor me for a visa and will it help improve my chances of getting it approved?",
          id: 16,
        },
      ],
    },
    {
      name: "USA-Tourism Immigration",
      id: 8,
      // these are the children or 'sub items'
      children: [
        {
          name: "I faced difficulties with getting my B2 Visa approved. How do I qualify for a visa to take a vacation in the U.S?",
          id: 17,
        },
        {
          name: "What is the eligibility criteria and process to extend my B2 Visitor’s Visa?",
          id: 18,
        },
      ],
    },
    {
      name: "USA-Study Immigration",
      id: 9,
      // these are the children or 'sub items'
      children: [
        {
          name: "What are the restrictions I will face on a J1 Visa? Is there any way to avoid it?",
          id: 19,
        },
        {
          name: "How can I stay in the US after my F1 visa expires?",
          id: 20,
        },
      ],
    },
    {
      name: "UK-Residency Immigration",
      id: 10,
      children: [
        {
          name: "Is it difficult to get PR in the UK? I am planning to take a UK student visa?",
          id: 21,
        },
        {
          name: "How long will it take me to get UK permanent residency? Does the UK have financial aid if you want to study there?",
          id: 22,
        },
      ],
    },
    {
      name: "UK-Tourism Immigration",
      id: 11,
      children: [
        {
          name: "I'm touring the US from India on a B1/B2 tourist visa currently. I'm planning to tour the UK on the way back to India. Am I allowed to apply for a UK visa from the US with a US B1/B2 tourist visa stamp? I know people with H1B work visas can apply for a UK tourist visa while in the US but I'm not sure if the B1/B2 stamp is eligible.",
          id: 23,
        },
      ],
    },
    {
      name: "UK-Study Immigration",
      id: 12,
      // these are the children or 'sub items'
      children: [
        {
          name: "I am intending to study in the UK. I am going to apply for a visa. So I would like to know what the documents needed as a proof of income source. Consider we have business, we have got trade licence and everything except the business tax assessment (I.e. tax return copy for business ). So is it necessary to have a tax return copy for applying in the UK for student visa.",
          id: 24,
        },
      ],
    },
    {
      name: "UK-Work Immigration",
      id: 13,
      // these are the children or 'sub items'
      children: [
        {
          name: "If I am already in the UK on a work permit, will I need to leave the country if I want to change employers?",
          id: 25,
        },
        {
          name: "Can I work in a self-employed capacity while in the UK under a work permit?",
          id: 26,
        },
        {
          name: "What happens if a company gets a work permit to employ me for two years and then does not employ me for the full period of the work permit?",
          id: 27,
        },
        {
          name: "What is the job skill level required for initial application for Tier 2 General visa category?",
          id: 28,
        },
        {
          name: "The Youth Mobility visa guidance says that I can only apply if I am entering the UK within three months of the application date — but what if I want/need to apply earlier?",
          id: 29,
        },
      ],
    },
    {
      name: "Germany-Work Immigration",
      id: 14,
      // these are the children or 'sub items'
      children: [
        {
          name: "I have a valid visa and I already entered Germany but went back to India. Can the embassy provide me with a new or an extension of my current visa since it will expire before I will be able to travel back to Germany?",
          id: 30,
        },
        {
          name: "I am an entrepreneur and would like to employ a qualified professional from abroad. What points do I have to take into consideration?",
          id: 31,
        },
      ],
    },
    {
      name: "Germany-Study Immigration",
      id: 15,
      // these are the children or 'sub items'
      children: [
        {
          name: "What is the minimum balance required to obtain a German Student Visa?",
          id: 32,
        },
        {
          name: "Can you work in Germany with a Student Pass/visa?",
          id: 33,
        },
        {
          name: "Can spouse and child travel with students to Germany?",
          id: 34,
        },
      ],
    },
    {
      name: "Germany-Residency Immigration",
      id: 16,
      // these are the children or 'sub items'
      children: [
        {
          name: "Where do I register my place of residence and what documents do I need?",
          id: 35,
        },
        {
          name: "How do I know if my profession is eligible for the EU Blue card? (We automatically generate the profession while sending it to the consultant?",
          id: 36,
        },
      ],
    },
    {
      name: "Germany Immigration",
      id: 17,
      // these are the children or 'sub items'
      children: [
        {
          name: "I have a valid Schengen visa issued by the German Embassy. Can I use it to travel to other countries in Europe? Do I have to enter and leave Germany or is this also possible elsewhere?",
          id: 37,
        },
        {
          name: "I am a spouse/child of a German or EU/EEA citizen and require a visa. How do I apply for a Schengen visa?",
          id: 38,
        },
        {
          name: "We are travelling with a minor child, what additional documents do we require?",
          id: 39,
        },
        {
          name: " I do not have sufficient funds for my trip. Can the person inviting me finance my trip and stay with an undertaking?",
          id: 40,
        },
      ],
    },
    {
      name: "Singapore-Study Immigration",
      id: 18,
      // these are the children or 'sub items'
      children: [
        {
          name: "How can we ensure whether the degrees offered by the Private Education Institutes of Singapore are globally recognised or not?",
          id: 41,
        },
        {
          name: "Can international students at the local universities or polytechnics work part-time during the term time?",
          id: 42,
        },
        {
          name: "Can international students work during vacation without a work pass?",
          id: 43,
        },
      ],
    },
    {
      name: "Singapore-Work Immigration",
      id: 19,
      // these are the children or 'sub items'
      children: [
        {
          name: "Do Singapore work visa holders need to contribute to CPF (social security fund)?",
          id: 44,
        },
        {
          name: " Is a medical test required when applying for a work pass in Singapore?",
          id: 45,
        },
        {
          name: "What is the minimum salary that a PEP holder needs to earn annually in Singapore?",
          id: 46,
        },
      ],
    },
    {
      name: "Singapore-Residency Immigration",
      id: 20,
      // these are the children or 'sub items'
      children: [
        {
          name: "Can a PEP (Personalised Employment Pass) holder apply for Singapore Permanent Residence?",
          id: 47,
        },
        {
          name: "Will there be any change to the assessment criteria for PR application anytime soon?",
          id: 48,
        },
      ],
    },
    {
      name: "Singapore-Tourism Immigration",
      id: 21,
      // these are the children or 'sub items'
      children: [
        {
          name: "Do I need travel insurance to get a Singapore Tourist Visa?",
          id: 49,
        },
      ],
    },
  ],
  referrals: [
    { name: "Instagram", id: 1 },
    { name: "Referral", id: 2 },
    { name: "Word of mouth", id: 3 },
    { name: "LinkedIn", id: 4 },
    { name: "Facebook", id: 5 },
    { name: "Others", id: 6 },
  ],
  countries: [
    { name: "Afghanistan", id: "AF" },
    { name: "Aland Islands", id: "AX" },
    { name: "Albania", id: "AL" },
    { name: "Algeria", id: "DZ" },
    { name: "American Samoa", id: "AS" },
    { name: "Andorra", id: "AD" },
    { name: "Angola", id: "AO" },
    { name: "Anguilla", id: "AI" },
    { name: "Antarctica", id: "AQ" },
    { name: "Antigua and Barbuda", id: "AG" },
    { name: "Argentina", id: "AR" },
    { name: "Armenia", id: "AM" },
    { name: "Aruba", id: "AW" },
    { name: "Australia", id: "AU" },
    { name: "Austria", id: "AT" },
    { name: "Azerbaijan", id: "AZ" },
    { name: "Bahamas", id: "BS" },
    { name: "Bahrain", id: "BH" },
    { name: "Bangladesh", id: "BD" },
    { name: "Barbados", id: "BB" },
    { name: "Belarus", id: "BY" },
    { name: "Belgium", id: "BE" },
    { name: "Belize", id: "BZ" },
    { name: "Benin", id: "BJ" },
    { name: "Bermuda", id: "BM" },
    { name: "Bhutan", id: "BT" },
    { name: "Bolivia", id: "BO" },
    { name: "Bosnia and Herzegovina", id: "BA" },
    { name: "Botswana", id: "BW" },
    { name: "Bouvet Island", id: "BV" },
    { name: "Brazil", id: "BR" },
    { name: "British Indian Ocean Territory", id: "IO" },
    { name: "Brunei Darussalam", id: "BN" },
    { name: "Bulgaria", id: "BG" },
    { name: "Burkina Faso", id: "BF" },
    { name: "Burundi", id: "BI" },
    { name: "Cambodia", id: "KH" },
    { name: "Cameroon", id: "CM" },
    { name: "Canada", id: "CA" },
    { name: "Cape Verde", id: "CV" },
    { name: "Cayman Islands", id: "KY" },
    { name: "Central African Republic", id: "CF" },
    { name: "Chad", id: "TD" },
    { name: "Chile", id: "CL" },
    { name: "China", id: "CN" },
    { name: "Christmas Island", id: "CX" },
    { name: "Cocos (Keeling) Islands", id: "CC" },
    { name: "Colombia", id: "CO" },
    { name: "Comoros", id: "KM" },
    { name: "Congo", id: "CG" },
    { name: "Congo, The Democratic Republic of the Congo", id: "CD" },
    { name: "Cook Islands", id: "CK" },
    { name: "Costa Rica", id: "CR" },
    { name: 'Cote D"Ivoire', id: "CI" },
    { name: "Croatia", id: "HR" },
    { name: "Cuba", id: "CU" },
    { name: "Cyprus", id: "CY" },
    { name: "Czech Republic", id: "CZ" },
    { name: "Denmark", id: "DK" },
    { name: "Djibouti", id: "DJ" },
    { name: "Dominica", id: "DM" },
    { name: "Dominican Republic", id: "DO" },
    { name: "Ecuador", id: "EC" },
    { name: "Egypt", id: "EG" },
    { name: "El Salvador", id: "SV" },
    { name: "Equatorial Guinea", id: "GQ" },
    { name: "Eritrea", id: "ER" },
    { name: "Estonia", id: "EE" },
    { name: "Ethiopia", id: "ET" },
    { name: "Falkland Islands (Malvinas)", id: "FK" },
    { name: "Faroe Islands", id: "FO" },
    { name: "Fiji", id: "FJ" },
    { name: "Finland", id: "FI" },
    { name: "France", id: "FR" },
    { name: "French Guiana", id: "GF" },
    { name: "French Polynesia", id: "PF" },
    { name: "French Southern Territories", id: "TF" },
    { name: "Gabon", id: "GA" },
    { name: "Gambia", id: "GM" },
    { name: "Georgia", id: "GE" },
    { name: "Germany", id: "DE" },
    { name: "Ghana", id: "GH" },
    { name: "Gibraltar", id: "GI" },
    { name: "Greece", id: "GR" },
    { name: "Greenland", id: "GL" },
    { name: "Grenada", id: "GD" },
    { name: "Guadeloupe", id: "GP" },
    { name: "Guam", id: "GU" },
    { name: "Guatemala", id: "GT" },
    { name: "Guernsey", id: "GG" },
    { name: "Guinea", id: "GN" },
    { name: "Guinea-Bissau", id: "GW" },
    { name: "Guyana", id: "GY" },
    { name: "Haiti", id: "HT" },
    { name: "Heard Island and Mcdonald Islands", id: "HM" },
    { name: "Holy See (Vatican City State)", id: "VA" },
    { name: "Honduras", id: "HN" },
    { name: "Hong Kong", id: "HK" },
    { name: "Hungary", id: "HU" },
    { name: "Iceland", id: "IS" },
    { name: "India", id: "IN" },
    { name: "Indonesia", id: "ID" },
    { name: "Iran, Islamic Republic Of", id: "IR" },
    { name: "Iraq", id: "IQ" },
    { name: "Ireland", id: "IE" },
    { name: "Isle of Man", id: "IM" },
    { name: "Israel", id: "IL" },
    { name: "Italy", id: "IT" },
    { name: "Jamaica", id: "JM" },
    { name: "Japan", id: "JP" },
    { name: "Jersey", id: "JE" },
    { name: "Jordan", id: "JO" },
    { name: "Kazakhstan", id: "KZ" },
    { name: "Kenya", id: "KE" },
    { name: "Kiribati", id: "KI" },
    { name: 'Korea, Democratic People"S Republic of Korea', id: "KP" },
    { name: "Korea, Republic of Korea", id: "KR" },
    { name: "Kuwait", id: "KW" },
    { name: "Kyrgyzstan", id: "KG" },
    { name: 'Lao People"S Democratic Republic', id: "LA" },
    { name: "Latvia", id: "LV" },
    { name: "Lebanon", id: "LB" },
    { name: "Lesotho", id: "LS" },
    { name: "Liberia", id: "LR" },
    { name: "Libyan Arab Jamahiriya", id: "LY" },
    { name: "Liechtenstein", id: "LI" },
    { name: "Lithuania", id: "LT" },
    { name: "Luxembourg", id: "LU" },
    { name: "Macao", id: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of Macedonia", id: "MK" },
    { name: "Madagascar", id: "MG" },
    { name: "Malawi", id: "MW" },
    { name: "Malaysia", id: "MY" },
    { name: "Maldives", id: "MV" },
    { name: "Mali", id: "ML" },
    { name: "Malta", id: "MT" },
    { name: "Marshall Islands", id: "MH" },
    { name: "Martinique", id: "MQ" },
    { name: "Mauritania", id: "MR" },
    { name: "Mauritius", id: "MU" },
    { name: "Mayotte", id: "YT" },
    { name: "Mexico", id: "MX" },
    { name: "Micronesia, Federated States of", id: "FM" },
    { name: "Moldova, Republic of", id: "MD" },
    { name: "Monaco", id: "MC" },
    { name: "Mongolia", id: "MN" },
    { name: "Montenegro", id: "ME" },
    { name: "Montserrat", id: "MS" },
    { name: "Morocco", id: "MA" },
    { name: "Mozambique", id: "MZ" },
    { name: "Myanmar", id: "MM" },
    { name: "Namibia", id: "NA" },
    { name: "Nauru", id: "NR" },
    { name: "Nepal", id: "NP" },
    { name: "Netherlands", id: "NL" },
    { name: "Netherlands Antilles", id: "AN" },
    { name: "New Caledonia", id: "NC" },
    { name: "New Zealand", id: "NZ" },
    { name: "Nicaragua", id: "NI" },
    { name: "Niger", id: "NE" },
    { name: "Nigeria", id: "NG" },
    { name: "Niue", id: "NU" },
    { name: "Norfolk Island", id: "NF" },
    { name: "Northern Mariana Islands", id: "MP" },
    { name: "Norway", id: "NO" },
    { name: "Oman", id: "OM" },
    { name: "Pakistan", id: "PK" },
    { name: "Palau", id: "PW" },
    { name: "Palestinian Territory, Occupied", id: "PS" },
    { name: "Panama", id: "PA" },
    { name: "Papua New Guinea", id: "PG" },
    { name: "Paraguay", id: "PY" },
    { name: "Peru", id: "PE" },
    { name: "Philippines", id: "PH" },
    { name: "Pitcairn", id: "PN" },
    { name: "Poland", id: "PL" },
    { name: "Portugal", id: "PT" },
    { name: "Puerto Rico", id: "PR" },
    { name: "Qatar", id: "QA" },
    { name: "Reunion", id: "RE" },
    { name: "Romania", id: "RO" },
    { name: "Russian Federation", id: "RU" },
    { name: "RWANDA", id: "RW" },
    { name: "Saint Helena", id: "SH" },
    { name: "Saint Kitts and Nevis", id: "KN" },
    { name: "Saint Lucia", id: "LC" },
    { name: "Saint Pierre and Miquelon", id: "PM" },
    { name: "Saint Vincent and the Grenadines", id: "VC" },
    { name: "Samoa", id: "WS" },
    { name: "San Marino", id: "SM" },
    { name: "Sao Tome and Principe", id: "ST" },
    { name: "Saudi Arabia", id: "SA" },
    { name: "Senegal", id: "SN" },
    { name: "Serbia", id: "RS" },
    { name: "Seychelles", id: "SC" },
    { name: "Sierra Leone", id: "SL" },
    { name: "Singapore", id: "SG" },
    { name: "Slovakia", id: "SK" },
    { name: "Slovenia", id: "SI" },
    { name: "Solomon Islands", id: "SB" },
    { name: "Somalia", id: "SO" },
    { name: "South Africa", id: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", id: "GS" },
    { name: "Spain", id: "ES" },
    { name: "Sri Lanka", id: "LK" },
    { name: "Sudan", id: "SD" },
    { name: "Surivalue", id: "SR" },
    { name: "Svalbard and Jan Mayen", id: "SJ" },
    { name: "Swaziland", id: "SZ" },
    { name: "Sweden", id: "SE" },
    { name: "Switzerland", id: "CH" },
    { name: "Syrian Arab Republic", id: "SY" },
    { name: "Taiwan, Province of China", id: "TW" },
    { name: "Tajikistan", id: "TJ" },
    { name: "Tanzania, United Republic of Tanzania", id: "TZ" },
    { name: "Thailand", id: "TH" },
    { name: "Timor-Leste", id: "TL" },
    { name: "Togo", id: "TG" },
    { name: "Tokelau", id: "TK" },
    { name: "Tonga", id: "TO" },
    { name: "Trinidad and Tobago", id: "TT" },
    { name: "Tunisia", id: "TN" },
    { name: "Turkey", id: "TR" },
    { name: "Turkmenistan", id: "TM" },
    { name: "Turks and Caicos Islands", id: "TC" },
    { name: "Tuvalu", id: "TV" },
    { name: "Uganda", id: "UG" },
    { name: "Ukraine", id: "UA" },
    { name: "United Arab Emirates", id: "AE" },
    { name: "United Kingdom", id: "GB" },
    { name: "United States", id: "US" },
    { name: "United States Minor Outlying Islands", id: "UM" },
    { name: "Uruguay", id: "UY" },
    { name: "Uzbekistan", id: "UZ" },
    { name: "Vanuatu", id: "VU" },
    { name: "Venezuela", id: "VE" },
    { name: "Viet Nam", id: "VN" },
    { name: "Virgin Islands, British", id: "VG" },
    { name: "Virgin Islands, U.S.", id: "VI" },
    { name: "Wallis and Futuna", id: "WF" },
    { name: "Western Sahara", id: "EH" },
    { name: "Yemen", id: "YE" },
    { name: "Zambia", id: "ZM" },
    { name: "Zimbabwe", id: "ZW" },
  ],
  secoundcountries: [
    { value: "", code: "NA" },
    { value: "Afghanistan", code: "AF" },
    { value: "land Islands", code: "AX" },
    { value: "Albania", code: "AL" },
    { value: "Algeria", code: "DZ" },
    { value: "American Samoa", code: "AS" },
    { value: "Andorra", code: "AD" },
    { value: "Angola", code: "AO" },
    { value: "Anguilla", code: "AI" },
    { value: "Antarctica", code: "AQ" },
    { value: "Antigua and Barbuda", code: "AG" },
    { value: "Argentina", code: "AR" },
    { value: "Armenia", code: "AM" },
    { value: "Aruba", code: "AW" },
    { value: "Australia", code: "AU" },
    { value: "Austria", code: "AT" },
    { value: "Azerbaijan", code: "AZ" },
    { value: "Bahamas", code: "BS" },
    { value: "Bahrain", code: "BH" },
    { value: "Bangladesh", code: "BD" },
    { value: "Barbados", code: "BB" },
    { value: "Belarus", code: "BY" },
    { value: "Belgium", code: "BE" },
    { value: "Belize", code: "BZ" },
    { value: "Benin", code: "BJ" },
    { value: "Bermuda", code: "BM" },
    { value: "Bhutan", code: "BT" },
    { value: "Bolivia", code: "BO" },
    { value: "Bosnia and Herzegovina", code: "BA" },
    { value: "Botswana", code: "BW" },
    { value: "Bouvet Island", code: "BV" },
    { value: "Brazil", code: "BR" },
    { value: "British Indian Ocean Territory", code: "IO" },
    { value: "Brunei Darussalam", code: "BN" },
    { value: "Bulgaria", code: "BG" },
    { value: "Burkina Faso", code: "BF" },
    { value: "Burundi", code: "BI" },
    { value: "Cambodia", code: "KH" },
    { value: "Cameroon", code: "CM" },
    { value: "Canada", code: "CA" },
    { value: "Cape Verde", code: "CV" },
    { value: "Cayman Islands", code: "KY" },
    { value: "Central African Republic", code: "CF" },
    { value: "Chad", code: "TD" },
    { value: "Chile", code: "CL" },
    { value: "China", code: "CN" },
    { value: "Christmas Island", code: "CX" },
    { value: "Cocos (Keeling) Islands", code: "CC" },
    { value: "Colombia", code: "CO" },
    { value: "Comoros", code: "KM" },
    { value: "Congo", code: "CG" },
    { value: "Congo, The Democratic Republic of the", code: "CD" },
    { value: "Cook Islands", code: "CK" },
    { value: "Costa Rica", code: "CR" },
    { value: 'Cote D"Ivoire', code: "CI" },
    { value: "Croatia", code: "HR" },
    { value: "Cuba", code: "CU" },
    { value: "Cyprus", code: "CY" },
    { value: "Czech Republic", code: "CZ" },
    { value: "Denmark", code: "DK" },
    { value: "Djibouti", code: "DJ" },
    { value: "Dominica", code: "DM" },
    { value: "Dominican Republic", code: "DO" },
    { value: "Ecuador", code: "EC" },
    { value: "Egypt", code: "EG" },
    { value: "El Salvador", code: "SV" },
    { value: "Equatorial Guinea", code: "GQ" },
    { value: "Eritrea", code: "ER" },
    { value: "Estonia", code: "EE" },
    { value: "Ethiopia", code: "ET" },
    { value: "Falkland Islands (Malvinas)", code: "FK" },
    { value: "Faroe Islands", code: "FO" },
    { value: "Fiji", code: "FJ" },
    { value: "Finland", code: "FI" },
    { value: "France", code: "FR" },
    { value: "French Guiana", code: "GF" },
    { value: "French Polynesia", code: "PF" },
    { value: "French Southern Territories", code: "TF" },
    { value: "Gabon", code: "GA" },
    { value: "Gambia", code: "GM" },
    { value: "Georgia", code: "GE" },
    { value: "Germany", code: "DE" },
    { value: "Ghana", code: "GH" },
    { value: "Gibraltar", code: "GI" },
    { value: "Greece", code: "GR" },
    { value: "Greenland", code: "GL" },
    { value: "Grenada", code: "GD" },
    { value: "Guadeloupe", code: "GP" },
    { value: "Guam", code: "GU" },
    { value: "Guatemala", code: "GT" },
    { value: "Guernsey", code: "GG" },
    { value: "Guinea", code: "GN" },
    { value: "Guinea-Bissau", code: "GW" },
    { value: "Guyana", code: "GY" },
    { value: "Haiti", code: "HT" },
    { value: "Heard Island and Mcdonald Islands", code: "HM" },
    { value: "Holy See (Vatican City State)", code: "VA" },
    { value: "Honduras", code: "HN" },
    { value: "Hong Kong", code: "HK" },
    { value: "Hungary", code: "HU" },
    { value: "Iceland", code: "IS" },
    { value: "India", code: "IN" },
    { value: "Indonesia", code: "ID" },
    { value: "Iran, Islamic Republic Of", code: "IR" },
    { value: "Iraq", code: "IQ" },
    { value: "Ireland", code: "IE" },
    { value: "Isle of Man", code: "IM" },
    { value: "Israel", code: "IL" },
    { value: "Italy", code: "IT" },
    { value: "Jamaica", code: "JM" },
    { value: "Japan", code: "JP" },
    { value: "Jersey", code: "JE" },
    { value: "Jordan", code: "JO" },
    { value: "Kazakhstan", code: "KZ" },
    { value: "Kenya", code: "KE" },
    { value: "Kiribati", code: "KI" },
    { value: 'Korea, Democratic People"S Republic of', code: "KP" },
    { value: "Korea, Republic of", code: "KR" },
    { value: "Kuwait", code: "KW" },
    { value: "Kyrgyzstan", code: "KG" },
    { value: 'Lao People"S Democratic Republic', code: "LA" },
    { value: "Latvia", code: "LV" },
    { value: "Lebanon", code: "LB" },
    { value: "Lesotho", code: "LS" },
    { value: "Liberia", code: "LR" },
    { value: "Libyan Arab Jamahiriya", code: "LY" },
    { value: "Liechtenstein", code: "LI" },
    { value: "Lithuania", code: "LT" },
    { value: "Luxembourg", code: "LU" },
    { value: "Macao", code: "MO" },
    {
      value: "Macedonia, The Former Yugoslav Republic of Macedonia",
      code: "MK",
    },
    { value: "Madagascar", code: "MG" },
    { value: "Malawi", code: "MW" },
    { value: "Malaysia", code: "MY" },
    { value: "Maldives", code: "MV" },
    { value: "Mali", code: "ML" },
    { value: "Malta", code: "MT" },
    { value: "Marshall Islands", code: "MH" },
    { value: "Martinique", code: "MQ" },
    { value: "Mauritania", code: "MR" },
    { value: "Mauritius", code: "MU" },
    { value: "Mayotte", code: "YT" },
    { value: "Mexico", code: "MX" },
    { value: "Micronesia, Federated States of", code: "FM" },
    { value: "Moldova, Republic of", code: "MD" },
    { value: "Monaco", code: "MC" },
    { value: "Mongolia", code: "MN" },
    { value: "Montenegro", code: "ME" },
    { value: "Montserrat", code: "MS" },
    { value: "Morocco", code: "MA" },
    { value: "Mozambique", code: "MZ" },
    { value: "Myanmar", code: "MM" },
    { value: "Namibia", code: "NA" },
    { value: "Nauru", code: "NR" },
    { value: "Nepal", code: "NP" },
    { value: "Netherlands", code: "NL" },
    { value: "Netherlands Antilles", code: "AN" },
    { value: "New Caledonia", code: "NC" },
    { value: "New Zealand", code: "NZ" },
    { value: "Nicaragua", code: "NI" },
    { value: "Niger", code: "NE" },
    { value: "Nigeria", code: "NG" },
    { value: "Niue", code: "NU" },
    { value: "Norfolk Island", code: "NF" },
    { value: "Northern Mariana Islands", code: "MP" },
    { value: "Norway", code: "NO" },
    { value: "Oman", code: "OM" },
    { value: "Pakistan", code: "PK" },
    { value: "Palau", code: "PW" },
    { value: "Palestinian Territory, Occupied", code: "PS" },
    { value: "Panama", code: "PA" },
    { value: "Papua New Guinea", code: "PG" },
    { value: "Paraguay", code: "PY" },
    { value: "Peru", code: "PE" },
    { value: "Philippines", code: "PH" },
    { value: "Pitcairn", code: "PN" },
    { value: "Poland", code: "PL" },
    { value: "Portugal", code: "PT" },
    { value: "Puerto Rico", code: "PR" },
    { value: "Qatar", code: "QA" },
    { value: "Reunion", code: "RE" },
    { value: "Romania", code: "RO" },
    { value: "Russian Federation", code: "RU" },
    { value: "RWANDA", code: "RW" },
    { value: "Saint Helena", code: "SH" },
    { value: "Saint Kitts and Nevis", code: "KN" },
    { value: "Saint Lucia", code: "LC" },
    { value: "Saint Pierre and Miquelon", code: "PM" },
    { value: "Saint Vincent and the Grenadines", code: "VC" },
    { value: "Samoa", code: "WS" },
    { value: "San Marino", code: "SM" },
    { value: "Sao Tome and Principe", code: "ST" },
    { value: "Saudi Arabia", code: "SA" },
    { value: "Senegal", code: "SN" },
    { value: "Serbia", code: "RS" },
    { value: "Seychelles", code: "SC" },
    { value: "Sierra Leone", code: "SL" },
    { value: "Singapore", code: "SG" },
    { value: "Slovakia", code: "SK" },
    { value: "Slovenia", code: "SI" },
    { value: "Solomon Islands", code: "SB" },
    { value: "Somalia", code: "SO" },
    { value: "South Africa", code: "ZA" },
    { value: "South Georgia and the South Sandwich Islands", code: "GS" },
    { value: "Spain", code: "ES" },
    { value: "Sri Lanka", code: "LK" },
    { value: "Sudan", code: "SD" },
    { value: "Surivalue", code: "SR" },
    { value: "Svalbard and Jan Mayen", code: "SJ" },
    { value: "Swaziland", code: "SZ" },
    { value: "Sweden", code: "SE" },
    { value: "Switzerland", code: "CH" },
    { value: "Syrian Arab Republic", code: "SY" },
    { value: "Taiwan, Province of China", code: "TW" },
    { value: "Tajikistan", code: "TJ" },
    { value: "Tanzania, United Republic of Tanzania", code: "TZ" },
    { value: "Thailand", code: "TH" },
    { value: "Timor-Leste", code: "TL" },
    { value: "Togo", code: "TG" },
    { value: "Tokelau", code: "TK" },
    { value: "Tonga", code: "TO" },
    { value: "Trinidad and Tobago", code: "TT" },
    { value: "Tunisia", code: "TN" },
    { value: "Turkey", code: "TR" },
    { value: "Turkmenistan", code: "TM" },
    { value: "Turks and Caicos Islands", code: "TC" },
    { value: "Tuvalu", code: "TV" },
    { value: "Uganda", code: "UG" },
    { value: "Ukraine", code: "UA" },
    { value: "United Arab Emirates", code: "AE" },
    { value: "United Kingdom", code: "GB" },
    { value: "United States", code: "US" },
    { value: "United States Minor Outlying Islands", code: "UM" },
    { value: "Uruguay", code: "UY" },
    { value: "Uzbekistan", code: "UZ" },
    { value: "Vanuatu", code: "VU" },
    { value: "Venezuela", code: "VE" },
    { value: "Viet Nam", code: "VN" },
    { value: "Virgin Islands, British", code: "VG" },
    { value: "Virgin Islands, U.S.", code: "VI" },
    { value: "Wallis and Futuna", code: "WF" },
    { value: "Western Sahara", code: "EH" },
    { value: "Yemen", code: "YE" },
    { value: "Zambia", code: "ZM" },
    { value: "Zimbabwe", code: "ZW" },
  ],
  homeCountries: [
    { name: "India", id: "IN" },
    { name: "Pakistan", id: "PK" },
    { name: "Bangladesh", id: "BD" },
    { name: "Singapore", id: "SG" },
    { name: "Malaysia", id: "MY" },
    { name: "Indonesia", id: "ID" },
    { name: "Phillipines", id: "PH" },
    { name: "Canada", id: "CA" },
    { name: "USA", id: "US" },
    { name: "Germany", id: "DE" },
    { name: "Russia", id: "RU" },
    { name: "UK", id: "UK" },
    { name: "Mexico", id: "MX" },
    { name: "China", id: "CN" },
    { name: "Brazil", id: "BR" },
  ],
  countriesSelect: [
    { name: "Afghanistan", id: "AF" },
    { name: "Aland Islands", id: "AX" },
    { name: "Albania", id: "AL" },
    { name: "Algeria", id: "DZ" },
    { name: "American Samoa", id: "AS" },
    { name: "Andorra", id: "AD" },
    { name: "Angola", id: "AO" },
    { name: "Anguilla", id: "AI" },
    { name: "Antarctica", id: "AQ" },
    { name: "Antigua and Barbuda", id: "AG" },
    { name: "Argentina", id: "AR" },
    { name: "Armenia", id: "AM" },
    { name: "Aruba", id: "AW" },
    { name: "Australia", id: "AU" },
    { name: "Austria", id: "AT" },
    { name: "Azerbaijan", id: "AZ" },
    { name: "Bahamas", id: "BS" },
    { name: "Bahrain", id: "BH" },
    { name: "Bangladesh", id: "BD" },
    { name: "Barbados", id: "BB" },
    { name: "Belarus", id: "BY" },
    { name: "Belgium", id: "BE" },
    { name: "Belize", id: "BZ" },
    { name: "Benin", id: "BJ" },
    { name: "Bermuda", id: "BM" },
    { name: "Bhutan", id: "BT" },
    { name: "Bolivia", id: "BO" },
    { name: "Bosnia and Herzegovina", id: "BA" },
    { name: "Botswana", id: "BW" },
    { name: "Bouvet Island", id: "BV" },
    { name: "Brazil", id: "BR" },
    { name: "British Indian Ocean Territory", id: "IO" },
    { name: "Brunei Darussalam", id: "BN" },
    { name: "Bulgaria", id: "BG" },
    { name: "Burkina Faso", id: "BF" },
    { name: "Burundi", id: "BI" },
    { name: "Cambodia", id: "KH" },
    { name: "Cameroon", id: "CM" },
    { name: "Canada", id: "CA" },
    { name: "Cape Verde", id: "CV" },
    { name: "Cayman Islands", id: "KY" },
    { name: "Central African Republic", id: "CF" },
    { name: "Chad", id: "TD" },
    { name: "Chile", id: "CL" },
    { name: "China", id: "CN" },
    { name: "Christmas Island", id: "CX" },
    { name: "Cocos (Keeling) Islands", id: "CC" },
    { name: "Colombia", id: "CO" },
    { name: "Comoros", id: "KM" },
    { name: "Congo", id: "CG" },
    { name: "Congo, The Democratic Republic of the", id: "CD" },
    { name: "Cook Islands", id: "CK" },
    { name: "Costa Rica", id: "CR" },
    { name: 'Cote D"Ivoire', id: "CI" },
    { name: "Croatia", id: "HR" },
    { name: "Cuba", id: "CU" },
    { name: "Cyprus", id: "CY" },
    { name: "Czech Republic", id: "CZ" },
    { name: "Denmark", id: "DK" },
    { name: "Djibouti", id: "DJ" },
    { name: "Dominica", id: "DM" },
    { name: "Dominican Republic", id: "DO" },
    { name: "Ecuador", id: "EC" },
    { name: "Egypt", id: "EG" },
    { name: "El Salvador", id: "SV" },
    { name: "Equatorial Guinea", id: "GQ" },
    { name: "Eritrea", id: "ER" },
    { name: "Estonia", id: "EE" },
    { name: "Ethiopia", id: "ET" },
    { name: "Falkland Islands (Malvinas)", id: "FK" },
    { name: "Faroe Islands", id: "FO" },
    { name: "Fiji", id: "FJ" },
    { name: "Finland", id: "FI" },
    { name: "France", id: "FR" },
    { name: "French Guiana", id: "GF" },
    { name: "French Polynesia", id: "PF" },
    { name: "French Southern Territories", id: "TF" },
    { name: "Gabon", id: "GA" },
    { name: "Gambia", id: "GM" },
    { name: "Georgia", id: "GE" },
    { name: "Germany", id: "DE" },
    { name: "Ghana", id: "GH" },
    { name: "Gibraltar", id: "GI" },
    { name: "Greece", id: "GR" },
    { name: "Greenland", id: "GL" },
    { name: "Grenada", id: "GD" },
    { name: "Guadeloupe", id: "GP" },
    { name: "Guam", id: "GU" },
    { name: "Guatemala", id: "GT" },
    { name: "Guernsey", id: "GG" },
    { name: "Guinea", id: "GN" },
    { name: "Guinea-Bissau", id: "GW" },
    { name: "Guyana", id: "GY" },
    { name: "Haiti", id: "HT" },
    { name: "Heard Island and Mcdonald Islands", id: "HM" },
    { name: "Holy See (Vatican City State)", id: "VA" },
    { name: "Honduras", id: "HN" },
    { name: "Hong Kong", id: "HK" },
    { name: "Hungary", id: "HU" },
    { name: "Iceland", id: "IS" },
    { name: "India", id: "IN" },
    { name: "Indonesia", id: "ID" },
    { name: "Iran, Islamic Republic Of", id: "IR" },
    { name: "Iraq", id: "IQ" },
    { name: "Ireland", id: "IE" },
    { name: "Isle of Man", id: "IM" },
    { name: "Israel", id: "IL" },
    { name: "Italy", id: "IT" },
    { name: "Jamaica", id: "JM" },
    { name: "Japan", id: "JP" },
    { name: "Jersey", id: "JE" },
    { name: "Jordan", id: "JO" },
    { name: "Kazakhstan", id: "KZ" },
    { name: "Kenya", id: "KE" },
    { name: "Kiribati", id: "KI" },
    { name: 'Korea, Democratic People"S Republic of', id: "KP" },
    { name: "Korea, Republic of", id: "KR" },
    { name: "Kuwait", id: "KW" },
    { name: "Kyrgyzstan", id: "KG" },
    { name: 'Lao People"S Democratic Republic', id: "LA" },
    { name: "Latvia", id: "LV" },
    { name: "Lebanon", id: "LB" },
    { name: "Lesotho", id: "LS" },
    { name: "Liberia", id: "LR" },
    { name: "Libyan Arab Jamahiriya", id: "LY" },
    { name: "Liechtenstein", id: "LI" },
    { name: "Lithuania", id: "LT" },
    { name: "Luxembourg", id: "LU" },
    { name: "Macao", id: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of Macedonia", id: "MK" },
    { name: "Madagascar", id: "MG" },
    { name: "Malawi", id: "MW" },
    { name: "Malaysia", id: "MY" },
    { name: "Maldives", id: "MV" },
    { name: "Mali", id: "ML" },
    { name: "Malta", id: "MT" },
    { name: "Marshall Islands", id: "MH" },
    { name: "Martinique", id: "MQ" },
    { name: "Mauritania", id: "MR" },
    { name: "Mauritius", id: "MU" },
    { name: "Mayotte", id: "YT" },
    { name: "Mexico", id: "MX" },
    { name: "Micronesia, Federated States of", id: "FM" },
    { name: "Moldova, Republic of", id: "MD" },
    { name: "Monaco", id: "MC" },
    { name: "Mongolia", id: "MN" },
    { name: "Montenegro", id: "ME" },
    { name: "Montserrat", id: "MS" },
    { name: "Morocco", id: "MA" },
    { name: "Mozambique", id: "MZ" },
    { name: "Myanmar", id: "MM" },
    { name: "Namibia", id: "NA" },
    { name: "Nauru", id: "NR" },
    { name: "Nepal", id: "NP" },
    { name: "Netherlands", id: "NL" },
    { name: "Netherlands Antilles", id: "AN" },
    { name: "New Caledonia", id: "NC" },
    { name: "New Zealand", id: "NZ" },
    { name: "Nicaragua", id: "NI" },
    { name: "Niger", id: "NE" },
    { name: "Nigeria", id: "NG" },
    { name: "Niue", id: "NU" },
    { name: "Norfolk Island", id: "NF" },
    { name: "Northern Mariana Islands", id: "MP" },
    { name: "Norway", id: "NO" },
    { name: "Oman", id: "OM" },
    { name: "Pakistan", id: "PK" },
    { name: "Palau", id: "PW" },
    { name: "Palestinian Territory, Occupied", id: "PS" },
    { name: "Panama", id: "PA" },
    { name: "Papua New Guinea", id: "PG" },
    { name: "Paraguay", id: "PY" },
    { name: "Peru", id: "PE" },
    { name: "Philippines", id: "PH" },
    { name: "Pitcairn", id: "PN" },
    { name: "Poland", id: "PL" },
    { name: "Portugal", id: "PT" },
    { name: "Puerto Rico", id: "PR" },
    { name: "Qatar", id: "QA" },
    { name: "Reunion", id: "RE" },
    { name: "Romania", id: "RO" },
    { name: "Russian Federation", id: "RU" },
    { name: "RWANDA", id: "RW" },
    { name: "Saint Helena", id: "SH" },
    { name: "Saint Kitts and Nevis", id: "KN" },
    { name: "Saint Lucia", id: "LC" },
    { name: "Saint Pierre and Miquelon", id: "PM" },
    { name: "Saint Vincent and the Grenadines", id: "VC" },
    { name: "Samoa", id: "WS" },
    { name: "San Marino", id: "SM" },
    { name: "Sao Tome and Principe", id: "ST" },
    { name: "Saudi Arabia", id: "SA" },
    { name: "Senegal", id: "SN" },
    { name: "Serbia", id: "RS" },
    { name: "Seychelles", id: "SC" },
    { name: "Sierra Leone", id: "SL" },
    { name: "Singapore", id: "SG" },
    { name: "Slovakia", id: "SK" },
    { name: "Slovenia", id: "SI" },
    { name: "Solomon Islands", id: "SB" },
    { name: "Somalia", id: "SO" },
    { name: "South Africa", id: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", id: "GS" },
    { name: "Spain", id: "ES" },
    { name: "Sri Lanka", id: "LK" },
    { name: "Sudan", id: "SD" },
    { name: "Surivalue", id: "SR" },
    { name: "Svalbard and Jan Mayen", id: "SJ" },
    { name: "Swaziland", id: "SZ" },
    { name: "Sweden", id: "SE" },
    { name: "Switzerland", id: "CH" },
    { name: "Syrian Arab Republic", id: "SY" },
    { name: "Taiwan, Province of China", id: "TW" },
    { name: "Tajikistan", id: "TJ" },
    { name: "Tanzania, United Republic of Tanzania", id: "TZ" },
    { name: "Thailand", id: "TH" },
    { name: "Timor-Leste", id: "TL" },
    { name: "Togo", id: "TG" },
    { name: "Tokelau", id: "TK" },
    { name: "Tonga", id: "TO" },
    { name: "Trinidad and Tobago", id: "TT" },
    { name: "Tunisia", id: "TN" },
    { name: "Turkey", id: "TR" },
    { name: "Turkmenistan", id: "TM" },
    { name: "Turks and Caicos Islands", id: "TC" },
    { name: "Tuvalu", id: "TV" },
    { name: "Uganda", id: "UG" },
    { name: "Ukraine", id: "UA" },
    { name: "United Arab Emirates", id: "AE" },
    { name: "United Kingdom", id: "GB" },
    { name: "United States", id: "US" },
    { name: "United States Minor Outlying Islands", id: "UM" },
    { name: "Uruguay", id: "UY" },
    { name: "Uzbekistan", id: "UZ" },
    { name: "Vanuatu", id: "VU" },
    { name: "Venezuela", id: "VE" },
    { name: "Viet Nam", id: "VN" },
    { name: "Virgin Islands, British", id: "VG" },
    { name: "Virgin Islands, U.S.", id: "VI" },
    { name: "Wallis and Futuna", id: "WF" },
    { name: "Western Sahara", id: "EH" },
    { name: "Yemen", id: "YE" },
    { name: "Zambia", id: "ZM" },
    { name: "Zimbabwe", id: "ZW" },
  ],
  secondCountriesSelect: [
    { name: "None", id: "NA" },
    { name: "Afghanistan", id: "AF" },
    { name: "Aland Islands", id: "AX" },
    { name: "Albania", id: "AL" },
    { name: "Algeria", id: "DZ" },
    { name: "American Samoa", id: "AS" },
    { name: "Andorra", id: "AD" },
    { name: "Angola", id: "AO" },
    { name: "Anguilla", id: "AI" },
    { name: "Antarctica", id: "AQ" },
    { name: "Antigua and Barbuda", id: "AG" },
    { name: "Argentina", id: "AR" },
    { name: "Armenia", id: "AM" },
    { name: "Aruba", id: "AW" },
    { name: "Australia", id: "AU" },
    { name: "Austria", id: "AT" },
    { name: "Azerbaijan", id: "AZ" },
    { name: "Bahamas", id: "BS" },
    { name: "Bahrain", id: "BH" },
    { name: "Bangladesh", id: "BD" },
    { name: "Barbados", id: "BB" },
    { name: "Belarus", id: "BY" },
    { name: "Belgium", id: "BE" },
    { name: "Belize", id: "BZ" },
    { name: "Benin", id: "BJ" },
    { name: "Bermuda", id: "BM" },
    { name: "Bhutan", id: "BT" },
    { name: "Bolivia", id: "BO" },
    { name: "Bosnia and Herzegovina", id: "BA" },
    { name: "Botswana", id: "BW" },
    { name: "Bouvet Island", id: "BV" },
    { name: "Brazil", id: "BR" },
    { name: "British Indian Ocean Territory", id: "IO" },
    { name: "Brunei Darussalam", id: "BN" },
    { name: "Bulgaria", id: "BG" },
    { name: "Burkina Faso", id: "BF" },
    { name: "Burundi", id: "BI" },
    { name: "Cambodia", id: "KH" },
    { name: "Cameroon", id: "CM" },
    { name: "Canada", id: "CA" },
    { name: "Cape Verde", id: "CV" },
    { name: "Cayman Islands", id: "KY" },
    { name: "Central African Republic", id: "CF" },
    { name: "Chad", id: "TD" },
    { name: "Chile", id: "CL" },
    { name: "China", id: "CN" },
    { name: "Christmas Island", id: "CX" },
    { name: "Cocos (Keeling) Islands", id: "CC" },
    { name: "Colombia", id: "CO" },
    { name: "Comoros", id: "KM" },
    { name: "Congo", id: "CG" },
    { name: "Congo, The Democratic Republic of the", id: "CD" },
    { name: "Cook Islands", id: "CK" },
    { name: "Costa Rica", id: "CR" },
    { name: 'Cote D"Ivoire', id: "CI" },
    { name: "Croatia", id: "HR" },
    { name: "Cuba", id: "CU" },
    { name: "Cyprus", id: "CY" },
    { name: "Czech Republic", id: "CZ" },
    { name: "Denmark", id: "DK" },
    { name: "Djibouti", id: "DJ" },
    { name: "Dominica", id: "DM" },
    { name: "Dominican Republic", id: "DO" },
    { name: "Ecuador", id: "EC" },
    { name: "Egypt", id: "EG" },
    { name: "El Salvador", id: "SV" },
    { name: "Equatorial Guinea", id: "GQ" },
    { name: "Eritrea", id: "ER" },
    { name: "Estonia", id: "EE" },
    { name: "Ethiopia", id: "ET" },
    { name: "Falkland Islands (Malvinas)", id: "FK" },
    { name: "Faroe Islands", id: "FO" },
    { name: "Fiji", id: "FJ" },
    { name: "Finland", id: "FI" },
    { name: "France", id: "FR" },
    { name: "French Guiana", id: "GF" },
    { name: "French Polynesia", id: "PF" },
    { name: "French Southern Territories", id: "TF" },
    { name: "Gabon", id: "GA" },
    { name: "Gambia", id: "GM" },
    { name: "Georgia", id: "GE" },
    { name: "Germany", id: "DE" },
    { name: "Ghana", id: "GH" },
    { name: "Gibraltar", id: "GI" },
    { name: "Greece", id: "GR" },
    { name: "Greenland", id: "GL" },
    { name: "Grenada", id: "GD" },
    { name: "Guadeloupe", id: "GP" },
    { name: "Guam", id: "GU" },
    { name: "Guatemala", id: "GT" },
    { name: "Guernsey", id: "GG" },
    { name: "Guinea", id: "GN" },
    { name: "Guinea-Bissau", id: "GW" },
    { name: "Guyana", id: "GY" },
    { name: "Haiti", id: "HT" },
    { name: "Heard Island and Mcdonald Islands", id: "HM" },
    { name: "Holy See (Vatican City State)", id: "VA" },
    { name: "Honduras", id: "HN" },
    { name: "Hong Kong", id: "HK" },
    { name: "Hungary", id: "HU" },
    { name: "Iceland", id: "IS" },
    { name: "India", id: "IN" },
    { name: "Indonesia", id: "ID" },
    { name: "Iran, Islamic Republic Of", id: "IR" },
    { name: "Iraq", id: "IQ" },
    { name: "Ireland", id: "IE" },
    { name: "Isle of Man", id: "IM" },
    { name: "Israel", id: "IL" },
    { name: "Italy", id: "IT" },
    { name: "Jamaica", id: "JM" },
    { name: "Japan", id: "JP" },
    { name: "Jersey", id: "JE" },
    { name: "Jordan", id: "JO" },
    { name: "Kazakhstan", id: "KZ" },
    { name: "Kenya", id: "KE" },
    { name: "Kiribati", id: "KI" },
    { name: 'Korea, Democratic People"S Republic of', id: "KP" },
    { name: "Korea, Republic of", id: "KR" },
    { name: "Kuwait", id: "KW" },
    { name: "Kyrgyzstan", id: "KG" },
    { name: 'Lao People"S Democratic Republic', id: "LA" },
    { name: "Latvia", id: "LV" },
    { name: "Lebanon", id: "LB" },
    { name: "Lesotho", id: "LS" },
    { name: "Liberia", id: "LR" },
    { name: "Libyan Arab Jamahiriya", id: "LY" },
    { name: "Liechtenstein", id: "LI" },
    { name: "Lithuania", id: "LT" },
    { name: "Luxembourg", id: "LU" },
    { name: "Macao", id: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of Macedonia", id: "MK" },
    { name: "Madagascar", id: "MG" },
    { name: "Malawi", id: "MW" },
    { name: "Malaysia", id: "MY" },
    { name: "Maldives", id: "MV" },
    { name: "Mali", id: "ML" },
    { name: "Malta", id: "MT" },
    { name: "Marshall Islands", id: "MH" },
    { name: "Martinique", id: "MQ" },
    { name: "Mauritania", id: "MR" },
    { name: "Mauritius", id: "MU" },
    { name: "Mayotte", id: "YT" },
    { name: "Mexico", id: "MX" },
    { name: "Micronesia, Federated States of", id: "FM" },
    { name: "Moldova, Republic of", id: "MD" },
    { name: "Monaco", id: "MC" },
    { name: "Mongolia", id: "MN" },
    { name: "Montenegro", id: "ME" },
    { name: "Montserrat", id: "MS" },
    { name: "Morocco", id: "MA" },
    { name: "Mozambique", id: "MZ" },
    { name: "Myanmar", id: "MM" },
    { name: "Namibia", id: "NA" },
    { name: "Nauru", id: "NR" },
    { name: "Nepal", id: "NP" },
    { name: "Netherlands", id: "NL" },
    { name: "Netherlands Antilles", id: "AN" },
    { name: "New Caledonia", id: "NC" },
    { name: "New Zealand", id: "NZ" },
    { name: "Nicaragua", id: "NI" },
    { name: "Niger", id: "NE" },
    { name: "Nigeria", id: "NG" },
    { name: "Niue", id: "NU" },
    { name: "Norfolk Island", id: "NF" },
    { name: "Northern Mariana Islands", id: "MP" },
    { name: "Norway", id: "NO" },
    { name: "Oman", id: "OM" },
    { name: "Pakistan", id: "PK" },
    { name: "Palau", id: "PW" },
    { name: "Palestinian Territory, Occupied", id: "PS" },
    { name: "Panama", id: "PA" },
    { name: "Papua New Guinea", id: "PG" },
    { name: "Paraguay", id: "PY" },
    { name: "Peru", id: "PE" },
    { name: "Philippines", id: "PH" },
    { name: "Pitcairn", id: "PN" },
    { name: "Poland", id: "PL" },
    { name: "Portugal", id: "PT" },
    { name: "Puerto Rico", id: "PR" },
    { name: "Qatar", id: "QA" },
    { name: "Reunion", id: "RE" },
    { name: "Romania", id: "RO" },
    { name: "Russian Federation", id: "RU" },
    { name: "RWANDA", id: "RW" },
    { name: "Saint Helena", id: "SH" },
    { name: "Saint Kitts and Nevis", id: "KN" },
    { name: "Saint Lucia", id: "LC" },
    { name: "Saint Pierre and Miquelon", id: "PM" },
    { name: "Saint Vincent and the Grenadines", id: "VC" },
    { name: "Samoa", id: "WS" },
    { name: "San Marino", id: "SM" },
    { name: "Sao Tome and Principe", id: "ST" },
    { name: "Saudi Arabia", id: "SA" },
    { name: "Senegal", id: "SN" },
    { name: "Serbia", id: "RS" },
    { name: "Seychelles", id: "SC" },
    { name: "Sierra Leone", id: "SL" },
    { name: "Singapore", id: "SG" },
    { name: "Slovakia", id: "SK" },
    { name: "Slovenia", id: "SI" },
    { name: "Solomon Islands", id: "SB" },
    { name: "Somalia", id: "SO" },
    { name: "South Africa", id: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", id: "GS" },
    { name: "Spain", id: "ES" },
    { name: "Sri Lanka", id: "LK" },
    { name: "Sudan", id: "SD" },
    { name: "Surivalue", id: "SR" },
    { name: "Svalbard and Jan Mayen", id: "SJ" },
    { name: "Swaziland", id: "SZ" },
    { name: "Sweden", id: "SE" },
    { name: "Switzerland", id: "CH" },
    { name: "Syrian Arab Republic", id: "SY" },
    { name: "Taiwan, Province of China", id: "TW" },
    { name: "Tajikistan", id: "TJ" },
    { name: "Tanzania, United Republic of Tanzania", id: "TZ" },
    { name: "Thailand", id: "TH" },
    { name: "Timor-Leste", id: "TL" },
    { name: "Togo", id: "TG" },
    { name: "Tokelau", id: "TK" },
    { name: "Tonga", id: "TO" },
    { name: "Trinidad and Tobago", id: "TT" },
    { name: "Tunisia", id: "TN" },
    { name: "Turkey", id: "TR" },
    { name: "Turkmenistan", id: "TM" },
    { name: "Turks and Caicos Islands", id: "TC" },
    { name: "Tuvalu", id: "TV" },
    { name: "Uganda", id: "UG" },
    { name: "Ukraine", id: "UA" },
    { name: "United Arab Emirates", id: "AE" },
    { name: "United Kingdom", id: "GB" },
    { name: "United States", id: "US" },
    { name: "United States Minor Outlying Islands", id: "UM" },
    { name: "Uruguay", id: "UY" },
    { name: "Uzbekistan", id: "UZ" },
    { name: "Vanuatu", id: "VU" },
    { name: "Venezuela", id: "VE" },
    { name: "Viet Nam", id: "VN" },
    { name: "Virgin Islands, British", id: "VG" },
    { name: "Virgin Islands, U.S.", id: "VI" },
    { name: "Wallis and Futuna", id: "WF" },
    { name: "Western Sahara", id: "EH" },
    { name: "Yemen", id: "YE" },
    { name: "Zambia", id: "ZM" },
    { name: "Zimbabwe", id: "ZW" },
  ],
  CurrentStages: [
    {
      name: 'Study',
      id: 1,
      children: [
        {
          name: 'Pre application',
          id: 1,
          subChildren: [
            {
              name: 'University Selection',
              id: 1
            },
            {
              name: 'University Application',
              id: 1
            },
            {
              name: 'Fees and Scholarship',
              id: 1
            },
            {
              name: 'Visa Application',
              id: 1
            }
          ]
        },
        {
          name: 'Post arrival ',
          id: 2,
          subChildren: [
            {
              name: 'Work Opportunities',
              id: 1
            },
            {
              name: 'Adaptation',
              id: 2
            },
          ]
        }
      ]
    },

    {
      name: 'Work',
      id: 2,
      children: [
        {
          name: 'Pre application',
          id: 1,
          subChildren: [
            {
              name: 'Job opportunities',
              id: 1
            },
            {
              name: 'Job Application',
              id: 1
            },
            {
              name: 'Fees and Scholarship',
              id: 1
            },
            {
              name: 'Visa Application',
              id: 1
            }
          ]
        },
        {
          name: 'Post arrival ',
          id: 2,
          subChildren: [
            {
              name: 'Work Opportunities',
              id: 1
            },
            {
              name: 'Adaptation',
              id: 2
            },
          ]
        }
      ]
    },

    {
      name: 'PR',
      id: 3,
      children: [
        {
          name: 'Application stage',
          id: 1,
          subChildren: [
            {
              name: 'Eligibility',
              id: 1
            },
            {
              name: 'Application Process',
              id: 2
            },
            {
              name: 'Admissibility',
              id: 3
            },
          ]
        },
        {
          name: 'Pre approval ',
          id: 2,
          subChildren: [
            {
              name: 'Travel preparation',
              id: 1
            },
            {
              name: 'Accomodation',
              id: 2
            },
          ]
        },
        {
          name: 'Post arrival ',
          id: 3,
          subChildren: [
            {
              name: 'Adaptation',
              id: 1
            },
          ]
        }
      ]
    },

    {
      name: 'Travel',
      id: 4,
    }
  ],
  education_profile: [
    // { "value": "None" },
    {
      name: "ARTS",
      id: 1,
      // these are the children or 'sub items'
      children: [
        {
          name: "Bachelor of Arts - General",
          id: 1,
        },
        {
          name: "Bachelor of Arts - Economics",
          id: 2,
        },
        {
          name: "Bachelor of Arts - English",
          id: 3,
        },
        {
          name: "Bachelor of Arts - History",
          id: 4,
        },
        {
          name: "Bachelor of Arts - Political science",
          id: 5,
        },
        {
          name: "Master of Arts - English",
          id: 6,
        },
        {
          name: "Master of Arts - Economics",
          id: 7,
        },
        {
          name: "Bachelor of Arts - Social Science",
          id: 8,
        },
        {
          name: "Master of Arts - History",
          id: 9,
        },
        {
          name: "Master of Arts - Political Science",
          id: 10,
        },
        {
          name: "Bachelor of Arts - Geography",
          id: 11,
        },
        {
          name: "Master of Arts - Sociology",
          id: 12,
        },
        {
          name: "Bachelor of Arts - Psychology",
          id: 13,
        },
        {
          name: "Bachelor of Arts - Education",
          id: 14,
        },
        {
          name: "Bachelor of Arts - Philosophy",
          id: 15,
        },
        {
          name: "Master of Arts - Geography",
          id: 16,
        },
        {
          name: "Bachelor of library and information science",
          id: 17,
        },
        {
          name: "Master of library and information science",
          id: 18,
        },
        {
          name: "Master of Philosophy - English",
          id: 19,
        },
        {
          name: "Bachelor of Arts - Mathematics",
          id: 20,
        },
        {
          name: "Bachelor of Arts - Home science",
          id: 21,
        },
        {
          name: "Bachelor of Arts - Mass communication and journalism",
          id: 22,
        },
        {
          name: "Bachelor of Performing Arts",
          id: 23,
        },

        {
          name: "Bachelor of Arts - Theology",
          id: 24,
        },
        {
          name: "Bachelor of Arts - Music",
          id: 25,
        },
        {
          name: "Bachelor of Arts - Language studies",
          id: 26,
        },
        {
          name: "Bachelor of Arts - Theatre studies",
          id: 27,
        },
        {
          name: "Bachelor of Arts - Visual Communication",
          id: 28,
        },
        {
          name: "Master of Arts - Music",
          id: 29,
        },
        {
          name: "Master of Arts - Language studies",
          id: 30,
        },

        {
          name: "Master of Arts - Library and information science",
          id: 31,
        },
        {
          name: "Master of Arts - Theatre studies",
          id: 32,
        },
        {
          name: "Master of Arts - Visual Communication",
          id: 33,
        },

      ],
    },
    {
      name: "COMMERCE",
      id: 2,
      // these are the children or 'sub items'
      children: [
        {
          name: "Bachelor of Commerce - General",
          id: 24,
        },
        {
          name: "Bachelor of Commerce - Computer Applications",
          id: 25,
        },
        {
          name: "PhD Commerce",
          id: 26,
        },
        {
          name: "Master of Philosophy - Commerce",
          id: 27,
        },

        {
          name: "Bachelor of Commerce - Accounting and Finance",
          id: 28,
        },
        {
          name: "Master of Commerce - Finance",
          id: 29,
        },
        {
          name: "Bachelor of Commerce - Corporate Secretaryship",
          id: 30,
        },
        {
          name: "Bachelor of Commerce - Banking and Finance",
          id: 31,
        },

        {
          name: "PhD Statistics",
          id: 32,
        },
        {
          name: "Master of Commerce - Computer Applications",
          id: 33,
        },
        {
          name: "Bachelor of Commerce - Taxation",
          id: 34,
        },
        {
          name: "Bachelor of Commerce - Accountancy",
          id: 35,
        },

        {
          name: "Bachelor of Commerce - Cooperation",
          id: 36,
        },
        {
          name: "Master of Commerce - Accounting and Finance",
          id: 37,
        },
        {
          name: "Bachelor of Commerce - Banking Management",
          id: 38,
        },
        {
          name: "Master of Commerce - Accountancy",
          id: 39,
        },
        {
          name: "Bachelor of Commerce - Marketing",
          id: 40,
        },
        {
          name: "Master of Commerce - Marketing",
          id: 41,
        },
        {
          name: "Bachelor of Commerce - Tax procedure and practice",
          id: 42,
        },
        {
          name: "Bachelor of Commerce - Information technology",
          id: 43,
        },
        {
          name: "Bachelor of Commerce - Financial Markets",
          id: 44,
        },
        {
          name: "Bachelor of Commerce - Taxation and Finance",
          id: 45,
        },
        {
          name: "Master of Commerce - Financial Management",
          id: 46,
        },
        {
          name: "Bachelor of Commerce - Foreign Trade management",
          id: 47,
        },
        {
          name: "Master of Commerce - Banking and Finance",
          id: 48,
        },
        {
          name: "Master of Commerce - Business Administration",
          id: 49,
        },
        {
          name: "PhD - Commerce and management",
          id: 50,
        },
        {
          name: "Master of Commerce - Business Management",
          id: 51,
        },
        {
          name: "Bachelor of Commerce - Banking and Finance",
          id: 52,
        },
        {
          name: "Bachelor of Commerce - Management studies",
          id: 53,
        },
        {
          name: "Bachelor of Commerce - Economics",
          id: 54,
        },
        {
          name: "Bachelor of Commerce - Statistics",
          id: 55,
        },
        {
          name: "Bachelor of Commerce - Tourism and travel management",
          id: 56,
        },
        {
          name: "Master of Finance and control",
          id: 57,
        },
        {
          name: "PhD - Accountancy",
          id: 58,
        },
        {
          name: "PhD - Business Economics",
          id: 59,
        },
        {
          name: "Master of Commerce - Banking",
          id: 60,
        },
        {
          name: "Master of Commerce - Taxation",
          id: 61,
        },
        {
          name: "Bachelor of Commerce - International business",
          id: 62,
        },
        {
          name: "PhD - Banking and Finance",
          id: 63,
        },
        {
          name: "Association of Chartered Certified Accountants - ACCA",
          id: 64,
        },
        {
          name: "Chartered Financial Analyst - CFA",
          id: 65,
        },
        {
          name: "Bachelor of Commerce - Investment Management",
          id: 66,
        },
        {
          name: "Master of Commerce - Statistics",
          id: 67,
        },
        {
          name: "Bachelor of Commerce - Supply chain management",
          id: 68,
        },
      ],
    },
    {
      name: "ENGINEERING",
      id: 3,
      // these are the children or 'sub items'
      children: [
        {
          name: "Bachelor of Engineering - Aerospace Engineering",
          id: 69,
        },
        {
          name: "Bachelor of Engineering - Agriculture & Food Engineering",
          id: 70,
        },
        {
          name: "Bachelor of Engineering - Automobile Engineering",
          id: 71,
        },
        {
          name: "Bachelor of Engineering - Mechanical Engineering",
          id: 72,
        },
        {
          name: "Bachelor of Engineering - Civil Engineering",
          id: 73,
        },
        {
          name: "Bachelor of Engineering - Computer science and Engineering",
          id: 74,
        },
        {
          name: "Bachelor of Engineering - Electronics & Communications Engineering ",
          id: 75,
        },
        {
          name: "Bachelor of Engineering - Information technology",
          id: 76,
        },
        {
          name: "PhD - Mechanical Engineering",
          id: 77,
        },
        {
          name: "Master of Engineering - Computer science and Engineering ",
          id: 78,
        },
        {
          name: "PhD - Computer science and Engineering",
          id: 79,
        },
        {
          name: "Bachelor of Engineering - Electrical engineering",
          id: 80,
        },
        {
          name: "PhD - Civil Engineering",
          id: 81,
        },
        {
          name: "Bachelor of Engineering - Electronics & Telecom Engineering",
          id: 82,
        },
        {
          name: "Bachelor of Engineering - Computer Engineering",
          id: 83,
        },
        {
          name: "Master of Engineering - Structural Engineering",
          id: 84,
        },
        {
          name: "PhD - Electronic and Communications Engineering",
          id: 85,
        },
        {
          name: "PhD - Electrical Engineering",
          id: 86,
        },
        {
          name: "PhD - Electrical and Electronic engineering",
          id: 87,
        },
        {
          name: "Master of Engineering - Power Electronics and Drives",
          id: 88,
        },
        {
          name: "Master of Engineering - Applied Electronics",
          id: 89,
        },
        {
          name: "Bachelor of Engineering - Automobile Engineering",
          id: 90,
        },
        {
          name: "PhD - Engineering",
          id: 91,
        },
        {
          name: "Master of Engineering",
          id: 92,
        },
        {
          name: "Bachelor of Engineering - Information Science and Engineering",
          id: 93,
        },
        {
          name: "Master of Engineering - Computer Engineering",
          id: 94,
        },
        {
          name: "PhD - Chemical Engineering",
          id: 95,
        },
        {
          name: "Master of Engineering - VLSI Design",
          id: 96,
        },
        {
          name: "Bachelor of Engineering - Chemical Engineering",
          id: 97,
        },
        {
          name: "Bachelor of Engineering - Biomedical Engineering",
          id: 98,
        },
        {
          name: "Master of Engineering - Communication systems",
          id: 99,
        },
        {
          name: "Bachelor of Engineering - Aeronautical Engineering",
          id: 100,
        },
        {
          name: "Bachelor of Engineering - Electronics and Instrumentation engineering",
          id: 101,
        },
        {
          name: "Master of Engineering - Thermal Engineering",
          id: 102,
        },
        {
          name: "Master of Engineering - Mechanical Engineering",
          id: 103,
        },
        {
          name: "Master of Engineering - Manufacturing Engineering",
          id: 104,
        },
        {
          name: "Master of Engineering - Power System Engineering",
          id: 105,
        },
        {
          name: "Master of Engineering - Engineering Design",
          id: 106,
        },
        {
          name: "Bachelor of Engineering - Mechatronics Engineering",
          id: 107,
        },
        {
          name: "Master of Engineering - Construction Engineering and Management",
          id: 108,
        },
        {
          name: "Master of Engineering - Electronics & Telecommunication Engineering",
          id: 109,
        },
        {
          name: "PhD - Engineering and Technology",
          id: 110,
        },
        {
          name: "PhD - Electronics and Telecommunication",
          id: 111,
        },
        {
          name: "Master of Engineering - Civil Engineering",
          id: 112,
        },
        {
          name: "PhD - Bioinformatics",
          id: 113,
        },
        {
          name: "Master of Engineering - Electronics & Communication",
          id: 114,
        },
        {
          name: "Master of Engineering - Electrical Engineering",
          id: 115,
        },
        {
          name: "PhD - Electronics Engineering",
          id: 116,
        },
        {
          name: "PhD - Computer Engineering",
          id: 117,
        },
        {
          name: "Master of Engineering - Information of technology",
          id: 118,
        },
        {
          name: "Master of Engineering - Embedded System Technologies",
          id: 119,
        },
        {
          name: "Bachelor of Engineering - Biotechnology",
          id: 120,
        },
        {
          name: "PhD - Food Technology",
          id: 121,
        },
        {
          name: "Master of Engineering - Environmental Engineering",
          id: 122,
        },
        {
          name: "Master of Engineering - Software Engineering",
          id: 123,
        },
        {
          name: "Bachelor of Engineering - Production Engineering",
          id: 124,
        },
        {
          name: "Bachelor of Engineering - Instrumentation Engineering",
          id: 125,
        },
        {
          name: "Bachelor of Engineering - Computer science",
          id: 126,
        },
        {
          name: "Bachelor of Engineering - Instrumentation & Control Engineering",
          id: 127,
        },
        {
          name: "Master of Engineering - Power Electronics",
          id: 128,
        },
        {
          name: "Bachelor of Engineering - Mining Engineering",
          id: 129,
        },
        {
          name: "Master of Engineering - Design Engineering",
          id: 130,
        },
        {
          name: "PhD - Biomedical Engineering",
          id: 131,
        },
        {
          name: "PhD - Nanotechnology",
          id: 132,
        },
        {
          name: "Master of Engineering - Energy Engineering",
          id: 133,
        },
        {
          name: "Master of Engineering - Electronics Engineering",
          id: 134,
        },
        {
          name: "Master of Engineering - Electrical Power System",
          id: 135,
        },
        {
          name: "Master of Engineering - Control and Instrumentation Engineering",
          id: 136,
        },
        {
          name: "Bachelor of Engineering - Telecommunication Engineering",
          id: 137,
        },
        {
          name: "Bachelor of Engineering - Marine Engineering",
          id: 138,
        },
        {
          name: "PhD - Environmental Engineering",
          id: 139,
        },
        {
          name: "PhD - Electrical communication Engineering",
          id: 140,
        },
        {
          name: "Bachelor of Engineering - Food Technology",
          id: 141,
        },
        {
          name: "Master of Engineering - Digital Communication & Networking",
          id: 142,
        },
        {
          name: "Master of Engineering - Communication Engineering",
          id: 143,
        },
        {
          name: "Bachelor of Engineering - Mechanical & Automation Engineering ",
          id: 144,
        },
      ],
    },
    {
      name: "TECH",
      id: 4,
      // these are the children or 'sub items'
      children: [
        {
          name: "Bachelor of Technology - Mechanical Engineering",
          id: 145,
        },
        {
          name: "Bachelor of Technology - Computer Science and Engineering",
          id: 146,
        },
        {
          name: "Bachelor of Technology - Civil Engineering",
          id: 147,
        },
        {
          name: "Bachelor of Technology - Electronics & Communication Engineering ",
          id: 148,
        },
        {
          name: "Master of Technology - Computer Science and Engineering",
          id: 149,
        },
        {
          name: "Bachelor of Technology - Electrical and Electronic Engineering",
          id: 150,
        },
        {
          name: "Bachelor of Technology - Information Technology",
          id: 151,
        },
        {
          name: "Bachelor of Technology - Electrical Engineering",
          id: 152,
        },
        {
          name: "Master of Technology - Structural Engineering",
          id: 153,
        },
        {
          name: "Master of Technology - Electronics and communication Engineering",
          id: 154,
        },
        {
          name: "Master of Technology - Mechanical Engineering",
          id: 155,
        },
        {
          name: "Bachelor of Technology - Electrical Engineering",
          id: 156,
        },
        {
          name: "Master of Technology - VLSI Design",
          id: 157,
        },
        {
          name: "Master of Technology - Thermal Engineering",
          id: 158,
        },
        {
          name: "Master of Technology - Chemical Engineering",
          id: 159,
        },
        {
          name: "Bachelor of Technology - Chemical Engineering",
          id: 160,
        },
        {
          name: "Bachelor of Technology - Biotechnology",
          id: 161,
        },
        {
          name: "Master of Technology - Power System Engineering",
          id: 162,
        },
        {
          name: "Master of Technology - VLSI & Embedded System",
          id: 163,
        },
        {
          name: "Master of Technology - Information Technology",
          id: 164,
        },
        {
          name: "Master of Technology - Electrical Engineering",
          id: 165,
        },
        {
          name: "Master of Technology - Computer science",
          id: 166,
        },
        {
          name: "Master of Technology - Embedded Systems",
          id: 167,
        },
        {
          name: "Master of Technology - Software Engineering",
          id: 168,
        },
        {
          name: "Bachelor of Technology - Computer Engineering",
          id: 169,
        },
        {
          name: "Master of Technology - Environmental Engineering",
          id: 170,
        },
        {
          name: "Bachelor of Technology - Agricultural Engineering",
          id: 171,
        },
        {
          name: "Master of Technology - Biotechnology",
          id: 172,
        },
        {
          name: "Master of Technology - Production Engineering",
          id: 173,
        },
        {
          name: "Master of Technology - Power Electronics and Drives",
          id: 174,
        },
        {
          name: "Bachelor of Technology - Food Technology",
          id: 175,
        },
        {
          name: "Master of Technology - Digital Communication",
          id: 176,
        },
        {
          name: "Master of Technology - Chemical Engineering",
          id: 177,
        },
        {
          name: "Master of Technology - Transportation Engineering",
          id: 178,
        },
        {
          name: "Master of Technology - Machine Design",
          id: 179,
        },
        {
          name: "Bachelor of Technology - Electronics and Instrumentation Engineering",
          id: 180,
        },
        {
          name: "Master of Technology - Computer Engineering",
          id: 181,
        },
        {
          name: "Bachelor of Technology - Mining Engineering",
          id: 182,
        },
        {
          name: "Bachelor of Technology - Aeronautical Engineering",
          id: 183,
        },
        {
          name: "Master of Technology - Geotechnological Engineering",
          id: 184,
        },
        {
          name: "Bachelor of Technology - Mechatronics",
          id: 185,
        },
        {
          name: "Bachelor of Technology - Computer Science",
          id: 186,
        },
        {
          name: "Bachelor of Technology - Data Science",
          id: 187,
        },
        {
          name: "Master of Technology - Digital Electronics & Communication Systems",
          id: 188,
        },
        {
          name: "Master of Technology - Electrical Power System",
          id: 189,
        },
        {
          name: "Bachelor of Technology - Biomedical Engineering",
          id: 190,
        },
        {
          name: "Master of Technology - Communication Systems",
          id: 191,
        },
        {
          name: "Master of Technology - Digital Communication and networking",
          id: 192,
        },
        {
          name: "Master of Technology - Computer Network & Information Security",
          id: 193,
        },
        {
          name: "PhD - Nanotechnology",
          id: 194,
        },
        {
          name: "Bachelor of Technology - Metallurgical Engineering",
          id: 195,
        },
        {
          name: "Master of Technology - Product design and manufacturing",
          id: 196,
        },
        {
          name: "Bachelor of Technology - Safety and fire engineering",
          id: 197,
        },
        {
          name: "Bachelor of Technology - Textile Engineering",
          id: 198,
        },
        {
          name: "Master of Technology - Electronics and instrumentation Engineering",
          id: 199,
        },
        {
          name: "Bachelor of Technology - Industrial & Production Engineering",
          id: 200,
        },
        {
          name: "Master of Technology - Aerospace engineering",
          id: 201,
        },
        {
          name: "Bachelor of Technology - Nanotechnology",
          id: 202,
        },
        {
          name: "Master of Technology - Telecommunication Engineering",
          id: 203,
        },
        {
          name: "Master of Technology - Robotics Engineering",
          id: 204,
        },
        {
          name: "Bachelor of Technology - Software Engineering",
          id: 205,
        },
        {
          name: "Bachelor of Technology - Information and communication technology",
          id: 206,
        },
        {
          name: "Master of Technology - Manufacturing Technology",
          id: 207,
        },
        {
          name: "Master of Technology - Petroleum Engineering",
          id: 208,
        },
        {
          name: "Master of Technology - Soil Water Conservation Engineering",
          id: 209,
        },
        {
          name: "Master of Technology - Digital System & Signal Processing",
          id: 210,
        },
        {
          name: "Master of Technology - Automotive Engineering",
          id: 211,
        },
        {
          name: "Bachelor of Technology - Robotics",
          id: 212,
        },
        {
          name: "Bachelor of Technology - Industrial Engineering",
          id: 213,
        },
        {
          name: "Master of Technology - Food Processing Engineering",
          id: 214,
        },
        {
          name: "Bachelor of Technology - Fashion Technology",
          id: 215,
        },
        {
          name: "Bachelor of Technology - Information Science & Engineering",
          id: 216,
        },
        {
          name: "Master of Technology - Wireless Communication Technology",
          id: 217,
        },
        {
          name: "Master of Technology - Data Analytics",
          id: 218,
        },
        {
          name: "Master of Technology - Manufacturing science & Engineering ",
          id: 219,
        },
        {
          name: "Master of Technology - Mining Engineering",
          id: 220,
        },
        {
          name: "Master of Technology - Energy Engineering",
          id: 221,
        },
        {
          name: "Master of Technology - Research",
          id: 222,
        },
        {
          name: "Master of Technology - Electrical Devices & Power system engineering",
          id: 223,
        },
        {
          name: "Bachelor of Technology - Engineering Physics",
          id: 224,
        },
        {
          name: "Master of Technology - Applied Electronics",
          id: 225,
        },
        {
          name: "Master of Technology - Intelligent System",
          id: 226,
        },
        {
          name: "Bachelor of Technology - Textile Chemistry",
          id: 227,
        },
        {
          name: "Bachelor of Technology - Genetic Engineering",
          id: 228,
        },
        {
          name: "Bachelor of Technology - Food Technology",
          id: 229,
        },
        {
          name: "Bachelor of Technology - Plastic And Polymer Engineering",
          id: 230,
        },
        {
          name: "Bachelor of Technology - Printing Technology",
          id: 231,
        },
        {
          name: "Bachelor of science in biotechnology",
          id: 232,
        },
      ],
    },
    {
      name: "MEDICINE",
      id: 5,
      // these are the children or 'sub items'
      children: [
        {
          name: "Bachelor of Medicine and Bachelor of Surgery - MBBS",
          id: 233,
        },
        {
          name: "Bachelor of Dental Surgery - BDS",
          id: 234,
        },
        {
          name: "Bachelor of Homeopathic Medicine & Surgery - BHMS",
          id: 235,
        },
        {
          name: "Bachelor of Ayurvedic Medicine and Surgery",
          id: 236,
        },
        {
          name: "Doctor of Medicine - M.D",
          id: 237,
        },
        {
          name: "Master of Surgery - MS",
          id: 238,
        },
        {
          name: "Doctorate in Medicine - DM",
          id: 239,
        },
        {
          name: "Bachelor of Pharmacy - B.Pharm",
          id: 240,
        },
        {
          name: "Bachelor of Science - Nursing",
          id: 241,
        },
        {
          name: "Bachelor of Physiotherapy",
          id: 242,
        },
        {
          name: "Bachelor Occupational Therapy",
          id: 243,
        },
        {
          name: "Bachelor of Unani Medicine & Surgery",
          id: 244,
        },
        {
          name: "Diploma in Pharmacy - Ayurvedic, Siddha Medicine",
          id: 245,
        },
        {
          name: "Bachelor of Medical Lab Technicians - BLMT",
          id: 246,
        },
        {
          name: "Bachelor of Veterinary Sciences & Animal Husbandry - B.V.Sc & AH",
          id: 247,
        },
        {
          name: "Bachelor of Occupational Therapy - BOT",
          id: 248,
        },
        {
          name: "Bachelor of Medical Laboratory Technology - BMLT",
          id: 249,
        },
      ],
    },
    {
      name: "DESIGN",
      id: 6,
      // these are the children or 'sub items'
      children: [
        {
          name: "Bachelor of Design - Fashion Designing",
          id: 250,
        },
        {
          name: "Bachelor of Design - Graphic Designing",
          id: 251,
        },
        {
          name: "Bachelor of Design - Interior and furniture design",
          id: 252,
        },
        {
          name: "Bachelor of Design - Animation",
          id: 253,
        },
        {
          name: "Bachelor of Design - Industrial/production design",
          id: 254,
        },
        {
          name: "Bachelor of Design - Jewellery/accessory design",
          id: 255,
        },
        {
          name: "Bachelor of Design - Textile Design",
          id: 256,
        },
        {
          name: "Bachelor of Design - Ceramic and Glass Design",
          id: 257,
        },
        {
          name: "Bachelor of Design - Fashion Communication/ Communication Design",
          id: 258,
        },
        {
          name: "Bachelor of Design - Fashion Communication",
          id: 259,
        },
        {
          name: "Master of Design",
          id: 260,
        },
        {
          name: "Bachelor of Design - Communication Design",
          id: 261,
        },
        {
          name: "PhD - Fashion Design",
          id: 262,
        },
        {
          name: "Master of Fashion Management",
          id: 263,
        },
        {
          name: "Bachelor of Fashion and apparel design",
          id: 264,
        },
        {
          name: "Bachelor in Fashion Technology",
          id: 265,
        },
        {
          name: "Master of Fashion Technology",
          id: 266,
        },
        {
          name: "Master of Textiles",
          id: 267,
        },
        {
          name: "Bachelor in graphic and digital design",
          id: 268,
        },
        {
          name: "Bachelor of Architecture",
          id: 269,
        },
      ],
    },
    {
      name: "LAW",
      id: 7,
      // these are the children or 'sub items'
      children: [
        {
          name: "Bachelor of Legislative Law",
          id: 270,
        },
        {
          name: "Bachelor of Arts and Bachelor of Legislative Law",
          id: 271,
        },
        {
          name: "Bachelor of Commerce and Bachelor of Legislative Law",
          id: 272,
        },
        {
          name: "Bachelor of Science and Bachelor of Legislative Law",
          id: 273,
        },
      ],
    },
    {
      name: "PRE-BACHELORS DEGREE",
      id: 8,
      children: [
        {
          name: "Associate Degree (Science)",
          id: 274,
        },
        {
          name: "Associate Degree (Arts)",
          id: 275,
        },
        {
          name: "Associate Degree (Economics) ",
          id: 276,
        },
        {
          name: "Associate Degree (Others)",
          id: 277,
        },
        {
          name: "Secondary Education",
          id: 278,
        },
      ],
    },

    {
      name: "SCIENCE",
      id: 9,
      children: [
        {
          name: "Bachelor of Science - Advanced zoology",
          id: 279,
        },
        {
          name: "Master of Science - Advanced zoology",
          id: 280,
        },
        {
          name: "Bachelor of Science - Biology",
          id: 281,
        },
        {
          name: "Master of Science - Biology",
          id: 282,
        },
        {
          name: "Bachelor of Science - Genetics",
          id: 283,
        },
        {
          name: "Master of Science - Genetics",
          id: 284,
        },
        {
          name: "Bachelor of Science - Advanced plant biology",
          id: 285,
        },
        {
          name: "Master of Science - Advanced plant biology",
          id: 286,
        },
        {
          name: "Bachelor of Science - Agriculture",
          id: 287,
        },
        {
          name: "Master of Science - Agriculture",
          id: 288,
        },
        {
          name: "Bachelor of Science - Animation",
          id: 289,
        },
        {
          name: "Master of Science - Animation",
          id: 290,
        },
        {
          name: "Bachelor of Science - Aquaculture",
          id: 291,
        },
        {
          name: "Master of Science - Aquaculture",
          id: 292,
        },
        {
          name: "Master of Science - Banking and finance",
          id: 293,
        },
        {
          name: "Bachelor of Science - Physics",
          id: 294,
        },
        {
          name: "Master of Science - Physics",
          id: 295,
        },
        {
          name: "Bachelor of Science - Chemistry",
          id: 296,
        },
        {
          name: "Master of Science - Chemistry",
          id: 297,
        },
        {
          name: "Bachelor of Science - Mathematics",
          id: 298,
        },
        {
          name: "Master of Science - Home science",
          id: 299,
        },
        {
          name: "Master of Science - Chemical science",
          id: 300,
        },
        {
          name: "Master of Science - Mathematics",
          id: 301,
        },
        {
          name: "Bachelor of Science - Statistics",
          id: 302,
        },
        {
          name: "Master of Science - Statistics",
          id: 303,
        },
        {
          name: "Bachelor of Science - Botany",
          id: 304,
        },
        {
          name: "Master of Science - Botany",
          id: 305,
        },
        {
          name: "Master of Science - Applied economics",
          id: 306,
        },
        {
          name: "Master of Science - Applications of mathematics",
          id: 307,
        },
        {
          name: "Bachelor of Science - Home science",
          id: 308,
        },
        {
          name: "Bachelor of Science - Biochemistry",
          id: 309,
        },
        {
          name: "Master of Science - Biochemistry",
          id: 310,
        },
        {
          name: "Bachelor of Science - Bioinformatics",
          id: 311,
        },
        {
          name: "Master of Science - Bioinformatics",
          id: 312,
        },
        {
          name: "Bachelor of Science - Computer science",
          id: 313,
        },
        {
          name: "Master of Science - Computer science",
          id: 314,
        },
        {
          name: "Bachelor of Science - Dietetics",
          id: 315,
        },
        {
          name: "Master of Science - Dietetics",
          id: 316,
        },
        {
          name: "Bachelor of Science - Electronic",
          id: 317,
        },
        {
          name: "Master of Science - Electronic",
          id: 318,
        },
        {
          name: "Master of Science - Clothing and textiles",
          id: 319,
        },
        {
          name: "Master of Science - Clinic research",
          id: 320,
        },
        {
          name: "Bachelor of Science - Fashion technology",
          id: 321,
        },
        {
          name: "Master of Science - Fashion technology",
          id: 322,
        },
        {
          name: "Bachelor of Science - Food technology",
          id: 323,
        },
        {
          name: "Master of Science - Food technology",
          id: 324,
        },
        {
          name: "Bachelor of Science - Forensic science",
          id: 325,
        },
        {
          name: "Master of Science - Forensic science",
          id: 326,
        },
        {
          name: "Bachelor of Science - Forestry",
          id: 327,
        },
        {
          name: "Master of Science - Forestry",
          id: 328,
        },
        {
          name: "Bachelor of Science - Medical technology",
          id: 329,
        },
        {
          name: "Master of Science - Medical technology",
          id: 330,
        },
        {
          name: "Bachelor of Science - Microbiology",
          id: 331,
        },
        {
          name: "Master of Science - Microbiology",
          id: 332,
        },
        {
          name: "Master of Science - Communication",
          id: 333,
        },
        {
          name: "Master of Science - Criminology",
          id: 334,
        },
        {
          name: "Bachelor of Science - Multimedia",
          id: 335,
        },
        {
          name: "Master of Science - Multimedia",
          id: 336,
        },
        {
          name: "Bachelor of Science - Nautical science",
          id: 337,
        },
        {
          name: "Master of Science - Nautical science",
          id: 338,
        },
        {
          name: "Bachelor of Science - Physiotherapy",
          id: 339,
        },
        {
          name: "Master of Science - Physiotherapy",
          id: 340,
        },
        {
          name: "Bachelor of Science - Nursing",
          id: 341,
        },
        {
          name: "Master of Science - Nursing",
          id: 342,
        },
        {
          name: "Bachelor of Science - Nutrition",
          id: 343,
        },
        {
          name: "Master of Science - Nutrition",
          id: 344,
        },
        {
          name: "Bachelor of Science - Psychology",
          id: 345,
        },
        {
          name: "Master of Science - Psychology",
          id: 346,
        },
        {
          name: "Bachelor of Science - Genetics",
          id: 347,
        },
        {
          name: "Master of Science - Genetics",
          id: 348,
        },
        {
          name: "Bachelor of Science - Information technology",
          id: 349,
        },
        {
          name: "Master of Science - Information technology",
          id: 350,
        },
        {
          name: "Bachelor of Science - Interior design",
          id: 351,
        },
        {
          name: "Master of Science - Earth science",
          id: 352,
        },
        {
          name: "Master of Science - Endocrinology",
          id: 353,
        },
      ],
    },
    {
      name: "VOCATIONAL COURSES AFTER 12th",
      id: 10,
      // these are the children or 'sub items'
      children: [
        {
          name: "SEO (Search Engine Optimisation) Training",
          id: 354,
        },
        {
          name: "Digital Marketing",
          id: 355,
        },
        {
          name: "Photography",
          id: 356,
        },
        {
          name: "Fashion Design",
          id: 357,
        },
        {
          name: "Animation",
          id: 358,
        },
        {
          name: "Beautician Courses",
          id: 359,
        },
        {
          name: "Foreign Languages",
          id: 360,
        },
        {
          name: "Translation",
          id: 361,
        },
        {
          name: "Typing",
          id: 362,
        },
        {
          name: "Tax Practice and Procedure",
          id: 363,
        },

        {
          name: "Foreign Trade and Practices",
          id: 364,
        },
        {
          name: "Catering Services",
          id: 365,
        },
        {
          name: "Marketing & Advertising",
          id: 366,
        },
        {
          name: "Tourism Management",
          id: 367,
        },
        {
          name: "Communication and IT",
          id: 368,
        },
        {
          name: "Cinematography",
          id: 369,
        },

        {
          name: "Multimedia",
          id: 370,
        },
        {
          name: "Counselling Psychology",
          id: 371,
        },
        {
          name: "Computer Application",
          id: 372,
        },
        {
          name: "Office Management and Secretarial Practice",
          id: 373,
        },
        {
          name: "Small and Medium Enterprises",
          id: 374,
        },
        {
          name: "Materials Management",
          id: 375,
        },
        {
          name: "Management and Marketing of Insurance",
          id: 376,
        },

        {
          name: "Marketing Management and Retail Business",
          id: 377,
        },
        {
          name: "Human Resource Management",
          id: 378,
        },
        {
          name: "Journalism & Media Management",
          id: 379,
        },
        {
          name: "Hospitality, Catering, Travel, Tourism and Service Industry",
          id: 380,
        },
        {
          name: "Fashion Technology",
          id: 381,
        },
        {
          name: "Interior Design",
          id: 382,
        },
        {
          name: "Bakery and Confectionery",
          id: 383,
        },

        {
          name: "Jewellery & Accessory Design",
          id: 384,
        },
        {
          name: "Boutique Management",
          id: 385,
        },
        {
          name: "Interior Design & Styling",
          id: 386,
        },
        {
          name: "Multi-Cuisine Course",
          id: 387,
        },
        {
          name: "Diet & Nutrition",
          id: 388,
        },
        {
          name: "Sports Nutrition",
          id: 389,
        },
        {
          name: "Acting & TV Production",
          id: 390,
        },
        {
          name: "Media Programming",
          id: 391,
        },
        {
          name: "Non-Linear Editing (Final Cut Pro)",
          id: 392,
        },
        {
          name: "Acting & TV Production",
          id: 393,
        },
        {
          name: "Documentary Film Making & TV Production",
          id: 394,
        },
        {
          name: "Development Journalism",
          id: 395,
        },
      ]
    },
    {
      name: "VOCATIONAL COURSES AFTER 10th",
      id: 11,
      // these are the children or 'sub items'
      children: [
        {
          name: "Physiotherapy Technician",
          id: 396,
        },
        {
          name: "Medical Lab Technology Course",
          id: 397,
        },
        {
          name: "Marketing & Salesmanship",
          id: 398,
        },
        {
          name: "Accounting & Taxation",
          id: 399,
        },
        {
          name: "Office Assistantship",
          id: 400,
        },
        {
          name: "Insurance & Marketing",
          id: 401,
        },
        {
          name: "Banking & Financial Services",
          id: 402,
        },
        {
          name: "Hospitality Management Courses",
          id: 403,
        },
        {
          name: "Food & Beverage Services",
          id: 404,
        },
        {
          name: "Bakery & Confectionary",
          id: 405,
        },
        {
          name: "Craft Course in Food Production",
          id: 406,
        },
        {
          name: "Cookery",
          id: 407,
        },
        {
          name: "Housekeeping",
          id: 408,
        },
        {
          name: "Restaurant & Counter Service",
          id: 409,
        },
        {
          name: "Hotel Reception & Bookkeeping",
          id: 410,
        },
        {
          name: "Boutique Management",
          id: 411,
        },
        {
          name: "Jewellery Design",
          id: 412,
        },
        {
          name: "Sports Nutrition",
          id: 413,
        },
        {
          name: "HR Management",
          id: 414,
        },
        {
          name: "Retail Business",
          id: 415,
        },
        {
          name: "Public Administration",
          id: 416,
        },
        {
          name: "Animation",
          id: 417,
        },
        {
          name: "Robotics",
          id: 418,
        },
        {
          name: "Audio Technician",
          id: 419,
        },
        {
          name: "Insurance",
          id: 420,
        },
        {
          name: "Law",
          id: 421,
        },
        {
          name: "Office Management",
          id: 422,
        },
      ]
    }
  ],
  education: [
    // { "value": "None" },
    { name: "Pre-Secondary" },
    { name: "Secondary/High School/12th Standard" },
    { name: "B.Sc. Mathematics" },
    { name: "M.Sc. Mathematics" },
    { name: "M.Phil. Mathematics" },
    { name: "Ph. D. Mathematics" },
    { name: "B.Sc. Chemistry" },
    { name: "M.Sc. Chemistry" },
    { name: "M.Phil. Chemistry" },
    { name: "Ph. D. Chemistry" },
    { name: "B.B.A Business Administration" },
    { name: "M.B.A. Business Administration" },
    { name: "B.Eng. Computer Science" },
    { name: "M.Eng. Computer Science" },
    { name: "B.Eng. Computer Engineering" },
    { name: "M.Eng. Computer Engineering" },
    { name: "B.Eng. Mechanical Engineering" },
    { name: "M.Eng. Mechanical Engineering" },
    { name: "B.Eng. Chemical Engineering" },
    { name: "M.Eng. Chemical Engineering" },
    { name: "B.Eng. Material Sciences Engineering" },
    { name: "M.Eng. Material Sciences Engineering" },
    { name: "B.Eng. Nuclear Engineering" },
    { name: "M.Eng. Nuclear Engineering" },
    { name: "B.Sc. Zoology" },
    { name: "M.Sc. Zoology" },
    { name: "M.Phil. Zoology" },
    { name: "Ph. D. Zoology" },
    { name: "B.Sc. Physics" },
    { name: "M.Sc. Physics" },
    { name: "M.Phil. Physics" },
    { name: "Ph. D. Physics" },
    { name: "B.Sc. Computer Science" },
    { name: "M.Sc. Computer Science" },
    { name: "Ph. D. Computer Science" },
    { name: "B.Tech Computer Applications" },
    { name: "M.C.A. Computer Applications" },
    { name: "B.F.A English Literature" },
    { name: "M.F.A English Literature" },
    { name: "B.Com. Commerce" },
    { name: "M.Com. Commerce" },
  ],
  // immigerat: [{ "value": "study", "code": 1 },
  // { "value": "Tourism", "code": 2 },
  // { "value": "Citizenship", "code": 3 },
  // { "value": "Residence", "code": 4 },
  // { "value": "Entrepreneurial", "code": 5 },
  // { "value": "Work", "code": 6 },
  // { "value": "Refug", "code": 7 },
  // ],
  immigerat: [
    { value: "Study", code: 1 },
    { value: "Work", code: 2 },
    { value: "Tourism", code: 4 },
    { value: "Permanent Residency", code: 3 },
  ],

  intrestcountryV2: [
    {
      value: "USA",
      label: "USA",
      code: 1,
      img: Image.icon_usa,
      webImg: usaHighlighted,
      webFlag: USAFlag,
    },
    {
      value: "CANADA",
      label: "Canada",
      code: 2,
      img: Image.icon_canada,
      webImg: canadaT1,
      webFlag: canadaT2,
    },
    {
      value: "AUSTRALIA",
      label: "Australia",
      code: 3,
      img: Image.icon_australia,
      webImg: ausHighlighted,
      webFlag: Ausflag,
    },
    {
      value: "SINGAPORE",
      label: "Singapore",
      code: 4,
      img: Image.icon_singapore,
      webImg: singaporeHighlighted,
      webFlag: singaporeFlag,
    },
    {
      value: "UNITED KINGDOM",
      label: "UK",
      code: 5,
      img: Image.icon_uk,
      webImg: ukHighlighted,
      webFlag: UKFLag,
    },
    {
      value: "UAE",
      code: 6,
      label: "UAE",
      img: Image.icon_uae,
      webImg: uaeHighlighted,
      webFlag: UAEFLAG,
    },
    {
      value: "Germany",
      label: "Germany",
      code: 7,
      img: Image.icon_germany,
      webImg: germanyHighlighted,
      webFlag: GermanyFlag,
    },
    {
      value: "Netherlands",
      label: "Netherlands",
      code: 8,
      img: Image.icon_netherlands,
      webImg: netherlandHighlighted,
      webFlag: netherlanFlag,
    },
    {
      value: "New Zealand",
      label: "New Zealand",
      code: 9,
      img: Image.icon_newZealand,
      webImg: nzHighlighted,
      webFlag: NZflag3,
    },
    {
      value: "Japan",
      code: 10,
      label: "Japan",
      img: Image.icon_japan,
      webImg: japanHighlighted,
      webFlag: JapanFlag,
    },
  ],

  intrestcountry: [
    {
      value: "USA",
      label: "USA",
      code: 1,
      img: Image.usaHighlighted,
      unselected: Image.USAFlag,
      webImg: usaHighlighted,
      webFlag: USAFlag,
      region: "America"
    },
    {
      value: "CANADA",
      label: "Canada",
      code: 2,
      img: Image.canadaT1,
      unselected: Image.canadaT2,
      webImg: canadaT1,
      webFlag: canadaT2,
      region: "America"
    },
    {
      value: "AUSTRALIA",
      label: "Australia",
      code: 3,
      img: Image.ausHighlighted,
      unselected: Image.Ausflag,
      webImg: ausHighlighted,
      webFlag: Ausflag,
      region: "Australia"
    },
    {
      value: "SINGAPORE",
      label: "Singapore",
      code: 4,
      img: Image.singaporeHighlighted,
      unselected: Image.singaporeFlag,
      webImg: singaporeHighlighted,
      webFlag: singaporeFlag,
      region: "Australia"
    },
    {
      value: "UNITED KINGDOM",
      label: "UK",
      code: 5,
      img: Image.ukHighlighted,
      unselected: Image.UKFLag,
      webImg: ukHighlighted,
      webFlag: UKFLag,
      region: "Asia"
    },
    {
      value: "UAE",
      code: 6,
      label: "UAE",
      img: Image.uaeHighlighted,
      unselected: Image.UAEFLAG,
      webImg: uaeHighlighted,
      webFlag: UAEFLAG,
      region: "Asia"
    },
    {
      value: "Germany",
      label: "Germany",
      code: 7,
      img: Image.germanyHighlighted,
      unselected: Image.GermanyFlag,
      webImg: germanyHighlighted,
      webFlag: GermanyFlag,
      region: "Asia"
    },
    {
      value: "Netherlands",
      label: "Netherlands",
      code: 8,
      img: Image.netherlandHighlighted,
      unselected: Image.netherlanFlag,
      webImg: netherlandHighlighted,
      webFlag: netherlanFlag,
      region: "Asia"
    },
    {
      value: "New Zealand",
      label: "New Zealand",
      code: 9,
      img: Image.nzHighlighted,
      unselected: Image.NZFlag3,
      webImg: nzHighlighted,
      webFlag: NZflag3,
      region: "Australia"
    },
    {
      value: "Japan",
      code: 10,
      label: "Japan",
      img: Image.japanHighlighted,
      unselected: Image.JapanFlag,
      webImg: japanHighlighted,
      webFlag: JapanFlag,
      region: "Asia"
    },
  ],
  creditBuilderintrestcountry: [
    {
      value: "USA",
      label: "USA",
      code: 1,
      img: Image.usaHighlighted,
      unselected: Image.USAFlag,
      webImg: usaHighlighted,
      webFlag: USAFlag,
    },
    {
      value: "CANADA",
      label: "Canada",
      code: 2,
      img: Image.canadaT1,
      unselected: Image.canadaT2,
      webImg: canadaT1,
      webFlag: canadaT2,
    },
    {
      value: "AUSTRALIA",
      label: "Australia",
      code: 3,
      img: Image.ausHighlighted,
      unselected: Image.Ausflag,
      webImg: ausHighlighted,
      webFlag: Ausflag,
    },
    {
      value: "SINGAPORE",
      label: "Singapore",
      code: 4,
      img: Image.singaporeHighlighted,
      unselected: Image.singaporeFlag,
      webImg: singaporeHighlighted,
      webFlag: singaporeFlag,
    },
    {
      value: "UNITED KINGDOM",
      label: "UK",
      code: 5,
      img: Image.ukHighlighted,
      unselected: Image.UKFLag,
      webImg: ukHighlighted,
      webFlag: UKFLag,
    },
    {
      value: "UAE",
      code: 6,
      label: "UAE",
      img: Image.uaeHighlighted,
      unselected: Image.UAEFLAG,
      webImg: uaeHighlighted,
      webFlag: UAEFLAG,
    },
    {
      value: "Germany",
      label: "Germany",
      code: 7,
      img: Image.germanyHighlighted,
      unselected: Image.GermanyFlag,
      webImg: germanyHighlighted,
      webFlag: GermanyFlag,
    },
    {
      value: "Netherlands",
      label: "Netherlands",
      code: 8,
      img: Image.netherlandHighlighted,
      unselected: Image.netherlanFlag,
      webImg: netherlandHighlighted,
      webFlag: netherlanFlag,
    },
    {
      value: "New Zealand",
      label: "New Zealand",
      code: 9,
      img: Image.nzHighlighted,
      unselected: Image.NZFlag3,
      webImg: nzHighlighted,
      webFlag: NZflag3,
    },
    {
      value: "Japan",
      code: 10,
      label: "Japan",
      img: Image.japanHighlighted,
      unselected: Image.JapanFlag,
      webImg: japanHighlighted,
      webFlag: JapanFlag,
    },
  ],
  news: [
    {
      images: Image.test1,
      title: "Headline Gold: Article Titles That Pop",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      category: ["test", "list", "data"],
    },
    {
      images: Image.test2,
      title: "Headline Gold: Article Titles That Pop",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      category: ["test", "list", "data"],
    },
    {
      images: Image.test1,
      title: "Headline Gold: Article Titles That Pop",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      category: ["test", "list", "data"],
    },
    {
      images: Image.test2,
      title: "Headline Gold: Article Titles That Pop",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      category: ["test", "list", "data"],
    },
    {
      images: Image.test1,
      title: "Headline Gold: Article Titles That Pop",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      category: ["test", "list", "data"],
    },
    {
      images: Image.test2,
      title: "Headline Gold: Article Titles That Pop",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      category: ["test", "list", "data"],
    },
  ],
  latestcountries: [
    { name: "Afghanistan", id: "AF" },
    { name: "Aland Islands", id: "AX" },
    { name: "Albania", id: "AL" },
    { name: "Algeria", id: "DZ" },
    { name: "American Samoa", id: "AS" },
    { name: "Andorra", id: "AD" },
    { name: "Angola", id: "AO" },
    { name: "Anguilla", id: "AI" },
    { name: "Antarctica", id: "AQ" },
    { name: "Antigua and Barbuda", id: "AG" },
    { name: "Argentina", id: "AR" },
    { name: "Armenia", id: "AM" },
    { name: "Aruba", id: "AW" },
    { name: "Australia", id: "AU" },
    { name: "Austria", id: "AT" },
    { name: "Azerbaijan", id: "AZ" },
    { name: "Bahamas", id: "BS" },
    { name: "Bahrain", id: "BH" },
    { name: "Bangladesh", id: "BD" },
    { name: "Barbados", id: "BB" },
    { name: "Belarus", id: "BY" },
    { name: "Belgium", id: "BE" },
    { name: "Belize", id: "BZ" },
    { name: "Benin", id: "BJ" },
    { name: "Bermuda", id: "BM" },
    { name: "Bhutan", id: "BT" },
    { name: "Bolivia", id: "BO" },
    { name: "Bosnia and Herzegovina", id: "BA" },
    { name: "Botswana", id: "BW" },
    { name: "Bouvet Island", id: "BV" },
    { name: "Brazil", id: "BR" },
    { name: "British Indian Ocean Territory", id: "IO" },
    { name: "Brunei Darussalam", id: "BN" },
    { name: "Bulgaria", id: "BG" },
    { name: "Burkina Faso", id: "BF" },
    { name: "Burundi", id: "BI" },
    { name: "Cambodia", id: "KH" },
    { name: "Cameroon", id: "CM" },
    { name: "Canada", id: "CA" },
    { name: "Cape Verde", id: "CV" },
    { name: "Cayman Islands", id: "KY" },
    { name: "Central African Republic", id: "CF" },
    { name: "Chad", id: "TD" },
    { name: "Chile", id: "CL" },
    { name: "China", id: "CN" },
    { name: "Christmas Island", id: "CX" },
    { name: "Cocos (Keeling) Islands", id: "CC" },
    { name: "Colombia", id: "CO" },
    { name: "Comoros", id: "KM" },
    { name: "Congo", id: "CG" },
    { name: "Congo, The Democratic Republic of the", id: "CD" },
    { name: "Cook Islands", id: "CK" },
    { name: "Costa Rica", id: "CR" },
    { name: 'Cote D"Ivoire', id: "CI" },
    { name: "Croatia", id: "HR" },
    { name: "Cuba", id: "CU" },
    { name: "Cyprus", id: "CY" },
    { name: "Czech Republic", id: "CZ" },
    { name: "Denmark", id: "DK" },
    { name: "Djibouti", id: "DJ" },
    { name: "Dominica", id: "DM" },
    { name: "Dominican Republic", id: "DO" },
    { name: "Ecuador", id: "EC" },
    { name: "Egypt", id: "EG" },
    { name: "El Salvador", id: "SV" },
    { name: "Equatorial Guinea", id: "GQ" },
    { name: "Eritrea", id: "ER" },
    { name: "Estonia", id: "EE" },
    { name: "Ethiopia", id: "ET" },
    { name: "Falkland Islands (Malvinas)", id: "FK" },
    { name: "Faroe Islands", id: "FO" },
    { name: "Fiji", id: "FJ" },
    { name: "Finland", id: "FI" },
    { name: "France", id: "FR" },
    { name: "French Guiana", id: "GF" },
    { name: "French Polynesia", id: "PF" },
    { name: "French Southern Territories", id: "TF" },
    { name: "Gabon", id: "GA" },
    { name: "Gambia", id: "GM" },
    { name: "Georgia", id: "GE" },
    { name: "Germany", id: "DE" },
    { name: "Ghana", id: "GH" },
    { name: "Gibraltar", id: "GI" },
    { name: "Greece", id: "GR" },
    { name: "Greenland", id: "GL" },
    { name: "Grenada", id: "GD" },
    { name: "Guadeloupe", id: "GP" },
    { name: "Guam", id: "GU" },
    { name: "Guatemala", id: "GT" },
    { name: "Guernsey", id: "GG" },
    { name: "Guinea", id: "GN" },
    { name: "Guinea-Bissau", id: "GW" },
    { name: "Guyana", id: "GY" },
    { name: "Haiti", id: "HT" },
    { name: "Heard Island and Mcdonald Islands", id: "HM" },
    { name: "Holy See (Vatican City State)", id: "VA" },
    { name: "Honduras", id: "HN" },
    { name: "Hong Kong", id: "HK" },
    { name: "Hungary", id: "HU" },
    { name: "Iceland", id: "IS" },
    { name: "India", id: "IN" },
    { name: "Indonesia", id: "ID" },
    { name: "Iran, Islamic Republic Of", id: "IR" },
    { name: "Iraq", id: "IQ" },
    { name: "Ireland", id: "IE" },
    { name: "Isle of Man", id: "IM" },
    { name: "Israel", id: "IL" },
    { name: "Italy", id: "IT" },
    { name: "Jamaica", id: "JM" },
    { name: "Japan", id: "JP" },
    { name: "Jersey", id: "JE" },
    { name: "Jordan", id: "JO" },
    { name: "Kazakhstan", id: "KZ" },
    { name: "Kenya", id: "KE" },
    { name: "Kiribati", id: "KI" },
    { name: 'Korea, Democratic People"S Republic of', id: "KP" },
    { name: "Korea, Republic of", id: "KR" },
    { name: "Kuwait", id: "KW" },
    { name: "Kyrgyzstan", id: "KG" },
    { name: 'Lao People"S Democratic Republic', id: "LA" },
    { name: "Latvia", id: "LV" },
    { name: "Lebanon", id: "LB" },
    { name: "Lesotho", id: "LS" },
    { name: "Liberia", id: "LR" },
    { name: "Libyan Arab Jamahiriya", id: "LY" },
    { name: "Liechtenstein", id: "LI" },
    { name: "Lithuania", id: "LT" },
    { name: "Luxembourg", id: "LU" },
    { name: "Macao", id: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of Macedonia", id: "MK" },
    { name: "Madagascar", id: "MG" },
    { name: "Malawi", id: "MW" },
    { name: "Malaysia", id: "MY" },
    { name: "Maldives", id: "MV" },
    { name: "Mali", id: "ML" },
    { name: "Malta", id: "MT" },
    { name: "Marshall Islands", id: "MH" },
    { name: "Martinique", id: "MQ" },
    { name: "Mauritania", id: "MR" },
    { name: "Mauritius", id: "MU" },
    { name: "Mayotte", id: "YT" },
    { name: "Mexico", id: "MX" },
    { name: "Micronesia, Federated States of", id: "FM" },
    { name: "Moldova, Republic of", id: "MD" },
    { name: "Monaco", id: "MC" },
    { name: "Mongolia", id: "MN" },
    { name: "Montenegro", id: "ME" },
    { name: "Montserrat", id: "MS" },
    { name: "Morocco", id: "MA" },
    { name: "Mozambique", id: "MZ" },
    { name: "Myanmar", id: "MM" },
    { name: "Namibia", id: "NA" },
    { name: "Nauru", id: "NR" },
    { name: "Nepal", id: "NP" },
    { name: "Netherlands", id: "NL" },
    { name: "Netherlands Antilles", id: "AN" },
    { name: "New Caledonia", id: "NC" },
    { name: "New Zealand", id: "NZ" },
    { name: "Nicaragua", id: "NI" },
    { name: "Niger", id: "NE" },
    { name: "Nigeria", id: "NG" },
    { name: "Niue", id: "NU" },
    { name: "Norfolk Island", id: "NF" },
    { name: "Northern Mariana Islands", id: "MP" },
    { name: "Norway", id: "NO" },
    { name: "Oman", id: "OM" },
    { name: "Pakistan", id: "PK" },
    { name: "Palau", id: "PW" },
    { name: "Palestinian Territory, Occupied", id: "PS" },
    { name: "Panama", id: "PA" },
    { name: "Papua New Guinea", id: "PG" },
    { name: "Paraguay", id: "PY" },
    { name: "Peru", id: "PE" },
    { name: "Philippines", id: "PH" },
    { name: "Pitcairn", id: "PN" },
    { name: "Poland", id: "PL" },
    { name: "Portugal", id: "PT" },
    { name: "Puerto Rico", id: "PR" },
    { name: "Qatar", id: "QA" },
    { name: "Reunion", id: "RE" },
    { name: "Romania", id: "RO" },
    { name: "Russian Federation", id: "RU" },
    { name: "RWANDA", id: "RW" },
    { name: "Saint Helena", id: "SH" },
    { name: "Saint Kitts and Nevis", id: "KN" },
    { name: "Saint Lucia", id: "LC" },
    { name: "Saint Pierre and Miquelon", id: "PM" },
    { name: "Saint Vincent and the Grenadines", id: "VC" },
    { name: "Samoa", id: "WS" },
    { name: "San Marino", id: "SM" },
    { name: "Sao Tome and Principe", id: "ST" },
    { name: "Saudi Arabia", id: "SA" },
    { name: "Senegal", id: "SN" },
    { name: "Serbia", id: "RS" },
    { name: "Seychelles", id: "SC" },
    { name: "Sierra Leone", id: "SL" },
    { name: "Singapore", id: "SG" },
    { name: "Slovakia", id: "SK" },
    { name: "Slovenia", id: "SI" },
    { name: "Solomon Islands", id: "SB" },
    { name: "Somalia", id: "SO" },
    { name: "South Africa", id: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", id: "GS" },
    { name: "Spain", id: "ES" },
    { name: "Sri Lanka", id: "LK" },
    { name: "Sudan", id: "SD" },
    { name: "Surivalue", id: "SR" },
    { name: "Svalbard and Jan Mayen", id: "SJ" },
    { name: "Swaziland", id: "SZ" },
    { name: "Sweden", id: "SE" },
    { name: "Switzerland", id: "CH" },
    { name: "Syrian Arab Republic", id: "SY" },
    { name: "Taiwan, Province of China", id: "TW" },
    { name: "Tajikistan", id: "TJ" },
    { name: "Tanzania, United Republic of Tanzania", id: "TZ" },
    { name: "Thailand", id: "TH" },
    { name: "Timor-Leste", id: "TL" },
    { name: "Togo", id: "TG" },
    { name: "Tokelau", id: "TK" },
    { name: "Tonga", id: "TO" },
    { name: "Trinidad and Tobago", id: "TT" },
    { name: "Tunisia", id: "TN" },
    { name: "Turkey", id: "TR" },
    { name: "Turkmenistan", id: "TM" },
    { name: "Turks and Caicos Islands", id: "TC" },
    { name: "Tuvalu", id: "TV" },
    { name: "Uganda", id: "UG" },
    { name: "Ukraine", id: "UA" },
    { name: "United Arab Emirates", id: "AE" },
    { name: "United Kingdom", id: "GB" },
    { name: "United States", id: "US" },
    { name: "United States Minor Outlying Islands", id: "UM" },
    { name: "Uruguay", id: "UY" },
    { name: "Uzbekistan", id: "UZ" },
    { name: "Vanuatu", id: "VU" },
    { name: "Venezuela", id: "VE" },
    { name: "Viet Nam", id: "VN" },
    { name: "Virgin Islands, British", id: "VG" },
    { name: "Virgin Islands, U.S.", id: "VI" },
    { name: "Wallis and Futuna", id: "WF" },
    { name: "Western Sahara", id: "EH" },
    { name: "Yemen", id: "YE" },
    { name: "Zambia", id: "ZM" },
    { name: "Zimbabwe", id: "ZW" },
  ],
  experience: [
    { value: "< 5 years", code: 1 },
    { value: " Between 6 And 10", code: 2 },
    { value: " Between 11 And 15", code: 3 },
    { value: " Between 16 And 20", code: 4 },
    { value: "Between 21 And 25", code: 5 },
    { value: "> 25 years", code: 6 },
  ],
  yesno: [
    { value: "Yes", code: 1 },
    { value: "No", code: 2 },
    { value: "In Processing", code: 3 },
  ],
  oneFive: [
    { value: "1" },
    { value: "2" },
    { value: "3" },
    { value: "4" },
    "5",
  ],
  currencyArray: [
    { value: "USD", label: "USD" },
    { value: "GBP", label: "GBP" },
    { value: "EUR", label: "EUR" },
    { value: "AUD", label: "AUD" },
    { value: "CAD", label: "CAD" },
    { value: "INR", label: "INR" },
    { value: "NZD", label: "NZD" },
    { value: "SGD", label: "SGD" },
    { value: "MYR", label: "MYR" },
    { value: "JPY", label: "JPY" },
    { value: "CNY", label: "CNY" },
    { value: "SAR", label: "SAR" },
    { value: "MXN", label: "MXN" },
    { value: "AED", label: "AED" },
    { value: "HKD", label: "HKD" },
    { value: "ILS", label: "ILS" },
    { value: "IRR", label: "IRR" },
    { value: "BRL", label: "BRL" },
  ],
  currencyArrayWithCountry: [
    { value: "USD", country: "United States" },
    { value: "GBP", country: "United Kingdom" },
    { value: "EUR", country: "Spain" },
    { value: "AUD", country: "Australia" },
    { value: "CAD", country: "Canada" },
    { value: "INR", country: "India" },
    { value: "NZD", country: "New Zealand" },
    { value: "SGD", country: "Singapore" },
    { value: "MYR", country: "Malaysia" },
    { value: "JPY", country: "Japan" },
    { value: "CNY", country: "China" },
    { value: "SAR", country: "Saudi Arabia" },
    { value: "MXN", country: "Mexico" },
    { value: "AED", country: "United Arab Emirates" },
    { value: "HKD", country: "Hong Kong" },
    { value: "ILS", country: "Israel" },
    { value: "IRR", country: "Iran" },
    { value: "BRL", country: "Brazil" },
  ],
  sex: [
    { name: "Male", id: "1" },
    { name: "Female", id: "2" },
    { name: "Other", id: "3" },
  ],
  marital_status: [
    { name: "Single", id: "1" },
    { name: "Married", id: "2" },
    { name: "Common-law partner", id: "3" },
  ],
  oneTwenty: [
    { value: "1" },
    { value: "2" },
    { value: "3" },
    { value: "4" },
    { value: "5" },
    { value: "6" },
    { value: "7" },
    { value: "8" },
    { value: "9" },
    { value: "10" },
    { value: "11" },
    { value: "12" },
    { value: "13" },
    { value: "14" },
    { value: "15" },
    { value: "16" },
    { value: "17" },
    { value: "18" },
    { value: "19" },
    { value: "20" },
  ],

  eligbility_qns: [
    {
      question:
        "Do you have any hard budget for the cost of your immigration application? If you do please enter a number in USD.",
      type: "integer",
      info: "The maximum cost you can spend for your immigration application, to filter options that may be out of that scope. We will use the choose the lowest cost possibility for each path to enable you to choose from any as many as possible.",
    },
    {
      question:
        "Do you have any hard budget for duration of your immigration application? If you do please enter a number in days.",
      type: "integer",
      info: "The maximum duration you can budget for your immigration application to filter options that may be out of that scope. We will use the choose the lowest duration possibility for each path to enable you to choose from any as many as possible.",
    },
    {
      question: "Are you single, married or have a common-law partner?",
      type: "select",
      select_options: ["", "single", "married", "common-law partner"],
      info: "Common-Law Partner: A person that you have lived in a conjugal relationship with for at least 12 months.",
    },
    {
      question:
        "Which University did you receive your highest-level graduate degree from?",
      type: "select",
      select_options: [
        "",
        "Loyola College, TN, India",
        "IIT Madras, TN, India",
        "College of Engineering Guindy, Anna University, TN, India",
        "St.Joseph's College of Engineering, TN, India",
        "SSN College of Engineering, TN, India",
        "SRM Institute of Science and Technology, TN, India",
        "VIT University - Vellore Institute of Technology, TN, India",
        "Ramakrishna Mission Vivekananda College, TN, India",
        "Karunya Institute of Technology and Sciences, TN, India",
        "Ethiraj College For Women, TN, India",
        "Madras Institute Of Technology, TN, India",
        "National University of Singapore, Singapore",
        "Harvard University, MA, USA",
        "Massachusetts Institute of Technology, MA, USA",
        "University of Illinois at Urbana-Champaign, IL, USA",
      ],
    },
    {
      question: "Do you have work experience in your domain(s) of study?",
      type: "boolean",
      select_options: ["", "Yes", "No"],
      info: "The domain of study could be able any area of study in which you have obtained a bachelors degree or higher.",
    },
    {
      question:
        "How many months of work experience in your domain(s) of study do you have (Please provide as a non-decimal number)?",
      type: "integer",
      info: "The domain of study could be able any area of study in which you have obtained a bachelors degree or higher.",
    },
  ],
  collegesInChennai: [
    "Sri g.v.g visalkshi college for women - Chennai",
    "Aalim Muhammed Salegh College of Paramedical Science - Chennai",
    "Aalim Muhammed Salegh College of Engineering - Chennai",
    "Aarupadai Veedu Insititute of Technology - Chennai",
    "AEC Business School - Chennai",
    "Agurchand Manmull Jain College - Chennai",
    "Alagappa Chettiar College of Engineering & Technology - Chennai",
    "All India Institute of Management Studies - Chennai",
    "Alpha Arts and Science College (AASC) - Chennai",
    "Alpha College of Engineering - Chennai",
    "Anand Institute of Higher Technology - Chennai",
    "Anna Adarsh College for Women - Chennai",
    "Apollo Institute of Hospital Management & Allied Sciences - Chennai",
    "Asan Memorial College of Arts & Science - Chennai",
    "Asian College of Journalism (ACJ) - Chennai",
    "B.S. Abdur Rahman Crescent Engineering College - Chennai",
    "Bhajarang Engineering College - Chennai",
    "Bhaktavatsalam Memorial College for Women - Chennai",
    "Bharath Institute of Higher Education & Research - Chennai",
    "Bharathi Womens College - Chennai",
    "Birla Institute of Technology - Chennai",
    "Brilliant School of Management - Chennai",
    "C. P. Ramaswamy Aiyar Environmental Education Centre - Chennai",
    "Canan School of Catering and Hotel Management - Chennai",
    "Central Footwear Training Centre - Chennai",
    "Central Institute of Plastics Engineering and Technology - Chennai",
    "Central Leather Research Institute - Chennai",
    "Central Polytechnic - Chennai",
    "Chennai Business School - Chennai",
    "Chennai Institute of Communication Advertising Club - Chennai",
    "City School of Social and Managerial Sciences - Chennai",
    "Chevalier T. Thomas Elizabeth College",
    "All India Institute of Management Studies - Chennai",
    "Alpha Arts and Science College - Chennai",
    "D.G. Vaishnav College-School of Management Studies - Chennai",
    "Darshan Dental College - Chennai",
    "Deen Dayal Engineering College - Chennai",
    "IITM, Department of Humanities and Social Sciences - Chennai",
    "IITM, Department of Management Studies - Chennai",
    "Dhaanish Ahmed College of Engineering - Chennai",
    "Dhanalakshmi College of Engineering - Chennai",
    "Dhanraj Baid Jain College - Chennai",
    "Dharmamurthi Rao Bahadur Calavala Cunnan Chetty - Chennai",
    "DMI College of Engineering - Chennai",
    "Dr. Ambedkar Government Arts College - Chennai",
    "Dr. Dharmambal Government Polytechnic College for Women - Chennai",
    "Dr. MGR Educational & Research Institute - Chennai",
    "Dr. MGR-Janaki College of Arts and Science for Women - Chennai",
    "Dr.MGR Educational & Research Institutes - Chennai",
    "Dwaraka Doss Goverdhan Doss Vaishnav College - Chennai",
    "E.V.P Engineering College - Chennai",
    "Easwari Engineering College for Women - Chennai",
    "EIKON Academy - Chennai",
    "Ethiraj College for Women - Chennai",
    "Film & Television Institute of Tamil Naidu - Chennai",
    "Ganesh Institute of Engineering - Chennai",
    "GKM College of Engineering and Technology - Chennai",
    "Government Arts College - Chennai",
    "Government Polytechnic for Women - Chennai",
    "Great Lakes Institute of Management - Chennai",
    "Guru Nanak College - Chennai",
    "Guru Shree Shanti Vijai Jain College for Women - Chennai",
    "Hindustan College of Arts and Science - Chennai",
    "Hindustan College of Engineering - Chennai",
    "ICFAI National College - Chennai",
    "Image College of Arts, Animation & Technology - Chennai",
    "Indian Institute of Planning and Management - Chennai",
    "Indian Institute of Technology Madras - Chennai",
    "Indira Institute of Engineering & Technology - Chennai",
    "Institute for Technology and Management - Chennai",
    "Institute of Advanced Study in Education - Chennai",
    "Institute of Child Health & Hospital for Children - Chennai",
    "Institute of Hotel Management, Catering Technology & Applied Nutrition - Chennai",
    "International Airlines Academy - Chennai",
    "International Council for Management Studies - Chennai",
    "Jaya College of Arts and Science - Chennai",
    "Jaya Engineering College - Chennai",
    "Jayagovind Harigopal Agarwal Agarsen College - Chennai",
    "Jeppiaar Engineering College - Chennai",
    "Jerusalem College of Engineering - Chennai",
    "Justice Basheer Ahmed Sayeed Womens College - Chennai",
    "K K College of Pharmacy - Chennai",
    "K.C.S Kasi Nadar College of Arts and Science - Chennai",
    "Karpaga Vinayaga College of Engineering and Technology - Chennai",
    "KCG College of Technology - Chennai",
    "Keltron Animation Campus - Chennai",
    "Kilpauk Medical College - Chennai",
    "King College Of Technology - Chennai",
    "Kumararani Meena Muthiah College of Arts And Science - Chennai",
    "Lady Willingdon Institute of Advanced Study in Education - Chennai",
    "Loyola College - Chennai",
    "Loyola Institute of Technology - Chennai",
    "M.O.P. Vaishnav College for Women - Chennai",
    "Madha Engineering College - Chennai",
    "Madras Christian College - Chennai",
    "Madras Institute of Development Studies - Chennai",
    "Madras Institute of Technology - Chennai",
    "Madras Medical College - Chennai",
    "Madras School of Economics - Chennai",
    "Magna College of Engineering - Chennai",
    "Magnus School of Business - Chennai",
    "Maharishi Institute of Management - Chennai",
    "Measi Institute of Information Technology - Chennai",
    "Meenakshi Ammal Dental College - Chennai",
    "Meenakshi College for Women - Chennai",
    "Meenakshi College of Engineering - Chennai",
    "Meenakshi Krishnan Polytechnic - Chennai",
    "Meenakshi Sundararajan Engineering College - Chennai",
    "Misrimal Navajee Munoth Jain Engineering College - Chennai",
    "Mohamed Sathak College of Arts and Science - Chennai",
    "Murugappa Polytechnic College - Chennai",
    "National Institute of Business Management - Chennai",
    "National Institute of Export Management - Chennai",
    "National Institute of Fashion Technology - Chennai",
    "National Institute of Human Resources Development - Chennai",
    "National Institute of Management Studies - Chennai",
    "NTTF Information Technology Centre - Chennai",
    "Ordnance Factories Institute of Learning - Chennai",
    "P.B. College Of Engineering - Chennai",
    "P.T. Lee Chengalvaraya Naicker College of Engineering & Technology - Chennai",
    "Pachaiyappa's College - Chennai",
    "Panimalar Engineering College - Chennai",
    "Panimalar Institute of Management Studies & Computer Science - Chennai",
    "Panimalar Polytechnic College - Chennai",
    "Patrician College of Arts and Science - Chennai",
    "PMR Institute of Technology - Chennai",
    "Police Training College - Chennai",
    "Presidency College - Chennai",
    "Prince Shri Venkateshwara Arts and Science College - Chennai",
    "Prince Shri Venkateshwara Padmavathy Engineering College - Chennai",
    "Prof. Dhanapalan College for Women - Chennai",
    "Quaid-E-Milleth Women College - Chennai",
    "Queen Mary's College - Chennai",
    "R.M.D.Engineering.College - Chennai",
    "Ragas Dental College & Hospital - Chennai",
    "Rai Business School - Chennai",
    "Raja Rajeswari Engineering College - Chennai",
    "Rajalakshmi Engineering College - Chennai",
    "Ramakrishna Mission Vivekananda College - Chennai",
    "Royal College of Engineering & Technology - Chennai",
    "S K R Engineering College - Chennai",
    "S.A. Engineering College - Chennai",
    "S.I.V.E.T. College - Chennai",
    "Sakthi Mariamman Engineering College - Chennai",
    "Sapthagiri College of Engineering - Chennai",
    "Saveetha Dental College & Hospitals - Chennai",
    "Shree Chandra Prabhu Jain College - Chennai",
    "Shree Motilal Kanhaiyalal Fomra Institute of Technology - Chennai",
    "Shri Andal Alagar College of Engineering - Chennai",
    "Shrimathi Devkunvar Nanalal Bhatt Vaishnav College For Women - Chennai",
    "Sindhi College of Arts and Science - Chennai",
    "Sir Theagaraya College - Chennai",
    "SMOT School Of Business - Chennai",
    "Sri Ambal Academy - Chennai",
    "Sri Balaji Polytechnic College - Chennai",
    "Sri Krishna Engineering College - Chennai",
    "Sri Lakshmi Ammal College of Engineering - Chennai",
    "Sri Muthukumaran Institute of Technology - Chennai",
    "Sri Nallalaghu Polytechnic - Chennai",
    "Sri Ramachandra Medical College & Research Institute - Chennai",
    "Sri Ramanujar Engineering College - Chennai",
    "Sri Sai Ram Engineering College - Chennai",
    "Sri Venkateswara College of Engineering and Technology - Chennai",
    "Srinivasa Institute of Engineering and Technology - Chennai",
    "Sri Ram Engineering College - Chennai",
    "SRM College of Arts and Science - Chennai",
    "SSN College of Engineering - Chennai",
    "SSN School of Management & Computer Applications - Chennai",
    "St Peter's Engineering College - Chennai",
    "St Thomas College of Arts and Science - Chennai",
    "St. Christophers College of Education - Chennai",
    "St. Joseph's College of Engineering - Chennai",
    "St. Michael Polytechnic - Chennai",
    "St. Mary - Chennai",
    "Stanley Medical College - Chennai",
    "Stansfield School of Business - Chennai",
    "Stella Maris College - Chennai",
    "Stella Matutina College of Education - Chennai",
    "T.J.Institute of Technology - Chennai",
    "Tagore Engineering College - Chennai",
    "Tamil Nadu Advanced Techical Training Institute - Chennai",
    "Tamil Nadu Institute of Labour Studies - Chennai",
    "Thangavelu College of Science And Management - Chennai",
    "The Institute for Financial Management and Research - Chennai",
    "The Institute of Cost & Works Accountants of India - Chennai",
    "The Institute of Mathematical Sciences - Chennai",
    "The Venkataramana Ayurveda College - Chennai",
    "TMG College of Arts & Science - Chennai",
    "Valliammal College for Women - Chennai",
    "Vel MultiTech Sri Rangarajan Sakuntahala Engineering College - Chennai",
    "VEL Tech Engineering College - Chennai",
    "VEL'S College of Science - Chennai",
    "Velammal Engineering College - Chennai",
    "Vels Srinivasa College of Engineering & Technology - Chennai",
    "Women's Christian College - Chennai",
    "Sri Kanyak Parameswari Arts & Science College For Women - Chennai",
    "Balaji Institute of Engineering and Technology - Chennai",
  ],
};
