import { isArray } from "lodash";
import React from "react";
import { Text, TouchableOpacity } from "react-native";
import { styles } from "./Bullets.styles";

export const Bullet = ({ text, data, updateSettings, maxSelection }) => {
  const onSelect = () => {
    try {
      const newArr = [...data];
      if (data?.includes(text)) {
        newArr.splice(
          data.findIndex((e) => e === text),
          1
        );
      } else if (newArr.length != maxSelection) {
        newArr.push(text);
      }
      updateSettings(newArr);
    }
    catch {
      console.log("Home Selection")
    }
  };

  return (
    <TouchableOpacity
      onPress={onSelect}
      style={[styles.container, {
        borderColor: isArray(data) && data?.includes(text) ? "#F1AB32" : "rgb(200,200,200)",
      }]}
    >
      <Text style={{ fontSize: 15 }}>⚪</Text>
      <Text style={styles.text}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};
