import React, { useState } from "react";
import { Text, View, TouchableOpacity, Image, FlatList } from "react-native";
import { Images } from "../../../common";
import { notificationModalStyles as styles } from "../notificationModal.styles";
import config from "../../../common/config";

const filterTypes = [
  {
    name: 'All',
    id: '0',
  },
  {
    name: 'Oppourtunity',
    id: '1',
    img: Images.oppicon
  },
  {
    name: 'Threats',
    id: '2',
    img: Images.threatIcon
  },
  {
    name: 'General',
    id: '3',
  },
  {
    name: 'Rules Change',
    id: '4',
  },
  {
    name: 'Health',
    id: '5',
  },
  {
    name: 'Emergency',
    id: '6',
  },
  {
    name: 'Guidance',
    id: '7',
  }
]

function Notifications({
  notifications = [],
  title,
  onNotificationPress = () => { },
}) {
  const [filteredArr, setfilteredArr] = useState([])
  const [filteredApplied, setfilteredApplied] = useState(false)
  const renderSource = (type) => {
    let icon;
    type == 1
      ? (icon = Images.rocketIcon)
      : type == 2
        ? (icon = Images.updatenotify)
        : type == 3
          ? (icon = Images.policynotify)
          : type == 4
            ? (icon = Images.eventnotify)
            : (icon = null);
    return icon;
  };

  const handeFilter = (type) => {
    let temp = [...notifications]
    let filtered = []
    setfilteredApplied(type)
    filtered = temp.filter((s) => s.type == type)
    setfilteredArr(filtered)
    if (type == 'All') {
      filtered = temp
      setfilteredArr(filtered)
    }
  }

  return (
    <>
      <View style={styles.header}>
        <Text style={styles.notificationHeaderText}>{title} </Text>
      </View>
      <View>
        <FlatList
          data={filterTypes}
          horizontal
          showsHorizontalScrollIndicator={false}
          style={{ marginVertical: 8, marginLeft: 23, }}
          renderItem={({ item, index }) => (
            <TouchableOpacity onPress={() => handeFilter(item.name)}
              activeOpacity={0.5}
              style={[styles.filterItemView, item.name == filteredApplied && styles.selected , item.img && styles.noborders]}>
              {
                item.img ?
                  <Image source={item.img} style={{ height: 22, width: 22 }} /> :
                  <Text style={{ color: item.name == filteredApplied ? 'white' : 'black' }}>
                    {item.name}
                  </Text>
              }
            </TouchableOpacity>
          )}
        />
      </View>
      <FlatList
        data={filteredApplied || filteredArr?.length > 0 ? filteredArr : notifications}
        contentContainerStyle={{ paddingHorizontal: 15 }}
        ListEmptyComponent={() => (
          <View
            style={{
              alignItems: "center",
              paddingTop: 15,
              justifyContent: "center",
            }}
          >
            <Text>No new {filteredApplied || ''} related {title}</Text>
          </View>
        )}
        renderItem={({ item, index }) => (
          <TouchableOpacity
            onPress={() => onNotificationPress(item.notification_id)}
            key={index + "bn"}
            style={[styles.notifItem, {}]}
          >
            <Image
              source={renderSource(item.notification_type)}
              style={styles.notifItem_type}
            />
            <View
              style={{
                width: "80%",
                marginRight: 5,
              }}
            >
              <Text style={styles.notifItemDescription}>{item.title}</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flex: 1,
                  marginTop: 5,
                }}
              >
                <Text
                  style={[
                    styles.notifItemDescriptionClassificationText,
                    {
                      color: item.classification === "threat" ? "red" : "green",
                    },
                  ]}
                >
                  {item.classification != "neither" ? item.classification : ""}
                </Text>
                <Text style={styles.notifItemDescriptionCountryText}>
                  {
                    config.intrestcountry[
                      parseInt(item.user_id.split("_")[1]) - 1
                    ].value
                  }{" "}
                </Text>
              </View>
            </View>
            <Image
              source={Images.notificationIconModal_forward}
              style={styles.rightIcon}
            />
          </TouchableOpacity>
        )}
      />
    </>
  );
}

export default Notifications;
