import Api from "../service/apiCall";
import { helpers } from "../common";
import NavigationService from "../navigation/NavigationService";
import { Network, apiPaths } from "../service/api";
import AsyncStorage from "@react-native-community/async-storage";

export const getImmipathDetails = (
  user_id,
  authtoken,
  id,
  countryName,
  goalArray,
  callback
) => {
  var authdata = user_id + "@" + authtoken;
  var string = "Success";

  return async dispatch => {
    dispatch({
      type: "GET_IMMIPATH_LOADING"
    });
    try {
      return Api("/immipaths/paths/" + id + "?", null, "GET", authdata)
        .then(res => {
          if (res.status == string) {
            dispatch({
              type: "GET_IMMIPATH_DETAILS_SUCCESS",
              pathDetails: res.path_details,
              countryName: countryName,
              goalArray: goalArray
            });
            callback("success");
          } else {
            dispatch({
              type: "GET_IMMIPATH_DETAILS_FAIL",
              payload: []
            });
          }
          return res;
        })
        .catch(function (error) {
          dispatch({
            type: 'GET_IMMIPATH_DETAILS_FAIL',
            payload: error.responseBody
          })
          if (error.status == 401) {
            helpers.handleSessionExpiry()
          }
        });
    } catch (error) {
      dispatch({
        type: "GET_IMMIPATH_DETAILS_FAIL",
        payload: error.responseBody
      });
      return error;
    }
  };
};

export const fetchQuestions = (userid, token) => {

  return async dispatch => {
    const headers = {
      Authorization: `${userid}@${token}`
    }
    var string = "Success";
    Network(apiPaths.eligibilityQuestions, 'GET', null, headers).then(response => {
      if (response.status == string) {
        const data = response.eligib_qns_data.eligbility_qns
        let newContentData = []
        data.map((content, index) => {
          newContentData.push({ id: index + 1, ...content })
        })
        dispatch({
          type: "FETCH_QUESTIONAIRE",
          payload: newContentData
        })
      }
    })
  }
}

export const getSavedUserPath = (userid, token) => {
  return async dispatch => {
    const headers = {
      Authorization: `${userid}@${token}`
    }
    const url = `/users/${userid}/app_tracker`;
    Network(url, 'GET', null, headers).then(response => {
      if (response.status == 'Success') {
        dispatch({
          type: "FETCH_SAVED_PATHS",
          payload: response?.applications || []
        })
      }
    })
  }
}

export const resolveQuestionnaire = (
  user_id,
  authtoken,
  body,
  queData,
  showAllVisas,
  callback
) => {
  body.elig_qns_responses == '' ?
    body.elig_qns_responses = {} :
    body.elig_qns_responses = body.elig_qns_responses
  showAllVisas == true ? body.show_all = true : body.show_all = false
  var authdata = user_id + "@" + authtoken;
  var string = "Success";
  const headers = {
    Authorization: authdata
  }
  return async dispatch => {
    dispatch({
      type: "RESOLVE_QUESTIONNAIRE_LOADING"
    });
    try {
      return Network(apiPaths.resolvequestionnaire, 'POST', body, headers)
        .then(res => {
          if (res.status == string) {
            callback && callback("Success");
            dispatch({
              type: "RESOLVE_QUESTIONNAIRE_SUCCESS",
              payload: res.immipaths,
              questionnaire: queData,
              excluded_path: res.excluded_paths,
              excluded_reason: res.excluded_reason,
              promotions: res.promotions
            });
          } else {
            dispatch({
              type: "RESOLVE_QUESTIONNAIRE_FAIL",
              payload: res.message
            });
            return error;
          }
          return res;
        })
        .catch(function (error) {
          dispatch({
            type: 'RESOLVE_QUESTIONNAIRE_FAIL',
            payload: error.responseBody
          })
          if (error.status == 401) {
            helpers.handleSessionExpiry()
          }
        });
    } catch (error) {
      dispatch({
        type: "RESOLVE_QUESTIONNAIRE_FAIL",
        payload: error.responseBody
      });
      return error;
    }
  };
};

export const saveQuestionsArray = questArray => {
  return async dispatch => {
    dispatch({
      type: "SAVE_QUESTIONS",
      payload: questArray
    });
  };
};

export const changeEligibility = (val, id, isTextInput, title, base_unit) => {
  return async dispatch => {
    dispatch({
      type: "CHANGE_ELIGIBILITY",
      val,
      id,
      isTextInput,
      title,
      base_unit
    });
  };
};

export const setPassportDate = date => {
  return async dispatch => {
    dispatch({
      type: "SET_PASSPORT_DATE",
      payload: date
    });
  };
};

export const setDropDownValue = val => {
  return async dispatch => {
    dispatch({
      type: "SET_DROPDOWN",
      payload: val
    });
  };
};

export const selectDropDown = val => {
  return async dispatch => {
    dispatch({
      type: "SELECT_DROPDOWN",
      payload: val
    });
  };
};

export const isKeepData = value => {
  return async dispatch => {
    dispatch({
      type: "KEEP_HISTORY",
      payload: value
    });
  };
};

export const backToInitialState = () => {
  return async dispatch => {
    dispatch({
      type: "CLEAR_QUESTIONS_DATA"
    });
  };
};

export const continueFromPrevious = value => {
  return async dispatch => {
    dispatch({
      type: "CONTINUE_FROM_PREVIOUS",
      payload: value
    });
  };
};

export const toggleTick = index => {
  return async dispatch => {
    dispatch({
      type: "TOGGLE_TICK",
      payload: index
    });
  };
};

export const selectAll = isSelected => {
  return async dispatch => {
    dispatch({
      type: 'SELECT_ALL',
      payload: isSelected,
      select_all: isSelected,
    })
  }
}

export const proceedFromImmipaths = () => {
  return async dispatch => {
    dispatch({
      type: "PROCEED_FROM_IMMIPATHS"
    });
    dispatch(continueFromPrevious(2))
  };
};

export const clearDetails = () => {
  return async dispatch => {
    dispatch({
      type: "CLEAR_DETAILS"
    });
  };
};

export const setSelectedGoalandCountries = (payload) => {
  return async dispatch => {
    dispatch({
      type: "SET_SELECTED_GOAL",
      payload
    });
  };
};

export const setSetupProfileQuestionsList = (payload) => {
  return async dispatch => {
    dispatch({
      type: "SETUP_PROFILE_QUESTION_LIST",
      payload
    });
  };
};

export const setPreviousSavePaths = (payload) => {
  return async dispatch => {
    dispatch({
      type: "PEVIOUS_SAVED_PATHS",
      payload
    });
  };
};

export const setPreviousTrack = (payload) => {
  return async dispatch => {
    dispatch({
      type: "PEVIOUS_USER_TRACK",
      payload
    });
  };
};

export const viewImmipathId = (path) => {
  return async dispatch => {
    dispatch({
      type: "VIEW_IMMIPATH_DATA",
      payload: path
    });
  };
};

export const continueFromPreviousSearch = (payload) => {
  return async dispatch => {
    dispatch({
      type: "CONTINUE_PREVIOUS_SEARCH",
      payload
    });
  };
};

export const setUndergraduteQuestions = (payload) => {
  return async dispatch => {
    dispatch({
      type: "SET_UNDERGRADUATE_QUESTIONS",
      payload
    });
  };
};

export const setUndergraduteUserAnswer = (payload) => {
  return async dispatch => {
    dispatch({
      type: "SET_UNDERGRADUATE_USER_ANSWERS",
      payload
    });
  };
};

export const setUserDegreeAndCourseSelection = (payload, payload2) => {
  return async dispatch => {
    dispatch({
      type: "SET_USER_DEGREE_COURSE_SELECTION",
      userselection: payload,
      userSelectionWithQuestionId: payload2
    });
  };
};

export const setUserImmipathSearchResult = (payload) => {
  return async dispatch => {
    dispatch({
      type: "SET_USER_IMMMIPATH_SEARCH_RESULT",
      payload
    });
  };
};

export const setSelectedUniversity = (payload) => {
  return async dispatch => {
    dispatch({
      type: "SET_SELECTED_UNIVERSITY",
      payload
    });
  };
};

export const setShortlistedUnivrsity = (payload) => {
  return async dispatch => {
    dispatch({
      type: "SET_SHORTLISTED_UNIVERSITY",
      payload
    });
  };
};

export const searchImmipaths = (user_id, authtoken, body, callback) => {
  var authdata = user_id + "@" + authtoken;
  var string = "Success";
  const headers = {
    Authorization: `${user_id}@${authtoken}`
  }
  return async dispatch => {
    dispatch({
      type: "SEARCH_IMMI_LOADING"
    });
    try {
      return Network(apiPaths.immipath_search, 'POST', body, headers)
        .then(res => {
          // console.log("@@@@@@@@", res);

          if (res.status == string) {
            callback(res);
            dispatch({
              type: "SEARCH_IMMI_SUCCESS",
              payload: {
                res,
                countryOfInterest: body.country_ids,
                immigationInterest: body.goal_ids
              }
            });
          } else {
            dispatch({
              type: "SEARCH_IMMI_FAIL",
              payload: {
                countryOfInterest: [],
                immigationInterest: []
              }
            });
            return error;
          }
          return res;
        })
        .catch(function (error) {
          dispatch({
            type: "SEARCH_IMMI_FAIL",
            payload: {
              countryOfInterest: [],
              immigationInterest: []
            }
          })
          if (error.status == 401) {
            helpers.handleSessionExpiry()
          }
        });
    } catch (error) {
      dispatch({
        type: "SEARCH_IMMI_FAIL",
        payload: {
          res,
          countryOfInterest: [],
          immigationInterest: []
        }
      });
      return error;
    }
  };
};

export const fetchDynamicFacts = (userid, token) => {

  return async dispatch => {
    const headers = {
      Authorization: `${userid}@${token}`
    }
    Network(apiPaths.dynamicFacts, 'GET', null, headers).then(response => {
      if (response.status === "Success") {
        dispatch({
          type: "FETCH_DYNAMIC_FACTS",
          payload: response.facts_metadata
        })
      }
    })
  }
}

export const pathExplorerTutorial = () => {
  return async dispatch => {
    dispatch({
      type: "TUTORIAL_GO_PRESSED",
    })
  }
}

export const pathGoalTutorialNextClick = () => {
  return async dispatch => {
    dispatch({
      type: "PATH_TUTORIAL_NEXT_CLICK",
    })
  }
}

export const pathGoalTutorialProceed = (text) => {
  return async dispatch => {
    dispatch({
      type: "PATH_TUTORIAL_PROCEED",
      pathTutorialProceed: text
    })
  }
}

export const sideBarTutorialFinishClicked = (status) => {
  return async dispatch => {
    dispatch({
      type: "SIDEBAR_TUTORIAL_FINISH_CLICKED",
      sideBarTutorialFinshClicked: status
    })
  }
}

export const countriesNextClickedStatus = (status) => {
  return async dispatch => {
    dispatch({
      type: "COUNTRIES_NEXT_CLICKED",
      countriesNextClicked: status
    })
  }
}

export const callQuesButtonTutorial = (status) => {
  return async dispatch => {
    dispatch({
      type: "QUESTIONNAIRE_TUTORIAL_NEXT_CLICKED",
      quesTutorialNextClicked: status
    })
  }
}
export const callEligiblePathNext = (status) => {
  return async dispatch => {
    dispatch({
      type: "CALL_ELIGIBLE_PATH_NEXT",
      eligiblePathNext: status
    })
  }
}
export const callShortListButtonGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "CALL_SHORTLIST_BUTTON_GUIDE",
      shortListButtonGuide: status
    })
  }
}
export const callApplicationTimelineGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "APPLICATION_TIMELINE_GUIDE",
      applicationTimelineGuide: status
    })
  }
}
export const callToPathExpGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "CALL_PATH_EXP_GUIDE",
      callPathExpGuide: status
    })
  }
}
export const restartEligibilitQuesGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "RESTART_QUES_GUIDE",
      restartQuesGuide: status
    })
  }
}
export const restartEligibilitPathHeaderGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "RESTART_ELIG_PATHEADER_GUIDE",
      restartEligiPathHeaderGuide: status
    })
  }
}

export const EligibilitPathHeaderGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "ELIG_PATHEADER_GUIDE",
      EligiPathHeaderGuide: status
    })
  }
}

export const restartTimelineGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "RESTART_APP_TIMELINE_GUIDE",
      restartAppTimelineGuide: status
    })
  }
}
export const restartNewsQuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "RESTART_NEWS_GUIDE",
      restartNewsGuideStatus: status
    })
  }
}
export const restartsaveImmiPathGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "RESTART_SAVE_IMMIPATH_GUIDE",
      restartsaveimmipath: status
    })
  }
}
export const callComparatorGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "CALL_COMPARATOR_GUIDE",
      comparatorGuide: status
    })
  }
}
export const comopareGuideNextClick = (status) => {
  return async dispatch => {
    dispatch({
      type: "COMPARE_GUIDE_NEXT_CLICK",
      compareGuideNext: status
    })
  }
}
export const restartComparatorGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "RESTART_COMPARATOR_GUIDE",
      restartComparator: status
    })
  }
}

export const callFilterButtonGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "FILTER_BUTTON_GUIDE",
      filterButtonGuide: status
    })
  }
}

export const callAdviceChannelNextQuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "ADVICE_CHANNEL_QUIDE_NEXT",
      adviceChannelQuideNext: status
    })
  }
}

export const restartAdvicechannelGuide = (status) => {
  return async dispatch => {
    dispatch({
      type: "RESTART_ADVICE_CHANNEL_GUIDE",
      restartAdviceChannelguideStatus: status
    })
  }
}

export const showAllVisasStatus = (status) => {
  return async dispatch => {
    dispatch({
      type: "SHOW_ALL_VISAS",
      showAllVisas: status
    })
  }
}

export const AddToDoList = (user_id, authtoken, body) => {
  var authdata = user_id + "@" + authtoken;
  let url = `/users/${user_id}/todo_list`
  var string = "Success";
  const headers = {
    Authorization: `${user_id}@${authtoken}`
  }

  return async dispatch => {
    dispatch({
      type: "ADD_TO_DO_LIST_LOADING"
    });
    try {
      return Network(url, 'POST', body, headers)
        .then(res => {
          if (res.status == 'Success') {
            return res;
          } else {

          }
        })
        .catch(function (error) {
          dispatch({
            type: "ADD_TO_DO_FAILED",
            payload: {

            }
          })
          if (error.status == 401) {
            helpers.handleSessionExpiry()
          }
          return error;
        });
    } catch (error) {
      dispatch({
        type: "ADD_TO_DO_FAILED",
        payload: {

        }
      });
      return error;
    }
  };
};

export const GetToDoItems = (user_id, authtoken) => {
  var authdata = user_id + "@" + authtoken;
  let url = `/users/${user_id}/todo_list`
  var string = "Success";
  const headers = {
    Authorization: `${authtoken}`
  }
  return async dispatch => {
    dispatch({
      type: "GET_TO_DO_LIST_LOADING"
    });
    try {
      return Network(url, 'GET', null, headers)
        .then(res => {
          if (res.status == 'Success') {
            dispatch({
              type: "GET_TO_DO_ITEM_SUCCESS",
              payload: res.data
            })
          } else {

          }
        })
        .catch(function (error) {
          dispatch({
            type: "ADD_TO_DO_FAILED",
            payload: {

            }
          })
          if (error.status == 401) {
            helpers.handleSessionExpiry()
          }
          return error;
        });
    } catch (error) {
      dispatch({
        type: "ADD_TO_DO_FAILED",
        payload: {

        }
      });
      return error;
    }
  };
};

export const setGuestlogin = (status) => {
  return async dispatch => {
    if(status){
      dispatch({
        type: "AUTH_USER_FAIL",
      });
    }
    dispatch({
      type: "GUEST_LOGIN_STATUS",
      guestLogin: status
    })
  }
}
