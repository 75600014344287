import { StyleSheet } from "react-native";
import { family } from "../../common";
import { heightPercentageToDP, widthPercentageToDP } from "../../common/responsiveScreen";

export const styles = StyleSheet.create({
  mainView: {
    height: heightPercentageToDP(100),
    position: "absolute",
    backgroundColor: "#0f1143",
    alignSelf: "flex-end",
    width: widthPercentageToDP(60),
    zIndex: 1000,
  },
  optionsText: {
    fontFamily: family.regular,
    color: "white",
    fontSize: 17,
    marginLeft: 15,
    textAlign: "left",
    width: "85%",
  },
  logoutButtonText: {
    fontFamily: family.regular,
    color: "white",
    fontSize: 17,
  },
  optionIconsStyle: {
    height: 25,
    width: 25,
  },
  imageView: {
    flexDirection: "row",
    width: "70%",
    alignSelf: "center",
    marginTop: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  logoutButton: {
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 50,
    alignSelf: "center",
    height: heightPercentageToDP("4%"),
    backgroundColor: "#F1AB32",
  },
  optionsView: {
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: heightPercentageToDP("12%"),
  },
  leftArrow: {
    alignItems: "center",
    justifyContent: "center",
    transform: [{ rotateX: "180deg" }],
    alignSelf: "flex-start",
    position: "absolute",

    top: 15,
  },
  mailImage: {
    height: heightPercentageToDP(12),
    width: heightPercentageToDP(12),
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 60,
    alignItems: "center",
    marginTop: 5,
    borderWidth: 2,
    borderColor: "#fff",
  },
  optionInnerView: {
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "flex-start",
    width: "65%",
    marginTop: heightPercentageToDP("4%"),
    justifyContent: "space-between",
  },
});
